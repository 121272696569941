export function deepClone<T>(obj: T): T {
    if (obj && typeof(obj) === 'object' && !(obj instanceof Date)) {
        return Object.keys(obj).reduce((accumulator, key) => {
            return {
                ...accumulator,
                [`${key}`]: deepClone(obj[key])
            };
        },
        {}) as T;
    }

    return obj;
}
