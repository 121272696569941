import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Store } from '@ngxs/store';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { ProfileState } from './../../../../modules/core/profile/state/profile.state';
import { ChannelPreference } from './../../../../shared/models/pharmacy/pharmacy-comms-details.model';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ClientViewModel } from '../../../../modules/core/profile/client.model';
import { MobileState } from '../../../../modules/profile/details/mobile-state-modal/state/mobile-state-modal.state';

export interface ChannelSelectModalSubmitEvent {
  commsType: ChannelPreference,
  contact: string
}

@Component({
  selector: 'app-channel-select-modal',
  templateUrl: './channel-select-modal.component.html',
  styleUrls: ['./channel-select-modal.component.scss']
})

export class ChannelSelectModalComponent implements OnInit {
  commsType = ChannelPreference;
  sendDocForm: FormGroup;
  profile: ClientViewModel;
  mobileStatus = MobileState;

  @Output() cancel: EventEmitter<void> = new EventEmitter<void>();
  @Output() submit: EventEmitter<ChannelSelectModalSubmitEvent> = new EventEmitter<ChannelSelectModalSubmitEvent>();

  constructor(public fb: FormBuilder, private store: Store) { }

  ngOnInit() {
    this.initForm();
  }

  private initForm() {
    this.profile = this.store.selectSnapshot(ProfileState.profile);
    this.sendDocForm = this.fb.group(
      {
        commsType: this.fb.control(ChannelPreference.Email),
        email: this.fb.control(this.profile.patient.emailAddress),
        mobile: this.fb.control(this.profile.patient.mobileNumber)
      }
    );
  }

  @Dispatch()
  closeClicked = () => this.cancel.emit()

  @Dispatch()
  submitClicked = () => {
    var commsType = this.sendDocForm.controls.commsType.value;
    this.submit.emit({
      commsType: commsType,
      contact: (commsType === ChannelPreference.Email) ? this.sendDocForm.controls.email.value : this.sendDocForm.controls.mobile.value
    });
  }
}
