import { GetAdvancedSearchResults, GetAdvancedSearchResultsSuccess, GetAdvancedSearchResultsError, ClearAdvancedSearchResults, ToggleAdvancedSearchClient } from './advanced-search.actions';
import { StateContext, State, Action, Selector } from '@ngxs/store';
import { AdvancedSearchService } from '../services/advanced-search.service';
import { tap, finalize, catchError } from 'rxjs/operators';
import { AdvancedClient } from '../models/advanced-client';
import { AlertService } from '~/modules/core/alert/alert.service';
import { Injectable } from '@angular/core';

export class AdvancedSearchStateModel {
  clients: AdvancedClient[];
  loading: boolean;
}
@Injectable()
@State<AdvancedSearchStateModel>({
  name: 'advancedSearch',
  defaults: {
    loading: false,
    clients: [],
  }
})

export class AdvancedSearchState {
  constructor(private advancedSearchService: AdvancedSearchService, private alertService: AlertService) { }

  @Selector()
  static clients(state: AdvancedSearchStateModel) { return state.clients }

  @Selector()
  static selectedClients(state: AdvancedSearchStateModel) { return state.clients.filter(x => x.selected).length }

  // This isn't right because we assume that the mobile number is the corect field where some may be using phone number
  @Selector()
  static recipientList(state: AdvancedSearchStateModel) {
    return [
      ...new Set(state.clients.filter(x => x.selected)
        .map(x => ({
          destination: x.mobileNumber ? x.mobileNumber : x.phoneNumber,
          customerName: `${x.firstname} ${x.surname}`,
          clientId: x.id
        })))
    ]
  }


  @Selector()
  static loading(state: AdvancedSearchStateModel) { return state.loading }

  @Action(GetAdvancedSearchResults)
  getAdvancedSearchResults(ctx: StateContext<AdvancedSearchStateModel>, { query }: GetAdvancedSearchResults) {
    ctx.patchState({ loading: true });
    return this.advancedSearchService.getAdvancedSearchResults(query).pipe(
      tap(response => ctx.dispatch(new GetAdvancedSearchResultsSuccess(response))),
      catchError(error => ctx.dispatch(new GetAdvancedSearchResultsError(error))),
      finalize(() => ctx.patchState({ loading: false }))
    )
  }

  @Action(ToggleAdvancedSearchClient)
  toggleAdvancedSearchClient(ctx: StateContext<AdvancedSearchStateModel>, { id }: ToggleAdvancedSearchClient) {
    const clients = ctx.getState().clients;
    ctx.patchState({ clients: clients.map(x => x.id == id ? ({ ...x, selected: !x.selected }) : x) });
  }

  @Action(GetAdvancedSearchResultsSuccess)
  getAdvancedSearchResultsSuccess(ctx: StateContext<AdvancedSearchStateModel>, { clients }: GetAdvancedSearchResultsSuccess) {
    //hack fix the dirty result set to make bulk messaging work
    clients.forEach(client => {
      if (client.mobileNumber == null || client.mobileNumber == undefined || client.mobileNumber == '') {
        if (client.phoneNumber && client.phoneNumber != null && client.phoneNumber.length == 10) {
          client.mobileNumber = client.phoneNumber;
        }
      }
    })
    ctx.patchState({ clients })
  }

  @Action(GetAdvancedSearchResultsError)
  getAdvancedSearchResultsError(ctx: StateContext<AdvancedSearchStateModel>, { error }: GetAdvancedSearchResultsError) {
    this.alertService.error(`An error occured fetching query results ${error}`);
    ctx.patchState({ loading: false })
  }

  @Action(ClearAdvancedSearchResults)
  clearAdvancedSearchResults(ctx: StateContext<AdvancedSearchStateModel>, { }: ClearAdvancedSearchResults) {
    ctx.patchState({ clients: [] });
  }
}
