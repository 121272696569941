<ng-container *ngIf="(outOfStoreState$ | async) as outOfStoreState">
  <div class="modal">
    <div class="modal-body border__grey out_of_store__items" g-xs="p3">
      <ng-container *ngIf="!(loading$ | async) else loading">

        <div>
          <span (click)="closeModal()" class="pull-right pointer">Close <i class="fas fa-times"></i></span>
        </div>
        <div>
          <h2 g-xs="my1">Add Out of Store Scripts</h2>
          <div class="grid">
            <div class="grid__unit" g-xs="cols:12 pr2">
              <div class="search">
                <input [disabled]="!outOfStoreState.isFullItem" [(ngModel)]="searchedVal"
                  (ngModelChange)="searchChanged($event)" (typeaheadOnSelect)="typeaheadOnSelect($event)"
                  [typeaheadAsync]="true" [typeahead]="searchedStocks$" typeaheadOptionField="name"
                  [typeaheadOptionsLimit]="15" [typeaheadHideResultsOnBlur]="true" placeholder="Enter product name"
                  class="form-control input-primary border__grey-light" />
                <i class="fas fa-search"></i>
              </div>
            </div>

          </div>
          <ng-container *ngIf="formItems as items">
            <form #outOfStoreForm="ngForm">
              <div g-xs="my2" class="scroll-table">
                <table  class="table table__primary">
                  <thead>
                    <tr>
                      <th class="round-top-left">Name</th>
                      <th>Category</th>
                      <th>Last Dispensed</th>
                      <th>Prescribed</th>
                      <th>Frequency</th>
                      <th>Repeats Remaining</th>
                      <th>Quantity</th>
                      <th>DOH</th>
                      <th style="font-size: 15px;" class="round-top-right"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let item of items">
                      <td>{{item.name}}</td>
                      <td>
                        <select [disabled]="!outOfStoreState.isFullItem" class="form-control input-primary border__grey-light"
                          name="{{getFormName(item, 'category')}}" [(ngModel)]="item.categoryId" required>
                          <option *ngFor="let category of (tenantCategories$ | async)" [value]="category.id">
                            {{category.name}}
                          </option>
                        </select>
                      </td>
                      <td>
                        <div class="w-75" >
                          <input [disabled]="!outOfStoreState.isFullItem" name="{{getFormName(item, 'lastDispensed')}}" type="date"
                            class="form-control border__grey-light" id="lastDispensed"
                            [(ngModel)]="item.lastDispensedDate">
                        </div>
                      </td>
                      <td>
                        <div class="w-75">
                          <input name="{{getFormName(item, 'prescriptionDate')}}" type="date"
                            class="form-control border__grey-light" id="prescriptionDate"
                            [(ngModel)]="item.prescriptionDate" required>
                        </div>
                      </td>
                      <td>
                        <input [disabled]="!outOfStoreState.isFullItem" type="number" min="0" required
                          class="width__50 number-input" name="{{getFormName(item, 'frequency')}}"
                          [(ngModel)]="item.frequency" />
                      </td>
                      <td>
                        <input type="number" min="1" required class="width__50 number-input form-control"
                          name="{{getFormName(item, 'repeats')}}" [(ngModel)]="item.repeats" />
                      </td>
                      <td>
                        <input type="number" min="1" required class="width__50 number-input"
                          name="{{getFormName(item, 'quantity')}}" [(ngModel)]="item.quantity" />
                      </td>
                      <td>
                        <input type="number" required class="width__50 number-input"
                          name="{{getFormName(item, 'DOH')}}" [(ngModel)]="item.doh" />
                      </td>
                      <td>
                        <span (click)="removeItem(item)">
                          <em class="fas fa-times red"></em>
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="out_of_store__items__total flex" g-xs="mt3">
                <div class="btn-pnl">
                  <button [disabled]="outOfStoreForm.invalid || !items || !items.length" (click)="addToProfile(items)"
                    class="add-btn btn__yellow">
                    <span>Save to Profile</span><i class="fas fa-check"></i>
                  </button>
                </div>
              </div>
            </form>
          </ng-container>
        </div>
      </ng-container>
      <ng-template #loading>
        <app-loader></app-loader>
      </ng-template>
    </div>

  </div>
  <div class="modal-background">
  </div>
</ng-container>
