import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';
import { ChatState } from './chat.state';
import { EmailModule } from '../../../../../modules/profile/comms/email/email.module';
import { SmsModule } from '../../../../../modules/profile/comms/sms/sms.module';

@NgModule({
  imports: [
    NgxsModule.forFeature([
      ChatState]),
      EmailModule,
      SmsModule
  ]
})
export class ChatStateModule { }
