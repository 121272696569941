import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Store, Select, Actions, ofActionSuccessful } from '@ngxs/store';
import { OrderRequestState, OrderRequestStateModel } from '../state/order.state';
import { OrderRequest } from '../models/order-request.model';
import { getTenantTimezoneDateString } from '../../../core/timezone/logic/timezone-tenant.logic';
import { ToggleSendToQueueModal, StripeModalVisibleAction, DispenseScriptsToQueue, ToggleOrderStatusModal, UpdateOrderAction, ToggleImageModal, SearchOrderStockAction, ToggleAdditionalItemsModal, SetCurrentOrder, ToggleAddMedicationModal, GetPredictedOrder, CreateOrderAction, CreateOrderResponse } from '../state/order.actions';
import { ProfileState } from '../../../core/profile/state/profile.state';
import { SendToQueueUpdate } from '../components/send-to-queue-modal/send-to-queue-modal.component';
import { OrderMethod, OrderRequestItemType, OrderRequestStatus } from '../models/order.enums';
import { OrderRequestItem } from '../models/order-request-item.model';
import { ClientViewModel } from '../../../core/profile/client.model';
import { combineDateAndTime } from '../../../../shared/helpers/date-helpers';
import { ValidService } from '../components/order-table/order-table.service';
import { takeUntil } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { isNullOrWhiteSpace } from '../../../../shared/helpers/string.helpers';
import { HumanizePipe } from '../../../../shared/pipes/humanize.pipe';
import { extractEScript } from '../../../../shared/helpers/eScript.helper';
import { PharmacyCommsDetailsState } from '../../../../modules/pharmacy-comms/pharmacy-comms-details/state/pharmacy-comms-details.state';
import { PharmacyLocation } from '../../../../shared/models/pharmacy/pharmacy-address.model';
import { GetPharmacyLocationAction } from '../../../../modules/pharmacy-comms/pharmacy-comms-details/state/pharmacy-comms-details.actions';
import { Clipboard } from '@angular/cdk/clipboard';
import { DispenseItemModel, EScriptType } from '@base/shared/models/dispense/dispenseItem.model';
import { mapOrderRequestItemstoDispenseItemsModel } from '@base/shared/helpers/dispense-request.helpers';

@Component({
  selector: 'app-order-create',
  templateUrl: './order-create.component.html',
  styleUrls: ['./order-create.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class OrderCreateComponent implements OnInit, OnDestroy {
  @Select(OrderRequestState) order$: Observable<OrderRequestStateModel>;
  @Select(OrderRequestState.searchListFiltered) searchListFiltered$: Observable<any>;
  @Select(OrderRequestState.profileOrderItems) profileOrderItems$: Observable<any[]>;
  @Select(OrderRequestState.hasSelectedOrderItems) hasSelectedOrderItems$: Observable<any>;
  @Select(PharmacyCommsDetailsState.pharmacy) pharmacy$: Observable<any>;

  pharmacy: PharmacyLocation;
  profile: ClientViewModel;
  notesOpen = false;
  orderMethods = OrderMethod;
  submitted = false;
  originalOrderStatus = null;
  private ngUnsubscribe = new Subject();

  constructor(
    private store: Store,
    private validService: ValidService,
    private actions$: Actions,
    private router: Router,
    private route: ActivatedRoute,
    private humanizer: HumanizePipe,
    private clipboard: Clipboard

  ) {
  }

  ngOnInit() {
    this.validService.validStatus.subscribe(x => x);
    this.profile = this.store.selectSnapshot(ProfileState.profile);
    this.store.dispatch(new GetPredictedOrder(this.profile.clientId));
    this.actions$
      .pipe(
        ofActionSuccessful(CreateOrderResponse),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe(() => {
        this.router.navigate([`../`], { relativeTo: this.route });
      });

      this.pharmacy = this.store.selectSnapshot(PharmacyCommsDetailsState.pharmacy);

      if (this.pharmacy == null) {
        this.store.dispatch(new GetPharmacyLocationAction());
        this.pharmacy$.subscribe((res) => {
          this.pharmacy = res;
        })
      }
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  changeOrderItems($event, order) {
    this.store.dispatch(new SetCurrentOrder(
      {
        ...order,
        orderRequestedItems: $event
      }
    ));
  }

  isReadOnly(order: OrderRequest) {
    return order.status === OrderRequestStatus.Cancelled || order.status === OrderRequestStatus.Ready;
  }

  formatDate = (date: string) => {
    return getTenantTimezoneDateString(this.store, date, 'DD/MM/YYYY');
  }

  changeOrderMethod(event) {
    this.store.dispatch(new UpdateOrderAction(event, null, null, false));
  }

  learnMore() {
    window.open('https://stripe.com/', '_blank');
  }

  visibleStripeModal(visible) {
    this.store.dispatch(new StripeModalVisibleAction(visible));
  }

  closeStripeModal() {
    this.store.dispatch(new StripeModalVisibleAction(false));
  }

  copyPaymentLink(paymentUrl: string) {
    this.clipboard.copy(paymentUrl);
  }

  closeModal(order) {
    this.store.dispatch(new ToggleOrderStatusModal(false, order, null));
  }

  closeSendModal() {
    this.store.dispatch(new ToggleSendToQueueModal(false));
  }

  updateOrderFromStatusCard(event) {
    this.openUpdateStatusModal(event);
  }

  openUpdateStatusModal(order) {
    this.store.dispatch(new ToggleOrderStatusModal(true, order));
  }

  createOrder(update) {
    this.store.dispatch(new CreateOrderAction(update.order, update.message, update.channel, update.sendMessage));
  }

  openSendToQueueModal(order) {
    this.store.dispatch(new ToggleSendToQueueModal(true))
  }

  sendToQueue(form: SendToQueueUpdate) {
    this.store.dispatch(new DispenseScriptsToQueue({
        items: mapOrderRequestItemstoDispenseItemsModel(form.order.orderRequestedItems, this.profile.clientId),
        patient: `${this.profile.patient.surname} ${this.profile.patient.firstname}`,
        pickupDate: form.datePart !== null ? combineDateAndTime(form.datePart, form.timePart).utc().toDate().toISOString() : null,
        options: form.options,
        notes: form.notes
      }));
  }

  openImageModal(item) {
    this.store.dispatch(new ToggleImageModal(true, item));
  }

  closeImageModal() {
    this.store.dispatch(new ToggleImageModal(false));
  }

  openAdditionalItemsModal() {
    this.store.dispatch(new ToggleAdditionalItemsModal(true));
  }

  closeAdditionalItemsModal() {
    this.store.dispatch(new ToggleAdditionalItemsModal(false));
  }

  openAddMedicationModal() {
    this.store.dispatch(new ToggleAddMedicationModal(true));
  }

  closeAddMedicationModal() {
    this.store.dispatch(new ToggleAddMedicationModal(false));
  }

  stockSearch(searchText) {
    this.store.dispatch(new SearchOrderStockAction(searchText));
  }

  addItem(order: OrderRequest, item: OrderRequestItem) {
    if (item.orderRequestItemType == OrderRequestItemType.EScript) {
      item.price = item.customerMedication.patientPrice;
      item.eScriptRepeats =  item.customerMedication?.repeats;
      item.eScriptQty = item.customerMedication?.quantity;
      item.eScriptDrugName = item.customerMedication?.name;
      item.eScriptTokenUrl = item.customerMedication?.repeatTokenUri;
    }
    order.orderRequestedItems = [...order.orderRequestedItems, item];
    this.store.dispatch(new SetCurrentOrder({ ...order }))
  }

  removeItem(order: OrderRequest, item: OrderRequestItem) {
    order.orderRequestedItems = [...order.orderRequestedItems.filter(x => x.stockId !== item.stockId)];
    this.store.dispatch(new SetCurrentOrder({ ...order }))
  }

  changeOrderStatus(orderMethod, order) {
    this.store.dispatch(new SetCurrentOrder({ ...order, orderMethod: orderMethod }))
  }

}
