import { AttendingDoctor } from '../model/attending-doctor';
import { State, Selector, StateContext, Action } from '@ngxs/store';
import { AttendingDoctorService } from '../service/attending-doctor.service';
import { FetchAttendingDoctors } from './attending-doctor.action';
import { tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';


export class AttendingDoctorStateModel {
  doctors: Array<AttendingDoctor>;
}
@Injectable()
@State<AttendingDoctorStateModel>({
  name: 'attendingDoctors',
  defaults: {
    doctors: []
  }
})
export class AttendingDoctorState {

  constructor(private readonly attendingDoctorService: AttendingDoctorService) { }

  @Selector()
  static patientDoctors(state: AttendingDoctorStateModel) {
    return state.doctors;
  }

  @Action(FetchAttendingDoctors)
  public fetchDoctors(ctx: StateContext<AttendingDoctorStateModel>, action: FetchAttendingDoctors) {
    // Remove old doctors so that you dont accidently
    // see another patients doctors when switching between clients in between loading
    ctx.patchState({
      doctors: []
    });
    return this.attendingDoctorService.getDoctors(action.clientId, action.fromDate, action.toDate)
      .pipe(tap((doctors) => {
        ctx.patchState({
          doctors
        });
      }));
  }
}
