<h3 class="ml-3">Advanced Search</h3>
<section g-xs="my3">
  <div class="grid">
    <div g-xs="cols:12">
      <tabset class="base-tabs" >
        <tab heading="Scrypt Search">
          <app-scrypt-search></app-scrypt-search>
          <app-scrypt-search-results-table></app-scrypt-search-results-table>
        </tab>
        <tab heading="Dispense Search">
          <form class="form mb-5 m-3" [formGroup]="advancedSearchForm" (ngSubmit)="submitSearch()">
            <div class="mt-3">
              <h4 class="pointer" (click)="togglePersonalDetailsAccordion()">
                Personal
                <i *ngIf="personalDetailsAccordion == 'in'" class="fas fa-caret-up"></i>
                <i *ngIf="personalDetailsAccordion == 'out'" class="fas fa-caret-down"></i>
              </h4>
              <div class="pt-3" [@accordionAnimation]="personalDetailsAccordion">
                <div class="form-row px-1">
                  <div class="form-group col-md-2">
                    <label>First Name</label>
                    <input type="text" class="form-control" formControlName="firstName" />
                  </div>
                  <div class="form-group col-md-2">
                    <label>Surname</label>
                    <input type="text" class="form-control" formControlName="surname" />
                  </div>
                  <div class="form-group col-md-2">
                    <label>Gender</label>
                    <select class="form-control gender-select" formControlName="genders" multiple>
                      <option selected value="">All</option>
                      <option value="M">Male</option>
                      <option value="F">Female</option>
                      <option value="U">Unknown</option>
                    </select>
                  </div>
                  <div class="form-group col-md-2">
                    <label>Date of Birth</label>
                    <input class="w100" formControlName="birthDate" type="text" placeholder="dd-mm-yyyy"
                      class="form-control border__grey-light calendar" id="date"
                      [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY' }" bsDatepicker />
                  </div>
                  <div class="form-group col-md-2">
                    <label>Mobile Number</label>
                    <input type="text" class="form-control" formControlName="mobileNumber" />
                  </div>
                  <div class="form-group col-md-3">
                    <label>Email</label>
                    <input type="text" class="form-control" formControlName="emailAddress" />
                  </div>
                  <div class="form-group col-md-3">
                    <label>Home Address</label>
                    <input type="text" class="form-control" formControlName="homeAddress" />
                  </div>
                  <div class="form-group col-md-2">
                    <label>Suburb</label>
                    <input type="text" class="form-control" formControlName="homeSuburb" />
                  </div>
                  <div class="form-group col-md-2">
                    <label>State</label>
                    <input type="text" class="form-control" formControlName="homeState" />
                  </div>
                </div>
                <div class="form-row px-1">
                  <div class="form-group col-md-2">
                    <label>Postcode From</label>
                    <input type="text" class="form-control" formControlName="postcodeFrom" />
                  </div>
                  <div class="form-group col-md-2">
                    <label>Postcode To</label>
                    <input type="text" class="form-control" formControlName="postcodeTo" />
                  </div>
                  <div class="form-group col-md-2">
                    <label>Age From</label>
                    <input type="text" class="form-control" formControlName="ageFrom" />
                  </div>
                  <div class="form-group col-md-2">
                    <label>Age To</label>
                    <input type="text" class="form-control" formControlName="ageTo" />
                  </div>
                  <div class="form-group col-md-2 d-flex align-items-center">
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" id="repeatsHeld" formControlName="repeatsHeld">
                      <label for="repeatsHeld" class="form-check-label">Repeats Held</label>
                    </div>
                  </div>
                  <div class="form-group col-md-2 d-flex align-items-center">
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" id="hasMobileNumber" [(ngModel)]="hasMobileNumber" formControlName="hasMobile">
                      <label class="form-check-label" for="hasMobileNumber">Has Mobile</label>
                    </div>
                  </div>
                  <div class="form-group col-md-2 d-flex align-items-center">
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" id="isNotDeceased" formControlName="isNotDeceased">
                      <label class="form-check-label" for="hasMobileNumber">Exclude Deceased Customers</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="mt-3">
              <h4 class="pointer" (click)="toggleMedicalDetailsAccordion()">Medical
                <i [ngClass]="medicalDetailsAccordion === 'in' ? 'fas fa-caret-up' : 'fas fa-caret-down'"></i>
              </h4>
              <div class="pt-3" [@accordionAnimation]="medicalDetailsAccordion">
                <div class="form-row px-1">
                  <div class="form-group col-md-3">
                    <label>Nursing Home</label>
                    <ng-container *ngIf="(nursingHomes$ | async) as nursingHomes" )>
                      <select class="form-control" formControlName="nursingHomeIds" multiple>
                        <ng-container *ngIf="(nursingHomeLoading$ | async) as loading">
                          <option selected *ngIf="loading">Loading...</option>
                        </ng-container>
                        <option selected *ngIf="nursingHomes.length === 0">No nursing homes</option>
                        <ng-container *ngFor="let home of nursingHomes; let first = first">
                          <option selected *ngIf="first" value="">Not applicable</option>
                          <option [value]="home.nursingHomeID">{{ home.name }}</option>
                        </ng-container>
                      </select>
                    </ng-container>
                  </div>
                  <div class="form-group col-md-3">
                    <label>Entitlements</label>
                    <select class="form-control" style="height: auto;" formControlName="concessions" multiple>
                      <option selected value="">Not applicable</option>
                      <option>Medicare</option>
                      <option>DVA</option>
                      <option>Concession</option>
                      <option>CTG</option>
                    </select>
                  </div>
                  <div class="form-group col-md-3">
                    <label>Medicare Number</label>
                    <input type="text" class="form-control" formControlName="medicareNo" />
                  </div>
                  <div class="form-group col-md-3">
                    <label>Repat Number</label>
                    <input type="text" class="form-control" formControlName="repatNo" />
                  </div>
                  <div class="form-group col-md-3">
                    <label>Safety Net Entitlement Number </label>
                    <input type="text" class="form-control" formControlName="safetyNetEntitlementNo" />
                  </div>
                </div>
              </div>
            </div>
            <div class="mt-3">
              <h4 class="pointer" (click)="toggleSystemDetailsAccordion()">
                Engagement
                <i *ngIf="systemDetailsAccordion == 'in'" class="fas fa-caret-up"></i>
                <i *ngIf="systemDetailsAccordion == 'out'" class="fas fa-caret-down"></i>
              </h4>
              <div class="py-3" [@accordionAnimation]="systemDetailsAccordion">
                <div class="form-row px-1">
                  <div class="form-group col-md-2">
                    <label>First Visit From</label>
                    <input class="w100" formControlName="createdFrom" type="text" placeholder="dd-mm-yyyy"
                      class="form-control border__grey-light calendar" id="date"
                      [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY' }" bsDatepicker />
                  </div>
                  <div class="form-group col-md-2">
                    <label>First Visit To</label>
                    <input class="w100" formControlName="createdTo" type="text" placeholder="dd-mm-yyyy"
                      class="form-control border__grey-light calendar" id="date"
                      [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY' }" bsDatepicker />
                  </div>
                  <div class="form-group col-md-2">
                    <label>Last Visit From</label>
                    <input class="w100" formControlName="lastDispenseFrom" type="text" placeholder="dd-mm-yyyy"
                      class="form-control border__grey-light calendar" id="date"
                      [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY' }" bsDatepicker />
                  </div>
                  <div class="form-group col-md-2">
                    <label>Last Visit To</label>
                    <input class="w100" formControlName="lastDispenseTo" type="text" placeholder="dd-mm-yyyy"
                      class="form-control border__grey-light calendar" id="date"
                      [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY' }" bsDatepicker />
                  </div>
                  <div class="form-group col-md-2 ">
                    <div class="form-check checkbox-margin">
                      <input type="checkbox" class="form-check-input" id="whoopingVax"  formControlName="whoopingVax">
                      <div class="d-flex justify-content-center">
                        <label class="form-check-label" for="whoopingVax">Whooping Cough Vax eligibility
                        </label>
                        <app-scrypt-insight (click)="stopPropagation($event)"
                            content="Results will show all patients over the age of 65, taking respiratory medication who have not had a pertussis vaccine in the past 10 years."
                            context="context">
                        </app-scrypt-insight>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="mt- ml-2 d-flex">
              <input type="submit" [disabled]="!advancedSearchForm.valid" class="btn btn-primary" value="Search" />
              <input type="button" class="btn btn-danger ml-4 mr-4" (click)="clearForm()" value="Clear Form" />
              <ng-container *ngIf="isLoading$ | async">
                  <app-loader loadingMessage="Fetching results"></app-loader>
              </ng-container>
            </div>
          </form>
          <app-advanced-search-results-table></app-advanced-search-results-table>
        </tab>
      </tabset>
    </div>
  </div>
</section>
