import { State, Action, StateContext, Selector } from '@ngxs/store';
import * as moment_ from 'moment';
const moment = moment_;
import { SickLeavePatientDetailsFormModel } from '../../../../../../shared/models/six-cpa/sick-leave/sick-leave-patient-details-form.model';
import { SetTotalItemsAndCompletedItems, UpdateSickLeavePatientDetails } from './sick-leave-patient-details.actions';
import { SetProfileForSixCpaAction } from '../../../state/six-cpa.actions';
import { TenantDatePipe } from '../../../../../core/timezone/pipes/tenant-date.pipe';
import { Injectable } from '@angular/core';

export class SickLeavePatientDetailsStateModel {
  totalItems: number;
  completedItems: number;
  sickLeavePatientDetailsForm: {
    model: Partial<SickLeavePatientDetailsFormModel>;
  };
}
@Injectable()
@State<SickLeavePatientDetailsStateModel>({
  name: 'sickLeavePatientDetails',
  defaults: {
    completedItems: 0,
    totalItems: 0,
    sickLeavePatientDetailsForm: {
      model: undefined
    }
  }
})
export class SickLeavePatientDetailsState {
  constructor(private tenantPipe: TenantDatePipe) {}

  @Selector()
  static sickLeavePatientDetailsForm(state: SickLeavePatientDetailsStateModel) { return state.sickLeavePatientDetailsForm.model; }

  @Selector()
  static totalItems({ totalItems }: SickLeavePatientDetailsStateModel) { return totalItems; }

  @Selector()
  static completedItems({ completedItems }: SickLeavePatientDetailsStateModel) { return completedItems; }

  @Action(SetProfileForSixCpaAction)
  onSetProfile(ctx: StateContext<SickLeavePatientDetailsStateModel>, { profile }: SetProfileForSixCpaAction) {
    const patient = profile.patient;
    ctx.patchState({
      sickLeavePatientDetailsForm: {
        model: {
          dob: patient.birthDate && this.tenantPipe.transform(patient.birthDate.toString(), 'DD-MM-YYYY'),
          firstName: patient.firstname,
          surname: patient.surname,
          address: `${patient.homeAddress} ${patient.homeSuburb} ${patient.homeState} ${patient.homePostcode}`
        }
      }
    });
  }

  @Action(UpdateSickLeavePatientDetails)
  updateForm(ctx: StateContext<SickLeavePatientDetailsStateModel>, { form }: UpdateSickLeavePatientDetails) {
    if (form) {
      ctx.patchState({
        sickLeavePatientDetailsForm: {
          model: {
            dob: form.dob && moment(form.dob, 'YYYY-MM-DD').format('DD-MM-YYYY'),
            firstName: form.firstName,
            surname: form.surname,
            address: form.address,
          }
        }
      });
    } else {
      const state = ctx.getState();
      ctx.patchState({
        sickLeavePatientDetailsForm: {
          model: {
            ...state.sickLeavePatientDetailsForm.model,
          }
        }
      });
    }

  }

  @Action(SetTotalItemsAndCompletedItems)
  setProgress({ patchState }: StateContext<SickLeavePatientDetailsStateModel>, { totalItems, completedItems }: SetTotalItemsAndCompletedItems) {
    patchState({ totalItems, completedItems });
  }
}
