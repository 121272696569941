import { ClientViewModel } from '../../../core/profile/client.model';
import { SixCpaDraftModel } from '../../../../shared/models/six-cpa/six-cpa-draft.model';
import { SixCpaResponse } from '../../profile-services/state/profile-services.state';
import { SixCpaFormTypes } from '../six-cpa-form-types.enum';

export enum SixCpaActionTypes {
  TOGGLE_SIXCPA_ACTION = '[SixCpa] Toggle Blade',
  SET_PROFILE_FOR_SIXCPA_ACTION = '[SixCpa] set Profile',
  TOGGLE_SIXCPA_CONTENT_ACTION = '[SixCpa] Toggle Content',
  QUICK_CI_SIXCPA_ACTION = '[SixCpa] Quick CI',
  SET_SELECTED_FORM_TYPE = '[SixCpa] Set Selected Form',
  START_NEW_SIX_CPA = '[SixCpa] start New',
  SWITCH_PROFILE_NEW_SIX_CPA = '[SixCpa] Switch Profile',
  OPEN_RESET_FORM_MODAL = '[SixCpa] Open Reset Modal',
  CLOSE_RESET_FORM_MODAL = '[SixCpa] Close Reset Modal',
  OPEN_PDF_FORM_MODAL = '[SixCpa] Open PDF Modal',
  CLOSE_PDF_FORM_MODAL = '[SixCpa] Close PDF Modal',
  SET_SIX_CPA_DRAFTS_TYPE = '[SixCpa] Set Drafts',
  GET_SIX_CPA_FORM_ERROR = '[SixCpa] Error',
  SET_SIX_CPA_FORM_SUMMARY = '[SixCpa] Set Summary',
  CLEAR_SIX_CPA_FORM_SERVICE = '[SixCpa] Clear Form Service'
}



export class OpenPdfFormModal {
  static readonly type = SixCpaActionTypes.OPEN_PDF_FORM_MODAL;
  constructor() { }
}

export class ClosePdfFormModal {
  static readonly type = SixCpaActionTypes.CLOSE_PDF_FORM_MODAL;
  constructor() { }
}

export class ToggleSixCpaAction {
  static readonly type = SixCpaActionTypes.TOGGLE_SIXCPA_ACTION;
  constructor(public sixCpaMode: string) { }
}
export class ToggleSixCpaContentAction {
  static readonly type = SixCpaActionTypes.TOGGLE_SIXCPA_CONTENT_ACTION;
  constructor(public sixCpaContentMode: string) { }
}

export class SetProfileForSixCpaAction {
  static readonly type = SixCpaActionTypes.SET_PROFILE_FOR_SIXCPA_ACTION;
  constructor(public profile: ClientViewModel) { }
}
export class QuickCISixCpaAction {
  static readonly type = SixCpaActionTypes.QUICK_CI_SIXCPA_ACTION;
  constructor(public productDispensedId: string) { }
}

export class SetSelectedFormType {
  static readonly type = SixCpaActionTypes.SET_SELECTED_FORM_TYPE;
  constructor(public formType: SixCpaFormTypes) { }
}
export class StartNewSixCpaAction {
  static readonly type = SixCpaActionTypes.START_NEW_SIX_CPA;
  constructor(public onlyClearFormIds: boolean = false) { }
}

export class SwitchProfileSixCpaAction {
  static readonly type = SixCpaActionTypes.SWITCH_PROFILE_NEW_SIX_CPA;
}

export class OpenResetFormModal {
  static readonly type = SixCpaActionTypes.OPEN_RESET_FORM_MODAL;
  constructor() { }
}

export class CloseResetFormModal {
  static readonly type = SixCpaActionTypes.CLOSE_RESET_FORM_MODAL;
  constructor() { }
}

export class SetSixCpaFormDrafts {
  static readonly type = SixCpaActionTypes.SET_SIX_CPA_DRAFTS_TYPE;
  constructor(public sixCpaDrafts: SixCpaDraftModel) { }
}
export class GetSixCpaFormError {
  static readonly type = SixCpaActionTypes.GET_SIX_CPA_FORM_ERROR;
  constructor(public error: any) { }
}

export class SetSixCpaFormSummary {
  static readonly type = SixCpaActionTypes.SET_SIX_CPA_FORM_SUMMARY;
  constructor(public selectedSixCpaResponse?: SixCpaResponse) { }
}

export class ClearSixCpaFormService {
  static readonly type = SixCpaActionTypes.CLEAR_SIX_CPA_FORM_SERVICE;
}