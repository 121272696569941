import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DownloadPdfComponent } from './download-pdf.component';

@NgModule({
  declarations: [DownloadPdfComponent],
  imports: [
    CommonModule
  ],
  exports: [DownloadPdfComponent]
})
export class DownloadPdfModule { }
