import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { tap, catchError, finalize } from 'rxjs/operators';
import { ImmunisationHistoryStatementType } from '../../models/individual-history-statement.model';
import { AIRStatementService } from '../services/air-statement.service';
import {
  ClearAIRHistoryStatement,
  DownloadAIRHistoryStatement,
  GoBackAirLookupFormConfirm,
  SendAIRHistoryStatement,
  SendAIRHistoryStatementError,
  SendAIRHistoryStatementSms,
  SendAIRHistoryStatementSuccess,
  SetAIRHistoryStatement,
  ToggleAirLookupForm,
} from './air-statement.actions';
import { AIRStatementModel } from './air-statement.model';
import { saveAs } from 'file-saver';
import { AlertService } from '../../../../../../modules/core/alert/alert.service';
import { ToggleSixCpaContentAction } from '../../../state/six-cpa.actions';
import { ProfileState } from '../../../../../../modules/core/profile/state/profile.state';
import * as moment from 'moment';

@State<AIRStatementModel>({
  name: 'statementHistory',
  defaults: {
    loading: false,
    error: null,
    name: null,
    showFormSuccess: false,
    airHistoryStatement: null,
    documentId: null,
    slideMode: 'out',
  },
})
@Injectable()
export class AIRStatementState {
  @Selector()
  static loading(state: AIRStatementModel): boolean {
    return state.loading;
  }

  @Selector()
  static slideMode(state: AIRStatementModel): string {
    return state.slideMode;
  }

  @Selector()
  static showAirStatementSuccess(state: AIRStatementModel): boolean {
    return state.showFormSuccess;
  }

  @Selector()
  static documentId(state: AIRStatementModel): number {
    return state.documentId;
  }

  @Selector()
  static statement(state: AIRStatementModel): ImmunisationHistoryStatementType {
    return state.airHistoryStatement;
  }

  constructor(
    private conformationService: AIRStatementService,
    private alertService: AlertService,
    private store: Store
  ) {}

  @Action(SendAIRHistoryStatement)
  sendAIRHistoryStatement(
    ctx: StateContext<AIRStatementModel>,
    { request }: SendAIRHistoryStatement
  ) {
    ctx.patchState({ loading: true });
    return this.conformationService.sendStatement(request).pipe(
      tap(() => ctx.dispatch(new SendAIRHistoryStatementSuccess())),
      catchError((err) => ctx.dispatch(new SendAIRHistoryStatementError(err))),
      finalize(() => ctx.patchState({ loading: false }))
    );
  }

  @Action(SendAIRHistoryStatementSms)
  sendAIRHistoryStatementSms(
    ctx: StateContext<AIRStatementModel>,
    { request }: SendAIRHistoryStatementSms
  ) {
    ctx.patchState({ loading: true });
    const clientId = this.store.selectSnapshot(ProfileState.clientId);
    return this.conformationService.sendStatementSms(clientId, request).pipe(
      tap(() => ctx.dispatch(new SendAIRHistoryStatementSuccess())),
      catchError((err) => ctx.dispatch(new SendAIRHistoryStatementError(err))),
      finalize(() => ctx.patchState({ loading: false }))
    );
  }

  @Action(SendAIRHistoryStatementSuccess)
  sendAIRHistoryStatementSuccess(ctx: StateContext<AIRStatementModel>) {
    this.alertService.success('Statement successfully sent!');
  }

  @Action(SetAIRHistoryStatement)
  SetAIRHistoryStatement(
    ctx: StateContext<AIRStatementModel>,
    { statement, documentId }: SetAIRHistoryStatement
  ) {
    ctx.patchState({ airHistoryStatement: statement, documentId: documentId });
    ctx.dispatch([
      new ToggleSixCpaContentAction('out'),
      new ToggleAirLookupForm('in'),
    ]);
  }

  @Action(SendAIRHistoryStatementError)
  sendAIRHistoryStatementError(
    ctx: StateContext<AIRStatementModel>,
    { error }: SendAIRHistoryStatementError
  ) {
    ctx.patchState({ error: error });
  }

  @Action(ClearAIRHistoryStatement)
  clearAIRHistoryStatement(ctx: StateContext<AIRStatementModel>) {
    ctx.patchState({
      loading: false,
      error: null,
      showFormSuccess: false,
      airHistoryStatement: null,
    });
  }

  @Action(DownloadAIRHistoryStatement)
  downloadAIRHistoryStatement(
    ctx: StateContext<AIRStatementModel>,
    {}: DownloadAIRHistoryStatement
  ) {
    const client = this.store.selectSnapshot(ProfileState.client);
    ctx.patchState({ loading: true });
    const statement = ctx.getState()
      .airHistoryStatement as ImmunisationHistoryStatementType;
    saveAs(
      this.dataURItoBlob(
        `data:application/pdf;base64,${statement.fileContent}`
      ),
      `AIR_History_Statement_${client.patient.firstname}_${
        client.patient.surname
      }_${moment(new Date()).format('DD-MM-YYYY')}`
    );
    ctx.patchState({ loading: false });
  }

  @Action(ToggleAirLookupForm)
  toggleAirLookupForm(
    ctx: StateContext<AIRStatementModel>,
    { slideMode }: ToggleAirLookupForm
  ) {
    ctx.patchState({
      slideMode: slideMode,
    });
  }

  dataURItoBlob(dataURI: string) {
    const byteString = atob(dataURI.split(',')[1]);
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([ab], { type: mimeString });
    return blob;
  }

  @Action(GoBackAirLookupFormConfirm)
  goBack(
    ctx: StateContext<AIRStatementModel>,
    action: GoBackAirLookupFormConfirm
  ) {
    ctx.dispatch([
      new ToggleSixCpaContentAction('in'),
      new ToggleAirLookupForm('out'),
    ]);
  }
}
