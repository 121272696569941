import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { FormsModule } from '@angular/forms';
import { TenantDatePipe } from '~/modules/core/timezone/pipes/tenant-date.pipe';
import { TimezoneModule } from '~/modules/core/timezone/timezone.module';
import { ReplyModal } from './reply-modal.component';
import { LoaderModule } from '~/modules/core/loader/loader.module';
import { RouterModule } from '@angular/router';
import { PipesModule } from '~/shared/pipes/pipes.module';
import { ReplyCompositionFooterComponentModule } from './reply-composition-footer/reply-composition-footer.module';
import { ChatStateModule } from './state/chat-state.module';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import { HumanizePipe } from '~/shared/pipes/humanize.pipe';
import { MessageActionsModule } from '../message-actions/message-actions.module';
import { PhoneNumberPipe } from '~/shared/pipes/phoneNumber.pipe';
import { StripWhiteSpacePipe } from '~/shared/pipes/stripWhiteSpace.pipe';

@NgModule({
  declarations: [
    ReplyModal
  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    NzButtonModule,
    TimezoneModule,
    LoaderModule,
    PipesModule,
    ReplyCompositionFooterComponentModule,
    NgxsFormPluginModule,
    ChatStateModule,
    MessageActionsModule
  ],
  exports: [
    ReplyModal
  ],
  providers: [
    TenantDatePipe,
    HumanizePipe,
    PhoneNumberPipe,
    StripWhiteSpacePipe
  ],
})

export class ReplyModalComponentModule { }
