<app-pharmacy-comms-details></app-pharmacy-comms-details>
<section class="mt-5">
  <tabset class="base-tabs unset-width">
    <tab heading="Scrypt Reminders">
      <app-comms-reminders></app-comms-reminders>
    </tab>
    <tab heading="Custom Messages">
      <app-comm-templates></app-comm-templates>
    </tab>
  </tabset>
  <app-add-edit-comm-template-modal></app-add-edit-comm-template-modal>
</section>
