import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { environment } from '../../../../environments/environment';
import { AlertService } from '~/modules/core/alert/alert.service';
import { BaseApiNoAlerts } from '~/shared/core/base-api/base-api-no-alerts';
import { StorageService } from '~/shared/core/storage/storage.service';
import { DispenseRunRequestModel } from '../models/dispense-run-request.model';
import { ReportViewModel } from '../models/dispense-report.model';
import { ClientViewModel } from '~/modules/core/profile/client.model';

const URL = environment.baseApiUrl;
@Injectable()
export class DispenseRunService extends BaseApiNoAlerts {
  constructor(public http: HttpClient, public router: Router, public storageService: StorageService, public alertService: AlertService) {
    super(http, router, storageService, alertService);
  }

  setUpDispenseRun(dispenseRunRequest: DispenseRunRequestModel): Observable<ClientViewModel[]> {
    return this.http.post<ClientViewModel[]>(`${URL}/api/dispense-v2/init`, dispenseRunRequest);
  }

  evaluate(dispenseRunRequest: DispenseRunRequestModel): Observable<ReportViewModel> {
    return this.http.post<ReportViewModel>(`${URL}/api/dispense-v2/evaluate`, dispenseRunRequest);
  }

  evaluateSingle(dispenseRunRequest: DispenseRunRequestModel): Observable<ReportViewModel> {
    return this.http.post<ReportViewModel>(`${URL}/api/dispense-v2/evaluate-single`, dispenseRunRequest);
  }
}
