import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImageModalComponent } from './image-modal.component';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzButtonModule } from 'ng-zorro-antd/button';
@NgModule({
  declarations: [
    ImageModalComponent
  ],
  imports: [
    CommonModule,
    NzModalModule,
    NzButtonModule
  ],
  exports:[
    ImageModalComponent
  ]
})
export class ImageModalModule { }
