import { State, Selector, Action, StateContext } from '@ngxs/store';
import { SetTotalItemsAndCompletedItems } from './patient-details-form.actions';
import { Injectable } from '@angular/core';

export class PatientDetailsFormStateModel {
  totalItems: number;
  completedItems: number;
}
@Injectable()
@State<PatientDetailsFormStateModel>({
  name: 'patientDetailsForm',
  defaults: {
    completedItems: 0,
    totalItems: 0
  }
})
export class PatientDetailsFormState {

  @Selector()
  static totalItems({ totalItems }: PatientDetailsFormStateModel) { return totalItems; }

  @Selector()
  static completedItems({ completedItems }: PatientDetailsFormStateModel) { return completedItems; }

  @Action(SetTotalItemsAndCompletedItems)
  setProgress({ patchState }: StateContext<PatientDetailsFormStateModel>, { totalItems, completedItems }: SetTotalItemsAndCompletedItems) {
    patchState({ totalItems, completedItems });
  }
}
