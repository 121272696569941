<form [formGroup]="patientDetailsForm" ngxsForm="ssPatientDetailsFormBasic.patientDetailsForm" class="p-4">
  <div class="form-group pb-1">
    <label for="firstName" class="input-label">Patients First Name</label>
    <input type="text" [class.has-error]="formControls.firstName.invalid && (showInvalid$ | async)"
      formControlName="firstName" class="form-control form-control-lg w-50" id="firstName"
      placeholder="Patients First Name" required>
  </div>
  <div class="form-group pb-1">
    <label for="surname" class="input-label">Patients Surname</label>
    <input type="text" [class.has-error]="formControls.surname.invalid && (showInvalid$ | async)"
      formControlName="surname" class="form-control form-control-lg w-50" id="surname" placeholder="Patients Surname"
      required>
  </div>
  <div class="form-group pb-1">
    <label for="dob" class="input-label">Patients Date of Birth</label>
    <input type="text" [class.has-error]="formControls.dob.invalid && (showInvalid$ | async)"
      class="form-control form-control-lg w-25" value="patientDetailsForm.value.dob | date: 'DD-MM-YYYY'"
      formControlName="dob" id="dob" [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY' }" placeholder="dd-mm-yyyy"
      bsDatepicker>
  </div>
  <div class="form-group pb-1">
    <label for="medicareNumber" class="input-label">Medicare/DVA Number</label>
    <input type="text" [class.has-error]="formControls.medicareNumber.invalid && (showInvalid$ | async)"
      class="form-control form-control-lg w-25" name="medicareNumber" formControlName="medicareNumber"
      id="medicareNumber" placeholder="123456789" required>
  </div>
  <div class="form-group pb-1">
    <div>
      <label class="input-label">Reviewed By Pharmacist?</label>
    </div>
    <app-radio-highlight controlName="reviewedTrue" [hasError]="formControls.reviewed.invalid && (showInvalid$ | async)"
      [isHighlighted]="!!patientDetailsForm.value.reviewed">
      <input class="form-check-input " type="radio" name="reviewed" formControlName="reviewed" id="reviewedTrue"
        [value]="true">
      <label class="form-check-label" for="reviewedTrue">Yes</label>
    </app-radio-highlight>
    <app-radio-highlight controlName="reviewedFalse"
      [hasError]="formControls.reviewed.invalid && (showInvalid$ | async)"
      [isHighlighted]="!patientDetailsForm.value.reviewed">
      <input class="form-check-input " type="radio" name="reviewed" formControlName="reviewed" id="reviewedFalse"
        [value]="false">
      <label class="form-check-label" for="reviewedFalse">No</label>
    </app-radio-highlight>
  </div>
</form>