import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseApiNoAlerts } from '../core/base-api/base-api-no-alerts';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { StorageService } from '../core/storage/storage.service';
import { AlertService } from '~/modules/core/alert/alert.service';
import { environment } from '../../../environments/environment';

@Injectable()
export class NursingHomeService extends BaseApiNoAlerts {
  private API = environment.baseApiUrl;

  constructor(public http: HttpClient, public router: Router, public storageService: StorageService, public alertService: AlertService) {
    super(http, router, storageService, alertService);
  }

  getNursingHomes(): Observable<any> {
    return this.get(`${this.API}/api/NursingHome`);
  }
}
