import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { StorageService } from '../../../../../shared/core/storage/storage.service';
import { AlertService } from '../../../../core/alert/alert.service';
import { HttpClient, HttpEvent } from '@angular/common/http';
//import { environment } from '../../../../../../environments/environment';
import { BaseApi } from '../../../../../shared/core/base-api/base-api';

export class CustomerDocument {
  id: number;
  tenantCustomerId: number;
  scryptDocumentName: string
  azureDocumentName: string
  created: Date;
  blobUri: string;
  fileType: string;
  notes: string;
  documentOrigin: DocumentOrigin;
  tag: string;
}

export class PatientDocumentViewModel
{
  id: number;
  documentName: string;
  fileType: string;
  documentOrigin: DocumentOrigin;
  notes: string;
  tag: string;
  created: Date;
}

export enum DocumentOrigin
{
    General,
    Vaccination,
    SevenCPA,
    Consent,
}

export interface PatientUploadDocument {
  customerId?: string;
  documentOrigin?: DocumentOrigin;
  notes?: string;
  tag?: string;
  document?: Blob;
  fileName?: string;
  fileType?: string;
  documentAsBase64?: string;
}

@Injectable()
export class DocumentStorageService extends BaseApi {
  API = '';

  constructor(
    @Inject('environment') env: any,
    public http: HttpClient,
    public router: Router,
    public storageService: StorageService,
    public alertService: AlertService
  ) {
    super(http, router, storageService, alertService);
    this.API = env.baseApiUrl;
  }

  getDocument(id: number) {
    return this.get<HttpEvent<Blob>>(`${this.API}/api/document/GetPatientDocument/${id}`, 
    {reportProgress: true, responseType: 'blob' as 'json'});
  }

  deleteDocument(id: number) {
    return this.delete(`${this.API}/api/document/DeletePatientDocument/${id}`);
  }

  uploadDocument(document: PatientUploadDocument) {
    const formData = new FormData();
    formData.append('document', document.document);
    formData.append('customerId', document.customerId);
    formData.append('documentAsBase64', document.documentAsBase64);
    formData.append('documentOrigin', document.documentOrigin.toString());
    formData.append('fileName', document.fileName);
    formData.append('fileType', document.fileType);
    if (document.notes) {
      formData.append('notes', document.notes);
    }
    if (document.tag) {
      formData.append('tag', document.tag);
    }
    return this.post<HttpEvent<PatientDocumentViewModel>>(`${this.API}/api/document/UploadPatientDocument`, formData, {
      reportProgress: true,
      observe: "events"
    });
  }

}
