<nz-modal
    [(nzVisible)]="isVisible"
    [nzTitle]="modalHeader"
    [nzFooter]="modalFooter"
    [nzWidth]="'700px'"
    [nzCloseIcon]="modalCloseIcon"
    (nzOnCancel)="handleCancel()"
>

    <ng-template #modalHeader>
        {{item.name}}
        <div class="small-text">{{updatedText}}</div>
    </ng-template>
    <ng-template #modalCloseIcon>
        <i class="fas fa-times"></i>
    </ng-template>
    <div *nzModalContent class="d-flex justify-content-center">
        <img class="image" [src]="item.attachmentUrl" alt="prescription image">
    </div>
    <ng-template #modalFooter>
        <a [href]="item.attachmentUrl" target="_blank" download><i class="fas fa-download"></i> &nbsp;Download</a>
        <button nz-button nzType="link" (click)="handlePrint()" ><i class="fas fa-print"></i> &nbsp;Print</button>
        <button nz-button nzType="link" (click)="handleCancel()" >Cancel</button>
    </ng-template>
</nz-modal>
