import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SixcpaCsvExportComponent } from './sixcpa-csv-export.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { CsvExportService } from '../services/csv-export.service';
import { LoaderModule } from '~/modules/core/loader/loader.module';

@NgModule({
  declarations: [
    SixcpaCsvExportComponent
  ],
  imports: [
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
    BsDatepickerModule.forRoot(),
    LoaderModule
  ],
  exports: [
    SixcpaCsvExportComponent
  ],
  providers: [
    CsvExportService
  ]
})
export class SixcpaCsvExportModule { }
