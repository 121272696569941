import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { ColumnDef, ColumnTypes } from "../order-request-table/column-definitions";

@Component({
    selector: 'app-order-mgmt-table-headings',
    templateUrl: './table-headings.component.html',
    styleUrls: ['./table-headings.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrderManagementTableHeadingsComponent<T> { 
    @Input() columns: ColumnDef<T>[];
    columnTypes = ColumnTypes;

    trackByColumnId(columnDef: ColumnDef<T>) {
        return columnDef.id;
    }
}