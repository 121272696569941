export enum BrandPreference {
    NoPreference,
    AllowSubstitutes,
    DontAllowSubstitutes
}

export enum OrderRequestStatus {
    Pending,
    Ready,
    PartiallyReady,
    UnableToFill,
    Cancelled
}

export enum OrderMethod {
    Undefined = 0,
    Delivery = 1,
    Pickup = 2
}

export enum OrderRequestItemStatus {
    Pending,
    Ready,
    Dispensed,
    OnOrder,
    UnableToFill,
    TooEarly
}

export enum OrderRequestItemType {
    OnFileScript,
    EScript,
    Image,
    OTCItem
}

export enum OrderStatusFilter {
  Current = 'Current',
  History = 'History',
  All = 'All'
}

export enum OrderOrigin {
    Unknown,
    MobileApp,
    OrderLink,
    PredictedOrder
}
