import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { environment } from '../../../../environments/environment';
import { BaseApi } from '~/shared/core/base-api/base-api';
import { Router } from '@angular/router';
import { StorageService } from '~/shared/core/storage/storage.service';
import { AlertService } from '~/modules/core/alert/alert.service';
import { SimpleSignUp } from '~/shared/models/simple-sign-up/simple-sign-up.model';

const API = environment.baseApiUrl;

@Injectable()
export class SignUpService extends BaseApi {
  constructor(public http: HttpClient, public router: Router, public storageService: StorageService, public alertService: AlertService) {
    super(http, router, storageService, alertService);
  }

  getCustomerByLink(encryptedLinkId: string): Observable<any> {
    return this.post<any>(`${API}/api/sign-up`, {encryptedLinkId});
  }

  createAccount(request: SimpleSignUp): Observable<any> {
    return this.post<any>(`${API}/api/sign-up/create`, request);
  }
}
