<form [formGroup]="additionalDetailsForm" ngxsForm="ssAdditionalDetailsForm.additionalDetailsForm" class="p-4">
  <div class="row">
    <div class="form-group pb-2 col-md-5">
      <label for="claimedCalendarMonth" class="input-label">Claimed Calendar Month</label>
      <select class="form-control-lg"
        [class.has-error]="formControls.claimedCalendarMonth.invalid && (showInvalid$ | async)"
        formControlName="claimedCalendarMonth">
        <option [value]="calendarMonths.January">January</option>
        <option [value]="calendarMonths.February">February</option>
        <option [value]="calendarMonths.March">March</option>
        <option [value]="calendarMonths.April">April</option>
        <option [value]="calendarMonths.May">May</option>
        <option [value]="calendarMonths.June">June</option>
        <option [value]="calendarMonths.July">July</option>
        <option [value]="calendarMonths.August">August</option>
        <option [value]="calendarMonths.September">September</option>
        <option [value]="calendarMonths.October">October</option>
        <option [value]="calendarMonths.November">November</option>
        <option [value]="calendarMonths.December">December</option>
      </select>
    </div>
  </div>
  <div class="row">
    <div class="form-group pb-2 col-md-5">
      <label for="claimedCalendarYear" class="input-label">Claimed Calendar Year</label>
      <select class="form-control-lg"
        [class.has-error]="formControls.claimedCalendarYear.invalid && (showInvalid$ | async)"
        formControlName="claimedCalendarYear">
        <option [value]="2018">2018</option>
        <option [value]="2019">2019</option>
        <option [value]="2020">2020</option>
        <option [value]="2021">2021</option>
        <option [value]="2022">2022</option>
      </select>
    </div>
  </div>
  <div class="form-group pb-1">
    <label for="amountOfDaysInMonthPatientVisitedForStagedSupplyMedication" class="input-label">How many days during the
      month did the patient visit the pharmacy and pick up a
      Staged Supply medication?</label>
    <input type="text"
      [class.has-error]="formControls.amountOfDaysInMonthPatientVisitedForStagedSupplyMedication.invalid && (showInvalid$ | async)"
      class="form-control form-control-lg w-25" name="amountOfDaysInMonthPatientVisitedForStagedSupplyMedication"
      formControlName="amountOfDaysInMonthPatientVisitedForStagedSupplyMedication"
      id="amountOfDaysInMonthPatientVisitedForStagedSupplyMedication" placeholder="5" required>
  </div>
  <div class="form-group pb-1">
    <label for="dateOfPatientsFinalDosingBatch" class="not-required input-label">Date of patients final dosing batch (Optional - only
      complete if patient has left the Staged Supply program)</label>
    <input type="text" [class.has-error]="formControls.dateOfPatientsFinalDosingBatch.invalid && (showInvalid$ | async)"
      class="form-control form-control-lg w-25" value="additionalDetailsForm.value.dateOfPatientsFinalDosingBatch"
      formControlName="dateOfPatientsFinalDosingBatch" id="dateOfPatientsFinalDosingBatch"
      [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY' }" placeholder="dd-mm-yyyy" bsDatepicker>
  </div>
</form>