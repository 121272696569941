import { IStock } from './stock.model';
import { State, Selector, Action, StateContext } from '@ngxs/store';
import { SearchStockAction, AddStockAction } from './stock.actions';
import { StockRepository } from './stock.repository';
import { tap, catchError } from 'rxjs/operators';
import { merge } from '../../core/arrays/arrays.helper';
import { orderBySearchTerm } from '../../core/search';
import { distinctByStockId } from './stock.logic';
import { of } from 'rxjs';
import { Injectable } from '@angular/core';

export class StockStateModel {
  public stocks: IStock[];
  public fetching: boolean;
}
@Injectable()
@State<StockStateModel>({
  name: 'stock',
  defaults: {
    stocks: [],
    fetching: false
  }
})
export class StockState {
  constructor(private readonly stockRepository: StockRepository) {}

  @Selector()
  static stocks(state: StockStateModel) {
    return state.stocks;
  }

  @Selector()
  static stocksOrderByClosestNameMatch(state: StockStateModel) {
    return (query: string) => {
      if (!query || query === '') {
        return [];
      }

      return orderBySearchTerm(stock => stock.name)(query)(state.stocks);
    };
  }

  @Selector()
  static isFetching(state: StockStateModel) {
    return state.fetching;
  }

  @Action(SearchStockAction)
  searchStock(ctx: StateContext<StockStateModel>, action: SearchStockAction) {
    const state = ctx.getState();

    if (state.fetching) { return; }

    ctx.patchState({
      fetching: true
    });

    const resetFetching = () =>
      ctx.patchState({
        fetching: false
      });

    return this.stockRepository.getStock(action.query).pipe(
      tap(stocks => ctx.dispatch(new AddStockAction(stocks))),
      tap(resetFetching),
      catchError(() => of(resetFetching))
    );
  }

  @Action(AddStockAction)
  addStock(ctx: StateContext<StockStateModel>, action: AddStockAction) {
    const state = ctx.getState();

    const currentStocks = state.stocks;
    ctx.patchState({
      stocks: distinctByStockId(merge(currentStocks, action.stocks))
    });
  }
}
