<section>
    <ng-container *ngIf="loading$ | async; else emailContent">
        <div g-xs="p4 text:center" class="flex">
          <app-loader class="loading"></app-loader>
        </div>
    </ng-container>
    <ng-template #emailContent>
      <div g-xs="px3 pt3 pb4">
        <div>
          <app-email-history [emailHistory]="emailHistory$ | async"></app-email-history>
        </div>
        <div>
          <app-add-email [templates]="templates" [client]="client" (emailSentEvent)="emailSent($event)"></app-add-email>
        </div>
      </div>
    </ng-template>
</section>
