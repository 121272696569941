import { Inject, Injectable } from '@angular/core';
import { StagedSupplyFormModel } from '../../../../../../shared/models/six-cpa/staged-supply/staged-supply-form.model';
import { BaseApiNoAlerts } from '../../../../../../shared/core/base-api/base-api-no-alerts';
import { Router } from '@angular/router';
import { StorageService } from '../../../../../../shared/core/storage/storage.service';
import { AlertService } from '../../../../../core/alert/alert.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DatesService } from '../../../../../../shared/core/dates/dates.service';

@Injectable()
export class StagedSupplyService extends BaseApiNoAlerts {
  API = '';

  constructor(
    @Inject('environment') env: any,
    public http: HttpClient,
    public router: Router,
    public storageService: StorageService,
    public alertService: AlertService
  ) {
    super(http, router, storageService, alertService);
    this.API = env.baseApiUrl;
  }

  postSave(clientId: string, formData: StagedSupplyFormModel) {
    formData = this.formatFormDataDates(formData);
    return this.post<StagedSupplyFormModel>(
      `${this.API}/api/staged-supply/${clientId}/save`,
      formData
    );
  }

  postSubmit(clientId: string, formData: StagedSupplyFormModel) {
    formData = this.formatFormDataDates(formData);
    return this.post<StagedSupplyFormModel>(
      `${this.API}/api/staged-supply/${clientId}/submit`,
      formData
    );
  }

  putSave(clientId: string, formData: StagedSupplyFormModel) {
    formData = this.formatFormDataDates(formData);
    return this.put(
      `${this.API}/api/staged-supply/${clientId}/update-save`,
      formData
    );
  }

  putSubmit(clientId: string, formData: StagedSupplyFormModel) {
    formData = this.formatFormDataDates(formData);
    return this.put(
      `${this.API}/api/staged-supply/${clientId}/update-submit`,
      formData
    );
  }

  getStagedSupplyForm(
    clientId: string,
    formId: number
  ): Observable<StagedSupplyFormModel> {
    return this.get<StagedSupplyFormModel>(
      `${this.API}/api/staged-supply/${clientId}/form/${formId}`
    );
  }

  private formatFormDataDates(
    form: StagedSupplyFormModel
  ): StagedSupplyFormModel {
    form.patientDetailsForm.dob = DatesService.ToEndOfLocalDayInUtc(
      form.patientDetailsForm.dob
    );
    form.consentAndEligibilityForm.dateOfPatientRegistration = DatesService.ToEndOfLocalDayInUtc(
      form.consentAndEligibilityForm.dateOfPatientRegistration
    );
    return form;
  }
}
