import { OrderRequest } from '@profile/order-requests/models/order-request.model';
import { OrderRequestStatus } from '@profile/order-requests/models/order.enums';
import { ActionLoadingKey, OrderStatusFilter, TabName } from '../order-mgmt.types';

enum OrderManagementActions {
    GET_ORDER_REQUESTS_FOR_SELECTED_TAB = '[Order Management] Get Tenant Order Requests For Selected Tab',
    GET_ORDER_REQUEST_ITEMS = '[Order Management] Get Order Request Items',
    GET_ORDER_REQUEST_ITEMS_SUCCESS = '[Order Management] Get Order Request Items Success',
    UPDATE_ORDER_REQUEST = '[Order Management] Update Order Request',
    UPDATE_ORDER_REQUEST_SUCCESS = '[Order Management] Update Order Request Success',
    UPDATE_SELECTED_TAB = '[Order Management] Update Selected Tab',
    UPDATE_SELECTED_STATUS_FILTER = '[Order Management] Update Selected Status Filter',
    UPDATE_CURRENT_PAGE = '[Order Management] Update Current Page',
    PATCH_ACTION_LOADING_STATE = '[Order Management] Patch Action Loading State',
    SEND_ORDER_TO_QUEUE = '[Order Management] Send Order To Queue',
    PUSH_ORDER_TO_QUEUE = '[Order Management] Send Order To Queue V2',
    GET_PENDING_ORDERS_COUNT = '[Order Management] Get Pending Orders Count',
    COMPLETE_ORDER = '[Order Management] Complete Order',
    RESET_ORDER_STATUS = '[Order Management] Reset Order Status',
    UPDATE_ORIGINAL_STATUSES = '[Order Management] Update Original Statuses',
}

export class GetOrderRequestsForSelectedTab {
    public static readonly type = OrderManagementActions.GET_ORDER_REQUESTS_FOR_SELECTED_TAB;
    constructor(public paginationPage: number, public statusFilters: OrderRequestStatus[]) {}
}

export class GetOrderRequestItems {
    public static readonly type = OrderManagementActions.GET_ORDER_REQUEST_ITEMS;
    constructor(public orderRequestId: number) {}
}

export class GetOrderRequestItemsSuccess {
    public static readonly type = OrderManagementActions.GET_ORDER_REQUEST_ITEMS_SUCCESS;
    constructor(public updatedOrder: OrderRequest) {}
}

export class UpdateOrderRequest {
    public static readonly type = OrderManagementActions.UPDATE_ORDER_REQUEST
    constructor(public order: OrderRequest, public notifyCustomer: boolean) {}
}

export class UpdateOrderRequestSuccess {
    public static readonly type = OrderManagementActions.UPDATE_ORDER_REQUEST_SUCCESS;
    constructor(public updatedOrder: OrderRequest) {}
}

export class UpdateSelectedTab {
    public static readonly type = OrderManagementActions.UPDATE_SELECTED_TAB
    constructor(public tab: TabName) {}
}

export class UpdateSelectedStatusFilter {
    public static readonly type = OrderManagementActions.UPDATE_SELECTED_STATUS_FILTER
    constructor(public statusFilter: OrderStatusFilter) {}
}

export class UpdateCurrentPage {
    public static readonly type = OrderManagementActions.UPDATE_CURRENT_PAGE
    constructor(public page: number) {}
}

export class PatchActionLoadingState {
    public static readonly type = OrderManagementActions.PATCH_ACTION_LOADING_STATE;
    constructor(public orderId: number, public key: ActionLoadingKey, public isActionLoading: boolean) {}
}

export class SendOrderToQueue {
    public static readonly type = OrderManagementActions.SEND_ORDER_TO_QUEUE
    constructor(public order: OrderRequest) {}
}

export class PushOrderToQueue {
    public static readonly type = OrderManagementActions.PUSH_ORDER_TO_QUEUE
    constructor(public order: OrderRequest) {}
}

export class GetPendingOrdersCount {
    public static readonly type = OrderManagementActions.GET_PENDING_ORDERS_COUNT;
    constructor() {}
}

export class CompleteOrderRequest {
    public static readonly type = OrderManagementActions.COMPLETE_ORDER;
    constructor(public order: OrderRequest, public notifyCustomer: boolean) {}
}

export class ResetOrderStatus {
  public static readonly type = OrderManagementActions.RESET_ORDER_STATUS;
  constructor(public orderId: number, public originalStatus: OrderRequestStatus) {}
}

export class UpdateOriginalStatuses {
  public static readonly type = OrderManagementActions.UPDATE_ORIGINAL_STATUSES;
  constructor() {}
}
