<ng-container *ngIf="enabled">
  <ng-container *ngIf="documents$ | async as documents">
    <section>
      <div class="crm-block">
        <div g-xs="mt3">
          <div class="table-responsive border__grey">
            <table class="table">
              <tbody>
                <tr class="crm-block__header">
                  <th>Date Created</th>
                  <th>Document Name</th>
                  <th>Document Type</th>
                  <th>Document Origin</th>
                  <th>Notes</th>
                  <th></th>
                </tr>
                <tr class="body-row" *ngFor="let doc of documents">
                  <td>
                    {{ doc.created | tenantDate: "DD/MM/YYYY hh:mm A" }}
                  </td>
                  <td>{{ doc.documentName }}</td>
                  <td>{{ doc.fileType }}</td>
                  <td>
                    {{
                      DocumentOrigin[doc.documentOrigin].replace(
                        "SevenCPA",
                        "Service"
                      )
                    }}
                  </td>
                  <td>{{ doc.notes }}</td>
                  <td>
                    <i
                      class="far fa-file-download"
                      (click)="downloadDocument(doc)"
                    ></i>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>
  </ng-container>  
</ng-container>