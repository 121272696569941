import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Feature } from './features.state';

const API = environment.baseApiUrl;

@Injectable({
  providedIn: 'root',
})
export class FeaturesService {
  constructor(private http: HttpClient) {}

  getLatest() {
    return this.http.get<Feature>(`${API}/api/features`, { observe: 'response' });
  }
}
