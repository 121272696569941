import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { slideRight } from '../../../../shared/core/animations';
// import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-confirm-form',
  templateUrl: './confirm-form.component.html',
  styleUrls: ['./confirm-form.component.scss'],
  animations: [
    slideRight
  ]
})
export class ConfirmFormComponent implements OnInit {

  constructor() { }
  @Input() showForm: string;
  @Input() goBackTitle: string;
  @Output() goBackEvent = new EventEmitter<any>();
  @Output() startNewEvent = new EventEmitter<any>();
  // CPA_Version = environment.CPAVersion;
  CPA_Version = '';

  ngOnInit() {
  }
  goBack() {
    this.goBackEvent.emit();
  }
  startNew() {
    this.startNewEvent.emit();
  }
}
