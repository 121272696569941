import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { StorageService } from '../core/storage/storage.service';
import { AlertService } from '../../modules/core/alert/alert.service';
import { BaseApi } from '../core/base-api/base-api';
import { InviteModel } from '../models/six-cpa/invite.model';
import { Observable } from 'rxjs';
import { InviteCredentialsAvailableResponse } from '../models/profile/invite-credentials-available-response..model';

@Injectable()
export class InviteService extends BaseApi {
  API = '';

  constructor(
    @Inject('environment') env: any,
    public http: HttpClient,
    public router: Router,
    public storageService: StorageService,
    public alertService: AlertService
  ) {
    super(http, router, storageService, alertService);
    this.API = env.baseApiUrl;
  }

  invite(customerProfileId: number, model: InviteModel): Observable<any> {
    return this.post<any>(
      `${this.API}/api/invite/customer/${customerProfileId}`,
      model
    );
  }

  inviteByDeepLink(customerProfileId: number, model: InviteModel): Observable<any> {
    return this.post<any>(
      `${this.API}/api/v2/invite/customer/${customerProfileId}`,
      model
    );
  }

  getCredentialsAvailability(mobilenumber: string, emailAddress: string): Observable<InviteCredentialsAvailableResponse> {
    return this.get(`${this.API}/api/invite/available?mobileNumber=${mobilenumber}&email=${emailAddress}`);
  }

  getExisitingInvite(customerProfileId: number): Observable<any> {
    return this.get(`${this.API}/api/invite/${customerProfileId}`);
  }
}
