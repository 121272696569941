import { Pipe, PipeTransform } from '@angular/core';
import { Trigger } from '../../../../shared/models/pharmacy/trigger.model';
import { Sort } from '../../../../shared/models/sort.enum';
@Pipe({
  name: 'sortTriggersPipe'
})
export class SortTriggersPipe implements PipeTransform {

  transform(triggers: Trigger[], sortBy: Sort): Trigger[] {
    let res = triggers;

    switch (sortBy) {
      case Sort.Newest:
        res = res.sort((a, b) => this.sortFunc(a, b, 'createdDate'));
        break;
      case Sort.Oldest:
        res = res.sort((a, b) => this.sortFunc(a, b, 'createdDate')).reverse();
        break;
      case Sort.Alphabetical:
        res = res.sort((a, b) => this.sortFunc(a, b, 'name')).reverse();
        break;
      case Sort.AlphabeticalReverse:
        res = res.sort((a, b) => this.sortFunc(a, b, 'name'));
        break;
    }
    return res;
  }
  
  private sortFunc(a: Trigger, b: Trigger, prop: string) {
    if (a[prop] > b[prop]) { return -1; }
    if (a[prop] < b[prop]) { return 1; }
    return 0;
  }
}
 