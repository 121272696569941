import { State, Action, StateContext, Selector } from '@ngxs/store';
import { Timezone } from '../timezone.model';
import { FetchAllTimezones, SetAllTimezones, FetchTimezone, SetSelectedTimezone } from './timezone.actions';
import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { TimezoneRepository } from '../repositories/timezone.repository';
import { Injectable } from '@angular/core';

export const timezoneStateName = 'coreTimezones'; // TODO rename to remove core once scrypt-hq updated to use scrypt-core timezone module only

export interface ITimezoneState {
  timezones: Timezone[];
  selected: string;
  isDebugging: boolean;
}
@Injectable()
@State<ITimezoneState>({
  name: timezoneStateName,
  defaults: {
    timezones: [],
    selected: null,
    isDebugging: false
  }
})
export class TimezoneState {

  @Selector()
  static selectedTimezone(state: ITimezoneState) { return state.selected; }

  @Selector()
  static allTimezones(state: ITimezoneState) { return state.timezones; }

  @Selector()
  static isDebugging(state: ITimezoneState) { return state.isDebugging; }

  constructor(private timezoneRepository: TimezoneRepository) { }

  @Action(FetchAllTimezones)
  fetchAllTimeZones(context: StateContext<ITimezoneState>, action: FetchAllTimezones): Observable<Timezone[]> {
    return this.timezoneRepository.getTimezones()
      .pipe(
        tap((timezones) => context.dispatch(new SetAllTimezones(timezones)))
      );
  }

  @Action(FetchTimezone)
  fetchTimezone(context: StateContext<ITimezoneState>, action: FetchTimezone): Observable<string> {
    return this.timezoneRepository.getTimezoneId()
      .pipe(tap((timezoneId) => context.dispatch(new SetSelectedTimezone(timezoneId))));
  }


  @Action(SetAllTimezones)
  setAllTimeZones(context: StateContext<ITimezoneState>, action: SetAllTimezones) {
    return context.patchState({
      timezones: action.timezones
    });
  }

  @Action(SetSelectedTimezone)
  setSelectedTimezone(context: StateContext<ITimezoneState>, action: SetSelectedTimezone) {
    return context.patchState({
      selected: action.timezoneId
    });
  }

}
