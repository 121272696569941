import { InterventionCategory } from "~/shared/models/six-cpa/classification-and-recommendations-form.model";

export const HealthConditionsForForm = [
  { key: 'cVD', value: 'CVD (including anticoagulants)' },
  { key: 'diabetes', value: 'Diabetes' },
  { key: 'osteoporosis', value: 'Osteoporosis' },
  { key: 'arthritis', value: 'Arthritis' },
  { key: 'pain', value: 'Pain' },
  { key: 'mentalHealthIssue', value: 'Mental Health issue' },
  { key: 'epilepsy', value: 'Epilepsy' },
  { key: 'parkinsonsDisease', value: 'Parkinson\'s Disease' },
  { key: 'dementia', value: 'Dementia' },
  { key: 'respiratoryDisorders', value: 'Respiratory disorders' },
  { key: 'alimentaryTract', value: 'Alimentary tract' },
  { key: 'other', value: 'Other' }
];

export const ForConsiderationByForm = [
  { key: 'patient', value: 'Patient' },
  { key: 'gp', value: 'GP' },
  { key: 'specialist', value: 'Specialist' },
  { key: 'diabetesEducator', value: 'Diabetes Educator' },
  { key: 'otherPharmacyService', value: 'Other Pharmacy Service' },
  { key: 'other', value: 'Other' }
];

export const MedsCheckReasonsForForm = {
  recentSignificantMedicalEvent: 'Recent significant medical event',
  identifyPatientProblems: 'To identify problems the patient may be experiencing',
  helpPatientLearnAboutMedicines: 'To help the patient learn more about their medicines',
  improveMedicationEffectiveness: 'To improve the effective use of medicines by patient',
  educatePatientOnUseAndStorageOfMedicines: 'To educate the patient about how to best use and store their medicines',
  medicationsWithHighAdverseEventRisk: 'Patient is taking medications with a high risk of adverse event'
};

export enum InterventionClassification {
  Duplication,
  DrugInteraction,
  WrongDrug,
  IncorrectStrength,
  InappropriateDosageForm,
  ContraindicationsApparent,
  NoIndicationApparent,
  OtherDrugSelectionProblem,
  PrescribedDoseTooHigh,
  PrescribedDoseTooLow,
  IncompleteOrUnclearDosingInstructions,
  OtherDoseProblem,
  UnderUseByConsumer,
  OverUseByConsumer,
  ErraticUseOfMedication,
  IntentionalDrugMisuseIncludingOTCs,
  DifficultyUsingDosageForm,
  OtherComplianceProblem,
  ConditionUndertreated,
  ConditionUntreated,
  PreventativeTherapyRequired,
  OtherUndertreatedIndicationProblem,
  LabratoryMonitoring,
  NonLabratoryMonitoring,
  OtherMonitoringProblem,
  PatientRequestsDrugInformation,
  PatientRequestsDiseaseManagementAdvice,
  OtherEducationOrInformationProblem,
  CannotBeClassifiedUnderAnotherCategory,
  ToxicityAllergicReactionOrAdverseEffect
}

export const DrugSelectionClassifications = [
  { key: InterventionClassification.Duplication, value: 'D1 Duplication' },
  { key: InterventionClassification.DrugInteraction, value: 'D2 Drug interaction' },
  { key: InterventionClassification.WrongDrug, value: 'D3 Wrong drug' },
  { key: InterventionClassification.IncorrectStrength, value: 'D4 Incorrect strength' },
  { key: InterventionClassification.InappropriateDosageForm, value: 'D5 Inappropriate dosage form' },
  { key: InterventionClassification.ContraindicationsApparent, value: 'D6 Contraindications apparent' },
  { key: InterventionClassification.NoIndicationApparent, value: 'D7 No indication apparent' },
  { key: InterventionClassification.OtherDrugSelectionProblem, value: 'D0 Other drug selection problem' }
];

export const DosingClassifications = [
  { key: InterventionClassification.PrescribedDoseTooHigh, value: 'O1 Prescribed dose too high' },
  { key: InterventionClassification.PrescribedDoseTooLow, value: 'O2 Prescribed dose too low' },
  { key: InterventionClassification.IncompleteOrUnclearDosingInstructions, value: 'O3 Incorrect or unclear dosing instructions' },
  { key: InterventionClassification.OtherDoseProblem, value: 'O0 Other dose problem' }
];

export const ComplianceClassifications = [
  { key: InterventionClassification.UnderUseByConsumer, value: 'C1 Under-use by consumer' },
  { key: InterventionClassification.OverUseByConsumer, value: 'C2 Over-use by consumer' },
  { key: InterventionClassification.ErraticUseOfMedication, value: 'C3 Erratic use of medication' },
  { key: InterventionClassification.IntentionalDrugMisuseIncludingOTCs, value: 'C4 Intentional drug misuse (incl. OTCs)' },
  { key: InterventionClassification.DifficultyUsingDosageForm, value: 'C5 Difficulty using dosage form' },
  { key: InterventionClassification.OtherComplianceProblem, value: 'C0 Other compliance problem' }
];

export const UndertreatedClassifications = [
  { key: InterventionClassification.ConditionUndertreated, value: 'U1 Condition undertreated' },
  { key: InterventionClassification.ConditionUntreated, value: 'U2 Condition untreated' },
  { key: InterventionClassification.PreventativeTherapyRequired, value: 'U3 Preventative therapy required' },
  { key: InterventionClassification.OtherUndertreatedIndicationProblem, value: 'U0 Other undertreated indication problem' }
];

export const MonitoringClassifications = [
  { key: InterventionClassification.LabratoryMonitoring, value: 'M1 Laboratory monitoring' },
  { key: InterventionClassification.NonLabratoryMonitoring, value: 'M2 Non-laboratory monitoring' },
  { key: InterventionClassification.OtherMonitoringProblem, value: 'M0 Other monitoring problem' }
];

export const EducationClassifications = [
  { key: InterventionClassification.PatientRequestsDrugInformation, value: 'E1 Patient requests drug information' },
  { key: InterventionClassification.PatientRequestsDiseaseManagementAdvice, value: 'E2 Patient requests disease management advice' },
  { key: InterventionClassification.OtherEducationOrInformationProblem, value: 'E0 Other education or information problem' }
];

export const NonClassificableClassifications = [
  { key: InterventionClassification.CannotBeClassifiedUnderAnotherCategory, value: 'N0 Clinical interventions that cannot be classified under another category' }
];

export const ToxicityReactionsClassifications = [
  { key: InterventionClassification.ToxicityAllergicReactionOrAdverseEffect, value: 'T1 Toxicity, allergic reaction or adverse effect present' }
];

export const CIRecommendationsGrouped = [
  {
    category: 'Change of therapy',
    subcategories: [
      {
        key: 'doseIncrease',
        value: 'R1 Dose increase'
      },
      {
        key: 'doseDecrease',
        value: 'R2 Dose decrease'
      },
      {
        key: 'drugChange',
        value: 'R3 Drug change'
      },
      {
        key: 'drugFormulationChange',
        value: 'R4 Drug formulation change'
      },
      {
        key: 'drugBrandChange',
        value: 'R5 Drug brand change'
      },
      {
        key: 'doseFrequencyScheduleChange',
        value: 'R6 Dose frequency/schedule change'
      },
      {
        key: 'prescriptionNotDispensed',
        value: 'R7 Prescription not dispensed'
      },
      {
        key: 'otherChangesToTherapy',
        value: 'R8 Other changes to therapy'
      }
    ]
  },
  {
    category: 'A referral required',
    subcategories: [
      {
        key: 'referToPrescriber',
        value: 'R9 Refer to prescriber'
      },
      {
        key: 'referToHospital',
        value: 'R10 Refer to hospital'
      },
      {
        key: 'referForMedicationReview',
        value: 'R11 Refer for medication review'
      },
      {
        key: 'otherReferralRequired',
        value: 'R12 Other referral required'
      }
    ]
  },
  {
    category: 'Provision of information',
    subcategories: [
      {
        key: 'educationOrCounsellingSession',
        value: 'R13 Education or counselling session'
      },
      {
        key: 'writtenSummaryOfMedications',
        value: 'R14 Written summary of medications'
      },
      {
        key: 'commenceDoseAdministrationAid',
        value: 'R15 Commence dose administration aid'
      },
      {
        key: 'otherWrittenInformation',
        value: 'R16 Other written information'
      }
    ]
  },
  {
    category: 'Monitoring',
    subcategories: [
      {
        key: 'monitoringLaboratory',
        value: 'R17 Monitoring: Laboratory'
      },
      {
        key: 'monitoringNonLaboratory',
        value: 'R18 Monitoring: Non-Laboratory'
      }
    ]
  },
  {
    category: 'No recommendation necessary',
    subcategories: [
      {
        key: 'noRecommendationNecessary',
        value: 'R19 No recommendation necessary'
      }
    ]
  }
];

export const CIRecommendations = {
  doseIncrease: 'R1 Dose increase',
  doseDecrease: 'R2 Dose decrease',
  drugChange: 'R3 Drug change',
  drugFormulationChange: 'R4 Drug formulation change',
  drugBrandChange: 'R5 Drug brand change',
  doseFrequencyScheduleChange: 'R6 Dose frequency/schedule change',
  prescriptionNotDispensed: 'R7 Prescription not dispensed',
  otherChangesToTherapy: 'R8 Other changes to therapy',
  referToPrescriber: 'R9 Refer to prescriber',
  referToHospital: 'R10 Refer to hospital',
  referForMedicationReview: 'R11 Refer for medication review',
  otherReferralRequired: 'R12 Other referral required',
  educationOrCounsellingSession: 'R13 Education or counselling session',
  writtenSummaryOfMedications: 'R14 Written summary of medications',
  commenceDoseAdministrationAid: 'R15 Commence dose administration aid',
  otherWrittenInformation: 'R16 Other written information',
  monitoringLaboratory: 'R17 Monitoring: Laboratory',
  monitoringNonLaboratory: 'R18 Monitoring: Non-Laboratory',
  noRecommendationNecessary: 'R19 No recommendation necessary'
};

export const InterventionCategories = [
  { key: InterventionCategory.DrugSelection, value: 'Drug selection' },
  { key: InterventionCategory.OverOrUnderDose, value: 'Over or under-dose' },
  { key: InterventionCategory.Compliance, value: 'Compliance' },
  { key: InterventionCategory.UnderTreated, value: 'Under-treated' },
  { key: InterventionCategory.Monitoring, value: 'Monitoring (not claimable)' },
  { key: InterventionCategory.EducationOrInformation, value: 'Education or information (not claimable)' },
  { key: InterventionCategory.NotClassifiable, value: 'Not classifiable (not claimable)' },
  { key: InterventionCategory.ToxicityOrAdverseReaction, value: 'Toxicity or adverse reaction' }
];

export const ClaimedCalendarYears = [
  { key: '2018', value: 2018 },
  { key: '2019', value: 2019 },
  { key: '2020', value: 2020 },
  { key: '2021', value: 2021 },
  { key: '2022', value: 2022 },
];

export enum CalendarMonths {
  January = 1,
  February = 2,
  March = 3,
  April = 4,
  May = 5,
  June = 6,
  July = 7,
  August = 8,
  September = 9,
  October = 10,
  November = 11,
  December = 12
}

export enum CIClaimingPeriodDateRanges {
  January1stToMarch31st,
  April1stToJune30th,
  July1stToSeptember30th,
  October1stToDecember31st,
}


export enum SickLeaveExperiencedSymptomsPeriods {
  Hours = 'Hours',
  Days = 'Days',
  Weeks = 'Weeks',
}

export enum SickLeaveCertificateCoverDays {
  OneDay,
  TwoDays,
}

export const EScriptOptions = [
  { key: 'Generic', value: 'Generic' },
  { key: 'CMI', value: 'CMI' }
];
