<div class="container">
  <div class="row">
    <div class="col-md-9">
      <h3 class="pl-2">Recent Customers</h3>
    </div>
    <div class="col-md-3">
      <div class="form-group customers-select">
        <select class="form-control" [(ngModel)]="customersLoaded" id="customersSelect" (change)="customersTable()">
          <option *ngFor="let v of customerAmounts" [ngValue]="v">{{v}}</option>
        </select>
      </div>
    </div>
  </div>

  <div class="grid__unit" g-xs="cols:12 px1">
    <div class="border rounded column-table">
      <div *ngIf="!isCustomersLoaded" g-xs="p4" class="hcenter">
        <app-loader class="loading"></app-loader>
      </div>
      <div *ngIf="isCustomersLoaded">
        <app-column-table [customerView]="true" filterTitle="Filter Customers" totalText="customers"
          [data]="customerList" (columnClicked)="handleCustomerColumnClick($event)"
          (filter)="handleCustomerFilter($event)" [showIcons]="false">
        </app-column-table>
      </div>
    </div>
  </div>
</div>
