import { Component, OnInit } from '@angular/core';
import { PharmacyService } from '~/shared/services/pharmacy.service';

@Component({
  selector: 'app-opt-out-confirmation',
  templateUrl: './opt-out-confirmation.component.html',
  styleUrls: ['./opt-out-confirmation.component.scss']
})
export class OptOutConfirmationComponent implements OnInit {
  pharmacyName: string;

  constructor(private pharmacyService: PharmacyService) { }

  ngOnInit() {
    return this.pharmacyService.getPharmacyLocation().subscribe((location) => {
      this.pharmacyName = location.name;
    });
  }
}
