import { HMRServiceProviderDetailsFormModel } from '~/shared/models/six-cpa/HMR/hmr-service-provider-details-form.model';

export enum HMRServiceProviderDetailsFormActions {
  UPDATE_PROGRESS = '[HMRServiceProviderDetailsForm] Update Progress',
  UPDATE = '[HMRServiceProviderDetailsForm] Update'
}

export class SetTotalItemsAndCompletedItems {
  static readonly type = HMRServiceProviderDetailsFormActions.UPDATE_PROGRESS;
  constructor(public totalItems: number, public completedItems: number) { }
}

export class UpdateHMRServiceProviderDetailsForm {
  static readonly type = HMRServiceProviderDetailsFormActions.UPDATE;
  constructor(public form: Partial<HMRServiceProviderDetailsFormModel>) { }
}
