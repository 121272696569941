import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'appCategoryGroupBy' })
export class CategoryGroupByPipe implements PipeTransform {
  transform(collection: any, property: any): any {
    // prevents the application from breaking if the array of objects doesn't exist yet
    if (!collection) {
      return null;
    }

    const groupedCollection = collection.reduce((previous, current) => {
      if (!previous[current[property.name]]) {
        previous[current[property.name]] = {
          category: {
            name: current[property.name],
            position: current[property.position]
          },
          items: [current]
        };
      } else {
        previous[current[property.name]].items.push(current);
      }

      return previous;
    }, {});

    // this will return an array of objects, each object containing a group of objects
    const res = Object.keys(groupedCollection).map(key => ({
      category: groupedCollection[key].category,
      value: groupedCollection[key].items }
      ))
      .sort((a, b) => a.category.position - b.category.position);
    return res;
  }
}
