import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MedicationInformationFormListItemComponent } from './medication-information-form-list-item.component';
import { SixCpaFormListItemChecklistModule } from '../six-cpa-form-list-item-checklist/six-cpa-form-list-item-checklist.module';
import { MedicationInformationFormModule } from '../medication-information-form/medication-information-form/medication-information-form.module';

@NgModule({
    declarations: [
        MedicationInformationFormListItemComponent
    ],
    imports: [
        CommonModule,
        SixCpaFormListItemChecklistModule,
        MedicationInformationFormModule
    ],
    exports: [
        MedicationInformationFormListItemComponent
    ]
})
export class MedicationInformationFormListItemModule { }