import { Recipient } from '~/shared/models/communication/bulk-message.model';

export enum BulkMessagingActions {
  GET_BULK_MESSAGE_HISTORY = '[BulkMessaging] Get History',
  GET_BULK_MESSAGE_UPCOMING = '[BulkMessaging] Get Upcoming',
  DELETE_BULK_MESSAGE = '[BulkMessaging] Delete Bulk Message',
  DELETE_BULK_MESSAGE_SUCCESS = '[BulkMessaging] Delete Bulk Message Success',
  BULK_MESSAGE_ERROR = '[BulkMessaging] Error',
  SET_BULK_MESSAGE_RECIPIENTS = '[BulkMessaging] Set Recipients',
  RESET_BULK_MESSAGE_RECIPIENTS = '[BulkMessaging] Re-set Recipients',
  GET_BULK_MESSAGE_WITH_RECIPIENTS = '[BulkMessaging] GET_BULK_MESSAGE_WITH_RECIPIENTS',
}

export class GetBulkMessageHistory {
  static readonly type = BulkMessagingActions.GET_BULK_MESSAGE_HISTORY;
  constructor(public showRecipients: boolean = true, public page: number = null, public pageSize: number = null) { }
}

export class GetBulkMessagesUpcoming {
  static readonly type = BulkMessagingActions.GET_BULK_MESSAGE_UPCOMING;
  constructor(public showRecipients: boolean = true) { }
}

export class RemoveBulkMessage {
  static readonly type = BulkMessagingActions.DELETE_BULK_MESSAGE;
  constructor(public bulkMessageId: number) { }
}

export class RemoveBulkMessageSuccess {
  static readonly type = BulkMessagingActions.DELETE_BULK_MESSAGE_SUCCESS;
  constructor() { }
}

export class BulkMessageError {
  static readonly type = BulkMessagingActions.BULK_MESSAGE_ERROR;
  constructor(public error: any) { }
}

export class SetBulkMessageRecipients {
  static readonly type = BulkMessagingActions.SET_BULK_MESSAGE_RECIPIENTS;
  constructor(public recipients: Recipient[]) { }
}

export class ResetBulkMessageRecipients {
  static readonly type = BulkMessagingActions.RESET_BULK_MESSAGE_RECIPIENTS;
  constructor() { }
}

export class GetBulkMessageWithRecipients {
  static readonly type = BulkMessagingActions.GET_BULK_MESSAGE_WITH_RECIPIENTS;
  constructor(public bulkMessageId: number) { }
}
