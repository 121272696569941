import { State, Selector, StateContext, Action, Store } from '@ngxs/store';
import { ToggleSixCpaContentAction, ToggleSixCpaAction, SetSelectedFormType, CloseResetFormModal, SetSixCpaFormSummary } from '../../state/six-cpa.actions';
import { ToggleConfirmMedsCheckAction, SetupMedicationsConfirmMedsCheck } from '../../confirm-meds-check/state/confirm-meds-check.actions';
import { MedsCheckService } from '../services/meds-check.service';
import { switchMap, catchError, finalize } from 'rxjs/operators';
import { ProfileUpdateMedsCheckEligibilityAction } from '../../../../core/profile/state/profile.actions';
import { UpdatePatientDetailsForm } from '../../patient-details-form/state/patient-details-form.actions';
import { UpdateMedicationInformationForm } from '../../medication-information-form/state/medication-information.actions';
import { UpdateOutcomesAndActionsForm } from '../../outcomes-and-actions-form/state/outcomes-and-actions-form.actions';
import { UpdatePharmacyInformationForm } from '../../pharmacy-information-form/state/pharmacy-information-form.actions';
import { UpdateMedsCheckMedicationProfileList, ResetMedsCheckMedicationProfileIdsAction, SetMedsCheckMedicationProfileFormAction } from '../../meds-check-medication-profile/state/meds-check-medication-profile.actions';
import { UpdateConsentEligibilityDetailedForm } from '../../consent-eligibility-form/state/consent-eligibility-form-detailed.actions';
import { UpdateConsentEligibilityBasicForm } from '../../consent-eligibility-form/state/consent-eligibility-form-basic.actions';
import {
  SetMedsCheckTypeAction,
  SaveMedsCheckFormListAction,
  SaveMedsCheckFormSuccessAction,
  SaveMedsCheckFormFailedAction,
  PutUpdateMedsCheckFormListAction,
  SubmitNewMedsCheck,
  SubmitExisitingMedsCheck,
  GetMedsCheckForm,
  GetMedsCheckFormSuccess,
  GetMedsCheckFormError,
  UpdateDetailedMedsCheckForms,
  UpdateBasicMedsCheckForms,
  ResetMedsCheckFormListAction,
  OpenMedsCheckConfirmFormAction,
  CloseMedsCheckConfirmFormAction,
  ResetMedsCheckFormStateToDefaults,
  RedirectToClaimFormAfterPatientRegistration,
  ShowInvalidMedsCheckForm,
  GenerateMedsCheckForm,
  ToggleMedsCheckSaving,
  OpenMedsCheckSendDocumentFormAction,
  CloseMedsCheckSendDocumentFormAction,
} from './meds-check-form-list.actions';
import { MedsCheckFormModel } from '../../../../../shared/models/six-cpa/meds-check-form.model';
import { GetMedsCheckPatientRegistrationCsv } from '../../../../../system/csv-export-page/state/csv-export.actions';
import { SetSixCpaSuccessMessages, SetSixCpaErrors, ClearSixCpaCommunications, SetSixCpaInfo } from '../../six-cpa-user-communications/state/six-cpa-user-communications.actions';
import { Injectable } from '@angular/core';
import { SaveOrUpdateMedsCheckAction } from './meds-check-save.actions';
import { closeConfirmationActions } from '../../state/six-cpa-toggles-list';
import { GetSixCpaFormsFromService } from '../../../profile-services/state/profile-services.actions';
import { SixCpaFormTypes } from '../../six-cpa-form-types.enum';
import { SixCpaResponse } from '../../../profile-services/state/profile-services.state';

import { ClearDocuments, SetUploadedDocuments } from '../../upload-document-form/state/upload-document-form.actions';
import { SetActionPlanForm } from '../../action-plan-form/state/action-plan-form.actions';
export enum MedsCheckType {
  PatientRegistration,
  Regular,
  Diabetes,
  RegularAndDiabetes
}

export class MedsCheckFormListStateModel {
  medsCheckId: number;
  sixCpaFormId: number;
  patientDetailsFormId: number;
  consentAndEligibilityFormId: number;
  medicationInformationFormId: number;
  outcomesAndActionsFormId: number;
  pharmacyInformationFormId: number;
  valid: boolean;
  medsCheckType: MedsCheckType;
  saving: boolean;
  showInvalid: boolean;
  static showConfirmForm(showConfirmForm: any) {
    throw new Error("Method not implemented.");
  }
  error: any;
  showConfirmForm: boolean;
  showSendDocumentForm: boolean;
}
@Injectable()
@State<MedsCheckFormListStateModel>({
  name: 'medsCheckFormList',
  defaults: {
    medsCheckId: null,
    sixCpaFormId: null,
    patientDetailsFormId: null,
    consentAndEligibilityFormId: null,
    medicationInformationFormId: null,
    outcomesAndActionsFormId: null,
    pharmacyInformationFormId: null,
    valid: false,
    medsCheckType: MedsCheckType.Regular,
    saving: false,
    error: null,
    showConfirmForm: false,
    showSendDocumentForm: false,
    showInvalid: false,
  }
})
export class MedsCheckFormListState {
  constructor(
    private medsCheckService: MedsCheckService
  ) { }
  @Selector()
  static showInvalid(state: MedsCheckFormListStateModel) { return state.showInvalid; }

  @Selector()
  static saving(state: MedsCheckFormListStateModel) { return state.saving; }

  @Selector()
  static medsCheckType(state: MedsCheckFormListStateModel) { return state.medsCheckType; }

  @Selector()
  static sixCpaFormId(state: MedsCheckFormListStateModel) { return state.sixCpaFormId; }

  @Selector()
  static showConfirmForm(state: MedsCheckFormListStateModel) { return state.showConfirmForm; }

  @Selector()
  static showSendDocumentForm(state: MedsCheckFormListStateModel) { return state.showSendDocumentForm; }

  @Action(SetMedsCheckTypeAction)
  setMedsCheckType(ctx: StateContext<MedsCheckFormListStateModel>, action: SetMedsCheckTypeAction) {
    ctx.patchState({
      medsCheckType: action.medsCheckType
    });
  }

  @Action(SaveMedsCheckFormListAction)
  saveMedsCheck(ctx: StateContext<MedsCheckFormListStateModel>, { clientId, formData }: SaveMedsCheckFormListAction) {
    ctx.patchState({ saving: true });
    return this.medsCheckService.postSave(clientId, formData).pipe(
      switchMap(response => ctx.dispatch(new SaveMedsCheckFormSuccessAction(response))),
      catchError(error => ctx.dispatch(new SaveMedsCheckFormFailedAction(error))),
      finalize(() => ctx.patchState({ saving: false })));
  }

  @Action(PutUpdateMedsCheckFormListAction)
  updateMedsCheck(ctx: StateContext<MedsCheckFormListStateModel>, { clientId, formData }: PutUpdateMedsCheckFormListAction) {
    ctx.patchState({ saving: true });
    return this.medsCheckService.putSave(clientId, formData).pipe(
      switchMap(response => ctx.dispatch(new SaveMedsCheckFormSuccessAction(response))),
      catchError(error => ctx.dispatch(new SaveMedsCheckFormFailedAction(error))),
      finalize(() => ctx.patchState({ saving: false })));
  }

  @Action(SaveMedsCheckFormSuccessAction)
  saveMedsCheckSuccess(ctx: StateContext<MedsCheckFormListStateModel>, { formData }: SaveMedsCheckFormSuccessAction) {
    const { id, sixCpaFormId, patientDetailsFormId, consentAndEligibilityFormId, medicationInformationFormId,
      outcomesAndActionsFormId, pharmacyInformationFormId, hasSubmitted, claimId } = formData;
    ctx.patchState({
      medsCheckId: id,
      sixCpaFormId,
      patientDetailsFormId,
      consentAndEligibilityFormId,
      medicationInformationFormId,
      outcomesAndActionsFormId,
      pharmacyInformationFormId
    });
    ctx.dispatch([
      new SetSixCpaFormSummary({
        id: sixCpaFormId,
        formType: SixCpaFormTypes.MedsCheck,
        hasSubmitted: hasSubmitted
      } as SixCpaResponse),
      new SetupMedicationsConfirmMedsCheck(formData.sixCpaMedications, formData.sixCpaActionPlanForm)
    ]);
    if(claimId && hasSubmitted) {
      ctx.dispatch(new SetSixCpaSuccessMessages([`MedsCheck form sucessfully submitted to the PPA, your claim id is: ${claimId}`]));
    }
    else {
      ctx.dispatch(new SetSixCpaSuccessMessages(['MedsCheck draft form Saved to Scrypt']));
    }
    ctx.dispatch(new GetSixCpaFormsFromService(null));
  }

  @Action(SaveMedsCheckFormFailedAction)
  saveMedsCheckFailed(ctx: StateContext<MedsCheckFormListStateModel>, { errors }: SaveMedsCheckFormFailedAction) {
    const errorsArray = [];
    if(errors.status === 403){
      errorsArray.push("You do not have permission to submit service forms. If you believe this is an error, please contact Scrypt Support via Intercom");
    }
    else if (errors.error[0].value) {
      errors.error.forEach(err => {
        errorsArray.push(err.value);
      });
    } else {
      errorsArray.push(errors.error);
    }
    ctx.dispatch(new SetSixCpaErrors(errorsArray));
  }

  @Action(SubmitNewMedsCheck)
  submitNewMedsCheck(ctx: StateContext<MedsCheckFormListStateModel>, { clientId, formData }: SubmitNewMedsCheck) {
    ctx.patchState({ saving: true, showConfirmForm: false });
    return this.medsCheckService.postSubmit(clientId, formData).pipe(
      switchMap(response => ctx.dispatch([new SaveMedsCheckFormSuccessAction(response) /*, new ProfileUpdateMedsCheckEligibilityAction */])),
      catchError(errors => ctx.dispatch([new SaveMedsCheckFormFailedAction(errors), new SetSixCpaInfo(["We were unable to submit the claim so we've saved it as a draft."]), new SaveOrUpdateMedsCheckAction(true)])),
      finalize(() => {
        ctx.patchState({ saving: false });
        if (formData.medsCheckFormType !== MedsCheckType.PatientRegistration) {
          ctx.dispatch([new GenerateMedsCheckForm(formData)]);
        } else {
          ctx.dispatch([
            new RedirectToClaimFormAfterPatientRegistration(formData),
            new GetMedsCheckPatientRegistrationCsv(formData)]);
        }
      }));
  }

  @Action(SubmitExisitingMedsCheck)
  submitExisitingMedsCheck(ctx: StateContext<MedsCheckFormListStateModel>, { clientId, formData }: SubmitExisitingMedsCheck) {
    ctx.patchState({ saving: true, showConfirmForm: false });
    return this.medsCheckService.putSubmit(clientId, formData).pipe(
      switchMap(response => ctx.dispatch(new SaveMedsCheckFormSuccessAction(response))),
      catchError(errors => ctx.dispatch([new SaveMedsCheckFormFailedAction(errors), new SetSixCpaInfo(["We were unable to submit the claim so we've saved it as a draft."]), new SaveOrUpdateMedsCheckAction(true)])),
      finalize(() => {
        ctx.patchState({ saving: false });
        if (formData.medsCheckFormType !== MedsCheckType.PatientRegistration) {
          ctx.dispatch([new GenerateMedsCheckForm(formData)]);
        } else {
          ctx.dispatch([
            new RedirectToClaimFormAfterPatientRegistration(formData),
            new GetMedsCheckPatientRegistrationCsv(formData)]);
        }
      }));
  }

  @Action(GetMedsCheckForm)
  getMedsCheckForm(ctx: StateContext<MedsCheckFormListStateModel>, { clientId, sixCpaFormId }: GetMedsCheckForm) {
    return this.medsCheckService.getMedsCheckForm(clientId, sixCpaFormId)
      .pipe(switchMap(response => ctx.dispatch([new GetMedsCheckFormSuccess(response), new ToggleSixCpaAction('in')])),
        catchError(err => ctx.dispatch(new GetMedsCheckFormError(err))));
  }

  @Action(GetMedsCheckFormSuccess)
  getMedsCheckFormSuccess(ctx: StateContext<MedsCheckFormListStateModel>, { medsCheckForm }: GetMedsCheckFormSuccess) {
    ctx.patchState({
      medsCheckId: medsCheckForm.id,
      sixCpaFormId: medsCheckForm.sixCpaFormId
    });

    ctx.dispatch(closeConfirmationActions);

    medsCheckForm.medsCheckFormType === MedsCheckType.PatientRegistration
      ? ctx.dispatch(new UpdateDetailedMedsCheckForms(medsCheckForm))
      : ctx.dispatch(new UpdateBasicMedsCheckForms(medsCheckForm));

      if (medsCheckForm.hasSubmitted) {
        ctx.dispatch([new GenerateMedsCheckForm(medsCheckForm)]);
      }

  }

  @Action(RedirectToClaimFormAfterPatientRegistration)
  redirectToClaimForm(ctx: StateContext<MedsCheckFormListStateModel>, { medsCheckForm }: RedirectToClaimFormAfterPatientRegistration) {
    medsCheckForm = this.configureFormDataForNewClaim(medsCheckForm);
    ctx.dispatch([
      new ResetMedsCheckFormStateToDefaults(),
      new UpdateBasicMedsCheckForms(medsCheckForm)
    ]);
    medsCheckForm.patientDetailsForm.patientHasDiabetes ?
      ctx.dispatch(new SetMedsCheckTypeAction(MedsCheckType.Diabetes)) : ctx.dispatch(new SetMedsCheckTypeAction(MedsCheckType.Regular));
  }

  @Action(UpdateDetailedMedsCheckForms)
  updateDetailedMedsCheckForms(ctx: StateContext<MedsCheckFormListStateModel>, { medsCheckForm }: UpdateDetailedMedsCheckForms) {
    ctx.dispatch([
      new UpdatePatientDetailsForm(medsCheckForm.patientDetailsForm),
      new UpdateConsentEligibilityDetailedForm(medsCheckForm.consentAndEligibilityForm),
      new UpdateMedicationInformationForm(medsCheckForm.medicationInformationForm),
      new UpdateOutcomesAndActionsForm(medsCheckForm.outcomesAndActionsForm),
      new UpdatePharmacyInformationForm(medsCheckForm.pharmacyInformationForm),
      new UpdateMedsCheckMedicationProfileList(medsCheckForm.sixCpaMedications),
      new SetSelectedFormType(SixCpaFormTypes.MedsCheck),
      new SetMedsCheckTypeAction(medsCheckForm.medsCheckFormType),
      new SetUploadedDocuments(medsCheckForm.consentDocuments),
      new SetActionPlanForm(medsCheckForm.sixCpaActionPlanForm),
      new SetMedsCheckMedicationProfileFormAction({
        primaryDoctor: medsCheckForm.primaryDoctor,
        allergies: medsCheckForm.allergies
      })
    ]);
  }

  @Action(UpdateBasicMedsCheckForms)
  updateBasicMedsCheckForms(ctx: StateContext<MedsCheckFormListStateModel>, { medsCheckForm }: UpdateBasicMedsCheckForms) {
    ctx.dispatch([
      new UpdatePatientDetailsForm(medsCheckForm.patientDetailsForm),
      new UpdateConsentEligibilityBasicForm(medsCheckForm.consentAndEligibilityForm),
      new UpdatePharmacyInformationForm(medsCheckForm.pharmacyInformationForm),
      new SetSelectedFormType(SixCpaFormTypes.MedsCheck),
      new SetMedsCheckTypeAction(medsCheckForm.medsCheckFormType),
      new UpdateMedsCheckMedicationProfileList(medsCheckForm.sixCpaMedications),
      new SetUploadedDocuments(medsCheckForm.consentDocuments),
      new SetActionPlanForm(medsCheckForm.sixCpaActionPlanForm),
      new SetMedsCheckMedicationProfileFormAction({
        primaryDoctor: medsCheckForm.primaryDoctor,
        allergies: medsCheckForm.allergies
      })
    ]);
  }

  @Action(GetMedsCheckFormError)
  getMedsCheckFormError(ctx: StateContext<MedsCheckFormListStateModel>, action: GetMedsCheckFormError) {
    ctx.patchState({
      error: action.error
    });
  }

  @Action(ResetMedsCheckFormListAction)
  resetMedsCheck(ctx: StateContext<MedsCheckFormListStateModel>, {onlyClearFormIds}: ResetMedsCheckFormListAction) {
    ctx.dispatch([
      new CloseResetFormModal(),
      new ResetMedsCheckFormStateToDefaults(onlyClearFormIds),
      new ClearSixCpaCommunications(),
      new ClearDocuments()
    ]
    );
  }

  @Action(ResetMedsCheckFormStateToDefaults)
  resetFormStateToDefaults(ctx: StateContext<MedsCheckFormListStateModel>, {onlyClearFormIds}: ResetMedsCheckFormStateToDefaults) {
    ctx.patchState({
      medsCheckId: null,
      sixCpaFormId: null,
      patientDetailsFormId: null,
      consentAndEligibilityFormId: null,
      medicationInformationFormId: null,
      outcomesAndActionsFormId: null,
      pharmacyInformationFormId: null,
      valid: false,
      medsCheckType: MedsCheckType.Regular,
      saving: false,
      error: null,
      showConfirmForm: false
    });
    ctx.dispatch(new ResetMedsCheckMedicationProfileIdsAction());
    ctx.dispatch(new ClearDocuments());
  }

  @Action(OpenMedsCheckConfirmFormAction)
  openConfirmForm(ctx: StateContext<MedsCheckFormListStateModel>, action: OpenMedsCheckConfirmFormAction) {
    ctx.patchState({ showConfirmForm: true });
  }

  @Action(CloseMedsCheckConfirmFormAction)
  closeConfirmForm(ctx: StateContext<MedsCheckFormListStateModel>, action: CloseMedsCheckConfirmFormAction) {
    ctx.patchState({ showConfirmForm: false });
  }

  @Action(OpenMedsCheckSendDocumentFormAction)
  openSendDocumentForm(ctx: StateContext<MedsCheckFormListStateModel>, action: OpenMedsCheckSendDocumentFormAction) {
    ctx.patchState({ showSendDocumentForm: true });
  }

  @Action(CloseMedsCheckSendDocumentFormAction)
  closeSendDocumentForm(ctx: StateContext<MedsCheckFormListStateModel>, action: CloseMedsCheckSendDocumentFormAction) {
    ctx.patchState({ showSendDocumentForm: false });
  }

  @Action(ShowInvalidMedsCheckForm)
  showInvalidMedsCheckForm(ctx: StateContext<MedsCheckFormListStateModel>) {
    ctx.patchState({
      showInvalid: true
    });
  }

  @Action(GenerateMedsCheckForm)
  generate(ctx: StateContext<MedsCheckFormListStateModel>, { formData }: GenerateMedsCheckForm) {
    ctx.dispatch([
     new SetupMedicationsConfirmMedsCheck(formData.sixCpaMedications, formData.sixCpaActionPlanForm),
     new ToggleSixCpaContentAction('out'),
     new ToggleConfirmMedsCheckAction('in')
    ]);
  }

  @Action(ToggleMedsCheckSaving)
  toggleMedsCheckSaving(ctx: StateContext<MedsCheckFormListStateModel>, { saving }: ToggleMedsCheckSaving) {
    ctx.patchState({
      saving: saving
    })
  }

  private configureFormDataForNewClaim(form: MedsCheckFormModel) {
    form.medsCheckFormType = MedsCheckType.Regular;
    form.id = null;
    form.sixCpaFormId = null;
    form.patientDetailsFormId = null;
    form.patientDetailsForm.id = null;
    form.consentAndEligibilityFormId = null;
    form.consentAndEligibilityForm.id = null;
    form.medicationInformationFormId = null;
    form.medicationInformationForm.id = null;
    form.outcomesAndActionsFormId = null ;
    form.outcomesAndActionsForm.id = null;
    form.pharmacyInformationFormId = null;
    form.pharmacyInformationForm.id = null;
    return form;
  }
}
