import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClassificationAndRecommendationsFormComponent } from './classification-and-recommendations-form/classification-and-recommendations-form.component';
// tslint:disable-next-line:max-line-length
import { ClassificationAndRecommendationsFormListItemComponent } from './classification-and-recommendations-form-list-item/classification-and-recommendations-form-list-item.component';
import { SixCpaFormListItemChecklistModule } from '../six-cpa-form-list-item-checklist/six-cpa-form-list-item-checklist.module';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';

@NgModule({
  declarations: [ClassificationAndRecommendationsFormComponent, ClassificationAndRecommendationsFormListItemComponent],
  imports: [
    CommonModule,
    NgxsFormPluginModule,
    ReactiveFormsModule,
    SixCpaFormListItemChecklistModule
  ],
  exports: [ClassificationAndRecommendationsFormListItemComponent]
})
export class ClassificationAndRecommendationsModule { }
