import { State, Selector, Action, StateContext } from '@ngxs/store';
import * as moment_ from 'moment';
const moment = moment_;
import { IDAAPatientDetailsFormStateModel } from './daa-patient-details-form-state-model.interface';
import { SetProfileForSixCpaAction } from '../../../state/six-cpa.actions';
import { UpdateDAAPatientDetailsForm } from './daa-patient-details-form.actions';
import { getMedicareDVAOrRepatNumber } from '../../../../../../shared/helpers/patient.helper';
import { Injectable } from '@angular/core';

export interface IDAAPatientDetailsFormBasicStateModel extends IDAAPatientDetailsFormStateModel {
  dateOfService: Date | string;
}

export class DAAPatientDetailsFormBasicStateModel {
  patientDetailsForm: {
    model: Partial<IDAAPatientDetailsFormBasicStateModel>;
  };
}
@Injectable()
@State<DAAPatientDetailsFormBasicStateModel>({
  name: 'daaPatientDetailsFormBasic',
  defaults: {
    patientDetailsForm: {
      model: undefined
    }
  }
})
export class DAAPatientDetailsFormBasicState {
  constructor() {}
  @Selector()
  static form(state: DAAPatientDetailsFormBasicStateModel) { return state.patientDetailsForm.model; }

  @Action(SetProfileForSixCpaAction)
  onSetProfile(ctx: StateContext<DAAPatientDetailsFormBasicStateModel>, { profile }: SetProfileForSixCpaAction) {
    const patient = profile.patient;
    ctx.patchState({
      patientDetailsForm: {
        model: {
          medicareNumber: getMedicareDVAOrRepatNumber(patient),
        }
      }
    });
  }

  @Action(UpdateDAAPatientDetailsForm)
  updateForm(ctx: StateContext<DAAPatientDetailsFormBasicStateModel>, { form }: UpdateDAAPatientDetailsForm) {
    ctx.patchState({
      patientDetailsForm: {
        model: {
          dateOfService: form.dateOfDAAService && moment(form.dateOfDAAService, 'YYYY-MM-DD').format('DD-MM-YYYY'),
          medicareNumber: form.medicareNumber
        }
      }
    });
  }
}
