import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { TenantCategoryState } from '~/modules/profile/tenant-categories/state/tenant-category.state';
import { TenantCategory } from '~/modules/profile/tenant-categories/tenant-category.model';
import { Group } from '~/shared/models/group.model';
import { GroupPageState } from '~/system/group-page/state/group-page.state';
import { DispenseRunRequestModel, ReportType } from '../models/dispense-run-request.model';
import { InitDispenseRunRequest, ResetReport } from '../state/dispense-run.actions';

const FormControls = {
  groups: new FormControl(Validators.required),
  selectedCategories: new FormControl(Validators.required),
  alternateDoh: new FormControl(false),
  alternateDispenseInterval: new FormControl(false),
  targetDoh: new FormControl(),
  dispenseInterval: new FormControl(),
  excludeNoRepeats: new FormControl(false),
};
type FormControlNames = keyof typeof FormControls;

@Component({
  selector: 'app-dispense-run-form',
  templateUrl: './dispense-run-form.component.html',
  styleUrls: ['./dispense-run-form.component.scss']
})
export class DispenseRunFormComponent implements OnInit {
  @Select(GroupPageState.groups) groups$: Observable<Group[]>;
  @Select(TenantCategoryState.categories) categories$: Observable<TenantCategory[]>;
  dispenseRunRequestForm: FormGroup;

  constructor(private formBuilder: FormBuilder, private store: Store) { }

  ngOnInit(): void {
    this.initForm();
  }

  private initForm() {
    this.dispenseRunRequestForm = this.formBuilder.group(FormControls)
  }

  generateReport() {
    const request = this.dispenseRunRequestForm.value as DispenseRunRequestModel;
    request.type = ReportType.dispense;
    this.store.dispatch(new ResetReport());
    this.store.dispatch(new InitDispenseRunRequest(request));
  }

  enforceMaxLength(value) {
    if (value.length === 5) return false;
  }

  clearFormValue(formName: FormControlNames) {
    this.dispenseRunRequestForm.patchValue({
      [formName]: null
    });
  }
}
