<form [formGroup]="serviceProviderDetailsForm" ngxsForm="hmrServiceProviderDetailsFormBasic.serviceProviderDetailsForm"
    class="p-4">
    <div class="form-group pb-1">
        <label for="serviceProviderName" class="input-label">Service Provider Name
        </label>
        <input type="text" [class.has-error]="formControls.serviceProviderName.invalid && (showInvalid$ | async)"
            class="form-control form-control-lg w-50" name="serviceProviderName" formControlName="serviceProviderName"
            id="serviceProviderName" required>
    </div>
    <div class="form-group pb-1">
        <label for="serviceProviderPhoneNumber" class="input-label">Service Provider Phone Number
        </label>
        <input type="text" [class.has-error]="formControls.serviceProviderPhoneNumber.invalid && (showInvalid$ | async)"
            class="form-control form-control-lg w-50" name="serviceProviderPhoneNumber"
            formControlName="serviceProviderPhoneNumber" id="serviceProviderPhoneNumber" required>
    </div>
    <div class="form-group pb-1">
        <label for="serviceProviderEmail" class="input-label">Service Provider Email Address
        </label>
        <input type="text" [class.has-error]="formControls.serviceProviderEmail.invalid && (showInvalid$ | async)"
            class="form-control form-control-lg w-50" name="serviceProviderEmail" formControlName="serviceProviderEmail"
            id="serviceProviderEmail" required>
    </div>
</form>