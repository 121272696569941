import { FilteredSearch, MailboxMessage } from '../../../shared/models/message.model';
import { MailTabs } from '../../../shared/types/communications';

export enum CommunicationsActions {
  GET_COMMUNICATIONS_HISTORY = '[Communications] Get History',
  TOGGLE_SELECTED_MESSAGE = '[Communications] toggle selected message',
  BULK_TOGGLE_SELECTED_MESSAGES = '[Communications] bulk toggle selected messages',
  GET_MAILBOX_PAGE = '[Communications] Get Mailbox Page',
  GET_MAILBOX_ALL = '[Communications] Get Mailbox All',
  SEARCH_MAILBOX_KEYWORD = '[Communications] Search Mailbox Keyword',
  MAILBOX_ERROR = '[Communications] MAILBOX_ERROR',
  SET_MESSAGE_ARCHIVE = '[Communications] MESSAGE_ARCHIVE',
  SET_MESSAGE_FLAGGED = '[Communications] MESSAGE_FLAGGED',
  SET_MESSAGE_READ = '[Communications] MESSAGE_READ',
  SET_MESSAGE_REPLIED = '[Communications] MESSAGE_REPLIED',
  ARCHIVE_SELECTED = '[Communications] ARCHIVE_SELECTED',
  FLAG_SELECTED = '[Communications] FLAG_SELECTED',
  READ_SELECTED = '[Communications] READ_SELECTED',
  REPLIED_SELECTED = '[Communications] REPLIED_SELECTED',
  GET_MONTHLY_SENT = '[Comnunications] Get monthly sent',
  GET_UNREAD = '[Communications] Get unread inbox',
  REFRESH_ALL_MAILBOXES = '[Communications] Refresh All Mailboxes',
  OPEN_BULK_COMM_RECIPIENTS_MODAL = '[Communications] Open bulk comm recipients modal',
  CLOSE_BULK_COMM_RECIPIENTS_MODAL = '[Communications] Close bulk comm recipients modal',
}

export class OpenBulkCommRecipientsModal {
  static readonly type = CommunicationsActions.OPEN_BULK_COMM_RECIPIENTS_MODAL;
  constructor(public bulkCommId: string) { }
}

export class CloseBulkCommRecipientsModal {
  static readonly type = CommunicationsActions.CLOSE_BULK_COMM_RECIPIENTS_MODAL;
  constructor() { }
}

export class GetCommunicationsHistory {
  static readonly type = CommunicationsActions.GET_COMMUNICATIONS_HISTORY;
  constructor() { }
}

export class SetMessageArchive {
  static readonly type = CommunicationsActions.SET_MESSAGE_ARCHIVE;
  constructor(
    public message: MailboxMessage,
    public isArchived: boolean,
    public mailType: MailTabs
  ) { }
}

export class SetMessageFlagged {
  static readonly type = CommunicationsActions.SET_MESSAGE_FLAGGED;
  constructor(
    public message: MailboxMessage,
    public isFlagged: boolean,
    public mailType: MailTabs
  ) { }
}

export class SetMessageRead {
  static readonly type = CommunicationsActions.SET_MESSAGE_READ;
  constructor(
    public message: MailboxMessage,
    public isRead: boolean,
    public mailType: MailTabs
  ) { }
}

export class SetMessageReplied {
  static readonly type = CommunicationsActions.SET_MESSAGE_REPLIED;
  constructor(
    public message: MailboxMessage,
    public isReplied: boolean,
    public mailType: MailTabs
  ) { }
}

export class ArchiveSelected {
  static readonly type = CommunicationsActions.ARCHIVE_SELECTED;
  constructor(
    public mailType: MailTabs,
    public isArchived: boolean
  ) { }
}

export class FlagSelected {
  static readonly type = CommunicationsActions.FLAG_SELECTED;
  constructor(
    public mailType: MailTabs,
    public isFlagged: boolean
  ) { }
}

export class ReadSelected {
  static readonly type = CommunicationsActions.READ_SELECTED;
  constructor(
    public mailType: MailTabs,
    public isRead: boolean
  ) { }
}

export class RepliedSelected {
  static readonly type = CommunicationsActions.REPLIED_SELECTED;
  constructor(
    public mailType: MailTabs,
    public isReplied: boolean
  ) { }
}

export class SearchMailByKeywords {
  static readonly type = CommunicationsActions.SEARCH_MAILBOX_KEYWORD;
  constructor(
    public filters: FilteredSearch,
    public mailType: MailTabs
  ) { }
}

export class GetMailboxPage {
  static readonly type = CommunicationsActions.GET_MAILBOX_PAGE;
  constructor(
    public filters: FilteredSearch,
    public mailType: MailTabs
  ) { }
}

export class GetMailboxAll {
  static readonly type = CommunicationsActions.GET_MAILBOX_ALL;
  constructor(
    public filters: FilteredSearch,
    public mailType: MailTabs
  ) { }
}

export class GetMonthlySent{
  static readonly type = CommunicationsActions.GET_MONTHLY_SENT;
  constructor(){}
}

export class GetUnread{
  static readonly type = CommunicationsActions.GET_UNREAD;
  constructor(){}
}

export class ToggleSelectedMessage {
  static readonly type = CommunicationsActions.TOGGLE_SELECTED_MESSAGE;
  constructor(
    public message: MailboxMessage,
    public selected: boolean,
    public mailType: MailTabs
    ) { }
}

export class BulkToggleSelectedMessages {
  static readonly type = CommunicationsActions.BULK_TOGGLE_SELECTED_MESSAGES;
  constructor(
    public startIndex: number,
    public endIndex: number,
    public selected: boolean,
    public mailType: MailTabs
  ) { }
}

export class MailboxError {
  static readonly type = CommunicationsActions.MAILBOX_ERROR;
  constructor(
    public error: any,
    ) { }
}

