import { Medicine } from '~/shared/models/six-cpa/medicine.model';
import { SixCpaMedicationModel } from '~/shared/models/six-cpa/six-cpa-medication.model';

export enum DaaMedicationProfileActions {
  SET_MEDICATION_PROFILE_SCRIPRS_ACTION = '[DaaMedicationProfile] Set Scripts',
  TOGGLE_MEDICATION_PROFILE_LIST_ITEM_ACTION = '[DaaMedicationProfile] Toggle List Item',
  TOGGLE_ALL_MEDICATION_PROFILE_LIST_ACTION = '[DaaMedicationProfile] Toggle All List Item',
  ADD_MEDICATION_MEDICATION_PROFILE_LIST_ACTION = '[DaaMedicationProfile] Add Medication To List Item',
  SELECT_MEDICATION_PROFILE_LIST_ITEM_ACTION = '[DaaMedicationProfile] Select List Item',
  TOGGLE_MEDICATION_PROFILE_ACTION = '[DaaMedicationProfile] Toggle Content',
  UPDATE = '[DaaMedicationProfile] Update Content',
  RESET = '[DaaMedicationProfile] Reset Medication Profile Ids'
}

export class SetDAAMedicationProfileScriptsAction {
  static readonly type = DaaMedicationProfileActions.SET_MEDICATION_PROFILE_SCRIPRS_ACTION;
  constructor(public categorisedScripts: any[]) { }
}

export class ToggleDAAMedicationProfileListItemAction {
  static readonly type = DaaMedicationProfileActions.TOGGLE_MEDICATION_PROFILE_LIST_ITEM_ACTION;
  constructor(public item: Medicine) { }
}

export class ToggleAllDAAMedicationProfileListAction {
  static readonly type = DaaMedicationProfileActions.TOGGLE_ALL_MEDICATION_PROFILE_LIST_ACTION;
  constructor(public isSelectAll: boolean, public categoryId?: number) { }
}

export class AddMedicationToDAAMedicationProfileListAction {
  static readonly type = DaaMedicationProfileActions.ADD_MEDICATION_MEDICATION_PROFILE_LIST_ACTION;
  constructor(public item: Medicine) { }

}

export class SelectDAAMedicationProfileListItemAction {
  static readonly type = DaaMedicationProfileActions.SELECT_MEDICATION_PROFILE_LIST_ITEM_ACTION;
  constructor(public productDispensedId: string) { }
}

export class ToggleDAAMedicationProfileAction {
  static readonly type = DaaMedicationProfileActions.TOGGLE_MEDICATION_PROFILE_ACTION;
  constructor(public showMode: string) { }
}

export class UpdateDAAMedicationProfileList {
  static readonly type = DaaMedicationProfileActions.UPDATE;
  constructor(public medication: SixCpaMedicationModel[]) { }
}

export class ResetDAAMedicationProfileIdsAction {
  static readonly type = DaaMedicationProfileActions.RESET;
}