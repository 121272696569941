import { NgModule } from "@angular/core";
import { NgSelectModule } from "@ng-select/ng-select";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { LoaderModule } from "~/modules/core/loader/loader.module";
import { TimezoneModule } from "~/modules/core/timezone/timezone.module";
import { NewPharmacyFormComponent } from "./new-pharmacy-form.component";


@NgModule({
  declarations: [
    NewPharmacyFormComponent
  ],
  imports: [
    NgSelectModule,
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
    LoaderModule,
    TimezoneModule,
  ],
  exports: [
    NewPharmacyFormComponent
  ]
})
export class NewPharmacyFormModule { }
