import { Component, OnInit } from '@angular/core';
import {
  ServicesFilter,
  ProfileServicesState,
  PPAClaimType,
  SixCpaResponse,
} from './state/profile-services.state';
import { Store, Select } from '@ngxs/store';
import {
  ToggleFormLoading,
  UpdateSixCpaFormFilter,
} from './state/profile-services.actions';
import { FormControl, FormBuilder } from '@angular/forms';
import { GetMedsCheckForm } from '../six-cpa/meds-check/state/meds-check-form-list.actions';
import { ProfileState } from '../../../modules/core/profile/state/profile.state';
import { GetCIForm } from '../six-cpa/clinical-intervention/state/clinical-intervention.actions';
import { MedsCheckType } from '../six-cpa/meds-check/state/meds-check-form-list.state';
import { GetDAAForm } from '../six-cpa/DAA/dose-administration-aids/state/dose-administration-aids.actions';
import { GetStagedSupplyForm } from '../six-cpa/Staged-Supply/staged-supply/state/staged-supply-form-list.actions';
import { GetSickLeaveForm } from '../six-cpa/sick-leave/sick-leave-form-list/state/sick-leave.actions';
import { GetHMRForm } from '../six-cpa/HMR/hmr-form-list/state/hmr-form.actions';
import { HMRFormType } from '../six-cpa/HMR/hmr-form-list/state/hmr-form.state';
import { GetMedicationListForm } from '../six-cpa/medication-list/medication-list-form-list/state/medication-list-form.actions';
import { SetSixCpaFormSummary } from '../six-cpa/state/six-cpa.actions';
import { SixCpaFormTypes } from '../six-cpa/six-cpa-form-types.enum';
import { OnDestroy } from '@angular/core';
import { Observable, Subscription, Unsubscribable } from 'rxjs';
import { FeatureDecisions } from '@base/modules/core/features/feature-decisions.service';
import { map, take } from 'rxjs/operators';
import { GetDocumentsFromService } from '../../../modules/document/state/document-services.actions';
import { FeaturesState } from '../../../modules/core/features/features.state';
@Component({
  selector: 'app-profile-services',
  templateUrl: './profile-services.component.html',
  styleUrls: ['./profile-services.component.scss'],
})
export class ProfileServicesComponent implements OnInit, OnDestroy {
  @Select(ProfileServicesState.filteredSixCpaForms) filteredSixCpaForms$: Observable<any>;
  @Select(FeaturesState.getFeatures) features$: Observable<any>;
  filter = ServicesFilter;
  formTypes = SixCpaFormTypes;
  profileServicesForm = this.formBuilder.group({
    selectedFilter: new FormControl(this.filter.All),
  });
  subscriptions: Unsubscribable[] = [];

  constructor(
    private store: Store,
    private formBuilder: FormBuilder,
    public featureDecisions: FeatureDecisions
  ) {}

  ngOnInit() {
    this.store.dispatch(new GetDocumentsFromService());
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub && sub.unsubscribe());
  }

  editSixCpaForm(form: SixCpaResponse) {
    this.featureDecisions.isAllowedToEditServiceForm().pipe(take(1)).subscribe(canEdit => {
      const { id, formType, hasSubmitted } = form;
      const isUserAllowedToEditNotSubmittedForm = canEdit || hasSubmitted;

      if(!isUserAllowedToEditNotSubmittedForm) return;

      const clientId = this.store.selectSnapshot(ProfileState.clientId);

      this.store.dispatch(new ToggleFormLoading(id, true));

      this.subscriptions.push(
        this.getFormOpenSubscription(formType, clientId, id)
      );

      this.store.dispatch(new SetSixCpaFormSummary(form));
      });
  }

  getFormOpenSubscription(formType: SixCpaFormTypes, clientId: string, id: number) {
    let action = null;
    switch (formType) {
      case SixCpaFormTypes.MedsCheck: {
        action = new GetMedsCheckForm(clientId, id);
        break;
      }
      case SixCpaFormTypes.ClinicalIntervention: {
        action = new GetCIForm(clientId, id);
        break;
      }
      case SixCpaFormTypes.DAA: {
        action = new GetDAAForm(clientId, id);
        break;
      }
      case SixCpaFormTypes.StagedSupply: {
        action = new GetStagedSupplyForm(clientId, id);
        break;
      }
      case SixCpaFormTypes.SickLeaveCertificate: {
        action = new GetSickLeaveForm(clientId, id);
        break;
      }
      case SixCpaFormTypes.HMR: {
        action = new GetHMRForm(clientId, id);
        break;
      }
      case SixCpaFormTypes.MedicationList: {
        action = new GetMedicationListForm(clientId, id);
        break;
      }
      case SixCpaFormTypes.AIRLookup:
      case SixCpaFormTypes.HMS: {
        alert('not implemented');
        this.store.dispatch(new ToggleFormLoading(id, false));
        return new Subscription();
      }
      default:
        throw new Error('Unknown form type');
    }
    return this.store
          .dispatch(action)
          .subscribe(() =>
            this.store.dispatch(new ToggleFormLoading(id, false))
          );
  }

  updateFormTypeFilter() {
    this.store.dispatch(
      new UpdateSixCpaFormFilter(this.profileServicesForm.value.selectedFilter)
    );
  }

  getClaimType(claimType: PPAClaimType) {
    switch (claimType) {
      case PPAClaimType.MedsCheck: {
        return 'MedsCheck Claim';
      }
      case PPAClaimType.DiabetesMedsCheck: {
        return ' MedsCheck Diabetes Claim';
      }
      case PPAClaimType.HMR: {
        return 'HMR Claim';
      }
      default:
        return 'PPA Claim';
    }
  }

  getFormType(formType: SixCpaFormTypes, subType?: any) {
    if (formType === SixCpaFormTypes.MedsCheck) {
      switch (subType) {
        case MedsCheckType.PatientRegistration:
          return 'Medscheck Patient Registration';
        case MedsCheckType.Regular:
          return 'Regular Medscheck';
        case MedsCheckType.Diabetes:
          return 'Diabetes Medscheck';
        default:
          return 'Meds Check';
      }
    } else {
      switch (formType) {
        case SixCpaFormTypes.HMR: {
          switch (subType) {
            case HMRFormType.NewClaim:
              return 'HMR New Claim';
            case HMRFormType.InterviewRegisteredPhamacist:
              return 'HMR Interview Registered Phamacist';
            case HMRFormType.InterviewOutsidePatientHome:
              return "HMR Interview Outside Patient's Home";
            default:
              return 'HMR';
          }
        }
        case SixCpaFormTypes.SickLeaveCertificate: {
          return 'Sick Leave Certificate';
        }
        case SixCpaFormTypes.DAA: {
          return 'DAA';
        }
        case SixCpaFormTypes.StagedSupply: {
          return 'Staged Supply';
        }
        case SixCpaFormTypes.ClinicalIntervention: {
          return 'Clinical Intervention';
        }
        case SixCpaFormTypes.MedicationList: {
          return 'Medication List';
        }
        case SixCpaFormTypes.HMS: {
          return 'Home Medicine Service';
        }
        case SixCpaFormTypes.AIRLookup: {
          return 'AIR Lookup'
        }
      }
    }
  }

  getEditIcon(formSubmitted: boolean) {
    return this.featureDecisions.isAllowedToEditServiceForm().pipe(take(1), map((canEditForm) => {
      return formSubmitted ? 'far fa-eye' : canEditForm ? 'far fa-edit' : '';
    }))

  }
}
