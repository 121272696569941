import { State, Action, StateContext, Selector } from '@ngxs/store';
import { GetFeaturesAction } from './features.actions';
import { FeaturesService } from './features.service';
import { tap, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { FeatureFlags } from './features.enum';
import { Injectable } from '@angular/core';

export type Feature = { [featureName: string]: boolean };

export class FeaturesStateModel {
  public features: Feature;
}

@Injectable()
@State<FeaturesStateModel>({
  name: 'features',
  defaults: {
    features: {},
  },
})
export class FeaturesState {
  @Selector()
  static isEnabled(
    state: FeaturesStateModel
  ): (featureName: FeatureFlags) => boolean {
    return (featureName: string) => state.features[featureName] || false;
  }

  @Selector()
  static getFeatures(state: FeaturesStateModel): Feature {
    return state.features;
  }

  constructor(private featureService: FeaturesService) { }

  @Action(GetFeaturesAction)
  get(ctx: StateContext<FeaturesStateModel>) {
    return this.featureService.getLatest().pipe(
      tap(({ body  }) => ctx.setState({ features: body })),
      catchError((err) => of(console.log(err)))
    );
  }
}
