<section>
  <div>
      <div *ngIf="!isLoaded" g-xs="p4 text:center" class="flex">
        <app-loader class="loading"></app-loader>
      </div>
      <div *ngIf="isLoaded" class="send-message" g-xs="px3 pt3 pb4">
          <h4>Mail History</h4>
        <div>
          <app-mail-note-history [mailNoteList]="mailNoteList"></app-mail-note-history>
        </div>
        <div>
          <app-add-mail-note [clientId]="client.id" (noteAddedEvent)="noteAdded($event)"></app-add-mail-note>
        </div>
      </div>
  </div>
</section>
