import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClinicalInterventionComponent } from './clinical-intervention.component';
import { ClassificationAndRecommendationsModule } from '../classification-and-recommendations/classification-and-recommendations.module';
import { NgxsModule } from '@ngxs/store';
import { ClassificationAndRecommendationsState } from '../classification-and-recommendations/state/classification-and-recommendations.state';
import { AdditionalDetailsState } from '../additional-details/state/additional-details.state';
import { AdditionalDetailsModule } from '../additional-details/additional-details.module';
import { MedicationProfileState } from '../medication-profile/state/medication-profile.state';
import { MedicationProfileModule } from '../medication-profile/medication-profile.module';
import { ClinicalInterventionState } from './state/clinical-intervention.state';
import { ClinicalInterventionService } from './services/clinical-intervention.service';
import { ClinicalInterventionSaveState } from './state/clinical-intervention-save.state';

@NgModule({
  declarations: [ClinicalInterventionComponent],
  imports: [
    CommonModule,
    ClassificationAndRecommendationsModule,
    AdditionalDetailsModule,
    MedicationProfileModule,
    NgxsModule.forFeature([
      ClassificationAndRecommendationsState,
      AdditionalDetailsState,
      MedicationProfileState,
      ClinicalInterventionState,
      ClinicalInterventionSaveState
    ])
  ],
  exports: [
    ClinicalInterventionComponent
  ],
  providers: [ClinicalInterventionService]
})
export class ClinicalInterventionModule { }
