<ng-container *ngIf="(inviteToSignUp$ | async) as inviteToSignUp">
  <div class="modal">
    <div class="modal-body">
      <div>
        <span (click)="closeModal()" class="pull-right pointer">Close <i class="fas fa-times"></i></span>
      </div>
      <form [formGroup]="inviteForm" ngxsForm="inviteToSignUp.inviteForm">
        <div class="form-group">
          <label for="userId" class="input-label">Email</label>
          <input class="form-control form-control-lg" type="text" id="email" name="email" formControlName="email" />
          <div *ngIf="!inviteToSignUp.checkingCredentials && inviteForm.value.email" class="mt-1">
            <div *ngIf="inviteToSignUp.emailAvailable">This email is available &nbsp;<i class="fas fa-check-circle success"></i></div>
            <div *ngIf="!inviteToSignUp.emailAvailable">This email is already in use &nbsp;<i class="fas fa-times danger"></i></div>
          </div>
        </div>
        <div class="form-group">
          <label for="mobile" class="input-label">Mobile</label>
          <input class="form-control form-control-lg" type="text" id="mobile" name="mobile" formControlName="mobile" />
        </div>
        <div>
          <app-loader *ngIf="inviteToSignUp.checkingCredentials" size="30px" loadingMessage="Checking availability"></app-loader>
          <div *ngIf="!inviteToSignUp.checkingCredentials && inviteForm.value.mobile">
            <div *ngIf="inviteToSignUp.mobileNumberAvailable">This number is available &nbsp;<i class="fas fa-check-circle success"></i></div>
            <div *ngIf="!inviteToSignUp.mobileNumberAvailable">This number is already in use &nbsp;<i class="fas fa-times danger"></i></div>
          </div>
        </div>
        <div class="mt-3">
          <button (click)="invite(inviteTypes.Sms)" [disabled]="inviteToSignUp.loading || !mobileIsValid()"
            class="button btn__green mr-3">Send SMS</button>
          <button (click)="invite(inviteTypes.Email)"
            [disabled]="inviteToSignUp.loading || !emailIsValid()" class="button btn__green mr-4">Send
            Email</button>
            <button (click)="validateNewInviteDetails()" class="btn btn-outline-info">Check again <i class="fal fa-sync"></i></button>
        </div>
        <ng-container *ngIf="inviteToSignUp.loading">
          <div class="mt-3">
            <app-loader></app-loader>
          </div>
        </ng-container>
      </form>
    </div>
  </div>
  <div class="modal-background">
  </div>
</ng-container>