import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { BaseApi } from '../core/base-api/base-api';
import { Router } from '@angular/router';
import { StorageService } from '../core/storage/storage.service';
import { AlertService } from '../../modules/core/alert/alert.service';
import { Pharmacist } from '../models/pharmacy/pharmacist.model';

@Injectable()
export class PharmacistService extends BaseApi {
  API = '';

  constructor(
    @Inject('environment') env: any,
    public http: HttpClient,
    public router: Router,
    public storageService: StorageService,
    public alertService: AlertService
  ) {
    super(http, router, storageService, alertService);
    this.API = env.baseApiUrl;
  }

  getPharmacistsForTenant(): Observable<Pharmacist[]> {
    return this.get<Pharmacist[]>(`${this.API}/api/pharmacists`);
  }

  updatePharmacist(pharmacist: Pharmacist) {
    return this.put(`${this.API}/api/pharmacists`, pharmacist);
  }

  createPharmacist(pharmacist: Pharmacist) {
    return this.post(`${this.API}/api/pharmacists`, pharmacist);
  }
  deletePharmacist(pharmacistId: number) {
    return this.delete(`${this.API}/api/pharmacists/${pharmacistId}`);
  }
}
