<div class="accordian">
  <div class="row align-items-center"
    [ngClass]="{'top-panel-active': showForm == 'in', 'top-panel': showForm == 'out' }"
    (click)="showForm = toggle(showForm)">
    <div class="px-3 title col-9 ">{{formTitle}}</div>
    <div *ngIf="complete" class="progress done px-3 col-3">Complete &nbsp;
      <i [ngClass]="{ 'fa fa-caret-up': showForm == 'in', 'fa fa-caret-down': showForm == 'out' }"></i>
    </div>
    <div *ngIf="!complete" class="progress pending px-3 col-3">Needs Review &nbsp;
      <i class="fa" [ngClass]="{ 'fa-caret-up': showForm == 'in', 'fa-caret-down': showForm == 'out' }"></i>
    </div>
  </div>
  <div [@accordionAnimation]="showForm" class="form">
    <ng-content></ng-content>
  </div>
</div>