<section>
    <app-notification [notification]="notification"></app-notification>
    <div *ngIf="!isLoaded">
      <app-loader></app-loader>
    </div>
    <div *ngIf="isLoaded">
      <div>
        <form #f="ngForm" (ngSubmit)="onSubmit(f)">
          <div class="form-group">
            <label class="font__bold font__title-1" for="pre-selected-messages">Pre-selected messages</label>
            <select 
            class="form-control pre-selected-messages color__grey-text" 
            g-xs="py2" 
            id="pre-selected-messages"
            name="emailTypeId"
            #emailTypeId="ngModel"
            [ngModel]="'0'"
            (change)="onEmailTypeChange(f)">
              <option value="0">
                Use a pre-selected emails to send to your clients
              </option>
              <option *ngFor="let item of emailTypes" [value]="item.id">
                {{item.subject}}
              </option>
            </select>
          </div>
          <div class="form-group" [ngClass]="{'has-error': subject.invalid && subject.touched}">
              <label class="font__bold font__title-1" for="email-subject">Subject</label>
              <input id="email-subject"  class="form-control" #subject="ngModel" name="subject" ngModel/>
          </div>
          <div class="form-group" [ngClass]="{'has-error': message.invalid && message.touched}">
            <label class="font__bold font__title-1" for="email-message">Your Message</label>
            <textarea id="email-message" g-xs="p3" class="form-control email-message" required #message="ngModel" name="message" ngModel>
              {{message}}
            </textarea>
            <span *ngIf="message.invalid && message.touched" class="form-help-text">Required field for message</span>
          </div>
          <button type="submit" g-xs="p2 text:center" class="btn__yellow color__primary-text bg__yellow font__bold" [disabled]="f.invalid || !client.emailAddress">Send Email</button>
          <span *ngIf="!client.emailAddress" class="alert alert-danger">No client email on file</span>
        </form>
      </div>
    </div>
  
  </section>