import { Component, OnInit } from '@angular/core';
import { AddEditMedicationState } from './state/add-edit-medication.state';
import { Observable } from 'rxjs';
import { Store, Select } from '@ngxs/store';
import { slideRight } from '../../../../shared/core/animations';
import { ToggleSixCpaContentAction } from '../state/six-cpa.actions';
import { ToggleAddEditMedicationAction, SearchMedicationAction, SelectMedicationAction, ResetMedicationAction, AddMedication } from './state/add-edit-medication.actions';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Medicine } from '../../../../shared/models/six-cpa/medicine.model';

@Component({
  selector: 'app-add-edit-medication',
  templateUrl: './add-edit-medication.component.html',
  styleUrls: ['./add-edit-medication.component.scss'],
  animations: [
    slideRight
  ]
})
export class AddEditMedicationComponent implements OnInit {

  constructor(private store: Store) { }
  @Select(AddEditMedicationState) addEditMedication$: Observable<any>;

  @Select(AddEditMedicationState.searchList) searchList$: Observable<Medicine[]>;


  medicationForm = new FormGroup({
    id: new FormControl(null),
    productDispensedId: new FormControl(null, Validators.required),
    brandName: new FormControl(null, Validators.required),
    genericName: new FormControl(null),
    quantity: new FormControl(null),
    directions: new FormControl(null),
    reasonsForTaking: new FormControl(null),
    notes: new FormControl(null),
    prescriber: new FormControl(null),
    startDate: new FormControl(null)
  });

  ngOnInit() {
  }

  checkQuery(loading: boolean) {
    const { brandName } = this.medicationForm.controls;
    if (brandName.invalid) {
      return true;
    }
    if (brandName.value.length < 3) {
      return true;
    }
    if (loading) {
      return true;
    }
    return false;
  }

  search(query: string) {
    if (query !== '') {
      this.store.dispatch(new SearchMedicationAction(query));
    }
  }

  selectMedication(item: Medicine) {
    this.store.dispatch(new SelectMedicationAction(item));
  }

  resetDrug() {
    this.store.dispatch(new ResetMedicationAction());
  }

  addMedication() {
    const item = this.store.selectSnapshot(AddEditMedicationState.medication);
    this.store.dispatch(new AddMedication(item));
    this.close();
  }

  /*
  @Dispatch()
  addMedication = () => {
    const item = this.store.selectSnapshot(AddEditMedicationState.medication);
    this.store.select(SixCpaState.activeServiceType).subscribe(formType =>
      formType == FormType.MedsCheck ?
        this.store.dispatch(new AddMedicationToMedsCheckMedicationProfileListAction(item))
        : this.store.dispatch(new AddMedicationToMedicationProfileListAction(item))
    )
    this.close();
  }
  */

  close() {
    this.medicationForm.reset();
    this.store.dispatch([new ToggleSixCpaContentAction('in'), new ToggleAddEditMedicationAction('out')]);
  }
}
