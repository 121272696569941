import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { AuthService } from '../../shared/services/auth.service';
import { AuthUser } from '../../shared/models/authUser.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-password-reset-page',
  templateUrl: './password-reset-page.component.html',
  styleUrls: ['./password-reset-page.component.scss']
})
export class PasswordResetPageComponent implements OnInit {

  constructor(private authServise: AuthService,
    private router: Router) { }

  form: FormGroup;
  isShowMessage = false;
  resetMessage = `We've just sent you an email to reset your password.`;

  ngOnInit() {
    this.form = new FormGroup({
      'username': new FormControl(null, [Validators.required, Validators.email])
    });
  }

  onGoBack() {
    this.router.navigate(['']);
  }

  onResetClick() {
    const formData = this.form.value;
    const user = new AuthUser(formData.username, '');
    this.authServise.changePassword(user)
      .subscribe(
        () => {
          this.isShowMessage = true;
        });
  }

}
