import { Component, OnInit } from '@angular/core';
import { SickLeaveConsentAndEligibilityState } from '../sick-leave-consent-and-eligibility/state/sick-leave-consent-and-eligibility.state';
import { Observable } from 'rxjs';
import { Select } from '@ngxs/store';

@Component({
  selector: 'app-sick-leave-consent-and-eligibility-list-item',
  templateUrl: './sick-leave-consent-and-eligibility-list-item.component.html'
})
export class SickLeaveConsentAndEligibilityListItemComponent implements OnInit {
  @Select(SickLeaveConsentAndEligibilityState) sickLeaveConsentAndEligibilityForm$: Observable<any>;

  constructor() { }

  ngOnInit() {
  }

}
