import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SSOutcomesAndActionsFormListItemComponent } from './ss-outcomes-and-actions-form-list-item.component';
import { SixCpaFormListItemChecklistModule } from '../../six-cpa-form-list-item-checklist/six-cpa-form-list-item-checklist.module';
import { SSOutcomesAndActionsFormModule } from '../ss-outcomes-and-actions-form/ss-outcomes-and-actions-form.module';

@NgModule({
    declarations: [
        SSOutcomesAndActionsFormListItemComponent
    ],
    imports: [
        CommonModule,
        SixCpaFormListItemChecklistModule,
        SSOutcomesAndActionsFormModule
    ],
    exports: [
        SSOutcomesAndActionsFormListItemComponent
    ]
})
export class SSOutcomesAndActionsFormListItemModule { }