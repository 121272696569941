import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class StorageService {
  constructor(private cookieService: CookieService) {}

  public getItem(key: string) {
    const data = this.cookieService.get(key);
    if (data === '' || data == null) {
      return null;
    }
    return data;
  }

  public setItem(key: string, data: string) {
    this.cookieService.set(key, data, undefined, '/');
  }

  public deleteItem(key: string) {
    this.cookieService.delete(key);
  }

  public clearStorage() {
    this.cookieService.deleteAll();
  }
}
