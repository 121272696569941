import { HMRChildForm } from '../hmr-child-form';
import { OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Store, Actions } from '@ngxs/store';
import { SetTotalItemsAndCompletedItems } from './state/hmr-service-provider-details-form.actions';

const controlsToIgnore = ['pharmacistGivenName', 'pharmacistFamilyName', 'pharmacist', 'pharmacistPhoneNumber', 'pharmacistEmail', 'ahpraNumber'];
export class HMRServiceProviderDetailsFormBaseComponent extends HMRChildForm implements OnInit, OnDestroy {
    serviceProviderDetailsForm: FormGroup;
    formResetStatus: boolean;

    constructor(private formBuilder: FormBuilder, protected store: Store, actions: Actions) {
      super(actions);
      this.serviceProviderDetailsForm = this.formBuilder.group({
        serviceProviderName: new FormControl('', Validators.required),
        serviceProviderPhoneNumber: new FormControl('', Validators.required),
        serviceProviderEmail: new FormControl('', [Validators.required, Validators.email]),
      });
    }

    protected actionOnResetForm() {

      const controls = Object.keys(this.serviceProviderDetailsForm.controls);
      controls.filter((key) => {
        if (!controlsToIgnore.includes(key)) {
          this.serviceProviderDetailsForm.controls[key].setValue('');
        }
      });
    }

    protected onFormChange() {
      const controls = Object.keys(this.serviceProviderDetailsForm.controls);
      const completedItems = this.getTotalValidItems(controls);
      this.store.dispatch(new SetTotalItemsAndCompletedItems(controls.length, completedItems));
    }

    private getTotalValidItems(controls: Array<string>) {
      return controls.filter((key) => {
        const formControl = this.serviceProviderDetailsForm.get(key);
        return  formControl.status === 'VALID' || (formControl.status === 'DISABLED' && !formControl.invalid && formControl.errors === null);
      }).length;
    }
    
    get formControls() {
      return this.serviceProviderDetailsForm.controls;
    }
  }

