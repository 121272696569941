<div g-xs="p3 my3" class="bg__grey">
  <form #f="ngForm" (ngSubmit)="onSubmit(f)">
      <div class="form-group" [ngClass]="{'has-error': name.invalid && name.touched}">
          <label class="font__bold font__title-1" for="name">Name</label>
          <input id="name"  class="form-control" #name="ngModel" name="name" required
          [ngModel]="currentItem.name"/>
          <span *ngIf="name.invalid && name.touched" class="form-help-text">Required field</span>

      </div>
      <div class="form-group" [ngClass]="{'has-error': connection.invalid && connection.touched}">
          <label class="font__bold font__title-1" for="connection">Connection</label>
          <input id="connection"  class="form-control" #connection="ngModel" name="connection" required
          [ngModel]="currentItem.connection"/>
          <span *ngIf="connection.invalid && connection.touched" class="form-help-text">Required field</span>

      </div>
      <div class="form-group">
          <label for="connectionType">ConnectionType</label>
          <select class="form-control"
           id="connectionType"
           name="connectionType"
           required
           #connectionType="ngModel" [ngModel]="currentItem.connectionType | appConnectionType: connectionTypeList">
              <option [ngValue]="null">
                Select Connection Type
              </option>
              <option *ngFor="let item of connectionTypeList" [ngValue]="item.name">
                {{item.name}}
              </option>
            </select>
          <span *ngIf="connectionType.invalid && connectionType.touched" class="form-help-text">Required field</span>
      </div>
      <div class="form-group" [ngClass]="{'has-error': connection.invalid && connection.touched}">
        <label class="font__bold font__title-1" for="connection">Permissions</label>
        <div class="form-check" *ngFor="let permission of currentItem.permissions">
          <input class="form-check-input"
          id="{{permission.tenantId}}_{{permission.permissionId}}"
          type="checkbox"
          [ngModel]="permission.isAllowed"
          name="{{permission.name}}"/>
          <label class="form-check-label" for="{{permission.tenantId}}_{{permission.permissionId}}">{{permission.name}}</label>
        </div>
      </div>
      <div class="btn-group">
        <button type="submit" class="btn btn-primary" [disabled]="f.invalid">Save</button>
        <button class="btn btn-danger" (click)="cancel()">Cancel</button>
      </div>
    </form>
</div>

