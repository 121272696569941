import { State, Selector, Action, StateContext } from '@ngxs/store';
import { SetMedicationInformationFormValidAction, SetTotalItemsAndCompletedItems, UpdateMedicationInformationForm, SetupNumberOfMedicines } from './medication-information.actions';
import { Injectable } from '@angular/core';


export class MedicationInformationStateModel {
  medicationInformationForm: {
    model: Partial<IMedicationInformationStateModel>;
  };
  valid: boolean;
  totalItems: number;
  completedItems: number;
}

export interface IMedicationInformationStateModel {
  prescriptionMedicines: number | null;
  nonPrescriptionMedicines: number | null;
  medIndexScore: number | null;
  medicationHealthConditions: {
    cVD: boolean;
    diabetes: boolean;
    osteoporosis: boolean;
    arthritis: boolean;
    pain: boolean;
    mentalHealthIssue: boolean;
    epilepsy: boolean;
    parkinsonsDisease: boolean;
    dementia: boolean;
    respiratoryDisorders: boolean;
    alimentaryTract: boolean;
    other: boolean;
  };
  medicationHealthConditionsOtherDetails: string | null;
  medsCheckServiceReasons: {
    recentSignificantMedicalEvent: boolean;
    identifyPatientProblems: boolean;
    helpPatientLearnAboutMedicines: boolean;
    improveMedicationEffectiveness: boolean;
    educatePatientOnUseAndStorageOfMedicines: boolean;
    medicationsWithHighAdverseEventRisk: boolean;
  };
}
@Injectable()
@State<MedicationInformationStateModel>({
  name: 'medicationInformationForm',
  defaults: {
    medicationInformationForm: {
      model: undefined
    },
    valid: false,
    completedItems: 0,
    totalItems: 0
  }
})
export class MedicationInformationState {
  @Selector()
  static medicationInformationForm(state: MedicationInformationStateModel) { return state.medicationInformationForm.model; }

  @Selector()
  static totalItems(state: MedicationInformationStateModel) { return state.totalItems; }

  @Selector()
  static completedItems(state: MedicationInformationStateModel) { return state.completedItems; }

  @Action(SetMedicationInformationFormValidAction)
  setValid(ctx: StateContext<MedicationInformationStateModel>, action: SetMedicationInformationFormValidAction) {
    ctx.patchState({
      valid: action.valid
    });
  }

  @Action(SetTotalItemsAndCompletedItems)
  setProgress(ctx: StateContext<MedicationInformationStateModel>, action: SetTotalItemsAndCompletedItems) {
    ctx.patchState({
      totalItems: action.totalItems,
      completedItems: action.completedItems
    });
  }

  @Action(UpdateMedicationInformationForm)
  updateForm(ctx: StateContext<MedicationInformationStateModel>, { form }: UpdateMedicationInformationForm) {
    ctx.patchState({
      medicationInformationForm: {
        model: {
          prescriptionMedicines: form.prescriptionMedicines,
          nonPrescriptionMedicines: form.nonPrescriptionMedicines,
          medIndexScore: form.medIndexScore,
          medicationHealthConditions: form.medicationHealthConditions,
          medsCheckServiceReasons: form.medsCheckServiceReasons,
          medicationHealthConditionsOtherDetails: form.medicationHealthConditionsOtherDetails
        }
      }
    });
  }

  @Action(SetupNumberOfMedicines)
  setupNumberOfMedicines(ctx: StateContext<MedicationInformationStateModel>, action: SetupNumberOfMedicines) {
    const prescriptionMedicines  = action.scripts.filter(x => x.schedule != null).length;
    ctx.patchState({
      medicationInformationForm: {
        model: {
          prescriptionMedicines,
          nonPrescriptionMedicines: action.scripts.length - prescriptionMedicines,
        }
      }
    });
  }
}
