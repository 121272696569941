import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SignUpRouteComponent } from './components/sign-up-route/sign-up-route.component';
import { SignUpErrorComponent } from './components/sign-up-error/sign-up-error.component';

import { CreateAccountComponent } from './create-account/components/create-account.component';
import { SignUpResolver } from './resolvers/sign-up.resolver';
import { SignUpSuccessComponent } from './components/sign-up-success/sign-up-success.component';

const routes: Routes = [
  {
    path: 'signup', component: SignUpRouteComponent,
    children: [
      { path: '', component: CreateAccountComponent, resolve: [SignUpResolver] },
      { path: 'error', component: SignUpErrorComponent },
      { path: 'success', component: SignUpSuccessComponent }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class SingUpRoutingModule {
}
