export const InboxFilterControls = {
    Unread: 'unread',
    Flagged: 'flagged',
    UnReplied: 'unreplied',
    Archived: 'archived',
    SMS: 'sms',
    Email: 'email',
    MobileApp: 'app',
    StartDate: 'startdate',
    EndDate: 'enddate',
    Customer: 'customer',
    User: 'user',
    AutomatedSystemComms: 'automatedsystem',
    AutomatedProjectedComms: 'automatedprojected',
    BulkComms: 'bulkcommunications',
    Scheduled: 'scheduled',
    NewCustomerWelcomeMessage: 'newcustomerwelcomemessage',
    Survey: 'survey',
    SurveyResponse: 'surveyresponse',
    Standard: 'standard'
}
