import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardComponent } from './dashboard.component';
import { DesktopComponent } from './../dekstop/desktop.component';
import { DashboardRoutingModule } from './dashboard-routing.module';
import { DashboardPageComponent } from '../../system/dashboard-page/dashboard-page.component';
import { SharedModule } from '../../shared/shared.module';
import { ChartModule } from 'angular-highcharts';
import { NgxPowerBiModule } from 'ngx-powerbi';
import { LoaderModule } from '../core/loader/loader.module';
import { NgxsModule } from '@ngxs/store';
import { PharmacyCommsDetailsState } from '../pharmacy-comms/pharmacy-comms-details/state/pharmacy-comms-details.state';
import { PharmacyCommsDetailsService } from '../pharmacy-comms/pharmacy-comms-details/services/pharmacy-comms-details.service';
import { TriggersState } from '../pharmacy-comms/comms-reminders/state/triggers.state';
import { TriggerService } from '../pharmacy-comms/comms-reminders/services/trigger.service';
import { CommTemplatesState } from '../pharmacy-comms/comm-templates/state/comm-templates.state';
import { CommTemplatesService } from '../pharmacy-comms/comm-templates/services/comm-templates.service';

@NgModule({
  declarations: [DashboardComponent, DashboardPageComponent, DesktopComponent],
  imports: [
    CommonModule,
    DashboardRoutingModule,
    SharedModule,
    CommonModule,
    ChartModule,
    NgxPowerBiModule,
    LoaderModule,
    NgxsModule.forFeature([PharmacyCommsDetailsState, TriggersState, CommTemplatesState]),
  ],
  providers: [
    PharmacyCommsDetailsService,
    TriggerService,
    CommTemplatesService
  ]
})
export class DashboardModule { }
