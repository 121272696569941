import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { HMRPharmacyInformationState } from '../hmr-pharmacy-information/state/hmr-pharmacy-information.state';
import { Select } from '@ngxs/store';

@Component({
  selector: 'app-hmr-pharmacy-information-list-item',
  templateUrl: './hmr-pharmacy-information-list-item.component.html'
})
export class HMRPharmacyInformationListItemComponent implements OnInit {
  @Select(HMRPharmacyInformationState) hmrPharmacyInformationForm$: Observable<any>;

  constructor() { }

  ngOnInit() {
  }

}
