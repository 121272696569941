import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { pairwise, startWith } from 'rxjs/operators';
import { GroupPageState } from '../group-page/state/group-page.state';
import {
  ArchiveSelected,
  FlagSelected,
  GetMonthlySent,
  GetMailboxAll,
  GetMailboxPage,
  GetUnread,
  ReadSelected,
  SearchMailByKeywords,
  BulkToggleSelectedMessages,
  CloseBulkCommRecipientsModal,
} from './state/communications.actions';
import {
  CommunicationsState,
  CommunicationsStateModel,
} from './state/communications.state';
import { FilteredSearch, MailboxMessage } from '~/shared/models/message.model';
import * as moment from 'moment';
import {
  Channels,
  IncomingMessageTypes,
  MailTabs,
  MessageTypes,
  SortOrder,
} from '~/shared/types/communications';
import { InboxQuickModifierDefaults } from './defaults/quick-modifiers.defaults';
import { DefaultInboxChannels } from './defaults/inbox-channels.defaults';
import { DefaultInboxMessageTypes } from './defaults/inbox-message-types.defaults';
import { getDefaultInboxSortOptions } from './defaults/inbox-sort-options.defaults';
import { CommunicationsInboxConstants } from './constants/communications-inbox.constants';
import { DefaultFilterControls } from './defaults/default-filter-controls.defaults';
import { ToggleReplyModal } from './Components/reply-modal/state/chat.actions';
import { PharmacyCommsDetailsState } from '@base/modules/public-api';

@Component({
  selector: 'app-communications-page',
  templateUrl: './communications-page.component.html',
  styleUrls: ['./communications-page.component.scss'],
})
export class CommunicationsPageComponent implements OnInit {
  @Select(CommunicationsState.getState)
  messageBoxState$: Observable<CommunicationsStateModel>;
  @Select(CommunicationsState.selectedInbox) selectedInboxMessages$: Observable<
    MailboxMessage[]
  >;
  @Select(CommunicationsState.selectedSent) selectedSentMessages$: Observable<
    MailboxMessage[]
  >;
  @Select(CommunicationsState.selectedScheduled)
  selectedScheduledMessages$: Observable<MailboxMessage[]>;
  @Select(CommunicationsState.unread) unread$: Observable<number>;
  @Select(CommunicationsState.allSelectedInboxAreRead)
  selectedInboxAreRead$: Observable<boolean>;
  @Select(CommunicationsState.allSelectedInboxAreFlagged)
  selectedInboxAreFlagged$: Observable<boolean>;
  @Select(PharmacyCommsDetailsState.pharmacyDedicatedPhoneNumber)
  dedicatedPhoneNumber$: Observable<string>;

  mailTabs = MailTabs;
  showFilters = false;
  messages = 25;
  messagesPerPage = [25, 50, 100, 200];
  channels = DefaultInboxChannels;
  messageTypes = DefaultInboxMessageTypes;
  sortOrder = SortOrder.mostRecent;
  sortOrders = getDefaultInboxSortOptions(
    CommunicationsInboxConstants.TimeDescending,
    CommunicationsInboxConstants.TimeAscending,
    CommunicationsInboxConstants.ByNameAlphabeticalAscending,
    CommunicationsInboxConstants.ByNameAlphabeticalDescending
  );
  tab = MailTabs.Inbox;
  searchForm: FormGroup;
  patientGroups = [{ value: 'allgroups', name: 'all', id: -1 }];
  quickModifiers = InboxQuickModifierDefaults;
  lastRefresh = Date.now();
  timeSinceLastRefresh = '';
  currentPage = 0;
  displayedFromRow = 0;
  displayedToRow = this.messages;
  navigationFromPage = 0;
  navigationToPage = 2;
  navigationPageRange = 2;
  defaultForTab = null; //used to track changes to filters after reset
  changesSubscription: Subscription[] = [];
  filterCount = 0;
  defaultVisibleFilters = 3;
  isFirstFilterChange = true;
  incomingMessageTypes = [{label: 'All', value: 'All'}];

  constructor(
    private store: Store,
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.initMessageForm();
  }

  private initMessageForm() {
    this.route.queryParams.subscribe((params) => {
      this.tab = params['tab'] || this.tab;
    });

    this.searchForm = this.fb.group({
      unread: new FormControl(true),
      flagged: new FormControl(false),
      unreplied: new FormControl(false),
      archived: new FormControl(false),
      keywords: new FormControl(''),
      messages: new FormControl(this.messages),
      sortOrder: new FormControl(this.sortOrder),
      channelsall: new FormControl(true),
      sms: new FormControl(true),
      email: new FormControl(true),
      app: new FormControl(true),
      messagetypesall: new FormControl(true),
      customer: new FormControl(true),
      user: new FormControl(true),
      automatedsystem: new FormControl(true),
      automatedprojected: new FormControl(true),
      bulkcommunications: new FormControl(true),
      recurringcomm: new FormControl(true),
      scheduled: new FormControl(true),
      allgroups: new FormControl(true),
      startdate: new FormControl(null),
      enddate: new FormControl(null),
      newcustomerwelcomemessage: new FormControl(true),
      surveyresponse: new FormControl(true),
      survey: new FormControl(true),
      standard: new FormControl(true)
    });

    this.resetFilters();
    this.setPatientGroups();
    this.setupIncomingMessageTypesFormGroup();

    this.searchForm.valueChanges.subscribe((x) => {
      if (!this.isFirstFilterChange) {
        this.getFilterCount(x, x);
      }
    });

    this.enforceOneCheckboxNeededAndMasterCheckboxBehaviour(
      this.messageTypes,
      'messagetypesall'
    );
    this.enforceOneCheckboxNeededAndMasterCheckboxBehaviour(
      this.channels,
      'channelsall'
    );
    this.enforceOneCheckboxNeededAndMasterCheckboxBehaviour(
      this.patientGroups,
      'allgroups'
    );
    this.enforceOneCheckboxNeededAndMasterCheckboxBehaviour(
      this.quickModifiers,
      null
    );
    this.enforceOneCheckboxNeededAndMasterCheckboxBehaviour(
      this.incomingMessageTypes,
      'All'
    );
    this.messages = this.searchForm.controls['messages'].value;

    switch (this.tab) {
      case MailTabs.Inbox:
      case MailTabs.Sent:
        this.getMailboxPage(this.tab);
        break;
      case MailTabs.Scheduled:
        this.getMailboxAll(this.tab);
        break;
    }

    this.store.dispatch(GetMonthlySent);
  }

  getPageCount(total) {
    var amnt = Math.ceil(total / this.messages);
    var newArray = [];
    for (var i = 0; i < amnt; i++) {
      newArray.push(i);
    }
    return newArray;
  }

  resetTimer() {
    this.lastRefresh = Date.now();
    this.resetTimerText();
  }

  resetTimerText() {
    var timeSince = moment(Date.now()).subtract(this.lastRefresh);
    this.timeSinceLastRefresh = `Checked ${timeSince
      .toDate()
      .getMinutes()
      .toString()}m ago`;
  }

  setPatientGroups() {
    const groups = this.store.selectSnapshot(GroupPageState.groups);
    groups.forEach((g) => {
      const formControlName = 'group' + g.id;
      this.searchForm.addControl(formControlName, new FormControl(true));
      this.patientGroups.push({
        value: formControlName,
        name: g.name,
        id: g.id,
      });
    });
  }

  setupIncomingMessageTypesFormGroup() {
    const messageTypes = IncomingMessageTypes;

    const formatLabel = (val: string) => {
      return val.replace(/([a-z])([A-Z])/g, '$1 $2');
    }

    const types = messageTypes.map((type) => {
      return {
        label: formatLabel(type),
        value: type
      }
    })
    this.incomingMessageTypes = this.incomingMessageTypes.concat(types);
    this.incomingMessageTypes.forEach(({value}) => {
      this.searchForm.addControl(value, new FormControl(true));
    })
  }

  messageTypesProvidedForTab() {
    var typesProvided = false;
    this.messageTypes.forEach((messageType) => {
      if (messageType.visible.includes(this.tab)) {
        typesProvided = true;
      }
    });
    return typesProvided;
  }

  clearKeywordSearch() {
    this.searchForm.controls['keywords'].setValue('');
  }

  resetDates() {
    this.searchForm.controls['startdate'].setValue(null);
    this.searchForm.controls['enddate'].setValue(null);
  }

  closeRecipientsModal() {
    this.store.dispatch(new CloseBulkCommRecipientsModal());
  }

  enforceOneCheckboxNeededAndMasterCheckboxBehaviour(controls, masterControl) {
    const controlsNotMaster = controls.filter(
      ({ value }) => value !== masterControl
    );

    const controlsNotMasterVisible = controlsNotMaster.filter(
      (control) =>
        control.visible === undefined || control.visible.includes(this.tab)
    );

    this.changesSubscription.push(
      this.searchForm.valueChanges
        .pipe(startWith(this.searchForm.value), pairwise())
        .subscribe(([prev, next]: [any, any]) => {
          if (this.isFirstFilterChange) {
            this.getFilterCount(prev, next);
            this.isFirstFilterChange = false;
          }

          const prevControlsInGroup = controlsNotMasterVisible.map(
            ({ value }) => ({ name: value, checked: prev[value] })
          );
          const nextControlsInGroup = controlsNotMasterVisible.map(
            ({ value }) => ({ name: value, checked: next[value] })
          );

          const prevControlsInGroupChecked = prevControlsInGroup.map(
            ({ checked }) => checked
          );
          // when no controls are true
          const nextControlsInGroupChecked = nextControlsInGroup.map(
            ({ checked }) => checked
          );

          if (masterControl) {
            const allGroupsPrev = prev[masterControl];
            const allGroupsNext = next[masterControl];
            const allNextChecked = nextControlsInGroupChecked.every(
              (checked) => checked
            );

            if (
              !allGroupsPrev &&
              allGroupsNext &&
              nextControlsInGroupChecked.some((checked) => !checked)
            ) {
              const batchUpdate = nextControlsInGroup
                .filter(({ checked }) => !checked)
                .reduce((prev, { name }) => {
                  return { ...prev, [name]: true };
                }, {});
              this.searchForm.patchValue(batchUpdate);
            }

            if (!allGroupsPrev && !allGroupsNext && allNextChecked) {
              this.searchForm.controls[masterControl].setValue(true);
            }

            if (
              allGroupsPrev &&
              allGroupsNext &&
              nextControlsInGroupChecked.some((checked) => checked) &&
              !allNextChecked
            ) {
              this.searchForm.controls[masterControl].setValue(false);
            }

            if (
              allGroupsPrev &&
              !allGroupsNext &&
              prevControlsInGroupChecked.every((checked) => checked) &&
              nextControlsInGroupChecked.every((checked) => checked)
            ) {
              const batchUpdate = nextControlsInGroup.reduce(
                (prev, { name }) => {
                  return { ...prev, [name]: false };
                },
                {}
              );
              this.searchForm.patchValue(batchUpdate);
            }
          }
        })
    );
  }

  patientGroupsBtnText() {
    const numberOfTrueCheckboxes = this.patientGroups
      .map(({ value }) => this.searchForm.controls[value].value)
      .filter((searchControl) => !!searchControl).length;

    if (numberOfTrueCheckboxes < this.patientGroups.length) {
      return 'Patient groups (' + numberOfTrueCheckboxes + ')';
    }

    if (numberOfTrueCheckboxes === this.patientGroups.length) {
      return 'All patient groups';
    }
  }

  messageTypesBtnText() {
    const validForThisPageMessageTypes = this.messageTypes.filter((m) =>
      m.visible.includes(this.tab)
    );

    const numberOfTrueCheckboxes = validForThisPageMessageTypes
      .map(({ value }) => this.searchForm.controls[value].value)
      .filter((x) => !!x).length;

    if (numberOfTrueCheckboxes < validForThisPageMessageTypes.length) {
      return 'Message types (' + numberOfTrueCheckboxes + ')';
    }

    if (numberOfTrueCheckboxes === validForThisPageMessageTypes.length) {
      return 'All message types';
    }

    return 'Message types';
  }

  channelsBtnText() {
    const validForThisPageChannels = this.channels;

    const numberOfTrueCheckboxes = validForThisPageChannels
      .map(({ value }) => this.searchForm.controls[value].value)
      .filter((x) => !!x).length;

    if (numberOfTrueCheckboxes < validForThisPageChannels.length) {
      return 'Channels (' + numberOfTrueCheckboxes + ')';
    }
    if (numberOfTrueCheckboxes === validForThisPageChannels.length) {
      return 'All channels';
    }

    return 'Channels';
  }

  onFilterButtonClicked(controlName) {
    this.searchForm.controls[controlName].setValue(
      !this.searchForm.controls[controlName].value
    );
  }

  // add reset filters to defaults
  resetFilters() {
    this.resetMessageTypes();
    this.resetPatientGroups();
    this.resetChannels();
    this.resetDates();
    this.resetQuickModifiers();
    this.searchForm.controls['keywords'].setValue('');
    this.defaultForTab = null;
    this.filterCount = 0;
    this.isFirstFilterChange = true;
    if (this.changesSubscription && this.changesSubscription.length > 0) {
      this.changesSubscription.forEach((x) => x.unsubscribe());
    }
  }

  resetQuickModifiers() {
    // default to false
    this.quickModifiers.forEach((pg) => {
      this.searchForm.controls[pg.value].setValue(false, { emitEvent: false });
    });

    // update based on defaults
    this.quickModifiers.forEach((qm) => {
      qm.defaults
        .filter((qmDefault) => qmDefault.tab === this.tab)
        .forEach((qmDefault) =>
          this.searchForm.controls[qm.value].setValue(qmDefault.default, {
            emitEvent: false,
          })
        );
    });
  }

  resetMessageTypes() {
    // default to false
    this.messageTypes.forEach((mt) => {
      this.searchForm.controls[mt.value].setValue(false, { emitEvent: false });
    });
    //update based on defaults
    this.messageTypes.forEach((mt) => {
      mt.defaults
        .filter((mtDefault) => mtDefault.tab === this.tab)
        .forEach((mtDefault) =>
          this.searchForm.controls[mt.value].setValue(mtDefault.default, {
            emitEvent: false,
          })
        );
    });
  }

  resetPatientGroups() {
    this.patientGroups.forEach((pg) => {
      this.searchForm.controls[pg.value].setValue(true, { emitEvent: false });
    });
  }

  resetChannels() {
    this.channels.forEach((c) => {
      this.searchForm.controls[c.value].setValue(true, { emitEvent: false });
    });
  }

  getFilterCount(updatedListValues, updatedValues) {
    const filterControls = DefaultFilterControls;

    this.patientGroups
      .filter((patientGroup) => patientGroup.value !== 'allgroups')
      .forEach((patientGroup) => {
        filterControls.push(patientGroup.value);
      });

    if (!this.defaultForTab) {
      const objects = [];
      filterControls.forEach((cont) => {
        objects.push({ name: cont, value: updatedListValues[cont] });
      });
      this.defaultForTab = objects;
    }

    this.filterCount = 0;

    var channelFilterActive = false;
    DefaultInboxChannels.forEach((channel) => {
      var defaultValue = this.defaultForTab.filter(
        (tab) => tab.name.toLowerCase() === channel.value.toString()
      );
      var actualValue = updatedListValues[channel.value.toString()];

      if (
        actualValue !== null &&
        defaultValue !== null &&
        defaultValue.length > 0 &&
        actualValue !== defaultValue[0].value
      ) {
        channelFilterActive = true;
      }
    });

    var typeFilterActive = false;
    DefaultInboxMessageTypes.forEach((type) => {
      var defaultValue = this.defaultForTab.filter(
        (tab) => tab.name.toLowerCase() === type.value.toString()
      );
      var actualValue = updatedListValues[type.value.toString()];

      if (
        actualValue !== null &&
        defaultValue !== null &&
        defaultValue.length > 0 &&
        actualValue !== defaultValue[0].value
      ) {
        typeFilterActive = true;
      }
    });

    var patientGroupFilterActive = false;
    this.patientGroups.forEach((patientGroup) => {
      var defaultValue = this.defaultForTab.filter(
        (tab) => tab.name.toLowerCase() === patientGroup.value.toString()
      );
      var actualValue = updatedListValues[patientGroup.value.toString()];

      if (
        actualValue !== null &&
        defaultValue !== null &&
        defaultValue.length > 0 &&
        actualValue !== defaultValue[0].value
      ) {
        patientGroupFilterActive = true;
      }
    });

    if (channelFilterActive) {
      this.filterCount++;
    }
    if (typeFilterActive) {
      this.filterCount++;
    }
    if (patientGroupFilterActive) {
      this.filterCount++;
    }

    const isSurveyResponseFilterActive = !updatedListValues['All'] && (updatedListValues['Standard'] || updatedListValues['SurveyResponse']);
    if(isSurveyResponseFilterActive) {
      this.filterCount++;
    }


    this.filterCount += this.defaultForTab
      .filter(
        (tabDefault) =>
          !this.patientGroups
            .map((pg) => pg.value.toString())
            .includes(tabDefault.name.toLowerCase()) &&
          !DefaultInboxChannels.map((channel) =>
            channel.value.toString()
          ).includes(tabDefault.name.toLowerCase()) &&
          !DefaultInboxMessageTypes.map((type) =>
            type.value.toString()
          ).includes(tabDefault.name.toLowerCase())
      )
      .filter(
        (tabDefault) => updatedValues[tabDefault.name] !== tabDefault.value
      ).length;
  }

  getDisplayedToRowValue(total) {
    var actualToRowValue = (this.currentPage + 1) * this.messages;
    if (actualToRowValue > total) {
      return total;
    } else {
      return actualToRowValue;
    }
  }

  getChannelName(channelId) {
    return this.channels[channelId].name;
  }

  getChannelIcon(channelId) {
    return this.channels[channelId].icon;
  }

  search() {
    if (this.tab !== MailTabs.Scheduled) {
      return this.filterList();
    }

    //Scheduled logic
    this.currentPage = 0;
    this.displayedFromRow = 0;
    this.displayedToRow = this.messages;
    this.store.dispatch(
      new SearchMailByKeywords(this.getSearchModel(), this.tab)
    );
  }

  filterList() {
    this.currentPage = 0;
    if (this.filterCount > 0 || this.tab === MailTabs.Scheduled) {
      this.getMailboxAll(this.tab);
    } else {
      this.getMailboxPage(this.tab);
    }
    this.displayedFromRow = this.currentPage * this.messages;
    this.displayedToRow = this.displayedFromRow + this.messages;
  }

  getSearchModel() {
    const sortOrder = this.sortOrders.find(s => s.value === this.sortOrder);
    const sortOrderString = sortOrder?.searchString ?? null;

    var startDate = '';
    var endDate = null;

    if (this.searchForm.controls['startdate'].value !== null) {
      const startDatePicker = this.searchForm.controls['startdate']
        .value as Date;
      startDatePicker.setHours(0, 0, 0, 0);
      startDate = startDatePicker.toISOString();
    }

    if (this.searchForm.controls['enddate'].value !== null) {
      const endDatePicker = this.searchForm.controls['enddate'].value as Date;
      endDatePicker.setHours(0, 0, 0, 0);
      endDate = endDatePicker.toISOString();
    }

    const selectedMessageTypes = this.getSelectedMessageTypes() ?? this.getSelectedIncomingMessageType();

    return new FilteredSearch(
      this.currentPage,
      this.messages,
      sortOrderString,
      startDate,
      endDate,
      this.getKeywords(),
      this.getSelectedChannels(),
      selectedMessageTypes,
      this.getSelectedPatientGroups(),
      this.getSelectedModifiers()
    );
  }

  getKeywords() {
    var keywords = this.searchForm.controls['keywords'].value;
    if (keywords === '') {
      keywords = null;
    }
    return keywords;
  }

  getSelectedMessageTypes() {
    var types = null;
    var allType = this.messageTypes.filter(
      (type) => type.value === MessageTypes.All
    )[0];
    if (
      allType.defaults.filter(
        (typeDefault) =>
          typeDefault.tab === this.tab &&
          typeDefault.default === this.searchForm.controls[allType.value].value
      ).length > 0
    ) {
      return null;
    }

    this.messageTypes.forEach((messageType) => {
      if (this.searchForm.controls[messageType.value].value) {
        if (types === null) {
          types = [];
        }
        if (this.messageTypes.indexOf(messageType) > 0) {
          types.push(messageType.value.toLowerCase());
        }
      }
    });
    return types;
  }

  getSelectedChannels() {
    var channels = null;
    var allChannel = this.channels.filter(
      (channel) => channel.value === Channels.All
    )[0];
    if (
      allChannel.defaults.filter(
        (channelDefault) =>
          channelDefault.tab === this.tab &&
          channelDefault.default ===
            this.searchForm.controls[allChannel.value].value
      ).length > 0
    ) {
      return null;
    }

    this.channels.forEach((channel) => {
      if (this.searchForm.controls[channel.value].value) {
        if (channels === null) {
          channels = [];
        }
        if (this.channels.indexOf(channel) > 0) {
          channels.push(channel.name.toLowerCase());
        }
      }
    });
    return channels;
  }

  getSelectedPatientGroups() {
    var groups = null;
    if (
      this.searchForm.controls[
        this.patientGroups.filter(
          (patientGroup) => patientGroup.name === 'all'
        )[0].value
      ].value
    ) {
      return null;
    }

    this.patientGroups.forEach((patientGroup) => {
      if (this.searchForm.controls[patientGroup.value].value) {
        if (groups === null) {
          groups = [];
        }
        if (this.patientGroups.indexOf(patientGroup) > 0) {
          groups.push(patientGroup.id);
        }
      }
    });
    return groups;
  }

  getSelectedModifiers() {
    var filters = null;
    this.quickModifiers.forEach((modifier) => {
      if (this.searchForm.controls[modifier.value].value) {
        if (filters === null) {
          filters = [];
        }
        filters.push(modifier.name.toLowerCase());
      }
    });
    return filters;
  }

  getSelectedIncomingMessageType() {
    if(this.tab !== MailTabs.Inbox) {
      return null;
    }

    const selectedTypes = this.incomingMessageTypes
      .filter(({ value }) => this.searchForm.controls[value].value)
      .map(({value}) => value)

    if(selectedTypes.includes('All')) {
      return selectedTypes.filter(type => type !== 'All');
    }
    return selectedTypes;
  }

  getMailboxPage(mailType) {
    this.displayedFromRow = 0;
    this.displayedToRow = this.messages;
    this.store.dispatch([
      new GetMailboxPage(this.getSearchModel(), mailType),
      GetUnread,
    ]);
    this.resetTimer();
  }

  getMailboxAll(mailType) {
    this.displayedFromRow = 0;
    this.displayedToRow = this.messages;
    this.store.dispatch(new GetMailboxAll(this.getSearchModel(), mailType));
    this.resetTimer();
  }

  markSelectedAsUnread() {
    this.store.dispatch(new ReadSelected(this.tab, false));
  }

  markSelectedAsRead() {
    this.store.dispatch(new ReadSelected(this.tab, true));
  }

  markSelectedAsFlagged() {
    this.store.dispatch(new FlagSelected(this.tab, true));
  }

  markSelectedAsNotFlagged() {
    this.store.dispatch(new FlagSelected(this.tab, false));
  }

  markSelectedArchived() {
    this.store.dispatch(new ArchiveSelected(this.tab, true));
  }

  showNextPage() {
    this.goToPage(this.currentPage + 1);
  }

  showPrevPage() {
    this.goToPage(this.currentPage - 1);
  }

  goToPage(pageNumber) {
    if (pageNumber === null || pageNumber < 0) {
      return;
    }

    var totalPages = 0;
    var mailboxState = this.store.selectSnapshot(CommunicationsState.getState);

    switch (this.tab) {
      case MailTabs.Inbox:
        totalPages = this.getPageCount(
          mailboxState.inboxState?.total ?? 0
        ).length;
        if (pageNumber >= totalPages && mailboxState.inboxState?.total > 0) {
          return;
        }
        break;
      case MailTabs.Sent:
        totalPages = this.getPageCount(
          mailboxState.sentState?.total ?? 0
        ).length;
        if (pageNumber >= totalPages && mailboxState.sentState?.total > 0) {
          return;
        }
        break;
      case MailTabs.Scheduled:
        totalPages = this.getPageCount(
          mailboxState.filteredScheduledMessages?.length ?? 0
        ).length;
        if (
          pageNumber >= totalPages &&
          mailboxState.filteredScheduledMessages?.length > 0
        ) {
          return;
        }
        break;
    }

    this.currentPage = pageNumber;

    this.navigationFromPage = this.currentPage - this.navigationPageRange;
    this.navigationToPage = this.currentPage + this.navigationPageRange;

    if (this.navigationFromPage < 0) {
      this.navigationFromPage = 0;
    }
    if (this.navigationToPage > totalPages) {
      this.navigationToPage = totalPages;
    }

    if (this.filterCount > 0 || this.tab === MailTabs.Scheduled) {
      this.displayedFromRow = this.currentPage * this.messages;
      this.displayedToRow = this.displayedFromRow + this.messages;

      switch (this.tab) {
        case MailTabs.Inbox:
          if (this.displayedToRow > mailboxState.inboxState.total) {
            this.displayedToRow = mailboxState.inboxState.total;
          }
          break;
        case MailTabs.Sent:
          if (this.displayedToRow > mailboxState.sentState.total) {
            this.displayedToRow = mailboxState.sentState.total;
          }
          break;
        case MailTabs.Scheduled:
          if (
            this.displayedToRow > mailboxState.filteredScheduledMessages.length
          ) {
            this.displayedToRow = mailboxState.filteredScheduledMessages.length;
          }
          break;
      }
    } else {
      this.getMailboxPage(this.tab);
      this.displayedFromRow = 0;
      this.displayedToRow = this.messages;
    }
  }

  toggleFilters() {
    this.showFilters = !this.showFilters;
  }

  isTabVisible(tabName) {
    return this.tab === tabName;
  }

  messageTypesDisplayed() {
    var isVisible = false;
    this.messageTypes.forEach((type) => {
      if (type.visible.includes(this.tab)) {
        isVisible = true;
      }
    });
    return isVisible;
  }

  controlVisible(visibleTabs: MailTabs[]) {
    return visibleTabs.includes(this.tab);
  }

  visibleControls(controls) {
    return controls.filter((c) => this.controlVisible(c.visible));
  }

  onMessagesDisplayedChanged() {
    this.messages = this.searchForm.controls['messages'].value;
    this.filterList();
  }

  onSortOrderChanged() {
    this.sortOrder = this.searchForm.controls['sortOrder'].value;
    this.filterList();
  }

  selectAllDisplayedRows() {
    this.store.dispatch(
      new BulkToggleSelectedMessages(
        this.displayedFromRow,
        this.displayedToRow,
        true,
        this.tab
      )
    );
  }

  deselectAllDisplayedRows() {
    this.store.dispatch(
      new BulkToggleSelectedMessages(
        this.displayedFromRow,
        this.displayedToRow,
        false,
        this.tab
      )
    );
  }

  inboxTabClass(tabname) {
    return {
      'd-flex justify-content-between align-items-center': true,
      'active-tab': this.tab === tabname,
      'inactive-tab': this.tab !== tabname,
    };
  }

  openTab(tabname) {
    this.store.dispatch(new ToggleReplyModal(false));
    this.tab = tabname;
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { tab: tabname },
      queryParamsHandling: 'merge', // remove to replace all query params by provided
    });
    this.resetFilters();
    this.enforceOneCheckboxNeededAndMasterCheckboxBehaviour(
      this.messageTypes,
      'messagetypesall'
    );
    this.enforceOneCheckboxNeededAndMasterCheckboxBehaviour(
      this.channels,
      'channelsall'
    );
    this.enforceOneCheckboxNeededAndMasterCheckboxBehaviour(
      this.patientGroups,
      'allgroups'
    );
    this.enforceOneCheckboxNeededAndMasterCheckboxBehaviour(
      this.quickModifiers,
      null
    );
    this.enforceOneCheckboxNeededAndMasterCheckboxBehaviour(
      this.incomingMessageTypes,
      'All'
    )
    this.filterList();
  }
}
