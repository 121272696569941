<div class="modal" style="overflow: scroll; min-width: 800;">
  <div class="modal-body border__grey">
    <h2><strong>Mobile status</strong></h2>
    <ng-container *ngFor="let state of [state$ | async]">
      <div [ngSwitch]="state.state.mobileState">
        <div *ngSwitchCase="mobileState.Inactive">
          <div>
            <p>Currently no Scrypt Mobile account active.</p>
            <ng-container *ngIf="state.state.mobileInviteDate && !isInFuture(state.state.mobileInviteDate)">
              Invitation link expired on {{state.state.mobileInviteDate | tenantDate: 'DD MMMM YYYY hh:mmA'}}.
            </ng-container>
            <span *ngIf="state.state.inviteEmail && state.state.inviteEmail.length > 0"><br/>Recipient email: {{state.state.inviteEmail}}</span>
           <span *ngIf="state.state.inviteMobileNumber && state.state.inviteMobileNumber.length > 0"><br />Recipient mobile: {{state.state.inviteMobileNumber}}</span>
          </div>
          <div class="buttons">
            <button [disabled]="!(featureDecisions.isInviteToMobileAllowed() | async)" class="button btn__green" (click)="invite()">Invite to Scrypt Mobile</button>
          </div>
        </div>
        <div *ngSwitchCase="mobileState.Pending">
          <p>
            Invite to Scrypt Mobile pending.<br />
            <ng-container *ngIf="isInFuture(state.state.mobileInviteDate)">
              Invitation sent, user has until {{state.state.mobileInviteDate | tenantDate: 'DD MMMM YYYY hh:mmA'}} to complete sign up.
            </ng-container>
            <ng-container *ngIf="state.state.mobileInviteDate && !isInFuture(state.state.mobileInviteDate)">
              Invitation link expired on {{state.state.mobileInviteDate | tenantDate: 'DD MMMM YYYY hh:mmA'}}.
            </ng-container>
            <span *ngIf="state.state.inviteEmail && state.state.inviteEmail.length > 0"><br/>Recipient email: {{state.state.inviteEmail}}</span>
            <span *ngIf="state.state.inviteMobileNumber && state.state.inviteMobileNumber.length > 0"><br />Recipient mobile: {{state.state.inviteMobileNumber}}</span>
          </p>
          <app-loader *ngIf="state.deleting" loadingMessage="Canceling invitations..."></app-loader>
          <div class="buttons">
            <button class="button btn__green mr-2" [disabled]="state.deleting" (click)="invite()">Re-send invitation</button>
            <button class="button btn__yellow" [disabled]="state.deleting" (click)="cancelInvite()">Cancel invitation</button>
          </div>
        </div>
        <div *ngSwitchCase="mobileState.Active">
          <p>
            Customer has an active Scrypt Mobile Account.<br />
            Activated {{state.state.mobileSignupDate | tenantDate: 'DD MMMM YYYY hh:mmA'}}
          </p>
          <ng-container *ngIf="!deleteAccountPrompt">
            <button class="button btn__yellow" (click)="deleteAccountPrompt = true">Delete Account</button>
          </ng-container>
          <ng-container *ngIf="deleteAccountPrompt">
            <div>
              <br/>
              <h4>
                Are you sure you want to delete this customer's Scrypt Mobile account?
              </h4>
              <ul>
                <li>Deleting will prevent them from using existing credentials to log into their account
                  May remove patient visibility to some orders, messages or even stored eScript tokens (token’s will still be valid if saved elsewhere).</li>
              </ul>
              <i>
                You will still be able to re-invite them to Scrypt Mobile afterwards if required.
              </i>
            </div>
            <app-loader *ngIf="state.deleting" loadingMessage="deleting user..."></app-loader>
            
            <div class="buttons">
              <button [disabled]="state.deleting" class="button btn__yellow mr-2" (click)="deleteAccount()">Yes, delete account</button>
              <button [disabled]="state.deleting" class="button btn__yellow" (click)="deleteAccountPrompt = false">Cancel</button>
            </div>
          </ng-container>
        </div>
      </div>
    </ng-container>
    <div g-xs="mt2">
      <button class="button btn__yellow" g-xs="mr1" (click)="closeModal()">Close</button>
    </div>
  </div>

</div>
<div class="modal-background"></div>
