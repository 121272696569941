import { State, Action, StateContext, Selector } from '@ngxs/store';
import {
  ResetSickLeaveFormList,
  SaveSickLeaveFormSuccessAction,
  SaveSickLeaveFormFailedAction,
  SubmitNewSickLeave,
  SubmitExisitingSickLeave,
  SaveNewSickLeave,
  SaveExisitingSickLeave,
  GetSickLeaveForm,
  GetSickLeaveFormSuccess,
  GetSickLeaveFormError,
  UpdateSickLeaveForm,
  ShowInvalidSickLeaveForm,
  GenerateSickLeaveForm
} from './sick-leave.actions';
import { SickLeaveService } from '../services/sick-leave.service';
import { switchMap, catchError, finalize } from 'rxjs/operators';
import { ToggleSixCpaContentAction, ToggleSixCpaAction, SetSelectedFormType, CloseResetFormModal, SetSixCpaFormSummary } from '../../../state/six-cpa.actions';
import { ToggleSickLeaveConfirmAction } from '../../sick-leave-confirm/state/sick-leave-confirm.actions';
import { UpdateSickLeaveCertificateType } from '../../sick-leave-certificate-type/state/sick-leave-certificate-type.actions';
import { UpdateSickLeaveConsentAndEligibilityForm } from '../../sick-leave-consent-and-eligibility/state/sick-leave-consent-and-eligibility.actions';
import { UpdateSickLeavePatientDetails } from '../../sick-leave-patient-details/state/sick-leave-patient-details.actions';
import { UpdateSickLeaveConsulation } from '../../sick-leave-consulation/state/sick-leave-consulation.actions';
import { UpdateSickLeavePharmacistDeclaration } from '../../sick-leave-pharmacist-declaration/state/sick-leave-pharmacist-declaration.actions';
import { Injectable } from '@angular/core';
import { SaveOrUpdateSickLeaveAction } from './sick-leave-save.actions';
import { SixCpaResponse } from '../../../../profile-services/state/profile-services.state';
import { ClearSixCpaCommunications, SetSixCpaErrors, SetSixCpaInfo, SetSixCpaSuccessMessages } from '../../../six-cpa-user-communications/state/six-cpa-user-communications.actions';
import { AlertService } from '../../../../../core/alert/alert.service';
import { closeConfirmationActions } from '../../../state/six-cpa-toggles-list';
import { GetSixCpaFormsFromService } from '../../../../profile-services/state/profile-services.actions';
import { SixCpaFormTypes } from '../../../six-cpa-form-types.enum';

export class SickLeaveStateModel {
  sickLeaveId: number;
  sixCpaFormId: number;
  patientDetailsFormId: number;
  consentAndEligibilityFormId: number;
  certificateTypeFormId: number;
  consulationFormId: number;
  pharmacistDeclarationFormId: number;
  showInvalid: boolean;
  saving: boolean;
  error: any;
  showConfirmForm: boolean;
}
@Injectable()
@State<SickLeaveStateModel>({
  name: 'sickLeave',
  defaults: {
    sickLeaveId: null,
    sixCpaFormId: null,
    patientDetailsFormId: null,
    consentAndEligibilityFormId: null,
    certificateTypeFormId: null,
    consulationFormId: null,
    pharmacistDeclarationFormId: null,
    showInvalid: false,
    saving: false,
    error: null,
    showConfirmForm: false
  }
})
export class SickLeaveState {
  constructor(
    private sickLeaveService: SickLeaveService,
     private alertService: AlertService) { }

  @Selector()
  static saving(state: SickLeaveStateModel) { return state.saving; }

  @Selector()
  static sixCpaFormId(state: SickLeaveStateModel) { return state.sixCpaFormId; }

  @Selector()
  static showConfirmForm(state: SickLeaveStateModel) { return state.showConfirmForm; }

  @Selector()
  static showInvalid(state: SickLeaveStateModel) { return state.showInvalid; }

  @Action(ResetSickLeaveFormList)
  resetSickLeaveForm(ctx: StateContext<SickLeaveStateModel>, {onlyClearFormIds}: ResetSickLeaveFormList) {
    ctx.patchState({
      sickLeaveId: null,
      sixCpaFormId: null,
      patientDetailsFormId: null,
      consentAndEligibilityFormId: null,
      certificateTypeFormId: null,
      consulationFormId: null,
      pharmacistDeclarationFormId: null,
    });
    if (!onlyClearFormIds) {
      ctx.dispatch([
        new UpdateSickLeaveConsulation(null),
        new UpdateSickLeavePatientDetails(null),
        new UpdateSickLeaveConsentAndEligibilityForm(null),
        new UpdateSickLeaveCertificateType(null),
        new UpdateSickLeavePharmacistDeclaration(null),
      ]);
    }
    ctx.dispatch([
      new CloseResetFormModal(),
      new ClearSixCpaCommunications()
    ]);
  }

  @Action(SaveSickLeaveFormSuccessAction)
  saveSickLeaveSuccess(ctx: StateContext<SickLeaveStateModel>, { formData }: SaveSickLeaveFormSuccessAction) {
    const { id, sixCpaFormId, consentAndEligibilityFormId, certificateTypeFormId,
       consulationFormId, pharmacistDeclarationFormId, hasSubmitted } = formData;
    ctx.patchState({
      sickLeaveId: id,
      sixCpaFormId,
      consentAndEligibilityFormId,
      certificateTypeFormId,
      consulationFormId,
      pharmacistDeclarationFormId
    });
    ctx.dispatch([
      new SetSixCpaSuccessMessages(['Sick Leave draft form Saved to Scrypt']),
      new SetSixCpaFormSummary({
        id: sixCpaFormId,
        formType: SixCpaFormTypes.SickLeaveCertificate,
        hasSubmitted: hasSubmitted
      } as SixCpaResponse)
    ]);
    if (hasSubmitted) {
      ctx.dispatch([new GenerateSickLeaveForm()]);
    }
    ctx.dispatch(new GetSixCpaFormsFromService(null));
  }

  @Action(SaveSickLeaveFormFailedAction)
  saveSickLeaveFailed(ctx: StateContext<SickLeaveStateModel>, { error }: SaveSickLeaveFormFailedAction) {
    ctx.patchState({
      error: error
    });
    this.alertService.error(error);
    ctx.dispatch(new SetSixCpaErrors([error]));
  }

  @Action(SubmitNewSickLeave)
  submitNewSickLeave(ctx: StateContext<SickLeaveStateModel>, { clientId, formData }: SubmitNewSickLeave) {
    ctx.patchState({ saving: true, showConfirmForm: false });
    return this.sickLeaveService.postSubmit(clientId, formData).pipe(
      switchMap(response => ctx.dispatch([new SaveSickLeaveFormSuccessAction(response)])),
      catchError(error => ctx.dispatch([new SaveSickLeaveFormFailedAction(error), new SetSixCpaInfo(["We were unable to submit the claim so we've saved it as a draft."]), new SaveOrUpdateSickLeaveAction()])),
      finalize(() => {
        ctx.patchState({ saving: false });
      }));
  }

  @Action(SubmitExisitingSickLeave)
  submitExisitingSickLeave(ctx: StateContext<SickLeaveStateModel>, { clientId, formData }: SubmitExisitingSickLeave) {
    ctx.patchState({ saving: true, showConfirmForm: false });
    return this.sickLeaveService.putSubmit(clientId, formData).pipe(
      switchMap(response => ctx.dispatch([new SaveSickLeaveFormSuccessAction(response)])),
      catchError(error => ctx.dispatch([new SaveSickLeaveFormFailedAction(error), new SetSixCpaInfo(["We were unable to submit the claim so we've saved it as a draft."]), new SaveOrUpdateSickLeaveAction()])),
      finalize(() => {
        ctx.patchState({ saving: false });
      }));
  }

  @Action(GenerateSickLeaveForm)
  generate(ctx: StateContext<SickLeaveStateModel>, action: GenerateSickLeaveForm) {
    ctx.dispatch([
      new ToggleSixCpaContentAction('out'), 
      new ToggleSickLeaveConfirmAction('in')
    ]);
  }

  @Action(SaveNewSickLeave)
  saveNewSickLeave(ctx: StateContext<SickLeaveStateModel>, { clientId, formData }: SaveNewSickLeave) {
    ctx.patchState({ saving: true });
    return this.sickLeaveService.postSave(clientId, formData).pipe(
      switchMap(response => ctx.dispatch(new SaveSickLeaveFormSuccessAction(response))),
      catchError(error => ctx.dispatch(new SaveSickLeaveFormFailedAction(error))),
      finalize(() => ctx.patchState({ saving: false })));
  }

  @Action(SaveExisitingSickLeave)
  saveExisitingSickLeave(ctx: StateContext<SickLeaveStateModel>, { clientId, formData }: SaveExisitingSickLeave) {
    ctx.patchState({ saving: true });
    return this.sickLeaveService.putSave(clientId, formData).pipe(
      switchMap(response => ctx.dispatch(new SaveSickLeaveFormSuccessAction(response))),
      catchError(error => ctx.dispatch(new SaveSickLeaveFormFailedAction(error))),
      finalize(() => ctx.patchState({ saving: false })));
  }

  @Action(GetSickLeaveForm)
  getSickLeaveForm(ctx: StateContext<SickLeaveStateModel>, { clientId, sixCpaFormId }: GetSickLeaveForm) {
    return this.sickLeaveService.getSickLeaveForm(clientId, sixCpaFormId)
      .pipe(switchMap(response => ctx.dispatch([new GetSickLeaveFormSuccess(response), new ToggleSixCpaAction('in')])),
        catchError(err => ctx.dispatch(new GetSickLeaveFormError(err))));
  }

  @Action(GetSickLeaveFormSuccess)
  getSickLeaveFormSuccess(ctx: StateContext<SickLeaveStateModel>, { sickLeaveForm }: GetSickLeaveFormSuccess) {
    const { id, sixCpaFormId, consentAndEligibilityFormId, certificateTypeFormId, consulationFormId, pharmacistDeclarationFormId } = sickLeaveForm;
    ctx.patchState({
      sickLeaveId: id,
      sixCpaFormId,
      consentAndEligibilityFormId,
      certificateTypeFormId,
      consulationFormId,
      pharmacistDeclarationFormId
    });
    ctx.dispatch(closeConfirmationActions);
    ctx.dispatch(new UpdateSickLeaveForm(sickLeaveForm));
    
    if (sickLeaveForm.hasSubmitted) {
      ctx.dispatch(new GenerateSickLeaveForm());
    }
  }

  @Action(UpdateSickLeaveForm)
  updateSickLeaveForms(ctx: StateContext<SickLeaveStateModel>, { sickLeaveForm }: UpdateSickLeaveForm) {
    ctx.dispatch([
      new UpdateSickLeavePatientDetails(sickLeaveForm.patientDetailsForm),
      new UpdateSickLeaveCertificateType(sickLeaveForm.certificateTypeForm),
      new UpdateSickLeaveConsentAndEligibilityForm(sickLeaveForm.consentAndEligibilityForm),
      new UpdateSickLeaveConsulation(sickLeaveForm.consulationForm),
      new UpdateSickLeavePharmacistDeclaration(sickLeaveForm.pharmacistDeclarationForm),
      new SetSelectedFormType(SixCpaFormTypes.SickLeaveCertificate)
    ]);
  }

  @Action(GetSickLeaveFormError)
  getSickLeaveFormError(ctx: StateContext<SickLeaveStateModel>, action: GetSickLeaveFormError) {
    ctx.patchState({
      error: action.error
    });
  }

  @Action(ShowInvalidSickLeaveForm)
  showInvalidSickLeaveForm(ctx: StateContext<SickLeaveStateModel>) {
    ctx.patchState({
      showInvalid: true
    });
  }

}
