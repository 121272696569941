import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { CustomiseDoctorReportComponent } from './customise-doctor-report.component';
import { DoctorReportTemplatesSelectorModule } from '../doctor-report-templates-selector/doctor-report-templates-selector.module';
import { NgxsModule } from '@ngxs/store';
import { DoctorReportTemplatesSelectorState } from '../doctor-report-templates-selector/state/doctor-report-templates-selector.state';
import { CustomisedDoctorReportState } from './state/customised-doctor-report.state';
import { QuillModule } from 'ngx-quill';

@NgModule({
  declarations: [
    CustomiseDoctorReportComponent
  ],
  imports: [
    CommonModule,
    NgxsFormPluginModule,
    FormsModule,
    ReactiveFormsModule,
    QuillModule.forRoot(),
    DoctorReportTemplatesSelectorModule,
    NgxsModule.forFeature([DoctorReportTemplatesSelectorState, CustomisedDoctorReportState])
  ],
  exports: [
    CustomiseDoctorReportComponent
  ]
})
export class CustomiseDoctorReportModule { }
