import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';
import { TenantDatePipe } from '../../../../core/timezone/pipes/tenant-date.pipe';
import { HMRGPDetailsReferralFormState } from './state/hmr-gp-details-referral-form.state';
import { HMRGPDetailsReferralFormBasicState } from './state/hmr-gp-details-referral-form-basic.state';
import { HMRGPDetailsReferralFormDetailedState } from './state/hmr-gp-details-referral-form-detailed.state';
import { HMRGPDetailsReferralFormBasicLightState } from './state/hmr-gp-details-referral-form-basic-light.state';


@NgModule({
  imports: [
    NgxsModule.forFeature([
        HMRGPDetailsReferralFormState,
        HMRGPDetailsReferralFormDetailedState,
        HMRGPDetailsReferralFormBasicState,
        HMRGPDetailsReferralFormBasicLightState
        ]),
  ],
  providers: [
    TenantDatePipe
  ]
})
export class HMRGPDetailsReferralStateModule { }
