import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import { ReactiveFormsModule } from '@angular/forms';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ConsentEligibilityFormComponent, ConsentEligibilityFormHostDirective } from './consent-eligibility-form/consent-eligibility-form.component';
import { RadioHighlightModule } from '../../../../shared/components/radio-highlight/radio-highlight.module';
import { ConsentEligibilityFormDetailedComponent } from './detailed/consent-eligibility-form-detailed.component';
import { ConsentEligibilityFormBasicComponent } from './basic/consent-eligibility-form-basic.component';

import { UploadDocumentsFormModule } from '../upload-document-form/upload-document-form.module';
@NgModule({
  declarations: [
    ConsentEligibilityFormComponent,
    ConsentEligibilityFormBasicComponent,
    ConsentEligibilityFormDetailedComponent,
    ConsentEligibilityFormHostDirective
  ],
  imports: [
    CommonModule,
    NgxsFormPluginModule,
    ReactiveFormsModule,
    BsDatepickerModule.forRoot(),
    RadioHighlightModule,
    UploadDocumentsFormModule
  ],
  exports: [
    ConsentEligibilityFormComponent
  ]
})
export class ConsentEligibilityFormModule { }
