import { Inject, Injectable } from '@angular/core';
import { BaseApi } from '../../../../../../shared/core/base-api/base-api';
import { Router } from '@angular/router';
import { StorageService } from '../../../../../../shared/core/storage/storage.service';
import { AlertService } from '../../../../../core/alert/alert.service';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { SickLeaveFormModel } from '../../../../../../shared/models/six-cpa/sick-leave/sick-leave-form.model';
import { DatesService } from '../../../../../../shared/core/dates/dates.service';
import { SickLeaveDoctorEmailModel } from '../../../../../../shared/models/six-cpa/sick-leave/sick-leave-doctor-email.model';
import { SickLeavePatientEmailModel } from '../../../../../../shared/models/six-cpa/sick-leave/sick-leave-patient-email.model';

@Injectable()
export class SickLeaveService extends BaseApi {
  API = '';

  constructor(
    @Inject('environment') env: any,
    public http: HttpClient,
    public router: Router,
    public storageService: StorageService,
    public alertService: AlertService
  ) {
    super(http, router, storageService, alertService);
    this.API = env.baseApiUrl;
  }

  postSave(clientId: string, formData: SickLeaveFormModel) {
    formData = this.formatFormDataDates(formData);
    return this.post<SickLeaveFormModel>(
      `${this.API}/api/sick-leave/${clientId}/save`,
      formData
    );
  }

  postSubmit(clientId: string, formData: SickLeaveFormModel) {
    formData = this.formatFormDataDates(formData);
    return this.post<SickLeaveFormModel>(
      `${this.API}/api/sick-leave/${clientId}/submit`,
      formData
    );
  }

  putSave(clientId: string, formData: SickLeaveFormModel) {
    formData = this.formatFormDataDates(formData);
    return this.put(
      `${this.API}/api/sick-leave/${clientId}/update-save`,
      formData
    );
  }

  putSubmit(clientId: string, formData: SickLeaveFormModel) {
    formData = this.formatFormDataDates(formData);
    return this.put(
      `${this.API}/api/sick-leave/${clientId}/update-submit`,
      formData
    );
  }

  getSickLeaveForm(clientId: string, formId: number): Observable<any> {
    return this.get<any>(
      `${this.API}/api/sick-leave/${clientId}/form/${formId}`
    );
  }

  getCertificate(
    clientId: string,
    sixCpaFormId: number,
    signature: string
  ): Observable<any> {
    const request = {
      signature: signature,
      clientId: clientId,
      sixCpaFormId: sixCpaFormId,
    };
    return this.post(
      `${this.API}/api/sick-leave/print/getCertificate`,
      request,
      { responseType: 'blob' }
    );
  }

  getDoctorReport(
    clientId: string,
    sixCpaFormId: number,
    formData: SickLeaveDoctorEmailModel
  ): Observable<any> {
    return this.post(
      `${this.API}/api/sick-leave/${clientId}/print/${sixCpaFormId}/getDoctorReport`,
      formData,
      { responseType: 'blob' }
    );
  }
  sendDoctorReport(
    clientId: string,
    sixCpaFormId: number,
    formData: SickLeaveDoctorEmailModel
  ) {
    return this.post(
      `${this.API}/api/sick-leave/${clientId}/${sixCpaFormId}/sendDoctorReport`,
      formData
    );
  }

  sendSickLeaveReport(clientId: string, sixCpaFormId: number, formData: SickLeaveDoctorEmailModel) {
    return this.post(`${this.API}/api/sick-leave/${clientId}/${sixCpaFormId}/sendSickLeaveReport`, formData);
  }

  sendPatientReport(
    clientId: string,
    sixCpaFormId: number,
    formData: SickLeavePatientEmailModel
  ) {
    return this.post(
      `${this.API}/api/sick-leave/${clientId}/${sixCpaFormId}/sendPatientReport`,
      formData
    );
  }

  private formatFormDataDates(form: SickLeaveFormModel): SickLeaveFormModel {
    form.patientDetailsForm.dob = DatesService.ToEndOfLocalDayInUtc(
      form.patientDetailsForm.dob
    );
    form.pharmacistDeclarationForm.dateOfService = DatesService.ToEndOfLocalDayInUtc(
      form.pharmacistDeclarationForm.dateOfService
    );
    form.consulationForm.dateCertificateFrom = DatesService.ToEndOfLocalDayInUtc(
      form.consulationForm.dateCertificateFrom
    );
    form.consulationForm.dateCertificateTo = DatesService.ToEndOfLocalDayInUtc(
      form.consulationForm.dateCertificateTo
    );
    form.consulationForm.dateOfCertificate = DatesService.ToEndOfLocalDayInUtc(
      form.consulationForm.dateOfCertificate
    );
    form.pharmacistDeclarationForm.dateOfService = DatesService.ToEndOfLocalDayInUtc(
      form.pharmacistDeclarationForm.dateOfService
    );
    return form;
  }
}
