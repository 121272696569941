import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { Select, Store } from '@ngxs/store';
import { CommQueueState, CommQueueStateModel } from './state/comms-queue.state';

import { TemplateType } from '../../../shared/models/pharmacy/comm-template.model';
import 'rxjs/add/operator/debounceTime';
import { CommsQueueSort } from './models/comms-queue-sort.enum';
import { SortCommsQueueAction, ToggleShowMessageModalAction, ToggleShowTemplateModalAction } from './state/comms-queue.actions';
import { CommsQueueItem } from './models/comms-queue-item.model';
import * as moment from 'moment';

@Component({
  selector: 'app-comms-queue',
  templateUrl: './comms-queue.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./comms-queue.component.scss'],
})
export class CommQueueComponent implements OnInit, OnDestroy {
  @Select(CommQueueState) commsQueueState$: Observable<CommQueueStateModel>;
  @Select(CommQueueState.commsQueueItems) commQueueItems$: Observable<CommsQueueItem[]>;
  sortByTypes = CommsQueueSort;
  sortBy = CommsQueueSort.Scheduled;
  templateType = TemplateType;
  searchInput = new FormControl();
  formGroup: FormGroup;
  minDate: Date = new Date();
  resultsPerPage = 25;
  resultsPerPageList = [25, 50, 75, 100];
  pageSize$ = new BehaviorSubject<number>(25);
  resultsPerPageSubscription: Subscription
  startDateSubscription: Subscription
  sortBySubscription: Subscription
  sortKey$ = new BehaviorSubject<string>('name');
  sortDirection$ = new BehaviorSubject<string>('asc');
  startDate = ''

  constructor(private store: Store) { }

  ngOnDestroy(): void {
    if (this.resultsPerPageSubscription) {
      this.resultsPerPageSubscription.unsubscribe();
    }
    if (this.startDateSubscription) {
      this.startDateSubscription.unsubscribe();
    }
    if(this.sortBySubscription) {
      this.sortBySubscription.unsubscribe();
    }
  }

  ngOnInit() {
    this.startDate = moment().format('DD MMM YYYY');
    this.formGroup = new FormGroup({
      'startDate': new FormControl(new Date()),
      'resultsPerPage': new FormControl(25),
      'sortBy': new FormControl(CommsQueueSort.Scheduled)
    });

    this.resultsPerPageSubscription = this.formGroup.get('resultsPerPage').valueChanges.subscribe(result => {
      this.pageSize$.next(result);
    });

    // this.startDateSubscription = this.formGroup.get('startDate').valueChanges.subscribe(startDateResult => {
    //   const startDate = moment(startDateResult, 'DD/MM/YYYY');
    //   this.store.dispatch(new GetCommsQueueAction(startDate.toDate())).subscribe(result => {
    //     this.startDate = startDate.format('DD MMM YYYY');
    //     const sortBy = this.formGroup.get('sortBy').value;
    //     this.sortMethod(sortBy);
    //   });
    // });

    // this.sortBySubscription = this.formGroup.get('sortBy').valueChanges.subscribe((result) => this.sortMethod(result));
  }

  sortMethod(sortBy: CommsQueueSort) {
    if (sortBy === CommsQueueSort.Scheduled) {
      this.store.dispatch(new SortCommsQueueAction('sendDate', 'asc'));
    }else if (sortBy === CommsQueueSort.LastFirst) {
      this.store.dispatch(new SortCommsQueueAction('sendDate', 'desc'));
    }
  }

  closeMessageModal() {
    this.store.dispatch(new ToggleShowMessageModalAction(null));
  }

  closeTemplateModal() {
    this.store.dispatch(new ToggleShowTemplateModalAction(null));
  }
}
