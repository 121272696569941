import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AlertService } from '../../../../modules/core/alert/alert.service';
import { BaseApiNoAlerts } from '../../../../shared/core/base-api/base-api-no-alerts';
import { StorageService } from '../../../../shared/core/storage/storage.service';
import { TriggerType } from '../../../../shared/models/pharmacy/trigger.model';
import { OrderMethod } from '../../../../shared/models/communication/order-method.enum';

@Injectable()
export class EvaluationService extends BaseApiNoAlerts {
  API = '';
  constructor(
    @Inject('environment') env: any,
    public http: HttpClient,
    public router: Router,
    public storageService: StorageService,
    public alertService: AlertService
  ) {
    super(http, router, storageService, alertService);
    this.API = env.baseApiUrl;
  }

  getNotification(tenantCustomerId: number, orderMethod: OrderMethod, patientType: string, triggerType: TriggerType): Observable<any> {
    return this
      .get<Observable<any>>(`${this.API}/api/evaluation/notification`,
        {
          params: {
            tenantCustomerId: `${tenantCustomerId}`,
            orderMethod: `${orderMethod}`,
            patientType: patientType,
            triggerType: `${triggerType}`
          }
        });
  }

  dispatchConfirmedNotification(notification: any): Observable<any> {
    return this.post(`${this.API}/api/evaluation/notification/${notification.commType}`, notification);
  }
}
