<form [formGroup]="classificationAndRecommendationsForm"
    ngxsForm="classificationAndRecommendations.classificationAndRecommendationsForm" class="p-4">
    <div class="form-row">
        <div class="col-6">
            <p class="col-form-label-lg font__bold">Intervention Category</p>
            <select formControlName="interventionCategory" class="form-control form-control-lg"
            [class.has-error]="formControls.interventionCategory.invalid && (showInvalid$ | async)"
                (change)="updateCIClassifications()">
                <option *ngFor="let item of interventionCategories" [value]="item.key">{{item.value}}</option>
            </select>
        </div>
        <div class="col-6">
            <p class="col-form-label-lg font__bold">Intervention Classification</p>
            <select formControlName="interventionClassification" class="form-control form-control-lg"
            [class.has-error]="formControls.interventionClassification.invalid && (showInvalid$ | async)">
                <option *ngFor="let item of interventionClassifications" [value]="item.key">{{item.value}}</option>
            </select>
        </div>
    </div>
    <div class="form-group py-3" formGroupName="recommendations" 
    [class.has-error-group]="formControls.recommendations.invalid && (showInvalid$ | async)">
        <p class="col-form-label-lg font__bold required">Recommendations</p>
        <div *ngFor="let el of recommendationsGrouped">
            <div class="font__bold mb-1">
                {{el.category}}
            </div>
            <div class="form-check" *ngFor="let item of el.subcategories">
                <label class="form-check-label pl-2">
                    <input class="form-check-input big-checkbox" [formControlName]="item.key" type="checkbox"
                        [value]="item.value">
                    {{item.value}}</label>
            </div>
        </div>
    </div>
    <div class="form-group">
        <input type="text" formControlName="clinicalRecommendationsNotes" class="form-control form-control-lg"
        [class.has-error]="formControls.clinicalRecommendationsNotes.invalid && (showInvalid$ | async)"
            placeholder="Notes about clinical recommendation">
    </div>
</form>