import { Attachment } from '../../../../../../shared/models/messageHistory.model';

export enum SmsAttachmentModalActions {
  OPEN_MODAL = '[SmsAttachmentModal] Open Modal',
  CLOSE_MODAL = '[SmsAttachmentModal] Close Modal'
}


export class OpenAttachmentsModal {
  static readonly type = SmsAttachmentModalActions.OPEN_MODAL;
  constructor(public attachments: Attachment[]) {}
}

export class CloseAttachmentsModal {
  static readonly type = SmsAttachmentModalActions.CLOSE_MODAL;
  constructor() {}
}
