import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { CommTemplate } from '../../../../shared/models/pharmacy/comm-template.model';
import { Trigger, TriggerType } from '../../../../shared/models/pharmacy/trigger.model';
import { SetupAddEditCommTemplateModalAction, OpenAddEditCommTemplateModalAction } from '../../comm-templates/add-edit-comm-template-modal/state/add-edit-comm-template-modal.actions';
import { SetTriggerForEditing, ToggleAddEditTriggerModal } from '../add-edit-trigger-modal/state/add-edit-trigger-modal.actions';
import { ToggleTriggerActive } from '../state/triggers.actions';

@Component({
  selector: 'app-comms-reminders-list',
  templateUrl: './comms-reminders-list.component.html',
  styleUrls: ['./comms-reminders-list.component.scss']
})
export class CommsRemindersListComponent implements OnInit {
  @Input() triggers: Trigger[] = [];
  @Input() patientType: string = '';

  constructor(private store: Store) { }

  ngOnInit() {
  }

  getTriggerType(type: TriggerType) {
    if(type== TriggerType.lastRepeatDue) {
      return 'No Repeats';
    }

    var stringType = TriggerType[+type];
    var sentence = stringType.replace(/([A-Z])/g, " $1");

    return sentence.charAt(0).toUpperCase() + sentence.slice(1);
  }

  editTemplate(template: CommTemplate) {
    this.store.dispatch([new SetupAddEditCommTemplateModalAction(template), new OpenAddEditCommTemplateModalAction()]);
  }

  editTrigger(trigger: Trigger) {
    this.store.dispatch([new SetTriggerForEditing(trigger), new ToggleAddEditTriggerModal(true)])
  }

  toggle(triggerId: number) {
    this.store.dispatch(new ToggleTriggerActive(triggerId));
  }

  getChronology(type: TriggerType) {
    switch(type) {
      case TriggerType.scriptOwing:
      case TriggerType.medicationOverdue:
      case TriggerType.medicationOverdueOnLastRepeat: {
        return 'after'
      }
      default: {
        return 'before'
      }
    }
  }
}
