import { HMRChildForm } from '../hmr-child-form';
import { OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Store, Actions } from '@ngxs/store';
import { SetTotalItemsAndCompletedItems } from './state/hmr-proposed-details-form.actions';

const controlsToIgnore = ['termsAndConditions'];
export class HMRProposedDetailsFormBaseComponent extends HMRChildForm implements OnInit, OnDestroy {
    proposedDetailsForm: FormGroup;
    formResetStatus: boolean;

    constructor(private formBuilder: FormBuilder, protected store: Store, actions: Actions) {
      super(actions);
      this.proposedDetailsForm = this.formBuilder.group({
        proposedDateOfInterview: new FormControl(null, Validators.required)
      });
    }
    get formControls() {
      return this.proposedDetailsForm.controls;
    }
    protected actionOnResetForm() {
      const controls = Object.keys(this.proposedDetailsForm.controls);
      controls.filter((key) => {
        if (!controlsToIgnore.includes(key)) {
          this.proposedDetailsForm.controls[key].setValue('');
        }
      });
      if (this.proposedDetailsForm.controls['termsAndConditions'] !== undefined) {
        this.proposedDetailsForm.controls['termsAndConditions'].setValue(null);
      }
    }

    protected onFormChange() {
      const controls = Object.keys(this.proposedDetailsForm.controls);
      const completedItems = this.getTotalValidItems(controls);
      this.store.dispatch(new SetTotalItemsAndCompletedItems(controls.length, completedItems));
    }

    private getTotalValidItems(controls: Array<string>) {
      return controls.filter((key) => {
        const formControl = this.proposedDetailsForm.get(key);
        return  formControl.status === 'VALID' || (formControl.status === 'DISABLED' && !formControl.invalid && formControl.errors === null);
      }).length;
    }
  }

