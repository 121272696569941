import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { NgxAutoScroll } from 'ngx-auto-scroll';
import { Observable } from 'rxjs';
import { CommsQueueItem } from '../../../../modules/pharmacy-comms/comms-queue/models/comms-queue-item.model';
import { ProjectedMessagesState } from './state/projected-messages.state';
import { Client } from '../../../../shared/models/client.model';
import { GetProjectedMessages } from './state/projected-messages.actions';
import * as moment from 'moment';

@Component({
  selector: 'app-projected-messages',
  templateUrl: './projected-messages.component.html',
  styleUrls: ['./projected-messages.component.scss']
})
export class ProjectedMessagesComponent implements OnInit {

  constructor(private store: Store) { }

  @Select(ProjectedMessagesState.messages) projectedMessages$: Observable<CommsQueueItem[]>;
  @Select(ProjectedMessagesState.loading) projectedMessagesLoading$: Observable<boolean>;
  @ViewChild(NgxAutoScroll, { static: false }) ngxAutoScroll: NgxAutoScroll;
  @Input() client: Client;

  ngOnInit(): void {
    this.store.dispatch(new GetProjectedMessages(this.client.id, new Date));
  }

  format(date: Date) {
    return moment(date).utcOffset(0, false).calendar();
  }

}
