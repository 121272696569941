import { DriveYelloOrderForm } from "~/shared/models/drive-yello/drive-yello-order.model";

export enum DriveYelloNewOrderActions {
  NEW_DRIVE_YELLO_ORDER = '[DriveYelloNewOrder] New Order',
  CANCEL_DRIVE_YELLO_ORDER = '[DriveYelloNewOrder] Cancel Order',
  NEW_DRIVE_YELLO_ORDER_SUCCESS = '[DriveYelloNewOrder] New Order Success',
  CANCEL_DRIVE_YELLO_ORDER_SUCCESS = '[DriveYelloNewOrder] Cancel Order Success',
  DRIVE_YELLO_ORDER_ERROR = '[DriveYelloNewOrder] Order Error',
  TOGGLE_MODAL = '[DriveYelloNewOrder] Toggle Modal',
}

export class ToggleDriveYelloNewOrderModal {
  static readonly type = DriveYelloNewOrderActions.TOGGLE_MODAL
  constructor(public show: boolean) { }
}

export class NewDriveYelloNewOrder {
  static readonly type = DriveYelloNewOrderActions.NEW_DRIVE_YELLO_ORDER
  constructor(public order: DriveYelloOrderForm) { }
}

export class NewDriveYelloNewOrderSuccess {
  static readonly type = DriveYelloNewOrderActions.NEW_DRIVE_YELLO_ORDER_SUCCESS
  constructor(public orderConfirmation: any) { }
}

export class CancelDriveYelloNewOrder {
  static readonly type = DriveYelloNewOrderActions.CANCEL_DRIVE_YELLO_ORDER
  constructor(public orderId: string) { }
}

export class CancelDriveYelloNewOrderSuccess {
  static readonly type = DriveYelloNewOrderActions.CANCEL_DRIVE_YELLO_ORDER_SUCCESS
  constructor(public orderId: string) { }
}

export class DriveYelloOrderError {
  static readonly type = DriveYelloNewOrderActions.DRIVE_YELLO_ORDER_ERROR
  constructor(public error: any) { }
}


