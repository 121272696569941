import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RadioHighlightModule } from '../../../../../shared/components/radio-highlight/radio-highlight.module';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import { ReactiveFormsModule } from '@angular/forms';
import { DaaConsentAndEligibilityFormComponent } from './daa-consent-and-eligibility-form.component';

@NgModule({
  declarations: [
    DaaConsentAndEligibilityFormComponent
  ],
  imports: [
    CommonModule,
    RadioHighlightModule,
    NgxsFormPluginModule,
    ReactiveFormsModule
  ],
  exports: [
    DaaConsentAndEligibilityFormComponent
  ]
})
export class DAAConsentAndEligibilityFormModule { }
