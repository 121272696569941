import { ChangeDetectionStrategy, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { OrderRequest } from '../../models/order-request.model';

@Component({
  selector: 'app-order-empty',
  templateUrl: './order-empty.component.html',
  styleUrls: ['./order-empty.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class OrderEmptyComponent implements OnInit {
  @Input() orderTypeText: string = '';

  constructor(
  ) { }

  ngOnInit() {
  }

}
