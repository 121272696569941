import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PhoneComponent } from './phone.component';
import { LoaderModule } from '../../../../modules/core/loader/loader.module';
import { AddNoteComponent } from './add-note/add-note.component';
import { NoteHistoryComponent } from './note-history/note-history.component';
import { PhoneService } from '../../../../shared/services/phone.service';
import { FormsModule } from '@angular/forms';
import { TimezoneModule } from '../../../../modules/core/timezone/timezone.module';

@NgModule({
  declarations: [
    PhoneComponent,
    AddNoteComponent,
    NoteHistoryComponent
  ],
  providers: [
    PhoneService
  ],
  imports: [
    TimezoneModule,
    CommonModule,
    FormsModule,
    LoaderModule
  ],
  exports: [
    PhoneComponent
  ]
})
export class PhoneModule { }
