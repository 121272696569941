import { State, Action, StateContext, Selector, Store } from '@ngxs/store';
import * as moment from 'moment';
import { Note } from '~/shared/models/crm/note.model';
import { GetCrmAction, CrmErrorAction, SetCrmAction, UpdateTotalsAction, UpdateNoteAction, UpdateNoteListAction, UpdateFiltersAction, GetOutstandingNoteCount } from './crm.actions';
import { CrmService } from '~/shared/services/crm.service';
import { tap, catchError } from 'rxjs/operators';
import { NoteInfo } from '~/shared/models/crm/noteInfo.model';
import { NoteFilter } from '~/shared/models/crm/noteFilter.model';
import { GetClientNotes } from '~/modules/profile/notes/state/notes.actions';
import { ProfileState } from '~/modules/core/profile/state/profile.state';
import { Injectable } from '@angular/core';

export class CrmStateModel {
  public noteList: Note[];
  public noteInfo: NoteInfo;
  public filters: NoteFilter;
  public loading: boolean;
  public isLoadedTable: boolean;
  public isScrollNextLoaded: boolean;
  public error: any;
  public awaitingTotal: number;
}
@Injectable()
@State<CrmStateModel>({
  name: 'crm',
  defaults: {
    noteList: [],
    noteInfo: null,
    filters: null,
    loading: false,
    isLoadedTable: false,
    isScrollNextLoaded: true,
    error: null,
    awaitingTotal: null
  }
})
export class CrmState {
  constructor(private crmService: CrmService, private store: Store) { }

  @Selector()
  static noteList(state: CrmStateModel) {
    return state.noteList;
  }

  @Selector()
  static noteInfo(state: CrmStateModel) {
    return state.noteInfo;
  }

  @Selector()
  static awaitingTotal(state: CrmStateModel) {
    return state.awaitingTotal;
  }

  @Action(GetOutstandingNoteCount)
  getOutstandingNoteCount({ patchState, dispatch }: StateContext<CrmStateModel>) {
    return this.crmService.getOutstandingNoteCount(null).pipe(
      tap(res => {
        patchState({ awaitingTotal: res });
      }),
      catchError(x => dispatch(new CrmErrorAction(x)))
    );
  }

  @Action(GetCrmAction)
  getCrmAction(ctx: StateContext<CrmStateModel>, action: GetCrmAction) {
    const isFirstLoad = action.filter.pageIndex === 1;
    ctx.patchState({ loading: true });
    if (isFirstLoad) {
      ctx.patchState({ isLoadedTable: false });
    } else {
      ctx.patchState({ isScrollNextLoaded: false });
    }
    return this.crmService.getFilteredNotes(action.filter)
      .pipe(
        tap((data) => ctx.dispatch(new SetCrmAction(data, action.filter))),
        catchError((error) => ctx.dispatch(new CrmErrorAction(error)))
      );
  }

  @Action(SetCrmAction)
  setCrmAction(ctx: StateContext<CrmStateModel>, action: SetCrmAction) {
    const isFirstLoad = action.filter.pageIndex === 1;
    if (isFirstLoad) {
      ctx.patchState({
        noteList: action.data.notes,
        noteInfo: action.data.info,
        isLoadedTable: true,
        loading: false
      });
    } else {
      const state = ctx.getState();
      ctx.patchState({
        noteList: [...state.noteList, ...action.data.notes],
        isScrollNextLoaded: true,
        loading: false
      });
    }

  }

  @Action(CrmErrorAction)
  handleError(ctx: StateContext<CrmStateModel>, action: CrmErrorAction) {
    ctx.patchState({ error: action.error, loading: false });
  }

  @Action(UpdateTotalsAction)
  updateTotals(ctx: StateContext<CrmStateModel>, { event }: UpdateTotalsAction) {
    const { isComplete, count } = event;
    const { noteInfo, awaitingTotal } = ctx.getState();

    if (isComplete) {
      noteInfo.completedToday += count;
    } else {
      noteInfo.remainingNotes -= count;
    }

    noteInfo.percent = +((noteInfo.completedToday / noteInfo.remainingNotes) * 100).toFixed(0);
    ctx.patchState({ noteInfo: noteInfo, awaitingTotal: awaitingTotal - count });
  }

  @Action(UpdateNoteAction)
  updateNote(ctx: StateContext<CrmStateModel>, action: UpdateNoteAction) {
    ctx.patchState({ isLoadedTable: false });
    return this.crmService.updateNote(action.items)
      .pipe(
        tap((data) => ctx.dispatch(new UpdateNoteListAction(data))),
        catchError((error) => ctx.dispatch(new CrmErrorAction(error)))
      );
  }

  @Action(UpdateNoteListAction)
  updateNoteList(ctx: StateContext<CrmStateModel>, action: UpdateNoteListAction) {
    const state = ctx.getState();
    const clientId = this.store.selectSnapshot(ProfileState.clientId);
    let list = state.noteList.map(i => {
      const item = action.items.find(x => x.id === i.id);
      return (item !== undefined && item.delayedUntil !== null) ? { ...i, nextActionDate: item.delayedUntil } : i;
    });
    if (state.filters) {
      const { fromDate, toDate } = state.filters;

      list = list.filter(x => {
        const date = moment(x.nextActionDate);
        if (fromDate && !date.isAfter(fromDate)) {
          return false;
        }
        if (toDate && !date.isBefore(toDate)) {
          return false;
        }
        return true;
      });
    }
    ctx.patchState({
      noteList: list.sort((a, b) => this.compareDate(a, b)),
      isLoadedTable: true
    });
    if (clientId) {
      ctx.dispatch(new GetClientNotes(clientId));
    }
  }

  @Action(UpdateFiltersAction)
  updateFilters(ctx: StateContext<CrmStateModel>, action: UpdateFiltersAction) {
    ctx.patchState({
      filters: action.filters
    });
    ctx.dispatch(new GetCrmAction(action.filters));
  }

  private compareDate(a: Note, b: Note): number {
    if (new Date(a.nextActionDate) > new Date(b.nextActionDate)) { return 1; }
    if (new Date(b.nextActionDate) > new Date(a.nextActionDate)) { return -1; }
    return 0;
  }
}
