import { Component, OnInit } from '@angular/core';
import { HMRConsentAndEligibilityState } from '../hmr-consent-and-eligibility/state/hmr-consent-and-eligibility.state';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-hmr-consent-and-eligibility-list-item',
  templateUrl: './hmr-consent-and-eligibility-list-item.component.html'
})
export class HMRConsentAndEligibilityListItemComponent implements OnInit {
  @Select(HMRConsentAndEligibilityState) hmrConsentAndEligibilityForm$: Observable<any>;

  constructor() { }

  ngOnInit() {
  }


}
