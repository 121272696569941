import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommTemplatesComponent } from './comm-templates.component';
import { CommTemplatesListModule } from './comm-templates-list/comm-templates-list.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { CommTemplatesFilterPipe } from './pipes/comm-templates-filter.pipe';
import { LoaderModule } from '../../../modules/core/loader/loader.module';

@NgModule({
  declarations: [CommTemplatesComponent, CommTemplatesFilterPipe],
  imports: [
    CommonModule,
    CommTemplatesListModule,
    ReactiveFormsModule,
    FormsModule,
    LoaderModule
  ],
  exports: [CommTemplatesComponent]
})
export class CommTemplatesModule { }
