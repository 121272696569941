import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import { ReactiveFormsModule } from '@angular/forms';
import { MedicationInformationFormComponent } from './medication-information-form.component';

@NgModule({
  declarations: [
    MedicationInformationFormComponent
  ],
  imports: [
    CommonModule,
    NgxsFormPluginModule,
    ReactiveFormsModule
  ],
  exports: [
    MedicationInformationFormComponent
  ]
})
export class MedicationInformationFormModule { }
