import { BaseApi } from '../../../../shared/core/base-api/base-api';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { StorageService } from '../../../../shared/core/storage/storage.service';
import { AlertService } from '../../../core/alert/alert.service';
import { HttpClient } from '@angular/common/http';
import { AslMedication } from '../models/asl-medication.model';
import { Observable } from 'rxjs';

@Injectable()
export class AslMedicationsService extends BaseApi {
  API = '';

  constructor(
    @Inject('environment') env: any,
    public http: HttpClient,
    public router: Router,
    public storageService: StorageService,
    public alertService: AlertService
  ) {
    super(http, router, storageService, alertService);
    this.API = env.customerApiBase;
  }

  getMedications(ihiNumber: string, firstName: string, lastName: string): Observable<AslMedication[]> {
    return this.get<AslMedication[]>(`${this.API}/api/medication/medications?ihiNumber=${ihiNumber}&firstName=${firstName}&lastName=${lastName}`);
  }

}
