import { Component, Directive, ViewContainerRef, ViewChild, ComponentFactoryResolver, Input, Type, OnChanges } from '@angular/core';
import { HasViewContainerRef } from '../../../has-view-container-ref.interface';
import { DAAPatientDetailsFormBasicComponent } from '../basic/daa-patient-details-form-basic.component';
import { DAAPatientDetailsFormDetailedComponent } from '../detailed/daa-patient-details-form-detailed.component';
import { DAAResolvedForm } from '../../daa-resolved-form';


@Directive({
  selector: '[appDAAPatientDetailsFormHost]'
})
export class DAAPatientDetailsFormHostDirective implements HasViewContainerRef {
  constructor(public viewContainerRef: ViewContainerRef) { }
}

@Component({
  selector: 'app-daa-patient-details-form',
  templateUrl: './daa-patient-details-form.component.html'
})
export class DAAPatientDetailsFormComponent extends DAAResolvedForm implements OnChanges {
  @Input() isDetailed: boolean;
  @ViewChild(DAAPatientDetailsFormHostDirective, { static: true }) formHost: DAAPatientDetailsFormHostDirective;

  constructor(
    componentFactoryResolver: ComponentFactoryResolver
  ) {
    super(componentFactoryResolver);
  }

  ngOnChanges() {
    this.resolveFormComponent(this.isDetailed);
  }

  protected getFormComponent(isDetailed: boolean): Type<any> {
    return isDetailed
      ? DAAPatientDetailsFormDetailedComponent
      : DAAPatientDetailsFormBasicComponent;
  }
}


