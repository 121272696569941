import { Component, OnInit, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-confirm-reset',
  templateUrl: './confirm-reset.component.html',
  styleUrls: ['./confirm-reset.component.scss']
})
export class ConfirmResetComponent implements OnInit {
  @Output() submitEvent = new EventEmitter<any>();
  @Output() closeEvent = new EventEmitter<any>();
  
  constructor() { }

  ngOnInit() {
  }

  close() {
    this.closeEvent.emit();
  }
  submit() {
    this.submitEvent.emit();
  }
}
