<ng-container *ngIf="(triggerState$ | async) as triggerState">
  <div class="p-4 position-relative">
    <div class="row flex justify-content-between mx-2">
      <h1 class="blue-header">Scrypt Reminders</h1>
      <div class="mt-4">
        <select [(ngModel)]="sortBy" class="form-control form-control-lg width__200">
          <option [ngValue]="sortByTypes.Newest">Newest</option>
          <option [ngValue]="sortByTypes.Oldest">Oldest to Newest</option>
          <option [ngValue]="sortByTypes.Alphabetical">Alphabetical A-Z</option>
          <option [ngValue]="sortByTypes.AlphabeticalReverse">Alphabetical Z-A</option>
        </select>
      </div>
    </div>
    <div >
        <app-comms-reminders-list
            patientType="All Scrypt Reminders"
            [triggers]="triggerState.triggers | sortTriggersPipe: sortBy">
        </app-comms-reminders-list>
      </div>
    <app-add-edit-trigger-modal></app-add-edit-trigger-modal>
  </div>
  <div class="loading__container" *ngIf="triggerState.loading;">
    <app-loader loadingMessage="Won't be long..."></app-loader>
  </div>
</ng-container>
