import { Injectable } from '@angular/core';
import { State, Action, StateContext, Selector, Store } from '@ngxs/store';
import * as moment from 'moment';
import { catchError, concatMap, finalize, take, takeUntil, tap } from 'rxjs/operators';
import { AlertService } from '~/modules/core/alert/alert.service';
import { ClientViewModel } from '~/modules/core/profile/client.model';
import { TenantCategoryState } from '~/modules/profile/tenant-categories/state/tenant-category.state';
import { GroupPageState } from '~/system/group-page/state/group-page.state';
import { ReportViewModel } from '../models/dispense-report.model';
import { DispenseRunRequestModel } from '../models/dispense-run-request.model';
import { DispenseRunService } from '../services/dispense-run.service';
import { DispenseRunError, EvaluateClientSuccess, EvaluteClients, InitDispenseRunRequest, ResetReport, SubmitDispenseForm } from './dispense-run.actions';
import { Subject, from, interval } from 'rxjs';

export class DispenseRunStateModel {
  preFetchedClientViewModels: ClientViewModel[]
  loading: boolean;
  loadingMessage: string;
  initialPayload: DispenseRunRequestModel;
  report: ReportViewModel;
  evaluatingClients: boolean;
}

const defaultValues: DispenseRunStateModel = {
  preFetchedClientViewModels: [],
  loading: false,
  loadingMessage: null,
  initialPayload: null,
  report: null,
  evaluatingClients: false
}

@Injectable()
@State<DispenseRunStateModel>({
  name: 'dispenseRun',
  defaults: defaultValues,
})
export class DispenseRunState {
  private callDelayInMs = 4000;
  private cancelRunningRequests$ = new Subject();

  constructor(
    private dispenseRunService: DispenseRunService,
    private store: Store,
    private alertService: AlertService) { }

  @Selector()
  static state(state: DispenseRunStateModel) { return state; }

  @Selector()
  static report(state: DispenseRunStateModel) { return state.report; }

  @Selector()
  static preFetchedClients(state: DispenseRunStateModel) { return state.preFetchedClientViewModels; }

  @Action(InitDispenseRunRequest, { cancelUncompleted: true })
  initDispenseRunRequest(ctx: StateContext<DispenseRunStateModel>, { request }: InitDispenseRunRequest) {
    this.cancelRunningRequests$.next();

    ctx.patchState({
      loading: true,
      loadingMessage: 'Finding clients matching parameters...',
      initialPayload: request,
      report: null,
      preFetchedClientViewModels: []
    });

    return this.dispenseRunService.setUpDispenseRun(request).pipe(
      tap(resp => {
        ctx.patchState({
          preFetchedClientViewModels: resp,
          initialPayload: {
            ...request,
            preFetchedClients: resp
          }
        })

        if (resp.length) {
          ctx.patchState({
            report: this.getReportModel()
          });
          ctx.dispatch(new EvaluteClients());
        }
        else {
          ctx.patchState({ loading: false });
          this.alertService.info('Clients found in the selected groups with medicines..')
        }
      }),
      catchError(err => ctx.dispatch(new DispenseRunError(err))),
      finalize(() => ctx.patchState({
        loadingMessage: `Found ${ctx.getState().preFetchedClientViewModels.length} potential clients, starting evaluation...`
      })));
  }


  @Action(SubmitDispenseForm)
  submitDispenseForm(ctx: StateContext<DispenseRunStateModel>, { }: SubmitDispenseForm) {
    const request = ctx.getState().initialPayload;
    ctx.patchState({
      loading: true,
      loadingMessage: `Evaluating initial ${ctx.getState().preFetchedClientViewModels.length} clients against criteria`
    });

    return this.dispenseRunService.evaluate(request).pipe(
      tap(resp => ctx.patchState({ report: resp })),
      catchError(err => ctx.dispatch(new DispenseRunError(err))),
      finalize(() => ctx.patchState({ loading: false })
      ))
  }

  @Action(EvaluteClients, { cancelUncompleted: true })
  async evaluteClients(ctx: StateContext<DispenseRunStateModel>, { }: EvaluteClients) {
    const state = ctx.getState();
    const request = state.initialPayload;

    ctx.patchState({ evaluatingClients: true });

    const clients$ = from(state.preFetchedClientViewModels);
    clients$.pipe(
      concatMap(param => {
        request.clientId = param.clientId;
        request.preFetchedClients = []
        if(!this.cancelRunningRequests$.isStopped) {
          return interval(this.callDelayInMs).pipe(
            take(1),
            concatMap(() => this.dispenseRunService.evaluateSingle(request))
          );
        }
      }),
      takeUntil(this.cancelRunningRequests$),
    ).subscribe(response => {
      ctx.dispatch(new EvaluateClientSuccess(response))
    }, catchError(err => ctx.dispatch(new DispenseRunError(err))));

    ctx.patchState({
      evaluatingClients: false
    });
  }

  @Action(EvaluateClientSuccess)
  evaluateClientSuccess(ctx: StateContext<DispenseRunStateModel>, { response }: EvaluateClientSuccess) {
    const state = ctx.getState();
    const clientIndex = state.preFetchedClientViewModels.findIndex(c => c.clientId == response.clientId);
    let preFetchedClients = state.preFetchedClientViewModels;
    preFetchedClients[clientIndex].evaluated = true;

    ctx.patchState({
      loading: true,
      preFetchedClientViewModels: preFetchedClients
    });

    if (!response.clients) {
      return;
    }

    let newReport = state.report;
    newReport.clients = state.report.clients.concat(response.clients)
    ctx.patchState({
      report: newReport,
      loading: false
    });
  }

  @Action(DispenseRunError)
  error(ctx: StateContext<DispenseRunStateModel>, { error }: DispenseRunError) {
    ctx.patchState({
      loading: false
    });
    this.alertService.error(JSON.stringify(error));
  }

  private getReportModel(): ReportViewModel {
    const report: ReportViewModel = {
      clients: [],
      groups: this.store.selectSnapshot(GroupPageState.groups).map(g => g.name),
      categories: this.store.selectSnapshot(TenantCategoryState.categories).map(g => g.name),
      fromDate: moment(new Date()).add('years', -1).toDate(),
      toDate: new Date()
    }

    return report;
  }

  @Action(ResetReport)
  resetReport(ctx: StateContext<DispenseRunStateModel>) {
    ctx.setState(defaultValues);
  }
}
