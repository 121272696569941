import { Inject, Injectable } from '@angular/core';
import { BaseApi } from '../../../../../../shared/core/base-api/base-api';
import { Router } from '@angular/router';
import { StorageService } from '../../../../../../shared/core/storage/storage.service';
import { AlertService } from '../../../../../core/alert/alert.service';
import { DaaFormModel } from '../../../../../../shared/models/six-cpa/DAA/daa-form.model';
import { DatesService } from '../../../../../../shared/core/dates/dates.service';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class DoseAdministrationAidsService extends BaseApi {
  API = '';

  constructor(
    @Inject('environment') env: any,
    public http: HttpClient,
    public router: Router,
    public storageService: StorageService,
    public alertService: AlertService
  ) {
    super(http, router, storageService, alertService);
    this.API = env.baseApiUrl;
  }

  postSave(clientId: string, formData: DaaFormModel) {
    formData = this.formatFormDataDates(formData);
    return this.post<DaaFormModel>(
      `${this.API}/api/daa/${clientId}/save`,
      formData
    );
  }

  postSubmit(clientId: string, formData: DaaFormModel) {
    formData = this.formatFormDataDates(formData);
    return this.post<DaaFormModel>(
      `${this.API}/api/daa/${clientId}/submit`,
      formData
    );
  }

  putSave(clientId: string, formData: DaaFormModel) {
    formData = this.formatFormDataDates(formData);
    return this.put(`${this.API}/api/daa/${clientId}/update-save`, formData);
  }

  putSubmit(clientId: string, formData: DaaFormModel) {
    formData = this.formatFormDataDates(formData);
    return this.put(`${this.API}/api/daa/${clientId}/update-submit`, formData);
  }

  getDAAForm(clientId: string, formId: number): Observable<any> {
    return this.get<any>(`${this.API}/api/daa/${clientId}/form/${formId}`);
  }

  private formatFormDataDates(form: DaaFormModel): DaaFormModel {
    form.patientDetailsForm.dob = DatesService.ToEndOfLocalDayInUtc(
      form.patientDetailsForm.dob
    );
    form.patientDetailsForm.dateOfDAAService = DatesService.ToEndOfLocalDayInUtc(
      form.patientDetailsForm.dateOfDAAService
    );
    return form;
  }
}
