<form [formGroup]="gpDetailsReferralForm" ngxsForm="hmrGPDetailsReferralFormDetailed.gpDetailsReferralForm" class="p-4">
    <div class="form-group pb-1">
        <label for="referralDate" class="input-label">Date of GP Referral</label>
        <input type="text" [class.has-error]="formControls.referralDate.invalid && (showInvalid$ | async)"
            class="form-control form-control-lg w-25"
            value="gpDetailsReferralForm.value.referralDate | date: 'DD-MM-YYYY'" formControlName="referralDate"
            id="referralDate" [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY' }" placeholder="dd-mm-yyyy" bsDatepicker>
    </div>

    <div class="form-group mb-0" formGroupName="reasonForReferral"
        [class.has-error-group]="formControls.reasonForReferral.invalid && (showInvalid$ | async)">
        <label class="input-label" for="">Reason for Referral</label>
        <div *ngFor="let item of reasonForReferralForFormArr">
            <div class="form-check">
                <label class="form-check-label pl-2">
                    <input class="form-check-input big-checkbox" [formControlName]="item.key" type="checkbox"
                        [value]="item.value" />
                    {{item.value}}</label>
            </div>
        </div>
    </div>
    <input [class.has-error]="formControls.otherReasonForReferral.invalid && gpDetailsReferralForm.value.reasonForReferral.other && (showInvalid$ | async)"
        class="form-control form-control-lg w-50 ml-4 mb-2" type="text" name="otherReasonForReferral"
        id="otherReasonForReferral" formControlName="otherReasonForReferral"
        [attr.disabled]="!gpDetailsReferralForm.value.reasonForReferral.other ? '' : null" />
    <div class="form-group pb-1">
        <label for="providerNumber" class="input-label">Provider Number of requesting general practitioner

        </label>
        <input type="text" [class.has-error]="formControls.providerNumber.invalid && (showInvalid$ | async)"
            class="form-control form-control-lg w-25" name="providerNumber" formControlName="providerNumber"
            id="providerNumber" required>
            <span class="note-label"><b>Note:</b> must contain numbers and uppercase characters only</span>
    </div>
    <div class="form-group pb-1">
        <label for="firstName" class="input-label">Requesting GP Given Name(s)
        </label>
        <input type="text" [class.has-error]="formControls.firstName.invalid && (showInvalid$ | async)"
            class="form-control form-control-lg w-25" name="firstName" formControlName="firstName" id="firstName"
            required>
    </div>
    <div class="form-group pb-1">
        <label for="surname" class="input-label">Requesting GP Family Name
        </label>
        <input type="text" [class.has-error]="formControls.surname.invalid && (showInvalid$ | async)"
            class="form-control form-control-lg w-25" name="surname" formControlName="surname" id="surname" required>
    </div>
</form>