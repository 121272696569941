<ng-container *ngIf="(sixCpa$ | async) as sixCpa; else loading">
  <ng-container *ngIf="(profile$ | async) as profile">
    <div class="fixed-blade six-cpa-blade" [@slideStateAnimation]="sixCpa.sixCpaMode">
      <div class="bg__dark color__lightest pointer fixed-blade__show" (click)="toggle(sixCpa.sixCpaMode)">
        <div *ngIf="sixCpa.sixCpaMode==='out'">
          <i class="fas fa-plus font__title-4"></i>
          <div class="font__title-2">New {{CPA_Version}}</div>
        </div>
        <div *ngIf="sixCpa.sixCpaMode==='in'">
          <i class="fas fa-times  font__title-4"></i>
          <div class="font__title-0">Close</div>
        </div>
      </div>
      <div class="fixed-blade__body bg__dark color__lightest" g-xs="p3">
        <div *ngIf="sixCpa.sixCpaMode==='in'">
          <i class="fas fa-times font__title-4 pointer" (click)="toggle(sixCpa.sixCpaMode)"></i>
        </div>
        <div [ngClass]="{'fixed-blade__body__content': canOpenServiceForm() | async}">
          <ng-container *ngIf="canOpenServiceForm() | async; else readonly">
            <div class="flex justify-content-between align-items-center">
              <h1 class="font__bold" g-xs="my3">New {{CPA_Version}}</h1>
              <ng-container *ngIf="activeServiceType !== formTypes.AIR">
                <h3 *ngIf="(progress$ | async) as progress; else noProgress" class="six-cpa-blade__progress p-3">{{progress}}% Complete</h3>
              </ng-container>
              <ng-template #noProgress>
                <h3 class="six-cpa-blade__progress p-3">0% Complete</h3>
              </ng-template>
            </div>
            <app-add-edit-medication></app-add-edit-medication>
            <app-air-statement  *ngIf="activeServiceType === formTypes.AIR"></app-air-statement>
            <app-confirm-form goBackTitle="Meds Check" [showForm]="slideMode$ | async" (goBackEvent)="goBackMedsCheck()"
              (startNewEvent)="startNewSixCpaFromMedsCheck()">
              <app-confirm-meds-check-form></app-confirm-meds-check-form>
            </app-confirm-form>
            <app-confirm-form goBackTitle="Sick Leave Certificate" [showForm]="sickLeaveSlideMode$ | async"
              (goBackEvent)="goBackSickLeave()" (startNewEvent)="startNewSixCpaFromSickLeave()">
              <app-sick-leave-confirm></app-sick-leave-confirm>
            </app-confirm-form>
            <app-confirm-form goBackTitle="Medication List" [showForm]="medListSlideMode$ | async"
              (goBackEvent)="goBackMedList()" (startNewEvent)="startNewSixCpaFromMedList()">
              <app-medication-list-confirm></app-medication-list-confirm>
            </app-confirm-form>
            <div [@slideLeftAnimation]="sixCpa.sixCpaContentMode">
              <div *ngIf="sixCpa.sixCpaSummary" class="align-items-center">
                <div class="ml-1">
                  <button class="button submit" (click)="startNewCpa()">Start New Application</button>
                  <div class="p-1" *ngFor="let summary of sixCpa.sixCpaSummary">{{summary}}</div>
                </div>
              </div>
              <div class="row align-items-center">
                <div class="col-md-5">
                  <h3>For Patient: {{profile.patient.firstname}} {{profile.patient.surname}}</h3>
                </div>
                <div class="col-md-5">
                  <h4 class="mb-1">Service Type</h4>
                  <form>
                    <select name="activeServiceType" [(ngModel)]="activeServiceType" class="form-control form-control-lg"
                      (change)="toggleSelectedformType()"
                      [disabled]="sixCpa.readOnly"
                      >
                      <option [ngValue]="formTypes.MedsCheck">Meds Check</option>
                      <option [ngValue]="formTypes.ClinicalIntervention">Clinical Intervention</option>
                      <option [ngValue]="formTypes.DAA">DAA</option>
                      <option [ngValue]="formTypes.SickLeaveCertificate">Sick Leave Certificate</option>
                      <option [ngValue]="formTypes.StagedSupply">Staged Supply</option>
                      <option [ngValue]="formTypes.HMR">HMR</option>
                      <option [ngValue]="formTypes.MedicationList">Medication List</option>
                      <option *ngIf="(features$ | async)?.air || (features$ | async)?.AIR" [ngValue]="formTypes.AIR">AIR Lookup</option>
                    </select>
                  </form>
                </div>
              </div>
              <div *ngFor="let summary of sixCpa.sixCpaSummary">{{summary}}</div>
              <ng-container [ngSwitch]="activeServiceType">
                <app-meds-check *ngSwitchCase="formTypes.MedsCheck"></app-meds-check>
                <app-clinical-intervention *ngSwitchCase="formTypes.ClinicalIntervention"></app-clinical-intervention>
                <app-dose-administration-aids *ngSwitchCase="formTypes.DAA"></app-dose-administration-aids>
                <app-sick-leave-form-list *ngSwitchCase="formTypes.SickLeaveCertificate"></app-sick-leave-form-list>
                <app-staged-supply *ngSwitchCase="formTypes.StagedSupply"></app-staged-supply>
                <app-hmr-form-list *ngSwitchCase="formTypes.HMR"></app-hmr-form-list>
                <app-medication-list-form-list *ngSwitchCase="formTypes.MedicationList"></app-medication-list-form-list>
                <app-air-lookup-form *ngSwitchCase="formTypes.AIR"></app-air-lookup-form>
              </ng-container>
              <div>
                <app-six-cpa-user-communications></app-six-cpa-user-communications>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>

    <app-meds-check-confirm-modal></app-meds-check-confirm-modal>
    <app-meds-check-send-document-modal></app-meds-check-send-document-modal>
    <app-meds-check-save-pdf-modal></app-meds-check-save-pdf-modal>
    <app-hmr-confirm-form></app-hmr-confirm-form>
  </ng-container>
</ng-container>
<ng-template #loading>
  <app-loader></app-loader>
</ng-template>
<ng-template #readonly>
  <lib-unlock-services>Readonly</lib-unlock-services>
</ng-template>