import { Component, OnInit, Input, ViewChild, AfterViewInit } from '@angular/core';
import { Email } from '../../../../../shared/models/email.model';
import { detectEScript, extractEScript } from '../../../../../shared/helpers/eScript.helper';
import { SetEScriptToken, ToggleEScriptModal } from '../../../../../shared/components/escripts/state/escripts.actions';
import { Store, Select } from '@ngxs/store';
import { EmailState } from '../state/email.state';
import { Observable } from 'rxjs';
import { ToggleModal } from '../state/email.actions';
import { DirtyEmailTrailConstants } from '../../../../../shared/constants/dirty-email-trail.constant';
import { RegularExpressions } from '../../../../../shared/constants/regular-expressions';
import { ProfileState } from '../../../../core/profile/state/profile.state';
import { NgxAutoScroll } from 'ngx-auto-scroll';

@Component({
  selector: 'app-email-history',
  templateUrl: './email-history.component.html',
  styleUrls: ['./email-history.component.scss']
})
export class EmailHistoryComponent implements AfterViewInit {
  @Input() emailHistory: Email[] = [];
  @Select(EmailState.showModal) showModal$: Observable<boolean>;
  @ViewChild(NgxAutoScroll) ngxEmailAutoScroll: NgxAutoScroll;
  fullEmail = '';

  constructor(private store: Store) { }

  ngAfterViewInit(): void {
    this.forceScrollDown();
  }

  forceScrollDown(): void {
    this.ngxEmailAutoScroll.forceScrollDown();
  }

  checkForEScript(message: string) {
    return detectEScript(message);
  }

  openEScriptModal(message: string) {
    const scriptToken = extractEScript(message);
    this.store.dispatch([new SetEScriptToken(scriptToken), new ToggleEScriptModal(true)]);
  }

  showFullEmail(html: string) {
    this.fullEmail = html;
    this.store.dispatch(new ToggleModal(true));
  }

  isCustomer(fromMail) {
    const email = this.store.selectSnapshot(ProfileState.emailAddress);
    return email === fromMail;
  }

  formatEmailContent(content) {
    if (!content) {
      return content;
    }

    content = this.removeDuplicateLinks(content);
    content = this.replaceUrls(content);
    content = this.addNewLineBreaks(content);
    content = this.addLineBreaksForOlderTrail(content);
    content = this.removeCopyrightTrail(content);

    return content;
  }

  private replaceUrls(content: string) {
    return content.replace(RegularExpressions.urlRegex, function (url) {
      return `<a target="_blank" href="${url}"><br />${url}<br /></a>`;
    });
  }

  private addNewLineBreaks(content: string) {
    const emailTrailSeparator = DirtyEmailTrailConstants.latestForwardedEmail;
    var expression = new RegExp(emailTrailSeparator, "g")
    return content.replace(expression, function (separation) {
      return `<span><br />${separation}<br /></span>`;
    });
  }

  private addLineBreaksForOlderTrail(content: string) {
    const emailTrailSeparator = DirtyEmailTrailConstants.forwardedEmail;
    var expression = new RegExp(emailTrailSeparator, "g")
    return content.replace(expression, function (separation) {
      return `<span><br />${separation}<br /></span>`;
    });
  }

  private removeCopyrightTrail(content: string) {
    if (content.includes(DirtyEmailTrailConstants.copyright)) {
      content = content.substring(0, content.indexOf(DirtyEmailTrailConstants.copyright))
    }

    return content;
  }

  private removeDuplicateLinks(content: string) {
    var expression = new RegExp(RegularExpressions.emailDuplicateUrlRegex, "g")
    return content.replace(expression, function () {
      return '';
    });
  }
}
