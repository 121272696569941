<form [formGroup]="serviceProviderDetailsForm" ngxsForm="hmrServiceProviderDetailsFormDetailed.serviceProviderDetailsForm" class="p-4">
    <div class="form-group pb-1">
        <label for="serviceProviderName" class="input-label">Service Provider Name
        </label>
        <input type="text" 
        [class.has-error]="formControls.serviceProviderName.invalid && (showInvalid$ | async)"
        class="form-control form-control-lg w-50" name="serviceProviderName"
            formControlName="serviceProviderName" id="serviceProviderName" required>
    </div>
    <div class="form-group pb-1">
        <label for="serviceProviderPhoneNumber" class="input-label">Service Provider Phone Number
        </label>
        <input type="text" 
        [class.has-error]="formControls.serviceProviderPhoneNumber.invalid && (showInvalid$ | async)"
        class="form-control form-control-lg w-50" name="serviceProviderPhoneNumber"
            formControlName="serviceProviderPhoneNumber" id="serviceProviderPhoneNumber" required>
    </div>
    <div class="form-group pb-1">
        <label for="serviceProviderEmail" class="input-label">Service Provider Email Address
        </label>
        <input type="text"
        [class.has-error]="formControls.serviceProviderEmail.invalid && (showInvalid$ | async)"
         class="form-control form-control-lg w-50" name="serviceProviderEmail"
            formControlName="serviceProviderEmail" id="serviceProviderEmail" required>
    </div>
    <ng-container *ngIf="(pharmacists$ | async) as pharmacists">
        <div class="form-group pb-1">
          <label for="pharmacist" class="input-label">Select Pharmacist</label>
          <select 
          [class.has-error]="formControls.pharmacist.invalid && (showInvalid$ | async)"
          formControlName="pharmacist" class="form-control form-control-lg w-50" (change)="setPharmacistData()">
            <option *ngFor="let p of pharmacists" value="{{p.givenNames}} {{p.familyName}}">{{p.givenNames}} {{p.familyName}}</option>
          </select>
        </div>
      </ng-container>
    <div class="form-group pb-1">
        <label for="pharmacistGivenName" class="input-label">Registered Pharmacist Given Name(s)

        </label>
        <input type="text"
        [class.has-error]="formControls.pharmacistGivenName.invalid && (showInvalid$ | async)"
         class="form-control form-control-lg w-50" name="pharmacistGivenName"
            formControlName="pharmacistGivenName" id="pharmacistGivenName" required readonly>
    </div>
    <div class="form-group pb-1">
        <label for="pharmacistFamilyName" class="input-label">Registered Pharmacist Family Name
        </label>
        <input type="text" 
        [class.has-error]="formControls.pharmacistFamilyName.invalid && (showInvalid$ | async)"
        class="form-control form-control-lg w-50" name="pharmacistFamilyName"
            formControlName="pharmacistFamilyName" id="pharmacistFamilyName" required readonly>
    </div>
    <div class="form-group pb-1">
        <label for="ahpraNumber" class="input-label">AHPRA number
        </label>
        <input type="text" 
        [class.has-error]="formControls.ahpraNumber.invalid && (showInvalid$ | async)"
        class="form-control form-control-lg w-50" name="ahpraNumber"
            formControlName="ahpraNumber" id="ahpraNumber" required readonly>
    </div>
    <div class="form-group pb-1">
        <label for="pharmacistPhoneNumber" class="input-label">Registered Pharmacist Phone Number
        </label>
        <input type="text" 
        [class.has-error]="formControls.pharmacistPhoneNumber.invalid && (showInvalid$ | async)"
        class="form-control form-control-lg w-50" name="pharmacistPhoneNumber"
            formControlName="pharmacistPhoneNumber" id="pharmacistPhoneNumber" required readonly>
    </div>
    <div class="form-group pb-1">
        <label for="pharmacistEmail" class="input-label">Registered Pharmacist Email Address
        </label>
        <input type="text" 
        [class.has-error]="formControls.pharmacistEmail.invalid && (showInvalid$ | async)"
        class="form-control form-control-lg w-50" name="pharmacistEmail"
            formControlName="pharmacistEmail" id="pharmacistEmail" required readonly>
    </div>
</form>