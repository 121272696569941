<section class="mt-2">
  <div class="text-center">
    <h3>Dispense Run Paramaters</h3>
  </div>
  <hr />
  <form [formGroup]="dispenseRunRequestForm" class="mt-2">
    <div class="row">
      <div class="form-group col">
        <label class="font-weight-bold">Filter by Groups</label>
        <ng-container *ngIf="(groups$ | async) as groups">
          <select multiple class="form-control" name="groups" formControlName="groups">
            <option *ngFor="let group of groups" [ngValue]="group.id">{{group.name}}</option>
          </select>
        </ng-container>
      </div>
      <div class="form-group col">
        <label class="font-weight-bold">Filter by Categories</label>
        <ng-container *ngIf="(categories$ | async) as categories">
          <select multiple class="form-control" name="status" formControlName="selectedCategories">
            <option *ngFor="let category of categories" [ngValue]="category.id">{{category.name}}</option>
          </select>
        </ng-container>
      </div>
    </div>
    <div class="row ml-2">
      <div class="form-check col-3">
        <input class="form-check-input" (click)="clearFormValue('targetDoh')" type="checkbox" formControlName="alternateDoh" />
        <label class="form-check-label font-weight-bold">Override DOH Target?</label>
      </div>
      <div class="form-check col-3">
        <input class="form-check-input" (click)="clearFormValue('dispenseInterval')" type="checkbox" formControlName="alternateDispenseInterval" />
        <label class="form-check-label font-weight-bold">Override Dispense Interval?</label>
      </div>
      <div class="form-check col-3">
        <input class="form-check-input" type="checkbox" formControlName="excludeNoRepeats" />
        <label class="form-check-label font-weight-bold">Exclude Medicines with no repeats remaining?</label>
      </div>
    </div>
    <div class="row mt-1">
      <div *ngIf="!!dispenseRunRequestForm.value.alternateDoh" class="form-group ml-1 col-3">
        <label class="font-weight-bold">New Target</label>
        <input class="form-control" type="number" formControlName="targetDoh" (keypress)="enforceMaxLength($event.target.value)" max="99999" />
      </div>
      <div *ngIf="!dispenseRunRequestForm.value.alternateDoh" class="form-group col-3">

      </div>
      <div *ngIf="!!dispenseRunRequestForm.value.alternateDispenseInterval" class="form-group col-3">
        <label class="font-weight-bold">New Target</label>
        <input class="form-control" type="number" formControlName="dispenseInterval" (keypress)="enforceMaxLength($event.target.value)" max="99999" />
      </div>
    </div>
    <div class="row ml-2">
        <div class="form-group">
          <button class="button report-button btn__yellow" (click)="generateReport()">Generate Report</button>
        </div>
    </div>
  </form>
</section>
