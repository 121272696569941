import { Component } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { MedsCheckFormListState, MedsCheckType } from '../state/meds-check-form-list.state';
import { Observable, forkJoin } from 'rxjs';
import { PatientDetailsFormDetailedState } from '../../patient-details-form/state/patient-details-form-detailed.state';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { CloseMedsCheckConfirmFormAction, SubmitNewMedsCheck, SubmitExisitingMedsCheck } from '../state/meds-check-form-list.actions';
import { MedsCheckFormsProgressService } from '../state/meds-check-forms-progress.service';
import { filter, switchMap, map } from 'rxjs/operators';
import { MedsCheckFormsService } from '../state/meds-check-forms.service';
import { ProfileState } from '../../../../core/profile/state/profile.state';

@Component({
  selector: 'app-meds-check-confirm-modal',
  templateUrl: './meds-check-confirm-modal.component.html'
})
export class MedsCheckConfirmModalComponent {
  @Select(MedsCheckFormListState.medsCheckType) medsCheckType$: Observable<MedsCheckType>;
  @Select(MedsCheckFormListState.showConfirmForm) showConfirmForm$: Observable<boolean>;
  @Select(PatientDetailsFormDetailedState.patientHasDiabetes) patientHasDiabetes$: Observable<boolean>;
  constructor(private store: Store) {
  }

  @Dispatch()
  closeConfirmMedsCheck = () => new CloseMedsCheckConfirmFormAction()

  @Dispatch()
  submitBasicMedsCheck = () =>
    this.store.selectOnce(MedsCheckFormsProgressService.getProgressBasic).pipe(
      filter(progress => progress === 100),
      switchMap(() => forkJoin([
        this.store.selectOnce(MedsCheckFormsService.getMedsCheckBasicRequest),
        this.store.selectOnce(ProfileState.clientId)
      ])),
      map(([formData, clientId]) =>
        formData && formData.sixCpaFormId !== null
          ? new SubmitExisitingMedsCheck(clientId, formData)
          : new SubmitNewMedsCheck(clientId, formData)))
}
