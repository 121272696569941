import { Component, OnInit } from '@angular/core';
import { PharmacyInformationFormState } from '../pharmacy-information-form/state/pharmacy-information-form.state';
import { Observable } from 'rxjs';
import { Select } from '@ngxs/store';

@Component({
  selector: 'app-pharmacy-info-form-list-item',
  templateUrl: './pharmacy-info-form-list-item.component.html'
})
export class PharmacyInfoFormListItemComponent implements OnInit {
  @Select(PharmacyInformationFormState) pharmacyInformationForm$: Observable<any>;

  constructor() { }

  ngOnInit() {
  }

}
