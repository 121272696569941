import { OnInit, OnDestroy, OnChanges, Injectable } from "@angular/core";
import { Subscription } from "rxjs";
import { Actions, ofActionSuccessful } from "@ngxs/store";
import { ResetMedicationListFormListAction } from "./medication-list-form-list/state/medication-list-form.actions";

@Injectable()
export abstract class MedicationListChildForm implements OnInit, OnDestroy, OnChanges {
  private onResetFormSubscription: Subscription;

  constructor(
    protected actions$: Actions
  ) {
    this.configureLifecycleHooks();
  }

  ngOnInit() { }

  ngOnDestroy() {
    this.destroy();
  }

  ngOnChanges() { }

  protected abstract actionOnResetForm();

  private init(): void {
    this.onResetFormSubscription = this.getResetFormSubscription();
  }

  private destroy(): void {
    if (this.onResetFormSubscription) {
      this.onResetFormSubscription.unsubscribe();
    }
  }

  private configureLifecycleHooks() {
    this.init();
    const onDestroy = this.ngOnDestroy;
    const onInit = this.ngOnInit;
    this.ngOnInit = () => { this.init(); onInit.apply(this); };
    this.ngOnDestroy = () => { this.destroy(); onDestroy.apply(this); };
  }

  private getResetFormSubscription(): Subscription {
    return this.actions$.pipe(ofActionSuccessful(ResetMedicationListFormListAction))
      .subscribe(() => this.actionOnResetForm());
  }
}
