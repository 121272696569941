import { Component, OnInit } from '@angular/core';
import { StagedSupplyChildForm } from '../../staged-supply-child-form';
import { Subscription } from 'rxjs';
import { Store, Actions } from '@ngxs/store';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { SSAdditionalDetailsFormState } from './state/ss-additional-details-form.state';
import { SetSSAdditionalDetailsProgress } from './state/ss-additional-details-form.actions';
import * as moment_ from 'moment';
const moment = moment_;
import { CalendarMonths } from '../../../../../shared/models/six-cpa/FormKeyValuePairs';

import { SixCpaState } from '../../state/six-cpa.state';
@Component({
  selector: 'app-ss-additional-details-form',
  templateUrl: './ss-additional-details-form.component.html',
  styleUrls: ['./ss-additional-details-form.component.scss']
})
export class SSAdditionalDetailsFormComponent extends StagedSupplyChildForm implements OnInit {
  private formSubscription: Subscription;
  private readonlySubscription: Subscription;
  additionalDetailsForm: FormGroup;
  calendarMonths = CalendarMonths;

  constructor(private formBuilder: FormBuilder, private store: Store, actions: Actions) {
    super(actions);
    this.generateFormControls();
  }

  ngOnInit() {
    this.formSubscription = this.store.select(SSAdditionalDetailsFormState.additionalDetailsForm)
      .subscribe(() => this.onFormChange());
    this.readonlySubscription = this.store.select(SixCpaState.formsReadOnly)
      .subscribe(readonly => readonly ? this.additionalDetailsForm.disable() : this.additionalDetailsForm.enable());
  }

  ngOnDestroy() {
    this.formSubscription.unsubscribe();
    this.readonlySubscription.unsubscribe();
  }
  get formControls() {
    return this.additionalDetailsForm.controls;
  }
  protected actionOnResetForm() {
    this.additionalDetailsForm.patchValue({
      claimedCalendarMonth: 1,
      claimedCalendarYear: 2019,
      amountOfDaysInMonthPatientVisitedForStagedSupplyMedication: null,
      dateOfPatientsFinalDosingBatch: moment(new Date()).toDate()
    })
  }

  private onFormChange() {
    const controls = Object.keys(this.additionalDetailsForm.controls);
    const completedItems = this.getTotalValidItems(controls);
    this.store.dispatch(new SetSSAdditionalDetailsProgress(controls.length, completedItems));
  }

  private getTotalValidItems(controls: Array<string>) {
    return controls.filter((key) => {
      const formControl = this.additionalDetailsForm.get(key);
      return  formControl.status === 'VALID' || (formControl.status === 'DISABLED' && !formControl.invalid && formControl.errors === null);
    }).length;
  }

  private generateFormControls() {
    this.additionalDetailsForm = this.formBuilder.group({
      claimedCalendarMonth: new FormControl(this.calendarMonths.January, Validators.required),
      claimedCalendarYear: new FormControl(2019, Validators.required),
      amountOfDaysInMonthPatientVisitedForStagedSupplyMedication: new FormControl(null, Validators.required),
      dateOfPatientsFinalDosingBatch: new FormControl(moment(new Date()).toDate())
    });
  }
}
