import { Selector } from '@ngxs/store';
import { SickLeaveConsentAndEligibilityState } from '../../sick-leave-consent-and-eligibility/state/sick-leave-consent-and-eligibility.state';
import { SickLeaveCertificateTypeState } from '../../sick-leave-certificate-type/state/sick-leave-certificate-type.state';
import { SickLeaveConsulationState } from '../../sick-leave-consulation/state/sick-leave-consulation.state';
import { SickLeavePharmacistDeclarationState } from '../../sick-leave-pharmacist-declaration/state/sick-leave-pharmacist-declaration.state';
import { SickLeavePatientDetailsState } from '../../sick-leave-patient-details/state/sick-leave-patient-details.state';

export class SickLeaveFormsProgressService {
    @Selector([
        SickLeavePatientDetailsState.totalItems,
        SickLeavePatientDetailsState.completedItems,
        SickLeaveConsentAndEligibilityState.totalItems,
        SickLeaveConsentAndEligibilityState.completedItems,
        SickLeaveCertificateTypeState.totalItems,
        SickLeaveCertificateTypeState.completedItems,
        SickLeaveConsulationState.totalItems,
        SickLeaveConsulationState.completedItems,
        SickLeavePharmacistDeclarationState.totalItems,
        SickLeavePharmacistDeclarationState.completedItems,
    ])
    static getProgress(
        patientDetailsTotal,
        patientDetailsCompleted,
        consentAndEligibilityTotal,
        consentAndEligibilityCompleted,
        certificateTypeTotal,
        certificateTypeCompleted,
        consulationTotal,
        consulationCompleted,
        pharmacistDeclarationTotal,
        pharmacistDeclarationCompleted,
    ) {
        return Math.floor(
            (patientDetailsCompleted + consentAndEligibilityCompleted + certificateTypeCompleted + consulationCompleted + pharmacistDeclarationCompleted)
        / (patientDetailsTotal + consentAndEligibilityTotal + certificateTypeTotal + consulationTotal + pharmacistDeclarationTotal) * 100) ;
    }
}
