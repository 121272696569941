import { Inject, Pipe, PipeTransform } from '@angular/core';
@Pipe({
    name: 'appNoteTypeLink'
})
export class NoteTypeLinkPipe implements PipeTransform {
  isLite = false;

  constructor(@Inject('environment') env: any) {
    this.isLite = env.isLite;
  }


  transform(item: any): string {
    const linkPrefix = this.isLite ? '' : '/app/';
    switch (item.notificationType) {
      case 'General':
      case 'Dispense':
      case 'Awaiting Pickup':
      case 'Patient Note':
      return `${linkPrefix}profile/${item.clientId}/notes`;
      case 'Order':
      case 'Order Cycle':
      return `${linkPrefix}profile/${item.clientId}/orders/create`;
      case 'Email':
      case 'Email Reply':
      return `${linkPrefix}profile/${item.clientId}/comms/email`;
      case 'Phone call':
      return `${linkPrefix}profile/${item.clientId}/comms/phone`;
      case 'Scrypt Message':
      return this.isLite ? `${linkPrefix}profile/${item.clientId}`:`${linkPrefix}profile/${item.clientId}/comms/messenger`;
      case 'SMS':
      case 'SMS Reply':
      return `${linkPrefix}profile/${item.clientId}/comms/sms`;
      case 'Mail':
      return `${linkPrefix}profile/${item.clientId}/comms/mail`;
      case 'Customer Order':
      return item.link;
      default:
      return `${linkPrefix}profile/${item.clientId}`;
    }
  }
}
