import { AslMedication } from '../models/asl-medication.model';

export enum AslMedicationActionTypes {
  SET_MEDICATIONS = '[Asl Medications] Set',
  GET_MEDICATIONS_ERROR = '[Asl Medications] Error',
  REFRESH_MEDICATIONS = '[Asl Medications] Refresh',
  TOGGLE_UNCATEGORISED = '[Asl Medications] Toggle uncategorised',
  CLEAR_ASL_MEDICATION_STATE = '[Asl Medications] CLEAR_ASL_STATE',
}
export class SetAslMedicationsAction {
  static readonly type = AslMedicationActionTypes.SET_MEDICATIONS;
  constructor(public medications: AslMedication[]) { }
}
export class GetAslMedicationsErrorAction {
  static readonly type = AslMedicationActionTypes.GET_MEDICATIONS_ERROR;
  constructor(public error: any) {}
}
export class RefreshAslMedicationsAction {
  static readonly type = AslMedicationActionTypes.REFRESH_MEDICATIONS;
  constructor(public ihiNumber: string, public firstName: string, public lastName: string) { }
}

export class ToggleAslMedicationAction {
  static readonly type = AslMedicationActionTypes.TOGGLE_UNCATEGORISED;
}
export class ClearASLMedicationState {
  static readonly type = AslMedicationActionTypes.CLEAR_ASL_MEDICATION_STATE;
}
