<form [formGroup]="medicationInformationForm" ngxsForm="medicationInformationForm.medicationInformationForm"
  class="p-4">
  <div class="form-group pb-1" formGroupName="medsCheckServiceReasons" 
  [class.has-error-group]="formControls.medsCheckServiceReasons.invalid && (showInvalid$ | async)">
    <label class="input-label-not-required" for="">Reason for MedsCheck Service</label>
    <div *ngFor="let reason of medsCheckReasonsForForm | keyvalue">
      <div class="form-check">
        <input class="form-check-input big-checkbox" [formControlName]="reason.key" type="checkbox"
          [value]="reason.value" [id]="reason.key">
        <label class="form-check-label pl-2" [for]="reason.key">{{reason.value}}</label>
      </div>
    </div>
  </div>
  <div class="form-group pb-1">
    <label class="input-label-not-required" for="">Total Number of Medicines</label>
    <div class="form-row">
      <div class="col-3">
        <p class="col-form-label-lg">Prescription</p>
        <input 
        [class.has-error]="formControls.prescriptionMedicines.invalid && (showInvalid$ | async)"
        type="text" formControlName="prescriptionMedicines" class="form-control form-control-lg" placeholder="0">
      </div>
      <div class="col-3">
        <p class="col-form-label-lg">Non-Prescription</p>
        <input 
        [class.has-error]="formControls.nonPrescriptionMedicines.invalid && (showInvalid$ | async)"
         type="text" formControlName="nonPrescriptionMedicines" class="form-control form-control-lg"
          placeholder="0">
      </div>
    </div>
  </div>
  <div class="form-group mb-0" formGroupName="medicationHealthConditions" (change)="deselectOtherBox()" 
  [class.has-error-group]="formControls.medicationHealthConditions.invalid && (showInvalid$ | async)">
    <label class="input-label-not-required" for="">What health conditions/co-morbidities is the patient taking
      medications for?
    </label>
    <div *ngFor="let condition of healthConditionsForFormArr">
      <div class="form-check">
        <input class="form-check-input big-checkbox" [formControlName]="condition.key" type="checkbox"
          [value]="condition.value" [id]="condition.key">
        <label class="form-check-label pl-2" [for]="condition.key">{{condition.value}}</label>
      </div>
    </div>
  </div>
  <input 
  [class.has-error]="formControls.medicationHealthConditionsOtherDetails.invalid && (showInvalid$ | async)" class="form-control form-control-lg w-50 ml-4 mb-2" type="text" name="medicationHealthConditionsOtherDetails"
    id="medicationHealthConditionsOtherDetails" formControlName="medicationHealthConditionsOtherDetails"
    [attr.disabled]="!medicationInformationForm.value.medicationHealthConditions.other ? '' : null">
  <div class="form-group pb-1">
    <label class="input-label" for="medIndexScore">Patients MedIndex Score</label><span (click)="openMedIndexCalculator()" class="medindex-link pl-1">You can calculate the patient's MedIndex score using this spreadsheet</span>
    <input
    [class.has-error]="formControls.medIndexScore.invalid && (showInvalid$ | async)"
     type="text" class="form-control form-control-lg w-25" formControlName="medIndexScore" value=""
      id="medIndexScore">
  </div>
</form>