import { Component, OnInit, OnDestroy, InjectionToken } from '@angular/core';
import { ConsentEligibilityFormBaseComponent } from '../consent-eligibility-form-base.component';
import { Observable, Subscription } from 'rxjs';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { Store, Actions, Select } from '@ngxs/store';
import { ConsentEligibilityFormDetailedState } from '../state/consent-eligibility-form-detailed.state';
import * as moment_ from 'moment';
const moment = moment_;
import { CurrentLivingStatus } from '../../../../../shared/models/six-cpa/consent-and-eligibility-form.model';

import { SixCpaState } from '../../state/six-cpa.state';
import { MedsCheckFormListState, MedsCheckType } from '../../meds-check/state/meds-check-form-list.state';
import { ConsentDocumentConstants } from '../consent-document.constants';
import { DocumentOrigin } from '../../upload-document-form/services/document-storage.service';
export const CONSENT_FORM_DEFAULT_CHECKUP_DATE: InjectionToken<Date> = new InjectionToken('CONSENT_FORM_DEFAULT_CHECKUP_DATE');

@Component({
  selector: 'app-consent-eligibility-form-detailed',
  templateUrl: './consent-eligibility-form-detailed.component.html',
  styleUrls: ['./../consent-eligibility-form.component.scss'],
  providers: [
    { provide: CONSENT_FORM_DEFAULT_CHECKUP_DATE, useValue: moment(new Date()).add(6, 'M').toDate() }
  ]
})

export class ConsentEligibilityFormDetailedComponent extends ConsentEligibilityFormBaseComponent implements OnInit, OnDestroy {
  private formSubscription: Subscription;
  private readonlySubscription: Subscription;
  livingStatus = CurrentLivingStatus;
  defaultCheckUpDate = moment(new Date()).add(6, 'M').toDate();
  documentOrigins=DocumentOrigin;
  @Select(MedsCheckFormListState.medsCheckType) medsCheckType$: Observable<MedsCheckType>;
  constructor(formBuilder: FormBuilder, store: Store, actions: Actions) {
    super(formBuilder, store, actions);
    this.consentEligibilityForm.addControl('hasPreviousMedsCheck', new FormControl(false, Validators.required));
    this.consentEligibilityForm.addControl('currentLivingStatus', new FormControl(0, Validators.required));
    this.consentEligibilityForm.addControl('currentLivingOtherDetails', new FormControl(''));
    this.consentEligibilityForm.addControl('gpOrHospitalLastSixMonthsForMedicationProblems', new FormControl(false));
    this.consentEligibilityForm.addControl('medicineManagementSupport', new FormControl(0, Validators.required));
    this.consentEligibilityForm.addControl('followUpService', new FormControl(this.defaultCheckUpDate, Validators.required));
  }

  ngOnInit() {
    this.formSubscription = this.store.select(ConsentEligibilityFormDetailedState.form)
      .subscribe(() => this.onFormChange());
    this.readonlySubscription = this.store.select(SixCpaState.formsReadOnly)
      .subscribe(readonly => readonly ? this.consentEligibilityForm.disable() : this.consentEligibilityForm.enable());
  }

  ngOnDestroy() {
    this.formSubscription.unsubscribe();
    this.readonlySubscription.unsubscribe();
  }

  deselectOtherBox() {
    if(this.consentEligibilityForm.value.currentLivingStatus != this.livingStatus.Other) {
      this.consentEligibilityForm.controls['currentLivingOtherDetails'].setValue('');
    }
  }

  getConsentDocumentLink(formType: MedsCheckType){
    switch(formType) {
      case MedsCheckType.PatientRegistration: return ConsentDocumentConstants.medscheckConsent;
      case MedsCheckType.Regular: return ConsentDocumentConstants.medscheckConsent;
      case MedsCheckType.Diabetes: return ConsentDocumentConstants.diabetesConsent;
    }
  }
}
