import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReportBladeComponent } from './report-blade.component';
import { RedirectService } from '../../../shared/services/redirect.service';
import { FormsModule } from '@angular/forms';
import { AttendingDoctorModule } from '../attending-doctor/attending-doctor.module';
import { LoaderModule } from '../../../modules/core/loader/loader.module';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';

@NgModule({
  declarations: [
    ReportBladeComponent
  ],
  providers: [
    RedirectService
  ],
  imports: [
    CommonModule,
    FormsModule,
    LoaderModule,
    // TODO: This should not be required in the future because report-blade component  should not be a smart component
    AttendingDoctorModule,
    BsDatepickerModule.forRoot()
  ],
  exports: [
    ReportBladeComponent
  ]
})
export class ReportBladeModule { }
