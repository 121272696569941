<ng-container *ngIf="(sickLeaveConfirm$ | async) as sickLeaveConfirm">
  <div class="confirm-form">
    <div class="accordian" *ngIf="(certificate$ | async) as certificate">
      <div class="row align-items-center top-panel-active">
        <div class="px-3 py-2 col-8 ">
          <div class="title">Sick Leave Certificate</div>
        </div>
      </div>
      <div class="form p-4">
        <app-download-pdf *ngIf="!certificate.loading; else loadingPatientReport"
            [name]="certificate.name" (downloadReportEvent)="getCertificate()">
        </app-download-pdf>
        <p class="col-form-label-lg font__bold">Send Medication List by email, SMS or Scrypt Mobile</p>
        <div class="d-flex align-items-center">
            <button (click)="onSubmit()" class="btn__green mt-2">Send report</button>
            <i *ngIf="sickLeaveConfirm.loading"
                class="fas fa-sync fa-spin font__title-2 ml-2 mt-2"></i>
        </div>
        <ng-template #loadingPatientReport>
            <app-loader></app-loader>
        </ng-template>
      </div>
    </div>

    <form class="confirm-meds-check__form" [formGroup]="doctorDetailsForm" ngxsForm="sickLeaveConfirm.doctorDetailsForm"
      (submit)="sendDoctorReport()">
      <div class="accordian" *ngIf="(doctorReport$ | async) as doctorReport">
        <div class="row align-items-center top-panel-active">
          <div class="px-3 py-2 col-8 ">
            <div class="title">Sick Leave Report</div>
          </div>
          <div class="w-100">
            <div class="form-group px-4 pt-4">
              <p class="col-form-label-lg">Recipient's Full Name</p>
              <input formControlName="fullName" [typeahead]="sickLeaveConfirm.doctors" typeaheadOptionField="fullName"
                class="form-control form-control-lg typeahead" (typeaheadOnSelect)="onSelect($event)"
                placeholder="Start typing a name...">
            </div>
            <div class="form-group p-4">
              <p class="col-form-label-lg">Recipient's Address</p>
              <textarea type="text" formControlName="doctorAddress" class="form-control form-control-lg"
              id="doctorAddress" ></textarea>
            </div>
            <app-customise-doctor-report *ngIf="sickLeaveConfirm.slideMode === 'in'"></app-customise-doctor-report>
          </div>
        </div>
        <div class="form p-4">
          <app-loader *ngIf="doctorReport.loading; else downloadPdf"></app-loader>
          <ng-template #downloadPdf>
            <app-download-pdf [name]="doctorReport.name" (downloadReportEvent)="getDoctorReport()">
            </app-download-pdf>
          </ng-template>
          <p class="col-form-label-lg font__bold">Email Report</p>
          <div class="form-group">
            <p class="col-form-label-lg">Email Address</p>
            <input type="text" formControlName="email" class="form-control form-control-lg" placeholder="Email Address">
          </div>
          <div class="d-flex align-items-center">
            <button type="submit" [disabled]="doctorDetailsForm.invalid || sickLeaveConfirm.loading"
              class="btn__green mt-2">Send report to doctor</button>
            <i *ngIf="sickLeaveConfirm.loading" class="fas fa-sync fa-spin font__title-2 ml-2 mt-2"></i>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="response">
    <app-report-response [showMode]="sickLeaveConfirm.showResponse" (hideEvent)="hideResponse()">
    </app-report-response>
  </div>
</ng-container>
<app-channel-select-modal *ngIf="(showSendDocumentForm$ | async)" (submit)="onChannelSelected($event)" (cancel)="onChannelSelectCancelled()"></app-channel-select-modal>
