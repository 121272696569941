import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HMRLocationComponent } from './hmr-location.component';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import { ReactiveFormsModule } from '@angular/forms';
import { RadioHighlightModule } from '../../../../../shared/components/radio-highlight/radio-highlight.module';

@NgModule({
  declarations: [HMRLocationComponent],
  imports: [
    CommonModule,
    NgxsFormPluginModule,
    ReactiveFormsModule,
    RadioHighlightModule
  ],
  exports: [HMRLocationComponent]
})
export class HMRLocationModule { }
