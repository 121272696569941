import { OrderRequestStatus } from "@profile/order-requests/models/order.enums";
import { StatusFilters } from "./order-mgmt.types";

export const OrderRequestSortConstants = {
  requestedDateAscending: 'req-date-asc',
  requestedDateDescending: 'req-date-desc',
  updatedDateAscending: 'upd-date-asc',
  updatedDateDescending: 'upd-date-desc',
}

// Used when reducing the dictionary coming back from the get order requests endpoint
export const IncomingStatuses = [
  OrderRequestStatus[OrderRequestStatus.Pending]
].map(s => s.charAt(0).toLowerCase() + s.slice(1));

export const PartiallyReadyStatuses = [
  OrderRequestStatus[OrderRequestStatus.PartiallyReady]
].map(s => s.charAt(0).toLowerCase() + s.slice(1));

export const CompletedStatuses = [
  OrderRequestStatus[OrderRequestStatus.Ready],
  OrderRequestStatus[OrderRequestStatus.UnableToFill]
].map(s => s.charAt(0).toLowerCase() + s.slice(1));

export const CompletedOrdersStatusFilterOptions = [
  {
    value: OrderRequestStatus.Ready,
    label: 'Ready'
  },
  {
    value: OrderRequestStatus.UnableToFill,
    label: 'Unable To Fill'
  }
];

export const TabToStatusFilterOptions = {
  'Completed Orders': CompletedOrdersStatusFilterOptions
}

export const TabToSortByFilter = {
  'Incoming Orders': OrderRequestSortConstants.requestedDateAscending,
  'Partially Ready Orders': OrderRequestSortConstants.requestedDateAscending,
  'Completed Orders': OrderRequestSortConstants.updatedDateDescending
}

export const DefaultPageSize = 50 as const;

/**
 * Default status filters for each tab
 * @internal Intended for use only within the OrderManagement module only.
 */
export const DefaultStatusFilters: StatusFilters = {
  'Incoming Orders': [
    OrderRequestStatus.Pending,
    OrderRequestStatus.PartiallyReady
  ],
  'Partially Ready Orders': [
    OrderRequestStatus.Pending,
    OrderRequestStatus.PartiallyReady
  ],
  'Completed Orders': [
    OrderRequestStatus.Pending,
    OrderRequestStatus.PartiallyReady,
    OrderRequestStatus.Ready,
    OrderRequestStatus.UnableToFill
  ]
}

export const TabNames = ['Incoming Orders', 'Partially Ready Orders', 'Completed Orders'] as const;

export const OrderStatusLabelMap: { [key in OrderRequestStatus]?: string } = {
  [OrderRequestStatus.Pending]: 'Pending',
  [OrderRequestStatus.PartiallyReady]: 'Partially Ready',
  [OrderRequestStatus.Ready]: 'Ready',
  [OrderRequestStatus.UnableToFill]: 'Unable to fill'
};

export const OrderStatusColourMap: { [key in OrderRequestStatus] ?: string } = {
  [OrderRequestStatus.Pending]: '#FFA500',
  [OrderRequestStatus.PartiallyReady]: '#096DD9',
  [OrderRequestStatus.Ready]: '#98CF98',
  [OrderRequestStatus.UnableToFill]: '#FF9291'
};
