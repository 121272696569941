import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { BulkMessage, BulkScheduledMessageRecipientStatus, Recipient } from '../../../shared/models/communication/bulk-message.model';
import { messageIsProcessing } from '../new-bulk-message/helpers/date-helpers';
import { EditNewBulkMessage } from '../new-bulk-message/state/new-bulk-message-form.actions';
import { BulkMessageForm } from '../new-bulk-message/state/new-bulk-message-form.state';
import { MessageSource } from '~/shared/models/messageHistory.model';
import { GetBulkMessageWithRecipients, SetBulkMessageRecipients } from '../state/bulk-messaging.actions';
import { BulkMessagingState } from '../state/bulk-messaging.state';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-bulk-messaging-messages-table',
  templateUrl: './bulk-messaging-messages-table.component.html',
  styleUrls: ['./bulk-messaging-messages-table.component.scss']
})
export class BulkMessagingMessagesTableComponent {
  @Select(BulkMessagingState.currentBulkMessage) currentBulkMessage$: Observable<BulkMessage>;
  @Select(BulkMessagingState.loadingCurrentMessage) loadingCurrentMessage$: Observable<boolean>;
  
  @Input() bulkMessages: BulkMessage[];
  @Input() canDeleteMessages: boolean
  @Output() onDelete: EventEmitter<number> = new EventEmitter<number>();
  @Output() onOpenEditModal: EventEmitter<any> = new EventEmitter();
  @Input() isHistoric: boolean = false;
  showRecipientsModal: boolean = false;
  expandSet = new Set<number>();

  constructor(private store: Store) { }

  onExpandChange(id: number, checked: boolean): void {
    if (checked) {
      this.expandSet.add(id);
    } else {
      this.expandSet.delete(id);
    }
  }

  openRecipientsModal(row: BulkMessage) {
   this.store.dispatch(new GetBulkMessageWithRecipients(row.id));
   this.showRecipientsModal = true;
  }

  closeModal() {
    this.showRecipientsModal = false;
  }

  deleteUpcoming(id: number) {
    if(confirm("Are you sure you want to delete this bulk communuication?")) {
    this.onDelete.emit(id);
    }
  }

  editMessage(message: BulkMessage) {
    return this.store.dispatch(new GetBulkMessageWithRecipients(message.id)).subscribe(x => {
      const message = this.store.selectSnapshot(BulkMessagingState.currentBulkMessage);
      return this.openEditMessage(message);
    })
  }

  addRecipientsToNewBulkMessage() {
    let recipients: Recipient[] = this.store.selectSnapshot(BulkMessagingState.currentBulkMessage)
      .recipients
      .map((r) => ({
        destination: r.destination,
        status: BulkScheduledMessageRecipientStatus.Pending,
        clientId: r.clientId,
        customerName: r.customerName
      }));      

    this.store.dispatch(new SetBulkMessageRecipients(recipients));
    this.closeModal();
  }

  //private removeInternationalFormatting = (num) => `${num.replace('+61', '')}`;

  openEditMessage(message: BulkMessage) {
    this.onOpenEditModal.emit();
    this.store.dispatch(new EditNewBulkMessage({
      message: message.message,
      recipients: message.recipients.map(x => ({...x, destination: x.destination})),
      sendDate: new Date(message.scheduledFrom),
      id: message.id,
      chosenTemplate: null,
      csvFile: null,
      manualNumber: "",
      recipientSearch: "",
      tenantId: message.tenantId
    }));
  }

  messageProcessing(message: BulkMessage) {
    return messageIsProcessing({
      sendDate: new Date(message.scheduledFrom),
    } as BulkMessageForm);
  }


  isAutomatedBulkMessage(bulkMessage: BulkMessage): boolean {
    return bulkMessage.creationSource === MessageSource.NewCustomerWelcomeMessage || bulkMessage.creationSource === MessageSource.Survey;
  }
}
