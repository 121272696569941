import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SSConsentEligibilityFormListItemComponent } from './ss-consent-eligibility-form-list-item.component';
import { SixCpaFormListItemChecklistModule } from '../../six-cpa-form-list-item-checklist/six-cpa-form-list-item-checklist.module';
import { SSConsentEligibilityFormModule } from '../consent-eligibility-form/ss-consent-eligibility-form.module';



@NgModule({
  declarations: [
    SSConsentEligibilityFormListItemComponent
  ],
  imports: [
    CommonModule,
    SixCpaFormListItemChecklistModule,
    SSConsentEligibilityFormModule
  ],
  exports: [
    SSConsentEligibilityFormListItemComponent
  ]
})
export class SSConsentEligibilityFormListItemModule { }
