import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HMRConsentAndEligibilityComponent } from './hmr-consent-and-eligibility.component';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import { ReactiveFormsModule } from '@angular/forms';
import { RadioHighlightModule } from '../../../../../shared/components/radio-highlight/radio-highlight.module';

@NgModule({
  declarations: [HMRConsentAndEligibilityComponent],
  imports: [
    CommonModule,
    NgxsFormPluginModule,
    ReactiveFormsModule,
    RadioHighlightModule
  ],
  exports: [HMRConsentAndEligibilityComponent]
})
export class HMRConsentAndEligibilityModule { }
