import { Component, OnInit, OnDestroy } from '@angular/core';
import { UserService } from '../../shared/services/user.service';
import { Subscription } from 'rxjs/Subscription';
import 'rxjs/add/operator/map';
import { User } from '../../shared/models/user.model';

@Component({
  selector: 'app-user-page',
  templateUrl: './user-page.component.html',
  styleUrls: ['./user-page.component.scss']
})

export class UserPageComponent implements OnInit, OnDestroy {
  constructor(private userService: UserService) { }
  isLoaded = false;
  isUpdateFormVisible = false;
  sub1: Subscription;
  sub2: Subscription;
  userList: User[] = [];
  currentItem: User;

  ngOnInit() {
    this.resetCurrentItem();
    this.sub1 = this.userService.getList().subscribe(
      (item: User[]) => {
        this.userList = item;
        this.isLoaded = true;
      }
    );
  }

  openUpdateForm(data?: User) {
    if (data) {
      this.currentItem = data;
    }
    this.isUpdateFormVisible = true;
  }


  updateList(data: any) {
    const {item, mode} = data;
    if (mode === 'create') {
      this.userList.push(item);
    } else {
      const index = this.userList.findIndex(c => c.id === item.id);
      this.userList[index] = item;
    }
    this.resetCurrentItem();
  }
  resetCurrentItem() {
    this.currentItem = new User(null);
  }
  onUpdateFormCancel() {
    this.isUpdateFormVisible = false;
    this.resetCurrentItem();
  }

  removeItem(id: number) {
    this.sub2 = this.userService.removeUser(id).subscribe(
      data => {
        const index = this.userList.findIndex(c => c.id === id);
        if (index !== -1) {
            this.userList.splice(index, 1);
        }
      }
    );
  }
  ngOnDestroy() {
    if (this.sub1) { this.sub1.unsubscribe(); }
    if (this.sub2) { this.sub2.unsubscribe(); }

  }
}
