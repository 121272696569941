import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import {
  AslPatient,
  ASLStateModel,
  SLConsentState,
} from '../../asl/state/asl.models';
import { catchError, tap } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { LiteCustomerService } from '../../../../services/lite-customer.service';
import {
  ClearASLPatientState,
  CloseRegisterMySlModal,
  CloseSuccessModal,
  CloseSummaryModal,
  GetASLPatient,
  GetASLPatientFailed,
  GetASLPatientSuccess,
  GetASLStatus,
  GetASLStatusFailed,
  GetASLStatusSuccess,
  OpenRegisterMySl,
  OpenSuccessModal,
  OpenSummaryModal,
  RegisterASLPatient,
  RegisterASLPatientFailed,
  RegisterASLPatientSuccess,
  RequestASLConsentFailed,
  RequestASLConsentSuccess,
  RequestASLIcolConsent,
  RequestASLInfaConsent,
  RequestASLRemoveConsent,
  RequestASLRemoveConsentFailed,
  RequestASLRemoveConsentSuccess,
  ToggleRemoveModal,
  UpdateASLPatient,
  UpdateASLPatientFailed,
  UpdateASLPatientSuccess,
} from '../../asl/state/asl.actions';
import { patch } from '@ngxs/store/operators';
import {
  RefreshAslMedicationsAction,
  SetAslMedicationsAction,
} from '../../asl-categories/state/asl-medication.actions';

@State<ASLStateModel>({
  name: 'ASL',
  defaults: {
    isSubmitting: false,
    isFetchingPatient: false,
    aslPatientLoadFailed: false,
    error: null,
    showModal: false,
    showFormSuccess: false,
    showSummaryModal: false,
    aslDetails: null,
    showRemoveModal: false,
    isSubmittingConsentRequest: false,
  },
})
@Injectable()
export class ASLState {
  @Selector()
  static isSubmitting(state: ASLStateModel): boolean {
    return state.isSubmitting;
  }

  @Selector()
  static error(state: ASLStateModel): string {
    return state.error;
  }

  @Selector()
  static showASLModal(state: ASLStateModel): boolean {
    return state.showModal;
  }

  @Selector()
  static showASLSuccess(state: ASLStateModel): boolean {
    return state.showFormSuccess;
  }

  @Selector()
  static showASLSummary(state: ASLStateModel): boolean {
    return state.showSummaryModal;
  }

  @Selector()
  static aslDetails(state: ASLStateModel): AslPatient {
    return state.aslDetails;
  }

  @Selector()
  static showRemoveModal(state: ASLStateModel): boolean {
    return state.showRemoveModal;
  }

  @Selector()
  static isFetchingPatient(state: ASLStateModel): boolean {
    return state.isFetchingPatient;
  }

  @Selector()
  static aslPatientLoadFailed(state: ASLStateModel): boolean {
    return state.aslPatientLoadFailed;
  }

  @Selector()
  static isSubmittingConsentRequest({
    isSubmittingConsentRequest,
  }: ASLStateModel): boolean {
    return isSubmittingConsentRequest;
  }

  constructor(
    private liteCustomerService: LiteCustomerService,
    private store: Store
  ) {}

  @Action(RegisterASLPatient)
  registerASLPatient(
    ctx: StateContext<ASLStateModel>,
    { patient }: RegisterASLPatient
  ) {
    ctx.patchState({
      isSubmitting: true,
      error: null,
    });

    return this.liteCustomerService.registerASLCustomer(patient).pipe(
      tap(() => ctx.dispatch(new RegisterASLPatientSuccess(patient))),
      catchError((error: HttpErrorResponse) =>
        ctx.dispatch(new RegisterASLPatientFailed(error))
      )
    );
  }

  @Action(ToggleRemoveModal)
  toggleRemoveModal(
    ctx: StateContext<ASLStateModel>,
    { show }: ToggleRemoveModal
  ) {
    ctx.patchState({
      showRemoveModal: show,
    });
  }

  @Action(RegisterASLPatientSuccess)
  registerASLPatientSuccess(
    ctx: StateContext<ASLStateModel>,
    { patient }: RegisterASLPatientSuccess
  ) {
    this.store.dispatch([
      new CloseRegisterMySlModal(),
      new OpenSuccessModal(),
      new GetASLPatient(patient.ihi, patient.firstName, patient.surname),
    ]);
    ctx.patchState({
      isSubmitting: false,
    });
  }

  @Action(RegisterASLPatientFailed) registerASLPatientFailed(
    ctx: StateContext<ASLStateModel>,
    { error }: RegisterASLPatientFailed
  ) {
    ctx.patchState({
      isSubmitting: false,
      error: error.error,
    });
  }

  @Action(OpenRegisterMySl) openRegister(
    ctx: StateContext<ASLStateModel>
  ): void {
    ctx.patchState({
      showModal: true,
    });
  }

  @Action(CloseRegisterMySlModal) closeRegister(
    ctx: StateContext<ASLStateModel>
  ): void {
    ctx.patchState({
      showModal: false,
    });
  }

  @Action(OpenSuccessModal) openSuccess(
    ctx: StateContext<ASLStateModel>
  ): void {
    ctx.patchState({
      showFormSuccess: true,
    });
  }

  @Action(CloseSuccessModal) closeSuccess(
    ctx: StateContext<ASLStateModel>
  ): void {
    ctx.patchState({
      showFormSuccess: false,
    });
  }

  @Action(OpenSummaryModal) openSummary(
    ctx: StateContext<ASLStateModel>
  ): void {
    ctx.patchState({
      showSummaryModal: true,
    });
  }

  @Action(CloseSummaryModal) closeSummary(
    ctx: StateContext<ASLStateModel>
  ): void {
    ctx.patchState({
      showSummaryModal: false,
    });
  }

  @Action(GetASLPatient)
  getASLPatient(
    ctx: StateContext<ASLStateModel>,
    { ihiNumber, firstName, surname, isTimerReload }: GetASLPatient
  ) {
    if (!ihiNumber) {
      ctx.patchState({
        aslDetails: null,
      });
      return;
    }

    // isTimerReload exists so we don't show the spinner everytime we do a check for ASLPatient from the polling
    if (!isTimerReload) {
      ctx.patchState({
        isFetchingPatient: true,
      });
    }
    return this.liteCustomerService
      .getASLPatient(ihiNumber, firstName, surname)
      .pipe(
        tap((aslPatient) =>
          ctx.dispatch(
            new GetASLPatientSuccess(aslPatient.response as AslPatient)
          )
        ),
        catchError((error: HttpErrorResponse) =>
          ctx.dispatch(new GetASLPatientFailed(error))
        )
      );
  }

  @Action(GetASLPatientSuccess)
  getASLPatientSuccess(
    ctx: StateContext<ASLStateModel>,
    { patient }: GetASLPatientSuccess
  ) {
    ctx.patchState({
      isFetchingPatient: false,
    });
    if (patient) {
      ctx.dispatch(
        new RefreshAslMedicationsAction(
          patient.ihi,
          patient.firstName,
          patient.surname
        )
      );
      ctx.patchState({
        aslDetails: patient,
      });
    } else {
      ctx.dispatch(new SetAslMedicationsAction([]));
      ctx.patchState({
        aslPatientLoadFailed: false,
        aslDetails: null,
      });
    }
  }

  @Action(GetASLPatientFailed)
  getASLPatientFailed(
    ctx: StateContext<ASLStateModel>,
    { error }: GetASLPatientFailed
  ) {
    ctx.patchState({
      aslDetails: null,
      error: error.error,
      isFetchingPatient: false,
      aslPatientLoadFailed: true,
    });
  }

  @Action(RequestASLIcolConsent)
  requestASLConsent(
    ctx: StateContext<ASLStateModel>,
    { ihiNumber, firstName, lastName }: RequestASLIcolConsent
  ) {
    return this.liteCustomerService
      .consentASL(ihiNumber, firstName, lastName, 'Icol')
      .pipe(
        tap(() =>
          ctx.dispatch(
            new RequestASLConsentSuccess(ihiNumber, firstName, lastName)
          )
        ),
        catchError((error: HttpErrorResponse) =>
          ctx.dispatch(new RequestASLConsentFailed(error))
        )
      );
  }

  @Action(RequestASLInfaConsent)
  requestASLInfaConsent(
    ctx: StateContext<ASLStateModel>,
    { ihiNumber, firstName, lastName }: RequestASLInfaConsent
  ) {
    return this.liteCustomerService
      .consentASL(ihiNumber, firstName, lastName, 'Infa')
      .pipe(
        tap(() =>
          ctx.dispatch(
            new RequestASLConsentSuccess(ihiNumber, firstName, lastName)
          )
        ),
        catchError((error: HttpErrorResponse) =>
          ctx.dispatch(new RequestASLConsentFailed(error))
        )
      );
  }

  @Action([RequestASLInfaConsent, RequestASLIcolConsent])
  onRequestConsent(ctx: StateContext<ASLStateModel>) {
    ctx.patchState({ isSubmittingConsentRequest: true });
    setTimeout(() => {
      ctx.patchState({ isSubmittingConsentRequest: false });
    }, 10000);
  }

  @Action([RequestASLConsentFailed])
  onRequestConsentComplete(ctx: StateContext<ASLStateModel>) {
    ctx.patchState({ isSubmittingConsentRequest: false });
  }

  @Action(RequestASLConsentSuccess)
  requestASLConsentSuccess(
    ctx: StateContext<ASLStateModel>,
    { ihiNumber, firstName, lastName }: RequestASLConsentSuccess
  ) {
    ctx.dispatch(new GetASLStatus(ihiNumber, firstName, lastName));
  }

  @Action(RequestASLConsentFailed)
  requestASLConsentFailed(
    ctx: StateContext<ASLStateModel>,
    { error }: RequestASLConsentFailed
  ) {
    ctx.patchState({
      error: error.error,
    });
  }

  @Action(RequestASLRemoveConsent)
  requestASLRemoveConsent(
    ctx: StateContext<ASLStateModel>,
    { id, ihiNumber, firstName, lastName }: RequestASLRemoveConsent
  ) {
    return this.liteCustomerService
      .removeConsent(id, ihiNumber, firstName, lastName)
      .pipe(
        tap((slStatus) =>
          ctx.dispatch(new RequestASLRemoveConsentSuccess(slStatus.response))
        ),
        catchError((error: HttpErrorResponse) =>
          ctx.dispatch(new RequestASLConsentFailed(error))
        )
      );
  }

  @Action(RequestASLRemoveConsentSuccess)
  requestASLRemoveConsentSuccess(
    ctx: StateContext<ASLStateModel>,
    { mySLStatus }: RequestASLRemoveConsentSuccess
  ) {
    ctx.dispatch(new GetASLStatusSuccess(mySLStatus));
  }

  @Action(RequestASLRemoveConsentFailed)
  requestASLRemoveConsentFailed(
    ctx: StateContext<ASLStateModel>,
    { error }: RequestASLRemoveConsentFailed
  ) {
    ctx.patchState({
      error: error.error,
    });
  }

  @Action(GetASLStatus)
  getASLStatus(
    ctx: StateContext<ASLStateModel>,
    { ihiNumber, firstName, lastName }: GetASLStatus
  ) {
    return this.liteCustomerService
      .getASLStatus(ihiNumber, firstName, lastName)
      .pipe(
        tap((slStatus) =>
          ctx.dispatch(new GetASLStatusSuccess(slStatus.response))
        ),
        catchError((error: HttpErrorResponse) =>
          ctx.dispatch(new GetASLStatusFailed(error))
        )
      );
  }

  @Action(GetASLStatusSuccess)
  getMySLStatusSuccess(
    ctx: StateContext<ASLStateModel>,
    { mySLStatus }: GetASLStatusSuccess
  ) {
    const state = ctx.getState();
    // Don't update if nothing has changed
    if (
      !!state.aslDetails?.status &&
      JSON.stringify(mySLStatus) === JSON.stringify(state.aslDetails.status)
    ) {
      return;
    }

    //on receiving a change in status you
    //need to be registered, and transitioned from "proposed" to "active" infa
    //and have an icol status of either null or proposed (you don't have one at all or you have previously ignored one)
    if (
      !!state.aslDetails?.status &&
      mySLStatus.isRegistered &&
      state.aslDetails.status.infaStatus === SLConsentState.Proposed &&
      mySLStatus.infaStatus === SLConsentState.Active &&
      (!mySLStatus.icolStatus ||
        mySLStatus.icolStatus === SLConsentState.Proposed)
    ) {
      ctx.dispatch(
        new RequestASLIcolConsent(
          state.aslDetails.ihi,
          state.aslDetails.firstName,
          state.aslDetails.surname
        )
      );
    }

    if (!state.aslDetails) {
      const patient = new AslPatient();
      patient.status = mySLStatus;
      ctx.setState(
        patch({
          aslDetails: patient
        })
      );
    } else {
      ctx.setState(
        patch({
          aslDetails: patch({
            status: mySLStatus,
          }),
        })
      );
    }
  }

  @Action(GetASLStatusFailed)
  getMySLStatusFailed(
    ctx: StateContext<ASLStateModel>,
    { error }: GetASLStatusFailed
  ) {
    ctx.patchState({
      error: error.error,
    });
  }

  @Action(UpdateASLPatient)
  updateASLPatient(
    ctx: StateContext<ASLStateModel>,
    { patient }: UpdateASLPatient
  ) {
    ctx.patchState({
      isSubmitting: true,
      error: null,
    });

    return this.liteCustomerService.updateASLCustomer(patient).pipe(
      tap(() => ctx.dispatch(new UpdateASLPatientSuccess(patient))),
      catchError((error: HttpErrorResponse) =>
        ctx.dispatch(new UpdateASLPatientFailed(error))
      )
    );
  }

  @Action(UpdateASLPatientSuccess)
  updateASLPatientSuccess(
    ctx: StateContext<ASLStateModel>,
    { patient }: UpdateASLPatientSuccess
  ) {
    ctx.patchState({
      isSubmitting: false,
    });
    return this.store.dispatch([
      new GetASLPatient(patient.ihi, patient.firstName, patient.surname),
    ]);
  }

  @Action(UpdateASLPatientFailed)
  updateASLPatientFailed(
    ctx: StateContext<ASLStateModel>,
    { error }: UpdateASLPatientFailed
  ) {
    ctx.patchState({
      isSubmitting: false,
      error: error.error,
    });
  }

  @Action(ClearASLPatientState)
  clearASLPatientState(ctx: StateContext<ASLStateModel>) {
    ctx.patchState({
      isSubmitting: false,
      error: null,
      aslDetails: null,
    });
  }
}
