import { State, Action, StateContext, Selector, Store } from '@ngxs/store';
import { ScheduledMessage } from '../../../../../shared/models/message.model';
import {
  GetScheduledMessages,
  GetScheduledMessagesSuccess,
  GetScheduledMessagesError,
  RemoveScheduledMessage,
  RemoveScheduledMessageSuccess,
  AddScheduledMessage,
  RetryFailedMessage,
  RetryFailedMessageFailed,
  RetryFailedMessageSuccess,
} from './scheduled-messages.actions';
import { switchMap, catchError, tap, finalize } from 'rxjs/operators';
import { AlertService } from '../../../../../modules/core/alert/alert.service';
import { ScheduledMessageService } from '../../../../../shared/services/scheduled-message.service';
import { ProfileState } from '../../../../../modules/core/profile/state/profile.state';
import { GetClientNotes } from '../../../../../modules/profile/notes/state/notes.actions';
import { FetchSMSHistory } from '../../sms/state/sms-history.actions';
import { Injectable } from '@angular/core';

export class ScheduledMessagesStateModel {
  public scheduledMessages: ScheduledMessage[];
  loading: boolean;
}
@Injectable()
@State<ScheduledMessagesStateModel>({
  name: 'scheduledMessages',
  defaults: {
    scheduledMessages: [],
    loading: false,
  },
})
export class ScheduledMessagesState {
  constructor(
    private alertService: AlertService,
    private scheduledMessageService: ScheduledMessageService,
    private store: Store
  ) { }

  @Selector()
  static scheduledMessages(state: ScheduledMessagesStateModel) {
    return state.scheduledMessages;
  }

  @Selector()
  static scheduledMessagesLoading(state: ScheduledMessagesStateModel) {
    return state.loading;
  }

  @Action(GetScheduledMessages)
  getScheduledMessages(ctx: StateContext<ScheduledMessagesStateModel>, { clientId }: GetScheduledMessages) {
    return this.scheduledMessageService.getScheduledMessages(clientId)
      .pipe(
        switchMap((response) => ctx.dispatch(new GetScheduledMessagesSuccess(response))),
        catchError((err) => ctx.dispatch(new GetScheduledMessagesError(err)))
      );
  }

  @Action(RemoveScheduledMessage)
  removeScheduledMessagecheduledMessages(ctx: StateContext<ScheduledMessagesStateModel>, { scheduledMessageId }: RemoveScheduledMessage) {
    return this.scheduledMessageService
      .removeScheduledMessage(scheduledMessageId)
      .pipe(
        switchMap(() => ctx.dispatch(new RemoveScheduledMessageSuccess())),
        catchError((err) => ctx.dispatch(new GetScheduledMessagesError(err)))
      );
  }

  @Action(GetScheduledMessagesSuccess)
  getScheduledMessagesSuccess(ctx: StateContext<ScheduledMessagesStateModel>, { messages }: GetScheduledMessagesSuccess) {
    ctx.patchState({
      scheduledMessages: messages,
    });
  }

  @Action(AddScheduledMessage)
  addScheduledMessage(ctx: StateContext<ScheduledMessagesStateModel>, { message }: AddScheduledMessage) {
    const messages = ctx.getState().scheduledMessages;
    messages.push(message);
    ctx.patchState({
      scheduledMessages: messages,
    });
  }

  @Action(RemoveScheduledMessageSuccess)
  removeScheduledMessageSuccess(ctx: StateContext<ScheduledMessagesStateModel>, { }: RemoveScheduledMessageSuccess) {
    const clientId = this.store.selectSnapshot(ProfileState.clientId);
    ctx.dispatch([
      new GetScheduledMessages(clientId),
      new GetClientNotes(clientId),
    ]);
  }

  @Action(GetScheduledMessagesError)
  getScheduledMessagesError(ctx: StateContext<ScheduledMessagesStateModel>, { error }: GetScheduledMessagesError) {
    this.alertService.error(error.error);
  }

  @Action(RetryFailedMessage)
  retryFailedMessage(ctx: StateContext<ScheduledMessagesStateModel>, { id, clientId }: RetryFailedMessage) {
    ctx.patchState({ loading: true });

    return this.scheduledMessageService.retryFailedMessage(id).pipe(
      tap(() => ctx.dispatch(new RetryFailedMessageSuccess(clientId))),
      catchError((error) => ctx.dispatch(new RetryFailedMessageFailed(error))),
      finalize(() => ctx.patchState({ loading: false }))
    );
  }

  @Action(RetryFailedMessageSuccess)
  retryFailedMessageSuccess(ctx: StateContext<ScheduledMessagesStateModel>, { clientId }: RetryFailedMessageSuccess) {
    this.alertService.success('Successfully sent message');

    ctx.dispatch(new FetchSMSHistory(clientId));
    ctx.dispatch(new GetScheduledMessages(clientId));
  }

  @Action(RetryFailedMessageFailed)
  retryFailedMessageFailed(ctx: StateContext<ScheduledMessagesStateModel>, { error }: RetryFailedMessageFailed) {
    this.alertService.error(error.message);
  }
}
