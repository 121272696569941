import { DoseAdministrationAidsState, DoseAdministrationAidsStateModel } from './dose-administration-aids.state';
import { DAAPatientDetailsFormDetailedState } from '../../daa-patient-details-form/state/daa-patient-details-form-detailed.state';
import { DAAConsentAndEligibilityFormState } from '../../daa-consent-and-eligibility-form/state/daa-consent-and-eligibility-form.state';
import { DAAMedicationInformationFormState } from '../../daa-medication-information-form/state/daa-medication-information-form.state';
import { DAAClassificationsRecommendationsState } from '../../daa-classifications-recommendations/state/daa-classifications-recommendations.state';
import { DAAAdditionalDetailsFormState } from '../../daa-additional-details-form/state/daa-additional-details-form.state';
import { DAAMedicationProfileState } from '../../daa-medication-profile/state/daa-medication-profile.state';
import { Selector } from '@ngxs/store';
import { Medicine } from '../../../../../../shared/models/six-cpa/medicine.model';
import { DaaFormModel } from '../../../../../../shared/models/six-cpa/DAA/daa-form.model';
import { DAAPatientDetailsFormModel } from '../../../../../../shared/models/six-cpa/DAA/daa-patient-details-form.model';
import { DAAConsentAndEligibilityFormModel } from '../../../../../../shared/models/six-cpa/DAA/daa-consent-and-eligibility-form.model';
import { DAAMedicationInformationFormModel } from '../../../../../../shared/models/six-cpa/DAA/daa-medication-information-form.model';
import { DAAClassificationsRecomendationsFormModel } from '../../../../../../shared/models/six-cpa/DAA/daa-classifications-recommendations-form.model';
import { DAAAdditionalDetailsFormModel } from '../../../../../../shared/models/six-cpa/DAA/daa-additional-details-form.model';
import * as moment_ from 'moment';
const moment = moment_;
import { DAAPatientDetailsFormBasicState } from '../../daa-patient-details-form/state/daa-patient-details-form-basic.state';
import { Injectable } from '@angular/core';

@Injectable()
export class DAAFormsService {
  @Selector([
    DoseAdministrationAidsState,
    DAAPatientDetailsFormDetailedState.form,
    DAAConsentAndEligibilityFormState.daaConsentAndEligibilityForm,
    DAAMedicationInformationFormState.daaMedicationInformationForm,
    DAAClassificationsRecommendationsState.daaClassificationsRecommendations,
    DAAAdditionalDetailsFormState.daaAdditionalDetailsForm,
    DAAMedicationProfileState.selectedMedicines
  ])
  static getDAAPatientRegistrationRequest(
    daaState: DoseAdministrationAidsStateModel,
    patientDetails: DAAPatientDetailsFormModel,
    consentEligibility: DAAConsentAndEligibilityFormModel,
    medicationInformation: DAAMedicationInformationFormModel,
    classificationsRecommendations: DAAClassificationsRecomendationsFormModel,
    additionalDetails: DAAAdditionalDetailsFormModel,
    selectedMedicines: Medicine[]): DaaFormModel {
    const patientDetailsForm = this.getPatientDetailsRegistrationData(daaState.patientDetailsFormId, patientDetails);
    const consentAndEligibilityForm = this.getConsentAndEligibilityData(daaState.consentAndEligibilityFormId, consentEligibility);
    const medicationInformationForm = this.getMedicationInformationData(daaState.medicationInformationFormId, medicationInformation);
    const additionalDetailsForm =  this.getAdditionalDetailsData(daaState.additionalDetailsFormId, additionalDetails);
    const classificationAndRecommendationsForm = this.getClassificationsAndRecommendationsData(daaState.classificationsAndRecommendationsFormId, classificationsRecommendations);
    const sixCpaMedications = this.getSixCpaMedications(selectedMedicines);

    return {
      id: daaState.daaId,
      sixCpaFormId: daaState.sixCpaFormId,
      patientDetailsFormId: daaState.patientDetailsFormId,
      consentAndEligibilityFormId: daaState.consentAndEligibilityFormId,
      medicationInformationFormId: daaState.medicationInformationFormId,
      classificationAndRecommendationsFormId: daaState.classificationsAndRecommendationsFormId,
      additionalDetailsFormId: daaState.additionalDetailsFormId,
      patientDetailsForm,
      consentAndEligibilityForm,
      medicationInformationForm,
      classificationAndRecommendationsForm,
      additionalDetailsForm,
      sixCpaMedications,
      daaFormType: daaState.daaFormType
    };
  }

  @Selector([
    DoseAdministrationAidsState,
    DAAPatientDetailsFormBasicState.form,
    DAAMedicationProfileState.selectedMedicines
  ])
  static getDAAClaimRequest(
    daaState: DoseAdministrationAidsStateModel,
    patientDetails: DAAPatientDetailsFormModel,
    selectedMedicines: Medicine[]): DaaFormModel {
    const patientDetailsForm = this.getPatientDetailsClaimData(daaState.patientDetailsFormId, patientDetails);
    const sixCpaMedications = this.getSixCpaMedications(selectedMedicines);
    return {
      id: daaState.daaId,
      sixCpaFormId: daaState.sixCpaFormId,
      patientDetailsFormId: daaState.patientDetailsFormId,
      patientDetailsForm,
      daaFormType: daaState.daaFormType,
      sixCpaMedications
    };
  }

  private static getPatientDetailsRegistrationData(id: number, patientDetails: DAAPatientDetailsFormModel) {
    return patientDetails ? {
      id,
      dob: moment(patientDetails.dob).toDate(),
      medicareNumber: patientDetails.medicareNumber,
      gender: patientDetails.gender,
      postCode: patientDetails.postCode,
      dateOfService: patientDetails.dateOfDAAService,
      currentLivingStatus: patientDetails.currentLivingStatus,
      concessionCard: patientDetails.concessionCard
    } : null;
  }

  private static getPatientDetailsClaimData(id: number, patientDetails: DAAPatientDetailsFormModel) {
    return patientDetails ? {
      id,
      medicareNumber: patientDetails.medicareNumber,
      dateOfService: moment(patientDetails.dateOfDAAService).toDate()
    } : null;
  }

  private static getConsentAndEligibilityData(id: number, consentEligibility: DAAConsentAndEligibilityFormModel) {
    return consentEligibility ? {
      id,
      writtenConsentForServiceProvision: consentEligibility.writtenConsentForServiceProvision,
      writtenConsentForProvisionOfData: consentEligibility.writtenConsentForProvisionOfData
    } : null;
  }

  private static getMedicationInformationData(id: number, medicationInfo: DAAMedicationInformationFormModel) {
    return medicationInfo ? {
      id,
      prescriptionMedicines: medicationInfo.prescriptionMedicines,
      nonPrescriptionMedicines: medicationInfo.nonPrescriptionMedicines,
      daaEligibileDisability: medicationInfo.daaEligibileDisability,
      difficultiesWithMedicationManagement: medicationInfo.difficultiesWithMedicationManagement,
      medIndexScore: medicationInfo.medIndexScore,
      historyOfNonAdherence: medicationInfo.historyOfNonAdherence
    } : null;
  }

  private static getAdditionalDetailsData(id: number, additionalDetails: DAAAdditionalDetailsFormModel) {
    return additionalDetails ? {
      id,
      daaReferralSource: additionalDetails.daaReferralSource,
      datePlanWasMade: moment(additionalDetails.datePlanWasMade).toDate(),
      dateOfInitialContact: moment(additionalDetails.dateOfInitialContact).toDate(),
      daaCollectionSource: additionalDetails.daaCollectionSource,
      typeOfDAAPackedByPharmacy: additionalDetails.typeOfDAAPackedByPharmacy
    } : null;
  }

  private static getClassificationsAndRecommendationsData(id: number, classificationsRecommendations: DAAClassificationsRecomendationsFormModel) {
    return classificationsRecommendations ? {
      id,
      gpOrHospitalLastSixMonthsForMedicationProblems: classificationsRecommendations.gpOrHospitalLastSixMonthsForMedicationProblems,
      patientUsingDAAPriorToVisit: classificationsRecommendations.patientUsingDAAPriorToVisit,
      healthConditionsBeingMedicated: {
        cVD: classificationsRecommendations.healthConditionsBeingMedicated.cVD,
        diabetes: classificationsRecommendations.healthConditionsBeingMedicated.diabetes,
        osteoporosis: classificationsRecommendations.healthConditionsBeingMedicated.osteoporosis,
        arthritis: classificationsRecommendations.healthConditionsBeingMedicated.arthritis,
        pain: classificationsRecommendations.healthConditionsBeingMedicated.pain,
        mentalHealthIssue: classificationsRecommendations.healthConditionsBeingMedicated.mentalHealthIssue,
        epilepsy: classificationsRecommendations.healthConditionsBeingMedicated.epilepsy,
        parkinsonsDisease: classificationsRecommendations.healthConditionsBeingMedicated.parkinsonsDisease,
        dementia: classificationsRecommendations.healthConditionsBeingMedicated.dementia,
        respiratoryDisorders: classificationsRecommendations.healthConditionsBeingMedicated.respiratoryDisorders,
        alimentaryTract: classificationsRecommendations.healthConditionsBeingMedicated.alimentaryTract,
        other: classificationsRecommendations.healthConditionsBeingMedicated.other
      },
      healthConditionsBeingMedicatedOtherDetails: classificationsRecommendations.healthConditionsBeingMedicatedOtherDetails,
      patientSupportWithManagingMedicines: classificationsRecommendations.patientSupportWithManagingMedicines,
      englishAtHome: classificationsRecommendations.englishAtHome,
      aboriginalOrTorresStraitIslander: classificationsRecommendations.aboriginalOrTorresStraitIslander,
      daasPackedPerWeek: classificationsRecommendations.daasPackedPerWeek,
      frequencyOfCollection: classificationsRecommendations.frequencyOfCollection,
    } : null;
  }


  private static getSixCpaMedications(medicines: Medicine[]) {
    return medicines
      .map(m => ({
        id: m.id,
        productDispensedId: m.productDispensedId,
        brandName: m.brandName,
        genericName: m.genericName,
        directions: m.directions,
        quantity: m.quantity,
        reasonsForTaking: m.reasonsForTaking,
        notes: m.notes
      }));
  }
}
