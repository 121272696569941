import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store, Select, Actions } from '@ngxs/store';
import { MedsCheckMedicationProfileState } from '../state/meds-check-medication-profile.state';
import { Observable, Subscription } from 'rxjs';
import { Medicine, MedicineCategory } from '../../../../../shared/models/six-cpa/medicine.model';
import {
  ToggleMedsCheckMedicationProfileAction, ToggleAllMedsCheckMedicationProfileListAction,
  ToggleMedsCheckMedicationProfileListItemAction
} from '../state/meds-check-medication-profile.actions';
import { ToggleSixCpaContentAction } from '../../state/six-cpa.actions';
import { ToggleAddEditMedicationAction, SelectMedicationAction } from '../../add-edit-medication/state/add-edit-medication.actions';
import { MedsCheckChildForm } from '../../meds-check-child-form';
import { SixCpaState } from '../../state/six-cpa.state';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SetMedsListsForSixCpa } from '../../../../../modules/core/profile/state/profile.actions';
import { CategoriesState } from '../../../../../modules/profile/categories/state/categories.state';
@Component({
  selector: 'app-meds-check-medication-profile',
  templateUrl: './meds-check-medication-profile.component.html',
  styleUrls: ['./meds-check-medication-profile.component.scss']
})
export class MedsCheckMedicationProfileComponent extends MedsCheckChildForm implements OnInit, OnDestroy {
  @Select(MedsCheckMedicationProfileState) medsCheckMedicationProfile$: Observable<any>;
  @Select(MedsCheckMedicationProfileState.categorisedMedicines) categorisedMedicines$: Observable<MedicineCategory[]>;
  @Select(MedsCheckMedicationProfileState.selectedMedicineCount) selectedMedicines$: Observable<any>;
  @Select(MedsCheckMedicationProfileState.showMode) showMode$: Observable<string>;
  @Select(SixCpaState.formsReadOnly) readonly$: Observable<boolean>;
  medProfileForm: FormGroup;
  sixCpaSubscription: Subscription;

  constructor(private store: Store, actions: Actions, private fb: FormBuilder) {
    super(actions);
  }

  ngOnInit() {
    this.medProfileForm = this.fb.group({
      allergies: this.fb.control(''),
      primaryDoctor: this.fb.control('')
    });
  }

  ngOnDestroy() {
    if (this.sixCpaSubscription) { this.sixCpaSubscription.unsubscribe() }
  }

  protected actionOnResetForm() {
    this.sixCpaSubscription = CategoriesState.categorised$(this.store)
    .subscribe((results) =>{
      this.reset();
      this.store.dispatch(new SetMedsListsForSixCpa(results));
    }
    );
  }

  // This is wired up to the ResetMedsCheckFormListAction which is a user triggered hard reset
  private reset() {
    this.medProfileForm.patchValue({
      allergies: '',
      primaryDoctor: ''
    })
  }

  select(item: Medicine) {
    this.store.dispatch(new ToggleMedsCheckMedicationProfileListItemAction(item));
  }

  selectAll(isSelected: boolean, categoryId?: number) {
    this.store.dispatch(new ToggleAllMedsCheckMedicationProfileListAction(isSelected, categoryId));
  }
  checkIsSelectedByCategory(medicationsByCategory: Medicine[]) {
    return medicationsByCategory.some(x => !x.isSelected);
  }
  updateMedication(item: Medicine) {
    this.store.dispatch(new SelectMedicationAction(item));
    this.addEditMedication();
  }

  addEditMedication() {
    this.store.dispatch([new ToggleSixCpaContentAction('out'), new ToggleAddEditMedicationAction('in')]);
  }

  modeChanged(mode) {
    this.store.dispatch(new ToggleMedsCheckMedicationProfileAction(mode));
  }
}
