import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { environment } from '../../../../environments/environment';
import { BaseApi } from '~/shared/core/base-api/base-api';
import { Router } from '@angular/router';
import { StorageService } from '~/shared/core/storage/storage.service';
import { AlertService } from '~/modules/core/alert/alert.service';
import { SimpleSignUp } from '~/shared/models/simple-sign-up/simple-sign-up.model';
import { ScreeningForm, ScreeningFormLink } from '../types/vaccination';

const API = environment.baseApiUrl;

@Injectable()
export class VaccinationService extends BaseApi {
  constructor(
    public http: HttpClient,
    public router: Router,
    public storageService: StorageService,
    public alertService: AlertService
  ) {
    super(http, router, storageService, alertService);
  }

  getCustomerByLink(encryptedLinkId: string): Observable<ScreeningFormLink> {
    return this.get<ScreeningFormLink>(
      `${API}/api/Vaccination/${encryptedLinkId}`
    );
  }

  submitScreeningForm(form: Partial<ScreeningForm>) {
    return this.post<any>(`${API}/api/Vaccination/form`, form);
  }
}
