import { State, Action, StateContext, Selector } from '@ngxs/store';
import {
  SetMedicationProfileScriptsAction,
  ToggleMedicationProfileListItemAction,
  ToggleAllMedicationProfileListAction,
  AddMedicationToMedicationProfileListAction,
  SelectMedicationProfileListItemAction,
  ToggleMedicationProfileAction,
  UpdateMedicationProfileList,
  ResetMedicationProfileIdsAction
} from './medication-profile.actions';
import { Medicine, MedicineCategory } from '../../../../../shared/models/six-cpa/medicine.model';
import { addUncategorisedIfMissing } from '../../../../../shared/helpers/uncategorised-meds.helper';
import { Injectable } from '@angular/core';
import { getDoctorName } from '../../../../../shared/helpers/doctor-name.helper';

export class MedicationProfileStateModel {
  public categorisedMedicines: MedicineCategory[];
  public showMode: string;
}

@Injectable()
@State<MedicationProfileStateModel>({
  name: 'medicationProfile',
  defaults: {
    categorisedMedicines: [],
    showMode: 'out'
  }
})
export class MedicationProfileState {

  @Selector()
  static categorisedMedicines(state: MedicationProfileStateModel) { return state.categorisedMedicines; }

  @Selector()
  static showMode(state: MedicationProfileStateModel) { return state.showMode; }

  @Selector()
  static selectedMedicines(state: MedicationProfileStateModel) {
    let res = [] as Medicine[];
    state.categorisedMedicines.forEach(category => {
      res = res.concat(category.medicines.filter(x => x.isSelected));
    });
    return res;
  }
  @Selector()
  static selectedMedicineCount(state: MedicationProfileStateModel) {
    let res = [] as Medicine[];
    state.categorisedMedicines.forEach(category => {
      res = res.concat(category.medicines.filter(x => x.isSelected));
    });
    return res.length;
  }

  @Action(ToggleMedicationProfileAction)
  toggle(ctx: StateContext<MedicationProfileStateModel>, action: ToggleMedicationProfileAction) {
    ctx.patchState({ showMode: action.showMode });
  }

  @Action(SetMedicationProfileScriptsAction)
  setScripts(ctx: StateContext<MedicationProfileStateModel>, action: SetMedicationProfileScriptsAction) {
    const categorisedMedicines = action.categorisedScripts.map(category => {
      const { id, name } = category.key;
      const medicineList = category.items.map(item => {
        return this.ConstructMedicine(item);
      });
      return {
        id,
        name,
        medicines: medicineList
      } as MedicineCategory;
    });
    ctx.patchState({ categorisedMedicines });
  }

  private ConstructMedicine(item: any) {
    const { productDispensedId, description, directionsDesc, quantity, name, doctor, dispensedDate } = item;
    return {
      id: null,
      productDispensedId,
      brandName: description,
      genericName: name,
      directions: directionsDesc,
      isSelected: false,
      quantity,
      prescriber: getDoctorName(doctor),
      dispensedDate
    } as Medicine;
  }

  @Action(ToggleMedicationProfileListItemAction)
  toggleListItem(ctx: StateContext<MedicationProfileStateModel>, action: ToggleMedicationProfileListItemAction) {
    const state = ctx.getState();
    ctx.patchState({ categorisedMedicines: state.categorisedMedicines.map(category => {
      return {
        ...category,
        medicines: category.medicines.map(item => item === action.item ? { ...item, isSelected: !item.isSelected } : item)
      };
    })
   });
  }

  @Action(ToggleAllMedicationProfileListAction)
  toggleAll(ctx: StateContext<MedicationProfileStateModel>, {isSelectAll, categoryId}: ToggleAllMedicationProfileListAction) {
    const state = ctx.getState();
    ctx.patchState({
      categorisedMedicines: state.categorisedMedicines.map(category => {
        if ((categoryId && category.id === categoryId) || !categoryId) {
          return {
            ...category,
            medicines: category.medicines.map(item => {
              return { ...item, isSelected: isSelectAll } as Medicine;
            })
          };
        } else { return category; }
      })
    });
  }

  @Action(AddMedicationToMedicationProfileListAction)
  addMedication(ctx: StateContext<MedicationProfileStateModel>, action: AddMedicationToMedicationProfileListAction) {
    const state = ctx.getState();
    const findDrug = state.categorisedMedicines.find(y => !!y.medicines.find(x => x.productDispensedId === action.item.productDispensedId));
    if (findDrug) {
      ctx.patchState({
        categorisedMedicines: state.categorisedMedicines.map(category => {
          return {
            ...category,
            medicines: category.medicines.map(item => item.productDispensedId === action.item.productDispensedId
              ? { ...action.item, isSelected: item.isSelected }
              : item)
          };
        })
      });
    } else {
      addUncategorisedIfMissing(state.categorisedMedicines);
      ctx.patchState({
        categorisedMedicines: state.categorisedMedicines.map(category => {
          if (category.id) {
            return {
              ...category,
            };
          } else {
            return {
              ...category,
              medicines: [...category.medicines, { ...action.item, isSelected: true }]
            };
          }
        })
      });
    }
  }

  @Action(SelectMedicationProfileListItemAction)
  selectListItem(ctx: StateContext<MedicationProfileStateModel>, action: SelectMedicationProfileListItemAction) {
    const state = ctx.getState();
    ctx.patchState({
      categorisedMedicines: state.categorisedMedicines.map(category => {
        return {
          ...category,
          medicines: category.medicines.map(item => item.productDispensedId === action.productDispensedId ? { ...item, isSelected: true } : item)
        };
      })
    });
  }

  @Action(UpdateMedicationProfileList)
  updateMedicines(ctx: StateContext<MedicationProfileStateModel>, { medication }: UpdateMedicationProfileList) {
    const state = ctx.getState();

    //clear all previous selections
    ctx.dispatch(new ToggleAllMedicationProfileListAction(false, null));

    medication.forEach(med => {
      ctx.dispatch(new SelectMedicationProfileListItemAction(med.productDispensedId));
    });

    ctx.patchState({
      categorisedMedicines: state.categorisedMedicines.map(category => {
        return {
          ...category,
          medicines: category.medicines.map(item => {
            const el = medication.find(x => x.productDispensedId === item.productDispensedId);
            return el ? el : item;
          })
        };
      })
    });
  }

  @Action(ResetMedicationProfileIdsAction)
  resetMedicationProfileIds(ctx: StateContext<MedicationProfileStateModel>, action: ResetMedicationProfileIdsAction) {
    const state = ctx.getState();
    ctx.patchState({
      categorisedMedicines: state.categorisedMedicines.map(category => {
        return {
          ...category,
          medicines: category.medicines ? category.medicines.map(med => {return {...med, id: null}}): [],
        }
      })
    });
  }
}
