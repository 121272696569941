import { Directive, Input, HostBinding } from '@angular/core';

@Directive({
  selector: '[appTotalDohClass]'
})
export class TotalDohClassDirective {
  @Input() set stock(value) { if (value && value.totalDoh) {
    this._hasEnoughStock = value.totalDoh.value >= value.totalDohTarget;
  }}
  @HostBinding('class') get getClass() { return this._hasEnoughStock ? 'success' : 'danger'; }
  private _hasEnoughStock = false;
  constructor() { }
}
