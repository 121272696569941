import { Medicine } from '../../../../../shared/models/six-cpa/medicine.model';
import { SixCpaMedicationModel } from '../../../../../shared/models/six-cpa/six-cpa-medication.model';
import { MedsCheckMedicationProfileForm } from './meds-check-medication-profile.state';

export enum MedsCheckMedicationProfileActionTypes {
  SET_MEDICATION_PROFILE_SCRIPRS_ACTION = '[MedsCheckMedicationProfile] Set Scripts',
  TOGGLE_MEDICATION_PROFILE_LIST_ITEM_ACTION = '[MedsCheckMedicationProfile] Toggle List Item',
  TOGGLE_ALL_MEDICATION_PROFILE_LIST_ACTION = '[MedsCheckMedicationProfile] Toggle All List Item',
  ADD_MEDICATION_MEDICATION_PROFILE_LIST_ACTION = '[MedsCheckMedicationProfile] Add Medication To List Item',
  SELECT_MEDICATION_PROFILE_LIST_ITEM_ACTION = '[MedsCheckMedicationProfile] Select List Item',
  TOGGLE_MEDICATION_PROFILE_ACTION = '[MedsCheckMedicationProfile] Toggle Content',
  UPDATE = '[MedsCheckMedicationProfile] Update Content',
  RESET = '[MedsCheckMedicationProfile] Reset Medication Ids',
  SET_MEDICATION_PROFILE_FORM = '[MedsCheckMedicationProfile] Set medication profile form'
}

export class SetMedsCheckMedicationProfileFormAction {
  static readonly type = MedsCheckMedicationProfileActionTypes.SET_MEDICATION_PROFILE_FORM;
  constructor(public form: Partial<MedsCheckMedicationProfileForm>) { }
}

export class SetMedsCheckMedicationProfileScriptsAction {
  static readonly type = MedsCheckMedicationProfileActionTypes.SET_MEDICATION_PROFILE_SCRIPRS_ACTION;
  constructor(public categorisedScripts: any[]) { }
}

export class ToggleMedsCheckMedicationProfileListItemAction {
  static readonly type = MedsCheckMedicationProfileActionTypes.TOGGLE_MEDICATION_PROFILE_LIST_ITEM_ACTION;
  constructor(public item: Medicine) { }
}

export class ToggleAllMedsCheckMedicationProfileListAction {
  static readonly type = MedsCheckMedicationProfileActionTypes.TOGGLE_ALL_MEDICATION_PROFILE_LIST_ACTION;
  constructor(public isSelectAll: boolean, public categoryId?: number) { }
}

export class AddMedicationToMedsCheckMedicationProfileListAction {
  static readonly type = MedsCheckMedicationProfileActionTypes.ADD_MEDICATION_MEDICATION_PROFILE_LIST_ACTION;
  constructor(public item: Medicine) { }
}

export class SelectMedsCheckMedicationProfileListItemAction {
  static readonly type = MedsCheckMedicationProfileActionTypes.SELECT_MEDICATION_PROFILE_LIST_ITEM_ACTION;
  constructor(public productDispensedId: string) { }
}

export class ToggleMedsCheckMedicationProfileAction {
  static readonly type = MedsCheckMedicationProfileActionTypes.TOGGLE_MEDICATION_PROFILE_ACTION;
  constructor(public showMode: string) { }
}

export class UpdateMedsCheckMedicationProfileList {
  static readonly type = MedsCheckMedicationProfileActionTypes.UPDATE;
  constructor(public medication: SixCpaMedicationModel[]) { }
}

export class ResetMedsCheckMedicationProfileIdsAction {
  static readonly type = MedsCheckMedicationProfileActionTypes.RESET;
}