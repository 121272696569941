import {
  Component,
  Input,
  ChangeDetectionStrategy,
  OnInit,
  Inject,
} from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-nav-panel',
  templateUrl: './nav-panel.component.html',
  styleUrls: ['./nav-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavPanelComponent implements OnInit {
  @Input() clientId: string;

  profile = [];
  notes = [];
  orders = [];
  comms = [];
  services = [];

  private isLite = false;

  constructor(@Inject('environment') env: any, private router: Router) {
    this.isLite = env.isLite;
  }

  ngOnInit() {
    this.profile = ['/', 'profile', this.clientId, 'categories'];
    this.notes = ['/', 'profile', this.clientId, 'notes'];
    this.orders = ['/', 'profile', this.clientId, 'orders'];
    this.comms = ['/', 'profile', this.clientId, 'comms'];
    this.services = ['/', 'profile', this.clientId, 'profile-services'];

    if (this.isLite) {
      this.setupLiteRoutes();
    }
  }

  private setupLiteRoutes() {
    this.profile.shift();
    this.notes.shift();
    this.orders.shift();
    this.comms.shift();
    this.services.shift();

    this.profile = this.createOutletSettings(this.profile);
    this.notes = this.createOutletSettings(this.notes);
    this.orders = this.createOutletSettings(this.orders);
    this.comms = this.createOutletSettings(this.comms);
    this.services = this.createOutletSettings(this.services);
  }

  private createOutletSettings(routes: string[]): any {
    return [
      '',
      {
        outlets: {
          patient: ['patient', ...routes],
        },
      },
    ];
  }

  isActive(route: any[]): boolean {
    return this.router.routerState.snapshot.url.includes(route.join('/'));
  }
}
