import { ScheduledMessagesComponent } from './scheduled-messages.component';
import { NgModule } from '@angular/core';
import { TimezoneModule } from '../../../../modules/core/timezone/timezone.module';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { LoaderModule } from '../../../../modules/core/loader/loader.module';
import { NotificationModule } from '../../../../shared/components/notification/notification.module';
import { NgxsModule } from '@ngxs/store';
import { ScheduledMessagesState } from './state/scheduled-messages.state';
import { ScheduledMessageService } from '../../../../shared/services/scheduled-message.service';


@NgModule({
  declarations: [
    ScheduledMessagesComponent
  ],
  imports: [
    TimezoneModule,
    CommonModule,
    FormsModule,
    LoaderModule,
    NotificationModule,
    NgxsModule.forFeature([ScheduledMessagesState])
  ],
  providers: [
    ScheduledMessageService
  ],
  exports: [
    ScheduledMessagesComponent
  ]
})
export class ScheduledMessagesModule { }
