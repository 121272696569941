import { ScryptSearchClient } from "../models/scrypt-search-client.model"

export enum ScryptSearchActions {
  GET_SEARCH_RESULTS = '[ScryptSearch] Get Search Results',
  GET_SEARCH_RESULTS_SUCCESS = '[ScryptSearch] Get Search Results Success',
  GET_SEARCH_RESULTS_ERROR = '[ScryptSearch] Get Search Results Error',
  CLEAR_SEARCH_RESULTS = '[ScryptSearch] CLear Search Results ',
  TOGGLE_SCRYPT_SEARCH_CLIENT= '[ScryptSearch] Toggle Scrypt Search Client',
}


export class GetScryptSearchResults {
  static readonly type = ScryptSearchActions.GET_SEARCH_RESULTS
  constructor(public query: string) { }
}

export class GetScryptSearchResultsSuccess {
  static readonly type = ScryptSearchActions.GET_SEARCH_RESULTS_SUCCESS
  constructor(public results: ScryptSearchClient[]) { }
}

export class ToggleScryptSearchClient {
  static readonly type = ScryptSearchActions.TOGGLE_SCRYPT_SEARCH_CLIENT
  constructor(public id: any) { }
}


export class GetScryptSearchResultsError {
  static readonly type = ScryptSearchActions.GET_SEARCH_RESULTS_ERROR
  constructor(public error: any) { }
}

export class ClearScryptSearchResults {
  static readonly type = ScryptSearchActions.CLEAR_SEARCH_RESULTS
  constructor() { }
}
