import { Component, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators
} from '@angular/forms';
import { ProfileState } from '../../../../modules/core/profile/state/profile.state';
import { AslRegisterPayload, AslRelatedPerson, CarerType, postCodePattern } from '../../asl/state/asl.models';
import { CloseRegisterMySlModal, RegisterASLPatient } from '../../asl/state/asl.actions';
import { ASLState } from '../../asl/state/asl.state';
import { ClientViewModel } from '../../../../modules/core/profile/client.model';
import { ASLService } from '../../../../services/asl.service';

@Component({
  selector: 'app-asl-register-form',
  templateUrl: './asl-register-form.component.html',
  styleUrls: ['./asl-register-form.component.scss']
})
export class AslRegisterFormComponent implements OnInit {
  @Select(ASLState.isSubmitting)
  isSubmitting$: Observable<boolean>;

  @Select(ASLState.error)
  error$: Observable<string>;

  @Select(ProfileState.profile)
  profile$: Observable<ClientViewModel>;

  channelForInviteSelect = 'mobileNumber';

  patientForm: FormGroup;

  carerForms: FormArray;
  agentForms: FormArray;

  constructor(private fb: FormBuilder, private store: Store, private aslService: ASLService) {}

  ngOnInit(): void {
    const patient = this.store.selectSnapshot(ProfileState.profile).patient;
    if (!patient.mobileNumber && patient.emailAddress) {
      this.channelForInviteSelect = 'email';
    }
    this.patientForm = this.fb.group({
      mobileNumber:
        new FormControl(
          this.channelForInviteSelect === 'mobileNumber'
            ? patient.mobileNumber
            : '',
          this.channelForInviteSelect === 'mobileNumber'
            ? [Validators.required, Validators.pattern('04[0-9]{8}')]
            : []),
      email:
        new FormControl(
          this.channelForInviteSelect === 'email'
            ? patient.emailAddress
            : '',
          this.channelForInviteSelect === 'email'
            ? [Validators.email, Validators.required]
            : []),
      mySLConfirm: new FormControl('', [Validators.requiredTrue]),
      carers: new FormArray([]),
      agents: new FormArray([]),
    });
    this.carerForms = this.patientForm.get('carers') as FormArray;
    this.agentForms = this.patientForm.get('agents') as FormArray;
  }

  get formDisabled(): boolean {
    return this.patientForm.invalid;
  }

  isInvalidControl(controlName: string): boolean {
    return this.patientForm.get(controlName).invalid;
  }

  submit(): void {
    const registerPayload = this.patientForm.value as AslRegisterPayload;
    const patient = this.store.selectSnapshot(ProfileState.profile).patient;
    registerPayload.ihi = patient.ihiNumber;
    registerPayload.gender = patient.gender;
    registerPayload.firstName = patient.firstname;
    registerPayload.surname = patient.surname;
    registerPayload.address = patient.homeAddress;
    registerPayload.addressSuburb = patient.homeSuburb;
    registerPayload.addressState = patient.homeState;
    registerPayload.addressPostcode = patient.homePostcode ?  patient.homePostcode.toString() : null;
    registerPayload.dateOfBirth = patient.birthDate?.toString();
    registerPayload.medicareNumber = patient.medicareNo;
    registerPayload.DVANumber = patient.repatNo;
    registerPayload.address = patient.homeAddress;
    registerPayload.addressPostcode = patient.homePostcode?.toString();
    registerPayload.addressSuburb = patient.homeSuburb;
    registerPayload.addressState = patient.homeState;

    const relatedPersons = [];
    for (const carerForm of this.carerForms.controls) {
      const carer = carerForm.value;
      const relatedPerson = new AslRelatedPerson();
      relatedPerson.email = carer.email;
      relatedPerson.phoneNumber = carer.mobileNumber;
      relatedPerson.address = carer.address;
      relatedPerson.suburb = carer.suburb;
      relatedPerson.state = carer.state;
      relatedPerson.postcode = carer.postcode;
      relatedPerson.primaryContact = carer.primary;
      if (carer.carerType === CarerType.INDIVIDUAL) {
        relatedPerson.firstName = carer.firstName;
        relatedPerson.surname = carer.surname;
        relatedPerson.relationship = carer.relationship;
      } else {
        relatedPerson.organisationName = carer.organisation;
      }
      relatedPerson.isAgent = false;
      relatedPersons.push(relatedPerson);
    }
    for (const agentForm of this.agentForms.controls) {
      const agent = agentForm.value;
      const relatedPerson = new AslRelatedPerson();
      relatedPerson.email = agent.email;
      relatedPerson.phoneNumber = agent.mobileNumber;
      relatedPerson.address = agent.address;
      relatedPerson.suburb = agent.suburb;
      relatedPerson.state = agent.state;
      relatedPerson.postcode = agent.postcode;
      relatedPerson.firstName = agent.firstName;
      relatedPerson.surname = agent.surname;
      relatedPerson.relationship = agent.relationship;
      relatedPerson.primaryContact = agent.primary;
      relatedPerson.isAgent = true;

      relatedPersons.push(relatedPerson);
    }
    registerPayload.relatedPersons = relatedPersons;

    registerPayload.consentNeeded = "Infa";
    // form.ihi = '8001847563748587';
    this.store.dispatch(new RegisterASLPatient(registerPayload));
  }

  updateChannel(event: any): void {
    if (event.value === 'mobileNumber') {
      this.patientForm.get('email').clearValidators();
      this.patientForm.get('email').setValue(null);
      this.patientForm.get('mobileNumber').setValidators([Validators.required]);
    } else if (event.value === 'email') {
      this.patientForm.get('mobileNumber').clearValidators();
      this.patientForm.get('mobileNumber').setValue(null);
      this.patientForm.get('email').setValidators([Validators.required, Validators.email]);
    }
    this.patientForm.get('mobileNumber').updateValueAndValidity();
    this.patientForm.get('email').updateValueAndValidity();
    this.channelForInviteSelect = event.value;
  }

  closeModal(): void {
    this.store.dispatch(new CloseRegisterMySlModal());
  }

  nullOrLengthValidator(length: number): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
      return control.value.length !== length && control.value ? {length: {value: control.value.length}} : null;
    };
  }

  addNewRelatedPerson(formArray: FormArray): void {
    formArray.push(this.fb.group({
      carerType: new FormControl(CarerType.INDIVIDUAL, [Validators.required]),
      firstName: new FormControl('', [Validators.required]),
      surname: new FormControl('', [Validators.required]),
      mobileNumber: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.email]),
      relationship: new FormControl('', [Validators.required]),
      address: new FormControl(''),
      suburb: new FormControl(''),
      state: new FormControl(''),
      postcode: new FormControl('', [Validators.pattern(postCodePattern)]),
      organisation: new FormControl(''),
      primary: new FormControl(false),
      consent: new FormControl('', [Validators.requiredTrue])
    }));
  }

  removeRelatedPersons(formArray: FormArray, id: number): void {
    formArray.removeAt(id);
  }

  updatePrimaryContact(event: {id: number; isAgent: boolean}): void {
    for (const form of this.carerForms.controls) {
      form.get('primary').setValue(false);
    }
    for (const form of this.agentForms.controls) {
      form.get('primary').setValue(false);
    }

    if (event.isAgent) {
      this.agentForms.at(event.id).get('primary').setValue(true);
    } else {
      this.carerForms.at(event.id).get('primary').setValue(true);
    }
  }
}
