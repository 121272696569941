import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { GetGroupList } from '../group-page/state/group-page.actions';

@Injectable()
export class GroupsResolver implements Resolve<Observable<any>> {
  constructor(private store: Store) { }
  resolve(route: ActivatedRouteSnapshot): Observable<any> {
    return this.store.dispatch([
      new GetGroupList()
    ]);
  }
}