<section>
    <div class="header  border__grey header__desktop">
        <div class="grid header__block flex" g-xs="py3" z>
            <div class="grid__unit" g-xs="cols:9">
                <a href="/"> <img src="/assets/img/logo.png" /></a>
            </div>
            <div class="grid__unit" g-xs="cols:3 text:right">
                <ul class="nav nav__right color__blue-text">
                    <li g-xs="pl3"><a (click)="login()">Login <i class="fal fa-sign-in-alt" g-xs="ml1"></i></a></li>
                </ul>
            </div>
        </div>
    </div>
    <div class="header  border__grey header__mobile">
        <div class="header__block" g-xs="p3">
            <div class="flex header__block__nav">
                <a href="/"> <img src="/assets/img/logo.png" /></a>
            </div>
            <div g-xs="mt3">
                <ul class="nav flex color__blue-text">
                    <li g-xs="pl1"><a (click)="login()">Login <i class="fal fa-sign-in-alt" g-xs="ml1"></i></a></li>
                </ul>
            </div>

        </div>
    </div>
</section>
