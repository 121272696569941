import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { LinkShortenerService } from './linkshortener.service';

export interface FireStoreMessage {
  message: string;
}

@Injectable()
export class FirestoreService {
  constructor(private fireStore: AngularFirestore,
    private linkShortener: LinkShortenerService
    ) { }

  getMessageHistoryCollection<TMessageHistory>(profileId: number): AngularFirestoreCollection<TMessageHistory> {
    return this.fireStore.collection<TMessageHistory>(`/messages/${profileId}/message-history`, ref => ref.orderBy('timestamp', 'desc'));
  }

  createId() {
    return this.fireStore.createId();
  }

  markMessageAsRead(profileId: number, messageId: string, message: object) {
    this.fireStore.collection(`/messages/${profileId}/message-history`).doc(messageId).set({ ...message, read: true });
  }

  sendMessage<TMessageType extends FireStoreMessage>(message: TMessageType, profileId: number) {
    this.linkShortener.shortenLinks({message: message.message}).subscribe(result => {
      message.message = result.message;
      this.fireStore.collection('/messages').doc(`${profileId}`).get().toPromise().then(doc => {
        if(!doc.exists) {
          this.fireStore.collection('/messages').doc(`${profileId}`).set({});
        }
        this.fireStore.collection(`/messages/${profileId}/message-history`).add(message);
      });
    });
  }
}
