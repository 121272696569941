<nz-modal
    [(nzVisible)]="isVisible"
    [nzTitle]="modalHeader"
    [nzFooter]="modalFooter"
    [nzWidth]="'740px'"
    [nzCloseIcon]="modalCloseIcon"
    (nzOnCancel)="handleCancel()"
>

    <ng-template #modalHeader>
        Add additional items
    </ng-template>
    <ng-template #modalCloseIcon>
        <i class="fas fa-times"></i>
    </ng-template>
    <div *nzModalContent>
          <nz-input-group class="normal"  [nzPrefix]="prefixIconSearch" [nzSuffix]="suffixIconSearch">
            <input [formControl]="searchInput"  type="text" nz-input class="w100" placeholder="search for item" />
          </nz-input-group>
          <ng-template #prefixIconSearch>
            <i class="far fa-search"></i>
          </ng-template>
          <ng-template #suffixIconSearch>
            <i class="far fa-times"></i>
          </ng-template>
        <ul class="items-list" nz-list [nzDataSource]="data"
        [nzLoading]="isLoading"
        nzSize="large">
            <li class="additional-item"
                nz-list-item *ngFor="let item of data" nzNoFlex>
                <ul nz-list-item-actions>
                    <nz-list-item-action>
                        <a (click)="addItem(item)">
                            <i class="fas fa-plus"></i>&nbsp;Add
                        </a>
                    </nz-list-item-action>
                </ul>
                {{ item.name }}
            </li>
        </ul>
    </div>
    <ng-template #modalFooter>
        <button class="done-button button btn__yellow" (click)="handleCancel()">Done</button>
    </ng-template>
</nz-modal>
