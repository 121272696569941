import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfirmMedsCheckFormComponent } from './confirm-meds-check-form/confirm-meds-check-form.component';
import { NgxsModule } from '@ngxs/store';
import { ConfirmMedsCheckState } from './state/confirm-meds-check.state';
import { DownloadPdfModule } from '../../../core/download-pdf/download-pdf.module';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import { TypeaheadModule, BsDatepickerModule } from 'ngx-bootstrap';
import { ReportResponseModule } from '../report-response/report-response.module';
import { LoaderModule } from '../../../core/loader/loader.module';
import { MedsCheckService } from '../meds-check/services/meds-check.service';
import { CustomiseDoctorReportModule } from '../../../../shared/components/customise-doctor-report/customise-doctor-report.module';
import { UploadDocumentsFormModule } from '../upload-document-form/upload-document-form.module';

@NgModule({
  declarations: [ConfirmMedsCheckFormComponent],
  imports: [
    CommonModule,
    NgxsModule.forFeature([
      ConfirmMedsCheckState
    ]),
    DownloadPdfModule,
    ReactiveFormsModule,
    TypeaheadModule.forRoot(),
    NgxsFormPluginModule,
    ReportResponseModule,
    LoaderModule,
    BsDatepickerModule.forRoot(),
    CustomiseDoctorReportModule,
    UploadDocumentsFormModule
  ],
  providers: [MedsCheckService],
  exports: [ConfirmMedsCheckFormComponent]
})
export class ConfirmMedsCheckModule { }
