import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OptOutPageComponent } from './opt-out-page/opt-out-page.component';
import { NgxsModule } from '@ngxs/store';
import { OptOutState } from './state/opt-out.state';
import { OptOutRoutingModule } from './opt-out.routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoaderModule } from '../../modules/core/loader/loader.module';
import { OptOutConfirmationComponent } from './opt-out-confirmation/opt-out-confirmation.component';
import { OptOutService } from './services/opt-out.service';


@NgModule({
  declarations: [OptOutPageComponent, OptOutConfirmationComponent],
  imports: [
    CommonModule,
    NgxsModule.forFeature([OptOutState]),
    OptOutRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    LoaderModule
  ],
  providers: [
    OptOutService
  ],
  exports: [
    OptOutPageComponent
  ]
})
export class OptOutModule { }
