import { NgModule } from '@angular/core';
import { PageLoaderComponent } from './page-loader/page-loader.component';
import { LoaderComponent } from './loader/loader.component';
import { CommonModule } from '@angular/common';
import { NgxsModule } from '@ngxs/store';
import { LoadingState } from './state/loading.state';

@NgModule({
    declarations: [LoaderComponent, PageLoaderComponent],
    imports: [
        CommonModule,
        NgxsModule.forFeature([LoadingState]),
    ],
    exports: [LoaderComponent, PageLoaderComponent]
})
export class LoaderModule {
}
