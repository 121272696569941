import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { ProfileState } from '../../../../modules/core/profile/state/profile.state';
import { TriggersState } from '../../../../modules/pharmacy-comms/comms-reminders/state/triggers.state';
import { PharmacyCommsDetailsState } from '../../../../modules/pharmacy-comms/pharmacy-comms-details/state/pharmacy-comms-details.state';
import { TriggerType } from '../../../../shared/models/pharmacy/trigger.model';

@Injectable()
export class EvaluationHelperService {
  constructor(private store: Store) { }

  shouldEvaluateEvent(triggerType: TriggerType, profileType: string): boolean {
    const customerProfile = this.store.selectSnapshot(ProfileState.profile).customerProfile;
    return this.pharmacyAndPatientCommsEnabled(customerProfile.isAutomation) &&
      this.profileTypeMatchesEvent(customerProfile.type, profileType) &&
      this.triggerExistsAndEnabled(triggerType);
  }

  triggerExistsAndEnabled(triggerType: TriggerType) {
    const trigger = this.store.selectSnapshot(TriggersState.triggers).find(trigger => trigger.triggerType == triggerType);
    return trigger && trigger.enabled;
  }

  private profileTypeMatchesEvent(customerProfileType: string, profileType: string): boolean {
    return customerProfileType == profileType || profileType == null;
  }

  pharmacyAndPatientCommsEnabled(isAutomation: boolean) {
    const pharmacyCommsDetails = this.store.selectSnapshot(PharmacyCommsDetailsState.pharmacyCommsDetails);
    return pharmacyCommsDetails && pharmacyCommsDetails.communicationsEnabled && isAutomation;
  }
}
