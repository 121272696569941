import { State, Selector, Action, StateContext } from '@ngxs/store';
import { SetProfileForSixCpaAction } from '../../state/six-cpa.actions';
import { UpdatePatientDetailsForm } from './patient-details-form.actions';
import { IPatientDetailsFormStateModel } from './patient-details-form-state-model.interface';
import * as moment_ from 'moment';
const moment = moment_;
import { TenantDatePipe } from '../../../../core/timezone/pipes/tenant-date.pipe';
import { getMedicareDVAOrRepatNumber } from '../../../../../shared/helpers/patient.helper';
import { Injectable } from '@angular/core';

export interface IPatientDetailsFormBasicStateModel extends IPatientDetailsFormStateModel {
  dateOfMedsCheck: Date | string;
}

export class PatientDetailsFormBasicStateModel {
  patientDetailsForm: {
    model: Partial<IPatientDetailsFormBasicStateModel>;
  };
}
@Injectable()
@State<PatientDetailsFormBasicStateModel>({
  name: 'patientDetailsFormBasic',
  defaults: {
    patientDetailsForm: {
      model: undefined
    }
  }
})
export class PatientDetailsFormBasicState {
  constructor(private tenantPipe: TenantDatePipe) {}
  @Selector()
  static form(state: PatientDetailsFormBasicStateModel) { return state.patientDetailsForm.model; }

  @Action(SetProfileForSixCpaAction)
  onSetProfile(ctx: StateContext<PatientDetailsFormBasicStateModel>, { profile }: SetProfileForSixCpaAction) {
    const patient = profile.patient;
    ctx.patchState({
      patientDetailsForm: {
        model: {
          patientFirstName: patient.firstname,
          patientSurname: patient.surname,
          patientDOB: patient.birthDate && moment(this.tenantPipe.transform(patient.birthDate.toString(), 'YYYY-MM-DD'), 'YYYY-MM-DD').toDate(),
          medicareNumber: getMedicareDVAOrRepatNumber(patient)
        }
      }
    });
  }
 
  @Action(UpdatePatientDetailsForm)
  updateForm(ctx: StateContext<PatientDetailsFormBasicStateModel>, { form }: UpdatePatientDetailsForm) {
    ctx.patchState({
      patientDetailsForm: {
        model: {
          patientFirstName: form.firstName,
          patientSurname: form.surname,
          patientDOB: moment(form.dob, 'YYYY-MM-DD').toDate(),
          medicareNumber: form.medicareNumber,
          dateOfMedsCheck: moment(form.dateOfMedsCheck, 'YYYY-MM-DD').toDate(),
          reviewed: form.reviewed
        }
      }
    });
  }

}
