<section>
  <form #f="ngForm" (ngSubmit)="onSubmit(f)">
    <div class="form-group">
      <label class="font__bold font__title-1" for="pre-selected-messages">Pre-selected messages</label>
      <select 
      class="form-control pre-selected-messages color__grey-text" 
      g-xs="py2" 
      id="pre-selected-messages"
      name="templateId"
      #templateId="ngModel"
      [ngModel]="'0'"
      (change)="onTemplateChange(f)">
        <option value="0">
          Use a pre-selected emails to send to your clients
        </option>
        <option *ngFor="let template of templates" [value]="template.id">
          {{template.title}}
        </option>
      </select>
    </div>
    <div class="form-group" [ngClass]="{'has-error': subject.invalid && subject.touched}">
        <label class="font__bold font__title-1" for="email-subject">Subject</label>
        <input id="email-subject"  class="form-control" #subject="ngModel" name="subject" required ngModel />
        <label *ngIf="subject.invalid && subject.touched" class="form-help-text">No subject provided</label>
    </div>
    <div class="form-group" [ngClass]="{'has-error': message.invalid && message.touched}">
      <label class="font__bold font__title-1" for="email-message">Your Message</label>
      <textarea id="email-message" g-xs="p3" class="form-control email-message" required #message="ngModel" name="message" ngModel>
        {{message}}
      </textarea>
      <label *ngIf="message.invalid && message.touched" class="form-help-text">No message provided</label>
    </div>
    <button type="submit" g-xs="p2 text:center" class="btn__yellow color__primary-text bg__yellow font__bold" [disabled]="f.invalid || !client.emailAddress && subject.invalid && subject.touched">Send Email</button>
    <span *ngIf="!client.emailAddress" class="alert alert-danger">No client email on file</span>
  </form> 
</section>