import { Component, OnInit, Output, Input, OnDestroy, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { CrmService } from '../../../shared/services/crm.service';
import { Note } from '../../../shared/models/crm/note.model';
import { Subscription } from 'rxjs/Subscription';
import { NoteType } from '../../../shared/models/crm/noteType.model';
import { hide } from '../../../shared/core/animations';
import { NoteTypeEnum } from '~/shared/models/note/note-type.enum';
import { extractEScriptFromNote } from '~/shared/helpers/eScript.helper';
import { Select, Store } from '@ngxs/store';
import { SetEScriptToken, ToggleEScriptModal, SetEScriptNote } from '~/shared/components/escripts/state/escripts.actions';
import * as moment from 'moment';
import { ProfileState } from '@base/modules/core/public-api';
import { ActionClientNote, ResetOrdersCycle } from '~/modules/profile/notes/state/notes.actions';
import { combineLatest, Observable } from 'rxjs';
import { NotesState } from '~/modules/profile/notes/state/notes.state';

@Component({
  selector: 'app-note-list',
  templateUrl: './note-list.component.html',
  styleUrls: ['./note-list.component.scss'],
  animations: [
    hide
  ],
})
export class NoteListComponent implements OnInit, OnDestroy {
  @Select(NotesState.clientNotes) clientNotes$: Observable<Note[]>;
  @Select(NotesState.noteTypes) noteTypes$: Observable<NoteType[]>;

  @Input() isScrollNextLoaded = 'in';
  @Input() noteList: Note[] = [];
  @Input() noteTypeList: NoteType[] = [];
  @Output() scrollNotes: EventEmitter<number> = new EventEmitter();
  @Output() noteActioned = new EventEmitter<any>();
  confirmOrderCycleRemoval = false;
  confirmOrderCycleReset = false;
  confirmNoteRemoval = false;
  currentNoteToAction: Note;

  private sub: Subscription;
  showSelectedPopup = 'in';
  isLoaded = true;
  selectedNotes: Note[] = [];
  modifiedNotes: Note[] = [];
  args: any;
  property: string;
  isDesc = false;
  direction = 1;
  page = 1;
  modalText = 'before';

  constructor(private router: Router,
    private crmService: CrmService, private store: Store) { }

  ngOnInit() { }

  dateTimeFormat(item: Note)
  {
    return (item.type === 19) ? "DD/MM/YYYY hh:mm a" : "DD/MM/YYYY";
  }

  onScroll() {
    this.scrollNotes.emit(this.page);
  }

  toggle(val: string) {
    if (val === 'in') { return 'out'; } else { return 'in'; }
  }

  actionNote(note: Note) {
    const actionDate = moment(note.nextActionDate);
    const now = new Date();
    if (note.type === NoteTypeEnum.order && !actionDate.isSame(now, 'day'))
    {
      if (actionDate.isBefore(now, 'days')) {
        this.modalText = 'after';
      } else if (actionDate.isAfter(now, 'days')) {
        this.modalText = 'before';
      }
      this.currentNoteToAction = note;
      this.confirmOrderCycleReset = true;
      return;
    }

    this.actionClientNote(note);
  }

  actionClientNote(note: Note)
  {
    this.sub = this.crmService.actionNote({ id: note.id }).subscribe((data) => {
      this.onChangeNote(note);
      this.noteActioned.emit({ isComplete: true, count: 1 });
    });
  }

  removeNote(note: Note) {
    if (note.type == NoteTypeEnum.order) {
      this.currentNoteToAction = note;
      this.confirmOrderCycleRemoval = true;
    }
    else {
      this.removeSingleNote(note);
    }
  }

  removeSingleNote(note: Note) {
    this.sub = this.crmService.removeNote(note.id).subscribe(
      () => {
        this.onChangeNote(note);
        this.noteActioned.emit({ isComplete: false, count: 1 });
      },
      (error) => console.log(error));
  }

  completeNoteList() {
    if (this.selectedNotes.length > 0) {
      this.isLoaded = false;
      const ids = this.selectedNotes.map(x => x.id);
      this.sub = this.crmService.completeNoteList(ids).subscribe((data) => {
        this.noteListActioned(ids, true);
      });
    }
  }

  confirmCycleReset() {
    const note = this.currentNoteToAction;
    this.dismiss();
    this.actionClientNote(note);
    this.store.dispatch(new ResetOrdersCycle(note.tenantCustomerId));
  }

  confirmMaintainCycle() {
    const note = this.currentNoteToAction;
    this.dismiss();
    this.actionClientNote(note);
  }

  snoozeNoteList() {
    if (this.selectedNotes.length > 0) {
      this.modifiedNotes = this.selectedNotes;
    }
  }

  removeNoteList() {
    if (this.selectedNotes.length > 0) {
      if (this.selectedNotes.some(note => note.type == NoteTypeEnum.order)) {
        this.confirmOrderCycleRemoval = true;
      }
      else {
        this.removeSelectedNotes();
      }
    }
  }

  removeSelectedNotes() {
    const ids = this.selectedNotes.map(x => x.id);
    this.sub = this.crmService.removeNoteList(ids).subscribe((data) => {
      this.noteListActioned(ids, false);
    });
  }

  noteListActioned(ids: any, isComplete: boolean) {
    this.deleteFromList(ids);
    this.noteActioned.emit({ isComplete: isComplete, count: ids.length });
    this.selectedNotes = [];
    this.isLoaded = true;
  }

  deleteFromList(ids: number[]) {
    ids.forEach(item => {
      const index = this.noteList.findIndex(c => c.id === item);
      if (index !== -1) {
        this.noteList.splice(index, 1);
      }
    });
  }

  navigate(item: Note) {
    if (item.notificationType === 'General') {
      this.router.navigate(['profile', item.clientId, 'categories']);
    }
    if (item.notificationType === 'Mail') {
      this.router.navigate(['profile', item.clientId, '/comms', item.notificationType]);
    }
    if (item.notificationType === 'Callback') {
      this.router.navigate(['profile', item.clientId, '/comms', 'Phone']);
    }
  }

  setModifiedNote(item: Note) {
    if (item.type == NoteTypeEnum.eScript) {
      return this.store.dispatch([
        new SetEScriptToken(extractEScriptFromNote(item.body)),
        new SetEScriptNote(item.id),
        new ToggleEScriptModal(true)
      ]);
    }

    if (this.modifiedNotes.length === 0) {
      this.modifiedNotes = [item];
    } else {
      if (!this.modifiedNotes.find(x => x === item)) {
        this.modifiedNotes = [];
        setTimeout(() => {
          this.modifiedNotes = [item];
        }, 100);
      }
    }
  }

  goToLink = (item: Note) => {
    this.router.navigate(['profile', item.clientId, 'notes']);
  }

  clearModifiedNote() {
    this.modifiedNotes = [];
  }

  getArrow(value: string) {
    if (value === this.property && this.isDesc) {
      return 'fa-sort-asc';
    } else {
      return 'fa-sort-desc';
    }
  }

  sort(property) {
    this.isDesc = !this.isDesc;
    this.property = property;
    const direction = this.isDesc ? 1 : -1;
    this.args = { property: property, direction: direction };
  }

  selectNote(event) {
    if (event.event.target.checked) {
      const index = this.selectedNotes.findIndex(c => c.id === event.note.id);
      if (index === -1) {
        this.selectedNotes.push(event.note);
      }
    } else {
      const index = this.selectedNotes.findIndex(c => c.id === event.note.id);
      if (index !== -1) {
        this.selectedNotes.splice(index, 1);
      }
    }
  }

  setChecked(item: Note) {
    if (this.selectedNotes.length > 0) {
      if (this.selectedNotes.findIndex(c => c.id === item.id) !== -1) {
        return true;
      }
    }
    return false;
  }

  selectAll(e) {
    if (e.target.checked) {
      this.selectedNotes = this.noteList.filter(x => x.type !== 6 && x.type !== 7);
    } else {
      this.selectedNotes = [];
    }
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  private onChangeNote(note) {
    const index = this.noteList.findIndex(c => c.id === note.id);
    if (index !== -1) {
      this.noteList.splice(index, 1);
    }
  }

  confirmRemoval() {
    this.confirmOrderCycleRemoval = false;
    if (this.currentNoteToAction == null) {
      this.removeSelectedNotes();
    }
    else{
      this.removeSingleNote(this.currentNoteToAction);
    }

    this.currentNoteToAction = null;
  }

  dismiss() {
    this.confirmOrderCycleRemoval = false;
    this.currentNoteToAction = null;
    this.confirmNoteRemoval = false;
    this.confirmOrderCycleReset = false;
  }

  daysUntilNextDueDate(note: Note)
  {
    var nextDate = note.nextActionDate;
    var today = new Date();
    while(moment(nextDate).isBefore(today))
    {
      switch(note.repeatType)
      {
        case 1:
          nextDate = moment(nextDate).add(note.intervalValue, "days").toDate();
          break;
        case 2:
          nextDate = moment(nextDate).add(note.intervalValue, "weeks").toDate();
          break;
        case 3:
          nextDate = moment(nextDate).add(note.intervalValue, "months").toDate();
          break;
      }
    }

    var daysUntilNextDue = moment(nextDate).diff(moment(today), 'days');
    return daysUntilNextDue;
  }
}
