export interface ChangePrimaryContactRequest {
    newContactTenantCustomerId: number;
    linkedTenantCustomerIds: number[];
    primaryContactType: PrimaryContactType
}

export enum PrimaryContactType {
    SMS,
    Email
}
