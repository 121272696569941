import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subscription, Observable } from 'rxjs';
import { Select, Store } from '@ngxs/store';
import { CommTemplatesState, CommTemplatesStateModel } from './state/comm-templates.state';
import { CommTemplate, TemplateType } from '../../../shared/models/pharmacy/comm-template.model';
import 'rxjs/add/operator/debounceTime';
import { ActivateCommTemplatesAction, DeactivateCommTemplatesAction } from './state/comm-templates.actions';
import { Sort } from '../../../shared/models/sort.enum';
import { SetupAddEditCommTemplateModalAction, OpenAddEditCommTemplateModalAction } from './add-edit-comm-template-modal/state/add-edit-comm-template-modal.actions';

@Component({
  selector: 'app-comm-templates',
  templateUrl: './comm-templates.component.html',
  styleUrls: ['./comm-templates.component.scss'],
})
export class CommTemplatesComponent implements OnInit, OnDestroy {
  @Select(CommTemplatesState) commTemplatesState$: Observable<CommTemplatesStateModel>;
  @Select(CommTemplatesState.commTemplates) commTemplates$: Observable<CommTemplate[]>;
  sortByTypes = Sort;
  sortBy = Sort.Newest;
  templateType = TemplateType;
  searchInput = new FormControl();
  searchQuery = '';
  formCtrlSub: Subscription;

  constructor(private store: Store) { }

  ngOnInit() {
    this.searchdDebounce();
  }

  private searchdDebounce() {
    this.formCtrlSub = this.searchInput.valueChanges
      .debounceTime(200)
      .subscribe(newValue => {
        this.searchQuery = newValue;
      });

  }
  toggleActivation(item: CommTemplate) {
    item.isActive ? this.store.dispatch(new DeactivateCommTemplatesAction(item.id)) : this.store.dispatch(new ActivateCommTemplatesAction(item.id));
  }

  ngOnDestroy() {
    if (this.formCtrlSub) { this.formCtrlSub.unsubscribe(); }
  }

  edit(item: CommTemplate) {
    this.store.dispatch([new SetupAddEditCommTemplateModalAction(item), new OpenAddEditCommTemplateModalAction()]);
  }

  delete(item: CommTemplate) {
    this.store.dispatch(new DeactivateCommTemplatesAction(item.id));
  }

  create() {
    this.store.dispatch([new SetupAddEditCommTemplateModalAction(), new OpenAddEditCommTemplateModalAction()]);
  }
}
