import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild, } from '@angular/core';
import { MessageSource, Attachment, SmsMessageHistory } from '../../../../../shared/models/messageHistory.model';
import { Select, Store } from '@ngxs/store';
import { SmsAttachmentModalState } from '../sms-attachment-modal/state/sms-attachment-modal.state';
import { Observable, Subscription } from 'rxjs';
import { OpenAttachmentsModal } from '../sms-attachment-modal/state/sms-attachment-modal.actions';
import { detectEScript, extractEScript } from '../../../../../shared/helpers/eScript.helper';
import { ToggleEScriptModal, SetEScriptToken, DispensePaperlessScript } from '../../../../../shared/components/escripts/state/escripts.actions';
import { DispensePaperlessRequest } from '../../../../../shared/models/lite/paperlessDispense.model';
import { ProfileState } from '../../../../../modules/core/profile/state/profile.state';
import { PharmacyLocation } from '../../../../../shared/models/pharmacy/pharmacy-address.model';
import { PharmacyCommsDetailsState } from '../../../../../modules/pharmacy-comms/pharmacy-comms-details/state/pharmacy-comms-details.state';
import { PharmacyService } from '../../../../../shared/services/pharmacy.service';
import { ConnectionTypeEnum } from '@base/shared/models/connectionType.model';
import { NgxAutoScroll } from 'ngx-auto-scroll';

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss']
})
export class HistoryComponent implements OnInit, OnDestroy, AfterViewInit {
  @Select(SmsAttachmentModalState.showModal) showModal$: Observable<boolean>;
  @Input() messageHistory: SmsMessageHistory[] = [];
  @ViewChild(NgxAutoScroll) ngxAutoScroll: NgxAutoScroll;

  messageSource = MessageSource;
  pharmacy: PharmacyLocation;
  pharmacySubscription: Subscription;

  constructor(private store: Store, private pharmacyService: PharmacyService) { }

  ngOnDestroy(): void {
    if (this.pharmacySubscription) { this.pharmacySubscription.unsubscribe() }
  }

  ngOnInit(): void {
    this.pharmacySubscription = this.pharmacyService.getPharmacyLocation().subscribe(pharmacy => {
      this.pharmacy = pharmacy;
    });
  }

  ngAfterViewInit(): void {
    this.forceScrollDown();
  }

  forceScrollDown(): void {
    this.ngxAutoScroll.forceScrollDown();
  }

  getMessageSource(source: MessageSource) {
    return `Created by: ${MessageSource[source]}`;
  }

  showAttachmentsModal(attachments: Attachment[]) {
    this.store.dispatch(new OpenAttachmentsModal(attachments))
  }

  checkForEScript(message: string) {
    return detectEScript(message);
  }

  checkIsSystem(source) {
    return source === MessageSource.BulkCommunications
      || source === MessageSource.BulkCommunications
      || source === MessageSource.RecurringMessage
      || source === MessageSource.ScheduledMessage
      || source === MessageSource.System;
  }

  openEScriptModal(message: string) {
    const pharmacyType = this.pharmacy.connectionType;
    const scriptToken = extractEScript(message);

    switch (pharmacyType) {
      case ConnectionTypeEnum.ZDispense: {
        return this.store.dispatch([new SetEScriptToken(scriptToken), new ToggleEScriptModal(true)]);
      }
      case ConnectionTypeEnum.FredDispense: {
        alert('This feature is unavailable for FRED Dispense');
        break;
      }
      case ConnectionTypeEnum.FredNXTDispense: {
        alert('This feature is unavailable for FRED NXT Dispense');
        break;
      }
      case ConnectionTypeEnum.Aquarius: {
        this.dispenseAquariusMedication(scriptToken);
        break;
      }
      default: {
        alert('This feature is unavailable for your dispense system');
      }
    }
  }

  dispenseAquariusMedication(token: string): void {
    const patient = this.store.selectSnapshot(ProfileState.profile).patient;
    const request: DispensePaperlessRequest = {
      token: token,
      drugName: '',
      firstName: patient.firstname,
      lastName: patient.surname,
      prescriberName: ''
    };
    this.store.dispatch(new DispensePaperlessScript(request));
  }

  urlify(text) {
    if (!text) {
      return text;
    }

    var urlRegex = /(https?:\/\/[^\s]+)/g;
    return text.replace(urlRegex, function (url) {
      return `<a target="_blank" href="${url}">${url}</a>`;
    });
  }
}
