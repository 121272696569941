import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import { ReactiveFormsModule } from '@angular/forms';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { RadioHighlightModule } from '../../../../../shared/components/radio-highlight/radio-highlight.module';
import { HMRGPDetailsReferralFormBasicComponent } from './basic/hmr-gp-details-referral-form-basic.component';
import { HMRGPDetailsReferralFormDetailedComponent } from './detailed/hmr-gp-details-referral-form-detailed.component';
import { HMRGPDetailsReferralFormHostDirective, HMRGPDetailsReferralFormComponent } from './gp-details-referral-form/hmr-gp-details-referral-form.components';
import { HMRGPDetailsReferralFormBasicLightComponent } from './basic-light/hmr-gp-details-referral-form-basic-light.component';

@NgModule({
    declarations: [
      HMRGPDetailsReferralFormComponent,
      HMRGPDetailsReferralFormBasicComponent,
      HMRGPDetailsReferralFormBasicLightComponent,
      HMRGPDetailsReferralFormDetailedComponent,
      HMRGPDetailsReferralFormHostDirective
    ],
    imports: [
      CommonModule,
      NgxsFormPluginModule,
      ReactiveFormsModule,
      BsDatepickerModule.forRoot(),
      RadioHighlightModule
    ],
    exports: [
      HMRGPDetailsReferralFormComponent
    ],
    entryComponents: [
      HMRGPDetailsReferralFormBasicComponent,
      HMRGPDetailsReferralFormBasicLightComponent,
      HMRGPDetailsReferralFormDetailedComponent
    ]
  })
  export class HMRGPDetailsReferralFormModule { }
