import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import {
  ValidateFormLinkForScreening,
  RedirectToError,
} from '../state/vaccination.actions';
import { VaccinationErrorType, VaccinationRoute } from '../types/vaccination';

@Injectable()
export class VaccinationResolver implements Resolve<Observable<any>> {
  constructor(private router: Router, private store: Store) {}

  resolve(route: ActivatedRouteSnapshot) {
    const { link } = route.queryParams;
    if (link) {
      return this.store
        .dispatch(new ValidateFormLinkForScreening(link))
        .map(({ vaccination }) => {
          if (vaccination.error) {
            this.store.dispatch(
              new RedirectToError({
                type: VaccinationErrorType.InvalidLink,
                error: 'Invalid Link',
              })
            );
          } else {
            this.router.navigate([
              VaccinationRoute.Screening + '/' + VaccinationRoute.WhoopingCough,
            ]);
          }
        });
    } else {
      this.store.dispatch(
        new RedirectToError({
          type: VaccinationErrorType.InvalidLink,
          error: 'Invalid Link',
        })
      );
    }
  }
}
