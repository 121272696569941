import { Group } from '../../../shared/models/group.model';

export class GroupPageAction {
  static readonly type = '[GroupPage] Add item';
  constructor(public payload: string) { }
}


export enum GroupPageActionTypes {
  GET_GROUP_LIST_ACTION = '[GroupPage] Get Group List',
  GET_GROUP_PAGE_ERROR_ACTION = '[GroupPage] Get Group Error',
  CREATE_NEW_GROUP_PAGE_ACTION = '[GroupPage] Create New Group',
  DELETE_GROUP_PAGE_ACTION = '[GroupPage] Delete Group',
  UPDATE_GROUP_PAGE_ACTION = '[GroupPage] Update Group'
}

export class GetGroupList {
  static readonly type = GroupPageActionTypes.GET_GROUP_LIST_ACTION;
}

export class GroupPageError {
  static readonly type = GroupPageActionTypes.GET_GROUP_PAGE_ERROR_ACTION;
  constructor(public error: any) { }
}


export class CreateNewGroupPage {
  static readonly type = GroupPageActionTypes.CREATE_NEW_GROUP_PAGE_ACTION;
  constructor(public item: Group) { }
}

export class DeleteGroupPage {
  static readonly type = GroupPageActionTypes.DELETE_GROUP_PAGE_ACTION;
  constructor(public itemId: string) { }
}


export class UpdateGroupPage {
  static readonly type = GroupPageActionTypes.UPDATE_GROUP_PAGE_ACTION;
  constructor(public item: Group) { }
}
