<ng-container *ngIf="(medicationListConfirm$ | async) as medicationListConfirm">
    <div class="confirm-form" *ngIf="medicationListConfirm.slideMode === 'in'">
        <div class="accordian" *ngIf="(patientReport$ | async) as patientReport">
            <div class="row align-items-center top-panel-active">
                <div class="px-3 py-2 col-8 ">
                    <div class="title">Patients Report (Meds List)</div>
                </div>
            </div>
            <div class="form p-4">
                <form [formGroup]="medsListEmailForm" ngxsForm="medicationListConfirm.medsListEmailForm">
                    <app-download-pdf *ngIf="!patientReport.loading; else loadingPatientReport"
                        [name]="patientReport.name" (downloadReportEvent)="getPatientReport()">
                    </app-download-pdf>
                    <p class="col-form-label-lg font__bold">Send Medication List by email, SMS or Scrypt Mobile</p>
                    <div class="d-flex align-items-center">
                        <button [disabled]="medicationListConfirm.loading" class="btn__green mt-2"
                            (click)="openMedsListSendFormModal()">Send Meds List</button>
                        <i *ngIf="medicationListConfirm.loading"
                            class="fas fa-sync fa-spin font__title-2 ml-2 mt-2"></i>
                    </div>
                    <ng-template #loadingPatientReport>
                        <app-loader></app-loader>
                    </ng-template>
                </form>
            </div>
        </div>

        <form class="confirm-form__form" [formGroup]="doctorDetailsForm"
            ngxsForm="medicationListConfirm.doctorDetailsForm" (submit)="sendDoctorReport()">
            <div class="accordian" *ngIf="(doctorReport$ | async) as doctorReport">
                <div class="row align-items-center top-panel-active">
                    <div class="px-3 py-2 col-8 ">
                        <div class="title">Doctor Report</div>
                    </div>
                    <div class="w-75">
                        <div class="form-group px-4 pt-4">
                            <p class="col-form-label-lg">Doctor's Full Name</p>
                            <input formControlName="fullName" [typeahead]="medicationListConfirm.doctors"
                                typeaheadOptionField="fullName" class="form-control form-control-lg typeahead"
                                (typeaheadOnSelect)="onSelect($event)" placeholder="Start typing a name...">
                        </div>
                        <div class="form-group p-4">
                            <p class="col-form-label-lg">Doctor's Address</p>
                            <textarea type="text" formControlName="doctorAddress" class="form-control form-control-lg"
                            id="doctorAddress" ></textarea>
                          </div>
                        <app-customise-doctor-report></app-customise-doctor-report>

                    </div>
                </div>

                <div class="form p-4">
                    <app-loader *ngIf="doctorReport.loading; else downloadPdf"></app-loader>
                    <ng-template #downloadPdf>
                        <app-download-pdf [name]="doctorReport.name" (downloadReportEvent)="getDoctorReport()">
                        </app-download-pdf>
                    </ng-template>
                    <p class="col-form-label-lg font__bold">Email Doctor's Report</p>
                    <div class="form-group">
                        <p class="col-form-label-lg">Email Address</p>
                        <input type="text" formControlName="email" class="form-control form-control-lg"
                            placeholder="Email Address">
                    </div>
                    <div class="d-flex align-items-center">
                        <button type="submit" [disabled]="doctorDetailsForm.invalid || medicationListConfirm.loading"
                            class="btn__green mt-2">Send report to
                            doctor</button>
                        <i *ngIf="medicationListConfirm.loading"
                            class="fas fa-sync fa-spin font__title-2 ml-2 mt-2"></i>
                    </div>
                </div>
            </div>
        </form>
        <div class="response">
            <app-report-response [showMode]="medicationListConfirm.showResponse" (hideEvent)="hideResponse()">
            </app-report-response>
        </div>
    </div>
</ng-container>
<app-channel-select-modal *ngIf="(showSendDocumentForm$ | async)" (submit)="onChannelSelected($event)" (cancel)="onChannelSelectCancelled()"></app-channel-select-modal>

