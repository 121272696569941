import { Timezone } from '../timezone.model';


export const fetchAllTimeZones = '[timezone] fetch all';
export const fetchTimeZone = '[timezone] fetch';
export const setAllTimeZones = '[timezone] set all';
export const setTimeZone = '[timezone] set';

export class FetchAllTimezones {
    public static readonly type = fetchAllTimeZones;
    constructor() {}
}

export class FetchTimezone {
    public static readonly type = fetchTimeZone;
    constructor() {}
}

export class SetAllTimezones {
    public static readonly type = setAllTimeZones;
    constructor(public timezones: Timezone[]) {}
}

export class SetSelectedTimezone {
    public static readonly type = setTimeZone;
    constructor(public timezoneId: string) {}
}
