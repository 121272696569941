import { State, Action, StateContext, Selector } from '@ngxs/store';
import { GetFeaturesAction } from './features.actions';
import { FeaturesService } from './features.service';
import { tap, catchError } from 'rxjs/operators';
import { combineLatest, of } from 'rxjs';
import { FeatureFlags } from './features.enum';
import { Injectable } from '@angular/core';
import { Feature } from '../core/features/features.state';

export class FeaturesStateModel {
  public features: Feature;
  public version: string;
}
@Injectable()
@State<FeaturesStateModel>({
  name: 'features',
  defaults: {
    features: {},
    version: null,
  },
})
export class FeaturesState {
  @Selector()
  static isEnabled(
    state: FeaturesStateModel
  ): (featureName: FeatureFlags) => boolean {
    return (featureName: string) => state.features[featureName] || false;
  }

  @Selector()
  static getFeatures(
    state: FeaturesStateModel
  ): Feature {
    return state.features;
  }

  @Selector()
  static getVersion(state: FeaturesStateModel) {
    return state.version;
  }

  constructor(private featureService: FeaturesService) {}

  @Action(GetFeaturesAction)
  get(ctx: StateContext<FeaturesStateModel>) {
    return combineLatest([
      this.featureService.getLatest(),
      this.featureService.getVersion()
    ]).pipe(
      tap(([features, response]) =>
        this.setFeatures(ctx, { features, version: response.version })
      ),
      catchError((err) => of(console.log(err)))
    );
  }

  private setFeatures(
    { setState }: StateContext<FeaturesStateModel>,
    { features, version }
  ) {
    setState({
      version,
      features: Object.assign(
        {},
        ...Object.keys(features).map((s) => ({ [s]: features[s] }))
      ),
    });
  }
}
