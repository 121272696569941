import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PharmacyCommsComponent } from './pharmacy-comms.component';
import { PharmacyCommsRoutingModule } from './pharmacy-comms-routing.module';
import { TabsModule } from 'ngx-bootstrap';
import { CommTemplatesModule } from '../comm-templates/comm-templates.module';
import { PharmacyCommsDetailsModule } from '../pharmacy-comms-details/pharmacy-comms-details.module';
import { CommsRemindersModule } from '../comms-reminders/comms-reminders/comms-reminders.module';
import { NgxsModule } from '@ngxs/store';
import { CommTemplatesState } from '../comm-templates/state/comm-templates.state';
import { TriggersState } from '../comms-reminders/state/triggers.state';
import { CommTemplatesService } from '../comm-templates/services/comm-templates.service';
import { TriggerService } from '../comms-reminders/services/trigger.service';
import { AddEditCommTemplateModalModule } from '../comm-templates/add-edit-comm-template-modal/add-edit-comm-template-modal.module';
import { CommQueueModule } from '../comms-queue/comms-queue.module';
import { PharmacyService } from '../../../shared/services/pharmacy.service';

@NgModule({
  declarations: [PharmacyCommsComponent],
  imports: [
    CommonModule,
    PharmacyCommsRoutingModule,
    PharmacyCommsDetailsModule,
    TabsModule.forRoot(),
    CommTemplatesModule,
    CommQueueModule,
    CommsRemindersModule,
    AddEditCommTemplateModalModule,
    NgxsModule.forFeature([CommTemplatesState, TriggersState]),
  ],
  providers: [CommTemplatesService, TriggerService, PharmacyService],
  exports: [PharmacyCommsComponent],
})
export class PharmacyCommsModule {}
