import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DesktopComponent } from './../dekstop/desktop.component';
import { DashboardComponent } from './dashboard.component';
import { DashboardPageComponent } from '../../system/dashboard-page/dashboard-page.component';

const routes: Routes = [
  {
    path: '', component: DashboardComponent, children: [
      { path: 'dashboard', component: DashboardPageComponent },
      { path: 'desktop', component: DesktopComponent },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})

export class DashboardRoutingModule {
}
