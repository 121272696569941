import { State, Action, StateContext, Selector } from '@ngxs/store';
import { catchError, finalize, tap } from 'rxjs/operators';
import { Trigger } from '../../../../shared/models/pharmacy/trigger.model';
import { TriggerService } from '../services/trigger.service';
import { GetTriggers, GetTriggersSuccess, ToggleTriggerActive, ToggleTriggerSuccess, TriggerStateError } from './triggers.actions';
import { Injectable } from '@angular/core';

export class TriggersStateModel {
  triggers: Trigger[];
  loading: boolean;
  errors: any;
  loadingMessage: string;
  showAddEditModal: boolean;
}
@Injectable()
@State<TriggersStateModel>({
  name: 'Triggers',
  defaults: {
    triggers: [],
    loading: false,
    errors: null,
    loadingMessage: null,
    showAddEditModal: false
  }
})
export class TriggersState {
  constructor(private triggerService: TriggerService) { }

  @Selector()
  static triggers(state: TriggersStateModel) { return state.triggers }

  @Selector()
  static state(state: TriggersStateModel) { return state }

  @Action(GetTriggers)
  getTriggers(ctx: StateContext<TriggersStateModel>, { }: GetTriggers) {
    ctx.patchState({
      loading: true,
      loadingMessage: 'Fetching your triggers...'
    });

    return this.triggerService.getTriggers().pipe(
      tap(triggers => ctx.dispatch(new GetTriggersSuccess(triggers))),
      catchError(err => ctx.dispatch(new TriggerStateError(err))),
      finalize(() => ctx.patchState({ loading: false, loadingMessage: null }))
    )
  }

  @Action(ToggleTriggerActive)
  toggleTriggerActive(ctx: StateContext<TriggersStateModel>, { triggerId }: ToggleTriggerActive) {
    ctx.patchState({
      loading: true,
      loadingMessage: 'Updating...'
    });

    return this.triggerService.toggleActive(triggerId).pipe(
      tap(() => ctx.dispatch(new ToggleTriggerSuccess(triggerId))),
      catchError(err => ctx.dispatch(new TriggerStateError(err))),
      finalize(() => ctx.patchState({ loading: false, loadingMessage: null }))
    )
  }

  @Action(ToggleTriggerSuccess)
  toggleTriggerSuccess(ctx: StateContext<TriggersStateModel>, { triggerId }: ToggleTriggerSuccess) {
    let triggers = ctx.getState().triggers;
    let trigger = triggers.find(t => t.id == triggerId);
    const index = triggers.indexOf(trigger);
    trigger.enabled = !trigger.enabled;
    triggers[index] = trigger;

    ctx.patchState({
      triggers: triggers
    });
  }

  @Action(GetTriggersSuccess)
  getTriggersSuccess(ctx: StateContext<TriggersStateModel>, { triggers }: GetTriggersSuccess) {
    ctx.patchState({
      triggers: triggers
    });
  }

  @Action(TriggerStateError)
  triggerStateError(ctx: StateContext<TriggersStateModel>, { error }: TriggerStateError) {
    ctx.patchState({
      errors: error.error
    });
  }
}
