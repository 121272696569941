import { Component, OnInit } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { DispenseRunState, DispenseRunStateModel } from '../state/dispense-run.state';

@Component({
  selector: 'app-dispense-run-page',
  templateUrl: './dispense-run-page.component.html',
  styleUrls: ['./dispense-run-page.component.scss']
})
export class DispenseRunPageComponent implements OnInit {
  @Select(DispenseRunState.state) dispenseRunState$: Observable<DispenseRunStateModel>;

  constructor() { }

  ngOnInit(): void {
  }

}
