import { AfterViewInit, Component, Input, ViewChild } from '@angular/core';
import { MessengerHistory, FirebaseMessageSource } from '../../../../../shared/models/messageHistory.model';
import { detectEScript, extractEScript } from '../../../../../shared/helpers/eScript.helper';
import { SetEScriptToken, ToggleEScriptModal } from '../../../../../shared/components/escripts/state/escripts.actions';
import { Store } from '@ngxs/store';
import { NgxAutoScroll } from 'ngx-auto-scroll';

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss']
})
export class HistoryComponent implements AfterViewInit {
  @ViewChild(NgxAutoScroll) ngxMessengerAutoScroll: NgxAutoScroll;
  @Input() messageHistory: MessengerHistory[] = [];
  messengerFrom = FirebaseMessageSource;
  showModal =  false;
  currentAttachment = null;

  constructor(private store: Store) { }

  ngAfterViewInit(): void {
    this.forceScrollDown();
  }

  forceScrollDown(): void {
    this.ngxMessengerAutoScroll.forceScrollDown();
  }

  checkForEScript(message: string) {
    return detectEScript(message);
  }

  openAttachment(attachment: string) {
    this.showModal = true;
    this.currentAttachment = attachment;
  }

  openAttachmentInNewWindow(attachment: string) {
    const url = window.URL.createObjectURL(attachment);
    window.open(url, '_blank');
  }

  closeModal() {
    this.showModal = false;
    this.currentAttachment = null;
  }

  openEScriptModal(message: string) {
    const scriptToken = extractEScript(message);
    this.store.dispatch([new SetEScriptToken(scriptToken), new ToggleEScriptModal(true)]);
  }

  urlify(text) {
    if(!text) {
      return text;
    }

    var urlRegex = /(https?:\/\/[^\s]+)/g;
    return text.replace(urlRegex, function(url) {
      return `<a target="_blank" href="${url}">${url}</a>`;
    });
  }
}
