import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';

import { BaseApi } from '../core/base-api/base-api';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import { StorageService } from '../core/storage/storage.service';
import { EmailType } from '../models/emailType.model';
import { Email } from '../models/email.model';
import { AlertService } from '~/modules/core/alert/alert.service';

const API = environment.baseApiUrl;

@Injectable()
export class EmailService extends BaseApi {
  constructor(public http: HttpClient, public router: Router, public storageService: StorageService, public alertService: AlertService) {
    super(http, router, storageService, alertService);
  }

  getClientEmailHistory(clientId: string): Observable<Email[]> {
    return this.get(`${API}/api/GetClientEmailHistory/${clientId}`);
  }

  sendEmail(email: Email): Observable<Email> {
    return this.post(`${API}/api/SendEmail`, email);
  }
}
