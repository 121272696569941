<ng-container>
  <app-med-list-medication-profile></app-med-list-medication-profile>

  <ng-container *ngIf="(sixCpaState$ | async) as sixCpaState">

    <div class="pt-4" *ngFor="let saving of [saving$ | async]" >
      <ng-container>
        <button class="button submit mr-3"  [disabled]="saving" (click)="submitMedicationList()">{{sixCpaState.readOnly ? 'Save':'Generate'}} Medication List</button>
        <span class="mr-3" (click)="openConfirmResetModal()">Reset Form</span>
      </ng-container>
      <ng-container *ngIf="sixCpaState.readOnly">
        <button class="button submit mr-3" [disabled]="saving" (click)="viewConfirmation()">View Report</button>
      </ng-container>
    </div>


    <app-confirm-reset *ngIf="sixCpaState.showResetModal" (closeEvent)="closeConfirmResetModal()"
      (submitEvent)="resetMedicationListFormList()">
    </app-confirm-reset>

  </ng-container>

</ng-container>
