<section class="details" g-xs="my3">
  <div class="grid">
    <tabset class="grid__unit base-tabs" g-xs="cols:12">
      <tab heading="Services">
        <ng-container *ngIf="(filteredSixCpaForms$ | async) as filteredSixCpaForms">
          <section>
            <form [formGroup]="profileServicesForm" class="form-inline">
              <div class=form-group pb-1>
                <label class="pr-3" for="formType">Filter</label>
                <select class="form-control form-control-md mw-25" formControlName="selectedFilter"
                  (change)="updateFormTypeFilter()" id="formType">
                  <option [ngValue]="filter.All" selected>All</option>
                  <option [ngValue]="filter.MedsCheck">Meds Check</option>
                  <option [ngValue]="filter.ClinicalIntervention">Clinical Intervention</option>
                  <option [ngValue]="filter.DAA">DAA</option>
                  <option [ngValue]="filter.StagedSupply">Staged Supply</option>
                  <option [ngValue]="filter.HMR">HMR</option>
                  <option [ngValue]="filter.SickLeaveCertificate">Sick Leave Certificate</option>
                  <option [ngValue]="filter.MedicationList">Medication List</option>
                </select>
              </div>
            </form>
            <div class="crm-block">
              <div g-xs="mt3">
                <div class="table-responsive border__grey">
                  <table class="table" *ngIf="filteredSixCpaForms">
                    <tbody>
                      <tr class="crm-block__header">
                        <th>Date Created</th>
                        <th>Created By</th>
                        <th>Type</th>
                        <th></th>
                      </tr>
                      <tr class="body-row" *ngFor="let form of filteredSixCpaForms">
                        <td>{{ form.createdUTC | tenantDate: 'DD/MM/YYYY hh:mm A' }}</td>
                        <td>{{ form.tenantName }}</td>
                        <td *ngIf="!form.claimId">{{ getFormType(form.formType, form.subType) }}
                          <span *ngIf="!form.hasSubmitted && form.formType != formTypes.AIRLookup"> -
                            <span class="draft">Draft</span>
                          </span>
                          <span *ngIf="form.hasSubmitted && form.claimId"> -
                            <span class="success">{{form.claimId}}</span>
                          </span>
                          <span *ngIf="form.submissionFailed">
                            - <span class="draft">&nbsp;Last submission failed</span>
                          </span>
                        </td>
                        <td *ngIf="form.claimId">{{ getClaimType(form.ppaClaimType) }}
                          <span class="success"> - {{form.claimId}}</span>
                        </td>
                        <td>
                          <ng-container *ngIf="form.loading; else editable">
                            <app-loader size="20px"></app-loader>
                          </ng-container>
                          <ng-template #editable>
                            <i *ngIf="form.formType != formTypes.AIRLookup" [ngClass]="getEditIcon(form.hasSubmitted) | async" (click)="editSixCpaForm(form)"></i>
                          </ng-template>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </section>
        </ng-container>
      </tab>
      <tab heading="Documents">
        <ng-container  *ngIf="(features$ | async)?.documentStorage as documentStorage">
          <app-document-list [enabled]="documentStorage"></app-document-list>
        </ng-container>
      </tab>
    </tabset>
  </div>
</section>
