<div class="spinner" [style.width]="size" [style.height]="size">
    <svg viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
        <circle class="length" fill="none" stroke-width="8" stroke-linecap="round" cx="33" cy="33" r="28"></circle>
    </svg>
    <svg viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
        <circle fill="none" stroke-width="8" stroke-linecap="round" cx="33" cy="33" r="28"></circle>
    </svg>
    <svg viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
        <circle fill="none" stroke-width="8" stroke-linecap="round" cx="33" cy="33" r="28"></circle>
    </svg>
    <svg viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
        <circle fill="none" stroke-width="8" stroke-linecap="round" cx="33" cy="33" r="28"></circle>
    </svg>
</div>
<div class="loadingMessage" *ngIf="loadingMessage; else loadingStateTemplate">
    {{loadingMessage}}
</div>

<ng-template #loadingStateTemplate>
    <div class="loadingMessage" *ngIf="(loadingState$ | async) as loadingState">
        {{loadingState.loadingMessage}}
    </div>
</ng-template>
