<form [formGroup]="consentEligibilityForm" ngxsForm="ssConsentEligibilityFormDetailed.consentEligibilityForm"
  class="p-4">
  <div class="form-group pb-1">
    <label class="input-label mb-0">Written patient consent for provision of data?</label>
    <a class="d-block" (click)="openSSConsentForm()">Click here to view the patient consent form.</a>
    <div>
      <app-radio-highlight controlName="consentYes"
        [hasError]="formControls.writtenConsentForProvisionOfData.invalid && (showInvalid$ | async)"
        [isHighlighted]="!!consentEligibilityForm.value.writtenConsentForProvisionOfData">
        <input class="form-check-input" type="radio" name="writtenConsentForProvisionOfData" id="consentYes"
          formControlName="writtenConsentForProvisionOfData" [value]="true">
        <label class="form-check-label" for="consentYes">Yes</label>
      </app-radio-highlight>
      <app-radio-highlight controlName="consentNo"
        [hasError]="formControls.writtenConsentForProvisionOfData.invalid && (showInvalid$ | async)"
        [isHighlighted]="!consentEligibilityForm.value.writtenConsentForProvisionOfData">
        <input class="form-check-input" type="radio" name="writtenConsentForProvisionOfData" id="consentNo"
          formControlName="writtenConsentForProvisionOfData" [value]="false" checked>
        <label class="form-check-label" for="consentNo">No</label>
      </app-radio-highlight>
    </div>
  </div>
  <div class="form-group pb-1">
    <label class="input-label mb-0">In the last six months did the patient go to the GP or hospital because of problems
      with the medicine?</label>
    <div>
      <app-radio-highlight controlName="hosipitalYes"
        [hasError]="formControls.gpOrHospitalLastSixMonthsForMedicationProblems.invalid && (showInvalid$ | async)"
        [isHighlighted]="!!consentEligibilityForm.value.gpOrHospitalLastSixMonthsForMedicationProblems">
        <input class="form-check-input" type="radio" name="gpOrHospitalLastSixMonthsForMedicationProblems"
          id="hosipitalYes" formControlName="gpOrHospitalLastSixMonthsForMedicationProblems" [value]="true">
        <label class="form-check-label" for="hosipitalYes">Yes</label>
      </app-radio-highlight>
      <app-radio-highlight controlName="hosipitalNo"
        [hasError]="formControls.gpOrHospitalLastSixMonthsForMedicationProblems.invalid && (showInvalid$ | async)"
        [isHighlighted]="!consentEligibilityForm.value.gpOrHospitalLastSixMonthsForMedicationProblems">
        <input class="form-check-input" type="radio" name="gpOrHospitalLastSixMonthsForMedicationProblems"
          id="hosipitalNo" formControlName="gpOrHospitalLastSixMonthsForMedicationProblems" [value]="false" checked>
        <label class="form-check-label" for="hosipitalNo">No</label>
      </app-radio-highlight>
    </div>
  </div>
  <div class="form-group pb-1">
    <label for="dateOfPatientRegistration" class="input-label">Date of Patient Registration</label>
    <input type="text" [class.has-error]="formControls.dateOfPatientRegistration.invalid && (showInvalid$ | async)"
      class="form-control form-control-lg w-25" formControlName="dateOfPatientRegistration"
      id="dateOfPatientRegistration" [bsValue]="defaultDate | date: 'DD-MM-YYYY'"
      [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY', adaptivePosition: true }" placeholder="dd-mm-yyyy" bsDatepicker>
  </div>
</form>