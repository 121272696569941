<ng-container *ngIf="(addEditMedication$ | async) as addEditMedication">

  <div class="medication" [@slideRightAnimation]="addEditMedication.slideMode">
    <div class="medication__body">
      <div class="flex medication__title">
        <div style="cursor: pointer;" (click)="close()" g-xs="px3" class="flex align-items-center"><i
            class="fas fa-arrow-left font__title-3"></i></div>
        <h2 g-xs="px3 py2">Add New Medication</h2>
      </div>
      <div class="medication__content" g-xs="p3">
        <form [formGroup]="medicationForm" ngxsForm="addEditMedication.medicationForm" (ngSubmit)="addMedication()">
          <div class="medication__search flex align-items-end justify-content-between" g-xs="pb3">
            <div>
              <h4 g-xs="pb2">Brand Name</h4>
              <input formControlName="brandName" type="text" class="form-control" placeholder="Brand Name"
                (change)="resetDrug()" />
            </div>
            <div>
              <h4 g-xs="pb2">Generic Name</h4>
              <input formControlName="genericName" type="text" class="form-control" placeholder="Generic Name"
                (change)="resetDrug()" />
              <input type="hidden" formControlName="id" />
              <input type="hidden" formControlName="productDispensedId" />
            </div>
            <div>
              <button [disabled]="checkQuery(addEditMedication.loading)" type="button" class="btn__green"
                (click)="search(medicationForm.controls.brandName.value)">Search</button>
            </div>
          </div>
          <div class="medication__results" *ngIf="(searchList$ | async) as searchList">
            <div *ngIf="searchList.length === 0">No medication found</div>
            <table>
              <tr *ngFor="let item of searchList">
                <td>{{item.genericName}}</td>
                <td>{{item.brandName}}</td>
                <td><button type="button" class="btn__white select" (click)="selectMedication(item)">Select</button>
                </td>
              </tr>
            </table>
          </div>
          <div class="medication__details">
            <div class="mask" *ngIf="!medicationForm.controls.productDispensedId.value"></div>
            <div g-xs="pt3" class="row">
              <div class="col-md-4">
                <h4 g-xs="pb2">Prescriber</h4>
                <input type="text" class="form-control" formControlName="prescriber" />
              </div>
              <div class="col-md-5">
                <h4 g-xs="pb2">Indication &amp; reasons for taking</h4>
                <input type="text" class="form-control" formControlName="reasonsForTaking" />
              </div>
              <div class="col-md-3">
                <h4 g-xs="pb2">Start date</h4>
                <input type="text" class="form-control" formControlName="startDate" />
              </div>
            </div>
            <div g-xs="pt3">
              <h4 g-xs="pb2">Dosage instructions</h4>
              <input type="text" formControlName="directions" class="form-control" placeholder="Use as directed" />
            </div>
            <div g-xs="pt3">
              <h4 g-xs="pb2">Notes &amp; special instructions</h4>
              <input type="text" formControlName="notes" class="form-control" />
            </div>
            <button class="btn__green" type="submit" g-xs="mt3" [disabled]="medicationForm.invalid">Add
              Medication</button>
          </div>
        </form>
      </div>

    </div>

  </div>
</ng-container>
