import { State, Action, StateContext, Store } from '@ngxs/store';
import { OpenStockAdjustmentAction,
  CloseStockAdjustmentAction,
  StockAdjustmentErrorAction,
  StockAdjustmentRequestAction,
  StockAdjustmentResponseAction } from './stock-adjustment.actions';
import { ScriptService } from '../../../../../shared/services/script.service';
import { UpdateScriptActionsAction } from '../../state/script-actions.actions';
import { ScriptLoadingStartAction, ScriptLoadingStopAction, UpdateScriptAction } from '../../state/categories.actions';
import { mergeMap, catchError } from 'rxjs/operators';
import { ProfileState } from '../../../../core/profile/state/profile.state';
import { GetClientNotes } from '../../../../../modules/profile/notes/state/notes.actions';
import { Injectable } from '@angular/core';
import { AdjustmentRequestModel } from '../../../../../shared/models/script/adjustment-request.model';
import { getDohOrZero } from '../../../../../shared/helpers/doh.helper';

export enum StockAdjustmentType {
  ABSOLUTE,
  RELATIVE
}

export class StockAdjustmentStateModel {
  public show: boolean;
  public loading: boolean;
  public error: string;
}
@Injectable()
@State<StockAdjustmentStateModel>({
  name: 'stockAdjustment',
  defaults: {
    show: false,
    loading: false,
    error: null
  }
})
export class StockAdjustmentState {

  constructor(
    private scriptService: ScriptService,
    private store: Store
  ) { }

  @Action(OpenStockAdjustmentAction)
  open(ctx: StateContext<StockAdjustmentStateModel>) {
    ctx.patchState({ show: true });
  }

  @Action(CloseStockAdjustmentAction)
  close(ctx: StateContext<StockAdjustmentStateModel>) {
    ctx.patchState({ show: false });
  }

  @Action(StockAdjustmentRequestAction)
  request(ctx: StateContext<StockAdjustmentStateModel>, action: StockAdjustmentRequestAction) {
    ctx.patchState({ loading: true, error: '' });
    ctx.dispatch(new ScriptLoadingStartAction(action.script));

    const addAdjustmentRequest = this.buildRequest(action.script, action.days, action.isAbsolute, null);

    return this.scriptService.addAdjustment(addAdjustmentRequest).pipe(
      mergeMap((response) => ctx.dispatch(new StockAdjustmentResponseAction(response))),
      catchError((error) => ctx.dispatch(new StockAdjustmentErrorAction(error, action.script)))
    );
  }

  @Action(StockAdjustmentErrorAction)
  error(ctx: StateContext<StockAdjustmentStateModel>, action: StockAdjustmentErrorAction) {
    ctx.patchState({ error: action.error });
    ctx.dispatch(new ScriptLoadingStopAction(action.script));
  }

  @Action([StockAdjustmentErrorAction, StockAdjustmentResponseAction])
  stopLoading(ctx: StateContext<StockAdjustmentStateModel>) {
    ctx.patchState({ loading: false });
  }

  @Action([StockAdjustmentResponseAction])
  handleResponse(ctx: StateContext<StockAdjustmentStateModel>, action: StockAdjustmentResponseAction) {
    const clientId = this.store.selectSnapshot(ProfileState.clientId);
    ctx.dispatch([new UpdateScriptActionsAction(action.script), new GetClientNotes(clientId)]);
    return ctx.dispatch([new UpdateScriptAction(action.script)]);
  }

  private buildRequest(script: any, days: number, isAbsolute: boolean, reason: string): AdjustmentRequestModel {
    return {
      metaId: script.cMeta.id,
      isAbsolute: isAbsolute,
      adjustedDoses: days,
      reason: reason,
      currentDoh: getDohOrZero(script)
    };
  }
}
