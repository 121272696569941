<div>
  <h3>Category List</h3>
  <div class="text-right">
    <button class="btn btn-primary" routerLink="add">Add new</button>
  </div>

  <div class="table mt-4 margin-sm-top">
    <div class="col-sm-12">
      <table class="table">
        <tr>
          <th> Name </th>
          <th> Friendly Name </th>
          <th> Dispense Interval </th>
          <th> Target Days on Hand </th>
          <th> Position </th>
          <th colspan="2"> Actions </th>
        </tr>
        <tr *ngFor="let item of categories">
          <td>{{item.name}}</td>
          <td>{{item.friendlyName}}</td>
          <td>{{item.dispenseBuffer}} days</td>
          <td>{{item.targetTotalDoh}}</td>
          <td>{{item.position}}</td>
          <div *ngIf="item.tenantId != null" class="btn-group">
            <button routerLink="edit/{{item.id}}" class="btn btn-primary">Edit</button>
            <button (click)="deleteCategory(item.id)" class="btn btn-danger">Remove</button>
          </div>
          <div *ngIf="item.tenantId == null" class="btn-group">
            <td></td>
            <td></td>
          </div>
        </tr>
      </table>
    </div>
  </div>

  <div class="loading-spinner" *ngIf="!isLoaded">
    <section>
      <app-loader></app-loader>
    </section>
  </div>
  <app-fluid-modal *ngIf="showModal" (closeEvent)="modalClosed()" (submitEvent)="confirmed()" title="Please confirm"
    message="Are you sure you want to remove this category?">
  </app-fluid-modal>
</div>
