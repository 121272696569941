import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, ActivatedRouteSnapshot, Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { Validators } from 'powerbi-models';
import { Observable } from 'rxjs';
import { PharmacyService } from '~/shared/services/pharmacy.service';
import { CommunicationOptOut, OptOutType } from '../models/opt-out.model';
import { ConfirmOptOut, VerifyOptOutLink } from '../state/opt-out.actions';
import { OptOutState, OptOutStateModel } from '../state/opt-out.state';

@Component({
  selector: 'app-opt-out-page',
  templateUrl: './opt-out-page.component.html',
  styleUrls: ['./opt-out-page.component.scss']
})
export class OptOutPageComponent implements OnInit {
  @Select(OptOutState.optOutstate) optOutState$: Observable<OptOutStateModel>;
  optOutForm: FormGroup;
  optOutTypes = OptOutType;
  pharmacyName: string;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private store: Store,
    private pharmacyService: PharmacyService) { }

  ngOnInit(): void {
    this.verifyOptOutLink()
    this.initForm();
  }

  submitOptOutForm() {
    this.store.dispatch(new ConfirmOptOut(this.optOutForm.value as CommunicationOptOut));
  }

  private verifyOptOutLink() {
    const { pharmacy } = this.route.snapshot.queryParams;
    this.store.dispatch(new VerifyOptOutLink(pharmacy)).subscribe(() => this.patchForm());
  }

  private initForm() {

    this.optOutForm =  this.formBuilder.group({
      tenantId: new FormControl(),
      mobileNumber: new FormControl(),
      optOutType: new FormControl(OptOutType.campaigns, Validators.required),
      created: new FormControl(new Date()),
      optOutReason: new FormControl(),
    })
  }

  private patchForm() {
    const optOut = this.store.selectSnapshot(OptOutState.optOutRequest) as CommunicationOptOut;
    this.optOutForm.patchValue({
      tenantId: optOut.TenantId,
      mobileNumber: optOut.MobileNumber,
      optOutReason: optOut.OptOutReason,
    });
    return this.pharmacyService.getAnonymousPharmacyLocation(optOut.TenantId).subscribe((location) => {
      this.pharmacyName = location.name;
    });
  }
}
