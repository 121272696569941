import { Component, OnInit } from '@angular/core';
import { Select } from '@ngxs/store';
import { SickLeaveConsulationState } from '../sick-leave-consulation/state/sick-leave-consulation.state';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-sick-leave-consulation-list-item',
  templateUrl: './sick-leave-consulation-list-item.component.html'
})
export class SickLeaveConsulationListItemComponent implements OnInit {
  @Select(SickLeaveConsulationState) sickLeaveConsulationForm$: Observable<any>;

  constructor() { }

  ngOnInit() {
  }

}
