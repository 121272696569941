<div class="communications-page" *ngIf="(messageBoxState$ | async) as messageBoxState">

  <reply-modal [messageType]="tab"></reply-modal>

  <app-bulk-messaging-recipients-modal
  [showRecipients]="messageBoxState.showBulkCommRecipientsModal"
  [bulkMessage]="messageBoxState.bulkMessage"
  [isLoading]="messageBoxState.bulkCommRecipientsIsLoading"
  [isHistoric]="false" (close)="closeRecipientsModal()">
  </app-bulk-messaging-recipients-modal>

  <div class="d-flex justify-content-between align-items-center background-grey" g-xs="pt4 pl3 pr3 pb2 valign:middle">
    <div class="comms-header d-flex justify-content-between align-items-center">
      <h1 g-xs="m0 valign:middle" class="blue-header">Communications</h1>
      <app-sent-messages-status-bar></app-sent-messages-status-bar>
    </div>

    <div class="comms-links d-flex justify-content-between align-items-center">
      <span *ngIf="(dedicatedPhoneNumber$ | async) as phoneNumber" g-xs="mr2">Dedicated Number: {{phoneNumber | formatPhoneNumber}}</span>
      <a class="link" routerLink="/bulk-messaging" g-xs="mr2">Bulk Communications</a>
      <a class="link" routerLink="/config/communication"><i class="fal fa-cog"></i>&nbsp;Settings</a>
    </div>
  </div>

  <!-- There is no reason for the entire table to be inside this form -->
  <form [formGroup]="searchForm">
    <div class="communications-bar d-flex justify-content-between align-items-stretch">
      <div class="tabs row d-flex align-items-stretch">

        <!--Tabs-->
        <button type="button" [ngClass]="inboxTabClass(mailTabs.Inbox)" (click)="openTab(mailTabs.Inbox)">
          <nz-badge *ngIf="(unread$ | async) as inboxCount; else noUnread" [nzCount]="inboxCount" [nzOverflowCount]="99" [nzClass]="inbox-badge">
            <div class="tabname">Inbox</div>
          </nz-badge>
          <ng-template #noUnread>
            <div class="tabname">Inbox</div>
          </ng-template>
        </button>
        <button type="button" [ngClass]="inboxTabClass(mailTabs.Scheduled)" (click)="openTab(mailTabs.Scheduled)">
          <div class="tabname">Scheduled</div>
        </button>
        <button type="button" [ngClass]="inboxTabClass(mailTabs.Sent)" (click)="openTab(mailTabs.Sent)">
          <div class="tabname">Sent</div>
        </button>

        <!--Search keywords-->
        <div class="d-flex justify-content-between align-items-center vertical-stretch communications-bar-input">
          <nz-input-group [nzSuffix]="suffixIconSearch" class="vertical-stretch">
            <input type="text" (keyup.enter)="search()" formControlName="keywords" nz-input placeholder="Keyword Search" class="vertical-stretch"/>
          </nz-input-group>
          <ng-template #suffixIconSearch>
            <i (click)="clearKeywordSearch()" class="fal fa-times cursor-pointer clear-keyword"></i>
            &nbsp;&nbsp;
            <i (click)="search()" class="far fa-search cursor-pointer keyword-search"></i>
          </ng-template>
        </div>

        <button type="button" class="filter-btn filters-color" (click)="toggleFilters()">
          <nz-badge [nzStyle]="{ backgroundColor: '#118AB2' }" [nzCount]="filterCount" [nzOverflowCount]="99">
            <i class="fal fa-filter filters-color"></i>
          </nz-badge>
          &nbsp;&nbsp;Filters
        </button>

        <div class="d-flex p-3 justify-content-between align-items-center">
          <div class="cursor-pointer" (click)="filterList()">
            <i class="far fa-redo-alt"></i>&nbsp;<span class="small refresh-text">{{timeSinceLastRefresh}}</span>
          </div>
        </div>
      </div>

      <div class="row pagination d-flex align-items-stretch">
        <div class="pages d-flex justify-content-between align-items-center">
          <span *ngIf="tab === mailTabs.Inbox"
            class="small">{{((currentPage) * messages) + 1}}-{{getDisplayedToRowValue(messageBoxState.inboxState?.total ?? 0)}} of {{messageBoxState.inboxState?.total ?? 0}}</span>
          <span *ngIf="tab === mailTabs.Scheduled"
            class="small">{{((currentPage) * messages) + 1}}-{{getDisplayedToRowValue(messageBoxState.filteredScheduledMessages?.length ?? 0)}}
            of {{messageBoxState.filteredScheduledMessages?.length ?? 0}}</span>
          <span *ngIf="tab === mailTabs.Sent"
            class="small">{{((currentPage) * messages) + 1}}-{{getDisplayedToRowValue(messageBoxState.sentState?.total ?? 0)}}
            of {{messageBoxState.sentState?.total ?? 0}}</span>
          <button type="button" (click)="showPrevPage()">
            <i class="fal fa-chevron-left"></i>
          </button>
          <button type="button" (click)="showNextPage()">
            <i class="fal fa-chevron-right"></i>
          </button>
        </div>

        <div class="filter-dropdowns">
          <nz-select (ngModelChange)="onMessagesDisplayedChanged()" formControlName="messages" [nzSuffixIcon]="icon">
            <ng-container *ngFor="let msgs of messagesPerPage">
              <nz-option [nzValue]="msgs" [nzLabel]="msgs + ' messages'"> </nz-option>
            </ng-container>
          </nz-select>
        </div>
        <div class="filter-dropdowns">
          <nz-select (ngModelChange)="onSortOrderChanged()" formControlName="sortOrder" [nzSuffixIcon]="icon">
            <ng-container *ngFor="let order of sortOrders">
              <nz-option [nzValue]="order.value" [nzLabel]="order.name"> </nz-option>
            </ng-container>
          </nz-select>
        </div>
      </div>
    </div>
    <ng-template #icon><i class="fal fa-chevron-down dropdown-arrow"></i></ng-template>

    <!-- Filters -->
    <div [ngClass]="{ 'filters-visible': showFilters, 'filters-hidden': !showFilters }" class="filter-bar d-flex justify-content-between align-items-center">
      <div nz-row nzJustify="space-around" nzAlign="middle" class="row filters p-2">
        <div nz-col class="heading">
          Filters
        </div>
        <div nz-col>
          <button type="button" nz-button nz-dropdown nzTrigger="click" [nzDropdownMenu]="channelDropdownMenu">
            {{channelsBtnText()}} &nbsp;
            <i class="fal fa-chevron-down"></i>
          </button>
          <nz-dropdown-menu #channelDropdownMenu="nzDropdownMenu">
            <ul nz-menu *ngFor="let channel of channels">
              <li nz-menu-item>
                <label nz-checkbox [formControlName]="channel.value" [value]="channel.value">{{channel.name}}</label>
              </li>
            </ul>
          </nz-dropdown-menu>
        </div>

        <div nz-col *ngIf="messageTypesProvidedForTab()">
          <button type="button" nz-button nz-dropdown nzTrigger="click" [nzDropdownMenu]="messageTypeDropdownMenu">
            {{messageTypesBtnText()}} &nbsp;
            <i class="fal fa-chevron-down"></i>
          </button>
          <nz-dropdown-menu #messageTypeDropdownMenu="nzDropdownMenu">
            <ng-container *ngFor="let messageType of messageTypes">
              <ul nz-menu *ngIf="controlVisible(messageType.visible)">
                <li nz-menu-item><label nz-checkbox [formControlName]="messageType.value"
                    [value]="messageType.value">{{messageType.name}}</label></li>
              </ul>
            </ng-container>
          </nz-dropdown-menu>
        </div>

        <div nz-col>
          <button type="button" nz-button nz-dropdown nzTrigger="click" [nzDropdownMenu]="patientGroupsDropdownMenu">
            {{patientGroupsBtnText()}} &nbsp;
            <i class="fal fa-chevron-down"></i>
          </button>
          <nz-dropdown-menu #patientGroupsDropdownMenu="nzDropdownMenu">
            <ul nz-menu *ngFor="let patientGroup of patientGroups">
              <li nz-menu-item><label nz-checkbox [formControlName]="patientGroup.value"
                  [value]="patientGroup.value">{{patientGroup.name}}</label></li>
            </ul>
          </nz-dropdown-menu>
        </div>

        <div nz-col>
          <button type="button" nz-button nz-dropdown nzTrigger="click" [nzDropdownMenu]="calenderDropdownMenu">
            <div class="calender-btn-text">
              <div class="calender-items">
                <i class="fal fa-calendar-alt"></i>
                <ng-container *ngIf="!searchForm.controls.startdate.value && !searchForm.controls.enddate.value">
                  All time
                </ng-container>
                <div class="calender-time-text">
                  <small *ngIf="searchForm.controls.startdate.value">from {{searchForm.controls.startdate.value | tenantDate:'DD/MM/YYYY'}}</small>
                  <small *ngIf="searchForm.controls.enddate.value">to {{searchForm.controls.enddate.value | tenantDate:'DD/MM/YYYY'}}</small>
                </div>
              </div>
            </div>
          </button>

          <nz-dropdown-menu #calenderDropdownMenu="nzDropdownMenu">
            <ul nz-menu>
              <li nz-menu-item>
                <nz-date-picker nzFormat="dd/MM/YYYY" formControlName="startdate" nzPlaceHolder="Start date"></nz-date-picker>
              </li>
            </ul>
            <ul nz-menu>
              <li nz-menu-item>
                <nz-date-picker nzFormat="dd/MM/YYYY" formControlName="enddate" nzPlaceHolder="End date"></nz-date-picker>
              </li>
            </ul>
            <ul nz-menu>
              <li nz-menu-item>
                <button type="button" nz-button (click)="resetDates()">
                  Clear
                </button>
              </li>
            </ul>
          </nz-dropdown-menu>
        </div>

        <div class="toggle-filters">
          <!-- Default visible toggle filter list -->
          <ng-container *ngFor="let btn of visibleControls(quickModifiers).slice(0, defaultVisibleFilters)">
            <button type="button" nz-button (click)="onFilterButtonClicked(btn.value)"
              [ngClass]="{'btn-checked': searchForm.controls[btn.value].value}">
              {{btn.name}}
            </button>
            <input type="checkbox" [formControlName]="btn.value" class="hidden" />
          </ng-container>

          <!-- Additional filters hidden in dropdown menu -->
          <button type="button" *ngIf="visibleControls(quickModifiers).slice(defaultVisibleFilters).length > 0" nz-button nz-dropdown
            nzTrigger="click" [nzDropdownMenu]="additionalFiltersDropdown">
            More filters &nbsp;
            <i class="fal fa-chevron-down"></i>
          </button>
          <nz-dropdown-menu #additionalFiltersDropdown="nzDropdownMenu">
            <ul class="additional-filter" nz-menu *ngFor="let btn of visibleControls(quickModifiers).slice(defaultVisibleFilters)">
              <li nz-menu-item>
                <button type="button" nz-button (click)="onFilterButtonClicked(btn.value)" [ngClass]="{'btn-checked': searchForm.controls[btn.value].value, 'btn-dropdown': !searchForm.controls[btn.value].value}">
                  {{btn.name}}
                </button>
                <input type="checkbox" [formControlName]="btn.value" class="hidden"/>
              </li>
            </ul>
          </nz-dropdown-menu>
        </div>
        <div class="filter-commands d-flex justify-content-between align-items-center">
          <button type="button" class="button btn__yellow" (click)="filterList()">Apply filters</button>
          <div class="link" (click)="resetFilters()">Clear all</div>
        </div>
      </div>

      <div class="row filter-commands d-flex justify-content-between align-items-center">
        <div><i (click)="toggleFilters()" class="fal fa-times"></i></div>
      </div>
    </div>

    <app-inbox-table *ngIf="isTabVisible(mailTabs.Inbox) && !messageBoxState.isLoading"
      [messages]="messageBoxState?.inboxState?.items" [displayedFromRow]="displayedFromRow"
      [displayedToRow]="displayedToRow" [messageType]="mailTabs.Inbox">
    </app-inbox-table>

    <div *ngIf="messageBoxState?.filteredScheduledMessages">
      <app-inbox-table *ngIf="isTabVisible(mailTabs.Scheduled) && !messageBoxState.isLoading"
        [messages]="messageBoxState?.filteredScheduledMessages" [displayedFromRow]="displayedFromRow"
        [displayedToRow]="displayedToRow" [messageType]="mailTabs.Scheduled">
      </app-inbox-table>
    </div>

    <app-inbox-table *ngIf="isTabVisible(mailTabs.Sent) && !messageBoxState.isLoading"
      [messages]="messageBoxState?.sentState?.items" [displayedFromRow]="displayedFromRow"
      [displayedToRow]="displayedToRow" [messageType]="mailTabs.Sent">
    </app-inbox-table>

    <ng-container *ngIf="isTabVisible(mailTabs.Inbox) && !messageBoxState.isLoading">
      <div *ngIf="(selectedInboxMessages$ | async) as inboxMessages"
      class="hidden-banner"
      [ngClass]="{ 'selected-banner': inboxMessages?.length ?? 0 === 0 }">
        <div class="d-flex justify-content-between select-button-spacing">
          <span class="sub-title">{{inboxMessages?.length ?? 0}} selected</span>
          <button type="button" class="btn__link" (click)="selectAllDisplayedRows()">select all</button>
          <button type="button" class="btn__link" (click)="deselectAllDisplayedRows()">deselect all</button>
        </div>

        <div class="d-flex justify-content-between select-button-spacing">
          <div *ngFor="let selectedInboxRead of (selectedInboxAreRead$ | async)" class="d-flex justify-content-between">
            <button *ngIf="!selectedInboxRead" type="button" class="btn__link" (click)="markSelectedAsRead()"><i class="fal fa-envelope"></i>&nbsp;Mark as read</button>
            <button *ngIf="selectedInboxRead" type="button" class="btn__link" (click)="markSelectedAsUnread()"><i class="fal fa-envelope"></i>&nbsp;Mark as unread</button>
          </div>
          <div *ngFor="let selectedInboxFlagged of (selectedInboxAreFlagged$ | async)" class="d-flex justify-content-between">
            <button *ngIf="!selectedInboxFlagged" type="button" class="btn__link" (click)="markSelectedAsFlagged()"><i class="fal fa-flag"></i>&nbsp;Flag</button>
            <button *ngIf="selectedInboxFlagged" type="button" class="btn__link" (click)="markSelectedAsNotFlagged()"><i class="fal fa-flag"></i>&nbsp;Remove flag</button>
          </div>
          <button type="button" class="btn__link" (click)="markSelectedArchived()"><i class="fal fa-trash"></i>&nbsp;Archive</button>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="isTabVisible(mailTabs.Scheduled) && !messageBoxState.isLoading">
      <div *ngIf="(selectedScheduledMessages$ | async) as selectedMessages"
      class="hidden-banner"
      [ngClass]="{ 'selected-banner': selectedMessages?.length ?? 0 !== 0 }">
        <div class="d-flex justify-content-between select-button-spacing">
          <span class="sub-title">{{selectedMessages?.length ?? 0}} selected</span>
          <button type="button" class="btn__link" (click)="selectAllDisplayedRows()">select all</button>
          <button type="button" class="btn__link" (click)="deselectAllDisplayedRows()">deselect all</button>
        </div>

        <div class="d-flex justify-content-between select-button-spacing">
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="isTabVisible(mailTabs.Sent) && !messageBoxState.isLoading">
      <div *ngIf="(selectedSentMessages$ | async) as sentMessages"
      class="hidden-banner"
      [ngClass]="{ 'selected-banner': sentMessages?.length ?? 0 !== 0 }">
        <div class="d-flex justify-content-between select-button-spacing">
          <span class="sub-title">{{sentMessages?.length ?? 0}} selected</span>
          <button type="button" class="btn__link" (click)="selectAllDisplayedRows()">select all</button>
          <button type="button" class="btn__link" (click)="deselectAllDisplayedRows()">deselect all</button>
        </div>

        <div class="d-flex justify-content-between select-button-spacing">
        </div>
      </div>
    </ng-container>

    <div class="page-navigation" *ngIf="!messageBoxState.isLoading">
      <ng-container *ngIf="isTabVisible(mailTabs.Inbox)">
        <button type="button" *ngIf="getPageCount(messageBoxState.inboxState?.total ?? 0).length > 0" class="navigation-button navigation-back-button" (click)="showPrevPage()"><i class="fas fa-angle-left"></i></button>
        <span *ngIf="navigationFromPage > 0">
          <button type="button" class="navigation-button" (click)="goToPage(0)">1</button>
          <span><i class="far fa-ellipsis-h"></i></span>
        </span>
        <span *ngFor="let pageNumber of getPageCount(messageBoxState.inboxState?.total ?? 0) | slice:navigationFromPage:navigationToPage; index as i">
          <button type="button" [ngClass]="{'selected-button': pageNumber === currentPage, 'navigation-button' : pageNumber !== currentPage }"
            (click)="goToPage(pageNumber)">
            {{pageNumber+1}}
          </button>
        </span>
        <span *ngIf="navigationToPage < getPageCount(messageBoxState.inboxState?.total ?? 0).length">
          <span><i class="far fa-ellipsis-h"></i></span>
          <button type="button" class="navigation-button" (click)="goToPage(getPageCount(messageBoxState.inboxState?.total ?? 0).length-1)">{{getPageCount(messageBoxState.inboxState?.total ?? 0).length}}</button>
        </span>
        <button type="button" *ngIf="getPageCount(messageBoxState.inboxState?.total ?? 0).length > 0" class="navigation-button navigation-forward-button" (click)="showNextPage()"><i class="fas fa-angle-right"></i></button>
      </ng-container>

      <ng-container *ngIf="isTabVisible(mailTabs.Scheduled)">
        <button type="button" *ngIf="getPageCount(messageBoxState.filteredScheduledMessages?.length ?? 0).length > 0" class="navigation-button navigation-back-button" (click)="showPrevPage()"><i class="fas fa-angle-left"></i></button>
        <span *ngIf="navigationFromPage > 0">
          <button type="button" class="navigation-button" (click)="goToPage(0)">1</button>
          <span><i class="far fa-ellipsis-h"></i></span>
        </span>
        <span *ngFor="let pageNumber of getPageCount(messageBoxState.filteredScheduledMessages?.length ?? 0) | slice:navigationFromPage:navigationToPage; index as i">
          <button type="button" [ngClass]="{'selected-button': pageNumber === currentPage, 'navigation-button' : pageNumber !== currentPage }"
            (click)="goToPage(pageNumber)">
            {{pageNumber+1}}
          </button>
        </span>
        <span *ngIf="navigationToPage < getPageCount(messageBoxState.filteredScheduledMessages?.length ?? 0).length">
          <span><i class="far fa-ellipsis-h"></i></span>
          <button type="button" class="navigation-button" (click)="goToPage(getPageCount(messageBoxState.filteredScheduledMessages?.length ?? 0).length-1)">{{getPageCount(messageBoxState.filteredScheduledMessages?.length ?? 0).length}}</button>
        </span>
        <button type="button" *ngIf="getPageCount(messageBoxState.filteredScheduledMessages?.length ?? 0).length > 0" class="navigation-button navigation-forward-button" (click)="showNextPage()"><i class="fas fa-angle-right"></i></button>
      </ng-container>

      <ng-container *ngIf="isTabVisible(mailTabs.Sent)">
        <button type="button" *ngIf="getPageCount(messageBoxState.sentState?.total ?? 0).length > 0" class="navigation-button navigation-back-button" (click)="showPrevPage()"><i class="fas fa-angle-left"></i></button>
        <span *ngIf="navigationFromPage > 0">
          <button type="button" class="navigation-button" (click)="goToPage(0)">1</button>
          <span><i class="far fa-ellipsis-h"></i></span>
        </span>
        <span *ngFor="let pageNumber of getPageCount(messageBoxState.sentState?.total ?? 0) | slice:navigationFromPage:navigationToPage; index as i">
          <button type="button" [ngClass]="{'selected-button': pageNumber === currentPage, 'navigation-button' : pageNumber !== currentPage }"
            (click)="goToPage(pageNumber)">
            {{pageNumber+1}}
          </button>
        </span>
        <span *ngIf="navigationToPage < getPageCount(messageBoxState.sentState?.total ?? 0).length">
          <span><i class="far fa-ellipsis-h"></i></span>
          <button type="button" class="navigation-button" (click)="goToPage(getPageCount(messageBoxState.sentState?.total ?? 0).length-1)">{{getPageCount(messageBoxState.sentState?.total ?? 0).length}}</button>
        </span>
        <button type="button" *ngIf="getPageCount(messageBoxState.sentState?.total ?? 0).length > 0" class="navigation-button navigation-forward-button" (click)="showNextPage()"><i class="fas fa-angle-right"></i></button>
      </ng-container>
    </div>

    <div class="loading__container" *ngIf="messageBoxState.isLoading">
      <app-loader class="loading" [loadingMessage]="isTabVisible(mailTabs.Scheduled) ? 'Evaluating patients for Scrypt Reminders': ''"></app-loader>
    </div>
  </form>
</div>
