<ng-container *ngFor="let medsCheckType of [medsCheckType$ | async];">
  <div [formGroup]="medsCheckFormList">
    <app-radio-highlight controlName="patientRegistration"
      [isHighlighted]="medsCheckType === medsCheckTypes.PatientRegistration" [fullWidth]="true" size="lg">
      <input class="form-check-input" type="radio" name="medsCheckType" id="patientRegistration"
        formControlName="medsCheckType" [value]="medsCheckTypes.PatientRegistration"
        [checked]="medsCheckType == medsCheckTypes.PatientRegistration">
      <label class="form-check-label" for="patientRegistration">Patient Registration</label>
    </app-radio-highlight>
    <app-radio-highlight controlName="regular" size="lg" [isHighlighted]="medsCheckType === medsCheckTypes.Regular"
      [fullWidth]="true">
      <input class="form-check-input" type="radio" name="medsCheckType" id="regular" formControlName="medsCheckType"
        [value]="medsCheckTypes.Regular" [checked]="medsCheckType == medsCheckTypes.Regular">
      <label class="form-check-label" for="regular">Regular Meds Check</label>
    </app-radio-highlight>
    <app-radio-highlight controlName="diabetes" size="lg" [isHighlighted]="medsCheckType === medsCheckTypes.Diabetes"
      [fullWidth]="true">
      <input class="form-check-input" type="radio" name="medsCheckType" id="diabetes" formControlName="medsCheckType"
        [value]="medsCheckTypes.Diabetes" [checked]="medsCheckType == medsCheckTypes.Diabetes">
      <label class="form-check-label" for="diabetes">Diabetes Meds Check</label>
    </app-radio-highlight>
  </div>
  <ng-container [ngSwitch]="medsCheckType" *ngFor="let patientHasDiabetes of [patientHasDiabetes$ | async]">
    <p *ngSwitchCase="medsCheckTypes.PatientRegistration">It’s not currently possible for MedsCheck Patient
      Registrations to be automatically claimed via the <a (click)="openPPAPortal()">PPA portal.</a>
      Completing this form will export the Medscheck Registration so it can be manually copied to the PPA portal.</p>
    <p *ngSwitchCase="medsCheckTypes.Regular">Submitting this form will send a Standard Medscheck Claim to the PPA</p>
    <p *ngSwitchCase="medsCheckTypes.Diabetes">Submitting this form will send a Diabetes Medscheck Claim to the PPA</p>
  </ng-container>
  <app-patient-form-list-item></app-patient-form-list-item>
  <app-consent-eligibility-form-list-item></app-consent-eligibility-form-list-item>
  <app-medication-information-form-list-item *ngIf="medsCheckType === medsCheckTypes.PatientRegistration">
  </app-medication-information-form-list-item>
  <app-outcomes-and-actions-form-list-item *ngIf="medsCheckType === medsCheckTypes.PatientRegistration">
  </app-outcomes-and-actions-form-list-item>
  <app-pharmacy-info-form-list-item></app-pharmacy-info-form-list-item>
  <app-meds-check-medication-profile></app-meds-check-medication-profile>
  <app-action-plan-form-list-item></app-action-plan-form-list-item>
  <app-patient-signature-form-list-item *ngIf="medsCheckType === medsCheckTypes.PatientRegistration">
  </app-patient-signature-form-list-item>


  <ng-container *ngIf="(sixCpaState$ | async) as sixCpaState">

    <div class="pt-4" *ngFor="let saving of [saving$ | async]">
      <ng-container *ngIf="!sixCpaState.readOnly">
        <app-loader class="loading" *ngIf="saving"></app-loader>
        <button *ngIf="medsCheckType !== medsCheckTypes.PatientRegistration" class="button submit w-25 mr-3"
          (click)="confirmMedsCheck()" [disabled]="saving">Submit</button>
        <button *ngIf="medsCheckType === medsCheckTypes.PatientRegistration" class="button submit mr-3"
          (click)="submitPatientRegistration()" [disabled]="saving">Complete Patient Registration</button>
      </ng-container>
      <button *ngIf="!sixCpaState.readOnly" class="button save mr-3" (click)="saveMedsCheck()" [disabled]="saving">Save
        Draft</button>
      <ng-container *ngIf="(medicineCount$ | async) as count">
        <button class="button save mr-3" (click)="previewServiceDocument()" [disabled]="saving || count == 0">Preview Meds List</button>
      </ng-container>
      <button *ngIf="sixCpaState.readOnly" class="button save mr-3" (click)="openPdfModal()" [disabled]="saving">Save
        Meds Check</button>
      <span *ngIf="!sixCpaState.readOnly" (click)="openConfirmResetModal()">Reset Form</span>

      <ng-container *ngIf="sixCpaState.readOnly">
        <button class="button submit mr-3" [disabled]="saving" (click)="viewConfirmation()">View Report</button>
      </ng-container>
    </div>

    <app-confirm-reset *ngIf="sixCpaState.showResetModal" (closeEvent)="closeConfirmResetModal()"
      (submitEvent)="resetMedsCheck()">
    </app-confirm-reset>
  </ng-container>


</ng-container>
