import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import { BaseApi } from '../shared/core/base-api/base-api';
import { Auth } from '../shared/models/auth.model';
import { AuthUser } from '../shared/models/authUser.model';
import { Router } from '@angular/router';
import { StorageService } from '../shared/core/storage/storage.service';
import { AlertService } from '../modules/core/alert/alert.service';

@Injectable()
export class AuthService extends BaseApi {
  private API:string;
  constructor(@Inject('environment') env:any, public http: HttpClient, public router: Router, public storageService: StorageService, public alertService: AlertService) {
    super(http, router, storageService, alertService);
    this.API = env.baseApiUrl;
  }

  deleteUser(customerId): Observable<string> {
    return this.delete(`${this.API}/api/delete_account/${customerId}`);
  }


  deleteInvites(customerId): Observable<string> {
    return this.delete(`${this.API}/api/delete__mobile_invites/${customerId}`);
  }

  changePassword(user: AuthUser): Observable<string> {
    return this.post(`${this.API}/api/password_reset`, user);
  }

  login(login: AuthUser): Observable<Auth> {
    return this.post(`${this.API}/api/Login`, login)
      .map((user: Auth) => {
        return user;
      });
  }

  logout() {
    this.storageService.clearStorage();

  }

  async isLoggedIn(): Promise<boolean> {
    const res = await this.storageService.getItem('scrypt_access_token');
    return res != null;
  }
}
