<ng-container *ngIf="(scryptSMSState$ | async) as scryptSMSState">
  <ng-container *ngIf="scryptSMSState.showModal">
    <div class="modal" style="top: 40px;">
      <div class="modal-body border__grey">
        <div class="container">
          <div class="float-right pointer">
            <i class="far fa-times-circle" (click)="closeModal()"></i>
          </div><br>
          <h3>Send Script Repeat</h3>
          <form [formGroup]="repeatForm">
            <div class="form-row">
              <div class="form-group">
                <label class="input-label" g-xs="mt2 mb1">Templates</label>
                <select class="form-control pre-selected-messages color__grey-text" g-xs="py2"
                  id="pre-selected-messages" formControlName="template" name="template"
                  (change)="onTemplateChange()">
                  <option value="0" selected="selected">
                    Use a pre-selected message to send to your clients
                  </option>
                  <option *ngFor="let template of scryptSMSState.templates" [value]="template.id">
                    {{template.key}}
                  </option>
                </select>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-md-11">
                <label class="input-label" g-xs="mt2 mb1">Message</label>
                <textarea name="message"
                    formControlName="message"
                    type="text"
                    class="form-control border__grey-light"
                    id="message"
                    rows="4">
                </textarea>
              </div>
            </div>
            <div class="form-row">
                <div class="form-group col-md-3">
                    <button class="btn btn__green" (click)="sendRepeat()" [disabled]="isLoading$ | async">
                      <app-loader *ngIf="isLoading$ | async"></app-loader>{{ (isLoading$ | async) ? '' : 'Send Repeat' }}
                    </button>
                </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="modal-background"></div>
  </ng-container>
</ng-container>
