import { Inject, Injectable } from '@angular/core';
import { BaseApiNoAlerts } from '../../../../shared/core/base-api/base-api-no-alerts';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { StorageService } from '../../../../shared/core/storage/storage.service';
import { AlertService } from '../../../../modules/core/alert/alert.service';
import { CommTemplate } from '../../../../shared/models/pharmacy/comm-template.model';
import { Observable } from 'rxjs';

@Injectable()
export class CommTemplatesService extends BaseApiNoAlerts {
  API = '';

  constructor(
    @Inject('environment') env: any,
    public http: HttpClient,
    public router: Router,
    public storageService: StorageService,
    public alertService: AlertService
  ) {
    super(http, router, storageService, alertService);
    this.API = env.baseApiUrl;
  }

  getComms(): Observable<CommTemplate[]> {
    return this.get<CommTemplate[]>(`${this.API}/api/tenant/comm-template`);
  }

  create(model: CommTemplate) {
    return this.post(`${this.API}/api/tenant/comm-template`, model);
  }

  update(model: CommTemplate) {
    return this.put(`${this.API}/api/tenant/comm-template`, model);
  }

  activate(id: number) {
    return this.patch(`${this.API}/api/tenant/comm-template`, {
      id,
      isActive: true,
    });
  }

  deactivate(id: number) {
    return this.patch(`${this.API}/api/tenant/comm-template`, {
      id,
      isActive: false,
    });
  }
}
