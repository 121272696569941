import { SimpleSignUp } from "~/shared/models/simple-sign-up/simple-sign-up.model";

export enum SignUpActions {
  GET_CUSTOMER_FOR_SIGNUP = '[SignUp] get customer for signup',
  GET_CUSTOMER_FOR_SIGNUP_SUCCESS = '[SignUp] get customer for signup success',
  SIGNUP_ERROR = '[SignUp] error',
  CREATE_ACCOUNT = '[SignUp] create account',
  CREATE_SUCCESS = '[SignUp] create account SUCCESS',
  CREATE_ERROR = '[SignUp] create account ERROR'
}
export class GetCustomerSignUp {
  static readonly type = SignUpActions.GET_CUSTOMER_FOR_SIGNUP;
  constructor(public linkId: string) { }
}


export class GetCustomerSignUpSuccess {
  static readonly type = SignUpActions.GET_CUSTOMER_FOR_SIGNUP_SUCCESS;
  constructor(public customer: any) { }
}

export class SignUpError {
  static readonly type = SignUpActions.SIGNUP_ERROR;
  constructor(public error: any) { }
}

export class CreateAccount {
  static readonly type = SignUpActions.CREATE_ACCOUNT;
  constructor(public createAccountRequest: SimpleSignUp) { }
}

export class CreateAccountSuccess {
  static readonly type = SignUpActions.CREATE_SUCCESS;
  constructor() { }
}

export class CreateAccountError {
  static readonly type = SignUpActions.CREATE_ERROR;
  constructor(public error: any) { }
}
