import { PharmacyCommsDetails } from '../../../../shared/models/pharmacy/pharmacy-comms-details.model';
import { PharmacyLocation } from '../../../../shared/models/pharmacy/pharmacy-address.model';

export enum PharmacyCommsDetailsActions {
  GET = '[PharmacyCommsDetails] get',
  GET_SUCCESS = '[PharmacyCommsDetails] get success',
  GET_LOCATION = '[PharmacyCommsDetails] get pharmacy location',
  GET_LOCATION_SUCCESS = '[PharmacyCommsDetails] get pharmacy location success',
  CREATE = '[PharmacyCommsDetails] create',
  CREATE_SUCCESS = '[PharmacyCommsDetails] create success',
  UPDATE = '[PharmacyCommsDetails] update',
  UPDATE_SUCCESS = '[PharmacyCommsDetails] update success',
  FAILED = '[PharmacyCommsDetails] failed',
  SUBMIT = '[PharmacyCommsDetails] submit'
}
export class GetPharmacyLocationAction {
  static readonly type = PharmacyCommsDetailsActions.GET_LOCATION;
}

export class GetPharmacyLocationSuccessAction {
  static readonly type = PharmacyCommsDetailsActions.GET_LOCATION_SUCCESS;
  constructor(public pharmacyLocation: PharmacyLocation) { }
}

export class GetPharmacyCommsDetailsAction {
  static readonly type = PharmacyCommsDetailsActions.GET;
}

export class GetPharmacyCommsDetailsSuccessAction {
  static readonly type = PharmacyCommsDetailsActions.GET_SUCCESS;
  constructor(public pharmacyCommsDetails: PharmacyCommsDetails) { }
}

export class PharmacyCommsDetailsFailedAction {
  static readonly type = PharmacyCommsDetailsActions.FAILED;
  constructor(public error: any) { }
}

export class CreatePharmacyCommsDetailsAction {
  static readonly type = PharmacyCommsDetailsActions.CREATE;
  constructor(public pharmacyCommsDetails: PharmacyCommsDetails) { }
}

export class CreatePharmacyCommsDetailsSuccessAction {
  static readonly type = PharmacyCommsDetailsActions.CREATE_SUCCESS;
  constructor(public pharmacyCommsDetails: PharmacyCommsDetails) { }
}
export class UpdatePharmacyCommsDetailsAction {
  static readonly type = PharmacyCommsDetailsActions.UPDATE;
  constructor(public pharmacyCommsDetails: PharmacyCommsDetails) { }
}

export class UpdatePharmacyCommsDetailsSuccessAction {
  static readonly type = PharmacyCommsDetailsActions.UPDATE_SUCCESS;
  constructor(public pharmacyCommsDetails: PharmacyCommsDetails) { }
}

export class SubmitPharmacyCommsDetailsAction {
  static readonly type = PharmacyCommsDetailsActions.SUBMIT;
}
