import { AdditionalDetailsFormModel } from '../../../../../shared/models/six-cpa/additional-details-form.model';

export enum AdditionalDetailsActions {
  SET_PROGRESS = '[AdditionalDetails] update progress',
  UPDATE = '[AdditionalDetails] Update Form'
}

export class SetAdditionalDetailsProgress {
  static readonly type = AdditionalDetailsActions.SET_PROGRESS;
  constructor(public totalItems, public completedItems) { }
}

export class UpdateAdditionalDetailsForm {
  static readonly type = AdditionalDetailsActions.UPDATE;
  constructor(public form: AdditionalDetailsFormModel) { }
}
