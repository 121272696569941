import { Component, OnInit, OnDestroy } from '@angular/core';
import { SickLeaveChildForm } from '../sick-leave-child-form';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Subscription, Observable } from 'rxjs';
import { Store, Actions, Select } from '@ngxs/store';
import { SickLeavePharmacistDeclarationState } from './state/sick-leave-pharmacist-declaration.state';
import { SetTotalItemsAndCompletedItems } from './state/sick-leave-pharmacist-declaration.actions';
import { PharmacistState } from '../../../../pharmacy/state/pharmacist.state';
import { Pharmacist } from '../../../../../shared/models/pharmacy/pharmacist.model';

import { SixCpaState } from '../../state/six-cpa.state';
@Component({
  selector: 'app-sick-leave-pharmacist-declaration',
  templateUrl: './sick-leave-pharmacist-declaration.component.html',
  styleUrls: ['./sick-leave-pharmacist-declaration.component.scss']
})
export class SickLeavePharmacistDeclarationComponent extends SickLeaveChildForm implements OnInit, OnDestroy {
  @Select(PharmacistState.pharmacists) pharmacists$: Observable<any>;
  sickLeavePharmacistDeclarationForm: FormGroup;
  formSubscription: Subscription;
  private readonlySubscription: Subscription;
  
  constructor(private formBuilder: FormBuilder, private store: Store, actions: Actions) {
    super(actions);
    this.generateFormControls();
  }

  ngOnInit() {
    const pharmacistList = this.store.selectSnapshot(PharmacistState.pharmacists);
    if (pharmacistList.length > 0) {
      this.setupPharmacistData(pharmacistList);
    }
    this.formSubscription = this.store.select(SickLeavePharmacistDeclarationState.sickLeavePharmacistDeclarationForm)
      .subscribe(() => this.onFormChange());
    this.readonlySubscription = this.store.select(SixCpaState.formsReadOnly)
      .subscribe(readonly => readonly ? this.sickLeavePharmacistDeclarationForm.disable() : this.sickLeavePharmacistDeclarationForm.enable());
  }
  get formControls() {
    return this.sickLeavePharmacistDeclarationForm.controls;
  }
  ngOnDestroy() {
   if (this.formSubscription) {this.formSubscription.unsubscribe(); }
   if (this.readonlySubscription) {this.readonlySubscription.unsubscribe(); }
  }
  setPharmacistData() {
    const pharmacist = this.store.selectSnapshot(PharmacistState.pharmacists)
      .filter((x) => `${x.givenNames} ${x.familyName}` === this.sickLeavePharmacistDeclarationForm.value.pharmacist);
    this.setupPharmacistData(pharmacist);
  }

  private setupPharmacistData (pharmacistList: Pharmacist[]) {
    if (pharmacistList.length > 0) {
      const { givenNames, ahpraNumber, familyName } = pharmacistList[0];
      this.sickLeavePharmacistDeclarationForm.patchValue({
        pharmacist: `${givenNames} ${familyName}`,
        pharmacistGivenName: givenNames,
        pharmacistFamilyName: familyName,
        ahpraNumber
       });
    }
  }
  protected actionOnResetForm() {
    this.sickLeavePharmacistDeclarationForm.patchValue(
      {
        haveDeliveredService: true,
        dateOfService: new Date(),
      });
      const pharmacistList = this.store.selectSnapshot(PharmacistState.pharmacists);
      this.setupPharmacistData(pharmacistList);
  }

  private onFormChange() {
    const controls = Object.keys(this.sickLeavePharmacistDeclarationForm.controls);
    const completedItems = this.getTotalValidItems(controls);
    this.store.dispatch(new SetTotalItemsAndCompletedItems(controls.length, completedItems));
  }

  private getTotalValidItems(controls: Array<string>) {
    return controls.filter((key) => {
      const formControl = this.sickLeavePharmacistDeclarationForm.get(key);
      return  formControl.status === 'VALID' || (formControl.status === 'DISABLED' && !formControl.invalid && formControl.errors === null);
    }).length;
  }

  private generateFormControls() {
    this.sickLeavePharmacistDeclarationForm = this.formBuilder.group({
      haveDeliveredService: new FormControl(true, Validators.requiredTrue),
      dateOfService: new FormControl(new Date(), Validators.required),
      pharmacist: new FormControl('', Validators.required),
      pharmacistGivenName: new FormControl('', Validators.required),
      pharmacistFamilyName: new FormControl('', Validators.required),
      ahpraNumber: new FormControl('', [Validators.required, Validators.maxLength(15)]),
    });
  }
}
