import { Component, ChangeDetectionStrategy, ChangeDetectorRef, Inject } from '@angular/core';
import { NgForm } from '@angular/forms';
import { collapse } from '../../../shared/core/animations';
import { Store, Select } from '@ngxs/store';
import { ToggleCategoryAction } from './state/categories.actions';
import { CategoriesState, DrugLoadingState } from './state/categories.state';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MultipleDrugsState } from './multiple-select/state/multiple-drugs.state';
import { ToggleDrugSelectedAction, UnselectManyDrugsAction, SelectManyDrugsAction } from './multiple-select/state/multiple-drugs.actions';
import { ManageMetaState, ManageMetaStateModel } from './manage-meta/state/manage-meta.state';
import { CloseManageMetaAction, ManageMetaRequestAction, OpenManageMetaAction } from './manage-meta/state/manage-meta.actions';
import { ScriptActionsState } from './state/script-actions.state';
import { ToggleScriptActionsAction, AddDosageRequestAction, CreateMetaRequestAction } from './state/script-actions.actions';
import { StockAdjustmentState, StockAdjustmentStateModel } from './stock-adjustment/state/stock-adjustment.state';
import { OpenStockAdjustmentAction, CloseStockAdjustmentAction } from './stock-adjustment/state/stock-adjustment.actions';
import { DosageHistoryState, DosageHistoryStateModel } from './dosage-history/state/dosage-history.state';
import { OpenDosageHistoryAction, CloseDosageHistoryAction } from './dosage-history/state/dosage-history.actions';
import { BulkStockAdjustmentState, BulkStockAdjustmentStateModel } from './bulk-stock-adjustment/state/bulk-stock-adjustment.state';
import { OpenBulkStockAdjustmentAction, CloseBulkStockAdjustmentAction } from './bulk-stock-adjustment/state/bulk-stock-adjustment.actions';
import { OpenInstoreOrderAction, CloseInstoreOrderAction } from './instore-order/state/instore-order.actions';
import { InstoreOrderState, InstoreOrderStateModel } from './instore-order/state/instore-order.state';
import { ProfileState } from '../../core/profile/state/profile.state';
import { ProfileZIndexCounterAction } from '../../core/profile/state/profile.actions';
import { QuickCISixCpaAction } from '../six-cpa/state/six-cpa.actions';
import { insightAvailable, getInsightContext } from '../order/categories/scrypt-insight.logic';
import { PauseMedicationState, PauseMedicationStateModel } from './pause-medication/state/pause-medication.state';
import { OpenPauseMedicationBlade, ClosePauseMedicationBlade, OpenPauseListBlade, ClosePauseListBlade } from './pause-medication/state/pause-medication.actions';
import { ToggleScryptSmsModal, GetScryptSMSRepeatTemplates, SetRepeatUri } from '../../../shared/components/scrypt-sms/state/scrypt-sms.actions';
import { SetDrugForScriptHistory, ToggleScriptHistoryModal } from './script-history/state/script-history.actions';
import { FeaturesState, FeaturesStateModel } from '../../core/features/features.state';
import { AddOutOfStoreItemActionFromCategory, OpenOutOfStore } from '../out-of-store/state/out-of-store.actions';
import { PassiveScriptType } from '../out-of-store/out-of-store.model';
import { Medication } from '../../../shared/models/script/chartviewitem.model';
import { StartingPointChangeReason } from '../../../shared/models/script/meta-view-model';
import * as moment_ from 'moment';
import { ASLService } from '../../../services/asl.service';
import { ClientViewModel } from '../../../modules/core/profile/client.model';
import { OpenRegisterMySl, OpenSummaryModal } from '../asl/state/asl.actions';
import { AslPatient, SLConsentState } from '../asl/state/asl.models';
import { ASLState } from '../asl/state/asl.state';
const moment = moment_;

enum ASLConsentState {
  FULL,
  NO,
  PARTIAL,
  REJECTED,
  NOT_REGISTERED
}

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss'],
  animations: [collapse],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CategoriesComponent {
  // TODO: You can tell looking at the number of selects below, this component is doing too much and needs breaking down
  @Select(CategoriesState.loading) loading$: Observable<boolean>;
  @Select(ProfileState.categoryOptions) categoryOptions$: Observable<any[]>;
  @Select(ManageMetaState) manageMeta$: Observable<ManageMetaStateModel>;
  @Select(StockAdjustmentState) stockAdjustment$: Observable<StockAdjustmentStateModel>;
  @Select(DosageHistoryState) dosageHistory$: Observable<DosageHistoryStateModel>;
  @Select(BulkStockAdjustmentState) bulkStockAdjustment$: Observable<BulkStockAdjustmentStateModel>;
  @Select(InstoreOrderState) instoreOrder$: Observable<InstoreOrderStateModel>;
  @Select(CategoriesState.categorised) categorised$: Observable<Medication[]>;
  @Select(PauseMedicationState) pauseMedicationState$: Observable<PauseMedicationStateModel>;
  @Select(FeaturesState.getFeatures) features$: Observable<FeaturesStateModel>;
  @Select(ProfileState.profile) profile$: Observable<ClientViewModel>;
  @Select(ASLState.aslDetails) aslDetails$: Observable<AslPatient>;
  @Select(ASLState.isFetchingPatient) isFetchingPatient$: Observable<boolean>;
  @Select(ASLState.aslPatientLoadFailed) aslPatientLoadFailed$: Observable<boolean>;

  drugLoadingState = DrugLoadingState;
  today: Date = new Date();
  changeReasons = StartingPointChangeReason;
  consentState = ASLConsentState;
  isLite = false;

  constructor(
    private store: Store,
    private changeDetectorRef: ChangeDetectorRef,
    private aslService: ASLService,
    @Inject('environment') env: any
  ) {
    this.isLite = env.isLite;
  }

  toggleHistory() {
  }

  toggleScript(item) {
    this.store.dispatch(new ToggleScriptActionsAction(item));
  }

  showScriptHistory(drug) {
    this.store.dispatch([new SetDrugForScriptHistory(drug), new ToggleScriptHistoryModal(true)]);
  }

  isDrugOpen$(drug): Observable<boolean> {
    return this.store.select(ScriptActionsState.isOpen)
      .pipe(
        map(isOpen => drug.cMeta && isOpen(drug))
      );
  }

  toggleScriptOnFile(chartViewItem: any) {
    chartViewItem.cMeta.onFile = !chartViewItem.cMeta.onFile;
    this.store.dispatch(new ManageMetaRequestAction(chartViewItem));
  }

  setZIndex() {
    const zInd = this.store.selectSnapshot(ProfileState.zIndexCounter) + 1;
    this.store.dispatch(new ProfileZIndexCounterAction(zInd));
  }

  deferredScriptClick(drug) {
    this.store.dispatch([new AddOutOfStoreItemActionFromCategory(drug, PassiveScriptType.deferredScript), new OpenOutOfStore(false)]);
  }

  outsideRepeatClick(drug) {
    this.store.dispatch([new AddOutOfStoreItemActionFromCategory(drug, PassiveScriptType.outsideRepeat), new OpenOutOfStore(false)]);
  }

  openAllPauses() {
    this.store.dispatch(new OpenPauseListBlade());
    this.setZIndex();
  }
  closeAllPauses() {
    this.store.dispatch(new ClosePauseListBlade());
  }

  openManageMeta() {
    this.store.dispatch(new OpenManageMetaAction());
    this.setZIndex();
  }
  closeManageMeta() { this.store.dispatch(new CloseManageMetaAction()); }

  openPauseMedication() {
    this.store.dispatch(new OpenPauseMedicationBlade());
    this.setZIndex();
  }
  closePauseMedication() { this.store.dispatch(new ClosePauseMedicationBlade()); }

  openStockAdjustment() {
    this.store.dispatch(new OpenStockAdjustmentAction());
    this.setZIndex();
  }
  closeStockAdjustment() { this.store.dispatch(new CloseStockAdjustmentAction()); }

  openDosageHistory() {
    this.store.dispatch(new OpenDosageHistoryAction());
    this.setZIndex();
  }
  closeDosageHistory() { this.store.dispatch(new CloseDosageHistoryAction()); }

  openBulkStockAdjustment() {
    this.store.dispatch(new OpenBulkStockAdjustmentAction());
    this.setZIndex();
  }
  closeBulkStockAdjustment() { this.store.dispatch(new CloseBulkStockAdjustmentAction()); }

  openInstoreOrder() {
    this.store.dispatch(new OpenInstoreOrderAction());
    this.setZIndex();
  }
  closeInstoreOrder() { this.store.dispatch(new CloseInstoreOrderAction()); }

  toggleCategory(categoryId) {
    this.store.dispatch(new ToggleCategoryAction(categoryId));
  }

  showCategory$(categoryId) {
    return this.store.select(CategoriesState.showCategory).pipe(map(showCategory => showCategory(categoryId)));
  }

  addDosage(drug) {
    if (drug.dosageForm.frequency % 1 === 0) {
      this.store.dispatch(new AddDosageRequestAction(drug.dosageForm));
    }
  }

  getDate(dispensedDate: Date | string) {
    if (dispensedDate) {
      return moment(dispensedDate).format('DD/MM/YYYY');
    }
    else {
      return moment(new Date()).format('DD/MM/YYYY');
    }
  }

  setUpDrug(drug, form: NgForm) {
    const { categoryId, frequency, startDate, doh } = form.value;
    const metaForm = {
      tenantCustomerId: this.store.selectSnapshot(ProfileState.tenantCustomerId),
      categoryId: categoryId,
      frequency: frequency,
      hasUncategorized: this.store.selectSnapshot(CategoriesState.uncategorised).length - 1 > 0,
      startDate: startDate ? moment(startDate, 'DD/MM/YYYY').format('YYYY-MM-DD') : drug.dispensedDate,
      doh: doh
    };

    this.store.dispatch(new CreateMetaRequestAction(drug, metaForm)).subscribe(() => this.toggleScript(drug));
  }

  matchDpd(drug) {
    const freq = drug.dosageForm.frequency;
    const qty = drug.quantity;
    drug.dosageForm.dosesPerDay = qty / freq;
    this.changeDetectorRef.markForCheck();
  }

  matchFrequency(drug) {
    const dpd = drug.dosageForm.dosesPerDay;
    const qty = drug.quantity;
    drug.dosageForm.frequency = Math.floor(qty / dpd);
    this.changeDetectorRef.markForCheck();
  }

  toggleScriptSelect(drug) {
    this.store.dispatch(new ToggleDrugSelectedAction(drug));
  }

  toggleCategorySelect(category) {
    if (category.selected) {
      this.store.dispatch(new SelectManyDrugsAction(category.items));
    } else {
      this.store.dispatch(new UnselectManyDrugsAction(category.items));
    }
  }

  isSelected(drug) {
    this.store.selectSnapshot(MultipleDrugsState.selectDrugsSet).has(drug);
  }

  quickCI(drug) {
    this.store.dispatch(new QuickCISixCpaAction(drug.productDispensedId));
  }

  insightAvailable(item) {
    return insightAvailable(item);
  }

  getInsightContext(item) {
    return getInsightContext(item);
  }

  sendRepeatSMS(drug) {
    this.store.dispatch([
      new ToggleScryptSmsModal(true),
      new GetScryptSMSRepeatTemplates(),
      new SetRepeatUri(drug.repeatTokenUri)
    ]);
  }

  openRegisterSLModal(): void {
    this.store.dispatch(new OpenRegisterMySl());
  }

  // sendConsent(): void {
  //   const aslDetails = this.store.selectSnapshot(ASLState.aslDetails);
  //   this.store.dispatch(new RequestASLConsent(aslDetails.ihi, aslDetails.firstName, aslDetails.surname));
  // }

  openMySLDetails(): void {
    this.store.dispatch(new OpenSummaryModal());
  }

  getConsentState(aslDetails: AslPatient): ASLConsentState {
    const status = aslDetails?.status;
    if (!status || !status.isRegistered) {
      return ASLConsentState.NOT_REGISTERED;
    } else if (status.infaStatus === SLConsentState.Rejected && status.icolStatus === SLConsentState.Rejected) {
      return ASLConsentState.REJECTED;
    } else if (status.infaStatus === SLConsentState.Active && status.icolStatus === SLConsentState.Active) {
      return ASLConsentState.FULL;
    } else if (status.infaStatus === SLConsentState.Active || status.icolStatus === SLConsentState.Active) {
      return ASLConsentState.PARTIAL;
    } else if (status.infaStatus === SLConsentState.Proposed || status.icolStatus === SLConsentState.Proposed) {
      return ASLConsentState.NO;
    }
  }
}
