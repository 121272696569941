<ng-container *ngIf="(stagedSupplyMedicationProfile$ | async) as stagedSupplyMedicationProfile">
    <ng-container *ngIf="(categorisedMedicines$ | async) as categorisedMedicines">

      <ng-container *ngFor="let selectedMedicines of [selectedMedicines$ | async]">
        <ng-container *ngFor="let readonly of [readonly$ | async]">
          <app-six-cpa-form-list-item formTitle="Medication Profile"
            formDescription="Select Meds to display on medications list" [itemsCompleted]="selectedMedicines"
            statusTitle="Selected" [showForm]="(showMode$ | async)" (modeChangedEvent)="modeChanged($event)">
            <section class="med-profile">
              <div g-xs="p3" class="med-profile__content">
                <div class="link" g-xs="mb2">
                  <button *ngIf="selectedMedicines === 0; else none" (click)="selectAll(true)" [disabled]="readonly">Select all</button>
                  <ng-template #none><button (click)="selectAll(false)" [disabled]="readonly">Select none</button></ng-template>
                </div>
                <ng-container *ngFor="let category of categorisedMedicines">
                  <div class="d-flex">
                    <h5 class="font__bold mb-1" *ngIf="category.medicines.length > 0">{{category.name}}</h5>
                    <div class="link category-link" g-xs="ml2" *ngIf="category.id">
                      <button *ngIf="checkIsSelectedByCategory(category.medicines); else none" (click)="selectAll(true, category.id)" [disabled]="readonly">Select all</button>
                      <ng-template #none><button (click)="selectAll(false, category.id)" [disabled]="readonly">Select none</button></ng-template>
                    </div>
                  </div>
                  <app-medication-profile-list-item
                    *ngFor="let item of category.medicines"
                    [drug]="item"
                    [disabled]="readonly"
                    (selectEvent)="select(item)"
                    (updateEvent)="updateMedication(item)">
                  </app-medication-profile-list-item>
                </ng-container>
                <button class="btn btn__yellow" g-xs="mt1" (click)="addEditMedication()" [disabled]="readonly">Add Additional Medicine</button>
              </div>
            </section>
          </app-six-cpa-form-list-item>
        </ng-container>
      </ng-container>
  </ng-container>
</ng-container>
