import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommsComponent } from './comms.component';
import { SmsModule } from './sms/sms.module';
import { PhoneModule } from './phone/phone.module';
import { MailModule } from './mail/mail.module';
import { EmailModule } from './email/email.module';
import { NotificationModule } from '../../../shared/components/notification/notification.module';
import { LoaderModule } from '../../core/loader/loader.module';
import { TabsModule } from 'ngx-bootstrap';
import { ProfileModule } from '../../core/profile/profile.module';
import { ScheduledMessagesModule } from './scheduled-messages/scheduled-messages.module';
import { MessageCycleModule } from './message-cycle/message-cycle.module';
import { CharacterCountService } from '../../../shared/helpers/character-count.service';
import { MessengerModule } from './messenger/messenger.module';
import { ProjectedMessagesModule } from './projected-messages/projected-messages.module';

@NgModule({
  declarations: [
    CommsComponent
  ],
  imports: [
    CommonModule,
    ProfileModule,
    NotificationModule,
    LoaderModule,
    SmsModule,
    PhoneModule,
    MailModule,
    EmailModule,
    TabsModule,
    ScheduledMessagesModule,
    MessageCycleModule,
    MessengerModule,
    ProjectedMessagesModule
  ],
  exports: [
    CommsComponent
  ],
  providers: [
    CharacterCountService
  ]
})
export class CommsModule { }
