<nz-modal
    [(nzVisible)]="isVisible"
    [nzTitle]="orderStatus !== orderStatusEnum.Cancelled ? 'Update order status': 'Cancel order'"
    [nzFooter]="modalFooter"
    [nzCloseIcon]="modalCloseIcon"
    (nzOnCancel)="handleCancel()"
    [nzWidth]="'740px'"
>
    <ng-template #modalCloseIcon>
        <i class="fas fa-times"></i>
    </ng-template>
    <h4>Order Status</h4>
    <div *nzModalContent>
        <div *ngIf="orderStatus !== orderStatusEnum.Cancelled">
            <label class="mb0">
                Order Status
            </label>
        </div>
        <nz-select
          *ngIf="orderStatus !== orderStatusEnum.Cancelled"
          class="select-status"
          ngDefaultControl
          [nzDropdownMatchSelectWidth]="false"
          [(ngModel)]="orderStatus">
            <!-- <nz-option [nzValue]="orderStatusEnum.Pending" [nzLabel]="'Pending'"></nz-option> -->
            <nz-option
              *ngIf="!hasOnOrderItems()"
              [nzValue]="orderStatusEnum.Ready" [nzLabel]="'Ready'"></nz-option>
            <nz-option
              *ngIf="!allItemsAreReady()"
              [nzValue]="orderStatusEnum.PartiallyReady" [nzLabel]="'Partially Ready'"></nz-option>
            <nz-option [nzValue]="orderStatusEnum.UnableToFill" [nzLabel]="'Unable to fill'"></nz-option>
            <nz-option [nzValue]="orderStatusEnum.Pending" [nzLabel]="'Pending'"></nz-option>
        </nz-select>

        <div>
            <label>Customer message</label>
        </div>
        <app-order-message
          [order]="order"
          [dateFn]="dateFn"
          [profile]="profile"
          [orderRequestStatus]="orderStatus"
          [(hasMessage)]="hasMessage"
          [(contactMethod)]="contactMethod"
          [(message)]="message"
        ></app-order-message>
    </div>
    <ng-template #modalFooter>
        <button class="update-button button btn__yellow"  (click)="handleOk()" [nzLoading]="isConfirmLoading">{{orderStatus !== orderStatusEnum.Cancelled ? 'Update order': 'Yes, cancel'}}</button>
        <button class="cancel-button" (click)="handleCancel()" >{{orderStatus !== orderStatusEnum.Cancelled ? 'Cancel': 'No, don\'t cancel'}}</button>
    </ng-template>
</nz-modal>
