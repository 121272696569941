import { Injectable } from '@angular/core';
import { environment } from "../../../../../environments/environment.prod";
import { BaseApiNoAlerts } from '~/shared/core/base-api/base-api-no-alerts';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { StorageService } from '~/shared/core/storage/storage.service';
import { AlertService } from '~/modules/core/alert/alert.service';
import { CommTemplate } from '~/shared/models/pharmacy/comm-template.model';
import { Observable } from 'rxjs';

const API = environment.baseApiUrl;

@Injectable()
export class CommTemplatesService extends BaseApiNoAlerts {
  constructor(public http: HttpClient, public router: Router, public storageService: StorageService, public alertService: AlertService) {
    super(http, router, storageService, alertService);
  }

  get(): Observable<CommTemplate[]> {
    return this.http.get<CommTemplate[]>(`${API}/api/tenant/comm-template`);
  }

  create(model: CommTemplate) {
    return this.http.post(`${API}/api/tenant/comm-template`, model);
  }
  update(model: CommTemplate) {
    return this.http.put(`${API}/api/tenant/comm-template`, model);
  }
  activate(id: number) {
    return this.http.patch(`${API}/api/tenant/comm-template`, {id, isActive: true});
  }

  deactivate(id: number) {
    return this.http.patch(`${API}/api/tenant/comm-template`, {id, isActive: false});
  }

}