import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators, FormArray, AbstractControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Store, Actions, ofActionSuccessful } from '@ngxs/store';
import { ActionPlanFormState, IActionPlan, IActionPlanForm } from '../state/action-plan-form.state';
import { SetActionPlanForm, SetTotalItemsAndCompletedItems } from '../state/action-plan-form.actions';
import { MedsCheckChildForm } from '../../meds-check-child-form';
import { ForConsiderationByForm } from '../../../../../shared/models/six-cpa/FormKeyValuePairs';
import { FormControlFunctions } from '../../form-control.functions';

@Component({
  selector: 'app-action-plan-form',
  templateUrl: './action-plan-form.component.html',
  styleUrls: ['./action-plan-form.component.scss']
})
export class ActionPlanFormComponent extends MedsCheckChildForm implements OnInit, OnDestroy {
  actionPlanForm: FormGroup;
  formSubscription: Subscription;
  forConsiderationByForm = ForConsiderationByForm;
  constructor(private formBuilder: FormBuilder, private store: Store, private formFunctions: FormControlFunctions, actions: Actions) {
    super(actions);
  }

  ngOnInit() {
    this.initForm();
    this.initsubscriptions();
  }

  private initForm() {
    this.actionPlanForm = this.formBuilder.group({
      hasActions: new FormControl(null, Validators.required),
      actions: this.formBuilder.array([])
    });
  }

  private initsubscriptions() {
    this.formSubscription = this.store.select(ActionPlanFormState.actionPlanForm)
      .subscribe(() => this.onFormChange());
    this.actions$.pipe(ofActionSuccessful(SetActionPlanForm))
      .subscribe(() => this.setCorrectActionsList());
  }

  removeFormControl(i) {
    let actionsArray = this.actionPlanForm.controls.actions as FormArray;
    actionsArray.removeAt(i);
  }

  setCorrectActionsList() {
    const actions = this.store.selectSnapshot(ActionPlanFormState.actionPlanForm).actions

    // remove all old form controls
    let actionsArray = this.actionPlanForm.controls.actions as FormArray;
    for (var i = actionsArray.length; i >= 0; i--) {
      actionsArray.removeAt(i);
    }
    if (actions) {
      // add all new needed form controls
      for (var i = 0; i < actions.length; i++) {
        this.addFormControl(actions[i]);
      }
    }
  }

  addFormControl(action: IActionPlan = null) {
    let actionsArray = this.actionPlanForm.controls.actions as FormArray;
    let arraylen = actionsArray.length;

    let newActiongroup: FormGroup = this.formBuilder.group({
      id: [0],
      issue: ['', Validators.required],
      recommendation: ['', Validators.required],
      forConsiderationBy: this.formBuilder.group({}),
      otherConsideration: ['']
    })
    this.formFunctions.generateControlsForGroupFromArr('forConsiderationBy', this.forConsiderationByForm, newActiongroup);

    if (action) {
      newActiongroup.patchValue(action);
    }

    actionsArray.insert(arraylen, newActiongroup);
  }

  setHasActions() {
    const hasAction = this.actionPlanForm.controls.hasActions.value;
    if (hasAction) {
      this.addFormControl();
    } else {
      let actionsArray = this.actionPlanForm.controls.actions as FormArray;
      for (var i = actionsArray.length; i >= 0; i--) {
        actionsArray.removeAt(i);
      }

    }
  }

  ngOnDestroy() {
    if (this.formSubscription) { this.formSubscription.unsubscribe(); }
  }

  get formControls() {
    return this.actionPlanForm.controls;
  }

  protected actionOnResetForm() {
    this.actionPlanForm.patchValue({
      hasActions: false,
      actions: []
    });

    this.setHasActions();
  }

  private onFormChange() {
    const requiredControls = this.countControls(this.actionPlanForm, () => true);
    const validRequiredControls = this.countControls(this.actionPlanForm, this.controlIsValid);
    this.store.dispatch(new SetTotalItemsAndCompletedItems(requiredControls, validRequiredControls));
  }

  private controlIsValid(control: FormControl) {
    return control.status === 'VALID' || (control.status === 'DISABLED' && !control.invalid && control.errors === null);
  }

  private countControls(control: AbstractControl, isValid): number {
    if (control instanceof FormControl) {
      return this.controlIsRequired(control) ? (isValid(control) ? 1 : 0) : 0;
    }

    if (control instanceof FormArray) {
      return control.controls.reduce((acc, curr) => acc + this.countControls(curr, isValid), 0)
    }

    if (control instanceof FormGroup) {
      return Object.keys(control.controls)
        .map(key => control.controls[key])
        .reduce((acc, curr) => acc + this.countControls(curr, isValid), 0);
    }
  }

  private controlIsRequired(control: AbstractControl) {
    const { validator } = control
    if (validator) {
      const validation = validator(new FormControl())
      return validation !== null && validation.required === true
    }
    return false;
  }
}
