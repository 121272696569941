import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { ClientRepository } from '../../core/profile/client.repository';
import { Client } from '../../../shared/models/client.model';
import { Notification } from '../../../shared/models/notification.model';
import { Select, Store } from '@ngxs/store';
import { NoteActionService } from '../../../shared/services/note-action.service';
import { NoteTypeService } from '../../../shared/core/notes/note-type.service';
import { ProfileState } from '../../core/profile/state/profile.state';
import { map, tap, switchMap, catchError, take } from 'rxjs/operators';
import { GetScheduledMessages } from './scheduled-messages/state/scheduled-messages.actions';
import { GetMessageCycles } from './message-cycle/state/message-cycle.actions';
import { FeaturesState, FeaturesStateModel } from '../../core/features/features.state';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-comms',
  templateUrl: './comms.component.html',
  styleUrls: ['./comms.component.scss']
})
export class CommsComponent implements OnInit {
  @Select(FeaturesState.getFeatures) features$: Observable<FeaturesStateModel>;
  isLoaded = false;
  type: string;
  client: Client;
  clientId: string;
  notification: Notification = new Notification('danger', '');
  clientTitle: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private clientRepository: ClientRepository,
    private store: Store,
    private noteActionService: NoteActionService
  ) {
  }

  ngOnInit() {
    this.clientId = this.store.selectSnapshot(ProfileState.clientId);
    this.dispatchCommsDataFetch(this.clientId);

    ProfileState.clientId$(this.store)
      .pipe(
        switchMap((clientId) => this.activatedRoute.params.pipe(map(params => [clientId, params]))),
        tap(([clientId, params]) => {
          this.clientId = clientId as string;
          this.type = (params as Params).type;
        }),
        switchMap(([clientId]) => this.clientRepository.getClientInfo(clientId as string)),
        tap((client: Client) => {
          this.client = client;
          this.isLoaded = true;
          if (this.client) {
            this.clientTitle = `${this.client.firstname} ${this.client.surname}`;
          }
        }),
        tap(() => {
          this.type = this.type ? this.type.toLowerCase() : 'sms';
          this.onSelect(this.type);
        }),
        catchError(error => this.notification.text = error),
        take(1)
      ).subscribe();
  }


  onSelect(noteTypeStr: string) {
    const noteType = NoteTypeService.getFromName(noteTypeStr);
    if (!NoteTypeService.shouldMarkAsActionedWhenViewed(noteType)) {
      return;
    }
    return this.noteActionService.markAsActioned(this.clientId, NoteTypeService.getNoteTypeVariants(noteType))
      .subscribe();
  }

  private dispatchCommsDataFetch(clientId: string) {
    this.store.dispatch(new GetScheduledMessages(clientId));
    this.store.dispatch(new GetMessageCycles(clientId));
  }
}
