import { SixCpaActionPlanForm } from '../../../../../shared/models/six-cpa/action-plan.model';
import { DoctorReportModel } from '../../../../../shared/models/six-cpa/doctor-report.model';
import { SixCpaMedicationModel } from '../../../../../shared/models/six-cpa/six-cpa-medication.model';

export enum ConfirmMedsCheckActionTypes {
  TOGGLE_CONFIRM_MEDS_CHECK_ACTION = '[ConfirmMedsCheck] Toggle Blade',
  SET_DOCTORS_CONFIRM_MEDS_CHECK_ACTION = '[ConfirmMedsCheck] set doctor list',
  SEND_TO_DOCTOR_CONFIRM_MEDS_CHECK_ACTION = '[ConfirmMedsCheck] send to doctor',
  GO_BACK_CONFIRM_MEDS_CHECK_ACTION = '[ConfirmMedsCheck] Go Back',
  TOGGLE_RESPONSE_CONFIRM_MEDS_CHECK_ACTION = '[ConfirmMedsCheck] Toggle Response',
  GET_PATIENT_REPORT_CONFIRM_MEDS_CHECK_ACTION = '[ConfirmMedsCheck] Get Patient Report',
  CONFIRM_MEDS_CHECK_ERROR_ACTION = '[ConfirmMedsCheck] Error',
  GET_DOCTOR_REPORT_CONFIRM_MEDS_CHECK_ACTION = '[ConfirmMedsCheck] Get Doctor Report',
  CREATE_SIX_CPA_NOTE_CONFIRM_MEDS_CHECK_ACTION = '[ConfirmMedsCheck] create SixCpa Note',
  SETUP_MADICATIONS_MEDS_CONFIRM_CHECK_ERROR_ACTION = '[ConfirmMedsCheck] Setup Medications',
  SEND_MEDICATION_LIST_EMAIL = '[ConfirmMedsCheck] Send Medication List Email'
}

export class ToggleConfirmMedsCheckAction {
  static readonly type = ConfirmMedsCheckActionTypes.TOGGLE_CONFIRM_MEDS_CHECK_ACTION;
  constructor(public slideMode: string) { }
}

export class SetDoctorListConfirmMedsCheckAction {
  static readonly type = ConfirmMedsCheckActionTypes.SET_DOCTORS_CONFIRM_MEDS_CHECK_ACTION;
  constructor(public doctors: any[]) { }
}

export class SendToDoctorConfirmMedsCheckAction {
  static readonly type = ConfirmMedsCheckActionTypes.SEND_TO_DOCTOR_CONFIRM_MEDS_CHECK_ACTION;
  constructor(public clientId: string, public customisedDoctorReport: Partial<DoctorReportModel>) { }
}

export class SendMedsicationList {
  static readonly type = ConfirmMedsCheckActionTypes.SEND_MEDICATION_LIST_EMAIL;
  constructor(public clientId: string, public customisedDoctorReport: Partial<DoctorReportModel>) { }
}

export class GoBackFromConfirmMedsCheckAction {
  static readonly type = ConfirmMedsCheckActionTypes.GO_BACK_CONFIRM_MEDS_CHECK_ACTION;
}

export class ToggleResponseConfirmMedsCheckAction {
  static readonly type = ConfirmMedsCheckActionTypes.TOGGLE_RESPONSE_CONFIRM_MEDS_CHECK_ACTION;
  constructor(public showResponse: string) { }
}

export class GetPatientReportConfirmMedsCheckAction {
  static readonly type = ConfirmMedsCheckActionTypes.GET_PATIENT_REPORT_CONFIRM_MEDS_CHECK_ACTION;
  constructor(public clientId: string, public doctorFullName?: string) { }
}

export class GetDoctorReportConfirmMedsCheckAction {
  static readonly type = ConfirmMedsCheckActionTypes.GET_DOCTOR_REPORT_CONFIRM_MEDS_CHECK_ACTION;
  constructor(public clientId: string, public customisedDoctorReport: Partial<DoctorReportModel>) { }
}

export class CreateSixCpaNoteConfirmMedsCheckAction {
  static readonly type = ConfirmMedsCheckActionTypes.CREATE_SIX_CPA_NOTE_CONFIRM_MEDS_CHECK_ACTION;
  constructor(public clientId: string, public folowUpDate: Date, public body: string) { }
}

export class ConfirmMedsCheckErrorAction {
  static readonly type = ConfirmMedsCheckActionTypes.CONFIRM_MEDS_CHECK_ERROR_ACTION;
  constructor(public error: any) { }
}

export class SetupMedicationsConfirmMedsCheck {
  static readonly type = ConfirmMedsCheckActionTypes.SETUP_MADICATIONS_MEDS_CONFIRM_CHECK_ERROR_ACTION;
  constructor(public sixCpaMedications: SixCpaMedicationModel[], public actionPlan: Partial<SixCpaActionPlanForm>) { }
}
