<form [formGroup]="gpDetailsReferralForm" ngxsForm="hmrGPDetailsReferralFormBasic.gpDetailsReferralForm" class="p-4">
    <div class="form-group pb-1">
        <label for="firstName" class="input-label">GP Given name(s)
        </label>
        <input type="text" [class.has-error]="formControls.firstName.invalid && (showInvalid$ | async)"
            class="form-control form-control-lg w-25" name="firstName" formControlName="firstName" id="firstName"
            required>
    </div>
    <div class="form-group pb-1">
        <label for="surname" class="input-label">GP Family Name
        </label>
        <input type="text" [class.has-error]="formControls.surname.invalid && (showInvalid$ | async)"
            class="form-control form-control-lg w-25" name="surname" formControlName="surname" id="surname" required>
    </div>
    <div class="form-group pb-1">
        <label for="providerNumber" class="input-label">GP Provider Number
        </label>
        <input type="text" [class.has-error]="formControls.providerNumber.invalid && (showInvalid$ | async)"
            class="form-control form-control-lg w-25" name="providerNumber" formControlName="providerNumber"
            id="providerNumber" required>
    </div>
    <div class="form-group pb-1">
        <label for="referralDate" class="input-label">Date of GP Referral</label>
        <input type="text" [class.has-error]="formControls.referralDate.invalid && (showInvalid$ | async)"
            class="form-control form-control-lg w-25"
            value="gpDetailsReferralForm.value.referralDate | date: 'DD-MM-YYYY'" formControlName="referralDate"
            id="referralDate" [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY' }" placeholder="dd-mm-yyyy" bsDatepicker>
    </div>
</form>