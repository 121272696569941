<ng-container *ngIf="(eventConfirmation$ | async) as eventConfirmation">
  <ng-container *ngIf="eventConfirmation.showModal">
    <div class="modal confirm-modal">
      <div class="modal-body border__grey">
        <div *ngIf="!eventConfirmation.loading; else loading">
          <h3 class="p-2">Send <span *ngIf="eventConfirmation.notification.commType">({{eventConfirmation.notification.commType}})</span> Communication</h3>
          <p class="p-2">Do you want to send the following {{getTriggerTypeAsEvent(eventConfirmation.currentTriggerType)}} message?</p>
          <p class="p-2"><small>{{eventConfirmation.notification.message}}</small></p>
          <div g-xs="mt2">
            <button class="button btn__yellow" g-xs="mr1" (click)="confirm()">Yes, send message</button>
            <button class="button btn__outline-dark" (click)="close()">No, don't send the message</button>
          </div>
          <div g-xs="mt2">
            <p class="p-2">
              Manage your pharmacy <a href="config/communication" target="_blank">communication preferences here</a>
            </p>
          </div>
        </div>
        <ng-template #loading>
          <app-loader loadingMessage="Just a sec..."></app-loader>
        </ng-template>
      </div>
    </div>
    <div class="modal-background"></div>
  </ng-container>
</ng-container>
