import { State, Selector, StateContext, Action } from '@ngxs/store';
import { BulkMessage, Recipient } from '~/shared/models/communication/bulk-message.model';
import { BulkMessageError, GetBulkMessagesUpcoming, RemoveBulkMessage, RemoveBulkMessageSuccess } from './bulk-messaging.actions';
import { BulkMessagingService } from '../services/bulk-messaging.service';
import { tap, catchError, finalize } from 'rxjs/operators';
import { of } from 'rxjs';
import { Injectable } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';

const ALERT_POSITION = 'topRight'

export class BulkMessagingUpcomingStateModel {
  upcomingBulkMessages: BulkMessage[];
  loading: boolean;
  recipients: Recipient[];
}
@Injectable()
@State<BulkMessagingUpcomingStateModel>({
  name: 'bulkMessagingUpcoming',
  defaults: {
    upcomingBulkMessages: [],
    loading: false,
    recipients: [], //TODO: this is probably unused I don't know what its for

  }
})
export class BulkMessagingUpcomingState {
  constructor(private nzNotificationService: NzNotificationService, private bulkMessagingService: BulkMessagingService) { }

  @Selector()
  static upcomingBulkMessages(state: BulkMessagingUpcomingStateModel) { return state.upcomingBulkMessages; }

  @Selector()
  static loading(state: BulkMessagingUpcomingStateModel) { return state.loading; }

  @Action(GetBulkMessagesUpcoming)
  getBulkMessagesUpcoming({ patchState }: StateContext<BulkMessagingUpcomingStateModel>, { showRecipients }: GetBulkMessagesUpcoming) {
    patchState({ loading: true });
    return this.bulkMessagingService.getUpcomingBulkMessages(showRecipients).pipe(
      tap(upcomingBulkMessages => patchState({ upcomingBulkMessages })),
      catchError(error => of(this.nzNotificationService.error('An error occured', error, {nzPlacement: ALERT_POSITION}))),
      finalize(() => patchState({ loading: false })));
  }

  @Action(RemoveBulkMessage)
  removeBulkMessage(ctx: StateContext<BulkMessagingUpcomingStateModel>, { bulkMessageId }: RemoveBulkMessage) {
    ctx.patchState({ loading: true });
    return this.bulkMessagingService.removeBulkMessage(bulkMessageId).pipe(
      tap(() => ctx.dispatch(new RemoveBulkMessageSuccess())),
      catchError(error => ctx.dispatch(new BulkMessageError(error))),
      finalize(() => ctx.patchState({ loading: false })));
  }

  @Action(RemoveBulkMessageSuccess)
  removeBulkMessageSuccess(ctx: StateContext<BulkMessagingUpcomingStateModel>) {
    this.nzNotificationService.success('Success', 'Bulk message removed successfully', {nzPlacement: ALERT_POSITION});
    ctx.dispatch(new GetBulkMessagesUpcoming()); // refetch messages
  }
}
