import { Selector } from '@ngxs/store';
import { MedListMedicationProfileState, MedsListMedicationProfileForm } from '../../med-list-medication-profile/state/med-list-medication-profile.state';
import { MedicationListFormModel } from '../../../../../../shared/models/six-cpa/medication-list/medication-list-form.model';
import { Medicine } from '../../../../../../shared/models/six-cpa/medicine.model';
import { MedicationListFormListStateModel, MedicationListFormListState } from './medication-list-form.state';

export class MedicationListFormsService {
  @Selector([
    MedicationListFormListState,
    MedListMedicationProfileState.selectedMedicines,
    MedListMedicationProfileState.medsListForm
  ])
  static getMedicationListRequest(
    medListState: MedicationListFormListStateModel,
    selectedMedicines: Medicine[],
    medsList: MedsListMedicationProfileForm): MedicationListFormModel {
   const sixCpaMedications = this.getSixCpaMedications(selectedMedicines);
    return {
      id: medListState.medicationListId,
      sixCpaFormId: medListState.sixCpaFormId,
      sixCpaMedications,
      allergies: medsList.allergies,
      primaryDoctor: medsList.primaryDoctor
    };
  }

  private static getSixCpaMedications(medicines: Medicine[]) {
    return medicines
      .map(m => ({
        id: m.id,
        productDispensedId: m.productDispensedId,
        brandName: m.brandName,
        genericName: m.genericName,
        directions: m.directions,
        quantity: m.quantity,
        reasonsForTaking: m.reasonsForTaking,
        notes: m.notes,
        prescriber: m.prescriber,
        startDate: m.startDate
      }));
  }
}
