import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';
import { PharmacistService } from '../../../shared/services/pharmacist.service';
import { PharmacistState } from './pharmacist.state';

@NgModule({
  imports: [
    NgxsModule.forFeature([
      PharmacistState
    ])
  ],
  providers: [
    PharmacistService
  ]
})
export class PharmacistStateModule { }
