import { HMRChildForm } from '../hmr-child-form';
import { OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Store, Actions } from '@ngxs/store';
import { SetTotalItemsAndCompletedItems } from './state/hmr-patient-details-form.actions';

const controlsToIgnore = ['dob', 'medicareNumber', 'firstName', 'surname', 'residentialPostCode', 'homeSuburb'];
export class HMRPatientDetailsFormBaseComponent extends HMRChildForm implements OnInit, OnDestroy {
    patientDetailsForm: FormGroup;
    formResetStatus: boolean;

    constructor(private formBuilder: FormBuilder, protected store: Store, actions: Actions) {
      super(actions);
      this.patientDetailsForm = this.formBuilder.group({});
    }

    protected actionOnResetForm() {
      const controls = Object.keys(this.patientDetailsForm.controls);

      controls.filter((key) => {
        if (!controlsToIgnore.includes(key)) {
          this.patientDetailsForm.controls[key].setValue('');
        }
      });
    }
    get formControls() {
      return this.patientDetailsForm.controls;
    }
    protected onFormChange() {
      const controls = Object.keys(this.patientDetailsForm.controls);
      const completedItems = this.getTotalValidItems(controls);
      this.store.dispatch(new SetTotalItemsAndCompletedItems(controls.length, completedItems));
    }

    private getTotalValidItems(controls: Array<string>) {
      return controls.filter((key) => {
        const formControl = this.patientDetailsForm.get(key);
        return  formControl.status === 'VALID' || (formControl.status === 'DISABLED' && !formControl.invalid && formControl.errors === null);
      }).length;
    }
  }

