<form [formGroup]="consentEligibilityForm" ngxsForm="consentEligibilityFormBasic.consentEligibilityForm" class="p-4">
    <div class="form-group pb-1">
        <label class="input-label mb-0">Patients Consent</label>
        <div>
            <small class="disclaimer">
                The patient consents to the collection of personal information by the Pharmacy
                Programs Administrator and the Department for program monitoring and evaluation purposes
            </small>
        </div>
        <app-radio-highlight controlName="consentYes"
        [hasError]="formControls.patientsConsent.invalid && (showInvalid$ | async)"
         [isHighlighted]="consentEligibilityForm.value.patientsConsent == true">
            <input class="form-check-input" type="radio" name="patientsConsent" id="consentYes"
                formControlName="patientsConsent" [value]="true">
            <label class="form-check-label" for="consentYes">Yes</label>
        </app-radio-highlight>
        <app-radio-highlight
        [hasError]="formControls.patientsConsent.invalid && (showInvalid$ | async)"
         controlName="consentNo" [isHighlighted]="consentEligibilityForm.value.patientsConsent == false">
            <input class="form-check-input" type="radio" name="patientsConsent" id="consentNo"
                formControlName="patientsConsent" [value]="false" checked>
            <label class="form-check-label" for="consentNo">No</label>
        </app-radio-highlight>
    </div>
    <div class="form-group pb-1" [class.has-error-group]="formControls.consentType.invalid && (showInvalid$ | async)">
        <label class="input-label">How has the patient given consent?</label>
        <div class="form-check form-check">
            <input class="form-check-input" type="radio" name="consentType" id="written"
                formControlName="consentType" [value]=0>
            <label class="form-check-label" for="minimal">Written</label>
        </div>
        <div class="form-check form-check">
            <input class="form-check-input" type="radio" name="consentType" id="verbal"
                formControlName="consentType" [value]=1>
            <label class="form-check-label" for="occasional">Verbal</label>
            <div>
                <small class="xs-disclaimer">
                    The patient has verbally provided consent for the pharmacy to collect and disclose their personal information to PPA and the Australian Government Department of Health for the purpose indicated above.
                </small>
            </div>
        </div>
    </div>
    <label class="input-label-not-required mb-0">Consent form</label>
    <div>
        <div *ngFor="let type of [medsCheckType$| async]" class="upload-files">
            <i class="far fa-file-pdf"></i>&nbsp;&nbsp; <a [href]="getConsentDocumentLink(type)" target="_blank">Consent Form PDF</a>
        </div>

        <app-upload-documents-form
            name="Upload documents to service form"
            [disabled]="consentEligibilityForm.disabled"
            [documentOrigin]="documentOrigins.Consent"
        >
        </app-upload-documents-form>
    </div>
</form>
