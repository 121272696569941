import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges, SimpleChange } from '@angular/core';
import * as moment from 'moment';
import { Note } from '~/shared/models/crm/note.model';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { slide } from '~/shared/core/animations';
import { Store } from '@ngxs/store';
import { UpdateNoteAction } from '~/system/crm-page/state/crm.actions';

export function repeatValidator(form: FormGroup): { [key: string]: boolean } | null {
  if (form) {
    if (form.controls.repeat.value) {
      if (form.controls.intervalValue.value === null) {
        form.controls.intervalValue.setErrors({ required: true });
      }
      if (form.controls.repeatType.value === null) {
        form.controls.repeatType.setErrors({ required: true });
      }
    }
  }
  return null;
}

@Component({
  selector: 'app-note-settings',
  templateUrl: './note-settings.component.html',
  styleUrls: ['./note-settings.component.scss'],
  animations: [
    slide
  ]
})
export class NoteSettingsComponent implements OnInit, OnChanges {
  constructor(private store: Store) { }
  @Input() noteList: Note[];
  @Output() closeEvent = new EventEmitter<any>();
  tags: any[] = [
    {
      description: '+ 15 min',
      adjustment: (date: Date): Date => moment(date).add(15, 'minutes').toDate()
    }, {
      description: '+ 1 hour',
      adjustment: (date: Date): Date => moment(date).add(1, 'hour').toDate()
    }, {
      description: '+ 1 day',
      adjustment: (date: Date): Date => moment(date).add(1, 'day').toDate()
    }, {
      description: '+ 1 week',
      adjustment: (date: Date): Date => moment(date).add(7, 'day').toDate()
    }, {
      description: 'Clear',
      adjustment: (date: Date): Date => null
    }
  ];
  repeatTypeList: any[] = [];
  currentDate: Date = new Date();
  minDate: Date = new Date();
  noteSettingsForm = new FormGroup({
    repeat: new FormControl(0, Validators.required),
    intervalValue: new FormControl(null),
    repeatType: new FormControl(null),
    snoozeDate: new FormControl(null),
    snoozeInterval: new FormControl(null)
  }, [(f: FormGroup) => repeatValidator(f)]);
  isEScript = false;

  ngOnInit() {
    this.repeatTypeList.push(
      { name: 'Day(s)', value: 1 },
      { name: 'Week(s)', value: 2 },
      { name: 'Month(s)', value: 3 }
    );

  }

  ngOnChanges(changes: SimpleChanges) {
    const noteList: SimpleChange = changes.noteList;
    if (noteList.currentValue !== undefined && this.noteList.length === 1) {
      this.currentDate = new Date(this.noteList[0].nextActionDate);

      this.minDate = moment(this.currentDate).add(1, 'days').toDate();
      this.noteSettingsForm.setValue({
        repeat: this.noteList[0].repeat,
        intervalValue: this.noteList[0].intervalValue,
        repeatType: this.noteList[0].repeatType === 0 ? null : this.noteList[0].repeatType,
        snoozeDate: null,
        snoozeInterval: null
      });
    } else {
      this.noteSettingsForm.reset();
      this.noteSettingsForm.patchValue({ repeat: 0 });
    }
  }

  updateTime(adjustment: (date: Date) => Date): void {
    let {snoozeDate, snoozeInterval} = this.noteSettingsForm.value;
    if(!snoozeDate || !snoozeInterval) {
      snoozeDate = this.currentDate > new Date() ? this.currentDate : new Date();
      snoozeInterval = this.currentDate > new Date() ? this.currentDate : new Date();
    }
    this.noteSettingsForm.patchValue({
      snoozeDate: snoozeDate ? adjustment(snoozeDate): snoozeDate,
      snoozeInterval: snoozeInterval ? adjustment(snoozeInterval): snoozeInterval
    });
  }

  get formControls() {
    return this.noteSettingsForm.controls;
  }

  closeBlade() {
    this.noteList = undefined;
    this.closeEvent.emit();
  }

  onSubmit() {
    const { repeat, repeatType, intervalValue, snoozeDate, snoozeInterval } = this.noteSettingsForm.value;
    if (snoozeDate !== null && snoozeInterval !== null) {
      const hh = parseInt(moment(snoozeInterval).format("HH"));
      const mm = parseInt(moment(snoozeInterval).format("mm"));

      var date = new Date(snoozeDate);
        date.setHours(hh);
        date.setMinutes(mm)
    }


    this.noteList = this.noteList.map((item) => {
      return {
        ...item,
        repeat,
        repeatType: repeat ? repeatType : 0,
        intervalValue: repeat ? intervalValue : null,
        delayedUntil: (snoozeDate !== null && snoozeDate != undefined) ? date : item.delayedUntil
      };
    });


    this.store.dispatch(new UpdateNoteAction(this.noteList))
      .subscribe((data) => {
        this.noteSettingsForm.reset();
        this.closeBlade();
      });
  }

}
