import { Component, OnInit } from '@angular/core';
import { FormControl, Validators, FormBuilder, FormGroup } from '@angular/forms';
import { SSOutcomesAndActionsFormState } from './state/ss-outcomes-and-actions-form.state';
import { Store, Actions } from '@ngxs/store';
import { SetTotalItemsAndCompletedItems } from './state/ss-outcomes-and-actions-form.actions';
import { Subscription } from 'rxjs';
import { FormControlFunctions } from '../../form-control.functions';
import { StagedSupplyChildForm } from '../../staged-supply-child-form';
import { StagedSupplyPharmacistActionsKeyValues } from '../../../../../shared/models/six-cpa/staged-supply/ss-outcomes-and-actions-form.model';

import { SixCpaState } from '../../state/six-cpa.state';
@Component({
  selector: 'app-ss-outcomes-and-actions-form',
  templateUrl: './ss-outcomes-and-actions-form.component.html',
  styleUrls: ['./ss-outcomes-and-actions-form.component.scss']
})
export class SSOutcomesAndActionsFormComponent extends StagedSupplyChildForm implements OnInit {
  private formSubscription: Subscription;
  private readonlySubscription: Subscription;
  outcomesAndActionsForm: FormGroup;
  stagedSupplyPharmacistActions = StagedSupplyPharmacistActionsKeyValues;

  constructor(private formBuilder: FormBuilder, private store: Store, private formFunctions: FormControlFunctions, actions: Actions) {
    super(actions);
    this.generateFormControls();
  }

  ngOnInit() {
    this.formSubscription = this.store.select(SSOutcomesAndActionsFormState.outcomesAndActionsForm)
      .subscribe(() => this.onFormChange());
    this.readonlySubscription = this.store.select(SixCpaState.formsReadOnly)
      .subscribe(readonly => readonly ? this.outcomesAndActionsForm.disable() : this.outcomesAndActionsForm.enable());
  }

  ngOnDestroy() {
    this.formSubscription.unsubscribe();
    this.readonlySubscription.unsubscribe();
  }
  get formControls() {
    return this.outcomesAndActionsForm.controls;
  }
  protected actionOnResetForm() {
    this.outcomesAndActionsForm.reset();
  }

  private onFormChange() {
    const controls = Object.keys(this.outcomesAndActionsForm.controls);
    const completedItems = this.getTotalValidItems(controls);
    this.store.dispatch(new SetTotalItemsAndCompletedItems(controls.length, completedItems));
  }

  private getTotalValidItems(controls: Array<string>) {
    return controls.filter((key) => {
      const formControl = this.outcomesAndActionsForm.get(key);
      return  formControl.status === 'VALID' || (formControl.status === 'DISABLED' && !formControl.invalid && formControl.errors === null);
    }).length;
  }

  private generateFormControls() {
    this.outcomesAndActionsForm = this.formBuilder.group({
      stagedSupplyPharmacistActions: this.formBuilder.group({}, Validators.required),
      patientRatingOfServicePreventingMedicineProblems: new FormControl(null, [Validators.min(1), Validators.max(10), Validators.required]),
      patientRatingOfServiceInMagaingMedicines: new FormControl(null, [Validators.min(1), Validators.max(10), Validators.required]),
    });
    this.formFunctions.generateControlsForGroupFromArr('stagedSupplyPharmacistActions', this.stagedSupplyPharmacistActions, this.outcomesAndActionsForm);
  }
}
