import { Component, EventEmitter, Input, Output } from "@angular/core";
import { OrderRequest } from "@profile/order-requests/models/order-request.model";

@Component({
    selector: 'app-send-to-queue-button',
    templateUrl: './send-to-queue-button.component.html',
    styleUrls: ['./send-to-queue-button.component.scss']
})
export class SendToQueueButtonComponent {
    @Input() order: OrderRequest | undefined;
    @Input() loading: boolean | undefined;
    @Output() sendOrderToQueueEvent= new EventEmitter<OrderRequest>();

    constructor() {}

    sendToQueue() {
        this.sendOrderToQueueEvent.emit(this.order);
    }
}
