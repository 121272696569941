import { State, Action, StateContext, Selector } from '@ngxs/store';
import {
  GetPharmacyCommsDetailsAction,
  GetPharmacyCommsDetailsSuccessAction,
  PharmacyCommsDetailsFailedAction,
  UpdatePharmacyCommsDetailsAction,
  UpdatePharmacyCommsDetailsSuccessAction,
  GetPharmacyLocationAction,
  GetPharmacyLocationSuccessAction,
  SubmitPharmacyCommsDetailsAction,
  CreatePharmacyCommsDetailsAction,
  CreatePharmacyCommsDetailsSuccessAction
} from './pharmacy-comms-details.actions';
import { PharmacyCommsDetails } from '~/shared/models/pharmacy/pharmacy-comms-details.model';
import { PharmacyCommsDetailsService } from '../services/pharmacy-comms-details.service';
import { tap, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { PharmacyLocation } from '~/shared/models/pharmacy/pharmacy-address.model';
import { PharmacyService } from '~/shared/services/pharmacy.service';

export class PharmacyCommsDetailsStateModel {
  defaultPharmacyCommsDetails: PharmacyCommsDetails;
  pharmacyLocation: PharmacyLocation;
  error: any;
  loading: boolean;
  pharmacyCommsDetailsForm: {
    model: Partial<PharmacyCommsDetails>;
  };
}
@Injectable()
@State<PharmacyCommsDetailsStateModel>({
  name: 'pharmacyCommsDetails',
  defaults: {
    defaultPharmacyCommsDetails: null,
    pharmacyLocation: null,
    error: null,
    loading: false,
    pharmacyCommsDetailsForm: {
      model: undefined
    },
  }
})
@Injectable()
export class PharmacyCommsDetailsState {
  constructor(private pharmacyCommsDetailsService: PharmacyCommsDetailsService, private pharmacyService: PharmacyService) { }

  @Selector()
  static pharmacy(state: PharmacyCommsDetailsStateModel): PharmacyLocation {
    return state.pharmacyLocation;
  }

  @Selector()
  static defaultPharmacyCommsDetails(state: PharmacyCommsDetailsStateModel): PharmacyCommsDetails {
    return state.defaultPharmacyCommsDetails;
  }

  @Selector()
  static pharmacyCommsDetails(state: PharmacyCommsDetailsStateModel) {
    return state.pharmacyCommsDetailsForm.model;
  }

  @Selector()
  static enableOrderManagementForPharmacy({ pharmacyLocation }: PharmacyCommsDetailsStateModel) {
    return pharmacyLocation.enableOrderManagement;
  }

  @Action(GetPharmacyLocationAction)
  getPharmacyLocationAction({ patchState, dispatch }: StateContext<PharmacyCommsDetailsStateModel>) {
    patchState({ loading: true });

    return this.pharmacyService.getPharmacyLocation().pipe(
      tap((res) => dispatch(new GetPharmacyLocationSuccessAction(res))),
      catchError((error) => dispatch(new PharmacyCommsDetailsFailedAction(error))));
  }

  @Action(GetPharmacyLocationSuccessAction)
  getPharmacyLocationSuccessAction({ patchState }: StateContext<PharmacyCommsDetailsStateModel>, { pharmacyLocation }: GetPharmacyLocationSuccessAction) {
    patchState({ pharmacyLocation, loading: false });
  }

  @Action(GetPharmacyCommsDetailsAction)
  getPharmacyCommsDetails({ patchState, dispatch }: StateContext<PharmacyCommsDetailsStateModel>) {
    patchState({ error: null, loading: true });
    return this.pharmacyCommsDetailsService.get().pipe(
      tap((res) => dispatch(new GetPharmacyCommsDetailsSuccessAction(res))),
      catchError((error) => dispatch(new PharmacyCommsDetailsFailedAction(error))));
  }

  @Action(GetPharmacyCommsDetailsSuccessAction)
  getPharmacyCommsDetailsSuccess({ patchState }: StateContext<PharmacyCommsDetailsStateModel>, { pharmacyCommsDetails }: GetPharmacyCommsDetailsSuccessAction) {
    if (pharmacyCommsDetails) {
      patchState({ defaultPharmacyCommsDetails: pharmacyCommsDetails, loading: false, pharmacyCommsDetailsForm: { model: pharmacyCommsDetails } });
    } else {
      patchState({ loading: false });
    }
  }

  @Action(PharmacyCommsDetailsFailedAction)
  pharmacyCommsDetailsFailed({ patchState }: StateContext<PharmacyCommsDetailsStateModel>, { error }: PharmacyCommsDetailsFailedAction) {
    patchState({ error, loading: false });
  }

  @Action(CreatePharmacyCommsDetailsAction)
  createPharmacyCommsDetailsAction({ dispatch }: StateContext<PharmacyCommsDetailsStateModel>, { pharmacyCommsDetails }: UpdatePharmacyCommsDetailsAction) {
    return this.pharmacyCommsDetailsService.create(pharmacyCommsDetails).pipe(
      tap(() => dispatch(new CreatePharmacyCommsDetailsSuccessAction(pharmacyCommsDetails))),
      catchError((error) => dispatch(new PharmacyCommsDetailsFailedAction(error))));
  }

  @Action(CreatePharmacyCommsDetailsSuccessAction)
  createPharmacyCommsDetailsSuccessAction({ patchState }: StateContext<PharmacyCommsDetailsStateModel>, { pharmacyCommsDetails }: UpdatePharmacyCommsDetailsSuccessAction) {
    patchState({ defaultPharmacyCommsDetails: pharmacyCommsDetails, pharmacyCommsDetailsForm: { model: pharmacyCommsDetails }, loading: false });
  }

  @Action(UpdatePharmacyCommsDetailsAction)
  updatePharmacyCommsDetailsAction({ dispatch }: StateContext<PharmacyCommsDetailsStateModel>, { pharmacyCommsDetails }: UpdatePharmacyCommsDetailsAction) {
    return this.pharmacyCommsDetailsService.update(pharmacyCommsDetails).pipe(
      tap(() => dispatch(new UpdatePharmacyCommsDetailsSuccessAction(pharmacyCommsDetails))),
      catchError((error) => dispatch(new PharmacyCommsDetailsFailedAction(error))));
  }

  @Action(UpdatePharmacyCommsDetailsSuccessAction)
  updatePharmacyCommsDetailsSuccessAction({ patchState }: StateContext<PharmacyCommsDetailsStateModel>, { pharmacyCommsDetails }: UpdatePharmacyCommsDetailsSuccessAction) {
    patchState({ defaultPharmacyCommsDetails: pharmacyCommsDetails, loading: false });
  }

  @Action(SubmitPharmacyCommsDetailsAction)
  submitPharmacyCommsDetailsAction({ patchState, getState, dispatch }: StateContext<PharmacyCommsDetailsStateModel>) {
    patchState({ loading: true, error: null });
    const state = getState();
    const requestModel = state.pharmacyCommsDetailsForm.model as PharmacyCommsDetails;
    requestModel.id && requestModel.id ? dispatch(new UpdatePharmacyCommsDetailsAction(requestModel)) : dispatch(new CreatePharmacyCommsDetailsAction(requestModel));
  }
}
