import { MedicationListFormModel } from '~/shared/models/six-cpa/medication-list/medication-list-form.model';
import { MedsCheckFormModel } from '~/shared/models/six-cpa/meds-check-form.model';
import { MedsCheckType } from './meds-check-form-list.state';




export enum MedsCheckFormListActions {
  SET_VALID = '[MedsCheckFormList] Set Valid',
  SUBMIT = '[MedsCheckFormList] Submit',
  RESET = '[MedsCheckFormList] Reset',
  RESET_STATE = '[MedsCheckFormList] Reset State',
  SAVE = '[MedsCheckFormList] Save',
  UPDATE_SAVE = '[MedsCheckFormList] Update Save',
  UPDATE_SUBMIT = '[MedsCheckFormList] Update Submit',
  SAVE_SUCCESS = '[MedsCheckFormList] Save Success',
  SAVE_FAILED = '[MedsCheckFormList] Save Failed',
  SET_MEDS_CHECK_TYPE = '[MedsCheckFormList] Set Meds Check Type',
  GET = '[MedsCheckFormList] Get',
  GET_MEDSCHECK_FORM_SUCCESS = '[MedsCheckFormList] On Get Meds Check Form Success',
  GET_MEDSCHECK_FORM_ERROR = '[MedsCheckFormList] On Get Meds Check Form Error',
  UPDATE_BASIC_MEDSCHECK_FORM = '[MedsCheckFormList] Update Basic Meds Check Child Forms',
  UPDATE_DETAILED_MEDSCHECK_FORM = '[MedsCheckFormList] Update Detailed Meds Check Child Forms',
  OPEN_MEDSCHECK_CONFIRM_FORM = '[MedsCheckFormList] Open Confirm Form',
  CLOSE_MEDSCHECK_CONFIRM_FORM = '[MedsCheckFormList] Close Confirm Form',
  REDIRECT_TO_CLAIM_FORM_AFTER_PATIENT_REGISTRATION = '[MedsCheckFormList] Redirect to claim form',
  SHOW_INVALID_MEDSCHECK_FORM = '[MedsCheckFormList] Show Invalid Form',
  GENERATE_MEDSCHECK_FORM = '[MedsCheckFormList] Generate MedsCheck Form',
  TOGGLE_SAVING = '[MedsCheckFormList] TOGGLE_SAVING',
  OPEN_MEDSCHECK_SEND_DOCUMENT_FORM = "[MedsCheckFormList] Open Send Document Form",
  CLOSE_MEDSCHECK_SEND_DOCUMENT_FORM = "[MedsCheckFormList] Close Send Document Form"
}

export class MedsCheckFormListValidAction {
  static readonly type = MedsCheckFormListActions.SET_VALID;
  constructor(public valid = false) { }
}

export class ToggleMedsCheckSaving {
  static readonly type = MedsCheckFormListActions.TOGGLE_SAVING;
  constructor(public saving: boolean) { }
}

export class SubmitNewMedsCheck {
  static readonly type = MedsCheckFormListActions.SUBMIT;
  constructor(public clientId: string, public formData: MedsCheckFormModel) { }
}

export class SubmitExisitingMedsCheck {
  static readonly type = MedsCheckFormListActions.UPDATE_SUBMIT;
  constructor(public clientId: string, public formData: MedsCheckFormModel) { }
}

export class ResetMedsCheckFormListAction {
  static readonly type = MedsCheckFormListActions.RESET;
  constructor(public onlyClearFormIds: boolean = false) { }
}

export class ResetMedsCheckFormStateToDefaults {
  static readonly type = MedsCheckFormListActions.RESET_STATE;
  constructor(public onlyClearFormIds: boolean = false) { }
}

export class SaveMedsCheckFormListAction {
  static readonly type = MedsCheckFormListActions.SAVE;
  constructor(public clientId: string, public formData: MedsCheckFormModel) { }
}

export class PutUpdateMedsCheckFormListAction {
  static readonly type = MedsCheckFormListActions.UPDATE_SAVE;
  constructor(public clientId: string, public formData: MedsCheckFormModel) { }
}

export class SaveMedsCheckFormSuccessAction {
  static readonly type = MedsCheckFormListActions.SAVE_SUCCESS;
  constructor(public formData: MedsCheckFormModel) { }
}

export class SaveMedsCheckFormFailedAction {
  static readonly type = MedsCheckFormListActions.SAVE_FAILED;
  constructor(public errors: any) { }
}

export class SetMedsCheckTypeAction {
  static readonly type = MedsCheckFormListActions.SET_MEDS_CHECK_TYPE;
  constructor(public medsCheckType: MedsCheckType) { }
}

export class GetMedsCheckForm {
  static readonly type = MedsCheckFormListActions.GET;
  constructor(public clientId: string, public sixCpaFormId: number) { }
}

export class GetMedsCheckFormSuccess {
  static readonly type = MedsCheckFormListActions.GET_MEDSCHECK_FORM_SUCCESS;
  constructor(public medsCheckForm: MedsCheckFormModel) { }
}

export class GetMedsCheckFormError {
  static readonly type = MedsCheckFormListActions.GET_MEDSCHECK_FORM_ERROR;
  constructor(public error: any) { }
}

export class RedirectToClaimFormAfterPatientRegistration {
  static readonly type = MedsCheckFormListActions.REDIRECT_TO_CLAIM_FORM_AFTER_PATIENT_REGISTRATION;
  constructor(public medsCheckForm: MedsCheckFormModel) { }
}

export class UpdateBasicMedsCheckForms {
  static readonly type = MedsCheckFormListActions.UPDATE_BASIC_MEDSCHECK_FORM;
  constructor(public medsCheckForm: MedsCheckFormModel) { }
}

export class UpdateDetailedMedsCheckForms {
  static readonly type = MedsCheckFormListActions.UPDATE_DETAILED_MEDSCHECK_FORM;
  constructor(public medsCheckForm: MedsCheckFormModel) { }
}

export class OpenMedsCheckConfirmFormAction {
  static readonly type = MedsCheckFormListActions.OPEN_MEDSCHECK_CONFIRM_FORM;
  constructor() { }
}

export class CloseMedsCheckConfirmFormAction {
  static readonly type = MedsCheckFormListActions.CLOSE_MEDSCHECK_CONFIRM_FORM;
  constructor() { }
}

export class OpenMedsCheckSendDocumentFormAction {
  static readonly type = MedsCheckFormListActions.OPEN_MEDSCHECK_SEND_DOCUMENT_FORM;
  constructor() { }
}

export class CloseMedsCheckSendDocumentFormAction {
  static readonly type = MedsCheckFormListActions.CLOSE_MEDSCHECK_SEND_DOCUMENT_FORM;
  constructor() { }
}
export class ShowInvalidMedsCheckForm {
  static readonly type = MedsCheckFormListActions.SHOW_INVALID_MEDSCHECK_FORM;
}

export class GenerateMedsCheckForm {
  static readonly type = MedsCheckFormListActions.GENERATE_MEDSCHECK_FORM;
  constructor(public formData: MedsCheckFormModel) { }
}