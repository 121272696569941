import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseApi } from '~/shared/core/base-api/base-api';
import { Router } from '@angular/router';
import { StorageService } from '~/shared/core/storage/storage.service';
import { AlertService } from '~/modules/core/alert/alert.service';
import { Observable } from 'rxjs/Observable';
import { environment } from '../../../../../environments/environment';
import { Timezone } from '../timezone.model';

const API = environment.baseApiUrl;
@Injectable()
export class TimezoneRepository extends BaseApi {
    constructor(public http: HttpClient, public router: Router, public storageService: StorageService, public alertService: AlertService) {
      super(http, router, storageService, alertService);
    }

    getTimezoneId(): Observable<string> {
      return this.get(`${API}/api/tenant/timezone`).map(result => result.id);
    }

    getTimezones(): Observable<Timezone[]> {
      return this.get(`${API}/api/tenant/timezones`);
    }
}
