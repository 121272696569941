<section class="details" g-xs="my3">
  <div class="row detail-container">
    <div class="update-box" *ngIf="loading">
      <div>
        Updating profile...
      </div>
    </div>
    <!-- Patient Details -->
    <div class="col-lg-6 p-4">
      <form [formGroup]="editCustomerForm">
        <div class="divider">
          <!-- Title Row & Buttons -->
          <div class="row">
            <div class="col-7">
              <div class="font__title-4">Customer Details</div>
            </div>
            <div class="col">
              <button (click)="toggleEditMode(false)" class="btn-cancel pointer" *ngIf="isEditMode">
                Cancel
              </button>
            </div>
            <div class="col">
              <button class="btn-edit pointer" (click)="isEditMode ? save() : toggleEditMode(true)">
                <span *ngIf="!isEditMode">Edit </span>
                <span *ngIf="isEditMode">Save </span>
                <i *ngIf="!isEditMode" class="far fa-pencil pointer" aria-hidden="true"></i>
              </button>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <!-- Top Row -->
              <div class="row p-0 mt-2">
                <!-- Patient Type Column -->
                <div class="col">
                  <div class="font__bold">Patient Type</div>
                  <div *ngIf="!isEditMode">{{ customerProfile?.type }}</div>
                  <!-- Edit patient type -->
                  <div class="form-group" *ngIf="isEditMode">
                    <select formControlName="type" class="form-control w-100">
                      <option value="SOF">SOF</option>
                      <option value="DAA">DAA</option>
                      <option value="LEAD">LEAD</option>
                    </select>
                  </div>
                </div>
                <!-- Group Column -->
                <div class="col-3">
                  <div class="font__bold">Group</div>
                  <div *ngIf="!isEditMode">{{ customerProfile?.groupName }}</div>
                  <div *ngIf="!customerProfile?.groupName && !isEditMode">N/A</div>
                  <!-- Edit patient group -->
                  <div class="form-group" *ngIf="isEditMode">
                    <select formControlName="groupId" class="form-control w-100">
                      <option [value]="group.id" *ngFor="let group of customer.groups">{{group.name}}</option>
                    </select>
                  </div>
                </div>
                <!-- Status Column -->
                <div class="col">
                  <div class="font__bold">Status</div>
                  <div class="min-width-auto" *ngIf="!isEditMode" [ngClass]="
                customerProfile?.status === customerStatus.Active
                  ? 'status-label active'
                  : 'status-label inactive'">
                    <i [ngClass]="
                  customerProfile?.status === customerStatus.Active
                    ? 'status bg__green'
                    : ' status bg__red'" class="mr-2">
                    </i>{{ getCustomerStatus(customerProfile?.status) }}
                  </div>
                  <!-- Edit patient status -->
                  <div class="form-group" *ngIf="isEditMode">
                    <select formControlName="status" class="form-control w-100">
                      <option [ngValue]="customerStatus.Active">Active</option>
                      <option [ngValue]="customerStatus.Inactive">Inactive</option>
                      <option [ngValue]="customerStatus.Deceased">Deceased</option>
                      <option [ngValue]="customerStatus.Hospital">Hospital</option>
                      <option [ngValue]="customerStatus.Holiday">Holiday</option>
                      <option [ngValue]="customerStatus.Unknown">Unknown</option>
                    </select>
                  </div>
                </div>
                <!-- MySL Status -->
                <div class="col-4">
                  <div class="font__bold">MySL</div>
                  <ng-container *ngIf="!(isFetchingPatient$ | async) && !(aslPatientLoadFailed$ | async)">
                    <div [ngClass]="
                      {
                        'label__green status-label active': (aslDetails?.status?.isRegistered
                        && aslDetails?.status?.infaStatus === slConsentStates.Active
                        && aslDetails?.status?.icolStatus === slConsentStates.Active),
                        'label__yellow status-label inactive': (aslDetails?.status?.isRegistered
                        && !(aslDetails?.status?.infaStatus === slConsentStates.Active
                          && aslDetails?.status?.icolStatus === slConsentStates.Active)
                        && !(aslDetails?.status?.infaStatus === slConsentStates.Rejected
                          && aslDetails?.status?.icolStatus === slConsentStates.Rejected)),
                        'label__red status-label inactive': (aslDetails?.status?.isRegistered
                        && aslDetails?.status?.infaStatus === slConsentStates.Rejected
                        && aslDetails?.status?.icolStatus === slConsentStates.Rejected)
                      }
                    ">
                      <i [ngClass]="
                      {
                        'bg__green': (aslDetails?.status?.isRegistered
                        && aslDetails?.status?.infaStatus === slConsentStates.Active
                        && aslDetails?.status?.icolStatus === slConsentStates.Active),
                        'bg__yellow': (aslDetails?.status?.isRegistered
                        && !(aslDetails?.status?.infaStatus === slConsentStates.Active
                          && aslDetails?.status?.icolStatus === slConsentStates.Active)
                        && !(aslDetails?.status?.infaStatus === slConsentStates.Rejected
                          && aslDetails?.status?.icolStatus === slConsentStates.Rejected)),
                        'bg__gray': (!aslDetails?.status?.isRegistered),
                        'bg__red': (aslDetails?.status?.isRegistered
                        && aslDetails?.status?.infaStatus === slConsentStates.Rejected
                        && aslDetails?.status?.icolStatus === slConsentStates.Rejected)
                      }
                    " class="mr-2 status"></i>{{ getASLStatus(aslDetails?.status) }}
                    </div>
                    <div>
                      <a (click)="openMySLDetails()" class="view-concession">
                        View details
                      </a>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="!(isFetchingPatient$ | async) && (aslPatientLoadFailed$ | async)">
                    <div class="status-label label__red">
                      <i class="mr-2 status bg__red"></i>Failed Load
                    </div>
                  </ng-container>
                  <div style="width: 10px" *ngIf="isFetchingPatient$ | async">
                    <app-loader></app-loader>
                  </div>
                </div>
              </div>
              <!-- Bottom Row -->
              <div class="row p-0 mt-2">
                <div class="col">
                  <div class="font__bold">Gender</div>
                  <div>{{ patient.gender }}</div>
                </div>
                <div class="col-3">
                  <div class="font__bold">Date Of Birth</div>
                  <div>
                    {{ patient.birthDate ? (patient.birthDate | tenantDate) : "N/A" }}
                  </div>
                  <div class="sub-info greywash" *ngIf="patient.birthDate">({{getAge(patient.birthDate)}} years old)
                  </div>
                </div>
                <div class="col">
                  <div class="font__bold">Concessions</div>
                  <div *ngIf="showConcessions">
                    Medicare: {{ patient.medicareNo }}<br />
                    Repat: {{ patient.repatNo }}<br />
                    Concession: {{ patient.concessionNo }}<br />
                    SafetyNet: {{ patient.safetyNetEntitlementNo }}<br />
                    NDSS: {{ patient.nDSSNumber }}
                  </div>
                  <a (click)="toggleConcessions()" class="view-concession">
                    {{ showConcessions ? 'View less' : 'View all' }}
                  </a>
                </div>
                <div class="col-4">
                  <div *ngIf="(lastVisitDate$ | async) as lastVisitDate">
                    <div *ngIf="lastVisitDate" class="font__bold">
                      Last dispense date
                    </div>
                    <div>
                      <span>
                        {{lastVisitDate | tenantDate}}
                      </span>
                    </div>
                    <div class="sub-info">
                      <span [ngSwitch]="daysAgo(lastVisitDate)" [class]="getLastDispensedClass(lastVisitDate)">
                        <span *ngSwitchCase="0">(Today)</span>
                        <span class="" *ngSwitchCase="1">(Yesterday)</span>
                        <span class="" *ngSwitchDefault>({{daysAgo(lastVisitDate)}} days ago)</span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Additonal DAA Row -->
              <div
                *ngIf="(customerProfile?.type == customerType.Daa && !isEditMode) || (editCustomerForm.value.type == customerType.Daa && isEditMode)"
                class="row p-0 mt-3">
                <!-- DAA Date Column -->
                <div [ngClass]="isEditMode ? 'col-4': 'col'">
                  <div class="font__bold">DAA start date</div>
                  <span *ngIf="!isEditMode">{{customerProfile?.daaCommencementDate | tenantDate}}</span>
                  <!-- Edit DAA commencement date -->
                  <div class="form-group" *ngIf="isEditMode">
                    <input formControlName="dAACommencementDate" type="text" placeholder="DD-MM-YYYY"
                      class="form-control border__grey-light snooze-date" [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY' }"
                      name="commencementDate" bsDatepicker>
                  </div>
                </div>
                <!-- Indigenous Status Column -->
                <div *ngIf="customerProfile?.indigenousStatus != null" class="col-3">
                  <div class="font__bold">ATSI Status</div>
                  <div *ngIf="!isEditMode" [ngClass]="
                customerProfile?.indigenousStatus === 1
                  ? 'status-label active'
                  : 'status-label inactive'">
                    <i [ngClass]="
                  customerProfile?.indigenousStatus === 1
                    ? 'status bg__green'
                    : 'status bg__red'" class="mr-2">
                    </i>
                    {{ indigenousStatus[customerProfile?.indigenousStatus]}}
                  </div>
                  <!-- Edit status -->
                  <div *ngIf="isEditMode">
                    <select formControlName="indigenousStatus" class="form-control w-100">
                      <option [value]="indigenousStatus.Yes">Yes </option>
                      <option [value]="indigenousStatus.No">No</option>
                      <option [value]="indigenousStatus.Unsure">Unsure</option>
                    </select>
                  </div>
                </div>
                <div *ngIf="!isEditMode" class="col">
                  <!-- width placeholder -->
                </div>
                <div class="col-4">
                  <!-- width placeholder -->
                </div>
              </div>
            </div>

          </div>
        </div>
      </form>
    </div>

    <!-- Contact & Comms -->
    <div class="col-lg-6 p-4">
      <!-- Title row & button -->
      <div class="row">
        <div class="col">
          <div class="font__title-4">Contact and Communication Details</div>
        </div>
        <div class="col-2">
          <button (click)="editClick()" class="btn-edit pointer">
            Edit <i class="far fa-pencil pointer" aria-hidden="true"></i>
          </button>
        </div>
      </div>
      <!-- Top Row -->
      <div class="row p-0 mt-2">
        <!-- Contact Details Column -->
        <div class="col-7">
          <div class="font__bold">Contact Details</div>
          <div class="ml-2">
            <!-- Mobile -->
            <div *ngIf="patient.mobileNumber">
              <div class="icon-container">
                <i class="far fa-mobile mr-2"></i>
              </div>
              {{ patient.mobileNumber }}
              <!-- Primary Contact SMS-->
              <ng-container *ngIf="(primaryContactState$ | async) as primaryContactState">
                <ng-container
                  *ngIf="primaryContactState.stateStatus == primaryContactStateStatus.Finalised && primaryContactState.hasLinkedContacts">
                  <span (click)="toggleInfoBox(infoBoxTypes.SMS)">
                    <span *ngIf="primaryContactState.summary.smsPrimaryContact"> <i
                        class="fas fa-check-circle icon_success pointer"></i></span>
                    <span *ngIf="!primaryContactState.summary.smsPrimaryContact"> <i class="info-icon fas fa-info"></i>
                    </span>
                  </span>
                  <!-- SMS Contact Info Box -->
                  <div class="info-box" *ngIf="showSMSContactInfoBox">
                    <div class="info-box-text">
                      <span *ngIf="primaryContactState.smsPrimaryContact">
                        {{getSMSPrimaryName(primaryContactState.summary)}} is the primary contact for this mobile
                        number.
                      </span>
                      <span *ngIf="!primaryContactState.smsPrimaryContact">
                        <span *ngIf="hasPrimarySMSContact(primaryContactState.summary) else defaultPCMessage">
                          {{getSMSPrimaryName(primaryContactState.summary)}} is the primary contact for this mobile
                          number.
                        </span>
                      </span>
                    </div>
                  </div>
                </ng-container>
              </ng-container>

              <!-- Is Mobile opted out -->
              <ng-container *ngIf="(isOptOut$ | async) as isOptOut">
                <ng-container *ngIf="isOptOut" class="font__bold">
                  <span class="lbl-optout">Opted Out</span>
                </ng-container>
              </ng-container>
            </div>
            
             <!--Home Phone-->
             <div *ngIf="patient.phoneNumber">
              <div class="icon-container">
                <i class="far fa-home mr-2"></i>
              </div>
              {{ patient.phoneNumber }}
            </div>

            <!-- Email -->
            <div *ngIf="patient.emailAddress">
              <div class="icon-container">
                <i class="far fa-envelope mr-2"></i>
              </div>
              {{ patient.emailAddress }}
              <!-- primary Contact Email -->
              <ng-container *ngIf="(primaryContactState$ | async) as primaryContactState">
                <ng-container
                  *ngIf="primaryContactState.stateStatus == primaryContactStateStatus.Finalised && primaryContactState.hasLinkedContacts">
                  <span (click)="toggleInfoBox(infoBoxTypes.Email)">
                    <span *ngIf="primaryContactState.summary.emailPrimaryContact"> <i
                        class="fas fa-check-circle icon_success pointer"></i></span>
                    <span *ngIf="!primaryContactState.summary.emailPrimaryContact"> <i
                        class="info-icon fas fa-info"></i>
                    </span>
                  </span>
                  <!-- Email Contact Info Box -->
                  <div class="info-box" *ngIf="showEmailContactInfoBox">
                    <div class="info-box-text">
                      <span *ngIf="primaryContactState.emailPrimaryContact">
                        {{getEmailPrimaryName(primaryContactState.summary)}} is the primary contact for this email
                        address.
                      </span>
                      <span *ngIf="!primaryContactState.emailPrimaryContact">
                        <span *ngIf="hasPrimaryEmailContact(primaryContactState.summary) else defaultPCMessage">
                          {{getEmailPrimaryName(primaryContactState.summary)}} is the primary contact for this email
                          address.
                        </span>
                      </span>
                    </div>
                  </div>
                </ng-container>
              </ng-container>
            </div>
          </div>

          <!-- Primary Contact -->
          <ng-container>
            <div class="ml-2">
              <span>
                <span>
                  <span (click)="toggleManagePrimaryContact()" class="view-concession">
                    Manage Contact
                  </span>
                </span>
              </span>
            </div>
          </ng-container>
        </div>
        <div class="col-5">
          <div class="font__bold">Home Address</div>
          <div style="line-height: 16px;">
            {{ patient.homeAddress ? patient.homeAddress + ', ' : '' }}{{ patient.homeSuburb ? patient.homeSuburb + ', '
            : '' }}
            {{ patient.homeState ? patient.homeState + ', ' : '' }}{{ patient.homePostcode ? patient.homePostcode : ''
            }}
          </div>
        </div>
      </div>
      <!-- Bottom Row -->
      <div class="row p-0 mt-2">
        <div class="col">
          <div class="font__bold text-nowrap">Scrypt Reminders</div>
          <div [ngClass]="autoComms ? 'status-label active': 'status-label inactive'">
            <i [ngClass]="
                  autoComms ? 'status bg__green' : 'status bg__red'
                " class="mr-2"></i>{{ autoComms ? 'Active' : 'Inactive' }}
          </div>
        </div>
        <div class="col">
          <div class="font__bold text-nowrap">
            Mobile Status
            <i (click)="editMobileStatus(customerProfile)" class="info-icon fas fa-info"></i>
          </div>
          <div [ngClass]="customerProfile?.mobileStatus == 2 ? 'status-label active': 'status-label inactive'">
            <i [ngClass]="{
              'bg__green' : customerProfile?.mobileStatus == 2,
              'bg__red' : customerProfile?.mobileStatus == 0,
              'bg__yellow' : customerProfile?.mobileStatus == 1}" class="status mr-2">
            </i>
            {{getMobileStatus(customerProfile?.mobileStatus)}}
          </div>
        </div>
        <!-- Order cycle Column -->
        <div class="col">
          <div class="font__bold">
            <span>Order Cycle
              <i *ngIf="customerProfile?.orderCycleId" (click)="toggleInfoBox(infoBoxTypes.OrderCycle)"
                class="info-icon fas fa-info">
              </i>
            </span>
          </div>
          <div [ngClass]="customerProfile?.orderCycleId ? 'status-label active': 'status-label inactive'">
            <i [ngClass]="
                  customerProfile?.orderCycleId
                    ? 'status bg__green'
                    : 'status bg__red'
                " class="mr-2"></i>{{ customerProfile?.orderCycleId ? 'Active' : 'Inactive' }}
          </div>
          <!-- Order Cycle Info Box -->
          <div class="info-box" *ngIf="showOrderCycle">
            <div class="info-box-text">
              <div class="row">
                <div class="col-md-5">
                  Next Date
                </div>
                <div class="col-md-7">
                  {{customerProfile?.nextDate | tenantDate}}
                </div>
              </div>
              <div class="row">
                <div class="col-md-5">
                  Interval
                </div>
                <div class="col-md-7">
                  {{customerProfile?.intervalInDays}} day(s)
                </div>
              </div>
              <div class="row">
                <div class="col-md-5">
                  Schedule
                </div>
                <div class="col-md-7">
                  Every {{customerProfile?.intervalInDays}} day(s) starting {{customerProfile?.startDate | tenantDate}}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col" *ngIf="customerProfile?.orderMethod != null">
          <div class="font__bold">Delivery Preference</div>
          <div>{{ orderMethod[customerProfile?.orderMethod] | titlecase }}</div>
        </div>
        <div class="col">
          <div class="font__bold">Preference</div>
          <div>{{ contactMethod.toDisplayName(customerProfile.contactMethod) | titlecase }}</div>
        </div>
      </div>
    </div>
  </div>
</section>
<ng-container *ngIf="(primaryContactState$ | async) as primaryContactState">
  <app-primary-contact *ngIf="primaryContactState.showModal"></app-primary-contact>
</ng-container>
<ng-container *ngIf="(showModal$ | async) as showModal">
  <div *ngIf="showModal">
    <app-mobile-state-modal></app-mobile-state-modal>
  </div>
</ng-container>
<ng-template #defaultPCMessage>
  <span>Matches found for this mobile number/email, <strong class="view-concession"
      (click)="toggleManagePrimaryContact()">manage primary
      contact</strong> to view details</span>
</ng-template>