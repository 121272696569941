import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScriptHistoryComponent } from './script-history.component';
import { TimezoneModule } from '../../../core/timezone/timezone.module';
import { NgxsModule } from '@ngxs/store';
import { ScriptHistoryState } from './state/script-history.state';
import { UiSwitchModule } from 'ngx-ui-switch';
import { ScriptHistoryService } from './services/script-history.service';
import { OrderByOriginalScriptPipe } from './pipes/order-by-original-sript.pipe';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OriginalScriptComponent } from './original-script/original-script.component';
import { LoaderModule } from '../../../core/loader/loader.module';

@NgModule({
  declarations: [
    ScriptHistoryComponent,
    OrderByOriginalScriptPipe,
    OriginalScriptComponent
  ],
  imports: [
    CommonModule,
    TimezoneModule,
    NgxsModule.forFeature([ScriptHistoryState]),
    UiSwitchModule,
    FormsModule,
    ReactiveFormsModule,
    LoaderModule
  ],
  exports: [
    ScriptHistoryComponent
  ],
  providers: [
    ScriptHistoryService
  ]
})
export class ScriptHistoryModule { }
