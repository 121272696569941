import { Category } from '../../../modules/profile/tenant-categories/category.model';
import { CategoryRepository } from '../../../modules/profile/tenant-categories/category.repository';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';

@Component({
  selector: 'app-category-item',
  templateUrl: './category-item.component.html',
  styleUrls: ['./category-item.component.scss']
})
export class CategoryItemComponent implements OnInit, OnDestroy {

  public isLoaded = false;
  public categoryForm;
  public category: Category;
  private categoryId: number;
  private mode = 'create';
  private updateSubscription: Subscription;
  private createSubscription: Subscription;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private categoryRepository: CategoryRepository
  ) {
    this.categoryForm = this.formBuilder.group({
      name: new FormControl('', Validators.required),
      friendlyName: new FormControl('', Validators.required),
      dispenseBuffer: new FormControl('', Validators.required),
      targetTotalDoh: new FormControl('', Validators.required),
      position: new FormControl('', Validators.required)
    });
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => this.categoryId = params.id);

    if (this.categoryId) {
      this.categoryRepository.getCategory(this.categoryId).subscribe({
        next: (response: Category) => {
          this.category = response;
          this.categoryForm.patchValue(response);
        },
        complete: () => {
          this.finishLoad();
        }
      });

      this.mode = 'update';
    } else {
      this.finishLoad();
    }
  }

  public onSubmit(values: Category) {
    if (this.mode === 'update') {
      this.updateItem(values);
    } else {
      this.addItem(values);
    }
  }

  private startLoad(): void {
    this.isLoaded = false;
  }

  private finishLoad(): void {
    this.isLoaded = true;
  }

  private addItem(data: Category) {
    this.startLoad();
    this.createSubscription = this.categoryRepository.createCategory(data)
      .subscribe({
        next: (response: any) => {
          this.router.navigate(['/config/category']);
        },
        complete: () => {
          this.finishLoad();
        }
      });
  }

  private updateItem(data: Category) {
    data.id = this.categoryId;
    this.startLoad();
    this.updateSubscription = this.categoryRepository.updateCategory(data).subscribe({
      next: (response: any) => {
        this.router.navigate(['/config/category']);
      },
      complete: () => {
        this.finishLoad();
      }
    });
  }

  ngOnDestroy(): void {
    if (this.updateSubscription) {
      this.updateSubscription.unsubscribe();
    }
    if (this.createSubscription) {
      this.createSubscription.unsubscribe();
    }
  }

}
