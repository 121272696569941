import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommTemplatesListComponent } from './comm-templates-list.component';
import { UiSwitchModule } from 'ngx-ui-switch';
import { FluidModalModule } from '../../../../modules/core/fluid-modal/fluid-modal.module';

@NgModule({
  declarations: [CommTemplatesListComponent],
  imports: [
    CommonModule,
    UiSwitchModule,
    FluidModalModule
  ],
  exports: [CommTemplatesListComponent]
})
export class CommTemplatesListModule { }
