import { State, Action, StateContext, Selector, Store } from '@ngxs/store';
import {
  GetDocumentsFromService,
  DownloadDocumentFromService,
  AuthDocumentRequest,
  AuthSetEncryptedDocumentId,
  AuthResponseAction,
  AuthErrorAction,
} from './document-services.actions';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { DocumentsService } from '../../../shared/services/documents.service';
import { saveAs } from 'file-saver';
import { ProfileState } from '../../core/profile/state/profile.state';
import * as moment from 'moment';
import { WindowService } from '../../../shared/services/window.service';
import { AuthState } from '../../core/auth/state/auth.state';

export class DocumentServicesStateModel {
  public documentsList: DocumentResponse[];
  public encryptedDocumentId: string;
  public isAuth: boolean;
  public loading: boolean;
  public error: any;
}

export enum DocumentOrigin {
  General,
  Vaccination,
  SevenCPA,
  Consent,
}

export class AuthDocument {
  encryptedDocumentId: string;
  encryptedTenantCustomerId: string;
  DOB: Date;
}

export class DocumentResponse {
  id: any;
  created: Date;
  documentName: string;
  fileType: string;
  notes: string;
  documentOrigin: DocumentOrigin;
  tag: string;
}

@Injectable()
@State<DocumentServicesStateModel>({
  name: 'documentServices',
  defaults: {
    documentsList: [],
    isAuth: false,
    encryptedDocumentId: '',
    loading: false,
    error: null,
  },
})
export class DocumentServicesState {
  constructor(
    private store: Store,
    private windowService: WindowService,
    private documentsService: DocumentsService
  ) {}

  @Selector()
  static documents(state: DocumentServicesStateModel) {
    return state.documentsList;
  }

  @Selector()
  static loading(state: DocumentServicesStateModel) {
    return state.loading;
  }

  @Selector()
  static isAuth(state: DocumentServicesStateModel) {
    return state.isAuth;
  }

  @Action(AuthSetEncryptedDocumentId)
  setEncryptedDocument(
    ctx: StateContext<DocumentServicesStateModel>,
    action: AuthSetEncryptedDocumentId
  ) {
    const { encryptedDocumentId } = action;
    ctx.patchState({ encryptedDocumentId });
  }

  @Action(AuthDocumentRequest)
  authDocumentRequest(
    ctx: StateContext<DocumentServicesStateModel>,
    action: AuthDocumentRequest
  ) {
    const encryptedTenantCustomerId = this.store.selectSnapshot(
      AuthState.encryptedTenantCustomerId
    );
    const state = ctx.getState();
    const auth = {
      encryptedDocumentId: state.encryptedDocumentId,
      encryptedTenantCustomerId: encryptedTenantCustomerId,
      DOB: action.DOB,
    } as AuthDocument;

    ctx.patchState({ loading: true, error: null });
    return this.documentsService.authDocumentLink(auth).pipe(
      switchMap((res) => ctx.dispatch(new AuthResponseAction(res))),
      catchError((error) => ctx.dispatch(new AuthErrorAction(error)))
    );
  }

  @Action(AuthResponseAction)
  handleResponse(
    ctx: StateContext<DocumentServicesStateModel>,
    { response }: AuthResponseAction
  ) {
    ctx.patchState({ isAuth: true });
    window.location.href = response.link;
  }

  @Action(AuthErrorAction)
  handleError(
    ctx: StateContext<DocumentServicesStateModel>,
    action: AuthErrorAction
  ) {
    ctx.patchState({ error: action.error });
  }

  @Action([AuthResponseAction, AuthErrorAction])
  stopLoading(ctx: StateContext<DocumentServicesStateModel>) {
    ctx.patchState({ loading: false });
  }

  @Action(DownloadDocumentFromService)
  downloadDocument(
    ctx: StateContext<DocumentServicesStateModel>,
    action: DownloadDocumentFromService
  ) {
    const profile = this.store.selectSnapshot(ProfileState.profile);
    const fileName = `document - ${profile.patient.firstname} ${
      profile.patient.surname
    } - ${moment(profile.patient.birthDate, 'YYYY-MM-DD').format(
      'DD-MM-YYYY'
    )}`;
    return this.documentsService.getPatientDocument(action.id).pipe(
      tap((response) => {
        saveAs(response, fileName);
        this.openInNewTab(response);
      })
    );
  }

  private openInNewTab(response: any) {
    const fileURL = URL.createObjectURL(response);
    this.windowService.open(fileURL);
  }

  @Action(GetDocumentsFromService)
  getAllDocuments(ctx: StateContext<DocumentServicesStateModel>) {
    const clientId = this.store.selectSnapshot(ProfileState.clientId);
    return this.documentsService
    .getDocumentsForPatient(clientId)
    .pipe(
      tap((response) => {
        ctx.patchState({
          documentsList: response as DocumentResponse[],
        });
      })
    );
  }
}
