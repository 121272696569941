<section>
  <div class="header header__desktop">
    <div class="grid header__block flex" g-xs="py2">
      <div class="flex-xxl-grow-1 d-flex align-items-center">
        <div>
          <a routerLink="/dashboard">
            <img src="https://scrypt.blob.core.windows.net/scrypt-assets/logo-white.svg" class="logo"/>
          </a>
        </div>

        <div class="header__block__nav flex ml-5">
          <ul class="nav flex color__blue-text">
            <li class="px-3"><a routerLink="/dashboard" class="nav__button"><i class="fas fa-chart-pie"
                  g-xs="mr1"></i>Dashboard</a>
                  <span class="active" *ngIf="isActiveUrl('/dashboard')"></span>
            </li>
            <li class="px-3">
              <a routerLink="/communications" class="nav__button">
                <nz-badge *ngIf="(unread$ | async) as total; else noUnread" [nzCount]="total" [nzOverflowCount]="99" class="nav__button">
                  <i class="far fa-comment-alt-lines"
                g-xs="mr1"></i>Communications
                </nz-badge>
                <ng-template #noUnread>
                  <i class="far fa-comment-alt-lines" g-xs="mr1"></i>Communications
                </ng-template>
              </a>
              <span class="active" *ngIf="isActiveUrl('/communications')"></span>
            </li>
            <li *ngIf="(enableOrders$ | async)" class="px-3">
              <nz-badge [nzCount]="pendingOrders$ | async" [nzOverflowCount]="99">
                <a routerLink="/manage/orders" class="nav__button">
                  <i class="far fa-shopping-cart" g-xs="mr1"></i>Orders
                </a>
              </nz-badge>
              <span class="active" *ngIf="isActiveUrl('/manage/orders')"></span>
            </li>
            <li class="px-3">
              <a routerLink="/crm" class="nav__button">
                <nz-badge *ngIf="(awaitingTotal$ | async) as total; else noAwaiting" [nzCount]="total" [nzOverflowCount]="99" class="nav__button">
                  <i class="far fa-users" g-xs="mr1"></i>Tasks
                </nz-badge>
                <ng-template #noAwaiting>
                  <i class="far fa-users"g-xs="mr1"></i>Tasks
                </ng-template>
                <i *ngIf="(newNotifications | async) > 0" class="far fa-envelope color__red" g-xs="ml1"></i>
              </a>
              <span class="active" *ngIf="isActiveUrl('/crm')"></span>
            </li>
            <li class="px-3">
              <a *ngIf="tenantCustomerCanDispense" (click)="toggleEScript()"
                class="nav__button pointer">
                <i class="fas fa-file-prescription" g-xs="mr1"></i>eScript
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div class="flex-xxl-grow-1 d-flex align-items-center justify-content-end">
        <form class="d-flex flex-align align-items-center search__block pr-3 mr-4" (submit)="navigate()">
          <span class="px-3" (click)="navigate()">
            <i class="far fa-search"></i>
          </span>
          <input (keyup)="setSearch($event)" (blur)="onSearchFocusOut()" type="text"
            [ngClass]="{'red-border': isSearchBorderRed}" class="pl-0 search border__yellow color__dark-blue"
            g-xs="pr4 py2" placeholder="Search Client">
          <a routerLink="/advanced-search" class="font-regular">
          Advanced
          </a>
        </form>

        <div class="text-right">
          <ul class="nav nav__right color__blue-text">
            <li g-xs="px2">
              <div class="btn-group" dropdown>
                <a class="dropdown-toggle nav__button font-regular" dropdownToggle>More</a>
                <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu dropdown-more" role="menu" aria-labelledby="button-basic">
                  <li role="menuitem"><a class="dropdown-item" routerLink="/config/users">Users</a></li>
                  <li role="menuitem"><a class="dropdown-item" routerLink="/config/groups"> Patient Groups</a></li>
                  <li role="menuitem"><a class="dropdown-item" routerLink="/config/category">Drug Categories</a></li>
                  <li role="menuitem"><a class="dropdown-item" routerLink="/config/pharmacy">Settings</a></li>
                  <li role="menuitem"><a class="dropdown-item" routerLink="/dispense-run">Dispense Run</a></li>
                  <ng-container *ngIf="(location$ | async)?.stripeApiKey != null">
                    <li role="menuitem"><a class="dropdown-item" routerLink="/invoices">Invoices</a></li>
                  </ng-container>
                  <li role="menuitem"><a class="dropdown-item" href="/report/request">Script Requests</a></li>
                  <li role="menuitem"><a class="dropdown-item" routerLink="/config/export">Reports</a></li>
                  <li role="menuitem"><a class="dropdown-item" [href]="SCRYPT_NOTIFY">Scrypt Notify</a></li>
                  <li role="menuitem"><a class="dropdown-item" [href]="HELP_LINK" target=_blank>Help</a></li>
                </ul>
              </div>
            </li>
            <li g-xs="pl2"><a (click)="logout()" class="nav__button font-regular">Logout <i class="fal fa-sign-out" g-xs="ml1"></i></a></li>
          </ul>
        </div>

      </div>
    </div>
  </div>
  <div class="header border__grey header__mobile">
    <div class="header__block" g-xs="p3">
      <div class="flex" style="justify-content: flex-end;">
        <ul class="nav flex color__blue-text float-right">
          <li g-xs="pl1"><a (click)="logout()">Logout <i class="fal fa-sign-out" g-xs="ml1"></i></a></li>
        </ul>
      </div>
      <div class="flex header__block__nav">
        <a href="/"> <img src="/assets/img/logo.png" /></a>
      </div>
    </div>
  </div>
</section>
<app-e-script-modal [tenantCustomerCanDispense]="tenantCustomerCanDispense"></app-e-script-modal>
