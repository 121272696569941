import { Component, OnInit } from '@angular/core';
import { DAAChildForm } from '../daa-child-form';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { Store, Actions } from '@ngxs/store';
import { DAAAdditionalDetailsFormState } from './state/daa-additional-details-form.state';
import { Subscription } from 'rxjs';
import { SetTotalItemsAndCompletedItems } from './state/daa-additional-details-form.actions';
import { DAAReferralSource, DAACollectionSource, DAAPackageTypes } from '../../../../../shared/models/six-cpa/DAA/daa-additional-details-form.model';
import { SixCpaState } from '../../state/six-cpa.state';


@Component({
  selector: 'app-daa-additional-details-form',
  templateUrl: './daa-additional-details-form.component.html',
  styleUrls: ['./daa-additional-details-form.component.scss']
})
export class DaaAdditionalDetailsFormComponent extends DAAChildForm implements OnInit {
  daaAdditionalDetailsForm: FormGroup;
  formSubscription: Subscription;
  daaReferralSource = DAAReferralSource;
  daaCollectionSource = DAACollectionSource;
  daaPackageTypes = DAAPackageTypes;
  private readonlySubscription: Subscription;
  constructor(private formBuilder: FormBuilder, private store: Store, actions: Actions) {
    super(actions);
    this.generateFormControls();
  }

  ngOnInit() {
    this.formSubscription = this.store.select(DAAAdditionalDetailsFormState.daaAdditionalDetailsForm)
      .subscribe(() => this.onFormChange());
    this.readonlySubscription = this.store.select(SixCpaState.formsReadOnly)
      .subscribe(readonly => readonly ? this.daaAdditionalDetailsForm.disable() : this.daaAdditionalDetailsForm.enable());
  }

  ngOnDestroy() {
    this.formSubscription.unsubscribe();
    this.readonlySubscription.unsubscribe();
  }

  get formControls() {
    return this.daaAdditionalDetailsForm.controls;
  }
  protected actionOnResetForm() {
    this.daaAdditionalDetailsForm.reset();
    this.daaAdditionalDetailsForm.patchValue(
      {
        daaReferralSource: this.daaReferralSource.HMRManagementPlan,
        daaCollectionSource: this.daaCollectionSource.CollectedFromPharmacy,
        typeOfDAAPackedByPharmacy: this.daaPackageTypes.CompartmentalisedBoxes,
        datePlanWasMade: new Date(),
        dateOfInitialContact: new Date()
      });
  }

  private onFormChange() {
    const controls = Object.keys(this.daaAdditionalDetailsForm.controls);
    const completedItems = this.getTotalValidItems(controls);
    this.store.dispatch(new SetTotalItemsAndCompletedItems(controls.length, completedItems));
  }

  private getTotalValidItems(controls: Array<string>) {
    return controls.filter((key) => {
      const formControl = this.daaAdditionalDetailsForm.get(key);
      return  formControl.status === 'VALID' || (formControl.status === 'DISABLED' && !formControl.invalid && formControl.errors === null);
    }).length;
  }

  private generateFormControls() {
    this.daaAdditionalDetailsForm = this.formBuilder.group({
      daaReferralSource: new FormControl(this.daaReferralSource.Pharmacy, Validators.required),
      datePlanWasMade: new FormControl(new Date(), Validators.required),
      dateOfInitialContact: new FormControl(new Date(), Validators.required),
      daaCollectionSource: new FormControl(this.daaCollectionSource.CollectedFromPharmacy, Validators.required),
      typeOfDAAPackedByPharmacy: new FormControl(this.daaPackageTypes.CompartmentalisedBoxes, Validators.required)
    });
  }
}
