import { AdminDashboardComponent } from "./admin-dashboard.component";
import { NgModule } from "@angular/core";
import { NgSelectModule } from "@ng-select/ng-select";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { LoaderModule } from "~/modules/core/loader/loader.module";
import { TimezoneModule } from "~/modules/core/timezone/timezone.module";
import { NewPharmacyFormModule } from "../new-pharmacy-form/new-pharmacy-form.module";
import { NgxsModule } from "@ngxs/store";
import { AdminDashboardState } from "../state/admin-dashboard.state";
import { AdminDashboardService } from "../services/admin-dashboard.service";
import { AlertService } from "~/modules/core/alert/alert.service";
import { TenantInstallReportModule } from "../tenant-install-report/tenant-install-report.module";
import { GlobalTemplateModule } from "../global-template/global-template.module";


@NgModule({
  declarations: [
    AdminDashboardComponent,
  ],
  imports: [
    NgSelectModule,
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
    LoaderModule,
    TimezoneModule,
    NewPharmacyFormModule,
    TenantInstallReportModule,
    GlobalTemplateModule,
    NgxsModule.forFeature([AdminDashboardState])
  ],
  exports: [
    AdminDashboardComponent
  ],
  providers: [
    AdminDashboardService,
    AlertService
  ]
})
export class AdminDashboardModule { }
