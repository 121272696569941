import { Pipe, PipeTransform } from '@angular/core';
import { StockStartingPointModel } from '../../../../../shared/models/script/meta-view-model';

@Pipe({
  name: 'orderByActiveSSP'
})
export class ActiveStockStartingPointPipe implements PipeTransform {
  transform(ssps: StockStartingPointModel[]): StockStartingPointModel[] {
    if (!ssps) {
      return ssps;
    }

    ssps = ssps.sort((x, y) => {
      return   (y.active as any) - (x.active as any);
    });
    return ssps;
  }
}
