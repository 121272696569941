import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthComponent } from './auth.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { LoaderModule } from '../../core/loader/loader.module';
import { DocumentsService } from '../../../shared/services/documents.service';
import { NgxsModule } from '@ngxs/store';
import { DocumentServicesState } from '../state/document-services.state';

@NgModule({
  declarations: [AuthComponent],
  providers: [DocumentsService],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    BsDatepickerModule.forRoot(),
    NgxsModule.forFeature([DocumentServicesState]),
    LoaderModule,
  ],
  exports: [AuthComponent],
})
export class AuthModule {}
