import { InviteType } from '~/shared/models/profile/inviteType.enum';
import { MobileUserView } from '~/shared/models/profile/mobile-user-view.model';
import { ClientViewModel } from '../client.model';

export enum ProfileActionTypes {
  GET = '[Profile] Get',
  SET = '[Profile] Set',
  ERROR = '[Profile] Error',
  POPUP_VISIBLE = '[Profile] Popup visible',
  Z_INDEX_COUNTER = '[Profile] Z-index counter',
  ALERT_VISIBLE = '[Profile] Alert visible',
  CLIENTID = '[Profile] Client Id',
  PROFILE_UPDATE_MEDS_CHECK_ELIGIBILITY = '[Profile] Update Meds Check Eligibility',
  SET_MEDS_LIST_STATES = '[Profile] Set medication list for all sixcpa states',
  CHECK_CUSTOMER_MOBILE_APP_STATUS = '[Profile] Check customer mobile app status',
  CHECK_CUSTOMER_MOBILE_APP_STATUS_SUCCESS = '[Profile] Check customer mobile app status success',
  SET_LAST_VISIT_DATE = '[Profile] SET_LAST_VISIT_DATE',
  GET_MOBILE_USER_PROFILE = '[Profile] GET_MOBILE_USER_PROFILE',
  SET_MOBILE_USER_PROFILE = '[Profile] SET_MOBILE_USER_PROFILE',
  ONBOARD_CUSTOMER = '[Profile] onboard customer',
  ONBOARD_CUSTOMER_SUCCESS = '[Profile] onboard customer success',
  GET_OPT_OUT_LOOKUP = '[Profile] GET_OPT_OUT_LOOKUP',
}

export class SetClientIdAction {
  static readonly type = ProfileActionTypes.CLIENTID;
  constructor(public clientId: string) { }
}

export interface InviteToMobile {
  inviteType: InviteType;
  email: string;
  mobile: string;
}

export class GetProfileAction {
  static readonly type = ProfileActionTypes.GET;
  constructor(public profileId: string, public inviteToMobile?: InviteToMobile) { }
}

export class SetProfileAction {
  static readonly type = ProfileActionTypes.SET;
  constructor(public profile: ClientViewModel, public tenantCustomerId: number, public categoryOptions: any[], public inviteToMobile?: InviteToMobile) { }
}

export class OnboardCustomer {
  static readonly type = ProfileActionTypes.ONBOARD_CUSTOMER;
  constructor(public id: string, public mobileNumber: string, public email: string, public name: string) { }
}

export class OnboardCustomerSuccess {
  static readonly type = ProfileActionTypes.ONBOARD_CUSTOMER_SUCCESS;
  constructor(public id: string) { }
}

export class ProfileErrorAction {
  static readonly type = ProfileActionTypes.ERROR;
  constructor(public error: any) { }
}

export class ProfilePopupVisibleAction {
  static readonly type = ProfileActionTypes.POPUP_VISIBLE;
  constructor(public visible: boolean) { }
}

export class ProfileZIndexCounterAction {
  static readonly type = ProfileActionTypes.Z_INDEX_COUNTER;
  constructor(public zIndexCounter: number) { }
}

export class ProfileAlertVisibleAction {
  static readonly type = ProfileActionTypes.ALERT_VISIBLE;
  constructor(public visible: boolean) { }
}

export class ProfileUpdateMedsCheckEligibilityAction {
  static readonly type = ProfileActionTypes.PROFILE_UPDATE_MEDS_CHECK_ELIGIBILITY;
}

export class SetMedsListsForSixCpa {
  static readonly type = ProfileActionTypes.SET_MEDS_LIST_STATES;
  constructor(public categorisedScripts: any[]) { }
}

export class GetCustomerMobileAppStatus {
  static readonly type = ProfileActionTypes.CHECK_CUSTOMER_MOBILE_APP_STATUS;
  constructor() { }
}

export class GetCustomerMobileAppStatusSuccess {
  static readonly type = ProfileActionTypes.CHECK_CUSTOMER_MOBILE_APP_STATUS_SUCCESS;
  constructor(public customerHasApp: boolean) { }
}

export class SetLastVisitDate {
  static readonly type = ProfileActionTypes.SET_LAST_VISIT_DATE;
  constructor(public lastVisitDate: Date | string) { }
}
export class GetMobileUserProfile {
  static readonly type = ProfileActionTypes.GET_MOBILE_USER_PROFILE;
  constructor(public tenantCustomerId: number) { }
}

export class SetMobileUserProfile {
  static readonly type = ProfileActionTypes.SET_MOBILE_USER_PROFILE;
  constructor(public mobileProfile: MobileUserView) { }
}
export class GetOptOutLookup {
  static readonly type = ProfileActionTypes.GET_OPT_OUT_LOOKUP;
  constructor(public mobileNumber: string) { }
}

