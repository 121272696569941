import { Component, OnInit } from '@angular/core';
import { Select } from '@ngxs/store';
import { OutcomesAndActionsFormState } from '../outcomes-and-actions-form/state/outcomes-and-actions-form.state';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-outcomes-and-actions-form-list-item',
  templateUrl: './outcomes-and-actions-form-list-item.component.html'
})
export class OutcomesAndActionsFormListItemComponent implements OnInit {
  @Select(OutcomesAndActionsFormState) outcomesAndActionsForm$: Observable<any>;

  constructor() { }

  ngOnInit() {
  }

}
