<table class="table">
    <thead>
      <tr>
        <th scope="col">Received or Sent</th>
        <th scope="col">Date</th>
        <th scope="col">Message</th>
        <th scope="col">Customer name</th>
        <th scope="col">Customer phone</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of smsList">
        <td>{{item.type}}</td>
        <td>{{item.date | tenantDate}}</td>
        <td>{{item.message}}</td>
        <td>{{item.customerName}}</td>
        <td>{{item.customerPhone}}</td>
      </tr>
    </tbody>
  </table>