import { NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { LoaderModule } from '../../../../modules/core/loader/loader.module';
import { EScriptModalComponent } from './e-script-modal.component';
import { NgxsModule } from '@ngxs/store';
import { EscriptsState } from '../state/escripts.state';
import { ScriptService } from '../../../../shared/services/script.service';
import { CommonModule } from '@angular/common';
import { RadioHighlightModule } from '../../radio-highlight/radio-highlight.module';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { FormControlFunctions } from '../../../../modules/profile/six-cpa/form-control.functions';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';

@NgModule({
  declarations: [
    EScriptModalComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    LoaderModule,
    NgxsModule.forFeature([EscriptsState]),
    RadioHighlightModule,
    ZXingScannerModule,
    BsDatepickerModule
  ],
  exports: [
    EScriptModalComponent
  ],
  providers: [
    ScriptService,
    FormControlFunctions
  ]
})
export class EScriptModalModule {

}
