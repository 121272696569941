<ng-container *ngIf="(script$ | async) as drug">

  <ng-container *ngIf="(selectedDrugs$ | async) as selectedDrugs">
    <form>
      <h2>
        <span *ngIf="drug.cMeta.isPaused">Resume</span>
        <span *ngIf="!drug.cMeta.isPaused || drug.cMeta.isPaused == null">Pause</span>
        Medication
      </h2>

      <ng-container *ngIf="(selectedDrugs$ | async) as selectedDrugs">
        <label *ngIf="selectedDrugs.length && (!drug.cMeta.isPaused || drug.cMeta.isPaused == null)">This action
          will pause {{selectedDrugs.length}} medicine(s)</label>
          <label><strong>Note: </strong>Any drugs with an exisiting pause will <strong>not</strong> be overriden.</label>
      </ng-container>

      <div class="form-group" g-xs="mt2" *ngIf="!drug.cMeta.isPaused || drug.cMeta.isPaused == null">
        <label class="font__bold">Pause Type: </label>
        <select name="selectedPauseOption" class="form-control w-75" [(ngModel)]="selectedPauseOption">
          <option [value]="pauseOptions.DateRange">Date Range</option>
          <option [value]="pauseOptions.NumberOfDays">Number Of Days</option>
          <option [value]="pauseOptions.Indefinite" selected>Indefinite</option>
        </select>
      </div>

      <div class="form-group" g-xs="mt2" *ngIf="(!drug.cMeta.isPaused || drug.cMeta.isPaused == null)">
        <label class="font__bold">Start Date: </label>
        <input name="startDate" [(ngModel)]="pauseMedicationRequest.startDate" type="text" placeholder="DD/MM/YYYY"
          class="form-control border__grey-light calendar" id="fromDate" [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY' }"
          bsDatepicker required />
      </div>
      <div class="form-group" g-xs="mt2" *ngIf="selectedPauseOption == pauseOptions.DateRange || drug.cMeta.isPaused">
        <label class="font__bold">End Date: </label>
        <input name="endDate" [(ngModel)]="pauseMedicationRequest.endDate" type="text" placeholder="DD/MM/YYYY"
          class="form-control border__grey-light calendar" id="toDate" [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY' }"
          bsDatepicker />
      </div>

      <div class="form-group" g-xs="mt2"
        *ngIf="selectedPauseOption == pauseOptions.NumberOfDays && (!drug.cMeta.isPaused || drug.cMeta.isPaused == null)">
        <label class="font__bold">Days to pause for: </label>
        <input type="text" name="daysToPause" [(ngModel)]="daysToPause" class="form-control w-50" g-xs="mt1" />
      </div>
      <div class="form-group" g-xs="mt2">
        <label class="font__bold">Reason: </label>
        <textarea rows="2" name="reason" [(ngModel)]="pauseMedicationRequest.reason" class="form-control"
          g-xs="mt1"></textarea>
      </div>

      <div class="form-group" g-xs="mt2">
        <button class="button btn__yellow" *ngIf="!drug.cMeta.isPaused || drug.cMeta.isPaused == null"
          (click)="pauseMedication(drug.cMeta.id)" g-xs="mt2">Pause DOH</button>
        <button class="button btn__green" *ngIf="drug.cMeta.isPaused" (click)="resumeMedication(drug.cMeta.id)"
          g-xs="mt2">Resume
          Medication</button>
      </div>

    </form>
  </ng-container>
</ng-container>
