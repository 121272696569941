<div class="analytics-mobile">
  <div class="d-flex flex-column justify-content-around">
    <div class="d-flex justify-content-center">
      <p>Mobile Analytics Coming Soon</p>
    </div>
    <div class="d-flex justify-content-center link-button">
      <div class="button mobile-button btn__yellow analytics__new">
        <a routerLink="..">Back</a>
      </div>
    </div>
  </div>
</div>
