export interface IHasStockId {
  stockId: string;
}

export enum PassiveScriptType {
  outsideRepeat,
  deferredScript
}
export interface IAdditionalItem extends IHasStockId {
  frequency: number;
  repeats: number;
  quantity: number;
  categoryId: number;
  lastDispensedDate: Date;
  prescriptionDate: Date | string | null;
  originalScriptNo: string;
  passiveScriptType: PassiveScriptType;
}
