import { SSConsentEligibilityFormModel } from "~/shared/models/six-cpa/staged-supply/ss-consent-eligibility-form.model";

export enum SSConsentEligibilityBasicFormActions {
  UPDATE = '[SSConsentEligibilityFormBasic] Update Form'
}

export class UpdateSSConsentEligibilityBasicForm {
  static readonly type = SSConsentEligibilityBasicFormActions.UPDATE;
  constructor(public form: Partial<SSConsentEligibilityFormModel>) { }
}
