import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Store, Select, ofActionSuccessful, Actions } from '@ngxs/store';
import { Observable } from 'rxjs';
import { MobileState, MobileStateModalState } from './state/mobile-state-modal.state';
import { CloseMobileStateModal, DeleteInvitesSuccessful, DeleteMobileInvites, DeleteMobileUser, DeleteUserSuccessful } from './state/mobile-state-modal.actions';
import { OpenInviteToSignUpModal, RequestInviteToSignUpSuccess } from '../../invite-to-sign-up/state/invite-to-sign-up.actions';
import * as moment from 'moment';
import { ProfileState } from '../../../core/profile/state/profile.state';
import { GetProfileAction } from '../../../core/profile/state/profile.actions';
import { FeatureDecisions } from '@base/modules/core/features/feature-decisions.service';

@Component({
  selector: 'app-mobile-state-modal',
  templateUrl: './mobile-state-modal.component.html',
  styleUrls: ['./mobile-state-modal.component.scss']
})
export class MobileStateModalComponent implements OnInit {
  @Select(MobileStateModalState) state$: Observable<any>
  public mobileState = MobileState;
  deleteAccountPrompt = false;
  constructor(
    private store: Store,
    private cd: ChangeDetectorRef,
    private actions$: Actions,
    public featureDecisions: FeatureDecisions) { }

  ngOnInit() {
  }

  isInFuture(date: any) {
    return moment(date).isAfter();
  }

  invite() {
    this.store.dispatch(new OpenInviteToSignUpModal());
    this.actions$.pipe(ofActionSuccessful(RequestInviteToSignUpSuccess))
      .subscribe(() => {
        const clientId = this.store.selectSnapshot(ProfileState.clientId);
        this.store.dispatch(new CloseMobileStateModal());
        this.store.dispatch(new GetProfileAction(clientId));
      });
  }

  deleteAccount() {
    this.store.dispatch(new DeleteMobileUser());
    this.actions$.pipe(ofActionSuccessful(DeleteUserSuccessful))
      .subscribe(() => {
        const clientId = this.store.selectSnapshot(ProfileState.clientId);
        this.store.dispatch(new CloseMobileStateModal());
        this.store.dispatch(new GetProfileAction(clientId));
      });
  }

  cancelInvite() {
    this.store.dispatch(new DeleteMobileInvites());
    this.actions$.pipe(ofActionSuccessful(DeleteInvitesSuccessful))
      .subscribe(() => {
        const clientId = this.store.selectSnapshot(ProfileState.clientId);
        this.store.dispatch(new CloseMobileStateModal());
        this.store.dispatch(new GetProfileAction(clientId));
      });
  }

  closeModal() {
    this.store.dispatch(new CloseMobileStateModal());
  }
}
