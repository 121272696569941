<ng-container *ngIf="(confirmMedsCheck$ | async) as confirmMedsCheck">
  <div class="confirm-meds-check" *ngIf="confirmMedsCheck.slideMode === 'in'">
    <ng-container *ngIf="(sixCpaUserCommunications$ | async) as sixCpaUserCommunications">
      <ng-container *ngFor="let message of sixCpaUserCommunications.messages">
        <ng-container [ngSwitch]="message.type">
          <div class="alert alert-success mt-4" role="alert" *ngSwitchCase="sixCpaUserCommunicationTypes.success">
            <div class="font__bold">Successful</div>
            <div> {{message.message}} </div>
          </div>
          <div class="alert alert-info mt-4" *ngSwitchCase="sixCpaUserCommunicationTypes.info">
            <div class="font__bold">Information</div>
            <div> {{message.message}} </div>
          </div>
          <div class="alert alert-danger mt-4" *ngSwitchCase="sixCpaUserCommunicationTypes.error">
            <div class="font__bold">Unsuccessful</div>
            <div> {{message.message}} </div>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
    <div class="accordian" *ngIf="(patientReport$ | async) as patientReport">
      <div class="row align-items-center top-panel-active">
        <div class="px-3 py-2 col-8 ">
          <div class="title">Patients Report (Meds List)</div>
        </div>
        <div></div>
      </div>

      <div class="form p-4">
            <div>
            <app-download-pdf *ngIf="!patientReport.loading; else loadingPatientReport" [name]="patientReport.name"
                (downloadReportEvent)="getPatientReport()">
            </app-download-pdf>
            <p class="col-form-label-lg font__bold">Send Medication List by email, SMS or Scrypt Mobile</p>
            <div class="d-flex align-items-center">
                <button [disabled]="confirmMedsCheck.loading" class="btn__green mt-2"
                  (click)="sendMedsicationList()">Send Meds List</button>
                <i *ngIf="confirmMedsCheck.loading" class="fas fa-sync fa-spin font__title-2 ml-2 mt-2"></i>
            </div>
            <ng-template #loadingPatientReport>
                <app-loader></app-loader>
            </ng-template>
            </div>
      </div>
    </div>

    <ng-container *ngIf="(documents$ | async) as documents">
      <div *ngIf="documents.length > 0" class="accordian" >
        <div class="row align-items-center top-panel-active">
          <div class="px-3 py-2 col-8 ">
            <div class="title">Uploaded documents</div>
          </div>
          <div></div>
        </div>
        <div class="form p-4">
              <div *ngFor="let file of documents">
                <div class="download" (click)="downloadFile(file)">{{file.documentName || file.scryptDocumentName}}</div>
                <small *ngIf="file.notes">Notes: {{file.notes}}</small>
              </div>
        </div>
      </div>
    </ng-container>

    <ng-container>
      <app-upload-documents-form
          name="Upload documents to service form"
          [disabled]="false"
          [documentOrigin]="documentOrigins.Consent"
          [isDocumentListDisplay]="false"
      >
      </app-upload-documents-form>
    </ng-container>

    <form class="confirm-meds-check__form" [formGroup]="doctorDetailsForm" ngxsForm="confirmMedsCheck.doctorDetailsForm"
      (submit)="sendDoctorReport()">
      <div class="accordian" *ngIf="(doctorReport$ | async) as doctorReport">
        <div class="row align-items-center top-panel-active">
          <div class="px-3 py-2 col-8 ">
            <div class="title">Doctor Report</div>
          </div>
          <div class="w-100">
            <div class="form-group px-4 pt-4">
              <p class="col-form-label-lg">Doctor's Full Name</p>
              <input formControlName="fullName" [typeahead]="confirmMedsCheck.doctors" typeaheadOptionField="fullName"
                class="form-control form-control-lg typeahead" (typeaheadOnSelect)="onSelect($event)"
                placeholder="Start typing a name...">
            </div>
            <div class="form-group p-4">
              <p class="col-form-label-lg">Doctor's Address</p>
              <textarea type="text" formControlName="doctorAddress" class="form-control form-control-lg"
              id="doctorAddress"></textarea>
            </div>
            <app-customise-doctor-report></app-customise-doctor-report>
          </div>
        </div>
        <div class="form p-4">
          <app-loader *ngIf="doctorReport.loading; else downloadPdf"></app-loader>
          <ng-template #downloadPdf>
            <app-download-pdf [name]="doctorReport.name" (downloadReportEvent)="getDoctorReport()">
            </app-download-pdf>
          </ng-template>
          <p class="col-form-label-lg font__bold">Email Doctor's Report</p>
          <div class="form-group">
            <p class="col-form-label-lg">Email Address</p>
            <input type="text" formControlName="email" class="form-control form-control-lg" placeholder="Email Address">
          </div>
          <div class="d-flex align-items-center">
            <button type="submit" [disabled]="doctorDetailsForm.invalid || confirmMedsCheck.loading"
              class="btn__green mt-2">Send report to doctor</button>
            <i *ngIf="confirmMedsCheck.loading" class="fas fa-sync fa-spin font__title-2 ml-2 mt-2"></i>
          </div>
        </div>
      </div>
    </form>

    <div class="accordian">
      <div class="row align-items-center top-panel-active">
        <div class="px-3 py-2 col-8 ">
          <div class="title">Create CRM Note</div>
        </div>
      </div>
      <div class="form p-4">
        <form [formGroup]="sixCpaNoteForm" ngxsForm="confirmMedsCheck.sixCpaNoteForm"
          *ngIf="!confirmMedsCheck.sixCpaNoteForm.loading; else loadingNote" (submit)="createNote()">
          <div class="form-group">
            <p class="col-form-label-lg font__bold">Follow Up Date</p>
            <div class="calendar">
              <input type="text" class="form-control form-control-lg" formControlName="followUpDate" id="folowUpDate"
                [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY' }" placeholder="dd-mm-yyyy" bsDatepicker>
            </div>
            <p class="col-form-label-lg font__bold">Action</p>
            <select  class="form-control" formControlName="template" (change)="onNoteTypeChange()">
              <option value="" selected>Pre-defined note text</option>
              <option value="Follow up on Medscheck actions & recommendations">Follow up on Medscheck actions & recommendations</option>
              <option value="12 month reminder">12 month reminder</option>
              <option value="Schedule another service for: ">Schedule another service for: </option>
            </select>
            <input type="text" class="form-control form-control-lg mt-1" placeholder="note text" formControlName="body" id="body">
          </div>
          <button type="submit" [disabled]="sixCpaNoteForm.invalid" class="btn__green mt-2">Create CRM Note</button>
          <div class="color__green mt-2" *ngIf="confirmMedsCheck.sixCpaNoteForm.successResponse"><i
              class="fas fa-check mr-2"></i>Note was created successfully.</div>
        </form>

        <ng-template #loadingNote>
          <app-loader></app-loader>
        </ng-template>
      </div>
    </div>
    <div class="alert alert mt-4">
      <div class="font__bold">Have you made a mistake in your claim?</div>
      <div>
        Contact PPA Support centre - Phone: 1800 951 285 (9 am to 8 pm AET) Monday to Friday<br />
        Email: <a href="mailto:support@ppaonline.com.au">support@ppaonline.com.au</a><br />
        More contact details here - <a href="https://www.ppaonline.com.au/contact">https://www.ppaonline.com.au/contact</a>
      </div>
    </div>

    <div class="response">
      <app-report-response [showMode]="confirmMedsCheck.showResponse" (hideEvent)="hideResponse()">
      </app-report-response>
    </div>
  </div>
</ng-container>
