export class Email {
  constructor(
    public html: string,
    public emailAttachments: EmailAttachment[],
    public message: string,
    public subject: string,
    public toMail: string,
    public createdAt?: string,
    public fromMail?: string,
    public id?: string
  ) { }
}

export class EmailAttachment {
  constructor(
    public id: number,
    public emailId: string,
    public contentLink: string
  ) { }
}
