import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Store } from '@ngxs/store';
import { RefreshCategoriesAction } from '../../../modules/profile/categories/state/categories.actions';
import { ProfileState } from './state/profile.state';
import { GetASLPatient } from '../../../modules/profile/asl/state/asl.actions';
import { takeWhile, tap } from 'rxjs/operators';

@Injectable()
export class CategoriesResolver implements Resolve<Observable<any>> {
  constructor(private store: Store) { }
  resolve(route: ActivatedRouteSnapshot) {
    // resolvers execute in parrallel, wait for profile resolver to get client
    return this.store.select(ProfileState.client)
      .pipe(
        tap(client => {
          if (!client) { return; }
          const patient = client.patient;
          const clientId = route.params.clientId;
          this.store.dispatch([
            new RefreshCategoriesAction(clientId),
          ]);
          if (patient.ihiNumber) {
            this.store.dispatch(new GetASLPatient(patient.ihiNumber, patient.firstname, patient.surname));
          }
        }),
        takeWhile(client => !client) // unsubscribe after first client
      );

  }
}
