import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxsModule } from '@ngxs/store';
import { AttendingDoctorState } from './state/attending-doctor.state';
import { AttendingDoctorService } from './service/attending-doctor.service';
import { DoctorReportTemplatesSelectorState } from '../../../shared/components/doctor-report-templates-selector/state/doctor-report-templates-selector.state';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    NgxsModule.forFeature([AttendingDoctorState, DoctorReportTemplatesSelectorState])
  ],
  providers: [
    AttendingDoctorService
  ]
})
export class AttendingDoctorModule { }
