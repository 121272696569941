export class Customer {
    constructor(
        public clientId: string,
        public name: string,
        public surname: string,
        public createDate: Date,
        public type: string,
        public score?: number
    ) { }
}

export class CustomerItem {
  constructor(
    public value: string,
    public status: string
  ) { }
}

export class DateWrapper {
  constructor(
    public value: string
  ) { }
}

export class CustomerDataPoint {
  constructor(
    public Customer: CustomerItem,
    public Date: DateWrapper,
    public Raw: Customer
  ) { }
}

export class CustomerData {
  constructor(
    public list: CustomerDataPoint[],
    public headers: string[]
  ) { }
}
