import { Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { GetGroupList } from '~/system/group-page/state/group-page.actions';

@Injectable()
export class CommunicationsResolver implements Resolve<Observable<any>> {
  constructor(private store: Store) { }
  resolve(): Observable<any> {
    return this.store.dispatch([
      new GetGroupList()
    ]);
  }
}