import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { BaseApi } from '../core/base-api/base-api';
import { Router } from '@angular/router';
import { StorageService } from '../core/storage/storage.service';
import { AlertService } from '../../modules/core/alert/alert.service';
import {
  AuthDocument,
  DocumentResponse,
} from '../../modules/document/state/document-services.state';

@Injectable()
export class DocumentsService extends BaseApi {
  API = '';

  constructor(
    @Inject('environment') env: any,
    public http: HttpClient,
    public router: Router,
    public storageService: StorageService,
    public alertService: AlertService
  ) {
    super(http, router, storageService, alertService);
    this.API = env.baseApiUrl;
  }

  getDocumentsForPatient(clientId: string): Observable<DocumentResponse[]> {
    return this.get<DocumentResponse[]>(
      `${this.API}/api/document/getDocumentsForPatient/${clientId}`
    );
  }

  getPatientDocument(id: number): Observable<any> {
    return this.get<any>(`${this.API}/api/document/getPatientDocument/${id}`, {
      responseType: 'blob',
    });
  }

  authDocumentLink(auth: AuthDocument): Observable<any> {
    return this.post<any>(
      `${
        this.API
      }/api/document/verifyPatientDocumentLink?customer=${encodeURIComponent(
        decodeURIComponent(auth.encryptedTenantCustomerId)
      )}&document=${encodeURIComponent(
        decodeURIComponent(auth.encryptedDocumentId)
      )}`,
      auth
    );
  }
}
