import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { StorageService } from '../core/storage/storage.service';
import { AlertService } from '~/modules/core/alert/alert.service';
import { CrudService } from './crud.service';
import { environment } from '../../../environments/environment';
import { CustomerProfile } from '../models/customerProfile.model';
import { Observable } from 'rxjs/Observable';

const API = environment.baseApiUrl;

@Injectable()
export class CustomerService extends CrudService {

  constructor(public http: HttpClient, public router: Router, public storageService: StorageService, public alertService: AlertService) {
    super(http, router, storageService, alertService, 'tenant');
  }

  getCustomers(): Observable<CustomerProfile[]> {
    return this.get(`${API}/api/customers/`);
  }

  onboardCustomer(customerId: string, phoneNumber: string, email: string) {
    return this.post(`${API}/api/customers/add-lead`,
      {
        customerId,
        phoneNumber,
        email
      });
  }

}
