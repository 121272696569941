import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BaseApi } from '../../../shared/core/base-api/base-api';
import { StorageService } from '../../../shared/core/storage/storage.service';
import { AlertService } from '../alert/alert.service';
import { IPassiveScript } from './passive-script.model';
// import { environment } from '../../../../environments/environment';

@Injectable()
export class PassiveScriptRepository extends BaseApi {
  API = '';

  constructor(
    @Inject('environment') env: any,
    public http: HttpClient,
    public router: Router,
    public storage: StorageService,
    public alert: AlertService
  ) {
    super(http, router, storage, alert);
    this.API = env.baseApiUrl;
  }

  public create(tenantCustomerId: number, items: IPassiveScript[]) {
    return this.post(
      `${this.API}/api/v1/customer/${tenantCustomerId}/passive-script`,
      items
    );
  }

  public remove(tenantCustomerId: number, passiveScriptId: string) {
    return this.delete(
      `${this.API}/api/v1/customer/${tenantCustomerId}/passive-script/${passiveScriptId}`
    );
  }

  public updateRepeats(passiveScriptId: string, repeats: number) {
    return this.put(
      `${this.API}/api/v1/passive-script/${passiveScriptId}/repeats/${repeats}`
    );
  }
}
