<form [formGroup]="patientDetailsForm" ngxsForm="daaPatientDetailsFormBasic.patientDetailsForm" class="p-4">

    <div class="form-group pb-1">
        <label for="medicareNumber" class="input-label">Medicare Number</label>
        <input type="text" [class.has-error]="formControls.medicareNumber.invalid && (showInvalid$ | async)"
            class="form-control form-control-lg w-25" name="medicareNumber" formControlName="medicareNumber"
            id="medicareNumber" placeholder="123456789" required>
    </div>
    <div class="form-group pb-1">
        <label for="dateOfService" class="input-label">Date of Service</label>
        <input type="text" [class.has-error]="formControls.dateOfService.invalid && (showInvalid$ | async)"
            class="form-control form-control-lg w-25"
            [bsValue]="patientDetailsForm.value.dateOfService | date: 'DD-MM-YYYY'" formControlName="dateOfService"
            id="dateOfService" [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY' }" placeholder="dd-mm-yyyy" bsDatepicker>
    </div>
</form>