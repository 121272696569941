import { PauseMedicationRequest, PausedMedicationModel } from '../../../../../shared/models/pause-medication-request.model';

export enum PauseMedicationActions {
  OPEN_PAUSE_MEDICATION_BLADE = '[PauseMedication] Open Blade',
  CLOSE_PAUSE_MEDICATION_BLADE = '[PauseMedication] Close Blade',
  OPEN_PAUSE_LIST_BLADE = '[PauseMedication] Open All Blade',
  CLOSE_PAUSE_LIST_BLADE  = '[PauseMedication] Close All Blade',
  PAUSE_MEDICATION = '[PauseMedication] Pause Medication',
  UNPAUSE_MEDICATION = '[PauseMedication] UnPause Medication',
  PAUSE_BULK_MEDICATION = '[PauseMedication] Pause Bulk Medication',
  UNPAUSE_BULK_MEDICATION = '[PauseMedication] UnPause Bulk Medication',
  PAUSE_MEDICATION_SUCCESS = '[PauseMedication] Pause Medication Success',
  PAUSE_MEDICATION_ERROR = '[PauseMedication] Pause Medication Error',
  PAUSE_BULK_MEDICATION_SUCCESS = '[PauseMedication] Pause Bulk Medication Success',
  GET_PAUSED_MEDICATIONS = '[PauseMedication] Get paused medications',
  GET_PAUSED_MEDICATIONS_SUCCESS = '[PauseMedication] Get paused medications success',
}

export class GetPausedMedicatonsSuccess {
  static readonly type = PauseMedicationActions.GET_PAUSED_MEDICATIONS_SUCCESS;
  constructor(public medications: PausedMedicationModel[]) { }
}

export class GetPausedMedicatonsForProfile {
  static readonly type = PauseMedicationActions.GET_PAUSED_MEDICATIONS;
  constructor(public clientId: string) { }
}

export class PauseMedication {
  static readonly type = PauseMedicationActions.PAUSE_MEDICATION;
  constructor(public request: PauseMedicationRequest) { }
}

export class UnPauseMedication {
  static readonly type = PauseMedicationActions.UNPAUSE_MEDICATION;
  constructor(public request: PauseMedicationRequest) { }
}

export class PauseBulkMedication {
  static readonly type = PauseMedicationActions.PAUSE_BULK_MEDICATION;
  constructor(public request: PauseMedicationRequest[]) { }
}

export class UnPauseBulkMedication {
  static readonly type = PauseMedicationActions.UNPAUSE_BULK_MEDICATION;
  constructor(public request: PauseMedicationRequest[]) { }
}

export class OpenPauseMedicationBlade {
  static readonly type = PauseMedicationActions.OPEN_PAUSE_MEDICATION_BLADE;
  constructor() { }
}

export class ClosePauseMedicationBlade {
  static readonly type = PauseMedicationActions.CLOSE_PAUSE_MEDICATION_BLADE;
  constructor() { }
}

export class OpenPauseListBlade {
  static readonly type = PauseMedicationActions.OPEN_PAUSE_LIST_BLADE;
  constructor() { }
}

export class ClosePauseListBlade {
  static readonly type = PauseMedicationActions.CLOSE_PAUSE_LIST_BLADE;
  constructor() { }
}

export class PauseMedicationSuccess {
  static readonly type = PauseMedicationActions.PAUSE_MEDICATION_SUCCESS;
  constructor(public status: any) { }
}

export class PauseBulkMedicationSuccess {
  static readonly type = PauseMedicationActions.PAUSE_BULK_MEDICATION_SUCCESS;
  constructor(public status: any) { }
}

export class PauseMedicationError {
  static readonly type = PauseMedicationActions.PAUSE_MEDICATION_ERROR;
  constructor(public error: any) { }
}
