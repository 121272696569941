<ng-container *ngIf="(selectedDrugs$ | async) as selectedDrugs">
  <div class="multiselect-overlay" g-xs="p3" *ngIf="selectedDrugs.length">
    <p>You have selected</p>
    <h3 class="font__bold pointer flex" (click)="showBtnGroup = toggle(showBtnGroup)">{{selectedDrugs.length}} drug(s)
      selected <i class="fas fa-chevron-down" g-xs="ml2"></i></h3>
      <ul class="drugs-list" g-xs="p3">
        <li *ngFor="let drug of selectedDrugs">
            <span *ngIf="drug.description">{{drug.description}}</span>
            <span *ngIf="!drug.description">{{drug.name}}</span>
            |
            Rpts: {{drug.repeats - drug.timesDispensed}}
        </li>
      </ul>
    <a (click)="deselectAll(selectedDrugs)" class="font__title-1 pointer">Unselect all drugs</a>
    <div class="multiselect-overlay__btns">
      <div [@accordionAnimation]="showBtnGroup">
        <button *ngIf="!selectedDrugsContainHistoricScripts(selectedDrugs) && !drugsContainsUncategorised(selectedDrugs)" type="button" class="button btn__yellow btn-block" (click)="openBsaBlade()">Adjust Patient DOH</button>
        <button *ngIf="!selectedDrugsContainHistoricScripts(selectedDrugs) && !drugsContainsUncategorised(selectedDrugs) && repeatsCanBeSms(selectedDrugs)" class="btn btn__white btn-block" (click)="sendRepeats(selectedDrugs)">SMS Repeats</button>
        <ng-container *ngIf="(bulkDispenseState$ | async) as bulkDispenseState">
          <button *ngIf="!bulkDispenseState.loading && tenantCustomerCanDispense && drugsCanBeDispensed(selectedDrugs)" class="btn btn__yellow btn-block" (click)="dispenseDrugs(selectedDrugs)">Dispense Drugs</button>
          <app-loader *ngIf="bulkDispenseState.loading"></app-loader>
        </ng-container>

        <button *ngIf="!selectedDrugsContainHistoricScripts(selectedDrugs) && !drugsContainsUncategorised(selectedDrugs)" type="button" class="button btn__green btn-block" (click)="resetScripts()">Reset Drugs</button>
        <button type="button" class="button btn__red btn-block" (click)="deselectAll(selectedDrugs)">Cancel</button>

    </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="overriddenScripts.length">
    <app-dispense-overide-confirmation-modal
        (closeEvent)="closeConfirmation()"
        (continueEvent)="continue()"
        [scripts]="overriddenScripts">
</app-dispense-overide-confirmation-modal>
</ng-container>
