import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SickLeaveConsentAndEligibilityComponent } from './sick-leave-consent-and-eligibility.component';
import { ReactiveFormsModule } from '@angular/forms';
import { RadioHighlightModule } from '../../../../../shared/components/radio-highlight/radio-highlight.module';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';

@NgModule({
  declarations: [SickLeaveConsentAndEligibilityComponent],
  imports: [
    CommonModule,
    RadioHighlightModule,
    NgxsFormPluginModule,
    ReactiveFormsModule
  ],
  exports: [SickLeaveConsentAndEligibilityComponent]
})
export class SickLeaveConsentAndEligibilityModule { }
