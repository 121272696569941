import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ManageMetaComponent } from './manage-meta.component';
import { ScriptService } from '../../../../shared/services/script.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxsModule } from '@ngxs/store';
import { ManageMetaState } from './state/manage-meta.state';
import { TimezoneModule } from '../../../core/timezone/timezone.module';
import { UiSwitchModule } from 'ngx-ui-switch';
import { LoaderModule } from '../../../core/loader/loader.module';
import { CategoriesService } from '../services/categories.service';
import { ScryptInsightModule } from '../../../../shared/scrypt-insight/scrypt-insight.module';

@NgModule({
  declarations: [
    ManageMetaComponent
  ],
  providers: [
    ScriptService,
    CategoriesService
  ],
  imports: [
    CommonModule,
    FormsModule,
    TimezoneModule,
    NgxsModule.forFeature([ManageMetaState]),
    UiSwitchModule,
    ReactiveFormsModule,
    LoaderModule,
    ScryptInsightModule
  ],
  exports: [
    ManageMetaComponent
  ]
})
export class ManageMetaModule { }
