import { DAAClassificationsRecomendationsFormModel } from "~/shared/models/six-cpa/DAA/daa-classifications-recommendations-form.model";

export enum DAAClassificationsRecommendationsActions {
  SET_VALID = '[DAAClassificationsRecommendations] set valid',
  UPDATE = '[DAAClassificationsRecommendations] Update Form',
  UPDATE_PROGRESS = '[DAAClassificationsRecommendations] Update Progress'
}

export class SetDAAClassificationsRecommendationsFormValidAction {
  static readonly type = DAAClassificationsRecommendationsActions.SET_VALID;
  constructor(public valid = false) { }
}

export class SetTotalItemsAndCompletedItems {
  static readonly type = DAAClassificationsRecommendationsActions.UPDATE_PROGRESS;
  constructor(public totalItems, public completedItems) { }
}

export class UpdateDAAClassificationsRecommendationsForm {
  static readonly type = DAAClassificationsRecommendationsActions.UPDATE;
  constructor(public form: DAAClassificationsRecomendationsFormModel) { }
}
