import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';

import { HttpClient } from '@angular/common/http';
import { BaseApi } from '../core/base-api/base-api';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import { StorageService } from '../core/storage/storage.service';
import { AlertService } from '~/modules/core/alert/alert.service';
import { AdditionalDrug } from '~/modules/profile/models/additionalDrug.model';

const API = environment.baseApiUrl;

@Injectable()
export class OrderService extends BaseApi {
  constructor(public http: HttpClient, public router: Router, public storageService: StorageService, public alertService: AlertService) {
    super(http, router, storageService, alertService);
  }

  getOrderProfile(clientId: string): Observable<any> {
    return this.get(`${API}/api/order/getOrderProfile/${clientId}`);
  }

  createOrder(order: any): Observable<any> {
    return this.post(`${API}/api/order/create`, order);
  }

  deliverOrder(orderId: number): Observable<any> {
    return this.post(`${API}/api/order/deliver`, orderId);
  }

  cancelOrder(orderId: number): Observable<any[]> {
    return this.delete(`${API}/api/order/${orderId}`);
  }

  confirmOrderByPharmacist(orderId: number, drugs: any[], additionalItems: AdditionalDrug[]): Observable<any[]> {
    const data = { drugs, additionalItems };
    return this.put(`${API}/api/order/confirmByPharmacist/${orderId}`, data);
  }

  updateOrder(order: any): Observable<any> {
    return this.post(`${API}/api/order/update`, order);
  }

  getOrderHistory(tenantCustomerId: number): Observable<any> {
    return this.get(`${API}/api/order/history/${tenantCustomerId}`);
  }

}
