import { Component, OnInit, OnDestroy } from '@angular/core';
import { SickLeaveChildForm } from '../sick-leave-child-form';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Store, Actions } from '@ngxs/store';
import { SickLeaveConsentAndEligibilityState } from './state/sick-leave-consent-and-eligibility.state';
import { SetTotalItemsAndCompletedItems } from './state/sick-leave-consent-and-eligibility.actions';
import { SixCpaState } from '../../state/six-cpa.state';

@Component({
  selector: 'app-sick-leave-consent-and-eligibility',
  templateUrl: './sick-leave-consent-and-eligibility.component.html',
  styleUrls: ['./sick-leave-consent-and-eligibility.component.scss']

})
export class SickLeaveConsentAndEligibilityComponent extends SickLeaveChildForm implements OnInit, OnDestroy {

  sickLeaveConsentAndEligibilityForm: FormGroup;
  private readonlySubscription: Subscription;
  formSubscription: Subscription;

  constructor(private formBuilder: FormBuilder, private store: Store, actions: Actions) {
    super(actions);
    this.generateFormControls();
  }

  ngOnInit() {
    this.formSubscription = this.store.select(SickLeaveConsentAndEligibilityState.sickLeaveConsentAndEligibilityForm)
      .subscribe(() => this.onFormChange());
    this.readonlySubscription = this.store.select(SixCpaState.formsReadOnly)
      .subscribe(readonly => readonly ? this.sickLeaveConsentAndEligibilityForm.disable() : this.sickLeaveConsentAndEligibilityForm.enable());
  }

  ngOnDestroy() {
    this.formSubscription.unsubscribe();
    this.readonlySubscription.unsubscribe();
  }
  get formControls() {
    return this.sickLeaveConsentAndEligibilityForm.controls;
  }
  protected actionOnResetForm() {
    this.sickLeaveConsentAndEligibilityForm.patchValue(
      {
        advisedYouAreNotMedicalPractitioner: true,
        consultationWasConductedForAssessingPersonFitnessToWork: true,
        advisedIfIllnessPersistsPersonShouldSeePractitioner: true
      });
  }

  private onFormChange() {
    const controls = Object.keys(this.sickLeaveConsentAndEligibilityForm.controls);
    const completedItems = this.getTotalValidItems(controls);
    this.store.dispatch(new SetTotalItemsAndCompletedItems(controls.length, completedItems));
  }

  private getTotalValidItems(controls: Array<string>) {
    return controls.filter((key) => {
      const formControl = this.sickLeaveConsentAndEligibilityForm.get(key);
      return  formControl.status === 'VALID' || (formControl.status === 'DISABLED' && !formControl.invalid && formControl.errors === null);
    }).length;
  }

  private generateFormControls() {
    this.sickLeaveConsentAndEligibilityForm = this.formBuilder.group({
      advisedYouAreNotMedicalPractitioner: new FormControl(true, Validators.requiredTrue),
      consultationWasConductedForAssessingPersonFitnessToWork: new FormControl(true, Validators.requiredTrue),
      advisedIfIllnessPersistsPersonShouldSeePractitioner: new FormControl(true, Validators.requiredTrue)
    });
  }

}
