<div class="related-wrapper mb-2">
  <div class="related-header p-4 d-flex justify-content-between">
    <h4 class="font__bold">{{ isAgent ? 'Agent' : 'Carer' }} {{ id + 1 }}</h4>
    <div>
      <button class="btn-remove mr-4" (click)="removeRelatedPerson()"><i class="far fa-trash mr-2"></i>Remove</button>
      <i
        [class.collapse-icon-rotate]="!showForm"
        class="far fa-chevron-up fa-lg collapse-icon"
        (click)="toggleForm()"></i>
    </div>
  </div>
  <div *ngIf="showForm">
    <hr class="m-0">
    <form [formGroup]="relatedPersonForm">
      <div class="related-persons-form p-4">
        <div class="form-group" *ngIf="!isAgent">
          <label class="label register-label">
            Carer type<span class="text-danger ml-2">*</span>
          </label>
          <div>
            <div class="flex">
              <label class="radio-inline mr-3">
                <input
                  class="mr-1"
                  formControlName="carerType"
                  type="radio"
                  name="carerType"
                  [value]="carerTypes.INDIVIDUAL"
                  (change)="updateCarer()"
                />
                <span class="radio-labels">Individual</span>
              </label>
              <label class="radio-inline">
                <input
                  class="mr-1"
                  formControlName="carerType"
                  type="radio"
                  name="carerType"
                  [value]="carerTypes.ORGANISATION"
                  (change)="updateCarer()"
                />
                <span class="radio-labels">Organisation</span>
              </label>
            </div>
          </div>
        </div>
        <div *ngIf="carerType === carerTypes.ORGANISATION" class="form-group">
          <label for="organisation" class="label register-label">
            Organisation name<span class="text-danger ml-2">*</span>
          </label>
          <input
            class="form-control"
            formControlName="organisation"
            placeholder="Organisation name"
            id="organisation"
          />
        </div>
        <div *ngIf="carerType === carerTypes.INDIVIDUAL" class="mt-3 d-flex justify-content-between">
          <div class="form-group w-100 mr-2">
            <label class="label register-label" for="firstName">
              First Name<span class="text-danger ml-2">*</span>
            </label>
            <input
              placeholder="Mary"
              class="form-control"
              formControlName="firstName"
              id="firstName"
            />
          </div>
          <div class="form-group w-100 mr-2">
            <label for="surname" class="label register-label">Surname<span class="text-danger ml-2">*</span> </label>
            <input
              placeholder="Scrypt"
              class="form-control"
              formControlName="surname"
              id="surname"
            />
          </div>
        </div>
        <div *ngIf="carerType === carerTypes.INDIVIDUAL" class="form-group">
          <label for="relationship" class="label register-label">
            Relationship<span class="text-danger ml-2">*</span>
          </label>
          <input
            class="form-control"
            formControlName="relationship"
            placeholder="Mother"
            id="relationship"
          />
        </div>
        <div class="form-group">
          <label class="label register-label">
            Address<span class="optional"> (Optional)</span>
          </label>
          <input
            class="form-control"
            formControlName="address"
            placeholder="100 Market Lane"
          />
        </div>
        <div class="form-group">
          <label class="label register-label">
            Suburb<span class="optional"> (Optional)</span>
          </label>
          <input
            class="form-control"
            formControlName="suburb"
            placeholder="Sydney"
          />
        </div>
        <div class="d-flex">
          <div class="form-group mr-2">
            <label class="label register-label">
              State<span class="optional"> (Optional)</span>
            </label>
            <select class="form-control" name="state" formControlName="state">
              <option value="">Select...</option>
              <option value="NSW">NSW</option>
              <option value="VIC">VIC</option>
              <option value="QLD">QLD</option>
              <option value="NT">NT</option>
              <option value="ACT">ACT</option>
              <option value="TAS">TAS</option>
              <option value="WA">WA</option>
              <option value="SA">SA</option>
            </select>
          </div>
          <div class="form-group">
            <label class="label register-label">
              Postcode<span class="optional"> (Optional)</span>
            </label>
            <input
              maxlength="4"
              class="form-control"
              formControlName="postcode"
              placeholder="2000"
            />
          </div>
        </div>
        <div class="mt-3 justify-content-between">
          <label class="label register-label">
            Contact Details<span class="text-danger ml-2">*</span>
          </label>
          <div class="d-flex">
            <div class="form-group mr-1 w-25">
              <select name="channelSelect" class="form-control" (change)="updateChannel($event.target)">
                <option value="mobileNumber">Phone</option>
                <option value="email">Email</option>
              </select>
            </div>
            <div class="form-group w-100 ml-1" *ngIf="selectedContact === 'mobileNumber'">
              <input
                class="form-control"
                formControlName="mobileNumber"
                placeholder="0401 123 456"
              />
              <div *ngIf="relatedPersonForm.controls['mobileNumber'].invalid && (relatedPersonForm.controls['mobileNumber'].dirty || relatedPersonForm.controls['mobileNumber'].touched)" class="alert">
                <div *ngIf="relatedPersonForm.controls['mobileNumber'].errors.pattern">
                  Please enter a 10 digit mobile number
                </div>
              </div>
            </div>
            <div class="form-group w-100 ml-1" *ngIf="selectedContact === 'email'">
              <input
                class="form-control"
                formControlName="email"
                placeholder="name@email.com"
              />
            </div>
          </div>
        </div>
        <div class="form-group mb-4">
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              formControlName="primary"
              (change)="updatePrimaryContact()"
            />
            <span class="check-box-labels">Set as primary contact</span>
          </div>
        </div>
        <div class="form-group mb-4">
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              formControlName="consent"
            />
            <span class="check-box-labels">{{ isAgent ? 'Agent' : 'Carer' }} consents to adding their details to the ASL</span>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
