export class Tenant {
  public dedicatedPhoneNumber: string;
  constructor(
    public name: string,
    public connection: string,
    public connectionType: number,
    public permissions?: TenantPermission[],
    public id?: number,
    public isActive?: boolean,
    public eclTenantId?: string,
    public timezone?: string
  ) { }
}

export class TenantPermission{
  constructor(
    public name:string,
    public permissionId:number,
    public tenantId:number,
    public isAllowed:boolean
  ){}
}
