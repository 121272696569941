import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthRoutingModule } from './auth-routing.module';
import { AuthComponent } from './auth.component';
import { SharedModule } from '../shared/shared.module';
import { PasswordResetPageComponent } from './password-reset-page/password-reset-page.component';
import { FormsModule } from '@angular/forms';
import { LoginPageComponent } from './login-page/login-page.component';
import { LoaderModule } from '../modules/core/loader/loader.module';

@NgModule({
  declarations: [
      AuthComponent,
      PasswordResetPageComponent,
      LoginPageComponent
      ],
  imports: [
    FormsModule,
    CommonModule,
    SharedModule,
    AuthRoutingModule,
    LoaderModule
  ],
  providers: [],
})
export class AuthModule { }
