import { Injectable } from '@angular/core';

@Injectable()
export class WindowService {

  constructor() {
  }

  open(url: string, target = '_blank') {
    window.open(url, target);
  }
}
