export const VaccinationRoute = {
  Screening: 'screening',
  WhoopingCough: 'whooping-cough',
  ScreeningForm: 'screening-form',
  Error: 'error',
  Success: 'success',
  PatientInfo: 'patient-info',
  SubmitSuccess: 'submit-success',
};

export enum VaccinationErrorType {
  InvalidLink = 0,
  SubmitFormError = 1,
}

export interface VaccinationError {
  type: VaccinationErrorType;
  error: any;
}

export const Step = {
  PatientInfo: 0,
  SubmitSuccess: 1,
};

export interface ScreeningFormLink {
  id: number;
  tenantId: number;
  tenantCustomerId: number;
  pharmacyName: string;
  pharmacyAddress: string;
  pharmacyLogo: string;
  customerFirstName: string;
  customerLastName: string;
}

export interface ScreeningForm extends PatientForm {
  id: number;
  formLinkId: number;
  created: Date | string;
}

export interface PatientForm {
  overAge65: string;
  anyRespiratoryCondition: string;
  contactChildLessThan4YearsOld: string;
  whoopingCoughVaccinationLast10Years: string;
  receiveWhoopingCoughToday: string;
}
