<ng-container *ngIf="aslDetails$ | async as aslDetails">
  <div *ngIf="profile$ | async as profile">
    <div class="summary-header d-flex py-4">
      <h3 class="font__bold">MySL Registration Details</h3>
      <i class="far fa-times fa-2x" (click)="closeModal()"></i>
    </div>
    <!--CONSENT SECTION-->
    <div class="my-4">
      <div [ngClass]="getConsentStyles(aslDetails.status)" class="d-flex" class="d-flex flex-column p-3 br-6">
      <div class="d-flex">
        <i class="fal fa-clock status-icon requested pt-3" *ngIf="aslDetails.status.infaStatus === slConsentStates.Proposed"></i>
        <i class="far fa-times status-icon rejected pt-3" *ngIf="aslDetails.status.infaStatus === slConsentStates.Rejected"></i>
        <i class="far fa-check status-icon active active pt-3" *ngIf="aslDetails.status.infaStatus === slConsentStates.Active"></i>
        <i class="fal fa-comment-alt-lines status-icon no-consent pt-3" *ngIf="!aslDetails.status.infaStatus"></i>
        <div class="consent-section ml-3 d-flex justify-content-between">
          <div class="d-flex justify-content-between">
            <div class="d-flex align-items-center">
              <div class="consent-description">
                <div class="font__bold">{{getConsentStatusTitle(aslDetails.status)}}</div>
                <div class="d-flex align-items-center"  >
                  <i [className]="getAccessTypeIconClass(aslDetails.status.infaStatus, aslDetails.status.icolStatus)"></i>
                  <span>Access to ongoing script list</span>
                  <i class="far fa-info-circle ml-1" tooltip="This gives access to what is currently in the patient's active script list."></i>
                </div>
                <div class="d-flex align-items-center">
                  <i [className]="getAccessTypeIconClass(aslDetails.status.icolStatus, aslDetails.status.infaStatus)"></i>
                  <span>Consent granted to upload historical scripts to MySL</span>
                  <i class="far fa-info-circle ml-1" tooltip="This gives access to look up all current active prescriptions that may or may not exist in the patients current active script list."></i></div>
              </div>
            </div>
          </div>
          <div class="d-flex align-items-center flex-column">
            <button
              *ngIf="aslDetails.status.infaStatus === slConsentStates.Active && aslDetails.status.icolStatus !== slConsentStates.Active && aslDetails.status.icolStatus !== slConsentStates.Rejected && aslDetails.status.icolStatus !== slConsentStates.Proposed"
              (click)="sendIcolConsent(aslDetails)" class="consent-control" [disabled]="isSubmittingConsentRequest$ | async">
              Request Historical Consent
            </button>
            <button
              *ngIf="aslDetails.status.infaStatus !== slConsentStates.Active && aslDetails.status.infaStatus !== slConsentStates.Proposed"
              (click)="sendInfaConsent(aslDetails)" class="consent-control" [disabled]="isSubmittingConsentRequest$ | async">
              Request Consent
            </button>
            <button
              *ngIf="aslDetails.status.infaStatus === slConsentStates.Active || aslDetails.status.infaStatus === slConsentStates.Proposed"
              (click)="openRemoveModal()" class="consent-control">
              Remove consent
            </button>
            <ng-container *ngIf="showRemoveModal$ | async">
            <div class="modal">
              <div class="modal-body w375">
                <div class="d-flex py-4">
                  <h3 class="font__bold">Are you sure you want to remove the MySL consent?</h3>
                  <i class="far fa-times fa-2x close-modal" (click)="closeRemoveModal()"></i>
                </div>
                <p>
                  You'll lose access to any previous consent given
                  and need the patient to provide access again.
                </p>
                <div class="d-flex align-items-center flex-row mt-4">
                  <button
                  *ngIf="aslDetails.status.infaStatus === slConsentStates.Active || aslDetails.status.infaStatus === slConsentStates.Proposed"
                  (click)="removeConsent(aslDetails)" class="button btn__yellow">
                    Yes, remove consent
                  </button>
                  <button
                    (click)="closeRemoveModal()" class="consent-control">
                    Cancel
                  </button>
                </div>
              </div>
            </div>
            <div class="modal-background"></div>
          </ng-container>
          </div>
        </div>
        </div>
        <div class="summary-box">
          <div>Consent will be sent to:</div>
          <div *ngIf="!(isSubmitting$ | async); else loadingsummery">
            <strong class="pr-5">{{profile.patient.firstname}} {{profile.patient.surname}}</strong>
            <span class="pr-5">{{aslDetails.mobileNumber ? aslDetails.mobileNumber : aslDetails.email}}</span>
            <span class="small"><i class="far fa-info-circle ml-1" ></i> &nbsp;Change the primary contact below</span>
          </div>
          <ng-template #loadingsummery>
            <i class="fas fa-spinner fa-spin"></i>
          </ng-template>
        </div>
      </div>
    </div>
    <form [formGroup]="patientForm">
      <!--PATIENT DETAILS SECTION-->
      <div class="patient-details mt-2">
        <h4 class="mb-3">Patient Details</h4>
        <div *ngIf="!isEditing" class="static-details p-4">
          <button (click)="handleEditClick()" class="edit-btn-position lite-btn btn__white pt-1">
            <i class="far fa-pencil mr-2"></i>Edit
          </button>
          <div class="row mb-3">
            <div class="col">
              <div class="static-heading">
                IHI number
              </div>
              <div class="static-content">
                <span class="mr-4">{{ profile.patient.ihiNumber }}</span>
                <span class="ihi-success"><i class="fas fa-check mr-2"></i>IHI verified</span>
              </div>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-3">
              <div class="static-heading">Gender<i class="fas fa-lock-alt fa-xs ml-2"></i></div>
              <div class="static-content">{{ profile.patient.gender }}</div>
            </div>
            <div class="col-3">
              <div class="static-heading">Given name<i class="fas fa-lock-alt fa-xs ml-2"></i></div>
              <div class="static-content">{{ profile.patient.firstname }}</div>
            </div>
            <div class="col-3">
              <div class="static-heading">Family name<i class="fas fa-lock-alt fa-xs ml-2"></i></div>
              <div class="static-content">{{ profile.patient.surname }}</div>
            </div>
            <div class="col-3">
              <div class="static-heading">Date of birth<i class="fas fa-lock-alt fa-xs ml-2"></i></div>
              <div class="static-content">{{ profile.patient.birthDate ? (profile.patient.birthDate | tenantDate) :
                "N/A" }}</div>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <div class="static-heading">Contact</div>
              <div class="static-content">{{aslDetails.mobileNumber ? aslDetails.mobileNumber : aslDetails.email}}</div>
            </div>
            <div class="col-6">
              <div class="static-heading">Address<i class="fas fa-lock-alt fa-xs ml-2"></i></div>
              <div class="static-content">
                {{ profile.patient.homeAddress ? profile.patient.homeAddress + ', ' : '' }}{{ profile.patient.homeSuburb
                ? profile.patient.homeSuburb + ', ' : '' }}
                {{ profile.patient.homeState ? profile.patient.homeState + ', ' : '' }}{{ profile.patient.homePostcode ?
                profile.patient.homePostcode : '' }}
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-4" *ngIf="profile.patient.medicareNo">
              <div class="static-heading">Medicare number<i class="fas fa-lock-alt fa-xs ml-2"></i></div>
              <div class="static-content">{{profile.patient.medicareNo}}</div>
            </div>
            <div class="col-4" *ngIf="profile.patient.repatNo">
              <div class="static-heading">DVA Number<i class="fas fa-lock-alt fa-xs ml-2"></i></div>
              <div class="static-content">{{profile.patient.repatNo}}</div>
            </div>
          </div>
        </div>
        <ng-container *ngIf="isEditing">
          <div class="static-details p-4">
            <i class="fas fa-lock-alt fa-lg tool-lock" placement="bottom"
              tooltip="To make changes to these patient details go to your dispense system"></i>
            <div class="row mb-3">
              <div class="col">
                <div class="static-heading">
                  IHI number
                </div>
                <div class="static-content">
                  <span class="mr-4">{{ profile.patient.ihiNumber }}</span>
                  <span class="ihi-success"><i class="fas fa-check mr-2"></i>IHI verified</span>
                </div>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-3">
                <div class="static-heading">Gender</div>
                <div class="static-content">{{ profile.patient.gender }}</div>
              </div>
              <div class="col-3">
                <div class="static-heading">Given name</div>
                <div class="static-content">{{ profile.patient.firstname }}</div>
              </div>
              <div class="col-3">
                <div class="static-heading">Family name</div>
                <div class="static-content">{{ profile.patient.surname }}</div>
              </div>
              <div class="col-3">
                <div class="static-heading">Date of birth</div>
                <div class="static-content">{{ profile.patient.birthDate ? (profile.patient.birthDate | tenantDate) :
                  "N/A" }}</div>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col">
                <div class="static-heading">Address</div>
                <div class="static-content">
                  {{ profile.patient.homeAddress ? profile.patient.homeAddress + ', ' : '' }}{{
                  profile.patient.homeSuburb ? profile.patient.homeSuburb + ', ' : '' }}
                  {{ profile.patient.homeState ? profile.patient.homeState + ', ' : '' }}{{ profile.patient.homePostcode
                  ? profile.patient.homePostcode : '' }}
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-4" *ngIf="profile.patient.medicareNo">
                <div class="static-heading">Medicare number</div>
                <div class="static-content">{{profile.patient.medicareNo}}</div>
              </div>
              <div class="col-4" *ngIf="profile.patient.repatNo">
                <div class="static-heading">DVA Number</div>
                <div class="static-content">{{profile.patient.repatNo}}</div>
              </div>
            </div>
          </div>
          <div class="asl-register-form mt-3">
            <div class="mt-3 mb-3 justify-content-between">
              <label class="label register-label">
                Contact Details<span class="text-danger ml-2">*</span>
              </label>
              <div class="d-flex">
                <div class="form-group mr-1 w-50">
                  <select [value]="channelForInviteSelect" name="channelSelect" class="form-control"
                    (change)="updateChannel($event.target)">
                    <option value="mobileNumber">Mobile Number</option>
                    <option value="email">Email</option>
                  </select>
                </div>
                <div class="form-group w-100 ml-1" *ngIf="channelForInviteSelect === 'mobileNumber'">
                  <input
                    class="form-control"
                    formControlName="mobileNumber"
                    placeholder="Mobile number"
                  />
                  <div *ngIf="patientForm.controls['mobileNumber'].invalid && (patientForm.controls['mobileNumber'].dirty || patientForm.controls['mobileNumber'].touched)" class="alert">
                    <div *ngIf="patientForm.controls['mobileNumber'].errors.pattern">
                      Please enter a 10 digit mobile number
                    </div>
                  </div>
                </div>
                <div class="form-group w-100 ml-1" *ngIf="channelForInviteSelect === 'email'">
                  <input class="form-control" formControlName="email" placeholder="name@email.com" />
                </div>
              </div>
            </div>
          </div>
          <ng-container *ngIf="!(isSubmitting$ | async); else loading">
            <button [class.button-disabled]="formDisabled" [disabled]="formDisabled" class="lite-btn btn__yellow mr-4"
              (click)="save()">
              Save
            </button>
            <button class="lite-btn btn__white" (click)="exitEditMode()">
              Cancel
            </button>
          </ng-container>
        </ng-container>
      </div>
      <!--CARER DETAILS SECTION-->
      <div class="patient-details mt-4">
        <h4 class="mb-3">Carer Details</h4>
        <div class="no-related-persons" *ngIf="!containsCarer(aslDetails.relatedPersons)">
          No carer details added
        </div>
        <ng-container *ngFor="let carer of carerForms.controls; let i=index" formArrayName="carers">
          <app-asl-related-person-summary *ngIf="getCarers(aslDetails.relatedPersons)[i]" [isAgent]="false" [id]="i"
            [relatedPerson]="getCarers(aslDetails.relatedPersons)[i]" [relatedPersonForm]="carer"
            (updatePrimaryContactEvent)="updatePrimaryContact($event)"
            (removeClickEvent)="removeRelatedPersons(carerForms, $event)" (resetFormEvent)="resetForm()"
            (saveEvent)="save()"></app-asl-related-person-summary>
        </ng-container>
        <button *ngIf="!isEditingNewCarer" class="lite-btn btn__white my-4 d-flex" (click)="isEditingNewCarer = true">
          <i class="far fa-plus fa-lg mr-2"></i>
          Add another carer
        </button>
        <ng-container *ngIf="isEditingNewCarer">
          <app-asl-related-person-edit-form [isAgent]="false" (saveEvent)="saveNewRelatedPerson($event, false)"
            (cancelEvent)="isEditingNewCarer = false"></app-asl-related-person-edit-form>
        </ng-container>
      </div>
      <!--AGENT DETAILS SECTION-->
      <div class="patient-details mt-4">
        <h4 class="mb-3">Agents Details</h4>
        <div class="no-related-persons" *ngIf="!containsAgent(aslDetails.relatedPersons)">
          No agent details added
        </div>
        <ng-container *ngFor="let agent of agentForms.controls; let i=index" formArrayName="agents">
          <app-asl-related-person-summary *ngIf="getAgents(aslDetails.relatedPersons)[i]" [isAgent]="true" [id]="i"
            [relatedPerson]="getAgents(aslDetails.relatedPersons)[i]" [relatedPersonForm]="agent"
            (updatePrimaryContactEvent)="updatePrimaryContact($event)"
            (removeClickEvent)="removeRelatedPersons(agentForms, $event)" (resetFormEvent)="resetForm()"
            (saveEvent)="save()"></app-asl-related-person-summary>
        </ng-container>
        <button *ngIf="!isEditingNewAgent" class="lite-btn btn__white my-4 d-flex" (click)="isEditingNewAgent = true">
          <i class="far fa-plus fa-lg mr-2"></i>
          Add another agent
        </button>
        <ng-container *ngIf="isEditingNewAgent">
          <app-asl-related-person-edit-form [isAgent]="true" (saveEvent)="saveNewRelatedPerson($event, true)"
            (cancelEvent)="isEditingNewAgent = false"></app-asl-related-person-edit-form>
        </ng-container>
      </div>
    </form>
    <hr />
    <button class="lite-btn btn__white" (click)="closeModal()">
      Done
    </button>
    <ng-template #loading>
      <app-loader></app-loader>
    </ng-template>
  </div>
</ng-container>
