import { Component } from '@angular/core';
import { Store, Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { MultipleDrugsState } from '../multiple-select/state/multiple-drugs.state';
import { BulkStockAdjustmentState, BulkStockAdjustmentStateModel } from './state/bulk-stock-adjustment.state';
import { BulkAdjustmentRequestAction } from './state/bulk-stock-adjustment.actions';
import { take, switchMap } from 'rxjs/operators';
import { ProfileState } from '../../../core/profile/state/profile.state';
import { getDohOrZero } from '../../../../shared/helpers/doh.helper';

@Component({
  selector: 'app-bulk-stock-adjustment',
  templateUrl: './bulk-stock-adjustment.component.html',
})
export class BulkStockAdjustmentComponent {
  @Select(MultipleDrugsState.selectedDrugsArray) selectedDrugs$: Observable<any>;
  @Select(BulkStockAdjustmentState) bulkStockAdjusment$: Observable<BulkStockAdjustmentStateModel>;
  adjustment = 0;

  constructor(private store: Store) { }

  submit(selectedDrugs) {
    ProfileState.clientId$(this.store)
      .pipe(
        switchMap((clientId) => this.store.dispatch(new BulkAdjustmentRequestAction(
          clientId,
          {
            adjustments: selectedDrugs
              .filter(script => !!script.cMeta.currentDosage)
              .map(script => ({
                metaId: script.cMeta.id,
                adjustedDoses: this.adjustment,
                currentDoh: getDohOrZero(script)
              }))
          }))),
        take(1)
      ).subscribe(() => this.adjustment = 0);
  }
}
