import { SortOrder } from "~/shared/types/communications";

export function getDefaultInboxSortOptions(searchStringTimeDesc: string, searchStringTimeAsc: string, searchStringNameAsc: string, searchStringNameDesc: string): any[] {
    return [
        {
          name: 'Most recent first',
          value: SortOrder.mostRecent,
          searchString: searchStringTimeDesc
        },
        {
          name: 'Oldest first',
          value: SortOrder.leastRecent,
          searchString: searchStringTimeAsc
        },
        {
          name: 'Patient name (A-Z)',
          value: SortOrder.nameDesc,
          searchString: searchStringNameAsc
        },
        {
          name: 'Patient name (Z-A)',
          value: SortOrder.nameAsc,
          searchString: searchStringNameDesc
        }
      ];
}