import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import { ReactiveFormsModule } from '@angular/forms';
import { RadioHighlightModule } from '../../../../../shared/components/radio-highlight/radio-highlight.module';
import { HMRProposedDetailsFormComponent, HMRProposedDetailsFormHostDirective } from './proposed-details-form/hmr-proposed-details-form.components';
import { HMRProposedDetailsFormBasicComponent } from './basic/hmr-proposed-details-form-basic.component';
import { HMRProposedDetailsFormDetailedComponent } from './detailed/hmr-proposed-details-form-detailed.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';

@NgModule({
    declarations: [
      HMRProposedDetailsFormComponent,
      HMRProposedDetailsFormBasicComponent,
      HMRProposedDetailsFormDetailedComponent,
      HMRProposedDetailsFormHostDirective
    ],
    imports: [
      CommonModule,
      NgxsFormPluginModule,
      ReactiveFormsModule,
      RadioHighlightModule,
      BsDatepickerModule.forRoot()
    ],
    exports: [
      HMRProposedDetailsFormComponent
    ],
    entryComponents: [
      HMRProposedDetailsFormBasicComponent,
      HMRProposedDetailsFormDetailedComponent
    ]
  })
  export class HMRProposedDetailsFormModule { }
