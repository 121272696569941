import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TemplateType, CommTemplate } from '../../../../shared/models/pharmacy/comm-template.model';
import { accordion } from '../../../../shared/core/animations';
// TODO: commented out to clarify requirements
// import { TemplateType, CommTemplate, Trigger, TriggerType } from '../../../../shared/models/pharmacy/comm-template.model';

@Component({
  selector: 'app-comm-templates-list',
  templateUrl: './comm-templates-list.component.html',
  styleUrls: ['./comm-templates-list.component.scss'],
  animations: [accordion]
})
export class CommTemplatesListComponent implements OnInit {

  @Input() templateType: TemplateType;
  @Input() commTemplates: CommTemplate[];
  @Output() toggleActivationEvent = new EventEmitter<CommTemplate>();
  @Output() editEvent = new EventEmitter<CommTemplate>();
  @Output() deleteEvent = new EventEmitter<CommTemplate>();
  @Output() createEvent = new EventEmitter<void>();
  templateTypesEnum = TemplateType;
  confirmationRequired =  false;
  currentItem: CommTemplate = null;

  templateTypes: Array<string> = Object.keys(TemplateType).filter(key => isNaN(+key));
  commsTableOption = 'in';

  constructor() { }

  ngOnInit() {
    this.commsTableOption = (this.templateType === TemplateType.Default || this.templateType === TemplateType.Report) ? 'out' : 'in';
  }

  getTypeName() {
    return `${this.templateTypes[this.templateType]} messages`;
  }

  toggleCommsTable() {
    this.commsTableOption = this.commsTableOption === 'in' ? 'out' : 'in';
  }

  toggleActivation(item: CommTemplate) {
    this.toggleActivationEvent.emit(item);
  }

  edit(item: CommTemplate) {
    this.editEvent.emit(item);
  }

  delete(item: CommTemplate){
    this.confirmationRequired = true;
    this.currentItem = item;
  }

  confirm() {
    this.confirmationRequired = false;
    this.deleteEvent.emit(this.currentItem);
    this.currentItem = null;
  }

  dismiss() {
    this.confirmationRequired = false;
    this.currentItem = null;
  }

  create(event) {
    event.stopPropagation();
    this.createEvent.emit();
  }
}
