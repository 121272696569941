import { AdvancedClient } from '../models/advanced-client';

export enum AdvancedSearchActions {
  GET_ADVANCED_SEARCH_RESULTS = '[AdvancedSearch] Get Results',
  GET_ADVANCED_SEARCH_RESULTS_SUCCESS = '[AdvancedSearch] Get Results Success',
  GET_ADVANCED_SEARCH_RESULTS_ERROR = '[AdvancedSearch] Get Results Error',
  CLEAR_ADVANCED_SEARCH_RESULTS = '[AdvancedSearch] Clear Results',
  TOGGLE_ADVANCED_SEARCH_CLIENT = '[AdvancedSearch] Toggle client',
}

export class GetAdvancedSearchResults {
  static readonly type = AdvancedSearchActions.GET_ADVANCED_SEARCH_RESULTS;
  constructor(public query: any) { }
}

export class GetAdvancedSearchResultsSuccess {
  static readonly type = AdvancedSearchActions.GET_ADVANCED_SEARCH_RESULTS_SUCCESS;
  constructor(public clients: AdvancedClient[]) {}
}

export class ToggleAdvancedSearchClient {
  static readonly type = AdvancedSearchActions.TOGGLE_ADVANCED_SEARCH_CLIENT;
  constructor(public id: any) {}
}

export class GetAdvancedSearchResultsError {
  static readonly type = AdvancedSearchActions.GET_ADVANCED_SEARCH_RESULTS_ERROR;
  constructor(public error: any) {}
}

export class ClearAdvancedSearchResults {
  static readonly type = AdvancedSearchActions.CLEAR_ADVANCED_SEARCH_RESULTS;
  constructor() {}
}
