import { MessageCycleModel } from '../../../../../shared/models/communication/messageCycleModel';

export enum MessageCycleActions {
  SHOW_MESSAGE_CYCLE_MODAL = '[MessageCycle] Show modal',
  CLOSE_MESSAGE_CYCLE_MODAL = '[MessageCycle] Close modal',
  SHOW_MESSAGE_CYCLE_SCHEDULE = '[MessageCycle] Show message cycle schedule modal',
  CLOSE_MESSAGE_CYCLE_SCHEDULE = '[MessageCycle] Close message cycle schedule modal',
  GET_MESSAGE_CYCLES = '[MessageCycle] GetMessageCycles',
  CREATE_NEW_MESSAGE_CYCLE = '[MessageCycle] Create new message cycle',
  UPDATE_EXISITING_MESSAGE_CYCLE = '[MessageCycle] Update message cycle',
  REMOVE_MESSAGE_CYCLE = '[MessageCycle] Disable message cycle',
  CREATE_MESSAGE_CYCLE_SUCCESS = '[MessageCycle] create message cycle success',
  HANDLE_MESSAGE_CYCLE_ERROR = '[MessageCycle] Handle message cycle error',
  REMOVE_MESSAGE_CYCLE_SUCCESS = '[MessageCycle] Remove message cycle success'
}

export class OpenMessageCycleModal {
  static readonly type = MessageCycleActions.SHOW_MESSAGE_CYCLE_MODAL;
  constructor() {}
}

export class CloseMessageCycleModal {
  static readonly type = MessageCycleActions.CLOSE_MESSAGE_CYCLE_MODAL;
  constructor() {}
}

export class OpenMessageCycleScheduleModal {
  static readonly type = MessageCycleActions.SHOW_MESSAGE_CYCLE_SCHEDULE;
  constructor() {}
}

export class CloseMessageCycleScheduleModal {
  static readonly type = MessageCycleActions.CLOSE_MESSAGE_CYCLE_SCHEDULE;
  constructor() {}
}

export class GetMessageCycles {
  static readonly type = MessageCycleActions.GET_MESSAGE_CYCLES;
  constructor(public clientId: string) {}
}

export class CreateNewMessageCycle {
  static readonly type = MessageCycleActions.CREATE_NEW_MESSAGE_CYCLE;
  constructor(public messagecycle: MessageCycleModel) {}
}

export class UpdateMessageCycle {
  static readonly type = MessageCycleActions.UPDATE_EXISITING_MESSAGE_CYCLE;
  constructor(public messagecycle: MessageCycleModel) {}
}

export class RemoveMessageCycle {
  static readonly type = MessageCycleActions.REMOVE_MESSAGE_CYCLE;
  constructor(public messageCycleId: number) {}
}

export class RemoveMessageCycleSuccess {
  static readonly type = MessageCycleActions.REMOVE_MESSAGE_CYCLE_SUCCESS;
  constructor(public messageCycleId: number) {}
}

export class CreateMessageCycleSuccess {
  static readonly type = MessageCycleActions.CREATE_MESSAGE_CYCLE_SUCCESS;
  constructor(public messageCycle: MessageCycleModel) {}
}

export class HandleMessageCycleError {
  static readonly type = MessageCycleActions.HANDLE_MESSAGE_CYCLE_ERROR;
  constructor(public error: any) {}
}
