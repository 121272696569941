import { State, StateContext, Action, Selector } from '@ngxs/store';
import { SSOutcomesAndActionsFormModel } from '../../../../../../shared/models/six-cpa/staged-supply/ss-outcomes-and-actions-form.model';
import { UpdateSSOutcomesAndActionsForm, SetTotalItemsAndCompletedItems, SetSSOutcomesAndActionsFormValidAction } from './ss-outcomes-and-actions-form.actions';
import { Injectable } from '@angular/core';

export class SSOutcomesAndActionsFormStateModel {
  outcomesAndActionsForm : {
    model: Partial<SSOutcomesAndActionsFormModel>
  }
  totalItems: number;
  completedItems: number;
  valid: boolean;
}
@Injectable()
@State<SSOutcomesAndActionsFormStateModel>({
  name: 'ssOutcomesAndActionsForm',
  defaults: {
    outcomesAndActionsForm : {
      model: undefined
    },
    totalItems: 0,
    completedItems: 0,
    valid: false
  }
})
export class SSOutcomesAndActionsFormState {
  @Selector()
  static outcomesAndActionsForm(state: SSOutcomesAndActionsFormStateModel) { return state.outcomesAndActionsForm.model; }

  @Selector()
  static totalItems(state: SSOutcomesAndActionsFormStateModel) { return state.totalItems; }

  @Selector()
  static completedItems(state: SSOutcomesAndActionsFormStateModel) { return state.completedItems; }

  @Action(SetSSOutcomesAndActionsFormValidAction)
  setValid(ctx: StateContext<SSOutcomesAndActionsFormStateModel>, action: SetSSOutcomesAndActionsFormValidAction) {
    ctx.patchState({
      valid: action.valid
    });
  }

  @Action(SetTotalItemsAndCompletedItems)
  setProgress(ctx: StateContext<SSOutcomesAndActionsFormStateModel>, action: SetTotalItemsAndCompletedItems) {
    ctx.patchState({
      totalItems: action.totalItems,
      completedItems: action.completedItems
    });
  }

  @Action(UpdateSSOutcomesAndActionsForm)
  updateForm(ctx: StateContext<SSOutcomesAndActionsFormStateModel>, { form }: UpdateSSOutcomesAndActionsForm) {
    ctx.patchState({
      outcomesAndActionsForm: {
        model: form
      }
    });
  }
}
