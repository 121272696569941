<form [formGroup]="consentEligibilityForm" ngxsForm="consentEligibilityFormDetailed.consentEligibilityForm" class="p-4">
    <div class="form-group pb-1">
        <label class="input-label mb-0">Patients Consent</label>
        <div>
            <small class="disclaimer">
                The patient consents to the collection of personal information by the Pharmacy
                Programs Administrator and the Department for program monitoring and evaluation purposes
            </small>
        </div>
        <app-radio-highlight controlName="consentYes"
        [hasError]="formControls.patientsConsent.invalid && (showInvalid$ | async)"
         [isHighlighted]="consentEligibilityForm.value.patientsConsent == true">
            <input class="form-check-input" type="radio" name="patientsConsent" id="consentYes"
                formControlName="patientsConsent" [value]="true">
            <label class="form-check-label" for="consentYes">Yes</label>
        </app-radio-highlight>
        <app-radio-highlight
        [hasError]="formControls.patientsConsent.invalid && (showInvalid$ | async)"
         controlName="consentNo" [isHighlighted]="consentEligibilityForm.value.patientsConsent == false">
            <input class="form-check-input" type="radio" name="patientsConsent" id="consentNo"
                formControlName="patientsConsent" [value]="false" checked>
            <label class="form-check-label" for="consentNo">No</label>
        </app-radio-highlight
        >
    </div>
    <div class="form-group pb-1">
        <div>
            <label class="input-label">Has this Patient had a Medscheck completed previously?</label>
        </div>
        <app-radio-highlight 
        [hasError]="formControls.hasPreviousMedsCheck.invalid && (showInvalid$ | async)"
         controlName="medscheckYes" [isHighlighted]="consentEligibilityForm.value.hasPreviousMedsCheck == true">
            <input class="form-check-input" type="radio" name="hasPreviousMedsCheck" id="medscheckYes"
                formControlName="hasPreviousMedsCheck" [value]="true">
            <label class="form-check-label" for="medscheckYes">Yes</label>
        </app-radio-highlight>
        <app-radio-highlight  [hasError]="formControls.hasPreviousMedsCheck.invalid && (showInvalid$ | async)"
         controlName="medscheckNo" [isHighlighted]="consentEligibilityForm.value.hasPreviousMedsCheck == false">
            <input class="form-check-input" type="radio" name="hasPreviousMedsCheck" id="medscheckNo"
                formControlName="hasPreviousMedsCheck" [value]="false" checked>
            <label class="form-check-label" for="medscheckNo">No</label>
        </app-radio-highlight>
    </div>
    <div class="form-group pb-1" (change)="deselectOtherBox()" 
    [class.has-error-group]="formControls.currentLivingStatus.invalid && (showInvalid$ | async)">
        <label class="input-label mb-0">Where is the patient currently living?</label>
        <div class="form-check form-check">
            <input class="form-check-input" type="radio" name="currentLivingStatus" id="private"
                formControlName="currentLivingStatus" [value]="livingStatus.Private">
            <label class="form-check-label" for="private">Private residence</label>
        </div>
        <div class="form-check form-check">
            <input class="form-check-input" type="radio" name="currentLivingStatus" id="independent"
                formControlName="currentLivingStatus" [value]="livingStatus.IndependenLivingRetirementVillage">
            <label class="form-check-label" for="independent">Independent living unit within a retirement
                village</label>
        </div>
        <div class="form-check form-check">
            <input class="form-check-input" type="radio" name="currentLivingStatus" id="supportedLiving"
                formControlName="currentLivingStatus" [value]="livingStatus.Supported">
            <label class="form-check-label" for="supportedLiving">Supported accommodation or supported living</label>
        </div>
        <div class="form-check form-check">
            <input class="form-check-input" type="radio" name="currentLivingStatus" id="institutionalSetting"
                formControlName="currentLivingStatus" [value]="livingStatus.Institutional">
            <label class="form-check-label" for="institutionalSetting">Institutional setting</label>
            <div>
                <small class="xs-disclaimer">
                    Including aged care and psychiatric or mental health community carefacilities
                </small>
            </div>
        </div>
        <div class="form-check form-check" [class.has-error-group]="formControls.currentLivingStatus.invalid && (showInvalid$ | async)">
            <input class="form-check-input" type="radio" name="currentLivingStatus" id="other"
                formControlName="currentLivingStatus" [value]="livingStatus.Other" checked>
            <label class="form-check-label" for="other">Other</label>
            <input class="form-control form-control-lg w-50" type="text" name="currentLivingOtherDetails" id="otherDetails"
            formControlName="currentLivingOtherDetails" [attr.disabled]="consentEligibilityForm.value.currentLivingStatus !== 4 ? '' : null" >

        </div>
    </div>
    <div class="form-group pb-1">
        <label class="input-label-not-required">In the Last 6 Months did the Patient go to the GP or Hospital due to
            problems with their medicines?</label>
        <app-radio-highlight
        [hasError]="formControls.gpOrHospitalLastSixMonthsForMedicationProblems.invalid && (showInvalid$ | async)"
            controlName="hospitalisedYes"
            [isHighlighted]="consentEligibilityForm.value.gpOrHospitalLastSixMonthsForMedicationProblems == true">
            <input class="form-check-input" type="radio" name="gpOrHospitalLastSixMonthsForMedicationProblems"
                id="hospitalisedYes" formControlName="gpOrHospitalLastSixMonthsForMedicationProblems" [value]="true">
            <label class="form-check-label" for="hospitalisedYes">Yes</label>
        </app-radio-highlight>
        <app-radio-highlight
        [hasError]="formControls.gpOrHospitalLastSixMonthsForMedicationProblems.invalid && (showInvalid$ | async)"
            controlName="hospitalisedNo"
            [isHighlighted]="consentEligibilityForm.value.gpOrHospitalLastSixMonthsForMedicationProblems == false">
            <input class="form-check-input" type="radio" name="gpOrHospitalLastSixMonthsForMedicationProblems"
                id="hospitalisedNo" formControlName="gpOrHospitalLastSixMonthsForMedicationProblems" [value]="false" checked>
            <label class="form-check-label" for="hospitalisedNo">No</label>
        </app-radio-highlight>
    </div>
    <div class="form-group pb-1" [class.has-error-group]="formControls.medicineManagementSupport.invalid && (showInvalid$ | async)">
        <label class="input-label">Does the Patient have Support with Managing Medicines?</label>
        <div class="form-check form-check">
            <input class="form-check-input" type="radio" name="medicineManagementSupport" id="minimal"
                formControlName="medicineManagementSupport" [value]=0>
            <label class="form-check-label" for="minimal">Minimal (e.g. Living alone)</label>
        </div>
        <div class="form-check form-check">
            <input class="form-check-input" type="radio" name="medicineManagementSupport" id="occasional"
                formControlName="medicineManagementSupport" [value]=1>
            <label class="form-check-label" for="occasional">Occasional assistance (e.g. Living alone with periodic
                help)</label>
        </div>
        <div class="form-check form-check">
            <input class="form-check-input" type="radio" name="medicineManagementSupport" id="routine"
                formControlName="medicineManagementSupport" [value]=2>
            <label class="form-check-label" for="routine">Routine assistance (e.g. regular carer)</label>
        </div>
        <div class="form-check form-check">
            <input class="form-check-input" type="radio" name="medicineManagementSupport" id="complete"
                formControlName="medicineManagementSupport" [value]=3>
            <label class="form-check-label" for="complete">
                Complete assistance (assistance with preparation and taking of medicines)</label>
        </div>
    </div>
    <div class="form-group pb-1">
        <label for="followUpService" class="input-label">Date of Follow-Up Service</label>
        <input type="text" class="form-control form-control-lg w-25"
        [class.has-error]="formControls.followUpService.invalid && (showInvalid$ | async)" formControlName="followUpService" id="followUpService"
            [bsValue]="defaultCheckUpDate | date: 'DD-MM-YYYY'"
            [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY', adaptivePosition: true }" placeholder="dd-mm-yyyy"
            bsDatepicker>
    </div>
    <div class="form-group pb-1" [class.has-error-group]="formControls.consentType.invalid && (showInvalid$ | async)">
        <label class="input-label">How has the patient given consent?</label>
        <div class="form-check form-check">
            <input class="form-check-input" type="radio" name="consentType" id="written"
                formControlName="consentType" [value]=0>
            <label class="form-check-label" for="minimal">Written</label>
        </div>
        <div class="form-check form-check">
            <input class="form-check-input" type="radio" name="consentType" id="verbal"
                formControlName="consentType" [value]=1>
            <label class="form-check-label" for="occasional">Verbal</label>
            <div>
                <small class="xs-disclaimer">
                    The patient has verbally provided consent for the pharmacy to collect and disclose their personal information to PPA and the Australian Government Department of Health for the purpose indicated above.
                </small>
            </div>
        </div>
    </div>
    <label class="input-label-not-required mb-0">Consent form</label>
    <div>
        <div *ngFor="let type of [medsCheckType$| async]" class="upload-files">
            <i class="far fa-file-pdf"></i>&nbsp;&nbsp; <a [href]="getConsentDocumentLink(type)" target="_blank">Consent Form PDF</a>
        </div>

        <app-upload-documents-form
            name="Upload documents to service form"
            [disabled]="consentEligibilityForm.disabled"
            [documentOrigin]="documentOrigins.Consent"
        >
        </app-upload-documents-form>
    </div>
</form>
