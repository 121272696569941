import { Medicine } from '../../../../../shared/models/six-cpa/medicine.model';

export enum AddEditMedicationActionTypes {
  TOGGLE_ADD_EDIT_MEDICATION_ACTION= '[AddEditMedication] Toggle Blade',
  SEARCH_ADD_EDIT_MEDICATION_ACTION= '[AddEditMedication] Search Medication',
  ADD_EDIT_MEDICATION_ERROR_ACTION= '[AddEditMedication] Error',
  ADD_EDIT_MEDICATION_SET_LIST_MEDICATION_ACTION= '[AddEditMedication] Set Medication List',
  ADD_EDIT_MEDICATION_SELECT_MEDICATION_ACTION= '[AddEditMedication] Select Medication',
  ADD_EDIT_MEDICATION_RESET_MEDICATION_ACTION= '[AddEditMedication] Reset Medication',
  ADD_MEDICATION= '[AddEditMedication] Add Medication'
}

export class ToggleAddEditMedicationAction {
  static readonly type = AddEditMedicationActionTypes.TOGGLE_ADD_EDIT_MEDICATION_ACTION;
  constructor(public slideMode: string) { }
}

export class SearchMedicationAction {
  static readonly type = AddEditMedicationActionTypes.SEARCH_ADD_EDIT_MEDICATION_ACTION;
  constructor(public query: string) { }
}

export class AddEditMedicationErrorAction {
  static readonly type = AddEditMedicationActionTypes.ADD_EDIT_MEDICATION_ERROR_ACTION;
  constructor(public error: any) { }
}
export class SetMedicationListAction {
  static readonly type = AddEditMedicationActionTypes.ADD_EDIT_MEDICATION_SET_LIST_MEDICATION_ACTION;
  constructor(public items: any[]) { }
}

export class SelectMedicationAction {
  static readonly type = AddEditMedicationActionTypes.ADD_EDIT_MEDICATION_SELECT_MEDICATION_ACTION;
  constructor(public item: Medicine) { }
}

export class ResetMedicationAction {
  static readonly type = AddEditMedicationActionTypes.ADD_EDIT_MEDICATION_RESET_MEDICATION_ACTION;
}

export class AddMedication {
  static readonly type = AddEditMedicationActionTypes.ADD_MEDICATION;
  constructor(public medication: Medicine) { }
}
