import { TenantInstallReportModel } from '~/shared/models/install-report.model';
import { NewPharmacy } from '../../../shared/models/pharmacy/new-pharmacy.model';
import { MessageTemplate } from '~/shared/models/pharmacy/message-template.model';

export enum AdminDashboardActions {
  ADD_PHARMACY = '[AdminDashboard] Add Pharmacy',
  ADD_INSTALL_REPORT = '[AdminDashboard] Add Install Report',
  GET_TENANTS = '[AdminDashboard] Get Tenants',
  SET_TENANTS = '[AdminDashboard] SET Tenants',
  CREATE_GLOBAL_TEMPLATE = '[AdminDashboard] CREATE_GLOBAL_TEMPLATE',
  GET_CONNECTION_TYPES = '[AdminDashboard] GET VALID CONNECTION TYPES'
}

export class AddNewPharmacy {
  static readonly type = AdminDashboardActions.ADD_PHARMACY;
  constructor(public newPharmacy: NewPharmacy) { }
}

export class AddTenantInstallReport {
  static readonly type = AdminDashboardActions.ADD_INSTALL_REPORT;
  constructor(public model: TenantInstallReportModel) { }
}

export class GetTenantDetails {
  static readonly type = AdminDashboardActions.GET_TENANTS;
  constructor() { }
}

export class SetTenantDetails {
  static readonly type = AdminDashboardActions.SET_TENANTS;
  constructor(public model: any) { }
}

export class CreateGlobalTemplate {
  static readonly type = AdminDashboardActions.CREATE_GLOBAL_TEMPLATE;
  constructor(public messageTemplate: MessageTemplate) { }
}

export class GetConnectionTypes {
  static readonly type = AdminDashboardActions.GET_CONNECTION_TYPES;
  constructor() {}
}
