import { Medicine } from '../../../../../../shared/models/six-cpa/medicine.model';
import { SixCpaMedicationModel } from '../../../../../../shared/models/six-cpa/six-cpa-medication.model';
import { MedsListMedicationProfileForm } from './med-list-medication-profile.state';

export enum MedListMedicationProfileActions {
  SET_MEDICATION_PROFILE_SCRIPRS_ACTION = '[MedListMedicationProfile] Set Scripts',
  TOGGLE_MEDICATION_PROFILE_LIST_ITEM_ACTION = '[MedListMedicationProfile] Toggle List Item',
  TOGGLE_ALL_MEDICATION_PROFILE_LIST_ACTION = '[MedListMedicationProfile] Toggle All List Item',
  ADD_MEDICATION_MEDICATION_PROFILE_LIST_ACTION = '[MedListMedicationProfile] Add Medication To List Item',
  SELECT_MEDICATION_PROFILE_LIST_ITEM_ACTION = '[MedListMedicationProfile] Select List Item',
  TOGGLE_MEDICATION_PROFILE_ACTION = '[MedListMedicationProfile] Toggle Content',
  UPDATE_MEDICATION_PROFILE = '[MedListMedicationProfile] Update Content',
  RESET_MEDICATION_PROFILE = '[MedListMedicationProfile] Reset Medication Profile Ids',
  SET_MEDICATION_PROFILE_LIST_FORM = '[MedListMedicationProfile] Set medication profile form'
}

export class SetMedsListMedicationProfileFormAction {
  static readonly type = MedListMedicationProfileActions.SET_MEDICATION_PROFILE_LIST_FORM;
  constructor(public form: Partial<MedsListMedicationProfileForm>) { }
}


export class SetMedListMedicationProfileScripts {
  static readonly type = MedListMedicationProfileActions.SET_MEDICATION_PROFILE_SCRIPRS_ACTION;
  constructor(public categorisedScripts: any[]) { }
}

export class ToggleMedListMedicationProfileListItem {
  static readonly type = MedListMedicationProfileActions.TOGGLE_MEDICATION_PROFILE_LIST_ITEM_ACTION;
  constructor(public item: Medicine) { }
}

export class ToggleAllMedListMedicationProfileList {
  static readonly type = MedListMedicationProfileActions.TOGGLE_ALL_MEDICATION_PROFILE_LIST_ACTION;
  constructor(public isSelectAll: boolean, public categoryId?: number) { }
}
export class AddMedicationToMedListMedicationProfileList {
  static readonly type = MedListMedicationProfileActions.ADD_MEDICATION_MEDICATION_PROFILE_LIST_ACTION;
  constructor(public item: Medicine) { }

}
export class SelectMedListMedicationProfileListItem {
  static readonly type = MedListMedicationProfileActions.SELECT_MEDICATION_PROFILE_LIST_ITEM_ACTION;
  constructor(public productDispensedId: string) { }
}

export class ToggleMedListMedicationProfile {
  static readonly type = MedListMedicationProfileActions.TOGGLE_MEDICATION_PROFILE_ACTION;
  constructor(public showMode: string) { }
}

export class UpdateMedListMedicationProfileList {
  static readonly type = MedListMedicationProfileActions.UPDATE_MEDICATION_PROFILE;
  constructor(public medication: SixCpaMedicationModel[]) { }
}

export class ResetMedListMedicationProfileIdsAction {
  static readonly type = MedListMedicationProfileActions.RESET_MEDICATION_PROFILE;
}
