import { PharmacyInformationFormModel } from '../../../../../shared/models/six-cpa/pharmacy-information-form.model';

export enum PharmacyInformationFormActions {
  SET_VALID = '[PharmacyInformationForm] set valid',
  UPDATE = '[PharmacyInformationForm] Update Form'
}

export class SetPharmacyInformationFormValidAction {
  static readonly type = PharmacyInformationFormActions.SET_VALID;
  constructor(public valid = false) { }
}

export class SetTotalItemsAndCompletedItems {
  static readonly type = '[PharmacyInformationForm] Update Progress';
  constructor(public totalItems, public completedItems) { }
}

export class UpdatePharmacyInformationForm {
  static readonly type = PharmacyInformationFormActions.UPDATE;
  constructor(public form: PharmacyInformationFormModel) { }
}
