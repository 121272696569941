<ng-container *ngIf="show">
    <ng-container *ngIf="state$ | async as state">
        <div class="modal">
            <div class="modal-dialog modal-xl" role="document">
                <div class="modal-content">
                    <div class="modal-header modal-heading">
                        <h5 class="modal-title">Client Results</h5>
                        <button type="button" (click)="closeResults()" class="close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                        <div>
                            <nz-table #zClientTable [nzData]="state?.clients" [nzFrontPagination]="false"
                                [nzShowPagination]="false" [nzVirtualScroll]="true">
                                <thead>
                                    <tr>
                                        <th>Title</th>
                                        <th>Name</th>
                                        <th>DOB</th>
                                        <th>Mobile</th>
                                        <th>Sex</th>
                                        <th>Suburb</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container *ngFor="let client of zClientTable.data">
                                        <tr>
                                            <td>{{client.title}}</td>
                                            <td>{{client.firstName}} {{client.surname}}</td>
                                            <td>{{client.dateOfBirth | date: 'short'}}</td>
                                            <td>{{client.mobileNumber}}</td>
                                            <td>{{client.sex}}</td>
                                            <td>{{client.homeSuburb}}</td>
                                        </tr>
                                    </ng-container>
                                </tbody>
                            </nz-table>
                        </div>
                    <ng-template #loading>
                        <div class="modal-body border__grey">
                            <div>
                                <app-loader loadingMessage="Loading..."></app-loader>
                            </div>
                        </div>
                    </ng-template>
                </div>
            </div>
        </div>
        <div class="modal-background"></div>
    </ng-container>
</ng-container>