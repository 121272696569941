export enum ContactMethods {
  phone,
  sms,
  email,
  postal,
  scryptMobile,
  medAdvisor,
  guildCare,
  scryptMessenger
}

export namespace ContactMethods {
  export function toDisplayName(contactMethod: ContactMethods): string {
    switch(contactMethod) {
      case ContactMethods.email:
        return "email";
      case ContactMethods.guildCare: 
        return "guild care";
      case ContactMethods.medAdvisor:
        return "MedAdvisor";
      case ContactMethods.phone:
        return "phone";
      case ContactMethods.postal:
        return "postal mail";
      case ContactMethods.scryptMessenger:
        return "scrypt messenger";
      case ContactMethods.scryptMobile:
        return "scrypt mobile";
      case ContactMethods.sms:
        return "SMS";
      default: throw new Error("Invalid Contact Method:" + contactMethod);
    }
  }
}