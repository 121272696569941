import { Component, OnInit } from '@angular/core';
import { HMRAccreditedPharmacistDetailsState } from '../hmr-accredited-pharmacist-details/state/hmr-accredited-pharmacist-details.state';
import { Observable } from 'rxjs';
import { Select } from '@ngxs/store';

@Component({
  selector: 'app-hmr-accredited-pharmacist-details-list-item',
  templateUrl: './hmr-accredited-pharmacist-details-list-item.component.html'
})
export class HMRAccreditedPharmacistDetailsListItemComponent implements OnInit {
  @Select(HMRAccreditedPharmacistDetailsState) hmrAccreditedPharmacistDetailsForm$: Observable<any>;

  constructor() { }

  ngOnInit() {
  }

}
