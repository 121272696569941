import { ReportViewModel } from "../models/dispense-report.model";
import { DispenseRunRequestModel } from "../models/dispense-run-request.model";

export enum DispenseRunActions {
  INIT_DISPENSE_RUN_REQUEST = '[DispenseRun] INIT_DISPENSE_RUN_REQUEST',
  SUBMIT_FORM = '[DispenseRun] SUBMIT_FORM',
  SUBMIT_FORM_FOR_CLIENT = '[DispenseRun] SUBMIT_FORM_FOR_CLIENT',
  EVALUATE_CLIENT_SUCCESS = '[DispenseRun] EVALUATE_CLIENT_SUCCESS',
  DISPENSE_RUN_ERROR = '[DispenseRun] DISPENSE_RUN_ERROR',
  RESET_REPORT = '[DispenseRun] RESET_REPORT'
}
export class InitDispenseRunRequest {
  static readonly type = DispenseRunActions.INIT_DISPENSE_RUN_REQUEST
  constructor(public request: DispenseRunRequestModel) { }
}

export class SubmitDispenseForm {
  static readonly type = DispenseRunActions.SUBMIT_FORM
  constructor() { }
}

export class EvaluteClients {
  static readonly type = DispenseRunActions.SUBMIT_FORM_FOR_CLIENT
  constructor() { }
}

export class EvaluateClientSuccess {
  static readonly type = DispenseRunActions.EVALUATE_CLIENT_SUCCESS
  constructor(public response: ReportViewModel) { }
}

export class DispenseRunError {
  static readonly type = DispenseRunActions.DISPENSE_RUN_ERROR
  constructor(public error: any) { }
}

export class ResetReport {
  static readonly type = DispenseRunActions.RESET_REPORT
  constructor() { }
}
