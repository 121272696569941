import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subscription, Observable } from 'rxjs';
import { Store, Actions, Select } from '@ngxs/store';
import { PharmacyInformationFormState } from '../state/pharmacy-information-form.state';
import { SetTotalItemsAndCompletedItems } from '../state/pharmacy-information-form.actions';
import { MedsCheckChildForm } from '../../meds-check-child-form';
import { PharmacistState } from '../../../../pharmacy/state/pharmacist.state';


import { SixCpaState } from '../../state/six-cpa.state';
@Component({
  selector: 'app-pharmacy-information-form',
  templateUrl: './pharmacy-information-form.component.html',
  styleUrls: ['./pharmacy-information-form.component.scss']
})
export class PharmacyInformationFormComponent extends MedsCheckChildForm implements OnInit, OnDestroy {
  @Select(PharmacistState.pharmacists) pharmacists$: Observable<any>;
  pharmacyInformationForm: FormGroup;
  formSubscription: Subscription;
  private readonlySubscription: Subscription;
  constructor(private formBuilder: FormBuilder, private store: Store, actions: Actions) {
    super(actions);
    this.pharmacyInformationForm = this.formBuilder.group({
      pharmacistName: new FormControl('', Validators.required),
      ahpraNumber: new FormControl('', [Validators.required, Validators.maxLength(15)])
    });
  }

  ngOnInit() {
    const pharmacistList = this.store.selectSnapshot(PharmacistState.pharmacists);
    if (pharmacistList.length > 0) {
      const { ahpraNumber, givenNames, familyName } = pharmacistList[0];
      this.pharmacyInformationForm.patchValue({ pharmacistName: `${givenNames} ${familyName}`, ahpraNumber });
    }
    this.formSubscription = this.store.select(PharmacyInformationFormState.pharmacyInformationForm)
      .subscribe(() => this.onFormChange());
    this.readonlySubscription = this.store.select(SixCpaState.formsReadOnly)
      .subscribe(readonly => readonly ? this.pharmacyInformationForm.disable() : this.pharmacyInformationForm.enable());
  }

  ngOnDestroy() {
    if (this.formSubscription) { this.formSubscription.unsubscribe(); }
    if (this.readonlySubscription) { this.readonlySubscription.unsubscribe(); }
  }
  get formControls() {
    return this.pharmacyInformationForm.controls;
  }
  protected actionOnResetForm() {
    this.pharmacyInformationForm.reset();
  }

  private onFormChange() {
    const controls = Object.keys(this.pharmacyInformationForm.controls);
    const completedItems = this.getTotalValidItems(controls);
    this.store.dispatch(new SetTotalItemsAndCompletedItems(controls.length, completedItems));
  }

  private getTotalValidItems(controls: Array<string>) {
    return controls.filter((key) => {
      const formControl = this.pharmacyInformationForm.get(key);
      return  formControl.status === 'VALID' || (formControl.status === 'DISABLED' && !formControl.invalid && formControl.errors === null);
    }).length;
  }

  setAhpraNumber() {
    const pharmacist = this.store.selectSnapshot(PharmacistState.pharmacists)
      .filter((x) => `${x.givenNames} ${x.familyName}` === this.pharmacyInformationForm.value.pharmacistName);
    this.pharmacyInformationForm.controls['ahpraNumber'].setValue(pharmacist[0].ahpraNumber ? pharmacist[0].ahpraNumber : 'NIL');
  }
}
