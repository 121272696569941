import { Component, OnInit } from '@angular/core';
import { Store, Select } from '@ngxs/store';
import { CloseCipherModalAction } from '../state/cipher.actions';
import { CipherState, CipherStateModel } from '../state/cipher.state';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-cipher',
  templateUrl: './cipher.component.html',
  styleUrls: ['./cipher.component.scss']
})
export class CipherComponent implements OnInit {
  @Select(CipherState) cipher$: Observable<CipherStateModel>;
  constructor(private store: Store) { }

  ngOnInit() {
  }

  closeModal() {
    this.store.dispatch(new CloseCipherModalAction());
  }
}
