import { Component, OnInit } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { Group } from '../../../../shared/models/group.model';
import { GroupPageState } from '../../../../settings/group-page/state/group-page.state';
import { TriggersState, TriggersStateModel } from '../state/triggers.state';
import { Sort } from '../../../../shared/models/sort.enum';

@Component({
  selector: 'app-comms-reminders',
  templateUrl: './comms-reminders.component.html',
  styleUrls: ['./comms-reminders.component.scss']
})
export class CommsRemindersComponent implements OnInit {
  @Select(TriggersState.state) triggerState$: Observable<TriggersStateModel>;
  @Select(GroupPageState.groups) groups$: Observable<Group[]>
  sortByTypes = Sort;
  sortBy = Sort.Alphabetical;
  
  constructor() { }

  ngOnInit() {
  }

}
