import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CategoriesComponent } from './categories.component';
import { FormsModule } from '@angular/forms';
import { MultipleSelectModule } from './multiple-select/multiple-select.module';
import { CategoriesState } from './state/categories.state';
import { NgxsModule } from '@ngxs/store';
import { CategoriesService } from './services/categories.service';
import { BladeModule } from '../../core/blade/blade.module';
import { ManageMetaModule } from './manage-meta/manage-meta.module';
import { DosageHistoryModule } from './dosage-history/dosage-history.module';
import { StockAdjustmentModule } from './stock-adjustment/stock-adjustment.module';
import { ScriptActionsState } from './state/script-actions.state';
import { BulkStockAdjustmentModule } from './bulk-stock-adjustment/bulk-stock-adjustment.module';
import { InstoreOrderModule } from './instore-order/instore-order.module';
import { TotalDohClassDirective } from './directives/total-doh-class.directive';
import { LoaderModule } from '../../core/loader/loader.module';
import { ProfileModule } from '../../core/profile/profile.module';
import { RouterModule } from '@angular/router';
import { TimezoneModule } from '../../core/timezone/timezone.module';
import { SixCpaModule } from '../six-cpa/six-cpa.module';
import { OrderDohModule } from '../../../shared/order-doh/order-doh.module';
import { ScryptInsightModule } from '../../../shared/scrypt-insight/scrypt-insight.module';
import { PauseMedicationModule } from './pause-medication/pause-medication.module';
import { PausePopupModule } from './pause-medication/pause-popup/pause-popup.module';
import { ViewEditPausedMedicationsModule } from './pause-medication/view-edit-paused-medications/view-edit-paused-medications.module';
import { PauseMedicationState } from './pause-medication/state/pause-medication.state';
import { BulkDispenseState } from './multiple-select/bulk-dispense/bulk-dispense.state';
import { ScryptSMSModule } from '../../../shared/components/scrypt-sms/scrypt-sms.module';
import { ScryptSmsState } from '../../../shared/components/scrypt-sms/state/scrypt-sms.state';
import { ScriptHistoryModule } from './script-history/script-history.module';
import { UiSwitchModule } from 'ngx-ui-switch';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { AslMedicationsModule } from '../asl-categories/asl-medications.module';

@NgModule({
  declarations: [
    CategoriesComponent,
    TotalDohClassDirective,
  ],
  providers: [
    CategoriesService
  ],
  imports: [
    RouterModule,
    CommonModule,
    TimezoneModule,
    FormsModule,
    ProfileModule,
    NgxsModule.forFeature([
      CategoriesState,
      ScriptActionsState,
      PauseMedicationState,
      BulkDispenseState,
      ScryptSmsState
    ]),
    BladeModule,
    MultipleSelectModule,
    LoaderModule,
    ManageMetaModule,
    DosageHistoryModule,
    StockAdjustmentModule,
    BulkStockAdjustmentModule,
    InstoreOrderModule,
    SixCpaModule,
    OrderDohModule,
    ScryptInsightModule,
    PauseMedicationModule,
    PausePopupModule,
    ViewEditPausedMedicationsModule,
    ScryptSMSModule,
    ScriptHistoryModule,
    UiSwitchModule,
    BsDatepickerModule.forRoot(),
    AslMedicationsModule
  ],
  exports: [
    CategoriesComponent
  ]
})
export class CategoriesModule { }
