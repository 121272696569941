<ng-container *ngIf="(displayBreadCrumbs | async) && links?.length">
<section>
    <div class="breadcrumbs-block" g-xs="py4">
        <ul class="breadcrumbs flex color__blue-text">
            <li *ngFor="let item of links; let idx = index;">
                <a href="#">{{item}} <span *ngIf="idx < links.length-1" g-xs="px1">/</span></a>
            </li>
        </ul>
    </div>
</section>
</ng-container>
