import { HMRGPDetailsReferralFormModel } from '~/shared/models/six-cpa/HMR/hmr-gp-details-referral-form.model';

export enum HMRGPDetailsReferralFormActions {
  UPDATE_PROGRESS = '[HMRGPDetailsReferralForm] Update Progress',
  UPDATE = '[HMRGPDetailsReferralForm] Update'
}

export class SetTotalItemsAndCompletedItems {
  static readonly type = HMRGPDetailsReferralFormActions.UPDATE_PROGRESS;
  constructor(public totalItems: number, public completedItems: number) { }
}

export class UpdateHMRGPDetailsReferralForm {
  static readonly type = HMRGPDetailsReferralFormActions.UPDATE;
  constructor(public form: Partial<HMRGPDetailsReferralFormModel>) { }
}
