import { ToggleBladeAction } from '../../../../core/blade/state/blade.actions';

export const INSTORE_ORDER_BLADE_NAME = '[InstoreOrder]';

export enum InstoreOrderActionTypes {
  OPEN = '[InstoreOrder] Open',
  CLOSE = '[InstoreOrder] Close',
  ORDER_REQUEST = '[InstoreOrder] Request',
  ORDER_RESPONSE = '[InstoreOrder] Response',
  ORDER_ERROR = '[InstoreOrder] Error',
}

export class OpenInstoreOrderAction extends ToggleBladeAction {
  static readonly type = InstoreOrderActionTypes.OPEN;
  constructor() { super(INSTORE_ORDER_BLADE_NAME); }
}

export class CloseInstoreOrderAction extends ToggleBladeAction {
  static readonly type = InstoreOrderActionTypes.CLOSE;
  constructor() { super(INSTORE_ORDER_BLADE_NAME); }
}

export class InstoreOrderRequestAction {
  static readonly type = InstoreOrderActionTypes.ORDER_REQUEST;
  constructor(public clientId: string, public request: any) {}
}

export class InstoreOrderResponseAction {
  static readonly type = InstoreOrderActionTypes.ORDER_RESPONSE;
  constructor(public clientId: string) { }
}

export class InstoreOrderErrorAction {
  static readonly type = InstoreOrderActionTypes.ORDER_ERROR;
  constructor(public error: any) {}
}
