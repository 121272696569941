import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoaderModule } from '~/modules/core/loader/loader.module';
import { BulkMessagingRecipientsModalComponent } from './bulk-messaging-recipients-modal.component';
import { NzTableModule } from 'ng-zorro-antd/table';
import { TimezoneModule } from '~/modules/core/timezone/timezone.module';
@NgModule({
  declarations: [
    BulkMessagingRecipientsModalComponent
  ],
  imports: [
    CommonModule,
    LoaderModule,
    NzTableModule,
    TimezoneModule
  ],
  exports: [
    BulkMessagingRecipientsModalComponent
  ] 
})
export class BulkMessagingRecipientsModalModule { }
