import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Select } from '@ngxs/store';
import { SickLeavePharmacistDeclarationState } from '../sick-leave-pharmacist-declaration/state/sick-leave-pharmacist-declaration.state';

@Component({
  selector: 'app-sick-leave-pharmacist-declaration-list-item',
  templateUrl: './sick-leave-pharmacist-declaration-list-item.component.html'
})
export class SickLeavePharmacistDeclarationListItemComponent implements OnInit {
  @Select(SickLeavePharmacistDeclarationState) sickLeavePharmacistDeclarationForm$: Observable<any>;

  constructor() { }

  ngOnInit() {
  }

}
