import { MessageSource } from "../types/communications";

export class Message {
  constructor(
    public clientID: string,
    public messageText: string
  ) { }
}

export class MessageDetailAttachment {
  constructor(
    public isLink: boolean,
    public mimeType: string,
    public attachment: string
  ) { }
}

export enum MessageChannel {
  Undefined,
  Sms,
  Email,
  App,
  Push
}

export class SendMessageDetailResponse {
  constructor(
    public messageDetail: MessageDetail,
    public lastMessageTimestamp: string
  ) { }
}

export class MessageDetail {
  constructor(
    public id: string,
    public subject: string,
    public content: string,
    public date: string,
    public isReplied: Boolean,
    public isRead: Boolean,
    public customerId: string,
    public channel: MessageChannel,
    public attachments: Array<MessageDetailAttachment>,
    public firstName: string,
    public lastName: string,
    public name: string,
    public source: MessageSource,
    public isFlagged: boolean,
    public inboxMessageId: number,
    public sourceNumber?: string
  ) { }
}

export class MailboxResult {
  constructor(
    public total: number,
    public page: number,
    public pageSize: number,
    public items: MailboxMessage[]
  ) { }
}

export class MailboxMessage {
  constructor(
    public id: number,
    public messageId: string,
    public firstName: string,
    public lastName: string,
    public content: string,
    public type: string,
    public isRead: boolean,
    public isReplied: boolean,
    public isFlagged: boolean,
    public hasAttachment: boolean,
    public isArchived: boolean,
    public channel: number,
    public selected: boolean,
    public sendDateTime: Date,
    public tooltipText: string,
    public customerId: string,
    public receipientAddress: string
  ) { }
}

export class FilteredSearch {
  constructor(
    public Page: number,
    public PageSize: number,
    public Sort: string,
    public StartDate: string,
    public EndDate: string,
    public Keyword: string,
    public Channel: string[],
    public Type: string[],
    public Group: string[],
    public Filter: string[]
  ) { }
}

export class ScheduledMessage {
  constructor(
    public clientID: string,
    public text: string,
    public sendOn: string,
    public type: CommunicationType,
    public status?: ScheduledMessageStatus
  ) { }
}

export enum CommunicationType {
  SMS,
  Email,
  MessageCycleSMS
}

export enum ScheduledMessageStatus {
  Pending,
  Failed
}
