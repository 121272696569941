import { State, Action, StateContext, Selector } from '@ngxs/store';
import { SickLeaveConsulationFormModel } from '../../../../../../shared/models/six-cpa/sick-leave/sick-leave-consultation-form.model';
import { SetSickLeaveConsulationValidAction, SetTotalItemsAndCompletedItems, UpdateSickLeaveConsulation } from './sick-leave-consulation.actions';
import * as moment_ from 'moment';
const moment = moment_;
import { SickLeaveExperiencedSymptomsPeriods } from '../../../../../../shared/models/six-cpa/FormKeyValuePairs';
import { Injectable } from '@angular/core';

export class SickLeaveConsulationStateModel {
  sickLeaveConsulationForm: {
    model: Partial<SickLeaveConsulationFormModel>
  };
  valid: boolean;
  totalItems: number;
  completedItems: number;
}
@Injectable()
@State<SickLeaveConsulationStateModel>({
  name: 'sickLeaveConsulation',
  defaults: {
    sickLeaveConsulationForm: {
      model: undefined
    },
    valid: false,
    completedItems: 0,
    totalItems: 0
  }
})
export class SickLeaveConsulationState {
  @Selector()
  static sickLeaveConsulationForm(state: SickLeaveConsulationStateModel) { return state.sickLeaveConsulationForm.model; }

  @Selector()
  static totalItems(state: SickLeaveConsulationStateModel) { return state.totalItems; }

  @Selector()
  static completedItems(state: SickLeaveConsulationStateModel) { return state.completedItems; }

  @Action(SetSickLeaveConsulationValidAction)
  setValid(ctx: StateContext<SickLeaveConsulationStateModel>, action: SetSickLeaveConsulationValidAction) {
    ctx.patchState({
      valid: action.valid
    });
  }

  @Action(SetTotalItemsAndCompletedItems)
  setProgress(ctx: StateContext<SickLeaveConsulationStateModel>, action: SetTotalItemsAndCompletedItems) {
    ctx.patchState({
      totalItems: action.totalItems,
      completedItems: action.completedItems
    });
  }

  @Action(UpdateSickLeaveConsulation)
  updateForm(ctx: StateContext<SickLeaveConsulationStateModel>, { form }: UpdateSickLeaveConsulation) {
    if (form) {
      let howLongExperiencedSymptomsInterval = 1;
      let howLongExperiencedSymptomsPeriod = SickLeaveExperiencedSymptomsPeriods.Days;
      if (form.howLongExperiencedSymptoms !== null) {
        const symptoms = form.howLongExperiencedSymptoms.split(' ');
        if (symptoms.length === 2) {
          howLongExperiencedSymptomsInterval = +symptoms[0];
          howLongExperiencedSymptomsPeriod = SickLeaveExperiencedSymptomsPeriods[symptoms[1]];
        }
      }
      ctx.patchState({
        sickLeaveConsulationForm: {
          model: {
            id: form.id,
            dateCertificateFrom: moment(form.dateCertificateFrom).toDate(),
            dateCertificateTo: moment(form.dateCertificateTo).toDate(),
            describeVisualSymptoms: form.describeVisualSymptoms,
            hasPersonConsultedADoctor: form.hasPersonConsultedADoctor,
            haveReferredToAnyOtherHealthCareProfessional: form.haveReferredToAnyOtherHealthCareProfessional,
            howLongExperiencedSymptoms: form.howLongExperiencedSymptoms,
            howLongExperiencedSymptomsInterval,
            howLongExperiencedSymptomsPeriod,
            isAMedicationBeingProvidedByYou: form.isAMedicationBeingProvidedByYou,
            isThereAPriorCertificate: form.isThereAPriorCertificate,
            satisfiedThePersonIsUnfitForWork: form.satisfiedThePersonIsUnfitForWork,
            whatIsTheIllness: form.whatIsTheIllness,
            detailOfConsultation: form.detailOfConsultation,
            dateOfCertificate: moment(form.dateOfCertificate).toDate(),
            medicationDetail: form.medicationDetail,
            referralDetail: form.referralDetail,
          }
        }
      });
    } else {
      ctx.patchState({
        sickLeaveConsulationForm: {
          model: undefined
        }
      });
    }

  }
}


