import { State, Action, StateContext, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { SaveOrUpdateMedicationListAction } from './medication-list-save.action';
import { filter, map } from 'rxjs/operators';
import { switchMap } from 'rxjs/operators';
import { forkJoin } from 'rxjs';
import { MedicationListFormsProgressService } from './medication-list-forms-progress.service';
import { MedicationListFormsService } from './medication-list-forms.service';
import { SubmitExisitingMedicationList, SubmitNewMedicationList } from './medication-list-form.actions';
import { tap } from 'rxjs/operators';
import { ProfileState } from '@base/modules/core/profile/state/profile.state';

export class MedicationListSaveStateModel {
}
@Injectable()
@State<MedicationListSaveStateModel>({
  name: 'medicationListSave',
  defaults: {
  }
})
export class MedicationListSaveState {
  constructor(private store: Store) { }

  @Action(SaveOrUpdateMedicationListAction)
  saveOrUpdateMedicationList(ctx: StateContext<MedicationListSaveStateModel>, action: SaveOrUpdateMedicationListAction) {
    return this.store.selectOnce(MedicationListFormsProgressService.getProgress).pipe(
        filter(progress => progress === 100),
        switchMap(() => forkJoin([
          this.store.selectOnce(MedicationListFormsService.getMedicationListRequest),
          this.store.selectOnce(ProfileState.clientId)
        ])),
        map(([formData, clientId]) =>
          formData && formData.sixCpaFormId !== null
            ? new SubmitExisitingMedicationList(clientId, formData)
            : new SubmitNewMedicationList(clientId, formData)),
        tap(dispatch => ctx.dispatch(dispatch))
        );
  }
}
