
export interface DispenseRunRequestModel {
  type: ReportType;
  groups: number[];
  selectedCategories: number[];
  targetDoh: number | null;
  preFetchedClients: any;
  clientId: string | null;
  dispenseInterval: number |  null;
  excludeNoRepeats: boolean | null;
}

export enum ReportType {
    dispense,
    request
}