import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';
import { StockState } from './stock.state';
import { BaseApiModule } from '../../../shared/core/base-api/base-api.module';
import { StockRepository } from './stock.repository';

@NgModule({
  imports: [
    BaseApiModule,
    NgxsModule.forFeature([StockState])
  ],
  providers: [
    StockRepository
  ]
})
export class StockModule {}
