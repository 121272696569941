import { Component, OnInit } from '@angular/core';
import { Select } from '@ngxs/store';
import { AdditionalDetailsState } from '../state/additional-details.state';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-additional-details-form-list-item',
  templateUrl: './additional-details-form-list-item.component.html'
})
export class AdditionalDetailsFormListItemComponent implements OnInit {

  @Select(AdditionalDetailsState) additionalDetails$: Observable<any>;

  constructor() { }

  ngOnInit() {
  }

}
