import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { BaseApi } from '../core/base-api/base-api';
import { Message, ScheduledMessage } from '../models/message.model';
import { SmsMessageHistory } from '../models/messageHistory.model';
import { Sms } from '../models/sms.model';
import { Router } from '@angular/router';
import { StorageService } from '../core/storage/storage.service';
import { AlertService } from '../../modules/core/alert/alert.service';
import { MarketingSetting } from '../models/marketingSetting.model';
import { TemplateType } from '../../shared/models/pharmacy/comm-template.model';

@Injectable()
export class SmsService extends BaseApi {
  API = '';

  constructor(
    @Inject('environment') env: any,
    public http: HttpClient,
    public router: Router,
    public storageService: StorageService,
    public alertService: AlertService
  ) {
    super(http, router, storageService, alertService);
    this.API = env.baseApiUrl;
  }

  getMessageTypes(clientId: string): Observable<MarketingSetting[]> {
    return this.get(
      `${this.API}/api/marketing/tenant/sms-templates/${clientId}`
    );
  }

  getTemplatesByType(
    clientId: string,
    type: TemplateType
  ): Observable<MarketingSetting[]> {
    return this.get(
      `${this.API}/api/marketing/${clientId}/templates-by-type/${type}`
    );
  }

  getMessageHistory(clientId: string): Observable<SmsMessageHistory[]> {
    return this.get(`${this.API}/api/GetMessageHistory/${clientId}`);
  }

  sendMessage(message: Message) {
    return this.post(`${this.API}/api/SendMessage`, message);
  }

  scheduleMessage(message: ScheduledMessage) {
    return this.post(`${this.API}/api//scheduled-message/create`, message);
  }

  getSmsList(): Observable<Sms[]> {
    return this.get(`${this.API}/api/GetSmsList`);
  }
}
