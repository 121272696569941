import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { SickLeaveCertificateTypeState } from '../sick-leave-certificate-type/state/sick-leave-certificate-type.state';
import { Select } from '@ngxs/store';

@Component({
  selector: 'app-sick-leave-certificate-type-list-item',
  templateUrl: './sick-leave-certificate-type-list-item.component.html'
})
export class SickLeaveCertificateTypeListItemComponent implements OnInit {
  @Select(SickLeaveCertificateTypeState) sickLeaveCertificateTypeForm$: Observable<any>;

  constructor() { }

  ngOnInit() {
  }

}
