// import { environment } from '../../../../../../environments/environment';
import { Inject, Injectable } from '@angular/core';
import { BaseApi } from '../../../../../shared/core/base-api/base-api';
import { Router } from '@angular/router';
import { StorageService } from '../../../../../shared/core/storage/storage.service';
import { AlertService } from '../../../../core/alert/alert.service';
import { ClinicalInterventionFormModel } from '../../../../../shared/models/six-cpa/clinical-intervention-form.model';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class ClinicalInterventionService extends BaseApi {
  API = '';

  constructor(
    @Inject('environment') env: any,
    public http: HttpClient,
    public router: Router,
    public storageService: StorageService,
    public alertService: AlertService
  ) {
    super(http, router, storageService, alertService);
    this.API = env.baseApiUrl;
  }

  postSave(clientId: string, formData: ClinicalInterventionFormModel) {
    return this.post<ClinicalInterventionFormModel>(
      `${this.API}api/clinical-intervention/${clientId}/save`,
      formData
    );
  }

  postSubmit(clientId: string, formData: ClinicalInterventionFormModel) {
    return this.post<ClinicalInterventionFormModel>(
      `${this.API}/api/clinical-intervention/${clientId}/submit`,
      formData
    );
  }

  putUpdate(clientId: string, formData: ClinicalInterventionFormModel) {
    return this.put(
      `${this.API}/api/clinical-intervention/${clientId}/update-save`,
      formData
    );
  }

  putSubmit(clientId: string, formData: ClinicalInterventionFormModel) {
    return this.put(
      `${this.API}/api/clinical-intervention/${clientId}/update-submit`,
      formData
    );
  }

  getCIForm(clientId: string, sixCpaFormId: number): Observable<any> {
    return this.get<any>(
      `${this.API}/api/clinical-intervention/${clientId}/form/${sixCpaFormId}`
    );
  }
}
