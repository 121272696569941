import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { LoaderModule } from "~/modules/core/loader/loader.module";
import { GlobalTemplateComponent } from "./global-template.component";
import { NzSelectModule } from "ng-zorro-antd/select";


@NgModule({
    declarations: [
        GlobalTemplateComponent
    ],
    imports: [
        FormsModule,
        CommonModule,
        ReactiveFormsModule,
        LoaderModule,
        NzSelectModule
    ],
    exports: [
        GlobalTemplateComponent
    ]
})
export class GlobalTemplateModule { }
