import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomerOrderRoutingModule } from './customer-order-routing.module';
import { SystemModule } from '~/system/system.module';
import { DashboardModule } from '~/modules/dashboard/dashboard.module';
import { AuthModule } from '~/modules/core/auth/auth.module';
import { CustomerOrderRouteComponent } from './customer-order-route.component';

@NgModule({
  declarations: [CustomerOrderRouteComponent],
  imports: [
    CommonModule,
    AuthModule,
    CustomerOrderRoutingModule,
    SystemModule,
    DashboardModule
  ]
})
export class CustomerOrderRouteModule { }
