import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { StorageService } from '../core/storage/storage.service';
import { AlertService } from '~/modules/core/alert/alert.service';
import { CrudService } from './crud.service';
import { environment } from '../../../environments/environment';

const API = environment.baseApiUrl;

@Injectable()
export class VerifyService extends CrudService {

  constructor(public http: HttpClient, public router: Router, public storageService: StorageService, public alertService: AlertService) {
    super(http, router, storageService, alertService, 'tenant');
  }

  verifyCustomer(customerProfileId: number) {
    return this.post(`${API}/api/verification/customer/${customerProfileId}`);
  }
}
