<form [formGroup]="hmrConsentAndEligibilityForm" ngxsForm="hmrConsentAndEligibility.hmrConsentAndEligibilityForm"
  class="p-4">
  <div class="form-group pb-1">
    <label class="input-label mb-0">Written Patient Consent Held</label><br />
    <app-radio-highlight [hasError]="formControls.writtenConsentForProvisionOfData.invalid && (showInvalid$ | async)"
      controlName="writtenConsentForProvisionOfDataYes"
      [isHighlighted]="hmrConsentAndEligibilityForm.value.writtenConsentForProvisionOfData == true">
      <input class="form-check-input" type="radio" name="writtenConsentForProvisionOfData"
        id="writtenConsentForProvisionOfDataYes" formControlName="writtenConsentForProvisionOfData" [value]="true"
        checked>
      <label class="form-check-label" for="writtenConsentForProvisionOfDataYes">Yes</label>
    </app-radio-highlight>
    <app-radio-highlight [hasError]="formControls.writtenConsentForProvisionOfData.invalid && (showInvalid$ | async)"
      controlName="writtenConsentForProvisionOfDataNo"
      [isHighlighted]="hmrConsentAndEligibilityForm.value.writtenConsentForProvisionOfData == false">
      <input class="form-check-input" type="radio" name="writtenConsentForProvisionOfData"
        id="writtenConsentForProvisionOfDataNo" formControlName="writtenConsentForProvisionOfData" [value]="false">
      <label class="form-check-label" for="writtenConsentForProvisionOfDataNo">No</label>
    </app-radio-highlight>
  </div>
</form>