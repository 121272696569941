import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SixCpaFormListItemChecklistModule } from '../six-cpa-form-list-item-checklist/six-cpa-form-list-item-checklist.module';
import { OutcomesAndActionsFormModule } from '../outcomes-and-actions-form/outcomes-and-actions-form/outcomes-and-actions-form.module';
import { OutcomesAndActionsFormListItemComponent } from './outcomes-and-actions-form-list-item.component';


@NgModule({
    declarations: [
        OutcomesAndActionsFormListItemComponent
    ],
    imports: [
        CommonModule,
        SixCpaFormListItemChecklistModule,
        OutcomesAndActionsFormModule
    ],
    exports: [
        OutcomesAndActionsFormListItemComponent
    ]
})
export class OutcomesAndActionsFormListItemModule { }