import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxsModule } from '@ngxs/store';
import { SixCpaFormListItemChecklistModule } from '../../six-cpa-form-list-item-checklist/six-cpa-form-list-item-checklist.module';
import { MedicationProfileListItemModule } from '../../medication-profile/medication-profile-list-item/medication-profile-list-item.module';
import { DaaMedicationProfileComponent } from './daa-medication-profile.component';
import { DAAMedicationProfileState } from './state/daa-medication-profile.state';

@NgModule({
  declarations: [DaaMedicationProfileComponent],
  imports: [
    CommonModule,
    NgxsModule.forFeature([
      DAAMedicationProfileState
    ]),
    SixCpaFormListItemChecklistModule,
    MedicationProfileListItemModule
  ],
  exports: [
    DaaMedicationProfileComponent
  ]
})
export class DAAMedicationProfileModule { }
