import { ConsentAndEligibilityFormModel } from '../../../../../shared/models/six-cpa/consent-and-eligibility-form.model';

export enum UpdateConsentEligibilityDetailedFormActions {
  UPDATE = '[ConsentEligibilityFormDetailed] Update Form'
}

export class UpdateConsentEligibilityDetailedForm {
  static readonly type = UpdateConsentEligibilityDetailedFormActions.UPDATE;
  constructor(public form: Partial<ConsentAndEligibilityFormModel>) { }
}
