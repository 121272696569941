import { Component, OnInit } from '@angular/core';
import { Select } from '@ngxs/store';
import { PatientSignatureState } from '../state/patient-signature.state';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-patient-signature-form-list-item',
  templateUrl: './patient-signature-form-list-item.component.html'
})
export class PatientSignatureFormListItemComponent implements OnInit {

  @Select(PatientSignatureState) patientSignature$: Observable<any>;

  constructor() { }

  ngOnInit() {
  }

}
