import { Medication } from '../../../../shared/models/script/chartviewitem.model';

export enum ScriptActionsActionTypes {
  TOGGLE = '[Script Actions] toggle',
  ADD_DOSAGE_REQUEST = '[Script Actions] Add dosage request',
  ADD_DOSAGE_ERROR = '[Script Actions] Add dosage error',
  CREATE_META_REQUEST = '[Script Actions] Create meta request',
  CREATE_META_ERROR = '[Script Actions] Create meta error',
  UPDATE_SCRIPT = '[Script Actions] Update Script',
  DELETE_METAS_REQUEST = '[Script Actions] Delete metas request',
  UPDATE_SCRIPT_PAUSED_STATE = '[Script Actions] Update Script Paused',
  RESET_TO_DEFAULT = '[Script Actions] Reset to default',
}

export class ResetScriptToDefault {
  static readonly type = ScriptActionsActionTypes.RESET_TO_DEFAULT;
  constructor() { }
}

export class UpdateScriptActionsAction {
  static readonly type = ScriptActionsActionTypes.UPDATE_SCRIPT;
  constructor(public script: any) { }
}

export class UpdateScriptPausedState {
  static readonly type = ScriptActionsActionTypes.UPDATE_SCRIPT_PAUSED_STATE;
  constructor(public paused: boolean) { }
}

export class ToggleScriptActionsAction {
  static readonly type = ScriptActionsActionTypes.TOGGLE;
  constructor(public script: any) { }
}

export class AddDosageRequestAction {
  static readonly type = ScriptActionsActionTypes.ADD_DOSAGE_REQUEST;
  constructor(public dosageForm: any) {}
}

export class AddDosageErrorAction {
  static readonly type = ScriptActionsActionTypes.ADD_DOSAGE_ERROR;
  constructor(public error: any, public script: any) {}
}

export class CreateMetaRequestAction {
  static readonly type = ScriptActionsActionTypes.CREATE_META_REQUEST;
  constructor(public script: Medication, public metaForm: any) {}
}

export class CreateMetaErrorAction {
  static readonly type = ScriptActionsActionTypes.CREATE_META_ERROR;
  constructor(public error: any, public scripts: any[]) {}
}

export class ResetMetasRequestAction {
  static readonly type = ScriptActionsActionTypes.DELETE_METAS_REQUEST;
  constructor(public scripts: any[]) {}
}
