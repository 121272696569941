import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationEnd, NavigationStart, NavigationCancel, NavigationError } from '@angular/router';
import { UserService } from '../shared/services/user.service';
import { Select, Store } from '@ngxs/store';
import { ContentContainerState } from '~/modules/state/content-container/content-container.state';
import { Observable, Unsubscribable, interval } from 'rxjs';
import { isLoadingRoute } from '~/modules/core/routing/routing.logic';
import { PushNotificationsService } from '~/modules/core/push-notifications/push-notifications.service';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { ReceivePushNotificationAction } from '~/modules/core/push-notifications/push-notifications.actions';
import { GetFeaturesAction } from '~/modules/core/features/features.actions';
import { AddContentContainerClassAction, RemoveContentContainerClassAction } from '~/modules/state/content-container/content-container.actions';

declare var window: any;

@Component({
  selector: 'app-system',
  templateUrl: './system.component.html',
  styleUrls: ['./system.component.scss']
})
export class SystemComponent implements OnInit, OnDestroy {
  @Select(ContentContainerState.classValue) contentContainerClasses$: Observable<Set<string>>;

  links: any[] = [];
  pageLoading = false;
  showBorder = false;
  isFullWidth = true;
  isLoading = false;

  private subscriptions: Unsubscribable[] = [];
  private pagesWithFullWidth: string[] = ['communications', 'report', 'analytics', 'dispense-run', 'manage'];
  private pagesWithNoBorder: string[] = ['communication', 'dashboard', 'manage'];

  @Dispatch() receivePushMessage = (message: any) => new ReceivePushNotificationAction(message);

  constructor(
    private router: Router,
    private userService: UserService,
    private pushNotificationService: PushNotificationsService,
    private store: Store
  ) {
    this.checkNavigationLoading();
  }


  ngOnInit() {
    this.checkIsRouteLoading();
    this.setGlobalStyles();
    this.updateIntercom();
    this.subscribeToPushNotifications();
    this.store.dispatch(new GetFeaturesAction());
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  private checkIsRouteLoading() {
    this.subscriptions
      .push(isLoadingRoute(this.router, this.isLoading).subscribe(isLoading => this.isLoading = isLoading));
  }

  private setGlobalStyles() {
    this.setContainerAndBorder();
    this.subscriptions.push(this.router.events.subscribe(route => { // subscribe to changes
      if (route instanceof NavigationEnd) {
        this.setContainerAndBorder();
      }
    }));
  }

  private setContainerAndBorder(){
    this.isFullWidth = this.pagesWithFullWidth.some(page => this.router.url.toLowerCase().includes(page));
    if (this.isFullWidth){
      this.store.dispatch(new RemoveContentContainerClassAction('fixed-width'))
    }
    else {
      this.store.dispatch(new AddContentContainerClassAction('fixed-width'))
    }

    //checking full width pages so that includes the settings as well as the mailbox
    this.showBorder = this.pagesWithNoBorder.every(page => !this.router.url.toLowerCase().includes(page));
  }

  private checkNavigationLoading() {
    this.subscriptions.push(
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.pageLoading = true;
      } else if (
        event instanceof NavigationEnd ||
        event instanceof NavigationCancel ||
        event instanceof NavigationError
        ) {
        this.pageLoading = false;
    }
    }));
  }

  private updateIntercom() {
    this.subscriptions.push(this.userService.getLoggedInUser()
      .subscribe(user => {
        window.Intercom('Shutdown');

        window.Intercom('boot', {
          app_id: 'kow7asty',
          name: user.name, // Full name
          email: user.email, // Email address
          created_at: Date.now().valueOf() // Returns unix timestamp
        });
        window.Intercom('update');
    }));
  }

  private subscribeToPushNotifications() {
    this.subscriptions.push(this.pushNotificationService.receiveMessage()
      .subscribe((message) => this.receivePushMessage(message)));
  }
}
