import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { HMRFormState, HMRFormType } from './state/hmr-form.state';
import { Observable, Subscription, forkJoin } from 'rxjs';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { SetHMRFormType, SubmitExisitingHMR, SubmitNewHMR, SaveExisitingHMR, SaveNewHMR, OpenHMRConfirmForm, ShowInvalidHMRForm, ResetHMRFormListAction } from './state/hmr-form.actions';
import { switchMap, withLatestFrom, filter, map } from 'rxjs/operators';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { HMRFormsProgressService } from './state/hmr-forms-progress.service';
import { HMRFormsService } from './state/hmr-forms.service';
import { ProfileState } from '../../../../core/profile/state/profile.state';
import { WindowService } from '../../../../../shared/services/window.service';
import { SaveOrUpdateHMRFormAction } from './state/hmr-form-save.actions';
import { SixCpaState } from '../../state/six-cpa.state';

@Component({
  selector: 'app-hmr-form-list',
  templateUrl: './hmr-form-list.component.html',
  styleUrls: ['./hmr-form-list.component.scss']
})
export class HMRFormListComponent implements OnInit, OnDestroy {
  @Select(HMRFormState.hmrFormType) hmrFormType$: Observable<HMRFormType>;
  @Select(HMRFormState.saving) saving$: Observable<boolean>;
  @Select(SixCpaState.readonly) readonly$: Observable<boolean>;
  hmrFormList: FormGroup;
  formSubscription: Subscription;
  hmrFormTypes = HMRFormType;
  private readonlySubscription: Subscription;

  constructor(
    private formBuilder: FormBuilder,
    private store: Store,
    private cdr: ChangeDetectorRef,
    private windowService: WindowService
  ) { }

  ngOnInit() {
    this.initForm();
    this.initFormChangesSubscription();
    this.initReadonlySubscription();
  }

  ngOnDestroy() {
    if (this.formSubscription) {
      this.formSubscription.unsubscribe();
    }
    if (this.readonlySubscription) {
      this.readonlySubscription.unsubscribe();
    }
  }

  openPPAPortal() {
    // this.windowService.open(environment.ppaPortalLink);
    this.windowService.open('');
  }

  resetHMRFormList() {
    this.store.dispatch(new ResetHMRFormListAction());
  }

  private initForm() {
    this.hmrFormList = this.formBuilder.group({
      hmrFormType: new FormControl(HMRFormType.NewClaim, Validators.required)
    });
  }

  private initFormChangesSubscription() {
    this.formSubscription = this.hmrFormList.get('hmrFormType').valueChanges.pipe(
      switchMap((hmrFormType) => this.store.dispatch(new SetHMRFormType(hmrFormType)).pipe(
        withLatestFrom(this.store.select(HMRFormState.hmrFormType)))))
      .subscribe(() => { this.cdr.detectChanges(); });
  }

  private initReadonlySubscription() {
    this.readonlySubscription = this.store.select(SixCpaState.formsReadOnly)
      .subscribe(readonly => readonly ? this.hmrFormList.disable() : this.hmrFormList.enable());
  }

  @Dispatch()
  confirmHMR = () => {
    if (this.store.selectSnapshot(HMRFormsProgressService.getHMRNewClaimProgress) !== 100) {
      this.store.dispatch(new ShowInvalidHMRForm());
    }
    return this.store.selectOnce(HMRFormsProgressService.getHMRNewClaimProgress).pipe(
      filter(progress => progress === 100),
      map(() => new OpenHMRConfirmForm()));
    }

  @Dispatch()
  submitHMRNewClaim = () => {
    if (this.store.selectSnapshot(HMRFormsProgressService.getHMRNewClaimProgress) !== 100) {
      this.store.dispatch(new ShowInvalidHMRForm());
    }
    return this.store.selectOnce(HMRFormsProgressService.getHMRNewClaimProgress).pipe(
      filter(progress => progress === 100),
      switchMap(() => forkJoin([
        this.store.selectOnce(HMRFormsService.getHMRNewClaimRequest),
        this.store.selectOnce(ProfileState.clientId)
      ])),
      map(([formData, clientId]) =>
        formData && formData.sixCpaFormId !== null
          ? new SubmitExisitingHMR(clientId, formData)
          : new SubmitNewHMR(clientId, formData)));
    }
  @Dispatch()
  submitHMRInterviewRegisteredPhamacist = () => {
    if (this.store.selectSnapshot(HMRFormsProgressService.getHMRInterviewRegisteredPhamacistProgress) !== 100) {
      this.store.dispatch(new ShowInvalidHMRForm());
    }
    return this.store.selectOnce(HMRFormsProgressService.getHMRInterviewRegisteredPhamacistProgress).pipe(
      filter(progress => progress === 100),
      switchMap(() => forkJoin([
        this.store.selectOnce(HMRFormsService.getHMRInterviewRegisteredPhamacistRequest),
        this.store.selectOnce(ProfileState.clientId)
      ])),
      map(([formData, clientId]) =>
        formData && formData.sixCpaFormId !== null
          ? new SubmitExisitingHMR(clientId, formData)
          : new SubmitNewHMR(clientId, formData)));
    }
  @Dispatch()
  submitHMRInterviewOutsidePatientHome = () => {
    if (this.store.selectSnapshot(HMRFormsProgressService.getHMRInterviewOutsidePatientHomeProgress) !== 100) {
      this.store.dispatch(new ShowInvalidHMRForm());
    }
    return this.store.selectOnce(HMRFormsProgressService.getHMRInterviewOutsidePatientHomeProgress).pipe(
      filter(progress => progress === 100),
      switchMap(() => forkJoin([
        this.store.selectOnce(HMRFormsService.getHMRInterviewOutsidePatientHomeRequest),
        this.store.selectOnce(ProfileState.clientId)
      ])),
      map(([formData, clientId]) =>
        formData && formData.sixCpaFormId !== null
          ? new SubmitExisitingHMR(clientId, formData)
          : new SubmitNewHMR(clientId, formData)));
    }

  @Dispatch()
  saveHMRForm = () => new SaveOrUpdateHMRFormAction();

  @Dispatch()
  saveHMRNewClaim = () =>
    this.store.selectOnce(HMRFormsService.getHMRNewClaimRequest).pipe(
      withLatestFrom(this.store.select(ProfileState.clientId)),
      map(([formData, clientId]) =>
        formData && formData.sixCpaFormId !== null
          ? new SaveExisitingHMR(clientId, formData)
          : new SaveNewHMR(clientId, formData)))

  @Dispatch()
  saveHMRInterviewRegisteredPhamacist = () =>
    this.store.selectOnce(HMRFormsService.getHMRInterviewRegisteredPhamacistRequest).pipe(
      withLatestFrom(this.store.select(ProfileState.clientId)),
      map(([formData, clientId]) =>
        formData && formData.sixCpaFormId !== null
          ? new SaveExisitingHMR(clientId, formData)
          : new SaveNewHMR(clientId, formData)))

  @Dispatch()
  saveHMRInterviewOutsidePatientHome = () =>
    this.store.selectOnce(HMRFormsService.getHMRInterviewOutsidePatientHomeRequest).pipe(
      withLatestFrom(this.store.select(ProfileState.clientId)),
      map(([formData, clientId]) =>
        formData && formData.sixCpaFormId !== null
          ? new SaveExisitingHMR(clientId, formData)
          : new SaveNewHMR(clientId, formData)))
}
