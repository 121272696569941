<ng-container *ngIf="chatState$ | async as chatState">
  <div
    class="reply-modal"
    [ngClass]="{
      'reply-modal-visible': chatState.showReplyModal,
      'reply-modal-invisible': !chatState.showReplyModal
    }"
  >
    <div class="reply-modal-content-container hidden-overflow">
      <div *ngIf="chatState.isLoadingMessageDetails">
        <app-loader
          loadingMessage="Loading..."
          class="loading loading-wheel-background"
        ></app-loader>
      </div>
      <div
        *ngIf="chatState.modalErrorMessage !== null"
        class="reply-modal-message-outgoing"
        class="reply-modal-message-local"
      >
        <div class="m-a">
          <div [innerHTML]="chatState.modalErrorMessage"></div>
        </div>
      </div>

      <div
        class="reply-modal-content"
        *ngIf="mostRecentClientMessage$ | async as mostRecentClientMessage"
      >
        <div nz-row class="reply-modal-title">
          <span class="pointer title-text">
            <a
              (click)="closeModal()"
              routerLink="/profile/{{
                mostRecentClientMessage?.customerId
              }}/categories"
            >
              <i class="fas fa-user-circle"></i>
              {{ chatState?.customer?.firstName }}
              {{ chatState?.customer?.lastName }}
              <i class="far fa-chevron-right fa-sm"></i>
            </a>
          </span>
          <span class="reply-modal-close-button" (click)="closeModal()"
            ><i class="fal fa-times fa-2x"></i
          ></span>
        </div>

        <div>
          <hr class="no-spacing" />
        </div>

        <div
          *ngIf="!(moreRecentMessageExists$ | async)"
          class="load-more-message-button-view"
        >
          <div class="load-more-message-button-container">
            <button
              class="load-more-message-button"
              (click)="
                showMoreHistory(
                  mostRecentClientMessage?.customerId ?? '',
                  mostRecentClientMessage?.channel,
                  chatState.messageHistory.messages,
                  false
                )
              "
            >
              View Previous Messages <i class="fal fa-arrow-up"></i>
            </button>
          </div>
        </div>

        <div
          *ngIf="moreRecentMessageExists$ | async"
          class="load-more-message-button-view"
        >
          <div class="load-more-message-button-container">
            <button
              class="load-more-message-button"
              (click)="
                showMoreHistory(
                  mostRecentClientMessage?.customerId ?? '',
                  mostRecentClientMessage?.channel,
                  chatState.messageHistory.messages,
                  true
                )
              "
            >
              View Latest Messages
            </button>
          </div>
        </div>

        <div #tabsContentRef nz-row class="reply-modal-message-container">
          <div>
            <div
              #message
              *ngFor="let historyMessage of chatState.messageHistory.messages"
              [attr.id]="historyMessage.id"
              class="reply-modal-message-outgoing"
              [ngClass]="{
                'reply-modal-message-incoming':
                  historyMessage.source === messageSources.Customer
              }"
            >
              <div class="reply-modal-titlebar">
                <div class="reply-modal-titlebar-name">
                  <span
                    *ngIf="historyMessage.source !== messageSources.Customer"
                    ><span class="bold">From: </span
                    >{{ historyMessage.name }}</span
                  >
                  <span
                    *ngIf="historyMessage.source === messageSources.Customer"
                    ><span class="bold">From: </span
                    >{{ chatState?.customer?.firstName }}
                    {{ chatState?.customer?.lastName }}</span
                  >
                  &nbsp;&nbsp;
                  <span
                    *ngIf="historyMessage.source === messageSources.Customer"
                    [ngClass]="{ flagged: historyMessage.isFlagged }"
                    (click)="toggleFlagged(historyMessage)"
                  >
                    <span class="bold"
                      ><i class="far fa-flag cursor-pointer"></i
                    ></span>
                  </span>
                </div>

                <div class="reply-modal-titlebar-details">
                  <span class="selectable" *ngIf="historyMessage.sourceNumber">{{
                    historyMessage.sourceNumber | formatPhoneNumber | stripWhiteSpace
                  }}</span>
                  <span>{{
                    historyMessage.date | date: "HH:mm dd/MM/yy"
                  }}</span>
                  <span
                    ><i [ngClass]="channels[historyMessage.channel].icon"></i>
                    {{ channels[historyMessage.channel].name }}</span
                  >
                  <span *ngIf="historyMessage.isReplied"
                    ><i class="fal fa-sm fa-share fa-flip-horizontal"></i>
                    Replied</span
                  >
                  <span *ngIf="historyMessage.isRead"
                    ><i class="fal fa-sm fa-check"></i> Read</span
                  >
                  <app-message-actions
                    [messageDetail]="historyMessage"
                    [messageType]="messageType"
                    [actions]="['flag', 'read']"
                  >
                  </app-message-actions>
                </div>
              </div>

              <hr />

              <div
                [innerHTML]="
                  sanitize(
                    linkEscripts(historyMessage.content, historyMessage.channel)
                  )
                "
              ></div>
              <div *ngIf="checkForEScript(historyMessage.content)">
                <a
                  class="image-link"
                  (click)="openEScriptModal(historyMessage.content)"
                  >Queue eScript</a
                >
              </div>
              <div g-xs="py2" *ngIf="historyMessage?.attachments?.length > 0">
                <span class="reply-modal-message-attachment"
                  ><i class="far fa-paperclip"></i> Attachments</span
                >
              </div>

              <div class="reply-modal-attachments">
                <div
                  *ngFor="let attachment of historyMessage.attachments"
                  class="reply-modal-attachment"
                >
                  <a href="{{ attachment.attachment }}" target="_blank"
                    ><img
                      src="{{ attachment.attachment }}"
                      class="reply-modal-attachment-image"
                  /></a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <hr class="no-spacing" />

        <reply-composition-footer
          [channelType]="mostRecentClientMessage?.channel"
          [customerId]="chatState?.customer?.customerId"
          [tenantCustomerId]="mostRecentClientMessage?.tenantCustomerId"
        ></reply-composition-footer>
      </div>
    </div>
  </div>
</ng-container>
