import { NoteFilter } from '../../../shared/models/crm/noteFilter.model';
import { NoteFullInfo } from '../../../shared/models/crm/noteFullInfo.model';
import { Note } from '../../../shared/models/crm/note.model';

export enum CrmActionTypes {
  GET = '[Crm] Get Note List',
  SET = '[Crm] Set Note List',
  ERROR = '[Crm] Error',
  UPDATE_TOTALS = '[Crm] Update Totals',
  UPDATE_NOTE = '[Crm] Update Note',
  UPDATE_NOTE_LIST = '[Crm] Update Note List',
  UPDATE_FILTERS = '[Crm] Update Filters',
  GET_OUTSTANDING_NOTES = '[Crm] Get outstanding note count'
}

export class GetCrmAction {
  static readonly type = CrmActionTypes.GET;
  constructor(public filter: NoteFilter) { }
}

export class SetCrmAction {
  static readonly type = CrmActionTypes.SET;
  constructor(public data: NoteFullInfo, public filter: NoteFilter) { }
}

export class CrmErrorAction {
  static readonly type = CrmActionTypes.ERROR;
  constructor(public error: any) { }
}

export class UpdateTotalsAction {
  static readonly type = CrmActionTypes.UPDATE_TOTALS;
  constructor(public event: any) { }
}

export class UpdateNoteAction {
  static readonly type = CrmActionTypes.UPDATE_NOTE;
  constructor(public items: Note[]) { }
}

export class UpdateNoteListAction {
  static readonly type = CrmActionTypes.UPDATE_NOTE_LIST;
  constructor(public items: any) { }
}


export class UpdateFiltersAction {
  static readonly type = CrmActionTypes.UPDATE_FILTERS;
  constructor(public filters: NoteFilter) { }
}

export class GetOutstandingNoteCount{
  static readonly type = CrmActionTypes.GET_OUTSTANDING_NOTES;
  constructor(){}
}
