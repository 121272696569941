import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FluidModalComponent } from './fluid-modal.component';

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        FluidModalComponent
    ],
    exports: [
        FluidModalComponent
    ]
})
export class FluidModalModule {}