import { Component, ChangeDetectionStrategy, OnInit, OnDestroy } from '@angular/core';
import { collapse } from '../../../shared/core/animations';
import { Store, Select } from '@ngxs/store';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
//import * as moment_ from 'moment';
import { AslMedication, MedicationStatus } from './models/asl-medication.model';
import { AslMedicationsState } from './state/asl-medication.state';
import { RefreshAslMedicationsAction, ToggleAslMedicationAction } from './state/asl-medication.actions';
import { DispensePaperlessScript } from '../../../shared/components/escripts/state/escripts.actions';
import { ProfileState } from '../../../modules/core/profile/state/profile.state';
import { DispensePaperlessRequest } from '../../../shared/models/lite/paperlessDispense.model';
import {
  GetScryptSMSRepeatTemplates, SetRepeatUri,
  ToggleScryptSmsModal
} from '../../../shared/components/scrypt-sms/state/scrypt-sms.actions';
import { Subscription } from 'rxjs/Subscription';
//const moment = moment_;

@Component({
  selector: 'app-asl-medications',
  templateUrl: './asl-medications.component.html',
  styleUrls: ['./asl-medications.component.scss'],
  animations: [collapse],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AslCategoriesComponent implements OnInit, OnDestroy {
  @Select(AslMedicationsState.showAslMedications) showAslMedications$: Observable<boolean>;
  @Select(AslMedicationsState.all) aslMedications$: Observable<AslMedication[]>;
  @Select(AslMedicationsState.loading) loading$: Observable<boolean>;

  today: Date = new Date();
  medicationStatus = MedicationStatus;
  refreshLoading = false;
  dispenseStates = [];
  dispenseStateSubscription: Subscription;
  displayedColumns$ = new BehaviorSubject<string[]>([
    'status',
    'medicationGenericName',
    'medicationBrandName',
    'patientName',
    'quantity',
    'repeatsRemaining',
    'prescribedDate',
    'dispensedDate',
    'token',
    'actions'
  ]);
  headers = [
    'medicationGenericName',
    'medicationBrandName',
    'patientName',
    'quantity',
    'repeatsRemaining',
    'prescribedDate',
    'dispensedDate',
    'token'
  ];
  sortKey$ = new BehaviorSubject<string>('medicationGenericName');
  sortDirection$ = new BehaviorSubject<string>('asc');
  dataOnPage$ = new BehaviorSubject<any[]>([]);
  constructor(
    private store: Store
  ) { }

  ngOnInit(): void {
    this.dispenseStateSubscription = this.store.select(AslMedicationsState.all).subscribe(medications => {
        this.dispenseStates = new Array((medications?.length ?? 0)).fill(false);
      }
    );
    this.initializeTableDataSubscription();
  }

  private initializeTableDataSubscription() {
    combineLatest([this.aslMedications$, this.sortKey$, this.sortDirection$])
      .subscribe(([allSources, sortKey, sortDirection]) => {
        if(!allSources) return;
        const sourceWithSortableFields = allSources
        .map((item, index) => ({
          ...item,
          qty: parseInt(item.quantity),
          index: index
          }));

        const sortedSource = sourceWithSortableFields.sort((a, b) => {
          if (a === b) {
            return 0;
          }
          else if (a[sortKey] === null) {
              return sortDirection === 'asc' ? 1 : -1;
          }
          else if (b[sortKey] === null) {
            return sortDirection === 'asc' ? -1 : 1;
          }
          if(a[sortKey] > b[sortKey]) return sortDirection === 'asc' ? 1 : -1;
          if(a[sortKey] < b[sortKey]) return sortDirection === 'asc' ? -1 : 1;
          return 0;
        });


        const onPage = sortedSource;
        this.dataOnPage$.next(<any>onPage);
      });
  }

  adjustSort(key: string) {
    if (this.sortKey$.value === key) {
      if (this.sortDirection$.value === 'asc') {
        this.sortDirection$.next('desc');
      } else {
        this.sortDirection$.next('asc');
      }
      return;
    }

    this.sortKey$.next(key);
    this.sortDirection$.next('asc');
  }

  toggleShowAslMedications() {
    this.store.dispatch(new ToggleAslMedicationAction());
  }

  dispenseMedication(medication: AslMedication, index: number): void {
    const patient = this.store.selectSnapshot(ProfileState.profile).patient;
    const request: DispensePaperlessRequest = {
      token: medication.token,
      drugName: medication.medicationGenericName,
      firstName: patient.firstname,
      lastName: patient.surname,
      prescriberName: ''
    };
    this.dispenseStates[index] = true;
    this.store.dispatch(new DispensePaperlessScript(request));
  }

  sendSMSRepeat(medication: AslMedication): void {
    this.store.dispatch([
      new SetRepeatUri(medication.repeatTokenUri),
      new GetScryptSMSRepeatTemplates(),
      new ToggleScryptSmsModal(true),
    ]);
  }

  refreshMedications(): void {
    const patient = this.store.selectSnapshot(ProfileState.profile).patient;
    this.store.dispatch(new RefreshAslMedicationsAction(patient.ihiNumber, patient.firstname, patient.surname));
  }

  ngOnDestroy(): void {
    this.dispenseStateSubscription.unsubscribe();
  }
}
