<section>
  <ng-container *ngIf="!(smsHistoryLoading$ | async); else loading">
    <div  class="send-message" g-xs="px3 pt3 pb4">
      <div>
        <app-history [messageHistory]="smsHistory$ | async"></app-history>
      </div>
      <div>
        <app-add-sms
          [clientId]="client.id"
          [templates]="templates"
          [hasMobileNumber]="!!client.mobileNumber"
          (messageSentEvent)="messageSent($event)"
        ></app-add-sms>
      </div>
    </div>
  </ng-container>

</section>

<ng-template #loading>
  <div g-xs="p4 text:center" class="flex">
    <app-loader class="loading"></app-loader>
  </div>
</ng-template>
