<section>
  <app-notification [notification]="notification"></app-notification>
  <div *ngIf="!isLoaded">
    <app-loader></app-loader>
  </div>
  <div *ngIf="isLoaded">
    <div class="add-sms-block">
      <form #f="ngForm" (ngSubmit)="onSubmit(f)">
        <div class="form-group">
          <label class="font__bold font__title-1" for="pre-selected-messages">Pre-selected messages</label>
          <select class="form-control pre-selected-messages color__grey-text" g-xs="py2" id="pre-selected-messages"
            name="messageTypeId" #messageTypeId="ngModel" [ngModel]="'0'" (change)="onMessageTypeChange(f)">
            <option value="0">
              Use a pre-selected message to send to your clients
            </option>
            <option *ngFor="let item of messageTypes" [value]="item.id">
              {{item.title}}
            </option>
          </select>
        </div>

        <div class="form-group mt-1">
          <div class="form-check">
            <input type="checkbox" class="form-check-input" id="schedule" #scheduleSMS="ngModel" name="scheduleSMS"
              ngModel (change)="toggleScheduleForm()">
            <label class="form-check-label" for="schedule">Schedule a date/time within the next 12 months</label>
          </div>
        </div>
        <div class="form-row" *ngIf="showScheduleSMS">
          <div class="form-group col-md-3">
            <label class="font__bold" g-xs="mt3 mb1">Scheduled Date</label>
            <nz-date-picker
              name="scheduledDate"
              #scheduledDate="ngModel"
              [ngModel]="defaultDate"
              nzFormat="dd-MM-YYYY"
              id="date"
              nzPlaceHolder="dd-mm-yyyy"
              [nzDisabledDate]="disabledDates"
            ></nz-date-picker>
          </div>
          <div class="form-group col-md-3">
            <label class="font__bold" g-xs="mt3 mb1">Scheduled Time <i class="fal fa-info-circle"
                tooltip="Messages will be sent within 5 minutes of scheduled time"></i></label>
            <nz-time-picker
              #scheduledTime="ngModel"
              name="scheduledTime"
              [ngModel]="defaultTime"
              [nzUse12Hours]="false"
              nzPlaceHolder="10:00 AM"
              nzFormat="HH:mm a">
            </nz-time-picker>
            <label g-xs="mt2"><i>Leave blank to send NOW</i></label>
          </div>
        </div>

        <div class="form-group" [ngClass]="{'has-error': message.invalid && message.touched}">
          <label class="font__bold font__title-1" for="sms-message">Your Message</label>
          <textarea id="sms-message" g-xs="p3" class="form-control sms-message" required #message="ngModel"
            name="message" ngModel>
            {{message}}
          </textarea>
          <span *ngIf="message.invalid && message.touched" class="form-help-text">Required field for message</span>
        </div>
        <div>
          <button type="submit" g-xs="p2 text:center" class="btn__green color__primary-text bg__green font__bold"
            [disabled]="f.invalid || !hasMobileNumber">
            <span *ngIf="!showScheduleSMS">Send Message Now</span>
            <span *ngIf="showScheduleSMS">Send SMS</span>
          </button>
          <div class="mt-3 mb-1">
            <span class="alert alert-info" style="padding: 3px;">Any links you use here will automatically be shortned
              to https://scryp.co/xxxxxxx</span>
          </div>
          <div class="float-right">
            <h5 class="mt-1 mb-1" *ngIf="f.value.message && f.value.message.length">
              <b>Message length: </b> {{getCharacterCount(f.value.message)}} | <b>Total Messages: </b>
              {{getMessageCount(f.value.message)}}
            </h5>
          </div>
        </div>
        <span class="alert alert-danger ml-2" *ngIf="!hasMobileNumber">No mobile number, please add a mobile number to
          your dispense system for the patient.</span>
      </form>
    </div>
  </div>
</section>
