import { Component, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { DoctorReportTemplatesSelectorState } from './state/doctor-report-templates-selector.state';
import { Observable } from 'rxjs';
import { MarketingSetting } from '../../models/marketingSetting.model';
import { GetDoctorReportTemplates, SetSelectedDoctorReportTemplate } from './state/doctor-report-templates-selector.actions';
import { ProfileState } from '../../../modules/core/profile/state/profile.state';

@Component({
  selector: 'app-doctor-report-templates-selector',
  templateUrl: './doctor-report-templates-selector.component.html'
})
export class DoctorReportTemplatesSelectorComponent implements OnInit {
  @Select(DoctorReportTemplatesSelectorState.doctorReportTemplates) doctorReportTemplates$: Observable<MarketingSetting>

  constructor(private store: Store) {
  }
  ngOnInit() {
    const clientId = this.store.selectSnapshot(ProfileState.clientId);
    this.store.dispatch(new GetDoctorReportTemplates(clientId));
  }
  setSelectedTemplate(templateId: number) {
    this.store.dispatch(new SetSelectedDoctorReportTemplate(templateId));
  }
}
