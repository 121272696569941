import { State, Action, StateContext, Store } from '@ngxs/store';
import { catchError, finalize, tap } from 'rxjs/operators';
import { ProfileState } from '../../../../core/profile/state/profile.state';
import { RefreshCategoriesAction } from '../../state/categories.actions';
import { ScriptHistoryService } from '../services/script-history.service';
import { SetDrugForScriptHistory, ToggleScriptAvailability, ToggleScriptAvailabilityError, ToggleScriptAvailabilitySuccess, ToggleScriptHistoryModal } from './script-history.actions';
import { Injectable } from '@angular/core';

export class ScriptHistoryStateModel {
  public showModal: boolean;
  public drug: any;
  public loading: boolean;
}
@Injectable()
@State<ScriptHistoryStateModel>({
  name: 'scriptHistory',
  defaults: {
    showModal: false,
    drug: null,
    loading: false
  }
})
export class ScriptHistoryState {
  constructor(private scriptHistoryService: ScriptHistoryService, private store: Store) { }

  @Action(ToggleScriptHistoryModal)
  toggleScriptHistoryModal(ctx: StateContext<ScriptHistoryStateModel>, { show }: ToggleScriptHistoryModal) {
    ctx.patchState({
      showModal: show
    })
  }

  @Action(SetDrugForScriptHistory)
  setDrugForScriptHistory(ctx: StateContext<ScriptHistoryStateModel>, { drug }: SetDrugForScriptHistory) {
    ctx.patchState({
      drug: drug
    })
  }

  @Action(ToggleScriptAvailability)
  toggleScriptAvailability(ctx: StateContext<ScriptHistoryStateModel>, { script }: ToggleScriptAvailability) {
    ctx.patchState({
      loading: true
    });

    return this.scriptHistoryService.toggleScriptAvailability(script).pipe(
      tap(() => ctx.dispatch(new ToggleScriptAvailabilitySuccess())),
      catchError(error => ctx.dispatch(new ToggleScriptAvailabilityError(error))),
      finalize(() => ctx.patchState({ loading: false }))
    )
  }

  @Action(ToggleScriptAvailabilitySuccess)
  toggleScriptAvailabilitySuccess(ctx: StateContext<ScriptHistoryStateModel>) {
    const clientId = this.store.selectSnapshot(ProfileState.clientId)
    ctx.dispatch(new RefreshCategoriesAction(clientId));
  }

  @Action(ToggleScriptAvailabilityError)
  toggleScriptAvailabilityError(ctx: StateContext<ScriptHistoryStateModel>, { error }: ToggleScriptAvailabilityError) {
    console.log(JSON.stringify(error));
  }
}
