import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TenantDatePipe } from '~/modules/core/timezone/pipes/tenant-date.pipe';
import { TimezoneModule } from '~/modules/core/timezone/timezone.module';
import { LoaderModule } from '~/modules/core/loader/loader.module';
import { PipesModule } from '~/shared/pipes/pipes.module';
import { ReplyCompositionFooterComponent } from './reply-composition-footer.component';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';

@NgModule({
  declarations: [
    ReplyCompositionFooterComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    NzButtonModule,
    NzInputModule,
    NzGridModule,
    NzSelectModule,
    TimezoneModule,
    LoaderModule,
    PipesModule,
    ReactiveFormsModule,
    NgxsFormPluginModule
  ],
  exports: [
    ReplyCompositionFooterComponent
  ],
  providers: [
    TenantDatePipe
  ],
})

export class ReplyCompositionFooterComponentModule { }
