<section>
  <div>
    <div *ngIf="!isLoaded" g-xs="p4 text:center" class="flex">
      <app-loader class="loading"></app-loader>
    </div>
    <div *ngIf="isLoaded" g-xs="px3 pt3 pb4">
      <div>
        <app-email-history [emailHistory]="emailHistory"></app-email-history>
      </div>
      <div>
        <app-add-email [client]="client" (emailSentEvent)="emailSent($event)"></app-add-email>
      </div>
    </div>
  </div>
</section>
