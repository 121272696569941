import { MobileInfo } from "./mobile-state-modal.state";

export enum MobileStateModalActions {
  OPEN_MODAL = '[MobileStateModal] Open Modal',
  CLOSE_MODAL = '[MobileStateModal] Close Modal',
  DELETE_USER = '[MobileStateModal] Delete Mobile User',
  DELETE_SUCCESSFUL = '[MobileStateModal] Delete mobile user successful',
  DELETE_FAILED = '[MobileStateModal] Delete failed',
  DELETE_INVITES = '[MobileStateModal] Delete Invites',
  DELETE_INVITES_SUCCESSFUL = '[MobileStateModal] Delete Invites successful',
  DELETE_INVITES_FAILED = '[MobileStateModal] Delete Invites failed'
}


export class OpenMobileStateModal {
  static readonly type = MobileStateModalActions.OPEN_MODAL;
  constructor(public info: MobileInfo) {}
}

export class CloseMobileStateModal {
  static readonly type = MobileStateModalActions.CLOSE_MODAL;
  constructor() {}
}

export class DeleteMobileUser {
  static readonly type = MobileStateModalActions.DELETE_USER;
  constructor() {}
}

export class DeleteUserSuccessful {
  static readonly type = MobileStateModalActions.DELETE_SUCCESSFUL;
  constructor() {}
}

export class DeleteUserFailed {
  static readonly type = MobileStateModalActions.DELETE_FAILED;
  constructor(public error: any) {}
}

export class DeleteMobileInvites {
  static readonly type = MobileStateModalActions.DELETE_INVITES;
  constructor() {}
}

export class DeleteInvitesSuccessful {
  static readonly type = MobileStateModalActions.DELETE_INVITES_SUCCESSFUL;
  constructor() {}
}

export class DeleteInvitesFailed {
  static readonly type = MobileStateModalActions.DELETE_INVITES_FAILED;
  constructor(public error: any) {}
}


