import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { catchError, tap } from 'rxjs/operators';
import { AlertService } from '../../../../../modules/core/alert/alert.service';
import { CommsQueueItem } from '../../../../pharmacy-comms/comms-queue/models/comms-queue-item.model';
import { CommsQueueService } from '../../../../pharmacy-comms/comms-queue/services/comms-queue.service';
import {
  GetProjectedMessages,
  GetProjectedMessagesErrors,
  GetProjectedMessagesSuccess
} from './projected-messages.actions';

export class ProjectedMessagesStateModel {
  loading: boolean;
  projectedMessages: CommsQueueItem[]
}

@Injectable()
@State<ProjectedMessagesStateModel>({
  name: 'projectedMessages',
  defaults: {
    loading: false,
    projectedMessages: []
  }
})
export class ProjectedMessagesState {
  constructor(
    private commsQueueService: CommsQueueService,
    private alertService: AlertService
  ) { }

  @Selector()
  static loading(state: ProjectedMessagesStateModel) { return state.loading; }

  @Selector()
  static messages(state: ProjectedMessagesStateModel) { return state.projectedMessages; }

  @Action(GetProjectedMessages)
  getProjectedMessages(ctx: StateContext<ProjectedMessagesStateModel>, { clientId, startDate }: GetProjectedMessages) {
    ctx.patchState({ loading: true });
    return this.commsQueueService.getPatientProjectedComms(clientId, startDate)
      .pipe(
        tap(response => ctx.dispatch(new GetProjectedMessagesSuccess(response))),
        catchError(err => ctx.dispatch(new GetProjectedMessagesErrors(err)))
      );
  }

  @Action(GetProjectedMessagesSuccess)
  getProjectedMessagesSuccess(ctx: StateContext<ProjectedMessagesStateModel>, { messages }: GetProjectedMessagesSuccess) {
    ctx.patchState({
      loading: false,
      projectedMessages: messages
    });
  }

  @Action(GetProjectedMessagesErrors)
  getProjectedMessagesError(ctx: StateContext<ProjectedMessagesStateModel>, { error }: GetProjectedMessagesErrors) {
    this.alertService.error(error.error);
    ctx.patchState({ loading: false });
  }
}
