import { MedicationHealthConditions } from '../medication-information-form.model'

export enum StagedSupplyReasons {
  cognitiveOrPhysicalImpairment,
  disorientedOrConfused,
  historyOrRiskOfDeliberateSelfHarmOrHarmToOthers,
  historyOrRiskOfIntentionalMisuse,
  historyOrRiskOfMedicineDiversion,
  historyOrRiskOfPoorAdherence,
  other
}

export enum PatientMedicationProblemsPastMonth {
  no,
  notYetNewMedicine,
  hadTroubleFollowingDoctorsInstructions,
  hadDifficultyCollectingMedicineFromPharmacy,
  hadToGoToTheDoctorOrHospitalBecauseOfProblemsWithMedication
}

export interface SSMedicationInformationFormModel {
  id?: number | null;
  medicationHealthConditions: MedicationHealthConditions;
  medIndexScore: number | null;
  patientHadAnyProblemsOverThePastMonthWithTheirMedications?: PatientMedicationProblemsPastMonth;
  gPPrescriberNumber: string | null;
  reasonForStagedSupply?: StagedSupplyReasons;
  medicationHealthConditionsOtherDetails: string | null;
}

