import { Component, OnInit } from '@angular/core';
import { MarketingSettingService } from '../services/marketing-settings.sevice';
import { MarketingSetting } from '~/shared/models/marketingSetting.model';
import { Router, ActivatedRoute } from '@angular/router';
import { MarketingItemService } from '../services/marketing-item.service';
import { Tenant } from '~/shared/models/tenant.model';
import { MarketingType } from '~/shared/models/sequence-type.enum';
import { validMergeTags } from '~/shared/models/communication/mergeTags';

@Component({
  templateUrl: './marketing-item.component.html'
})
export class MarketingItemComponent implements OnInit {

  public marketingItem: MarketingSetting;
  tenants: Array<Partial<Tenant>>;
  marketingTypes: Array<MarketingType>;
  marketingType = MarketingType;
  marketingTypeRequiresInterval = false;
  mergeTags = validMergeTags;
  mergeTagExample = 'Hi <<FirstName>>, Your order is ready for pickup at <<PharmacyName>>';
  mergeTagLive = 'Hi John, Your order is ready for pickup at Scrypt Pharmacy';
  tagExample = '<<FirstName>>';
  showInformation = false;

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected settingsService: MarketingSettingService,
    protected marketingItemService: MarketingItemService
  ) { }

  ngOnInit() {
    this.tenants = this.marketingItemService.getMarketingItemTenants();
    this.marketingTypes = this.marketingItemService.getMarketingTypes();
  }

  toggleInformation() {
    this.showInformation = !this.showInformation;
  }

  insertTag(tag: string) {
    // I can only assume that users will smash tags back to back and require a space.
    this.marketingItem.messageTemplate += `${tag} `;
  }

  goBack() {
    this.router.navigate(['../../'], { relativeTo: this.route });
  }

  submit() {
    throw new Error('Submit not implemented for this component');
  }

  cancel() {
    this.goBack();
  }

  hasInterval() {
    return this.settingsService.hasInterval(this.marketingItem);
  }

  getTenant(item: MarketingSetting) {
    return this.marketingItemService.getTenant(item);
  }

  getMarketingTypeName(marketingType: MarketingType) {
    return MarketingType[marketingType].toString().match(/[A-Z][a-z]+|[0-9]+/g).join(" ");
  }

  isReadonlyTenant() {
    return false;
  }

  isReadonlyMarketingKey() {
    return false;
  }

  isReadonlyMarketingType() {
    return false;
  }

  isTypeWithInterval(setting: MarketingSetting) {
    if (
      setting.marketingType === MarketingType.OrderDelivery
      || setting.marketingType === MarketingType.OrderPickup
      || setting.marketingType === MarketingType.UserConfigurable
      || setting.marketingType === MarketingType.DoctorReportTemplate
    ) {
      this.marketingTypeRequiresInterval = true;
    } else {
      this.marketingTypeRequiresInterval = false;
    }
  }
}
