import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { FooterComponent } from './components/footer/footer.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { ColumnTableComponent } from './components/column-table/column-table.component';
import { PipesModule } from './pipes/pipes.module';
import { AlertComponent } from './components/alert/alert.component';
import { SystemRoutingModule } from '../system/system-routing.module';
import { NotificationModule } from './components/notification/notification.module';
import { LoaderModule } from '../modules/core/loader/loader.module';
import { HeaderComponent } from './components/header/header.component';
import { BreadcrumbsComponent } from './components/breadcrumbs/breadcrumbs.component';
import { AlertModule } from '../modules/core/alert/alert.module';
import { BsDropdownModule } from 'ngx-bootstrap';
import { EScriptModalModule } from './components/escripts/e-script-modal/e-script-modal.module';
import { NzBadgeModule } from 'ng-zorro-antd/badge';

@NgModule({
  imports: [
    ReactiveFormsModule,
    FormsModule,
    BrowserModule,
    PipesModule,
    SystemRoutingModule,
    LoaderModule,
    NotificationModule,
    AlertModule,
    EScriptModalModule,
    BsDropdownModule.forRoot(),
    NzBadgeModule
  ],
  exports: [
    ReactiveFormsModule,
    FormsModule,
    NgxChartsModule,
    LoaderModule,
    NotificationModule,
    FooterComponent,
    ColumnTableComponent,
    AlertComponent,
    HeaderComponent,
    BreadcrumbsComponent
  ],
  declarations: [
    FooterComponent,
    ColumnTableComponent,
    AlertComponent,
    HeaderComponent,
    BreadcrumbsComponent
  ]
})
export class SharedModule {

}
