import {
  Component,
  Input,
  TemplateRef,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'app-tooltip',
  encapsulation: ViewEncapsulation.None,
  template: `
    <div class="help-block">
      <button
        class="btn btn-default"
        container="body"
        outsideClick="true"
        [placement]="placement"
        [triggers]="triggers"
        [popoverTitle]="title"
        [popover]="content"
        [popoverContext]="contentContext">
        {{ name }}
      </button>
    </div>

  `,
  styleUrls: ['./tooltip.component.scss']
})
export class TooltipComponent {
  @Input() title: string;
  @Input() contentContext: any = {};
  @Input() content: string | TemplateRef<any>;
  @Input() placement = 'top';
  @Input() name: string;
  @Input() triggers = 'focus';
}



