import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AirLookupFormComponent } from './air-lookup-form.component';
import { RadioHighlightModule } from '../../../../../shared/components/radio-highlight/radio-highlight.module';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { NgxsModule } from '@ngxs/store';
import { AirLookupState } from './state/air-lookup-form.state';
import { AirLookupService } from './services/air-lookup.service';
import { LoaderModule } from '../../../../core/loader/loader.module';



@NgModule({
  declarations: [AirLookupFormComponent],
  imports: [
    CommonModule,
    LoaderModule,
    RadioHighlightModule,
    NgxsFormPluginModule,
    ReactiveFormsModule,
    FormsModule,
    NgxsModule.forFeature([AirLookupState]),
    BsDatepickerModule.forRoot(),

  ],
  exports: [
    AirLookupFormComponent
  ],
  providers: [
    AirLookupService
  ]
})
export class AirLookupFormModule { }
