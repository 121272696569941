import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SickLeavePharmacistDeclarationComponent } from './sick-leave-pharmacist-declaration.component';
import { RadioHighlightModule } from '../../../../../shared/components/radio-highlight/radio-highlight.module';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import { ReactiveFormsModule } from '@angular/forms';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ScryptDigitalSignatureModule } from '../../../../../shared/components/scrypt-digital-signature/scrypt-digital-signature/scrypt-digital-signature.module';

@NgModule({
  declarations: [SickLeavePharmacistDeclarationComponent],
  imports: [
    CommonModule,
    RadioHighlightModule,
    NgxsFormPluginModule,
    ReactiveFormsModule,
    BsDatepickerModule.forRoot(),
    ScryptDigitalSignatureModule
  ],
  exports: [SickLeavePharmacistDeclarationComponent]
})
export class SickLeavePharmacistDeclarationModule { }
