import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { RedirectToError } from '../state/vaccination.actions';
import { VaccinationState } from '../state/vaccination.state';
import { VaccinationErrorType } from '../types/vaccination';

@Injectable()
export class VaccinationFormGuard implements CanActivate {
  constructor(private store: Store) {}

  canActivate(): Observable<boolean> {
    return this.store.selectOnce(VaccinationState.formLink).pipe(
      map((formLink) => {
        if (formLink != null) {
          return true;
        }
        this.store.dispatch(
          new RedirectToError({
            type: VaccinationErrorType.InvalidLink,
            error: 'Invalid Link',
          })
        );
        return false;
      })
    );
  }
}
