import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Select } from '@ngxs/store';
import { ActionPlanFormState } from '../action-plan-form/state/action-plan-form.state';

@Component({
  selector: 'app-action-plan-form-list-item',
  templateUrl: './action-plan-form-list-item.component.html'
})
export class ActionPLanFormListItemComponent implements OnInit {
  @Select(ActionPlanFormState) actionPlanForm$: Observable<any>;

  constructor() { }

  ngOnInit() {
  }

}
