<section>
  <app-notification [notification]="notification"></app-notification>
  <div *ngIf="!isLoaded">
    <app-loader></app-loader>
  </div>
  <div *ngIf="isLoaded">
    <div class="add-sms-block">
      <form #f="ngForm" (ngSubmit)="onSubmit(f)">
        <div class="form-group">
          <label class="font__bold font__title-1" for="pre-selected-messages">Pre-selected messages</label>
          <select class="form-control pre-selected-messages color__grey-text" g-xs="py2" id="pre-selected-messages"
            name="messageTypeId" #messageTypeId="ngModel" [ngModel]="'0'" (change)="onMessageTypeChange(f)">
            <option value="0">
              Use a pre-selected message to send to your clients
            </option>
            <option *ngFor="let item of messageTypes" [value]="item.id">
              {{item.title}}
            </option>
          </select>
        </div>

        <div class="form-group" [ngClass]="{'has-error': message.invalid && message.touched}">
          <label class="font__bold" for="messenger-message">Your Message</label>
          <textarea id="messenger-message" g-xs="p3" class="form-control messenger-message" required #message="ngModel"
            name="message" ngModel>
            {{message}}
          </textarea>
          <span *ngIf="message.invalid && message.touched" class="form-help-text">
            Required field for message
          </span>
        </div>

        <button type="submit" g-xs="p2 text:center" class="btn__green color__primary-text bg__green font__bold"
          [disabled]="f.invalid">
          Send Message Now
        </button>
      </form>
    </div>
  </div>
</section>
