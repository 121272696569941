<section class="login">
  <div g-xs="mb3">
    <img src="./assets/img/scrypt-vector.svg" class="logo-size" />
  </div>
  <form [formGroup]="form" (ngSubmit)="onResetClick()">
    <div class="form-group" [ngClass]="{'has-error': form.get('username').invalid && form.get('username').touched}">
      <h4 g-xs="mb2">Your Email</h4>
      <input type="text" class="form-control input__yellow" id="username" placeholder="your@email.com" formControlName="username">
      <span class="form-help-text" g-xs='mtq' *ngIf="form.get('username').invalid && form.get('username').touched">
        <div *ngIf="form.get('username')['errors']['required']">Required</div>
        <div *ngIf="form.get('username')['errors']['email']">Wrong email format</div>
      </span>
    </div>
    <div class="form-group" g-xs='mt4'>
        <button type="submit"  class="btn__yellow font__title-4" g-xs="mr3" [disabled]="form.invalid">Send</button>
        <button (click)="onGoBack()" class="btn__white font__title-4">Go back</button>
    </div>
    <div class="alert alert-success" *ngIf="isShowMessage">
      <span>{{resetMessage}}</span>
    </div>
  </form>
</section>
