import { Component, OnInit, OnDestroy } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import * as moment_ from 'moment';
const moment = moment_;
import { ConfirmMedsCheckState } from '../state/confirm-meds-check.state';
import { Observable, Subscription, combineLatest } from 'rxjs';
import { slideRight } from '../../../../../shared/core/animations';
import {
  SendToDoctorConfirmMedsCheckAction,
  SetDoctorListConfirmMedsCheckAction,
  ToggleResponseConfirmMedsCheckAction,
  GetPatientReportConfirmMedsCheckAction,
  GetDoctorReportConfirmMedsCheckAction,
  CreateSixCpaNoteConfirmMedsCheckAction
} from '../state/confirm-meds-check.actions';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { AttendingDoctorState } from '../../../../../modules/profile/attending-doctor/state/attending-doctor.state';
import { tap, withLatestFrom, map, take } from 'rxjs/operators';
import { TypeaheadMatch } from 'ngx-bootstrap';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { ProfileState } from '../../../../../modules/core/profile/state/profile.state';
import { CustomisedDoctorReportState } from '../../../../../shared/components/customise-doctor-report/state/customised-doctor-report.state';
import { IReportModel } from '../../../../../shared/models/six-cpa/doctor-report.model';
import { SixCpaUserCommunicationsState, SixCpaUserCommunicationTypes } from '../../six-cpa-user-communications/state/six-cpa-user-communications.state';

import { CustomerDocument, DocumentOrigin } from '../../upload-document-form/services/document-storage.service';
import { GetDocument } from '../../upload-document-form/state/upload-document-form.actions';
import { UploadDocumentFormState } from '../../upload-document-form/state/upload-document-form.state';
import { OpenMedsCheckSendDocumentFormAction } from '../../meds-check/state/meds-check-form-list.actions';

@Component({
  selector: 'app-confirm-meds-check-form',
  templateUrl: './confirm-meds-check-form.component.html',
  styleUrls: ['./confirm-meds-check-form.component.scss', '../../six-cpa-form-list-item.component.scss'],
  animations: [
    slideRight
  ]
})
export class ConfirmMedsCheckFormComponent implements OnInit, OnDestroy {
  @Select(ConfirmMedsCheckState) confirmMedsCheck$: Observable<any>;
  @Select(ConfirmMedsCheckState.patientReport) patientReport$: Observable<IReportModel>;
  @Select(ConfirmMedsCheckState.doctorReport) doctorReport$: Observable<IReportModel>;
  @Select(UploadDocumentFormState.documents) documents$: Observable<any>;
  @Select(SixCpaUserCommunicationsState) sixCpaUserCommunications$: Observable<any>;
  sixCpaUserCommunicationTypes = SixCpaUserCommunicationTypes;
  doctorDetailsForm: FormGroup;
  sixCpaNoteForm: FormGroup;
  combinedSubscription$: Subscription;
  documentOrigins =  DocumentOrigin;
  attendingDoctorsSubscription$: Subscription;
  constructor(private formBuilder: FormBuilder, private store: Store) {
    this.generateFormControls();
  }

  downloadFile(file: CustomerDocument) {
    this.store.dispatch(new GetDocument(file.id, file.scryptDocumentName));
  }

  @Dispatch()
  sendMedsicationList = () => new OpenMedsCheckSendDocumentFormAction()


  @Dispatch()
  getPatientReport = () =>
    this.store.selectOnce(ProfileState.clientId).pipe(
      map((clientId) => new GetPatientReportConfirmMedsCheckAction(clientId, this.doctorDetailsForm.value.fullName)))

  @Dispatch()
  createNote = () =>
    this.store.selectOnce(ConfirmMedsCheckState.sixCpaNoteForm).pipe(
      withLatestFrom(this.store.select(ProfileState.clientId)),
      map(([formData, clientId]) =>
        new CreateSixCpaNoteConfirmMedsCheckAction(clientId, formData.followUpDate, formData.body)
      ))

  @Dispatch()
  getDoctorReport = () =>
    combineLatest([
      this.store.select(ProfileState.clientId),
      this.store.select(CustomisedDoctorReportState.customisedDoctorReport)])
      .pipe(take(1),
        map(([clientId, customisedDoctorReport]) =>
          new GetDoctorReportConfirmMedsCheckAction(clientId, customisedDoctorReport)
        ))

  @Dispatch()
  sendDoctorReport = () =>
    combineLatest([
      this.store.select(ProfileState.clientId),
      this.store.select(CustomisedDoctorReportState.customisedDoctorReport)])
      .pipe(take(1),
        map(([clientId, customisedDoctorReport]) =>
          new SendToDoctorConfirmMedsCheckAction(clientId, customisedDoctorReport)
        ))

  ngOnInit() {
    this.attendingDoctorsSubscription$ = this.store.selectOnce(AttendingDoctorState.patientDoctors).pipe(
      tap((data: any[]) => this.store.dispatch(new SetDoctorListConfirmMedsCheckAction(data)))
    ).subscribe();
  }
  
  ngOnDestroy() {
    if (this.attendingDoctorsSubscription$) { this.attendingDoctorsSubscription$.unsubscribe() }
  }

  onSelect(event: TypeaheadMatch): void {
    const { email } = event.item;
    this.doctorDetailsForm.patchValue({ email });
  }
  hideResponse() {
    this.store.dispatch(new ToggleResponseConfirmMedsCheckAction('out'));
  }

  onNoteTypeChange() {
    const date = new Date();
    date.setHours(0, 0, 0, 0);
    this.sixCpaNoteForm.patchValue({ body: this.sixCpaNoteForm.controls.template.value });
    if(this.sixCpaNoteForm.controls.template.value == '12 month reminder') {
      this.sixCpaNoteForm.patchValue({
        followUpDate: moment(date).add(12, 'month').toDate()
      })
    }
  }

  private generateFormControls() {
    this.doctorDetailsForm = this.formBuilder.group({
      fullName: new FormControl(null),
      email: new FormControl(null, [Validators.required, Validators.email]),
      doctorAddress: new FormControl(null),
    });

    const date = new Date();
    date.setHours(0, 0, 0, 0);

    this.sixCpaNoteForm = this.formBuilder.group({
      followUpDate: new FormControl(moment(date).add(12, 'month').toDate(), [Validators.required]),
      body: new FormControl(''),
      template: new FormControl('')
    });
  }
}
