import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';
import { PatientDetailsFormDetailedState } from '../patient-details-form/state/patient-details-form-detailed.state';
import { PatientDetailsFormState } from '../patient-details-form/state/patient-details-form.state';
import { PatientDetailsFormBasicState } from '../patient-details-form/state/patient-details-form-basic.state';
import { TenantDatePipe } from '../../../core/timezone/pipes/tenant-date.pipe';

@NgModule({
  imports: [
    NgxsModule.forFeature([
      PatientDetailsFormState,
      PatientDetailsFormDetailedState,
      PatientDetailsFormBasicState
    ]),
  ],
  providers: [
    TenantDatePipe
  ]
})
export class PatientDetailsStateModule { }
