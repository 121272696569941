export class ConnectionType {
    constructor(
        public value: number,
        public name: string,
    ) { }
}

export enum ConnectionTypeEnum {
  ZDispense = 0,
  FredDispense = 1,
  FredNXTDispense = 2,
  Aquarius = 3,
  Corum = 4
}
