<div class="flex note-filters">
  <div>
    <div g-xs="mb1">
      <span class="font__bold">Note Type</span> &nbsp;
      <span class="ml1" *ngIf="hasFilters" (click)="selectNone()"><small class="select">Select none</small></span>
      <span class="ml1" *ngIf="!hasFilters" (click)="selectAll()"><small class="select">Select all</small></span>
    </div>
    <div class="btn-group" dropdown>
      <button dropdownToggle type="button"
        class="dropdown-toggle form-control note-filters__type-btn border__grey-light" aria-controls="filter-type">
        <div>
          <span [innerHTML]="selectedType.name | appNoteTypeIcon: noteTypeList"></span>
          <span g-xs="ml1">{{selectedType.name}}</span>
        </div>
        <span class="caret"></span>
      </button>
      <form [formGroup]="filterForm">
        <ul id="filter-type" *dropdownMenu class="dropdown-menu border__grey-light note-filters__type"
          (click)="$event.stopPropagation()" role="menu">
          <li role="menuitem" *ngFor="let item of noteTypeList" (change)="update(item.name)">
            <label g-xs="py1 px2" [for]="item.name" class="note-filters__label">
              <span g-xs="mr2">
                <input type="checkbox" [id]="item.name" [formControlName]="item.name" value="item.value" />
                <span class="checkmark"></span>
              </span>
              <span [innerHTML]="item.name | appNoteTypeIcon: noteTypeList"></span>
              <span g-xs="ml1">{{item.name}}</span>
            </label>
          </li>
          <li>
            <button class="btn btn-outline-success w-100" (click)="getFilteredNotes()"><strong>Go</strong>&nbsp;
              <i class="far fa-paper-plane"></i>
            </button>
          </li>
        </ul>
      </form>
    </div>
  </div>

  <div g-md="ml1" g-lg="ml2">
    <div class="font__bold" g-xs="mb1">Patient Grouping</div>
    <div class="btn-group" dropdown>
      <button dropdownToggle type="button"
        class="dropdown-toggle form-control note-filters__type-btn border__grey-light" aria-controls="filter-group">
        <div>
          <span g-xs="ml1">{{selectedGroup.name}}</span>
        </div>
        <span class="caret"></span>
      </button>
      <ul id="filter-group" *dropdownMenu class="dropdown-menu border__grey-light note-filters__type" role="menu">
        <li g-xs="py1 px2" role="menuitem" *ngFor="let item of groupTypeList" (click)="onGroupChanged(item)">
          <span g-xs="ml1">{{item.name}}</span>
        </li>
      </ul>
    </div>
  </div>
  <div g-md="mx1" g-lg="mx4">
    <div class="font__bold" g-xs="mb1">Date Range</div>
    <div class="flex">
      <div class="calendar" g-xs="mr2">
        <input type="text" placeholder="From" class="form-control border__grey-light calendar" id="date-from"
          [(ngModel)]="filters.fromDate" (ngModelChange)="filterChange()" [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY' }"
          bsDatepicker>
      </div>
      <div class="calendar">
        <input type="text" placeholder="To" class="form-control border__grey-light calendar" id="date-to"
          [(ngModel)]="filters.toDate" (ngModelChange)="filterChange()" [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY' }"
          bsDatepicker>
      </div>
    </div>
  </div>
  <div>
    <div class="font__bold" g-xs="mb1">Search Customer</div>
    <div class="flex note-filters__search">
      <form (submit)="filterChange()">
        <input [(ngModel)]="filters.query" [ngModelOptions]="{standalone: true}" type="text"
          placeholder="Filter Customers" class="form-control border__grey-light" />
        <button type="submit" class="btn__img">
          <i class="fas fa-search"></i>
        </button>
      </form>
    </div>
  </div>

</div>
<div class="flex note-filters">
  <button class="btn btn-outline-success mt-2" (click)="getFilteredNotes()"><strong>Apply filters</strong>&nbsp;
    <i class="far fa-paper-plane"></i>
  </button>
</div>
