import { Component, OnInit, OnDestroy } from '@angular/core';
import { HMRServiceProviderDetailsFormBaseComponent } from '../hmr-service-provider-details-form.base.component';
import { Subscription, Observable } from 'rxjs';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { Store, Actions, Select } from '@ngxs/store';
import { HMRServiceProviderDetailsFormDetailedState } from '../state/hmr-service-provider-details-form-detailed.state';
import { PharmacistState } from '../../../../../pharmacy/state/pharmacist.state';
import { Pharmacist } from '../../../../../../shared/models/pharmacy/pharmacist.model';
import { SixCpaState } from '../../../state/six-cpa.state';

@Component({
    selector: 'app-hmr-service-provider-details-form-detailed',
    templateUrl: './hmr-service-provider-details-form-detailed.component.html',
    styleUrls: ['./../hmr-service-provider-details-form.component.scss']
  })
  export class HMRServiceProviderDetailsFormDetailedComponent extends HMRServiceProviderDetailsFormBaseComponent implements OnInit, OnDestroy {
    @Select(PharmacistState.pharmacists) pharmacists$: Observable<any>;
    private readonlySubscription: Subscription;
    private formSubscription: Subscription;

    constructor(formBuilder: FormBuilder, store: Store, actions: Actions) {
      super(formBuilder, store, actions);
      this.serviceProviderDetailsForm.addControl('pharmacist', new FormControl('', Validators.required));
      this.serviceProviderDetailsForm.addControl('pharmacistGivenName', new FormControl('', Validators.required));
      this.serviceProviderDetailsForm.addControl('pharmacistFamilyName', new FormControl('', Validators.required));
      this.serviceProviderDetailsForm.addControl('ahpraNumber', new FormControl('', [Validators.required, Validators.max(15)]));
      this.serviceProviderDetailsForm.addControl('pharmacistPhoneNumber', new FormControl('', Validators.required));
      this.serviceProviderDetailsForm.addControl('pharmacistEmail', new FormControl('', [Validators.required, Validators.email]));

    }

    ngOnInit() {
      const pharmacistList = this.store.selectSnapshot(PharmacistState.pharmacists);
      if (pharmacistList.length > 0) {
        this.setupPharmacistData(pharmacistList);
      }
      this.formSubscription = this.store.select(HMRServiceProviderDetailsFormDetailedState.form)
        .subscribe(() => this.onFormChange());
      this.readonlySubscription = this.store.select(SixCpaState.formsReadOnly)
        .subscribe(readonly => readonly ? this.serviceProviderDetailsForm.disable() : this.serviceProviderDetailsForm.enable());
    }

    ngOnDestroy() {
     if (this.formSubscription) { this.formSubscription.unsubscribe(); }
     if (this.readonlySubscription) { this.readonlySubscription.unsubscribe(); }
     
    }

    setPharmacistData() {
      const pharmacist = this.store.selectSnapshot(PharmacistState.pharmacists)
        .filter((x) => `${x.givenNames} ${x.familyName}` === this.serviceProviderDetailsForm.value.pharmacist);
      this.setupPharmacistData(pharmacist);
    }

    private setupPharmacistData (pharmacistList: Pharmacist[]) {
      if (pharmacistList.length > 0) {
        const { givenNames, ahpraNumber, familyName, pharmacistEmail, pharmacistPhone } = pharmacistList[0];
        this.serviceProviderDetailsForm.patchValue({
          pharmacist: `${givenNames} ${familyName}`,
          pharmacistGivenName: givenNames,
          pharmacistFamilyName: familyName,
          ahpraNumber,
          pharmacistPhoneNumber: pharmacistPhone,
          pharmacistEmail
         });
      }
    }
  }
