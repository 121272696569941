import { State, Action, StateContext, Selector } from '@ngxs/store';
import { SetAdditionalDetailsProgress, UpdateAdditionalDetailsForm } from './additional-details.actions';
import { Injectable } from '@angular/core';

export class AdditionalDetailsStateModel {
  public completedItems: number;
  public totalItems: number;
  public additionalDetailsForm: {
    model: Partial<IAdditionalDetailsStateModel>;
    status: string,
    errors: any
  };
}
export interface IAdditionalDetailsStateModel {
  relevantMedicalConditions: string;
  clinicalInterventions: Date | string;
  result: string;
}
@Injectable()
@State<AdditionalDetailsStateModel>({
  name: 'additionalDetails',
  defaults: {
    completedItems: 0,
    totalItems: 0,
    additionalDetailsForm: {
      model: undefined,
      status: '',
      errors: {}
    },
  }
})
export class AdditionalDetailsState {
  @Selector()
  static totalItems(state: AdditionalDetailsStateModel) { return state.totalItems; }

  @Selector()
  static completedItems(state: AdditionalDetailsStateModel) { return state.completedItems; }

  @Selector()
  static additionalDetailsForm(state: AdditionalDetailsStateModel) { return state.additionalDetailsForm.model; }

  @Action(SetAdditionalDetailsProgress)
  setProgress(ctx: StateContext<AdditionalDetailsStateModel>, action: SetAdditionalDetailsProgress) {
    ctx.patchState({
      totalItems: action.totalItems,
      completedItems: action.completedItems
    });
  }

  @Action(UpdateAdditionalDetailsForm)
  UpdateForm(ctx: StateContext<AdditionalDetailsStateModel>, { form }: UpdateAdditionalDetailsForm) {
    ctx.patchState({
      additionalDetailsForm: {
        model: {
          relevantMedicalConditions: form.relevantMedicalConditions,
          clinicalInterventions: form.clinicalInterventions,
          result: form.result
        },
        status: null,
        errors: null
      }
    })
  }
}
