<nz-modal [(nzVisible)]="isVisible" [nzTitle]="modalHeader" [nzFooter]="modalFooter" [nzWidth]="'900px'"
    [nzCloseIcon]="modalCloseIcon" (nzOnCancel)="handleCancel()">
    <ng-template #modalHeader>
        <div class="stripe-logo">
            <img src="assets/img/stripe.png" />
        </div>
    </ng-template>
    <ng-template #modalCloseIcon>
        <i class="fas fa-times"></i>
    </ng-template>
    <div *nzModalContent>
        <div class="error-description" *ngIf="submitted && stripeForm.invalid">
            <i class="fas fa-exclamation-triangle fa-lg mr-3 asl-error"></i>
            <div>It appears there has been an error found with the submission of your order to STRIPE. Please rectify
                the issues below and attempt to re-generate the link. If the issue persists please contact support.
            </div>
        </div>

         <!--if we want to display error message insted of popup error -->
        <!-- <div class="error-description" *ngIf="(error$ | async) as error">
            <ng-container *ngIf="error && error?.message">
                <i class="fas fa-exclamation-triangle fa-lg mr-3 asl-error"></i>
                <div> {{ error.message }} </div>
             </ng-container>
        </div> -->

        <p>
            Create an invoice for a customer via STRIPE using the table below. Select the medication you wish
            to include in the transaction and when you're ready click 'generate payment link'.
        </p>

        <form [formGroup]="stripeForm">
            <div class="medications-container">
                <ng-container>
                    <table class="medications-table">
                        <tr>
                            <th><label class="checkbox" nz-checkbox formControlName="mastercheckbox"
                                    (ngModelChange)="changeMasterCheckbox($event)"></label></th>
                            <th>Medication description</th>
                            <th class="center">Script QTY</th>
                            <th class="center">Unit Price</th>
                            <th class="center">GST Inclusive</th>
                        </tr>
                        <ng-container formArrayName="medications">
                            <tr [formGroupName]="i"
                                *ngFor="let med of stripeForm.controls.medications.controls; let i=index">
                                <td class="checkbox-selection">
                                    <label *ngIf="!med.controls.isAdditional.value" nz-checkbox
                                        formControlName="selected" (ngModelChange)="changeCheckbox($event, i)"></label>
                                    <i *ngIf="med.controls.isAdditional.value" class="far fa-trash-alt icon-color"
                                        (click)="removeAdditionalItem(i)"></i>
                                </td>
                                <td>
                                    <div class="d-flex justify-content-between align-items-center">
                                        <div class="d-flex justify-content-between align-items-center">
                                            <div class="item-icon">
                                                <div class="item-icon"
                                                    [ngSwitch]="med.controls.orderRequestItemType.value">
                                                    <div *ngSwitchCase="orderItemType.OnFileScript">
                                                        <i class="fal fa-file-prescription"></i>
                                                    </div>
                                                    <div *ngSwitchCase="orderItemType.EScript">
                                                        <i class="fas fa-qrcode"></i>
                                                    </div>
                                                    <div *ngSwitchCase="orderItemType.Image">
                                                        <img class="med-image" width="15px" height="15px"
                                                            [src]="med.controls.attachmentUrl.value"
                                                            alt="medication image">
                                                    </div>
                                                    <div *ngSwitchCase="orderItemType.OTCItem">
                                                        <i class="fal fa-prescription-bottle-alt"></i>
                                                    </div>
                                                    <div *ngSwitchDefault style="padding: 10px;"></div>
                                                </div>
                                            </div>
                                            <div>
                                                <div class="item-name">
                                                    <input name="stockItemName" type="text"
                                                        formControlName="stockItemName"
                                                        class="form-control border__grey-light" id="stockItemName"
                                                        [ngClass]="{'error':  submitted && med.get('stockItemName').invalid}" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td class="center">{{med.controls.quantity.value}}</td>
                                <td class="center">
                                    <input name="price" type="number" formControlName="price" (change)="generateTotal()"
                                        class="form-control border__grey-light" id="price" step="0.01"
                                        [ngClass]="{'error':  submitted && med.get('price').invalid}" />
                                </td>
                                <td class="center">
                                    <label [ngClass]="{ 'checkbox-margin': med.controls.isAdditional.value }" nz-checkbox formControlName="isGSTIncluded"></label>&nbsp;&nbsp;
                                    <i  *ngIf="!med.controls.isAdditional.value" nzTooltipTitle="GST provided by Point of Sale" nzTooltipPlacement="topRight"
                                        nz-button nz-tooltip class="fas fa-info-circle" aria-hidden="true"></i>
                                </td>
                            </tr>
                        </ng-container>
                    </table>

                    <a class="cancel-button" (click)="addAdditionalCharge()"><i class="fas fa-plus"
                            aria-hidden="true"></i> Add Additional Charge</a>
                </ng-container>
            </div>
        </form>
        <div g-xs="p4 text:center" class="flex" *ngIf="(invoiceLoading$ | async) as loading">
            <app-loader class="loading"></app-loader>
        </div>
    </div>
    <ng-template #modalFooter>
        <div class="flex-container">
            <div>
                <button class="done-button button btn__yellow" (click)="generatePaymentLink()">Generate Payment
                    Link</button>
                <a class="cancel-button" (click)="handleCancel()">Cancel</a>
            </div>
            <div>
                <table class="custom-table">
                    <tr class="font-bold">
                        <td>Total</td>
                        <td>${{stripeForm.value.total | number : '1.2-2' }} </td>
                    </tr>
                    <!--this was part of the designs but we aren't really tracking GST total-->
                    <!--can add if required -->
                    <!-- <tr>
                        <td>GST</td>
                        <td>$12.20</td>
                    </tr> -->
                </table>
            </div>
        </div>
    </ng-template>
</nz-modal>


