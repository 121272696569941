import { TogglePatientConfirm } from "../AIR/air-lookup-form/state/air-lookup-form.actions";
import { ToggleConfirmMedsCheckAction } from "../confirm-meds-check/state/confirm-meds-check.actions";
import { ToggleMedicationListConfirm } from "../medication-list/medication-list-confirm/state/medication-list-confirm.actions";
import { ToggleSickLeaveConfirmAction } from "../sick-leave/sick-leave-confirm/state/sick-leave-confirm.actions";
import { ToggleSixCpaContentAction } from "./six-cpa.actions";

export const closeConfirmationActions = [
    new ToggleSixCpaContentAction('in'),
    new ToggleConfirmMedsCheckAction('out'),
    new ToggleSickLeaveConfirmAction('out'),
    new ToggleMedicationListConfirm('out'),
    new TogglePatientConfirm('out')
]