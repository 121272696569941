import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfirmFormComponent } from './confirm-form.component';

@NgModule({
  declarations: [ConfirmFormComponent],
  imports: [
    CommonModule
  ],
   exports: [ConfirmFormComponent]
})
export class ConfirmFormModule { }
