import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import { Router } from '@angular/router';
import { StorageService } from '../core/storage/storage.service';
import { Tenant, TenantPermission } from '../models/tenant.model';
import { CrudService } from './crud.service';
import { AlertService } from '~/modules/core/alert/alert.service';

@Injectable()
export class TenantService extends CrudService {
  constructor(public http: HttpClient, public router: Router, public storageService: StorageService, public alertService: AlertService) {
    super(http, router, storageService, alertService, 'tenant');
  }

  getTenantList(): Observable<Tenant[]> {
    return this.getList();
  }

  addTenant(item: Tenant): Observable<Tenant> {
    return this.add(item);
  }

  updateTenant(item: Tenant): Observable<Tenant> {
    return this.update(item);
  }

  removeTenant(id: any) {
    return this.remove(id);
  }

  updatePermissions(tenantId:number, permissions:TenantPermission[]){
    return this.http.put("/api/features", {
      tenantId,
      permissions
    })
  }
}
