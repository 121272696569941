import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MedicationProfileListItemComponent } from './medication-profile-list-item.component';
import { ReactiveFormsModule } from '@angular/forms';
import { InlineInputEditModule } from '../../../six-cpa/inline-input-edit/inline-input-edit.module';


@NgModule({
    declarations: [
        MedicationProfileListItemComponent
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        InlineInputEditModule
    ],
    exports: [
        MedicationProfileListItemComponent
    ]
})
export class MedicationProfileListItemModule { }
