<ng-container *ngIf="(commsInboxState$ | async) as state">
  <form (submit)="clickReply()" [formGroup]="messageForm" ngxsForm="chat.messageForm" nz-row [ngClass]="{'reply-modal-compose': true, 'expanded': expanded}">
    <div *ngIf="!expanded" nz-row [nzGutter]="3" nzAlign="middle" class="w100">
      <div nz-col nzFlex="90px"  class="channel-info">
        <div class="icon">
          <i [ngClass]="getChannelIcon(channelType)"></i>
        </div>
        <div class="name">
          {{getChannelName(channelType)}}
        </div>
      </div>
      <input nz-col nzFlex="auto" formControlName="message"  nz-input placeholder="Write your message" nzBorderless />
      <div nz-col nzFlex="90px">
        <button type="submit" [disabled]="!messageForm.valid || state.isSendingMessage" nz-col class="send-button">
          <ng-container *ngIf="!state.isSendingMessage">
            <i class="fal fa-paper-plane"></i>&nbsp;Send
          </ng-container>
          <ng-container *ngIf="state.isSendingMessage">
            <i class="fas fa-spinner fa-spin"></i>&nbsp;Loading
          </ng-container>
        </button>
      </div>
      <div nz-col nzFlex="40px">
        <!-- hide expand/collapse due to feedback -->
        <!-- <button [ngClass]="{'expand-button': true, 'expanded': expanded}"  (click)="toggleExpandedView()" type="button" nz-button nzType="default">
          <i *ngIf="!expanded" class="fal fa-expand"></i>
          <i *ngIf="expanded" class="fal fa-window-minimize"></i>
        </button> -->
      </div>
    </div>
    <div *ngIf="expanded" [nzGutter]="3" class="w100">
      <div nz-row nzAlign="middle" class="w100">
        <div nz-col nzFlex="90px"  class="channel-info">
          <div class="icon">
            <i [ngClass]="getChannelIcon(channelType)"></i>
          </div>
          <div class="name">
            {{getChannelName(channelType)}}
          </div>
        </div>
        <div nz-col nzFlex="auto">
          <nz-select *ngIf="state.isLoadingTemplates || state.messageTemplates" [nzLoading]="state.isLoadingTemplates" [ngClass]="{'template-selected': !!messageForm.get('template').value, 'templates-select-box': true}" (ngModelChange)="changeTemplate($event)" formControlName="template" nzShowSearch nzAllowClear nzPlaceHolder="Select template" [nzSuffixIcon]="icon">
            <nz-option *ngFor="let i of state.messageTemplates" 
              [nzLabel]="i.title"
              [nzValue]="i.message">
            </nz-option>
          </nz-select>
        </div>
        <ng-template #icon><i class="fal fa-chevron-down dropdown-arrow"></i></ng-template>
        <div nz-col nzFlex="40px">
           <!-- removed expand/collapse due to feedback -->
          <!-- <button class="expand-button"  (click)="toggleExpandedView()" type="button" nz-button nzType="default">
            <i *ngIf="!expanded" class="fal fa-expand"></i>
            <i *ngIf="expanded" class="fal fa-window-minimize"></i>
          </button> -->
        </div>
        
      </div>
      <div *ngIf="channelType === this.messageChannels.Email" nz-row class="w100 mt5">
        <input [ngClass]="{'has-error': messageForm.get('subject').invalid && messageForm.get('subject').touched }" class="subject" nz-col nzFlex="auto" nz-input formControlName="subject" placeholder="Subject" nzBorderless />
      </div>
      <div nz-row class="w100 mt5">
        <textarea class="message" nz-col rows="2" nz-input nzFlex="auto" formControlName="message" placeholder="Write your message" nzBorderless></textarea>
      </div>
      <div nz-row class="w100 mt5">
        <button nzFlex="100px" type="submit" [disabled]="!messageForm.valid || state.isSendingMessage" nz-col class="send-button">
          <ng-container *ngIf="!state.isSendingMessage">
            <i class="fal fa-paper-plane"></i>&nbsp;Send
          </ng-container>
          <ng-container *ngIf="state.isSendingMessage">
            <i class="fas fa-spinner fa-spin"></i>&nbsp;Loading
          </ng-container>
        </button>
      </div>
    </div>
  </form>
</ng-container>
