import { DAAPatientDetailsFormModel } from '../../../../../../shared/models/six-cpa/DAA/daa-patient-details-form.model';

export enum DAAPatientDetailsFormActions {
  UPDATE_PROGRESS = '[DAAPatientDetailsForm] Update Progress',
  UPDATE = '[DAAPatientDetailsForm] Update'
}

export class SetTotalItemsAndCompletedItems {
  static readonly type = DAAPatientDetailsFormActions.UPDATE_PROGRESS;
  constructor(public totalItems: number, public completedItems: number) { }
}

export class UpdateDAAPatientDetailsForm {
  static readonly type = DAAPatientDetailsFormActions.UPDATE;
  constructor(public form: Partial<DAAPatientDetailsFormModel>) { }
}
