import { State, Action, StateContext, Selector, Store } from '@ngxs/store';
import {
  ToggleSickLeaveConfirmAction,
  GoBackFromSickLeaveConfirmAction,
  SickLeaveConfirmErrorAction,
  GetCertificateSickLeaveConfirmAction,
  SetDoctorListSickLeaveConfirm,
  SendToDoctorSickLeaveConfirm,
  GetDoctorReportSickLeaveConfirm,
  SendToPatientSickLeaveConfirm,
  ToggleResponseSickLeaveConfirm,
  SendSickLeaveReport,
  OpenSickLeaveSendDocumentFormAction,
  CloseSickLeaveSendDocumentFormAction
} from './sick-leave-confirm.actions';
import { ToggleSixCpaContentAction, SetProfileForSixCpaAction } from '../../../state/six-cpa.actions';
import { SickLeaveService } from '../../sick-leave-form-list/services/sick-leave.service';
import { tap, catchError, finalize } from 'rxjs/operators';
import { saveAs } from 'file-saver';
import { IDoctorDetailsModel, IReportModel } from '../../../../../../shared/models/six-cpa/doctor-report.model';
import { ProfileState } from '../../../../../core/profile/state/profile.state';
import * as moment_ from 'moment';
const moment = moment_;
import { SickLeaveDoctorEmailModel } from '../../../../../../shared/models/six-cpa/sick-leave/sick-leave-doctor-email.model';
import { WindowService } from '../../../../../../shared/services/window.service';
import { ScryptDigitalSignatureState } from '../../../../../../shared/components/scrypt-digital-signature/scrypt-digital-signature/state/scrypt-digital-signature.state';
import { SickLeavePatientEmailModel } from '../../../../../../shared/models/six-cpa/sick-leave/sick-leave-patient-email.model';
import { Injectable } from '@angular/core';
import { getDoctorName } from '../../../../../../shared/helpers/doctor-name.helper';
import { ChannelPreference } from './../../../../../../shared/models/pharmacy/pharmacy-comms-details.model';

export class SickLeaveConfirmStateModel {
  public slideMode: string;
  public error: any;
  public certificate: IReportModel;
  public doctorReport: IReportModel;
  public doctors: IDoctorDetailsModel[];
  public doctorDetailsForm: {
    model: Partial<IDoctorDetailsModel>;
  };
  public patientDetaisForm: {
    model: Partial<IPatientDetailsModel>;

  };
  public loading: boolean;
  public showResponse: string;
  public showSendDocumentForm: boolean;
}

export interface IPatientDetailsModel {
  email?: string;
}

@Injectable()
@State<SickLeaveConfirmStateModel>({
  name: 'sickLeaveConfirm',
  defaults: {
    slideMode: 'out',
    error: null,
    certificate: {
      name: 'Sick Leave Certificate',
      loading: false
    },
    doctorReport: {
      name: 'Sick Leave Report',
      loading: false
    },
    patientDetaisForm: undefined,
    doctorDetailsForm: undefined,
    doctors: [],
    loading: false,
    showResponse: 'out',
    showSendDocumentForm: false
  }
})
export class SickLeaveConfirmState {
  constructor(private sickLeaveService: SickLeaveService, private store: Store, private windowService: WindowService) { }

  @Selector()
  static slideMode(state: SickLeaveConfirmStateModel) { return state.slideMode; }

  @Selector()
  static certificate(state: SickLeaveConfirmStateModel) { return state.certificate; }

  @Selector()
  static doctorReport(state: SickLeaveConfirmStateModel) { return state.doctorReport; }

  @Selector()
  static doctorDetailsForm(state: SickLeaveConfirmStateModel) { return state.doctorDetailsForm.model; }

  @Selector()
  static showSendDocumentForm(state: SickLeaveConfirmStateModel) { return state.showSendDocumentForm; }

  @Action(SetDoctorListSickLeaveConfirm)
  setDoctorList(ctx: StateContext<SickLeaveConfirmStateModel>, action: SetDoctorListSickLeaveConfirm) {
    const state = ctx.getState();
    const doctors = action.doctors.map(item => {
      const { doctorID, emailAddress } = item;
      return {
        id: doctorID,
        fullName: getDoctorName(item),
        email: emailAddress
      } as IDoctorDetailsModel;
    });
    let doctorDetailsForm;
    if (doctors.length > 0) {
      const { email, fullName } = doctors[0];
      doctorDetailsForm = { email, fullName } as IDoctorDetailsModel;
    }
    ctx.patchState({
      doctors,
      doctorDetailsForm: { ...state.doctorDetailsForm, model: doctorDetailsForm }
    });
  }

  @Action(SendToDoctorSickLeaveConfirm)
  sendToDoctor(ctx: StateContext<SickLeaveConfirmStateModel>, { sixCpaFormId, customisedDoctorReport, clientId }: SendToDoctorSickLeaveConfirm) {
    const state = ctx.getState();
    const { fullName, email, doctorAddress } = state.doctorDetailsForm.model;
    const signature = this.store.selectSnapshot(ScryptDigitalSignatureState.currentDigitalSignature);

    ctx.patchState({ loading: true });
    const formData = {
      fullName,
      email,
      doctorAddress,
      reportBody: customisedDoctorReport.reportBody,
      signature
    } as SickLeaveDoctorEmailModel;
    return this.sickLeaveService.sendDoctorReport(clientId, sixCpaFormId, formData).pipe(
      tap(() => ctx.patchState({ showResponse: 'in' })),
      catchError((error) => {
        return ctx.dispatch(new SickLeaveConfirmErrorAction(error));
      }),
      finalize(() => ctx.patchState({ loading: false }))
    );
  }

  @Action(OpenSickLeaveSendDocumentFormAction)
  openSickLeaveSendDocumentFormAction(ctx: StateContext<SickLeaveConfirmStateModel>, action: OpenSickLeaveSendDocumentFormAction) {
    ctx.patchState({ showSendDocumentForm: true });
  }

  @Action(CloseSickLeaveSendDocumentFormAction)
  closeSickLeaveSendDocumentFormAction(ctx: StateContext<SickLeaveConfirmStateModel>, action: CloseSickLeaveSendDocumentFormAction) {
    ctx.patchState({ showSendDocumentForm: false });
  }

  @Action(SendSickLeaveReport)
  sendSickLeaveReport(ctx: StateContext<SickLeaveConfirmStateModel>, { sixCpaFormId, customisedDoctorReport, clientId, commsType, contact }: SendSickLeaveReport) {
    const state = ctx.getState();
    const { fullName, email, doctorAddress } = state.doctorDetailsForm.model;
    const signature = this.store.selectSnapshot(ScryptDigitalSignatureState.currentDigitalSignature);

    ctx.patchState({ loading: true });
    const formData = {
      fullName,
      email: (commsType === ChannelPreference.Email) ? contact : null,
      doctorAddress,
      reportBody: customisedDoctorReport.reportBody,
      signature,
      commsType,
      mobile: (commsType === ChannelPreference.Sms) ? contact : null,
    } as SickLeaveDoctorEmailModel;

    return this.sickLeaveService.sendSickLeaveReport(clientId, sixCpaFormId, formData).pipe(
      tap(() => ctx.patchState({ showResponse: 'in' })),
      catchError((error) => {
        return ctx.dispatch(new SickLeaveConfirmErrorAction(error));
      }),
      finalize(() => ctx.patchState({ loading: false }))
    );
  }


  @Action(GetDoctorReportSickLeaveConfirm)
  getDoctorReport(ctx: StateContext<SickLeaveConfirmStateModel>, { clientId, sixCpaFormId, customisedDoctorReport }: GetDoctorReportSickLeaveConfirm) {
    const state = ctx.getState();
    const { fullName, email, doctorAddress } = state.doctorDetailsForm.model;
    ctx.patchState({ doctorReport: { ...state.doctorReport, loading: true } });
    const profile = this.store.selectSnapshot(ProfileState.profile);
    const signature = this.store.selectSnapshot(ScryptDigitalSignatureState.currentDigitalSignature);
    const fileName =
      `${profile.patient.firstname} ${profile.patient.surname} - ${moment(profile.patient.birthDate, 'YYYY-MM-DD').format('DD-MM-YYYY')} - Doctor Certificate Report.pdf`;
    const formData = {
      fullName,
      email,
      doctorAddress,
      reportBody: customisedDoctorReport.reportBody,
      signature: signature
    } as SickLeaveDoctorEmailModel;
    return this.sickLeaveService.getDoctorReport(clientId, sixCpaFormId, formData).pipe(
      tap((response) => {
        saveAs(response, fileName);
        this.openPdfInNewTab(response);
      }),
      catchError((error) => {
        return ctx.dispatch(new SickLeaveConfirmErrorAction(error));
      }),
      finalize(() => ctx.patchState({ doctorReport: { ...state.doctorReport, loading: false } }))
    );
  }
  @Action(SendToPatientSickLeaveConfirm)
  sendToPatient(ctx: StateContext<SickLeaveConfirmStateModel>, { sixCpaFormId, clientId }: SendToPatientSickLeaveConfirm) {
    const state = ctx.getState();
    const { email } = state.patientDetaisForm.model;
    const signature = this.store.selectSnapshot(ScryptDigitalSignatureState.currentDigitalSignature);

    ctx.patchState({ loading: true });
    const formData = {
      email,
      signature
    } as SickLeavePatientEmailModel;
    return this.sickLeaveService.sendPatientReport(clientId, sixCpaFormId, formData).pipe(
      tap(() => ctx.patchState({ showResponse: 'in' })),
      catchError((error) => {
        return ctx.dispatch(new SickLeaveConfirmErrorAction(error));
      }),
      finalize(() => ctx.patchState({ loading: false }))
    );
  }
  @Action(ToggleSickLeaveConfirmAction)
  toggle(ctx: StateContext<SickLeaveConfirmStateModel>, action: ToggleSickLeaveConfirmAction) {
    ctx.patchState({ slideMode: action.slideMode });
  }

  @Action(GoBackFromSickLeaveConfirmAction)
  goBack(ctx: StateContext<SickLeaveConfirmStateModel>, action: GoBackFromSickLeaveConfirmAction) {
    ctx.dispatch([new ToggleSixCpaContentAction('in'), new ToggleSickLeaveConfirmAction('out')]);
  }

  @Action(SickLeaveConfirmErrorAction)
  handleError(ctx: StateContext<SickLeaveConfirmStateModel>, action: SickLeaveConfirmErrorAction) {
    ctx.patchState({ error: action.error });
  }

  @Action(GetCertificateSickLeaveConfirmAction)
  getPatientReport(ctx: StateContext<SickLeaveConfirmStateModel>, action: GetCertificateSickLeaveConfirmAction) {
    const state = ctx.getState();
    const signature = this.store.selectSnapshot(ScryptDigitalSignatureState.currentDigitalSignature);
    ctx.patchState({ certificate: { ...state.certificate, loading: true } });
    const { clientId, sixCpaFormId } = action;
    return this.sickLeaveService.getCertificate(clientId, sixCpaFormId, signature).pipe(
      tap((response) => {
        saveAs(response, 'Sick_Leave_Certificate.pdf');
      }),
      catchError((error) => ctx.dispatch(new SickLeaveConfirmErrorAction(error))),
      finalize(() => ctx.patchState({ certificate: { ...state.certificate, loading: false } }))
    );
  }

  @Action(SetProfileForSixCpaAction)
  onSetProfile(ctx: StateContext<SickLeaveConfirmStateModel>, { profile }: SetProfileForSixCpaAction) {
    const patient = profile.patient;
    ctx.patchState({
      patientDetaisForm: {
        model: {
          email: patient.emailAddress,
        }
      }
    });
  }
  @Action(ToggleResponseSickLeaveConfirm)
  toggleResponse(ctx: StateContext<SickLeaveConfirmStateModel>, { showResponse }: ToggleResponseSickLeaveConfirm) {
    ctx.patchState({ showResponse });
  }

  private openPdfInNewTab(response: any) {
    const fileURL = URL.createObjectURL(response);
    this.windowService.open(fileURL);
  }
}
