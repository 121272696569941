<div class="container">
  <div style="padding-top: 5%;">
    <div class="scrypt text-center">
      <img src="/assets/img/logo-big.png" /><br />
    </div>
    <div class="mt-2 text-center">
      <hr />
    </div>
    <div class="confirmation-icon mt-2">
      <i class="fas fa-check-circle color__green confirmation-check"></i>
    </div>
  </div>

  <div class="main-content">
    <div class="main-text">
      You have unsubscribed communications from {{pharmacyName}}.<br /><br />
    </div>
    <div class="sub-text">
      You won't recieve any communications <small>(of the chosen type)</small> from {{pharmacyName}} in the future.
    </div>
  </div>

  <div class="row footer-scrypt" style="text-align: center;">
    <div class="col">
        © 2021 Scrypt
      </div>
    <div class="col">
      <a href="mailto:info@scrypt.com.au">info@scrypt.com.au</a>
    </div>
  </div>
</div>
