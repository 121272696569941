export interface MetaViewModel {
  id?: number;
  tenantCustomerId?: number;
  genericCategoryId?: string;
  productDispensedId?: string;
  requestable?: boolean;
  isflagged?: boolean;
  dohTarget?: number;
  dispenseBuffer?: number;
  createdOn?: Date | string;
  createdBy?: number;
  enabled?: boolean;
  categoryId?: number | null;
  categoryName?: string;
  categoryFriendlyName?: string;
  categoryPosition?: number | null;
  categoryDispenseBufferInDays?: number | null;
  categoryDohTarget?: number | null;
  startDateId?: number | null;
  startDate?: Date | string | null;
  startDateType?: string;
  customerId?: string;
  profileId?: number | null;
  tenantId?: number | null;
  customerType?: string;
  isPaused?: boolean | null;
  pauseEnd?: Date | string | null;
  onfile?: boolean;
  notificationsEnabled?: boolean;
  lastRepeatDue?: boolean | null;
  beforeNextRepeatDue?: boolean | null;
  medicationOverdue?: boolean | null;
  medicationOverdueOnLastRepeat?: boolean | null;
  scriptExpiring?: boolean | null;
  scriptOwing?: boolean | null;
  currentDosage?: DosageHistoryViewModel;
  dosageHistory?: DosageHistoryViewModel[];
  adjustments?: AdjustmentViewModel[];
  stockStartingPoints?: StockStartingPointModel[];
  currentStockStartingPoint?: StockStartingPointModel;
}


export interface DosageHistoryViewModel {
  id: number;
  metaId: number;
  frequency: number;
  dosesPerDay: number;
  createdOn: Date | string;
  enabled: boolean;
}

export interface AdjustmentViewModel {
  doseAdjustment: number;
  createdOn: Date | string;
}

export interface StockStartingPointModel {
  id?: number;
  scriptMetaId: number;
  doh: number;
  startDate: Date | string;
  createdOn: Date | string;
  active: boolean;
  deleted?: boolean;
  changeReason?: StartingPointChangeReason;
}

export enum StartingPointChangeReason {
  dosageChange,
  adjustment,
  systemGenerated,
  categoryChange,
  nonCompliantDoh,
  initialSetup,
  bulkRelativeAdjustment
}

export interface StockModel {
  totalDisp: number;
  consumed: number | null;
  totalAdjustments: number;
  totalDohTarget: number;
  totalDoh: AmountOnHand;
  calculationLog: string[];
  totalDoses?: number;
}

export interface AmountOnHand {
  value: number | null;
  isInfinite: boolean;
}
