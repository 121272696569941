import { SixCpaActionPlanForm } from "~/shared/models/six-cpa/action-plan.model";

export enum ActionPlanFormActions {
  UPDATE = '[ActionPlanForm] Update Form',
  SET_TOTAL_COMPLETED_ITEMS = '[ActionPlanForm] Update Progress'
}

export class SetTotalItemsAndCompletedItems {
  static readonly type = ActionPlanFormActions.SET_TOTAL_COMPLETED_ITEMS;
  constructor(public totalItems, public completedItems) { }
}

export enum UpdateConsentEligibilityDetailedFormActions {
  UPDATE = '[ConsentEligibilityFormDetailed] Update Form'
}

export class SetActionPlanForm {
  static readonly type = ActionPlanFormActions.UPDATE;
  constructor(public form: Partial<SixCpaActionPlanForm>) { }
}