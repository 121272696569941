<form [formGroup]="additionalDetailsForm"
  ngxsForm="additionalDetails.additionalDetailsForm" class="p-4">
  <div class="form-group">
    <p class="col-form-label-lg font__bold">Relevant Medical Conditions</p>
    <input type="text" formControlName="relevantMedicalConditions" class="form-control form-control-lg"
    [class.has-error]="formControls.relevantMedicalConditions.invalid && (showInvalid$ | async)"
      placeholder="Notes about medical conditions">
  </div>
  <div class="form-group">
      <p class="col-form-label-lg font__bold">Clinical Interventions</p>
        <input type="text" class="form-control form-control-lg w-25"
        [class.has-error]="formControls.clinicalInterventions.invalid && (showInvalid$ | async)"
        [bsValue]="additionalDetailsForm.value.clinicalInterventions" formControlName="clinicalInterventions" id="clinicalInterventions"
        [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY' }" placeholder="dd-mm-yyyy" bsDatepicker>
    </div>
    <div class="form-group">
        <p class="col-form-label-lg font__bold">Result</p>
        <input type="text" formControlName="result" class="form-control form-control-lg"
        [class.has-error]="formControls.result.invalid && (showInvalid$ | async)"
          placeholder="Anticipated / actual">
      </div>
</form>