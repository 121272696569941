import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Store } from '@ngxs/store';
import { PushNotificationsState } from '~/modules/core/push-notifications/push-notifications.state';
import { InitPushNotificationsAction } from '~/modules/core/push-notifications/push-notifications.actions';
import { Injectable } from '@angular/core';

@Injectable()
export class PushNotificationsResolver implements Resolve<boolean> {
    constructor(private store: Store) { }
    resolve(route: ActivatedRouteSnapshot) {
        if (!this.store.selectSnapshot(PushNotificationsState.hasToken)) {
          this.store.dispatch(new InitPushNotificationsAction());
        }
        return true;
    }
}
