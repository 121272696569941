import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { ExportToCsvRequest } from '~/shared/models/export-to-csv-request.model';
import * as moment from 'moment';
import { MedsCheckType } from '~/modules/profile/six-cpa/meds-check/state/meds-check-form-list.state';
import { StagedSupplyFormType } from '~/modules/profile/six-cpa/Staged-Supply/staged-supply/state/staged-supply-form-list.state';
import { DAAFormType } from '~/modules/profile/six-cpa/DAA/dose-administration-aids/state/dose-administration-aids.state';
import { HMRFormType } from '~/modules/profile/six-cpa/HMR/hmr-form-list/state/hmr-form.state';
import { Store, Select } from '@ngxs/store';
import { ExportToCsv } from '../state/csv-export.actions';
import { CIClaimingPeriodDateRanges, CalendarMonths } from '~/shared/models/six-cpa/FormKeyValuePairs';
import { CsvExportState } from '../state/csv-export.state';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { CrmService } from '~/shared/services/crm.service';
import { GroupType } from '~/shared/models/crm/groupType.model';
import { SixCpaFormTypes } from '~/modules/profile/six-cpa/six-cpa-form-types.enum';

const CURRENT_YEAR = new Date().getFullYear();
@Component({
  selector: 'app-sixcpa-csv-export',
  templateUrl: './sixcpa-csv-export.component.html',
  styleUrls: ['./sixcpa-csv-export.component.scss']
})
export class SixcpaCsvExportComponent implements OnInit {
  @Select(CsvExportState.clinicalInterventionCount) clinicalInterventionCount$: Observable<any>;
  @Select(CsvExportState.loading) loading$: Observable<any>;
  sixcpaCsvExportForm: FormGroup;
  serviceTypes = SixCpaFormTypes;
  medsCheckTypes = MedsCheckType;
  stagedSupplyTypes = StagedSupplyFormType;
  daaTypes = DAAFormType;
  hmrTypes = HMRFormType;
  ciClaimingPeriods = CIClaimingPeriodDateRanges;
  calendarMonths = CalendarMonths;
  activeServiceSubTypes: any;
  request: ExportToCsvRequest;
  groupList: GroupType[];
  valid = false;
  CPA_Version = environment.CPAVersion;

  constructor(private formBuilder: FormBuilder, private store: Store, private crmService: CrmService) {
    this.sixcpaCsvExportForm = this.formBuilder.group({
      fromDate: new FormControl(moment(new Date(), 'YYYY-MM-DD').format('DD-MM-YYYY'), Validators.required),
      toDate: new FormControl(moment(new Date(), 'YYYY-MM-DD').format('DD-MM-YYYY'), Validators.required),
      serviceType: new FormControl(this.serviceTypes.MedsCheck, Validators.required),
      daaGroup: new FormControl(),
      serviceSubType: new FormControl(this.medsCheckTypes.Regular),
      ciClaimingPeriod: new FormControl(this.ciClaimingPeriods.January1stToMarch31st),
      claimedCalendarMonth: new FormControl(this.calendarMonths.January),
      includePatientsWithNoConcessionOnFile: new FormControl(false)
    });
  }

  ngOnInit() {
    this.loadGroups();
  }

  exportToCsv() {
    this.request = this.buildRequest();
    this.store.dispatch(new ExportToCsv(this.request, this.sixcpaCsvExportForm.value.serviceType));
  }

  private loadGroups() {
    this.crmService.getGroups().subscribe((groups: GroupType[]) => {
      this.groupList = groups;
    });
  }

  private buildRequest() {
    if (this.sixcpaCsvExportForm.value.serviceType === this.serviceTypes.ClinicalIntervention) {
      return this.getDateRangeForCIRequest();
    }
    else if (this.sixcpaCsvExportForm.value.serviceType === this.serviceTypes.DAA) {
      return this.getDateRangeForDAARequest(this.sixcpaCsvExportForm.value.claimedCalendarMonth);
    }
    else if (this.sixcpaCsvExportForm.value.serviceType === this.serviceTypes.IDAA) {
      return this.getDateRangeForDAARequest(this.sixcpaCsvExportForm.value.claimedCalendarMonth);
    }
    else {
      return this.request = {
        fromDate: moment(this.sixcpaCsvExportForm.value.fromDate, 'DD-MM-YYYY').format('YYYY-MM-DD'),
        toDate: moment(this.sixcpaCsvExportForm.value.toDate, 'DD-MM-YYYY').format('YYYY-MM-DD'),
        serviceSubType: this.sixcpaCsvExportForm.value.serviceSubType,
        includePatientsWithNoConcessionOnFile: this.sixcpaCsvExportForm.value.includePatientsWithNoConcessionOnFile,
        aboriginalOrTorresStraitIslander: false
      }
    }
  }

  private getDateRangeForCIRequest() {
    const includePatientsWithNoConcessionOnFile = this.sixcpaCsvExportForm.value.includePatientsWithNoConcessionOnFile;

    switch (this.sixcpaCsvExportForm.value.ciClaimingPeriod) {
      case CIClaimingPeriodDateRanges.January1stToMarch31st: {
        return this.request = {
          fromDate: moment(`01-01-${CURRENT_YEAR}`, 'DD-MM-YYYY').format('YYYY-MM-DD'),
          toDate: moment(`31-03-${CURRENT_YEAR}`, 'DD-MM-YYYY').format('YYYY-MM-DD'),
          includePatientsWithNoConcessionOnFile: includePatientsWithNoConcessionOnFile,
          aboriginalOrTorresStraitIslander: false
        }
      }
      case CIClaimingPeriodDateRanges.April1stToJune30th: {
        return this.request = {
          fromDate: moment(`01-04-${CURRENT_YEAR}`, 'DD-MM-YYYY').format('YYYY-MM-DD'),
          toDate: moment(`30-06-${CURRENT_YEAR}`, 'DD-MM-YYYY').format('YYYY-MM-DD'),
          includePatientsWithNoConcessionOnFile: includePatientsWithNoConcessionOnFile,
          aboriginalOrTorresStraitIslander: false
        }
      }
      case CIClaimingPeriodDateRanges.July1stToSeptember30th: {
        return this.request = {
          fromDate: moment(`01-07-${CURRENT_YEAR}`, 'DD-MM-YYYY').format('YYYY-MM-DD'),
          toDate: moment(`30-09-${CURRENT_YEAR}`, 'DD-MM-YYYY').format('YYYY-MM-DD'),
          includePatientsWithNoConcessionOnFile: includePatientsWithNoConcessionOnFile,
          aboriginalOrTorresStraitIslander: false
        }
      }
      case CIClaimingPeriodDateRanges.October1stToDecember31st: {
        return this.request = {
          fromDate: moment(`01-10-${CURRENT_YEAR}`, 'DD-MM-YYYY').format('YYYY-MM-DD'),
          toDate: moment(`31-12-${CURRENT_YEAR}`, 'DD-MM-YYYY').format('YYYY-MM-DD'),
          includePatientsWithNoConcessionOnFile: includePatientsWithNoConcessionOnFile,
          aboriginalOrTorresStraitIslander: false
        }
      }
      default: {
        return this.request = {
          fromDate: moment(`01-01-${CURRENT_YEAR}`, 'DD-MM-YYYY').format('YYYY-MM-DD'),
          toDate: moment(`31-03-${CURRENT_YEAR}`, 'DD-MM-YYYY').format('YYYY-MM-DD'),
          includePatientsWithNoConcessionOnFile: includePatientsWithNoConcessionOnFile,
          aboriginalOrTorresStraitIslander: false
        }
      }
    }
  }

  private getDateRangeForDAARequest(calendarMonth: CalendarMonths) {
    return this.request = {
      fromDate: moment(this.sixcpaCsvExportForm.value.fromDate, 'DD-MM-YYYY').format('YYYY-MM-DD'),
      toDate: moment(this.sixcpaCsvExportForm.value.toDate, 'DD-MM-YYYY').format('YYYY-MM-DD'),
      groups: this.sixcpaCsvExportForm.value.daaGroup,
      serviceSubType: this.sixcpaCsvExportForm.value.serviceSubType,
      includePatientsWithNoConcessionOnFile: this.sixcpaCsvExportForm.value.includePatientsWithNoConcessionOnFile,
      aboriginalOrTorresStraitIslander: (this.sixcpaCsvExportForm.value.serviceType === this.serviceTypes.IDAA)
    }
  }
}
