import { State, Action, StateContext, Selector } from '@ngxs/store';
import { SSMedicationInformationFormModel } from '../../../../../../shared/models/six-cpa/staged-supply/ss-medication-information-form.model';
import { SetTotalAndCompletedItems, UpdateSSMedicationInformationForm } from './ss-medication-information-form.actions';
import { Injectable } from '@angular/core';

export class SSMedicationInformationFormStateModel {
  medicationInformationForm: {
    model: Partial<SSMedicationInformationFormModel>
  };
  totalItems: number;
  completedItems: number;
  valid: boolean;
}
@Injectable()
@State<SSMedicationInformationFormStateModel>({
  name: 'ssMedicationInformationForm',
  defaults: {
    medicationInformationForm: {
      model: undefined
    },
    totalItems: 0,
    completedItems: 0,
    valid: false
  }
})
export class SSMedicationInformationFormState {
  @Selector()
  static medicationInformationForm(state: SSMedicationInformationFormStateModel) { return state.medicationInformationForm.model; }

  @Selector()
  static totalItems(state: SSMedicationInformationFormStateModel) { return state.totalItems; }

  @Selector()
  static completedItems(state: SSMedicationInformationFormStateModel) { return state.completedItems; }

  @Action(SetTotalAndCompletedItems)
  setProgress(ctx: StateContext<SSMedicationInformationFormStateModel>, action: SetTotalAndCompletedItems) {
    ctx.patchState({
      totalItems: action.totalItems,
      completedItems: action.completedItems
    });
  }

  @Action(UpdateSSMedicationInformationForm)
  updateForm(ctx: StateContext<SSMedicationInformationFormStateModel>, { form }: UpdateSSMedicationInformationForm) {
    ctx.patchState({
      medicationInformationForm: {
        model: form
      }
    });
  }
}
