import { OrderMethod } from '../../../../shared/models/communication/order-method.enum';
import { TriggerType } from '../../../../shared/models/pharmacy/trigger.model';

export enum EventConfirmationActions {
  TOGGLE_MODAL = '[EventConfirmation] TOGGLE_MODAL',
  GET_EVENT_NOTIFICATION = '[EventConfirmation] GET_NOTIFICATION',
  GET_EVENT_NOTIFICATION_OVERLOAD = '[EventConfirmation] GET_EVENT_NOTIFICATION_OVERLOAD',
  DISPATCH_EVENT_NOTIFICATION = '[EventConfirmation] DISPATCH_NOTIFICATION',
  EVENT_NOTIFICATION_ERROR = '[EventConfirmation] EVENT_NOTIFICATION_ERROR',
  REFRESH_PAGE_IF_REQUIRED = '[EventConfirmation] REFRESH_PAGE_IF_REQUIRED'
}
export class ToggleEventConfirmationModal {
  static readonly type = EventConfirmationActions.TOGGLE_MODAL;
  constructor(public show: boolean) { }
}

export class GetEventNotification {
  static readonly type = EventConfirmationActions.GET_EVENT_NOTIFICATION;
  constructor(public triggerType: TriggerType) { }
}

export class GetEventNotificationOverload {
  static readonly type = EventConfirmationActions.GET_EVENT_NOTIFICATION_OVERLOAD;
  constructor(public tenantCustomerId: number, public  orderMethod: OrderMethod, public patientType: string, public triggerType: TriggerType) { }
}

export class DispatchEventNotification {
  static readonly type = EventConfirmationActions.DISPATCH_EVENT_NOTIFICATION;
  constructor() { }
}

export class EventNotificationError {
  static readonly type = EventConfirmationActions.EVENT_NOTIFICATION_ERROR;
  constructor(public error: any) { }
}

export class RefreshPageIfRequired {
  static readonly type = EventConfirmationActions.REFRESH_PAGE_IF_REQUIRED;
  constructor() { }
}

