import { Component, OnInit } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { MedicationInformationState } from '../medication-information-form/state/medication-information.state';

@Component({
  selector: 'app-medication-information-form-list-item',
  templateUrl: './medication-information-form-list-item.component.html'
})
export class MedicationInformationFormListItemComponent implements OnInit {

  constructor() { }

  @Select(MedicationInformationState) medicationInformationForm$: Observable<any>;

  ngOnInit() {
  }

}
