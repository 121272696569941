<section>
  <div class="font__title-3 font__light">Message History
    <small><a class="float-right pointer" (click)="forceScrollDown()">See latest</a></small>
  </div>
  <div id="messenger-history" g-xs="p3 mt2 mb3" class="border__grey container" ngx-auto-scroll lock-y-offset="10">
    <app-message *ngFor="let item of messageHistory | orderByMessageDate"
      [fromCustomer]="item.sentFrom === messengerFrom.Client"
      [fromPharmacy]="item.sentFrom === messengerFrom.Pharmacy">
      <div class="font__title-0">
        {{item.timestamp | tenantDate: "DD/MM/YYYY hh:mm A"}}
      </div>
      <div class="font__title-1 message">
        <p [innerHTML]="urlify(item.message)"></p>
        <div class="attachments">
          <ng-container *ngFor="let attachment of item.attachments">
            <img class="attachment" style="cursor: pointer;" (click)="openAttachment(attachment)" [src]="attachment">
          </ng-container>
        </div>
        <div *ngIf="checkForEScript(item.message)" class="image-link">
          <a class="image-link" (click)="openEScriptModal(item.message)">Queue eScript</a>
        </div>
      </div>
    </app-message>
  </div>
</section>
<ng-container *ngIf="showModal">
  <div class="modal confirm-modal">
    <div class="modal-body border__grey">
      <img [src]="currentAttachment" (click)="openAttachmentInNewWindow(currentAttachment)" />
      <div class="print-wrapper">
        <button class="button btn-sm btn__green" (click)="openAttachmentInNewWindow(currentAttachment)">Open and
          Print</button>
      </div>
      <div g-xs="mt2">
        <button class="button btn__yellow" g-xs="mr1" (click)="closeModal()">Cancel</button>
      </div>
    </div>
  </div>
  <div class="modal-background"></div>
</ng-container>
