<section class="message-history">
  <div class="font__title-3 font__light">SMS Message History</div>
  <div id="sms-history" g-xs="p3 mt2 mb3" class="sms-history border__grey" ngx-auto-scroll lock-y-offset="10" observe-attributes>
    <app-message *ngFor="let item of messageHistory"
      [fromCustomer]="item.creationSource === messageSource.Customer"
      [fromPharmacy]="item.creationSource !== messageSource.Customer"
      [isSystem]="checkIsSystem(item.creationSource)">
      <div class="font__title-0">
        {{item.author}} - {{item.date | tenantDate: "DD/MM/YYYY hh:mm A"}} - {{getMessageSource(item.creationSource)}}
      </div>
      <div class="font__title-0">
        {{item.phoneNumber}}
      </div>
      <div class="font__title-1 mt-2" [innerHTML]="urlify(item.messageText)">
      </div>
      <div *ngIf="checkForEScript(item.messageText)">
        <a class="image-link"
        (click)="openEScriptModal(item.messageText)">Queue eScript
      </a>
      </div>
      <div *ngIf="item.attachments && item.attachments.length">
        <a class="image-link" (click)="showAttachmentsModal(item.attachments)">View Attachments</a>
      </div>
    </app-message>
  </div>
</section>
<ng-container *ngIf="(showModal$ | async) as showModal">
  <div *ngIf="showModal">
    <app-sms-attachment-modal></app-sms-attachment-modal>
  </div>
</ng-container>
