import { Router } from '@angular/router';
import { State, Action, StateContext, Store } from '@ngxs/store';
import { catchError, finalize, tap } from 'rxjs/operators';
import { AlertService } from '../../../../modules/core/alert/alert.service';
import { ProfileState } from '../../../../modules/core/profile/state/profile.state';
import { TriggerType } from '../../../../shared/models/pharmacy/trigger.model';
import { EvaluationService } from '../services/evaluation.service';
import { DispatchEventNotification, EventNotificationError, GetEventNotification, GetEventNotificationOverload, RefreshPageIfRequired, ToggleEventConfirmationModal } from './event-confirmation.actions';
import { Injectable } from '@angular/core';

export class EventConfirmationStateModel {
  public showModal: boolean;
  public notification: any;
  public loading: boolean;
  public currentTriggerType: TriggerType;
}
@Injectable()
@State<EventConfirmationStateModel>({
  name: 'eventConfirmation',
  defaults: {
    showModal: false,
    notification: null,
    loading: false,
    currentTriggerType: null
  }
})
export class EventConfirmationState {
  constructor(
    private evaluationService: EvaluationService,
    private store: Store,
    private alertService: AlertService,
    private router: Router
  ) {
  }

  @Action(ToggleEventConfirmationModal)
  toggle(ctx: StateContext<EventConfirmationStateModel>, { show }: ToggleEventConfirmationModal) {
    ctx.patchState({
      showModal: show
    });
    if (!show) {
      ctx.dispatch(new RefreshPageIfRequired());
    }
  }

  @Action(GetEventNotification)
  getEventNotification(ctx: StateContext<EventConfirmationStateModel>, { triggerType }: GetEventNotification) {
    const customer = this.store.selectSnapshot(ProfileState.profile);

    ctx.patchState({ loading: true, currentTriggerType: triggerType });
    return this.evaluationService.getNotification(
      customer.customerProfile.tenantCustomerId,
      customer.customerProfile.orderMethod,
      customer.customerProfile.type,
      triggerType).pipe(
        tap(response => {
          if (response == null) {
            ctx.dispatch(new ToggleEventConfirmationModal(false));
          } else {
            ctx.patchState({ notification: response });
          }
        }),
        catchError(err => ctx.dispatch(new EventNotificationError(err))),
        finalize(() => ctx.patchState({ loading: false }))
      )
  }

  @Action(GetEventNotificationOverload)
  getEventNotificationOverload(ctx: StateContext<EventConfirmationStateModel>, { tenantCustomerId, orderMethod, patientType, triggerType }: GetEventNotificationOverload) {
    ctx.patchState({ loading: true, currentTriggerType: triggerType });
    return this.evaluationService.getNotification(
      tenantCustomerId,
      orderMethod,
      patientType,
      triggerType).pipe(
        tap(response => {
          if (response == null) {
            ctx.dispatch(new ToggleEventConfirmationModal(false));
          } else {
            ctx.patchState({ notification: response });
          }
        }),
        catchError(err => ctx.dispatch(new EventNotificationError(err))),
        finalize(() => ctx.patchState({ loading: false }))
      )
  }

  @Action(DispatchEventNotification)
  dispatchEventNotification(ctx: StateContext<EventConfirmationStateModel>, { }: DispatchEventNotification) {
    ctx.patchState({ loading: true });
    const notification = ctx.getState().notification;
    return this.evaluationService.dispatchConfirmedNotification(notification).pipe(
      tap(() => {
        this.success();
        ctx.dispatch(new RefreshPageIfRequired());
      }),
      catchError(err => ctx.dispatch(new EventNotificationError(err))),
      finalize(() => {
        ctx.patchState({ loading: false });
        ctx.dispatch(new ToggleEventConfirmationModal(false))
      })
    )
  }

  @Action(EventNotificationError)
  error(ctx: StateContext<EventConfirmationStateModel>, { error }: EventNotificationError) {
    this.alertService.warn(JSON.stringify(error));
  }

  @Action(RefreshPageIfRequired)
  refresh(ctx: StateContext<EventConfirmationStateModel>) {
    if (!this.router.url.includes('orders')) {
      window.location.reload();
    }
  }

  private async success() {
    this.alertService.success('Notification successfully queued! It will be send shortly.');
  }
}
