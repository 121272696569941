import { Injectable } from '@angular/core';
import { ProfileState } from '@base/modules/core/profile/state/profile.state';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { catchError, finalize, tap } from 'rxjs/operators';
import { ToggleSixCpaContentAction } from '../../../state/six-cpa.actions';
import {
  SetAIRHistoryStatement,
  ToggleAirLookupForm,
} from '../../air-statement/state/air-statement.actions';
import {
  AirHistoryRequestType,
  ImmunisationHistoryStatementResponseType,
} from '../../models/individual-history-statement.model';
import { AirLookupService } from '../services/air-lookup.service';
import {
  ClearAIRLookup,
  GetAIRHistoryStatement,
  GetAIRHistoryStatementError,
  SubmitAIRLookupByMedicare,
  SubmitAIRLookupWithoutMedicare,
  SubmitAIRLookupWithoutMedicareIRN,
  SubmitAIRPatient,
  SubmitAIRPatientFailed,
  TogglePatientConfirm,
} from './air-lookup-form.actions';
import {
  AirStateModel,
  IndividualDetailsResponseType,
} from './air-lookup-form.model';

@State<AirStateModel>({
  name: 'AIR',
  defaults: {
    saving: false,
    error: null,
    showFormSuccess: false,
    individualDetailsResponseType: null,
    slideMode: 'out',
  },
})
@Injectable()
export class AirLookupState {
  @Selector()
  static saving(state: AirStateModel): boolean {
    return state.saving;
  }
  @Selector()
  static error(state: AirStateModel): any {
    return state.error;
  }

  @Selector()
  static showAirLookupSuccess(state: AirStateModel): boolean {
    return state.showFormSuccess;
  }

  constructor(private airService: AirLookupService, private store: Store) {}

  @Action(SubmitAIRPatient)
  submitAIRPatient(
    ctx: StateContext<AirStateModel>,
    { patient }: SubmitAIRPatient
  ) {
    ctx.patchState({ saving: true, error: null });

    return this.airService.lookup(patient).pipe(
      tap((response) => {
        if (!response.errors) {
          ctx.patchState({ individualDetailsResponseType: response });
          var individual = response as IndividualDetailsResponseType;

          const historyRequest: AirHistoryRequestType = {
            individualIdentifier:
              individual.individualDetails.individualIdentifier,
            informationProvider: {
              providerNumber: patient.informationProvider.providerNumber,
            },
          };
          ctx.dispatch(new GetAIRHistoryStatement(historyRequest));
        } else {
          ctx.dispatch(new SubmitAIRPatientFailed(response));
        }
      }),
      catchError((error) => ctx.dispatch(new SubmitAIRPatientFailed(error)))
      // finalize(() => ctx.patchState({ saving: false }))
    );
  }

  // Lookup the patient using medicare number only
  @Action(SubmitAIRLookupByMedicare)
  submitAIRLookupByMedicare(
    ctx: StateContext<AirStateModel>,
    { request, tenantCustomerId }: SubmitAIRLookupByMedicare
  ) {
    ctx.patchState({ saving: true, error: null });

    return this.airService.lookupByMedicare(request, tenantCustomerId).pipe(
      tap((response) => {
        if (!response.errors) {
          ctx.patchState({ individualDetailsResponseType: response });
          var individual = response as IndividualDetailsResponseType;
          const historyRequest: AirHistoryRequestType = {
            individualIdentifier:
              individual.individualDetails.individualIdentifier,
            informationProvider: {
              providerNumber: request.informationProvider.providerNumber,
            },
          };
          ctx.dispatch(new GetAIRHistoryStatement(historyRequest));
        } else {
          ctx.dispatch(new SubmitAIRPatientFailed(response));
        }
      }),
      catchError((error) => ctx.dispatch(new SubmitAIRPatientFailed(error)))
      // finalize(() => ctx.patchState({ saving: false }))
    );
  }

  // Lookup the patient using patient details without medicare details
  @Action(SubmitAIRLookupWithoutMedicare)
  submitAIRLookupWithoutMedicare(
    ctx: StateContext<AirStateModel>,
    { request, tenantCustomerId }: SubmitAIRLookupWithoutMedicare
  ) {
    ctx.patchState({ saving: true, error: null });
    return this.airService
      .lookupWithoutMedicare(request, tenantCustomerId)
      .pipe(
        tap((response) => {
          if (!response.errors) {
            ctx.patchState({ individualDetailsResponseType: response });
            var individual = response as IndividualDetailsResponseType;

            const historyRequest: AirHistoryRequestType = {
              individualIdentifier:
                individual.individualDetails.individualIdentifier,
              informationProvider: {
                providerNumber: request.informationProvider.providerNumber,
              },
            };
            ctx.dispatch(new GetAIRHistoryStatement(historyRequest));
          } else {
            ctx.dispatch(new SubmitAIRPatientFailed(response));
          }
        }),
        catchError((error) => ctx.dispatch(new SubmitAIRPatientFailed(error)))
        // finalize(() => ctx.patchState({ saving: false }))
      );
  }

  // Lookup the patient using medicare number but with no IRN
  @Action(SubmitAIRLookupWithoutMedicareIRN)
  submitAIRLookupWithoutMedicareIRN(
    ctx: StateContext<AirStateModel>,
    { request, tenantCustomerId }: SubmitAIRLookupWithoutMedicareIRN
  ) {
    ctx.patchState({ saving: true, error: null });

    return this.airService
      .lookupWithoutMedicareIRN(request, tenantCustomerId)
      .pipe(
        tap((response) => {
          if (!response.errors) {
            ctx.patchState({ individualDetailsResponseType: response });
            var individual = response as IndividualDetailsResponseType;

            const historyRequest: AirHistoryRequestType = {
              individualIdentifier:
                individual.individualDetails.individualIdentifier,
              informationProvider: {
                providerNumber: request.informationProvider.providerNumber,
              },
            };
            ctx.dispatch(new GetAIRHistoryStatement(historyRequest));
          } else {
            ctx.dispatch(new SubmitAIRPatientFailed(response));
          }
        }),
        catchError((error) => ctx.dispatch(new SubmitAIRPatientFailed(error)))
        // finalize(() => ctx.patchState({ saving: false }))
      );
  }

  @Action(SubmitAIRPatientFailed)
  submitAIRPatientFailed(
    ctx: StateContext<AirStateModel>,
    { error }: SubmitAIRPatientFailed
  ) {
    ctx.patchState({ error: error, saving: false });
  }

  @Action(GetAIRHistoryStatement)
  getAIRHistoryStatement(
    ctx: StateContext<AirStateModel>,
    { request }: GetAIRHistoryStatement
  ) {
    ctx.patchState({ saving: true, error: null });
    const clientId = this.store.selectSnapshot(ProfileState.clientId);
    return this.airService.getStatement(clientId, request).pipe(
      tap((response) => {
        const statementResponse =
          response as ImmunisationHistoryStatementResponseType;
        if (!statementResponse.errors) {
          ctx.dispatch([
            new SetAIRHistoryStatement(
              statementResponse.immunisationHistoryStatement,
              statementResponse.documentId
            ),
            new ToggleSixCpaContentAction('out'),
            new ToggleAirLookupForm('in'),
          ]);
        } else {
          ctx.dispatch(
            new GetAIRHistoryStatementError(statementResponse.errors)
          );
        }
      }),
      catchError((error) => ctx.dispatch(new SubmitAIRPatientFailed(error))),
      finalize(() => ctx.patchState({ saving: false }))
    );
  }

  @Action(GetAIRHistoryStatementError)
  getAIRHistoryStatementError(
    ctx: StateContext<AirStateModel>,
    { error }: GetAIRHistoryStatementError
  ) {
    ctx.patchState({ error: error });
  }

  @Action(ClearAIRLookup)
  clearAIRLookup(ctx: StateContext<AirStateModel>) {
    ctx.patchState({
      saving: false,
      error: null,
      showFormSuccess: false,
      individualDetailsResponseType: null,
    });
  }

  @Action(TogglePatientConfirm)
  toggle(ctx: StateContext<AirStateModel>, action: TogglePatientConfirm) {
    ctx.patchState({ slideMode: action.slideMode });
  }
}
