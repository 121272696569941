<section *ngIf="!isLoaded">
  <app-loader></app-loader>
</section>
<section *ngIf="isLoaded">
  <div>
      <h3>Tenant List</h3>
      <div g-xs="text:right my3">
          <button (click)="openUpdateForm()" class="btn btn__green">Create Item</button>
      </div>
    </div>
    <div *ngIf="isUpdateFormVisible">
         <app-tenant-item
        (saveEvent)="updateList($event)"
        (cancelEvent)="onUpdateFormCancel()"
        [currentItem]="currentItem"
        [connectionTypeList] = "connectionTypeList"
        ></app-tenant-item>
    </div>
  <table class="table">
      <thead>
      <tr>
        <th scope="col">Name</th>
        <th scope="col">Connection</th>
        <th scope="col">Connection Type</th>
        <th scope="col">Permissions</th>
        <th scope="col">IsActive</th>
        <th></th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let item of tenantList">
        <td>{{item.name}}</td>
        <td>{{item.connection}}</td>
        <td>{{item.connectionType | appConnectionType: connectionTypeList }}</td>
        <td>
          <div class="form-check" *ngFor="let permission of item.permissions">
            <input class="form-check-input" type="checkbox" [checked]="permission.isAllowed"/>
            <label class="form-check-label">{{permission.name}}</label>
          </div></td>
        <td><div class="form-check">
          <input class="form-check-input" type="checkbox" [(ngModel)]="item.isActive" (change)="toggleActiveStatus(item)"/>
          <label class="form-check-label">Active</label>
        </div></td>
        <td>
          <div class="btn-group">
            <button (click)="openUpdateForm(item)" class="btn btn-primary">Edit</button>
            <button (click)="remove(item.id)" class="btn btn-danger">Remove</button>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
</section>
