import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HMRFormListComponent } from './hmr-form-list.component';
import { NgxsModule } from '@ngxs/store';
import { HMRFormState } from './state/hmr-form.state';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import { ReactiveFormsModule } from '@angular/forms';
import { RadioHighlightModule } from '../../../../../shared/components/radio-highlight/radio-highlight.module';
import { HMRService } from './services/hmr.service';
import { HMRPatientDetailsStateModule } from '../hmr-patient-details-form/hmr-patient-details-state.module';
import { HMRPatientDetailsFormModule } from '../hmr-patient-details-form/hmr-patient-details-form.module';
import { HMRPatientDetailsFormListItemModule } from '../hmr-patient-details-form-list-item/hmr-patient-details-form-list-item.module';
import { HMRPharmacyInformationState } from '../hmr-pharmacy-information/state/hmr-pharmacy-information.state';
import { HMRPharmacyInformationListItemModule } from '../hmr-pharmacy-information-list-item/hmr-pharmacy-information-list-item.module';
import { HMRGPDetailsReferralFormListItemModule } from '../hmr-gp-details-referral-form-list-item/hmr-gp-details-referral-form-list-item.module';
import { HMRGPDetailsReferralStateModule } from '../hmr-gp-details-referral-form/hmr-gp-details-referral-state.module';
import { HMRLocationListItemModule } from '../hmr-location-list-item/hmr-location-list-item.module';
import { HMRLocationState } from '../hmr-location/state/hmr-location.state';
import { HMRServiceProviderDetailsStateModule } from '../hmr-service-provider-details/hmr-service-provider-details-state.module';
import { HMRServiceProviderDetailsListItemModule } from '../hmr-service-provider-details-list-item/hmr-service-provider-details-list-item.module';
import { HMRProposedDetailsListItemModule } from '../hmr-proposed-details-list-item/hmr-proposed-details-list-item.module';
import { HMRProposedDetailsStateModule } from '../hmr-proposed-details/hmr-proposed-details-state.module';
import { HMRAccreditedPharmacistDetailsState } from '../hmr-accredited-pharmacist-details/state/hmr-accredited-pharmacist-details.state';
import { HMRAccreditedPharmacistDetailsListItemModule } from '../hmr-accredited-pharmacist-details-list-item/hmr-accredited-pharmacist-details-list-item.module';
import { HMRConsentAndEligibilityListItemModule } from '../hmr-consent-and-eligibility-list-item/hmr-consent-and-eligibility-list-item.module';
import { HMRConsentAndEligibilityState } from '../hmr-consent-and-eligibility/state/hmr-consent-and-eligibility.state';
import { LoaderModule } from '../../../../core/loader/loader.module';
import { HMRSaveFormState } from './state/hmr-form-save.state';

@NgModule({
  declarations: [HMRFormListComponent],
  imports: [
    CommonModule,
    NgxsFormPluginModule,
    ReactiveFormsModule,
    RadioHighlightModule,
    NgxsModule.forFeature([
      HMRFormState,
      HMRSaveFormState,
      HMRPharmacyInformationState,
      HMRLocationState,
      HMRAccreditedPharmacistDetailsState,
      HMRConsentAndEligibilityState
    ]),
    HMRPatientDetailsStateModule,
    HMRPatientDetailsFormModule,
    HMRPatientDetailsFormListItemModule,
    HMRPharmacyInformationListItemModule,
    HMRGPDetailsReferralFormListItemModule,
    HMRGPDetailsReferralStateModule,
    HMRLocationListItemModule,
    HMRServiceProviderDetailsStateModule,
    HMRServiceProviderDetailsListItemModule,
    HMRProposedDetailsListItemModule,
    HMRProposedDetailsStateModule,
    HMRAccreditedPharmacistDetailsListItemModule,
    HMRConsentAndEligibilityListItemModule,
    LoaderModule
  ],
  providers: [
    HMRService
  ],
  exports: [HMRFormListComponent]
})
export class HMRFormListModule { }
