<div class="modal">
    <div class="modal-body border__grey">
        <div>
            <p>Please allocate a Cycle before placing an Order for <span class="font__bold">{{customer.firstname}} {{customer.surname}}</span>.</p>
            <p>You will need to update the Patient Status to SOF type.</p>
            <div g-xs="mt2">
                <button class="button btn__yellow" g-xs="mr1" (click)="onClose()">Cancel</button>
                <button class="button btn__green" (click)="onEdit()">Edit</button>
            </div>
        </div>
    </div>
</div>
<div class="modal-background"></div>
