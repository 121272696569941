import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SingUpRoutingModule } from './sign-up-routing.module';
import { SignUpRouteComponent } from './components/sign-up-route/sign-up-route.component';
import { CreateAccountModule } from './create-account/create-account.module';
import { SignUpErrorComponent } from './components/sign-up-error/sign-up-error.component';
import { SignUpResolver } from './resolvers/sign-up.resolver';
import { NgxsModule } from '@ngxs/store';
import { SignUpState } from './state/sign-up.state';
import { SignUpService } from './services/sign-up.service';
import { LoaderModule } from '../core/loader/loader.module';
import { SignUpSuccessComponent } from './components/sign-up-success/sign-up-success.component';

@NgModule({
  declarations: [SignUpRouteComponent, SignUpErrorComponent, SignUpSuccessComponent],
  imports: [
    CommonModule,
    SingUpRoutingModule,
    CreateAccountModule,
    NgxsModule.forFeature([
      SignUpState
    ]),
    LoaderModule
  ],
  providers: [SignUpResolver, SignUpService]
})
export class SingUpRouteModule { }
