<section>
  <h3 class="pb-3">Export {{CPA_Version}}</h3>
  <form [formGroup]="sixcpaCsvExportForm">
    <div class="row">
      <div class="col">
        <label for="serviceType" class="input-label">Service Type</label>
        <select name="serviceType" formControlName="serviceType" class="form-control form-control-lg" id="serviceType">
          <option [ngValue]="serviceTypes.MedsCheck">Meds Check</option>
          <option [ngValue]="serviceTypes.DAA">DAA</option>
          <option [ngValue]="serviceTypes.IDAA">IDAA</option>
          <option [ngValue]="serviceTypes.ClinicalIntervention">Clinical Intervention</option>
          <option [ngValue]="serviceTypes.StagedSupply">Staged Supply</option>
          <option [ngValue]="serviceTypes.HMR">HMR</option>
        </select>
      </div>
      <div class="col">
        <label for="serviceSubType" class="input-label">Service Sub-Type</label>
        <select [ngSwitch]="sixcpaCsvExportForm.value.serviceType" name="serviceSubType"
          formControlName="serviceSubType" class="form-control form-control-lg" id="serviceSubType">
          <option *ngSwitchCase="serviceTypes.MedsCheck" [ngValue]="medsCheckTypes.Regular">Regular Claim</option>
          <option *ngSwitchCase="serviceTypes.MedsCheck" [ngValue]="medsCheckTypes.PatientRegistration">Patient
            Registration</option>
          <option *ngSwitchCase="serviceTypes.MedsCheck" [ngValue]="medsCheckTypes.Diabetes">Diabetes Claim</option>
          <option *ngSwitchCase="serviceTypes.MedsCheck" [ngValue]="medsCheckTypes.RegularAndDiabetes">Regular and Diabetes</option>
          <option *ngSwitchCase="serviceTypes.DAA" [ngValue]="daaTypes.PatientRegistration">Patient Registration
          </option>
          <option *ngSwitchCase="serviceTypes.IDAA" [ngValue]="daaTypes.PatientRegistration">Patient Registration
          </option>
          <option *ngSwitchCase="serviceTypes.DAA" [ngValue]="daaTypes.DAAClaim">Claim</option>
          <option *ngSwitchCase="serviceTypes.IDAA" [ngValue]="daaTypes.DAAClaim">Claim</option>
          <option *ngSwitchCase="serviceTypes.StagedSupply" [ngValue]="stagedSupplyTypes.PatientRegistration">Patient
            Registration</option>
          <option *ngSwitchCase="serviceTypes.StagedSupply" [ngValue]="stagedSupplyTypes.Claim">Claim</option>
          <option *ngSwitchCase="serviceTypes.HMR" [ngValue]="hmrTypes.NewClaim">Claim</option>
          <option *ngSwitchCase="serviceTypes.HMR" [ngValue]="hmrTypes.InterviewRegisteredPhamacist">Interview By
            Phamacist</option>
          <option *ngSwitchCase="serviceTypes.HMR" [ngValue]="hmrTypes.InterviewOutsidePatientHome">Interview At Home
          </option>
          <option *ngSwitchCase="serviceTypes.ClinicalIntervention" [ngValue]="'N/A'">N/A</option>
        </select>
      </div>
      <div class="row"
        *ngIf="sixcpaCsvExportForm.value.serviceType !== serviceTypes.ClinicalIntervention">
        <div class="col">
          <label for="fromDate" class="input-label">Query From</label>
          <input type="text" class="form-control form-control-lg" formControlName="fromDate" id="fromDate"
            value="sixcpaCsvExportForm.value.fromDate" [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY' }"
            placeholder="dd-mm-yyyy" bsDatepicker>
        </div>
        <div class="col">
          <label for="toDate" class="input-label">Query To</label>
          <input type="text" class="form-control form-control-lg" formControlName="toDate" id="toDate"
            value="sixcpaCsvExportForm.value.toDate" [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY' }"
            placeholder="dd-mm-yyyy" bsDatepicker>
        </div>
      </div>
      <div hidden class="col" *ngIf="sixcpaCsvExportForm.value.serviceType === serviceTypes.DAA || sixcpaCsvExportForm.value.serviceType === serviceTypes.IDAA">
        <label for="claimedCalendarMonth" class="input-label">Claimed Calendar Month</label>
        <select class="form-control form-control-lg" formControlName="claimedCalendarMonth">
          <option [value]="calendarMonths.January">January</option>
          <option [value]="calendarMonths.February">February</option>
          <option [value]="calendarMonths.March">March</option>
          <option [value]="calendarMonths.April">April</option>
          <option [value]="calendarMonths.May">May</option>
          <option [value]="calendarMonths.June">June</option>
          <option [value]="calendarMonths.July">July</option>
          <option [value]="calendarMonths.August">August</option>
          <option [value]="calendarMonths.September">September</option>
          <option [value]="calendarMonths.October">October</option>
          <option [value]="calendarMonths.November">November</option>
          <option [value]="calendarMonths.December">December</option>
        </select>
      </div>
      <div class="col" *ngIf="sixcpaCsvExportForm.value.serviceType === serviceTypes.DAA || sixcpaCsvExportForm.value.serviceType === serviceTypes.IDAA">
        <label for="daaGroup" class="input-label">Group</label>
        <select [ngSwitch]="sixcpaCsvExportForm.value.serviceType" name="daaGroup" formControlName="daaGroup"
          class="custom-select" multiple size="groupList.length" id="daaGroup">
          <option *ngFor="let group of groupList" [ngValue]="group.id">{{group.name}}</option>
        </select>
      </div>
    </div>

    <div *ngIf="sixcpaCsvExportForm.value.serviceType === serviceTypes.ClinicalIntervention">
      <div class="form-group pb-2 pt-3">
        <label class="input-label">Clinical intervention Claim Date Range</label>
        <div class="form-check form-check">
          <input class="form-check-input" type="radio" name="ciClaimingPeriod" id="periodOne"
            formControlName="ciClaimingPeriod" [value]="ciClaimingPeriods.January1stToMarch31st">
          <label class="form-check-label" for="periodOne">1 January - 31 March (Claiming Period 01-14 April)</label>
        </div>
        <div class="form-check form-check">
          <input class="form-check-input" type="radio" name="ciClaimingPeriod" id="periodTwo"
            formControlName="ciClaimingPeriod" [value]="ciClaimingPeriods.April1stToJune30th">
          <label class="form-check-label" for="periodTwo">1 April - 30 June (Claiming Period 01-14 July)</label>
        </div>
        <div class="form-check form-check">
          <input class="form-check-input" type="radio" name="ciClaimingPeriod" id="periodThree"
            formControlName="ciClaimingPeriod" [value]="ciClaimingPeriods.July1stToSeptember30th">
          <label class="form-check-label" for="periodThree">1 July - 30 September (Claiming Period 01-14
            October)</label>
        </div>
        <div class="form-check form-check">
          <input class="form-check-input" type="radio" name="ciClaimingPeriod" id="periodFour"
            formControlName="ciClaimingPeriod" [value]="ciClaimingPeriods.October1stToDecember31st">
          <label class="form-check-label" for="periodFour">1 October - 31 December (Claiming Period 01-14
            January)</label>
        </div>
      </div>
    </div>

    <div *ngIf="sixcpaCsvExportForm.value.serviceType === serviceTypes.DAA || sixcpaCsvExportForm.value.serviceType === serviceTypes.IDAA">
        <div class="form-check">
            <input class="form-check-input" type="checkbox" formControlName="includePatientsWithNoConcessionOnFile" id="includePatientsWithNoConcessionOnFile">
            <label for="includePatientsWithNoConcessionOnFile">Include patients with <strong>no</strong> Concession/DVA numbers on file.</label>
        </div>
      </div>

    <div class="d-flex  align-items-center">
      <div class="pt-3">
        <button (click)="exportToCsv()" class="w-100"
          *ngIf="sixcpaCsvExportForm.value.serviceType === serviceTypes.ClinicalIntervention; else exportButton">Get
          Total Interventions</button>
        <ng-template #exportButton>
          <button (click)="exportToCsv()">Export</button>
        </ng-template>
      </div>
      <div class="font__title-1 mt-3 ml-3" *ngIf="sixcpaCsvExportForm.value.serviceType === serviceTypes.DAA">
        This will export all DAA customers in Scrypt who are active at any time during the selected month
      </div>
    </div>

  </form>
  <ng-container *ngIf="(loading$ | async) as loading">
    <div *ngIf="loading">
      <app-loader loadingMessage="Exporting to CSV..."></app-loader>
    </div>
  </ng-container>
</section>
<ng-container *ngIf="(clinicalInterventionCount$ | async) as clinicalInterventionCount">
  <section class="pt-3" *ngIf="sixcpaCsvExportForm.value.serviceType === serviceTypes.ClinicalIntervention">
    <label for="" class="input-label btn btn-outline-success">Total Completed Clinical Interventions:
      <strong>{{clinicalInterventionCount}}</strong></label>
  </section>
</ng-container>
