import { Pharmacist } from '~/shared/models/pharmacy/pharmacist.model';

export enum PharmacistActions {
  GET = '[Pharmacist] Get Pharmacists',
  GET_SUCCESS = '[Pharmacist] Get Success',
  ERROR = '[Pharmacist] Error',
  ADD = '[Pharmacist] Add',
  UPDATE = '[Pharmacist] Update',
  DELETE = '[Pharmacist] Delete',
  SAVE = '[Pharmacist] Save',
  TOGGLE_FORM = '[Pharmacist] Toggle Form',
  UPDATE_PHARMACIST_FORM = '[Pharmacist] Update Form',
  UPDATE_PHARMACIST_LIST = '[Pharmacist] Update List',
  ADD_PHARMACIST_LIST = '[Pharmacist] Add To List',
  DELETE_PHARMACIST_LIST = '[Pharmacist] Delete From List'
}

export class GetPharmacists {
  static readonly type = PharmacistActions.GET;
  constructor() { }
}

export class GetPharmacistsSuccess {
  static readonly type = PharmacistActions.GET_SUCCESS;
  constructor(public pharmacists: Pharmacist[]) { }
}

export class GetPharmacistsError {
  static readonly type = PharmacistActions.ERROR;
  constructor(public error: any) { }
}

export class TogglePharmacistForm {
  static readonly type = PharmacistActions.TOGGLE_FORM;
  constructor(public showPharmacistForm: boolean) { }
}

export class UpdatePharmacistForm {
  static readonly type = PharmacistActions.UPDATE_PHARMACIST_FORM;
  constructor(public currentPharmacist: Pharmacist) { }
}

export class SavePharmacist {
  static readonly type = PharmacistActions.SAVE;
  constructor(public pharmacist: Pharmacist) { }
}

export class AddNewPharmacist {
  static readonly type = PharmacistActions.ADD;
  constructor(public pharmacist: Pharmacist) { }
}

export class UpdatePharmacist {
  static readonly type = PharmacistActions.UPDATE;
  constructor(public pharmacist: Pharmacist) { }
}

export class DeletePharmacist {
  static readonly type = PharmacistActions.DELETE;
  constructor(public pharmacistId: number) { }
}

export class UpdatePharmacistList {
  static readonly type = PharmacistActions.UPDATE_PHARMACIST_LIST;
  constructor(public pharmacist: Pharmacist) { }
}

export class AddToPharmacistList {
  static readonly type = PharmacistActions.ADD_PHARMACIST_LIST;
  constructor(public pharmacist: Pharmacist) { }
}

export class DeleteFromPharmacistList {
  static readonly type = PharmacistActions.DELETE_PHARMACIST_LIST;
  constructor(public pharmacistId: number) { }
}
