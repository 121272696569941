import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Client } from '../../../../shared/models/client.model';
import { Subscription } from 'rxjs/Subscription';
import { MailService } from '../../../../shared/services/mail.service';
import { MailNote } from '../../../../shared/models/mail.model';

@Component({
  selector: 'app-mail',
  templateUrl: './mail.component.html',
  styleUrls: ['./mail.component.scss']
})

export class MailComponent implements OnInit, OnDestroy {

  constructor(private mailService: MailService) { }

  @Input() client: Client;
  isLoaded = false;
  sub1: Subscription;
  mailNoteList: MailNote[] = [];

  ngOnInit() {
    this.sub1 = this.mailService.getMailNotesByClient(this.client.id)
      .subscribe((item: MailNote[]) => {
        this.mailNoteList = item;
        this.isLoaded = true;
      });
  }
  noteAdded(item: MailNote) {
    this.mailNoteList.push(item);
  }
  ngOnDestroy() {
    if (this.sub1) { this.sub1.unsubscribe(); }
  }
}

