import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Store, Actions } from '@ngxs/store';
import { Subscription } from 'rxjs';
import { SixCpaState } from '../../../state/six-cpa.state';
import { SSPatientDetailsFormBaseComponent } from '../ss-patient-details-form.base.component';
import { SSPatientDetailsFormBasicState } from '../state/ss-patient-details-form-basic.state';

@Component({
  selector: 'app-ss-patient-details-form-basic',
  templateUrl: './ss-patient-details-form-basic.component.html',
  styleUrls: ['./../ss-patient-details-form.component.scss']
})
export class SSPatientDetailsFormBasicComponent extends SSPatientDetailsFormBaseComponent implements OnInit, OnDestroy {
  private formSubscription: Subscription;
  private readonlySubscription: Subscription;
  constructor(formBuilder: FormBuilder, store: Store, actions: Actions) {
    super(formBuilder, store, actions);
  }

  ngOnInit() {
    this.formSubscription = this.store.select(SSPatientDetailsFormBasicState.form)
      .subscribe(() => this.onFormChange());
    this.readonlySubscription = this.store.select(SixCpaState.formsReadOnly)
      .subscribe(readonly => readonly ? this.patientDetailsForm.disable() : this.patientDetailsForm.enable());
  }

  ngOnDestroy() {
    this.formSubscription.unsubscribe();
    this.readonlySubscription.unsubscribe();
  }
}
