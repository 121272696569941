import { State, Action, StateContext, Selector, Store } from '@ngxs/store';
import { MedicineCategory, Medicine } from '../../../../../../shared/models/six-cpa/medicine.model';
import {
  ToggleMedListMedicationProfile,
  SetMedListMedicationProfileScripts,
  ToggleMedListMedicationProfileListItem,
  ToggleAllMedListMedicationProfileList,
  AddMedicationToMedListMedicationProfileList,
  SelectMedListMedicationProfileListItem,
  UpdateMedListMedicationProfileList,
  ResetMedListMedicationProfileIdsAction,
  SetMedsListMedicationProfileFormAction
} from './med-list-medication-profile.actions';
import { Injectable } from '@angular/core';
import { MedicineCategoryConstants } from '../../../../../../shared/constants/medicine-category.constant';
import { addUncategorisedIfMissing } from '../../../../../../shared/helpers/uncategorised-meds.helper';
import { getDoctorName } from '../../../../../../shared/helpers/doctor-name.helper';
import { ProfileState } from '../../../../../core/profile/state/profile.state';

export class MedsListMedicationProfileForm {
  public allergies: string;
  public primaryDoctor: string;
}

export class MedListMedicationProfileStateModel {
  public categorisedMedicines: MedicineCategory[];
  public showMode: string;
  public medsListForm: {
    model: Partial<MedsListMedicationProfileForm>
  };
}
@Injectable()
@State<MedListMedicationProfileStateModel>({
  name: 'medListMedicationProfile',
  defaults: {
    categorisedMedicines: [],
    showMode: 'out',
    medsListForm: {
      model: undefined
    }
  }
})
export class MedListMedicationProfileState {

  constructor(private store: Store) { }

  @Selector()
  static categorisedMedicines(state: MedListMedicationProfileStateModel) { return state.categorisedMedicines; }

  @Selector()
  static showMode(state: MedListMedicationProfileStateModel) { return state.showMode; }

  @Selector()
  static medsListForm(state: MedListMedicationProfileStateModel) { return state.medsListForm.model; }


  @Selector()
  static selectedMedicines(state: MedListMedicationProfileStateModel) {
    let res = [] as Medicine[];
    state.categorisedMedicines.forEach(category => {
      res = res.concat(category.medicines.filter(x => x.isSelected));
    });
    return res;
  }

  @Selector()
  static selectedMedicineCount(state: MedListMedicationProfileStateModel) {
    let res = [] as Medicine[];
    state.categorisedMedicines.forEach(category => {
      res = res.concat(category.medicines.filter(x => x.isSelected));
    });
    return res.length;
  }

  @Action(SetMedsListMedicationProfileFormAction)
  setForm(ctx: StateContext<MedListMedicationProfileStateModel>, action: SetMedsListMedicationProfileFormAction) {
    const doctor = this.store.selectSnapshot(ProfileState.profile)?.patient?.preferredDoctor;
    if (!action.form.primaryDoctor) {
      action.form.primaryDoctor = getDoctorName(doctor);
    }
    ctx.patchState({
      medsListForm: {
        model: action.form
      }
    })
  }

  @Action(ToggleMedListMedicationProfile)
  toggle(ctx: StateContext<MedListMedicationProfileStateModel>, action: ToggleMedListMedicationProfile) {
    ctx.patchState({ showMode: action.showMode });
  }

  @Action(SetMedListMedicationProfileScripts)
  setScripts(ctx: StateContext<MedListMedicationProfileStateModel>, action: SetMedListMedicationProfileScripts) {
    const categorisedMedicines = action.categorisedScripts.map(category => {
      const { id, name } = category.key;
      const medicineList = category.items.map(item => {
        return this.ConstructMedicine(item, name);
      });
      return {
        id,
        name,
        medicines: medicineList
      } as MedicineCategory;
    });
    ctx.patchState({ categorisedMedicines });
  }

  private ConstructMedicine(item: any, categoryName: string) {
    const { productDispensedId, description, directionsDesc, quantity, name, doctor, dispensedDate } = item;
    return {
      id: null,
      productDispensedId,
      brandName: description,
      genericName: name,
      directions: directionsDesc,
      isSelected: this.notDiscontinuedCategory(categoryName),
      quantity,
      prescriber: getDoctorName(doctor),
      dispensedDate: dispensedDate
    } as Medicine;
  }

  private notDiscontinuedCategory(categoryName: string): boolean {
    return (categoryName == MedicineCategoryConstants.DiscontinuedFriendly || categoryName == MedicineCategoryConstants.Discontinued) ? false : true;
  }

  @Action(ToggleMedListMedicationProfileListItem)
  toggleListItem(ctx: StateContext<MedListMedicationProfileStateModel>, action: ToggleMedListMedicationProfileListItem) {
    const state = ctx.getState();
    ctx.patchState({
      categorisedMedicines: state.categorisedMedicines.map(category => {
        return {
          ...category,
          medicines: category.medicines.map(item => item === action.item ? { ...item, isSelected: !item.isSelected } : item)
        };
      })
    });
  }

  @Action(ToggleAllMedListMedicationProfileList)
  toggleAll(ctx: StateContext<MedListMedicationProfileStateModel>, { isSelectAll, categoryId }: ToggleAllMedListMedicationProfileList) {
    const state = ctx.getState();
    ctx.patchState({
      categorisedMedicines: state.categorisedMedicines.map(category => {
        if ((categoryId && category.id === categoryId) || !categoryId) {
          return {
            ...category,
            medicines: category.medicines.map(item => {
              return { ...item, isSelected: isSelectAll } as Medicine;
            })
          };
        } else { return category; }
      })
    });
  }

  @Action(AddMedicationToMedListMedicationProfileList)
  addMedication(ctx: StateContext<MedListMedicationProfileStateModel>, action: AddMedicationToMedListMedicationProfileList) {
    const state = ctx.getState();
    const findDrug = state.categorisedMedicines.find(y => !!y.medicines.find(x => x.productDispensedId === action.item.productDispensedId));
    if (findDrug) {
      ctx.patchState({
        categorisedMedicines: state.categorisedMedicines.map(category => {
          return {
            ...category,
            medicines: category.medicines.map(item => item.productDispensedId === action.item.productDispensedId
              ? { ...action.item, isSelected: item.isSelected }
              : item)
          };
        })
      });
    } else {
      addUncategorisedIfMissing(state.categorisedMedicines);
      ctx.patchState({
        categorisedMedicines: state.categorisedMedicines.map(category => {
          if (category.id) {
            return {
              ...category,
            };
          } else {
            return {
              ...category,
              medicines: [...category.medicines, { ...action.item, isSelected: true }]
            };
          }
        })
      });
    }
  }

  @Action(SelectMedListMedicationProfileListItem)
  selectListItem(ctx: StateContext<MedListMedicationProfileStateModel>, action: SelectMedListMedicationProfileListItem) {
    const state = ctx.getState();
    ctx.patchState({
      categorisedMedicines: state.categorisedMedicines.map(category => {
        return {
          ...category,
          medicines: category.medicines.map(item => item.productDispensedId === action.productDispensedId ? { ...item, isSelected: true } : item)
        };
      })
    });
  }

  @Action(UpdateMedListMedicationProfileList)
  updateMedicines(ctx: StateContext<MedListMedicationProfileStateModel>, { medication }: UpdateMedListMedicationProfileList) {
    const state = ctx.getState();

    //clear all previous selections
    ctx.dispatch(new ToggleAllMedListMedicationProfileList(false, null));

    medication.forEach(med => {
      ctx.dispatch(new SelectMedListMedicationProfileListItem(med.productDispensedId));
    });


    ctx.patchState({
      categorisedMedicines: state.categorisedMedicines.map(category => {
        return {
          ...category,
          medicines: category.medicines.map(item => {
            const el = medication.find(x => x.productDispensedId === item.productDispensedId);
            return el ? el : item;
          })
        };
      })
    });
  }

  /* This keeps the default medications selected but will reset the two form fields - used when starting a new application or navigating profiles */
  @Action(ResetMedListMedicationProfileIdsAction)
  resetMedicationProfileIds(ctx: StateContext<MedListMedicationProfileStateModel>, action: ResetMedListMedicationProfileIdsAction) {
    const state = ctx.getState();
    ctx.patchState({
      categorisedMedicines: state.categorisedMedicines.map(category => {
        return {
          ...category,
          medicines: category.medicines ? category.medicines.map(med => { return { ...med, id: null } }) : [],
        }
      }),
      medsListForm: undefined
    });
  }
}


