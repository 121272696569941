import { PipeTransform, Pipe } from '@angular/core';
import { Store } from '@ngxs/store';
import { defaultDateFormat } from './tenant-date.config';
import { convertToTimezone } from '../logic/timezone.logic';
import { TimezoneState } from '../state/timezone.state';
import { Moment } from 'moment';

@Pipe({
  name: 'tenantTimezone'
})
export class TenantTimezonePipe implements PipeTransform {

  constructor(private store: Store) { }

  transform(value: string): Moment {
    const timezone = this.store.selectSnapshot(TimezoneState.selectedTimezone);
    return convertToTimezone(timezone, value);
  }
}
