import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { StorageService } from '../shared/core/storage/storage.service';
import { BaseApi } from '../shared/core/base-api/base-api';
import { Subject } from 'rxjs';
import { ClientViewModel } from '../modules/core/profile/client.model';

@Injectable()
export class ASLService extends BaseApi {
  API = '';
  // Observable emit source
  private emitSource = new Subject<any>();
  // Observable emit watcher
  emitted$ = this.emitSource.asObservable();

  constructor(
    @Inject('environment') environment: any,
    public httpClient: HttpClient,
    public router: Router,
    public storage: StorageService
  ) {
    super(httpClient, router, storage, null);
    this.API = environment.customerApiBase;
  }

  getToken<T>() {
    return this.get<T>(`${this.API}/api/asl/token`);
  }

  emit(): void {
    this.emitSource.next();
  }

  validatedIHI(customer: ClientViewModel): boolean {
    return !!customer.patient.ihiNumber;
  }

  validatedMedicareOrDva(customer: ClientViewModel): boolean {
    return !!(customer.patient.medicareNo || customer.patient.repatNo);
  }
}
