import { Category } from './category.model';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';

import { BaseApi } from '../../../shared/core/base-api/base-api';
import { environment } from '../../../../environments/environment';
import { Router } from '@angular/router';
import { StorageService } from '../../../shared/core/storage/storage.service';
import { HttpClient } from '@angular/common/http';
import { AlertService } from '~/modules/core/alert/alert.service';
import { CustomerAuth } from '~/modules/order/services/customer-auth';

const API = environment.baseApiUrl;

@Injectable()
export class CategoryRepository extends BaseApi {
    constructor(public http: HttpClient,
        public router: Router,
        public storageService: StorageService,
        public alertService: AlertService,
        private customerAuth: CustomerAuth) {
        super(http, router, storageService, alertService);
    }

    getCategories(): Observable<Category[]> {
        return this.get(`${API}/api/categories`);
    }

    getOrderCategories(): Observable<Category[]> {
        return this.get(
            this.customerAuth.urlForEncryptedTenantCustomer(
                `${API}/api/customer/orderrequest/categories`
                )
            )
    }

    getCategory(id: number): Observable<Category> {
        return this.get(`${API}/api/categories/${id}`);
    }

    updateCategory(item: Category): Observable<any> {
        return this.put(`${API}/api/categories/${item.id}`, item);
    }

    createCategory(item: Category): Observable<any> {
        return this.post(`${API}/api/categories`, item);
    }

    deleteCategory(id: number): Observable<any> {
        return this.post(`${API}/api/categories/${id}`);
    }
}
