import { State, Selector, Action, StateContext } from '@ngxs/store';
import { UpdateSSConsentEligibilityDetailedForm } from './ss-consent-eligibility-form-detailed.actions';
import { ISSConsentEligibilityFormStateModel } from './ss-consent-eligibility-form-state-model.interface';
import * as moment_ from 'moment';
const moment = moment_;
import { Injectable } from '@angular/core';

export class SSConsentEligibilityFormDetailedStateModel {
  consentEligibilityForm: {
    model: Partial<ISSConsentEligibilityFormStateModel>;
  };
  valid: boolean;
  totalItems: number;
  completedItems: number;
}
@Injectable()
@State<SSConsentEligibilityFormDetailedStateModel>({
  name: 'ssConsentEligibilityFormDetailed',
  defaults: {
    consentEligibilityForm: {
      model: undefined
    },
    valid: false,
    completedItems: 0,
    totalItems: 0
  }
})
export class SSConsentEligibilityFormDetailedState {
  @Selector()
  static form(state: SSConsentEligibilityFormDetailedStateModel) { return state.consentEligibilityForm.model; }

  @Action(UpdateSSConsentEligibilityDetailedForm)
  updateForm(ctx: StateContext<SSConsentEligibilityFormDetailedStateModel>, { form }: UpdateSSConsentEligibilityDetailedForm) {
    ctx.patchState({
      consentEligibilityForm: {
        model: {
          dateOfPatientRegistration: form.dateOfPatientRegistration && moment(form.dateOfPatientRegistration, 'YYYY-MM-DD').format('DD-MM-YYYY'),
          writtenConsentForProvisionOfData: form.writtenConsentForProvisionOfData,
          gpOrHospitalLastSixMonthsForMedicationProblems: form.gpOrHospitalLastSixMonthsForMedicationProblems
        }
      }
    });
  }
}
