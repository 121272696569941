import { CommTemplate } from './comm-template.model';

export interface Trigger {
  id?: number;
  tenantId: number;
  templateId: number;
  name: string;
  patientType: string | null;
  leadTimeInDays: number | null;
  triggerType: TriggerType;
  createdDate: Date | string | null;
  enabled: boolean;
  commTemplate?: CommTemplate;
}

export enum TriggerType {
  onboardedAsSOF,
  orderConfirmed,
  orderOutForDelivery,
  orderReadyForCollection,
  beforeNextRepeatDue,
  lastRepeatDue,
  medicationOverdue,
  medicationOverdueOnLastRepeat,
  scriptExpiring,
  scriptOwing,
  orderCycleSMS,
  whoopingCoughScreening,
}
