import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Auth } from '../../order/models/auth.model';
import { BaseApi } from '../../../shared/core/base-api/base-api';
import { StorageService } from '../../../shared/core/storage/storage.service';
import { AlertService } from '../alert/alert.service';

@Injectable()
export class AuthService extends BaseApi {
    constructor(public http: HttpClient, public router: Router, public storageService: StorageService, public alertService: AlertService) {
        super(http, router, storageService, alertService);
    }

    confirmIdentity(auth: Auth) {
        return this.post(`/api/customer/orderrequest/auth?customer=${encodeURIComponent(decodeURIComponent(auth.encryptedTenantCustomerId))}`, auth); 
    }

}
