import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { GetProfileAction, ProfileAlertVisibleAction } from '../../modules/core/profile/state/profile.actions';
import { ProfileState } from '../../modules/core/profile/state/profile.state';
@Injectable({
    providedIn: 'root'
})
export class OrdersTabGuard implements CanActivate {
    constructor(private store: Store, private router: Router) { }
    private clientId = null;
    canActivate(route: ActivatedRouteSnapshot) {
        const profile = this.store.selectSnapshot(ProfileState.profile);
        this.clientId = route.parent.params.clientId;
        if (!profile) {
            return this.store.dispatch(new GetProfileAction(this.clientId)).map(state => {
                return this.checkCustomerType(state.profile.profile);
            });
        } else {
           return this.checkCustomerType(profile);
        }

    }
    checkCustomerType(profile: any): boolean {
        if (!profile || !profile.customerProfile) {
            this.router.navigate(['/profile', this.clientId, 'categories']);
            this.store.dispatch(new ProfileAlertVisibleAction(true));
            return false;
        }
        return true;
    }
}
