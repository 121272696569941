import { Injectable } from '@angular/core';
import { State, Action, StateContext, Selector } from '@ngxs/store';
import { ZScript } from '../models/z-script';
import { ZClientDispenseInfo } from '../models/z-client-dispense-info.model';
import { ZApiClientDispenseInfoAction, ZApiClientDispenseInfoErrorAction, ZApiClientDispenseInfoSuccessAction, ZApiScriptQueryAction, ZApiScriptQueryErrorAction, ZApiScriptQuerySuccessAction } from './zapi.actions';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { ZApiService } from '../services/z-api.service';
import { catchError, finalize, tap } from 'rxjs/operators';


const ALERT_POSITION = 'topRight';

export class ZapiStateModel {
  loading?: boolean;
  clients?: ZClientDispenseInfo[];
  scripts?: ZScript[];
  error?: any;
}

const defaults = {
  loading: false,
  clients: [],
  scripts: [],
  error: null
};

@State<ZapiStateModel>({
  name: 'ZApi',
  defaults
})
@Injectable()
export class ZapiState {
  constructor(private nzNotificationService: NzNotificationService, private zApiService: ZApiService) {
  }

  @Selector()
  static state(state: ZapiStateModel): ZapiStateModel { return state; }

  @Selector()
  static loading(state: ZapiStateModel): boolean { return state.loading }

  // Script Query
  @Action(ZApiScriptQueryAction)
  scriptQueryAction({ setState, dispatch }: StateContext<ZapiStateModel>, { scriptNos }: ZApiScriptQueryAction) {
    setState({ loading: true });
    return this.zApiService.scriptQuery(scriptNos).pipe(
      tap(scripts => dispatch(new ZApiScriptQuerySuccessAction(scripts))),
      catchError(error => dispatch(new ZApiScriptQueryErrorAction(error))),
      finalize(() => setState({ loading: false }))
    );
  }

  @Action(ZApiScriptQuerySuccessAction)
  scriptQuerySuccessAction({ setState }: StateContext<ZapiStateModel>, { scripts }: ZApiScriptQuerySuccessAction) {
    setState({ loading: false, scripts });
  }

  @Action(ZApiScriptQueryErrorAction)
  scriptQueryErrorAction({ setState }: StateContext<ZapiStateModel>, { error }: ZApiScriptQueryErrorAction) {
    setState({ loading: false, error });
    this.nzNotificationService.error('Error', error, { nzPlacement: ALERT_POSITION })
  }

  // Client Dispense Info
  @Action(ZApiClientDispenseInfoAction)
  clientDispenseInfoAction( ctx : StateContext<ZapiStateModel>, { searchKeyword }: ZApiClientDispenseInfoAction) {

    ctx.patchState({ loading: true });
    return this.zApiService.clientDispenseInfo(searchKeyword).pipe(
      tap(clients => ctx.dispatch(new ZApiClientDispenseInfoSuccessAction(clients))),
      catchError(error => ctx.dispatch(new ZApiClientDispenseInfoSuccessAction(error))),
      finalize(() => ctx.patchState({ loading: false }))
    );
  }

  @Action(ZApiClientDispenseInfoSuccessAction)
  clientDispenseInfoSuccessAction(ctx: StateContext<ZapiStateModel>, { clients }: ZApiClientDispenseInfoSuccessAction) {
    ctx.patchState({clients: clients });
  }

  @Action(ZApiClientDispenseInfoErrorAction)
  clientDispenseInfoErrorAction({ setState }: StateContext<ZapiStateModel>, { error }: ZApiClientDispenseInfoErrorAction) {
    setState({ loading: false, error });
    this.nzNotificationService.error('Error', error, { nzPlacement: ALERT_POSITION })
  }
}
