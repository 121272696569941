import { Component, ChangeDetectionStrategy } from '@angular/core';
import { Store, Select } from '@ngxs/store';
import { ScriptActionsState } from '../state/script-actions.state';
import { Observable } from 'rxjs';
import { StockAdjustmentState, StockAdjustmentStateModel, StockAdjustmentType } from './state/stock-adjustment.state';
import { StockAdjustmentRequestAction } from './state/stock-adjustment.actions';

@Component({
  selector: 'app-stock-adjustment',
  templateUrl: './stock-adjustment.component.html',
  changeDetection: ChangeDetectionStrategy.Default
})
export class StockAdjustmentComponent {
  @Select(ScriptActionsState.script) script$: Observable<any>;
  @Select(StockAdjustmentState) stockAdjustment$: Observable<StockAdjustmentStateModel>;

  stockAdjustmentType = StockAdjustmentType;
  days = 0;
  type = StockAdjustmentType.ABSOLUTE;

  constructor(private store: Store) {}

  commitChange(script) {
    const isAbsolute = this.type === StockAdjustmentType.ABSOLUTE;
    this.store.dispatch(new StockAdjustmentRequestAction(script, this.days, isAbsolute));
  }
}
