import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MultipleSelectComponent } from './multiple-select.component';
import { NgxsModule } from '@ngxs/store';
import { MultipleDrugsState } from './state/multiple-drugs.state';
import { LoaderModule } from '../../../core/loader/loader.module';
import { DispenseOverideConfirmationModalModule } from './dispense-overide-confirmation-modal/dispense-overide-confirmation-modal.module';

@NgModule({
  declarations: [
    MultipleSelectComponent
  ],
  imports: [
    CommonModule,
    LoaderModule,
    NgxsModule.forFeature([MultipleDrugsState]),
    DispenseOverideConfirmationModalModule
  ],
  exports: [
    MultipleSelectComponent
  ]
})
export class MultipleSelectModule { }
