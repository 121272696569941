import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import { ReactiveFormsModule } from '@angular/forms';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { SSConsentEligibilityFormComponent, SSConsentEligibilityFormHostDirective } from './consent-eligibility-form/ss-consent-eligibility-form.component';
import { SSConsentEligibilityFormBasicComponent } from './basic/ss-consent-eligibility-form-basic.component';
import { SSConsentEligibilityFormDetailedComponent } from './detailed/ss-consent-eligibility-form-detailed.component';
import { RadioHighlightModule } from '../../../../../shared/components/radio-highlight/radio-highlight.module';

@NgModule({
  declarations: [
    SSConsentEligibilityFormComponent,
    SSConsentEligibilityFormBasicComponent,
    SSConsentEligibilityFormDetailedComponent,
    SSConsentEligibilityFormHostDirective
  ],
  imports: [
    CommonModule,
    NgxsFormPluginModule,
    ReactiveFormsModule,
    BsDatepickerModule.forRoot(),
    RadioHighlightModule
  ],
  exports: [
    SSConsentEligibilityFormComponent
  ],
  entryComponents: [
    SSConsentEligibilityFormBasicComponent,
    SSConsentEligibilityFormDetailedComponent
  ]
})
export class SSConsentEligibilityFormModule { }
