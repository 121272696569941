import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { AlertService } from '../../../../modules/core/alert/alert.service';
import { BaseApi } from '../../../../shared/core/base-api/base-api';
import { StorageService } from '../../../../shared/core/storage/storage.service';
import { CommsQueueItem } from '../models/comms-queue-item.model';

@Injectable()
export class CommsQueueService extends BaseApi {
  API = '';

  constructor(
    @Inject('environment') env: any,
    public http: HttpClient,
    public router: Router,
    public storageService: StorageService,
    public alertService: AlertService
  ) {
    super(http, router, storageService, alertService);
    this.API = env.baseApiUrl;
  }

  getCommsQueueItems(startDate: Date): Observable<CommsQueueItem[]> {
    return this.get(
      `${this.API}/api/projection/pharmacy?fromDate=${moment(startDate).format(
        'MM/DD/YYYY hh:mm:ss A'
      )}&projectionDays=7`
    );
  }

  getPatientProjectedComms(clientId: string, startDate: Date): Observable<CommsQueueItem[]> {
    return this.get(
      `${this.API}/api/projection/${clientId}/patient?fromDate=${moment(startDate).format(
        'MM/DD/YYYY hh:mm:ss A'
      )}&projectionDays=7`
    );
  }
}
