<section class="auth">
  <div class="header  border__grey">
    <div class="grid header__block flex" g-xs="py3">
      <div class="grid__unit" g-xs="cols:9">
        <img src="./assets/img/scrypt-vector.svg" class="logo-size" />
      </div>
    </div>
  </div>
  <div class="auth__main" g-xs="pt6 pb5">
    <router-outlet></router-outlet>
  </div>
  <app-footer></app-footer>
</section>
