import { State, Action, StateContext, Selector } from '@ngxs/store';
import {
  GenerateMedicationListForm,
  ResetMedicationListFormListAction,
  ResetMedicationListFormStateToDefaults,
  SaveMedicationListFormSuccessAction,
  SaveMedicationListFormFailedAction,
  SubmitNewMedicationList,
  SubmitExisitingMedicationList,
  GetMedicationListForm,
  GetMedicationListFormError,
  GetMedicationListFormSuccess,
  UpdateMedicationListForm
} from './medication-list-form.actions';
import { CloseResetFormModal, ToggleSixCpaAction, ToggleSixCpaContentAction, SetSelectedFormType, SetSixCpaFormSummary } from '../../../state/six-cpa.actions';
import { SetupMedicationsMedicationListConfirm, ToggleMedicationListConfirm } from '../../medication-list-confirm/state/medication-list-confirm.actions';
import { AlertService } from '../../../../../core/alert/alert.service';
import { SetSixCpaErrors } from '../../../six-cpa-user-communications/state/six-cpa-user-communications.actions';
import { MedicationListService } from '../services/medication-list.service';
import { switchMap, catchError, finalize } from 'rxjs/operators';
import { UpdateMedListMedicationProfileList, ResetMedListMedicationProfileIdsAction, SetMedsListMedicationProfileFormAction } from '../../med-list-medication-profile/state/med-list-medication-profile.actions';
import { Injectable } from '@angular/core';
import { closeConfirmationActions } from '../../../state/six-cpa-toggles-list';
import { GetSixCpaFormsFromService } from '../../../../profile-services/state/profile-services.actions';
import { SixCpaFormTypes } from '../../../six-cpa-form-types.enum';
import { SixCpaResponse } from '../../../../profile-services/state/profile-services.state';
import { } from '../../med-list-medication-profile/state/med-list-medication-profile.actions';

export class MedicationListFormListStateModel {
  medicationListId: number;
  sixCpaFormId: number;
  saving: boolean;
}
@Injectable()
@State<MedicationListFormListStateModel>({
  name: 'medicationListFormList',
  defaults: {
    medicationListId: null,
    sixCpaFormId: null,
    saving: false
  }
})
export class MedicationListFormListState {
  constructor(
    private medicationListService: MedicationListService,
    private alertService: AlertService
  ) { }
  @Selector()
  static saving(state: MedicationListFormListStateModel) { return state.saving; }

  @Selector()
  static sixCpaFormId(state: MedicationListFormListStateModel) { return state.sixCpaFormId; }

  @Action(SaveMedicationListFormSuccessAction)
  saveMedicationListSuccess(ctx: StateContext<MedicationListFormListStateModel>, { formData }: SaveMedicationListFormSuccessAction) {
    const { id, sixCpaFormId, hasSubmitted
    } = formData;
    ctx.patchState({
      medicationListId: id,
      sixCpaFormId
    });
    this.alertService.success('Medication List form successfully saved to Scrypt.');
    ctx.dispatch([
      new SetSixCpaFormSummary({
        id: sixCpaFormId,
        formType: SixCpaFormTypes.MedicationList,
        hasSubmitted: hasSubmitted
      } as SixCpaResponse)
    ]);
    ctx.dispatch(new GetSixCpaFormsFromService(null));
  }

  @Action(SaveMedicationListFormFailedAction)
  saveMedsCheckFailed(ctx: StateContext<MedicationListFormListStateModel>, { errors }: SaveMedicationListFormFailedAction) {
    if (errors.error) {
      errors.error.forEach(err => {
        this.alertService.error(`Please check the following error: ${err.value}`);
      });
    } else {
      this.alertService.error(errors.message);
      ctx.dispatch(new SetSixCpaErrors([errors.message]));
    }
  }

  @Action(SubmitNewMedicationList)
  submitNewMedicationList(ctx: StateContext<MedicationListFormListStateModel>, { clientId, formData }: SubmitNewMedicationList) {
    ctx.patchState({ saving: true });
    return this.medicationListService.postSubmit(clientId, formData).pipe(
      switchMap(response => ctx.dispatch([new SaveMedicationListFormSuccessAction(response)])),
      catchError(error => ctx.dispatch(new SaveMedicationListFormFailedAction(error))),
      finalize(() => {
        ctx.patchState({ saving: false });
        ctx.dispatch(new GenerateMedicationListForm(formData));
      }));
  }

  @Action(SubmitExisitingMedicationList)
  submitExisitingMedicationList(ctx: StateContext<MedicationListFormListStateModel>, { clientId, formData }: SubmitExisitingMedicationList) {
    ctx.patchState({ saving: true });
    return this.medicationListService.putSubmit(clientId, formData).pipe(
      switchMap(response => ctx.dispatch([new SaveMedicationListFormSuccessAction(response)])),
      catchError(error => ctx.dispatch(new SaveMedicationListFormFailedAction(error))),
      finalize(() => {
        ctx.patchState({ saving: false });
        ctx.dispatch(new GenerateMedicationListForm(formData));
      }));
  }
  @Action(GetMedicationListForm)
  getMedicationListForm(ctx: StateContext<MedicationListFormListStateModel>, { clientId, sixCpaFormId }: GetMedicationListForm) {
    return this.medicationListService.getMedicationListForm(clientId, sixCpaFormId)
      .pipe(switchMap(response => ctx.dispatch([new GetMedicationListFormSuccess(response), new ToggleSixCpaAction('in')])),
        catchError(err => ctx.dispatch(new GetMedicationListFormError(err))));
  }

  @Action(GetMedicationListFormSuccess)
  getMedicationListSuccess(ctx: StateContext<MedicationListFormListStateModel>, { formData }: GetMedicationListFormSuccess) {
    const {
      id,
      sixCpaFormId
    } = formData;
    ctx.patchState({
      medicationListId: id,
      sixCpaFormId
    });
    ctx.dispatch(closeConfirmationActions);
    ctx.dispatch(new UpdateMedicationListForm(formData));
    if (formData.hasSubmitted) {
      ctx.dispatch(new GenerateMedicationListForm(formData));
    }
  }

  @Action(UpdateMedicationListForm)
  updateMedicationListForms(ctx: StateContext<MedicationListFormListStateModel>, { medicationListForm }: UpdateMedicationListForm) {
    ctx.dispatch([
      new UpdateMedListMedicationProfileList(medicationListForm.sixCpaMedications),
      new SetSelectedFormType(SixCpaFormTypes.MedicationList),
      new SetMedsListMedicationProfileFormAction({
        allergies: medicationListForm.allergies,
        primaryDoctor: medicationListForm.primaryDoctor
      })
    ]);
  }

  @Action(GetMedicationListFormError)
  getMedicationListFormError(ctx: StateContext<MedicationListFormListStateModel>, { errors }: GetMedicationListFormError) {
    this.alertService.error(errors);
  }

  @Action(ResetMedicationListFormListAction)
  resetMedicationList(ctx: StateContext<MedicationListFormListStateModel>, { onlyClearFormIds }: ResetMedicationListFormListAction) {
    ctx.dispatch([
      new ResetMedicationListFormStateToDefaults(onlyClearFormIds),
      new CloseResetFormModal()
    ]);
  }

  @Action(ResetMedicationListFormStateToDefaults)
  resetFormStateToDefaults(ctx: StateContext<MedicationListFormListStateModel>, { onlyClearFormIds }: ResetMedicationListFormStateToDefaults) {
    ctx.patchState({
      sixCpaFormId: null,
      medicationListId: null,
      saving: false
    });
    ctx.dispatch(new ResetMedListMedicationProfileIdsAction());
  }

  @Action(GenerateMedicationListForm)
  generate(ctx: StateContext<MedicationListFormListStateModel>, { formData }: GenerateMedicationListForm) {
    ctx.dispatch([
      new SetupMedicationsMedicationListConfirm(formData.sixCpaMedications),
      new ToggleSixCpaContentAction('out'),
      new ToggleMedicationListConfirm('in')
    ]);
  }
}
