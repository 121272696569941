import { State, Action, StateContext } from '@ngxs/store';
import { ScriptService } from '../../../../../shared/services/script.service';
import { OpenBulkStockAdjustmentAction,
  CloseBulkStockAdjustmentAction,
  BulkAdjustmentRequestAction,
  BulkAdjustmentResponseAction,
  BulkAdjustmentErrorAction } from './bulk-stock-adjustment.actions';
import { catchError, mergeMap } from 'rxjs/operators';
import { ClearSelectedDrugsAction } from '../../multiple-select/state/multiple-drugs.actions';
import { RefreshCategoriesAction } from '../../state/categories.actions';
import { Injectable } from '@angular/core';

export class BulkStockAdjustmentStateModel {
  show: boolean;
  loading: boolean;
  error: any;
}
@Injectable()
@State<BulkStockAdjustmentStateModel>({
  name: 'bulkStockAdjustment',
  defaults: {
    show: false,
    loading: false,
    error: null
  }
})
export class BulkStockAdjustmentState {
  constructor(private scriptService: ScriptService) {}

  @Action(OpenBulkStockAdjustmentAction)
  open(ctx: StateContext<BulkStockAdjustmentStateModel>) {
    ctx.patchState({ show: true });
  }

  @Action(BulkAdjustmentRequestAction)
  adjust(ctx: StateContext<BulkStockAdjustmentStateModel>, action: BulkAdjustmentRequestAction) {
    ctx.patchState({ loading: true });
    return this.scriptService.addBulkAdjustment(action.request).pipe(
      mergeMap(() => ctx.dispatch(new BulkAdjustmentResponseAction(action.clientId))),
      catchError(error => ctx.dispatch(new BulkAdjustmentErrorAction(error)))
    );
  }

  @Action(BulkAdjustmentResponseAction)
  handleResponse(ctx: StateContext<BulkStockAdjustmentStateModel>, action: BulkAdjustmentResponseAction) {
    ctx.dispatch([
      new ClearSelectedDrugsAction(),
      new RefreshCategoriesAction(action.clientId)
    ]);
  }

  @Action(BulkAdjustmentErrorAction)
  handleError(ctx: StateContext<BulkStockAdjustmentStateModel>, action: BulkAdjustmentErrorAction) {
    ctx.patchState({ error: action.error });
  }

  @Action([BulkAdjustmentResponseAction, BulkAdjustmentErrorAction])
  stopLoading(ctx: StateContext<BulkStockAdjustmentStateModel>) {
    ctx.patchState({ loading: false });
  }

  @Action(CloseBulkStockAdjustmentAction)
  close(ctx: StateContext<BulkStockAdjustmentStateModel>) {
    ctx.patchState({ show: false });
  }
}
