<div class="modal send-document-modal">
  <div class="modal-body border__grey">
    <div>
      <h3 class="p-2">Send document by</h3>
      <form [formGroup]="sendDocForm" ngxsForm="confirmMedsCheck.medsListEmailForm">
        	<select class="form-control mt-1" formControlName="commsType" id="commsType">
            <option [ngValue]="commsType.Email">Email</option>
            <option [ngValue]="commsType.Sms">Sms</option>
            <!-- <option [ngValue]="commsType.ScryptMessenger">Scrypt Messenger</option> -->
          </select>
          <input *ngIf="sendDocForm.controls.commsType.value === commsType.Email" class="form-control mt-1" placeholder="Email Address" type="text" formControlName="email"/>
          <input *ngIf="sendDocForm.controls.commsType.value === commsType.Sms" class="form-control mt-1" type="text" placeholder="Mobile number" formControlName="mobile"/>
      </form>
      <div g-xs="mt2">
        <button class="button btn__yellow" g-xs="mr1" (click)="close()">Cancel</button>
        <button class="button btn__green" (click)="submit()">Send</button>
      </div>
    </div>
  </div>
</div>
<div class="modal-background"></div>
