import { Store } from '@ngxs/store';
import { Timezone } from '../timezone.model';
import { of, Observable } from 'rxjs';
import { map, take, switchMap } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { FetchAllTimezones, FetchTimezone } from '../state/timezone.actions';
import { TimezoneState } from '../state/timezone.state';

export function resolveTimezones(store: Store) {
    return store.select(TimezoneState.allTimezones)
        .pipe(
            switchMap(dynamicResolver<Timezone[]>(timezonesAreValid, () => fetchAllTimezones(store))),
            take(1)
        );
}

export function resolveSelectedTimezone(store: Store): Observable<Timezone> {
  return combineLatest([store.select(TimezoneState.selectedTimezone).pipe(
          switchMap(dynamicResolver<string>(isSelectedTimezoneValid, () => fetchSelectedTimezone(store)))),
          store.select(TimezoneState.allTimezones)]).pipe(
        map(([selected, timezones]) => [timezones, selected]),
        map(getTimezoneFromArray),
        take(1));
}

export function dynamicResolver<T>(isValidValues: (value: T) => boolean, fetcher: () => Observable<T>): (value: T) => Observable<T> {
    return (value: T) => isValidValues(value) ? of(value) : fetcher();
}

export function timezonesAreValid(timezones: Timezone[]) {
    return !!(timezones && timezones.length > 0);
}

export function fetchAllTimezones(store: Store): Observable<Timezone[]> {
    return store.dispatch(new FetchAllTimezones())
    .pipe(switchMap(() => store.select(TimezoneState.allTimezones)), take(1));
}

export function fetchSelectedTimezone(store: Store): Observable<string> {
    return store.dispatch(new FetchTimezone())
        .pipe(
            switchMap(() => store.select(TimezoneState.selectedTimezone)),
            take(1)
        );
}

export function isSelectedTimezoneValid(selectedTimezone: string): boolean {
    return !!(selectedTimezone && selectedTimezone.length);
}

export function withTimezones<T>(timezones: Timezone[]): (T) => [Timezone[], string] {
    return (selectedTimezone) => [timezones, selectedTimezone];
}

export function getTimezoneFromArray([timezones, timezoneId]: [Timezone[], string]) {
    return timezones.find(tz => tz.id === timezoneId);
}
