import { Component, Directive, ViewContainerRef, ViewChild, ComponentFactoryResolver, Input, Type, OnChanges } from '@angular/core';
import { StagedSupplyResolvedForm } from '../../../staged-supply-resolved-form';
import { HasViewContainerRef } from '../../../has-view-container-ref.interface';
import { SSPatientDetailsFormBasicComponent } from '../basic/ss-patient-details-form-basic.component';
import { SSPatientDetailsFormDetailedComponent } from '../detailed/ss-patient-details-form-detailed.component';

@Directive({
  selector: '[appSSPatientDetailsFormHost]'
})
export class SSPatientDetailsFormHostDirective implements HasViewContainerRef {
  constructor(public viewContainerRef: ViewContainerRef) { }
}

@Component({
  selector: 'app-ss-patient-details-form',
  templateUrl: './ss-patient-details-form.component.html'
})
export class SSPatientDetailsFormComponent extends StagedSupplyResolvedForm implements OnChanges {
  @Input() isDetailed: boolean;
  @ViewChild(SSPatientDetailsFormHostDirective, { static: true }) formHost: SSPatientDetailsFormHostDirective;

  constructor(
    componentFactoryResolver: ComponentFactoryResolver
  ) {
    super(componentFactoryResolver);
  }

  ngOnChanges() {
    this.resolveFormComponent(this.isDetailed);
  }

  protected getFormComponent(isDetailed: boolean): Type<any> {
    return isDetailed
      ? SSPatientDetailsFormDetailedComponent
      : SSPatientDetailsFormBasicComponent;
  }
}


