export enum PushNotificationActionTypes {
  INIT = '[PushNotification] Init',
  RECEIVE = '[PushNotification] Receive',
}

export class InitPushNotificationsAction {
  static readonly type = PushNotificationActionTypes.INIT;
}

export class ReceivePushNotificationAction {
  static readonly type = PushNotificationActionTypes.RECEIVE;
  constructor(public message: any) {}
}
