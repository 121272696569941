<div g-xs="p3 my3" class="bg__grey">
  <form #f="ngForm" (ngSubmit)="onSubmit(f)">
      <div class="form-group">
          <label class="font__bold font__title-1" for="email">Email</label>
          <input id="email"  class="form-control" #email="ngModel" name="email" required
          type="email"
          email
          [ngModel]="currentItem.email"
          [disabled]="mode==='update'"/>
          <span class="form-help-text" *ngIf="email.invalid && (email.touched || email.dirty)">
            <span *ngIf="email.errors.email">Wrong email format</span>
            <span *ngIf="email.errors.required">Required field</span>
        </span>
      </div>
      <div class="form-group">
          <label class="font__bold font__title-1" for="connection">Password</label>
          <input id="password"  class="form-control" #password="ngModel" name="password" required
          pattern="^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,}$"
          minlength="8"
          ngModel/>
          <span class="form-help-text" *ngIf="password.invalid && (password.touched || password.dirty)">
            <span *ngIf="password.errors.pattern">Need to include a lower-case letter, an upper-case letter, a number, and a non-alphanumeric character.</span>
            <span *ngIf="password.errors.minlength">At least 8 characters</span>
            <span *ngIf="password.errors.required">Required field</span>
        </span>
      </div>
      <div class="btn-group">
        <button type="submit" class="btn btn-primary" [class.disabled]="f.invalid" [disabled]="f.invalid">Save</button>
        <button class="btn btn-danger" (click)="cancel()">Cancel</button>
      </div>
      <div g-xs="mt3">
        <app-notification [notification]="notification"></app-notification>
      </div>

    </form>
</div>

