<ng-container *ngIf="(script$ | async) as drug">
  <ng-container *ngIf="(categoryOptions$ | async) as categoryOptions">
    <ng-container *ngIf="(manageMeta$ | async) as manageMeta">
      <div>
        <h2>Manage {{drug.name}}</h2>
        <hr />
        <div>
          <h3>Script Meta</h3>
          <div>
            <div *ngIf="categoryOptions.length" class="form-group" g-xs="mt2">
              <label class="font__bold">Category</label>
              <select class="form-control width__200 input__primary" (ngModelChange)="onChangeCategoryId($event, drug)"
                [(ngModel)]="drug.cMeta.categoryId">
                <option *ngFor="let opt of categoryOptions" value="{{opt.value}}">{{opt.text}}</option>
              </select>
            </div>
            <div class="doh-alert"
              *ngIf="isDiscontinuedCategory(drug.cMeta.categoryId) || isDiscontinuedCategory(oldCategoryId)">
              <span *ngIf="drug.cMeta.currentStockStartingPoint?.doh != null; else unknownDohRecord"
                class="alert alert-warning">Last recorded days on hand:
                <strong>{{drug.cMeta.currentStockStartingPoint.doh}} days</strong></span>
              <ng-template #unknownDohRecord>
                <span class="alert alert-warning">Unknown last days on hand.</span>
              </ng-template>
            </div>
            <div *ngIf="!isDiscontinuedCategory(drug.cMeta.categoryId) && isDiscontinuedCategory(oldCategoryId)"
              class="form-group" g-xs="mt2">
              <label class="font__bold">Days-on-Hand</label>
              <input type="text" class="form-control input__primary"
                [ngModel]="drug.cMeta.currentStockStartingPoint?.doh || 0" (ngModelChange)="changeDOH($event)" />
            </div>
            <div class="form-group" g-xs="mt2">
              <label class="font__bold">Target Total Days-on-Hand</label>
              <input type="text" class="form-control input__primary" [(ngModel)]="drug.cMeta.dohTarget" />
            </div>
            <div class="form-group" g-xs="mt2">
              <label class="font__bold">Dispense Interval</label>
              <p><i>In how many days should this medication be dispensed?</i></p>
              <input type="text" class="form-control input__primary" g-xs="mt1"
                [(ngModel)]="drug.cMeta.dispenseBuffer" />
            </div>
            <div class="form-group" g-xs="mt2">
              <div class="checkbox">
                <label for="requestable" class="font__size-1">
                  <input type="checkbox" name="requestable" id="requestable" [(ngModel)]="drug.cMeta.requestable" />
                  Allow Script Requests
                </label>
              </div>
            </div>
            <div class="form-group" g-xs="mt2">
              <div class="checkbox">
                <label for="flagged" class="font__size-1">
                  <input type="checkbox" name="flagged" id="flagged" [(ngModel)]="drug.cMeta.isFlagged" /> Inactive for
                  Reporting
                </label>
              </div>
            </div>
            <div class="mt--xs pull-right">
              <button (click)="updateMeta(drug)" class="button btn__yellow"
                [disabled]="manageMeta.loadingMetaData">Update ScriptMeta</button>
            </div>
            <div class="clearfix"></div>
          </div>
        </div>
        <hr />
        <ng-container>
          <div g-xs="mt2">
            <h3>Scrypt Reminders</h3>
            <div *ngIf="!patientAutoCommsEnabled || !pharmacyAutoCommsEnabled" class="alert alert-warning mt-2">
              <span *ngIf="!pharmacyAutoCommsEnabled">
                Pharmacy Scrypt Reminders are currently turned OFF - please review in <a class="hyperlink pointer"
                  routerLink="/config/communication">Communication Settings</a>
              </span>
              <span *ngIf="pharmacyAutoCommsEnabled && !patientAutoCommsEnabled">
                Patient Scrypt Reminders are currently turned OFF - please review in Profile Settings
              </span>
            </div>
            <div>
              <app-loader *ngIf="manageMeta.loadingNotifications" loadingMessage="Updating..."></app-loader>
              <form [formGroup]="notificationsForm" class="mt-3">
                <div class="form-group"
                  [ngClass]="notificationsForm.value.notificationsEnabled ? 'toggle-on': 'toggle-off'" g-xs="mt2">
                  <label class="font__bold ml-1 mr-3">
                    <i *ngIf="notificationsForm.value.notificationsEnabled" class="fas fa-check mr-2"></i>
                    <span *ngIf="!notificationsForm.value.notificationsEnabled">
                      <i class="fas fa-times mr-2"></i>
                      Not
                    </span>
                    Enabled
                  </label>
                  <ui-switch class="float-right" size="small" [checked]="notificationsForm.value.notificationsEnabled"
                    (valueChange)="notificationsToggled($event)">
                  </ui-switch>
                </div>
                <div *ngIf="notificationsForm.value.notificationsEnabled">
                  <table>
                    <tr class="border">
                      <div class="form-group " g-xs="mt2">
                        <label class="ml-1 mr-3">Repeat Reminders</label>
                        <ui-switch (change)="updateRepeatReminders()" class="float-right mr-1" size="small"
                          [checked]="notificationsForm.value.lastRepeatDue" formControlName="lastRepeatDue">
                        </ui-switch>
                      </div>
                    </tr>
                    <tr hidden class="border">
                      <div class="form-group" g-xs="mt2">
                        <label class="ml-1 mr-3">Before Next Repeat Due</label>
                        <ui-switch class="float-right mr-1" size="small"
                          [checked]="notificationsForm.value.beforeNextRepeatDue" formControlName="beforeNextRepeatDue">
                        </ui-switch>
                      </div>
                    </tr>
                    <tr class="border">
                      <div class="form-group" g-xs="mt2">
                        <label class="ml-1 mr-3">Medication Overdue Reminders</label>
                        <ui-switch (change)="updateMedicationReminders()" class="float-right mr-1" size="small"
                          [checked]="notificationsForm.value.medicationOverdue" formControlName="medicationOverdue">
                        </ui-switch>
                      </div>
                    </tr>
                    <tr hidden class="border">
                      <div class="form-group" g-xs="mt2">
                        <label class="ml-1 mr-3">Medication Overdue &amp; Last Repeat</label>
                        <ui-switch class="float-right mr-1" size="small"
                          [checked]="notificationsForm.value.medicationOverdueOnLastRepeat"
                          formControlName="medicationOverdueOnLastRepeat">
                        </ui-switch>
                      </div>
                    </tr>
                    <tr class="border">
                      <div class="form-group" g-xs="mt2">
                        <label class="ml-1 mr-3">Script Owing or Expiring</label>
                        <ui-switch (change)="updateScriptReminders()" class="float-right mr-1" size="small"
                          [checked]="notificationsForm.value.scriptOwing" formControlName="scriptOwing">
                        </ui-switch>
                      </div>
                    </tr>
                    <tr hidden class="border">
                      <div class="form-group" g-xs="mt2">
                        <label class="ml-1 mr-3">Script Expiring</label>
                        <ui-switch class="float-right mr-1" size="small"
                          [checked]="notificationsForm.value.scriptExpiring" formControlName="scriptExpiring">
                        </ui-switch>
                      </div>
                    </tr>
                  </table>
                </div>
                <div class="clearfix"></div>
              </form>
            </div>
          </div>
        </ng-container>
        <hr />
        <div class="text-center">
          <i class="fas fa-sync fa-spin font__size-4"
            *ngIf="manageMeta.loadingStartDate || manageMeta.loadingMetaData"></i>
        </div>
      </div>

      <ng-container *ngIf="manageMeta.showDisableAutoCommsModal">
        <div class="modal confirm-modal">
          <div class="modal-body border__grey">
            Do you want to disable scrypt reminders?
            <div class="flex action__order__cta" g-xs="mt2">
              <button class="btn__yellow radius__primary" g-xs="mr1" (click)="disableAutoComms()">Disable</button>
              <button class="btn__dark radius__primary" g-xs="mr1" (click)="cancelAutoComms()">Cancel</button>
            </div>
          </div>
        </div>
        <div class="modal-background"></div>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-container>
