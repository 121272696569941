import { Component } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { PatientDetailsFormState, PatientDetailsFormStateModel } from '../patient-details-form/state/patient-details-form.state';
import { MedsCheckFormListState, MedsCheckType } from '../meds-check/state/meds-check-form-list.state';

@Component({
  selector: 'app-patient-form-list-item',
  templateUrl: './patient-form-list-item.component.html'
})
export class PatientFormListItemComponent  {
  @Select(MedsCheckFormListState.medsCheckType) medsCheckType$: Observable<MedsCheckType>;
  @Select(PatientDetailsFormState) formState$: Observable<PatientDetailsFormStateModel>;
  medsCheckTypes = MedsCheckType;
}
