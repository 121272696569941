import { State, Action, StateContext, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { SaveOrUpdateDAAAction } from './daa-administration-aids-save.actions';
import { map, tap, withLatestFrom } from 'rxjs/operators';
import { SaveExisitingDAA, SaveNewDAA } from './dose-administration-aids.actions';
import { DAAFormType, DoseAdministrationAidsState } from './dose-administration-aids.state';
import { DAAFormsService } from './daa-forms.service';
import { Observable } from 'rxjs/Observable';
import { ProfileState } from '@base/modules/core/profile/state/profile.state';

export class DoseAdministrationAidsSaveStateModel {
}


@Injectable()
@State<DoseAdministrationAidsSaveStateModel>({
  name: 'doseAdministrationAidsSave',
  defaults: {
  }
})

export class DoseAdministrationAidsSaveState {
  constructor(private store: Store) { }

  @Action(SaveOrUpdateDAAAction)
  saveOrUpdateDAAByType(ctx: StateContext<DoseAdministrationAidsSaveStateModel>, action: SaveOrUpdateDAAAction) {
    const formType = this.store.selectSnapshot(DoseAdministrationAidsState.daaFormType);
    return this.saveOrUpdateByFormType(formType).pipe(tap(action => ctx.dispatch(action)));
  }

  private saveOrUpdateByFormType(formType: DAAFormType): Observable<any> {
    return this.store.selectOnce(this.getFormSelectorForType(formType)).pipe(
      withLatestFrom(this.store.select(ProfileState.clientId)),
      map(([formData, clientId]) =>
      formData && formData.sixCpaFormId !== null
        ? new SaveExisitingDAA(clientId, formData)
        : new SaveNewDAA(clientId, formData))
    );
  }

  private getFormSelectorForType(formType: DAAFormType) {
    switch(formType) {
        case DAAFormType.PatientRegistration: return DAAFormsService.getDAAPatientRegistrationRequest;
        case DAAFormType.DAAClaim: return DAAFormsService.getDAAClaimRequest;
        default: throw Error('unsupported form type')
    }
  }
}
