import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { BaseApiNoAlerts } from '../../../../../../shared/core/base-api/base-api-no-alerts';
import { StorageService } from '../../../../../../shared/core/storage/storage.service';
import { AlertService } from '../../../../../core/alert/alert.service';
import {
  SendStatementRequest,
  SendStatementSmsRequest,
} from '../../models/send-statement-request.model';

@Injectable({
  providedIn: 'root',
})
export class AIRStatementService extends BaseApiNoAlerts {
  API = '';

  constructor(
    @Inject('environment') env: any,
    public httpClient: HttpClient,
    public router: Router,
    public storageService: StorageService,
    public alertService: AlertService
  ) {
    super(httpClient, router, storageService, alertService);
    this.API = env.baseApiUrl;
  }

  sendStatement(request: SendStatementRequest): Observable<any> {
    return this.post(
      `${this.API}/api/IndividualHistory/statement/send`,
      request
    );
  }

  sendStatementSms(
    clientId: string,
    request: SendStatementSmsRequest
  ): Observable<any> {
    return this.post(
      `${this.API}/api/IndividualHistory/statement/sendSms/${clientId}`,
      request
    );
  }
}
