import { Component, Input, ViewChild, ComponentFactoryResolver, Type, Directive, ViewContainerRef, OnChanges } from '@angular/core';
import { ConsentEligibilityFormDetailedComponent } from '../detailed/consent-eligibility-form-detailed.component';
import { ConsentEligibilityFormBasicComponent } from '../basic/consent-eligibility-form-basic.component';
import { HasViewContainerRef } from '../../has-view-container-ref.interface';
import { MedsCheckResolvedForm } from '../../meds-check-resolved-form';

@Directive({
  selector: '[appConsentEligibilityFormHost]'
})
export class ConsentEligibilityFormHostDirective implements HasViewContainerRef {
  constructor(public viewContainerRef: ViewContainerRef) { }
}

@Component({
  selector: 'app-consent-eligibility-form',
  templateUrl: './consent-eligibility-form.component.html'
})
export class ConsentEligibilityFormComponent extends MedsCheckResolvedForm implements OnChanges {
  @Input() isDetailed: boolean;
  @ViewChild(ConsentEligibilityFormHostDirective, {static: true}) formHost: ConsentEligibilityFormHostDirective;

  constructor(
    componentFactoryResolver: ComponentFactoryResolver
  ) { 
    super(componentFactoryResolver);
  }

  ngOnChanges() {
    this.resolveFormComponent(this.isDetailed);
  }

  protected getFormComponent(isDetailed: boolean): Type<any> {
    return isDetailed
      ? ConsentEligibilityFormDetailedComponent
      : ConsentEligibilityFormBasicComponent;
  }
}
