import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SickLeaveCertificateTypeModule } from '../sick-leave-certificate-type/sick-leave-certificate-type.module';
import { SixCpaFormListItemChecklistModule } from '../../six-cpa-form-list-item-checklist/six-cpa-form-list-item-checklist.module';
import { SickLeaveCertificateTypeListItemComponent } from './sick-leave-certificate-type-list-item.component';

@NgModule({
  declarations: [SickLeaveCertificateTypeListItemComponent],
  imports: [
    CommonModule,
    SickLeaveCertificateTypeModule,
    SixCpaFormListItemChecklistModule
  ],
  exports: [SickLeaveCertificateTypeListItemComponent]
})
export class SickLeaveCertificateTypeListItemModule { }
