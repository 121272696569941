import { Component, OnInit, OnDestroy, InjectionToken } from '@angular/core';
import { Subscription } from 'rxjs';
import { FormBuilder, Validators, FormControl } from '@angular/forms';
import { Store, Actions } from '@ngxs/store';
import { SSConsentEligibilityFormBasicState } from '../state/ss-consent-eligibility-form-basic.state';
import { SSConsentEligibilityFormBaseComponent } from '../ss-consent-eligibility-form-base.component';
import * as moment_ from 'moment';
const moment = moment_;
import { WindowService } from '../../../../../../shared/services/window.service';
//import { environment } from '../../../../../../../environments/environment';
import { SixCpaState } from '../../../state/six-cpa.state';
export const CONSENT_FORM_DEFAULT_DATE: InjectionToken<Date> = new InjectionToken('CONSENT_FORM_DEFAULT_DATE');

@Component({
  selector: 'app-ss-consent-eligibility-form-basic',
  templateUrl: './ss-consent-eligibility-form-basic.component.html',
  styleUrls: ['./../ss-consent-eligibility-form.component.scss'],
  providers: [
    { provide: CONSENT_FORM_DEFAULT_DATE, useValue: moment(new Date()).add(6, 'M').toDate() }
  ]
})
export class SSConsentEligibilityFormBasicComponent extends SSConsentEligibilityFormBaseComponent implements OnInit, OnDestroy {
  private formSubscription: Subscription;
  private readonlySubscription: Subscription;
  defaultDate = moment(new Date()).add(6, 'M').toDate();
  constructor(
    formBuilder: FormBuilder,
    store: Store,
    actions: Actions,
    private windowService: WindowService
    ) {
    super(formBuilder, store, actions);
    this.consentEligibilityForm.addControl('writtenConsentForServiceProvision', new FormControl(false, Validators.required));
    this.consentEligibilityForm.addControl('governmentIssuedConcessionCard', new FormControl(false, Validators.required));
    this.consentEligibilityForm.addControl('pbsCodeOfTheMedicationSupplied', new FormControl(null, Validators.required));
    this.consentEligibilityForm.addControl('dateStagedSupplyServiceCommenced', new FormControl(this.defaultDate, Validators.required));
  }

  ngOnInit() {
    this.formSubscription = this.store.select(SSConsentEligibilityFormBasicState.form)
      .subscribe(() => this.onFormChange());
    this.readonlySubscription = this.store.select(SixCpaState.formsReadOnly)
      .subscribe(readonly => readonly ? this.consentEligibilityForm.disable() : this.consentEligibilityForm.enable());
  }

  ngOnDestroy() {
    this.formSubscription.unsubscribe();
    this.readonlySubscription.unsubscribe();
  }

  openStagedSupplyConsentForm() {
    // this.windowService.open(environment.stagedSupplyConsentPdf);
    this.windowService.open('');
  }
}
