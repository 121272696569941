import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxsModule } from '@ngxs/store';
import { AppComponent } from './app.component';
import { AuthModule } from './auth/auth.module';
import { AuthService } from './shared/services/auth.service';
import { AuthGuard } from './shared/services/auth-guard';
import { CookieService } from 'ngx-cookie-service';
import { CrmService } from './shared/services/crm.service';
import { AlertModule } from './modules/core/alert/alert.module';
import { TimezoneModule } from './modules/core/timezone/timezone.module';
import { environment } from '../environments/environment';
import { StorageModule } from './shared/core/storage/storage.module';
import { BaseApiModule } from './shared/core/base-api/base-api.module';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import { NgxsDispatchPluginModule } from '@ngxs-labs/dispatch-decorator';
import { WindowService } from './shared/services/window.service';
import { CustomerOrderRouteModule } from './modules/customer-order-route/customer-order-route.module';
import { PushNotificationsState } from './modules/core/push-notifications/push-notifications.state';
import { AngularFireModule } from '@angular/fire';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { PushNotificationsResolver } from './modules/core/push-notifications/push-notifications.resolver';
import { NursingHomeModule } from './shared/nursing-homes/nursing-homes.module';
import {
  AngularFireAnalyticsModule,
  ScreenTrackingService,
} from '@angular/fire/analytics';
import { SingUpRouteModule } from './modules/sing-up/sing-up-route.module';
import { VaccinationRouteModule } from './modules/vaccination/vaccination-route.module';
import { RouterService } from './shared/services/router.service';
import { OptOutModule } from './modules/opt-out/opt-out.module';
import { DocumentRouteModule } from './modules/document-route/document-route.module';
import { QuillModule } from 'ngx-quill';
import { LoaderModule } from './modules/core/loader/loader.module';
import { registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';
import { en_US, NZ_I18N } from 'ng-zorro-antd/i18n';
import { StatisticsService } from './shared/services/statistics.service';
import { StatisticsState } from './shared/state/statistics/statistics.state';
registerLocaleData(en);

@NgModule({
  declarations: [AppComponent],
  imports: [
    AlertModule,
    StorageModule,
    BaseApiModule,
    NursingHomeModule,
    BrowserModule,
    HttpClientModule,
    FormsModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    CustomerOrderRouteModule,
    AuthModule,
    TimezoneModule,
    SingUpRouteModule,
    VaccinationRouteModule,
    NgxsModule.forRoot([PushNotificationsState, StatisticsState]),
    NgxsFormPluginModule.forRoot(),
    NgxsReduxDevtoolsPluginModule.forRoot({
      disabled: environment.production,
    }),
    NgxsLoggerPluginModule.forRoot({
      disabled: true,
    }),
    NgxsDispatchPluginModule.forRoot(),
    AngularFireModule.initializeApp(environment.firestore),
    AngularFirestoreModule,
    AngularFireMessagingModule,
    AngularFireAnalyticsModule,
    OptOutModule,
    DocumentRouteModule,
    QuillModule.forRoot(),
    LoaderModule
  ],
  providers: [
    CookieService,
    AuthService,
    RouterService,
    AuthGuard,
    CrmService,
    WindowService,
    PushNotificationsResolver,
    ScreenTrackingService,
    StatisticsService,
    { provide: NZ_I18N, useValue: en_US },
    { provide: 'environment', useValue: environment }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
