import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HMRLocationListItemComponent } from './hmr-location-list-item.component';
import { SixCpaFormListItemChecklistModule } from '../../six-cpa-form-list-item-checklist/six-cpa-form-list-item-checklist.module';
import { HMRLocationModule } from '../hmr-location/hmr-location.module';

@NgModule({
  declarations: [HMRLocationListItemComponent],
  imports: [
    CommonModule,
    SixCpaFormListItemChecklistModule,
    HMRLocationModule,

  ],
  exports: [HMRLocationListItemComponent]

})
export class HMRLocationListItemModule { }
