import { Component, OnInit, Input, OnDestroy, SimpleChanges, OnChanges, Output, EventEmitter } from '@angular/core';
import { hide } from '../../../../shared/core/animations';
import { Observable, timer, Subscription } from 'rxjs';

@Component({
  selector: 'app-report-response',
  templateUrl: './report-response.component.html',
  styleUrls: ['./report-response.component.scss'],
  animations: [
    hide
  ]
})
export class ReportResponseComponent implements OnInit, OnChanges, OnDestroy {

  constructor() { }
  @Input() showMode: string;
  @Output() hideEvent = new EventEmitter<any>();

  private timer: Observable<any>;
  private subscription: Subscription;

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.setTimer();
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
  private setTimer() {
    if (this.showMode === 'in') {
      this.timer = timer(4500);
      this.subscription = this.timer.subscribe(() => {
          this.showMode = 'out';
          this.hideEvent.emit();
      });
    }

  }

}
