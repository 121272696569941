<ng-container *ngIf="({total: monthlySent$ | async}) as sent">
  <div class="status-border" g-xs="ml5">
    <i class="far fa-chart-pie icon-background"></i>
    <span class="status-text small">{{sent.total}} messages sent this month</span>
    <div class="progress-bar-status">
      <nz-progress [nzPercent]="(sent.total / targetTotal) * 100" [nzShowInfo]="false" [nzStrokeColor]="'#50DFA6'">
      </nz-progress>
    </div>
  </div>
</ng-container>
