import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { NgxsModule } from '@ngxs/store';
import { RouterModule } from '@angular/router';
import { TimezoneModule } from '~/modules/core/timezone/timezone.module';
import { ScryptSearchResultsTableComponent } from './scrypt-search-results-table.component';
import { ScryptSearchState } from '../state/scrypt-search.state';
import { AdvancedSearchState } from '../state/advanced-search.state';
import { CdkTableModule } from '@angular/cdk/table';
import { PaginationModule } from 'ngx-bootstrap';
import { AdvancedSearchExportOptionsModule } from '../advanced-search-export-options/advanced-search-export-options.module';
import { LoaderModule } from '~/modules/core/loader/loader.module';

@NgModule({
  declarations: [ScryptSearchResultsTableComponent],
  exports: [ScryptSearchResultsTableComponent],
  providers: [],
  imports: [
    FormsModule,
    RouterModule,
    CommonModule,
    ReactiveFormsModule,
    TimezoneModule,
    NgxsModule.forFeature([
      AdvancedSearchState,
      ScryptSearchState]),
      CdkTableModule,
    PaginationModule.forRoot(),
    AdvancedSearchExportOptionsModule,
    LoaderModule
  ]
})
export class ScryptSearchResultsTableModule {
  constructor() { }
}
