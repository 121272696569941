import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { tap, catchError, finalize } from 'rxjs/operators';
import { GetAllInvoicesFromAMonthAgo } from "./invoices-page.actions";
import { InvoicesPageService } from '../services/invoices-page.service';
import { InvoiceStateModel } from "../models/InvoiceStateModel.model";
import { InvoiceStatus } from "../models/invoiceStatus.model";
import { Invoice } from "../models/invoice.model";

@State<InvoiceStateModel>({
    name:'invoice',
    defaults: {
        invoices: [],
        isLoading: false
    }
}) 

@Injectable()
export class InvoicesPageState {
    constructor(
       private invoicesPageService: InvoicesPageService,
    ) {}

    @Selector()
    static getState(state: InvoiceStateModel) {
      return state;
    }

    @Action(GetAllInvoicesFromAMonthAgo)
    GetInvoices(ctx: StateContext<InvoiceStateModel>) {
        ctx.patchState({
            isLoading: true,
            invoices: []
        });
        return this.invoicesPageService.getAllInvoicesFromMonthAgo()
        .pipe(
            tap((response: Invoice[]) => {
                let prioritisedInvoices = this.invoicesOrder(response)

                ctx.patchState({
                    invoices: prioritisedInvoices
                })
            }),
            catchError(error => ctx.dispatch({ error })),
            finalize(() => {
                ctx.patchState({ isLoading: false });
            })
        );
    }

    private invoicesOrder(allInvoices: Invoice[]) {
        let openInvoices = allInvoices.filter(x => x.status === InvoiceStatus.Open);
        let remainingInvoices = allInvoices.filter(x => x.status !== InvoiceStatus.Open);

        let invoicesSortedByDate = remainingInvoices.sort(function(a, b) {
            return new Date(a.created).getTime() - new Date(b.created).getTime();
        });

        return [
            ...openInvoices, 
            ...invoicesSortedByDate
        ];
    }
}
