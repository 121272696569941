import { Component, OnDestroy, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { ProfileState } from '../../../../modules/core/profile/state/profile.state';
import { Observable, Subscription } from 'rxjs';
import { ClientViewModel } from '../../../../modules/core/profile/client.model';
import { CloseSummaryModal, RequestASLIcolConsent, RequestASLInfaConsent, RequestASLRemoveConsent, ToggleRemoveModal, UpdateASLPatient } from '../state/asl.actions';
import { ASLState } from '../state/asl.state';
import {
  AslPatient,
  AslRegisterPayload,
  AslRelatedPerson,
  CarerType,
  postCodePattern,
  SLConsent,
  SLConsentState
} from '../state/asl.models';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators
} from '@angular/forms';

@Component({
  selector: 'app-asl-summary-form',
  templateUrl: './asl-summary-form.component.html',
  styleUrls: ['./asl-summary-form.component.scss']
})
export class AslSummaryFormComponent implements OnInit, OnDestroy {
  @Select(ProfileState.profile)
  profile$: Observable<ClientViewModel>;

  @Select(ASLState.aslDetails)
  aslDetails$: Observable<AslPatient>;

  @Select(ASLState.isSubmitting)
  isSubmitting$: Observable<boolean>;

  @Select(ASLState.isSubmittingConsentRequest)
  isSubmittingConsentRequest$: Observable<boolean>;

  @Select(ASLState.showRemoveModal)
  showRemoveModal$: Observable<boolean>;

  slConsentStates = SLConsentState;

  patientForm: FormGroup;

  updateListener: Subscription;
  aslDetailListener: Subscription;

  isEditing = false;
  carerForms: FormArray;
  agentForms: FormArray;
  channelForInviteSelect: string;
  isEditingNewCarer = false;
  isEditingNewAgent = false;

  constructor(
    private store: Store,
    private fb: FormBuilder
  ) { }

  ngOnInit(): void {
    this.resetForm();
    this.aslDetailListener = this.store.select(
      ASLState.aslDetails
    ).subscribe(() => {
      this.resetForm();
    });
  }

  openRemoveModal() {
    this.store.dispatch(new ToggleRemoveModal(true));
  }

  closeRemoveModal(){
    this.store.dispatch(new ToggleRemoveModal(false));
  }

  closeModal(): void {
    this.store.dispatch(new CloseSummaryModal());
  }

  containsCarer(relatedPersons: AslRelatedPerson[]): boolean {
    return relatedPersons?.some(relatedPerson => !relatedPerson.isAgent);
  }

  containsAgent(relatedPersons: AslRelatedPerson[]): boolean {
    return relatedPersons?.some(relatedPerson => relatedPerson.isAgent);
  }

  getCarers(relatedPersons: AslRelatedPerson[]): AslRelatedPerson[] {
    return relatedPersons?.filter(relatedPerson => !relatedPerson.isAgent);
  }

  getAgents(relatedPersons: AslRelatedPerson[]): AslRelatedPerson[] {
    return relatedPersons?.filter(relatedPerson => relatedPerson.isAgent);
  }

  sendInfaConsent(aslDetails: AslPatient): void {
    this.store.dispatch(new RequestASLInfaConsent(aslDetails.ihi, aslDetails.firstName, aslDetails.surname));
  }

  sendIcolConsent(aslDetails: AslPatient): void {
    this.store.dispatch(new RequestASLIcolConsent(aslDetails.ihi, aslDetails.firstName, aslDetails.surname));
  }

  removeConsent(aslDetails: AslPatient): void{
    this.store.dispatch(new RequestASLRemoveConsent(aslDetails.status.id, aslDetails.ihi, aslDetails.firstName, aslDetails.surname));
    this.store.dispatch(new ToggleRemoveModal(false));
  }

  handleEditClick(): void {
    this.isEditing = true;
  }

  nullOrLengthValidator(length: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      return control.value.length !== length && control.value ? { length: { value: control.value.length } } : null;
    };
  }

  updateChannel(event: any): void {
    if (event.value === 'mobileNumber') {
      this.patientForm.get('email').clearValidators();
      this.patientForm.get('email').setValue(null);
      this.patientForm.get('mobileNumber').setValidators([Validators.required, Validators.pattern('04[0-9]{8}')]);
    } else if (event.value === 'email') {
      this.patientForm.get('mobileNumber').clearValidators();
      this.patientForm.get('mobileNumber').setValue(null);
      this.patientForm.get('email').setValidators([Validators.required, Validators.email]);
    }
    this.patientForm.get('mobileNumber').updateValueAndValidity();
    this.patientForm.get('email').updateValueAndValidity();
    this.channelForInviteSelect = event.value;
  }

  exitEditMode(): void {
    this.isEditing = false;
    this.resetForm();
  }

  save(): void {
    const registerPayload = this.patientForm.value as AslRegisterPayload;
    const patient = this.store.selectSnapshot(ProfileState.profile).patient;
    registerPayload.ihi = patient.ihiNumber;
    registerPayload.gender = patient.gender;
    registerPayload.firstName = patient.firstname;
    registerPayload.surname = patient.surname;
    registerPayload.address = patient.homeAddress;
    registerPayload.addressSuburb = patient.homeSuburb;
    registerPayload.addressState = patient.homeState;
    registerPayload.addressPostcode = patient.homePostcode.toString();
    registerPayload.dateOfBirth = patient.birthDate?.toString();
    registerPayload.medicareNumber = patient.medicareNo;
    registerPayload.DVANumber = patient.repatNo;

    const relatedPersons = [];
    for (const carerForm of this.carerForms.controls) {
      const carer = carerForm.value;
      const relatedPerson = new AslRelatedPerson();
      relatedPerson.email = carer.email;
      relatedPerson.phoneNumber = carer.mobileNumber;
      relatedPerson.address = carer.address;
      relatedPerson.suburb = carer.suburb;
      relatedPerson.state = carer.state;
      relatedPerson.postcode = carer.postcode;
      relatedPerson.primaryContact = carer.primary;
      if (carer.carerType === CarerType.INDIVIDUAL) {
        relatedPerson.firstName = carer.firstName;
        relatedPerson.surname = carer.surname;
        relatedPerson.relationship = carer.relationship;
      } else {
        relatedPerson.organisationName = carer.organisation;
      }
      relatedPerson.isAgent = false;
      relatedPerson.id = carer.id;
      relatedPersons.push(relatedPerson);
    }
    for (const agentForm of this.agentForms.controls) {
      const agent = agentForm.value;
      const relatedPerson = new AslRelatedPerson();
      relatedPerson.email = agent.email;
      relatedPerson.phoneNumber = agent.mobileNumber;
      relatedPerson.address = agent.address;
      relatedPerson.suburb = agent.suburb;
      relatedPerson.state = agent.state;
      relatedPerson.postcode = agent.postcode;
      relatedPerson.firstName = agent.firstName;
      relatedPerson.surname = agent.surname;
      relatedPerson.relationship = agent.relationship;
      relatedPerson.primaryContact = agent.primary;
      relatedPerson.isAgent = true;
      relatedPerson.id = agent.id;
      relatedPersons.push(relatedPerson);
    }
    registerPayload.relatedPersons = relatedPersons;

    this.updateListener = this.store.dispatch([
      new UpdateASLPatient(registerPayload),
    ]).subscribe(() => {
      this.isEditing = false;
      this.isEditingNewCarer = false;
      this.isEditingNewAgent = false;
      this.resetForm();
    });
  }

  get formDisabled(): boolean {
    return this.patientForm.invalid;
  }

  getConsentStyles(consentStatus: SLConsent): {[keyof: string]: boolean} {
    return {
      'consent-requested': consentStatus.infaStatus === SLConsentState.Proposed || consentStatus.icolStatus === SLConsentState.Proposed,
      'consent-rejected': consentStatus.infaStatus === SLConsentState.Rejected,
      'consent-active': consentStatus.infaStatus === SLConsentState.Active,
      'no-consent': !consentStatus.infaStatus
    }
  }

  getConsentStatusTitle(consentStatus: SLConsent) {
    if(!consentStatus.infaStatus) {
      return 'Request consent to access MySL';
    }
    switch(consentStatus.infaStatus) {
      case SLConsentState.Proposed: return 'Requested consent to access MySL';
      case SLConsentState.Rejected: return 'Consent rejected to access MySL';
      case SLConsentState.Active: return 'Consent received to access MySL';
      default: consentStatus.isRegistered? 'Patient is Registered for MySL but pharmacy consent required': 'Request consent to access MySL';
    }
  }

  getAccessTypeIconClass(consentStatus: SLConsentState, otherConsentStatus: SLConsentState) {
    if(!consentStatus) {
      return 'fas fa-circle mr-1';
    }
    switch(consentStatus) {
      case SLConsentState.Proposed: return 'fal fa-clock mr-1';
      case SLConsentState.Rejected: return 'far fa-times mr-1';
      case SLConsentState.Active: return 'far fa-check mr-1';
      default: 'fas fa-times mr-1';
    }
  }

  resetForm(): void {
    const aslDetails = this.store.selectSnapshot(ASLState.aslDetails);
    this.channelForInviteSelect = aslDetails.mobileNumber ? 'mobileNumber' : 'email';
    this.patientForm = this.fb.group({
      mobileNumber: new FormControl(
        aslDetails.mobileNumber,
        this.channelForInviteSelect === 'mobileNumber' ? [Validators.required] : null
      ),
      email: new FormControl(
        aslDetails.email,
        this.channelForInviteSelect === 'email' ? [Validators.email, Validators.required] : null
      ),
      carers: new FormArray([]),
      agents: new FormArray([]),
      id: new FormControl(aslDetails.status.id)
    });
    this.carerForms = this.patientForm.get('carers') as FormArray;
    this.agentForms = this.patientForm.get('agents') as FormArray;

    for (const carer of this.getCarers(aslDetails.relatedPersons)) {
      this.addNewRelatedPerson(this.carerForms, carer);
    }

    for (const agent of this.getAgents(aslDetails.relatedPersons)) {
      this.addNewRelatedPerson(this.agentForms, agent);
    }
  }

  addNewRelatedPerson(formArray: FormArray, relatedPerson: AslRelatedPerson): void {
    if (!relatedPerson) {
      relatedPerson = new AslRelatedPerson();
    }

    formArray.push(this.fb.group({
      carerType: new FormControl(relatedPerson.organisationName ? CarerType.ORGANISATION : CarerType.INDIVIDUAL, [Validators.required]),
      firstName: new FormControl(relatedPerson.firstName, [Validators.required]),
      surname: new FormControl(relatedPerson.surname, [Validators.required]),
      mobileNumber: new FormControl(relatedPerson.phoneNumber, [Validators.required]),
      email: new FormControl(relatedPerson.email, [Validators.email]),
      relationship: new FormControl(relatedPerson.relationship, [Validators.required]),
      address: new FormControl(relatedPerson.address),
      suburb: new FormControl(relatedPerson.suburb),
      state: new FormControl(relatedPerson.state),
      postcode: new FormControl(relatedPerson.postcode, [Validators.pattern(postCodePattern)]),
      organisation: new FormControl(relatedPerson.organisationName),
      primary: new FormControl(relatedPerson.primaryContact),
      id: new FormControl(relatedPerson.id)
    }));
  }

  removeRelatedPersons(formArray: FormArray, id: number): void {
    formArray.removeAt(id);
    this.save();
  }

  updatePrimaryContact(event: { id: number; isAgent: boolean }): void {
    for (const form of this.carerForms.controls) {
      form.get('primary').setValue(false);
    }
    for (const form of this.agentForms.controls) {
      form.get('primary').setValue(false);
    }

    if (event.isAgent) {
      this.agentForms.at(event.id).get('primary').setValue(true);
    } else {
      this.carerForms.at(event.id).get('primary').setValue(true);
    }
  }

  ngOnDestroy(): void {
    if(this.updateListener) { this.updateListener.unsubscribe(); };
    if (this.aslDetailListener) { this.aslDetailListener.unsubscribe(); };
  }

  saveNewRelatedPerson(relatedPerson: FormGroup, isAgent: boolean): void {
    if (relatedPerson.get('primary')) {
      for (const form of this.carerForms.controls) {
        form.get('primary').setValue(false);
      }
      for (const form of this.agentForms.controls) {
        form.get('primary').setValue(false);
      }
    }

    if (isAgent) {
      this.agentForms.push(relatedPerson);
    } else {
      this.carerForms.push(relatedPerson);
    }
    this.save();
  }
}
