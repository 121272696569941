import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { DoseAdministrationAidsState, DAAFormType } from './state/dose-administration-aids.state';
import { Observable, Subscription, forkJoin } from 'rxjs';
import { Select, Store } from '@ngxs/store';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { switchMap, withLatestFrom, filter, map } from 'rxjs/operators';
import { SetDAAFormType, SubmitNewDAA, SubmitExisitingDAA, ShowInvalidDAAForm, ResetDAAFormListAction } from './state/dose-administration-aids.actions';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { DAAFormsProgressService } from './state/daa-forms-progress.service';
import { DAAFormsService } from './state/daa-forms.service';
import { ProfileState } from '../../../../core/profile/state/profile.state';
import { SixCpaState } from '../../state/six-cpa.state';
import { OpenResetFormModal, CloseResetFormModal } from '../../state/six-cpa.actions';
import { SaveOrUpdateDAAAction } from './state/daa-administration-aids-save.actions';

@Component({
  selector: 'app-dose-administration-aids',
  templateUrl: './dose-administration-aids.component.html',
  styleUrls: ['./dose-administration-aids.component.scss']
})
export class DoseAdministrationAidsComponent implements OnInit, OnDestroy {
  @Select(DoseAdministrationAidsState.daaFormType) daaFormType$: Observable<DAAFormType>;
  @Select(DoseAdministrationAidsState.saving) saving$: Observable<boolean>;
  @Select(SixCpaState) sixCpaState$: Observable<any>;
  daaFormList: FormGroup;
  formSubscription: Subscription;
  daaFormTypes = DAAFormType;
  private readonlySubscription: Subscription;

  constructor(
    private formBuilder: FormBuilder,
    private store: Store,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.initForm();
    this.initFormChangesSubscription();
    this.initReadonlySubscription();
  }

  ngOnDestroy() {
    if (this.formSubscription) {
      this.formSubscription.unsubscribe();
    }
    if (this.readonlySubscription) {
      this.readonlySubscription.unsubscribe();
    }
  }

  resetDAAFormList() {
    this.store.dispatch(new ResetDAAFormListAction());
  }

  openConfirmResetModal() {
    this.store.dispatch(new OpenResetFormModal())
  }

  closeConfirmResetModal() {
    this.store.dispatch(new CloseResetFormModal())
  }

  private initForm() {
    this.daaFormList = this.formBuilder.group({
      daaFormType: new FormControl(DAAFormType.PatientRegistration, Validators.required)
    });
  }

  private initFormChangesSubscription() {
    this.formSubscription = this.daaFormList.get('daaFormType').valueChanges.pipe(
      switchMap((daaFormType) => this.store.dispatch(new SetDAAFormType(daaFormType)).pipe(
        withLatestFrom(this.store.select(DoseAdministrationAidsState.daaFormType)))))
      .subscribe(() => { this.cdr.detectChanges() });
  }

  private initReadonlySubscription() {
    this.readonlySubscription = this.store.select(SixCpaState.formsReadOnly)
      .subscribe(readonly => readonly ? this.daaFormList.disable() : this.daaFormList.enable());
  }

  @Dispatch()
  saveDaaForm = () => new SaveOrUpdateDAAAction();

  @Dispatch()
  submitDAARegistration = () => {
    if (this.store.selectSnapshot(DAAFormsProgressService.getDAARegistrationProgress) !== 100) {
      this.store.dispatch(new ShowInvalidDAAForm());
    }
    return this.store.selectOnce(DAAFormsProgressService.getDAARegistrationProgress).pipe(
      filter(progress => progress === 100),
      switchMap(() => forkJoin([
        this.store.selectOnce(DAAFormsService.getDAAPatientRegistrationRequest),
        this.store.selectOnce(ProfileState.clientId)
      ])),
      map(([formData, clientId]) =>
        formData && formData.sixCpaFormId !== null
          ? new SubmitExisitingDAA(clientId, formData)
          : new SubmitNewDAA(clientId, formData)));
    }


  @Dispatch()
  submitDAAClaim = () => {
    if (this.store.selectSnapshot(DAAFormsProgressService.getDAAClaimProgress) !== 100) {
      this.store.dispatch(new ShowInvalidDAAForm());
    }
    return this.store.selectOnce(DAAFormsProgressService.getDAAClaimProgress).pipe(
      filter(progress => progress === 100),
      switchMap(() => forkJoin([
        this.store.selectOnce(DAAFormsService.getDAAClaimRequest),
        this.store.selectOnce(ProfileState.clientId)
      ])),
      map(([formData, clientId]) =>
        formData && formData.sixCpaFormId !== null
          ? new SubmitExisitingDAA(clientId, formData)
          : new SubmitNewDAA(clientId, formData)));
    }
  
}
