<div class="modal confirm-modal">
        <div class="modal-body border__grey">
          <div>
            <h3 class="p-2">Warning:</h3>
            <p>Confirming this action will reset all form data back to its defaults, are you sure?</p>
            <div g-xs="mt2">
              <button class="button btn__yellow" g-xs="mr1" (click)="close()">Cancel</button>
              <button class="button btn__green" (click)="submit()">Confirm</button>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-background"></div>