import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { InviteType } from '~/shared/models/profile/inviteType.enum';
import { SimpleSignUp } from '~/shared/models/simple-sign-up/simple-sign-up.model';
import { CreateAccount } from '../../state/sign-up.actions';
import { SignUpState, SignUpStateModel } from '../../state/sign-up.state';
import { PRIVACY_POLICY_URL, TERMS_URL } from '~/shared/constants/policies';

@Component({
  selector: 'app-create-account',
  templateUrl: './create-account.component.html',
  styleUrls: ['./create-account.component.scss']
})
export class CreateAccountComponent implements OnInit {
  @Select(SignUpState.state) signUpState$: Observable<SignUpStateModel>;
  createAccountForm: FormGroup;
  inviteTypes = InviteType;
  showPopup = false;

  privacyUrl = PRIVACY_POLICY_URL;
  termsUrl = TERMS_URL;
  constructor(private formBuilder: FormBuilder, private store: Store) { }

  ngOnInit() {
    this.generateFormControls();
  }

  private generateFormControls() {
    var digitUpperCharLowerCharEightCharsRegex = new RegExp(/^(?=.*\d).{8,}$/);
    this.createAccountForm = this.formBuilder.group({
      password: new FormControl(null, [Validators.required, Validators.pattern(digitUpperCharLowerCharEightCharsRegex)]),
      confirmPassword: new FormControl(null, [Validators.required, Validators.pattern(digitUpperCharLowerCharEightCharsRegex)]),
      loginMethod: new FormControl(InviteType.Email, Validators.required),
      consent: new FormControl(false, Validators.required),
    });
  }

  passwordsDontMatch(): boolean {
    return this.createAccountForm.value.password !== this.createAccountForm.value.confirmPassword;
  }

  createAccount() {
    let createAccountRequest = this.createAccountForm.value as SimpleSignUp;
    const customer = this.store.selectSnapshot(SignUpState.state).customer;
    createAccountRequest.customerProfileId = customer.customerProfileId;
    createAccountRequest.email = customer.email;
    createAccountRequest.mobile = customer.mobile;
    this.store.dispatch(new CreateAccount(createAccountRequest));
  }

  toggleIncorrectDetailsModal() {
    this.showPopup =  !this.showPopup;
  }
}
