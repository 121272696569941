import { Selector } from '@ngxs/store';
import { PatientDetailsFormState } from '../../patient-details-form/state/patient-details-form.state';
import { ConsentEligibilityFormState } from '../../consent-eligibility-form/state/consent-eligibility-form.state';
import { MedicationInformationState } from '../../medication-information-form/state/medication-information.state';
import { OutcomesAndActionsFormState } from '../../outcomes-and-actions-form/state/outcomes-and-actions-form.state';
import { PharmacyInformationFormState } from '../../pharmacy-information-form/state/pharmacy-information-form.state';
import { MedsCheckMedicationProfileState } from '../../meds-check-medication-profile/state/meds-check-medication-profile.state';
import { MedsCheckFormListState, MedsCheckType } from './meds-check-form-list.state';
import { ActionPlanFormState } from '../../action-plan-form/state/action-plan-form.state';

export class MedsCheckFormsProgressService {
  @Selector([
    PatientDetailsFormState.totalItems,
    PatientDetailsFormState.completedItems,
    ConsentEligibilityFormState.totalItems,
    ConsentEligibilityFormState.completedItems,
    MedicationInformationState.totalItems,
    MedicationInformationState.completedItems,
    OutcomesAndActionsFormState.totalItems,
    OutcomesAndActionsFormState.completedItems,
    PharmacyInformationFormState.totalItems,
    PharmacyInformationFormState.completedItems,
    ActionPlanFormState.totalItems,
    ActionPlanFormState.completedItems,
    MedsCheckMedicationProfileState.selectedMedicineCount
  ])
  static getProgressDetailed(
    patientDetailsTotal,
    patientDetailsCompleted,
    consentEligibilityTotal,
    consentEligibilityCompleted,
    medicationInformationTotal,
    medicationInformationCompleted,
    outcomesActionsTotal,
    outcomesActionsCompleted,
    pharmacyInformationTotal,
    pharmacyInformationCompleted,
    actionPlanTotal,
    actionPlanCompleted,
    selectedMedicines
  ) {
    return Math.floor((
      patientDetailsCompleted +
      consentEligibilityCompleted +
      medicationInformationCompleted +
      outcomesActionsCompleted +
      pharmacyInformationCompleted +
      actionPlanCompleted +
      (selectedMedicines > 0 ? 1 : 0)) /
      (patientDetailsTotal +
        consentEligibilityTotal +
        medicationInformationTotal +
        outcomesActionsTotal +
        actionPlanTotal +
        pharmacyInformationTotal + 1) * 100);
  }

  @Selector([
    PatientDetailsFormState.totalItems,
    PatientDetailsFormState.completedItems,
    ConsentEligibilityFormState.totalItems,
    ConsentEligibilityFormState.completedItems,
    PharmacyInformationFormState.totalItems,
    PharmacyInformationFormState.completedItems,
    ActionPlanFormState.totalItems,
    ActionPlanFormState.completedItems,
    MedsCheckMedicationProfileState.selectedMedicineCount
  ])
  static getProgressBasic(
    patientDetailsTotal,
    patientDetailsCompleted,
    consentEligibilityTotal,
    consentEligibilityCompleted,
    pharmacyInformationTotal,
    pharmacyInformationCompleted,
    actionPlanTotal,
    actionPlanCompleted,
    selectedMedicines
  ) {
    return Math.floor((
      patientDetailsCompleted +
      consentEligibilityCompleted +
      pharmacyInformationCompleted +
      actionPlanCompleted +
      (selectedMedicines > 0 ? 1 : 0)) /
      (patientDetailsTotal +
        consentEligibilityTotal +
        actionPlanTotal +
        pharmacyInformationTotal + 1) * 100);
  }

  @Selector([MedsCheckFormListState.medsCheckType, MedsCheckFormsProgressService.getProgressBasic, MedsCheckFormsProgressService.getProgressDetailed])
  static getProgress(medsCheckType, basicProgress, detailedProgress) {
    return medsCheckType == MedsCheckType.PatientRegistration
      ? detailedProgress
      : basicProgress;
  }
}
