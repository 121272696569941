import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SickLeaveConsentAndEligibilityListItemComponent } from './sick-leave-consent-and-eligibility-list-item.component';
import { SickLeaveConsentAndEligibilityModule } from '../sick-leave-consent-and-eligibility/sick-leave-consent-and-eligibility.module';
import { SixCpaFormListItemChecklistModule } from '../../six-cpa-form-list-item-checklist/six-cpa-form-list-item-checklist.module';

@NgModule({
  declarations: [SickLeaveConsentAndEligibilityListItemComponent],
  imports: [
    CommonModule,
    SickLeaveConsentAndEligibilityModule,
    SixCpaFormListItemChecklistModule
  ],
  exports: [SickLeaveConsentAndEligibilityListItemComponent]
})
export class SickLeaveConsentAndEligibilityListItemModule { }
