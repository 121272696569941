<nz-table #scheduledTable [nzData]="messages" [nzFrontPagination]="false" [nzShowPagination]="false"
  class="message-list" nzTableLayout="fixed">
  <thead>
    <tr class="hide">
      <th nz-th nzWidth="50px">T</th>
      <th nz-th nzWidth="250px">Name</th>
      <th>Content</th>
      <th nz-th nzWidth="200px" *ngIf="messageType === mailTabs.Scheduled || messageType === mailTabs.Sent">Type</th>
      <th nz-th nzWidth="150px" *ngIf="messageType === mailTabs.Inbox">Date</th>
      <th nz-th nzWidth="200px" *ngIf="messageType === mailTabs.Sent">Date</th>
      <th nz-th nzWidth="300px" *ngIf="messageType === mailTabs.Scheduled">Date</th>
      <th nz-th nzWidth="100px">Channel</th>
      <th nz-th nzWidth="50px">A</th>
      <th nz-th nzWidth="50px">F</th>
      <th nz-th nzWidth="50px">R</th>
      <th nz-th nzWidth="150px">Action</th>
    </tr>
  </thead>

  <tbody>
    <tr *ngFor="let message of scheduledTable.data | slice:displayedFromRow:displayedToRow" class="message" [ngClass]="{'messageSelected': message?.selected }" (click)="onMessageClicked($event, message)">
      <td>
        <label nz-checkbox [(ngModel)]="message.selected" (ngModelChange)="changeCheckbox(message,$event)"></label>
      </td>
      <td nzEllipsis>
        <span *ngIf="!message?.isRead && messageType === mailTabs.Inbox" class="message-read-indicator mr-2"></span>
        <span *ngIf="messageType === mailTabs.Scheduled" class="message-name-bold">To: </span>
        <span class="message-name" [ngClass]="{'message-name-bold' : (messageType !== mailTabs.Scheduled && messageType !== mailTabs.Sent && !message.isRead)}">
          <span *ngIf="message?.firstName !== null && message?.firstName !== ''">{{message?.firstName}} </span>
          <span *ngIf="message?.lastName !== null && message?.lastName !== ''">{{message?.lastName}}</span>
          <span *ngIf="(message?.lastName === null || message?.lastName === '') && (message?.firstName === null || message?.firstName === '')">{{message?.receipientAddress}}</span>
        </span>
      </td>
      <td nzEllipsis>
        <ng-container *ngIf="message?.content">
          <span [innerHTML]="sanitize(preserveMergeTags(linkEscripts(message?.content))| striphtml)"></span>
        </ng-container>
        <ng-container *ngIf="!message?.content && message?.hasAttachment"><i>[This message has no content. Attachment only]</i></ng-container>
      </td>
      <td *ngIf="messageType === mailTabs.Scheduled" class="message-recurring-container">
        <div *ngIf="message?.sendDateTime !== null">
          <span class="message-recurring">
            <i class="fal fa-info-circle"></i> {{message.type}}
          </span>
          <div *ngIf="message?.tooltipText" class="recurring-hover-dialog-container">
            <div class="recurring-hover-dialog">{{message?.tooltipText}}</div>
          </div>
        </div>
      </td>
      <td *ngIf="messageType === mailTabs.Sent" class="message-recurring-container">
        <div *ngIf="message?.type === 'BulkCommunications'">
          <span class="message-recurring">
            <i class="fal fa-info-circle"></i> Bulk Communication
          </span>
          <div *ngIf="message?.tooltipText" class="recurring-hover-dialog-container">
            <div class="recurring-hover-dialog">{{message?.tooltipText}}</div>
          </div>
        </div>
      </td>
      <td *ngIf="messageType === mailTabs.Inbox && message?.sendDateTime !== null">
        <span class="message-created">{{message?.sendDateTime | tenantTimezone | humanize }}</span>
      </td>
      <td *ngIf="messageType === mailTabs.Scheduled && message?.sendDateTime !== null && message?.type === 'Projected'">
        <span class="message-created"><i class="fas fa-history fa-flip-horizontal"></i> Scheduled: {{message?.sendDateTime | humanize }}</span>
      </td>
      <td *ngIf="messageType === mailTabs.Scheduled && message?.sendDateTime !== null && (message?.type === 'Scheduled' || message?.type === 'Recurring')">
        <span class="message-created"><i class="fas fa-history fa-flip-horizontal"></i> Scheduled: {{ message?.sendDateTime | tenantTimezone | humanize }}</span>
      </td>
      <td *ngIf="messageType === mailTabs.Sent && message?.sendDateTime !== null"><span class="message-created">
        <i class="fal fa-paper-plane"></i> Sent: {{message?.sendDateTime | tenantTimezone | humanize}}</span>
      </td>

      <td class="message-channel">
        <span class="message-colour">
          <i [ngClass]="getChannelIcon(message?.channel)"></i>
          {{getChannelName(message?.channel)}}
        </span>
      </td>

      <td g-xs="text:center"><i *ngIf="message.hasAttachment" class="message-attachment message-colour far fa-paperclip"></i></td>
      <td g-xs="text:center">
        <i [ngClass]="{'message-colour fa-share fa-flip-horizontal': true, 'fas': message.isReplied, 'far': !message.isReplied}" g-xs="mr1"></i>
      </td>
      <td g-xs="text:center">
        <i [ngClass]="{'fa-flag': true, 'fas flagged': message.isFlagged, 'far message-colour': !message.isFlagged}" g-xs="mr1"></i>
      </td>

      <td class="align-items-center" g-xs="p1 text:center" id="actions-menu">
        <span class="buttons-container" g-xs="pr1">
          <button type="button" class="comms-button view-button py-2 px-3">View</button>
        </span>

        <button *ngIf="message.type !=='BulkCommunications'" nz-button nz-dropdown nzTrigger="click" type="button" [nzDropdownMenu]="menu" id="more-button" class="comms-button more-button-container py-2"><i class="fal fa-ellipsis-h-alt" id="more-button-text"></i></button>
        <nz-dropdown-menu #menu="nzDropdownMenu">
          <ul nz-menu>
            <li *ngIf="messageType === mailTabs.Inbox" nz-menu-item class="more-dropdown-option cursor-pointer" (click)="toggleFlagged(message)">
              {{(message.isFlagged) ? 'Unflag' : 'Flag'}}
            </li>
            <li *ngIf="messageType === mailTabs.Inbox" nz-menu-item class="more-dropdown-option cursor-pointer" (click)="toggleRead(message)">
              {{(message.isRead) ? 'Mark as unread' : 'Mark as read'}}
            </li>
            <li nz-menu-item class="more-dropdown-option cursor-pointer">
              <a class="hidden-link" routerLink="/profile/{{ message?.customerId }}/categories">View patient</a>
            </li>
            <li *ngIf="messageType === mailTabs.Inbox" nz-menu-item class="more-dropdown-option cursor-pointer" (click)="toggleArchived(message)">
              {{(message.isArchived) ? 'Remove from archive' : 'Archive message'}}
            </li>
          </ul>
        </nz-dropdown-menu>

      </td>

    </tr>
  </tbody>
</nz-table>
