export interface PauseMedicationRequest {
  metaId:  number;
  startDate: Date | String;
  endDate?: Date | string;
  reason: string;
  pauseType: PauseType;
}

export interface PausedMedicationModel {
  metaId:  number;
  startDate: Date | String;
  endDate?: Date | string;
  reason: string;
  status: PauseStatus;
  type: PauseType;
}

export enum PauseStatus {
  Active,
  Pending,
  Complete,
  InActive
}

export enum PauseType {
  Indefinite,
  Fixed
}