<ng-container>
  <div>
    <ng-container *ngIf="(loading$ | async) as loading">
      <app-loader *ngIf="loading" [loadingMessage]="loadingMessage"></app-loader>
    </ng-container>
    <div class="mt-3">
      <div class="form-group" *ngIf="(tenants$ | async) as tenants">
        <label class="font__bold">Pharmacy Name</label>
        <br />
        <nz-select
          class="w-50"
          [nzDisabled]="!tenants.values"
          nzMode="multiple"
          nzPlaceHolder="Select tenants (select none to send to all)"
          [(ngModel)]="selectedTenants"
          [nzAllowClear]="true">
          <nz-option *ngFor="let tenant of tenants" [nzLabel]="tenant.tenantName" [nzValue]="tenant.tenantId"></nz-option>
        </nz-select>
      </div>
      <form [formGroup]="commTemplateForm">
        <div class="form-group">
          <label class="font__bold">Template Type</label>
          <select class="form-control form-control-lg w-50" formControlName="templateType">
            <option [value]="templateTypes.Default">Default</option>
            <option [value]="templateTypes.Custom">Custom</option>
            <option [value]="templateTypes.Report">Report</option>
          </select>
        </div>
        <div class="form-group">
          <label class="font__bold">Template Name</label>
          <input type="text" class="form-control form-control-lg w-50" formControlName="name" required>
        </div>
        <div class="form-group">
          <label class="font__bold">Subject Line (optional)</label>
          <input type="text" class="form-control form-control-lg w-50" formControlName="subject" />
        </div>
        <div class="form-group">
          <label class="font__bold">Content</label>
          <textarea #messageTextArea rows="6" class="form-control" required formControlName="message">
                    </textarea>
          <div class="row ml-1">
            <div class="p-1" *ngFor="let tag of mergeTags">
              <span class="pointer font-italic underline text-info" *ngIf="tag.key != 'RepeatTokenLink'"
                (click)="insertTag(tag.value)">
                {{tag.key}}
              </span>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div g-xs="mt2">
      <button class="btn btn-success create-button btn-lg" (click)="submit()">Create Template</button>
    </div>
  </div>
</ng-container>
