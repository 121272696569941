import { ClassificationAndRecommendationsFormModel } from '../../../../../shared/models/six-cpa/classification-and-recommendations-form.model';

export enum ClassificationAndRecommendationsActions {
  SET_PROGRESS = '[ClassificationAndRecommendations] update progress',
  RESET_FORM = '[ClassificationAndRecommendations] reset form',
  UPDATE = '[ClassificationAndRecommendations] Update'
}

export class SetClassificationAndRecommendationsProgress {
  static readonly type = ClassificationAndRecommendationsActions.SET_PROGRESS;
  constructor(public totalItems, public completedItems) { }
}

export class UpdateClassificationAndRecommendationsForm {
  static readonly type = ClassificationAndRecommendationsActions.UPDATE;
  constructor(public form: ClassificationAndRecommendationsFormModel) { }
}
