import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store, Select } from '@ngxs/store';
import { PauseMedication, UnPauseMedication, PauseBulkMedication } from './state/pause-medication.actions';
import { PauseMedicationRequest, PauseType } from '../../../../shared/models/pause-medication-request.model';
import { ScriptActionsState } from '../state/script-actions.state';
import { Observable, Subscription } from 'rxjs';
import * as moment_ from 'moment';
const moment = moment_;
import { MultipleDrugsState } from '../multiple-select/state/multiple-drugs.state';

export enum PauseOptions {
  DateRange,
  NumberOfDays,
  Indefinite
}

@Component({
  selector: 'app-pause-medication',
  templateUrl: './pause-medication.component.html'
})
export class PauseMedicationComponent implements OnInit, OnDestroy {
  @Select(ScriptActionsState.script) script$: Observable<any>;
  @Select(MultipleDrugsState.selectedDrugsArray) selectedDrugs$: Observable<any>;
  selectedDrugsSubscription: Subscription;
  pauseMedicationBulkRequest: PauseMedicationRequest[] = [];
  pauseMedicationRequest: PauseMedicationRequest = { metaId: null, startDate: new Date(), reason: null, pauseType: PauseType.Indefinite };
  selectedPauseOption: PauseOptions;
  daysToPause: any;
  pauseOptions = PauseOptions;
  isDrugPaused = false;

  constructor(private store: Store) { }

  ngOnInit() {
    this.selectedPauseOption = this.pauseOptions.Indefinite;
  }

  ngOnDestroy() {
    if (this.selectedDrugsSubscription) { this.selectedDrugsSubscription.unsubscribe() };
  }

  pauseMedication(metaId: number) {
    const selectedDrugs = this.store.selectSnapshot(MultipleDrugsState.selectedDrugsArray);
    selectedDrugs.length ?
      this.store.dispatch(new PauseBulkMedication(this.buildBulkRequest(selectedDrugs)))
      : this.store.dispatch(new PauseMedication(this.buildRequest(metaId)))
  }

  resumeMedication(metaId: number) {
    this.pauseMedicationRequest.metaId =  metaId;
    this.store.dispatch(new UnPauseMedication(this.pauseMedicationRequest));
  }

  private buildBulkRequest(selectedDrugs: any) {
    this.pauseMedicationRequest = this.buildRequest(null);
    selectedDrugs.forEach(drug => {
      this.pauseMedicationBulkRequest.push(
        {
          metaId: drug.cMeta.id,
          startDate: this.pauseMedicationRequest.startDate,
          endDate: this.pauseMedicationRequest.endDate,
          reason: this.pauseMedicationRequest.reason,
          pauseType: this.pauseMedicationRequest.pauseType
        }
      )
    });
    return this.pauseMedicationBulkRequest;
  }

  private buildRequest(metaId: number) {
    this.pauseMedicationRequest.metaId = metaId;
    switch (PauseOptions[this.selectedPauseOption]) {
      case PauseOptions[PauseOptions.Indefinite]: {
        this.pauseMedicationRequest.endDate = null;
        this.pauseMedicationRequest.pauseType = PauseType.Indefinite;
        return this.pauseMedicationRequest;
      }
      case PauseOptions[PauseOptions.DateRange]: {
        this.pauseMedicationRequest.pauseType = PauseType.Fixed;
        return this.pauseMedicationRequest;
      }
      case PauseOptions[PauseOptions.NumberOfDays]: {
        this.pauseMedicationRequest.pauseType = PauseType.Fixed;
        this.pauseMedicationRequest.endDate = moment().add(this.daysToPause, 'days').toDate();
        return this.pauseMedicationRequest;
      }
    }
  }
}
