import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxsModule } from '@ngxs/store';
import { AddEditMedicationState } from './state/add-edit-medication.state';
import { AddEditMedicationComponent } from './add-edit-medication.component';
import { ReactiveFormsModule } from '@angular/forms';
import { StockService } from '../../../../modules/order/services/stock.service';
import { CustomerAuth } from '../../../../modules/order/services/customer-auth';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';

@NgModule({
  declarations: [AddEditMedicationComponent],
  imports: [
    CommonModule,
    NgxsModule.forFeature([
      AddEditMedicationState
    ]),
    ReactiveFormsModule,
    NgxsFormPluginModule
  ],
  providers: [
    StockService,
    CustomerAuth
  ],
  exports: [
    AddEditMedicationComponent
  ]
})
export class AddEditMedicationModule { }
