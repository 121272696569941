import { Actions, ofActionSuccessful, Select } from '@ngxs/store';
import { OnInit, OnDestroy, Injectable } from '@angular/core';
import { Subscription, Observable } from 'rxjs';
import { ResetCIAction } from './clinical-intervention/state/clinical-intervention.actions';
import { ClinicalInterventionState } from './clinical-intervention/state/clinical-intervention.state';

@Injectable()

export abstract class ClinicalInterventionChildForm implements OnInit, OnDestroy {
  private onResetFormSubscription: Subscription;

  constructor(
    protected actions$: Actions
  ) {
    this.configureLifecycleHooks();
  }
  @Select(ClinicalInterventionState.showInvalid) showInvalid$: Observable<boolean>;

  ngOnInit() {}
  ngOnDestroy() {}

  protected abstract actionOnResetForm();

  private init(): void {
    this.onResetFormSubscription = this.getResetFormSubscription();
  }

  private destroy(): void {
    if (this.onResetFormSubscription) {
      this.onResetFormSubscription.unsubscribe();
    }
  }

  private configureLifecycleHooks() {
    const onDestroy = this.ngOnDestroy;
    const onInit = this.ngOnInit;
    this.ngOnInit = () => { this.init(); onInit.apply(this); };
    this.ngOnDestroy = () => { this.destroy(); onDestroy.apply(this); };
  }

  private getResetFormSubscription(): Subscription {
    return this.actions$.pipe(ofActionSuccessful(ResetCIAction))
      .subscribe(() => this.actionOnResetForm());
  }
}
