export interface CommTemplate {
  id: number | null;
  name: string;
  message: string;
  createdDate: Date | null;
  subject: string;
  isActive: boolean;
  templateType: TemplateType;
}

export enum TemplateType {
  Default,
  Custom,
  Report
}

