<ng-container *ngIf="(dispenseRunState$ | async) as dispenseRunState">
    <h2 class="text-center">Dispense Runs</h2>
  <section>
    <app-dispense-run-form></app-dispense-run-form>
    <ng-container *ngIf="dispenseRunState.loading">
        <app-loader [loadingMessage]="dispenseRunState.loadingMessage"></app-loader>
    </ng-container>
  </section>
  <section>
      <app-dispense-run-results></app-dispense-run-results>
  </section>
</ng-container>
