import { MailTabs, MessageTypes } from "~/shared/types/communications";

export const DefaultInboxMessageTypes = [
    {
        name: 'All',
        value: MessageTypes.All,
        visible: [MailTabs.Sent, MailTabs.Inbox, MailTabs.Scheduled],
        defaults: [
            { tab: MailTabs.Inbox, default: true },
            { tab: MailTabs.Sent, default: true },
            { tab: MailTabs.Scheduled, default: true },
        ],
    },
    {
        name: 'Customer',
        value: MessageTypes.Customer,
        visible: [],
        defaults: [],
    },
    {
        name: 'User',
        value: MessageTypes.User,
        visible: [MailTabs.Sent],
        defaults: [{ tab: MailTabs.Sent, default: true }],
    },
    {
        name: 'System',
        value: MessageTypes.AutomatedSystem,
        visible: [MailTabs.Sent],
        defaults: [
            { tab: MailTabs.Sent, default: true },
        ],
    },
    {
        name: 'Projected',
        value: MessageTypes.AutomatedProjected,
        visible: [MailTabs.Scheduled],
        defaults: [
            { tab: MailTabs.Scheduled, default: true },
        ],
    },
    {
        name: 'Bulk SMS',
        value: MessageTypes.BulkComm,
        visible: [MailTabs.Sent],
        defaults: [{ tab: MailTabs.Sent, default: true }],
    },
    {
        name: 'Recurring',
        value: MessageTypes.RecurringComm,
        visible: [MailTabs.Scheduled],
        defaults: [
            { tab: MailTabs.Scheduled, default: true },
        ],
    },
    {
        name: 'Scheduled',
        value: MessageTypes.Scheduled,
        visible: [MailTabs.Scheduled],
        defaults: [
            { tab: MailTabs.Scheduled, default: true },
        ],
    },
    {
        name: 'New Customer Welcome Message',
        value: MessageTypes.NewCustomerWelcomeMessage,
        visible: [MailTabs.Sent],
        defaults: [{ tab: MailTabs.Sent, default: true }],
    },
    {
        name: 'Standard',
        value: MessageTypes.Standard,
        visible: [MailTabs.Inbox],
        defaults: [{ tab: MailTabs.Inbox, default: true }],
    },
    {
        name: 'Survey Response',
        value: MessageTypes.SurveyResponse,
        visible: [MailTabs.Inbox],
        defaults: [{ tab: MailTabs.Inbox, default: true }],
    },
    {
        name: 'Survey',
        value: MessageTypes.Survey,
        visible: [MailTabs.Sent],
        defaults: [{ tab: MailTabs.Sent, default: true }],
    },
];
