import { OrderRequest } from "../models/order-request.model";
import { OrderRequestStatus } from "../models/order.enums";

export function sortIntoCurrentAndPast(orders: OrderRequest[], page: number): OrderRequest[] {


    const currentOrders = orders.filter(order => order.status === OrderRequestStatus.Pending || order.status === OrderRequestStatus.PartiallyReady);
    const pastOrders = orders.filter(order => order.status !== OrderRequestStatus.Pending && order.status !== OrderRequestStatus.PartiallyReady);

    const currentOrdersDisplay = currentOrders.length === 0  && page === 0 ? [{isEmpty: true} as OrderRequest] : currentOrders;
    const [firstCurrent, ...restCurrent] = currentOrdersDisplay;

    if (firstCurrent) {
      firstCurrent.groupHeading = 'Current Orders';
    }

    const pastOrdersDisplay = pastOrders;
    const [firstPast, ...restPast] = pastOrdersDisplay;
    if (firstPast) {
      firstPast.groupHeading = 'Past Orders';
    }

    let ordersForDisplay = [];

    if (firstCurrent) {
      ordersForDisplay.push(firstCurrent);
    }
    if (restCurrent.length > 0) {
      ordersForDisplay.push(...restCurrent);
    }
    if (firstPast) {
      ordersForDisplay.push(firstPast);
    }
    if (restPast.length > 0) {
      ordersForDisplay.push(...restPast);
    }


    // if there are no past or current orders show one empty slot
    if (currentOrders.length === 0 && pastOrders.length ===0) {
      ordersForDisplay = [{isEmpty: true, groupHeading: null } as OrderRequest]
    }


    return ordersForDisplay;
  }
