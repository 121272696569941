import { State, Action, StateContext, Selector, Store } from '@ngxs/store';
import {
  SetOrderProfileAction,
  OrderErrorAction,
  DeleteOrderAction,
  RemoveOrderResponse,
  UpdateOrderAction,
  CreateOrderAction,
  GetOrderList,
  SetOrderPagination,
  UpdateOrderResponse,
  ToggleOrderStatusModal,
  GetOrderRequestById,
  ToggleSendToQueueModal,
  DispenseScriptsToQueue,
  ToggleImageModal,
  SearchOrderStockAction,
  SetStockListAction,
  ToggleAdditionalItemsModal,
  SetCurrentOrder,
  ToggleAddMedicationModal,
  GetPredictedOrder,
  CreateOrderResponse,
  UpdateOrderMethod,
  GeneratePaymentLinkAction,
  StripeModalVisibleAction
} from './order.actions';
import { mergeMap, catchError, tap, finalize } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { OrderService } from '../services/order.service';
import { OrderRequestSearchResult } from '../models/order-request-search-result.model';
import { OrderRequestSortConstants } from '../models/order-request.constants';
import { UpdateOrderRequestModel } from '../models/order-request-update.model';
import { OrderRequest } from '../models/order-request.model';
import { ScriptService } from '../../../../shared/services/script.service';
import { AlertService } from '../../../core/alert/alert.service';
import { OrderMethod, OrderRequestItemStatus, OrderRequestItemType, OrderRequestStatus } from '../models/order.enums';
import { of } from 'rxjs';
import { OrderRequestItem } from '../models/order-request-item.model';
import { StockService } from '../../../order/services/stock.service';
import { CategoriesState } from '../../categories/state/categories.state';
import { Medication } from '../../../../shared/models/script/chartviewitem.model';
import { ProfileState } from '../../../../modules/core/profile/state/profile.state';
import { NzNotificationService } from 'ng-zorro-antd/notification';

export class OrderRequestStateModel {
  public orders: OrderRequestSearchResult;
  public loading: boolean;
  public error: any;
  public pagination: any;
  public showStatusModal: boolean;
  public showSendToQueue: boolean;
  public currentOrder: OrderRequest;
  public dispensing: boolean;
  public imageViewItem: OrderRequestItem;
  public showImageModal: boolean;
  public searchList: OrderRequestItem[];
  public showAdditionalItemsModal: boolean;
  public showAddMedicationModal: boolean;
  public additionalItemsLoading: boolean;
  public orderStatus: any;
  public stripeModalVisible: boolean;
  public invoiceLoading: boolean;
}

@Injectable()
@State<OrderRequestStateModel>({
  name: 'orderRequests',
  defaults: {
    orders: null,
    loading: true,
    error: null,
    pagination: {
      pageSize: 10,
      currentPage: 0,
      sortOrder: OrderRequestSortConstants.requestedDateDescending
    },
    showStatusModal: false,
    showSendToQueue: false,
    currentOrder: null,
    dispensing: false,
    imageViewItem: null,
    showImageModal: false,
    searchList: [],
    showAdditionalItemsModal: false,
    showAddMedicationModal: false,
    additionalItemsLoading: false,
    orderStatus: null,
    stripeModalVisible: false,
    invoiceLoading: false
  }
})

export class OrderRequestState {
  constructor(
    private orderService: OrderService,
    private scriptService: ScriptService,
    private alertService: AlertService,
    private stockService: StockService,
    private store: Store,
    private notificationService: NzNotificationService) { }


  @Selector([CategoriesState.categorised])
  static profileOrderItems(state: OrderRequestStateModel, categorised: any[]) {
    return categorised.map(cat => {
      return {
        name: cat.key.name,
        orderItems: cat.items.map((x: Medication): OrderRequestItem => {
          return {
            customerMedication: x,
            metaId: x.cMeta.id,
            name: x.name,
            stockItemName: x.description,
            stockId: x.productDispensedId,
            quantity: x.quantity,
            orderRequestItemType: x.isPaperless ?
              OrderRequestItemType.EScript
              : OrderRequestItemType.OnFileScript,
            isAdded: state
              .currentOrder
              .orderRequestedItems
              .filter(y => y.stockId == x.productDispensedId).length > 0,
            addedByPharmacist: state
              .currentOrder
              .orderRequestedItems
              .filter(y => y.stockId == x.productDispensedId)[0]?.addedByPharmacist
          } as OrderRequestItem;
        })
      };
    });
  }

  @Selector()
  static searchListFiltered(state: OrderRequestStateModel) {
    const existingStockIds = state.currentOrder.orderRequestedItems.map(i => i.stockId);
    return state.searchList.filter(x => !existingStockIds.includes(x.stockId));
  }

  @Selector()
  static hasSelectedOrderItems(state: OrderRequestStateModel) {
    return state.currentOrder.orderRequestedItems.filter(i => i.selected).length > 0;
  }

  @Selector()
  static orderItems(state: OrderRequestStateModel) {
    // 'mutating' the items for UI purposes without changing underlying data i.e. making on file scripts that are paperless be displayed as escripts
    return state.orders.items;
  }

  @Selector()
  static orderWithNewStatus(state: OrderRequestStateModel): OrderRequest {
    return { ...state.currentOrder, status: state.orderStatus == null ? state.currentOrder.status : state.orderStatus };
  }

  @Selector()
  static pagination(state: OrderRequestStateModel) {
    return state.pagination;
  }

  @Selector()
  static currentOrder(state: OrderRequestStateModel) {
    return state.currentOrder;
  }

  @Selector()
  static invoiceLoading(state: OrderRequestStateModel) {
    return state.invoiceLoading;
  }

  @Selector()
  static orders(state: OrderRequestStateModel) {
    return state.orders;
  }

  @Selector()
  static loading(state: OrderRequestStateModel) {
    return state.loading;
  }

  @Selector()
  static getPaymentUrl(state: OrderRequestStateModel) {
    return state.currentOrder.orderLink;
  }

  @Selector()
  static error(state: OrderRequestStateModel) {
    return state.error;
  }

  @Action(SetCurrentOrder)
  setCurrentOrder(ctx: StateContext<OrderRequestStateModel>, { currentOrder }: SetCurrentOrder) {
    ctx.patchState({
      currentOrder: currentOrder
    });
  }

  @Action(ToggleAddMedicationModal)
  toggleAddMedicationsModal(ctx: StateContext<OrderRequestStateModel>, { open }: ToggleAddMedicationModal) {
    ctx.patchState({ showAddMedicationModal: open });
  }

  @Action(ToggleAdditionalItemsModal)
  toggleShowAdditionalItemsModal(ctx: StateContext<OrderRequestStateModel>, { open }: ToggleAdditionalItemsModal) {
    ctx.patchState({ showAdditionalItemsModal: open });
  }

  @Action(ToggleImageModal)
  toggleImageModal(ctx: StateContext<OrderRequestStateModel>, { open, currentItem }: ToggleImageModal) {
    ctx.patchState({ showImageModal: open, imageViewItem: currentItem });
  }

  @Action(ToggleOrderStatusModal)
  toggleOrderStatusModal(ctx: StateContext<OrderRequestStateModel>, { open, current, status }: ToggleOrderStatusModal) {
    ctx.patchState({ showStatusModal: open, currentOrder: current, orderStatus: status });
  }

  @Action(ToggleSendToQueueModal)
  toggleSendToQueueModal(ctx: StateContext<OrderRequestStateModel>, { open }: ToggleSendToQueueModal) {
    ctx.patchState({ showSendToQueue: open });
  }

  @Action(SetOrderPagination)
  setOrderPagination(ctx: StateContext<OrderRequestStateModel>, { pagination }: SetOrderPagination) {
    ctx.patchState({ pagination: pagination });
  }

  @Action(GetOrderRequestById)
  getByID(ctx: StateContext<OrderRequestStateModel>, { id }: GetOrderRequestById) {
    ctx.patchState({ orders: null, loading: true });
    return this.orderService.getOrder(id).pipe(
      mergeMap((data) => ctx.dispatch(new SetOrderProfileAction(data))),
      catchError((error) => ctx.dispatch(new OrderErrorAction(error)))
    );
  }

  @Action(GetOrderList)
  get(ctx: StateContext<OrderRequestStateModel>, { clientId, filters }: GetOrderList) {
    ctx.patchState({ orders: null, loading: true });
    return this.orderService.getOrdersByClient(clientId, filters).pipe(
      mergeMap((data) => ctx.dispatch(new SetOrderProfileAction(data))),
      catchError((error) => ctx.dispatch(new OrderErrorAction(error)))
    );
  }

  @Action(SetOrderProfileAction)
  set(ctx: StateContext<OrderRequestStateModel>, action: SetOrderProfileAction) {
    ctx.patchState({
      orders: action.orders.items !== undefined ? action.orders : { items: [action.orders], total: 1 } as OrderRequestSearchResult,
      loading: false
    });
  }

  @Action(OrderErrorAction)
  handleError(ctx: StateContext<OrderRequestStateModel>, action: OrderErrorAction) {
    ctx.patchState({ error: action.error, loading: false, invoiceLoading: false });
  }

  @Action(DeleteOrderAction)
  cancelOrder(ctx: StateContext<OrderRequestStateModel>, action: DeleteOrderAction) {
    ctx.patchState({ loading: true, error: null });
    return this.orderService.deleteOrder(action.orderId).pipe(
      mergeMap((data) => ctx.dispatch(new RemoveOrderResponse(action.orderId))),
      catchError(error => ctx.dispatch(new OrderErrorAction(error)))
    );
  }


  @Action(RemoveOrderResponse)
  handleActionResponse(ctx: StateContext<OrderRequestStateModel>, action: RemoveOrderResponse) {
    const state = ctx.getState();
    ctx.patchState({
      orders: {
        items: state.orders.items.filter(item => item.id !== action.orderId),
        total: state.orders.total - 1
      },
      loading: false
    });
  }

  @Action(UpdateOrderMethod)
  updateOrderMethod(ctx: StateContext<OrderRequestStateModel>, { order }: UpdateOrderMethod) {
    ctx.patchState({ error: null });

    return this.orderService.updateOrderMethod(order.id, order.orderMethod).pipe(
      tap(() => {
        this.notificationService
          .success('Order updated', 'Order method updated', { nzDuration: 4000 });
        const currentOrder = ctx.getState().currentOrder;
        ctx.dispatch(new SetCurrentOrder({ ...currentOrder, orderMethod: order.orderMethod }));
      }),
      catchError(error => ctx.dispatch(new OrderErrorAction(error)))
    );
  }

  @Action(UpdateOrderAction)
  updateOrder(ctx: StateContext<OrderRequestStateModel>, action: UpdateOrderAction) {
    ctx.patchState({ loading: true, error: null });
    const orderUpdate: UpdateOrderRequestModel = {
      ...action.order,
      messageText: action.message,
      messageChannel: action.messageChannel,
      sendMessage: action.sendMessage
    };

    return this.orderService.updateOrder(orderUpdate).pipe(
      mergeMap((data) => ctx.dispatch(new UpdateOrderResponse(orderUpdate))),
      catchError(error => ctx.dispatch(new OrderErrorAction(error))),
      finalize(() => ctx.patchState({ loading: false }))
    );
  }

  @Action(CreateOrderAction)
  createOrder(ctx: StateContext<OrderRequestStateModel>, action: CreateOrderAction) {
    ctx.patchState({ loading: true, error: null });
    const orderUpdate: UpdateOrderRequestModel = {
      ...action.order,
      messageText: action.message,
      messageChannel: action.messageChannel,
      sendMessage: action.sendMessage
    };

    return this.orderService.createOrder(orderUpdate).pipe(
      mergeMap((data) => ctx.dispatch(new CreateOrderResponse(orderUpdate))),
      catchError(error => ctx.dispatch(new OrderErrorAction(error))),
      finalize(() => ctx.patchState({ loading: false }))
    );
  }

  @Action(CreateOrderResponse)
  createOrderResponse(ctx: StateContext<OrderRequestStateModel>, { request }: CreateOrderResponse) {
    if (request) {
      const orders = ctx.getState().orders;

      let title = 'Order created';
      let message = 'Created as ';

      switch (request.status) {
        case OrderRequestStatus.Cancelled: message = message + 'Cancelled '; break;
        case OrderRequestStatus.Pending: message = message + 'Pending '; break;
        case OrderRequestStatus.UnableToFill: message = message + 'Unable to fill '; break;
        case OrderRequestStatus.Ready:
          if (request.orderMethod === OrderMethod.Pickup) {
            message = message + 'Ready to collect ';
          }
          if (request.orderMethod === OrderMethod.Delivery) {
            message = message + 'Scheduled for delivery ';
          }
          break;
        case OrderRequestStatus.PartiallyReady:
          if (request.orderMethod === OrderMethod.Pickup) {
            message = message + 'Partially ready to collect ';
          }
          if (request.orderMethod === OrderMethod.Delivery) {
            message = message + 'Partially scheduled for delivery ';
          }
          break;
        default:
          message = 'Order updated';
          break;
      }
      if (request.messageText) {
        message = message + 'and communication sent to patient';
      }

      ctx.patchState({
        orders: {
          ...orders,
          items: orders.items.map(i => i.id === request.id ? request as unknown as OrderRequest : i)
        }
      });

      this.notificationService
        .success(title, message, { nzDuration: 4000 });
    }
  }

  @Action(UpdateOrderResponse)
  updateOrderResponse(ctx: StateContext<OrderRequestStateModel>, { request }: UpdateOrderResponse) {
    if (request) {
      const orders = ctx.getState().orders;

      const oldOrder = orders.items.filter(x => x.id == request.id)[0];

      let title = 'Order updated';
      let message = 'Order information has been updated.';
      if (oldOrder.status !== request.status) {
        title = 'Order status updated';
        message = 'Updated to ';
        switch (request.status) {
          case OrderRequestStatus.Cancelled: message = message + 'Cancelled '; break;
          case OrderRequestStatus.Pending: message = message + 'Pending '; break;
          case OrderRequestStatus.UnableToFill: message = message + 'Unable to fill '; break;
          case OrderRequestStatus.Ready:
            if (request.orderMethod === OrderMethod.Pickup) {
              message = message + 'Ready to collect ';
            }
            if (request.orderMethod === OrderMethod.Delivery) {
              message = message + 'Scheduled for delivery ';
            }
            if (request.orderMethod == OrderMethod.Undefined) {
              message = message + 'Ready'
            }
            break;
          case OrderRequestStatus.PartiallyReady:
            if (request.orderMethod === OrderMethod.Pickup) {
              message = message + 'Partially ready to collect ';
            }
            if (request.orderMethod === OrderMethod.Delivery) {
              message = message + 'Partially scheduled for delivery ';
            }
            if (request.orderMethod == OrderMethod.Undefined) {
              message = message + 'Partially ready'
            }
            break;
        }
        if (request.messageText) {
          message = message + 'and communication sent to patient';
        }
      }

      ctx.patchState({
        orders: {
          ...orders,
          items: orders.items.map(i => i.id === request.id ? request as unknown as OrderRequest : i)
        },
        currentOrder: request as unknown as OrderRequest
      });
      this.notificationService
        .success(title, message, { nzDuration: 4000 });
    }
  }

  @Action(DispenseScriptsToQueue)
  DispenseEScript(ctx: StateContext<OrderRequestStateModel>, { request }: DispenseScriptsToQueue) {
    ctx.patchState({ dispensing: true });
    return this.scriptService.dispenseEScript(request).pipe(
      tap(res => {
        const order = ctx.getState().currentOrder;
        ctx.dispatch(new SetCurrentOrder({
          ...order,
          orderRequestedItems: order.orderRequestedItems.map(i =>
            request.items.filter(y => y.itemDescription === i.name).length > 0 ?
              ({ ...i, orderRequestItemStatus: OrderRequestItemStatus.Dispensed }) :
              i)
        }));
        this.alertService.success('Scripts successfully pushed to queue!')
      }),
      catchError(err => {
        ctx.patchState({ error: err.error });
        this.alertService.error(JSON.stringify(err.error));
        return of(null);
      }),
      finalize(() => ctx.patchState({ dispensing: false }))
    );
  }

  @Action(SearchOrderStockAction)
  searchStock(ctx: StateContext<OrderRequestStateModel>, { query }: SearchOrderStockAction) {
    ctx.patchState({ additionalItemsLoading: true });
    return this.stockService.pharmacistSearchStock(query).pipe(
      tap((data) => ctx.dispatch(new SetStockListAction(data))),
      catchError((error) => ctx.dispatch(new OrderErrorAction(error))),
      finalize(() => ctx.patchState({ additionalItemsLoading: false }))
    );
  }

  @Action(SetStockListAction)
  setStock(ctx: StateContext<OrderRequestStateModel>, action: SetStockListAction) {
    ctx.patchState({
      searchList: action.items.map((item) => {
        const { name, id, retailPrice, taxInclusive } = item;
        return {
          stockId: id,
          name: name,
          stockItemName: name,
          retailPrice: retailPrice,
          taxIncluded: taxInclusive,
          orderRequestItemType: OrderRequestItemType.OTCItem,
          quantity: 1,
          orderRequestItemStatus: OrderRequestItemStatus.Pending
        } as OrderRequestItem;
      })
    });
  }

  @Action(GetPredictedOrder)
  getPredictedOrder(ctx: StateContext<OrderRequestStateModel>, action: GetPredictedOrder) {
    ctx.patchState({ loading: true });
    const tenantCustomerId = this.store.selectSnapshot(ProfileState.tenantCustomerId);
    const customerProfileId = this.store.selectSnapshot(ProfileState.customerProfileId);
    return this.orderService.getPredictedOrderByClient(action.clientId).pipe(
      tap((data) => ctx.dispatch(new SetCurrentOrder({
        tenantCustomerId: tenantCustomerId,
        orderRequestedItems: data,
        orderMethod: OrderMethod.Undefined,
        customerProfileId: customerProfileId,
      } as OrderRequest))),
      catchError((error) => ctx.dispatch(new OrderErrorAction(error))),
      finalize(() => ctx.patchState({ loading: false }))
    );
  }

  @Action(StripeModalVisibleAction)
  modalVisible(ctx: StateContext<OrderRequestStateModel>, action: StripeModalVisibleAction) {
    ctx.patchState({ stripeModalVisible: action.visible });
  }

  @Action(GeneratePaymentLinkAction)
  generatePaymentLink(ctx: StateContext<OrderRequestStateModel>, action: GeneratePaymentLinkAction) {
    ctx.patchState({ invoiceLoading: true });
    return this.orderService.generatePaymentLink(action.invoiceRequest).pipe(
      tap(hostedUrl => {
        const currentOrder = ctx.getState().currentOrder;
        currentOrder.orderLink = hostedUrl;
        const orders = ctx.getState().orders;

        orders.items.forEach((x) => {
          if (x.id == currentOrder.id) {
            x.orderLink = hostedUrl;
          }
        });

        ctx.patchState({
          orders: orders,
          currentOrder: currentOrder,
          invoiceLoading: false
        });
        ctx.dispatch(new StripeModalVisibleAction(false))
      }),
      catchError(x => ctx.dispatch(new OrderErrorAction(x)))
    );
  }

}




