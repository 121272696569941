import { Component, OnInit, Input } from '@angular/core';
import { accordion } from '../../../../shared/core/animations';

@Component({
  selector: 'app-six-cpa-form-list-item-text',
  templateUrl: './six-cpa-form-list-item-text.component.html',
  styleUrls: ['../six-cpa-form-list-item.component.scss'],
  animations: [ accordion ]
})
export class SixCpaFormListItemTextComponent implements OnInit {
  @Input() formTitle: string;
  @Input() complete =  false;
  showForm = 'out';

  constructor() { }

  toggle(val: string) { return val === 'in' ? 'out' : 'in'; }

  ngOnInit() {
  }

}
