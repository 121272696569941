import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import { ReactiveFormsModule } from '@angular/forms';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { RadioHighlightModule } from '../../../../../shared/components/radio-highlight/radio-highlight.module';
import { HMRPatientDetailsFormComponent, HMRPatientDetailsFormHostDirective } from './patient-details-form/hmr-patient-details-form.components';
import { HMRPatientDetailsFormBasicComponent } from './basic/hmr-patient-details-form-basic.component';
import { HMRPatientDetailsFormDetailedComponent } from './detailed/hmr-patient-details-form-detailed.component';

@NgModule({
    declarations: [
      HMRPatientDetailsFormComponent,
      HMRPatientDetailsFormBasicComponent,
      HMRPatientDetailsFormDetailedComponent,
      HMRPatientDetailsFormHostDirective
    ],
    imports: [
      CommonModule,
      NgxsFormPluginModule,
      ReactiveFormsModule,
      BsDatepickerModule.forRoot(),
      RadioHighlightModule
    ],
    exports: [
      HMRPatientDetailsFormComponent
    ],
    entryComponents: [
      HMRPatientDetailsFormBasicComponent,
      HMRPatientDetailsFormDetailedComponent
    ]
  })
  export class HMRPatientDetailsFormModule { }
