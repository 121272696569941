import { State, Selector, StateContext, Action } from '@ngxs/store';
import { BulkMessage, BulkScheduledMessageStatus, Recipient } from '~/shared/models/communication/bulk-message.model';
import { SetBulkMessageRecipients, ResetBulkMessageRecipients } from './bulk-messaging.actions';
import { Injectable } from '@angular/core';
import { SetNewBulkMessageRecipients } from '../new-bulk-message/state/new-bulk-message-form.actions';
import { MessageSource } from '~/shared/models/messageHistory.model';
import { AlertService } from '~/modules/core/alert/alert.service';

export class BulkMessagingFormStateModel implements BulkMessage {
  id?: number;
  tenantId?: number;
  scheduledFrom: Date | string | null;
  status?: BulkScheduledMessageStatus;
  createdDate: Date | string | null;
  recipients: Recipient[];
  message: string;
  creationSource: MessageSource;
}
@Injectable()
@State<BulkMessagingFormStateModel>({
  name: 'bulkMessagingForm',
  defaults: {
    id: null,
    tenantId: null,
    scheduledFrom: null,
    status: null,
    createdDate: null,
    recipients: [],
    message: null,
    creationSource: MessageSource.Unknown
  }
})
export class BulkMessagingFormState {
  @Selector()
  static bulkMessageForm(state: BulkMessagingFormStateModel) { return state; }

  constructor(private alertService: AlertService) { }

  @Action(SetBulkMessageRecipients)
  setBulkMessageRecipients(ctx: StateContext<BulkMessagingFormStateModel>, { recipients }: SetBulkMessageRecipients) {
    ctx.patchState({
      scheduledFrom: null,
      createdDate: null,
      message: '',
      recipients: recipients
    });
    // Get recipients ready for new bulk message form
    ctx.dispatch(new SetNewBulkMessageRecipients(recipients))
    this.alertService.info('New recipients added to bulk message');
  }

  @Action(ResetBulkMessageRecipients)
  resetBulkMessageRecipients(ctx: StateContext<BulkMessagingFormStateModel>) {
    ctx.patchState({
      scheduledFrom: null,
      createdDate: null,
      message: '',
      recipients: []
    });
  }
}
