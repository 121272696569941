<div class="container">
  <div style="padding-top: 5%;">
      <div class="scrypt">
        <img src="./assets/img/scrypt-vector.svg" /><br />
      </div>
  </div>

  <div style="padding-top: 20%;">
    <div class="account-created">
      Account Created!
    </div>
  </div>

  <div style="padding-top: 5%;">
    <div class="download-blurb">
      Download the Scrypt App on your phone and take control of your medication & prescriptions!
    </div>
  </div>

  <div style="padding-top: 5%;">
    <a [href]="appStoreLink" target="_blank">
      <img class="app-store-img" src="https://scrypt.blob.core.windows.net/icons/app-store[1].png" target=_blank />
    </a>
    <a [href]="playStoreLink" target="_blank">
      <img class="app-store-img" src="https://scrypt.blob.core.windows.net/icons/google-play-badge[1].png"
        target=_blank />
    </a>
  </div>

  <div class="row flex-lg-nowrap" style="margin-left: -15px; padding-top: 15%;">
    <div class="col-sm-4">
      <div class="rectangle-border">
        <div>
          <i class="fal fa-capsules pills"></i>
        </div>
        <div class="icon-header">
          Manage your medication
        </div>
        <div class="icon-description">
          View, organise, and re-order your medication right from your phone.
        </div>
      </div>
    </div>

    <div class="col-sm-4">
      <div class="rectangle-border">
        <div>
          <i class="fal fa-comment-alt-lines message"></i>
        </div>
        <div class="icon-header">
          Communicate with your pharmacist
        </div>
        <div class="icon-description">
          Get direct advice from your pharmacist your way.
        </div>
      </div>
    </div>

    <div class="col-sm-4">
      <div class="rectangle-border">
        <div>
          <i class="fal fa-bell bell"></i>
        </div>
        <div class="icon-header">
          Custom medication reminders
        </div>
        <div class="icon-description">
          Get custom medication dosage reminders. Don’t miss a dosage.
        </div>
      </div>
    </div>
  </div>

  <div class="row footer-scrypt" style="padding-top: 20%; text-align: center;">
    <div class="col">
      info@scrypt.com.au
    </div>
    <div class="col">
      © 2021 Scrypt
    </div>
    <div class="col">
      Privacy Policy
    </div>
  </div>
</div>
