import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router, Resolve } from "@angular/router";
import { Store } from "@ngxs/store";
import { Observable } from "rxjs";
import { GetCustomerSignUp } from "../state/sign-up.actions";

@Injectable()
export class SignUpResolver implements Resolve<Observable<any>> {

  constructor(private router: Router, private store: Store) { }

  resolve(route: ActivatedRouteSnapshot) {
    const { userLink } = route.queryParams;
    if (userLink) {
      return this.store.dispatch(new GetCustomerSignUp(userLink)).map(state => {
        if (state.signUp.error) {
          this.router.navigate(['signup/error']);
        }
      });
    } else {
      this.router.navigate(['signup/error']);
    }
  }
}
