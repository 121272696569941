import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseApi } from '../../../shared/core/base-api/base-api';
import { Router } from '@angular/router';
import { StorageService } from '../../../shared/core/storage/storage.service';
import { AlertService } from '../../../modules/core/alert/alert.service';

@Injectable({
  providedIn: 'root'
})
export class FeaturesService extends BaseApi {

  API = ''

  constructor(
    @Inject('environment') private env: any,
    http: HttpClient,
    router: Router,
    storageService: StorageService,
    alertService: AlertService
    ) {
    super(http, router, storageService, alertService)
    this.API = env.baseApiUrl;
  }

  getLatest(): Observable<any> {
    return this.get(`${this.API}/api/features`);
  }

  getVersion(): Observable<any> {
    return this.get(`${this.API}/api/features/version`);
  }
}
