import { Pipe, PipeTransform } from '@angular/core';
import * as moment_ from 'moment';
const moment = moment_;
import { Note } from '../../../shared/models/crm/note.model';
import { NoteFilter } from './state/notes.state';

@Pipe({
  name: 'appNotesFilter',
  pure: false
})
export class NotesFilterPipe implements PipeTransform {
  transform(noteList: Note[], mode: string, filters?: NoteFilter): Note[] {
    if (filters) {
      noteList = noteList.filter(x =>
        (!filters.fromDate || moment(x.nextActionDate).isAfter(filters.fromDate))
        && (!filters.toDate || moment(x.nextActionDate).isBefore(filters.toDate)));
    }

    const todaysDate = moment(new Date()).format('YYYY-MM-DD');
    switch (mode) {
      /*Any note that requires action AND is not snoozed */
      case 'requires':
        return noteList.filter(note => this.requiresActionAndNotDelayed(note) && !this.hasBeenRemoved(note));

      /* Notes that do not require immediate action AND have not been removed (should be in history if removed) and the next action date is after today */
      case 'upcoming':
        return noteList.filter(note =>
          (
            (
              this.notRemovedAndNoActionsCurrentlyRequired(note) && this.hasNextActionDateAfterToday(note, todaysDate)
            ) ||
            this.isDelayedUntilAfterToday(note, todaysDate)
          ) &&
            !this.hasBeenRemoved(note)
          );

      /* Notes that don't require action AND aren't delayed OR notes that require action but the required action is in the past OR notes that have been removed */
      case 'history':
        return noteList.filter(note =>
            (
              (
                (this.noActionsAndNotDelayed(note) && !this.hasNextActionDateAfterToday(note, todaysDate)) ||
                this.hasActionCycleButHasPreviousActionHistory(note, todaysDate)
              ) ||
              this.hasBeenRemoved(note)
            )
          );
      default:
        return noteList;
    }
  }

  private hasNextActionDateAfterToday(note: Note, today: any) {
    return moment(note.nextActionDate ? moment(note.nextActionDate).format('YYYY-MM-DD') : moment(note.nextActionDate).format('YYYY-MM-DD')).isAfter(today);
  }

  //If the note has been snoozed until a date which is after today then it should also be in upcoming
  private isDelayedUntilAfterToday(note: Note, today: any): boolean {
    return note.delayedUntil != null && moment(note.delayedUntil).isAfter(today);
  }

  private requiresActionAndNotDelayed(note: Note): boolean {
    return note.requiresAction && !note.delayedUntil;
  }

  /* If the note requires action it should be in the requires tab until it's actioned, if it requires an action but has a actioned date of < today
  then pump that last actioned record into the history -  this is how we handle re-occuring actions */
  private hasActionCycleButHasPreviousActionHistory(note: Note, today: any): boolean {
    return note.requiresAction && (note.lastActioned != null && moment(note.lastActioned).isBefore(today));
  }

  private hasBeenRemoved(note: Note): boolean {
    return !!note.removedOn;
  }

  private notRemovedAndNoActionsCurrentlyRequired(note: Note): boolean {
    return !note.requiresAction && !note.removedOn;
  }

  private noActionsAndNotDelayed(note: Note): boolean {
    return !note.requiresAction && !note.delayedUntil
  }
}
