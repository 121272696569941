import { SickLeaveConsulationFormModel } from '../../../../../../shared/models/six-cpa/sick-leave/sick-leave-consultation-form.model';

export enum SickLeaveConsulationActions {
  SET_VALID = '[SickLeaveConsulation] set valid',
  UPDATE = '[SickLeaveConsulation] Update Form',
  UPDATE_PROGRESS = '[SickLeaveConsulation] Update Progress'
}

export class SetSickLeaveConsulationValidAction {
  static readonly type = SickLeaveConsulationActions.SET_VALID;
  constructor(public valid = false) { }
}

export class SetTotalItemsAndCompletedItems {
  static readonly type = SickLeaveConsulationActions.UPDATE_PROGRESS;
  constructor(public totalItems, public completedItems) { }
}

export class UpdateSickLeaveConsulation {
  static readonly type = SickLeaveConsulationActions.UPDATE;
  constructor(public form: Partial<SickLeaveConsulationFormModel>) { }
}
