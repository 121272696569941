import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { State, Action, StateContext, Selector } from '@ngxs/store';
import { catchError, tap, finalize } from 'rxjs/operators';
import { CommunicationOptOut } from '../models/opt-out.model';
import { OptOutConstants } from '../opt-out.constants';
import { OptOutService } from '../services/opt-out.service';
import { ConfirmOptOut, ConfirmOptOutSuccess, VerifyOptOutLink, VerifyOptOutLinkSuccess } from './opt-out.actions';

export class OptOutStateModel {
  optOutRequest: CommunicationOptOut;
  loading: boolean;
  loadingMessage: string;
  error: any;
  optOutLink: any;
}

@Injectable()
@State<OptOutStateModel>({
  name: 'optOut',
  defaults: {
    optOutRequest: null,
    loading: false,
    loadingMessage: null,
    error: null,
    optOutLink: null
  }
})
export class OptOutState {

  constructor(private optOutService: OptOutService, private ngZone: NgZone, private router: Router) { }

  @Selector()
  static optOutstate(state: OptOutStateModel) { return state; }

  @Selector()
  static optOutRequest(state: OptOutStateModel) { return state.optOutRequest; }

  @Action(VerifyOptOutLink)
  verifyOptOutLink(ctx: StateContext<OptOutStateModel>, { link }: VerifyOptOutLink) {
    ctx.patchState({
      loading: true,
      loadingMessage: OptOutConstants.verifyingLinkLoaderMessage
    });

    return this.optOutService.verifyLink(link).pipe(
      tap(resp => ctx.dispatch(new VerifyOptOutLinkSuccess(resp))),
      catchError(err => this.errorHandle(err)),
      finalize(() => ctx.patchState({ loading: false }))
    )
  }

  @Action(VerifyOptOutLinkSuccess)
  verifyOptOutLinkSuccess(ctx: StateContext<OptOutStateModel>, { optOut }: VerifyOptOutLinkSuccess) {
    ctx.patchState({
      optOutRequest: optOut
    });
  }

  @Action(ConfirmOptOut)
  confirmOptOut(ctx: StateContext<OptOutStateModel>, { optOut }: ConfirmOptOut) {
    ctx.patchState({
      loading: true,
      loadingMessage: OptOutConstants.submitLoadingMessage
    });

    return this.optOutService.optOut(optOut).pipe(
      tap(() => ctx.dispatch(new ConfirmOptOutSuccess())),
      catchError(err => this.errorHandle(err)),
      finalize(() => ctx.patchState({ loading: false }))
    )
  }

  @Action(ConfirmOptOutSuccess)
  confirmOptOutSuccess(ctx: StateContext<OptOutStateModel>, { }: ConfirmOptOutSuccess) {
    this.ngZone.run(() => this.router.navigate(['opt-out', 'confirmation']))
  }

  private async errorHandle(error: any) {
    console.log(error);
  }
}
