import { Component, OnInit, Input } from '@angular/core';
import { Sms } from '../../../shared/models/sms.model';

@Component({
  selector: 'app-sms-report-list',
  templateUrl: './sms-report-list.component.html',
  styleUrls: ['./sms-report-list.component.scss']
})
export class SmsReportListComponent implements OnInit {

  constructor() { }

  @Input() smsList: Sms[] = [];

  ngOnInit() {
  }

}
