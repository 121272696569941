import { Component, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { ScryptSmsState } from './state/scrypt-sms.state';
import { Observable } from 'rxjs';
import { ToggleScryptSmsModal, SetSelectedRepeatTemplate, SendRepeatReminder } from './state/scrypt-sms.actions';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { Message } from '../../models/message.model';
import { ProfileState } from '../../../modules/core/profile/state/profile.state';

@Component({
  selector: 'app-scrypt-sms',
  templateUrl: './scrypt-sms.component.html',
  styleUrls: ['./scrypt-sms.component.scss']
})
export class ScryptSmsComponent implements OnInit {
  @Select(ScryptSmsState.scryptSMSState) scryptSMSState$: Observable<any>;
  @Select(ScryptSmsState.isLoading) isLoading$: Observable<boolean>;
  repeatForm: FormGroup;

  constructor(private store: Store, private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.setFormControls();
  }

  private setFormControls(): void {
    const scryptSMSState = this.store.selectSnapshot(ScryptSmsState.scryptSMSState);
    const initialMessage = this.buildMessage(scryptSMSState?.selectedTemplate?.messageTemplate, scryptSMSState.repeatUri);

    this.repeatForm = this.formBuilder.group({
      template: new FormControl(0),
      message: new FormControl(initialMessage, Validators.required)
    });
  }

  closeModal(): void {
    this.store.dispatch(new ToggleScryptSmsModal(false));
  }

  onTemplateChange(): void {
    const templateId =  this.repeatForm.value.template;
    this.store.dispatch(new SetSelectedRepeatTemplate(templateId));
    const template = this.store.selectSnapshot(ScryptSmsState.selectedTemplate);
    const uri = this.store.selectSnapshot(ScryptSmsState.scryptSMSState).repeatUri;
    this.repeatForm.patchValue({
      message: this.buildMessage(template?.messageTemplate, uri)
    });
  }

  buildMessage(template: string, repeatUri: string): string {
    let message: string;

    if (template && template.includes('<<RepeatTokenLink>>')) {
      message = template.replace('<<RepeatTokenLink>>', repeatUri);
    }
    else {
     message = `${template != null ? template + '\r\n' : ''} ${repeatUri}`;
    }

    return message;
  }

  sendRepeat(): void {
    const { message } = this.repeatForm.value;
    const clientId = this.store.selectSnapshot(ProfileState.clientId);
    const repeatMessage =  new Message(
      clientId,
      message
    );

    this.store.dispatch(new SendRepeatReminder(repeatMessage));
  }
}
