import { CharacterCountConstants } from '../constants/character-count.constants';
import { Injectable } from '@angular/core';

@Injectable()
export class CharacterCountService {
  constructor() { }

  getMessageCount(message: string) {
    message = this.pseudoReplaceUrls(message);
    if (!message || message.length == 0) {
      return 1;
    }
    return Math.ceil(message.length / CharacterCountConstants.messageLength);
  }

  getCharacterCount(message) {
    message = this.pseudoReplaceUrls(message);
    return message.length ? message.length : 0;
  }

  private pseudoReplaceUrls(message: string) {
    return message.replace(CharacterCountConstants.urlRegex, (url) => {
      return CharacterCountConstants.exampleShortnenedLink;
    });
  }
}
