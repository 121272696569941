import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SixCpaFormListItemChecklistModule } from '../../six-cpa-form-list-item-checklist/six-cpa-form-list-item-checklist.module';
import { HMRProposedDetailsListItemComponent } from './hmr-proposed-details-list-item.component';
import { HMRProposedDetailsFormModule } from '../hmr-proposed-details/hmr-proposed-details-form.module';

@NgModule({
  declarations: [HMRProposedDetailsListItemComponent],
  imports: [
    CommonModule,
    SixCpaFormListItemChecklistModule,
    HMRProposedDetailsFormModule
  ],
  exports: [HMRProposedDetailsListItemComponent]
})
export class HMRProposedDetailsListItemModule { }
