import { State, Selector, Action, StateContext } from '@ngxs/store';
import { SetProfileForSixCpaAction } from '../../../state/six-cpa.actions';
import { UpdateHMRPatientDetailsForm } from './hmr-patient-details-form.actions';
import * as moment_ from 'moment';
const moment = moment_;
import { TenantDatePipe } from '../../../../../core/timezone/pipes/tenant-date.pipe';
import { getMedicareDVAOrRepatNumber } from '../../../../../../shared/helpers/patient.helper';
import { Injectable } from '@angular/core';

export interface IHMRPatientDetailsFormBasicStateModel {
  medicareNumber: string;
  firstName: string;
  surname: string;
  dob: Date | string | null;
}

export class HMRPatientDetailsFormBasicStateModel {
  patientDetailsForm: {
    model: Partial<IHMRPatientDetailsFormBasicStateModel>;
  };
}

@Injectable()
@State<HMRPatientDetailsFormBasicStateModel>({
  name: 'hmrPatientDetailsFormBasic',
  defaults: {
    patientDetailsForm: {
      model: undefined
    }
  }
})
export class HMRPatientDetailsFormBasicState {
  constructor(private tenantPipe: TenantDatePipe) { }
  @Selector()
  static form(state: HMRPatientDetailsFormBasicStateModel) { return state.patientDetailsForm.model; }

  @Action(SetProfileForSixCpaAction)
  onSetProfile(ctx: StateContext<HMRPatientDetailsFormBasicStateModel>, { profile }: SetProfileForSixCpaAction) {
    const patient = profile.patient;
    ctx.patchState({
      patientDetailsForm: {
        model: {
          medicareNumber: getMedicareDVAOrRepatNumber(patient),
          dob: patient.birthDate && moment(this.tenantPipe.transform(patient.birthDate.toString(), 'YYYY-MM-DD'), 'YYYY-MM-DD').toDate(),
          firstName: patient.firstname,
          surname: patient.surname,
        }
      }
    });
  }

  @Action(UpdateHMRPatientDetailsForm)
  updateForm(ctx: StateContext<HMRPatientDetailsFormBasicStateModel>, { form }: UpdateHMRPatientDetailsForm) {
    if (form) {
      ctx.patchState({
        patientDetailsForm: {
          model: {
            medicareNumber: form.medicareNumber,
            dob: moment(form.dob, 'YYYY-MM-DD').toDate(),
            firstName: form.firstName,
            surname: form.surname,
          }
        }
      });
    } else {
      const state = ctx.getState();
      ctx.patchState({
        patientDetailsForm: {
          model: {
            ...state.patientDetailsForm.model,
          }
        }
      });
    }

  }
}
