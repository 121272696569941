import { State } from '@ngxs/store';
import { Injectable } from '@angular/core';

export class PatientSignatureStateModel {
}
@Injectable()
@State<PatientSignatureStateModel>({
  name: 'patientSignature',
  defaults: {
  }
})
export class PatientSignatureState {
}
