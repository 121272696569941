import { CommTemplate } from '../../../../shared/models/pharmacy/comm-template.model';

export enum CommTemplatesActions {
  GET = '[CommTemplates] get',
  GET_SUCCESS = '[CommTemplates] get success',
  ACTIVATE = '[CommTemplates] activate',
  ACTIVATE_SUCCESS = '[CommTemplates] activate success',
  DEACTIVATE = '[CommTemplates] deactivate',
  DEACTIVATE_SUCCESS = '[CommTemplates] deactivate success',
  FAILED = '[CommTemplates] failed',
}


export class GetCommTemplatesAction {
  static readonly type = CommTemplatesActions.GET;
}

export class GetCommTemplatesSuccessAction {
  static readonly type = CommTemplatesActions.GET_SUCCESS;
  constructor(public commTemplates: CommTemplate[]) { }
}

export class ActivateCommTemplatesAction {
  static readonly type = CommTemplatesActions.ACTIVATE;
  constructor(public id: number) { }

}

export class  ActivateCommTemplatesSuccessAction {
  static readonly type = CommTemplatesActions.ACTIVATE_SUCCESS;
  constructor(public id: number) { }
}

export class DeactivateCommTemplatesAction {
  static readonly type = CommTemplatesActions.DEACTIVATE;
  constructor(public id: number) { }

}

export class  DeactivateCommTemplatesSuccessAction {
  static readonly type = CommTemplatesActions.DEACTIVATE_SUCCESS;
  constructor(public id: number) { }
}

export class CommTemplatesFailedAction {
  static readonly type = CommTemplatesActions.FAILED;
  constructor(public error: any) { }
}
