export class CustomerProfileModal {
    constructor(
        public tenantCustomerId: number = 0,
        public startDate?: Date,
        public intervalValue?: number,
        public repeatType: number = 1,
        public type?: string,
        public groupId: number = 0,
        public orderMethod: number = 0,
        public customerId?: string,
        public firstname?: string,
        public lastname?: string,
        public repeatCycle: boolean = true,
        public contactMethod: number = 0,
        public isAutomation: boolean = true,
        public status: CustomerStatus = CustomerStatus.Active,
        public agreesToTerms: boolean = false,
        public dAACommencementDate?: Date | string | null,
        public lastRepeatDue?: boolean | null,
        public beforeNextRepeatDue?: boolean | null,
        public medicationOverdue?: boolean | null,
        public medicationOverdueOnLastRepeat?: boolean | null,
        public scriptExpiring?: boolean | null,
        public scriptOwing?: boolean | null,
        public indigenousStatus? : IndigenousStatus | null
    ) { }
}

export enum CustomerStatus {
  Active,
  Inactive,
  Deceased,
  Hospital,
  Holiday,
  Unknown
}

export enum IndigenousStatus {
  Unsure,
  Yes,
  No
}



