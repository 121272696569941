import { Directive, ViewContainerRef, Component, OnChanges, Input, ViewChild, Type, ComponentFactoryResolver } from '@angular/core';
import { HasViewContainerRef } from '../../../has-view-container-ref.interface';
import { HMRResolvedForm } from '../../hmr-resolved-form';
import { HMRGPDetailsReferralFormDetailedComponent } from '../detailed/hmr-gp-details-referral-form-detailed.component';
import { HMRGPDetailsReferralFormBasicComponent } from '../basic/hmr-gp-details-referral-form-basic.component';
import { HMRFormType } from '../../hmr-form-list/state/hmr-form.state';
import { HMRGPDetailsReferralFormBasicLightComponent } from '../basic-light/hmr-gp-details-referral-form-basic-light.component';

@Directive({
    selector: '[appHMRGPDetailsReferralFormHost]'
  })
  export class HMRGPDetailsReferralFormHostDirective implements HasViewContainerRef {
    constructor(public viewContainerRef: ViewContainerRef) { }
  }

  @Component({
    selector: 'app-hmr-gp-details-referral-form',
    templateUrl: './hmr-gp-details-referral-form.component.html'
  })
  export class HMRGPDetailsReferralFormComponent extends HMRResolvedForm implements OnChanges {
    @Input() formType: HMRFormType;
    @ViewChild(HMRGPDetailsReferralFormHostDirective, { static: true }) formHost: HMRGPDetailsReferralFormHostDirective;

    constructor(
      componentFactoryResolver: ComponentFactoryResolver
    ) {
      super(componentFactoryResolver);
    }

    ngOnChanges() {
      this.resolveFormComponent(this.formType);
    }

    protected getFormComponent(formType: HMRFormType): Type<any> {
      switch (formType) {
        case HMRFormType.NewClaim:
          return HMRGPDetailsReferralFormDetailedComponent;
          case HMRFormType.InterviewRegisteredPhamacist:
            return HMRGPDetailsReferralFormBasicComponent;
            case HMRFormType.InterviewOutsidePatientHome:
          return HMRGPDetailsReferralFormBasicLightComponent;
      }

    }
  }

