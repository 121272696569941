import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-alert-profile',
    templateUrl: './alert-profile.component.html'
})
export class AlertProfileComponent implements OnInit {
    @Input() customer;
    @Output() close = new EventEmitter();
    @Output() edit = new EventEmitter();

    constructor() {}
    ngOnInit() {}

    onClose() {
        this.close.emit();
    }

    onEdit() {
        this.edit.emit();
    }
}
