import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SSAdditionalDetailsFormComponent } from './ss-additional-details-form.component';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import { ReactiveFormsModule } from '@angular/forms';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';

@NgModule({
  declarations: [
    SSAdditionalDetailsFormComponent
  ],
  imports: [
    CommonModule,
    NgxsFormPluginModule,
    ReactiveFormsModule,
    BsDatepickerModule.forRoot()
  ],
  exports: [
    SSAdditionalDetailsFormComponent
  ]
})
export class SSAdditionalDetailsModule { }
