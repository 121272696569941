import { Component, OnInit } from "@angular/core";
import { accordion } from "~/shared/core/animations";
import { FormGroup, FormBuilder, FormControl } from "@angular/forms";
import * as moment from "moment";
import { Store, Select } from '@ngxs/store';
import { GetAdvancedSearchResults } from './state/advanced-search.actions';
import { GetNursingHomes } from '~/shared/nursing-homes/state/nursing-homes.actions';
import { NursingHomeState } from '~/shared/nursing-homes/state/nursing-homes.state';
import { Observable } from 'rxjs';
import { NursingHome } from '~/shared/nursing-homes/models/nursing-home';
import { FormControlFunctions } from "~/modules/profile/six-cpa/form-control.functions";
import { AdvancedSearchState } from "./state/advanced-search.state";


@Component({
  selector: "app-advanced-search",
  templateUrl: "./advanced-search.component.html",
  styleUrls: ["./advanced-search.component.scss"],
  animations: [accordion]
})
export class AdvancedSearchComponent implements OnInit {
  @Select(NursingHomeState.nursingHomes) nursingHomes$: Observable<NursingHome[]>;
  @Select(NursingHomeState.loading) nursingHomeLoading$: Observable<boolean>;
  @Select(AdvancedSearchState.loading) isLoading$: Observable<boolean>;
  
  personalDetailsAccordion = "in";
  medicalDetailsAccordion = "out";
  systemDetailsAccordion = "out";
  hasMobileNumber: boolean = false;
  advancedSearchForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private store: Store,
    private formControlFunctions: FormControlFunctions
  ) { }

  ngOnInit() {
    this.generateFormControls();
    this.store.dispatch(new GetNursingHomes());
  }

  generateFormControls() {
    this.advancedSearchForm = this.formBuilder.group({
      firstName: new FormControl(''),
      genders: new FormControl(''),
      surname: new FormControl(''),
      birthDate: new FormControl(''),
      emailAddress: new FormControl(''),
      mobileNumber: new FormControl(''),
      hasMobile: new FormControl(false),
      repeatsHeld: new FormControl(false),
      homeAddress: new FormControl(''),
      homeSuburb: new FormControl(''),
      homeState: new FormControl(''),
      postcodeFrom: new FormControl(''),
      postcodeTo: new FormControl(''),
      nursingHomeIds: new FormControl(''),
      medicareNo: new FormControl(''),
      repatNo: new FormControl(''),
      concessions: new FormControl(''),
      safetyNetEntitlementNo: new FormControl(''),
      createdFrom: new FormControl(''),
      createdTo: new FormControl(''),
      ageFrom: new FormControl(''),
      ageTo: new FormControl(''),
      lastDispenseFrom: new FormControl(''),
      lastDispenseTo: new FormControl(''),
      whoopingVax: new FormControl(false),
      isNotDeceased: new FormControl(true)
    });
    this.advancedSearchForm.setValidators(this.formControlFunctions.requiresNFieldsNotNullValidator());

  }

  toggleMedicalDetailsAccordion() {
    this.medicalDetailsAccordion =
      this.medicalDetailsAccordion === "in" ? "out" : "in";
  }

  togglePersonalDetailsAccordion() {
    this.personalDetailsAccordion =
      this.personalDetailsAccordion === "in" ? "out" : "in";
  }

  toggleSystemDetailsAccordion() {
    this.systemDetailsAccordion =
      this.systemDetailsAccordion === "in" ? "out" : "in";
  }

  stopPropagation(event){
    event.stopPropagation();
    return false;
  }

  submitSearch() {
    const formValues = this.advancedSearchForm.value;
    const queryValues = {
      ...formValues,
      birthDate: formValues.birthDate
        ? moment(formValues.birthDate).format("YYYY-MM-DD")
        : null,
      createdFrom: formValues.createdFrom
        ? moment(formValues.createdFrom).format("YYYY-MM-DD")
        : null,
      createdTo: formValues.createdTo
        ? moment(formValues.createdTo).format("YYYY-MM-DD")
        : null,
      lastDispenseFrom: formValues.lastDispenseFrom
        ? moment(formValues.lastDispenseFrom).format("YYYY-MM-DD")
        : null,
      lastDispenseTo: formValues.lastDispenseTo
        ? moment(formValues.lastDispenseTo).format("YYYY-MM-DD")
        : null,
      isDeceased: !formValues.isNotDeceased 
    };

    this.store.dispatch(new GetAdvancedSearchResults(queryValues));
  }

  clearForm() {
    this.advancedSearchForm.reset();
  }
}
