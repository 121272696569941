import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SixCpaComponent } from './six-cpa.component';
import { NgxsModule } from '@ngxs/store';
import { SixCpaState } from './state/six-cpa.state';
import { AddEditMedicationModule } from './add-edit-medication/add-edit-medication.module';
import { SixCpaFormListItemChecklistModule } from './six-cpa-form-list-item-checklist/six-cpa-form-list-item-checklist.module';
import { SixCpaFormListItemTextModule } from './six-cpa-form-list-item-text/six-cpa-form-list-item-text.module';
import { MedsCheckModule } from './meds-check/meds-check.module';
import { ClinicalInterventionModule } from './clinical-intervention/clinical-intervention.module';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ConfirmMedsCheckModule } from './confirm-meds-check/confirm-meds-check.module';
import { ConfirmFormModule } from './confirm-form/confirm-form.module';
import { DoseAdministrationAidsModule } from './DAA/dose-administration-aids/dose-administration-aids.module';
import { SickLeaveModule } from './sick-leave/sick-leave-form-list/sick-leave.module';
import { StagedSupplyModule } from './Staged-Supply/staged-supply/staged-supply.module';
import { SickLeaveConfirmModule } from './sick-leave/sick-leave-confirm/sick-leave-confirm.module';
import { HMRFormListModule } from './HMR/hmr-form-list/hmr-form-list.module';
import { MedsCheckConfirmModalModule } from './meds-check/meds-check-confirm-modal/meds-check-confirm-modal.module';
import { SixCpaUserCommunicationsModule } from './six-cpa-user-communications/six-cpa-user-communications.module';
import { SixCpaUserCommunicationsState } from './six-cpa-user-communications/state/six-cpa-user-communications.state';
import { LoaderModule } from '../../core/loader/loader.module';
import { HmrConfirmFormModule } from './HMR/hmr-form-list/hmr-confirm-form/hmr-confirm-form.module';
import { MedicationListFormListModule } from './medication-list/medication-list-form-list/medication-list-form-list.module';
import { MedicationListConfirmModule } from './medication-list/medication-list-confirm/medication-list-confirm.module';
import { AirLookupFormModule } from './AIR/air-lookup-form/air-lookup-form.module';
import { AIRStatementModule } from './AIR/air-statement/air-statement.module';
import { MedsCheckSendDocumentModalModule } from './meds-check/meds-check-send-document-modal/meds-check-send-document-modal.module';
import { MedsCheckSavePdfModalModule } from './meds-check/meds-check-save-pdf-modal/meds-check-save-pdf-modal.module';
import { UnlockServicesModule } from './unlock-services/unlock-services.module';


@NgModule({
  declarations: [
    SixCpaComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NgxsModule.forFeature([
      SixCpaState,
      SixCpaUserCommunicationsState,
    ]),
    AddEditMedicationModule,
    SixCpaFormListItemChecklistModule,
    SixCpaFormListItemTextModule,
    MedsCheckModule,
    ClinicalInterventionModule,
    ConfirmMedsCheckModule,
    NgxsFormPluginModule,
    ConfirmFormModule,
    FormsModule,
    DoseAdministrationAidsModule,
    SickLeaveModule,
    StagedSupplyModule,
    SickLeaveConfirmModule,
    HMRFormListModule,
    MedsCheckConfirmModalModule,
    MedsCheckSendDocumentModalModule,
    MedsCheckSavePdfModalModule,
    LoaderModule,
    HmrConfirmFormModule,
    SixCpaUserCommunicationsModule,
    MedicationListFormListModule,
    MedicationListConfirmModule,
    AirLookupFormModule,
    AIRStatementModule,
    UnlockServicesModule
  ],
  exports: [
    SixCpaComponent,
  ]
})
export class SixCpaModule { }
