<form [formGroup]="hmrPharmacyInformationForm" ngxsForm="hmrPharmacyInformation.hmrPharmacyInformationForm" class="p-4">
  <ng-container *ngIf="(pharmacists$ | async) as pharmacists">
    <div class="form-group pb-1">
      <label for="pharmacist" class="input-label">Select Pharmacist</label>
      <select formControlName="pharmacist" [class.has-error]="formControls.pharmacist.invalid && (showInvalid$ | async)"
        class="form-control form-control-lg w-50" (change)="setPharmacistData()">
        <option *ngFor="let p of pharmacists" value="{{p.givenNames}} {{p.familyName}}">{{p.givenNames}}
          {{p.familyName}}</option>
      </select>
    </div>
  </ng-container>
  <div class="form-group pb-1">
    <label class="input-label" for="accreditationNumber">MRN or SHPA Accreditation Number</label>
    <input type="text" [class.has-error]="formControls.accreditationNumber.invalid && (showInvalid$ | async)"
      class="form-control form-control-lg w-25" formControlName="accreditationNumber" value="" id="accreditationNumber">
  </div>
  <div class="form-group pb-1">
    <label class="input-label" for="pharmacistGivenName">Pharmacist Given Name(s)</label>
    <input type="text" [class.has-error]="formControls.pharmacistGivenName.invalid && (showInvalid$ | async)"
      class="form-control form-control-lg w-25" formControlName="pharmacistGivenName" value="" id="pharmacistGivenName"
      readonly>
  </div>
  <div class="form-group pb-1">
    <label class="input-label" for="pharmacistFamilyName">Pharmacist Family Name</label>
    <input type="text" [class.has-error]="formControls.pharmacistFamilyName.invalid && (showInvalid$ | async)"
      class="form-control form-control-lg w-25" formControlName="pharmacistFamilyName" value=""
      id="pharmacistFamilyName" readonly>
  </div>
  <div class="form-group pb-1">
    <label for="dateOfService" class="input-label">Date of Service</label>
    <input type="text" [class.has-error]="formControls.dateOfService.invalid && (showInvalid$ | async)"
      class="form-control form-control-lg w-25"
      value="hmrPharmacyInformationForm.value.dateOfService | date: 'DD-MM-YYYY'" formControlName="dateOfService"
      id="dateOfService" [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY' }" placeholder="dd-mm-yyyy" bsDatepicker>
  </div>
  <div class="form-group pb-1">
    <label for="dateCompletedReportSentToGP" class="input-label">Date completed report sent to GP</label>
    <input type="text" [class.has-error]="formControls.dateCompletedReportSentToGP.invalid && (showInvalid$ | async)"
      class="form-control form-control-lg w-25"
      value="hmrPharmacyInformationForm.value.dateCompletedReportSentToGP | date: 'DD-MM-YYYY'"
      formControlName="dateCompletedReportSentToGP" id="dateCompletedReportSentToGP"
      [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY' }" placeholder="dd-mm-yyyy" bsDatepicker>
  </div>
</form>