import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Store } from '@ngxs/store';
import { MobileState } from '../../../../../profile/details/mobile-state-modal/state/mobile-state-modal.state';
import { ChannelPreference } from '../../../../../../shared/models/pharmacy/pharmacy-comms-details.model';
import { ProfileState } from '../../../../../../modules/core/profile/state/profile.state';

@Component({
  selector: 'app-send-document-modal',
  templateUrl: './send-document-modal.component.html',
  styleUrls: ['./send-document-modal.component.scss']
})
export class SendDocumentModalComponent implements OnInit {

  @Output() submitEvent = new EventEmitter<any>();
  @Output() closeEvent = new EventEmitter<any>();
  commsType = ChannelPreference;
  sendDocForm: FormGroup;
  profile: any;
  mobileStatus = MobileState;

  constructor(public fb: FormBuilder, private store: Store){}

  ngOnInit() {
    this.initForm();
  }

  private initForm() {
    this.profile = this.store.selectSnapshot(ProfileState.profile);
    this.sendDocForm = this.fb.group(
      {
        commsType: this.fb.control(ChannelPreference.Email),
        email: this.fb.control(this.profile.patient.emailAddress),
        mobile: this.fb.control(this.profile.patient.mobileNumber)
      }
    );
  }

  close() {
    this.closeEvent.emit();
  }

  submit() {
    this.submitEvent.emit();
  }
}
