import { NgModule } from '@angular/core';
import { DAAPatientFormListItemComponent } from './daa-patient-form-list-item.component';
import { CommonModule } from '@angular/common';
import { SixCpaFormListItemChecklistModule } from '../../six-cpa-form-list-item-checklist/six-cpa-form-list-item-checklist.module';
import { DAAPatientDetailsFormModule } from '../daa-patient-details-form/daa-patient-details-form.module';

@NgModule({
  declarations: [
    DAAPatientFormListItemComponent
  ],
  imports: [
    CommonModule,
    SixCpaFormListItemChecklistModule,
    DAAPatientDetailsFormModule
  ],
  exports: [
    DAAPatientFormListItemComponent
  ]
})
export class DAAPatientFormListItemModule { }
