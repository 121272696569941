import { MedicationListFormModel } from '../../../../../../shared/models/six-cpa/medication-list/medication-list-form.model';

export enum MedicationListActions {
  RESET = '[MedicationList] Reset',
  GENERATE = '[MedicationList] Generate List',
  OPEN_MEDICATION_LIST_CONFIRM_FORM = '[MedicationList] Open Confirm Form',
  CLOSE_MEDICATION_LIST_CONFIRM_FORM = '[MedicationList] Close Confirm Form',
  RESET_STATE = '[MedicationList] Reset State',
  SUBMIT = '[MedicationList] Submit',
  UPDATE_SUBMIT = '[MedicationList] Update Submit',
  SAVE_SUCCESS = '[MedicationList] Save Success',
  SAVE_FAILED = '[MedicationList] Save Failed',
  GET = '[MedicationList] Get',
  GET_SUCCESS = '[MedicationList] On Get Success',
  GET_ERROR = '[MedicationList]  On Get Error',
  UPDATE_FORM = '[MedicationList]  Update Form'

}

export class SubmitNewMedicationList {
  static readonly type = MedicationListActions.SUBMIT;
  constructor(public clientId: string, public formData: MedicationListFormModel) { }
}

export class SubmitExisitingMedicationList {
  static readonly type = MedicationListActions.UPDATE_SUBMIT;
  constructor(public clientId: string, public formData: MedicationListFormModel) { }
}
export class SaveMedicationListFormSuccessAction {
  static readonly type = MedicationListActions.SAVE_SUCCESS;
  constructor(public formData: MedicationListFormModel) { }
}

export class SaveMedicationListFormFailedAction {
  static readonly type = MedicationListActions.SAVE_FAILED;
  constructor(public errors: any) { }
}

export class GetMedicationListForm {
  static readonly type = MedicationListActions.GET;
  constructor(public clientId: string, public sixCpaFormId: number) { }
}

export class GetMedicationListFormSuccess {
  static readonly type = MedicationListActions.GET_SUCCESS;
  constructor(public formData: MedicationListFormModel) { }
}
export class GetMedicationListFormError {
  static readonly type = MedicationListActions.GET_ERROR;
  constructor(public errors: any) { }
}

export class GenerateMedicationListForm {
  static readonly type = MedicationListActions.GENERATE;
  constructor(public formData: MedicationListFormModel) { }
}

export class ResetMedicationListFormListAction {
  static readonly type = MedicationListActions.RESET;
  constructor(public onlyClearFormIds: boolean = false) { }
}

export class ResetMedicationListFormStateToDefaults {
  static readonly type = MedicationListActions.RESET_STATE;
  constructor(public onlyClearFormIds: boolean = false) { }
}


export class UpdateMedicationListForm {
  static readonly type = MedicationListActions.UPDATE_FORM;
  constructor(public medicationListForm: MedicationListFormModel) { }
}
