import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NzTableModule } from 'ng-zorro-antd/table';
import { LoaderModule } from '~/modules/core/loader/loader.module';
import { TimezoneModule } from '~/modules/core/timezone/timezone.module';
import { OrderManagementComponent } from './order-mgmt.component';
import { OrderService } from '~/modules/profile/order-requests/services/order.service';
import { CrmNotificationDateModule } from '~/modules/crm/crm-notification-date/crm-notification-date.module';
import { NgxsModule } from '@ngxs/store';
import { OrderManagementState } from './state/order-mgmt.state';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { StatusSelectorComponent } from './components/status-selector/status-selector.component';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { FormsModule } from '@angular/forms';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { OrderRequestState } from '~/modules/profile/order-requests/state/order.state';
import { StockService } from '~/modules/order/services/stock.service';
import { NzNotificationModule } from 'ng-zorro-antd/notification';
import { NzBackTopModule  } from 'ng-zorro-antd/back-top';
import { RouterModule } from '@angular/router';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { SendToQueueButtonComponent } from './components/send-to-queue-button/send-to-queue-button.component';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { OrderRequestItemsTableComponent } from './components/order-request-items-table/order-request-items-table.component';
import { OrderRequestTableComponent } from './components/order-request-table/order-request-table.component';
import { HttpClientModule } from '@angular/common/http';
import { NzInputModule } from 'ng-zorro-antd/input';
import { OrderManagementRoutingModule } from './order-mgmt-routing.module';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { TenantDatePipe } from '@base/modules/core/timezone/pipes/tenant-date.pipe';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { OrderManagementTableHeadingsComponent } from './components/table-headings/table-headings.component';
import { PipesModule } from '@base/shared/pipes/pipes.module';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { TabStatusFilterComponent } from './components/tab-status-filter/tab-status-filter.component';
import { AddressTooltip } from './components/address-tooltip/address-tooltip.component';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { ImageModalModule } from '@profile/order-requests/components/image-modal/image-modal.module';

@NgModule({
    declarations: [
        OrderManagementComponent,
        OrderRequestTableComponent,
        OrderRequestItemsTableComponent,
        OrderManagementTableHeadingsComponent,
        StatusSelectorComponent,
        SendToQueueButtonComponent,
        TabStatusFilterComponent,
        AddressTooltip
    ],
    imports: [
        CommonModule,
        LoaderModule,
        FormsModule,
        RouterModule,
        OrderManagementRoutingModule,
        NzTableModule,
        NzPaginationModule,
        NzAlertModule,
        NzSelectModule,
        NzDropDownModule,
        NzDividerModule,
        NzModalModule,
        NzNotificationModule,
        NzBackTopModule,
        NzToolTipModule,
        NzSpinModule,
        NzIconModule,
        NzCheckboxModule,
        NzInputModule,
        NzInputNumberModule,
        NzButtonModule,
        NzDatePickerModule,
        TimezoneModule,
        CrmNotificationDateModule,
        HttpClientModule,
        PipesModule,
        NgxsModule.forFeature([
            OrderManagementState,
            OrderRequestState
        ]),
        NzBadgeModule,
        NzTagModule,
        ImageModalModule
    ],
    exports: [
        OrderManagementComponent
    ],
    providers: [
        OrderService,
        StockService,
        TenantDatePipe
    ]
  })
  export class OrderManagementModule { }
