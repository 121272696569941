import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { Store, Select } from '@ngxs/store';
import { ToggleEScriptModal, DispenseEScript } from '../state/escripts.actions';
import { EscriptsState, EscriptsStateModel } from '../state/escripts.state';
import { Observable, Subscription } from 'rxjs';
import {  QueueDispenseRequestModel } from '../../../models/dispense/queueDispenseRequest.model';
import { accordion } from '../../../../shared/core/animations';
import { EScriptOptions } from '../../../../shared/models/six-cpa/FormKeyValuePairs';
import { FormControlFunctions } from '../../../../modules/profile/six-cpa/form-control.functions';
import * as moment_ from 'moment';
import { DispenseItemModel, EScriptType, ScriptSource } from '@base/shared/models/dispense/dispenseItem.model';
const moment = moment_;

@Component({
  selector: 'app-e-script-modal',
  templateUrl: './e-script-modal.component.html',
  styleUrls: ['./e-script-modal.component.scss'],
  animations: [accordion]
})
export class EScriptModalComponent implements OnInit, OnDestroy {
  @Select(EscriptsState.eScriptState) eScriptState$: Observable<EscriptsStateModel>;
  @Input() isZCustomer = false;
  eScriptForm: FormGroup;
  scriptSource = ScriptSource;
  showScanner = false;
  devicesFound = [];
  scannerForm: FormGroup;
  currentDevice = null;
  eScriptOptions = 'out';
  eScriptPickup = 'out';
  additionaloptions = EScriptOptions;
  eScriptTypes = EScriptType;
  minDate = moment(new Date()).toDate();
  tags = [];
  scannerEnabled = false;
  tokenSubscription: Subscription;
  mediaStream: MediaStream;

  constructor(private formBuilder: FormBuilder, private store: Store, private formFunctions: FormControlFunctions) { }

  ngOnInit() {
    this.initform();
    this.setTags();
    this.setToken();
  }

  ngOnDestroy() {
    this.scannerEnabledAndVisable(false);
    this.stopVideo();
    if (this.tokenSubscription) { this.tokenSubscription.unsubscribe(); }
  }

  private setTags() {
    this.tags.push(
      { name: '30 min', value: 30, isMinutes: true },
      { name: '1 hour', value: 60, isMinutes: true },
      { name: '2 hours', value: 120, isMinutes: true },
      { name: '3 hours', value: 180, isMinutes: true },
    );
  }

  private setToken() {
    this.tokenSubscription = this.eScriptState$
      .subscribe(state => {
        if (state.token != null) {
          this.eScriptForm.patchValue({
            scriptToken: state.token
          });
        }
      });
  }

  private getUserMedia() {
    const constraints = {
      video: true,
    };

    navigator.mediaDevices.getUserMedia(constraints).then(stream => {
      this.mediaStream = stream;
    })
  }

  private stopVideo() {
    if (this.mediaStream) {
      this.mediaStream.getTracks().forEach((track) => {
        track.stop();
      });
    }
  }

  private initform() {
    this.eScriptForm = this.formBuilder.group({
      scriptToken: new FormControl(null, [Validators.required]),
      patient: new FormControl(''),
      notes: new FormControl(''),
      source: new FormControl(ScriptSource.ETP1, Validators.required),
      defer: new FormControl(false),
      options: new FormGroup({}),
      type: new FormControl(EScriptType.EScript, Validators.required),
      pickupTime: new FormControl(null),
      pickupDate: new FormControl(null),
      pickupInterval: new FormControl(null)
    });

    this.formFunctions.generateControlsForGroupFromArrWithoutValidators('options', this.additionaloptions, this.eScriptForm);
    this.scannerForm = this.formBuilder.group({
      chosenDeviceId: new FormControl('')
    });
  }

  setPickupTime(minutes: number) {
    const timeToSet = moment(new Date()).add(minutes, 'minutes').toDate();
    this.eScriptForm.patchValue({
      pickupDate: moment(new Date()).toDate(),
      pickupTime: `${timeToSet.getHours()}:${timeToSet.getMinutes()}`,
    });
  }

  closeScanner() {
    this.scannerEnabledAndVisable(false);
    this.stopVideo();
  }

  openScanner() {
    this.scannerEnabledAndVisable(true);
    this.getUserMedia();
  }

  toggleEScriptOptions() {
    this.eScriptOptions = this.eScriptOptions === "in" ? "out" : "in";
  }

  toggleEScriptPickup() {
    this.eScriptPickup = this.eScriptPickup === "in" ? "out" : "in";
  }

  dispense() {
    this.store.dispatch(new DispenseEScript(this.buildRequest()));
  }

  closeModal() {
    this.patchToDefaults();
    this.scannerEnabledAndVisable(false);
    this.stopVideo();
    this.store.dispatch(new ToggleEScriptModal(false));
  }

  scanSuccess(event: any) {
    this.closeScanner();
    this.eScriptForm.patchValue({
      scriptToken: event
    });
  }

  scanDevices(event: any) {
    this.devicesFound = event;
    this.currentDevice = this.devicesFound[0];
  }

  toggleCamera() {
    const chosenDevice = this.devicesFound.find(device => device.deviceId == this.scannerForm.value.chosenDeviceId);
    this.currentDevice = chosenDevice != null ? chosenDevice : null;
  }

  private buildRequest(): QueueDispenseRequestModel {
    let dispenseItem: DispenseItemModel = {
      defer: this.eScriptForm.value.defer,
      generic: this.eScriptForm.value.options.Generic,
      cMI: this.eScriptForm.value.options.CMI,
      scriptToken: this.eScriptForm.value.scriptToken,
      scriptId: this.eScriptForm.value.scriptId,
      scriptNo: this.eScriptForm.value.scriptNo,
      isPaperless: this.eScriptForm.value.type === this.eScriptTypes.EScript
    }
    return {
      patient: this.eScriptForm.value.patient,
      notes: this.eScriptForm.value.notes,
      pickupDate: this.getPickupDate(),
      items: [dispenseItem]
    }
  }

  private getPickupDate() {
    const { pickupDate } = this.eScriptForm.value;
    const { pickupTime } = this.eScriptForm.value;
    if ((pickupDate != null || pickupDate == '') || (pickupTime != null || pickupTime == '')) {
      return new Date(`${moment(pickupDate).format('LL')} ${pickupTime}`);
    }

    return null;
  }

  private patchToDefaults() {
    this.eScriptForm.patchValue({
      scriptToken: '',
      pickupDate: '',
      pickupTime: null,
      source: ScriptSource.ETP1,
      defer: false,
      type: EScriptType.EScript
    });
  }

  private scannerEnabledAndVisable(enabled: boolean) {
    this.scannerEnabled = enabled;
    this.showScanner = enabled;
  }
}
