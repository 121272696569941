import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';
import { HMRPatientDetailsFormState } from './state/hmr-patient-details-form.state';
import { HMRPatientDetailsFormBasicState } from './state/hmr-patient-details-form-basic.state';
import { HMRPatientDetailsFormDetailedState } from './state/hmr-patient-details-form-detailed.state';

@NgModule({
  imports: [
    NgxsModule.forFeature([
        HMRPatientDetailsFormState,
        HMRPatientDetailsFormDetailedState,
        HMRPatientDetailsFormBasicState
        ]),
  ]
})
export class HMRPatientDetailsStateModule { }
