import { State, Action, StateContext, Selector } from '@ngxs/store';
import { HMRAccreditedPharmacistDetailsFormModel } from '../../../../../../shared/models/six-cpa/HMR/hmr-accredited-pharmacist-details-form.model';
import { SetHMRAccreditedPharmacistDetailsValidAction, SetTotalItemsAndCompletedItems, UpdateHMRAccreditedPharmacistDetails } from './hmr-accredited-pharmacist-details.actions';
import { Injectable } from '@angular/core';

export class HMRAccreditedPharmacistDetailsStateModel {
  hmrAccreditedPharmacistDetailsForm: {
    model: Partial<HMRAccreditedPharmacistDetailsFormModel>
  };
  valid: boolean;
  totalItems: number;
  completedItems: number;
}
@Injectable()
@State<HMRAccreditedPharmacistDetailsStateModel>({
  name: 'hmrAccreditedPharmacistDetails',
  defaults: {
    hmrAccreditedPharmacistDetailsForm: {
      model: undefined
    },
    valid: false,
    completedItems: 0,
    totalItems: 0
  }
})
export class HMRAccreditedPharmacistDetailsState {
  @Selector()
  static hmrAccreditedPharmacistDetailsForm(state: HMRAccreditedPharmacistDetailsStateModel) { return state.hmrAccreditedPharmacistDetailsForm.model; }

  @Selector()
  static totalItems(state: HMRAccreditedPharmacistDetailsStateModel) { return state.totalItems; }

  @Selector()
  static completedItems(state: HMRAccreditedPharmacistDetailsStateModel) { return state.completedItems; }

  @Action(SetHMRAccreditedPharmacistDetailsValidAction)
  setValid(ctx: StateContext<HMRAccreditedPharmacistDetailsStateModel>, action: SetHMRAccreditedPharmacistDetailsValidAction) {
    ctx.patchState({
      valid: action.valid
    });
  }

  @Action(SetTotalItemsAndCompletedItems)
  setProgress(ctx: StateContext<HMRAccreditedPharmacistDetailsStateModel>, action: SetTotalItemsAndCompletedItems) {
    ctx.patchState({
      totalItems: action.totalItems,
      completedItems: action.completedItems
    });
  }

  @Action(UpdateHMRAccreditedPharmacistDetails)
  updateForm(ctx: StateContext<HMRAccreditedPharmacistDetailsStateModel>, { form }: UpdateHMRAccreditedPharmacistDetails) {
    ctx.patchState({
      hmrAccreditedPharmacistDetailsForm: {
        model: form || undefined
      }
    });
  }
}
