import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import { BaseApi } from '~/shared/core/base-api/base-api';
import { environment } from '../../../../environments/environment';
import { Router } from '@angular/router';
import { StorageService } from '~/shared/core/storage/storage.service';
import { Client } from '~/shared/models/client.model';
import { AlertService } from '~/modules/core/alert/alert.service';
import { HttpClient } from '@angular/common/http';
import { MobileUserView } from '~/shared/models/profile/mobile-user-view.model';
import { OptOutUpdateModal } from '~/modules/profile/primary-contact/models/primary-contact-summary.model';

const API = environment.baseApiUrl;

@Injectable()
export class ClientRepository extends BaseApi {
  constructor(public http: HttpClient, public router: Router, public storageService: StorageService, public alertService: AlertService) {
    super(http, router, storageService, alertService);
  }

  getClientSearch(lastName: string, firstName: string, phoneNumber: string) {
    return this.get(`${API}/api/GetClientSearchList?firstname=${firstName}&lastname=${lastName}&phoneNumber=${phoneNumber}`);
  }

  getClientInfo(clientId: string): Observable<Client> {
    return this.get(`${API}/api/GetClientInfo/${clientId}`);
  }

  getCustomers(count: number): Observable<any[]> {
    return this.get(`${API}/api/leads/latest?count=${count}`);
  }

  getSearchClient(name: string): Observable<any[]> {
    return this.get(`${API}/api/leads/latest?count=${name}`);
  }

  getProfile(clientId: string): Observable<any> {
    return this.get(`${API}/api/client/getProfile/${clientId}`);
  }

  getMobileProfile(tenantCustomerId: number): Observable<MobileUserView> {
    return this.get<MobileUserView>(`${API}/api/client/${tenantCustomerId}/mobile`);
  }

  optOutLookup(MobileNumber: string): Observable<any> {
    return this.get(`${API}/api/OptOut/lookup?mobileNumber=${MobileNumber}`);
  }

  optOutUpdate(optOutModal: OptOutUpdateModal): Observable<any> {
    return this.post(`${API}/api/OptOut/update`, optOutModal);
  }

}
