import { DAAPatientDetailsFormState } from "../../daa-patient-details-form/state/daa-patient-details-form.state";
import { Selector } from "@ngxs/store";
import { DoseAdministrationAidsState, DAAFormType } from "./dose-administration-aids.state";
import { DAAMedicationInformationFormState } from "../../daa-medication-information-form/state/daa-medication-information-form.state";
import { DAAClassificationsRecommendationsState } from "../../daa-classifications-recommendations/state/daa-classifications-recommendations.state";
import { DAAAdditionalDetailsFormState } from "../../daa-additional-details-form/state/daa-additional-details-form.state";
import { DAAConsentAndEligibilityFormState } from "../../daa-consent-and-eligibility-form/state/daa-consent-and-eligibility-form.state";
import { DAAMedicationProfileState } from "../../daa-medication-profile/state/daa-medication-profile.state";

export class DAAFormsProgressService {
  @Selector([
    DAAPatientDetailsFormState.totalItems,
    DAAPatientDetailsFormState.completedItems,
    DAAMedicationInformationFormState.totalItems,
    DAAMedicationInformationFormState.completedItems,
    DAAClassificationsRecommendationsState.totalItems,
    DAAClassificationsRecommendationsState.completedItems,
    DAAAdditionalDetailsFormState.totalItems,
    DAAAdditionalDetailsFormState.completedItems,
    DAAConsentAndEligibilityFormState.totalItems,
    DAAConsentAndEligibilityFormState.completedItems,
    DAAMedicationProfileState.selectedMedicineCount
  ])
  static getDAARegistrationProgress(
    patientDetailsTotal,
    patientDetailsCompleted,
    medicationInformationTotal,
    medicationInformationCompleted,
    classificationsRecommendationsTotal,
    classificationsRecommendationsCompleted,
    additionalDetailsTotal,
    additionalDetailsCompleted,
    consentAndEligibilityTotal,
    consentAndEligibilityCompleted,
    selectedMedicines
  ) {
    return Math.floor((patientDetailsCompleted +
      medicationInformationCompleted +
      classificationsRecommendationsCompleted +
      additionalDetailsCompleted +
      consentAndEligibilityCompleted +
      (selectedMedicines > 0 ? 1 : 0)) /
      (patientDetailsTotal +
        medicationInformationTotal +
        classificationsRecommendationsTotal +
        additionalDetailsTotal +
        consentAndEligibilityTotal + 1) * 100);
  }

  @Selector([
    DAAPatientDetailsFormState.totalItems,
    DAAPatientDetailsFormState.completedItems,
  ])
  static getDAAClaimProgress(
    patientDetailsTotal,
    patientDetailsCompleted
  ) {
    return Math.floor(
      (patientDetailsCompleted) /
      (patientDetailsTotal) * 100);
  }

  @Selector([DoseAdministrationAidsState.daaFormType, DAAFormsProgressService.getDAAClaimProgress, DAAFormsProgressService.getDAARegistrationProgress])
  static getProgress(daaFormType, claimProgress, registrationProgress) {
    return daaFormType == DAAFormType.PatientRegistration
      ? registrationProgress
      : claimProgress;
  }
}
