import { State, Action, StateContext, Selector } from '@ngxs/store';
import { SetTotalItemsAndCompletedItems } from './hmr-gp-details-referral-form.actions';
import { Injectable } from '@angular/core';

export class HMRGPDetailsReferralFormStateModel {
  totalItems: number;
  completedItems: number;
}
@State<HMRGPDetailsReferralFormStateModel>({
  name: 'hmrGPDetailsReferralForm',
  defaults: {
    completedItems: 0,
    totalItems: 0
  }
})
@Injectable()
export class HMRGPDetailsReferralFormState {

  @Selector()
  static totalItems({ totalItems }: HMRGPDetailsReferralFormStateModel) { return totalItems; }

  @Selector()
  static completedItems({ completedItems }: HMRGPDetailsReferralFormStateModel) { return completedItems; }

  @Action(SetTotalItemsAndCompletedItems)
  setProgress({ patchState }: StateContext<HMRGPDetailsReferralFormStateModel>, { totalItems, completedItems }: SetTotalItemsAndCompletedItems) {
    patchState({ totalItems, completedItems });
  }
}
