import { OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Store, Actions } from '@ngxs/store';
import { StagedSupplyChildForm } from '../../staged-supply-child-form';
import { SetTotalItemsAndCompletedItems } from './state/ss-patient-details-form.actions';

export class SSPatientDetailsFormBaseComponent extends StagedSupplyChildForm implements OnInit, OnDestroy {
  patientDetailsForm: FormGroup;
  formResetStatus: boolean;

  constructor(private formBuilder: FormBuilder, protected store: Store, actions: Actions) {
    super(actions);
    this.patientDetailsForm = this.formBuilder.group({
      firstName: new FormControl('', Validators.required),
      surname: new FormControl('', Validators.required),
      dob: new FormControl('', Validators.required),
      medicareNumber: new FormControl('', [Validators.required, Validators.minLength(8)]),
      reviewed: new FormControl(null, Validators.requiredTrue)
    });
  }

  protected actionOnResetForm() {
    const controls = Object.keys(this.patientDetailsForm.controls);
    controls.filter((key) => {
      if (key != 'firstName'
        && key != 'surname'
        && key != 'dob'
        && key != 'medicareNumber'
        && key != 'gender'
        && key != 'residentialPostCode') {
        this.patientDetailsForm.controls[key].setValue(false);
      }
    });
  }
  get formControls() {
    return this.patientDetailsForm.controls;
  }
  protected onFormChange() {
    const controls = Object.keys(this.patientDetailsForm.controls);
    const completedItems = this.getTotalValidItems(controls);
    this.store.dispatch(new SetTotalItemsAndCompletedItems(controls.length, completedItems));
  }

  private getTotalValidItems(controls: Array<string>) {
    return controls.filter((key) => {
      const formControl = this.patientDetailsForm.get(key);
      return  formControl.status === 'VALID' || (formControl.status === 'DISABLED' && !formControl.invalid && formControl.errors === null);
    }).length;
  }
}
