import { Component, OnInit } from '@angular/core';
import { StagedSupplyChildForm } from '../../staged-supply-child-form';
import { Subscription } from 'rxjs';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { SSMedicationInformationFormState } from './state/ss-medication-information-form.state';
import { Store, Actions } from '@ngxs/store';
import { SetTotalAndCompletedItems } from './state/ss-medication-information-form.actions';
import { HealthConditionsForForm } from '../../../../../shared/models/six-cpa/FormKeyValuePairs';
import { FormControlFunctions } from '../../form-control.functions';
import { StagedSupplyReasons, PatientMedicationProblemsPastMonth } from '../../../../../shared/models/six-cpa/staged-supply/ss-medication-information-form.model';
import { WindowService } from '../../../../../shared/services/window.service';
//import { environment } from '../../../../../../environments/environment';
import { SixCpaState } from '../../state/six-cpa.state';

@Component({
  selector: 'app-ss-medication-information-form',
  templateUrl: './ss-medication-information-form.component.html',
  styleUrls: ['./ss-medication-information-form.component.scss']
})
export class SSMedicationInformationFormComponent extends StagedSupplyChildForm implements OnInit {
  private formSubscription: Subscription;
  private readonlySubscription: Subscription;
  medicationInformationForm: FormGroup;
  healthConditionsForFormArr = HealthConditionsForForm;
  reasonsForStagedSupply = StagedSupplyReasons;
  patientMedicationProblemsPastMonth = PatientMedicationProblemsPastMonth;

  constructor(private formBuilder: FormBuilder, private store: Store, actions: Actions, private formFunctions: FormControlFunctions, private windowService: WindowService) {
    super(actions);
    this.generateFormControls();
  }

  ngOnInit() {
    this.formSubscription = this.store.select(SSMedicationInformationFormState.medicationInformationForm)
      .subscribe(() => this.onFormChange());
    this.readonlySubscription = this.store.select(SixCpaState.formsReadOnly)
      .subscribe(readonly => readonly ? this.medicationInformationForm.disable() : this.medicationInformationForm.enable());
  }

  ngOnDestroy() {
    this.formSubscription.unsubscribe();
    this.readonlySubscription.unsubscribe();
  }
  get formControls() {
    return this.medicationInformationForm.controls;
  }
  openMedIndexCalculator() {
    // this.windowService.open(environment.medIndexCalculator);
    this.windowService.open('');
  }

  deselectOtherBox() {
    if (this.medicationInformationForm.value.medicationHealthConditions.other == false) {
      this.medicationInformationForm.controls['medicationHealthConditionsOtherDetails'].setValue('');
    }
  }

  protected actionOnResetForm() {
    this.medicationInformationForm.patchValue({
      medicationHealthConditions: { },
      medIndexScore: null,
      patientHadAnyProblemsOverThePastMonthWithTheirMedications: this.patientMedicationProblemsPastMonth.no,
      gPPrescriberNumber: null,
      reasonForStagedSupply: this.reasonsForStagedSupply.cognitiveOrPhysicalImpairment,
      medicationHealthConditionsOtherDetails: null
    })
  }


  private onFormChange() {
    const controls = Object.keys(this.medicationInformationForm.controls);
    const completedItems = this.getTotalValidItems(controls);
    this.store.dispatch(new SetTotalAndCompletedItems(controls.length, completedItems));
  }

  private getTotalValidItems(controls: Array<string>) {
    this.medicationInformationForm.controls['medicationHealthConditions'].setErrors(null);
    return controls.filter((key) => this.medicationInformationForm.get(key).status === 'VALID').length;
  }

  private generateFormControls() {
    this.medicationInformationForm = this.formBuilder.group({
      medicationHealthConditions: new FormGroup({}),
      medIndexScore: new FormControl('', Validators.required),
      patientHadAnyProblemsOverThePastMonthWithTheirMedications: new FormControl(this.patientMedicationProblemsPastMonth.no, Validators.required),
      gPPrescriberNumber: new FormControl('', Validators.required),
      reasonForStagedSupply: new FormControl(this.reasonsForStagedSupply.cognitiveOrPhysicalImpairment, Validators.required),
      medicationHealthConditionsOtherDetails: new FormControl('')
    });
    this.formFunctions.generateControlsForGroupFromArr('medicationHealthConditions', this.healthConditionsForFormArr, this.medicationInformationForm);
  }
}
