<div class="note-list__control">
    <button class="btn__img" [disabled]="!canTick" *ngIf="!isHiddenTick && note.requiresAction" (click)="clickedTick()">
      <i class="fas fa-check color__green"></i>
    </button>
    <button class="btn__img" *ngIf="canLink" (click)="clickedLink()">
      <i class="fas fa-chevron-right color__dark-blue"></i>
    </button>
    <button class="btn__img" (click)="clickedEdit()" [disabled]="!canEdit">
      <i class="fas fa-cog color__dark-blue"></i>
    </button>
    <button class="btn__img" [disabled]="!canDelete" (click)="clickedDelete()">
      <i class="far fa-trash-alt color__red"></i>
    </button>
  </div>
  