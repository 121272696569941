import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxsModule } from '@ngxs/store';
import { SixCpaFormListItemChecklistModule } from '../../six-cpa-form-list-item-checklist/six-cpa-form-list-item-checklist.module';
import { MedicationProfileListItemModule } from '../../medication-profile/medication-profile-list-item/medication-profile-list-item.module';
import { StagedSupplyMedicationProfileComponent } from './staged-supply-medication-profile.component';
import { StagedSupplyMedicationProfileState } from './state/staged-supply-medication-profile.state';

@NgModule({
  declarations: [StagedSupplyMedicationProfileComponent],
  imports: [
    CommonModule,
    NgxsModule.forFeature([
      StagedSupplyMedicationProfileState
    ]),
    SixCpaFormListItemChecklistModule,
    MedicationProfileListItemModule
  ],
  exports: [
    StagedSupplyMedicationProfileComponent
  ]
})
export class StagedSupplyMedicationProfileModule { }