<section>
  <div class="form-row">
    <div class="col-7">
      <h3>Administration</h3>
    </div>
    <div class="col-1">
    </div>
    <div class="col-4">
      <select class="form-control" [(ngModel)]="actionType">
        <option [value]="actionTypes.newPharmacy">Create a new pharmacy</option>
        <option [value]="actionTypes.newInstallReport">Create a new install report</option>
        <option [value]="actionTypes.newTemplate">Create a new message template</option>
      </select>
    </div>
  </div>
  <div *ngIf="actionType == actionTypes.newPharmacy">
    <h3>New Pharmacy</h3>
    <app-new-pharmacy-form></app-new-pharmacy-form>
  </div>
  <hr />
  <div *ngIf="actionType == actionTypes.newInstallReport">
    <h3 class="my-30">Tenant Install Report</h3>
    <app-tenant-install-report></app-tenant-install-report>
  </div>
  <hr />
  <div *ngIf="actionType == actionTypes.newTemplate">
    <h3 class="my-30">Message Template</h3>
    <app-global-template></app-global-template>
  </div>
</section>
