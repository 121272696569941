import { Component, OnInit, ViewChildren, QueryList, ElementRef } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { AdvancedClient } from '../models/advanced-client';
import { AdvancedSearchState } from '../state/advanced-search.state';
import { Select, Store } from '@ngxs/store';
import { Router } from '@angular/router';
import { SetBulkMessageRecipients } from '~/system/bulk-messaging-page/state/bulk-messaging.actions';
import { FeaturesState } from '~/modules/core/features/features.state';
import * as moment from 'moment';
import { withLatestFrom } from 'rxjs/operators';
import { GetAdvancedSearchResultsSuccess, ToggleAdvancedSearchClient } from '../state/advanced-search.actions';
import { makeFullName } from '~/shared/helpers/doctor-name.helper';
import { BulkScheduledMessageRecipientStatus, Recipient } from '~/shared/models/communication/bulk-message.model';

interface AdditonalSotrableFields {
  fullname: string;
  fulladdress: string;
}

type SortableRow = AdvancedClient & AdditonalSotrableFields;


@Component({
  selector: 'app-advanced-search-results-table',
  templateUrl: './advanced-search-results-table.component.html',
  styleUrls: ['./advanced-search-results-table.component.scss']
})
export class AdvancedSearchResultsTableComponent implements OnInit {
  @Select(AdvancedSearchState.loading) isLoading$: Observable<boolean>;
  @Select(FeaturesState.getFeatures) features$: Observable<any>;
  @Select(AdvancedSearchState.clients) results$: Observable<AdvancedClient[]>;
  @Select(AdvancedSearchState.recipientList) recipientList$: Observable<string>;

  @ViewChildren("checkboxes") checkboxes: QueryList<ElementRef>;
  //, Name, Address, DOB, Gender, Phone, Email, Medicare, Concession, Created, LastVisit
  displayedColumns$ = new BehaviorSubject<string[]>([
    'bulkSMS',
    'name',
    'address',
    'birthDate',
    'gender',
    'phone',
    'email',
    'medicare',
    'concessionNo',
    'created',
    'lastvisit',
  ]);
  headers = [
    'Name',
    'DOB',
    'Gender',
    'Phone',
    'ConcessionNo',
    'Email',
    'Medicare',
    'Created',
    'Address',
    'Last Visit',
  ];
  dataOnPage$ = new BehaviorSubject<any[]>([]);
  exportData$ = new BehaviorSubject<any[]>([]);
  currentPage$ = new BehaviorSubject<number>(1);
  pageSize = 25;
  resultsPerPage = 25;
  resultsPerPageList = [25, 50, 75, 100];
  pageSize$ = new BehaviorSubject<number>(25);
  sortKey$ = new BehaviorSubject<string>('fullname');
  sortDirection$ = new BehaviorSubject<string>('asc');
  selectTotal$ = new BehaviorSubject<boolean>(false);
  selectCurrentPage$ = new BehaviorSubject<boolean>(false);

  constructor(private router: Router, private store: Store) { }

  ngOnInit() {
    this.initializeTableDataSubscription();
    this.initializeSelectionSubscription();
  }

  private initializeTableDataSubscription() {
    // Make table data pagable, and sortable by column
    type sourcesTuple = [AdvancedClient[], number, number, string, string];
    combineLatest([this.results$, this.currentPage$, this.pageSize$, this.sortKey$, this.sortDirection$])
      .subscribe(([allSources, currentPage, pageSize, sortKey, sortDirection]: sourcesTuple) => {
        const startingIndex = (currentPage - 1) * pageSize;

        const sourceWithSortableFields = allSources
        .map(item => ({
          ...item,
           fullname: makeFullName(item.firstname, item.surname),
           fulladdress: makeFullName(item.homeAddress, item.homeSuburb, item.homeState, `${item.homePostcode}`)
          }));
        const sortedSource = sourceWithSortableFields.sort((a, b) => {
          const isAsc = sortDirection === 'asc';

          if (a === b) {
            return 0;
          }

          if(a[sortKey] === null || a[sortKey] > b[sortKey]) return isAsc ? 1 : -1;
          if(b[sortKey] === null || a[sortKey] < b[sortKey]) return isAsc ? -1 : 1;
          return 0;
        });

        this.prepareDataForExport(sortedSource);

        const onPage = sortedSource.slice(startingIndex, startingIndex + pageSize);

        this.dataOnPage$.next(<any>onPage);
      });
  }

  private initializeSelectionSubscription() {
    combineLatest([this.selectTotal$, this.selectCurrentPage$])
      .pipe(withLatestFrom(this.results$, this.dataOnPage$))
      .subscribe(([[selectTotal, selectCurrentPage], allSources, onPage]) => {

        allSources = allSources
          .map(item => ({
            ...item,
            selected: selectTotal,
          }));

        let myOnPage: AdvancedClient[] = onPage.map(item => ({
          ...item,
          selected: selectCurrentPage || selectTotal
        }));

        allSources = allSources.map(item => {
          const onPageItem = myOnPage.filter(x => x.id === item.id)[0];
          return onPageItem ? onPageItem : item;
        });

        this.store.dispatch(new GetAdvancedSearchResultsSuccess(allSources));
      });
  }

  prepareDataForExport(data: SortableRow[]) {
    let exportRows = [];
    data.map((item) => {
      exportRows.push([
        item.fullname,
        item.birthDate ? moment(item.birthDate).format("DD/MM/YYYY") : null,
        item.gender,
        item.mobileNumber,
        item.concessionNo,
        item.email,
        item.medicareNo,
        item.created ? moment(item.created).format("DD/MM/YYYY") : null,
        item.fulladdress,
        item.lastDispenseDate ? moment(item.lastDispenseDate).format("DD/MM/YYYY") : null,
      ]);
    })
    this.exportData$.next(exportRows);
  }

  changeResultsSize() {
    this.pageSize$.next(this.pageSize)
  }

  adjustSort(key: string) {
    if (this.sortKey$.value === key) {
      if (this.sortDirection$.value === 'asc') {
        this.sortDirection$.next('desc');
      } else {
        this.sortDirection$.next('asc');
      }
      return;
    }

    this.sortKey$.next(key);
    this.sortDirection$.next('asc');
  }

  selectAll() {
    this.selectTotal$.next(true);
  }

  unSelectAll() {
    this.selectTotal$.next(false);
  }

  selectAllOnPage() {
    this.selectCurrentPage$.next(true);
  }

  unSelectAllOnPage() {
    this.selectCurrentPage$.next(false);
  }

  toggleRecipient(id: any, $event: any) {
    this.store.dispatch(new ToggleAdvancedSearchClient(id));
  }

  navigateToBulkComms() {
    let recipients = [];
    const recipientsList = this.store.selectSnapshot(AdvancedSearchState.recipientList);
    if (recipientsList.length) {
      recipientsList.forEach(dest => {
        recipients.push(dest);
      })
    }
    else {
      const clients = this.store.selectSnapshot(AdvancedSearchState.clients);
      clients.forEach(client => {
        if (client.mobileNumber != undefined && client.mobileNumber != null && client.mobileNumber != '') {
          var recipient: Recipient = {
            destination: client.mobileNumber,
            clientId: client.id,
            customerName: `${client.firstname} ${client.surname}`,
            status: BulkScheduledMessageRecipientStatus.Pending
          };
          recipients.push(recipient);
        }
        else {
          if (client.phoneNumber && client.phoneNumber.length == 10) {
            var recipient: Recipient = {
              destination: client.mobileNumber,
              clientId: client.id,
              customerName: `${client.firstname} ${client.surname}`,
              status: BulkScheduledMessageRecipientStatus.Pending
            };
            recipients.push(recipient);
          }
        }
      });
    }
    this.store.dispatch(new SetBulkMessageRecipients(recipients));
    this.router.navigate(['bulk-messaging']);
  }
}
