import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HMRPharmacyInformationListItemComponent } from './hmr-pharmacy-information-list-item.component';
import { HMRPharmacyInformationModule } from '../hmr-pharmacy-information/hmr-pharmacy-information.module';
import { SixCpaFormListItemChecklistModule } from '../../six-cpa-form-list-item-checklist/six-cpa-form-list-item-checklist.module';

@NgModule({
  declarations: [HMRPharmacyInformationListItemComponent],
  imports: [
    CommonModule,
    HMRPharmacyInformationModule,
    SixCpaFormListItemChecklistModule
  ],
  exports: [HMRPharmacyInformationListItemComponent]
})
export class HMRPharmacyInformationListItemModule { }
