import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Select, Store } from '@ngxs/store';
import { GeneratePaymentLinkAction } from '../../../../../modules/profile/order-requests/state/order.actions';
import { OrderRequestItem } from '../../models/order-request-item.model';
import { OrderRequest } from '../../models/order-request.model';
import { InvoiceRequestModal } from '../../models/invoice-request-model';
import { OrderRequestItemType } from '../../models/order.enums';
import { InvoiceLine } from '../../../../profile/order-requests/models/invoice-line.model';
import { Observable } from 'rxjs';
import { OrderRequestState } from '../../../../../modules/profile/order-requests/state/order.state';

@Component({
  selector: 'app-stripe-modal',
  templateUrl: './stripe-modal.component.html',
  styleUrls: ['./stripe-modal.component.scss']
})
export class StripeModalComponent implements OnInit, OnChanges {
  @Select(OrderRequestState.invoiceLoading) invoiceLoading$: Observable<boolean>;
  @Select(OrderRequestState.error) error$: Observable<boolean>;

  @Input() order: OrderRequest;
  @Input() isVisible: boolean;
  @Output() isVisibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() orderItems: OrderRequestItem[] = null;


  orderItemType = OrderRequestItemType;
  stripeForm: FormGroup;
  submitted: boolean = false;

  constructor(private formBuilder: FormBuilder, private store: Store) { }

  ngOnInit(): void {
    this.stripeForm = this.formBuilder.group({
      mastercheckbox: [true],
      total: [0],
      medications: this.formBuilder.array([])
    });

    const control = <FormArray>this.stripeForm.controls['medications'];
    this.orderItems.forEach(element => {
      control.push(this.initMedications(element));
    });

    this.generateTotal();
  }

  ngOnChanges(): void {
    this.mapOrderRequestItem();
    this.ngOnInit();
  }

  initMedications(element): FormGroup {
    return this.formBuilder.group({
      stockItemName: [element.stockItemName, Validators.required],
      quantity: [element.quantity],
      price: [element.price, Validators.required],
      isGSTIncluded: [element.orderRequestItemType == this.orderItemType.OTCItem ? element.taxIncluded : false],
      orderRequestItemType: [element.orderRequestItemType],
      attachmentUrl: [element.attachmentUrl],
      selected: [true],
      isAdditional: [false],
    });
  }

  mapOrderRequestItem() {
    this.orderItems.forEach(x => {
      if (x?.customerMedication && x?.customerMedication?.description) {
        x.stockItemName = x.customerMedication.description;
        x.price = x.customerMedication.patientPrice;
        //this was changed because its altering the underlying object, and this is affecting our order table component.
        //this has broken the stripe behaviour for scryptqty
        x. quantity = x.quantity;
      }
      else if (x?.customerMedication == null) {
        x.stockItemName = x.name;
        x.price = x.retailPrice;
        x.quantity = x.quantity;
        x.taxIncluded = x.taxIncluded;
      }
    });
  }

  addAdditionalCharge() {
    const control = <FormArray>this.stripeForm.controls['medications'];
    control.push(this.addAdditionalControl());
  }

  addAdditionalControl() {
    return this.formBuilder.group({
      stockItemName: [, Validators.required],
      quantity: [1],
      price: [, Validators.required],
      isGSTIncluded: [true],
      orderRequestItemType: [],
      attachmentUrl: [],
      selected: [false],
      isAdditional: [true]
    })
  }

  removeAdditionalItem(index) {
    const medications = <FormArray>this.stripeForm.controls['medications'];
    medications.removeAt(index);
    this.generateTotal();
  }

  changeMasterCheckbox(event) {
    const medications = <FormArray>this.stripeForm.get('medications');
    medications.controls.forEach(x => {
      x['controls']['selected'].patchValue(event);
      this.setValidatorToPrice(event, x['controls']['price']);
    });
    this.generateTotal();
  }

  changeCheckbox(event, index) {
    const medications = <FormArray>this.stripeForm.get('medications');
    this.setValidatorToPrice(event, medications.controls[index]['controls']['price']);
    this.generateTotal();
  }

  setValidatorToPrice(event, control) {
    if (event) {
      control.setValidators([Validators.required]);
    } else {
      control.clearValidators();
    }
    control.updateValueAndValidity();
  }

  handleCancel() {
    this.isVisible = false;
    this.isVisibleChange.next(false);
  }

  generateTotal() {
    const medications = <FormArray>this.stripeForm.get('medications');
    var total: number = 0;
    medications.controls.forEach(x => {
      if ((x['controls']['selected'].value || x['controls']['isAdditional'].value) && x['controls']['price'].value) {
        total += parseFloat(x['controls']['price'].value);
      }
    });
    this.stripeForm.patchValue({
      total: total,
    });
  }

  generatePaymentLink() {
    this.submitted = true;
    if (this.stripeForm.valid) {
      const invoiceRequestModal = new InvoiceRequestModal();
      invoiceRequestModal.customerProfileID = this.order.customerProfileId,
        invoiceRequestModal.orderID = this.order.id,
        invoiceRequestModal.invoiceLines = [];

      const medications = <FormArray>this.stripeForm.get('medications');
      medications.controls.forEach(element => {
        if (element['controls']['selected'].value || element['controls']['isAdditional'].value)
        {
          var invoiceLine = new InvoiceLine();
          invoiceLine.description = element['controls']['stockItemName'].value;
          invoiceLine.price = element['controls']['price'].value;
          invoiceLine.quantity = 1;
          invoiceLine.isGSTIncluded = element['controls']['isGSTIncluded'].value;
          invoiceRequestModal.invoiceLines.push(invoiceLine);
        }
      });
      this.store.dispatch(new GeneratePaymentLinkAction(invoiceRequestModal));
    }
  }
}
