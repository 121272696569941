import { State, StateContext, Action, Selector } from '@ngxs/store';
import { SSAdditionalDetailsFormModel } from '../../../../../../shared/models/six-cpa/staged-supply/ss-additional-details-form.model';
import { UpdateSSAdditionalDetailsForm, SetSSAdditionalDetailsProgress } from './ss-additional-details-form.actions';
import { SetSSOutcomesAndActionsFormValidAction } from '../../ss-outcomes-and-actions-form/state/ss-outcomes-and-actions-form.actions';
import * as moment_ from 'moment';
const moment = moment_;
import { Injectable } from '@angular/core';

export class SSAdditionalDetailsFormStateModel {
  additionalDetailsForm: {
    model: Partial<SSAdditionalDetailsFormModel>
  }
  totalItems: number;
  completedItems: number;
  valid: boolean;
}
@Injectable()
@State<SSAdditionalDetailsFormStateModel>({
  name: 'ssAdditionalDetailsForm',
  defaults: {
    additionalDetailsForm: {
      model: undefined
    },
    totalItems: 0,
    completedItems: 0,
    valid: false
  }
})
export class SSAdditionalDetailsFormState {
  @Selector()
  static additionalDetailsForm(state: SSAdditionalDetailsFormStateModel) { return state.additionalDetailsForm.model; }

  @Selector()
  static totalItems(state: SSAdditionalDetailsFormStateModel) { return state.totalItems; }

  @Selector()
  static completedItems(state: SSAdditionalDetailsFormStateModel) { return state.completedItems; }

  @Action(SetSSOutcomesAndActionsFormValidAction)
  setValid(ctx: StateContext<SSAdditionalDetailsFormStateModel>, action: SetSSOutcomesAndActionsFormValidAction) {
    ctx.patchState({
      valid: action.valid
    });
  }

  @Action(SetSSAdditionalDetailsProgress)
  setProgress(ctx: StateContext<SSAdditionalDetailsFormStateModel>, action: SetSSAdditionalDetailsProgress) {
    ctx.patchState({
      totalItems: action.totalItems,
      completedItems: action.completedItems
    });
  }

  @Action(UpdateSSAdditionalDetailsForm)
  updateForm(ctx: StateContext<SSAdditionalDetailsFormStateModel>, { form }: UpdateSSAdditionalDetailsForm) {
    ctx.patchState({
      additionalDetailsForm: {
        model: {
          claimedCalendarMonth: form.claimedCalendarMonth,
          claimedCalendarYear: form.claimedCalendarYear,
          amountOfDaysInMonthPatientVisitedForStagedSupplyMedication: form.amountOfDaysInMonthPatientVisitedForStagedSupplyMedication,
          dateOfPatientsFinalDosingBatch: moment(form.dateOfPatientsFinalDosingBatch).toDate()
        }
      }
    });
  }
}
