<div>
  <ng-container *ngIf="order$ | async as orderState">
    <ng-container *ngIf="!orderState.loading; else loading">
      <div
        class="d-flex justify-content-between align-items-center"
        g-xs="pt4 pl0 pr3 pb3 valign:middle">
        <div class="d-flex justify-content-between align-items-center">
          <h1 g-xs="m0 valign:middle" class="blue-header">Orders</h1>
        </div>

        <div
          class="page-controls d-flex justify-content-between align-items-center">

          <select [(ngModel)]="orderFilter" id="orderFilter" name="resultsOrderFilter"
              class="form-control form-control-lg">
            <option [ngValue]="orderFilterStatuses.All">
              All
            </option>
            <option [ngValue]="orderFilterStatuses.Current">
              Current
            </option>
            <option [ngValue]="orderFilterStatuses.History">
              History
            </option>
          </select>

          <select
            [(ngModel)]="pageSize"
            (change)="changePageSize()"
            id="showResults"
            name="resultsPerPage"
            class="form-control form-control-lg">
            <option *ngFor="let size of pageSizes" [ngValue]="size">
              {{ size }} results
            </option>
          </select>
          <select
            [(ngModel)]="sortOrder"
            (change)="changeSortOrder()"
            id="sortOrder"
            name="resultsSortOrder"
            class="form-control form-control-lg">
            <option [ngValue]="sortOrderConstants.requestedDateDescending">
              Most Recent
            </option>
            <option [ngValue]="sortOrderConstants.requestedDateAscending">
              Least Recent
            </option>
          </select>
          <section class="order">
            <a [routerLink]="['create']"
              class="button btn__yellow order__new"
              g-xs="mr3">
              <i class="fas fa-plus"></i>
              Create Suggested Order</a>
          </section>
        </div>
      </div>
      <div>
        <div *ngFor="let order of dataOnPage$ | async">
          <div *ngIf="orderIsAllowedByFilter(order.status)">
            <h3 *ngIf="order.groupHeading">{{ order.groupHeading }}</h3>
            <ng-container *ngIf="order.isEmpty; else orderCard">
              <app-order-empty
                orderTypeText="orders"
              ></app-order-empty>
            </ng-container>
            <ng-template #orderCard>
              <app-order-card
                [dateFn]="formatDate"
                [order]="order"
                (orderMethodChange)="changeOrderMethod($event)"
                (updateStatus)="openUpdateStatusModal($event)"
                (onNavigateToComms)="navToComms()">
              </app-order-card>
            </ng-template>
          </div>
        </div>
      </div>
      <nz-pagination
        [nzPageSize]="pageSize$ | async"
        [nzTotal]="orderState.orders.total"
        [nzPageIndex]="(currentPage$ | async) + 1"
        (nzPageIndexChange)="currentPage$.next($event - 1)">
      </nz-pagination>
      <app-order-update-status-modal
        [order]="orderState.currentOrder"
        [dateFn]="formatDate"
        [profile]="profile"
        [isVisible]="orderState.showStatusModal"
        (isVisibleChange)="closeModal()"
        (updateOrder)="updateOrder($event)">
      </app-order-update-status-modal>
    </ng-container>
  </ng-container>
  <ng-template #loading>
    <app-loader></app-loader>
  </ng-template>
</div>
