<div (click)="clickCard()" [ngClass]="{'order-card': !isHeader}">
    <div class="d-flex justify-content-between align-items-center">
        <div class="d-flex justify-content-between align-items-center">
            <h2>Order #{{order.id}}</h2>
            <div *ngIf="!isHeader && orderHasItemsOnOrder()" class="on-order bg_blue status-badge">
                <i class="fas fa-clock"></i>
                On Order
            </div>
        </div>
        <button (click)="$event.stopPropagation()" class="mb12px" nz-button nz-dropdown [nzDropdownMenu]="menu">
            <i class="fal fa-ellipsis-h-alt"></i>
        </button>
        <nz-dropdown-menu #menu="nzDropdownMenu">
            <ul nz-menu>
                <li nz-menu-item (click)="navigateToComms()">Message patient</li>
                <ng-container *ngIf="!isHeader">
                    <li nz-menu-divider></li>
                    <li nz-menu-item nzDisabled>Update status</li>
                    <ng-container *ngIf="order.status === orderStates.Pending">
                        <li *ngIf="order.orderMethod === orderMethods.Pickup" nz-menu-item
                            (click)="updateStatusReady()">Ready for collection</li>
                        <li *ngIf="order.orderMethod === orderMethods.Delivery" nz-menu-item
                            (click)="updateStatusReady()">Ready for drop off</li>
                    </ng-container>
                </ng-container>
                <li nz-menu-divider></li>
                <li nz-menu-item [nzDisabled]="disabled" (click)="updateStatusCancel()">Cancel order</li>
            </ul>
        </nz-dropdown-menu>
    </div>
    <div [ngClass]="{'blue-background': isHeader}">
        <table>
            <tr>
                <th>Status</th>
                <th class="small">Requested</th>
                <th class="small">Updated</th>
                <th class="small">Items</th>
                <th class="small">Source</th>
                <th>Collection</th>
                <th>Brand preference</th>
                <th [attr.colspan]="isHeader? '2': '1'">Patient notes</th>
                <th class="small" *ngIf="!isHeader"></th>
            </tr>
            <tr>
                <td class="status-col">
                    <div [ngClass]="statusBackground(order.status) + ' status-badge'">
                        <i [ngClass]="statusIconClass(order.status)"></i>
                        {{statusText(order.status, order.orderMethod)}}
                    </div>
                </td>
                <td>{{dateFn(order.requestedDate)}}</td>
                <td>{{dateFn(order.lastUpdated)}}</td>
                <td>{{order.orderRequestedItems.length}} items</td>
                <td>{{orderOriginText(order.orderOrigin)}}</td>
                <td class="delivery">
                    <div><i [ngClass]="orderMethodClass(order.orderMethod)"></i> {{orderMethodText(order.orderMethod)}} </div>
                    <div>{{order.deliveryPreferenceAddress}}</div>
                </td>
                <td>
                    <i [ngClass]="brandPrefClass(order.brandPreference)"></i>
                    {{brandPrefText(order.brandPreference)}}
                </td>
                <td class="notes" [attr.colspan]="isHeader? '2': '1'">{{order.patientNotes}}</td>
                <td class="view-link" *ngIf="!isHeader"><a class="link" [routerLink]="['./' + order.id]">View Order <i
                            class="fal fa-arrow-right"></i></a></td>
            </tr>
            <tr *ngIf="isHeader">
                <td colspan="5">
                </td>
                <td>
                    <div *ngIf="!disabled" (click)="changeOrderMethod()" class="link">
                        Change to {{oppositeOrderMethodText(order.orderMethod)}}
                    </div>
                </td>
                <td colspan="3">
                </td>
            </tr>
        </table>
    </div>

</div>
