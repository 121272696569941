<form [formGroup]="daaClassificationsRecommendationsForm"
  ngxsForm="daaClassificationsRecommendationsForm.daaClassificationsRecommendationsForm" class="p-4">
  <div class="form-group pb-1">
    <label class="input-label">In the Last 6 Months did the Patient go to the GP or Hospital due to
      problems with their medicines?</label>
    <app-radio-highlight controlName="hospitalisedYes"
      [hasError]="formControls.gpOrHospitalLastSixMonthsForMedicationProblems.invalid && (showInvalid$ | async)"
      [isHighlighted]="daaClassificationsRecommendationsForm.value.gpOrHospitalLastSixMonthsForMedicationProblems == true">
      <input class="form-check-input" type="radio" name="gpOrHospitalLastSixMonthsForMedicationProblems"
        id="hospitalisedYes" formControlName="gpOrHospitalLastSixMonthsForMedicationProblems" [value]="true">
      <label class="form-check-label" for="hospitalisedYes">Yes</label>
    </app-radio-highlight>
    <app-radio-highlight controlName="hospitalisedNo"
      [hasError]="formControls.gpOrHospitalLastSixMonthsForMedicationProblems.invalid && (showInvalid$ | async)"
      [isHighlighted]="daaClassificationsRecommendationsForm.value.gpOrHospitalLastSixMonthsForMedicationProblems == false">
      <input class="form-check-input" type="radio" name="gpOrHospitalLastSixMonthsForMedicationProblems"
        id="hospitalisedNo" formControlName="gpOrHospitalLastSixMonthsForMedicationProblems" [value]="false" checked>
      <label class="form-check-label" for="hospitalisedNo">No</label>
    </app-radio-highlight>
  </div>
  <div class="form-group pb-1">
    <label class="input-label">Was the patient using a DAA prior to this visit?</label><br />
    <app-radio-highlight controlName="priorDAAYes"
      [hasError]="formControls.patientUsingDAAPriorToVisit.invalid && (showInvalid$ | async)"
      [isHighlighted]="daaClassificationsRecommendationsForm.value.patientUsingDAAPriorToVisit == true">
      <input class="form-check-input" type="radio" name="patientUsingDAAPriorToVisit" id="priorDAAYes"
        formControlName="patientUsingDAAPriorToVisit" [value]="true">
      <label class="form-check-label" for="priorDAAYes">Yes</label>
    </app-radio-highlight>
    <app-radio-highlight controlName="priorDAANo"
      [hasError]="formControls.patientUsingDAAPriorToVisit.invalid && (showInvalid$ | async)"
      [isHighlighted]="daaClassificationsRecommendationsForm.value.patientUsingDAAPriorToVisit == false">
      <input class="form-check-input" type="radio" name="patientUsingDAAPriorToVisit" id="priorDAANo"
        formControlName="patientUsingDAAPriorToVisit" [value]="false" checked>
      <label class="form-check-label" for="priorDAANo">No</label>
    </app-radio-highlight>
  </div>
  <div class="form-group mb-0 pb-2" formGroupName="healthConditionsBeingMedicated" (change)="deselectOtherBoxes()"
    [class.has-error-group]="formControls.healthConditionsBeingMedicated.invalid && (showInvalid$ | async)">
    <label class="input-label" for="">What health condition is the patient taking medications for?</label>
    <div *ngFor="let condition of healthConditionsForFormArr">
      <div class="form-check">
        <label class="form-check-label pl-2">
          <input class="form-check-input big-checkbox" [formControlName]="condition.key" type="checkbox"
            [value]="condition.value">
          {{condition.value}}</label>
      </div>
    </div>
  </div>
  <input class="form-control form-control-lg w-50 ml-4 mb-2" type="text"
    [class.has-error]="formControls.healthConditionsBeingMedicatedOtherDetails.invalid && (showInvalid$ | async)"
    name="healthConditionsBeingMedicatedOtherDetails" id="healthConditionsBeingMedicatedOtherDetails"
    formControlName="healthConditionsBeingMedicatedOtherDetails"
    [attr.disabled]="!daaClassificationsRecommendationsForm.value.healthConditionsBeingMedicated.other ? '' : null">
  <div class="form-group pb-2"
    [class.has-error-group]="formControls.patientSupportWithManagingMedicines.invalid && (showInvalid$ | async)">
    <label class="input-label">Does the Patient have Support with Managing Medicines?</label>
    <div class="form-check form-check">
      <input class="form-check-input" type="radio" name="patientSupportWithManagingMedicines" id="minimal"
        formControlName="patientSupportWithManagingMedicines" [value]="medicineSupport.Minimal">
      <label class="form-check-label" for="minimal">Minimal (e.g. Living alone)</label>
    </div>
    <div class="form-check form-check">
      <input class="form-check-input" type="radio" name="patientSupportWithManagingMedicines" id="occasional"
        formControlName="patientSupportWithManagingMedicines" [value]="medicineSupport.Occasional">
      <label class="form-check-label" for="occasional">Occasional assistance (e.g. Living alone with periodic
        help)</label>
    </div>
    <div class="form-check form-check">
      <input class="form-check-input" type="radio" name="patientSupportWithManagingMedicines" id="routine"
        formControlName="patientSupportWithManagingMedicines" [value]="medicineSupport.Routine">
      <label class="form-check-label" for="routine">Routine assistance (e.g. regular carer)</label>
    </div>
    <div class="form-check form-check">
      <input class="form-check-input" type="radio" name="patientSupportWithManagingMedicines" id="complete"
        formControlName="patientSupportWithManagingMedicines" [value]="medicineSupport.Complete">
      <label class="form-check-label" for="complete">
        Complete assistance (assistance with preparation and taking of medicines)</label>
    </div>
  </div>
  <div class="form-group pb-1">
    <div>
      <label class="input-label">Is English the patient’s primary language at home?</label>
    </div>
    <app-radio-highlight controlName="isEnglishPrimaryLanguageYes"
      [hasError]="formControls.englishAtHome.invalid && (showInvalid$ | async)"
      [isHighlighted]="daaClassificationsRecommendationsForm.value.englishAtHome == true">
      <input class="form-check-input" type="radio" name="englishAtHome" id="isEnglishPrimaryLanguageYes"
        formControlName="englishAtHome" [value]="true" checked>
      <label class="form-check-label " for="isEnglishPrimaryLanguageYes">Yes</label>
    </app-radio-highlight>
    <app-radio-highlight controlName="isEnglishPrimaryLanguageNo"
      [hasError]="formControls.englishAtHome.invalid && (showInvalid$ | async)"
      [isHighlighted]="daaClassificationsRecommendationsForm.value.englishAtHome == false">
      <input class="form-check-input" type="radio" name="englishAtHome" id="isEnglishPrimaryLanguageNo"
        formControlName="englishAtHome" [value]="false">
      <label class="form-check-label" for="isEnglishPrimaryLanguageNo">No</label>
    </app-radio-highlight>
  </div>
  <div class="form-group pb-1">
    <div>
      <label class="input-label-not-required">Does the patient identify as Aboriginal and/or Torres Strait
        Islander?</label>
    </div>
    <app-radio-highlight controlName="aboriginalYes"
      [hasError]="formControls.aboriginalOrTorresStraitIslander.invalid && (showInvalid$ | async)"
      [isHighlighted]="daaClassificationsRecommendationsForm.value.aboriginalOrTorresStraitIslander == true">
      <input class="form-check-input" type="radio" name="aboriginalOrTorresStraitIslander" id="aboriginalYes"
        formControlName="aboriginalOrTorresStraitIslander" [value]="true">
      <label class="form-check-label" for="aboriginalYes">Yes</label>
    </app-radio-highlight>
    <app-radio-highlight controlName="aboriginalNo"
      [hasError]="formControls.aboriginalOrTorresStraitIslander.invalid && (showInvalid$ | async)"
      [isHighlighted]="daaClassificationsRecommendationsForm.value.aboriginalOrTorresStraitIslander == false">
      <input class="form-check-input" type="radio" name="aboriginalOrTorresStraitIslander" id="aboriginalNo"
        formControlName="aboriginalOrTorresStraitIslander" [value]="false" checked>
      <label class="form-check-label" for="aboriginalNo">No</label>
    </app-radio-highlight>
  </div>
  <div class="form-group pb-1">
    <label class="input-label" for="daasPackedPerWeek">Number of DAAs packed per week</label>
    <input type="text" [class.has-error]="formControls.daasPackedPerWeek.invalid && (showInvalid$ | async)"
      class="form-control form-control-lg w-25" formControlName="daasPackedPerWeek" value="" id="daasPackedPerWeek">
  </div>

  <div class="form-group pb-2"
    [class.has-error-group]="formControls.frequencyOfCollection.invalid && (showInvalid$ | async)">
    <label class="input-label">Frequency of collection</label>
    <div class="form-check form-check">
      <input class="form-check-input" type="radio" name="frequencyOfCollection" id="weekly"
        formControlName="frequencyOfCollection" [value]="collectionFrequency.Weekly">
      <label class="form-check-label" for="weekly">Weekly</label>
    </div>
    <div class="form-check form-check">
      <input class="form-check-input" type="radio" name="frequencyOfCollection" id="fortnightly"
        formControlName="frequencyOfCollection" [value]="collectionFrequency.Fortnightly">
      <label class="form-check-label" for="fortnightly">Fortnightly</label>
    </div>
    <div class="form-check form-check">
      <input class="form-check-input" type="radio" name="frequencyOfCollection" id="monthly"
        formControlName="frequencyOfCollection" [value]="collectionFrequency.Monthly">
      <label class="form-check-label" for="monthly">Monthly</label>
    </div>
    <div class="form-check form-check">
      <input class="form-check-input" type="radio" name="frequencyOfCollection" id="other"
        formControlName="frequencyOfCollection" [value]="collectionFrequency.Other">
      <label class="form-check-label" for="other">Other</label>
    </div>
  </div>
</form>