import { State, Action, StateContext, Selector, Store } from '@ngxs/store';
import { Medicine, MedicineCategory } from '../../../../../shared/models/six-cpa/medicine.model';
import {
  ToggleMedsCheckMedicationProfileAction,
  SetMedsCheckMedicationProfileScriptsAction,
  ToggleMedsCheckMedicationProfileListItemAction,
  ToggleAllMedsCheckMedicationProfileListAction,
  AddMedicationToMedsCheckMedicationProfileListAction,
  SelectMedsCheckMedicationProfileListItemAction,
  UpdateMedsCheckMedicationProfileList,
  ResetMedsCheckMedicationProfileIdsAction,
  SetMedsCheckMedicationProfileFormAction
} from './meds-check-medication-profile.actions';
import { Injectable } from '@angular/core';
import { MedicineCategoryConstants } from '../../../../../shared/constants/medicine-category.constant';
import { addUncategorisedIfMissing } from '../../../../../shared/helpers/uncategorised-meds.helper';
import { getDoctorName } from '../../../../../shared/helpers/doctor-name.helper';
import { ProfileState } from '../../../../core/profile/state/profile.state';

export class MedsCheckMedicationProfileForm {
  public allergies: string;
  public primaryDoctor: string;
}

export class MedsCheckMedicationProfileStateModel {
  public categorisedMedicines: MedicineCategory[];
  public showMode: string;
  public medicationProfileForm: {
    model: Partial<MedsCheckMedicationProfileForm>
  };
}
@Injectable()
@State<MedsCheckMedicationProfileStateModel>({
  name: 'medsCheckMedicationProfile',
  defaults: {
    categorisedMedicines: [],
    showMode: 'out',
    medicationProfileForm: {
      model: undefined
    }
  }
})
export class MedsCheckMedicationProfileState {

  constructor(private store: Store){}

  @Selector()
  static categorisedMedicines(state: MedsCheckMedicationProfileStateModel) { return state.categorisedMedicines; }

  @Selector()
  static medicationProfileForm(state: MedsCheckMedicationProfileStateModel) { return state.medicationProfileForm.model; }

  @Selector()
  static showMode(state: MedsCheckMedicationProfileStateModel) { return state.showMode; }

  @Selector()
  static selectedMedicines(state: MedsCheckMedicationProfileStateModel) {
    let res = [] as Medicine[];
    state.categorisedMedicines.forEach(category => {
      res = res.concat(category.medicines.filter(x => x.isSelected));
    });
    return res;
  }

  @Selector()
  static selectedMedicineCount(state: MedsCheckMedicationProfileStateModel) {
    let res = [] as Medicine[];
    state.categorisedMedicines.forEach(category => {
      res = res.concat(category.medicines.filter(x => x.isSelected));
    });
    return res.length;
  }

  @Action(SetMedsCheckMedicationProfileFormAction)
  setForm(ctx: StateContext<MedsCheckMedicationProfileStateModel>, action: SetMedsCheckMedicationProfileFormAction){
    const doctor = this.store.selectSnapshot(ProfileState.profile)?.patient?.preferredDoctor;
    if (!action.form.primaryDoctor) {
      action.form.primaryDoctor = getDoctorName(doctor);
    }
    ctx.patchState({
      medicationProfileForm: {
        model: action.form
      }
    })
  }

  @Action(ToggleMedsCheckMedicationProfileAction)
  toggle(ctx: StateContext<MedsCheckMedicationProfileStateModel>, action: ToggleMedsCheckMedicationProfileAction) {
    ctx.patchState({ showMode: action.showMode });
  }

  @Action(SetMedsCheckMedicationProfileScriptsAction)
  setScripts(ctx: StateContext<MedsCheckMedicationProfileStateModel>, action: SetMedsCheckMedicationProfileScriptsAction) {
    const categorisedMedicines = action.categorisedScripts.map(category => {
      const { id, name } = category.key;
      const medicineList = category.items.map(item => {
        return this.ConstructMedicine(item, name);
      });
      return {
        id,
        name,
        medicines: medicineList
      } as MedicineCategory;
    });
    ctx.patchState({ categorisedMedicines });
  }

  private ConstructMedicine(item: any, categoryName: string) {
    const { productDispensedId, description, directionsDesc, quantity, name, doctor, dispensedDate } = item;
    return {
      id: null,
      productDispensedId,
      brandName: description,
      genericName: name,
      directions: directionsDesc,
      isSelected: this.notDiscontinuedCategory(categoryName),
      quantity,
      prescriber: getDoctorName(doctor),
      dispensedDate: dispensedDate
    } as Medicine;
  }

  private notDiscontinuedCategory(categoryName: string): boolean {
    return (categoryName == MedicineCategoryConstants.DiscontinuedFriendly || categoryName == MedicineCategoryConstants.Discontinued) ? false : true;
  }

  @Action(ToggleMedsCheckMedicationProfileListItemAction)
  toggleListItem(ctx: StateContext<MedsCheckMedicationProfileStateModel>, action: ToggleMedsCheckMedicationProfileListItemAction) {
    const state = ctx.getState();
    ctx.patchState({
      categorisedMedicines: state.categorisedMedicines.map(category => {
        return {
          ...category,
          medicines: category.medicines.map(item => item === action.item ? { ...item, isSelected: !item.isSelected } : item)
        };
      })
    });
  }

  @Action(ToggleAllMedsCheckMedicationProfileListAction)
  toggleAll(ctx: StateContext<MedsCheckMedicationProfileStateModel>, {isSelectAll, categoryId}: ToggleAllMedsCheckMedicationProfileListAction) {
    const state = ctx.getState();
    ctx.patchState({
      categorisedMedicines: state.categorisedMedicines.map(category => {
        if ((categoryId && category.id === categoryId) || !categoryId) {
          return {
            ...category,
            medicines: category.medicines.map(item => {
              return { ...item, isSelected: isSelectAll } as Medicine;
            })
          };
        } else { return category; }
      })
    });
  }

  @Action(AddMedicationToMedsCheckMedicationProfileListAction)
  addMedication(ctx: StateContext<MedsCheckMedicationProfileStateModel>, action: AddMedicationToMedsCheckMedicationProfileListAction) {
    const state = ctx.getState();
    const findDrug = state.categorisedMedicines.find(y => !!y.medicines.find(x => x.productDispensedId === action.item.productDispensedId));
    if (findDrug) {
      ctx.patchState({
        categorisedMedicines: state.categorisedMedicines.map(category => {
          return {
            ...category,
            medicines: category.medicines.map(item => item.productDispensedId === action.item.productDispensedId
              ? { ...action.item, isSelected: item.isSelected }
              : item)
          };
        })
      });
    } else {
      addUncategorisedIfMissing(state.categorisedMedicines);
      ctx.patchState({
        categorisedMedicines: state.categorisedMedicines.map(category => {
          if (category.id) {
            return {
              ...category,
            };
          } else {
            return {
              ...category,
              medicines: [...category.medicines, { ...action.item, isSelected: true }]
            };
          }
        })
      });
    }
  }

  @Action(SelectMedsCheckMedicationProfileListItemAction)
  selectListItem(ctx: StateContext<MedsCheckMedicationProfileStateModel>, action: SelectMedsCheckMedicationProfileListItemAction) {
    const state = ctx.getState();
    ctx.patchState({
      categorisedMedicines: state.categorisedMedicines.map(category => {
        return {
          ...category,
          medicines: category.medicines.map(item => item.productDispensedId === action.productDispensedId ? { ...item, isSelected: true } : item)
        };
      })
    });
  }

  @Action(UpdateMedsCheckMedicationProfileList)
  updateMedicines(ctx: StateContext<MedsCheckMedicationProfileStateModel>, { medication }: UpdateMedsCheckMedicationProfileList) {
    const state = ctx.getState();

    //clear all previous selections
    ctx.dispatch(new ToggleAllMedsCheckMedicationProfileListAction(false, null));

    medication.forEach(med => {
      ctx.dispatch(new SelectMedsCheckMedicationProfileListItemAction(med.productDispensedId));
    });

    ctx.patchState({
      categorisedMedicines: state.categorisedMedicines.map(category => {
        return {
          ...category,
          medicines: category.medicines.map(item => {
            const el = medication.find(x => x.productDispensedId === item.productDispensedId);
            return el ? el : item;
          })
        };
      })
    });
  }

  /* This keeps the default medications selected but will reset the two form fields - used when starting a new application or navigating profiles */
  @Action(ResetMedsCheckMedicationProfileIdsAction)
  resetMedicationProfileIds(ctx: StateContext<MedsCheckMedicationProfileStateModel>, action: ResetMedsCheckMedicationProfileIdsAction) {
    const state = ctx.getState();
    ctx.patchState({
      categorisedMedicines: state.categorisedMedicines.map(category => {
        return {
          ...category,
          medicines: category.medicines ? category.medicines.map(med => {return {...med, id: null}}) : [],
        }
      }),
      medicationProfileForm: undefined
    });
  }

}
