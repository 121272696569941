<ng-container *ngIf="(dispenseReport$ | async) as dispenseReport">
  <section class="mt-2" style="text-align: center;">
    <div>
      <h2><u>Results</u></h2>
    </div>
    <ng-container *ngIf="(clients$ | async) as clients">
      <div class="mt-2" style="font-size: 16px; text-align: center;">
        {{getEvaluationStatus(clients)}} &nbsp; <i [class]="getIcon(clients)"></i>
      </div>
    </ng-container>
    <hr />
    <ng-container *ngIf="(bulkDispenseState$ | async) as bulkDispenseState">
      <div>
        <div class="row mt--sm mt-2" *ngFor="let client of dispenseReport.clients">
          <div class="col-sm-6">
            <h2>{{client.patient.firstname}} {{client.patient.surname}}</h2>
            <a href="/app/profile/{{client.patient.id}}" target="_blank">View Patient Profile</a>
          </div>
          <div class="col-sm-6 preferred-doctor-wrapper">
            <div>
              <h2 *ngIf="client.patient.preferredDoctor">
                Preferred Doctor: {{client.patient.preferredDoctor.title}}
                {{client.patient.preferredDoctor.firstName}}
                {{client.patient.preferredDoctor.surname}}
              </h2>
              <h2 *ngIf="!client.patient.preferredDoctor">
                Preferred Doctor: Not Provided
              </h2>
              <p>
                {{client.patient.homeAddress}}
                {{client.patient.homeSuburb}} {{client.patient.homeState}}
                {{client.patient.homePostcode}}
              </p>
            </div>

            <button class="btn btn-outline-primary" *ngIf="!bulkDispenseState.loading"
              (click)="bulkDispenseforClient(client)" [disabled]="!hasSelectedItems(client)">
              Dispense
            </button>
            <app-loader *ngIf="bulkDispenseState.loading"
              loadingMessage="Sending {{bulkDispenseState.amountOfScripts.length}} to Z Dispense..."></app-loader>
          </div>
          <div class="row flex-grow-1">
            <div class="col-sm-12">
              <nz-table #resultTable class="mb-4" nzSize="small" [nzShowPagination]="false" [nzData]="client.items">
                <thead>
                  <tr>
                    <th *ngFor="let column of columns">{{column}}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let drug of resultTable.data"
                    [ngClass]="{'info': drug.hasAuthority, 'danger':drug.cMeta.isFlagged }">
                    <td nzAlign="center"><span nz-tooltip nzTooltipTitle="{{drug.isDangerousDrug ? drug.schedule : ''}}"><i [ngClass]="getScriptIcon(drug)"></i></span></td>
                    <td>{{drug.name}}</td>
                    <td>{{drug.description}}</td>
                    <td>{{drug.quantity}}</td>
                    <td>{{drug.directions}}</td>
                    <td>
                      <span *ngIf="drug.doctor?.firstName">{{drug.doctor.firstName}}</span>&nbsp;<span
                        *ngIf="drug.doctor?.surname">{{drug.doctor.surname}}</span>
                    </td>
                    <td>
                      {{drug.dispensedDate | date:'dd/MM/yyyy':'+1000'}}
                    </td>
                    <td>
                      {{drug.nextRepeatDue | date:'dd/MM/yyyy':'+1000'}}
                    </td>
                    <td>{{drug.cMeta.currentDosage.frequency}}</td>
                    <td [ngClass]="drug.isChart ? '' : drug.repeats > 0 ? 'success ': 'danger'">
                      {{drug.isChart ? '-' : drug.repeats}}</td>
                    <td>
                      <span *ngIf="drug.stock.totalDoh"
                        [ngClass]="drug.stock?.totalDoh?.value <= 0 ? 'danger': 'warning'">
                        {{drug.stock.totalDoh.value < 0 ? 0 : drug.stock.totalDoh.value}}
                      </span>
                      <span *ngIf="!drug.stock.totalDoh">
                        N/A
                      </span>
                    </td>
                    <td>
                      <label *ngIf="drug.scriptDispenseId != null && !!drug.cMeta.onFile" nz-checkbox [(ngModel)]="drug.bulkDispense">Add</label>
                    </td>
                  </tr>
                </tbody>
              </nz-table>
            </div>
          </div>
        </div>
        <div class="select-all-container">
          <label nz-checkbox [(ngModel)]="selectAll" (ngModelChange)="toggleAll(dispenseReport.clients)">Select all</label>
          <button class="button report-button btn__yellow" (click)="bulkDispenseAllSelected(dispenseReport.clients)"
            [disabled]="!hasAnyScriptsSelected(dispenseReport.clients)">Dispense All Selected</button>
        </div>
      </div>
    </ng-container>
  </section>
</ng-container>
