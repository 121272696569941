import { Directive, ViewContainerRef, Component, OnChanges, Input, ViewChild, Type, ComponentFactoryResolver } from '@angular/core';
import { HasViewContainerRef } from '../../../has-view-container-ref.interface';
import { HMRResolvedForm } from '../../hmr-resolved-form';
import { HMRServiceProviderDetailsFormDetailedComponent } from '../detailed/hmr-service-provider-details-form-detailed.component';
import { HMRServiceProviderDetailsFormBasicComponent } from '../basic/hmr-service-provider-details-form-basic.component';
import { HMRFormType } from '../../hmr-form-list/state/hmr-form.state';

@Directive({
    selector: '[appHMRServiceProviderDetailsFormHost]'
  })
  export class HMRServiceProviderDetailsFormHostDirective implements HasViewContainerRef {
    constructor(public viewContainerRef: ViewContainerRef) { }
  }

  @Component({
    selector: 'app-hmr-service-provider-details-form',
    templateUrl: './hmr-service-provider-details-form.component.html'
  })
  export class HMRServiceProviderDetailsFormComponent extends HMRResolvedForm implements OnChanges {
    @Input() formType: HMRFormType;
    @ViewChild(HMRServiceProviderDetailsFormHostDirective, { static: true }) formHost: HMRServiceProviderDetailsFormHostDirective;

    constructor(
      componentFactoryResolver: ComponentFactoryResolver
    ) {
      super(componentFactoryResolver);
    }

    ngOnChanges() {
      this.resolveFormComponent(this.formType);
    }

    protected getFormComponent(formType: HMRFormType): Type<any> {
      switch (formType) {
        case HMRFormType.InterviewRegisteredPhamacist:
          return HMRServiceProviderDetailsFormDetailedComponent;
          case HMRFormType.InterviewOutsidePatientHome:
            return HMRServiceProviderDetailsFormBasicComponent;
      }
    }
  }

