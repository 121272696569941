import { isBeforeToday } from '~/modules/core/timezone/logic/timezone.logic';
import * as moment from 'moment';

export const classColorRed = 'color__red';
export const classColorGreen = 'color__green';
export const classFontBold = 'font__bold';

export function getClasses(date: Date | string) {
    return appendColorClass('', date);
}

export function getClassesWithBold(date: Date | string) {
    return appendBoldFont(getClasses(date));
}

export function appendColorClass(classes: string, date: Date | string) {
    return `${classes} ${
          !moment(date, moment.RFC_2822).isValid()
      ||  !moment(date, moment.ISO_8601).isValid()
      || isBeforeToday(date) ? classColorRed : classColorGreen}`;
}

export function appendBoldFont(classes: string) {
    return `${classes} ${classFontBold}`;
}
