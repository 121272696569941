import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SickLeaveConsulationListItemComponent } from './sick-leave-consulation-list-item.component';
import { SickLeaveConsulationModule } from '../sick-leave-consulation/sick-leave-consulation.module';
import { SixCpaFormListItemChecklistModule } from '../../six-cpa-form-list-item-checklist/six-cpa-form-list-item-checklist.module';

@NgModule({
  declarations: [SickLeaveConsulationListItemComponent],
  imports: [
    CommonModule,
    SickLeaveConsulationModule,
    SixCpaFormListItemChecklistModule
  ],
  exports: [SickLeaveConsulationListItemComponent]
})
export class SickLeaveConsulationListItemModule { }
