<div>
  <h3>Category</h3>
  <div class="row mt-4">
    <div class="col-sm-12">
      <form [formGroup]="categoryForm" (ngSubmit)="onSubmit(categoryForm.value)">

        <div class="form-group">
          <label for="metric_name">Name</label>
          <input type="text" class="form-control" id="metric_name" placeholder="Name" formControlName="name">
        </div>

        <div class="form-group">
          <label for="metric_name">Friendly Name</label>
          <input type="text" class="form-control" id="friendly_name" placeholder="Friendly Name" formControlName="friendlyName">
        </div>

        <div class="form-group">
          <label for="current">Dispense Interval</label>
          <input type="number" class="form-control" id="current" placeholder="Dispense Interval" formControlName="dispenseBuffer">
        </div>

        <div class="form-group">
          <label for="target">Target Days-on-Hand (DOH)</label>
          <input type="number" class="form-control" id="target" placeholder="Target Days-on-Hand (DOH)" formControlName="targetTotalDoh">
        </div>

        <div class="form-group">
          <label for="target">Position</label>
          <input type="number" class="form-control" id="target" placeholder="Position" formControlName="position">
        </div>

        <div class="btn-group">
          <button type="submit" class="btn btn-primary">Save</button>
          <button routerLink="/config/category" class="btn btn-danger" type="button">Cancel</button>
        </div>

      </form>
    </div>
  </div>

  <div class="loading-spinner" *ngIf="!isLoaded">
    <section>
      <app-loader></app-loader>
    </section>
  </div>
</div>
