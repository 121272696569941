import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AddEditCommTemplateModalState, AddEditCommTemplateModalStateModel } from './state/add-edit-comm-template-modal.state';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { SubmitAddEditCommTemplateModalAction, CloseAddEditCommTemplateModalAction } from './state/add-edit-comm-template-modal.actions';
import { validMergeTags } from '../../../../shared/models/communication/mergeTags';
import { TemplateType } from '../../../../shared/models/pharmacy/comm-template.model';

@Component({
  selector: 'app-add-edit-comm-template-modal',
  templateUrl: './add-edit-comm-template-modal.component.html',
  styleUrls: ['./add-edit-comm-template-modal.component.scss']
})
export class AddEditCommTemplateModalComponent implements OnInit {
  @Select(AddEditCommTemplateModalState.showModal) showModal$: Observable<boolean>;
  @Select(AddEditCommTemplateModalState.addEditCommTemplateModalState) addEditCommTemplateModalState$: Observable<AddEditCommTemplateModalStateModel>;
  templateTypesEnum = TemplateType;
  commTemplateForm: FormGroup;
  mergeTags = validMergeTags;
  @ViewChild('messageTextarea') messageTextareaElementRef: ElementRef;
  constructor(private formBuilder: FormBuilder, private store: Store) { }

  ngOnInit() {
    this.generateFormControls();
  }

  submit() {
    this.store.dispatch(new SubmitAddEditCommTemplateModalAction()).subscribe(() => this.resetToDefaults());
  }

  closeModal() {
    this.resetToDefaults();
    this.store.dispatch(new CloseAddEditCommTemplateModalAction());
  }

  insertTag(tag: string) {
    const text = this.formControls.message.value ?? '';
    const cursorPos = this.messageTextareaElementRef.nativeElement.selectionStart;
    var output = [text.slice(0, cursorPos), tag, text.slice(cursorPos)].join('');
    this.commTemplateForm.patchValue({ message: output});
  }

  isNumeric(val) {
    return Number(parseFloat(val)) === val;
  }

  get formControls() {
    return this.commTemplateForm.controls;
  }

  get templateType() {
    return this.commTemplateForm.value.templateType;
  }

  private generateFormControls() {
    this.commTemplateForm = this.formBuilder.group({
      id: new FormControl(null),
      name: new FormControl('', Validators.required),
      message: new FormControl(null, Validators.required),
      interval: new FormControl(1, [Validators.min(0)]),
      isActive: new FormControl(true, Validators.required),
      templateType: new FormControl(TemplateType.Custom, Validators.required),
      subject: new FormControl(null)
    });
  }

  private resetToDefaults() {
    this.commTemplateForm.reset();
    this.commTemplateForm.patchValue({
      id: null,
      name: '',
      message: '',
      interval: 1,
      isActive: true,
      subject: null,
      templateType: TemplateType.Custom
    });
  }
}
