import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { ScryptInsightComponent } from './scrypt-insight.component';
import { TooltipModule } from '../../modules/core/tooltip/tooltip.module';

@NgModule({
    imports: [TooltipModule],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    declarations: [ScryptInsightComponent],
    exports: [ScryptInsightComponent]
})
export class ScryptInsightModule {}
