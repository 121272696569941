import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { AslRelatedPerson, CarerType } from '../../state/asl.models';
import { Select } from '@ngxs/store';
import { ASLState } from '../../state/asl.state';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-asl-related-person-summary',
  templateUrl: './asl-related-person-summary.component.html',
  styleUrls: ['./asl-related-person-summary.component.scss']
})
export class AslRelatedPersonSummaryComponent implements OnInit {
  @Input()
  relatedPersonForm: FormGroup;

  @Input()
  relatedPerson: AslRelatedPerson;

  @Input()
  id: number;

  @Input()
  isAgent: boolean;

  @Output()
  removeClickEvent = new EventEmitter<number>();

  @Output()
  updatePrimaryContactEvent = new EventEmitter<{id: number; isAgent: boolean}>();

  @Output()
  resetFormEvent = new EventEmitter();

  @Output()
  saveEvent = new EventEmitter();

  @Select(ASLState.isSubmitting)
  isSubmitting$: Observable<boolean>;

  channelForInviteSelect: string;
  isEditing = false;
  carerTypes = CarerType;
  carerType: CarerType;
  selectedContact: string;

  constructor() {
  }

  ngOnInit(): void {
    this.carerType = this.relatedPersonForm.get('carerType').value;
    this.selectedContact = this.relatedPerson.email ? 'email' : 'mobileNumber';
    this.updateCarer();
  }

  handleEditClick(): void {
    this.isEditing = true;
  }

  exitEditMode(): void {
    this.isEditing = false;
    this.resetFormEvent.emit();
  }

  get formDisabled(): boolean {
    return this.relatedPersonForm.invalid;
  }

  updateCarer(): void {
    this.carerType = this.relatedPersonForm.get('carerType').value;
    switch (this.carerType) {
      case CarerType.INDIVIDUAL:
        this.relatedPersonForm.get('firstName').setValidators([Validators.required]);
        this.relatedPersonForm.get('surname').setValidators([Validators.required]);
        this.relatedPersonForm.get('relationship').setValidators([Validators.required]);
        this.relatedPersonForm.get('organisation').clearValidators();
        break;
      case CarerType.ORGANISATION:
        this.relatedPersonForm.get('organisation').setValidators([Validators.required]);
        this.relatedPersonForm.get('firstName').clearValidators();
        this.relatedPersonForm.get('surname').clearValidators();
        this.relatedPersonForm.get('relationship').clearValidators();
        break;
    }
    this.relatedPersonForm.get('organisation').updateValueAndValidity();
    this.relatedPersonForm.get('firstName').updateValueAndValidity();
    this.relatedPersonForm.get('surname').updateValueAndValidity();
    this.relatedPersonForm.get('relationship').updateValueAndValidity();
  }

  updatePrimaryContact(): void {
    const primaryChecked = this.relatedPersonForm.get('primary').value;
    if (primaryChecked) {
      this.updatePrimaryContactEvent.emit({id: this.id, isAgent: this.isAgent});
    }
  }

  updateChannel(event: any): void {
    if (event.value === 'mobileNumber') {
      this.relatedPersonForm.get('email').clearValidators();
      this.relatedPersonForm.get('email').setValue(null);
      this.relatedPersonForm.get('mobileNumber').setValidators([Validators.required, Validators.pattern('04[0-9]{8}')]);
    } else if (event.value === 'email') {
      this.relatedPersonForm.get('mobileNumber').clearValidators();
      this.relatedPersonForm.get('mobileNumber').setValue(null);
      this.relatedPersonForm.get('email').setValidators([Validators.required, Validators.email]);
    }
    this.relatedPersonForm.get('mobileNumber').updateValueAndValidity();
    this.relatedPersonForm.get('email').updateValueAndValidity();
    this.selectedContact = event.value;
  }

  removeRelatedPerson(): void {
    this.removeClickEvent.emit(this.id);
  }

  save(): void {
    this.saveEvent.emit();
  }
}
