import { State, Selector, StateContext, Action } from '@ngxs/store';
import { SetTotalItemsAndCompletedItems } from './consent-eligibility-form.actions';
import { Injectable } from '@angular/core';

export class ConsentEligibilityFormStateModel {
  totalItems: number;
  completedItems: number;
}
@Injectable()
@State<ConsentEligibilityFormStateModel>({
  name: 'consentEligibilityForm',
  defaults: {
    completedItems: 0,
    totalItems: 0
  }
})
export class ConsentEligibilityFormState {

  @Selector()
  static totalItems({ totalItems }: ConsentEligibilityFormStateModel) { return totalItems; }

  @Selector()
  static completedItems({ completedItems }: ConsentEligibilityFormStateModel) { return completedItems; }

  @Action(SetTotalItemsAndCompletedItems)
  setProgress({ patchState }: StateContext<ConsentEligibilityFormStateModel>, { totalItems, completedItems }: SetTotalItemsAndCompletedItems) {
    patchState({ totalItems, completedItems });
  }
}
