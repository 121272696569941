import { Component, OnInit, OnDestroy } from '@angular/core';
import { HMRChildForm } from '../hmr-child-form';
import { FormGroup, FormBuilder, FormControl, Validators, AbstractControl } from '@angular/forms';
import { Subscription, Observable } from 'rxjs';
import { Store, Actions, Select } from '@ngxs/store';
import { HMRPharmacyInformationState } from './state/hmr-pharmacy-information.state';
import { SetTotalItemsAndCompletedItems } from './state/hmr-pharmacy-information.actions';
import * as moment_ from 'moment';
const moment = moment_;
import { PharmacistState } from '../../../../pharmacy/state/pharmacist.state';
import { Pharmacist } from '../../../../../shared/models/pharmacy/pharmacist.model';
import { SixCpaState } from '../../state/six-cpa.state';

function dateValidator(control: AbstractControl): { [key: string]: any } | null {
  const dateFrom = moment(new Date()).subtract(12, 'months').endOf('month').format('YYYY-MM-DD');
  const valid = moment(control.value).isAfter(dateFrom);
  return valid ? null : { invalidDate: true };
}
const controlsToIgnore = ['pharmacistGivenName', 'pharmacistFamilyName', 'pharmacist'];
@Component({
  selector: 'app-hmr-pharmacy-information',
  templateUrl: './hmr-pharmacy-information.component.html',
  styleUrls: ['./hmr-pharmacy-information.component.scss']
})
export class HMRPharmacyInformationComponent extends HMRChildForm implements OnInit, OnDestroy  {
  @Select(PharmacistState.pharmacists) pharmacists$: Observable<any>;

  hmrPharmacyInformationForm: FormGroup;
  formSubscription: Subscription;
  private readonlySubscription: Subscription;
  constructor(private formBuilder: FormBuilder, private store: Store, actions: Actions) {
    super(actions);
    this.generateFormControls();
  }

  ngOnInit() {
    const pharmacistList = this.store.selectSnapshot(PharmacistState.pharmacists);
    if (pharmacistList.length > 0) {
      this.setupPharmacistData(pharmacistList);
    }
    this.formSubscription = this.store.select(HMRPharmacyInformationState.hmrPharmacyInformationForm)
      .subscribe(() => this.onFormChange());
    this.readonlySubscription = this.store.select(SixCpaState.formsReadOnly)
      .subscribe(readonly => readonly ? this.hmrPharmacyInformationForm.disable() : this.hmrPharmacyInformationForm.enable());
  }

  ngOnDestroy() {
    if (this.formSubscription) {this.formSubscription.unsubscribe(); }
    if (this.readonlySubscription) {this.readonlySubscription.unsubscribe(); }
  }
  get formControls() {
    return this.hmrPharmacyInformationForm.controls;
  }
  protected actionOnResetForm() {
    const controls = Object.keys(this.hmrPharmacyInformationForm.controls);
    controls.filter((key) => {
      if (!controlsToIgnore.includes(key)) {
        this.hmrPharmacyInformationForm.controls[key].setValue('');
      }
    });
    this.hmrPharmacyInformationForm.patchValue(
      {
        dateOfService: new Date(),
        dateCompletedReportSentToGP: new Date()
      });
  }

  setPharmacistData() {
    const pharmacist = this.store.selectSnapshot(PharmacistState.pharmacists)
      .filter((x) => `${x.givenNames} ${x.familyName}` === this.hmrPharmacyInformationForm.value.pharmacist);
    this.setupPharmacistData(pharmacist);
  }

  private setupPharmacistData (pharmacistList: Pharmacist[]) {
    if (pharmacistList.length > 0) {
      const { givenNames, mrnOrSHPAAccreditationNumber, familyName } = pharmacistList[0];
      this.hmrPharmacyInformationForm.patchValue({
        pharmacist: `${givenNames} ${familyName}`,
        pharmacistGivenName: givenNames,
        pharmacistFamilyName: familyName,
        accreditationNumber: mrnOrSHPAAccreditationNumber
       });
    }
  }

  private onFormChange() {
    const controls = Object.keys(this.hmrPharmacyInformationForm.controls);
    const completedItems = this.getTotalValidItems(controls);
    this.store.dispatch(new SetTotalItemsAndCompletedItems(controls.length, completedItems));
  }

  private getTotalValidItems(controls: Array<string>) {
    return controls.filter((key) => {
      const formControl = this.hmrPharmacyInformationForm.get(key);
      return  formControl.status === 'VALID' || (formControl.status === 'DISABLED' && !formControl.invalid && formControl.errors === null);
    }).length;
  }

  private generateFormControls() {
    this.hmrPharmacyInformationForm = this.formBuilder.group({
      pharmacist: new FormControl('', Validators.required),
      accreditationNumber: new FormControl('', Validators.required),
      pharmacistGivenName: new FormControl('', Validators.required),
      pharmacistFamilyName: new FormControl('', Validators.required),
      dateOfService: new FormControl(new Date(), [Validators.required, dateValidator]),
      dateCompletedReportSentToGP: new FormControl(new Date(), Validators.required),
    });
  }


}
