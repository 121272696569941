<ng-container *ngIf="(state$ | async) as state">
    <form class="upload-form">
      <label class="input-label-not-required mb-0" for="consent-document">
        {{name || 'Upload document form'}}
      </label>
      <p class="upload-note">
        <span class="info-icon" style="font-size: 20px;">
          <span class="fa-stack fa-1x">
            <i class="fas fa-circle fa-stack-2x"></i>
            <i class="fas fa-info fa-stack-1x"></i>
          </span>
        </span>
        <strong>Note:</strong>
        &nbsp;You can upload these files later in the services tab of the patients profile.
      </p>
      <div class="file-input">
        <input [disabled]="disabled" (change)="fileChosen($event)" type="file" class="form-control-file"
          name="consent document" #consentDocument id="consent-document" multiple style="display:none" />
        <label class="btn btn-outline-secondary" style="margin: 2px;" for="consent-document">Browse </label>&nbsp;
        <span *ngIf="files && files.length">
          <small> {{files.length}} file(s) selected
            <i class="fas fa-times-circle remove" (click)="clearSelection()">
            </i>
          </small>
        </span>
      </div>
      <label class="upload-notes" for="consent-document-notes">
        <strong>Notes</strong> (Optional)
      </label>
      <textarea name="notes" [(ngModel)]="notes" [disabled]="disabled" placeholder="Please add details (optional)"
        type="text" id="consent-document-notes" class="upload-notes-input"></textarea>
      <button (click)="uploadFile()" [disabled]="disabled" class="upload-button">
        Upload files
      </button>
      <div class="uploaded-files" *ngIf="isDocumentListDisplay">
        <ng-container *ngFor="let file of state.documents">
          <ng-container [ngSwitch]="file.progressStatus.status">
            <div class="file" *ngSwitchCase="statusType.START">
              <div class="bar">
                <mat-progress-bar mode="determinate" [value]="file.progressStatus.percentage"></mat-progress-bar>
              </div>
            </div>
            <div class="file" *ngSwitchCase="statusType.IN_PROGRESS">
              <div class="bar">
                <mat-progress-bar *ngIf="file.progressStatus.percentage < 100" mode="determinate"
                  [value]="file.progressStatus.percentage"></mat-progress-bar>
                <mat-progress-bar *ngIf="file.progressStatus.percentage === 100" mode="indeterminate"></mat-progress-bar>
              </div>
              <div *ngIf="file.progressStatus.percentage < 100">
                <button class="cancel-button" (click)="cancelDownload(file.uploadId)">Cancel</button>
              </div>
            </div>
            <div class="file file-error" *ngSwitchCase="statusType.ERROR">
              <div>Error uploading file:</div>
              <div>{{file.error.error}}</div>
            </div>
            <div class="file" *ngSwitchCase="statusType.COMPLETE">
              <div>{{file.document.documentName || file.document.scryptDocumentName}}
                <i class="fas fa-times-circle remove" (click)="deleteFile(file)"
                  [disabled]="disabled"></i>
              </div>
              <small *ngIf="file.document.notes">Notes: {{file.document.notes}}</small>
              <div class="file file-error" *ngIf="file.error">
                <div>Error deleting file:</div>
                <div>{{file.error.error}}</div>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </form>
  </ng-container>
  