import {
  State,
  Action,
  StateContext,
  Selector,
  Store
} from '@ngxs/store';
import {
  ToggleScriptActionsAction,
  AddDosageRequestAction,
  AddDosageErrorAction,
  CreateMetaRequestAction,
  CreateMetaErrorAction,
  UpdateScriptActionsAction,
  ResetMetasRequestAction,
  UpdateScriptPausedState,
  ResetScriptToDefault,
} from './script-actions.actions';
import { ScriptService } from '../../../../shared/services/script.service';
import {
  ScriptLoadingStartAction,
  UpdateScriptAction,
  ScriptLoadingStopAction,
  ScriptsLoadingStartAction,
  ScriptsLoadingStopAction,
  ResetScriptsAction,
} from './categories.actions';
import { catchError, mergeMap, tap } from 'rxjs/operators';
import { ProfileState } from '../../../core/profile/state/profile.state';
import { GetClientNotes } from '../../notes/state/notes.actions';
import { Injectable } from '@angular/core';
import { Medication } from '../../../../shared/models/script/chartviewitem.model';
import { DosageForm } from './categories.state';

export class ScriptActionsStateModel {
  script: Medication;
}
@Injectable()
@State<ScriptActionsStateModel>({
  name: 'scriptActions',
  defaults: {
    script: { cMeta: { id: null } }
  }
})
export class ScriptActionsState {
  @Selector()
  static isOpen(state: ScriptActionsStateModel) {
    return (script: any) => state.script.cMeta.id === script.cMeta.id;
  }

  @Selector()
  static script(state: ScriptActionsStateModel) {
    return state.script;
  }

  constructor(private scriptService: ScriptService, private store: Store) { }

  @Action(UpdateScriptActionsAction)
  updateScript(ctx: StateContext<ScriptActionsStateModel>, action: UpdateScriptActionsAction) {
    ctx.patchState({ script: action.script });
  }

  @Action(ResetScriptToDefault)
  ResetScriptToDefault(ctx: StateContext<ScriptActionsStateModel>, action: ResetScriptToDefault) {
    ctx.patchState({
      script: {
        cMeta: {
          id: null
        }
      }
    });
  }

  @Action(ToggleScriptActionsAction)
  toggle(ctx: StateContext<ScriptActionsStateModel>, action: ToggleScriptActionsAction) {
    const state = ctx.getState();
    if (state.script && state.script.cMeta.id !== action.script.cMeta.id) {
      ctx.patchState({ script: action.script });
    } else {
      ctx.patchState({ script: { cMeta: { id: null } } });
    }
  }

  @Action(AddDosageRequestAction)
  addDosage(ctx: StateContext<ScriptActionsStateModel>, action: AddDosageRequestAction) {
    const state = ctx.getState();
    const script = state.script;
    ctx.dispatch(new ScriptLoadingStartAction(script));
    return this.scriptService.addDosage(this.buildAddDosageRequest(script, action.dosageForm)).pipe(
      tap((response) => {
        ctx.patchState({ script: response })
        ctx.dispatch(new UpdateScriptAction(response))
      }),
      catchError((error) => ctx.dispatch(new AddDosageErrorAction(error, script)))
    )
  }

  @Action(AddDosageErrorAction)
  addDosageError(ctx: StateContext<ScriptActionsStateModel>, action: AddDosageErrorAction) {
    return ctx.dispatch(new ScriptLoadingStopAction(action.script));
  }

  @Action(CreateMetaRequestAction)
  createMeta(ctx: StateContext<ScriptActionsStateModel>, action: CreateMetaRequestAction) {
    const clientId = this.store.selectSnapshot(ProfileState.clientId);
    ctx.dispatch(new ScriptLoadingStartAction(action.script));
    return this.scriptService.createMeta(this.buildCreateMetaRequest(action.script, action.metaForm)).pipe(
      mergeMap((response) => ctx.dispatch(
        [
          new UpdateScriptAction(response),
          new GetClientNotes(clientId),
        ]
      )),
      catchError((error) => ctx.dispatch(new CreateMetaErrorAction(error, [action.script])))
    );
  }

  @Action(ResetMetasRequestAction)
  resetMetasMeta(ctx: StateContext<ScriptActionsStateModel>, { scripts }: ResetMetasRequestAction) {
    ctx.dispatch(new ScriptsLoadingStartAction(scripts));
    const selectedScriptsIds = scripts.map(x => x.cMeta.id);
    return this.scriptService.resetMetas(selectedScriptsIds).pipe(
      mergeMap(() => ctx.dispatch(new ResetScriptsAction(scripts))),
      catchError((error) => ctx.dispatch(new CreateMetaErrorAction(error, scripts)))
    );
  }

  @Action(CreateMetaErrorAction)
  createMetaError(ctx: StateContext<ScriptActionsStateModel>, { scripts }: CreateMetaErrorAction) {
    return ctx.dispatch(new ScriptsLoadingStopAction(scripts));
  }

  @Action(UpdateScriptPausedState)
  updateScriptPausedState(ctx: StateContext<ScriptActionsStateModel>, { paused }: UpdateScriptPausedState) {
    const state = ctx.getState();
    let script = state.script;
    script.cMeta.isPaused = paused;
    ctx.patchState({
      script: script
    });
  }

  private buildAddDosageRequest(script, dosageForm: DosageForm) {
    return {
      metaId: script.cMeta.id,
      frequency: dosageForm.frequency,
      dosesPerDay: dosageForm.dosesPerDay,
      effectiveFrom: dosageForm.effectiveFrom,
      doh: dosageForm.doh
    };
  }

  private buildCreateMetaRequest(script: Medication, metaForm) {
    return {
      scriptMetaId: script.cMeta.id,
      tenantCustomerId: metaForm.tenantCustomerId,
      genericCategoryId: script.genericCategoryId,
      productDispensedId: script.productDispensedId,
      categoryId: metaForm.categoryId,
      frequency: metaForm.frequency,
      dosesPerDay: script.quantity / metaForm.frequency,
      lastDispensed: script.dispensedDate,
      hasUncategorized: metaForm.hasUncategorized,
      startDate: metaForm.startDate,
      doh: metaForm.doh
    };
  }
}
