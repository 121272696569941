import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScriptService } from '../../../../shared/services/script.service';
import { StockAdjustmentComponent } from './stock-adjustment.component';
import { FormsModule } from '@angular/forms';
import { NgxsModule } from '@ngxs/store';
import { StockAdjustmentState } from './state/stock-adjustment.state';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';

@NgModule({
  declarations: [
    StockAdjustmentComponent
  ],
  providers: [
    ScriptService
  ],
  imports: [
    CommonModule,
    FormsModule,
    BsDatepickerModule.forRoot(),
    NgxsModule.forFeature([StockAdjustmentState])
  ],
  exports: [
    StockAdjustmentComponent
  ],
})
export class StockAdjustmentModule { }
