import { PipeTransform, Pipe } from '@angular/core';
import * as moment from 'moment';
import { defaultDateFormat } from './date-in-future.config';

@Pipe({
  name: 'dateInFuture'
})
export class DateInFuturePipe implements PipeTransform {

  constructor() { }

  transform(value: Date): string {
    return moment(value).utcOffset(0, true).calendar();
  }
}
