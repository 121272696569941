import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmailComponent } from './email.component';
import { EmailService } from '~/shared/services/email.service';
import { LoaderModule } from '~/modules/core/loader/loader.module';
import { EmailHistoryComponent } from './email-history/email-history.component';
import { AddEmailComponent } from './add-email/add-email.component';
import { FormsModule } from '@angular/forms';
import { NotificationModule } from '~/shared/components/notification/notification.module';
import { TimezoneModule } from '~/modules/core/timezone/timezone.module';
import { EmailHistoryModalComponent } from './email-history-modal/email-history-modal.component';
import { NgxsModule } from '@ngxs/store';
import { EmailState } from './state/email.state';
import { MessageModule } from '../message/message.module';
import { NgxAutoScrollModule } from 'ngx-auto-scroll';

@NgModule({
  declarations: [
    EmailComponent,
    EmailHistoryComponent,
    AddEmailComponent,
    EmailHistoryModalComponent
  ],
  providers: [
    EmailService
  ],
  imports: [
    TimezoneModule,
    CommonModule,
    FormsModule,
    LoaderModule,
    NotificationModule,
    NgxsModule.forFeature([EmailState]),
    MessageModule,
    NgxAutoScrollModule
  ],
  exports: [
    EmailComponent
  ]
})
export class EmailModule { }
