import { ComponentFactoryResolver, Injectable } from "@angular/core";
import { HasViewContainerRef } from "./has-view-container-ref.interface";

@Injectable()
export abstract class MedsCheckResolvedForm {
  abstract formHost: HasViewContainerRef;
  constructor(
    protected componentFactoryResolver: ComponentFactoryResolver
  ) { }

  protected resolveFormComponent(isDetailed: boolean) {
    const component = this.getFormComponent(isDetailed);
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(component);
    const viewContainerRef = this.formHost.viewContainerRef;
    viewContainerRef.clear();
    viewContainerRef.createComponent(componentFactory);
  }

  protected abstract getFormComponent(isDetailed: boolean);
}