import { State, Action, StateContext, Selector } from '@ngxs/store';
import { GetCustomerSignUp, SignUpError, GetCustomerSignUpSuccess, CreateAccount, CreateAccountError, CreateAccountSuccess } from './sign-up.actions';
import { SignUpService } from '../services/sign-up.service';
import { tap, catchError, finalize } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Injectable, NgZone } from '@angular/core';

export class SignUpStateModel {
  public customer: any;
  public error: any;
  public validating: boolean;
}
@Injectable()
@State<SignUpStateModel>({
  name: 'signUp',
  defaults: {
    customer: null,
    error: null,
    validating: false
  }
})
export class SignUpState {
  constructor(private signUpService: SignUpService, private router: Router, private ngZone: NgZone) { }

  @Selector()
  static error(state: SignUpStateModel) { return state.error; }

  @Selector()
  static validating(state: SignUpStateModel) { return state.validating; }

  @Selector()
  static state(state: SignUpStateModel) { return state; }

  @Selector()
  static customerProfileId(state: SignUpStateModel) { 
    return state.customer.customerProfileId 
  }

  @Action(GetCustomerSignUp)
  getCustomerSignUp(ctx: StateContext<SignUpStateModel>, { linkId }: GetCustomerSignUp) {
    ctx.patchState({ validating: true });
    return this.signUpService.getCustomerByLink(linkId).pipe(
      tap(response => ctx.dispatch(new GetCustomerSignUpSuccess(response))),
      catchError(err => ctx.dispatch(new SignUpError(err))),
      finalize(() => ctx.patchState({ validating: false }))
    );
  }

  @Action(GetCustomerSignUpSuccess)
  setDetailsForPPAOnboarding(ctx: StateContext<SignUpStateModel>, { customer }: GetCustomerSignUpSuccess) {
    ctx.patchState({
      customer
    });
  }

  @Action(SignUpError)
  signUpError(ctx: StateContext<SignUpStateModel>, { error }: SignUpError) {
    ctx.patchState({
      error
    });
  }

  @Action(CreateAccount)
  createAccount(ctx: StateContext<SignUpStateModel>, { createAccountRequest }: CreateAccount) {
    ctx.patchState({ validating: true, error: null });
    return this.signUpService.createAccount(createAccountRequest).pipe(
      tap(response => ctx.dispatch(new CreateAccountSuccess())),
      catchError(err => ctx.dispatch(new CreateAccountError(err))),
      finalize(() => ctx.patchState({ validating: false }))
    );
  }

  @Action(CreateAccountSuccess)
  createAccountSuccess(ctx: StateContext<SignUpStateModel>, {  }: CreateAccountSuccess) {
    return this.ngZone.run(() =>this.router.navigate(['signup/success']));
  }

  @Action(CreateAccountError)
  createAccountError(ctx: StateContext<SignUpStateModel>, { error }: CreateAccountError) {
    ctx.patchState({ error: JSON.stringify(error.error) });
  }
}
