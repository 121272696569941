import { QueueDispenseRequestModel } from "@base/shared/models/dispense/queueDispenseRequest.model";
import { EScriptRequest } from "../../../../shared/models/zdispense/eScriptRequest.interface";

export enum EscriptsActions {
  TOGGLE_ESCRIPT_MODAL = '[Escripts] Toggle',
  DISPENSE_ESCRIPT = '[Escripts] Dispense',
  DISPENSE_ESCRIPT_SUCCESS = '[Escripts] Success',
  ESCRIPT_ERROR = '[Escripts] Error',
  Set_TOKEN = '[Escripts] Set Token',
  SET_ESCRIPT_NOTE = '[Escripts] Set Note'
}

export class ToggleEScriptModal {
  static readonly type = EscriptsActions.TOGGLE_ESCRIPT_MODAL;
  constructor(public showModal: boolean) { }
}

export class SetEScriptToken {
  static readonly type = EscriptsActions.Set_TOKEN;
  constructor(public token: string) { }
}

export class SetEScriptNote {
  static readonly type = EscriptsActions.SET_ESCRIPT_NOTE;
  constructor(public noteId: number) { }
}

export class DispenseEScript {
  static readonly type = EscriptsActions.DISPENSE_ESCRIPT;
  constructor(public request: QueueDispenseRequestModel) { }
}

export class EscriptSuccess {
  static readonly type = EscriptsActions.DISPENSE_ESCRIPT_SUCCESS;
  constructor(public response: any) { }
}

export class EscriptError {
  static readonly type = EscriptsActions.ESCRIPT_ERROR;
  constructor(public error: any) { }
}
