import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Select, Store } from '@ngxs/store';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { ProfileState } from '../../../../core/profile/state/profile.state';
import { slideRight } from '../../../../../shared/core/animations';
import { ToggleSixCpaContentAction } from '../../state/six-cpa.actions';
import { TogglePatientConfirm } from '../air-lookup-form/state/air-lookup-form.actions';
import { AIREmailConstants } from '../constants/air-email.constants';
import {
  SendStatementRequest,
  SendStatementSmsRequest,
} from '../models/send-statement-request.model';
import {
  ClearAIRHistoryStatement,
  DownloadAIRHistoryStatement,
  SendAIRHistoryStatement,
  SendAIRHistoryStatementSms,
} from './state/air-statement.actions';
import { AIRStatementState } from './state/air-statement.state';
import { Subscription } from 'rxjs/Subscription';
import { SmsService } from '../../../../../shared/services/sms.service';
import { MarketingSetting } from '../../../../../shared/models/marketingSetting.model';
import { MessageType } from '../../../../../shared/models/messageType.model';

@Component({
  selector: 'app-air-statement',
  templateUrl: './air-statement.component.html',
  styleUrls: ['./air-statement.component.scss'],
  animations: [slideRight],
})
export class AIRStatementComponent implements OnInit {
  @Select(AIRStatementState.slideMode) slideMode$: Observable<any>;

  @Select(AIRStatementState.loading) loading$: Observable<any>;
  @Select(AIRStatementState.statement) sendStatement$: Observable<any>;

  airStatementForm: FormGroup;
  airStatementSmsForm: FormGroup;

  templateSubscription: Subscription;
  messageTypes: MessageType[] = [];

  constructor(
    private store: Store,
    private formBuilder: FormBuilder,
    private smsService: SmsService
  ) {}

  ngOnInit(): void {
    this.fetchTemplates();
    this.initFormControls();
  }

  private fetchTemplates() {
    const clientId = this.store.selectSnapshot(ProfileState.clientId);
    this.templateSubscription = this.smsService
      .getMessageTypes(clientId)
      .subscribe(
        (marketingSettings: MarketingSetting[]) => {
          this.messageTypes = marketingSettings.map((setting) => ({
            id: `${setting.id}`,
            title: setting.key,
            message: setting.messageTemplate,
          }));
        },
        (error) => {
          console.log(`Could not fetch templates: ${error}`);
        }
      );
  }

  onMessageTypeChange() {
    const messageType = this.messageTypes.find(
      (c) => c.id === this.airStatementSmsForm.value.messageTypeId.toString()
    );

    const message = messageType ? messageType.message : '';
    this.airStatementSmsForm.value.body = message;
  }

  get f() {
    return this.airStatementForm.controls;
  }

  private initFormControls() {
    const client = this.store.selectSnapshot(ProfileState.client);
    this.airStatementForm = this.formBuilder.group({
      email: new FormControl(client.patient.emailAddress, [
        Validators.required,
        Validators.email,
      ]),
    });
    this.airStatementSmsForm = this.formBuilder.group({
      body: new FormControl(AIREmailConstants.body),
      messageTypeId: new FormControl(0),
      mobile: new FormControl(client.patient.mobileNumber, [
        Validators.required,
      ]),
    });
  }

  send() {
    if (!this.airStatementForm.valid) return;
    const emailAddress = this.f.email.value;
    const statement = this.store.selectSnapshot(AIRStatementState.statement);
    const request: SendStatementRequest = {
      destination: emailAddress,
      subject: `${AIREmailConstants.subject} - ${this.getSubject()}`,
      body: AIREmailConstants.body,
      attachment: {
        fileName: `${this.getSubject()}.pdf`,
        fileType: statement.mimeType,
        base64Content: statement.fileContent,
      },
    };

    this.store.dispatch(new SendAIRHistoryStatement(request));
  }

  sendSms() {
    if (!this.airStatementSmsForm.valid) return;
    const { mobile, body } = this.airStatementSmsForm.value;

    const request: SendStatementSmsRequest = {
      mobile: mobile,
      documentId: this.store.selectSnapshot(AIRStatementState.documentId),
      body: body ? body : AIREmailConstants.body,
    };

    this.store.dispatch(new SendAIRHistoryStatementSms(request));
  }

  private getSubject() {
    const client = this.store.selectSnapshot(ProfileState.client);

    let documentName = '';
    if (client.patient.firstname) {
      documentName = `${client.patient.firstname}`;
    }
    if (client.patient.surname) {
      documentName = `${documentName} ${client.patient.surname}`;
    }

    return `${documentName} - ${moment(new Date()).format('DD-MM-YYYY')}`;
  }

  donwloadStatement() {
    this.store.dispatch(new DownloadAIRHistoryStatement());
  }

  close() {
    this.store.dispatch(new ClearAIRHistoryStatement());
    this.airStatementForm.reset();
    this.store.dispatch([
      new ToggleSixCpaContentAction('in'),
      new TogglePatientConfirm('out'),
    ]);
  }
}
