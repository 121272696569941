<form [formGroup]="hmrAccreditedPharmacistDetailsForm"
  ngxsForm="hmrAccreditedPharmacistDetails.hmrAccreditedPharmacistDetailsForm" class="p-4">
  <ng-container *ngIf="(pharmacists$ | async) as pharmacists">
    <div class="form-group pb-1">
      <label for="pharmacist" class="input-label">Select Pharmacist</label>
      <select [class.has-error]="formControls.pharmacist.invalid && (showInvalid$ | async)" formControlName="pharmacist"
        class="form-control form-control-lg w-50" (change)="setPharmacistData()">
        <option *ngFor="let p of pharmacists" value="{{p.givenNames}} {{p.familyName}}">{{p.givenNames}}
          {{p.familyName}}</option>
      </select>
    </div>
  </ng-container>
  <div class="form-group pb-1">
    <label class="input-label" for="accreditationNumber">MRN/SHPA Accreditation Number</label>
    <input type="text" [class.has-error]="formControls.accreditationNumber.invalid && (showInvalid$ | async)"
      class="form-control form-control-lg w-25" formControlName="accreditationNumber" value="" id="accreditationNumber">
  </div>
  <div class="form-group pb-1">
    <label class="input-label" for="pharmacistGivenName">Accredited Pharmacist Given Name(s)</label>
    <input [class.has-error]="formControls.pharmacistGivenName.invalid && (showInvalid$ | async)" type="text"
      class="form-control form-control-lg w-25" formControlName="pharmacistGivenName" value="" id="pharmacistGivenName"
      readonly>
  </div>
  <div class="form-group pb-1">
    <label class="input-label" for="pharmacistFamilyName">Accredited Pharmacist Family Name</label>
    <input type="text" [class.has-error]="formControls.pharmacistFamilyName.invalid && (showInvalid$ | async)"
      class="form-control form-control-lg w-25" formControlName="pharmacistFamilyName" value=""
      id="pharmacistFamilyName" readonly>
  </div>
  <div class="form-group pb-1">
    <label class="input-label" for="pharmacistPhoneNumber">Accredited Pharmacist Phone Number</label>
    <input type="text" [class.has-error]="formControls.pharmacistPhoneNumber.invalid && (showInvalid$ | async)"
      class="form-control form-control-lg w-25" formControlName="pharmacistPhoneNumber" value=""
      id="pharmacistPhoneNumber" readonly>
  </div>
  <div class="form-group pb-1">
    <label class="input-label" for="pharmacistEmail">Accredited Pharmacist Email Address</label>
    <input type="text" [class.has-error]="formControls.pharmacistEmail.invalid && (showInvalid$ | async)"
      class="form-control form-control-lg w-25" formControlName="pharmacistEmail" value="" id="pharmacistEmail"
      readonly>
  </div>
</form>