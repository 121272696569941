<div class="column-table">
  <div class="d-flex p-3 align-items-center">
    <span class="font__bold table-title">{{ title }}</span>
    <span class="ml-5">Total: <span class="font__bold" *ngIf="data.list">{{ data.list.length }} {{ totalText}}</span></span>
  </div>
  <div>
    <div class="input-group ">
      <input type="text" class="form-control filter" (input)="textChanged()" [(ngModel)]="filterText" [placeholder]="filterTitle" aria-label="Filter actions" aria-describedby="basic-addon1">
      <div class="input-group-prepend">
        <span class="input-group-text icon" id="basic-addon1"><i class="fas fa-search"></i></span>
      </div>
    </div>
  </div>
  <div g-xs="mx1">
    <table class="table" *ngIf="data.list">
      <thead>
        <tr>
          <th *ngFor="let header of data.headers" (click)="sort(header)"
          [ngClass]="{'not-active': property !== header}" class="font__title-1">
            {{ header }} <i class="far" g-xs="ml1" [ngClass]="getArrow(header)" aria-hidden="true"></i>
          </th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let row of data.list | appSort: args">
          <td (click)="handleColumnClick(header,row)" *ngFor="let header of data.headers" class="{{ row[header].class }} font__title-1">
            <ng-container *ngIf="!customerView; else customerViewTemplate">
              <div *ngIf="row[header].status" [ngClass]="row[header].status" class="status" g-xs="mr1"></div>
              <span *ngIf="row[header].icon" [innerHtml]="row[header].icon | appNoteTypeIcon: data.noteTypes"></span>
              {{ row[header].value  }}
            </ng-container>
            <ng-template #customerViewTemplate>
              <a routerLink="/profile/{{row.Raw.clientId}}">
                <div *ngIf="row[header].status" [ngClass]="row[header].status" class="status" g-xs="mr1"></div>
                <span *ngIf="row[header].icon" [innerHtml]="row[header].icon | appNoteTypeIcon: data.noteTypes"></span>
                {{ row[header].value  }}
              </a>
            </ng-template>
          </td>
          <td><span *ngIf="showIcons"><i class="far fa-angle-right"></i></span></td>
        </tr>
      </tbody>

    </table>
  </div>
</div>
