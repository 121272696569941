import { Component } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { PrettifiedTriggerType } from '../../../shared/constants/prettified-triggertype-constants';
import { TriggerType } from '../../../shared/models/pharmacy/trigger.model';
import { DispatchEventNotification, ToggleEventConfirmationModal } from './state/event-confirmation.actions';
import { EventConfirmationState, EventConfirmationStateModel } from './state/event-confirmation.state';

@Component({
  selector: 'app-event-confirmation-modal',
  templateUrl: './event-confirmation-modal.component.html',
  styleUrls: ['./event-confirmation-modal.component.scss']
})
export class EventConfirmationModalComponent {
  @Select(EventConfirmationState) eventConfirmation$: Observable<EventConfirmationStateModel>;

  constructor(private store: Store) { }
  close() {
    this.store.dispatch(new ToggleEventConfirmationModal(false));
  }

  confirm() {
    this.store.dispatch(new DispatchEventNotification());
  }

  getTriggerTypeAsEvent(triggerType: TriggerType): string {
    switch(triggerType) {
      case TriggerType.onboardedAsSOF : {
        return PrettifiedTriggerType.onboardedToAutoComms;
      }
      case TriggerType.orderConfirmed : {
        return PrettifiedTriggerType.orderConfirmed;
      }
      case TriggerType.orderOutForDelivery : {
        return PrettifiedTriggerType.orderOutForDelivery;
      }
      case TriggerType.orderReadyForCollection : {
        return PrettifiedTriggerType.orderReadyForCollection;
      }
      default: {
        return '';
      }
    }
  }

}
