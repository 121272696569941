<ng-container *ngFor="let stagedSupplyType of [stagedSupplyType$ | async]">
  <div [formGroup]="stagedSupplyFormList">
    <app-radio-highlight 
        controlName="patientRegistration"
        [isHighlighted]="stagedSupplyType === stagedSupplyTypes.PatientRegistration" 
        [fullWidth]="true"
        size="lg"
        >
      <input class="form-check-input" type="radio" name="stagedSupplyType" id="patientRegistration"
        formControlName="stagedSupplyType" [value]="stagedSupplyTypes.PatientRegistration"
        [checked]="stagedSupplyType == stagedSupplyTypes.PatientRegistration">
      <label class="form-check-label" for="patientRegistration">Patient Registration</label>
    </app-radio-highlight>
    <app-radio-highlight controlName="claim" size="lg" [isHighlighted]="stagedSupplyType === stagedSupplyTypes.Claim"
      [fullWidth]="true">
      <input class="form-check-input" type="radio" name="stagedSupplyType" id="claim" formControlName="stagedSupplyType"
        [value]="stagedSupplyTypes.Claim" [checked]="stagedSupplyType == stagedSupplyTypes.Claim">
      <label class="form-check-label" for="claim">Staged Supply Claim</label>
    </app-radio-highlight>
  </div>
  <ng-container [ngSwitch]="stagedSupplyType" *ngFor="let patientHasDiabetes of [patientHasDiabetes$ | async]">
    <p *ngSwitchCase="stagedSupplyTypes.PatientRegistration">It’s not currently possible for Staged Supply Patient
      Registrations to be automatically claimed via the <a (click)="openPPAPortal()">PPA portal.</a>
      Completing this form will export the Staged Supply Registration so it can be manually copied across.
    </p>
    <p *ngSwitchCase="stagedSupplyTypes.Claim">It’s not currently possible for Staged Supply Claims to be automatically
      claimed via the <a (click)="openPPAPortal()">PPA portal.</a>
      Completing this form will export the Staged Supply Claim so it can be manually copied across.</p>
  </ng-container>
  <div>
    <app-ss-patient-details-list-item></app-ss-patient-details-list-item>
    <app-ss-consent-eligibility-form-list-item></app-ss-consent-eligibility-form-list-item>
    <app-staged-supply-medication-profile></app-staged-supply-medication-profile>
    <div *ngIf="stagedSupplyType === stagedSupplyTypes.PatientRegistration">
      <app-ss-outcomes-and-actions-form-list-item></app-ss-outcomes-and-actions-form-list-item>
      <app-ss-medication-information-form-list-item></app-ss-medication-information-form-list-item>
    </div>
    <app-ss-additional-details-form-list-item *ngIf="stagedSupplyType === stagedSupplyTypes.Claim">
    </app-ss-additional-details-form-list-item>
  </div>

  <ng-container *ngIf="(sixCpaState$ | async) as sixCpaState">
    <div class="pt-4" *ngFor="let saving of [saving$ | async]">
      <ng-container *ngIf="!sixCpaState.readOnly">
        <app-loader class="loading" *ngIf="saving"></app-loader>
        <button class="button submit mr-3" [disabled]="saving"
          (click)="stagedSupplyType === stagedSupplyTypes.PatientRegistration ? submitStagedSupplyRegistration() : submitStagedSupplyClaim()">Complete
          Staged Supply</button>
        <button class="button save mr-3" [disabled]="saving"
          (click)="saveForm()">Save
          Staged Supply</button>
        <span (click)="openConfirmResetModal()" class="mr-3">Reset Form</span>
        <span (click)="downloadSchedulePdf()">Download schedule template sheet</span>
      </ng-container>
    </div>
    
    <app-confirm-reset *ngIf="sixCpaState.showResetModal" (closeEvent)="closeConfirmResetModal()"
      (submitEvent)="resetStagedSupplyFormList()">
    </app-confirm-reset>
  </ng-container>

</ng-container>
