import { ClinicalInterventionFormModel } from '~/shared/models/six-cpa/clinical-intervention-form.model';

export enum ClinicalInterventionActions {
  SUBMIT_CI = '[ClinicalIntervention] submit',
  SAVE_CI = '[ClinicalIntervention] save',
  RESET_CI = '[ClinicalIntervention] reset',
  RESET_CI_STATE_TO_DEFAULTS = '[ClinicalIntervention] reset state',
  UPDATE_CI = '[ClinicalIntervention] update',
  SAVE_SUCCESS = '[ClinicalIntervention] save success',
  SAVE_FAILED = '[ClinicalIntervention] save failed',
  GET_CI_FORM = '[ClinicalIntervention] Get',
  GET_SUCCESS = '[ClinicalIntervention] Get Success',
  GET_ERROR = '[ClinicalIntervention] Get Error',
  SUBMIT_EXISTING_CI = '[ClinicalIntervention] Submit Exisiting CI',
  SHOW_INVALID_CI = '[ClinicalIntervention] Show Invalid Form',

}

export class SubmitCIAction {
  static readonly type = ClinicalInterventionActions.SUBMIT_CI;
  constructor(public clientId: string, public formData: ClinicalInterventionFormModel) { }
}

export class SaveCIAction {
  static readonly type = ClinicalInterventionActions.SAVE_CI;
  constructor(public clientId: string, public formData: ClinicalInterventionFormModel) { }
}

export class ResetCIAction {
  static readonly type = ClinicalInterventionActions.RESET_CI;
  constructor(public onlyClearFormIds: boolean = false) { }
}

export class ResetCIToDefaults {
  static readonly type = ClinicalInterventionActions.RESET_CI_STATE_TO_DEFAULTS;
  constructor(public onlyClearFormIds: boolean = false) {}
}

export class SaveCISuccessAction {
  static readonly type = ClinicalInterventionActions.SAVE_SUCCESS;
  constructor(public formData: ClinicalInterventionFormModel) { }
}

export class SaveCIFormFailedAction {
  static readonly type = ClinicalInterventionActions.SAVE_FAILED;
  constructor(public error: any) { }
}

export class GetCIForm {
  static readonly type = ClinicalInterventionActions.GET_CI_FORM;
  constructor(public clientId: string, public sixCpaFormId: number) { }
}

export class GetCIFormSuccess {
  static readonly type = ClinicalInterventionActions.GET_SUCCESS;
  constructor(public cIForm: ClinicalInterventionFormModel) { }
}

export class GetCIFormError {
  static readonly type = ClinicalInterventionActions.GET_ERROR;
  constructor(public error: any) { }
}

export class UpdateCIForm {
  static readonly type = ClinicalInterventionActions.UPDATE_CI;
  constructor(public clientId: string, public formData: ClinicalInterventionFormModel) { }
}

export class SubmitExisitingCI {
  static readonly type = ClinicalInterventionActions.SUBMIT_EXISTING_CI;
  constructor(public clientId: string, public formData: ClinicalInterventionFormModel) { }
}


export class ShowInvalidCIForm {
  static readonly type = ClinicalInterventionActions.SHOW_INVALID_CI;
}
