import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store, Select } from '@ngxs/store';
import { CloseMessageCycleModal, RemoveMessageCycle, CreateNewMessageCycle } from '../state/message-cycle.actions';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import * as moment_ from 'moment';
const moment = moment_;
import { MessageCycleModel, IntervalType } from '../../../../../shared/models/communication/messageCycleModel';
import { CommunicationType } from '../../../../../shared/models/message.model';
import { ProfileState } from '../../../../core/profile/state/profile.state';
import { MessageCycleState } from '../state/message-cycle.state';
import { Observable, Subscription } from 'rxjs';
import { MessageType } from '../../../../../shared/models/messageType.model';
import { SmsService } from '../../../../../shared/services/sms.service';
import { AlertService } from '../../../../core/alert/alert.service';
import { MarketingSetting } from '../../../../../shared/models/marketingSetting.model';

const tomorrowsDate = moment(new Date()).add(1, 'days').toDate();

@Component({
  selector: 'app-message-cycle-modal',
  templateUrl: './message-cycle-modal.component.html',
  styleUrls: ['../../sms/add-sms/add-sms.component.scss']
})
export class MessageCycleModalComponent implements OnInit, OnDestroy {
  @Select(MessageCycleState.loading) loading$: Observable<boolean>;
  messageCycleForm: FormGroup;
  minDate = new Date();
  messageCycle: MessageCycleModel;
  intervalTypes = IntervalType;
  messageTypes: MessageType[] = [];
  smsTemplateSubscription: Subscription;
  clientId = this.store.selectSnapshot(ProfileState.clientId);

  constructor(
    private store: Store,
    private formBuilder: FormBuilder,
    private smsService: SmsService,
    private alertService: AlertService
  ) { }

  ngOnInit() {
    this.generateFormControls();
    this.initSubscription();
  }

  ngOnDestroy() {
    if (this.smsTemplateSubscription) { this.smsTemplateSubscription.unsubscribe() }
  }

  initSubscription() {
    this.smsTemplateSubscription = this.smsService.getMessageTypes(this.clientId).subscribe((marketingSettings: MarketingSetting[]) => {
      this.messageTypes = marketingSettings.map(setting => ({
        id: `${setting.id}`,
        title: setting.key,
        message: setting.messageTemplate
      }));
    }, (error) => {
      this.alertService.warn(error);
    });
  }

  updateMessageTemplateBody() {
    const messageTypeId = this.messageCycleForm.value.templateId;
    const messageType = this.messageTypes.find(c => c.id === messageTypeId);
    let value = '';
    if (messageType) { value = messageType.message; }
    this.messageCycleForm.patchValue({
      text: value
    });
  }

  closeModal() {
    this.messageCycleForm.reset();
    this.store.dispatch(new CloseMessageCycleModal());
  }

  submitMessageCycleForm() {
    this.store.dispatch(new CreateNewMessageCycle(this.buildMessageCycleRequestObject(this.messageCycleForm)));
  }

  removeMessageCycle(messageCycleId: number) {
    this.store.dispatch(new RemoveMessageCycle(messageCycleId));
  }

  private generateFormControls() {
    this.messageCycleForm = this.formBuilder.group({
      text: new FormControl('', Validators.required),
      intervalValue: new FormControl(1, Validators.required),
      intervalType: new FormControl(this.intervalTypes.day, Validators.required),
      startDate: new FormControl(tomorrowsDate, Validators.required),
      startTime: new FormControl('10:00', Validators.required),
      notes: new FormControl(''),
      templateId: new FormControl(0)
    });
  }

  private buildMessageCycleRequestObject(messageCycleForm: FormGroup): MessageCycleModel {
    const formattedStartDate = new Date(`${moment(messageCycleForm.value.startDate)
      .format('LL')} ${messageCycleForm.value.startTime}`);

    return this.messageCycle = {
      text: messageCycleForm.value.text,
      communicationType: CommunicationType.MessageCycleSMS,
      intervalValue: messageCycleForm.value.intervalValue,
      intervalType: messageCycleForm.value.intervalType,
      startDate: formattedStartDate,
      clientId: this.clientId,
      notes: messageCycleForm.value.notes
    }
  }
}
