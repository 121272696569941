import { Injectable } from '@angular/core';
import { State, Action, StateContext, Selector, Store } from '@ngxs/store';
import { tap, map } from 'rxjs/operators';
import { FilterCrmTable, FilterCustomers, FireSearch, GetCleanlinessData, GetCrmTable, GetCustomers, GetGaugeData, SetCustomers, SetSearch, UpdateGauge } from './dashboard.action';
import { Customer, CustomerData, CustomerDataPoint, CustomerItem } from '~/shared/models/dashboard/customer.model';
import { ClientRepository } from '~/modules/core/profile/client.repository';
import * as moment from 'moment';
import { getTenantTimezoneDateString } from '~/modules/core/timezone/logic/timezone-tenant.logic';
import { combineLatest } from 'rxjs';
import { CrmService } from '~/shared/services/crm.service';
import { NoteFullInfo } from '~/shared/models/crm/noteFullInfo.model';
import { makeFullName } from '~/shared/helpers/doctor-name.helper';
import { getClassesWithBold } from '~/modules/crm/crm-notification-date/crm-notification-date.logic';
import { NoteTypeEnum } from '~/shared/models/note/note-type.enum';
import { Note } from '~/shared/models/crm/note.model';
import { NoteType } from '~/shared/models/crm/noteType.model';
import { BimetricService } from '~/shared/services/bimetric.service';
import { Bimetric } from '~/shared/models/bimetric.model';
import { Chart } from 'angular-highcharts';
import { Router } from '@angular/router';

export interface DashboardCrmTable {
  list: any[],
  headers: string[],
  noteTypes: NoteType[]
}

export interface ChartData {
  name: string,
  value: number
}

export interface SearchData {
  firstName: string,
  lastName: string,
  phoneNumber: string
}

export interface DashboardStateModel {
  customerData: CustomerData;
  isCrmLoaded: boolean;
  isCustomerListLoaded: boolean;
  isDashboardLoaded: boolean;
  crmTable: DashboardCrmTable;
  cleanChart: Chart;
  daa: Bimetric[];
  sof: Bimetric[];
  daaChart: Chart;
  sofChart: Chart;
  searchData: SearchData;
}

@Injectable()
@State<DashboardStateModel>({
  name: 'dashboard',
  defaults: {
    customerData: undefined,
    isCrmLoaded: false,
    isCustomerListLoaded: false,
    isDashboardLoaded: false,
    crmTable: undefined,
    cleanChart: undefined,
    daa: undefined,
    sof: undefined,
    daaChart: undefined,
    sofChart: undefined,
    searchData: {
      firstName: '',
      lastName: '',
      phoneNumber: ''
    } as SearchData
  }
})

export class DashboardState {
  constructor(
    private clientRepository: ClientRepository,
    private crmService: CrmService,
    private bimetricService: BimetricService,
    private router: Router,
    private store: Store
  ) { }

  @Selector()
  static isCrmLoaded(state: DashboardStateModel) {
    return state.isCrmLoaded;
  }

  @Selector()
  static isCustomerListLoaded(state: DashboardStateModel) {
    return state.isCustomerListLoaded;
  }

  @Selector()
  static isDashboardLoaded(state: DashboardStateModel) {
    return state.isCrmLoaded && state.isCustomerListLoaded;
  }

  @Selector()
  static customerData(state: DashboardStateModel) {
    return state.customerData;
  }

  @Selector()
  static crmTable(state: DashboardStateModel) {
    return state.crmTable;
  }

  @Selector()
  static daaChart(state: DashboardStateModel) {
    return state.daaChart;
  }

  @Selector()
  static sofChart(state: DashboardStateModel) {
    return state.sofChart;
  }

  @Selector()
  static cleanChart(state: DashboardStateModel) {
    return state.cleanChart;
  }

  @Action(GetCustomers)
  getCustomers(ctx: StateContext<DashboardStateModel>, { count }: GetCustomers) {
    return this.clientRepository
      .getCustomers(count)
      .pipe(tap(customers => ctx.dispatch(new SetCustomers(customers))));
  }

  @Action(SetCustomers)
  setCustomers(ctx: StateContext<DashboardStateModel>, { customers }: SetCustomers) {
    const customerList = customers
      .map((customer: Customer): CustomerDataPoint => ({
        Customer: this.getCustomerStatus(customer),
        Date: { value: getTenantTimezoneDateString(this.store, customer.createDate, 'DD/MM/YYYY') },
        Raw: customer
      }))
      .sort((a, b) => (moment(b.Raw.createDate).diff(moment(a.Raw.createDate))));

    const customerData = new CustomerData(
      customerList,
      ['Customer', 'Date']
    );

    ctx.patchState({ customerData, isCustomerListLoaded: true });
  }

  @Action(GetCrmTable)
  getCrmTable(ctx: StateContext<DashboardStateModel>, { filter }: GetCrmTable) {
    return combineLatest([
      this.crmService.getFilteredNotes(filter)
        .pipe(
          map((crm: NoteFullInfo) => ({
            list: crm.notes.map((note: Note) => ({
              Action: { value: note.notificationType, icon: note.notificationType },
              Customer: { value: note.patient ? makeFullName(note.patient.firstname, note.patient.surname) : 'Unknown' },
              Date: this.getDate(note.nextActionDate, (note.type === NoteTypeEnum.customerOrder) ? "DD/MM/YYYY hh:mm a" : "DD/MM/YYYY"),
              Raw: note
            })),
            headers: ['Action', 'Customer', 'Date']
          })),
        ),
      this.crmService.getNoteTypes()]
    ).pipe(tap(result => {
      const noteTypes = result[1];
      const { list, headers } = result[0];
      const crmTable = { list, headers, noteTypes } as DashboardCrmTable;
      ctx.patchState({ crmTable, isCrmLoaded: true });
    }))
  }

  @Action(FilterCrmTable)
  filterCrmTable(ctx: StateContext<DashboardStateModel>, { filterText }: FilterCrmTable) {
    const { noteTypes, headers, list } = ctx.getState().crmTable;
    const filteredList = list.filter(item =>
      item.Action.value.toLowerCase().includes(filterText.toLowerCase()) ||
      item.Customer.value.toLowerCase().includes(filterText.toLowerCase())
    );

    const crmTable = { list: filteredList, headers, noteTypes } as DashboardCrmTable;

    ctx.patchState({ crmTable });
  }

  @Action(FilterCustomers)
  filterCustomers(ctx: StateContext<DashboardStateModel>, { filterText }: FilterCustomers) {
    const { headers, list } = ctx.getState().customerData;
    const filteredList = list.filter(item =>
      item.Date.toString().toLowerCase().includes(filterText.toLowerCase()) ||
      item.Customer.value.toLowerCase().includes(filterText.toLowerCase())
    ).sort((a, b) => (moment(b.Raw.createDate).diff(moment(a.Raw.createDate))));

    const customerData = { list: filteredList, headers } as CustomerData;
    ctx.patchState({ customerData });
  }

  @Action(GetCleanlinessData)
  getCleanlinessData(ctx: StateContext<DashboardStateModel>, { biometricOption }: GetCleanlinessData) {
    return this.bimetricService.getCleanliness(biometricOption)
      .pipe(
        tap((data: Bimetric[]) => {
          const chartData: ChartData[] = data.map(item => {
            return {
              name: item.name,
              value: +((item.current * 100) / item.target).toFixed(0)
            };
          });
          const cleanChart = this.generateSolidGuage(chartData);
          ctx.patchState({ cleanChart });
        })
      );
  }

  @Action(GetGaugeData)
  getGaugeData(ctx: StateContext<DashboardStateModel>) {
    return combineLatest([
      // DAA data
      this.bimetricService.getNewCustomers('new-customer-1-day', 1, 'DAA'),
      this.bimetricService.getNewCustomers('new-customer-7-day', 7, 'DAA'),
      this.bimetricService.getNewCustomers('new-customer-28-day', 28, 'DAA'),

      // SOF data
      this.bimetricService.getNewCustomers('new-customer-1-day', 1, 'SOF'),
      this.bimetricService.getNewCustomers('new-customer-7-day', 7, 'SOF'),
      this.bimetricService.getNewCustomers('new-customer-28-day', 28, 'SOF')
    ]).pipe(
      tap((data: [Bimetric, Bimetric, Bimetric, Bimetric, Bimetric, Bimetric]) => {
        const daa = new Array<Bimetric>(data[0], data[1], data[2]);
        const sof = new Array<Bimetric>(data[3], data[4], data[5]);
        const daaChart = this.guageGenerator(daa[0], 'DAA Signups');
        const sofChart = this.guageGenerator(sof[0], 'SOF Signups');

        ctx.patchState({ daa, sof, daaChart, sofChart });
      })
    );
  }

  @Action(UpdateGauge)
  updateGauge(ctx: StateContext<DashboardStateModel>, { type, index }: UpdateGauge) {
    if (type === 'DAA') {
      const daaChart = this.guageGenerator(ctx.getState().daa[index], 'DAA Signups');
      ctx.patchState({ daaChart })
    }
    if (type === 'SOF') {
      const sofChart = this.guageGenerator(ctx.getState().sof[index], 'SOF Signups');
      ctx.patchState({ sofChart })
    }
  }

  @Action(SetSearch)
  setSearch(ctx: StateContext<DashboardStateModel>, { input }: SetSearch) {
    const firstName = input.length > 1 ? input[1] : '';
    const lastName = input[0];
    const phoneNumber = input[0];
    const searchData = { firstName, lastName, phoneNumber } as SearchData;
    ctx.patchState({ searchData });
  }

  @Action(FireSearch)
  fireSearch(ctx: StateContext<DashboardStateModel>) {
    const { firstName, lastName, phoneNumber } = ctx.getState().searchData;
    if (lastName.trim())
      this.router.navigate(['client', { firstName, lastName, phoneNumber }]);
  }

  private getCustomerStatus(customer: Customer): CustomerItem {
    let status = 'bg__red';
    if (customer.type === 'DAA' || customer.type === 'SOF') {
      status = 'bg__green';
    }
    else if (customer.type === 'LEAD') {
      status = 'bg__orange';
    }
    return {
      value: `${customer.name} ${customer.surname}`,
      status: status
    } as CustomerItem;
  }

  private getDate(date, format) {
    return {
      value: getTenantTimezoneDateString(this.store, date, format),
      class: getClassesWithBold(date)
    };
  }

  private generateSolidGuage(data: ChartData[]): Chart {
    const series = [];
    if (data && data[0]) {
      series.push({
        name: data[0].name,
        data: [{
          color: '#06D6A0',
          radius: '112%',
          innerRadius: '88%',
          y: data[0].value,
        }],
        showInLegend: true
      });
    }
    if (data && data[2]) {
      series.push({
        name: data[2].name,
        data: [{
          color: '#FFD166',
          radius: '87%',
          innerRadius: '63%',
          y: data[2].value
        }],
        showInLegend: true
      });
    }
    if (data && data[1]) {
      series.push({
        name: data[1].name,
        data: [{
          color: '#EF476F',
          radius: '62%',
          innerRadius: '38%',
          y: data[1].value
        }],
        showInLegend: true
      });
    }

    return new Chart(<any>{
      chart: {
        type: 'solidgauge',
        height: '100%',
      },
      title: {
        text: '',
        style: {
          fontSize: '24px'
        }
      },
      exporting: {
        enabled: false
      },
      tooltip: {
        borderWidth: 0,
        backgroundColor: 'none',
        shadow: false,
        style: {
          fontSize: '16px'
        },
        pointFormat: '{series.name}<br><span style="font-size:2em; color: {point.color}; font-weight: bold">{point.y}%</span>',
        positioner: function (labelWidth) {
          return {
            x: ((this.chart.chartWidth - labelWidth) / 2) + 2,
            y: (this.chart.plotHeight / 2) - 30
          };
        }
      },

      pane: {
        startAngle: 0,
        endAngle: 360,
        background: [{
          outerRadius: '112%',
          innerRadius: '88%',
          backgroundColor: '#B5EFD8',
          borderWidth: 0
        }, {
          outerRadius: '87%',
          innerRadius: '63%',
          backgroundColor: '#FFEDC6',
          borderWidth: 0
        }, {
          outerRadius: '62%',
          innerRadius: '38%',
          backgroundColor: '#FAC8D4',
          borderWidth: 0
        }]
      },

      yAxis: {
        min: 0,
        max: 100,
        lineWidth: 0,
        tickPositions: []
      },

      plotOptions: {
        solidgauge: {
          dataLabels: {
            enabled: false
          },
          linecap: 'round',
          stickyTracking: false,
          rounded: true
        }
      },
      legend: {
        labelFormatter: function () {
          return '<span style="text-weight:bold;color:' + this.userOptions.data[0].color + '">' + this.name + '</span>';
        },
        symbolWidth: 0,
      },
      series: [...series]
    });
  }

  guageGenerator(data: Bimetric, title: string): Chart {
    const navigationButton = {
      buttonOptions: {
        theme: {
          states: {
            hover: {
              fill: '#ffffff'
            },
            select: {
              fill: '#ffffff'
            }
          }
        },
        symbolStroke: '#0B3366',
        height: 35,
        width: 20,
        symbolX: 35,
        symbolStrokeWidth: 3,
      }
    };

    return new Chart({
      chart: {
        type: 'solidgauge',
        marginTop: -600

      },
      title: null,
      pane: {
        center: ['50%', '85%'],
        size: '80%',
        startAngle: -90,
        endAngle: 90,

        background: {
          backgroundColor: '#FFF',
          innerRadius: '60%',
          outerRadius: '100%',
          shape: 'arc'
        }
      },
      tooltip: {
        enabled: false
      },
      // the value axis
      yAxis: {
        stops: [
          [data.target, '#7CB5EC'], // green
          [data.target * 0.5, '#DDDF0D'], // yellow
          [data.target * 0.9, '#DF5353'] // red
        ],
        lineWidth: 0,
        minorTickInterval: 1,
        tickAmount: 2,
        title: {
          y: -100,
          text: title
        },
        labels: {
          y: 16
        },
        min: 0,
        max: data.target
      },
      navigation: navigationButton,

      plotOptions: {
        solidgauge: {
          dataLabels: {
            y: data.target,
            borderWidth: 0,
            useHTML: true
          }
        }
      },
      credits: {
        enabled: false
      },
      series: [{
        name: data.name,
        data: [+data.current.toFixed(2)]
      }]
    });
  }
}
