<div class="modal confirm-modal">
  <div class="modal-body border__grey">
    <div>
      <h3 class="p-2">Save service as PDF?</h3>
      <p>Would you like to upload a new version of the service summary to profile documents to reflect these changes?</p>
      <small>Note: you can always return to this service form to generate a new report</small>
      <div g-xs="mt2">
        <button class="button btn__yellow" g-xs="mr1" (click)="close()">No</button>
        <button class="button btn__green" (click)="submit()">Yes</button>
      </div>
    </div>
  </div>
</div>
<div class="modal-background"></div>