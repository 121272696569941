import { Component } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { DoseAdministrationAidsState, DAAFormType } from '../dose-administration-aids/state/dose-administration-aids.state';
import { DAAPatientDetailsFormState, DAAPatientDetailsFormStateModel } from '../daa-patient-details-form/state/daa-patient-details-form.state';

@Component({
  selector: 'app-daa-patient-form-list-item',
  templateUrl: './daa-patient-form-list-item.component.html'
})
export class DAAPatientFormListItemComponent {
  @Select(DoseAdministrationAidsState.daaFormType) daaFormType$: Observable<DAAFormType>;
  @Select(DAAPatientDetailsFormState) formState$: Observable<DAAPatientDetailsFormStateModel>;
  daaFormTypes = DAAFormType;
}
