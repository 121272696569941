import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { Router, Event, NavigationStart } from '@angular/router';
import { CrmService } from '../../../shared/services/crm.service';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { FeaturesState } from '../../../modules/core/features/features.state';
import { Select, Store } from '@ngxs/store';
import { ToggleEScriptModal } from '../escripts/state/escripts.actions';
import { ConnectionTypeEnum } from '../../../shared/models/connectionType.model';
import { PharmacyService } from '~/shared/services/pharmacy.service';
import { environment } from '../../../../environments/environment';
import { catchError, tap } from 'rxjs/operators';
import { GetUnread } from '~/system/communications-page/state/communications.actions';
import { CommunicationsState } from '~/system/communications-page/state/communications.state';
import { CrmState } from '~/system/crm-page/state/crm.state';
import { GetOutstandingNoteCount } from '~/system/crm-page/state/crm.actions';
import { PharmacyCommsDetailsState } from '~/modules/pharmacy-comms/pharmacy-comms-details/state/pharmacy-comms-details.state';
import { GetPharmacyLocationAction } from '~/modules/pharmacy-comms/pharmacy-comms-details/state/pharmacy-comms-details.actions';
import { OrderManagementState } from '~/system/order-management/state/order-mgmt.state';
import { GetPendingOrdersCount } from '~/system/order-management/state/order-mgmt.actions';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  @Select(FeaturesState.getFeatures) features$: Observable<any>;
  @Select(CommunicationsState.unread) unread$: Observable<number>;
  @Select(CrmState.awaitingTotal) awaitingTotal$: Observable<number>;
  @Select(PharmacyCommsDetailsState.pharmacy) location$: Observable<any>;
  @Select(PharmacyCommsDetailsState.enableOrderManagementForPharmacy) enableOrders$: Observable<boolean>;
  @Select(OrderManagementState.pendingOrdersCount) pendingOrders$: Observable<boolean>;

  isSearchBorderRed = false;
  firstName = '';
  lastName: string;
  phoneNumber: string;
  currentRoute: string;
  newNotifications = new BehaviorSubject<number>(0);
  eScriptActive = false;
  tenantCustomerCanDispense = false;
  HELP_LINK = environment.helpLink;
  CPA_Version = environment.CPAVersion;
  SCRYPT_DESKTOP = environment.scryptDesktop;
  SCRYPT_NOTIFY = environment.scryptNotify;
  crmSubscription: Subscription;
  routerSubscription: Subscription;

  constructor(
    private authService: AuthService,
    private router: Router,
    private crmService: CrmService,
    private store: Store,
    private pharmacyService: PharmacyService
  ) {
    this.initSubscriptions();
  }
  ngOnDestroy(): void {
    if (this.crmSubscription) {
      this.crmSubscription.unsubscribe();
    }
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }

  private initSubscriptions() {
    this.crmService
      .checkNotes()
      .pipe(
        tap(
          x => this.newNotifications.next(x)
        )
      ),
      catchError(err => this.handleError(err));

    this.routerSubscription = this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        this.crmService.checkNotes()
          .pipe(
            tap(
              x => this.newNotifications.next(x)
            )
          ),
          catchError(err => this.handleError(err));
      }
    });

    this.getTenantConnection();
    this.store.dispatch(GetUnread);
    this.store.dispatch(new GetPendingOrdersCount());
    this.store.dispatch(new GetOutstandingNoteCount());
  }

  private async handleError(err: any) {
    console.log(err);
  }

  private getTenantConnection() {
    this.pharmacyService.getPharmacyLocation()
      .subscribe(local => {
        switch(local.connectionType) {
          case ConnectionTypeEnum.ZDispense:
          case ConnectionTypeEnum.Corum:
            this.tenantCustomerCanDispense = true
            break;
          case ConnectionTypeEnum.FredDispense:
          case ConnectionTypeEnum.FredNXTDispense:
          case ConnectionTypeEnum.Aquarius:
            this.tenantCustomerCanDispense = false
            break;
        }
      });
  }

  isActiveUrl(url:string):boolean{
    return this.router.url.toLowerCase() == url
  }

  toggleEScript() {
    this.store.dispatch(new ToggleEScriptModal(true));
  }

  ngOnInit() {
    this.store.dispatch(new GetPharmacyLocationAction());
  }

  onSearchFocusOut() {
    this.isSearchBorderRed = false;
  }

  setSearch($event) {
    this.firstName = '';
    this.lastName = '';
    this.phoneNumber = '';
    const search = $event.target.value.split(' ');
    this.lastName = search[0];
    this.phoneNumber = search[0];
    if (search.length > 1) {
      this.firstName = search[1];
    }

    this.isSearchBorderRed = !this.isSearchValueValid();
  }

  logout() {
    this.authService.logout();
    this.router.navigate(['', 'login']);
    this.firstName = '';
    this.lastName = '';
  }

  navigate() {
    if (this.isSearchValueValid()) {
      this.router.navigate(['client', { firstName: this.firstName, lastName: this.lastName, phoneNumber: this.phoneNumber }]);
    }
  }

  isSearchValueValid() {
    return !(this.lastName === undefined || this.lastName === null || this.lastName.trim() === '');
  }
}
