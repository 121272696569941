
<div *ngIf="!loading; else loadingTemplate" class="btn-group" dropdown>
    <button 
        dropdownToggle type="button"
        class="dropdown-toggle form-control border__grey-light export__type-btn"
        aria-controls="filter-type">
        <div>
            Export data <span class="caret"></span>
        </div>
    </button>

    <ul *dropdownMenu class="dropdown-menu border__grey-light export__type" role="menu">
    <li g-xs="py1 px2" role="menuitem"
    (click)="exportAsCsv()">
        Export as CSV
    </li>
    <li g-xs="py1 px2" role="menuitem"
    (click)="exportAsPdf()">
        Export as PDF
    </li>
    </ul>
</div>
<ng-template #loadingTemplate>
    <div>
        <app-loader size="20px" loadingMessage="Generating report..."></app-loader>
    </div>
</ng-template>
