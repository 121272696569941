import { DAAAdditionalDetailsFormModel } from '../../../../../../shared/models/six-cpa/DAA/daa-additional-details-form.model';

export enum DAAAdditionalDetailsFormActions {
  SET_VALID = '[DAAAdditionalDetailsForm] set valid',
  UPDATE = '[DAAAdditionalDetailsForm] Update Form',
  UPDATE_PROGRESS = '[DAAAdditionalDetailsForm] Update Progress'
}

export class SetDAAAdditionalDetailsFormValidAction {
  static readonly type = DAAAdditionalDetailsFormActions.SET_VALID;
  constructor(public valid = false) { }
}

export class SetTotalItemsAndCompletedItems {
  static readonly type = DAAAdditionalDetailsFormActions.UPDATE_PROGRESS;
  constructor(public totalItems, public completedItems) { }
}

export class UpdateDAAAdditionalDetailsForm {
  static readonly type = DAAAdditionalDetailsFormActions.UPDATE;
  constructor(public form: DAAAdditionalDetailsFormModel) { }
}
