import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RadioHighlightModule } from '../../../../../shared/components/radio-highlight/radio-highlight.module';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import { ReactiveFormsModule } from '@angular/forms';
import { DaaAdditionalDetailsFormComponent } from './daa-additional-details-form.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';

@NgModule({
  declarations: [
    DaaAdditionalDetailsFormComponent
  ],
  imports: [
    CommonModule,
    RadioHighlightModule,
    NgxsFormPluginModule,
    ReactiveFormsModule,
    BsDatepickerModule.forRoot()
  ],
  exports: [
    DaaAdditionalDetailsFormComponent
  ]
})
export class DAAAdditionalDetailsFormModule { }
