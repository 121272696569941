<section class="sms-report">
  <div *ngIf="!isLoaded"><app-loader></app-loader>  </div>
  <div *ngIf="isLoaded">
    <div>
      <app-sms-report-filter (filterChangedEvent)=[applyFillters($event)]></app-sms-report-filter>
    </div>
    <div>
      <app-sms-report-list [smsList]="filteredSmsList"></app-sms-report-list>
    </div>
  </div>
</section>