import { SSMedicationInformationFormModel } from '../../../../../../shared/models/six-cpa/staged-supply/ss-medication-information-form.model';

export enum SSMedicationInformationFormAction {
  SET_PROGRESS = '[SSMedicationInformationForm] update progress',
  UPDATE = '[SSMedicationInformationForm] Update Form'
}

export class SetTotalAndCompletedItems {
  static readonly type = SSMedicationInformationFormAction.SET_PROGRESS;
  constructor(public totalItems, public completedItems) { }
}

export class UpdateSSMedicationInformationForm {
  static readonly type = SSMedicationInformationFormAction.UPDATE;
  constructor(public form: SSMedicationInformationFormModel) { }
}
