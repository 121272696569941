import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-dispense-overide-confirmation-modal',
  templateUrl: './dispense-overide-confirmation-modal.component.html',
  styleUrls: ['./dispense-overide-confirmation-modal.component.scss']
})
export class DispenseOverideConfirmationModalComponent implements OnInit {
  @Input() scripts: any[] = [];
  @Output() closeEvent = new EventEmitter<any>();
  @Output() continueEvent = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
  }

  continue() {
    this.continueEvent.emit();
  }

  close() {
    this.closeEvent.emit();
  }

}
