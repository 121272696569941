import { Alert } from '../alert.model';
import { State, Action, StateContext, Selector } from '@ngxs/store';
import { AddAlertAction, ClearAlertsAction, RemoveAlertAction } from './alert.actions';
import { addAlertToDictionary, removeAlertFromDictionary } from '../alert.logic';
import { Injectable } from '@angular/core';

export class AlertStateModel {
    alerts: {[alertId: string]: Alert};
}

const defaultState = { alerts: {}};
@Injectable()
@State<AlertStateModel>({
    name: 'alertState',
    defaults: defaultState
})
export class AlertState {

    @Selector()
    static alerts(state: AlertStateModel) {
        return state.alerts;
    }

    @Action(AddAlertAction)
    addAlert(ctx: StateContext<AlertStateModel>, action: AddAlertAction) {
        const state = ctx.getState();
        const alerts = addAlertToDictionary(state.alerts)(action.alert);
        return ctx.patchState({
            alerts
        });
    }

    @Action(RemoveAlertAction)
    removeAlert(ctx: StateContext<AlertStateModel>, action: RemoveAlertAction) {
        return ctx.patchState({
            alerts: removeAlertFromDictionary(ctx.getState().alerts)(action.alert)
        });
    }

    @Action(ClearAlertsAction)
    clearAlerts(ctx: StateContext<AlertStateModel>) {
        return ctx.patchState({
            alerts: defaultState.alerts
        });
    }



}
