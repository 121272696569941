import { State, Selector, StateContext, Action } from '@ngxs/store';
import { UpdateConsentEligibilityBasicForm } from './consent-eligibility-form-basic.actions';
import { Injectable } from '@angular/core';

export class ConsentEligibilityFormBasicStateModel {
  consentEligibilityForm: {
    model: Partial<IConsentEligibilityFormBasicStateModel>;
  };
  valid: boolean;
  totalItems: number;
  completedItems: number;
}

export enum ConsentType {
  Written,
  Verbal,
  Digital
}

export interface IConsentEligibilityFormBasicStateModel {
  patientsConsent: boolean | null;
  consentType: ConsentType
}
@Injectable()
@State<ConsentEligibilityFormBasicStateModel>({
  name: 'consentEligibilityFormBasic',
  defaults: {
    consentEligibilityForm: {
      model: undefined
    },
    valid: false,
    completedItems: 0,
    totalItems: 0
  }
})
export class ConsentEligibilityFormBasicState {
  @Selector()
  static form(state: ConsentEligibilityFormBasicStateModel) { return state.consentEligibilityForm.model; }

  @Action(UpdateConsentEligibilityBasicForm)
  updateForm(ctx: StateContext<ConsentEligibilityFormBasicStateModel>, { form }: UpdateConsentEligibilityBasicForm) {
    ctx.patchState({
      consentEligibilityForm: {
        model: {
          patientsConsent: form.patientsConsent,
          consentType: form.consentType
        }
      }
    });
  }
}
