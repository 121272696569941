import { State, Action, StateContext, Selector, Store } from '@ngxs/store';
import {
  ToggleAddEditMedicationAction,
  SearchMedicationAction,
  AddEditMedicationErrorAction,
  SelectMedicationAction,
  ResetMedicationAction,
  SetMedicationListAction,
  AddMedication
} from './add-edit-medication.actions';
import { Medicine } from '../../../../../shared/models/six-cpa/medicine.model';
import { StockService } from '../../../../../modules/order/services/stock.service';
import { catchError, mergeMap } from 'rxjs/operators';
import { SixCpaState } from '../../state/six-cpa.state';
import { AddMedicationToMedsCheckMedicationProfileListAction } from '../../meds-check-medication-profile/state/meds-check-medication-profile.actions';
import { AddMedicationToMedicationProfileListAction } from '../../medication-profile/state/medication-profile.actions';
import { AddMedicationToDAAMedicationProfileListAction } from '../../DAA/daa-medication-profile/state/daa-medication-profile.actions';
import { AddMedicationToStagedSupplyMedicationProfileListAction } from '../../Staged-Supply/staged-supply-medication-profile/state/staged-supply-medication-profile.actions';
import { AddMedicationToMedListMedicationProfileList } from '../../medication-list/med-list-medication-profile/state/med-list-medication-profile.actions';
import { Injectable } from '@angular/core';
import { SixCpaFormTypes } from '../../six-cpa-form-types.enum';
export class AddEditMedicationStateModel {
  public slideMode: string;
  public error: any;
  public searchList: Medicine[];
  public loading: boolean;
  public medicationForm: any;
}
@Injectable()
@State<AddEditMedicationStateModel>({
  name: 'addEditMedication',
  defaults: {
    slideMode: 'out',
    error: null,
    searchList: null,
    loading: false,
    medicationForm: {
      model: undefined,
      status: '',
      errors: {}
    }
  }
})
export class AddEditMedicationState {

  constructor(
    private stockService: StockService,
    private store: Store
  ) { }


  @Selector()
  static searchList(state: AddEditMedicationStateModel) { return state.searchList; }

  @Selector()
  static medication(state: AddEditMedicationStateModel) { return state.medicationForm.model; }

  @Action(ToggleAddEditMedicationAction)
  toggle(ctx: StateContext<AddEditMedicationStateModel>, action: ToggleAddEditMedicationAction) {
    ctx.patchState({ slideMode: action.slideMode });
  }

  @Action(AddMedication)
  addMedication({ dispatch }: StateContext<AddEditMedicationStateModel>, action: AddMedication) {
    const selectedForm = this.store.selectSnapshot(SixCpaState.activeServiceType);
    switch (selectedForm) {
      case SixCpaFormTypes.MedsCheck:
        dispatch(new AddMedicationToMedsCheckMedicationProfileListAction(action.medication));
        break;
      case SixCpaFormTypes.ClinicalIntervention:
        dispatch(new AddMedicationToMedicationProfileListAction(action.medication));
        break;
      case SixCpaFormTypes.DAA:
        dispatch(new AddMedicationToDAAMedicationProfileListAction(action.medication));
        break;
      case SixCpaFormTypes.StagedSupply: {
        dispatch(new AddMedicationToStagedSupplyMedicationProfileListAction(action.medication));
        break;
      }
      case SixCpaFormTypes.MedicationList: {
        dispatch(new AddMedicationToMedListMedicationProfileList(action.medication));
        break;
      }
      default:
        throw new Error('No valid form type selected');
    }
  }

  @Action(SearchMedicationAction)
  search(ctx: StateContext<AddEditMedicationStateModel>, action: SearchMedicationAction) {
    ctx.patchState({ loading: true });
    return this.stockService.pharmacistSearchStock(action.query).pipe(
      mergeMap((data) => ctx.dispatch(new SetMedicationListAction(data))),
      catchError((error) => ctx.dispatch(new AddEditMedicationErrorAction(error)))
    );
  }

  @Action(SetMedicationListAction)
  setMedication(ctx: StateContext<AddEditMedicationStateModel>, action: SetMedicationListAction) {
    ctx.patchState({
      searchList: action.items.map((item) => {
        const { id, name, genericCategoryName } = item;
        return { brandName: name, productDispensedId: id, genericName: genericCategoryName } as Medicine;
      }),
      loading: false
    });
  }

  @Action(SelectMedicationAction)
  selectMedication(ctx: StateContext<AddEditMedicationStateModel>, action: SelectMedicationAction) {
    const state = ctx.getState();
    ctx.patchState({ medicationForm: { ...state.medicationForm, model: action.item }, searchList: null });
  }

  @Action(ResetMedicationAction)
  resetMedication(ctx: StateContext<AddEditMedicationStateModel>) {
    const state = ctx.getState();
    ctx.patchState({ medicationForm: { ...state.medicationForm, model: { ...state.medicationForm.model, id: null } } });
  }

  @Action(AddEditMedicationErrorAction)
  handleError(ctx: StateContext<AddEditMedicationStateModel>, action: AddEditMedicationErrorAction) {
    ctx.patchState({ error: action.error });
  }
}
