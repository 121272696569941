import { NgModule } from '@angular/core';
import { TenantCategoryState } from './state/tenant-category.state';
import { NgxsModule } from '@ngxs/store';
import { CategoryRepository } from './category.repository';

@NgModule({
  imports: [NgxsModule.forFeature([TenantCategoryState])],
  providers: [CategoryRepository],
})
export class TenantCategoryModule {}
