import { Component, Output, EventEmitter } from '@angular/core';
import { Select } from '@ngxs/store';
import { PauseMedicationState } from '../state/pause-medication.state';
import { PausedMedicationModel } from '../../../../../shared/models/pause-medication-request.model';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-pause-popup',
  templateUrl: './pause-popup.component.html',
  styleUrls: ['./pause-popup.component.scss']
})
export class PausePopupComponent {
  @Select(PauseMedicationState.pausedMedications) pausedMedications$: Observable<PausedMedicationModel[]>;
  @Output() openAllPausesBladeEvent = new EventEmitter<any>();

  constructor() { }

  openAllPauses() { this.openAllPausesBladeEvent.emit(); }

}
