import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';
import { HMRProposedDetailsFormDetailedState } from './state/hmr-proposed-details-form-detailed.state';
import { HMRProposedDetailsFormBasicState } from './state/hmr-proposed-details-form-basic.state';
import { HMRProposedDetailsFormState } from './state/hmr-proposed-details-form.state';


@NgModule({
  imports: [
    NgxsModule.forFeature([
        HMRProposedDetailsFormState,
        HMRProposedDetailsFormDetailedState,
        HMRProposedDetailsFormBasicState
        ]),
  ]
})
export class HMRProposedDetailsStateModule { }
