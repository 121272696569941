import { Injectable } from '@angular/core';
import { environment } from 'projects/scrypt-hq/src/environments/environment';
import { BaseApiNoAlerts } from '~/shared/core/base-api/base-api-no-alerts';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { StorageService } from '~/shared/core/storage/storage.service';
import { AlertService } from '~/modules/core/alert/alert.service';
import { Observable } from 'rxjs';

const API = environment.baseApiUrl;


@Injectable()
export class ZApiService extends BaseApiNoAlerts {

  constructor(public http: HttpClient, public router: Router, public storageService: StorageService, public alertService: AlertService) {
    super(http, router, storageService, alertService);
  }

  scriptQuery(scriptNos: string[]): Observable<any> {
    const queryParams = scriptNos.map(no => `scriptnos=${no}`).join('&');
    return this.get(`${API}/api/zapi/script/query?${queryParams}`);
  }

  clientDispenseInfo(searchKeyword: string): Observable<any> {
    return this.get(`${API}/api/zapi/client/dispenseinfo?keyword=${searchKeyword}`);
  }
}
