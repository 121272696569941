import { Component, OnInit, OnDestroy, InjectionToken } from '@angular/core';
import { SSConsentEligibilityFormBaseComponent } from '../ss-consent-eligibility-form-base.component';
import { Subscription } from 'rxjs';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { Store, Actions } from '@ngxs/store';
import { SSConsentEligibilityFormDetailedState } from '../state/ss-consent-eligibility-form-detailed.state';
import * as moment_ from 'moment';
const moment = moment_;
import { CurrentLivingStatus } from '../../../../../../shared/models/six-cpa/consent-and-eligibility-form.model';
import { WindowService } from '../../../../../../shared/services/window.service';
//import { environment } from '../../../../../../../environments/environment';
import { SixCpaState } from '../../../state/six-cpa.state';

export const CONSENT_FORM_DEFAULT_DATE: InjectionToken<Date> = new InjectionToken('CONSENT_FORM_DEFAULT_DATE');

@Component({
  selector: 'app-ss-consent-eligibility-form-detailed',
  templateUrl: './ss-consent-eligibility-form-detailed.component.html',
  styleUrls: ['./../ss-consent-eligibility-form.component.scss'],
  providers: [
    { provide: CONSENT_FORM_DEFAULT_DATE, useValue: moment(new Date()).add(6, 'M').toDate() }
  ]
})

export class SSConsentEligibilityFormDetailedComponent extends SSConsentEligibilityFormBaseComponent implements OnInit, OnDestroy {
  private formSubscription: Subscription;
  private readonlySubscription: Subscription;
  livingStatus = CurrentLivingStatus;
  defaultDate = moment(new Date()).add(6, 'M').toDate();

  constructor(formBuilder: FormBuilder, store: Store, actions: Actions,private windowService: WindowService) {
    super(formBuilder, store, actions);
    this.consentEligibilityForm.addControl('gpOrHospitalLastSixMonthsForMedicationProblems', new FormControl(false, Validators.required));
    this.consentEligibilityForm.addControl('dateOfPatientRegistration', new FormControl(this.defaultDate, Validators.required));
    this.consentEligibilityForm.addControl('writtenConsentForProvisionOfData', new FormControl(false, Validators.required));
  }

  ngOnInit() {
    this.formSubscription = this.store.select(SSConsentEligibilityFormDetailedState.form)
      .subscribe(() => this.onFormChange());
    this.readonlySubscription = this.store.select(SixCpaState.formsReadOnly)
      .subscribe(readonly => readonly ? this.consentEligibilityForm.disable() : this.consentEligibilityForm.enable());
  }

  ngOnDestroy() {
    this.formSubscription.unsubscribe();
    this.readonlySubscription.unsubscribe();
  }
  openSSConsentForm() {
    // this.windowService.open(environment.stagedSupplyConsentPdf);
    this.windowService.open('');
  }
}
