import { Component, OnInit } from '@angular/core';
import { Store, Select, Actions } from '@ngxs/store';
import { Observable } from 'rxjs';
import { MedicationProfileState } from './state/medication-profile.state';
import {
  ToggleMedicationProfileListItemAction,
  ToggleAllMedicationProfileListAction,
  ToggleMedicationProfileAction
} from './state/medication-profile.actions';
import { Medicine, MedicineCategory } from '../../../../shared/models/six-cpa/medicine.model';
import { ToggleAddEditMedicationAction, SelectMedicationAction } from '../add-edit-medication/state/add-edit-medication.actions';
import { ToggleSixCpaContentAction } from '../state/six-cpa.actions';
import { ClinicalInterventionChildForm } from '../clinical-intervention-child-form';
import { SixCpaState } from '../state/six-cpa.state';

@Component({
  selector: 'app-medication-profile',
  templateUrl: './medication-profile.component.html',
  styleUrls: ['./medication-profile.component.scss']
})
export class MedicationProfileComponent extends ClinicalInterventionChildForm implements OnInit {
  constructor(private store: Store, actions: Actions) {
    super(actions);
  }

  @Select(MedicationProfileState) medicationProfile$: Observable<any>;
  @Select(MedicationProfileState.categorisedMedicines) categorisedMedicines$: Observable<MedicineCategory[]>;
  @Select(MedicationProfileState.selectedMedicineCount) selectedMedicines$: Observable<any>;
  @Select(MedicationProfileState.showMode) showMode$: Observable<string>;
  @Select(SixCpaState.formsReadOnly) readonly$: Observable<boolean>;
  
  ngOnInit() {

  }

  select(item: Medicine) {
    this.store.dispatch(new ToggleMedicationProfileListItemAction(item));
  }
  selectAll(isSelected: boolean, categoryId?: number) {
    this.store.dispatch(new ToggleAllMedicationProfileListAction(isSelected, categoryId));
  }
  checkIsSelectedByCategory(medicationsByCategory: Medicine[]) {
    return medicationsByCategory.some(x => !x.isSelected);
  }
  updateMedication(item: Medicine) {
    this.store.dispatch(new SelectMedicationAction(item));
    this.addEditMedication();
  }
  addEditMedication() {
    this.store.dispatch([new ToggleSixCpaContentAction('out'), new ToggleAddEditMedicationAction('in')]);
  }
  modeChanged(mode) {
    this.store.dispatch(new ToggleMedicationProfileAction(mode));
  }
  protected actionOnResetForm() {
    this.selectAll(false);
  }
}
