import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';
import { AlertService } from '~/modules/core/alert/alert.service';
import { BaseApiNoAlerts } from '~/shared/core/base-api/base-api-no-alerts';
import { StorageService } from '~/shared/core/storage/storage.service';
import { CommunicationOptOut } from '../models/opt-out.model';

const API = environment.baseApiUrl;
@Injectable({
  providedIn: 'root'
})
export class OptOutService extends BaseApiNoAlerts {
  constructor(public http: HttpClient, public router: Router, public storageService: StorageService, public alertService: AlertService) {
    super(http, router, storageService, alertService);
  }

  verifyLink(link: string): Observable<any> {
    return this.http.post<any>(`${API}/api/OptOut/decipher`, { link: link });
  }

  optOut(optOut: CommunicationOptOut): Observable<any> {
    return this.http.post<any>(`${API}/api/OptOut/submit`, optOut);
  }
}
