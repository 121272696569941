<form [formGroup]="tenantInstallReportForm">

  <div class="form-group">
    <label for="tenantId">Tenant</label>
    <select class="form-control" formControlName="tenantId">
      <option *ngFor="let item of tenantDetails" [value]="item.tenantId">{{item.tenantName}}</option>
    </select>
  </div>
  <div class="form-group">
    <label for="reportName">Report Name</label>
    <input type="text" formControlName="reportName" class="form-control" placeholder="Report Name">
  </div>
  <div class="form-group">
    <label for="reportId">Report Id</label>
    <input type="text" formControlName="reportId" class="form-control"
      placeholder="00000000-0000-0000-0000-000000000000">
  </div>
  <div class="form-group">
    <label for="dataSetId">Data Set Id</label>
    <input type="text" formControlName="dataSetId" class="form-control"
      placeholder="00000000-0000-0000-0000-000000000000">
  </div>
  <button type="submit" [disabled]="tenantInstallReportForm.status === 'INVALID'"
    (click)="setupInstallReport()" class="btn btn-primary">Set up Report</button>
  <ng-container *ngIf="(loadingReport$ | async) as loadingReport">
    <div *ngIf="loadingReport" class="progress">
      <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="100"
        aria-valuemin="0" aria-valuemax="100" style="width: 75%"></div>
    </div>
  </ng-container>
</form>