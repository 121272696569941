import { Alert } from '../alert.model';

export class AlertActions {
    static readonly AddAlert = '[AlertAction] Add';
    static readonly RemoveAlert = '[AlertAction] Remove';
    static readonly ClearAlerts = '[AlertAction] Clear';
}


export class AddAlertAction {
    static readonly type = AlertActions.AddAlert;
    constructor(public alert: Alert) {}
}

export class RemoveAlertAction {
    static readonly type = AlertActions.RemoveAlert;
    constructor(public alert: Alert) {}
}

export class ClearAlertsAction {
    static readonly type = AlertActions.ClearAlerts;
    constructor() {}
}
