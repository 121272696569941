import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewEncapsulation,
  OnChanges,
  SimpleChanges,
  OnDestroy
} from '@angular/core';
import { NoteType } from '../../../shared/models/crm/noteType.model';
import { NoteFilter } from '../../../shared/models/crm/noteFilter.model';
import { GroupType } from '../../../shared/models/crm/groupType.model';
import { StorageService } from '~/shared/core/storage/storage.service';
import * as moment from 'moment';
import { NotesScrollService } from './note-filter.service';
import { Unsubscribable } from 'rxjs';
import { initFilter, filtersLocalStorage, filterToUINoteTypes, filterToUIGroupTypes, getSelectedFilterValue, isNumber } from './note-filters.logic';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-note-filters',
  templateUrl: './note-filters.component.html',
  styleUrls: ['./note-filters.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NoteFiltersComponent implements OnInit, OnChanges, OnDestroy {
  @Input() noteTypeList: NoteType[] = [];
  @Input() groupTypeList: GroupType[] = [];
  @Output() filterChanged = new EventEmitter<NoteFilter>();
  filters: NoteFilter = initFilter(null);
  scrollListener: Unsubscribable;
  selectedType: NoteType = new NoteType('All', null, 'far fa-circle');
  selectedGroup: GroupType = new GroupType(null, 'All');
  fromDate: Date;
  filterForm: FormGroup;
  hasFilters = false;
  allSelected = false;

  constructor(
    private storageService: StorageService,
    private notesScrollService: NotesScrollService,
    private formBuilder: FormBuilder
  ) { }

  async ngOnInit() {
    const item = await this.storageService.getItem(filtersLocalStorage);
    this.filters = initFilter(
      JSON.parse(item ? item : null)
    );
    this.filters.fromDate = null;
    this.filters.toDate = moment().toDate();

    this.initTypeLists();
    this.selectedType = this.getSelectedType();
    this.selectedGroup = this.getSelectedGroup();
    this.scrollListener = this.notesScrollService.listen().subscribe(() => {
      this.filters.pageIndex++;
      this.filterChanged.emit(this.filters);
    });
    this.filters.noteTypes = [];
    this.filterChanged.emit(this.filters);
  }

  update(value) {
    const controls = Object.keys(this.filterForm.controls);
    this.hasFilters = controls
      .find(control => this.filterForm.controls[control].value === true) ? true : false;

    this.setFilters(value);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.initTypeLists();
  }

  initTypeLists() {
    this.noteTypeList = [
      new NoteType('All', null, 'far fa-circle'),
      ...filterToUINoteTypes([...this.noteTypeList])
    ];
    this.generateFormControls();
    this.groupTypeList = [
      new GroupType(null, 'All'),
      ...filterToUIGroupTypes(this.groupTypeList)
    ];
  }

  getSelectedType() {
    return getSelectedFilterValue<NoteType, number>(this.filters)(
      filters => filters.noteType
    )(isNumber)(value => value.value)(this.noteTypeList);
  }

  getSelectedGroup() {
    return getSelectedFilterValue<GroupType, number>(this.filters)(
      filters => filters.groupId
    )(isNumber)(value => value.id)(this.groupTypeList);
  }

  getFilteredNotes() {
    this.setFilters();
    this.filterChange();
  }

  private setFilters(value?: string) {
    this.filters.noteTypes = [];
    const controls = Object.keys(this.filterForm.controls);
    controls.forEach(control => {
      if (this.filterForm.controls[control].value === true) {
        if (control === 'All') {
          this.setToAll(value);
        } else {
          const noteType = this.noteTypeList.find(note => note.name === control);
          this.filters.noteTypes.push(noteType.value);
        }
      }
      else {
        if (control === 'All') {
          this.setToAll(value);
        }
        else {
          this.filterForm.patchValue({ All: false });
          this.allSelected = false;
        }
      }
    });
  }

  private setToAll(value?: string) {
    if (this.allSelected && value === 'All') {
      this.reset();
      this.selectedType = new NoteType('All', null, 'far fa-circle');
      this.filters.noteType = null;
      this.hasFilters = false;
      this.allSelected = false;
    }
    else if (!this.allSelected && value === 'All') {
      const controls = Object.keys(this.filterForm.controls);
      controls.forEach(control => {
        const set = { [control]: true};
        this.filterForm.patchValue(set);
      });
      this.allSelected = true;
    }
  }

  onGroupChanged(item: GroupType) {
    this.selectedGroup = item;
    this.filters.groupId = item.id;
    this.filterChange();
  }

  validateFilters(filters) {
    if ((!filters.fromDate || moment(filters.fromDate).isValid())
      && (!filters.toDate || moment(filters.toDate).isValid())) {
      return true;
    }
    return false;
  }

  filterChange() {
    if (this.validateFilters(this.filters)) {
      this.filters.pageIndex = 1;
      this.filterChanged.emit(this.filters);
      this.saveFilters();
    }
  }

  saveFilters() {
    const saveFilters = Object.assign({}, this.filters);
    delete saveFilters.fromDate;
    delete saveFilters.toDate;
    const data = JSON.stringify(saveFilters);
    this.storageService.setItem(filtersLocalStorage, data);
  }

  reset() {
    this.filterForm.reset();
    this.filters.noteTypes = [];
    this.hasFilters = false;
  }

  selectAll() {
    this.filters.noteType = null;
    this.filters.noteTypes = [];
    const controls = Object.keys(this.filterForm.controls);
    controls.forEach(control => {
      this.filterForm.controls[control].patchValue({
        control: true
      });
    });

    this.hasFilters = true;
  }

  selectNone() {
    this.filterForm.reset();
    this.hasFilters = false;
  }

  ngOnDestroy(): void {
    this.scrollListener.unsubscribe();
  }

  private generateFormControls() {
    this.filterForm = this.formBuilder.group({});
    this.noteTypeList.forEach(noteType => {
      this.filterForm.addControl(noteType.name, new FormControl(false));
    });
  }
}
