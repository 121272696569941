import { MedicineCategory } from '../models/six-cpa/medicine.model';
import { MedicineCategoryConstants } from '../constants/medicine-category.constant';

export function addUncategorisedIfMissing(categories: MedicineCategory[]) {
  const uncategorised = categories?.filter(category => category.id === 0) ?? [] as MedicineCategory[];
  if (!uncategorised.length) {
    categories.unshift({
      id: 0,
      name: MedicineCategoryConstants.Uncategorised,
      medicines: []
    } as MedicineCategory);
  }
}
