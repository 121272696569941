import { Component, OnInit, OnDestroy } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { MedicationListConfirmState } from './state/medication-list-confirm.state';
import { Observable, Subscription, combineLatest } from 'rxjs';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { ProfileState } from '../../../../core/profile/state/profile.state';
import { map, take, tap } from 'rxjs/operators';
import {
  GetPatientReportMedicationListConfirm,
  GetDoctorReportMedicationListConfirm,
  SendToDoctorMedicationListConfirm,
  SetDoctorListMedicationListConfirm,
  ToggleResponseMedicationListConfirm,
  SendMedsicationListEmail,
  SendMedicationListReport,
  OpenMedsListSendDocumentFormAction,
  CloseMedsListSendDocumentFormAction
} from './state/medication-list-confirm.actions';
import { CustomisedDoctorReportState } from '../../../../../shared/components/customise-doctor-report/state/customised-doctor-report.state';
import { AttendingDoctorState } from '../../../../../modules/profile/attending-doctor/state/attending-doctor.state';
import { TypeaheadMatch } from 'ngx-bootstrap';
import { IReportModel } from '../../../../../shared/models/six-cpa/doctor-report.model';
import { ChannelSelectModalSubmitEvent } from '../../channel-select-modal/channel-select-modal.component';

@Component({
  selector: 'app-medication-list-confirm',
  templateUrl: './medication-list-confirm.component.html',
  styleUrls: ['./medication-list-confirm.component.scss', '../../six-cpa-form-list-item.component.scss']
})

export class MedicationListConfirmComponent implements OnInit, OnDestroy {
  @Select(MedicationListConfirmState.showSendDocumentForm) showSendDocumentForm$: Observable<boolean>;
  @Select(MedicationListConfirmState) medicationListConfirm$: Observable<any>;
  @Select(MedicationListConfirmState.patientReport) patientReport$: Observable<IReportModel>;
  @Select(MedicationListConfirmState.doctorReport) doctorReport$: Observable<IReportModel>;
  doctorDetailsForm: FormGroup;
  sixCpaNoteForm: FormGroup;
  combinedSubscription$: Subscription;
  medsListEmailForm: FormGroup;
  attendingDoctorsSubscription$: Subscription;

  constructor(private formBuilder: FormBuilder, private store: Store) {
    this.generateFormControls();
  }

  @Dispatch()
  getPatientReport = () =>
  this.store.selectOnce(ProfileState.clientId).pipe(
      map((clientId) => new GetPatientReportMedicationListConfirm(clientId, this.doctorDetailsForm.value.fullName)))

    @Dispatch()
    sendMedsicationListEmail = () =>
      combineLatest([
        this.store.selectOnce(ProfileState.clientId),
        this.store.selectOnce(CustomisedDoctorReportState.customisedDoctorReport)])
          .pipe(take(1),
          map(([clientId, customisedDoctorReport]) =>
            new SendMedsicationListEmail(clientId, customisedDoctorReport)
          ))

  @Dispatch()
  getDoctorReport = () =>
    combineLatest([
      this.store.selectOnce(ProfileState.clientId),
      this.store.selectOnce(CustomisedDoctorReportState.customisedDoctorReport)])
      .pipe(take(1),
        map(([clientId, customisedDoctorReport]) =>
          new GetDoctorReportMedicationListConfirm(clientId, customisedDoctorReport)
        ))

  @Dispatch()
  sendDoctorReport = () =>
    combineLatest([
      this.store.selectOnce(ProfileState.clientId),
      this.store.selectOnce(CustomisedDoctorReportState.customisedDoctorReport)])
      .pipe(take(1),
        map(([clientId, customisedDoctorReport]) =>
          new SendToDoctorMedicationListConfirm(clientId, customisedDoctorReport)
        ))

  @Dispatch()
  openMedsListSendFormModal = () => new OpenMedsListSendDocumentFormAction();

  onChannelSelected($event: ChannelSelectModalSubmitEvent)
  {
    var clientId = this.store.selectSnapshot(ProfileState.clientId);
    var customisedDoctorReport = this.store.selectSnapshot(CustomisedDoctorReportState.customisedDoctorReport);
    this.store.dispatch([new SendMedicationListReport(clientId, $event.commsType, $event.contact, customisedDoctorReport),
      new CloseMedsListSendDocumentFormAction()]);
  }

  ngOnInit() {
    this.attendingDoctorsSubscription$ = this.store.selectOnce(AttendingDoctorState.patientDoctors).pipe(
      tap((data: any[]) => this.store.dispatch(new SetDoctorListMedicationListConfirm(data)))
    ).subscribe();
  }
  ngOnDestroy() {
    if (this.attendingDoctorsSubscription$) {this.attendingDoctorsSubscription$.unsubscribe()}
  }

  onSelect(event: TypeaheadMatch): void {
    const { email } = event.item;
    this.doctorDetailsForm.patchValue({ email });
  }
  hideResponse() {
    this.store.dispatch(new ToggleResponseMedicationListConfirm('out'));
  }

  private generateFormControls() {
    this.doctorDetailsForm = this.formBuilder.group({
      fullName: new FormControl(null),
      email: new FormControl(null, [Validators.required, Validators.email]),
      doctorAddress: new FormControl(null)
    });

    this.medsListEmailForm =  this.formBuilder.group({
      doctorEmail: new FormControl(null, [Validators.email])
    });
  }
}
