export enum DocumentServicesActionTypes {
  GET_ALL_DOCUMENTS = '[DocumentServices] Get All Documents',
  DOWNLOAD_DOCUMENT = '[DocumentServices] Download Document',
  AUTH_DOCUMENT = '[DocumentServices] Auth Document',
  AUTH_SET_ENCRYPTED_DOCUMENT = '[DocumentServices] Set Encrypted DocumentId',
  AUTH_RESPONSE = '[DocumentServices] Auth Response',
  AUTH_ERROR = '[DocumentServices] Auth Error',
}

export class DownloadDocumentFromService {
  static readonly type = DocumentServicesActionTypes.DOWNLOAD_DOCUMENT;
  constructor(public id: number) {}
}

export class GetDocumentsFromService {
  static readonly type = DocumentServicesActionTypes.GET_ALL_DOCUMENTS;
  constructor() {}
}

export class AuthDocumentRequest {
  static readonly type = DocumentServicesActionTypes.AUTH_DOCUMENT;
  constructor(public DOB: Date) {}
}

export class AuthSetEncryptedDocumentId {
  static readonly type =
    DocumentServicesActionTypes.AUTH_SET_ENCRYPTED_DOCUMENT;
  constructor(public encryptedDocumentId: string) {}
}

export class AuthResponseAction {
  static readonly type = DocumentServicesActionTypes.AUTH_RESPONSE;
  constructor(public response?: any) {}
}

export class AuthErrorAction {
  static readonly type = DocumentServicesActionTypes.AUTH_ERROR;
  constructor(public error: any) {}
}
