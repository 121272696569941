import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { UserService } from '../../../shared/services/user.service';
import { NgForm } from '@angular/forms';
import { User } from '../../../shared/models/user.model';
import { Notification } from '../../../shared/models/notification.model';

@Component({
  selector: 'app-user-item',
  templateUrl: './user-item.component.html',
  styleUrls: ['./user-item.component.scss']
})

export class UserItemComponent implements OnInit, OnDestroy {


  @Input() currentItem: User;


  @Output() saveEvent = new EventEmitter<any>();
  @Output() cancelEvent = new EventEmitter<any>();

  notification: Notification;
  mode = 'create';

  addUserSubscription: Subscription;

  public errorMessages = [];

  constructor(
    private userService: UserService
  ) {
  }

  ngOnInit() {
    this.notification = new Notification('danger', '');

    if (this.currentItem.id) { this.mode = 'update'; }
  }
  onSubmit(form: NgForm) {
    const { email, password } = form.value;
    const item = new User(email, password);
    if (this.mode === 'update') {
      item.id = this.currentItem.id;
    } else {
      this.addItem(item, form);
    }
  }


  private addItem(data: User, form: NgForm) {
   this.addUserSubscription = this.userService.addUser(data).subscribe(
      (item: User) => {
        this.saveEvent.emit(
          {
            'mode': this.mode,
            'item': item
          }
        );
        form.reset();
        this.cancelEvent.emit();
      },
      (error) => {
        this.showNotification({text: error._body, type: 'danger'});
      }
    );
  }
  cancel() {
    this.cancelEvent.emit();
  }
  private showNotification(notification: Notification) {
    this.notification = notification;
    window.setTimeout(() => {
      this.notification.text = '';
    }, 5000);
  }
  ngOnDestroy() {
    if (this.addUserSubscription) {this.addUserSubscription.unsubscribe(); }

  }

}
