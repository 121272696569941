<section>
  <div id="mail-history" g-xs="p3 mt2 mb3" class="history border__grey">
    <div *ngIf="mailNoteList.length === 0" class="color__red font__bold">
        No one record has been added yet.
    </div>
    <div g-xs="mb3" *ngFor="let item of mailNoteList" >
      <div class="color__grey-text font__title-0">{{item.date | tenantDate}}</div>
      <div class="font__title-1">
        {{item.note}}</div>
    </div>
  </div>
</section>