import { Component, OnInit } from '@angular/core';
import { Group } from '../../shared/models/group.model';
import { Observable } from 'rxjs/Observable';
import { Select, Store } from '@ngxs/store';
import { GroupPageState } from './state/group-page.state';
import { GetGroupList, CreateNewGroupPage, DeleteGroupPage, UpdateGroupPage } from './state/group-page.actions';

@Component({
  selector: 'app-group-page',
  templateUrl: './group-page.component.html',
  styleUrls: ['./group-page.component.scss']
})
export class GroupPageComponent implements OnInit {
  @Select(GroupPageState) groupPage$: Observable<any>;

  currentGroup: Group = { name: '' };
  displayAddNewSection = false;
  confirmationRequired = false;
  groupId: string;
  countForEnabled: number = 0;

  constructor(private store: Store) {
  }

  ngOnInit() {
    this.store.dispatch(new GetGroupList());
  }

  addNew() {
    this.displayAddNewSection = true;
  }

  saveGroup() {
    this.displayAddNewSection = false;
    if (this.currentGroup.id) {
      this.store.dispatch(new UpdateGroupPage(this.currentGroup));
    } else {
      this.store.dispatch(new CreateNewGroupPage(this.currentGroup));
    }
    this.currentGroup = { name: '' };
  }

  remove(groupId, countForEnabled) {
    this.groupId = groupId;
    this.countForEnabled = countForEnabled;
    this.confirmationRequired = true;
  }

  confirm() {
    this.confirmationRequired = false;
    this.store.dispatch(new DeleteGroupPage(this.groupId));
    this.groupId = null;
    this.countForEnabled = 0;
  }

  dismiss() {
    this.confirmationRequired = false;
    this.groupId = null;
    this.countForEnabled = 0;
  }

  cancel() {
    this.currentGroup = { name: '' };
    this.displayAddNewSection = false;
  }

  editGroup(item: Group) {
    this.displayAddNewSection = true;
    this.currentGroup = item;
  }

  filterGroups(groups: Group[]): Group[] {
    return groups.filter(g => !g.isDefaultGroup)
  }
}
