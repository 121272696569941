import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { DefaultTags, NextValidDispenseDate, TemplateLookUpTable, MessageTemplateAddons } from '../../models/order-request-messages.constants';
import { OrderMethod, OrderRequestItemStatus, OrderRequestStatus } from '../../models/order.enums';
import { OrderRequest } from '../../models/order-request.model';
import { ChannelPreference } from '../../../../../shared/models/pharmacy/pharmacy-comms-details.model';
import { ClientViewModel } from '../../../../../modules/core/profile/client.model';
import { ContactMethods } from '../../../../../shared/models/communication/contact-methods.enum';
import { TenantDatePipe } from '../../../../../modules/core/timezone/pipes/tenant-date.pipe';

@Component({
  selector: 'app-order-message',
  templateUrl: './order-message.component.html',
  styleUrls: ['./order-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrderMessageComponent implements OnInit, OnChanges {
  @Input() order: OrderRequest;
  @Input() dateFn = (date) => date;
  @Input() profile: ClientViewModel;
  @Input() hasMessage = true;
  @Input() contactMethod: ContactMethods;
  @Input() orderRequestStatus: OrderRequestStatus;
  @Input() message: string = null;
  @Output() hasMessageChange: EventEmitter<boolean> = new EventEmitter<boolean>(true);
  @Output() contactMethodChange: EventEmitter<ChannelPreference> = new EventEmitter<ChannelPreference>(null);
  @Output() messageChange: EventEmitter<string> = new EventEmitter<ChannelPreference>(null);
  messageView: string = null;
  messageTemplate = '';
  contactMethods = ContactMethods;
  isEditing = false;
  internalChanges = false;
  messageAtEdit = '';

  constructor(private tenantPipe: TenantDatePipe) { }

  ngOnInit() {
    if (this.profile) {
      this.contactMethod = this.profile.customerProfile.contactMethod;
    }

    if (this.order) {
      this.messageTemplate = this.getTemplate(this.order.orderMethod);
      this.hasMessage = this.message !== null;
      this.messageView = this.composeMessage(this.messageTemplate, this.order);
    }
    this.messageChange.next(this.messageView)
    this.hasMessageChange.emit(this.hasMessage);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.internalChanges) {
      this.internalChanges = false;
      return;
    }
    if (this.order) {
      const newTemplate = this.getTemplate(this.order.orderMethod)
      if (newTemplate !== this.messageTemplate) {
        this.messageTemplate = newTemplate;
        this.hasMessage = this.messageTemplate !== null;
      }
      this.messageView = this.composeMessage(this.messageTemplate, this.order);
    }

    this.messageChange.next(this.messageView)
  }

  updateHasMessage(event) {
    this.hasMessage = event;
    this.hasMessageChange.emit(event);
  }

  getChannelPrefName(preference: ContactMethods) {
    let pref = '';
    switch (preference) {
      case ContactMethods.email:
        pref = 'Email';
        break;
      case ContactMethods.sms:
        pref = 'SMS';
        break;
      case ContactMethods.scryptMessenger:
        pref = 'App';
        break;
      default:
        pref = '';
    }
    return `${pref}${preference === this.profile.customerProfile.contactMethod ? ' (Preferred)' : ''}`;
  }

  isAvailableMethod(preference: ContactMethods) {
    switch (preference) {
      case ContactMethods.email:
      case ContactMethods.sms:
      case ContactMethods.scryptMessenger:
        return true;
      default: return false;
    }
  }

  startEdit() {
    this.isEditing = true;
    this.messageAtEdit = this.messageTemplate;
  }

  save() {
    this.internalChanges = true;
    this.isEditing = false;
    this.messageView = this.composeMessage(this.messageTemplate, this.order);
    this.messageChange.next(this.messageView);
  }

  cancel() {
    this.internalChanges = true;
    this.messageTemplate = this.messageAtEdit ? this.messageAtEdit : this.getTemplate(this.order.orderMethod);
    this.messageView = this.composeMessage(this.messageTemplate, this.order);
    this.messageChange.next(this.messageView);
    this.isEditing = false;
  }

  composeMessage(template: string, order: OrderRequest): string {
    if (!template || !order) return '';

    // On Order
    let onOrderText = '';
    const onOrderItems = this.order.orderRequestedItems.filter(x => x.orderRequestItemStatus === OrderRequestItemStatus.OnOrder);
    if (onOrderItems.length){
      const { onOrderExpected: arrivalDate } = onOrderItems.sort(
        (itemA, itemB) => itemA.onOrderExpected.getDate() - itemB.onOrderExpected.getDate(),
      ).shift();
      if (order.orderMethod === OrderMethod.Pickup)
        onOrderText = `${MessageTemplateAddons.onOrderPickUp} ${this.formatDateForMessage(arrivalDate)}.`;
      else if (order.orderMethod === OrderMethod.Delivery)
        onOrderText = `${MessageTemplateAddons.onOrderDelivery} ${this.formatDateForMessage(arrivalDate)}.`;
    }
    template = this.replaceTag(template, DefaultTags.onOrder, onOrderText);

    // Too Early
    let tooEarlyText = '';
    const tooEarlyItems = this.order.orderRequestedItems.filter(x => x.orderRequestItemStatus === OrderRequestItemStatus.TooEarly);
    if (tooEarlyItems.length) {
      const { nextValidDispenseDate: dispenseDate } = tooEarlyItems.sort(
        (itemA, itemB) => itemA.nextValidDispenseDate.getDate() - itemB.nextValidDispenseDate.getDate(),
      ).shift();
      tooEarlyText = NextValidDispenseDate(this.formatDateForMessage(dispenseDate));
    }
    template = this.replaceTag(template, DefaultTags.nextValidDispenseDate, tooEarlyText);

    // Unable to Fill
    const hasUnableToFillItems = this.order.orderRequestedItems.filter(x => x.orderRequestItemStatus === OrderRequestItemStatus.UnableToFill).length;
    const unableToFillText = hasUnableToFillItems ? MessageTemplateAddons.unableToFill : '';
    template = this.replaceTag(template, DefaultTags.unableToFill, unableToFillText);

    // OrderId
    if (order.id)
      template = this.replaceTag(template, DefaultTags.orderNumber, order.id.toString());

    // Order Link
    template = this.replaceTag(template, DefaultTags.paymentUrl, order.orderLink);

    // First Name
    template = this.replaceTag(template, DefaultTags.firstName, this.profile.patient.firstname);

    return template;
  }

  getTemplate(orderMethod: OrderMethod): string {
    return TemplateLookUpTable[this.orderRequestStatus]?.[orderMethod] || '';
  }

  replaceTag(template: string, tag: string, replacement: string): string {
    return template.replace(new RegExp(tag, 'g'), replacement);
  }

  formatDateForMessage(date: Date) {
    return this.tenantPipe.transform(date.toString(), 'DD-MM-YYYY');
  }
}
