import { Component, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { InviteToSignUpStateModel, InviteToSignUpState } from '../state/invite-to-sign-up.state';
import { Observable } from 'rxjs';
import { CloseInviteToSignUpModal, RequestInviteToSignUp } from '../state/invite-to-sign-up.actions';
import { InviteType } from '../../../../shared/models/profile/inviteType.enum';
import { FormGroup, Validators, FormControl, FormBuilder } from '@angular/forms';
import { ProfileState } from '../../../../modules/core/profile/state/profile.state';
import { CheckInviteCredentialsAvailability } from '../state/invite-to-sign-up.actions';

@Component({
  selector: 'app-invite-to-sign-up',
  templateUrl: './invite-to-sign-up.component.html',
  styleUrls: ['./invite-to-sign-up.component.scss']
})
export class InviteToSignUpComponent implements OnInit {
  @Select(InviteToSignUpState) inviteToSignUp$: Observable<InviteToSignUpStateModel>;
  inviteForm: FormGroup;
  inviteTypes = InviteType;

  constructor(private store: Store, private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.generateFormControls();
    this.getInviteCredentialsValidity();
  }

  closeModal() {
    this.store.dispatch(new CloseInviteToSignUpModal());
  }

  invite(inviteType: InviteType) {
    this.store.dispatch(new RequestInviteToSignUp(inviteType));
  }

  private generateFormControls() {
    this.inviteForm = this.formBuilder.group({
      email: new FormControl('', [Validators.email]),
      mobile: new FormControl('', [Validators.pattern(/^-?(0|[0-9]\d*)?$/)])
    });
  }

  isValid() {
    return this.mobileIsValid() || this.emailIsValid();
  }

  private emailIsValid() {
    return this.inviteForm.value.email != null && this.inviteForm.value.email.includes('@');
  }

  private mobileIsValid() {
    var regex =  new RegExp(/^-?(0|[0-9]\d*)?$/);
    return this.inviteForm.value.mobile != null && this.inviteForm.value.mobile != '' && regex.test(this.inviteForm.value.mobile);
  }

  private getInviteCredentialsValidity() {
    const profile = this.store.selectSnapshot(ProfileState.profile);
    const mobile = profile.patient.mobileNumber;
    const email = profile.patient.emailAddress;

    this.store.dispatch(new CheckInviteCredentialsAvailability(mobile, email));
  }

  validateNewInviteDetails() {
    const { mobile, email } = this.inviteForm.value;
    this.store.dispatch(new CheckInviteCredentialsAvailability(mobile, email));
  }
}
