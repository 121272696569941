import { State, Action, StateContext, Selector, createSelector } from '@ngxs/store';
import { GetFeaturesAction } from './features.actions';
import { FeaturesService } from './features.service';
import { tap, catchError } from 'rxjs/operators';
import { combineLatest, of } from 'rxjs';
import { Injectable } from '@angular/core';

type FeatureFlag = 
'allowedToInviteToMobile' |
'allowedToSubmitServiceForms' | 
'air' | 
'appSimpleSignUp' | 
'autoComms' | 
'featureFlags' | 
'newStockCalc' | 
'scriptHistory' | 
'sixCPACovid19' |
'documentStorage';

export type Feature = { [featureName in FeatureFlag]?: boolean };
export class FeaturesStateModel {
  public features: { [featureName in FeatureFlag]?: boolean };
  public version: string;
}
@Injectable()
@State<FeaturesStateModel>({
  name: 'features',
  defaults: {
    features: {},
    version: null
  }
})
export class FeaturesState {
  static isEnabled(feature: FeatureFlag) {
    return  createSelector([FeaturesState.getFeatures], (features: Feature) => {
      return features[feature] || false;
    });
  }

  @Selector()
  static getFeatures(state: FeaturesStateModel): Feature {
    return state.features;
  }

  @Selector()
  static getVersion(state: FeaturesStateModel) {
    return state.version;
  }

  constructor(private featureService: FeaturesService) {
  }

  @Action(GetFeaturesAction)
  get(ctx: StateContext<FeaturesStateModel>) {
    return combineLatest([this.featureService.getLatest(), this.featureService.getVersion()]).pipe(
      tap(([features, response]) => this.setFeatures(ctx, { features, version: response.version })),
      catchError((err) => of(console.log(err))));
  }

  private setFeatures({ setState }: StateContext<FeaturesStateModel>, { features, version }) {
    setState({ version, features: Object.assign({}, ...Object.keys(features).map(s => ({ [s]: features[s] }))) });
  }
}
