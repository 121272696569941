import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { StorageService } from '../core/storage/storage.service';
import { AlertService } from '../../modules/core/alert/alert.service';
import { BaseApi } from '../core/base-api/base-api';
import { Observable } from 'rxjs';

@Injectable()
export class LinkShortenerService extends BaseApi {
  API = '';

  constructor(
    @Inject('environment') env: any,
    public http: HttpClient,
    public router: Router,
    public storageService: StorageService,
    public alertService: AlertService
  ) {
    super(http, router, storageService, alertService);
    this.API = env.baseApiUrl;
  }

  shortenLinks(model: any): Observable<any> {
    return this.post<any>(
      `${this.API}/api/linkshortener/shortenlinks`,
      model
    );
  }
}
