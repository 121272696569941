import { Component, OnInit, Input, OnDestroy, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, FormArray } from '@angular/forms';
import {
  BulkMessage,
  Recipient,
  BulkScheduledMessageStatus,
} from '../../../shared/models/communication/bulk-message.model';
import { Store, Select, Actions, ofActionSuccessful } from '@ngxs/store';
import { BulkMessagingUpcomingState } from '../state/bulk-messaging-upcoming.state';
import { Observable, Subject } from 'rxjs';
import { Papa, PapaParseConfig } from 'ngx-papaparse';
import { CharacterCountConstants } from '~/shared/constants/character-count.constants';
import { validMergeTags } from '~/shared/models/communication/mergeTags';
import { isInteger } from 'lodash';
import { GetBulkMessageTemplates, MatchNumbers, NewBulkMessage, ResetNewBulkMessage, SetNewBulkMessageRecipients, UpdateBulkMessage } from './state/new-bulk-message-form.actions';
import { NewBulkMessagingFormState, NewBulkMessagingFormStateModel } from './state/new-bulk-message-form.state';
import * as moment_ from 'moment';
import { CreateStatistic } from '~/shared/state/statistics/statistics.actions';
import { NzTableSortFn } from 'ng-zorro-antd/table';
import { CreateCommTemplateSuccessAction, OpenAddEditCommTemplateModalAction, SetupAddEditCommTemplateModalAction } from '~/modules/pharmacy-comms/comm-templates/add-edit-comm-template-modal/state/add-edit-comm-template-modal.actions';
import { CommTemplate, TemplateType } from '~/shared/models/pharmacy/comm-template.model';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { takeUntil } from 'rxjs/operators';
import { ZApiClientDispenseInfoAction } from '~/system/z-api/state/zapi.actions';
const moment = moment_;

@Component({
  selector: 'app-new-bulk-message',
  templateUrl: './new-bulk-message.component.html',
  styleUrls: ['./new-bulk-message.component.scss'],
})
export class NewBulkMessageComponent implements OnInit, OnDestroy {
  @Input() closeForm: () => VoidFunction;
  @Select(BulkMessagingUpcomingState.loading) isLoading$: Observable<boolean>;
  @Select(NewBulkMessagingFormState) newMessageState$: Observable<NewBulkMessagingFormStateModel>;
  @Select(NewBulkMessagingFormState.isDirty) isDirty$: Observable<boolean>;
  @ViewChild('messageTextarea') messageTextareaElementRef: ElementRef;
  newBulkMessageForm: FormGroup;
  urlDetected = false;
  mergeTags = validMergeTags.filter(x => x.key != 'RepeatTokenLink');
  isDuplicatesModalVisible = false;
  duplicateData: {
    destination: string;
    recipients: { recipient: Recipient; index: number, selected?: boolean }[];
  }[];  

  private ngUnsubscribe = new Subject();
  private newlyCreatedTemplateId?: number = null;

  constructor(
    private formBuilder: FormBuilder,
    private store: Store,
    private papaCSVParse: Papa,
    private nzAlertService: NzNotificationService,
    private actions$: Actions,
  ) {
    this.actions$.pipe(
      ofActionSuccessful(CreateCommTemplateSuccessAction),
      takeUntil(this.ngUnsubscribe))
      .subscribe(successfulAction => {
        this.newlyCreatedTemplateId = successfulAction.item.id;
        this.store.dispatch(new GetBulkMessageTemplates());
        this.nzAlertService.success('Success', `The new template ${successfulAction.item.name} was created successfully`, { nzPlacement: 'topRight' });
      });
    this.actions$.pipe(
      ofActionSuccessful(GetBulkMessageTemplates),
      takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        if (this.newlyCreatedTemplateId) {
          const newTemplateToSet = this.store.selectSnapshot(NewBulkMessagingFormState.templates)
            .find(t => t.id == this.newlyCreatedTemplateId)
          this.newBulkMessageForm.patchValue({
            ...this.newBulkMessageForm.value,
            chosenTemplate: newTemplateToSet
          })
        }
      });
  }

  ngOnInit() {
    this.createFormControls();
    this.store.dispatch(new GetBulkMessageTemplates());
  }

  ngOnDestroy() {
    this.store.dispatch(new ResetNewBulkMessage());
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.newlyCreatedTemplateId = null;
  }

  phoneNumberSort: NzTableSortFn<Recipient> = (a: Recipient, b: Recipient) => {
    const numA = a.destination.replace(/\D/g, '');
    const numB = b.destination.replace(/\D/g, '');
    return numA.localeCompare(numB);
  };

  nameSort: NzTableSortFn<Recipient> = (a: Recipient, b: Recipient) => {
    const nameA = a.customerName || 'Unknown';
    const nameB = b.customerName || 'Unknown';
    return nameA.localeCompare(nameB);
  };

  private createFormControls() {
    this.newBulkMessageForm = this.formBuilder.group({
      sendDate: new FormControl(),
      message: ['', [Validators.required]],
      recipients: [[], Validators.required],
      chosenTemplate: new FormControl(),
      manualNumber: new FormControl(),
      recipientSearch: new FormControl(''),
      csvFile: new FormControl(),
      id: new FormControl(),
      tenantId: new FormControl()
    });
  }

  closeFormEdit() {
    this.store.dispatch(new ResetNewBulkMessage());
  }

  templateChanged() {
    const template = this.newBulkMessageForm.value.chosenTemplate;
    if (template) {
      this.newBulkMessageForm.patchValue({
        message: template.message
      });
    }
  }

  insertTag(event) {
    const tag = event.target.value;
    if (tag == null)
      return;

    const text = this.message.value;
    const cursorPos = this.messageTextareaElementRef.nativeElement.selectionStart;
    var output = [text.slice(0, cursorPos), tag, text.slice(cursorPos)].join('');
    this.newBulkMessageForm.patchValue({ message: output });
  }

  addManual() {
    const number = this.manualNumber.value;
    const duplicate = this.recipients.value.some(recipient => recipient.destination == number);

    if (duplicate) {
      return this.nzAlertService.warning('Number already exists', `The recipient ${number} will not be added`, { nzPlacement: 'topRight' });
    }

    this.store.dispatch(new MatchNumbers([number]));
  }

  matchNumbers(numbers: string[]) {
    if (numbers && numbers.length > 0) {
      this.store.dispatch(new MatchNumbers(numbers));
    }
  }

  removeRecipient(recipient: Recipient) {
    this.store.dispatch(new SetNewBulkMessageRecipients(this.recipients.value.filter(x => x.clientId !== recipient.clientId)));
  }

  cleanAll() {
    this.store.dispatch(new SetNewBulkMessageRecipients(this.recipients.value.filter(x => x.destination && this.numberValid(x.destination))));
  }

  numberValid(number: string): boolean {
    if(!number) {
      return false;
    }

    const numberToCheck = number.replace('+61', '0')

    const digitsOnly = numberToCheck ? numberToCheck.replace(/\D/g, '') : numberToCheck;
    if (!digitsOnly) return false;

    const internationalNumber = digitsOnly.startsWith("04") || digitsOnly.startsWith("4") ? this.formatToInternational(digitsOnly) : digitsOnly;
    return this.numberIsValidLength(internationalNumber);
  }

  // TODO: something like awesome-phonenumber if it works for australia would be neater
  formatToInternational(number: string): string {
    return `+61${number.replace(/^0+/, '')}`;
  }

  numberIsValidLength(number): boolean {
    return number.length === 12;
  }

  getMessageCount(): number {
    const message = this.pseudoReplaceUrls();
    if (!message || message.length == 0) {
      return 1;
    }
    return Math.ceil(message.length / CharacterCountConstants.messageLength);
  }

  getCharacterCount(): number {
    const message = this.pseudoReplaceUrls();
    return message.length ? message.length : 0;
  }

  pseudoReplaceUrls(): string {
    const message = this.newBulkMessageForm.value.message;
    return message.replace(CharacterCountConstants.urlRegex, () => {
      this.urlDetected = true;
      return CharacterCountConstants.exampleShortenedLink;
    })
  }

  sendMessageNow(event, isEditing) {
    event.preventDefault();
    if (this.newBulkMessageForm.invalid) { return; }

    let sendDate: Date | any = Date();

    const newBulkMessage: BulkMessage = {
      ...this.newBulkMessageForm.value,
      scheduledFrom: moment(sendDate).utc().toDate(),
      status: BulkScheduledMessageStatus.Pending,
      createdDate: new Date()
    };

    this.schedule(newBulkMessage, isEditing);
  }

  private schedule(newBulkMessage: BulkMessage, isEditing: boolean) {
    if (!isEditing) {
      delete newBulkMessage['chosenTemplate'];
      delete newBulkMessage['csvFile'];
      delete newBulkMessage['id'];
      delete newBulkMessage['manualNumber'];
      delete newBulkMessage['recipientSearch'];
      delete newBulkMessage['tenantId'];

      this.updateCommTemplateStatistic();
      this.store.dispatch(new NewBulkMessage(newBulkMessage));
    } else {
      this.store.dispatch(new UpdateBulkMessage(newBulkMessage));
    }
  }

  saveMessage(event, isEditing) {
    event.preventDefault();
    if (this.newBulkMessageForm.invalid) { return; }

    let {
      sendDate,
    } = this.newBulkMessageForm.value;

    if (!sendDate) sendDate = Date();

    const newBulkMessage: BulkMessage = {
      ...this.newBulkMessageForm.value,
      scheduledFrom: moment(sendDate).utc().toDate(),
      status: BulkScheduledMessageStatus.Pending,
      createdDate: new Date()
    };

    this.schedule(newBulkMessage, isEditing);
  }

  openDialog() {
    const fileSelector = document.getElementById('fileSelector') as HTMLInputElement;
    fileSelector.value = '';
    fileSelector.click();  
  }

  importCSV({ srcElement }) {
    const [csvFile] = srcElement.files;
    if (!csvFile) { return; }

    const parseConfig: PapaParseConfig = {
      header: false,
      complete: ({ data }) => {
        if (!data.length) { return; }

        // Returns array of arrays, Will always have a one item to take
        const recipientPhoneNumbers = data.map((obj) => obj[0]);
        const cleanedNumbers = recipientPhoneNumbers.filter((n: string) => n != '');
        this.matchNumbers(cleanedNumbers);
      }
    };

    this.papaCSVParse.parse(csvFile, parseConfig);
  }

  clearCSVData() {
    this.store.dispatch(new SetNewBulkMessageRecipients([]));
  }

  disabledDates(current: Date): boolean {
    const minDate = moment().add(-1, 'days');
    const maxDate = moment().add(12, 'months');
    return !moment(current).isBetween(minDate, maxDate);
  }

  get hasInvalidNumbers() {
    return this.recipients.value.length && this.recipients.value.filter(x => !this.numberValid(x.destination)).length > 0;
  }

  get recipientSearch() {
    return this.newBulkMessageForm.get('recipientSearch');
  }

  get manualNumber() {
    return this.newBulkMessageForm.get('manualNumber');
  }

  get recipients() {
    return this.newBulkMessageForm.get('recipients');
  }

  get message() {
    return this.newBulkMessageForm.get('message');
  }

  get isFuture(): boolean {
    const sendDate = this.newBulkMessageForm.get('sendDate').value;
    return sendDate;
  }

  get filteredRecipientList() {
    const searchText = this.recipientSearch.value;
    const recipients = this.recipients.value;
    const filterRecipients = () => recipients.filter(recipient =>
      isInteger(parseInt((searchText))) ?
        recipient.destination.startsWith(searchText) :
        (recipient.customerName || '').toLowerCase().includes(searchText.toLowerCase()));

    return searchText ? filterRecipients() : recipients;
  }

  private updateCommTemplateStatistic() {
    const template = this.newBulkMessageForm.value.chosenTemplate;
    if (template) {
      const { id, name } = template;
      const updateStatRequest = {
        name: `CommTemplate - ${name}`,
        entityId: +id,
        entityName: 'CommTemplate',
      }
      this.store.dispatch(new CreateStatistic(updateStatRequest))
    }
  }

  saveAsTemplate() {
    const commTemplate: CommTemplate = {
      id: null,
      name: '',
      message: this.message.value,
      createdDate: new Date(),
      subject: '',
      isActive: true,
      templateType: TemplateType.Custom
    }
    this.store.dispatch([new SetupAddEditCommTemplateModalAction(commTemplate), new OpenAddEditCommTemplateModalAction()])
  }

  // MODAL & Duplicate Recipient Handling
  hasDuplicates(): boolean {
    if (!this.filteredRecipientList || this.filteredRecipientList?.length === 0)
      return false;

    return this.findDuplicateRecipients().hasDuplicates;
  }

  showDuplicates() {
    this.duplicateData = this.findDuplicateRecipients().duplicateGroups;
    this.isDuplicatesModalVisible = true;
  }

  findDuplicateRecipients(): {
    hasDuplicates: boolean;
    duplicateGroups: {
      destination: string;
      recipients: { recipient: Recipient; index: number }[];
    }[];
  } {
    const recipients = this.recipients.value;

    if (recipients == null || recipients.length === 0) {
      return { hasDuplicates: false, duplicateGroups: [] };
    }

    const groups: Map<string, { recipient: Recipient; index: number, selected?: boolean }[]> = new Map();

    recipients.forEach((recipient, index) => {
      const destination = recipient.destination;

      if (!groups.has(destination)) {
        groups.set(destination, [{ recipient, index, selected: false }]);
      } else {
        groups.get(destination)?.push({ recipient, index, selected: false });
      }
    });

    const duplicateGroups = Array.from(groups.entries())
      .filter(([_, recipients]) => recipients.length > 1)
      .map(([destination, recipients]) => ({
        destination,
        recipients
      }));

    return {
      hasDuplicates: duplicateGroups.length > 0,
      duplicateGroups
    };
  }

  handleOk(): void {
    this.isDuplicatesModalVisible = false;
  }

  handleCancel(): void {
    this.isDuplicatesModalVisible = false;
  }

  removeDuplicatedRecipient(index) {
    const recipients = this.newBulkMessageForm.get('recipients');

    if (recipients instanceof FormArray) {
      recipients.removeAt(index);
    } else if (Array.isArray(recipients?.value)) {
      const currentValue = [...recipients.value];
      currentValue.splice(index, 1);
      recipients.setValue(currentValue);
    } else {
      console.log('[removeDuplicatedRecipient] Problem');
    }
    const { duplicateGroups } = this.findDuplicateRecipients();
    this.duplicateData = duplicateGroups;

    if (duplicateGroups?.length == 0) {
      this.handleCancel();
    }
  }

  isAllCheckedForGroup(group: any): boolean {
    if (!group.recipients || group.recipients.length === 0) {
      return false;
    }
    return group.recipients.every(item => item.selected);
  }

  checkAllForGroup(checked: boolean, group: any): void {
    group.recipients.forEach(item => {
      item.selected = checked;
    });
  }

  hasSelectedRecipients(): boolean {
    return this.duplicateData.some(group =>
      group.recipients.some(item => item.selected)
    );
  }

  removeAllExceptSelectedRecipients(): void {
    interface PhoneNumberGroup {
      allIndices: number[];
      selectedIndices: number[];
    }

    const phoneNumberGroups: { [destination: string]: PhoneNumberGroup } = {};

    this.duplicateData.forEach(group => {
      phoneNumberGroups[group.destination] = {
        allIndices: group.recipients.map(item => item.index),
        selectedIndices: group.recipients
          .filter(item => item.selected)
          .map(item => item.index)
      };
    });

    const indicesToRemove: number[] = [];

    Object.values(phoneNumberGroups).forEach((group: PhoneNumberGroup) => {
      if (group.selectedIndices.length === 0) {
        return;
      }

      const toRemove = group.allIndices.filter(index =>
        !group.selectedIndices.includes(index)
      );

      indicesToRemove.push(...toRemove);
    });

    if (indicesToRemove.length === 0) {
      return;
    }

    const sortedIndices = [...indicesToRemove].sort((a, b) => b - a);

    sortedIndices.forEach(index => {
      this.removeDuplicatedRecipient(index);
    });

    this.handleCancel();
  }
}
