import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'lib-unlock-services',
  templateUrl: './unlock-services.component.html',
  styleUrls: ['./unlock-services.component.scss']
})
export class UnlockServicesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
