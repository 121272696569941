import { MarketingItemComponent } from './marketing-item.component';
import { MarketingItemService } from '../services/marketing-item.service';
import { MarketingService } from '~/shared/services/marketing.service';
import { MarketingSettingService } from '../services/marketing-settings.sevice';
import { Router, ActivatedRoute } from '@angular/router';
import { Unsubscribable } from 'rxjs';
import { OnDestroy, Component } from '@angular/core';
import { MarketingSetting } from '~/shared/models/marketingSetting.model';

@Component({
  selector: 'app-edit-martketing-item',
  templateUrl: './marketing-item.component.html'
})
export class EditMarketingItemComponent extends MarketingItemComponent implements OnDestroy {

  private subscription: Unsubscribable;

  constructor(
    protected marketingItemService: MarketingItemService,
    protected router: Router,
    protected route: ActivatedRoute,
    protected marketingService: MarketingService,
    settingsService: MarketingSettingService
  ) {
    super(router, route, settingsService, marketingItemService);
    this.subscription = this.marketingItemService.selectedItem.subscribe((setting) => {
      this.marketingItem = setting;
    });
  }

  submit() {
    this.updateItem(this.marketingItem);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  isReadonlyTenant() {
    return true;
  }

  isReadonlyMarketingType() {
    return true;
  }

  isReadonlyMarketingKey() {
    return true;
  }

  private updateItem(data: MarketingSetting) {
    this.marketingService.updateMarketingSequence(data)
      .subscribe(
        () => {
          this.marketingItemService.update(data);
          this.goBack();
        }
      );
  }
}
