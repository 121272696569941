import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TooltipComponent } from './tooltip.component';
import { PopoverModule } from 'ngx-bootstrap/popover';


@NgModule({
  imports: [
    CommonModule,
    PopoverModule.forRoot()
  ],
  declarations: [TooltipComponent],
  exports: [TooltipComponent]
})
export class TooltipModule {
}
