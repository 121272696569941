
export enum CipherActions {
  REQUEST = '[cipher] request',
  RECEIVE = '[cipher] receive',
  ERROR = '[cipher] error',
  CLOSE_MODAL = '[cipher] close modal'
}

export class RequestCipherAction {
  static readonly type = CipherActions.REQUEST;
  constructor(public customerProfileId: number) { }
}

export class ReceiveCipherAction {
  static readonly type = CipherActions.RECEIVE;
  constructor(public cipher: string) { }
}

export class CloseCipherModalAction {
  static readonly type = CipherActions.CLOSE_MODAL;
}

export class RequestCipherErrorAction {
  static readonly type = CipherActions.ERROR;
  constructor(public error: any) { }
}
