import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FeaturesService {
  API = '';

  constructor(@Inject('environment') env: any, private http: HttpClient) {
    this.API = env.baseApiUrl;
  }

  getLatest(): Observable<any> {
    return this.http.get(`${this.API}/api/features`);
  }

  getVersion(): Observable<any> {
    return this.http.get(`${this.API}/api/features/version`);
  }
}
