import { Selector } from '@ngxs/store';
import { BulkMessagingUpcomingState } from './bulk-messaging-upcoming.state';
import { BulkMessagingHistoryState } from './bulk-messaging-history.state';

export class BulkMessagingStatusService {
  @Selector([BulkMessagingUpcomingState.loading, BulkMessagingHistoryState.loading])
  static isLoading(upcomingLoading: boolean, historyLoading: boolean): boolean {
    return upcomingLoading || historyLoading;
  }
}
