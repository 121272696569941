import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ProfileComponent } from '../profile.component';
import { CommsComponent } from '../comms/comms.component';
import { CategoriesComponent } from '../categories/categories.component';
import { NotesComponent } from '../notes/notes.component';
import { ProfileResolver } from '../../core/profile/profile.resolver';
import { ProfileModule } from '../../core/profile/profile.module';
import { categoriesPath } from './profile-routing.paths';
import { OrdersTabGuard } from '../orders-tab.guard';
import { ProfileServicesComponent } from '../profile-services/profile-services.component';
import { CategoriesResolver } from '../../../modules/core/profile/categories.resolver';
import { ProfileServicesResolver } from '../../../modules/core/profile/profile-services.resolver';
import { NotesResolver } from '../../../modules/core/profile/notes-resolver';
import { SixCpaResolver } from '../../../modules/core/profile/six-cpa.resolver';
import { LoaderModule } from '@base/modules/core/loader/loader.module';

const routes: Routes = [
  {
    path: '',
    component: ProfileComponent,
    resolve: [ProfileResolver, SixCpaResolver, CategoriesResolver],
    children: [
      { path: '', pathMatch: 'full', redirectTo: 'categories' },
      { path: categoriesPath, component: CategoriesComponent },
      { path: 'notes', component: NotesComponent, resolve: [NotesResolver] },
      {
        path: 'orders',
        loadChildren: () =>
          import('../order-requests/order.module').then((m) => m.OrderRequestsModule),
        canActivate: [OrdersTabGuard],
      },
      { path: 'comms', component: CommsComponent },
      { path: 'comms/:type', component: CommsComponent },
      {
        path: 'profile-services',
        component: ProfileServicesComponent,
        resolve: [ProfileServicesResolver],
      },
    ],
  },
];
@NgModule({
  imports: [RouterModule.forChild(routes), ProfileModule, LoaderModule],
  providers: [ProfileServicesResolver],
  exports: [RouterModule],
})
export class ProfileRoutingModule {}
