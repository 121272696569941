import { Routes } from '@angular/router';
import { MarketingPageComponent } from '../marketing-page.component';
import { EditMarketingItemComponent } from '../marketing-item/edit-marketing-item.component';
import { AddMarketingItemComponent } from '../marketing-item/add-marketing-item.component';

export const MarketingRoutes: Routes  = [
    {
        path: '',
        component: MarketingPageComponent,
        children: [
            { path: 'edit/:id', component: EditMarketingItemComponent },
            { path: 'add', component: AddMarketingItemComponent }
        ]
    },

];
