import { State, Action, StateContext, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { SaveOrUpdateHMRFormAction } from './hmr-form-save.actions';
import { HMRFormsService } from './hmr-forms.service';
import { HMRFormState, HMRFormType } from './hmr-form.state';
import { map, tap, withLatestFrom } from 'rxjs/operators';
import { SaveExisitingHMR, SaveNewHMR } from './hmr-form.actions';
import { Observable } from 'rxjs/Observable';
import { ProfileState } from '@base/modules/core/profile/state/profile.state';

export class HMRSaveFormStateModel {
}

@Injectable()
@State<HMRSaveFormStateModel>({
  name: 'hmrSaveForm',
  defaults: {
  }
})
export class HMRSaveFormState {
  constructor(private store: Store) { }

  @Action(SaveOrUpdateHMRFormAction)
  saveOrUpdateHMRFormByType(ctx: StateContext<HMRSaveFormStateModel>, {submissionFailed}: SaveOrUpdateHMRFormAction) {
    const formType = this.store.selectSnapshot(HMRFormState.hmrFormType);
    return this.saveOrUpdateByFormType(formType, submissionFailed).pipe(tap(action => ctx.dispatch(action)));
  }

  private saveOrUpdateByFormType(formType: HMRFormType, submissionFailed: boolean): Observable<any> {
    return this.store.selectOnce(this.getFormSelectorForType(formType)).pipe(
      withLatestFrom(this.store.select(ProfileState.clientId)),
      map(([formData, clientId]) => {
        formData.submissionFailed = submissionFailed;
        return formData && formData.sixCpaFormId !== null
        ? new SaveExisitingHMR(clientId, formData)
        : new SaveNewHMR(clientId, formData)
      })
    );
  }

  private getFormSelectorForType(formType: HMRFormType) {
    switch(formType) {
        case HMRFormType.NewClaim: return HMRFormsService.getHMRNewClaimRequest;
        case HMRFormType.InterviewRegisteredPhamacist: return HMRFormsService.getHMRInterviewRegisteredPhamacistRequest;
        case HMRFormType.InterviewOutsidePatientHome: return HMRFormsService.getHMRInterviewOutsidePatientHomeRequest;
        default: throw Error('unsupported form type')
    }
  }
}
