import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { StorageService } from '../../../../../shared/core/storage/storage.service';
import { AlertService } from '../../../../core/alert/alert.service';
import { HttpClient } from '@angular/common/http';
import { MedsCheckFormModel } from '../../../../../shared/models/six-cpa/meds-check-form.model';
import { Observable } from 'rxjs';
import { DoctorEmailModel } from '../../../../../shared/models/six-cpa/doctor-email.model';
import { DatesService } from '../../../../../shared/core/dates/dates.service';
import { BaseApiNoAlerts } from '../../../../../shared/core/base-api/base-api-no-alerts';
import { SixCpaMedicationModel } from '../../../../../shared/models/six-cpa/six-cpa-medication.model';
import { SixCpaActionPlanForm } from '../../../../../shared/models/six-cpa/action-plan.model';

@Injectable()
export class MedsCheckService extends BaseApiNoAlerts {
  API = '';

  constructor(
    @Inject('environment') env: any,
    public http: HttpClient,
    public router: Router,
    public storageService: StorageService,
    public alertService: AlertService
  ) {
    super(http, router, storageService, alertService);
    this.API = env.baseApiUrl;
  }

  postSave(clientId: string, formData: MedsCheckFormModel) {
    formData = this.formatFormDataDates(formData);
    return this.post<MedsCheckFormModel>(
      `${this.API}/api/meds-check/${clientId}/save`,
      formData
    );
  }

  postSubmit(clientId: string, formData: MedsCheckFormModel) {
    formData = this.formatFormDataDates(formData);
    return this.post<MedsCheckFormModel>(
      `${this.API}/api/meds-check/${clientId}/submit`,
      formData
    );
  }

  putSave(clientId: string, formData: MedsCheckFormModel) {
    formData = this.formatFormDataDates(formData);
    return this.put(
      `${this.API}/api/meds-check/${clientId}/update-save`,
      formData
    );
  }

  putSubmit(clientId: string, formData: MedsCheckFormModel) {
    formData = this.formatFormDataDates(formData);
    return this.put(
      `${this.API}/api/meds-check/${clientId}/update-submit`,
      formData
    );
  }

  getMedsCheckForm(clientId: string, formId: number): Observable<any> {
    return this.get<any>(
      `${this.API}/api/meds-check/${clientId}/form/${formId}`
    );
  }

  getPatientReport(
    clientId: string, 
    sixCpaMedications: SixCpaMedicationModel[], 
    actionPlan: Partial<SixCpaActionPlanForm>, 
    fullName?: string,
    allergies?: string,
    primaryDoctor?: string
    ): Observable<any> {
    return this.post(`${this.API}/api/meds-check/${clientId}/print/getPatientReport`, {sixCpaMedications, actionPlan, fullName, allergies, primaryDoctor}, { responseType: 'blob' });
  }

  getDoctorReport(
    clientId: string, 
    sixCpaMedications: SixCpaMedicationModel[], 
    actionPlan: Partial<SixCpaActionPlanForm>, 
    reportBody: string, 
    fullName: string, 
    doctorAddress?: string,
    allergies?: string,
    primaryDoctor?: string
    ): Observable<any> {
    return this.post(`${this.API}/api/meds-check/${clientId}/print/getDoctorReport`, {sixCpaMedications,actionPlan, reportBody, fullName, doctorAddress, allergies, primaryDoctor}, {responseType: 'blob'});
  }

  sendDoctorReport(clientId: string, formData: DoctorEmailModel) {
    return this.post<DoctorEmailModel>(
      `${this.API}/api/meds-check/${clientId}/sendDoctorReport`,
      formData
    );
  }

  sendMedsListReport(clientId: string, formData: DoctorEmailModel) {
    return this.post<DoctorEmailModel>(
      `${this.API}/api/meds-check/${clientId}/sendMedsListReport`,
      formData,
	{responseType: 'text'}
    );
  }

  private formatFormDataDates(form: MedsCheckFormModel): MedsCheckFormModel {
    form.patientDetailsForm.dob = DatesService.ToEndOfLocalDayInUtc(
      form.patientDetailsForm.dob
    );
    form.patientDetailsForm.dateOfMedsCheck = DatesService.ToEndOfLocalDayInUtc(
      form.patientDetailsForm.dateOfMedsCheck
    );
    form.consentAndEligibilityForm.followUpService = DatesService.ToEndOfLocalDayInUtc(
      form.consentAndEligibilityForm.followUpService
    );
    return form;
  }
}
