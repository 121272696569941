import { Component, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { BulkMessagingUpcomingState } from './state/bulk-messaging-upcoming.state';
import { Observable } from 'rxjs';
import { BulkMessage } from '~/shared/models/communication/bulk-message.model';
import {
  RemoveBulkMessage,
} from './state/bulk-messaging.actions';
import { accordion } from '~/shared/core/animations';
import { BulkMessagingHistoryState } from './state/bulk-messaging-history.state';
import { BulkMessagingStatusService } from './state/bulk-messaging-status.service';
import { BulkMessagingFormState } from './state/bulk-messaging-form.state';

@Component({
  selector: 'app-bulk-messaging-page',
  templateUrl: './bulk-messaging-page.component.html',
  styles: [
    `
      .cursor-pointer {
        cursor: pointer;
      }
    `,
  ],
  animations: [accordion],
})
export class BulkMessagingPageComponent implements OnInit {
  @Select(BulkMessagingHistoryState.bulkMessageHistory) bulkMessageHistory$: Observable<BulkMessage[]>;
  @Select(BulkMessagingUpcomingState.upcomingBulkMessages)  upcomingBulkMessages$: Observable<BulkMessage[]>;
  @Select(BulkMessagingStatusService.isLoading)
  @Select(BulkMessagingUpcomingState.loading) loading$: Observable<boolean>;

  loading: Observable<boolean>;
  showMessagesUpcoming = 'in';
  showMessagesHistory = 'in';
  showCreateNewMessage = true;

  constructor(private store: Store) { }

  ngOnInit(): void {
    this.openFormIfRecipientsExist();
  }

  openFormIfRecipientsExist() {
    const recipients = this.store.selectSnapshot(BulkMessagingFormState.bulkMessageForm).recipients;
    if(recipients != undefined && recipients != null && recipients.length > 0) {
      this.displayCreateNewMessage();
    }
  }

  handleDelete(id: number) {
    this.store.dispatch(new RemoveBulkMessage(id));
  }

  toggleMessagesUpcoming() {
    this.showMessagesUpcoming =
      this.showMessagesUpcoming === 'in' ? 'out' : 'in';
  }

  displayCreateNewMessage() {
    this.showCreateNewMessage = true;
  }

  cancelNewMessage = () => {
    this.showCreateNewMessage = false;
  }

  toggleMessagesHistory() {
    this.showMessagesHistory = this.showMessagesHistory === 'in' ? 'out' : 'in';
  }
}
