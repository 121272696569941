import { State, Action, StateContext, Selector, Store } from '@ngxs/store';
import {
  CloseInviteToSignUpModal,
  OpenInviteToSignUpModal,
  CustomRequestInviteToSignUp,
  RequestInviteToSignUp,
  RequestInviteToSignUpSuccess,
  RequestInviteToSignUpError,
  SetInviteToSignUpForm,
  CheckInviteCredentialsAvailability
} from './invite-to-sign-up.actions';
import { InviteService } from '../../../../shared/services/invite.service';
import { tap, catchError, finalize } from 'rxjs/operators';
import { AlertService } from '../../../core/alert/alert.service';
import { AlertType } from '../../../core/alert/alert.model';
import { InviteModel } from '../../../../shared/models/six-cpa/invite.model';
import { ProfileState } from '../../../core/profile/state/profile.state';
import { Injectable } from '@angular/core';

export class InviteToSignUpStateModel {
  modalVisible: boolean;
  loading: boolean;
  error: any;
  customerProfileId: number;
  inviteForm: {
    model: Partial<InviteModel>;
  };
  emailAvailable: boolean;
  mobileNumberAvailable: boolean;
  checkingCredentials: boolean;
}
@Injectable()
@State<InviteToSignUpStateModel>({
  name: 'inviteToSignUp',
  defaults: {
    modalVisible: false,
    loading: false,
    error: null,
    customerProfileId: null,
    inviteForm: {
      model: undefined
    },
    emailAvailable: false,
    mobileNumberAvailable: false,
    checkingCredentials: false
  }
})
export class InviteToSignUpState {
  constructor(private inviteService: InviteService, private alertService: AlertService, private store: Store) { }

  @Selector()
  static modalVisible(state: InviteToSignUpStateModel) {
    return state.modalVisible;
  }

  @Action(CloseInviteToSignUpModal)
  closeModal(ctx: StateContext<InviteToSignUpStateModel>) {
    ctx.patchState({ modalVisible: false });
  }

  @Action(OpenInviteToSignUpModal)
  openModal(ctx: StateContext<InviteToSignUpStateModel>) {
    ctx.patchState({ modalVisible: true });
  }

  @Action(RequestInviteToSignUp)
  request(ctx: StateContext<InviteToSignUpStateModel>, { inviteType }: RequestInviteToSignUp) {
    ctx.patchState({ loading: true });
    const state = ctx.getState();
    const profile = this.store.selectSnapshot(ProfileState.profile);
    return this.inviteService.inviteByDeepLink(state.customerProfileId, { ...state.inviteForm.model, customerName: `${profile.patient.firstname} ${profile.patient.surname}`, inviteType }).pipe(
      tap((resp) => {
        ctx.dispatch(new RequestInviteToSignUpSuccess());
      }),
      catchError((error) => ctx.dispatch(new RequestInviteToSignUpError(error))),
      finalize(() => { ctx.patchState({ loading: false, modalVisible: false }); })
    );
  }

  @Action(CustomRequestInviteToSignUp)
  customRequest(ctx: StateContext<InviteToSignUpStateModel>, { inviteType, email, mobile }: CustomRequestInviteToSignUp) {
    ctx.patchState({ loading: true });
    const state = ctx.getState();
    const profile = this.store.selectSnapshot(ProfileState.profile);
    return this.inviteService.inviteByDeepLink(state.customerProfileId, {
      email,
      mobile,
      customerName: `${profile.patient.firstname} ${profile.patient.surname}`,
      inviteType
    }).pipe(
      tap((resp) => {
        ctx.dispatch(new RequestInviteToSignUpSuccess());
      }),
      catchError((error) => ctx.dispatch(new RequestInviteToSignUpError(error))),
      finalize(() => { ctx.patchState({ loading: false, modalVisible: false }); })
    );
  }

  @Action(RequestInviteToSignUpSuccess)
  requestSuccess(ctx: StateContext<InviteToSignUpStateModel>) {
    this.alertService.alert(AlertType.Success, 'Invitation has been successfully sent');
  }

  @Action(RequestInviteToSignUpError)
  error(ctx: StateContext<InviteToSignUpStateModel>, { error }: RequestInviteToSignUpError) {
    ctx.patchState({ error });
  }

  @Action(SetInviteToSignUpForm)
  setForm(ctx: StateContext<InviteToSignUpStateModel>, { profile }: SetInviteToSignUpForm) {
    ctx.patchState({
      inviteForm: {
        model: {
          email: profile.patient.emailAddress,
          mobile: profile.patient.mobileNumber,
          customerName: `${profile.patient.firstname} ${profile.patient.surname}`
        }
      },
      customerProfileId: this.getCustomerProfileId(profile)
    });
  }

  @Action(CheckInviteCredentialsAvailability)
  checkInviteCredentialsAvailability(ctx: StateContext<InviteToSignUpStateModel>, { mobileNumber, emailAddress }: CheckInviteCredentialsAvailability) {
    ctx.patchState({ checkingCredentials: true });
    return this.inviteService.getCredentialsAvailability(mobileNumber, emailAddress).pipe(
      tap(resp => {
        ctx.patchState({
          mobileNumberAvailable: resp.mobileNumberAvailable,
          emailAvailable: resp.emailAvailable
        })
      }),
      catchError((error) => ctx.dispatch(new RequestInviteToSignUpError(error))),
      finalize(() => ctx.patchState({ checkingCredentials: false }))
    );
  }

  private getCustomerProfileId(profile: any) {
    return (profile && profile.customerProfile && profile.customerProfile.customerProfileId) ? profile.customerProfile.customerProfileId : null
  }
}
