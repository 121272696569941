import { SickLeaveFormModel } from '../../../../../../shared/models/six-cpa/sick-leave/sick-leave-form.model';


export enum SickLeaveActions {
  SET_VALID = '[SickLeave] Set Valid',
  SUBMIT = '[SickLeave] Submit',
  RESET = '[SickLeave] Reset',
  SAVE = '[SickLeave] Save',
  UPDATE_SAVE = '[SickLeave] Update Save',
  UPDATE_SUBMIT = '[SickLeave] Update Submit',
  SAVE_SUCCESS = '[SickLeave] Save Success',
  SAVE_FAILED = '[SickLeave] Save Failed',
  GET = '[SickLeave] Get',
  GET_SICK_LEAVE_FORM_SUCCESS = '[SickLeave] On Get SickLeave Form Success',
  GET_SICK_LEAVE_FORM_ERROR = '[SickLeave] On Get SickLeave Form Error',
  UPDATE_SICK_LEAVE_FORMS = '[SickLeave] Update SickLeave Child Forms',
  OPEN_SICK_LEAVE_CONFIRM_FORM = '[SickLeave] Open Confirm Form',
  CLOSE_SICK_LEAVE_CONFIRM_FORM = '[SickLeave] Close Confirm Form',
  UPDATE_SICK_LEAVE_FORM = '[SickLeave] Update SickLeave Form',
  SHOW_INVALID = '[SickLeave] Show Invalid SickLeave Form',
  GENERATE = '[SickLeave] Generate Sick Leave Form'
}

export class SickLeaveFormListValid {
  static readonly type = SickLeaveActions.SET_VALID;
  constructor(public valid = false) { }
}

export class ResetSickLeaveFormList {
  static readonly type = SickLeaveActions.RESET;
  constructor(public onlyClearFormIds: boolean = false) { }
}


export class GetSickLeaveForm {
  static readonly type = SickLeaveActions.GET;
  constructor(public clientId: string, public sixCpaFormId: number) { }
}

export class GetSickLeaveFormSuccess {
  static readonly type = SickLeaveActions.GET_SICK_LEAVE_FORM_SUCCESS;
  constructor(public sickLeaveForm: SickLeaveFormModel) { }
}
export class GetSickLeaveFormError {
  static readonly type = SickLeaveActions.GET_SICK_LEAVE_FORM_ERROR;
  constructor(public error: any) { }
}

export class UpdateSickLeaveForm {
  static readonly type = SickLeaveActions.UPDATE_SICK_LEAVE_FORM;
  constructor(public sickLeaveForm: SickLeaveFormModel) { }
}

export class SubmitNewSickLeave {
  static readonly type = SickLeaveActions.SUBMIT;
  constructor(public clientId: string, public formData: SickLeaveFormModel) { }
}

export class SubmitExisitingSickLeave {
  static readonly type = SickLeaveActions.UPDATE_SUBMIT;
  constructor(public clientId: string, public formData: SickLeaveFormModel) { }
}

export class SaveNewSickLeave {
  static readonly type = SickLeaveActions.SAVE;
  constructor(public clientId: string, public formData: SickLeaveFormModel) { }
}

export class SaveExisitingSickLeave {
  static readonly type = SickLeaveActions.UPDATE_SAVE;
  constructor(public clientId: string, public formData: SickLeaveFormModel) { }
}

export class SaveSickLeaveFormSuccessAction {
  static readonly type = SickLeaveActions.SAVE_SUCCESS;
  constructor(public formData: SickLeaveFormModel) { }
}

export class SaveSickLeaveFormFailedAction {
  static readonly type = SickLeaveActions.SAVE_FAILED;
  constructor(public error: any) { }
}

export class ShowInvalidSickLeaveForm {
  static readonly type = SickLeaveActions.SHOW_INVALID;
}

export class GenerateSickLeaveForm {
  static readonly type = SickLeaveActions.GENERATE;
}
