import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { MarketingSetting } from '~/shared/models/marketingSetting.model';
import { Tenant } from '~/shared/models/tenant.model';
import { MarketingType } from '~/shared/models/sequence-type.enum';

@Injectable()
export class MarketingItemService {
  selectedItem: BehaviorSubject<MarketingSetting>;
  updatedItem = new BehaviorSubject<MarketingSetting>(null);
  private items$ = new BehaviorSubject<MarketingSetting[]>([]);
  private items: MarketingSetting[] = [];

  getItems(): Observable<MarketingSetting[]> {
    return this.items$;
  }

  setItems(newItems: MarketingSetting[]) {
    this.items.length = 0;
    this.items = newItems;
    this.items$.next(newItems);
  }

  select(setting: MarketingSetting) {
    if (this.selectedItem) {
      this.selectedItem.next(setting);
    } else {
      this.selectedItem = new BehaviorSubject<MarketingSetting>(setting);
    }
  }

  update(setting: MarketingSetting) {
    this.addOrUpdate(setting);
  }

  getTenant(item: MarketingSetting) {
    return ({ id: item.tenantId, name: item.tenantName });
  }

  getMarketingItemTenants(): Array<Partial<Tenant>> {
    return this.items
      .map(this.getTenant)
      .filter(function (value, index, self) {
        return self.findIndex(item => item.id === value.id) === index;
      });
  }

  getMarketingTypeKey(marketingType: MarketingType) {
    return this.items
      .filter(item => item.marketingType === marketingType)
      .map(item => item.key)
      .pop();
  }

  getMarketingTypes() {
    const list = [];
    for (const n in MarketingType) {
      if (typeof MarketingType[n] === 'number') {
        list.push(MarketingType[n]);
      }
    }
    return list;
  }

  private addOrUpdate(item: MarketingSetting) {
    this.removeIfExists(item);
    this.items = [
      ...this.items,
      item
    ];
    this.items$.next(this.items);
  }

  private removeIfExists(item: MarketingSetting) {
    const exists = this.items.filter(val => val.id === item.id);
    const current = exists && exists.length && exists.pop();
    if (current) {
      const indexOf = this.items.indexOf(current);
      this.items.splice(indexOf, 1);
    }
  }
}
