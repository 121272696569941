import { Inject, Injectable } from '@angular/core';
import { BaseApiNoAlerts } from '../../../shared/core/base-api/base-api-no-alerts';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { StorageService } from '../../../shared/core/storage/storage.service';
import { AlertService } from '../../../modules/core/alert/alert.service';
import { Observable } from 'rxjs';
import { FilteredSearch, MailboxMessage, MailboxResult, MessageDetail, SendMessageDetailResponse } from '../../../shared/models/message.model';
import { Channels, MailTabs } from '../../../shared/types/communications';
import { SendMessageModel } from '../models/send-message.model';
import { BulkMessage, Recipient } from '../../../shared/models/communication/bulk-message.model';

@Injectable()
export class CommunicationsService extends BaseApiNoAlerts {

  baseApiUrl = '';

  constructor(public http: HttpClient, public router: Router, public storageService: StorageService, public alertService: AlertService, @Inject('environment') public env: any) {
    super(http, router, storageService, alertService);
    this.baseApiUrl = env.baseApiUrl;
  }

  sendMessage(model: SendMessageModel) : Observable<SendMessageDetailResponse> {
    return this.post(`${this.baseApiUrl}/api/mailbox/message`, model);
  }

  getMessageHistory(customerId: string, channel: Channels, from: Date, pageSize: number) : Observable<MessageDetail[]> {
    return this.get(`${this.baseApiUrl}/api/mailbox/message/${customerId}/${channel}/history?from=${from.toISOString()}&pageSize=${pageSize}`);
  }

  getMailboxPage(filter: FilteredSearch, mailbox: MailTabs): Observable<MailboxResult> {
    // clear null values
    // TODO: see if this can be done with encode option on HttpParams
    Object.keys(filter).forEach(key => !filter[key] ? delete filter[key] : {});
    return this.get(`${this.baseApiUrl}/api/mailbox/${mailbox}`, {params: new HttpParams({fromObject: filter as any})});
  }

  getMailboxContents(customerId: string, channel: Channels, messageId: string): Observable<SendMessageDetailResponse> {
    return this.get(`${this.baseApiUrl}/api/mailbox/message/${customerId}/${channel}/${messageId}`);
  }

  setMessageFlagged(message: MailboxMessage, isFlagged: boolean, mailbox: MailTabs) {
    return this.put(`${this.baseApiUrl}/api/mailbox/message/${message.id}/flag`, { NewStatus: isFlagged });
  }

  setMessageArchived(message: MailboxMessage, isArchived: boolean, mailbox: MailTabs) {
    return this.put(`${this.baseApiUrl}/api/mailbox/message/${message.id}/archive`, { NewStatus: isArchived });
  }

  setMessageRead(message: MailboxMessage, isRead: boolean, mailbox: MailTabs) {
    return this.put(`${this.baseApiUrl}/api/mailbox/message/${message.id}/read`, { NewStatus : isRead });
  }

  setMessageReplied(message: MailboxMessage, isReplied: boolean, mailbox: MailTabs) {
    return this.put(`${this.baseApiUrl}/api/mailbox/message/${message.id}/replied`, { NewStatus: isReplied });
  }

  setMessageFlaggedBulk(messages: MailboxMessage[], isFlagged: boolean, mailbox: MailTabs) {
    let ids = []
    messages.forEach(message => ids.push(message.id));
    return this.put(`${this.baseApiUrl}/api/mailbox/message/bulk/flag`, { NewStatus: isFlagged, Ids: ids});
  }

  setMessageArchivedBulk(messages: MailboxMessage[], isArchived: boolean, mailbox: MailTabs) {
    let ids = []
    messages.forEach(message => ids.push(message.id));
    return this.put(`${this.baseApiUrl}/api/mailbox/message/bulk/archive`, { NewStatus: isArchived, Ids: ids});
  }

  setMessageReadBulk(messages: MailboxMessage[], isRead: boolean, mailbox: MailTabs) {
    let ids = []
    messages.forEach(message => ids.push(message.id));
    return this.put(`${this.baseApiUrl}/api/mailbox/message/bulk/read`, { NewStatus: isRead, Ids: ids});
  }

  setMessageRepliedBulk(messages: MailboxMessage[], isReplied: boolean, mailbox: MailTabs) {
    let ids = []
    messages.forEach(message => ids.push(message.id));
    return this.put(`${this.baseApiUrl}/api/mailbox/message/bulk/replied`, { NewStatus: isReplied, Ids: ids});
  }

  getBulkComms(bulkId: string): Observable<BulkMessage> {
    return this.get(`${this.baseApiUrl}/api/mailbox/message/bulk-comm/${bulkId}`);
  }

  getMonthlySent(): Observable<number> {
    return this.get(`${this.baseApiUrl}/api/mailbox/monthly-sent`);
  }

  getUnread(): Observable<number>{
    return this.get(`${this.baseApiUrl}/api/mailbox/unread`);
  }
}
