import { State, Action, StateContext, Selector } from '@ngxs/store';
import { catchError, finalize, tap } from 'rxjs/operators';
import { AlertService } from '../../../../../modules/core/alert/alert.service';
import { Trigger } from '../../../../../shared/models/pharmacy/trigger.model';
import { TriggerService } from '../../services/trigger.service';
import { GetTriggers, TriggerStateError } from '../../state/triggers.actions';
import { AddTrigger, AddTriggerSuccess, HandleAddEditEvent, SetTriggerForEditing, ToggleAddEditTriggerModal, UpdateTrigger, UpdateTriggerSuccess } from './add-edit-trigger-modal.actions';
import { Injectable } from '@angular/core';

export class AddEditTriggerModalStateModel {
  showModal: boolean;
  loading: boolean;
  addEditTriggerModalForm: {
    model: Partial<Trigger>;
  }
  error: any;
}
@Injectable()
@State<AddEditTriggerModalStateModel>({
  name: 'addEditTriggerModal',
  defaults: {
    showModal: false,
    loading: false,
    addEditTriggerModalForm: {
      model: undefined
    },
    error: null
  }
})
export class AddEditTriggerModalState {
  constructor(private triggerService: TriggerService, private alertService: AlertService) {

  }

  @Selector()
  static addEditTriggerState(state: AddEditTriggerModalStateModel) { return state };

  @Selector()
  static addEditTriggerModalForm(state: AddEditTriggerModalStateModel) { return state.addEditTriggerModalForm };

  @Selector()
  static showModal(state: AddEditTriggerModalStateModel) { return state.showModal };

  @Action(ToggleAddEditTriggerModal)
  toggleAddEditTriggerModal(ctx: StateContext<AddEditTriggerModalStateModel>, { showModal }: ToggleAddEditTriggerModal) {
    ctx.patchState({ showModal: showModal });
    if (!showModal) {
      ctx.patchState({
        addEditTriggerModalForm: {
          model: null
        }
      });
    }
  }

  @Action(SetTriggerForEditing)
  setTriggerForEditing(ctx: StateContext<AddEditTriggerModalStateModel>, { trigger }: SetTriggerForEditing) {
    ctx.patchState({
      addEditTriggerModalForm: {
        model: trigger
      }
    });
  }

  @Action(HandleAddEditEvent)
  handleAddEditEvent(ctx: StateContext<AddEditTriggerModalStateModel>, { }: HandleAddEditEvent) {
    ctx.patchState({
      loading: true
    });

    const currentTrigger = ctx.getState().addEditTriggerModalForm.model;
    return currentTrigger.id == null ?
      ctx.dispatch(new AddTrigger()) :
      ctx.dispatch(new UpdateTrigger())
  }

  @Action(AddTrigger)
  addTrigger(ctx: StateContext<AddEditTriggerModalStateModel>, { }: AddTrigger) {
    ctx.patchState({
      loading: true,
      error: null
    });

    const trigger = ctx.getState().addEditTriggerModalForm.model as Trigger;
    trigger.tenantId = 0;
    trigger.id = 0;

    return this.triggerService.create(trigger).pipe(
      tap(resp => ctx.dispatch(new AddTriggerSuccess(resp))),
      catchError(err => ctx.dispatch(new TriggerStateError(err))),
      finalize(() => ctx.patchState({ loading: false, showModal: false, addEditTriggerModalForm: { model: undefined } }))
    );
  }

  @Action(AddTriggerSuccess)
  addTriggerSuccess(ctx: StateContext<AddEditTriggerModalStateModel>, { trigger }: AddTriggerSuccess) {
    ctx.dispatch(new GetTriggers());
    this.alertService.success('Trigger successfully created!');
  }

  @Action(UpdateTrigger)
  updateTrigger(ctx: StateContext<AddEditTriggerModalStateModel>, { }: UpdateTrigger) {
    ctx.patchState({
      loading: true,
      error: null
    });

    const trigger = ctx.getState().addEditTriggerModalForm.model as Trigger;

    return this.triggerService.update(trigger).pipe(
      tap(resp => ctx.dispatch(new UpdateTriggerSuccess(resp))),
      catchError(err => ctx.dispatch(new TriggerStateError(err))),
      finalize(() => ctx.patchState({ loading: false, showModal: false, addEditTriggerModalForm: { model: undefined } }))
    );
  }

  @Action(UpdateTriggerSuccess)
  updateTriggerSuccess(ctx: StateContext<AddEditTriggerModalStateModel>, { trigger }: UpdateTriggerSuccess) {
    ctx.dispatch(new GetTriggers());
    this.alertService.success('Trigger successfully updated!');
  }

  @Action(TriggerStateError)
  triggerStateError(ctx: StateContext<AddEditTriggerModalStateModel>, { error }: TriggerStateError) {
    return this.alertService.error(JSON.stringify(error.error));
  }
}
