import { Component, OnInit, OnDestroy } from '@angular/core';
import { SickLeaveChildForm } from '../sick-leave-child-form';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Store, Actions } from '@ngxs/store';
import { SickLeaveConsulationState } from './state/sick-leave-consulation.state';
import { SetTotalItemsAndCompletedItems } from './state/sick-leave-consulation.actions';
import { SickLeaveExperiencedSymptomsPeriods, SickLeaveCertificateCoverDays } from '../../../../../shared/models/six-cpa/FormKeyValuePairs';
import * as moment_ from 'moment';
const moment = moment_;

import { SixCpaState } from '../../state/six-cpa.state';
@Component({
  selector: 'app-sick-leave-consulation',
  templateUrl: './sick-leave-consulation.component.html',
  styleUrls: ['./sick-leave-consulation.component.scss']
})
export class SickLeaveConsulationComponent extends SickLeaveChildForm implements OnInit, OnDestroy  {

  sickLeaveConsulationForm: FormGroup;
  private readonlySubscription: Subscription;
  formSubscription: Subscription;
  howLongInterval = Array.from(Array(12), (x, index) => index + 1);
  periods = SickLeaveExperiencedSymptomsPeriods;
  certificateCoverDays = SickLeaveCertificateCoverDays;
  constructor(private formBuilder: FormBuilder, private store: Store, actions: Actions) {
    super(actions);
    this.generateFormControls();
  }

  ngOnInit() {
    this.formSubscription = this.store.select(SickLeaveConsulationState.sickLeaveConsulationForm)
      .subscribe(() => this.onFormChange());
    this.readonlySubscription = this.store.select(SixCpaState.formsReadOnly)
      .subscribe(readonly => readonly ? this.sickLeaveConsulationForm.disable() : this.sickLeaveConsulationForm.enable());
  }

  ngOnDestroy() {
    this.formSubscription.unsubscribe();
    this.readonlySubscription.unsubscribe();
  }
  get formControls() {
    return this.sickLeaveConsulationForm.controls;
  }

  deselectControl(control: string) {
      this.sickLeaveConsulationForm.controls[control].setValue(
        (this.sickLeaveConsulationForm.value.isThereAPriorCertificate && control === 'dateOfCertificate') ? new Date
        : null
      );
  }

  updateDates(certificateCoverDays: SickLeaveCertificateCoverDays) {
      const dateCertificateFrom = this.sickLeaveConsulationForm.controls.dateCertificateFrom.value;
      let dateCertificateTo = moment(dateCertificateFrom, 'DD-MM-YYYY').add(0, 'days').toDate();
    if (certificateCoverDays === SickLeaveCertificateCoverDays.TwoDays) {
       dateCertificateTo = moment(dateCertificateFrom, 'DD-MM-YYYY').add(1, 'days').toDate();
    }
    this.sickLeaveConsulationForm.patchValue(
      {
        dateCertificateFrom: dateCertificateFrom,
        dateCertificateTo: dateCertificateTo,
      });
  }
  protected actionOnResetForm() {
    this.sickLeaveConsulationForm.reset();
    this.sickLeaveConsulationForm.patchValue(
      {
        satisfiedThePersonIsUnfitForWork: true,
        dateCertificateFrom: new Date(),
        dateCertificateTo: new Date(),
        dateOfCertificate: new Date()
      });
  }

  private onFormChange() {
    const controlsToExclude = [];
    if (!this.sickLeaveConsulationForm.value.hasPersonConsultedADoctor) {
      controlsToExclude.push('detailOfConsultation');
    }
    if (!this.sickLeaveConsulationForm.value.isThereAPriorCertificate) {
      controlsToExclude.push('dateOfCertificate');
    }
    if (!this.sickLeaveConsulationForm.value.isAMedicationBeingProvidedByYou) {
      controlsToExclude.push('medicationDetail');
    }
    if (!this.sickLeaveConsulationForm.value.haveReferredToAnyOtherHealthCareProfessional) {
      controlsToExclude.push('referralDetail');
    }
    const controls = Object.keys(this.sickLeaveConsulationForm.controls);
    const totalControls = controls.filter(x => !controlsToExclude.includes(x));
    const completedItems = this.getTotalValidItems(totalControls);
    this.store.dispatch(new SetTotalItemsAndCompletedItems(totalControls.length, completedItems));
  }

  private getTotalValidItems(controls: Array<string>) {
    return controls.filter((key) => {
      const formControl = this.sickLeaveConsulationForm.get(key);
      return  formControl.status === 'VALID' || (formControl.status === 'DISABLED' && !formControl.invalid && formControl.errors === null);
    }).length;
  }

  private generateFormControls() {
    const dateCertificateTo = moment(new Date(), 'DD-MM-YYYY').toDate();
    this.sickLeaveConsulationForm = this.formBuilder.group({
      whatIsTheIllness: new FormControl('', Validators.required),
      describeVisualSymptoms: new FormControl('', Validators.required),
      howLongExperiencedSymptomsInterval: new FormControl(1, Validators.required),
      howLongExperiencedSymptomsPeriod: new FormControl(SickLeaveExperiencedSymptomsPeriods.Days, Validators.required),
      hasPersonConsultedADoctor: new FormControl(false, Validators.required),
      isThereAPriorCertificate: new FormControl(false, Validators.required),
      isAMedicationBeingProvidedByYou: new FormControl(false, Validators.required),
      satisfiedThePersonIsUnfitForWork: new FormControl(true, Validators.requiredTrue),
      certificateCoverDays: new FormControl(SickLeaveCertificateCoverDays.OneDay, Validators.required),
      dateCertificateFrom: new FormControl(new Date(), Validators.required),
      dateCertificateTo: new FormControl(dateCertificateTo, Validators.required),
      haveReferredToAnyOtherHealthCareProfessional: new FormControl(false, Validators.required),
      detailOfConsultation: new FormControl('', Validators.required),
      dateOfCertificate: new FormControl(null, Validators.required),
      medicationDetail: new FormControl('', Validators.required),
      referralDetail: new FormControl('', Validators.required),
    });
  }


}
