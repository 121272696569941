import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import { ReactiveFormsModule } from '@angular/forms';
import { SSMedicationInformationFormComponent } from './ss-medication-information-form.component';
import { FormControlFunctions } from '../../form-control.functions';

@NgModule({
  declarations: [
    SSMedicationInformationFormComponent
  ],
  imports: [
    CommonModule,
    NgxsFormPluginModule,
    ReactiveFormsModule
  ],
  exports: [
    SSMedicationInformationFormComponent
  ],
  providers: [
    FormControlFunctions
  ]
})
export class SSMedicationInformationFormModule { }
