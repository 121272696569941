<form [formGroup]="patientDetailsForm" ngxsForm="hmrPatientDetailsFormBasic.patientDetailsForm" class="p-4">
    <div class="form-group pb-1">
        <label for="medicareNumber" class="input-label">Medicare or DVA Number</label>
        <input type="text" [class.has-error]="formControls.medicareNumber.invalid && (showInvalid$ | async)"
            class="form-control form-control-lg w-25" name="medicareNumber" formControlName="medicareNumber"
            id="medicareNumber" placeholder="123456789" required>
    </div>
    <div class="form-group pb-1">
        <label for="firstName" class="input-label">Patient Given Name(s)
        </label>
        <input type="text" [class.has-error]="formControls.firstName.invalid && (showInvalid$ | async)"
            class="form-control form-control-lg w-25" name="firstName" formControlName="firstName" id="firstName"
            required>
    </div>
    <div class="form-group pb-1">
        <label for="surname" class="input-label">Patient Family Name
        </label>
        <input type="text" [class.has-error]="formControls.surname.invalid && (showInvalid$ | async)"
            class="form-control form-control-lg w-25" name="surname" formControlName="surname" id="surname" required>
    </div>
    <div class="form-group pb-1">
        <label for="dob" class="input-label">Patients Date of Birth</label>
        <input type="text" [class.has-error]="formControls.dob.invalid && (showInvalid$ | async)"
            class="form-control form-control-lg w-25" value="patientDetailsForm.value.dob | date: 'DD-MM-YYYY'"
            formControlName="dob" id="dob" [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY' }" placeholder="dd-mm-yyyy"
            bsDatepicker>
    </div>
</form>