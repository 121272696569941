import { State, Selector, StateContext, Action } from '@ngxs/store';
import { BulkMessage } from '~/shared/models/communication/bulk-message.model';
import { BulkMessageError, GetBulkMessageWithRecipients } from './bulk-messaging.actions';
import { BulkMessagingService } from '../services/bulk-messaging.service';
import { tap, catchError, finalize } from 'rxjs/operators';
import { Injectable } from '@angular/core';

export class BulkMessagingStateModel {
  loadingCurrentMessage: boolean;
  currentMessage: BulkMessage;
}
@Injectable()
@State<BulkMessagingStateModel>({
  name: 'bulkMessaging',
  defaults: {
    loadingCurrentMessage: false,
    currentMessage: null
  }
})
export class BulkMessagingState {
  constructor(private bulkMessagingService: BulkMessagingService) { }

  @Selector()
  static currentBulkMessage(state: BulkMessagingStateModel) { return state.currentMessage; }

  @Selector()
  static loadingCurrentMessage(state: BulkMessagingStateModel) { return state.loadingCurrentMessage; }


  @Action(GetBulkMessageWithRecipients)
  getBulkMessageHistoryRecipients({ patchState, dispatch }: StateContext<BulkMessagingStateModel>, { bulkMessageId }: GetBulkMessageWithRecipients) {
    patchState({ loadingCurrentMessage: true });
    return this.bulkMessagingService.getMessageWithRecipients(bulkMessageId).pipe(
      tap(message => patchState({ currentMessage: message })),
      catchError(error => dispatch(new BulkMessageError(error))),
      finalize(() => patchState({ loadingCurrentMessage: false }))
    );
  }
}
