import { Component, EventEmitter, Inject, Input, OnInit, Output, ViewEncapsulation, } from '@angular/core';
import { RepeatType } from '../../../shared/models/profile/repeatType.model';
import { SixCpaFormTypesDictionary } from '../six-cpa/six-cpa-form-types.enum';
import { CustomerStatus, IndigenousStatus } from '../../../shared/models/profile/customerProfile.model';
import * as moment_ from 'moment';
import { ContactMethods } from '../../../shared/models/communication/contact-methods.enum';
import { Select, Store } from '@ngxs/store';
import { OpenRegisterMySl, OpenSummaryModal } from '../asl/state/asl.actions';
import { AslPatient, SLConsent, SLConsentState } from '../asl/state/asl.models';
import { MobileInfo, MobileState, MobileStateModalState } from './mobile-state-modal/state/mobile-state-modal.state';
import { Observable } from 'rxjs';
import { OpenMobileStateModal } from './mobile-state-modal/state/mobile-state-modal.actions';
import { ASLState } from '../asl/state/asl.state';
import { ProfileState } from '../../../modules/core/profile/state/profile.state';
import { CustomerType } from '../../../modules/profile/models/customerType.enum';
import { OrderMethod } from '../../../shared/models/communication/order-method.enum';
import { ProfileService } from '../../../shared/services/profile.service';
import { GetOptOutLookup, GetProfileAction } from '../../../modules/core/profile/state/profile.actions';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { PrimaryContactState, PrimaryContactStateModel, PrimaryContactStateStatus } from '../../../modules/profile/primary-contact/state/primary-contact.state';
import { GetPrimaryContactSummary, TogglePrimaryContactModal } from '../../../modules/profile/primary-contact/state/primary-contact.actions';
import { PrimaryContactHelperService } from '../../../modules/profile/primary-contact/helpers/primary-contact.helper';
import { PrimaryContactSummary } from '../../../modules/profile/primary-contact/models/primary-contact-summary.model';

const moment = moment_;

enum InfoBoxType {
  Email,
  SMS,
  OrderCycle
}
@Component({
  selector: 'app-profile-details',
  templateUrl: './profile-details.component.html',
  styleUrls: ['./profile-details.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ProfileDetailsComponent implements OnInit {
  @Select(MobileStateModalState.showModal) showModal$: Observable<boolean>;
  @Select(ASLState.isFetchingPatient) isFetchingPatient$: Observable<boolean>;
  @Select(ASLState.aslPatientLoadFailed) aslPatientLoadFailed$: Observable<boolean>;
  @Select(ProfileState.lastVisitDate) lastVisitDate$: Observable<Date | string>;
  @Select(PrimaryContactState.state) primaryContactState$: Observable<PrimaryContactStateModel>;
  @Select(ProfileState.optOutlookup) isOptOut$: Observable<boolean>;

  @Input() customer: any;
  @Input() aslDetails: AslPatient;
  @Output() editClickEvent = new EventEmitter<boolean>();
  customerProfile: any;
  patient: any;
  repeatType = RepeatType;
  sixCpaFormTypesDictionary = SixCpaFormTypesDictionary;
  customerStatus = CustomerStatus;
  autoComms = false;
  CPA_Version = '';
  contactMethod = ContactMethods;
  showConcessions = false;
  slConsentStates = SLConsentState;
  isLite = false;
  customerType = CustomerType;
  indigenousStatus = IndigenousStatus;
  orderMethod = OrderMethod;
  isEditMode = false;
  loading = false;
  editCustomerForm: FormGroup;
  showOrderCycle = false;
  primaryContactStateStatus = PrimaryContactStateStatus;
  showEmailContactInfoBox = false;
  showSMSContactInfoBox;
  infoBoxTypes = InfoBoxType;

  constructor(
    @Inject('environment') env: any,
    private store: Store,
    private profileService: ProfileService,
    private formBuilder: FormBuilder,
    private pcHelper: PrimaryContactHelperService
  ) {
    this.isLite = env.isLite;
  }

  ngOnInit() {
    this.customerProfile = this.customer.customerProfile;
    this.patient = this.customer.patient;
    this.determineCommsStatus();
    this.initCustomerForm();
    this.getPrimaryContactSummary();
  }

  private getPrimaryContactSummary() {
    if (this.customerProfile && this.customerProfile.tenantCustomerId) {
      const { tenantCustomerId } = this.customerProfile;
      const { mobileNumber, emailAddress } = this.customer.patient;
      this.store.dispatch(new TogglePrimaryContactModal(false));
      this.store.dispatch(new GetPrimaryContactSummary(tenantCustomerId, mobileNumber, emailAddress))
      if (mobileNumber) {
        this.store.dispatch(new GetOptOutLookup(mobileNumber));
      }
    }
  }

  private initCustomerForm() {
    this.editCustomerForm = this.formBuilder.group({
      type: new FormControl(this.customerProfile.type),
      groupId: new FormControl(this.customerProfile.groupId),
      status: new FormControl(this.customerProfile.status),
      dAACommencementDate: new FormControl(moment(this.customerProfile.daaCommencementDate, 'YYYY-MM-DD').format('DD-MM-YYYY')),
      indigenousStatus: new FormControl(this.customerProfile.indigenousStatus),
    });
  }

  private determineCommsStatus() {
    this.autoComms = this.customerHasContactMethodAndCommsEnabled();
  }

  private customerHasContactMethodAndCommsEnabled(): boolean {
    const customer = this.customerProfile;
    return (
      customer &&
      customer.contactMethod != null &&
      customer.isAutomation
    );
  }

  toggleManagePrimaryContact() {
    this.store.dispatch(new TogglePrimaryContactModal(true));
  }

  toggleEditMode(canEdit: boolean) {
    this.isEditMode = canEdit;
  }

  save() {
    this.loading = true;
    const payload = this.buildCustomerModalModel();

    this.profileService.quickUpdateCustomerProfile(payload).subscribe(_ => {
      this.store.dispatch(new GetProfileAction(this.customerProfile.customerId));
      this.loading = false;
    });

    this.toggleEditMode(false);
  }

  private buildCustomerModalModel() {
    const { type, dAACommencementDate, status, indigenousStatus, groupId } = this.editCustomerForm.value;

    const profileModal = {
      customerProfileId: this.customerProfile.customerProfileId,
      tenantCustomerId: this.customerProfile.tenantCustomerId,
      type: type,
      groupId: groupId,
      status: status,
      dAACommencementDate: dAACommencementDate ? moment(dAACommencementDate, 'DD-MM-YYYY').toDate() : null,
      indigenousStatus: indigenousStatus ? indigenousStatus : IndigenousStatus.Unsure
    };

    return profileModal;
  }

  toggleInfoBox(infoBoxType: InfoBoxType) {
    switch (infoBoxType) {
      case InfoBoxType.SMS: {
        this.showSMSContactInfoBox = !this.showSMSContactInfoBox;
        this.showEmailContactInfoBox = false;
        break;
      }
      case InfoBoxType.Email: {
        this.showEmailContactInfoBox = !this.showEmailContactInfoBox;
        this.showSMSContactInfoBox = false;
        break;
      }
      case InfoBoxType.OrderCycle: {
        this.showOrderCycle = !this.showOrderCycle;
        break;
      }
      default: {
        this.showSMSContactInfoBox = !this.showSMSContactInfoBox;
      }
    }
  }

  getSMSPrimaryName(summary: PrimaryContactSummary) {
    return this.pcHelper.getSMSPrimaryName(summary);
  }

  getEmailPrimaryName(summary: PrimaryContactSummary) {
    return this.pcHelper.getEmailPrimaryName(summary);
  }

  hasPrimarySMSContact(summary: PrimaryContactSummary): boolean {
    return this.pcHelper.hasPrimarySMSContact(summary);
  }

  hasPrimaryEmailContact(summary: PrimaryContactSummary): boolean {
    return this.pcHelper.hasPrimaryEmailContact(summary);
  }

  daysAgo(lastVisitDate) {
    const friendlyListDispensed = moment().diff(lastVisitDate, 'days');

    return friendlyListDispensed;
  }

  getLastDispensedClass(lastVisitDate) {
    const days = this.daysAgo(lastVisitDate);

    if (days < 30) {
      return 'icon_success';
    }
    else if (days > 30 && days < 90) {
      return 'icon_warning';
    }
    else {
      return 'icon_error';
    }
  }

  getAge(birthDate) {
    const age = moment().diff(birthDate, 'years');
    return age;
  }

  getNextOrderDate(date: Date, interval: number) {
    return moment(date).add(interval, 'days').toDate();
  }

  showPreferredDoctor() {
    return !!this.patient.preferredDoctor;
  }

  showMedExScore() {
    return false;
  }

  getContactMethod() {
    const result = this.customer.contactMethods.find(
      (x) => x.key === this.customerProfile.contactMethod
    ) || { value: '' };
    return result.value;
  }

  editClick() {
    this.editClickEvent.emit(true);
  }

  getCustomerStatus(status: any) {
    return CustomerStatus[status];
  }

  toggleConcessions(): void {
    this.showConcessions = !this.showConcessions;
  }

  openMySLDetails(): void {
    if (this.aslDetails?.status?.isRegistered) {
      this.store.dispatch(new OpenSummaryModal());
    } else {
      this.store.dispatch(new OpenRegisterMySl());
    }
  }

  getASLStatus(status: SLConsent): string {
    if (!status || !status.isRegistered) {
      return 'Not registered';
    } else if (status.infaStatus === SLConsentState.Rejected) {
      return 'Rejected';
    } else if (status.infaStatus === SLConsentState.Active) {
      return 'Access Granted';
    } else if (status.infaStatus === SLConsentState.Proposed) {
      return 'Consent Pending';
    } else {
      return 'Access Required';
    }
  }


  getMobileStatus(status: any) {
    return MobileState[status];
  }

  editMobileStatus(info: any) {
    this.store.dispatch(
      new OpenMobileStateModal(
        new MobileInfo(
          info.mobileStatus,
          info.lastInviteDate,
          info.mobileSignupDate,
          info.inviteEmail,
          info.inviteMobileNumber
        )
      )
    );
  }
}
