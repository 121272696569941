import { State, Selector, Action, StateContext } from '@ngxs/store';
import {
  SetDAAFormType,
  ResetDAAFormListAction,
  SaveDAAFormSuccessAction,
  SaveDAAFormFailedAction,
  SubmitNewDAA,
  SubmitExisitingDAA,
  SaveNewDAA,
  SaveExisitingDAA,
  UpdateDAAClaimForms,
  UpdateDAARegistrationForms,
  GetDAAFormSuccess,
  GetDAAForm,
  GetDAAFormError,
  ShowInvalidDAAForm
} from './dose-administration-aids.actions';
import { DoseAdministrationAidsService } from '../services/dose-administration-aids.service';
import { switchMap, catchError, finalize } from 'rxjs/operators';
import { ToggleSixCpaAction, CloseResetFormModal, SetSelectedFormType, SetSixCpaFormSummary, ToggleSixCpaContentAction } from '../../../state/six-cpa.actions';
import { UpdateDAAAdditionalDetailsForm } from '../../daa-additional-details-form/state/daa-additional-details-form.actions';
import { UpdateDAAPatientDetailsForm } from '../../daa-patient-details-form/state/daa-patient-details-form.actions';
import { UpdateDAAMedicationInformationForm } from '../../daa-medication-information-form/state/daa-medication-information-form.actions';
import { UpdateDAAClassificationsRecommendationsForm } from '../../daa-classifications-recommendations/state/daa-classifications-recommendations.actions';
import { UpdateDAAConsentAndEligibilityForm } from '../../daa-consent-and-eligibility-form/state/daa-consent-and-eligibility-form.actions';
import { ResetDAAMedicationProfileIdsAction, UpdateDAAMedicationProfileList } from '../../daa-medication-profile/state/daa-medication-profile.actions';
import { AlertService } from '~/modules/core/alert/alert.service';
import { SetSixCpaSuccessMessages, SetSixCpaErrors, ClearSixCpaCommunications, SetSixCpaInfo } from '../../../six-cpa-user-communications/state/six-cpa-user-communications.actions';
import { Injectable } from '@angular/core';
import { SaveOrUpdateDAAAction } from './daa-administration-aids-save.actions';
import { SixCpaFormTypes } from '../../../six-cpa-form-types.enum';
import { SixCpaResponse } from '~/modules/profile/profile-services/state/profile-services.state';
import { closeConfirmationActions } from '../../../state/six-cpa-toggles-list';
import { GetSixCpaFormsFromService } from '~/modules/profile/profile-services/state/profile-services.actions';

export class DoseAdministrationAidsStateModel {
  daaId: number;
  sixCpaFormId: number;
  patientDetailsFormId: number;
  consentAndEligibilityFormId: number;
  medicationInformationFormId: number;
  classificationsAndRecommendationsFormId: number;
  additionalDetailsFormId: number;
  valid: boolean;
  daaFormType: DAAFormType;
  saving: boolean;
  showConfirmForm: boolean;
  showInvalid: boolean;
  static showConfirmForm(showConfirmForm: any) {
    throw new Error("Method not implemented.");
  }
  error: any;
}

export enum DAAFormType {
  PatientRegistration,
  DAAClaim
}
@Injectable()
@State<DoseAdministrationAidsStateModel>({
  name: 'doseAdministrationAids',
  defaults: {
    daaId: null,
    sixCpaFormId: null,
    patientDetailsFormId: null,
    consentAndEligibilityFormId: null,
    medicationInformationFormId: null,
    classificationsAndRecommendationsFormId: null,
    additionalDetailsFormId: null,
    valid: false,
    daaFormType: DAAFormType.PatientRegistration,
    saving: false,
    error: null,
    showConfirmForm: false,
    showInvalid: false
  }
})

export class DoseAdministrationAidsState {
  constructor(private daaService: DoseAdministrationAidsService, private alertService: AlertService) { }

  @Selector()
  static saving(state: DoseAdministrationAidsStateModel) { return state.saving; }

  @Selector()
  static daaFormType(state: DoseAdministrationAidsStateModel) { return state.daaFormType; }

  @Selector()
  static sixCpaFormId(state: DoseAdministrationAidsStateModel) { return state.sixCpaFormId; }

  @Selector()
  static showConfirmForm(state: DoseAdministrationAidsStateModel) { return state.showConfirmForm; }

  @Selector()
  static showInvalid(state: DoseAdministrationAidsStateModel) { return state.showInvalid; }

  @Action(SetDAAFormType)
  setDAAFormType(ctx: StateContext<DoseAdministrationAidsStateModel>, action: SetDAAFormType) {
    ctx.patchState({
      daaFormType: action.daaFormType
    });
  }

  @Action(ResetDAAFormListAction)
  resetDAAForm(ctx: StateContext<DoseAdministrationAidsStateModel>, {onlyClearFormIds}: ResetDAAFormListAction) {
    ctx.patchState({
      daaId: null,
      sixCpaFormId: null,
      additionalDetailsFormId: null,
      classificationsAndRecommendationsFormId: null,
      consentAndEligibilityFormId: null,
      medicationInformationFormId: null,
      patientDetailsFormId: null,
      daaFormType:DAAFormType.DAAClaim,
    });
    ctx.dispatch(new ResetDAAMedicationProfileIdsAction());
    if (!onlyClearFormIds) {
      ctx.dispatch(new ToggleSixCpaAction('out'));
    }
    ctx.dispatch([
      new CloseResetFormModal(),
      new ClearSixCpaCommunications()
    ]);
  }

  @Action(SaveDAAFormSuccessAction)
  saveDAASuccess(ctx: StateContext<DoseAdministrationAidsStateModel>, { formData }: SaveDAAFormSuccessAction) {
    const { id, sixCpaFormId, patientDetailsFormId, consentAndEligibilityFormId, medicationInformationFormId,
      classificationAndRecommendationsFormId: classificationsAndRecommendationsFormId, additionalDetailsFormId, hasSubmitted } = formData;
    
    ctx.patchState({
      daaId: id,
      sixCpaFormId,
      patientDetailsFormId,
      consentAndEligibilityFormId,
      medicationInformationFormId,
      classificationsAndRecommendationsFormId,
      additionalDetailsFormId
    });
    this.alertService.success('Success! The DAA Form has been saved to Scrypt.');
    ctx.dispatch([
      new SetSixCpaSuccessMessages(['The DAA Form has been saved to Scrypt.']),
      new SetSixCpaFormSummary({
        id: sixCpaFormId,
        formType: SixCpaFormTypes.DAA,
        hasSubmitted: hasSubmitted
      } as SixCpaResponse)
    ]);
    ctx.dispatch(new GetSixCpaFormsFromService(null));
  }

  @Action(SaveDAAFormFailedAction)
  saveDAAFailed(ctx: StateContext<DoseAdministrationAidsStateModel>, { error }: SaveDAAFormFailedAction) {
    ctx.dispatch(new SetSixCpaErrors([error]));
  }

  @Action(SubmitNewDAA)
  submitNewDAA(ctx: StateContext<DoseAdministrationAidsStateModel>, { clientId, formData }: SubmitNewDAA) {
    ctx.patchState({ saving: true, showConfirmForm: false });

    return this.daaService.postSubmit(clientId, formData).pipe(
      switchMap(response => ctx.dispatch([new SaveDAAFormSuccessAction(response)])),
      catchError(error => ctx.dispatch([new SaveDAAFormFailedAction(error), new SetSixCpaInfo(["We were unable to submit the claim so we've saved it as a draft."]), new SaveOrUpdateDAAAction()])),
      finalize(() => {
        ctx.patchState({ saving: false });
      }));
  }

  @Action(SubmitExisitingDAA)
  submitExisitingDAA(ctx: StateContext<DoseAdministrationAidsStateModel>, { clientId, formData }: SubmitExisitingDAA) {
    ctx.patchState({ saving: true, showConfirmForm: false });
    return this.daaService.putSubmit(clientId, formData).pipe(
      switchMap(response => ctx.dispatch([new SaveDAAFormSuccessAction(response)])),
      catchError(error => ctx.dispatch([new SaveDAAFormFailedAction(error), new SetSixCpaInfo(["We were unable to submit the claim so we've saved it as a draft."]), new SaveOrUpdateDAAAction()])),
      finalize(() => {
        ctx.patchState({ saving: false });
      }));
  }

  @Action(SaveNewDAA)
  saveNewDAA(ctx: StateContext<DoseAdministrationAidsStateModel>, { clientId, formData }: SaveNewDAA) {
    ctx.patchState({ saving: true });

    return this.daaService.postSave(clientId, formData).pipe(
      switchMap(response => ctx.dispatch([new SaveDAAFormSuccessAction(response)])),
      catchError(error => ctx.dispatch(new SaveDAAFormFailedAction(error))),
      finalize(() => ctx.patchState({ saving: false })));
  }

  @Action(SaveExisitingDAA)
  saveExisitingDAA(ctx: StateContext<DoseAdministrationAidsStateModel>, { clientId, formData }: SaveExisitingDAA) {
    ctx.patchState({ saving: true });

    return this.daaService.putSave(clientId, formData).pipe(
      switchMap(response => ctx.dispatch([new SaveDAAFormSuccessAction(response)])),
      catchError(error => ctx.dispatch(new SaveDAAFormFailedAction(error))),
      finalize(() => ctx.patchState({ saving: false })));
  }

  @Action(GetDAAForm)
  getDAAForm(ctx: StateContext<DoseAdministrationAidsStateModel>, { clientId, sixCpaFormId }: GetDAAForm) {
    return this.daaService.getDAAForm(clientId, sixCpaFormId)
      .pipe(switchMap(response => ctx.dispatch([new GetDAAFormSuccess(response), new ToggleSixCpaAction('in')])),
        catchError(err => ctx.dispatch(new GetDAAFormError(err))));
  }

  @Action(GetDAAFormSuccess)
  getDAASuccess(ctx: StateContext<DoseAdministrationAidsStateModel>, { daaForm }: GetDAAFormSuccess) {
    ctx.patchState({
      daaId: daaForm.id,
      sixCpaFormId: daaForm.sixCpaFormId
    });

    ctx.dispatch(closeConfirmationActions);
    
    daaForm.daaFormType === DAAFormType.PatientRegistration
      ? ctx.dispatch(new UpdateDAARegistrationForms(daaForm))
      : ctx.dispatch(new UpdateDAAClaimForms(daaForm));
  }

  @Action(UpdateDAARegistrationForms)
  updateDAARegistrationForms(ctx: StateContext<DoseAdministrationAidsStateModel>, { daaForm }: UpdateDAARegistrationForms) {
    ctx.dispatch([
      new UpdateDAAPatientDetailsForm(daaForm.patientDetailsForm),
      new UpdateDAAAdditionalDetailsForm(daaForm.additionalDetailsForm),
      new UpdateDAAMedicationInformationForm(daaForm.medicationInformationForm),
      new UpdateDAAClassificationsRecommendationsForm(daaForm.classificationAndRecommendationsForm),
      new UpdateDAAConsentAndEligibilityForm(daaForm.consentAndEligibilityForm),
      new UpdateDAAMedicationProfileList(daaForm.sixCpaMedications),
      new SetSelectedFormType(SixCpaFormTypes.DAA),
      new SetDAAFormType(daaForm.daaFormType)
    ]);
  }

  @Action(UpdateDAAClaimForms)
  updateDAAClaimForms(ctx: StateContext<DoseAdministrationAidsStateModel>, { daaForm }: UpdateDAAClaimForms) {
    ctx.dispatch([
      new UpdateDAAPatientDetailsForm(daaForm.patientDetailsForm),
      new SetSelectedFormType(SixCpaFormTypes.DAA),
      new SetDAAFormType(daaForm.daaFormType),
      new ToggleSixCpaAction('in')
    ]);
  }

  @Action(GetDAAFormError)
  getDAAFormError(ctx: StateContext<DoseAdministrationAidsStateModel>, action: GetDAAFormError) {
    ctx.patchState({
      error: action.error
    });
  }

  @Action(ShowInvalidDAAForm)
  showInvalidDAAForm(ctx: StateContext<DoseAdministrationAidsStateModel>) {
    ctx.patchState({ showInvalid: true});
  }
}
