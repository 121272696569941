import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { OrderRequestStatus } from "@profile/order-requests/models/order.enums";
import { StatusFilterOption, TabName } from "../../order-mgmt.types";

type OrderStatusFilter = OrderRequestStatus | 'Default';

@Component({
    selector: 'app-order-mgmt-tab-status-filter',
    templateUrl: './tab-status-filter.component.html',
    styleUrls: ['tab-status-filter.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TabStatusFilterComponent {
    @Input() selectedTab: TabName;
    @Input() statusFilters: StatusFilterOption[];
    @Input() isLoading: boolean;
    @Input() selectedStatus: OrderStatusFilter = 'Default';
    @Output() onStatusFilterChange = new EventEmitter<OrderStatusFilter>();
    orderStatusEnum = OrderRequestStatus;

    changeStatusFilter($event: OrderStatusFilter) {
        this.onStatusFilterChange.emit($event);
    }
}
