import { Store } from '@ngxs/store';
import { TimezoneState } from '../state/timezone.state';
import { convertAndFormat } from './timezone.logic';


export function getTenantTimezoneDateString(store: Store, utcDate: string | Date, format: string): string {
  if (!utcDate) { return ''; }
  const timezone = store.selectSnapshot(TimezoneState.selectedTimezone);
  const isDebug = store.selectSnapshot(TimezoneState.isDebugging);
  return convertAndFormat(utcDate, timezone, isDebug, format);
}
