import { Component, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { slide, slideUp } from '../../../shared/core/animations';
import { Store } from '@ngxs/store';
import { ProfileState } from '../profile/state/profile.state';

export const BLADE_VISIBLE_CONTENT_CONTAINER_CLASSNAME = 'bladeVisible';

@Component({
  selector: 'app-blade',
  templateUrl: './blade.component.html',
  styleUrls: ['./blade.component.scss'],
  animations: [ slideUp, slide ],
})
export class BladeComponent implements OnDestroy {
  zIndex = 0;
  @Input() doubleWidth = false;
  @Input() mobile = false;
  @Input() mobileHeight = 70;
  @Input()
  set showBlade(value) {
    if (this._showBlade !== value) {
      this._showBlade = value;
      this.zIndex = this.store.selectSnapshot(ProfileState.zIndexCounter) + 100;
      this.toggleBlade();
    }
  }
  get showBlade() { return this._showBlade; }

  @Output() closeEvent = new EventEmitter<any>();

  private _showBlade = false;

  constructor(private store: Store) {}

  closeBlade() {
    this.showBlade = false;
    this.closeEvent.emit();
  }

  ngOnDestroy() {
    this.closeBlade();
  }

  private toggleBlade() {
    if (!this._showBlade) {
      this.closeBlade();
    }
  }
}
