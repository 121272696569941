import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { GetClientNotes } from '../../../modules/profile/notes/state/notes.actions';
import { ProfileState } from './state/profile.state';

@Injectable()
export class NotesResolver implements Resolve<Observable<any>> {
  constructor(private store: Store) { }
  resolve(route: ActivatedRouteSnapshot): Observable<any> {
    const clientId = this.store.selectSnapshot(ProfileState.clientId);
    return this.store.dispatch([
      new GetClientNotes(clientId)
    ]);
  }
}
