import { ConsentEligibilityFormState } from './state/consent-eligibility-form.state';
import { ConsentEligibilityFormBasicState } from './state/consent-eligibility-form-basic.state';
import { ConsentEligibilityFormDetailedState } from './state/consent-eligibility-form-detailed.state';
import { NgxsModule } from '@ngxs/store';
import { NgModule } from '@angular/core';

@NgModule({
  imports: [
    NgxsModule.forFeature([
      ConsentEligibilityFormState,
      ConsentEligibilityFormBasicState,
      ConsentEligibilityFormDetailedState
    ])
  ]
})
export class ConsentEligibilityStateModule { }
