import { State, Selector, StateContext, Action, Actions, ofActionDispatched, Store } from '@ngxs/store';
import { switchMap, catchError, takeUntil, filter } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { CustomerDocument, DocumentStorageService, PatientDocumentViewModel } from '../services/document-storage.service';
import { CancelDocumentUpload, ClearDocuments, DeleteDocument, DeleteDocumentFailed, DeleteDocumentSuccess, GetDocument, SetUploadedDocuments, UploadDocument, UploadDocumentFailed, UploadDocumentSuccess } from './upload-document-form.actions';
import { HttpEventType } from '@angular/common/http';
import { ProfileState } from '../../../../core/profile/state/profile.state';
import { ProgressStatusEnum } from '../../../../../shared/models/progress-status-type.enum';

export interface ProgressStatus {
    status: ProgressStatusEnum;
    percentage?: number;
}

export interface FormDocument {
    uploadId: number;
    document: PatientDocumentViewModel | CustomerDocument;
    progressStatus: ProgressStatus; 
    error: any;
}


export class UploadDocumentsFormStateModel {
  documents: FormDocument[];
  error: any;
}

@Injectable()
@State<UploadDocumentsFormStateModel>({
  name: 'uploadDocumentsForm',
  defaults: {
    documents: [],
    error: null
  }
})
export class UploadDocumentFormState {
  constructor(
    private service: DocumentStorageService,
    private actions$: Actions,
    private store: Store
  ) { }

  @Selector() 
  static saving(state: UploadDocumentsFormStateModel) {
    return state.documents.filter(doc => 
      doc.progressStatus.status == ProgressStatusEnum.START ||
      doc.progressStatus.status == ProgressStatusEnum.IN_PROGRESS).length > 0
  }

  @Selector() 
  static documents(state: UploadDocumentsFormStateModel) {
    return state.documents.filter(doc => 
      doc.progressStatus.status == ProgressStatusEnum.COMPLETE).map(doc => doc.document)
  }

  @Action(GetDocument)
  getDocumentFile(ctx: StateContext<UploadDocumentsFormStateModel>, { id, filename }: GetDocument) {
    return this.service.getDocument(id).subscribe((response: any) =>{
        let dataType = response.type;
        let binaryData = [];
        binaryData.push(response);
        let downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
        if (filename)
            downloadLink.setAttribute('download', filename);
        document.body.appendChild(downloadLink);
        downloadLink.click();
    });
  }

  @Action(DeleteDocument)
  deleteDocumentFile(ctx: StateContext<UploadDocumentsFormStateModel>, { id }: DeleteDocument) {
    return this.service.deleteDocument(id).pipe(
        switchMap(response => ctx.dispatch(new DeleteDocumentSuccess(id))),
        catchError(error => ctx.dispatch(new DeleteDocumentFailed(error, id)))
    );
  }

  @Action(DeleteDocumentSuccess)
  deleteDocumentFileSuccess(ctx: StateContext<UploadDocumentsFormStateModel>, { id }: DeleteDocumentSuccess) {
    const state = ctx.getState();
    ctx.patchState({
        documents:state.documents.filter(doc => doc.document.id != id)
    })
  }

  @Action(DeleteDocumentFailed)
  deleteDocumentFailed(ctx: StateContext<UploadDocumentsFormStateModel>, { errors, id }: DeleteDocumentFailed) {
    const state = ctx.getState();
    ctx.patchState({
        documents: state.documents.map(doc => {
            if (doc.document.id === id) {
                return {...doc,
                    error: errors
                }
            }else{
                return doc;
            }
        })
    });
  }


  @Action(SetUploadedDocuments)
  setUploadedDocuments(ctx: StateContext<UploadDocumentsFormStateModel>, { documents }: SetUploadedDocuments) {
    ctx.patchState({ 
        documents: [...documents.map((doc, index) => ({
            uploadId: index,
            document: doc,
            progressStatus: { 
                status: ProgressStatusEnum.COMPLETE,
                percentage: 100
            }
        } as FormDocument))]
     });
  }

  @Action(UploadDocument)
  uploadDocument(ctx: StateContext<UploadDocumentsFormStateModel>, { document }: UploadDocument) {
    const state = ctx.getState();
    const newForm = {
        progressStatus: { 
            status: ProgressStatusEnum.START,
            percentage: 0
        },
        uploadId: Math.max(0, ...state.documents.map(doc => doc.uploadId)) + 1
    } as FormDocument;

    ctx.patchState({ 
        documents: [...state.documents, newForm ]
     });

    const customerId = this.store.selectSnapshot(ProfileState.clientId);
    document.customerId = customerId;

    return this.service.uploadDocument(document).pipe(
        takeUntil(
            this.actions$.pipe(
                ofActionDispatched(CancelDocumentUpload),
                filter(action => (action as CancelDocumentUpload).uploadId === newForm.uploadId)
            )
        ),
        switchMap(response => ctx.dispatch(new UploadDocumentSuccess(response, newForm.uploadId))),
        catchError(error => ctx.dispatch(new UploadDocumentFailed(error, newForm.uploadId)))
    );
  }

  @Action(CancelDocumentUpload)
  cancelUpload(ctx: StateContext<UploadDocumentsFormStateModel>, { uploadId }: CancelDocumentUpload) {
    const state = ctx.getState();
    ctx.patchState({ 
        documents: state.documents.map(doc => {
            if (doc.uploadId === uploadId) {
                return {
                    ...doc,
                    progressStatus: {
                        status: ProgressStatusEnum.CANCELED,
                        percentage: null
                    }
                } as FormDocument;
            } else {
                return doc;
            }
        })
     });
  }

  @Action(UploadDocumentSuccess)
  uploadDocumentSuccess(ctx: StateContext<UploadDocumentsFormStateModel>, { event, uploadId }: UploadDocumentSuccess) {
    const state = ctx.getState();
    if(event) {
        console.log(HttpEventType[event.type])
        switch(event.type) {
            case HttpEventType.UploadProgress:
                //in progress
                ctx.patchState({ 
                    documents: state.documents.map(doc => {
                        if (doc.uploadId === uploadId) {
                            return {
                                ...doc,
                                progressStatus: {
                                    status: ProgressStatusEnum.IN_PROGRESS,
                                    percentage: Math.round((event.loaded / event.total) * 100)
                                }
                            } as FormDocument;
                        } else {
                            return doc;
                        }
                    })
                 });
                break;
            case HttpEventType.Response:
                //complete
                ctx.patchState({ 
                    documents: state.documents.map(doc => {
                        if (doc.uploadId === uploadId) {
                            return {
                                ...doc,
                                document: event.body,
                                progressStatus: {
                                    status: ProgressStatusEnum.COMPLETE,
                                    percentage: 100
                                }
                            } as FormDocument;
                        } else {
                            return doc;
                        }
                    })
                });
                break;
        }
    }
  }

  @Action(UploadDocumentFailed)
  uploadDocumentFailed(ctx: StateContext<UploadDocumentsFormStateModel>, { errors, uploadId }: UploadDocumentFailed) {
    const state = ctx.getState();
    ctx.patchState({
        documents: state.documents.map(doc => {
            if (doc.uploadId === uploadId) {
                return {...doc,
                    progressStatus: {
                        status: ProgressStatusEnum.ERROR
                    },
                    error: errors
                }
            }else{
                return doc;
            }
        })
    });
  }


  @Action(ClearDocuments)
  resetFormToDefaults(ctx: StateContext<UploadDocumentsFormStateModel>, {}: ClearDocuments) {
    ctx.patchState({
        documents: [],
        error: null
    });
  }

}
