import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CrmService } from '~/shared/services/crm.service';
import { NoteSettingsComponent } from './note-settings.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TimezoneModule } from '../timezone/timezone.module';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzTimePickerModule } from 'ng-zorro-antd/time-picker';

@NgModule({
  declarations: [
    NoteSettingsComponent
  ],
  providers: [
    CrmService
  ],
  imports: [
    TimezoneModule,
    CommonModule,
    FormsModule,
    NzDatePickerModule,
    NzTimePickerModule,
    BsDatepickerModule.forRoot(),
    ReactiveFormsModule
  ],
  exports: [
    NoteSettingsComponent
  ]
})
export class NoteSettingsModule { }
