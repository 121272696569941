import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxsModule } from '@ngxs/store';
import { ASLState } from '../asl/state/asl.state';
import { AslRegisterFormComponent } from '../asl/asl-register-form/asl-register-form.component';
import { AslSummaryFormComponent } from '../asl/asl-summary-form/asl-summary-form.component';
import { ReactiveFormsModule } from '@angular/forms';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { LoaderModule } from '../../../modules/core/loader/loader.module';
import { TimezoneModule } from '../../../modules/core/timezone/timezone.module';
import { AslRelatedPersonsFormComponent } from './asl-register-form/asl-related-persons-form/asl-related-persons-form.component';
import { AslRelatedPersonSummaryComponent } from './asl-summary-form/asl-related-person-summary/asl-related-person-summary.component';
import { AslRelatedPersonEditFormComponent } from './asl-summary-form/asl-related-person-edit-form/asl-related-person-edit-form.component';


@NgModule({
  declarations: [
    AslRegisterFormComponent,
    AslSummaryFormComponent,
    AslRelatedPersonsFormComponent,
    AslRelatedPersonSummaryComponent,
    AslRelatedPersonEditFormComponent,
  ],
  imports: [
    CommonModule,
    NgxsModule.forFeature([
      ASLState
    ]),
    ReactiveFormsModule,
    BsDatepickerModule,
    LoaderModule,
    TimezoneModule,
    TooltipModule.forRoot(),
  ],
  exports: [
    AslRegisterFormComponent,
    AslSummaryFormComponent,
  ]
})
export class AslModule { }
