export class AirStateModel {
    saving: boolean;
    error: any;
    showFormSuccess: boolean;
    individualDetailsResponseType: IndividualDetailsResponseType;
    slideMode: string
}

export enum IndividualRequestType {
    byMedicare,
    withoutMedicare,
    withoutMedicareIRN
}

export class IdentifyIndividualRequestModel {
    individual: IndividualType;
    informationProvider: ProviderIdentifierType;
}

export class IndividualType {
    personalDetails: PersonalDetailsType
    medicareCard: MedicareCardType;
}

export class ProviderIdentifierType {
    providerNumber: string;
}

export class PersonalDetailsType {
    dateOfBirth: string;
    firstName: string;
    lastName: string;
}
export class MedicareCardType {
    medicareCardNumber: string;
    medicareIRN: string;
}

//response model:
export class IndividualDetailsResponseType {
    individualDetails: IndividualDetailsType;
    statusCode: string;
    codeType: string;
    message: string;
    errors: string;
}

export class IndividualDetailsType {
    individualIdentifier: string;
    individual: ResponseIndividualType;
    catchupDate: string;
    endDateCode: string;
    indigenousStatus: boolean;
    additionalVaccineIndicator: boolean;
    medContraindicationIndicator: boolean;
    naturalImmunityIndicator: boolean;
    vaccineTrialIndicator: boolean;
    actionRequiredIndicator: boolean;
}

class ResponseIndividualType {
    personalDetails: PersonalDetailsType;
    medicareCard: MedicareCardType;
    address: AddressType;
}

class AddressType {
    addressLineOne: string;
    addressLineTwo: string;
    locality: string;
    postCode: string;
}