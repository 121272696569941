
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { TabsModule } from 'ngx-bootstrap';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { NgxsModule } from '@ngxs/store';

import { RouterModule } from '@angular/router';
import { TimezoneModule } from '~/modules/core/timezone/timezone.module';
import { AlertService } from '~/modules/core/alert/alert.service';
import { FeaturesState } from '~/modules/core/features/features.state';
import { ScryptSearchComponent } from './scrypt-search.component';
import { AdvancedSearchService } from '../services/advanced-search.service';
import { GroupPageState } from '~/system/group-page/state/group-page.state';
import { ScryptSearchState } from '../state/scrypt-search.state';
import { RadioHighlightModule } from '~/shared/components/radio-highlight/radio-highlight.module';
import { LoaderModule } from '~/modules/core/loader/loader.module';

@NgModule({
  declarations: [ScryptSearchComponent],
  exports: [ScryptSearchComponent],
  providers: [
    AdvancedSearchService,
    AlertService
  ],
  imports: [
    FormsModule,
    TabsModule,
    RouterModule,
    CommonModule,
    ReactiveFormsModule,
    TimezoneModule,
    BsDatepickerModule.forRoot(),
    RadioHighlightModule,
    LoaderModule,
    NgxsModule.forFeature([FeaturesState, GroupPageState, ScryptSearchState]),
  ]
})
export class ScryptSearchModule {
  constructor() { }
}
