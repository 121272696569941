import { NgModule } from '@angular/core';
import { GroupByPipe } from './groupBy.pipe';
import { ConnectionTypePipe } from './connectionType.pipe';
import { NoteTypePipeModule } from './noteTypeIcon.pipe';
import { SortPipe } from './sort.pipe';
import { NoteTypeLinkPipe } from './noteTypeLink.pipe';
import { CategoryGroupByPipe } from './categoryGroupBy.pipe';
import { SplitPipe } from './split.pipe';
import { HumanizePipe } from './humanize.pipe';
import { FormatEmailPipe } from './formatEmail.pipe';
import { StripHtmlPipe } from './stripHtml.pipe';
import { PhoneNumberPipe } from './phoneNumber.pipe';
import { StripWhiteSpacePipe } from './stripWhiteSpace.pipe';

@NgModule({
  imports: [NoteTypePipeModule],
  exports: [
    NoteTypePipeModule,
    ConnectionTypePipe,
    GroupByPipe,
    SortPipe,
    NoteTypeLinkPipe,
    CategoryGroupByPipe,
    SplitPipe,
    HumanizePipe,
    FormatEmailPipe,
    StripHtmlPipe,
    PhoneNumberPipe,
    StripWhiteSpacePipe
  ],
  declarations: [
    ConnectionTypePipe,
    GroupByPipe,
    SortPipe,
    NoteTypeLinkPipe,
    CategoryGroupByPipe,
    SplitPipe,
    HumanizePipe,
    FormatEmailPipe,
    StripHtmlPipe,
    PhoneNumberPipe,
    StripWhiteSpacePipe
  ],
})
export class PipesModule {}
