import { MedicationInformationFormModel } from '../../../../../shared/models/six-cpa/medication-information-form.model';

export enum MedicationInformationFormActions {
  SET_VALID = '[MedicationInformationForm] set valid',
  UPDATE = '[MedicationInformationForm] Update Form',
  SETUP_TOTAL_NUMBER_OF_MEDICINES = '[MedicationInformationForm] Setup Total Number Of Medicines'

}

export class SetMedicationInformationFormValidAction {
  static readonly type = MedicationInformationFormActions.SET_VALID;
  constructor(public valid = false) { }
}

export class SetTotalItemsAndCompletedItems {
  static readonly type = '[MedicationInformationForm] Update Progress';
  constructor(public totalItems, public completedItems) { }
}

export class UpdateMedicationInformationForm {
  static readonly type = MedicationInformationFormActions.UPDATE;
  constructor(public form: MedicationInformationFormModel) { }
}

export class SetupNumberOfMedicines {
  static readonly type = MedicationInformationFormActions.SETUP_TOTAL_NUMBER_OF_MEDICINES;
  constructor(public scripts: any[]) { }
}
