import { NgModule } from '@angular/core';
import { GroupByPipe } from './groupBy.pipe';
import { ConnectionTypePipe } from './connectionType.pipe';
import { NoteTypePipeModule } from './noteTypeIcon.pipe';
import { SortPipe } from './sort.pipe';
import { NoteTypeLinkPipe } from './noteTypeLink.pipe';
import { CategoryGroupByPipe } from './categoryGroupBy.pipe';
import { HumanizePipe } from './humanize.pipe';
import { FormatEmailPipe } from './formatEmail.pipe';
import { StripHtmlPipe } from './stripHtml.pipe';

@NgModule({
    imports: [NoteTypePipeModule],
    exports: [
        NoteTypePipeModule,
        ConnectionTypePipe,
        GroupByPipe,
        SortPipe,
        NoteTypeLinkPipe,
        CategoryGroupByPipe,
        HumanizePipe,
        FormatEmailPipe,
        StripHtmlPipe
    ],
    declarations: [
        ConnectionTypePipe,
        GroupByPipe,
        SortPipe,
        NoteTypeLinkPipe,
        CategoryGroupByPipe,
        HumanizePipe,
        FormatEmailPipe,
        StripHtmlPipe
    ]
})
export class PipesModule { }
