import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import { StorageService } from '../core/storage/storage.service';
import { AlertService } from '../../modules/core/alert/alert.service';
import { StatisticRequestModel } from '../models/statistics.model';
import { BaseApiNoAlerts } from '../core/base-api/base-api-no-alerts';

const API = `${environment.baseApiUrl}/api/statistics`;

@Injectable()
export class StatisticsService extends BaseApiNoAlerts {
  constructor(public http: HttpClient, public router: Router, public storageService: StorageService, public alertService: AlertService) {
    super(http, router, storageService, alertService);
  }
  createStatistics(createRequest: StatisticRequestModel) {
    return this.post(`${API}/create`, createRequest );
  }

  updateStatistic(updateRequest: StatisticRequestModel) {
    return this.post(`${API}/update`, updateRequest );
  }
}
