import { Component, OnInit, Input } from '@angular/core';
import { BreadCrumbService } from './breadcrumbs.service';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss'],
})
export class BreadcrumbsComponent implements OnInit {
  constructor(private breadCrumbservice: BreadCrumbService) {}

  @Input() links = [];

  displayBreadCrumbs = new BehaviorSubject<boolean>(true);

  ngOnInit() {
    //this.displayBreadCrumbs = this.breadCrumbservice.displayBreadCrumbs;
  }
}
