<ng-container *ngIf="(commsQueueState$ | async) as commsQueueState">
  <div class="p-4 position-relative">
    <ng-container *ngIf="commsQueueState.error as error">
      <div class="form-help-text" g-xs="mb2">{{error.message}}</div>
    </ng-container>
    <h1 class="blue-header">Message Queue</h1>
    <form class="comms-queue-form" [formGroup]="formGroup">
      <div class="comms-queue-filters flex justify-content-between mt-2">
        <div class="search">
          <label for="startDate"><strong>Start Date</strong></label>
          <input id="startDate" type="text" placeholder="DD/MM/YYYY" class="form-control border__grey-light snooze-date"
            [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY' }" formControlName="startDate" name="startDate"
            [minDate]="minDate" bsDatepicker>
          <i class="far fa-calendar"></i>
        </div>
        <div class="row queue-results">
          <div class="col">
            <label for="showResults"><strong>Show</strong></label>
            <select id="showResults" formControlName="resultsPerPage" name="resultsPerPage"
              class="form-control form-control-lg">
              <option *ngFor="let results of resultsPerPageList" [ngValue]="results">
                {{results}} results
              </option>
            </select>
          </div>
          <div class="col results-sort">
            <label for="sortBy"><strong>Sort</strong></label>
            <select id="sortBy" formControlName="sortBy" name="sortBy" class="form-control form-control-lg width__300">
              <option [ngValue]="sortByTypes.Scheduled">Scheduled (Soonest first)</option>
              <option [ngValue]="sortByTypes.LastFirst">Scheduled (Last First)</option>
            </select>
          </div>
        </div>
      </div>
    </form>
    <div class="mt-2 mb-1 ml-1" *ngIf="(commQueueItems$| async).length > 0">
      Showing <strong>{{(commQueueItems$| async).length}}</strong> messages for <strong>7</strong> days from
      <strong>{{startDate}}</strong>
    </div>
    <app-comms-queue-table class="mt-4" [dataSource]="commQueueItems$" [pageSize]="pageSize$"></app-comms-queue-table>
    <div class="loading__container" *ngIf="commsQueueState.loading;">
      <app-loader loadingMessage="Evaluating your clients and preferences..."></app-loader>
    </div>
  </div>

  <div class="comms-queue-modals">
    <ng-container *ngIf="commsQueueState.showMessageModal as showMessageModal">
      <div class="backdrop"></div>
      <div class="modal">
        <div class="modal-body border__grey position-relative">
          <i (click)="closeMessageModal()" class="fas fa-times float-right pointer"></i>
          <div *ngIf="showMessageModal.subject">
            <label class="font__bold">Subject</label>
            <div>{{showMessageModal.subject}}</div>
          </div>
          <div>
            <label class="font__bold">Message</label>
            <div>{{showMessageModal.message}}</div>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="commsQueueState.showTemplateModal as showTemplateModal">
      <div class="backdrop"></div>
      <div class="modal">
        <div class="modal-body border__grey position-relative">
          <i (click)="closeTemplateModal()" class="fas fa-times float-right pointer"></i>
          <div>
            <label class="font__bold">Template Name</label>
            <div>{{showTemplateModal.name}}</div>
          </div>
          <div *ngIf="showTemplateModal.subject">
            <label class="font__bold">Subject</label>
            <div>{{showTemplateModal.subject}}</div>
          </div>
          <div>
            <label class="font__bold">Message</label>
            <div>{{showTemplateModal.message}}</div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</ng-container>
