import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { NgxsModule } from "@ngxs/store";
import { LoaderModule } from "../../../modules/core/loader/loader.module";
import { TimezoneModule } from "../../../modules/core/timezone/timezone.module";
import { TenantCategoryState } from "../../../modules/profile/tenant-categories/state/tenant-category.state";
import { DispenseRunFormComponent } from "../dispense-run-form/dispense-run-form.component";
import { DispenseRunResultsComponent } from "../dispense-run-results/dispense-run-results.component";
import { DispenseRunResolver } from "../resolvers/dispense-run.resolver";
import { DispenseRunService } from "../services/dispense-run.service";
import { DispenseRunState } from "../state/dispense-run.state";
import { DispenseRunPageComponent } from "./dispense-run-page.component";
import { NzTableModule } from "ng-zorro-antd/table";
import { NzCheckboxModule } from "ng-zorro-antd/checkbox";
import { NzToolTipModule } from "ng-zorro-antd/tooltip";

@NgModule({
  declarations: [
    DispenseRunPageComponent,
    DispenseRunFormComponent,
    DispenseRunResultsComponent
  ],
  imports: [
    FormsModule,
    CommonModule,
    LoaderModule,
    ReactiveFormsModule,
    NgxsModule.forFeature([DispenseRunState, TenantCategoryState]),
    RouterModule,
    TimezoneModule,
    NzTableModule,
    NzCheckboxModule,
    NzToolTipModule
  ],
  providers: [
    DispenseRunResolver,
    DispenseRunService
  ]
})
export class DispenseRunPageModule { }
