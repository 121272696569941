import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { StorageService } from '../core/storage/storage.service';
import { AlertService } from '../../modules/core/alert/alert.service';
import { CrudService } from './crud.service';
// import { environment } from '../../../environments/environment';

@Injectable()
export class VerifyService extends CrudService {
  API = '';

  constructor(
    @Inject('environment') env: any,
    public http: HttpClient,
    public router: Router,
    public storageService: StorageService,
    public alertService: AlertService
  ) {
    super(http, router, storageService, alertService, 'tenant', env);

    this.API = env.baseApiUrl;
  }

  verifyCustomer(customerProfileId: number) {
    return this.post(
      `${this.API}/api/verification/customer/${customerProfileId}`
    );
  }
}
