import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import { ReactiveFormsModule } from '@angular/forms';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { RadioHighlightModule } from '../../../../../shared/components/radio-highlight/radio-highlight.module';
import { SSPatientDetailsFormDetailedComponent } from './detailed/ss-patient-details-form-detailed.component';
import { SSPatientDetailsFormComponent, SSPatientDetailsFormHostDirective } from './patient-details-form/ss-patient-details-form.component';
import { SSPatientDetailsFormBasicComponent } from './basic/ss-patient-details-form-basic.component';

@NgModule({
  declarations: [
    SSPatientDetailsFormComponent,
    SSPatientDetailsFormBasicComponent,
    SSPatientDetailsFormDetailedComponent,
    SSPatientDetailsFormHostDirective
  ],
  imports: [
    CommonModule,
    NgxsFormPluginModule,
    ReactiveFormsModule,
    BsDatepickerModule.forRoot(),
    RadioHighlightModule
  ],
  exports: [
    SSPatientDetailsFormComponent
  ],
  entryComponents: [
    SSPatientDetailsFormBasicComponent,
    SSPatientDetailsFormDetailedComponent
  ]
})
export class SSPatientDetailsFormModule { }
