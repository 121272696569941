import { HttpErrorResponse } from '@angular/common/http';
import { SmsMessageHistory } from '../../../../../shared/models/messageHistory.model';

enum SMSHistory {
  FETCH_SMS_HISTORY = "[SMS History] Fetch",
  FETCH_SMS_HISTORY_SUCCESS = "[SMS History] Success",
  FETCH_SMS_HISTORY_FAILED = "[SMS Histrory] Failed"
}

export class FetchSMSHistory {
  static readonly type = SMSHistory.FETCH_SMS_HISTORY;
  constructor(public clientId: string) {}
}

export class FetchSMSHistorySuccess {
  static readonly type = SMSHistory.FETCH_SMS_HISTORY_SUCCESS;
  constructor(public messages: SmsMessageHistory[]) {}
}

export class FetchSMSHistoryFailed {
  static readonly type = SMSHistory.FETCH_SMS_HISTORY_FAILED;
  constructor(public error: HttpErrorResponse) {}
}
