import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiSwitchModule } from 'ngx-ui-switch';
import { CommsRemindersListComponent } from './comms-reminders-list.component';

@NgModule({
  declarations: [CommsRemindersListComponent],
  imports: [
    CommonModule,
    UiSwitchModule
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  exports: [CommsRemindersListComponent]
})
export class CommsRemindersListModule { }
