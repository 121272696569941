import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';
import { TenantDatePipe } from '../../../../core/timezone/pipes/tenant-date.pipe';
import { DAAPatientDetailsFormState } from './state/daa-patient-details-form.state';
import { DAAPatientDetailsFormBasicState } from './state/daa-patient-details-form-basic.state';
import { DAAPatientDetailsFormDetailedState } from './state/daa-patient-details-form-detailed.state';

@NgModule({
  imports: [
    NgxsModule.forFeature([
      DAAPatientDetailsFormState,
      DAAPatientDetailsFormDetailedState,
      DAAPatientDetailsFormBasicState
    ]),
  ],
  providers: [
    TenantDatePipe
  ]
})
export class DAAPatientDetailsStateModule { }
