import { NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { DoctorReportTemplatesSelectorComponent } from './doctor-report-templates-selector.component';
import { NgxsModule } from '@ngxs/store';
import { DoctorReportTemplatesSelectorState } from './state/doctor-report-templates-selector.state';
import { MarketingService } from '../../../shared/services/marketing.service';

@NgModule({
  imports: [
    ReactiveFormsModule,
    FormsModule,
    CommonModule,
    NgxsModule.forFeature([DoctorReportTemplatesSelectorState]),
  ],
  providers: [MarketingService],
  exports: [DoctorReportTemplatesSelectorComponent],
  declarations: [DoctorReportTemplatesSelectorComponent],
})
export class DoctorReportTemplatesSelectorModule {}
