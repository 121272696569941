export enum OptOutType {
  autoComms,
  campaigns,
  all
}

export interface CommunicationOptOut {
  Id?: number;
  TenantId: number;
  MobileNumber: string;
  EmailAddress: string;
  OptOutType: OptOutType;
  Created: Date | string;
  OptOutReason: string;
}