import { Component, OnInit, Input, ViewChild, AfterViewInit } from '@angular/core';
import { MessageSource, Attachment, SmsMessageHistory } from '~/shared/models/messageHistory.model';
import { Select, Store } from '@ngxs/store';
import { SmsAttachmentModalState } from '../sms-attachment-modal/state/sms-attachment-modal.state';
import { Observable } from 'rxjs';
import { OpenAttachmentsModal } from '../sms-attachment-modal/state/sms-attachment-modal.actions';
import { detectEScript, extractEScript } from '../../../../../shared/helpers/eScript.helper';
import { ToggleEScriptModal, SetEScriptToken } from '~/shared/components/escripts/state/escripts.actions';
import { NgxAutoScroll } from 'ngx-auto-scroll';

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss']
})
export class HistoryComponent implements OnInit, AfterViewInit {
  @Select(SmsAttachmentModalState.showModal) showModal$: Observable<boolean>;
  @ViewChild(NgxAutoScroll) ngxAutoScroll: NgxAutoScroll;
  @Input() messageHistory: SmsMessageHistory[] = [];
  messageSource = MessageSource;
  
  constructor(private store: Store) { }

  ngAfterViewInit(): void {
    this.forceScrollDown();
  }

  ngOnInit() {
  }

  forceScrollDown(): void {
    this.ngxAutoScroll.forceScrollDown();
  }

  getMessageSource(source: MessageSource) {
    return `Created by: ${MessageSource[source]}`;
  }

  showAttachmentsModal(attachments: Attachment[]) {
    this.store.dispatch(new OpenAttachmentsModal(attachments))
  }

  checkForEScript(message: string) {
    return detectEScript(message);
  }

  checkIsSystem(source) {
    return source === MessageSource.BulkCommunications
      || source === MessageSource.BulkCommunications
      || source === MessageSource.RecurringMessage
      || source === MessageSource.ScheduledMessage
      || source === MessageSource.System;
  }

  openEScriptModal(message: string) {
    const scriptToken = extractEScript(message);
    this.store.dispatch([new SetEScriptToken(scriptToken), new ToggleEScriptModal(true)]);
  }

  urlify(text) {
    if (!text) {
      return text;
    }

    var urlRegex = /(https?:\/\/[^\s]+)/g;
    return text.replace(urlRegex, function (url) {
      return `<a target="_blank" href="${url}">${url}</a>`;
    });
  }
}
