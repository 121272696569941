import { NgModule } from '@angular/core';
import { PatientFormListItemComponent } from './patient-form-list-item.component';
import { CommonModule } from '@angular/common';
import { SixCpaFormListItemChecklistModule } from '../six-cpa-form-list-item-checklist/six-cpa-form-list-item-checklist.module';
import { PatientDetailsFormModule } from '../patient-details-form/patient-details-form.module';

@NgModule({
  declarations: [
    PatientFormListItemComponent
  ],
  imports: [
    CommonModule,
    SixCpaFormListItemChecklistModule,
    PatientDetailsFormModule
  ],
  exports: [
    PatientFormListItemComponent
  ]
})
export class PatientFormListItemModule { }
