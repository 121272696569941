import { OrderRequestItem } from '../../modules/profile/order-requests/models/order-request-item.model';
import {
  DispenseItemModel,
  EScriptType,
} from '../models/dispense/dispenseItem.model';
import { isNullOrWhiteSpace } from './string.helpers';
import { Medication } from '../../shared/models/script/chartviewitem.model';
import { mapIdentifierType, mapToken } from './eScript.helper';
import { DoctorViewModel } from '../models/script/chartviewitem.model';

export function mapOrderRequestItemstoDispenseItemsModel(
  data: OrderRequestItem[],
  customerId:string
): DispenseItemModel[] {
  let items: DispenseItemModel[] = data
    .filter((i) => i.selected)
    .filter(
      (i) =>
        mapIdentifierType(i.orderRequestItemType) !== EScriptType.Unknown &&
        !isNullOrWhiteSpace(mapToken(i.orderRequestItemType, i))
    )
    .map((i) => {
      return mapOrderRequestItemtoDispenseItemModel(i, customerId);
    });
  return items;
}

export function mapHQDrugsToDispenseItemModel(
  hqDrugs: Medication[],
  notes?: string
): DispenseItemModel[] {
  let items: DispenseItemModel[] = hqDrugs
    .filter((x) => x.scriptDispenseId != null || x.scriptId != null)
    .map((drug) => {
      return mapHQDrugToDispenseItemModel(drug, notes);
    });

  return items;
}

//Helper Functions
export function mapHQDrugToDispenseItemModel(
  drug: Medication,
  notes?: string
): DispenseItemModel {
  let model: DispenseItemModel = {
    scriptId: drug.scriptId,
    scriptToken: drug.scriptDispenseId
      ? drug.scriptDispenseId
      : drug.dispenseRepeatNo,
    scriptNo: drug.scriptNo,
    itemDescription: drug.name,
    customerId: drug.clientId,
    stockName: drug.name,
    genericName: drug.description,
    genericCategoryId: drug.genericCategoryId,
    directions: drug.directions,
    directionsDesc: drug.directionsDesc,
    quantity: drug.quantity,
    doctorName: getDoctor(drug.doctor),
    productDispensedId: drug.productDispensedId,
    isPaperless: drug.isPaperless, //if true eScript
    isCombination: false, //on the form
    notes: `${notes}-${drug.scriptDispenseId}`,
    defer: false,
    generic: false,
    cMI: false,
    itemIndex: 1,
  };

  return model;
}

function mapOrderRequestItemtoDispenseItemModel(
  item: OrderRequestItem,
  customerId:string
): DispenseItemModel {
  let { customerMedication } = item;
  let model: DispenseItemModel = {
    scriptToken: mapToken(item.orderRequestItemType, item),
    scriptType: mapIdentifierType(item.orderRequestItemType),
    stockName: item.stockItemName,
    itemDescription: item.name,
    isCombination: false, //on the form
    notes: '',
    defer: false,
    generic: false,
    cMI: false,
    itemIndex: 1,
    scriptNo: customerMedication?.scriptNo,
    scriptId: customerMedication?.scriptId,
    customerId: customerId,
    genericCategoryId: customerMedication?.genericCategoryId,
    directions: customerMedication?.directions,
    directionsDesc: customerMedication?.directionsDesc,
    quantity: customerMedication?.quantity,
    doctorName: getDoctor(customerMedication?.doctor),
    productDispensedId: customerMedication?.productDispensedId,
    isPaperless: customerMedication?.isPaperless ?? true,
  };

  return model;
}

function getDoctor(doctor: DoctorViewModel) {
    return doctor ? `${doctor.firstName} ${doctor.surname}` : `Not Provided`;
}
