import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { ClearAdvancedSearchResults } from './state/advanced-search.actions';
import { ResetBulkMessageRecipients } from '../bulk-messaging-page/state/bulk-messaging.actions';
import { ClearScryptSearchResults } from './state/scrypt-search.actions';

@Injectable()
export class AdvancedSearchResolver implements Resolve<Observable<any>> {
  constructor(private store: Store) { }
  resolve(route: ActivatedRouteSnapshot): Observable<any> {
    return this.store.dispatch([
      new ClearAdvancedSearchResults(),
      new ResetBulkMessageRecipients(),
      new ClearScryptSearchResults()   
    ]);
  }
}