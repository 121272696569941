import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SixCpaFormListItemChecklistModule } from '../../six-cpa-form-list-item-checklist/six-cpa-form-list-item-checklist.module';
import { SickLeavePharmacistDeclarationListItemComponent } from './sick-leave-pharmacist-declaration-list-item.component';
import { SickLeavePharmacistDeclarationModule } from '../sick-leave-pharmacist-declaration/sick-leave-pharmacist-declaration.module';

@NgModule({
  declarations: [SickLeavePharmacistDeclarationListItemComponent],
  imports: [
    CommonModule,
    SickLeavePharmacistDeclarationModule,
    SixCpaFormListItemChecklistModule
  ],
  exports: [SickLeavePharmacistDeclarationListItemComponent]
})
export class SickLeavePharmacistDeclarationListItemModule { }
