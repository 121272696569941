import { Component } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { MedsCheckFormListState } from '../state/meds-check-form-list.state';
import { Observable } from 'rxjs';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { CloseMedsCheckSendDocumentFormAction } from '../state/meds-check-form-list.actions';
import { SendMedsicationList } from '../../confirm-meds-check/state/confirm-meds-check.actions';
import { ProfileState } from '../../../../core/profile/state/profile.state';

@Component({
  selector: 'app-meds-check-send-document-modal',
  templateUrl: './meds-check-send-document-modal.component.html'
})
export class MedsCheckSendDocumentModalComponent {
  @Select(MedsCheckFormListState.showSendDocumentForm) showSendDocumentForm$: Observable<boolean>;
  constructor(private store: Store) {
  }

  @Dispatch()
  close = () => new CloseMedsCheckSendDocumentFormAction()

  submit = () => {
      const clientId = this.store.selectSnapshot(ProfileState.clientId)
      this.store.dispatch([new SendMedsicationList(clientId, null), new CloseMedsCheckSendDocumentFormAction()]);
    }
  
}
