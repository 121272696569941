import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MedsCheckComponent } from './meds-check.component';
import { PatientFormListItemModule } from '../patient-form-list-item/patient-form-list-item.module';
import { NgxsModule } from '@ngxs/store';
import { ConsentEligibilityFormListItemModule } from '../consent-eligibility-form-list-item/consent-eligibility-form-list-item.module';
import { OutcomesAndActionsFormListItemModule } from '../outcomes-and-actions-form-list-item/outcomes-and-actions-form-list-item.module';
import { OutcomesAndActionsFormState } from '../outcomes-and-actions-form/state/outcomes-and-actions-form.state';
import { FormControlFunctions } from '../form-control.functions';
import { PatientSignatureState } from '../patient-signature/state/patient-signature.state';
import { MedicationInformationFormListItemModule } from '../medication-information-form-list-item/medication-information-form-list-item.module';
import { MedicationInformationState } from '../medication-information-form/state/medication-information.state';
import { PharmacyInfoFormListItemModule } from '../pharmacy-info-form-list-item/pharmacy-info-form-list-item.module';
import { PharmacyInformationFormState } from '../pharmacy-information-form/state/pharmacy-information-form.state';
import { MedsCheckMedicationProfileState } from '../meds-check-medication-profile/state/meds-check-medication-profile.state';
import { MedsCheckMedicationProfileModule } from '../meds-check-medication-profile/meds-check-medication-profile/meds-check-medication-profile.module';
import { ReactiveFormsModule } from '@angular/forms';
import { RadioHighlightModule } from '../../../../shared/components/radio-highlight/radio-highlight.module';
import { PatientSignatureModule } from '../patient-signature/patient-signature.module';
import { MedsCheckFormListState } from './state/meds-check-form-list.state';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import { MedsCheckService } from './services/meds-check.service';
import { PatientDetailsStateModule } from '../patient-details-form/patient-details-state.module';
import { ConsentEligibilityStateModule } from '../consent-eligibility-form/consent-eligibility-state.module';
import { ConfirmResetModule } from '../../../../shared/components/confirm-reset/confirm-reset.module';
import { LoaderModule } from '../../../core/loader/loader.module';
import { MedsCheckSaveState } from './state/meds-check-save.state';

import { UploadDocumentFormState } from '../upload-document-form/state/upload-document-form.state';
import { ActionPlanFormListItemModule } from '../action-plan-form-list-item/action-plan-form-list-item.module';
import { ActionPlanFormState } from '../action-plan-form/state/action-plan-form.state';
@NgModule({
  declarations: [
    MedsCheckComponent
  ],
  imports: [
    CommonModule,
    NgxsFormPluginModule,
    PatientFormListItemModule,
    ConsentEligibilityFormListItemModule,
    MedicationInformationFormListItemModule,
    MedsCheckMedicationProfileModule,
    OutcomesAndActionsFormListItemModule,
    PharmacyInfoFormListItemModule,
    ActionPlanFormListItemModule,
    ReactiveFormsModule,
    RadioHighlightModule,
    PatientSignatureModule,
    NgxsModule.forFeature([
      MedicationInformationState,
      OutcomesAndActionsFormState,
      PharmacyInformationFormState,
      MedsCheckMedicationProfileState,
      PatientSignatureState,
      MedsCheckFormListState,
      MedsCheckSaveState,
      UploadDocumentFormState,
      ActionPlanFormState
    ]),
    PatientDetailsStateModule,
    ConsentEligibilityStateModule,
    ConfirmResetModule,
    LoaderModule
  ],
  exports: [
    MedsCheckComponent
  ],
  providers: [
    FormControlFunctions,
    MedsCheckService
  ]
})
export class MedsCheckModule { }
