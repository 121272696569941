import { TenantCategory } from './tenant-category.model';

export class Category extends TenantCategory {
    constructor(
        public id: number,
        public name: string,
        public friendlyName: string,
        public dispenseBuffer: number,
        public position: number,
        public targetTotalDoh: number,
        public enabled: boolean,
        public tenantId?: number
    ) {
        super();
    }
}
