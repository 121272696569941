import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NgxsModule } from '@ngxs/store';
import { ConfirmMedsCheckState } from '../../confirm-meds-check/state/confirm-meds-check.state';
import { SavePdfModule } from '../../../../../shared/components/save-pdf/save-pdf.module';
import { MedsCheckSavePdfModalComponent } from './meds-check-save-pdf-modal.component';

@NgModule({
  declarations: [MedsCheckSavePdfModalComponent],
  imports: [
    NgxsModule.forFeature([
      ConfirmMedsCheckState
    ]),
    CommonModule,
    SavePdfModule,
  ],
  exports: [MedsCheckSavePdfModalComponent]
})
export class MedsCheckSavePdfModalModule { }
