import { IAdditionalItem, PassiveScriptType } from './out-of-store.model';
import { IStock } from '../stock';

export class AdditionalItemStock implements IStock, IAdditionalItem {
  frequency: number;
  repeats: number;
  quantity: number;
  doh: number;
  categoryId: number;
  lastDispensedDate: Date;
  stockId: string;
  name: string;
  id: string;
  genericCategoryId: string;
  active: boolean;
  prescriptionDate: Date | string | null;
  originalScriptNo: string;
  passiveScriptType: PassiveScriptType;
}
