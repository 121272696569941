import { Selector } from '@ngxs/store';
import { PatientDetailsFormDetailedState, IPatientDetailsFormDetailedStateModel } from '../../patient-details-form/state/patient-details-form-detailed.state';
import { MedicationInformationState, IMedicationInformationStateModel } from '../../medication-information-form/state/medication-information.state';
import { OutcomesAndActionsFormState, IOutcomesAndActionsFormStateModel } from '../../outcomes-and-actions-form/state/outcomes-and-actions-form.state';
import { PharmacyInformationFormState, IPharmacyInformationFormModel } from '../../pharmacy-information-form/state/pharmacy-information-form.state';
import { MedsCheckMedicationProfileState } from '../../meds-check-medication-profile/state/meds-check-medication-profile.state';
import { MedsCheckFormModel } from '../../../../../shared/models/six-cpa/meds-check-form.model';
import { Medicine } from '../../../../../shared/models/six-cpa/medicine.model';
import { MedsCheckFormListState, MedsCheckFormListStateModel } from './meds-check-form-list.state';
import { ConsentEligibilityFormDetailedState } from '../../consent-eligibility-form/state/consent-eligibility-form-detailed.state';
import { IConsentEligibilityFormStateModel } from '../../consent-eligibility-form/state/consent-eligibility-form-state-model.interface';
import { ConsentEligibilityFormBasicState, IConsentEligibilityFormBasicStateModel } from '../../consent-eligibility-form/state/consent-eligibility-form-basic.state';
import * as moment_ from 'moment';
const moment = moment_;
import { PatientDetailsFormBasicState, IPatientDetailsFormBasicStateModel } from '../../patient-details-form/state/patient-details-form-basic.state';

import { UploadDocumentFormState } from '../../upload-document-form/state/upload-document-form.state';
import { SixCpaActionPlanForm, SixCpaActionPlanItemModel } from '../../../../../shared/models/six-cpa/action-plan.model';
import { ActionPlanFormState, IActionPlanForm } from '../../action-plan-form/state/action-plan-form.state';
export class MedsCheckFormsService {
  @Selector([
    MedsCheckFormListState,
    PatientDetailsFormDetailedState.form,
    ConsentEligibilityFormDetailedState.form,
    MedicationInformationState.medicationInformationForm,
    OutcomesAndActionsFormState.outcomesAndActionsForm,
    PharmacyInformationFormState.pharmacyInformationForm,
    MedsCheckMedicationProfileState.selectedMedicines,
    UploadDocumentFormState.documents,
    ActionPlanFormState.actionPlanForm
  ])
  static getMedsCheckRegistrationRequest(
    medsCheckstate: MedsCheckFormListStateModel,
    patientDetails: IPatientDetailsFormDetailedStateModel,
    consentEligibility: IConsentEligibilityFormStateModel,
    medicationInfo: IMedicationInformationStateModel,
    outcomesActions: IOutcomesAndActionsFormStateModel,
    pharmacyInfo: IPharmacyInformationFormModel,
    selectedMedicines: Medicine[],
    documents: any[],
    actionPlan: IActionPlanForm): MedsCheckFormModel {
    const patientDetailsForm = this.getPatientDetailsData(medsCheckstate.patientDetailsFormId, patientDetails);
    const consentAndEligibilityForm = this.getConsentAndEligibilityData(medsCheckstate.consentAndEligibilityFormId, consentEligibility);
    const medicationInformationForm = this.getMedicationInformationData(medsCheckstate.medicationInformationFormId, medicationInfo);
    const outcomesAndActionsForm = this.getOutcomesAndActionsData(medsCheckstate.outcomesAndActionsFormId, outcomesActions);
    const pharmacyInformationForm = this.getPharmacyInformationData(medsCheckstate.pharmacyInformationFormId, pharmacyInfo);
    const sixCpaMedications = this.getSixCpaMedications(selectedMedicines);
    const actionPlansData = this.getActionPlanData(actionPlan);
    return {
      id: medsCheckstate.medsCheckId,
      sixCpaFormId: medsCheckstate.sixCpaFormId,
      patientDetailsFormId: medsCheckstate.patientDetailsFormId,
      consentAndEligibilityFormId: medsCheckstate.consentAndEligibilityFormId,
      medicationInformationFormId: medsCheckstate.medicationInformationFormId,
      outcomesAndActionsFormId: medsCheckstate.outcomesAndActionsFormId,
      pharmacyInformationFormId: medsCheckstate.pharmacyInformationFormId,
      patientDetailsForm,
      consentAndEligibilityForm,
      medicationInformationForm,
      outcomesAndActionsForm,
      pharmacyInformationForm,
      sixCpaMedications,
      medsCheckFormType: medsCheckstate.medsCheckType,
      consentDocuments: documents,
      sixCpaActionPlanForm: actionPlansData
    };
  }

  @Selector([MedsCheckFormListState,
    PatientDetailsFormBasicState.form,
    ConsentEligibilityFormBasicState.form,
    PharmacyInformationFormState.pharmacyInformationForm,
    MedsCheckMedicationProfileState.selectedMedicines,
    UploadDocumentFormState.documents,
    ActionPlanFormState.actionPlanForm
  ])
  static getMedsCheckBasicRequest(
    medsCheckstate: MedsCheckFormListStateModel,
    patientDetails: IPatientDetailsFormBasicStateModel,
    consentEligibility: IConsentEligibilityFormBasicStateModel,
    pharmacyInfo: IPharmacyInformationFormModel,
    selectedMedicines: Medicine[],
    documents: any[],
    actionPlan: IActionPlanForm): MedsCheckFormModel {
    const patientDetailsForm = this.getBasicPatientDetailsData(medsCheckstate.patientDetailsFormId, patientDetails);
    const consentAndEligibilityForm = this.getBasicAndEligibilityData(medsCheckstate.consentAndEligibilityFormId, consentEligibility);
    const pharmacyInformationForm = this.getPharmacyInformationData(medsCheckstate.pharmacyInformationFormId, pharmacyInfo);
    const sixCpaMedications = this.getSixCpaMedications(selectedMedicines);
    const actionPlansData = this.getActionPlanData(actionPlan);
    return {
      id: medsCheckstate.medsCheckId,
      sixCpaFormId: medsCheckstate.sixCpaFormId,
      patientDetailsFormId: medsCheckstate.patientDetailsFormId,
      consentAndEligibilityFormId: medsCheckstate.consentAndEligibilityFormId,
      pharmacyInformationFormId: medsCheckstate.pharmacyInformationFormId,
      patientDetailsForm,
      consentAndEligibilityForm,
      pharmacyInformationForm,
      sixCpaMedications,
      medsCheckFormType: medsCheckstate.medsCheckType,
      consentDocuments: documents,
      sixCpaActionPlanForm: actionPlansData
    };
  }

  private static getActionPlanData(form: IActionPlanForm): SixCpaActionPlanForm {
    return {
      hasActions: form.hasActions,
      sixCpaActionPlanItems: form.actions ? form.actions.map(action => ({
        id: action.id,
        issue: action.issue,
        recommendation: action.recommendation,
        forConsiderationBy: action.forConsiderationBy,
        otherConsideration: action.otherConsideration
      } as SixCpaActionPlanItemModel)): null
  }
  }

  private static getPatientDetailsData(id: number, patientDetails: IPatientDetailsFormDetailedStateModel) {
    return patientDetails ? {
      id,
      firstName: patientDetails.patientFirstName,
      surname: patientDetails.patientSurname,
      dob: moment(patientDetails.patientDOB).toDate(),
      medicareNumber: patientDetails.medicareNumber,
      gender: patientDetails.gender,
      residentialPostCode: patientDetails.residentialPostCode,
      englishAtHome: patientDetails.englishAtHome,
      aboriginalOrTorresStraitIslander: patientDetails.isPatientAboriginalOrTSI,
      patientHasDiabetes: patientDetails.patientHasDiabetes,
      reviewed: patientDetails.reviewed
    } : null;
  }

  private static getBasicPatientDetailsData(id: number, patientDetails: IPatientDetailsFormBasicStateModel) {
    return patientDetails ? {
      id,
      firstName: patientDetails.patientFirstName,
      surname: patientDetails.patientSurname,
      dob: moment(patientDetails.patientDOB).toDate(),
      medicareNumber: patientDetails.medicareNumber,
      dateOfMedsCheck: moment(patientDetails.dateOfMedsCheck).toDate()
    } : null;
  }

  private static getConsentAndEligibilityData(id: number, consentEligibility: IConsentEligibilityFormStateModel) {
    return consentEligibility ? {
      id,
      patientsConsent: consentEligibility.patientsConsent,
      hasPreviousMedsCheck: consentEligibility.hasPreviousMedsCheck,
      currentLivingStatus: consentEligibility.currentLivingStatus,
      currentLivingOtherDetails: consentEligibility.currentLivingOtherDetails,
      gpOrHospitalLastSixMonthsForMedicationProblems: consentEligibility.gpOrHospitalLastSixMonthsForMedicationProblems,
      medicineManagementSupport: consentEligibility.medicineManagementSupport,
      followUpService: moment(consentEligibility.followUpService).toDate(),
      consentType: consentEligibility.consentType
    } : null;
  }

  private static getBasicAndEligibilityData(id: number, consentEligibility: IConsentEligibilityFormBasicStateModel) {
    return consentEligibility ? {
      id,
      patientsConsent: consentEligibility.patientsConsent,
      consentType: consentEligibility.consentType
    } : null;
  }

  private static getMedicationInformationData(id: number, medicationInfo: IMedicationInformationStateModel) {
    return medicationInfo ? {
      id,
      medsCheckServiceReasons: {
        recentSignificantMedicalEvent: medicationInfo.medsCheckServiceReasons.recentSignificantMedicalEvent,
        identifyPatientProblems: medicationInfo.medsCheckServiceReasons.identifyPatientProblems,
        helpPatientLearnAboutMedicines: medicationInfo.medsCheckServiceReasons.helpPatientLearnAboutMedicines,
        improveMedicationEffectiveness: medicationInfo.medsCheckServiceReasons.improveMedicationEffectiveness,
        educatePatientOnUseAndStorageOfMedicines: medicationInfo.medsCheckServiceReasons.educatePatientOnUseAndStorageOfMedicines,
        medicationsWithHighAdverseEventRisk: medicationInfo.medsCheckServiceReasons.medicationsWithHighAdverseEventRisk
      },
      prescriptionMedicines: medicationInfo.prescriptionMedicines,
      nonPrescriptionMedicines: medicationInfo.nonPrescriptionMedicines,
      medicationHealthConditions: {
        cVD: medicationInfo.medicationHealthConditions.cVD,
        diabetes: medicationInfo.medicationHealthConditions.diabetes,
        osteoporosis: medicationInfo.medicationHealthConditions.osteoporosis,
        arthritis: medicationInfo.medicationHealthConditions.arthritis,
        pain: medicationInfo.medicationHealthConditions.pain,
        mentalHealthIssue: medicationInfo.medicationHealthConditions.mentalHealthIssue,
        epilepsy: medicationInfo.medicationHealthConditions.epilepsy,
        parkinsonsDisease: medicationInfo.medicationHealthConditions.parkinsonsDisease,
        dementia: medicationInfo.medicationHealthConditions.dementia,
        respiratoryDisorders: medicationInfo.medicationHealthConditions.respiratoryDisorders,
        alimentaryTract: medicationInfo.medicationHealthConditions.alimentaryTract,
        other: medicationInfo.medicationHealthConditions.other
      },
      medicationHealthConditionsOtherDetails: medicationInfo.medicationHealthConditionsOtherDetails,
      medIndexScore: medicationInfo.medIndexScore
    } : null;
  }

  private static getOutcomesAndActionsData(id: number, outcomesActions: IOutcomesAndActionsFormStateModel) {
    return outcomesActions ? {
      id,
      medsCheckOutcome: {
        recommendNoChange: outcomesActions.medsCheckOutcome.recommendNoChange,
        recommendIncreaseDose: outcomesActions.medsCheckOutcome.recommendIncreaseDose,
        recommendDecreaseDose: outcomesActions.medsCheckOutcome.recommendDecreaseDose,
        increaseKnowledgeMedicineRegime: outcomesActions.medsCheckOutcome.increaseKnowledgeMedicineRegime,
        increaseKnowledgeMedicineAdherence: outcomesActions.medsCheckOutcome.increaseKnowledgeMedicineAdherence,
        other: outcomesActions.medsCheckOutcome.other
      },
      pharmacistActions: {
        actionPlanToPatient: outcomesActions.pharmacistActions.actionPlanToPatient,
        actionPlanToGP: outcomesActions.pharmacistActions.actionPlanToGP,
        gPVerballyConsulted: outcomesActions.pharmacistActions.gPVerballyConsulted,
        referredToGP: outcomesActions.pharmacistActions.referredToGP,
        other: outcomesActions.pharmacistActions.other
      },
      medsCheckOutcomeOtherDetails: outcomesActions.medsCheckOutcomeOtherDetails,
      pharmacistActionsOtherDetails: outcomesActions.pharmacistActionsOtherDetails

    } : null;
  }

  private static getPharmacyInformationData(id: number, pharmacyInfo: IPharmacyInformationFormModel) {
    return pharmacyInfo ? {
      id,
      pharmacist: pharmacyInfo.pharmacistName,
      ahpraNumber: pharmacyInfo.ahpraNumber
    } : null;
  }

  private static getSixCpaMedications(medicines: Medicine[]) {
    return medicines
      .map(m => ({
        id: m.id,
        productDispensedId: m.productDispensedId,
        brandName: m.brandName,
        genericName: m.genericName,
        directions: m.directions,
        quantity: m.quantity,
        reasonsForTaking: m.reasonsForTaking,
        notes: m.notes,
        prescriber: m.prescriber,
        startDate: m.startDate
      }));
  }
}


