<form [formGroup]="medicationInformationForm" ngxsForm="ssMedicationInformationForm.medicationInformationForm"
  class="p-4">

  <div class="form-group pb-1"
    [class.has-error-group]="formControls.reasonForStagedSupply.invalid && (showInvalid$ | async)">
    <label class="input-label mb-0">Reasons for Staged Supply</label>
    <div class="form-check form-check">
      <input class="form-check-input" type="radio" name="reasonForStagedSupply" id="cognitiveOrPhysicalImpairment"
        formControlName="reasonForStagedSupply" [value]="reasonsForStagedSupply.cognitiveOrPhysicalImpairment">
      <label class="form-check-label" for="cognitiveOrPhysicalImpairment">Cognitive or physical impairment</label>
    </div>
    <div class="form-check form-check">
      <input class="form-check-input" type="radio" name="reasonForStagedSupply" id="disorientedOrConfused"
        formControlName="reasonForStagedSupply" [value]="reasonsForStagedSupply.disorientedOrConfused">
      <label class="form-check-label" for="disorientedOrConfused">Disoriented or confused</label>
    </div>
    <div class="form-check form-check">
      <input class="form-check-input" type="radio" name="reasonForStagedSupply"
        id="historyOrRiskOfDeliberateSelfHarmOrHarmToOthers" formControlName="reasonForStagedSupply"
        [value]="reasonsForStagedSupply.historyOrRiskOfDeliberateSelfHarmOrHarmToOthers">
      <label class="form-check-label" for="historyOrRiskOfDeliberateSelfHarmOrHarmToOther">History or risk of deliberate
        self-harm or harm to others</label>
    </div>
    <div class="form-check form-check">
      <input class="form-check-input" type="radio" name="reasonForStagedSupply" id="historyOrRiskOfIntentionalMisuse"
        formControlName="reasonForStagedSupply" [value]="reasonsForStagedSupply.historyOrRiskOfIntentionalMisuse">
      <label class="form-check-label" for="historyOrRiskOfIntentionalMisuse">History or risk of intentional
        misuse</label>
    </div>
    <div class="form-check form-check">
      <input class="form-check-input" type="radio" name="reasonForStagedSupply" id="historyOrRiskOfMedicineDiversion"
        formControlName="reasonForStagedSupply" [value]="reasonsForStagedSupply.historyOrRiskOfMedicineDiversion">
      <label class="form-check-label" for="historyOrRiskOfMedicineDiversion">History or risk of medicine
        diversion</label>
    </div>
    <div class="form-check form-check">
      <input class="form-check-input" type="radio" name="reasonForStagedSupply" id="historyOrRiskOfPoorAdherence"
        formControlName="reasonForStagedSupply" [value]="reasonsForStagedSupply.historyOrRiskOfPoorAdherence">
      <label class="form-check-label" for="historyOrRiskOfPoorAdherence">History or risk of poor adherence</label>
    </div>
    <div class="form-check form-check">
      <input class="form-check-input" type="radio" name="reasonForStagedSupply" id="other"
        formControlName="reasonForStagedSupply" [value]="reasonsForStagedSupply.other">
      <label class="form-check-label" for="other">Other</label>
    </div>
  </div>

  <div class="form-group pb-1"
    [class.has-error-group]="formControls.patientHadAnyProblemsOverThePastMonthWithTheirMedications.invalid && (showInvalid$ | async)">
    <label class="input-label mb-0">Has the patient had any problems over the past month with their medications?</label>
    <div class="form-check form-check">
      <input class="form-check-input" type="radio" name="patientHadAnyProblemsOverThePastMonthWithTheirMedications"
        id="no" formControlName="patientHadAnyProblemsOverThePastMonthWithTheirMedications"
        [value]="patientMedicationProblemsPastMonth.no">
      <label class="form-check-label" for="no">No</label>
    </div>
    <div class="form-check form-check">
      <input class="form-check-input" type="radio" name="patientHadAnyProblemsOverThePastMonthWithTheirMedications"
        id="notYetNewMedicine" formControlName="patientHadAnyProblemsOverThePastMonthWithTheirMedications"
        [value]="patientMedicationProblemsPastMonth.notYetNewMedicine">
      <label class="form-check-label" for="notYetNewMedicine">Not yet - new medicine</label>
    </div>
    <div class="form-check form-check">
      <input class="form-check-input" type="radio" name="patientHadAnyProblemsOverThePastMonthWithTheirMedications"
        id="hadTroubleFollowingDoctorsInstructions"
        formControlName="patientHadAnyProblemsOverThePastMonthWithTheirMedications"
        [value]="patientMedicationProblemsPastMonth.hadTroubleFollowingDoctorsInstructions">
      <label class="form-check-label" for="hadTroubleFollowingDoctorsInstructions">Had trouble following doctor's
        instructions</label>
    </div>
    <div class="form-check form-check">
      <input class="form-check-input" type="radio" name="patientHadAnyProblemsOverThePastMonthWithTheirMedications"
        id="hadDifficultyCollectingMedicineFromPharmacy"
        formControlName="patientHadAnyProblemsOverThePastMonthWithTheirMedications"
        [value]="patientMedicationProblemsPastMonth.hadDifficultyCollectingMedicineFromPharmacy">
      <label class="form-check-label" for="hadDifficultyCollectingMedicineFromPharmacy">Had difficulty collecting
        medicine from pharmacy</label>
    </div>
    <div class="form-check form-check">
      <input class="form-check-input" type="radio" name="patientHadAnyProblemsOverThePastMonthWithTheirMedications"
        id="hadToGoToTheDoctorOrHospitalBecauseOfProblemsWithMedication"
        formControlName="patientHadAnyProblemsOverThePastMonthWithTheirMedications"
        [value]="patientMedicationProblemsPastMonth.hadToGoToTheDoctorOrHospitalBecauseOfProblemsWithMedication">
      <label class="form-check-label" for="hadToGoToTheDoctorOrHospitalBecauseOfProblemsWithMedication">Had to go to the
        doctor of hospital because of problems with medication</label>
    </div>
  </div>

  <div class="form-group mb-0" formGroupName="medicationHealthConditions" (change)="deselectOtherBox()"
    [class.has-error-group]="formControls.medicationHealthConditions.invalid && (showInvalid$ | async)">
    <label class="input-label-not-required" for="">What health conditions/co-morbidities is the patient taking
      medications for?
    </label>
    <div *ngFor="let condition of healthConditionsForFormArr">
      <div class="form-check">
        <input class="form-check-input big-checkbox" [formControlName]="condition.key" type="checkbox"
          [value]="condition.value" id="condition.key">
        <label class="form-check-label pl-2" for="condition.key">{{condition.value}}</label>
      </div>
    </div>
  </div>
  <input [class.has-error]="formControls.medicationHealthConditionsOtherDetails.invalid && (showInvalid$ | async)"
    class="form-control form-control-lg w-50 ml-4 mb-2" type="text" name="medicationHealthConditionsOtherDetails"
    id="medicationHealthConditionsOtherDetails" formControlName="medicationHealthConditionsOtherDetails"
    [attr.disabled]="!medicationInformationForm.value.medicationHealthConditions.other ? '' : null">

  <div class="form-group pb-1">
    <label class="input-label" for="gPPrescriberNumber">GP Prescriber Number</label>
    <input type="text" [class.has-error]="formControls.gPPrescriberNumber.invalid && (showInvalid$ | async)"
      class="form-control form-control-lg w-25" formControlName="gPPrescriberNumber" value="" id="gPPrescriberNumber">
  </div>

  <div class="form-group pb-1">
    <label class="input-label" for="medIndexScore">Patients MedIndex Score</label><span
      (click)="openMedIndexCalculator()" class="medindex-link pl-1">You can calculate the patient's MedIndex score using
      this spreadsheet</span>
    <input type="text" [class.has-error]="formControls.medIndexScore.invalid && (showInvalid$ | async)"
      class="form-control form-control-lg w-25" formControlName="medIndexScore" value="" id="medIndexScore">
  </div>
</form>