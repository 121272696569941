import { Inject, Injectable } from '@angular/core';
import { BaseApi } from '../../../../../../shared/core/base-api/base-api';
import { Router } from '@angular/router';
import { StorageService } from '../../../../../../shared/core/storage/storage.service';
import { AlertService } from '../../../../../core/alert/alert.service';
import { DatesService } from '../../../../../../shared/core/dates/dates.service';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { HmrFormModel } from '../../../../../../shared/models/six-cpa/HMR/hmr-form.model';
import { HMRFormType } from '../state/hmr-form.state';

@Injectable()
export class HMRService extends BaseApi {
  API = '';

  constructor(
    @Inject('environment') env: any,
    public http: HttpClient,
    public router: Router,
    public storageService: StorageService,
    public alertService: AlertService
  ) {
    super(http, router, storageService, alertService);
    this.API = env.baseApiUrl;
  }

  postSave(clientId: string, formData: HmrFormModel) {
    formData = this.formatFormDataDates(formData);
    return this.post<HmrFormModel>(
      `${this.API}/api/hmr/${clientId}/save`,
      formData
    );
  }

  postSubmit(clientId: string, formData: HmrFormModel) {
    formData = this.formatFormDataDates(formData);
    return this.post<HmrFormModel>(
      `${this.API}/api/hmr/${clientId}/submit`,
      formData
    );
  }

  putSave(clientId: string, formData: HmrFormModel) {
    formData = this.formatFormDataDates(formData);
    return this.put(`${this.API}/api/hmr/${clientId}/update-save`, formData);
  }

  putSubmit(clientId: string, formData: HmrFormModel) {
    formData = this.formatFormDataDates(formData);
    return this.put(`${this.API}/api/hmr/${clientId}/update-submit`, formData);
  }

  getHMRForm(clientId: string, formId: number): Observable<any> {
    return this.get<any>(`${this.API}/api/hmr/${clientId}/form/${formId}`);
  }

  private formatFormDataDates(form: HmrFormModel): HmrFormModel {
    switch (form.hmrFormType) {
      case HMRFormType.NewClaim:
        form.patientDetailsForm.dob = DatesService.ToEndOfLocalDayInUtc(
          form.patientDetailsForm.dob
        );
        form.pharmacyInformationForm.dateOfService = DatesService.ToEndOfLocalDayInUtc(
          form.pharmacyInformationForm.dateOfService
        );
        form.pharmacyInformationForm.dateCompletedReportSentToGP = DatesService.ToEndOfLocalDayInUtc(
          form.pharmacyInformationForm.dateCompletedReportSentToGP
        );
        form.gpDetailsReferralForm.referralDate = DatesService.ToEndOfLocalDayInUtc(
          form.gpDetailsReferralForm.referralDate
        );

        break;
      case HMRFormType.InterviewRegisteredPhamacist:
        form.gpDetailsReferralForm.referralDate = DatesService.ToEndOfLocalDayInUtc(
          form.gpDetailsReferralForm.referralDate
        );
        form.proposedDetailsForm.proposedDateOfInterview = DatesService.ToEndOfLocalDayInUtc(
          form.proposedDetailsForm.proposedDateOfInterview
        );
        form.proposedDetailsForm.dateOfGPReferral = DatesService.ToEndOfLocalDayInUtc(
          form.proposedDetailsForm.dateOfGPReferral
        );
        break;
      case HMRFormType.InterviewOutsidePatientHome:
        form.proposedDetailsForm.proposedDateOfInterview = DatesService.ToEndOfLocalDayInUtc(
          form.proposedDetailsForm.proposedDateOfInterview
        );
        form.proposedDetailsForm.dateOfGPReferral = DatesService.ToEndOfLocalDayInUtc(
          form.proposedDetailsForm.dateOfGPReferral
        );
        break;
    }
    return form;
  }
}
