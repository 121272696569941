import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
    name: 'appNoteTypeLink'
})
export class NoteTypeLinkPipe implements PipeTransform {
    transform(item: any): string {
        switch (item.notificationType) {
            case 'General':
            case 'Dispense':
            case 'Awaiting Pickup':
            case 'Patient Note':
            return `/app/profile/${item.clientId}/notes`;
            case 'Order':
            case 'Order Cycle':
            return `/app/profile/${item.clientId}/orders/create`;
            case 'Email':
            case 'Email Reply':
            return `/app/profile/${item.clientId}/comms/email`;
            case 'Phone call':
            return `/app/profile/${item.clientId}/comms/phone`;
            case 'Scrypt Message':
            return `/app/profile/${item.clientId}/comms/messenger`;
            case 'SMS':
            case 'SMS Reply':
            return `/app/profile/${item.clientId}/comms/sms`;
            case 'Mail':
            return `/app/profile/${item.clientId}/comms/mail`;
            case 'Customer Order':
            return item.link;
            default:
            return `/app/profile/${item.clientId}`;
          }
    }
}
