import { Channels, MailTabs, MessageTypes, SortOrder } from "~/shared/types/communications";

export const DefaultInboxChannels = [
    {
        name: 'All',
        value: Channels.All,
        defaults: [
            { tab: MailTabs.Inbox, default: true },
            { tab: MailTabs.Sent, default: true },
            { tab: MailTabs.Scheduled, default: true}
        ]
    },
    {
        name: 'SMS',
        value: Channels.Sms,
        icon: 'fal fa-comment-alt-lines',
        defaults: [
            { tab: MailTabs.Inbox, default: true },
            { tab: MailTabs.Sent, default: true },
            { tab: MailTabs.Scheduled, default: true }
        ]
    },
    {
        name: 'Email',
        value: Channels.Email,
        icon: 'fal fa-envelope',
        defaults: [
          { tab: MailTabs.Inbox, default: true },
          { tab: MailTabs.Sent, default: true },
          { tab: MailTabs.Scheduled, default: true }
        ]
    },
    {
        name: 'App',
        value: Channels.App,
        icon: 'fab fa-stripe-s',
        defaults: [
          { tab: MailTabs.Inbox, default: true },
          { tab: MailTabs.Sent, default: true },
          { tab: MailTabs.Scheduled, default: true }
        ]
    },
];






