import { BulkMessage, Recipient } from '~/shared/models/communication/bulk-message.model';
import { BulkMessageForm } from './new-bulk-message-form.state';

export enum NewBulkMessageFormActions {
  SET_BULK_MESSAGE_RECIPIENTS = '[NewBulkMessageFormActions] Set Recipients',
  EDIT_BULK_MESSAGE = '[NewBulkMessageFormActions] Edit Bulk Message',
  RESET_BULK_MESSAGE = '[NewBulkMessageFormActions] Reset Bulk Message',
  CREATE_BULK_MESSAGE = '[NewBulkMessageFormActions] New Bulk Message',
  UPDATE_BULK_MESSAGE = '[NewBulkMessageFormActions] Update Bulk Message',
  CREATE_BULK_MESSAGE_SUCCESS = '[NewBulkMessageFormActions] New Bulk Message Success',
  UPDATE_BULK_MESSAGE_SUCCESS = '[NewBulkMessageFormActions] Update Bulk Message Success',
  MATCH_NUMBERS = '[NewBulkMessageFormActions] Match recipients',
  GET_MESSAGE_TEMPLATES = '[NewBulkMessageFormActions] Get message templates',
}

export class GetBulkMessageTemplates {
  static readonly type = NewBulkMessageFormActions.GET_MESSAGE_TEMPLATES;
  constructor() { }
}

export class MatchNumbers {
  static readonly type = NewBulkMessageFormActions.MATCH_NUMBERS;
  constructor(public numbers: string[]) { }
}
export class SetNewBulkMessageRecipients {
  static readonly type = NewBulkMessageFormActions.SET_BULK_MESSAGE_RECIPIENTS;
  constructor(public recipients: Recipient[]) { }
}

export class EditNewBulkMessage {
  static readonly type = NewBulkMessageFormActions.EDIT_BULK_MESSAGE;
  constructor(public form: BulkMessageForm) { }
}

export class ResetNewBulkMessage {
  static readonly type = NewBulkMessageFormActions.RESET_BULK_MESSAGE;
  constructor() { }
}

export class NewBulkMessage {
  static readonly type = NewBulkMessageFormActions.CREATE_BULK_MESSAGE;
  constructor(public bulkMessage: BulkMessage) { }
}


export class UpdateBulkMessage {
  static readonly type = NewBulkMessageFormActions.UPDATE_BULK_MESSAGE;
  constructor(public bulkMessage: BulkMessage) { }
}

export class NewBulkMessageSuccess {
  static readonly type = NewBulkMessageFormActions.CREATE_BULK_MESSAGE_SUCCESS;
  constructor() { }
}

export class UpdateBulkMessageSuccess {
  static readonly type = NewBulkMessageFormActions.UPDATE_BULK_MESSAGE_SUCCESS;
  constructor() { }
}