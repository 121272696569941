import { State, Action, StateContext, Store } from '@ngxs/store';
import {
  SaveCIAction,
  UpdateCIForm,
} from './clinical-intervention.actions';
import { Injectable } from '@angular/core';
import { ClinicalInterventionFormsService } from './clinical-intervention-forms.service';
import { map, tap, withLatestFrom } from 'rxjs/operators';
import { SaveOrUpdateCIAction } from './clinical-intervention-save.actions';
import { ProfileState } from '@base/modules/core/profile/state/profile.state';

export class ClinicalInterventionSaveStateModel {
}
@Injectable()
@State<ClinicalInterventionSaveStateModel>({
  name: 'clinicalInterventionSave',
  defaults: {
  }
})
export class ClinicalInterventionSaveState {
  constructor(private store: Store) { }

  @Action(SaveOrUpdateCIAction)
  saveOrUpdateCI(ctx: StateContext<ClinicalInterventionSaveStateModel>, action: SaveOrUpdateCIAction) {
    return this.store.selectOnce(ClinicalInterventionFormsService.getClinicalInterventionRequest).pipe(
        withLatestFrom(this.store.select(ProfileState.clientId)),
        map(([formData, clientId]) =>
          formData && formData.sixCpaFormId !== null
            ? new UpdateCIForm(clientId, formData)
            : new SaveCIAction(clientId, formData)
        ),
        tap(dispatch => ctx.dispatch(dispatch))
    );
  }
}
