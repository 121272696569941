import { Component, OnInit, Input } from '@angular/core';
import { Client } from '~/shared/models/client.model';
import { Email } from '~/shared/models/email.model';
import { MessageTemplate } from '../state/comms.state';
import { Select, Store } from '@ngxs/store';
import { GetEmailHistory, SendEmail } from './state/email.actions';
import { EmailState } from './state/email.state';
import { Observable } from 'rxjs';
import { CreateStatistic } from '~/shared/state/statistics/statistics.actions';

@Component({
  selector: 'app-email',
  templateUrl: './email.component.html',
  styleUrls: ['./email.component.scss']
})
export class EmailComponent implements OnInit {
  @Select(EmailState.loading) loading$: Observable<boolean>;
  @Select(EmailState.emailHistory) emailHistory$: Observable<Email[]>;
  
  @Input() client: Client;
  @Input() templates: MessageTemplate[];

  constructor(private store: Store) { }

  ngOnInit() {
    this.store.dispatch(new GetEmailHistory(this.client.id));
  }

  emailSent({email, templateId }: {email: Email, templateId: string}) {
    this.store.dispatch(new SendEmail(email));
    this.updateCommTemplateStatistc(templateId);
  }

  private updateCommTemplateStatistc(templateId: string) {
    if(+templateId > 0) {
      const template = this.templates.find(c => c.id === templateId);
      
      if(!template) {
        return;
      }
      
      const {id, subject} = template;
      const updateStatRequest = {
        name: `CommTemplate - ${subject}`,
        entityId: +id,
        entityName: 'CommTemplate',
      }
  
      this.store.dispatch(new CreateStatistic(updateStatRequest))
    }
  }
}
