import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { Select, Store } from "@ngxs/store";
import { OrderManagementState } from "./state/order-mgmt.state";
import { Observable, Subject, timer } from "rxjs";
import { GetOrderRequestsForSelectedTab, UpdateSelectedTab, UpdateSelectedStatusFilter, PushOrderToQueue } from "./state/order-mgmt.actions";
import { OrderMethod, OrderRequestItemType, OrderRequestStatus } from "@profile/order-requests/models/order.enums";
import { takeUntil } from "rxjs/operators";
import { OrderRequestTableComponent } from "./components/order-request-table/order-request-table.component";
import { OrderRequestTableData, OrderStatusFilter, StatusFitlerOptionsSlice, TabName } from "./order-mgmt.types";
import { DefaultPageSize, TabNames } from "./order-mgmt.constants";

@Component({
    selector: 'app-order-mgmt',
    templateUrl: './order-mgmt.component.html',
    styleUrls: ['./order-mgmt.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrderManagementComponent implements OnInit, OnDestroy {
    @Select(OrderManagementState.tableData) tableData$: Observable<OrderRequestTableData>;
    @Select(OrderManagementState.statusFilterOptions) statusFilterOptions$: Observable<StatusFitlerOptionsSlice>;
    @Select(OrderManagementState.selectedTab) selectedTab$: Observable<TabName>;
    @Select(OrderManagementState.isLoading) isLoading$: Observable<boolean>;
    @Select(OrderManagementState.areFiltersAvailable) areFiltersAvailable$: Observable<boolean>;
    @Select(OrderManagementState.pendingOrdersCount) pendingOrdersCount$: Observable<number>;
    @Select(OrderManagementState.totalPartiallyReadyOrders) totalPartiallyReadyOrders$: Observable<number>;
    @ViewChild(OrderRequestTableComponent) orderRequestTable: OrderRequestTableComponent;

    ngOnDestroy$ = new Subject();

    refreshDelayInMilliseconds = 15 * 60 * 1000; //5 minutes
    refreshTimer$ = timer(0, this.refreshDelayInMilliseconds).pipe(
        takeUntil(this.ngOnDestroy$)
    );

    orderItemTypeEnum = OrderRequestItemType;
    orderMethodEnum = OrderMethod;
    orderStatusEnum = OrderRequestStatus;
    tabNames = TabNames;
    badgeCounts: Partial<{ [K in TabName]: Observable<number> | null }>;
    pageSize: number = DefaultPageSize;

    constructor(private store: Store) {}

    ngOnInit() {
        this.refreshTimer$.subscribe(() => {
            this.refreshOrders();
        });
        this.badgeCounts = {
            'Incoming Orders': this.pendingOrdersCount$,
            'Partially Ready Orders': this.totalPartiallyReadyOrders$
        };
    }

    ngOnDestroy() {
        this.ngOnDestroy$.next();
        this.ngOnDestroy$.complete();
    }

    refreshOrders() {
        const statusFilters = this.store.selectSnapshot(OrderManagementState.currentStatusFitlers);
        const page = this.store.selectSnapshot(OrderManagementState.currentPage);

        this.orderRequestTable.clearExpandedRows();
        this.store.dispatch(new GetOrderRequestsForSelectedTab(page, statusFilters));
    }

    changeSelectedTab(tabName: TabName) {
        this.store.dispatch(new UpdateSelectedTab(tabName));
    }

    changeSelectedStatusFilter(statusFilter: OrderStatusFilter) {
        this.store.dispatch(new UpdateSelectedStatusFilter(statusFilter));
    }

    tabClasses(tabName: TabName) {
        const selectedTab = this.store.selectSnapshot(OrderManagementState.selectedTab);
        const isActive = selectedTab === tabName;
        return {
          'd-flex justify-content-between align-items-center': true,
          'active-tab': isActive,
          'inactive-tab': !isActive,
        };
    }
}
