<ng-container *ngIf="(script$ | async) as script">
<ng-container *ngIf="(stockAdjustment$ | async) as stockAdjustment">
<div>
  <h1>Adjustments</h1>
  <h2>{{script.name}}</h2>
  <div>
      <div>
        <div class="form-group" g-xs="mt2">
          <label class="font__bold"><input type="radio" name="type" [value]="stockAdjustmentType.ABSOLUTE" [(ngModel)]="type" /> Absolute Change</label><br/>
          <label class="font__bold"><input type="radio" name="type" [value]="stockAdjustmentType.RELATIVE" [(ngModel)]="type" /> Relative Change</label>
        </div>

        <div class="form-group" g-xs="mt2" >
          <label class="font__bold">{{type === stockAdjustmentType.ABSOLUTE ? 'What is the total days on hand?' : 'How many days need to be adjusted?'}}</label>
          <input type="number" class="form-control" g-xs="mt1" name="days" [(ngModel)]="days" />
          <p *ngIf="type === stockAdjustmentType.RELATIVE" g-xs="mt1"><i>Negative changes represent a deduction</i></p>
        </div>

        <button (click)="commitChange(script)" class="button btn__yellow pull-right" g-xs="mt2" *ngIf="!stockAdjustment.loading">Update</button>
      </div>
  </div>
  <div class="clearfix"></div>
  <i class="fas fa-sync fa-spin" *ngIf="stockAdjustment.loading"></i>
  <p class="text-warning" *ngIf="stockAdjustment.error">{{stockAdjustment.error}}</p>
</div>
</ng-container>
</ng-container>
