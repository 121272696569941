<div *ngIf="(primaryContactState$ | async) as primaryContactState" class="modal">

  <div class="modal-body border__grey" *ngIf="primaryContactState && primaryContactState.summary">
    <div *ngIf="primaryContactState.stateStatus == stateStatus.Loading" class="text-center bubble-loader">
      <div class="spinner-grow text-info" role="status">
        <span class="sr-only"></span>
      </div>
      <div>
        Working...
      </div>
    </div>
    <div *ngIf="primaryContactState.stateStatus == stateStatus.Error">
      <div class="alert alert-danger">
        An error occured while applying the changes.
      </div>
    </div>
    <div class="ml-2 mr-2">
      <!-- Header -->
      <div class="row">
        <div class="col">
          <h2>Primary Contact</h2>
        </div>
        <div (click)="closeModal()" class="col-1 text-right pointer">
          <i style="font-size: 25px;" class="fal fa-times"></i>
        </div>
      </div>

      <!-- devider -->
      <div class="row">
        <div class="devider"></div>
      </div>

      <!-- Linked Mobile -->
      <div class="row my-4" *ngIf="notNullOrEmpty(primaryContactState.summary.mobileNumber)">
        <div class="col-1 text-center">
          <i class="far fa-2x fa-mobile"></i>
        </div>
        <div class="col">
          <span class="title"
            *ngIf="primaryContactState.summary.mobileNumber">{{primaryContactState.summary.mobileNumber}}</span>
        </div>
      </div>

      <!-- Linked Email -->
      <div class="row my-4" *ngIf="notNullOrEmpty(primaryContactState.summary.email)">
        <div class="col-1 text-center">
          <i class="far fa-2x fa-envelope"></i>
        </div>
        <div class="col">
          <span class="title">{{primaryContactState.summary.email}}</span>
        </div>
      </div>

      <!-- Disclaimer -->
      <div class="row mb-3">
        <div class="col disclaimer align-self-center">
          <div class="p-2">
            <span *ngIf="hasPrimaryContacts(primaryContactState.summary)"><i class="fal fa-info-circle"
                style="color: #1080A5;"></i> If the same
              details exist in other
              profiles, messages will only appear for the primary
              contact</span>
            <span *ngIf="!hasPrimaryContacts(primaryContactState.summary)"><i class="fas fa-info-circle"
                style="color: #1080A5;"></i> Incoming
              communications may get duplicated across the following profiles, please select a Primary Contact</span>
            <span *ngIf="!primaryContactState.summary.smsPrimaryContact">
              <br />
              <span
                *ngIf="hasSMSPrimary(primaryContactState.summary)"><strong>{{getSMSPrimary(primaryContactState.summary)}}</strong>
                <span><strong> is listed as the primary contact</strong> for this mobile number.</span>
              </span>
            </span>
            <span
              *ngIf="!primaryContactState.summary.emailPrimaryContact && notNullOrEmpty(primaryContactState.summary.email)">
              <br />
              <span
                *ngIf="hasEmailPrimary(primaryContactState.summary)"><strong>{{getEmailPrimary(primaryContactState.summary)}}</strong>
                <span><strong> is listed as the primary contact</strong> for this email address.</span>
              </span>
            </span>
          </div>
        </div>
      </div>

      <!-- Opt out -->
      <form [formGroup]="optoutForm">
        <div class="form-group" g-xs="mt2" *ngIf="notNullOrEmpty(primaryContactState.summary.mobileNumber)">
          <label class="ml-1 mr-3 title-opt">Opt out of scrypt reminders (this overrides all other settings)
            {{isCreating}}</label>
          <ui-switch class="float-right mr-1" size="small" [checked]="isCreating"
            [formControl]="optoutForm.controls['isCreating']" name="isCreating" (change)="updateOptOut()">
          </ui-switch>
        </div>
      </form>

      <!-- Top Content Edit Primary & Secondary SMS-->
      <div class="row my-4">
        <div class="col-4">
          <div>
            <div class="title" *ngIf="primaryContactState.summary.smsPrimaryContact">SMS Primary Contact: </div>
            <div class="title" *ngIf="!primaryContactState.summary.smsPrimaryContact">Linked SMS profile: </div>
          </div>
          <div>
            <i *ngIf="primaryContactState.summary.smsPrimaryContact" class="fas fa-check-circle icon_success"></i>
            <span
              [routerLink]="['/patient/profile', getProfileForContactType(primaryContactState.summary, primaryContactTypes.SMS), 'categories']"
              *ngIf="hasSMSPrimary(primaryContactState.summary) else noContactSelected" class="contact">
              {{getSMSPrimaryName(primaryContactState.summary)}}
            </span>
          </div>
        </div>
        <div class="col-4">
          <button (click)="removeAsSMSPrimary()" class="btn btn-warning contact-btn"
            *ngIf="primaryContactState.summary.smsPrimaryContact"
            [disabled]="primaryContactState.stateStatus == stateStatus.Loading">Remove as
            SMS primary</button>
          <button (click)="makeSMSPrimary()" class="btn btn-warning contact-btn"
            *ngIf="!primaryContactState.summary.smsPrimaryContact"
            [disabled]="primaryContactState.stateStatus == stateStatus.Loading">Make SMS
            primary contact</button>
        </div>
        <div class="col-1" (mouseenter)="toggleInfoBox(true)" (mouseleave)="toggleInfoBox(false)">
          <i class="fal fa-2x fa-info-circle mt-2" style="color: #1080A5;"></i>
          <div class="info-box" *ngIf="showInfo">
            <div class="info-box-text">
              Removing a primary contact may result in duplicated communications. This can be avoided by removing the
              same contact details in the dispense profile.
            </div>
          </div>
        </div>
      </div>

      <!-- Top Content Edit Primary & Secondary Email-->
      <div class="row my-4" *ngIf="notNullOrEmpty(primaryContactState.summary.email)">
        <div class="col-4">
          <div>
            <div class="title" *ngIf="primaryContactState.summary.emailPrimaryContact">Email Primary Contact: </div>
            <div class="title" *ngIf="!primaryContactState.summary.emailPrimaryContact">Linked Email profile: </div>
          </div>
          <div>
            <i *ngIf="primaryContactState.summary.emailPrimaryContact" class="fas fa-check-circle icon_success"></i>
            <span
              [routerLink]="['/patient/profile', getProfileForContactType(primaryContactState.summary, primaryContactTypes.Email), 'categories']"
              *ngIf="hasEmailPrimary(primaryContactState.summary) else noContactSelected" class="contact">
              {{getEmailPrimaryName(primaryContactState.summary)}}
            </span>
          </div>
        </div>
        <div class="col-4">
          <button (click)="removeAsEmailPrimary()" class="btn btn-warning contact-btn"
            *ngIf="primaryContactState.summary.emailPrimaryContact"
            [disabled]="primaryContactState.stateStatus == stateStatus.Loading">Remove as
            email primary</button>
          <button (click)="makeEmailPrimary()" class="btn btn-warning contact-btn"
            *ngIf="!primaryContactState.summary.emailPrimaryContact"
            [disabled]="primaryContactState.stateStatus == stateStatus.Loading">Make
            email primary contact</button>
        </div>
      </div>

      <!-- devider -->
      <div class="row">
        <div class="devider"></div>
      </div>

      <!-- Affected Customers / Linked PRofiles -->
      <div class="row my-4"
        *ngIf="currentPatientIsPrimary(primaryContactState.summary) || !hasPrimaryContacts(primaryContactState.summary)">
        <div class="col"
          *ngIf="primaryContactState.summary?.contacts?.length && notNullOrEmpty(primaryContactState.summary.mobileNumber)">
          <div class="title my-1">Linked SMS Profiles:</div>
          <div class="contact"
            *ngFor="let contact of primaryContactState.summary | filterLinkedContacts: primaryContactTypes.SMS">
            <a [routerLink]="['/patient/profile', contact.clientId, 'categories']">{{contact.firstName}}
              {{contact.surname}}</a>
          </div>
        </div>
        <div class="col"
          *ngIf="primaryContactState.summary?.contacts?.length && notNullOrEmpty(primaryContactState.summary.email)">
          <div class="title my-1">Linked Email Profiles:</div>
          <div class="contact"
            *ngFor="let contact of primaryContactState.summary | filterLinkedContacts: primaryContactTypes.Email">
            <a [routerLink]="['/patient/profile', contact.clientId, 'categories']">{{contact.firstName}}
              {{contact.surname}}</a>
          </div>
        </div>
      </div>

      <!-- devider -->
      <div class="row" *ngIf="currentPatientIsPrimary(primaryContactState.summary)">
        <div class="devider"></div>
      </div>

      <div class="row my-3" g-xs="mt2">
        <div class="col">
          <button class="btn btn-outline-info float-right" (click)="closeModal()"
            [disabled]="primaryContactState.stateStatus == stateStatus.Loading">
            Cancel
          </button>
        </div>
      </div>
    </div>
  </div>

</div>
<div class="modal-background"></div>
<ng-template #noContactSelected>
  No primary contact selected
</ng-template>
