<div class="mb-1" *ngIf="(results$ | async)?.length as count">
  <div class="float-right mb-3 mr-4">
    <div class="float-left mb-0 select-total">
      <span class="underline" *ngIf="!(selectTotal$ | async)"><a (click)="selectAll()">Select All Pages</a></span>
      <span class="underline" *ngIf="(selectTotal$ | async)"><a (click)="unSelectAll()">Select None</a></span>
    </div>
    <span class="mr-4">{{count}} total customers</span>
    <button class="btn btn-success" (click)="navigateToBulkComms()">Schedule Bulk SMS</button>
    <app-advanced-search-export-options [expandedColumns]="[8]" [headers]="headers" [rows]="exportData$ | async"
      class="ml-2"></app-advanced-search-export-options>
    <button *ngIf="(recipientList$ | async).length as recipientCount" class="ml-2 btn btn-outline-info">Selected valid
      recipients: {{recipientCount}}</button>
  </div>
  <div class="float-left mb-0">
    <span class="underline" *ngIf="!(selectCurrentPage$ | async)"><a (click)="selectAllOnPage()">Select Page</a></span>
    <span class="underline" *ngIf="selectCurrentPage$ | async"><a (click)="unSelectAllOnPage()">Select None</a></span>
  </div>
</div>
<div class="table-container w-100">
  <table cdk-table [dataSource]="dataOnPage$" class="table search-list__table">
    <tr class="bg__light-yellow-table" cdk-header-row *cdkHeaderRowDef="displayedColumns$.value"></tr>
    <tr class="bg__light-yellow-table" cdk-row *cdkRowDef="let row; columns: displayedColumns$.value"></tr>

    <tr *cdkNoDataRow>
      <ng-container *ngIf="isLoading$ | async">
        <td>
          <app-loader loadingMessage="Fetching results"></app-loader>
        </td>
      </ng-container>
      <ng-container *ngIf="!(isLoading$ | async)">
        <ng-container *ngIf="(results$ | async)?.length === 0">
          <td>
            No results
          </td>
        </ng-container>
      </ng-container>
    </tr>

    <ng-container cdkColumnDef="bulkSMS">
      <th cdk-header-cell *cdkHeaderCellDef>
        Bulk SMS
      </th>
      <td cdk-cell *cdkCellDef="let row">
        <div class="checkbox-inline" *ngIf="row.mobileNumber">
          <input [checked]="row.selected" #checkboxes type="checkbox" [value]="row.mobileNumber"
            (change)="toggleRecipient(row.id, $event)" />
        </div>
      </td>
    </ng-container>
    <ng-container cdkColumnDef="name">
      <th cdk-header-cell *cdkHeaderCellDef (click)="adjustSort('fullname')">
        Name
        <span *ngIf="sortKey$.value === 'fullname'">
          {{ sortDirection$.value === 'asc' ? '↥' : '↧' }}
        </span>
      </th>
      <td cdk-cell *cdkCellDef="let row">
        <a routerLink="/profile/{{ row.id }}" class="color__darkest flex align-items-center">
          {{ row.firstname }} {{ row.surname }}
        </a>
      </td>
    </ng-container>
    <ng-container cdkColumnDef="address">
      <th cdk-header-cell *cdkHeaderCellDef (click)="adjustSort('fulladdress')">
        Address
        <span *ngIf="sortKey$.value === 'fulladdress'">
          {{ sortDirection$.value === 'asc' ? '↥' : '↧' }}
        </span>
      </th>
      <td cdk-cell *cdkCellDef="let row">
        <span *ngIf="row?.homeAddress">{{row.homeAddress}} </span>
        <span *ngIf="row?.homeSuburb">{{row.homeSuburb}} </span>
        <span *ngIf="row?.homeState">{{row.homeState}} </span>
        <span *ngIf="row?.homePostcode">{{row.homePostcode}} </span>
      </td>
    </ng-container>
    <ng-container cdkColumnDef="birthDate">
      <th cdk-header-cell *cdkHeaderCellDef (click)="adjustSort('birthDate')">
        Date of birth
        <span *ngIf="sortKey$.value === 'birthDate'">
          {{ sortDirection$.value === 'asc' ? '↥' : '↧' }}
        </span>
      </th>
      <td cdk-cell *cdkCellDef="let row">
        {{ row.birthDate | tenantDate }}
      </td>
    </ng-container>
    <ng-container cdkColumnDef="gender">
      <th cdk-header-cell *cdkHeaderCellDef (click)="adjustSort('gender')">
        Gender
        <span *ngIf="sortKey$.value === 'gender'">
          {{ sortDirection$.value === 'asc' ? '↥' : '↧' }}
        </span>
      </th>
      <td cdk-cell *cdkCellDef="let row">
        {{ row.gender }}
      </td>
    </ng-container>
    <ng-container cdkColumnDef="phone">
      <th cdk-header-cell *cdkHeaderCellDef (click)="adjustSort('mobileNumber')">
        Phone
        <span *ngIf="sortKey$.value === 'mobileNumber'">
          {{ sortDirection$.value === 'asc' ? '↥' : '↧' }}
        </span>
      </th>
      <td cdk-cell *cdkCellDef="let row">
        <span *ngIf="row?.mobileNumber">{{row.mobileNumber}}</span>
        <span *ngIf="!row?.mobileNumber && row?.phoneNumber">{{row.phoneNumber}}</span>
      </td>
    </ng-container>
    <ng-container cdkColumnDef="email">
      <th cdk-header-cell *cdkHeaderCellDef (click)="adjustSort('email')">
        Email
        <span *ngIf="sortKey$.value === 'email'">
          {{ sortDirection$.value === 'asc' ? '↥' : '↧' }}
        </span>
      </th>
      <td cdk-cell *cdkCellDef="let row">
        <span *ngIf="row?.email">{{row.email}}</span>
      </td>
    </ng-container>
    <ng-container cdkColumnDef="medicare">
      <th cdk-header-cell *cdkHeaderCellDef (click)="adjustSort('medicareNo')">
        Medicare
        <span *ngIf="sortKey$.value === 'medicareNo'">
          {{ sortDirection$.value === 'asc' ? '↥' : '↧' }}
        </span>
      </th>
      <td cdk-cell *cdkCellDef="let row">
        <span *ngIf="row?.medicareNo">{{row.medicareNo}}</span>
      </td>
    </ng-container>
    <ng-container cdkColumnDef="concessionNo">
      <th cdk-header-cell *cdkHeaderCellDef (click)="adjustSort('concessionNo')">
        ConcessionNo
        <span *ngIf="sortKey$.value === 'concessionNo'">
          {{ sortDirection$.value === 'asc' ? '↥' : '↧' }}
        </span>
      </th>
      <td cdk-cell *cdkCellDef="let row">
        <span *ngIf="row?.concessionNo">{{row.concessionNo}}</span>
      </td>
    </ng-container>
    <ng-container cdkColumnDef="created">
      <th cdk-header-cell *cdkHeaderCellDef (click)="adjustSort('created')">
        Created
        <span *ngIf="sortKey$.value === 'created'">
          {{ sortDirection$.value === 'asc' ? '↥' : '↧' }}
        </span>
      </th>
      <td cdk-cell *cdkCellDef="let row">
        <span *ngIf="row?.created">{{row.created | tenantDate }}</span>
      </td>
    </ng-container>
    <ng-container cdkColumnDef="lastvisit">
      <th cdk-header-cell *cdkHeaderCellDef (click)="adjustSort('lastDispenseDate')">
        Last Visit
        <span *ngIf="sortKey$.value === 'lastDispenseDate'">
          {{ sortDirection$.value === 'asc' ? '↥' : '↧' }}
        </span>
      </th>
      <td cdk-cell *cdkCellDef="let row">
        <span *ngIf="row?.lastDispenseDate">{{row.lastDispenseDate | tenantDate }}</span>
      </td>
    </ng-container>
  </table>
</div>
<div *ngIf="(results$ | async)?.length as count" class="d-flex justify-content-between w-100">
  <pagination class="mt-1" #pagination [boundaryLinks]="true" [totalItems]="count" [itemsPerPage]="pageSize$ | async"
    [ngModel]="currentPage$ | async" (ngModelChange)="currentPage$.next($event)" [rotate]="true" [maxSize]="10">
  </pagination>
  <div class="mt-2">
    <select [(ngModel)]="pageSize" (change)="changeResultsSize()" id="showResults" name="resultsPerPage"
      class="form-control form-control-lg">
      <option *ngFor="let results of resultsPerPageList" [ngValue]="results">
        {{results}} results
      </option>
    </select>
  </div>
</div>
