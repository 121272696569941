import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { PhoneService } from '../../../../../shared/services/phone.service';
import { PhoneNote } from '../../../../../shared/models/phoneNote.model';
import { Subscription } from 'rxjs/Subscription';
import { Notification } from '../../../../../shared/models/notification.model';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-add-note',
  templateUrl: './add-note.component.html',
  styleUrls: ['./add-note.component.scss']
})

export class AddNoteComponent implements OnInit, OnDestroy {

  constructor(private phoneService: PhoneService) { }

  @Input() clientId: string;
  @Output() noteAddedEvent = new EventEmitter<PhoneNote>();

  sub1: Subscription;
  notification: Notification;


  ngOnInit() {
    this.notification = new Notification('success', '');
  }


  onSubmit(form: NgForm) {
    const { message } = form.value;
    this.addNote(message, form);
  }
  addNote(message: string, form: NgForm) {
    const newItem = new PhoneNote(message, this.clientId);
    this.sub1 = this.phoneService.sendPhoneNote(newItem)
      .subscribe((item: PhoneNote) => {
        form.reset();
        form.form.patchValue({ message: '' });
        this.noteAddedEvent.emit(item);
      },
        (error) => {
          this.showNotification('danger', error);
        });
  }

  showNotification(type: string = 'danger', text: string = '') {
    this.notification.text = text;
    this.notification.type = type;
    window.setTimeout(() => {
      this.notification.text = '';
    }, 4000);
  }
  ngOnDestroy() {
    if (this.sub1) { this.sub1.unsubscribe(); }
  }
}
