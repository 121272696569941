import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {  FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoaderModule } from '../../../../modules/core/loader/loader.module';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import { NgxsModule } from '@ngxs/store';
import { AddEditTriggerModalComponent } from './add-edit-trigger-modal.component';
import { AddEditTriggerModalState } from './state/add-edit-trigger-modal.state';

@NgModule({
  declarations: [AddEditTriggerModalComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    LoaderModule,
    NgxsFormPluginModule,
    NgxsModule.forFeature([AddEditTriggerModalState]),
    FormsModule
  ],
  exports: [AddEditTriggerModalComponent]
})
export class AddEditTriggerModalModule { }
