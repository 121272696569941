import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { ProfileState } from '../core/profile/state/profile.state';
import { Observable, combineLatest, Subscription } from 'rxjs';
import { ClientViewModel } from '../core/profile/client.model';
import { OutOfStoreState } from './out-of-store/state/out-of-store.state';
import { CipherState } from './cipher/state/cipher.state';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { AlertService } from '../core/alert/alert.service';
import {
  OpenOutOfStore,
  CloseOutOfStore,
} from './out-of-store/state/out-of-store.actions';
import {
  ProfilePopupVisibleAction,
  ProfileZIndexCounterAction,
  ProfileAlertVisibleAction,
  SetMedsListsForSixCpa,
} from '../core/profile/state/profile.actions';
import { RequestCipherAction } from './cipher/state/cipher.actions';
import { categoriesPath } from './routing/profile-routing.paths';
import { CategoriesState } from './categories/state/categories.state';
import { tap } from 'rxjs/operators';
import { SetupNumberOfMedicines } from './six-cpa/medication-information-form/state/medication-information.actions';
import { OpenInviteToSignUpModal } from './invite-to-sign-up/state/invite-to-sign-up.actions';
import { InviteToSignUpState } from './invite-to-sign-up/state/invite-to-sign-up.state';
import {
  FeaturesState,
  FeaturesStateModel,
} from '../core/features/features.state';
import { GetCommTemplatesAction } from '../pharmacy-comms/comm-templates/state/comm-templates.actions';
import { GetTriggers } from '../pharmacy-comms/comms-reminders/state/triggers.actions';
import { GetPharmacyCommsDetailsAction } from '../pharmacy-comms/pharmacy-comms-details/state/pharmacy-comms-details.actions';
import { GetDoctorReportTemplates } from '../../shared/components/doctor-report-templates-selector/state/doctor-report-templates-selector.actions';
import { FetchAttendingDoctors } from './attending-doctor/state/attending-doctor.action';
import { ASLService } from '../../services/asl.service';
import { CloseSuccessModal, OpenRegisterMySl } from './asl/state/asl.actions';
import { ASLState } from './asl/state/asl.state';
import { AslPatient, SLConsentState } from '../profile/asl/state/asl.models';
import { ScryptSmsState, ScryptSmsStateModel } from '../../shared/components/scrypt-sms/state/scrypt-sms.state';
import { FeatureDecisions } from '../core/features/feature-decisions.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit, OnDestroy {
  @Select(ProfileState.profile) profile$: Observable<ClientViewModel>;
  @Select(ProfileState.notification) notification$: Observable<Notification>;
  @Select(ProfileState.popupVisible) popupVisible$: Observable<boolean>;
  @Select(OutOfStoreState.loading) addItemsLoading$: Observable<boolean>;
  @Select(OutOfStoreState.showModal) addItemsVisible$: Observable<boolean>;
  @Select(CipherState.modalVisible) cipherVisible$: Observable<boolean>;
  @Select(ProfileState.zIndexCounter) zIndexCounter$: Observable<number>;
  @Select(ProfileState.alertVisible) alertVisible$: Observable<boolean>;
  @Select(CategoriesState.smallestDoh) smallestDoh$: Observable<number>;
  @Select(InviteToSignUpState.modalVisible)
  inviteToSignUpVisible$: Observable<boolean>;
  @Select(FeaturesState.getFeatures) features$: Observable<{ [featureName: string]: boolean }>;
  @Select(ASLState.showASLModal) showASLRegister$: Observable<boolean>;
  @Select(ASLState.showASLSuccess) showASLSuccess$: Observable<boolean>;
  @Select(ASLState.showASLSummary) showASLSummary$: Observable<boolean>;
  @Select(ASLState.aslDetails) aslDetails$: Observable<AslPatient>;
  @Select(ScryptSmsState.scryptSMSState) scryptSMSState$: Observable<ScryptSmsStateModel>;

  reportBladeOpen = false;
  sixCpaSubscription: Subscription;
  categoriesSubscription: Subscription;

  slConsentState = SLConsentState;

  isLite = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    public alertService: AlertService,
    private store: Store,
    private router: Router,
    private aslService: ASLService,
    public featureDecisions: FeatureDecisions,
    @Inject('environment') env: any
  ) {
    this.isLite = env.isLite;
  }

  ngOnInit() {
    this.checkForNewOrder();
    this.setScriptsForPatient();
    this.getCommSettings();
    this.getAttendingDoctors();
  }

  private getAttendingDoctors() {
    const clientId = this.store.selectSnapshot(ProfileState.clientId);
    return this.store.dispatch([
      new FetchAttendingDoctors(clientId),
      new GetDoctorReportTemplates(clientId),
    ]);
  }

  private getCommSettings() {
    return this.store.dispatch([
      new GetPharmacyCommsDetailsAction(),
      new GetCommTemplatesAction(),
      new GetTriggers(),
    ]);
  }

  ngOnDestroy() {
    if (this.sixCpaSubscription) {
      this.sixCpaSubscription.unsubscribe();
    }
    if (this.categoriesSubscription) {
      this.categoriesSubscription.unsubscribe();
    }
  }

  private setScriptsForPatient() {
    this.sixCpaSubscription = CategoriesState.categorised$(this.store).subscribe((results) =>
      this.store.dispatch(new SetMedsListsForSixCpa(results))
    );

    this.categoriesSubscription = CategoriesState.scripts$(this.store)
      .pipe(
        tap((data: any[]) =>
          this.store.dispatch(new SetupNumberOfMedicines(data))
        )
      )
      .subscribe();
  }

  addOutOfStoreClick() {
    this.store.dispatch(new OpenOutOfStore());
  }

  closeOutOfStoreScripts() {
    this.store.dispatch(new CloseOutOfStore());
  }

  generateReport() {
    const zInd = this.store.selectSnapshot(ProfileState.zIndexCounter) + 1;
    this.reportBladeOpen = true;
    this.store.dispatch(new ProfileZIndexCounterAction(zInd));
  }

  openProfileModal(event) {
    this.store.dispatch(new ProfilePopupVisibleAction(event));
  }

  closeProfileModal() {
    this.store.dispatch(new ProfilePopupVisibleAction(false));
  }

  closeAlert() {
    this.store.dispatch(new ProfileAlertVisibleAction(false));
  }

  showEditModal() {
    this.store.dispatch([
      new ProfileAlertVisibleAction(false),
      new ProfilePopupVisibleAction(true),
    ]);
  }

  getCipher(clientId: number) {
    this.store.dispatch(new RequestCipherAction(clientId));
  }

  openInviteToSignUpModal() {
    this.store.dispatch(new OpenInviteToSignUpModal());
  }

  showOutOfStoreButton() {
    return this.router.url.indexOf(categoriesPath) > -1;
  }

  private checkForNewOrder() {
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      if (params['newOrder']) {
        this.alertService.success(
          'Success - If not already done, Order should now be packed for pickup or delivery'
        );
      }
    });
  }

  openRegisterSLModal(): void {
    this.store.dispatch(new OpenRegisterMySl());
  }

  closeSuccessModal(): void {
    this.store.dispatch(new CloseSuccessModal());
  }
}
