import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { LoaderModule } from '../../../../modules/core/loader/loader.module';
import { AddEditCommTemplateModalModule } from '../../comm-templates/add-edit-comm-template-modal/add-edit-comm-template-modal.module';
import { AddEditTriggerModalModule } from '../add-edit-trigger-modal/add-edit-trigger-modal.module';
import { CommsRemindersListModule } from '../comms-reminders-list/comms-reminders-list.module';
import { SortTriggersPipe } from '../pipes/sort-triggers-pipe.pipe';
import { CommsRemindersComponent } from './comms-reminders.component';


@NgModule({
  declarations: [CommsRemindersComponent, SortTriggersPipe],
  imports: [
    CommonModule,
    CommsRemindersListModule,
    ReactiveFormsModule,
    FormsModule,
    LoaderModule,
    AddEditCommTemplateModalModule,
    AddEditTriggerModalModule
  ],
   exports: [CommsRemindersComponent],
})
export class CommsRemindersModule { }
