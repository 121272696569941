<form [formGroup]="daaAdditionalDetailsForm" ngxsForm="daaAdditionalDetailsForm.daaAdditionalDetailsForm" class="p-4">
  <div class="form-group pb-2"
    [class.has-error-group]="formControls.daaReferralSource.invalid && (showInvalid$ | async)">
    <label class="input-label">What is the referral source for the DAA?</label>
    <div class="form-check form-check">
      <input class="form-check-input" type="radio" name="daaReferralSource" id="hmr" formControlName="daaReferralSource"
        [value]="daaReferralSource.HMRManagementPlan">
      <label class="form-check-label" for="hmr">HMR Management Plan</label>
    </div>
    <div class="form-check form-check">
      <input class="form-check-input" type="radio" name="daaReferralSource" id="medsCheckReview"
        formControlName="daaReferralSource" [value]="daaReferralSource.MedsCheckReview">
      <label class="form-check-label" for="medsCheckReview">MedsCheck review</label>
    </div>
    <div class="form-check form-check">
      <input class="form-check-input" type="radio" name="daaReferralSource" id="gpReferralNotHealthCareHomesPilot"
        formControlName="daaReferralSource" [value]="daaReferralSource.GPReferralNotHealthCareHomesPilot">
      <label class="form-check-label" for="gpReferralNotHealthCareHomesPilot">GP Referral (not from GP participating in
        Health Care Homes
        pilot)</label>
    </div>
    <div class="form-check form-check">
      <input class="form-check-input" type="radio" name="daaReferralSource" id="gpReferralHealthCareHomesPilot"
        formControlName="daaReferralSource" [value]="daaReferralSource.GPReferralHealthCareHomesPilot">
      <label class="form-check-label" for="gpReferralHealthCareHomesPilot">GP Referral (from GP participating in Health
        Care Homes
        pilot)</label>
    </div>
    <div class="form-check form-check">
      <input class="form-check-input" type="radio" name="daaReferralSource" id="selfReferral"
        formControlName="daaReferralSource" [value]="daaReferralSource.SelfReferral">
      <label class="form-check-label" for="selfReferral">Self-referral</label>
    </div>
    <div class="form-check form-check">
      <input class="form-check-input" type="radio" name="daaReferralSource" id="pharmacy"
        formControlName="daaReferralSource" [value]="daaReferralSource.Pharmacy">
      <label class="form-check-label" for="pharmacy">Pharmacy</label>
    </div>
    <div class="form-check form-check">
      <input class="form-check-input" type="radio" name="daaReferralSource" id="notStated"
        formControlName="daaReferralSource" [value]="daaReferralSource.NotStatedOrInadequatelyDescribed">
      <label class="form-check-label" for="notStated">Not stated/inadequately described</label>
    </div>
  </div>
  <div class="form-group pb-1">
    <label for="datePlanWasMade" class="input-label">What was the date the referral/plan was made?</label>
    <input type="text" class="form-control form-control-lg w-25"
      [class.has-error]="formControls.datePlanWasMade.invalid && (showInvalid$ | async)"
      [bsValue]="daaAdditionalDetailsForm.value.datePlanWasMade | date: 'DD-MM-YYYY'" formControlName="datePlanWasMade"
      id="datePlanWasMade" [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY' }" placeholder="dd-mm-yyyy" bsDatepicker>
  </div>
  <div class="form-group pb-1">
    <label for="dateOfInitialContact" class="input-label">Date of initial contact</label>
    <input type="text" class="form-control form-control-lg w-25"
      [class.has-error]="formControls.dateOfInitialContact.invalid && (showInvalid$ | async)"
      [bsValue]="daaAdditionalDetailsForm.value.dateOfInitialContact | date: 'DD-MM-YYYY'"
      formControlName="dateOfInitialContact" id="dateOfInitialContact" [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY' }"
      placeholder="dd-mm-yyyy" bsDatepicker>
  </div>
  <div class="form-group pb-2"
    [class.has-error-group]="formControls.daaCollectionSource.invalid && (showInvalid$ | async)">
    <label class="input-label">How will the patient mainly obtain the DAA?</label>
    <div class="form-check form-check">
      <input class="form-check-input" type="radio" name="daaCollectionSource" id="collection"
        formControlName="daaCollectionSource" [value]="daaCollectionSource.CollectedFromPharmacy">
      <label class="form-check-label" for="collection">Collected from Pharmacy</label>
    </div>
    <div class="form-check form-check">
      <input class="form-check-input" type="radio" name="daaCollectionSource" id="delivery"
        formControlName="daaCollectionSource" [value]="daaCollectionSource.HomeDelivered">
      <label class="form-check-label" for="delivery">Home Delivered</label>
    </div>
    <div class="form-check form-check">
      <input class="form-check-input" type="radio" name="daaCollectionSource" id="other"
        formControlName="daaCollectionSource" [value]="daaCollectionSource.Other">
      <label class="form-check-label" for="other">Other</label>
    </div>
  </div>
  <div class="form-group pb-2"
    [class.has-error-group]="formControls.typeOfDAAPackedByPharmacy.invalid && (showInvalid$ | async)">
    <label class="input-label">Type of DAA packed by the pharmacy</label>
    <div class="form-check form-check">
      <input class="form-check-input" type="radio" name="typeOfDAAPackedByPharmacy" id="boxes"
        formControlName="typeOfDAAPackedByPharmacy" [value]="daaPackageTypes.CompartmentalisedBoxes">
      <label class="form-check-label" for="boxes">Compartmentalised boxes</label>
    </div>
    <div class="form-check form-check">
      <input class="form-check-input" type="radio" name="typeOfDAAPackedByPharmacy" id="blisterPacks"
        formControlName="typeOfDAAPackedByPharmacy" [value]="daaPackageTypes.BlisterPacks">
      <label class="form-check-label" for="blisterPacks">Blister packs</label>
    </div>
    <div class="form-check form-check">
      <input class="form-check-input" type="radio" name="typeOfDAAPackedByPharmacy" id="bubblePacks"
        formControlName="typeOfDAAPackedByPharmacy" [value]="daaPackageTypes.BubblePacks">
      <label class="form-check-label" for="bubblePacks">Bubble packs</label>
    </div>
    <div class="form-check form-check">
      <input class="form-check-input" type="radio" name="typeOfDAAPackedByPharmacy" id="sachet"
        formControlName="typeOfDAAPackedByPharmacy" [value]="daaPackageTypes.SachetSystems">
      <label class="form-check-label" for="othsacheter">Sachet systems</label>
    </div>
  </div>
</form>