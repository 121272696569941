import { Component, OnInit, Input } from '@angular/core';
import { SmsMessageHistory } from '../../../../shared/models/messageHistory.model';
import { Client } from '../../../../shared/models/client.model';
import { Store, Select } from '@ngxs/store';
import { FetchSMSHistory } from './state/sms-history.actions';
import { SMSHistoryState } from './state/sms-history.state';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-sms',
  templateUrl: './sms.component.html',
  styleUrls: ['./sms.component.scss']
})
export class SmsComponent implements OnInit {

  constructor(private store: Store) { }

  @Select(SMSHistoryState.smsHistoryLoading) smsHistoryLoading$: Observable<boolean>
  @Select(SMSHistoryState.smsHistory) smsHistory$: Observable<SmsMessageHistory[]>

  @Input() client: Client;

  ngOnInit() {
    this.fetchMessageHistory();
  }

  private fetchMessageHistory() {
    this.store.dispatch(new FetchSMSHistory(this.client.id));
  }

  messageSent() {
    this.fetchMessageHistory();
  }

}
