import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges, ChangeDetectionStrategy } from '@angular/core';
import { BulkMessage, BulkScheduledMessageRecipientStatus, Recipient } from '~/shared/models/communication/bulk-message.model';

@Component({
  selector: 'app-bulk-messaging-recipients-modal',
  templateUrl: './bulk-messaging-recipients-modal.component.html',
  styleUrls: ['./bulk-messaging-recipients-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BulkMessagingRecipientsModalComponent implements OnChanges {
  @Input() showRecipients: boolean = false;
  @Input() bulkMessage: BulkMessage;
  @Input() isHistoric: boolean = false;
  @Input() isLoading: boolean = false;
  @Input() showAddToNewMessage: boolean = false;
  @Output() close: EventEmitter<void> = new EventEmitter<void>();
  @Output() addRecipientsToBulkMessage: EventEmitter<void> = new EventEmitter<void>();

  
  recipientStatus = BulkScheduledMessageRecipientStatus;

  ngOnChanges(changes: SimpleChanges): void {
    if (this.bulkMessage?.recipients?.length) {
      this.bulkMessage.recipients = this.bulkMessage.recipients.map((recipient) => ({
        ...recipient,
        customerName: recipient.customerName || 'Unknown'
      }))
    }
  }

  sortFnName = (a: Recipient, b: Recipient) => a.customerName.localeCompare(b.customerName);
  sortFnMobileNumber = (a: Recipient, b: Recipient) => a.destination.localeCompare(b.destination);
  sortFnStatus = (a: Recipient, b: Recipient) => a.status.toString().localeCompare(b.status.toString());

  closeModal() {
    this.close.emit();
  }

  getSuccessfulRecipients() {
    return this.bulkMessage?.recipients.filter(r => r.status == BulkScheduledMessageRecipientStatus.Sent || r.status == BulkScheduledMessageRecipientStatus.PartialSuccess).length;
  }

  getfailedRecipients() {
    return this.bulkMessage?.recipients.filter(r => r.status == BulkScheduledMessageRecipientStatus.Failed).length;
  }

  addToBulkMessage() {
    this.addRecipientsToBulkMessage.emit();
  }

  exportRecipients() {
    const csvData = this.bulkMessage.recipients.map((r) => {
      return {
        'Name': r.customerName,
        // this is the only way to show numbers as strings in excel, will show in scientific notation otherwise
        'Mobile Number': `"=""${r.destination}"""`,
        'Status': BulkScheduledMessageRecipientStatus[r.status]
      }
    });
    const formattedHeaders = csvData.length ? Object.keys(csvData[0]).join(',') : [];
    const formattedRows = csvData.map((row) => Object.values(row).join(',')).join('\n'); 
    
    const csvContent = `${formattedHeaders}\n${formattedRows}`;
    this.exportToCsv(csvContent, 'bulk-comms-recipients.csv')
  }

  private exportToCsv(csvContent: string, fileName: string) {
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = window.URL.createObjectURL(blob);
  
    const link = document.createElement('a');
    link.href = url;
    link.download = fileName;
    link.click();
  
    window.URL.revokeObjectURL(url);
  }
}
