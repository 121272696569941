import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrderUpdateStatusModalComponent } from './order-update-status-modal.component';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { OrderMessageModule } from '../order-message/order-message.module';
import { FormsModule } from '@angular/forms';
import { NzSelectModule } from 'ng-zorro-antd/select';

@NgModule({
  declarations: [
    OrderUpdateStatusModalComponent
  ],
  imports: [
    CommonModule,
    NzModalModule,
    NzButtonModule,
    OrderMessageModule,
    FormsModule,
    NzSelectModule
  ],
  exports:[
    OrderUpdateStatusModalComponent
  ]
})
export class OrderUpdateStatusModule { }
