import { State, Action, StateContext, Selector, Store } from '@ngxs/store';
import {
  ManageMetaRequestAction,
  ManageMetaResponseAction,
  ManageMetaErrorAction,
  UpdateStartDateErrorAction,
  UpdateStartDateResponseAction,
  UpdateStartDateRequestAction,
  OpenManageMetaAction,
  CloseManageMetaAction,
  UpdateScriptNotifications,
  UpdateScriptNotificationsError,
  UpdateScriptNotificationsSuccess,
  ToggleAutoCommsModalAction,
  AddNewStockStartingPoint,
  AddNewStockStartingPointSuccess
} from './manage-meta.actions';
import { UpdateScriptActionsAction } from '../../state/script-actions.actions';
import { ScriptService } from '../../../../../shared/services/script.service';
import { mergeMap, catchError, tap, finalize } from 'rxjs/operators';
import { UpdateScriptAction, ScriptLoadingStartAction, ScriptLoadingStopAction } from '../../state/categories.actions';
import { AlertService } from '../../../../core/alert/alert.service';
import { ScriptActionsState } from '../../state/script-actions.state';
import { UpdateScriptNotificationsRequest } from '../../../../../shared/models/script/update-script-notifications-request.model';
import { Injectable } from '@angular/core';

export class ManageMetaStateModel {
  public show: boolean;
  public loadingMetaData: boolean;
  public loadingStartDate: boolean;
  public loadingNotifications: boolean;
  public showDisableAutoCommsModal: boolean;
}
@Injectable()
@State<ManageMetaStateModel>({
  name: 'manageMeta',
  defaults: {
    show: false,
    loadingMetaData: false,
    loadingStartDate: false,
    loadingNotifications: false,
    showDisableAutoCommsModal: false
  }
})
export class ManageMetaState {

  constructor(
    private scriptService: ScriptService,
    private store: Store,
    private alertService: AlertService
  ) { }

  @Selector()
  static loadingNotifications(state: ManageMetaStateModel) { return state.loadingNotifications }

  @Action(OpenManageMetaAction)
  open(ctx: StateContext<ManageMetaStateModel>) {
    ctx.patchState({ show: true });
  }

  @Action(ToggleAutoCommsModalAction)
  toggleAutoCommsModal(ctx: StateContext<ManageMetaStateModel>,
    { show }: ToggleAutoCommsModalAction) {
    ctx.patchState({ showDisableAutoCommsModal: show });
  }

  @Action(ManageMetaRequestAction)
  updateMeta(ctx: StateContext<ManageMetaStateModel>, action: ManageMetaRequestAction) {
    ctx.patchState({ loadingMetaData: true });
    this.startLoading(ctx, action.script);
    return this.scriptService.updateMeta(this.buildUpdateMetaRequest(action.script)).pipe(
      tap((response) => ctx.dispatch(new ManageMetaResponseAction(response))),
      catchError((error) => ctx.dispatch(new ManageMetaErrorAction(error, action.script)))
    );
  }

  @Action(UpdateStartDateRequestAction)
  updateStartDate(ctx: StateContext<ManageMetaStateModel>, action: UpdateStartDateRequestAction) {
    ctx.patchState({ loadingStartDate: true });
    this.startLoading(ctx, action.script);
    return this.scriptService.updateStartDate(this.buildUpdateStartDateRequest(action.script)).pipe(
      mergeMap((response) => ctx.dispatch(new UpdateStartDateResponseAction(response))),
      catchError((error) => ctx.dispatch(new UpdateStartDateErrorAction(error, action.script)))
    );
  }

  @Action([ManageMetaResponseAction, ManageMetaErrorAction])
  finishLoadingMeta(ctx: StateContext<ManageMetaStateModel>, action: ManageMetaResponseAction | ManageMetaErrorAction) {
    ctx.patchState({ loadingMetaData: false });
    if (action instanceof ManageMetaErrorAction) {
      this.stopLoading(ctx, action.script);
    }
  }

  @Action([UpdateStartDateErrorAction, UpdateStartDateResponseAction])
  finishLoadingStartDate(ctx: StateContext<ManageMetaStateModel>, action: UpdateStartDateResponseAction | UpdateStartDateErrorAction) {
    ctx.patchState({ loadingStartDate: false });
    if (action instanceof UpdateStartDateErrorAction) {
      this.stopLoading(ctx, action.script);
    }
  }

  @Action([ManageMetaResponseAction, UpdateStartDateResponseAction])
  handleResponse(ctx: StateContext<ManageMetaStateModel>, { script }: ManageMetaResponseAction | UpdateStartDateResponseAction) {
    return ctx.dispatch([new UpdateScriptAction(script), new UpdateScriptActionsAction(script)]);
  }

  @Action(CloseManageMetaAction)
  close(ctx: StateContext<ManageMetaStateModel>) {
    ctx.patchState({ show: false });
  }

  @Action(UpdateScriptNotifications)
  updateScriptNotifications(ctx: StateContext<ManageMetaStateModel>, { notificationsRequest }: UpdateScriptNotifications) {
    ctx.patchState({ loadingNotifications: true });
    return this.scriptService.updateScriptNotifications(notificationsRequest).pipe(
      tap(() => ctx.dispatch(new UpdateScriptNotificationsSuccess(notificationsRequest))),
      catchError(err => ctx.dispatch(new UpdateScriptNotificationsError(err))),
      finalize(() => ctx.patchState({ loadingNotifications: false }))
    )
  }

  @Action(AddNewStockStartingPoint)
  addNewStockStartingPoint(ctx: StateContext<ManageMetaStateModel>, { stockStartingPoint }: AddNewStockStartingPoint) {
    return this.scriptService.addStockStartingPoint(stockStartingPoint).pipe(
      tap(() => ctx.dispatch(new AddNewStockStartingPointSuccess(stockStartingPoint))),
      catchError(err => ctx.dispatch(new UpdateScriptNotificationsError(err))),
      finalize(() => ctx.patchState({ loadingNotifications: false }))
    )
  }

  @Action(AddNewStockStartingPointSuccess)
  addNewStockStartingPointSuccess(ctx: StateContext<ManageMetaStateModel>, { stockStartingPoint }: AddNewStockStartingPointSuccess) {
    let script = this.store.selectSnapshot(ScriptActionsState.script);
    script.cMeta.currentStockStartingPoint = stockStartingPoint;
    script.cMeta.stockStartingPoints.push(stockStartingPoint);
    ctx.dispatch(new UpdateScriptAction(script));
  }

  @Action(UpdateScriptNotificationsSuccess)
  updateScriptNotificationsSuccess(ctx: StateContext<ManageMetaStateModel>, { notificationsRequest }: UpdateScriptNotificationsSuccess) {
    let script = this.store.selectSnapshot(ScriptActionsState.script);
    script.cMeta = this.updateCMeta(script.cMeta, notificationsRequest);
    ctx.dispatch([new UpdateScriptAction(script),new UpdateScriptActionsAction(script)]);
  }

  @Action(UpdateScriptNotificationsError)
  updateScriptNotificationsError(ctx: StateContext<ManageMetaStateModel>, { error }: UpdateScriptNotificationsError) {
    this.alertService.error(JSON.stringify(error.error));
    ctx.patchState({ show: false });
  }

  private buildUpdateMetaRequest(drug: any) {
    return {
      metaId: drug.cMeta.id,
      requestable: drug.cMeta.requestable,
      isFlagged: drug.cMeta.isFlagged,
      categoryId: drug.cMeta.categoryId,
      dispenseBuffer: drug.cMeta.dispenseBuffer,
      dohTarget: drug.cMeta.dohTarget,
      onFile: drug.cMeta.onFile
    };
  }

  private buildUpdateStartDateRequest(drug: any) {
    const req = {
      metaId: drug.cMeta.id,
      date: null,
      xDisp: drug.startDate.xDisp,
      type: drug.startDate.type
    };

    if (!!drug.startDate.newDate) {
      const newDate = new Date(drug.startDate.newDate);
      const timeZoneOffset = newDate.getTimezoneOffset();
      newDate.setMinutes(newDate.getMinutes() - timeZoneOffset);
      req.date = newDate.toISOString();
    }

    return req;
  }

  private startLoading(ctx: StateContext<ManageMetaStateModel>, drug: any) {
    ctx.dispatch(new ScriptLoadingStartAction(drug));
  }

  private stopLoading(ctx: StateContext<ManageMetaStateModel>, drug: any) {
    ctx.dispatch(new ScriptLoadingStopAction(drug));
  }

  private updateCMeta(cMeta: any, notificationsRequest: UpdateScriptNotificationsRequest): any {
    cMeta.notificationsEnabled = notificationsRequest.notificationsEnabled;
    cMeta.lastRepeatDue = notificationsRequest.lastRepeatDue;
    cMeta.beforeNextRepeatDue = notificationsRequest.beforeNextRepeatDue;
    cMeta.medicationOverdue = notificationsRequest.medicationOverdue;
    cMeta.medicationOverdueOnLastRepeat = notificationsRequest.medicationOverdueOnLastRepeat;
    cMeta.scriptExpiring = notificationsRequest.scriptExpiring;
    cMeta.scriptOwing = notificationsRequest.scriptOwing;

    return cMeta;
  }
}
