import { State, Selector, Action, StateContext } from '@ngxs/store';
import { DAAConsentAndEligibilityFormModel } from '../../../../../../shared/models/six-cpa/DAA/daa-consent-and-eligibility-form.model';
import { SetDAAConsentAndEligibilityFormValidAction, SetTotalItemsAndCompletedItems, UpdateDAAConsentAndEligibilityForm } from './daa-consent-and-eligibility-form.actions';
import { Injectable } from '@angular/core';

export class DAAConsentAndEligibilityFormStateModel {
  daaConsentAndEligibilityForm: {
    model: Partial<DAAConsentAndEligibilityFormModel>
  };
  valid: boolean;
  totalItems: number;
  completedItems: number;
}
@Injectable()
@State<DAAConsentAndEligibilityFormStateModel>({
  name: 'daaConsentAndEligibilityForm',
  defaults: {
    daaConsentAndEligibilityForm: {
      model: undefined
    },
    valid: false,
    completedItems: 0,
    totalItems: 0
  }
})
export class DAAConsentAndEligibilityFormState {
  @Selector()
  static daaConsentAndEligibilityForm(state: DAAConsentAndEligibilityFormStateModel) { return state.daaConsentAndEligibilityForm.model; }

  @Selector()
  static totalItems(state: DAAConsentAndEligibilityFormStateModel) { return state.totalItems; }

  @Selector()
  static completedItems(state: DAAConsentAndEligibilityFormStateModel) { return state.completedItems; }

  @Action(SetDAAConsentAndEligibilityFormValidAction)
  setValid(ctx: StateContext<DAAConsentAndEligibilityFormStateModel>, action: SetDAAConsentAndEligibilityFormValidAction) {
    ctx.patchState({
      valid: action.valid
    });
  }

  @Action(SetTotalItemsAndCompletedItems)
  setProgress(ctx: StateContext<DAAConsentAndEligibilityFormStateModel>, action: SetTotalItemsAndCompletedItems) {
    ctx.patchState({
      totalItems: action.totalItems,
      completedItems: action.completedItems
    });
  }

  @Action(UpdateDAAConsentAndEligibilityForm)
  updateDAAConsentAndEligibilityForm(ctx: StateContext<DAAConsentAndEligibilityFormStateModel>, { form }: UpdateDAAConsentAndEligibilityForm) {
    ctx.patchState({
      daaConsentAndEligibilityForm: {
        model: form
      }
    });
  }
}
