<div class="modal">
  <div class="modal-body border__grey">
    <div class="col-md-12 patient-details">
      <div *ngIf="hasProfile" class="row">
        <span class="col-md-11">
          <h3>Update {{customerPersonalInfo.firstname | titlecase}}'s Profile Information</h3>
        </span>
        <span class="co-md-1 pointer" (click)="closeModal()"><i class="fas fa-times font__title-5"></i></span>
      </div>
    </div>
    <hr />
    <form [formGroup]="patientForm" (ngSubmit)="onSubmit(patientForm.value)">
      <div class="main">
        <app-loader [loadingMessage]="loadingMessage" *ngIf="isModalLoad"></app-loader>
        <div class="patient-details">

          <div class="row mb-4">
            <div class="col-md-6">
              <label class="font-weight-bold">How does {{customerPersonalInfo.firstname | titlecase}} wish to be
                alerted?
                <span class="text-danger" *ngIf="patientForm.get('contactMethod').invalid">*</span></label>
              <select (change)="changeContactMethod($event)" formControlName="contactMethod" g-xs="py2 pl1"
                class="form-control patient-details__select__group" name="contactMethod"
                placeholder="Select Contact Method">
                <option *ngFor="let method of customer.contactMethods" [value]="+method.key"
                  [hidden]="method.value=='Scrypt Messenger' || method.value == 'Push Notifications'">{{method.value}}
                </option>
              </select>
            </div>
            <div class="col-md-6" *ngIf="customer.customerProfile.type !== customerType.Lead">
              <label class="mb-4 font-weight-bold">How does {{customerPersonalInfo.firstname | titlecase}} receive their
                orders? <span class="text-danger" *ngIf="patientForm.get('orderMethod').invalid">*</span></label>
              <div class="flex">
                <label class="radio-inline mr-3">
                  <input class="mr-1" formControlName="orderMethod" type="radio" name="orderMethod" value="2" />
                  <span>Pickup</span>
                </label>
                <label class="radio-inline">
                  <input class="mr-1" formControlName="orderMethod" type="radio" name="orderMethod" value="1" />
                  <span>Delivery</span>
                </label>
              </div>
            </div>
          </div>
          <div g-xs="mt2">
            <label *ngIf="customer.customerProfile.type !== customerType.Lead" class="font-weight-bold">
              Scrypt Reminders</label>
            <label *ngIf="customer.customerProfile.type === customerType.Lead"
              class="font-weight-bold">Communication</label>
            <div class="ml-2" style="padding: 10px">
              <input type="checkbox" class="form-check-input" style="margin-top: 10px;" formControlName="isAutomation"
                (change)="onAutocommsChange()" />
              <span class="checkmark"></span>
              <label class="custom form-check-label"
                [ngClass]="patientForm.value.isAutomation ? 'comms-active' : 'comms-inactive'">
                Patient <span><strong>{{patientForm.value.isAutomation ? 'has' : 'has not'}}</strong></span> agreed to
                receive notifications
                <i
                  [ngClass]="!!patientForm.value.isAutomation ? 'fas fa-check alert-success': 'fas fa-times alert-danger'"></i>
              </label>
              <label *ngIf="patientForm.controls['isAutomation'].disabled">
                Select SMS, Email, or Scrypt Messenger as the alert method to enable.
              </label>
            </div>
            <ng-container>
              <div *ngIf="patientForm.value.isAutomation && !patientForm.value.repeatCycle">
                <table>
                  <tr clas="border">
                    <div class="form-group" g-xs="mt2">
                      <label class="ml-1 mr-3">Repeat Reminders</label>
                      <ui-switch class="float-right mr-1" size="small" (change)="updateRepeats()"
                        [checked]="patientForm.value.lastRepeatDue" formControlName="lastRepeatDue">
                      </ui-switch>
                    </div>
                  </tr>
                  <tr hidden clas="border">
                    <div class="form-group" g-xs="mt2">
                      <label class="ml-1 mr-3">Before Next Repeat Due</label>
                      <ui-switch class="float-right mr-1" size="small" [checked]="patientForm.value.beforeNextRepeatDue"
                        formControlName="beforeNextRepeatDue">
                      </ui-switch>
                    </div>
                  </tr>
                  <tr clas="border">
                    <div class="form-group" g-xs="mt2">
                      <label class="ml-1 mr-3">Medication Overdue Reminders</label>
                      <ui-switch class="float-right mr-1" (change)="updateMedicationOverdue()" size="small"
                        [checked]="patientForm.value.medicationOverdue" formControlName="medicationOverdue">
                      </ui-switch>
                    </div>
                  </tr>
                  <tr hidden clas="border">
                    <div class="form-group" g-xs="mt2">
                      <label class="ml-1 mr-3">Medication Overdue &amp; Last Repeat</label>
                      <ui-switch class="float-right mr-1" size="small"
                        [checked]="patientForm.value.medicationOverdueOnLastRepeat"
                        formControlName="medicationOverdueOnLastRepeat">
                      </ui-switch>
                    </div>
                  </tr>
                  <tr clas="border">
                    <div class="form-group" g-xs="mt2">
                      <label class="ml-1 mr-3">Script Owing or Expiring</label>
                      <ui-switch class="float-right mr-1" size="small" (change)="updateScriptReminders()"
                        [checked]="patientForm.value.scriptOwing" formControlName="scriptOwing">
                      </ui-switch>
                    </div>
                  </tr>
                  <tr hidden clas="border">
                    <div class="form-group" g-xs="mt2">
                      <label class="ml-1 mr-3">Script Expiring</label>
                      <ui-switch class="float-right mr-1" size="small" [checked]="patientForm.value.scriptExpiring"
                        formControlName="scriptExpiring">
                      </ui-switch>
                    </div>
                  </tr>
                </table>
              </div>
            </ng-container>
          </div>
          <ng-container *ngIf="(pharmacyComms$ |  async) as pharmacyComms">
            <div *ngIf="!pharmacyComms.communicationsEnabled && !!patientForm.value.isAutomation"
              class="alert alert-warning mt-2">
              Pharmacy Scrypt Reminders are currently turned OFF - please review in <a href="settings/communication"
                target="blank">Communication Settings</a>
              <br />
              <div *ngIf="refreshing" class="text-center">
                <div class="spinner-border text-info" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
              <small><a class="pointer" (click)="refreshPharmacyComms()">Check again <i
                    class="fal fa-sync"></i></a></small>
            </div>
          </ng-container>
          <div class="row mb-1">
            <div class="col-md-6">
              <label class="font-weight-bold">Configure Order Cycle</label>
              <div>
                <label class="custom">
                  I want to configure an order cycle
                  <input formControlName="repeatCycle" type="checkbox" (change)="updateAutoComms()" />
                  <span class="checkmark"></span>
                </label>
              </div>
            </div>
          </div>
          <div class="date-selection-container row px-2 py-1 mr-1"
            *ngIf="patientForm.get('repeatCycle').value === true">
            <div class="col">
              <span class="px-3">Every</span>
              <div class="flex">
                <input formControlName="intervalValue" style="width: 50px" type="number" min="0" max="365"
                  g-xs="py3 pl1 ml2" class="form-control display-inline" placeholder="5" />
                <select formControlName="repeatType" g-xs="py2 pl1 ml2"
                  class="form-control display-inline patient-details__select">
                  <option *ngFor="let key of repeatTypes" [value]="key" [label]="repeatType[key]"></option>
                </select>
              </div>
            </div>
            <div class="col">
              <span>Starting from</span>
              <input type="text" placeholder="From" class="form-control" formControlName="startDate" id="date-from"
                [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY' }" bsDatepicker placement="top">
            </div>
            <div class="col px-0 py-3">
              <label class="custom">
                Choose start date for me <small>(next medicine to run out)</small>
                <input type="checkbox" value="false" formControlName="chooseOrderDateForMe"
                  (change)="updateOrderCycleDate()" />
                <span class="checkmark"></span>
              </label>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="!hasDOB" class="alert alert-danger" style="padding: 5px;">
        <i class="fas fa-exclamation-triangle"></i> &nbsp;
        Please record a DOB for this customer to allow for full communications functionality
      </div>
      <div class="row ml-3">
        <button [disabled]="!isFormValid()" type="submit" class="btn__green patient-details__submit"
          g-xs="mt3">{{ hasProfile ? 'Update Profile' : 'Add to Scrypt' }}
          <i class="fas fa-check"></i>
        </button>
      </div>
    </form>
  </div>

</div>
<div class="modal-background">

</div>
