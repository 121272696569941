import { OrderRequestItem } from "@profile/order-requests/models/order-request-item.model";
import { OrderRequestItemType } from "@profile/order-requests/models/order.enums";
import { EScriptType } from "../models/dispense/dispenseItem.model";

export function detectEScript(content: string) {
  return content && content.includes('https://ausscripts');
}

export function extractEScript(content: string) {
  if(content && content.length > 0) {
    const tokenPrefix = '/scripts/';
    let eScript = content.slice(content.indexOf(tokenPrefix) + tokenPrefix.length);
    eScript = trimDirtyContent(eScript);
  
    return eScript;
  }
  return EScriptType.Unknown;
}

export function extractEScriptFromNote(content: string) {
  return content.substring(
    content.indexOf("*") + 1,
    content.lastIndexOf("*")
  );
}

export function trimDirtyContent(eScript: string) {
  if (eScript && eScript.includes('<')) {
    eScript = eScript.substring(0, eScript.indexOf('<'));
  }

  if (eScript && eScript.includes('>')) {
    eScript = eScript.substring(0, eScript.indexOf('>'));
  }

  if (eScript && eScript.includes('*')) {
    eScript = eScript.substring(0, eScript.indexOf('*'));
  }

  return eScript;
}

export function mapIdentifierType(type: OrderRequestItemType): EScriptType {
  switch (type) {
    case OrderRequestItemType.EScript: return EScriptType.EScript;
    case OrderRequestItemType.OnFileScript: return EScriptType.InsideRepeat;
    default: return EScriptType.Unknown;
  }
}

export function mapToken(type: OrderRequestItemType, item: OrderRequestItem): string {
  switch (type) {
    case OrderRequestItemType.EScript: return extractEScript(item.eScriptTokenUrl);
    case OrderRequestItemType.OnFileScript: return item.customerMedication?.dispenseRepeatNo;
    default: return EScriptType.Unknown;
  }
}
