import { NoteTypeEnum } from "../note/note-type.enum";

export class NoteFilter {
    constructor(
        public groupId?: number,
        public pageIndex: number = 1,
        public pageSize?: number,
        public query: string = '',
        public fromDate?: Date,
        public toDate?: Date,
        public noteType?: number,
        public noteTypes?: NoteTypeEnum[]
    ) { }
}

