import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AbstractBaseApiModule } from './abstract-base-api.module';

@NgModule({
    imports: [
        AbstractBaseApiModule,
        RouterModule
    ],
})
export class BaseApiModule {}
