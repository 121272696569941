import { PipeTransform, Pipe } from '@angular/core';
import { MessengerHistory } from '../../../../../shared/models/messageHistory.model';

@Pipe({
  name: 'orderByMessageDate'
})
export class OrderByMessageDatePipe implements PipeTransform {
  transform(messages: MessengerHistory[]): MessengerHistory[] {
    if(!messages) {
      return messages;
    }

    messages = messages.sort((x, y) => {
      return new Date(y.timestamp).getTime() - new Date(x.timestamp).getTime();
    })
    return messages;
  }

}
