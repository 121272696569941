import { State, Selector, Action, StateContext } from '@ngxs/store';
import {
  SubmitCIAction,
  SaveCIAction,
  ResetCIAction,
  SaveCIFormFailedAction,
  SaveCISuccessAction as SaveCIFormSuccessAction,
  GetCIForm,
  GetCIFormSuccess,
  GetCIFormError,
  UpdateCIForm,
  SubmitExisitingCI,
  ResetCIToDefaults,
  ShowInvalidCIForm
} from './clinical-intervention.actions';
import { ClinicalInterventionService } from '../services/clinical-intervention.service';
import { switchMap, catchError, finalize } from 'rxjs/operators';
import { UpdateClassificationAndRecommendationsForm } from '../../classification-and-recommendations/state/classification-and-recommendations.actions';
import { UpdateAdditionalDetailsForm } from '../../additional-details/state/additional-details.actions';
import { UpdateMedicationProfileList } from '../../medication-profile/state/medication-profile.actions';
import { ToggleSixCpaAction, SetSelectedFormType, CloseResetFormModal, SetSixCpaFormSummary } from '../../state/six-cpa.actions';
import { AlertService } from '../../../../core/alert/alert.service';
import { SetSixCpaSuccessMessages, SetSixCpaErrors, ClearSixCpaCommunications, SetSixCpaInfo } from '../../six-cpa-user-communications/state/six-cpa-user-communications.actions';
import { Injectable } from '@angular/core';
import { SaveOrUpdateCIAction } from './clinical-intervention-save.actions';
import { closeConfirmationActions } from '../../state/six-cpa-toggles-list';
import { GetSixCpaFormsFromService } from '../../../profile-services/state/profile-services.actions';
import { SixCpaFormTypes } from '../../six-cpa-form-types.enum';
import { ResetMedicationProfileIdsAction } from '../../medication-profile/state/medication-profile.actions';
import { SixCpaResponse } from '../../../profile-services/state/profile-services.state';

export class ClinicalInterventionStateModel {
  clinicalInterventionId: number;
  sixCpaFormId: number;
  classificationAndRecommendationsFormId: number;
  additionalDetailsFormId: number;
  saving: boolean;
  showInvalid: boolean;
}
@Injectable()
@State<ClinicalInterventionStateModel>({
  name: 'clinicalIntervention',
  defaults: {
    clinicalInterventionId: null,
    sixCpaFormId: null,
    classificationAndRecommendationsFormId: null,
    additionalDetailsFormId: null,
    saving: false,
    showInvalid: false
  }
})
export class ClinicalInterventionState {
  constructor(private clinicalInterventionService: ClinicalInterventionService, private alertService: AlertService) { }

  @Selector()
  static saving(state: ClinicalInterventionStateModel) { return state.saving; }

  @Selector()
  static sixCpaFormId(state: ClinicalInterventionStateModel) { return state.sixCpaFormId; }

  @Selector()
  static showInvalid(state: ClinicalInterventionStateModel) { return state.showInvalid; }

  @Action(SaveCIAction)
  saveCI(ctx: StateContext<ClinicalInterventionStateModel>, { clientId, formData }: SaveCIAction) {
    ctx.patchState({ saving: true });
    return this.clinicalInterventionService.postSave(clientId, formData).pipe(
      switchMap(response => ctx.dispatch(new SaveCIFormSuccessAction(response))),
      catchError(error => ctx.dispatch(new SaveCIFormFailedAction(error))),
      finalize(() => ctx.patchState({ saving: false })));
  }

  @Action(UpdateCIForm)
  updateCI(ctx: StateContext<ClinicalInterventionStateModel>, { clientId, formData }: UpdateCIForm) {
    ctx.patchState({ saving: true });
    return this.clinicalInterventionService.putUpdate(clientId, formData).pipe(
      switchMap(response => ctx.dispatch(new SaveCIFormSuccessAction(response))),
      catchError(error => ctx.dispatch(new SaveCIFormFailedAction(error))),
      finalize(() => ctx.patchState({ saving: false })));
  }

  @Action(SaveCIFormSuccessAction)
  saveCISuccess(ctx: StateContext<ClinicalInterventionStateModel>, { formData }: SaveCIFormSuccessAction) {
    const { id, sixCpaFormId, classificationAndRecommendationsFormId, additionalDetailsFormId, hasSubmitted } = formData;
    ctx.patchState({
      clinicalInterventionId: id,
      sixCpaFormId,
      classificationAndRecommendationsFormId,
      additionalDetailsFormId
    });
    
    this.alertService.success('Clinical Intervention form successfully saved to Scrypt.');
    ctx.dispatch([
      new SetSixCpaSuccessMessages(['Clinical Intervention form successfully saved to Scrypt.']),
      new SetSixCpaFormSummary({
        id: sixCpaFormId,
        formType: SixCpaFormTypes.SickLeaveCertificate,
        hasSubmitted: hasSubmitted
      } as SixCpaResponse)
    ]);
    ctx.dispatch(new GetSixCpaFormsFromService(null));
  }

  @Action(SaveCIFormFailedAction)
  saveCIFailed(ctx: StateContext<ClinicalInterventionStateModel>, { error }: SaveCIFormFailedAction) {
    this.alertService.error(error);
    ctx.dispatch(new SetSixCpaErrors([error]));
  }

  @Action(SubmitCIAction)
  submitCI(ctx: StateContext<ClinicalInterventionStateModel>, { clientId, formData }: SubmitCIAction) {
    ctx.patchState({ saving: true });
    return this.clinicalInterventionService.postSubmit(clientId, formData).pipe(
      switchMap(response => ctx.dispatch(new SaveCIFormSuccessAction(response))),
      catchError(error => ctx.dispatch([new SaveCIFormFailedAction(error), new SetSixCpaInfo(["We were unable to submit the claim so we've saved it as a draft."]), new SaveOrUpdateCIAction()])),
      finalize(() => {
        ctx.patchState({ saving: false });
      }));
  }

  @Action(SubmitExisitingCI)
  submitExisitingMedsCheck(ctx: StateContext<ClinicalInterventionStateModel>, { clientId, formData }: SubmitExisitingCI) {
    ctx.patchState({ saving: true });
    return this.clinicalInterventionService.putSubmit(clientId, formData).pipe(
      switchMap(response => ctx.dispatch(new SaveCIFormSuccessAction(response))),
      catchError(error => ctx.dispatch([new SaveCIFormFailedAction(error), new SetSixCpaInfo(["We were unable to submit the claim so we've saved it as a draft."]), new SaveOrUpdateCIAction()])),
      finalize(() => {
        ctx.patchState({ saving: false });
      }));
  }

  @Action(ResetCIAction)
  resetCI(ctx: StateContext<ClinicalInterventionStateModel>, {onlyClearFormIds}: ResetCIAction) {
    ctx.dispatch(new ResetCIToDefaults(onlyClearFormIds))
  }

  @Action(ResetCIToDefaults)
  resetCIToDefaults(ctx: StateContext<ClinicalInterventionStateModel>, {onlyClearFormIds}: ResetCIToDefaults) {
    ctx.patchState({
      clinicalInterventionId: null,
      sixCpaFormId: null,
      classificationAndRecommendationsFormId: null,
      additionalDetailsFormId: null,
      saving: false
    });
    ctx.dispatch(new ResetMedicationProfileIdsAction());
    if (!onlyClearFormIds) {
      ctx.dispatch(new ToggleSixCpaAction('out'));
    }
    ctx.dispatch([
      new ClearSixCpaCommunications(), 
      new CloseResetFormModal()
    ])
  }

  @Action(GetCIForm)
  GetCIForm(ctx: StateContext<ClinicalInterventionStateModel>, action: GetCIForm) {
    return this.clinicalInterventionService.getCIForm(action.clientId, action.sixCpaFormId)
      .pipe(switchMap(response => ctx.dispatch([new GetCIFormSuccess(response), new ToggleSixCpaAction('in')])),
        catchError(err => ctx.dispatch(new GetCIFormError(err))));
  }

  @Action(GetCIFormSuccess)
  GetCIFormSuccess(ctx: StateContext<ClinicalInterventionStateModel>, { cIForm }: GetCIFormSuccess) {
    ctx.patchState({
      clinicalInterventionId: cIForm.id,
      sixCpaFormId: cIForm.sixCpaFormId,
      classificationAndRecommendationsFormId: cIForm.classificationAndRecommendationsFormId,
      additionalDetailsFormId: cIForm.additionalDetailsFormId
    });

    ctx.dispatch(closeConfirmationActions);
    
    ctx.dispatch([
      new UpdateClassificationAndRecommendationsForm(cIForm.classificationAndRecommendationsForm),
      new UpdateAdditionalDetailsForm(cIForm.additionalDetailsForm),
      new UpdateMedicationProfileList(cIForm.sixCpaMedications),
      new SetSelectedFormType(SixCpaFormTypes.ClinicalIntervention)
    ]);
  }

  @Action(GetCIFormError)
  GetCIFormError(ctx: StateContext<ClinicalInterventionStateModel>, action: GetCIFormError) {
    console.log(action.error);
  }

  @Action(ShowInvalidCIForm)
  showInvalidSickLeaveForm(ctx: StateContext<ClinicalInterventionStateModel>) {
    ctx.patchState({
      showInvalid: true
    });
  }
}
