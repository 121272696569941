import { NgModule } from '@angular/core';
import { TimezoneModule } from '~/modules/core/timezone/timezone.module';
import { CrmNotificationDateComponent } from './crm-notification-date.component';

@NgModule({
    imports: [TimezoneModule],
    declarations: [CrmNotificationDateComponent],
    exports: [CrmNotificationDateComponent]
})
export class CrmNotificationDateModule {}
