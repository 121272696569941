import { Component, OnInit } from '@angular/core';
import { SSMedicationInformationFormState, SSMedicationInformationFormStateModel } from '../ss-medication-information-form/state/ss-medication-information-form.state';
import { Observable } from 'rxjs';
import { Select } from '@ngxs/store';

@Component({
  selector: 'app-ss-medication-information-form-list-item',
  templateUrl: './ss-medication-information-form-list-item.component.html'
})
export class SSMedicationInformationFormListItemComponent implements OnInit {
  @Select(SSMedicationInformationFormState) formState$: Observable<SSMedicationInformationFormStateModel>;
  constructor() { }

  ngOnInit() {
  }

}
