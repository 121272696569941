import { ZClientDispenseInfo } from "../models/z-client-dispense-info.model";
import { ZScript } from "../models/z-script";

export enum ZApiActions {
  SCRIPT_QUERY = '[ZApi] SCRIPT_QUERY',
  SCRIPT_QUERY_SUCCESS = '[ZApi] SCRIPT_QUERY_SUCCESS',
  SCRIPT_QUERY_ERROR = '[ZApi] SCRIPT_QUERY_ERROR',

  CLIENT_DISPENSE_INFO = '[ZApi] CLIENT_DISPENSE_INFO',
  CLIENT_DISPENSE_INFO_SUCCESS = '[ZApi] CLIENT_DISPENSE_INFO_SUCCESS',
  CLIENT_DISPENSE_INFO_ERROR = '[ZApi] CLIENT_DISPENSE_INFO_ERROR',
}

export class ZApiScriptQueryAction {
  static readonly type = ZApiActions.SCRIPT_QUERY;
  constructor(public scriptNos: string[]) { }
}

export class ZApiScriptQuerySuccessAction {
  static readonly type = ZApiActions.SCRIPT_QUERY_SUCCESS;
  constructor(public scripts: ZScript[]) { }
}

export class ZApiScriptQueryErrorAction {
  static readonly type = ZApiActions.SCRIPT_QUERY_ERROR;
  constructor(public error: any) { }
}

export class ZApiClientDispenseInfoAction {
  static readonly type = ZApiActions.CLIENT_DISPENSE_INFO;
  constructor(public searchKeyword: string) { }
}

export class ZApiClientDispenseInfoSuccessAction {
  static readonly type = ZApiActions.CLIENT_DISPENSE_INFO_SUCCESS;
  constructor(public clients: ZClientDispenseInfo[]) { }
}

export class ZApiClientDispenseInfoErrorAction {
  static readonly type = ZApiActions.CLIENT_DISPENSE_INFO_ERROR;
  constructor(public error: any) { }
}
