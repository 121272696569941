import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-csv-export-page',
  templateUrl: './csv-export-page.component.html'
})
export class CsvExportPageComponent implements OnInit {
  isPageLoaded =  false;
  
  constructor() { }

  ngOnInit() {
    this.isPageLoaded =  true;
  }

}
