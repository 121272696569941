import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SixCpaFormListItemTextComponent } from './six-cpa-form-list-item-text.component';

@NgModule({
  declarations: [
    SixCpaFormListItemTextComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    SixCpaFormListItemTextComponent
  ]
})
export class SixCpaFormListItemTextModule { }
