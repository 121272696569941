import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DocumentComponent } from './document.component';
import { AuthComponent } from './auth/auth.component';
import { AuthResolver } from '../core/auth/auth.resolver';
import { DocumentResolver } from './document.resolver';

const routes: Routes = [
  {
    path: '', resolve: [AuthResolver, DocumentResolver], component: DocumentComponent, children: [
      { path: 'auth', component: AuthComponent },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DocumentRoutingModule { }
