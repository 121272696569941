<ng-container>
  <app-sick-leave-patient-details-list-item></app-sick-leave-patient-details-list-item>
  <app-sick-leave-consent-and-eligibility-list-item></app-sick-leave-consent-and-eligibility-list-item>
  <app-sick-leave-certificate-type-list-item></app-sick-leave-certificate-type-list-item>
  <app-sick-leave-consulation-list-item></app-sick-leave-consulation-list-item>
  <app-sick-leave-pharmacist-declaration-list-item></app-sick-leave-pharmacist-declaration-list-item>
  
  <ng-container *ngIf="(sixCpaState$ | async) as sixCpaState">

    <div class="pt-4" *ngFor="let saving of [saving$ | async]">
      

      <ng-container>
        <app-loader class="loading" *ngIf="saving"></app-loader>
        <button *ngIf="!sixCpaState.readOnly" class="button submit mr-3" [disabled]="saving" (click)="submitSickLeave()">Complete Sick Leave
          Certificate</button>
        <button class="button save mr-3" [disabled]="saving" (click)="saveSickLeave()">Save Sick Leave Certificate</button>
        <span class="mr-3" (click)="openConfirmResetModal()">Reset Form</span>
      </ng-container>
      <ng-container *ngIf="sixCpaState.readOnly">
        <button class="button submit mr-3" [disabled]="saving" (click)="viewConfirmation()">View Report</button>
      </ng-container>
    </div>
    
    <app-confirm-reset *ngIf="sixCpaState.showResetModal" (closeEvent)="closeConfirmResetModal()"
      (submitEvent)="resetSickLeaveFormList()">
    </app-confirm-reset>
  </ng-container>

</ng-container>
