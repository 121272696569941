<form [formGroup]="daaConsentAndEligibilityForm" ngxsForm="daaConsentAndEligibilityForm.daaConsentAndEligibilityForm"
  class="p-4">
  <div class="form-group pb-1">
    <label class="input-label mb-0">Pharmacy holds written consent for service provision</label><br />
    <app-radio-highlight controlName="serviceConsentYes"
      [hasError]="formControls.writtenConsentForServiceProvision.invalid && (showInvalid$ | async)"
      [isHighlighted]="daaConsentAndEligibilityForm.value.writtenConsentForServiceProvision == true">
      <input class="form-check-input" type="radio" name="writtenConsentForServiceProvision" id="serviceConsentYes"
        formControlName="writtenConsentForServiceProvision" [value]="true">
      <label class="form-check-label" for="serviceConsentYes">Yes</label>
    </app-radio-highlight>
    <app-radio-highlight controlName="serviceConsentNo"
      [hasError]="formControls.writtenConsentForServiceProvision.invalid && (showInvalid$ | async)"
      [isHighlighted]="daaConsentAndEligibilityForm.value.writtenConsentForServiceProvision == false">
      <input class="form-check-input" type="radio" name="writtenConsentForServiceProvision" id="serviceConsentNo"
        formControlName="writtenConsentForServiceProvision" [value]="false" checked>
      <label class="form-check-label" for="serviceConsentNo">No</label>
    </app-radio-highlight>
  </div>
  <div class="form-group pb-1">
    <label class="input-label mb-0">Pharmacy holds written consent for provision of evaluation data</label><br />
    <a class="d-block" (click)="openDAAConsentForm()">Click here to view the patient consent form.</a>
    <app-radio-highlight controlName="provisionConsentYes"
      [hasError]="formControls.writtenConsentForProvisionOfData.invalid && (showInvalid$ | async)"
      [isHighlighted]="daaConsentAndEligibilityForm.value.writtenConsentForProvisionOfData == true">
      <input class="form-check-input" type="radio" name="writtenConsentForProvisionOfData" id="provisionConsentYes"
        formControlName="writtenConsentForProvisionOfData" [value]="true">
      <label class="form-check-label" for="provisionConsentYes">Yes</label>
    </app-radio-highlight>
    <app-radio-highlight controlName="provisionConsentNo"
      [hasError]="formControls.writtenConsentForProvisionOfData.invalid && (showInvalid$ | async)"
      [isHighlighted]="daaConsentAndEligibilityForm.value.writtenConsentForProvisionOfData == false">
      <input class="form-check-input" type="radio" name="writtenConsentForProvisionOfData" id="provisionConsentNo"
        formControlName="writtenConsentForProvisionOfData" [value]="false" checked>
      <label class="form-check-label" for="provisionConsentNo">No</label>
    </app-radio-highlight>
  </div>
</form>