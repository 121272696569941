import { InterventionClassification } from './FormKeyValuePairs';

export interface ClassificationAndRecommendationsFormModel {
  id: number | null;
  recomendations: Recommendations;
  interventionClassification: InterventionClassification;
  interventionCategory: InterventionCategory;
  clinicalRecommendationsNotes: string;
}

export enum InterventionCategory {
  DrugSelection,
  OverOrUnderDose,
  Compliance,
  UnderTreated,
  Monitoring,
  EducationOrInformation,
  NotClassifiable,
  ToxicityOrAdverseReaction
}

export interface Recommendations {
  doseIncrease: boolean;
  doseDecrease: boolean;
  drugChange: boolean;
  drugFormulationChange: boolean;
  drugBrandChange: boolean;
  doseFrequencyScheduleChange: boolean;
  prescriptionNotDispensed: boolean;
  otherChangesToTherapy: boolean;
  referToPrescriber: boolean;
  referToHospital: boolean;
  referForMedicationReview: boolean;
  otherReferralRequired: boolean;
  educationOrCounsellingSession: boolean;
  writtenSummaryOfMedications: boolean;
  commenceDoseAdministrationAid: boolean;
  otherWrittenInformation: boolean;
  monitoringLaboratory: boolean;
  monitoringNonLaboratory: boolean;
  noRecommendationNecessary: boolean;
}
