<ng-container>
  <app-header></app-header>
  <section class="body-content">
      <div class="main" g-xs="pt5">
          <app-breadcrumbs [links]="links"></app-breadcrumbs>
          <section>
              <app-alert></app-alert>
              <div>
                  <router-outlet></router-outlet>
              </div>
          </section>
      </div>
  </section>
  <app-footer></app-footer>
</ng-container>