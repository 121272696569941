import { State, Action, StateContext, Selector, Store } from '@ngxs/store';
import {
  UpdateSixCpaFormFilter,
  GetSixCpaFormsFromService,
  SetSixCpaFormsList,
} from './profile-services.actions';
import { SixCpaFormsService } from '../../../../shared/services/six-cpa-forms.service';
import { switchMap, tap } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { MedsCheckType } from '../../six-cpa/meds-check/state/meds-check-form-list.state';
import { HMRFormType } from '../../six-cpa/HMR/hmr-form-list/state/hmr-form.state';
import { Injectable } from '@angular/core';
import { SixCpaFormTypes } from '../../six-cpa/six-cpa-form-types.enum';
import { ProfileState } from '../../../core/profile/state/profile.state';
import { ToggleFormLoading } from './profile-services.actions';
export class ProfileServicesStateModel {
  public sixCpaFormsList: SixCpaResponse[];
  public currentFilter: ServicesFilter;
}

export enum ServicesFilter {
  All,
  MedsCheck,
  ClinicalIntervention,
  DAA,
  StagedSupply,
  HMR,
  SickLeaveCertificate,
  HMS,
}

export enum PPAClaimType {
  MedsCheck,
  DiabetesMedsCheck,
  HMR,
}

export class SixCpaResponse {
  id?: any;
  claimId?: number;
  createdUTC: Date;
  createdBy: string;
  formType: SixCpaFormTypes;
  hasSubmitted?: boolean;
  submissionFailed?: boolean;
  tenantName: string;
  subType?: MedsCheckType | HMRFormType | null;
  ppaClaimType?: PPAClaimType;
  loading?: boolean;
}

@Injectable()
@State<ProfileServicesStateModel>({
  name: 'profileServices',
  defaults: {
    sixCpaFormsList: [],
    currentFilter: ServicesFilter.All,
  },
})
export class ProfileServicesState {
  constructor(
    private sixCpaFormsService: SixCpaFormsService,
    private store: Store
  ) {}

  @Selector()
  static sixCpaForms(state: ProfileServicesStateModel) {
    return state.sixCpaFormsList;
  }

  @Selector()
  static currentFilter(state: ProfileServicesStateModel) {
    return state.currentFilter;
  }

  @Selector()
  static filteredSixCpaForms(state: ProfileServicesStateModel) {
    return state.sixCpaFormsList.filter((form) =>
      state.currentFilter === ServicesFilter.All
        ? true
        : this.mapFormType(form.formType) === state.currentFilter
    );
  }

  @Action(UpdateSixCpaFormFilter)
  filterForms(
    ctx: StateContext<ProfileServicesStateModel>,
    action: UpdateSixCpaFormFilter
  ) {
    ctx.patchState({
      currentFilter: action.filter,
    });
  }

  @Action(GetSixCpaFormsFromService)
  getAllSixCpaForms(
    ctx: StateContext<ProfileServicesStateModel>,
    action: GetSixCpaFormsFromService
  ) {
    let clientId = action.clientId;
    if (clientId === null) {
      clientId = this.store.selectSnapshot(ProfileState.clientId);
    }

    return this.sixCpaFormsService
      .getSixCpaFormsForClient(clientId)
      .pipe(
        switchMap((response) => ctx.dispatch(new SetSixCpaFormsList(response)))
      );
  }

  @Action(ToggleFormLoading)
  toggleFormLoading(
    ctx: StateContext<ProfileServicesStateModel>,
    { id, loading }: ToggleFormLoading
  ) {
    const state = ctx.getState();
    ctx.patchState({
      sixCpaFormsList: state.sixCpaFormsList.map((form) =>
        form.id === id ? { ...form, loading: loading } : form
      ),
    });
  }

  @Action(SetSixCpaFormsList)
  setFormsList(
    ctx: StateContext<ProfileServicesStateModel>,
    action: SetSixCpaFormsList
  ) {
    ctx.patchState({
      sixCpaFormsList: action.forms,
    });
  }

  private static mapFormType(formType: SixCpaFormTypes) {
    switch (formType) {
      case SixCpaFormTypes.MedsCheck: {
        return ServicesFilter.MedsCheck;
      }
      case SixCpaFormTypes.ClinicalIntervention: {
        return ServicesFilter.ClinicalIntervention;
      }
      case SixCpaFormTypes.DAA: {
        return ServicesFilter.DAA;
      }
      case SixCpaFormTypes.StagedSupply: {
        return ServicesFilter.StagedSupply;
      }
      case SixCpaFormTypes.HMR: {
        return ServicesFilter.HMR;
      }
      case SixCpaFormTypes.SickLeaveCertificate: {
        return ServicesFilter.SickLeaveCertificate;
      }
      default: {
        throwError;
      }
    }
  }
}
