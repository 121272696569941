export enum MarketingType {
  HvcSendInitialSms = 1,
  NotHvcSendInitialSms = 2,
  CreatePhoneCallNote = 3,
  SendNoReplyEmailToNonHvc = 4,
  HvcSendRefillSms = 5,
  NotHvcSendRefillSms = 6,
  SendFollowUpMail = 7,
  SendFollowUpEmail = 8,
  OrderPickup = 9,
  OrderDelivery = 10,
  UserConfigurable = 11,
  CustomerOrderSms = 12,
  DoctorReportTemplate = 13,
  ScriptRepeat =  14
}

