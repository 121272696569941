import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import * as moment from 'moment';
import { map } from 'rxjs/operators';
import { ClientRepository } from '~/modules/core/profile/client.repository';
import { getTenantTimezoneDateString } from '~/modules/core/timezone/logic/timezone-tenant.logic';
import { Customer } from '~/shared/models/dashboard/customer.model';

@Component({
  selector: 'app-recent-customers-page',
  templateUrl: './recent-customers-page.component.html',
  styleUrls: ['./recent-customers-page.component.scss']
})
export class RecentCustomersPageComponent implements OnInit {

  customersLoaded = 50;
  isCustomersLoaded = false;
  customerList = { list: [], headers: [] };
  private _customerList = [];
  customerAmounts = [50, 100, 200];

  constructor(
    private router: Router,
    private clientRepository: ClientRepository,
    private store: Store
  ) { }

  ngOnInit() {
    this.customersTable();
  }

  customersTable() {
    this.isCustomersLoaded = false;
    this.clientRepository.getCustomers(this.customersLoaded)
      .pipe(
        map((customers: Customer[]) => (
          {
            list: customers.map((item: Customer) => ({
              Customer: this.getCustomerStatus(item),
              Date: { value: getTenantTimezoneDateString(this.store, item.createDate, 'DD/MM/YYYY') },
              Raw: item
            })),
            headers: ['Customer', 'Date']
          })),
      )
      .subscribe(
        (data: any) => {
          const { list, headers } = data;
          list.sort((a, b) => (moment(b.Raw.createDate).diff(moment(a.Raw.createDate))));
          this.customerList.list = list;
          this._customerList = list;
          this.customerList.headers = headers;
          this.isCustomersLoaded = true;
        }
      );

  }

  handleCustomerFilter(filterText) {
    const newList = this._customerList;

    this.customerList.list = newList.filter(item =>
      item.Date.value.toLowerCase().includes(filterText.toLowerCase()) ||
      item.Customer.value.toLowerCase().includes(filterText.toLowerCase())
    ).sort((a, b) => (moment(b.Raw.createDate).diff(moment(a.Raw.createDate))));
  }

  handleCustomerColumnClick(data) {
    const { item } = data;
    this.router.navigate(['profile', item.Raw.clientId, 'categories']);
  }

  getCustomerStatus(item: Customer) {
    let status = 'bg__red';
    if (item.type === 'DAA' || item.type === 'SOF') {
      status = 'bg__green';
    }
    else if (item.type === 'LEAD') {
      status = 'bg__orange';
    }
    const res = {
      value: `${item.name} ${item.surname}`,
      status: status
    };
    return res;
  }
}
