import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HMRPatientDetailsFormListItemComponent } from './hmr-patient-details-form-list-item.component';
import { SixCpaFormListItemChecklistModule } from '../../six-cpa-form-list-item-checklist/six-cpa-form-list-item-checklist.module';
import { HMRPatientDetailsFormModule } from '../hmr-patient-details-form/hmr-patient-details-form.module';

@NgModule({
  declarations: [HMRPatientDetailsFormListItemComponent],
  imports: [
    CommonModule,
    SixCpaFormListItemChecklistModule,
    HMRPatientDetailsFormModule
  ],
  exports: [HMRPatientDetailsFormListItemComponent]
})
export class HMRPatientDetailsFormListItemModule { }
