import { State, Action, StateContext, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { SaveOrUpdateSickLeaveAction } from './sick-leave-save.actions';
import { SickLeaveFormsService } from './sick-leave-forms.service';
import { map, tap, withLatestFrom } from 'rxjs/operators';
import { SaveExisitingSickLeave, SaveNewSickLeave } from './sick-leave.actions';
import { ProfileState } from '@base/modules/core/profile/state/profile.state';

export class SickLeaveSaveStateModel {
}
@Injectable()
@State<SickLeaveSaveStateModel>({
  name: 'sickLeaveSave',
  defaults: {
  }
})
export class SickLeaveSaveState {
  constructor(private store: Store) { }

  @Action(SaveOrUpdateSickLeaveAction)
  saveOrUpdateSickLeave(ctx: StateContext<SickLeaveSaveStateModel>, action: SaveOrUpdateSickLeaveAction) {
    return this.store.selectOnce(SickLeaveFormsService.getSickLeaveRequest).pipe(
        withLatestFrom(this.store.select(ProfileState.clientId)),
        map(([formData, clientId]) =>
          formData && formData.sixCpaFormId !== null
            ? new SaveExisitingSickLeave(clientId, formData)
            : new SaveNewSickLeave(clientId, formData)),
        tap(dispatch => ctx.dispatch(dispatch))
        );
  }

}
