import { ScriptHistory } from '../../../../../shared/models/script/script-history.model';

export enum ScriptHistoryActions {
  TOGGLE_MODAL = '[ScriptHistory] TOGGLE_MODAL',
  SET_DRUG = '[ScriptHistory] SET_DRUG',
  TOGGLE_SCRIPT_AVAILABILITY = '[ScriptHistory] TOGGLE_SCRIPT_AVAILABILITY',
  TOGGLE_SCRIPT_AVAILABILITY_SUCCESS = '[ScriptHistory] TOGGLE_SCRIPT_AVAILABILITY_SUCCESS',
  TOGGLE_SCRIPT_AVAILABILITY_ERROR = '[ScriptHistory] TOGGLE_SCRIPT_AVAILABILITY_ERROR'
}

export class ToggleScriptHistoryModal {
  static readonly type = ScriptHistoryActions.TOGGLE_MODAL;
  constructor(public show: boolean) { }
}

export class SetDrugForScriptHistory {
  static readonly type = ScriptHistoryActions.SET_DRUG;
  constructor(public drug: any) { }
}

export class ToggleScriptAvailability {
  static readonly type = ScriptHistoryActions.TOGGLE_SCRIPT_AVAILABILITY;
  constructor(public script: ScriptHistory) { }
}

export class ToggleScriptAvailabilitySuccess {
  static readonly type = ScriptHistoryActions.TOGGLE_SCRIPT_AVAILABILITY_SUCCESS;
  constructor() { }
}

export class ToggleScriptAvailabilityError{
  static readonly type = ScriptHistoryActions.TOGGLE_SCRIPT_AVAILABILITY_ERROR;
  constructor(public error: any) { }
}
