<form [formGroup]="sickLeavePatientDetailsForm" ngxsForm="sickLeavePatientDetails.sickLeavePatientDetailsForm" class="p-4">
    <div class="form-group pb-1">
        <label for="firstName" class="input-label">Patients First Name</label>
        <input type="text" formControlName="firstName" class="form-control form-control-lg w-50"
            [class.has-error]="formControls.firstName.invalid && (showInvalid$ | async)" id="firstName"
            placeholder="Patients First Name" required>
    </div>
    <div class="form-group pb-1">
        <label for="surname" class="input-label">Patients Surname</label>
        <input type="text" formControlName="surname" class="form-control form-control-lg w-50"
            [class.has-error]="formControls.surname.invalid && (showInvalid$ | async)" id="surname"
            placeholder="Patients Surname" required>
    </div>
    <div class="form-group pb-1">
        <label for="dob" class="input-label">Patients Date of Birth</label>
        <input type="text" class="form-control form-control-lg w-25" value="sickLeavePatientDetailsForm.value.dob | date: 'DD-MM-YYYY'"
            formControlName="dob" id="dob" [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY' }"
            [class.has-error]="formControls.dob.invalid && (showInvalid$ | async)"
            placeholder="dd-mm-yyyy" bsDatepicker>
    </div>
    <div class="form-group pb-1">
        <label for="address" class="input-label">Patient Address</label>
        <input type="text" formControlName="address" class="form-control form-control-lg w-50"
            [class.has-error]="formControls.address.invalid && (showInvalid$ | async)" id="address"
            placeholder="Patient Address" required>
    </div>
</form>