import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScriptService } from '../../../../shared/services/script.service';
import { DosageHistoryComponent } from './dosage-history.component';
import { NgxsModule } from '@ngxs/store';
import { DosageHistoryState } from './state/dosage-history.state';
import { TimezoneModule } from '../../../core/timezone/timezone.module';
import { TenantDatePipe } from '../../../core/timezone/pipes/tenant-date.pipe';
import { ScryptInsightModule } from '../../../../shared/scrypt-insight/scrypt-insight.module';
import { ActiveStockStartingPointPipe } from './pipes/order-by-active-ssp.pipe';

@NgModule({
  declarations: [
    DosageHistoryComponent,
    ActiveStockStartingPointPipe
  ],
  providers: [
    ScriptService,
    TenantDatePipe
  ],
  imports: [
    TimezoneModule,
    CommonModule,
    NgxsModule.forFeature([DosageHistoryState]),
    ScryptInsightModule
  ],
  exports: [
    DosageHistoryComponent
  ]
})
export class DosageHistoryModule { }
