import { State, Action, StateContext, Selector } from '@ngxs/store';
import { GetDoctorReportTemplates, SetSelectedDoctorReportTemplate, GetDoctorReportTemplatesError } from './doctor-report-templates-selector.actions';
import { MarketingService } from '../../../services/marketing.service';
import { MarketingSetting } from '../../../models/marketingSetting.model';
import { tap, catchError, finalize } from 'rxjs/operators';
import { SetCustomisedDoctorReportData } from '../../customise-doctor-report/state/customised-doctor-report.actions';
import { Injectable } from '@angular/core';

export class DoctorReportTemplatesSelectorStateModel {
  doctorReportTemplates: MarketingSetting[];
  selectedTemplate: MarketingSetting;
  loading: boolean;
  errors: any;
}
@Injectable()
@State<DoctorReportTemplatesSelectorStateModel>({
  name: 'doctorReportTemplatesSelector',
  defaults: {
    doctorReportTemplates: [],
    selectedTemplate: undefined,
    loading: false,
    errors: undefined
  }
})
export class DoctorReportTemplatesSelectorState {
  constructor(private marketingService: MarketingService) { }

  @Selector()
  static doctorReportTemplates(state: DoctorReportTemplatesSelectorStateModel) { return state.doctorReportTemplates; }

  @Selector()
  static selectedTemplate(state: DoctorReportTemplatesSelectorStateModel) { return state.selectedTemplate; }

  @Action(GetDoctorReportTemplates)
  getDoctorReportTemplates(ctx: StateContext<DoctorReportTemplatesSelectorStateModel>, action: GetDoctorReportTemplates) {
    ctx.patchState({ loading: true });
    return this.marketingService.getDoctorReportTemplates(action.clientId).pipe(
      tap((response) => {
        ctx.patchState({
          doctorReportTemplates: response
        });
        ctx.dispatch(new SetSelectedDoctorReportTemplate(response[0].id));
      }),
      catchError((error) => {
        return ctx.dispatch(new GetDoctorReportTemplatesError(error));
      }),
      finalize(() => ctx.patchState({ loading: false }))
    );
  }

  @Action(SetSelectedDoctorReportTemplate)
  setSelectedDoctorReportTemplate(ctx: StateContext<DoctorReportTemplatesSelectorStateModel>, { templateId }: SetSelectedDoctorReportTemplate) {
    const { doctorReportTemplates } = ctx.getState();
    const template = doctorReportTemplates.find(template => template.id == templateId);
    ctx.patchState({
      selectedTemplate: template
    });
    ctx.dispatch(new SetCustomisedDoctorReportData({ reportBody: template.messageTemplate, subject: template.subject }))
  }

  @Action(GetDoctorReportTemplatesError)
  GetDoctorReportTemplatesError(ctx: StateContext<DoctorReportTemplatesSelectorStateModel>, action: GetDoctorReportTemplatesError) {
    ctx.patchState({
      errors: action.errors
    });
  }

}
