import { State, Action, StateContext, Selector, Store } from '@ngxs/store';
import {
  ToggleUpcoming,
  ToggleHistory,
  ToggleRequiresAction,
  GetClientNotes,
  NoteActionError,
  GetClientNotesSuccess,
  GetNoteTypes,
  GetNoteTypesSuccess,
  AddNewNote,
  CreateNewNote,
  RemoveNote,
  ActionClientNote,
  ResetProfileNoteFilters,
  ResetOrdersCycle
} from './notes.actions';
import { Note } from '../../../../shared/models/crm/note.model';
import { NoteType } from '../../../../shared/models/crm/noteType.model';
import { CrmService } from '../../../../shared/services/crm.service';
import { AlertService } from '../../../core/alert/alert.service';
import { catchError, switchMap } from 'rxjs/operators';
import { ProfileState } from '../../../core/profile/state/profile.state';
import { Injectable } from '@angular/core';

export class NotesStateModel {
  public showRequiresAction: boolean;
  public showUpcoming: boolean;
  public showHistory: boolean;
  public modifiedNotes: Note[];
  public noteTypes: NoteType[];
  public clientNotes: Note[];
  public newNoteTypeList: NoteType[];
  public loading: boolean;
  public noteFiltersForm: {
    model: Partial<NoteFilter>
  };
}
export interface NoteFilter {
  fromDate: Date | null;
  toDate: Date | null;
}
@Injectable()
@State<NotesStateModel>({
  name: 'notes',
  defaults: {
    showRequiresAction: true,
    showUpcoming: false,
    showHistory: false,
    modifiedNotes: [],
    noteTypes: [],
    clientNotes: [],
    newNoteTypeList: [],
    loading: false,
    noteFiltersForm: {
      model: undefined
    }
  }
})
export class NotesState {

  constructor(private crmService: CrmService, private alertService: AlertService, private store: Store) { }

  @Selector()
  static showRequiresAction(state: NotesStateModel) { return state.showRequiresAction; }

  @Selector()
  static showUpcoming(state: NotesStateModel) { return state.showUpcoming; }

  @Selector()
  static showHistory(state: NotesStateModel) { return state.showHistory; }

  @Selector()
  static clientNotes(state: NotesStateModel) { return state.clientNotes; }

  @Selector()
  static noteTypes(state: NotesStateModel) { return state.noteTypes; }

  @Selector()
  static modifiedNotes(state: NotesStateModel) { return state.modifiedNotes; }

  @Selector()
  static newNoteTypeList(state: NotesStateModel) { return state.newNoteTypeList; }

  @Selector()
  static loading(state: NotesStateModel) { return state.loading; }

  @Selector()
  static noteFiltersForm(state: NotesStateModel) { return state.noteFiltersForm.model; }

  @Action(ResetOrdersCycle)
  resetOrdersCycle(ctx: StateContext<NotesStateModel>, { clientId }: ResetOrdersCycle) {
    return this.crmService.resetOrderCycle(clientId);
  }

  @Action(ToggleRequiresAction)
  toggleRequiresAction(ctx: StateContext<NotesStateModel>) {
    const state = ctx.getState();
    ctx.patchState({ showRequiresAction: !state.showRequiresAction });
  }

  @Action(ToggleUpcoming)
  toggleUpcoming(ctx: StateContext<NotesStateModel>) {
    const state = ctx.getState();
    ctx.patchState({ showUpcoming: !state.showUpcoming });
  }

  @Action(ToggleHistory)
  toggleHistory(ctx: StateContext<NotesStateModel>) {
    const state = ctx.getState();
    ctx.patchState({ showHistory: !state.showHistory });
  }

  @Action(GetClientNotes)
  getClientNotes(ctx: StateContext<NotesStateModel>, { clientId }: GetClientNotes) {
    ctx.patchState({loading: true});
    return this.crmService.getNotesByClientId(clientId)
      .pipe(switchMap(response => ctx.dispatch(new GetClientNotesSuccess(response))),
        catchError(err => ctx.dispatch(new NoteActionError(err))));
  }

  @Action(GetClientNotesSuccess)
  getClientNotesSuccess(ctx: StateContext<NotesStateModel>, { notes }: GetClientNotesSuccess) {
    ctx.patchState({
      clientNotes: notes
    });
    ctx.patchState({loading: false});
    ctx.dispatch(new ResetProfileNoteFilters());
  }

  @Action(NoteActionError)
  noteActionError(ctx: StateContext<NotesStateModel>, { error }: NoteActionError) {
    this.alertService.error(error.error);
  }

  @Action(GetNoteTypes)
  getNoteTypes(ctx: StateContext<NotesStateModel>, { }: GetNoteTypes) {
    return this.crmService.getNoteTypes()
      .pipe(switchMap(response => ctx.dispatch(new GetNoteTypesSuccess(response))),
        catchError(err => ctx.dispatch(new NoteActionError(err))));
  }

  @Action(GetNoteTypesSuccess)
  getNoteTypesSuccess(ctx: StateContext<NotesStateModel>, { types }: GetNoteTypesSuccess) {
    ctx.patchState({
      noteTypes: types,
      newNoteTypeList: this.getNewNoteTypesList(types)
    });
  }

  @Action(AddNewNote)
  addNewNote(ctx: StateContext<NotesStateModel>, { note }: AddNewNote) {
    const notes = ctx.getState().clientNotes;
    notes.push(note);
    ctx.patchState({
      clientNotes: notes
    });
  }

  @Action(CreateNewNote)
  createNewNote(ctx: StateContext<NotesStateModel>, { newNote }: CreateNewNote) {
    return this.crmService.addNote(newNote)
      .pipe(switchMap(note => ctx.dispatch(new AddNewNote(note))),
        catchError(err => ctx.dispatch(new NoteActionError(err))));
  }

  @Action(RemoveNote)
  removeNote(ctx: StateContext<NotesStateModel>, { noteId }: RemoveNote) {
    ctx.patchState({loading: true});
    return this.crmService.removeNote(noteId).subscribe(() => {
      const clientNoteList = ctx.getState().clientNotes;
      const index = clientNoteList.findIndex(c => c.id === noteId);
      const clientId =  this.store.selectSnapshot(ProfileState.clientId);
      if (index !== -1) {
        clientNoteList.splice(index, 1);
      }
      ctx.patchState({
        clientNotes: clientNoteList
      });
      if (clientId) {
        ctx.dispatch(new GetClientNotes(clientId));
      }
    });
  }

  @Action(ActionClientNote)
  actionClientNote(ctx: StateContext<NotesStateModel>, { note, clientId }: ActionClientNote) {
    ctx.patchState({loading: true});
    return this.crmService.actionNote({ id: note.id }).subscribe((data) => {
      if (clientId) {
        ctx.dispatch(new GetClientNotes(clientId));
      }else{
        ctx.patchState({loading: false});
      }
    });
  }

  @Action(ResetProfileNoteFilters)
  resetProfileNoteFilters(ctx: StateContext<NotesStateModel>) {
    ctx.patchState({
      noteFiltersForm: {
        model: undefined
      }
    });
  }

  private getNewNoteTypesList(noteTypes: NoteType[]) {
    const newNoteTypes = [];
    noteTypes.forEach(item => {
      switch (item.name) {
        case 'General':
        case 'SMS':
        case 'Email':
        case 'Mail':
        case 'Phone call':
        case 'Patient note':
        case 'Delivery':
        case 'Special order':
        case 'High cost item':
        case 'Scripts ready':
        case 'Scheduled SMS':
          newNoteTypes.push(item);
          break;
        default:
          break;
      }
    });
    return newNoteTypes;
  }
}
