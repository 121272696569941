import { Component, OnInit, Input } from '@angular/core';
import { Client } from '../../../../shared/models/client.model';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { MessengerHistory, FirebaseMessageSource } from '../../../../shared/models/messageHistory.model';
import { ProfileState } from '../../../core/profile/state/profile.state';
import { FirestoreService } from '../../../../shared/services/firestore.service';
import { AngularFirestoreCollection } from '@angular/fire/firestore';
import { map } from 'rxjs/operators';
import { GetCustomerMobileAppStatus } from '../../../core/profile/state/profile.actions';

@Component({
  selector: 'app-messenger',
  templateUrl: './messenger.component.html',
  styleUrls: ['./messenger.component.scss']
})
export class MessengerComponent implements OnInit {
  @Input() client: Client;
  @Select(ProfileState.hasMobileApp) hasMobileApp$: Observable<boolean>;
  firestoreCollection: AngularFirestoreCollection<MessengerHistory>;
  messages$: Observable<MessengerHistory[]>;
  profileId: number;

  constructor(private store: Store, private firestoreService: FirestoreService) { }

  ngOnInit() {
    this.store.dispatch(new GetCustomerMobileAppStatus());
    this.store.selectOnce(ProfileState.customerProfileId)
      .subscribe(this.initializeFirestore)
  }

  initializeFirestore = (id: number) => {
    this.profileId = id;
    this.firestoreCollection = this.firestoreService.getMessageHistoryCollection<MessengerHistory>(id);
    this.messages$ = this.firestoreCollection.snapshotChanges()
      .pipe(
        map(actions => actions.map(mess => {
          const messageId = mess.payload.doc.id;
          const message = mess.payload.doc.data();
          if (!message.read && message.sentFrom === FirebaseMessageSource.Client) {
            this.firestoreService.markMessageAsRead(id, messageId, message);
          }
          return message;
        })));
  }
}
