<ng-container *ngIf="(profile$ | async) as profile">
  <div class="create-note fixed-blade" [ngClass]="{'fixed-blade-open': showBlade}" [@slideStateAnimation]="btnStatus">
    <div class="bg__dark color__lightest pointer fixed-blade__show" (click)="toggle()">
      <div *ngIf="!showBlade">
        <i class="fas fa-plus font__title-4"></i>
        <div class="font__title-2">Add Note</div>
      </div>
      <div *ngIf="showBlade">
        <i class="fas fa-times  font__title-4"></i>
        <div class="font__title-2">Close Note</div>
      </div>
    </div>
    <div class="fixed-blade__body bg__dark color__lightest" g-xs="p3">
      <i class="fas fa-times close color__lightest" (click)="closeBlade()"></i>

      <form [formGroup]="noteForm" (ngSubmit)="postNote()">
        <h2>Add Note</h2>
        <h4 g-xs="mt3">Add Note - {{clientName}}</h4>

        <label class="font__bold" g-xs="mt3 mb1">Note type</label>
        <div class="btn-group" dropdown>
          <button dropdownToggle type="button" class="dropdown-toggle form-control border__grey-light note__type-btn"
            aria-controls="filter-type">
            <div>
              <span [innerHTML]="selectedType.name | appNoteTypeIcon: noteTypeList"></span>
              <span g-xs="ml1">{{selectedType.name}}</span>
            </div>
            <span class="caret"></span>
          </button>
          <ul *dropdownMenu class="dropdown-menu border__grey-light note__type" role="menu">
            <li g-xs="py1 px2" role="menuitem" *ngFor="let item of noteTypeList" (click)="updateNoteType(item)">
              <span [innerHTML]="item.name | appNoteTypeIcon: noteTypeList"></span>
              <span g-xs="ml1">{{item.name}}</span>
            </li>
          </ul>
        </div>

        <div class="alert alert-warning" g-xs="mt2 mb2" role="alert"
          *ngIf="(selectedType.name =='Scheduled SMS' && (profile?.customerProfile?.contactMethod != 1 || profile?.customerProfile?.contactMethod == null))">
          {{clientName}}'s preferred contact method is not currently SMS, consider updating.
        </div>

        <div *ngIf="selectedType.name =='Scheduled SMS'" class="form-group">
          <label class="font__bold" g-xs="mt3 mb1">Template</label>
          <select class="form-control pre-selected-messages color__grey-text" g-xs="py2" id="pre-selected-messages"
            name="messageTypeId" formControlName="messageTypeId" (change)="onMessageTypeChange()">
            <option value="0">
              Use a pre-selected message to send to your clients
            </option>
            <option *ngFor="let item of messageTypes" [value]="item.id">
              {{item.title}}
            </option>
          </select>
        </div>

        <div class="form-group">
          <label class="font__bold" g-xs="mt3 mb1" *ngIf="selectedType.name !='Scheduled SMS'">Note</label>
          <label class="font__bold" g-xs="mt3 mb1" *ngIf="selectedType.name =='Scheduled SMS'">Message</label>
          <textarea class="form-control vertical" rows="4" placeholder="Enter Note" formControlName="body"></textarea>
        </div>

        <div class="form-group" *ngIf="noteForm.get('noteType').value !== 13 && selectedType.name != 'Scheduled SMS'">
          <label class="font__bold" g-xs="mt3 mb1">Start Date</label>
          <input name="startDate" formControlName="startDate" type="text" placeholder="DD/MM/YYYY"
            class="form-control border__grey-light calendar" id="fromDate"
            [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY' }" bsDatepicker (change)="logDate()" />
        </div>


        <div *ngIf="selectedType.name == 'Scheduled SMS'">
          <div class="form-group w-50">
            <label class="font__bold" g-xs="mt3 mb1">Scheduled Date</label>
            <nz-date-picker
              name="scheduledDate"
              formControlName="scheduledDate"
              nzFormat="dd-MM-YYYY"
              id="date"
              nzPlaceHolder="dd-mm-yyyy"
              [nzDisabledDate]="disabledDates"
            ></nz-date-picker>
          </div>
          <div class="form-group w-50">
            <label class="font__bold" g-xs="mt2 mb2">Scheduled Time</label>
            <nz-time-picker
              formControlName="scheduledTime"
              name="scheduledTime"
              [nzUse12Hours]="true"
              nzPlaceHolder="10:00 AM"
              nzFormat="h:mm a"
            ></nz-time-picker>
          </div>
          <label><i>Leave blank to send NOW</i></label>
        </div>

        <div class="form-group" *ngIf="noteForm.get('noteType').value !== 13 && selectedType.name !='Scheduled SMS'">
          <div class="checkbox-inline">
            <label class="font__bold">
              <input type="checkbox" formControlName="repeat" /> I want this to repeat</label>
          </div>
        </div>

        <div *ngIf="noteForm.get('repeat').value === true">
          <h3 g-xs="mb3">Configure Schedule</h3>
          <div class="form-group schedule">
            <label class="font__bold">Every</label>
            <input type="text" class="form-control width__50 pr-0" placeholder="0" formControlName="intervalValue" />
            <select name="repeatType" class="width__150 form-control" formControlName="repeatType">
              <option *ngFor="let item of repeatTypes" [value]="item.value"> {{item.name}} </option>
            </select>
          </div>
        </div>

        <button class="button btn__green" type="submit"
          [disabled]="noteForm.invalid || (selectedType.name === 'Scheduled SMS' && !profile.patient.mobileNumber)">
          <ng-template [ngIf]="selectedType.name === 'Scheduled SMS'" [ngIfElse]="createTask">
            <span>
              Send SMS
            </span>
          </ng-template>
          <ng-template #createTask>
            <span>
              Create Task
            </span>
          </ng-template>
          <i class="fas fa-check color__dark" g-xs="ml1"></i>
        </button>
        <div class="alert alert-danger" g-xs="mt2" role="alert"
          *ngIf="(selectedType.name =='Scheduled SMS' && !profile?.patient?.mobileNumber)">
          No mobile number on file
        </div>

        <div *ngIf="selectedType.name =='Scheduled SMS' && noteForm.value.body && noteForm.value.body.length"
          class="ml-3 mt-2">
          <ul>
            <li>Character Count: {{ getCharacterCount() }}</li>
            <li>Message Count: {{ getMessageCount() }}</li>
          </ul>
        </div>
      </form>
    </div>
  </div>
</ng-container>
