import { Component, Inject, OnInit } from '@angular/core';
import { CoreFeatures } from '../../../system/core-features';
import { Store } from '@ngxs/store';
import { GetCommsQueueAction } from '../comms-queue/state/comms-queue.actions';

@Component({
  selector: 'app-pharmacy-comms',
  templateUrl: './pharmacy-comms.component.html',
})
export class PharmacyCommsComponent implements OnInit {
  messageQueue: boolean;

  constructor(
    @Inject('coreFeatures') coreFeatures: CoreFeatures,
    private store: Store
  ) {
    this.messageQueue = coreFeatures.messageQueue;
  }

  ngOnInit() {
    if (this.messageQueue) {
      this.store.dispatch(new GetCommsQueueAction(new Date()));
    }
  }
}
