<div *ngIf="(searchResults$ | async)?.length as count">
  <div class="float-right mb-3 mr-4">
    <div class="float-left mb-0 select-total">
      <span class="underline" *ngIf="!(selectTotal$ | async)"><a (click)="selectAll()">Select All Pages</a></span>
      <span class="underline" *ngIf="(selectTotal$ | async)"><a (click)="unSelectAll()">Select None</a></span>
    </div>
    <span class="mr-4">{{count}} total customers</span>
    <button class="btn btn-success" (click)="navigateToBulkComms()">Schedule BulkSMS</button>
    <app-advanced-search-export-options [headers]="headers" [rows]="exportData$ | async" [expandedColumns]="[15]" class="ml-2"></app-advanced-search-export-options>
    <button *ngIf="(recipientList$ | async).length as recipientCount" class="ml-2 btn btn-outline-info">Selected recipients:
      {{recipientCount}}</button>
  </div>
  <div class="float-left mb-0">
    <span class="underline" *ngIf="!(selectCurrentPage$ | async)"><a (click)="selectAllOnPage()">Select Page</a></span>
    <span class="underline" *ngIf="selectCurrentPage$ | async"><a (click)="unSelectAllOnPage()">Select None</a></span>
  </div>
</div>
<div class="table-container w-100">
  <table cdk-table [dataSource]="dataOnPage$" class="table search-list__table">
    <tr class="bg__light-yellow-table" cdk-header-row *cdkHeaderRowDef="displayedColumns$.value"></tr>
    <tr class="bg__light-yellow-table" cdk-row *cdkRowDef="let row; columns: displayedColumns$.value"></tr>
    
    <tr *cdkNoDataRow>
      <ng-container *ngIf="fetching$ | async">
        <td><app-loader loadingMessage="Fetching results"></app-loader></td>
      </ng-container>
      <ng-container *ngIf="!(fetching$ | async)">
        <ng-container *ngIf="(searchResults$ | async)?.length === 0">
          <td>
            No results
          </td>
        </ng-container>
      </ng-container>
    </tr>

    <ng-container cdkColumnDef="bulkSMS">
      <th cdk-header-cell *cdkHeaderCellDef>
        Bulk SMS
      </th>
      <td cdk-cell *cdkCellDef="let row">
        <div class="checkbox-inline" *ngIf="row.mobileNumber">
          <input [checked]="row.selected" #checkboxes type="checkbox" [value]="row.mobileNumber"
            (change)="toggleRecipient(row.client.id, $event)" />
        </div>
      </td>
    </ng-container>
    <ng-container cdkColumnDef="name">
      <th cdk-header-cell *cdkHeaderCellDef (click)="adjustSort('fullname')">
        Name
        <span *ngIf="sortKey$.value === 'fullname'">
          {{ sortDirection$.value === 'asc' ? '↥' : '↧' }}
        </span>
      </th>
      <td cdk-cell *cdkCellDef="let row">
        <a routerLink="/profile/{{ row.client.id }}" class="color__darkest flex align-items-center">
          {{ row.client.firstname }} {{ row.client.surname }}
        </a>
      </td>
    </ng-container>
    <ng-container cdkColumnDef="address">
      <th cdk-header-cell *cdkHeaderCellDef (click)="adjustSort('fulladdress')">
        Address
        <span *ngIf="sortKey$.value === 'fulladdress'">
          {{ sortDirection$.value === 'asc' ? '↥' : '↧' }}
        </span>
      </th>
      <td cdk-cell *cdkCellDef="let row">
        <span *ngIf="row.client?.homeAddress">{{row.client.homeAddress}} </span>
      <span *ngIf="row.client?.homeSuburb">{{row.client.homeSuburb}} </span>
      <span *ngIf="row.client?.homeState">{{row.client.homeState}} </span>
      <span *ngIf="row.client?.homePostcode">{{row.client.homePostcode}} </span>
      </td>
    </ng-container>
    <ng-container cdkColumnDef="dob">
      <th cdk-header-cell *cdkHeaderCellDef (click)="adjustSort('birthDate')">
        DOB
        <span *ngIf="sortKey$.value === 'birthDate'">
          {{ sortDirection$.value === 'asc' ? '↥' : '↧' }}
        </span>
      </th>
      <td cdk-cell *cdkCellDef="let row">
        {{ row.client.birthDate | tenantDate }}
      </td>
    </ng-container>
    <ng-container cdkColumnDef="gender">
      <th cdk-header-cell *cdkHeaderCellDef (click)="adjustSort('gender')">
        Gender
        <span *ngIf="sortKey$.value === 'gender'">
          {{ sortDirection$.value === 'asc' ? '↥' : '↧' }}
        </span>
      </th>
      <td cdk-cell *cdkCellDef="let row">
        {{ row.client.gender }}
      </td>
    </ng-container>
    <ng-container cdkColumnDef="type">
      <th cdk-header-cell *cdkHeaderCellDef (click)="adjustSort('patientType')">
        Type
        <span *ngIf="sortKey$.value === 'patientType'">
          {{ sortDirection$.value === 'asc' ? '↥' : '↧' }}
        </span>
      </th>
      <td cdk-cell *cdkCellDef="let row">
        <span *ngIf="row.patientType">{{row.patientType}}</span>
      </td>
    </ng-container>
    <ng-container cdkColumnDef="group">
      <th cdk-header-cell *cdkHeaderCellDef (click)="adjustSort('groupName')">
        Group
        <span *ngIf="sortKey$.value === 'groupName'">
          {{ sortDirection$.value === 'asc' ? '↥' : '↧' }}
        </span>
      </th>
      <td cdk-cell *cdkCellDef="let row">
        <span *ngIf="row.groupName">{{row.groupName}}</span>
      </td>
    </ng-container>
    <ng-container cdkColumnDef="status">
      <th cdk-header-cell *cdkHeaderCellDef (click)="adjustSort('status')">
        Status
        <span *ngIf="sortKey$.value === 'status'">
          {{ sortDirection$.value === 'asc' ? '↥' : '↧' }}
        </span>
      </th>
      <td cdk-cell *cdkCellDef="let row">
        <span *ngIf="row.status">{{customerStatus[row.status]}}</span>
      </td>
    </ng-container>
    <ng-container cdkColumnDef="phone">
      <th cdk-header-cell *cdkHeaderCellDef (click)="adjustSort('mobileNumber')">
        Phone
        <span *ngIf="sortKey$.value === 'mobileNumber'">
          {{ sortDirection$.value === 'asc' ? '↥' : '↧' }}
        </span>
      </th>
      <td cdk-cell *cdkCellDef="let row">
        <span *ngIf="row.client?.mobileNumber">{{row.client.mobileNumber}}</span>
      </td>
    </ng-container>
    <ng-container cdkColumnDef="email">
      <th cdk-header-cell *cdkHeaderCellDef (click)="adjustSort('email')">
        Email
        <span *ngIf="sortKey$.value === 'email'">
          {{ sortDirection$.value === 'asc' ? '↥' : '↧' }}
        </span>
      </th>
      <td cdk-cell *cdkCellDef="let row">
        <span *ngIf="row.client?.email">{{row.client.email}}</span>
      </td>
    </ng-container>
    <ng-container cdkColumnDef="medicare">
      <th cdk-header-cell *cdkHeaderCellDef (click)="adjustSort('medicareNo')">
        Medicare
        <span *ngIf="sortKey$.value === 'medicareNo'">
          {{ sortDirection$.value === 'asc' ? '↥' : '↧' }}
        </span>
      </th>
      <td cdk-cell *cdkCellDef="let row">
        <span *ngIf="row.client?.medicareNo">{{row.client.medicareNo}}</span>
      </td>
    </ng-container>
    <ng-container cdkColumnDef="concession">
      <th cdk-header-cell *cdkHeaderCellDef (click)="adjustSort('concessionNo')">
        Concession
        <span *ngIf="sortKey$.value === 'concessionNo'">
          {{ sortDirection$.value === 'asc' ? '↥' : '↧' }}
        </span>
      </th>
      <td cdk-cell *cdkCellDef="let row">
        <span *ngIf="row.client?.concessionNo">{{row.client.concessionNo}}</span>
      </td>
    </ng-container>
    <ng-container cdkColumnDef="commsPref">
      <th cdk-header-cell *cdkHeaderCellDef (click)="adjustSort('commsTypePreference')">
        Comms Pref
        <span *ngIf="sortKey$.value === 'commsTypePreference'">
          {{ sortDirection$.value === 'asc' ? '↥' : '↧' }}
        </span>
      </th>
      <td cdk-cell *cdkCellDef="let row">
        <span *ngIf="row.commsTypePreference !== null && row.commsTypePreference !== undefined">{{contactMethods[row.commsTypePreference]}}</span>
      </td>
    </ng-container>
    <ng-container cdkColumnDef="autoComms">
      <th cdk-header-cell *cdkHeaderCellDef (click)="adjustSort('automatedCommsPreference')">
        Scrypt Reminders
        <span *ngIf="sortKey$.value === 'automatedCommsPreference'">
          {{ sortDirection$.value === 'asc' ? '↥' : '↧' }}
        </span>
      </th>
      <td cdk-cell *cdkCellDef="let row">
        <span *ngIf="row">{{row.automatedCommsPreference}}</span>
      </td>
    </ng-container>
    <ng-container cdkColumnDef="orderCycle">
      <th cdk-header-cell *cdkHeaderCellDef (click)="adjustSort('hasOrderCycle')">
        Order Cycle
        <span *ngIf="sortKey$.value === 'hasOrderCycle'">
          {{ sortDirection$.value === 'asc' ? '↥' : '↧' }}
        </span>
      </th>
      <td cdk-cell *cdkCellDef="let row">
        <span *ngIf="row">{{row.hasOrderCycle}}</span>
      </td>
    </ng-container>
    <ng-container cdkColumnDef="orderPref">
      <th cdk-header-cell *cdkHeaderCellDef (click)="adjustSort('deliveryPreference')">
        Order Pref
        <span *ngIf="sortKey$.value === 'deliveryPreference'">
          {{ sortDirection$.value === 'asc' ? '↥' : '↧' }}
        </span>
      </th>
      <td cdk-cell *cdkCellDef="let row">
        <span *ngIf="row.deliveryPreference">{{orderMethods[row.deliveryPreference]}}</span>
      </td>
    </ng-container>
    <ng-container cdkColumnDef="created">
      <th cdk-header-cell *cdkHeaderCellDef (click)="adjustSort('created')">
        Created
        <span *ngIf="sortKey$.value === 'created'">
          {{ sortDirection$.value === 'asc' ? '↥' : '↧' }}
        </span>
      </th>
      <td cdk-cell *cdkCellDef="let row">
        <span *ngIf="row.client?.created">{{row.client.created | tenantDate }}</span>
      </td>
    </ng-container>
  </table>
</div>
<div *ngIf="(searchResults$ | async)?.length as count" class="d-flex justify-content-between w-100">
  <pagination class="mt-1"
    #pagination
    [boundaryLinks]="true"
    [totalItems]="count"
    [itemsPerPage]="pageSize"
    [ngModel]="currentPage$ | async"
    (ngModelChange)="currentPage$.next($event)"
    [rotate]="true"
    [maxSize]="10"
  ></pagination>
  <div class="mt-2">
    <select (change)="changeResultsSize()" [(ngModel)]="pageSize" id="showResults" name="resultsPerPage"
      class="form-control form-control-lg">
      <option *ngFor="let results of resultsPerPageList" [ngValue]="results">
        {{results}} results
      </option>
    </select>
  </div>
</div>