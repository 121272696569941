<button
 nz-button 
 nz-dropdown 
 nzTrigger="click" 
 type="button" 
 [nzDropdownMenu]="menu" 
 id="more-button" 
 class="comms-button more-button-container py-2"
>
  <i class="fal fa-ellipsis-h-alt" id="more-button-text"></i>
</button>
<nz-dropdown-menu #menu="nzDropdownMenu">
  <ul nz-menu>
    <li *ngIf="messageType === mailTabs.Inbox && actions.includes('flag')" nz-menu-item class="more-dropdown-option cursor-pointer" (click)="toggleFlagged(getMessageObject())">
      {{(getMessageObject().isFlagged) ? 'Unflag' : 'Flag'}}
    </li>
    <li *ngIf="messageType === mailTabs.Inbox && actions.includes('read')" nz-menu-item class="more-dropdown-option cursor-pointer" (click)="toggleRead(getMessageObject())">
      {{(getMessageObject().isRead) ? 'Mark as unread' : 'Mark as read'}}
    </li>
    <li  *ngIf="actions.includes('view')" nz-menu-item class="more-dropdown-option cursor-pointer">
      <a class="hidden-link" routerLink="/profile/{{ getMessageObject()?.customerId }}/categories">View patient</a>
    </li>
    <li *ngIf="messageType === mailTabs.Inbox && actions.includes('archive')" nz-menu-item class="more-dropdown-option cursor-pointer" (click)="toggleArchived(getMessageObject())">
      {{(getMessageObject().isArchived) ? 'Remove from archive' : 'Archive message'}}
    </li>
  </ul>
</nz-dropdown-menu>