import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxsModule } from '@ngxs/store';
import { TimezoneState } from './state/timezone.state';
import { AllTimezonesResolver } from './resolvers/all-timezones.resolver';
import { TimezoneRepository } from './repositories/timezone.repository';
import { SelectedTimezoneResolver } from './resolvers/selected-timezone.resolver';
import { TenantDatePipe } from './pipes/tenant-date.pipe';
import { TenantTimezonePipe } from './pipes/tenantTimezone.pipe';

@NgModule({
    declarations: [TenantDatePipe, TenantTimezonePipe],
    exports: [TenantDatePipe, TenantTimezonePipe],
    providers: [
      TimezoneRepository,
      AllTimezonesResolver,
      SelectedTimezoneResolver
    ],
    imports: [
      CommonModule,
      NgxsModule.forFeature([TimezoneState])
    ]
  })
  export class TimezoneModule { }
