export interface HtmlAsPdfFromStringRequest {
    html: string;
    baseUrlOrPath?: string;
    pdfPrintOptions?: PdfPrintOptions;
    pdfSecurityOptions?: PdfSecurityOptions;
}

export interface PdfPrintOptions {
    viewPortWidth?: number | null;
    viewPortHeight?: number | null;
    title?: string;
    renderDelay?: number | null;
    printHtmlBackgrounds?: boolean | null;
    paperSize?: PdfPaperSize | null;
    paperOrientation?: PdfPaperOrientation | null;
    marginTop?: number | null;
    marginRight?: number | null;
    marginLeft?: number | null;
    marginBottom?: number | null;
    header?: SimpleHeaderFooter;
    grayScale?: boolean | null;
    generateUniqueDocumentIdentifiers?: boolean | null;
    fitToPaperWidth?: boolean | null;
    footer?: SimpleHeaderFooter;
    firstPageNumber?: number | null;
    enableJavaScript?: boolean | null;
    dPI?: number | null;
    customCssUrl?: string;
    cssMediaType?: PdfCssMediaType | null;
    createPdfFormsFromHtml?: boolean | null;
    jpegQuality?: number | null;
    zoom?: number | null;
    customPaperSize?: CustomPaperSize;
}

export enum PdfPaperSize {
    Letter = 0,
    Legal = 1,
    A4 = 2,
    CSheet = 3,
    DSheet = 4,
    ESheet = 5,
    LetterSmall = 6,
    Tabloid = 7,
    Ledger = 8,
    Statement = 9,
    Executive = 10,
    A3 = 11,
    A4Small = 12,
    A5 = 13,
    B4 = 14,
    B5 = 15,
    Folio = 16,
    Quarto = 17,
    Standard10x14 = 18,
    Standard11x17 = 19,
    Note = 20,
    Number9Envelope = 21,
    Number10Envelope = 22,
    Number11Envelope = 23,
    Number12Envelope = 24,
    Number14Envelope = 25,
    DLEnvelope = 26,
    C5Envelope = 27,
    C3Envelope = 28,
    C4Envelope = 29,
    C6Envelope = 30,
    C65Envelope = 31,
    B4Envelope = 32,
    B5Envelope = 33,
    B6Envelope = 34,
    ItalyEnvelope = 35,
    MonarchEnvelope = 36,
    PersonalEnvelope = 37,
    USStandardFanfold = 38,
    GermanStandardFanfold = 39,
    GermanLegalFanfold = 40,
    IsoB4 = 41,
    JapanesePostcard = 42,
    Standard9x11 = 43,
    Standard10x11 = 44,
    Standard15x11 = 45,
    InviteEnvelope = 46,
    LetterExtra = 47,
    LegalExtra = 48,
    TabloidExtra = 49,
    A4Extra = 50,
    LetterTransverse = 51,
    A4Transverse = 52,
    LetterExtraTransverse = 53,
    APlus = 54,
    BPlus = 55,
    LetterPlus = 56,
    A4Plus = 57,
    A5Transverse = 58,
    B5Transverse = 59,
    A3Extra = 60,
    A5Extra = 61,
    B5Extra = 62,
    A2 = 63,
    A3Transverse = 64,
    A3ExtraTransverse = 65,
    JapaneseDoublePostcard = 66,
    A6 = 67,
    LetterRotated = 68,
    A3Rotated = 69,
    A4Rotated = 70,
    A5Rotated = 71,
    B4JisRotated = 72,
    B5JisRotated = 73,
    JapanesePostcardRotated = 74,
    JapaneseDoublePostcardRotated = 75,
    A6Rotated = 76,
    B6Jis = 77,
    B6JisRotated = 78,
    Standard12x11 = 79,
    Prc16K = 80,
    Prc32K = 81,
    Prc32KBig = 82,
    PrcEnvelopeNumber1 = 83,
    PrcEnvelopeNumber2 = 84,
    PrcEnvelopeNumber3 = 85,
    PrcEnvelopeNumber4 = 86,
    PrcEnvelopeNumber5 = 87,
    PrcEnvelopeNumber6 = 88,
    PrcEnvelopeNumber7 = 89,
    PrcEnvelopeNumber8 = 90,
    PrcEnvelopeNumber9 = 91,
    PrcEnvelopeNumber10 = 92,
    Prc16KRotated = 93,
    Prc32KRotated = 94,
    Prc32KBigRotated = 95,
    PrcEnvelopeNumber1Rotated = 96,
    PrcEnvelopeNumber2Rotated = 97,
    PrcEnvelopeNumber3Rotated = 98,
    PrcEnvelopeNumber4Rotated = 99,
    PrcEnvelopeNumber5Rotated = 100,
    PrcEnvelopeNumber6Rotated = 101,
    PrcEnvelopeNumber7Rotated = 102,
    PrcEnvelopeNumber8Rotated = 103,
    PrcEnvelopeNumber9Rotated = 104,
    PrcEnvelopeNumber10Rotated = 105,
    Custom = 106
}


export enum PdfPaperOrientation {
    Portrait = 0,
    Landscape = 1
}

export interface SimpleHeaderFooter {
    centerText?: string | null;
    drawDividerLine?: boolean | null;
    fontFamily?: string | null;
    fontSize?: number | null;
    leftText?: string | null;
    rightText?: string | null;
    spacing?: number | null;
}

export enum PdfCssMediaType {
    Print = 0,
    Screen = 1
}

export interface CustomPaperSize {
    width: number;
    height: number;
    unitType: UnitType;
}

export enum UnitType {
    Inches = 0,
    Millimeters = 1
}

export interface PdfSecurityOptions {
    password: string;
    ownerPassword: string;
}