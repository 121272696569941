import { Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { Timezone } from '../timezone.model';
import {resolveSelectedTimezone } from '../logic/timezone-resolver.logic';


@Injectable({
    providedIn: 'root'
})
export class SelectedTimezoneResolver implements Resolve<Timezone> {
    constructor(private store: Store) {}

    resolve(): Observable<Timezone> {
       return resolveSelectedTimezone(this.store);
    }
}
