<ng-container *ngIf="(selectedDrugs$ | async) as selectedDrugs">
  <ng-container *ngIf="(bulkStockAdjusment$ | async) as bulkStockAdjusment">
    <div>
      <h2>Bulk Adjustments</h2>
      <div>
        <div class="form-group" g-xs="mt2">
          <label class="font__bold">How many days need to be adjusted?</label>
          <input type="number" step="0.01" class="form-control" g-xs="mt1" [(ngModel)]="adjustment" />
          <p g-xs="mt1"><i>Negative changes represent a deduction</i></p>
        </div>
        <div class="clearfix" *ngIf="selectedDrugs.length">
          <ng-container *ngIf="!bulkStockAdjusment.loading; else loading">
            <button (click)="submit(selectedDrugs)" class="button btn__yellow pull-right" g-xs="mt2"
              *ngIf="!bulkStockAdjusment.loading" [disabled]="adjustment === 0">Update</button>
          </ng-container>
        </div>
      </div>
      <hr />
      <div *ngIf="selectedDrugs.length; else noDrugs" g-xs="mt3">
        <div>Adjusting the TotalDoh for the following drugs:</div>
        <ul class="ml-3">
          <li *ngFor="let drug of selectedDrugs">{{drug?.name?.length > 0 ? drug.name : drug.description}}</li>
        </ul>
      </div>
    </div>
    <ng-template #noDrugs>
      <div>Please select some drugs to adjust</div>
    </ng-template>
    <ng-template #loading>
      <i class="fas fa-sync fa-spin" *ngIf="bulkStockAdjusment.loading"></i>
    </ng-template>
  </ng-container>
</ng-container>
