import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Client } from '../../../../shared/models/client.model';
import { Subscription } from 'rxjs/Subscription';
import { Email } from '../../../../shared/models/email.model';
import { EmailService } from '../../../../shared/services/email.service';

@Component({
  selector: 'app-email',
  templateUrl: './email.component.html',
  styleUrls: ['./email.component.scss']
})
export class EmailComponent implements OnInit, OnDestroy {

  constructor(private emailService: EmailService) { }

  @Input() client: Client;
  isLoaded = false;
  emailHistorySubscription: Subscription;
  emailHistory: Email[] = [];

  ngOnInit() {
    this.emailHistorySubscription = this.emailService.getClientEmailHistory(this.client.id)
      .subscribe((item: Email[]) => {
        this.emailHistory = item;
        this.isLoaded = true;
      });
  }
  emailSent(item: Email) {
    this.emailHistory.push(item);
  }

  ngOnDestroy() {
    if (this.emailHistorySubscription) { this.emailHistorySubscription.unsubscribe(); }
  }
}
