import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CrmService } from '../../../../shared/services/crm.service';
import { LoaderModule } from '../../../../modules/core/loader/loader.module';
import { NoteSettingsModule } from '../../../../modules/core/note-settings/note-settings.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NoteTypePipeModule } from '../../../../shared/pipes/noteTypeIcon.pipe';
import { BsDropdownModule } from 'ngx-bootstrap';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { PipesModule } from '../../../../shared/pipes/pipes.module';
import { TimezoneModule } from '../../../../modules/core/timezone/timezone.module';
import { CrmNotificationDateModule } from '../../../../modules/crm/crm-notification-date/crm-notification-date.module';
import { CreateNoteComponent } from './create-note.component';

@NgModule({
  declarations: [
    CreateNoteComponent,
  ],
  providers: [
    CrmService
  ],
  imports: [
    PipesModule,
    CommonModule,
    FormsModule,
    LoaderModule,
    TimezoneModule,
    CrmNotificationDateModule,
    NoteTypePipeModule,
    NoteSettingsModule,
    BsDatepickerModule,
    BsDropdownModule,
    ReactiveFormsModule
  ],
  exports: [
    CreateNoteComponent
  ]
})
export class CreateNoteModule { }
