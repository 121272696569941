import { SSOutcomesAndActionsFormModel } from '../../../../../../shared/models/six-cpa/staged-supply/ss-outcomes-and-actions-form.model';

export enum SSOutcomesAndActionsFormActions {
  SET_VALID = '[SSOutcomesAndActionsForm] set valid',
  UPDATE = '[SSOutcomesAndActionsForm] Update Form'
}

export class SetSSOutcomesAndActionsFormValidAction {
  static readonly type = SSOutcomesAndActionsFormActions.SET_VALID;
  constructor(public valid = false) { }
}

export class SetTotalItemsAndCompletedItems {
  static readonly type = '[SSOutcomesAndActionsForm] Update Progress';
  constructor(public totalItems, public completedItems) { }
}

export class UpdateSSOutcomesAndActionsForm {
  static readonly type = SSOutcomesAndActionsFormActions.UPDATE;
  constructor(public form: SSOutcomesAndActionsFormModel) { }
}
