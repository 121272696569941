import { Injectable } from '@angular/core';
import { MarketingType } from '../../../shared/models/sequence-type.enum';
import { MarketingSetting } from '~/shared/models/marketingSetting.model';

@Injectable()
export class MarketingSettingService {

  public getCreatableMarketingTypes() {
    return [MarketingType.UserConfigurable, MarketingType.DoctorReportTemplate, MarketingType.ScriptRepeat];
  }

  public hasInterval(setting: MarketingSetting) {
    return !this.isPickupOrDeliver(setting);
  }

  public hasActive(setting: MarketingSetting) {
    return !this.isPickupOrDeliver(setting);
  }

  private isPickupOrDeliver(setting: MarketingSetting) {
    return (
      setting.marketingType === MarketingType.OrderDelivery
      || setting.marketingType === MarketingType.OrderPickup
      || setting.marketingType === MarketingType.UserConfigurable
      || setting.marketingType === MarketingType.DoctorReportTemplate
    );
  }
}
