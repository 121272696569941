import { DaaAdditionalDetailsFormListItemComponent } from './daa-additional-details-form-list-item.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SixCpaFormListItemChecklistModule } from '../../six-cpa-form-list-item-checklist/six-cpa-form-list-item-checklist.module';
import { DAAAdditionalDetailsFormModule } from '../daa-additional-details-form/daa-additional-details-form.module';


@NgModule({
  declarations: [
    DaaAdditionalDetailsFormListItemComponent
  ],
  imports: [
    CommonModule,
    SixCpaFormListItemChecklistModule,
    DAAAdditionalDetailsFormModule
  ],
  exports: [
    DaaAdditionalDetailsFormListItemComponent
  ]
})
export class DAAAdditionalDetailsFormListItemModule { }
