<section class="history">
  <div class="font__title-3 font__light">
    Email Message History
    <small><a class="float-right pointer" (click)="forceScrollDown()">See latest</a></small>
  </div>

  <div id="email-history" g-xs="p3 mt2 mb3" class="border__grey" ngx-auto-scroll lock-y-offset="10" observe-attributes>
    <app-message *ngFor="let item of emailHistory"
      [fromCustomer]="isCustomer(item.fromMail)"
      [fromPharmacy]="!isCustomer(item.fromMail)">
      <div>
        <div class="font__title-0"><strong>From:</strong> {{item.fromMail}} - {{item.createdAt | tenantDate: 'DD/MM/YYYY hh:mm A'}}</div>
        <div class="font__title-0"><strong>Subject:</strong> {{item.subject}}</div>
        <div class="font__title-1 email-content mt-2" [innerHTML]="formatEmailContent(item.message)"></div>
        <div *ngIf="checkForEScript(item.message)">
          <a class="image-link" (click)="openEScriptModal(item.message)">Queue eScript</a>
        </div>
        <div *ngIf="item.emailAttachments && item.emailAttachments.length">
          <span *ngFor="let attachment of item.emailAttachments; let count=index">
            <a class="image-link" [href]="attachment.contentLink" target="_blank">Attachment ({{count + 1}})</a><br />
          </span>
        </div>
        <div *ngIf="item.html">
          <span class="image-link" (click)="showFullEmail(item.html)">See full original email <i class="fas fa-envelope-open-text"></i></span>
        </div>
      </div>
    </app-message>
  </div>
</section>

<section>
  <ng-container *ngIf="(showModal$ | async) as showModal">
    <app-email-history-modal *ngIf="showModal" [markup]="fullEmail">
    </app-email-history-modal>
  </ng-container>
</section>
