import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import { ReactiveFormsModule } from '@angular/forms';
import { HMRAccreditedPharmacistDetailsComponent } from './hmr-accredited-pharmacist-details.component';

@NgModule({
  declarations: [HMRAccreditedPharmacistDetailsComponent],
  imports: [
    CommonModule,
    NgxsFormPluginModule,
    ReactiveFormsModule,
  ],
  exports: [HMRAccreditedPharmacistDetailsComponent]
})
export class HMRAccreditedPharmacistDetailsModule { }
