import { SSConsentEligibilityFormBasicState } from "./state/ss-consent-eligibility-form-basic.state";
import { SSConsentEligibilityFormDetailedState } from "./state/ss-consent-eligibility-form-detailed.state";
import { NgxsModule } from "@ngxs/store";
import { NgModule } from "@angular/core";
import { SSConsentEligibilityFormState } from "./state/ss-consent-eligibility-form.state";

@NgModule({
  imports: [
    NgxsModule.forFeature([
      SSConsentEligibilityFormState,
      SSConsentEligibilityFormBasicState,
      SSConsentEligibilityFormDetailedState
    ])
  ]
})
export class SSConsentEligibilityStateModule { }