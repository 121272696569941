<div class="message-bg" *ngIf="profile">
    <div class="d-flex justify-content-between align-items-center"
       g-xs="pt1 pb1 valign:middle">
        <label nz-checkbox [ngModel]="hasMessage" (ngModelChange)="updateHasMessage($event)">Send a message to {{profile.patient.firstname}}</label>
        <nz-select
          nzPlaceHolder="Choose a contact method"
          *ngIf="hasMessage"
          ngDefaultControl
          [(ngModel)]="contactMethod"
          (ngModelChange)="contactMethodChange.next($event)">
            <ng-container *ngFor="let method of profile.contactMethods">
                <nz-option *ngIf="isAvailableMethod(method.key)" [nzValue]="method.key" [nzLabel]="getChannelPrefName(method.key)"></nz-option>
            </ng-container>
        </nz-select>
    </div>
    <div *ngIf="hasMessage">
        <textarea *ngIf="isEditing" rows="7" nz-input [(ngModel)]="messageTemplate"></textarea>
        <textarea *ngIf="!isEditing" rows="7" nz-input [ngModel]="messageView" disabled></textarea>
        <button class="edit-button" *ngIf="!isEditing" (click)="startEdit()"><i class="fal fa-pencil"></i> Edit</button>
    </div>
    <div class="save-cancel" *ngIf="isEditing && hasMessage">
        <button class="save-button button btn__yellow" (click)="save()">Save</button>
        <button class="cancel-button" (click)="cancel()">Cancel</button>
    </div>
</div>
