import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HmrConfirmFormComponent } from './hmr-confirm-form.component';
import { ConfirmModalComponent } from './confirm-modal/confirm-modal.component';

@NgModule({
  declarations: [
    HmrConfirmFormComponent,
    ConfirmModalComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    HmrConfirmFormComponent
  ]
})
export class HmrConfirmFormModule { }