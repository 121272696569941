import { Component, Input } from "@angular/core";
import { OrderRequest } from "@profile/order-requests/models/order-request.model";
import { OrderMethod } from "@profile/order-requests/models/order.enums";

@Component({
  selector: 'app-address-tooltip',
  templateUrl: './address-tooltip.component.html',
  styleUrls: ['./address-tooltip.component.scss']
})
export class AddressTooltip {
  @Input() order: OrderRequest | undefined;

  orderMethodEnum = OrderMethod;

  get address(): string | undefined {
    return this.order?.deliveryPreference?.address;
  }

  copyAddressToClipboard() {
    navigator.clipboard.writeText(this.address);
  }
}
