import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-radio-highlight',
  templateUrl: './radio-highlight.component.html',
  styleUrls: ['./radio-highlight.component.scss']
})
export class RadioHighlightComponent implements OnInit {
  @Input() isHighlighted = false;
  @Input() fullWidth = false;
  @Input() controlName: string;
  @Input() hasError = false;
  @Input() size = 'sm';
  @Input() border = false;

  constructor() { }

  ngOnInit() {
  }

}
