import { Injectable } from '@angular/core';
import { State, Selector, Action, StateContext } from '@ngxs/store';
import { IHMRServiceProviderDetailsFormStateModel } from './hmr-service-provider-details-form-state-model.interface';
import { UpdateHMRServiceProviderDetailsForm } from './hmr-service-provider-details-form.actions';

export interface IHMRServiceProviderDetailsFormDetailedStateModel extends IHMRServiceProviderDetailsFormStateModel  {
  pharmacistGivenName: string;
  pharmacistFamilyName: string;
  ahpraNumber: string;
  pharmacistPhoneNumber: string;
  pharmacistEmail: string;
  }

  export class HMRServiceProviderDetailsFormDetailedStateModel {
    serviceProviderDetailsForm: {
      model: Partial<IHMRServiceProviderDetailsFormDetailedStateModel>;
    };
  }

  @State<HMRServiceProviderDetailsFormDetailedStateModel>({
    name: 'hmrServiceProviderDetailsFormDetailed',
    defaults: {
      serviceProviderDetailsForm: {
        model: undefined
      }
    }
  })
  @Injectable()
  export class HMRServiceProviderDetailsFormDetailedState {
    constructor() {}
    @Selector()
    static form(state: HMRServiceProviderDetailsFormDetailedStateModel) { return state.serviceProviderDetailsForm.model; }

    @Action(UpdateHMRServiceProviderDetailsForm)
    updateForm(ctx: StateContext<HMRServiceProviderDetailsFormDetailedStateModel>, { form }: UpdateHMRServiceProviderDetailsForm) {
      ctx.patchState({
        serviceProviderDetailsForm: {
          model: form || undefined
        }
      });
    }
  }
