import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { BaseApiNoAlerts } from '~/shared/core/base-api/base-api-no-alerts';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { StorageService } from '~/shared/core/storage/storage.service';
import { AlertService } from '~/modules/core/alert/alert.service';
import { Observable } from 'rxjs';
import { BulkMessage } from '~/shared/models/communication/bulk-message.model';

const API = environment.baseApiUrl;

@Injectable()
export class BulkMessagingService extends BaseApiNoAlerts {

  constructor(public http: HttpClient, public router: Router, public storageService: StorageService, public alertService: AlertService) {
    super(http, router, storageService, alertService);
  }

  getBulkMessageHistory(includeRecipients: boolean = true, page: number = null, pageSize: number = null): Observable<any> {
    let query = `includeRecipients=${includeRecipients}`;

    if(page && pageSize) {
      query += `&page=${page}&pageSize=${pageSize}`;
    }
    return this.get(`${API}/api/bulk-communication/history?${query}`);
  }

  getUpcomingBulkMessages(includeRecipients: boolean = true): Observable<any> {
    return this.get(`${API}/api/bulk-communication/upcoming?includeRecipients=${includeRecipients}`);
  }

  newBulkMessage(bulkMessage: BulkMessage): Observable<any> {
    return this.post(`${API}/api/bulk-communication`, bulkMessage);
  }

  updateBulkMessage(bulkMessage: BulkMessage): Observable<any> {
    return this.put(`${API}/api/bulk-communication`, bulkMessage);
  }

  removeBulkMessage(bulkMessageId: number): Observable<any> {
    return this.delete(`${API}/api/bulk-communication/${bulkMessageId}`);
  }

  matchNumbers(numbers: string[]): Observable<any> {
    return this.post(`${API}/api/bulk-communication/match`, numbers);
  }

  matchNumber(number: string): Observable<any> {
    return this.get(`${API}/api/bulk-communication/match?number=${number}`);
  }

  getMessageWithRecipients(bulkMessageId: number): Observable<any> {
    return this.get(`${API}/api/bulk-communication/${bulkMessageId}/recipients`);
  }

}
