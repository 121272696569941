import { Component, OnInit, Input } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Select} from '@ngxs/store';
import { Observable } from 'rxjs';
import { RedirectService } from '../../../shared/services/redirect.service';
import { AttendingDoctor } from '../attending-doctor/model/attending-doctor';
import { AttendingDoctorState } from '../attending-doctor/state/attending-doctor.state';
@Component({
  selector: 'app-report-blade',
  templateUrl: './report-blade.component.html'
})
export class ReportBladeComponent implements OnInit {
  @Input() customer: any;
  isLoading = false;
  todayDate = new Date();
  @Select(AttendingDoctorState.patientDoctors) doctors$: Observable<Array<AttendingDoctor>>;

  constructor(private redirectService: RedirectService) { }

  ngOnInit() {
  }

  onSubmit(form: NgForm) {
    this.isLoading = true;
    const { categories, repeatsOptions, doctor, type, includeDOH, neededBy } = form.value;
    let doctorId;

    if (!doctor) {
      doctorId = [];
    } else {
      doctorId = [doctor];
    }
    const param = {
      clientId: this.customer.clientId,
      type: type,
      selectedCategories: categories,
      selectedRepeatOptions: repeatsOptions,
      doctors: doctorId,
      includeDOH,
      neededBy: neededBy ? neededBy.toISOString() : null
    };
    this.redirectService.post(param, '/Report/Customer');
  }
}
