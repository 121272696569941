<ng-container *ngIf="(optOutState$ | async) as optOutState">
  <div class="container" style="max-width: 600px;">
    <div class="scrypt text-center">
      <img src="./assets/img/scrypt-vector.svg" /><br />
    </div>
    <div class="row">
      <h1>Unsubscribe</h1>
    </div>
    <div class="mt-2">
      <form [formGroup]="optOutForm">
        <h6 class="no-left-margin">Please provide details to remove yourself from auto-communications from {{pharmacyName}}:</h6>
        <div class="row">
          <div class="mt-2">
            <div class="form-group">
              <div class="form-group">
                <label>Mobile</label>
                <input class="form-control" formControlName="mobileNumber" type="number" readonly />
              </div>
            </div>
          </div>
        </div>
        <!-- <h6 class="mt-3 no-left-margin">Unsubscribe me from:
        </h6>
        <div class="row">
          <div class="mt-2">
            <div class="form-check">
              <input class="form-check-input" formControlName="optOutType" type="radio" [value]="optOutTypes.all" />
              <label class="form-check-label">All communications</label>
            </div>
            <div class="form-check">
              <input class="form-check-input" formControlName="optOutType" type="radio"
                [value]="optOutTypes.autoComms" />
              <label class="form-check-label">Automatic script reminders</label>
            </div>
            <div class="form-check">
              <input class="form-check-input" formControlName="optOutType" type="radio"
                [value]="optOutTypes.campaigns" />
              <label class="form-check-label">Campaigns &amp; service invitations</label>
            </div>
          </div>
        </div> -->
        <div *ngIf="optOutState.loading">
          <app-loader [loadingMessage]="optOutState.loadingMessage"></app-loader>
        </div>
        <h6 class="mt-3 no-left-margin">Add reason for unsubscribing <span>(Optional)</span></h6>
        <div class="row">
          <div class="mt-2">
            <div class="form-group">
              <textarea class="form-control text-box-350" formControlName="optOutReason" rows="3">
            </textarea>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="mt-2">
            <div class="form-group">
              <button [disabled]="optOutForm.status === 'INVALID'" (click)="submitOptOutForm()"
                class="btn unsubscribe">Unsubscribe</button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</ng-container>
