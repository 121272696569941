<div *ngIf="(newMessageState$ | async) as state" class="p-3 mb-5 form-body">
  <form [formGroup]="newBulkMessageForm" ngxsForm="newBulkMessageFormState.newBulkMessageForm"
    (ngSubmit)="saveMessage($event, state.isEditing)">
    <div *ngIf="matching">
      <app-loader [loadingMessage]="state.loadingMessage"></app-loader>
    </div>
    <div class="row">
      <!-- LEFT -->
      <div class="col-md-7 divider">
        <h4>Create your Message</h4>
        <div class="form-row d-flex justify-content-between">
          <div class="form-group col-md-8">
            <select formControlName="chosenTemplate" (change)="templateChanged()" class="form-control">
              <option [ngValue]="null" selected>Choose a template</option>
              <option *ngFor="let template of state.templates" [ngValue]="template">{{template.name}}</option>
            </select>
          </div>
          <div class="form-group align-self-right mr-2">
            <button class="btn btn-outline-primary" (click)="$event.preventDefault();saveAsTemplate()">
              <i class="fal fa-save save"></i>&nbsp;
              Save as Template
            </button>
          </div>
        </div>
        <hr class="mx-3" />
        <div class="form-row">
          <div class="form-group col-md-12">
            <div class="form-row d-flex justify-content-between">
              <div class="ml-2">
                <label class="font__bold" g-xs="mt2 mb1">Message</label>
              </div>
              <div class="form-group col-md-3">
                <select class="form-control" (change)="insertTag($event)">
                  <option [value]="null" selected>Merge Tags</option>
                  <option *ngFor="let tag of mergeTags" [value]="tag.value">
                    {{tag.key}}</option>
                </select>
              </div>
            </div>
            <textarea class="form-control" #messageTextarea formControlName="message" rows="8" required></textarea>
          </div>
        </div>
        <div class="form-row d-flex justify-content-between mx-1 mb-3">
          <div>Are you sending a marketing message?
            <i class="far fa-info-circle ml-1"
              tooltip="Marketing messages must include an opt-out option and comply with relevant privacy and spam laws.">
            </i>
          </div>
          <div>Approx. {{getCharacterCount()}} characters = {{getMessageCount()}} SMS/Recipient </div>
        </div>
        <div class="form-row" *ngIf="urlDetected">
          <div class="form-group col-md-12">
            We've detected a URL link in your message, this will be shortened to a 25 character short link for you
            automatically. <i class="fas fa-check-circle success-color"></i><br>
            Your character count has been adjusted for the shortened URL link. <i
              class="fas fa-check-circle success-color"></i><br>
            Your message will be clearer and easier to read for your customers. <i
              class="fas fa-check-circle success-color"></i><br>
          </div>
        </div>
      </div>

      <!-- RIGHT -->
      <div class="col-md-5">
        <h4>Build your Contact List</h4>

        <div>
          <div class="form-row">
            <div class="form-group mr-1">
              <label class="font__bold" g-xs="mt3 mb1">Add manual number</label>
              <input class="form-control"
                [ngClass]="{'is-valid': numberValid(manualNumber.value),'is-invalid': !!manualNumber.value && !numberValid(manualNumber.value) }"
                type="text" name="manualNumber" formControlName="manualNumber"
                (keydown.enter)="$event.preventDefault();addManual()" placeholder="0400000000" />
            </div>
            <div class="form-group">
              <label g-xs="mt3 mb1"></label>
              <button type="button" (click)="addManual()" [disabled]="!numberValid(manualNumber.value)"
                class="form-control mt-3">
                <i class="fas fa-plus"></i>
              </button>
            </div>
          </div>
          <div class="form-row d-flex justify-content-between">
            <div>
              <input id="fileSelector" class="d-none mr-2" type="file" accept=".csv" formControlName="csvFile"
                (change)="$event.preventDefault();importCSV($event);" />
              <button class="btn btn-outline-primary" (click)="$event.preventDefault();openDialog()">
                <i class="fal fa-file-import"></i>&nbsp;
                Import from File
              </button>
            </div>
            <div class="d-flex align-items-center">
              <a class="link" routerLink="/advanced-search" g-xs="mr2">
                Advanced search &nbsp;<i class="fas fa-search"></i>
              </a>
            </div>
          </div>

          <div class="table-wrapper">
            <nz-table #recipients [nzData]="filteredRecipientList" [nzScroll]="{ y: '350px'}" nzSize="small"
              [nzFrontPagination]="false" [nzShowScrollbar]="false">
              <thead>
                <tr>
                  <th nzLeft nzColumnKey="destination" [nzSortFn]="phoneNumberSort">Phone Numbers</th>
                  <th nzLeft nzColumnKey="customerName" [nzSortFn]="nameSort">Name</th>
                  <th nzAlign="center" nzWidth="100px"> <a nz-button nzType="link" nzDanger class="color__red"
                      (click)="clearCSVData($event)">
                      Clear list
                    </a></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let data of recipients.data">
                  <td [ngClass]="numberValid(data.destination) ? '' : 'bg-invalid'">
                    {{data.destination}}
                  </td>
                  <td>
                    <span *ngIf="data.customerName">{{data.customerName}}</span>
                    <span *ngIf="!data.customerName">Unknown</span>
                  </td>
                  <td nzAlign="center" class="pointer" (click)="removeRecipient(data)">
                    <span><i class="fal fa-times-circle pointer danger-color"></i></span>
                  </td>
                </tr>
              </tbody>
            </nz-table>
          </div>

          <div *ngIf="hasInvalidNumbers" class="alert alert-warning mb-2 mt-2">
            There are invalid numbers that will be removed automatically when saved. These numbers are highlighted in
            the list above.
            <br>
            <a class="remove-invalid" (click)="cleanAll()">Remove invalid numbers now</a>
          </div>
          <div *ngIf="hasDuplicates()" class="alert alert-warning mb-2 mt-2 pointer" (click)="showDuplicates()">
            Duplicate numbers found in the list, click to show
          </div>

          <div class="form-row d-flex justify-content-between">
            <div class="form-group w-50">
              <label class="font__bold pl-1" g-xs="mb1">Search List</label>
              <input class="form-control" type="text" formControlName="recipientSearch"
                placeholder="Search name or phone number" />
            </div>
            <div>
              <label class="font__bold mr-2" g-xs="mb1">
                Total Contacts: <span>{{this.newBulkMessageForm.value.recipients?.length}}</span>
              </label>

            </div>
          </div>
        </div>
      </div>
    </div>

    <hr class="mt-5 mb-3" />

    <!-- BOTTOM -->
    <div class="d-flex justify-content-end flex-row mr-5">
      <h4>Send Now or Schedule your Message</h4>
    </div>
    <div class="d-flex justify-content-end flex-row mb-2">
      <nz-date-picker nzFormat="dd-MM-yyyy h:mm a" id="date" [nzShowNow]="true" formControlName="sendDate"
        nzPlaceHolder="dd-mm-yyyy" [nzDisabledDate]="disabledDates" [nzShowTime]="{ 
          nzUse12Hours: true, 
          nzMinuteStep: 5, 
          nzHideDisabledOptions: false, 
          nzSecondStep: 30, 
          nzFormat: 'h:mm a' 
          }" class="mr-2 w-25" nzSize="large">
      </nz-date-picker>
      <button *ngIf="!isFuture" class="btn btn-success" (click)="sendMessageNow($event, state.isEditing)"
        [disabled]="newBulkMessageForm.invalid || (isLoading$ | async)">
        <i class="fal fa-paper-plane"></i>&nbsp;
        Send Now
      </button>
      <button *ngIf="isFuture" class="btn btn-warning" type="submit"
        [disabled]="newBulkMessageForm.invalid || (isLoading$ | async)">
        <i class="fal fa-paper-plane"></i>&nbsp;
        Schedule
      </button>

      <button *ngIf="state.isEditing" class="btn btn-danger ml-2" type="button" (click)="closeFormEdit()">
        Cancel
      </button>
    </div>
  </form>
</div>
<hr />

<!-- DUPLICATE RECIPIENTS MODAL -->
<nz-modal [(nzVisible)]="isDuplicatesModalVisible" (nzOnCancel)="handleCancel()" (nzOnOk)="handleOk()"
  [nzCentered]="true" [nzTitle]="modalTitle" [nzContent]="modalContent" [nzFooter]="modalFooter">
  <ng-template #modalTitle>Select the customers to keep in contact list</ng-template>

  <ng-template #modalContent>
    <div *ngFor="let group of duplicateData" class="mb-3">
      <div class="p-2 font-weight-bold bg-light d-flex justify-content-between align-items-center">
        <span>Phone Number: {{ group.destination }}</span>
      </div>

      <nz-table #recipientsTable [nzData]="group.recipients" nzSize="small" [nzShowPagination]="false">
        <thead>
          <tr>
            <th nzWidth="40px">
              <label nz-checkbox [ngModel]="isAllCheckedForGroup(group)"
                (ngModelChange)="checkAllForGroup($event, group)">
              </label>
            </th>
            <th>Customer Name</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of recipientsTable.data">
            <td nzWidth="40px">
              <label nz-checkbox [(ngModel)]="item.selected" (ngModelChange)="updateGroupSelection(group)">
              </label>
            </td>
            <td>
              <a *ngIf="item.recipient.customerName; else unmatched" routerLink="/profile/{{item.recipient.clientId}}"
                target="_blank">
                {{item.recipient.customerName}}
              </a>
              <ng-template #unmatched>
                <span>
                  Un-matched customer
                </span>
              </ng-template>

            </td>
          </tr>
        </tbody>
      </nz-table>
    </div>
  </ng-template>

  <ng-template #modalFooter>
    <div class="d-flex justify-content-end">
      <button class="btn btn-outline-primary mr-2" (click)="handleCancel()">Cancel</button>
      <button class="btn btn-warning" [disabled]="!hasSelectedRecipients()"
        (click)="removeAllExceptSelectedRecipients()">
        Keep Selected
      </button>
    </div>

  </ng-template>
</nz-modal>

<app-add-edit-comm-template-modal></app-add-edit-comm-template-modal>