import { State, Action, StateContext, Selector, Store } from '@ngxs/store';
import { OpenPauseMedicationBlade, ClosePauseMedicationBlade, PauseMedication, PauseMedicationSuccess, PauseMedicationError, UnPauseMedication, PauseBulkMedication, UnPauseBulkMedication, PauseBulkMedicationSuccess, GetPausedMedicatonsForProfile, GetPausedMedicatonsSuccess, OpenPauseListBlade, ClosePauseListBlade } from './pause-medication.actions';
import { PauseMedicationService } from '../services/pause-medication.service';
import { catchError, finalize, switchMap } from 'rxjs/operators';
import { AlertService } from '../../../../core/alert/alert.service';
import { RefreshCategoriesAction, SetSelectedDrugsPausedStatus } from '../../state/categories.actions';
import { ProfileState } from '../../../../core/profile/state/profile.state';
import { ClearSelectedDrugsAction } from '../../multiple-select/state/multiple-drugs.actions';
import { PausedMedicationModel } from '../../../../../shared/models/pause-medication-request.model';
import { UpdateScriptPausedState } from '../../state/script-actions.actions';
import { Injectable } from '@angular/core';

export class PauseMedicationStateModel {
  show: boolean;
  showAllPauses: boolean;
  pausingMedication: boolean;
  pausedMedications: PausedMedicationModel[];
}
@Injectable()
@State<PauseMedicationStateModel>({
  name: 'pauseMedication',
  defaults: {
    show: false,
    pausingMedication: false,
    pausedMedications: [],
    showAllPauses: false
  }
})
export class PauseMedicationState {

  constructor(private pauseMedicationService: PauseMedicationService, private alertService: AlertService, private store: Store) { }

  @Selector()
  static showPauseMedicationBlade(state: PauseMedicationStateModel) { return state.show };

  @Selector()
  static showAllPauses(state: PauseMedicationStateModel) { return state.showAllPauses };

  @Selector()
  static pausingMedication(state: PauseMedicationStateModel) { return state.pausingMedication }

  @Selector()
  static pausedMedications(state: PauseMedicationStateModel) { return state.pausedMedications }

  @Action(OpenPauseMedicationBlade)
  showPauseMedicationBlade(ctx: StateContext<PauseMedicationStateModel>, action: OpenPauseMedicationBlade) {
    ctx.patchState({
      show: true
    });
  };

  @Action(ClosePauseMedicationBlade)
  closePauseMedicationBlade(ctx: StateContext<PauseMedicationStateModel>, action: ClosePauseMedicationBlade) {
    ctx.patchState({
      show: false
    });
  };

  @Action(OpenPauseListBlade)
  openPauseListBlade(ctx: StateContext<PauseMedicationStateModel>, action: OpenPauseListBlade) {
    ctx.patchState({
      showAllPauses: true
    });
  };

  @Action(ClosePauseListBlade)
  closePauseListBlade(ctx: StateContext<PauseMedicationStateModel>, action: ClosePauseListBlade) {
    ctx.patchState({
      showAllPauses: false
    });
  };

  @Action(GetPausedMedicatonsForProfile)
  getPausedMedicatonsForProfile(ctx: StateContext<PauseMedicationStateModel>, { clientId }: GetPausedMedicatonsForProfile) {
    return this.pauseMedicationService.getPausedMedications(clientId).pipe(
      switchMap((response) => ctx.dispatch(new GetPausedMedicatonsSuccess(response))),
      catchError(errors => ctx.dispatch(new PauseMedicationError(errors))));
  };

  @Action(PauseMedication)
  pauseMedication(ctx: StateContext<PauseMedicationStateModel>, { request }: PauseMedication) {
    ctx.patchState({ pausingMedication: true });
    return this.pauseMedicationService.pauseMedication(request).pipe(
      switchMap(() => ctx.dispatch(new PauseMedicationSuccess(true))),
      catchError(errors => ctx.dispatch(new PauseMedicationError(errors))),
      finalize(() => {
        ctx.patchState({ pausingMedication: false });
      }));
  };

  @Action(UnPauseMedication)
  unPauseMedication(ctx: StateContext<PauseMedicationStateModel>, { request }: UnPauseMedication) {
    ctx.patchState({ pausingMedication: true });
    return this.pauseMedicationService.unPauseMedication(request).pipe(
      switchMap(() => ctx.dispatch(new PauseMedicationSuccess(false))),
      catchError(errors => ctx.dispatch(new PauseMedicationError(errors))),
      finalize(() => {
        ctx.patchState({ pausingMedication: false });
      }));
  };

  @Action(PauseBulkMedication)
  pauseBulkMedication(ctx: StateContext<PauseMedicationStateModel>, { request }: PauseBulkMedication) {
    ctx.patchState({ pausingMedication: true });
    return this.pauseMedicationService.pauseBulkMedication(request).pipe(
      switchMap(() => ctx.dispatch(new PauseBulkMedicationSuccess(true))),
      catchError(errors => ctx.dispatch(new PauseMedicationError(errors))),
      finalize(() => {
        ctx.patchState({ pausingMedication: false });
      }));
  };

  @Action(UnPauseBulkMedication)
  unPauseBulkMedication(ctx: StateContext<PauseMedicationStateModel>, { request }: UnPauseBulkMedication) {
    ctx.patchState({ pausingMedication: true });
    return this.pauseMedicationService.unPauseBulkMedication(request).pipe(
      switchMap(() => ctx.dispatch(new PauseBulkMedicationSuccess(false))),
      catchError(errors => ctx.dispatch(new PauseMedicationError(errors))),
      finalize(() => {
        ctx.patchState({ pausingMedication: false });
      }));
  };

  @Action(PauseMedicationSuccess)
  pauseMedicationSuccess(ctx: StateContext<PauseMedicationStateModel>, action: PauseMedicationSuccess) {
    const clientId = this.store.selectSnapshot(ProfileState.clientId);
    this.alertService.success('Success')
    ctx.dispatch([
      new ClosePauseMedicationBlade(),
      new UpdateScriptPausedState(action.status),
      new ClearSelectedDrugsAction(),
      new RefreshCategoriesAction(clientId)
    ]);
  };

  @Action(PauseBulkMedicationSuccess)
  pauseBulkMedicationSuccess(ctx: StateContext<PauseMedicationStateModel>, action: PauseBulkMedicationSuccess) {
    const clientId = this.store.selectSnapshot(ProfileState.clientId);
    this.alertService.success('Medications successfully paused/resumed');
    ctx.dispatch([
      new ClosePauseMedicationBlade(),
      new ClearSelectedDrugsAction(),
      new SetSelectedDrugsPausedStatus(action.status),
      new RefreshCategoriesAction(clientId)
    ]);
  };


  @Action(GetPausedMedicatonsSuccess)
  getPausedMedicatonsSuccess(ctx: StateContext<PauseMedicationStateModel>, { medications }: GetPausedMedicatonsSuccess) {
    ctx.patchState({
      pausedMedications: medications
    });
  };

  @Action(PauseMedicationError)
  pauseMedicationError(ctx: StateContext<PauseMedicationStateModel>, { error }: PauseMedicationError) {
    this.alertService.error(error.error);
  };
}
