import { Component, OnInit } from '@angular/core';

const PATIENT_CONSENT_FORM_LINK = 'https://www.ppaonline.com.au/wp-content/uploads/2019/01/MedsCheck-Patient-Information-and-Consent.pdf';

@Component({
  selector: 'app-patient-signature-form',
  templateUrl: './patient-signature-form.component.html'
})
export class PatientSignatureFormComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  openPPAForm() {
    window.open(PATIENT_CONSENT_FORM_LINK, '_blank');
  }
}
