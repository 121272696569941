import { Pipe, PipeTransform } from '@angular/core';
import { CommTemplate, TemplateType } from '../../../../shared/models/pharmacy/comm-template.model';
import { Sort } from '../../../../shared/models/sort.enum';

@Pipe({
  name: 'commTemplatesFilter'
})
export class CommTemplatesFilterPipe implements PipeTransform {

  transform(commTemplateList: CommTemplate[], groupBy: TemplateType, searchQuery: string = '', sortBy: Sort = Sort.Newest): CommTemplate[] {
    let res = commTemplateList
      .filter(item => item.templateType === groupBy)
      .filter(item => item.name.toLowerCase().includes(searchQuery) || item.message.toLowerCase().includes(searchQuery));
    switch (sortBy) {
      case Sort.Newest:
        res = res.sort((a, b) => this.sortFunc(a, b, 'createdDate'));
        break;
      case Sort.Oldest:
        res = res.sort((a, b) => this.sortFunc(a, b, 'createdDate')).reverse();
        break;
      case Sort.Alphabetical:
        res = res.sort((a, b) => this.sortFunc(a, b, 'name')).reverse();
        break;
      case Sort.AlphabeticalReverse:
        res = res.sort((a, b) => this.sortFunc(a, b, 'name'));
        break;
    }
    return res;
  }
  private sortFunc(a: CommTemplate, b: CommTemplate, prop: string) {
    if (a[prop] > b[prop]) { return -1; }
    if (a[prop] < b[prop]) { return 1; }
    return 0;
  }
}
