import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { MedsCheckType } from '../../state/meds-check-form-list.state';
// import { environment } from '../../../../../../../environments/environment';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss']
})
export class ConfirmModalComponent implements OnInit {

  constructor() { }
  @Input() medsCheckClaimType: any;
  @Input() patientHasDiabetes: boolean;
  @Output() submitEvent = new EventEmitter<any>();
  @Output() closeEvent = new EventEmitter<any>();
  // CPA_Version = environment.CPAVersion;
  CPA_Version = '';

  ngOnInit() {
  }

  close() {
    this.closeEvent.emit();
  }

  submit() {
    this.submitEvent.emit();
  }

  getMedsCheckFormType(medsCheckFormType: MedsCheckType) {
    switch (medsCheckFormType) {
      case MedsCheckType.PatientRegistration:
        if (this.patientHasDiabetes) {
          return 'Diabetes MedsCheck Claim';
        } else {
          return 'Regular MedsCheck Claim';
        }
      case MedsCheckType.Regular:
        return 'Regular MedsCheck Claim';
      case MedsCheckType.Diabetes:
        return 'Diabetes MedsCheck Claim';
      default:
        return 'Meds Check';
    }
  }
}
