export interface AslMedication {
    id: string;
    privateNonSwitchable: boolean | null;
    scriptClass: string;
    scriptType: string;
    itemStatus: string;
    dispensedItemDescription: string;
    originalPharmacyApprovalNumber: string;
    extempDescription: string;
    privacyStatement: boolean | null;
    quantity: string | null;
    isExtemp: boolean | null;
    medicationGenericName: string;
    medicationBrandName: string;
    rtacId: string;
    isEmergencySupply: boolean | null;
    controlledSubstanceReference: string;
    unusualDoseFlag: boolean | null;
    privatePrescription: boolean | null;
    prescribedDate: Date;
    dispensedDate: Date | null;
    unusualQtyFlag: boolean | null;
    status: MedicationStatus | null;
    patientReference: string;
    patientName: string;
    authoredOn: string | null;
    directions: string;
    repeats: number;
    substitutionAllowed: boolean | null;
    token: string;
    isPaperless: boolean;
    timesDispensed: number | null;
    repeatsRemaining: number;
    repeatTokenUri: string | null;
}

export enum MedicationStatus {
    Active,
    OnHold,
    Cancelled,
    Completed,
    EnteredInError,
    Stopped,
    Draft,
    Unknown
}
