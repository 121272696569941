import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngxs/store';
import { AuthSetClientAction } from '../core/auth/state/auth.actions';

@Component({
  selector: 'app-document',
  templateUrl: './document.component.html',
  styleUrls: ['./document.component.scss']
})
export class DocumentComponent implements OnInit {

  constructor(private activatedRoute: ActivatedRoute, private store: Store) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      this.store.dispatch(new AuthSetClientAction(params.clientId));
   });
  }

}
