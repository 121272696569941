<div>
    <div class="form-group flex blade__period" *ngIf="isListLoaded && (selectedCustomer > 0)">
        <div>
            <select (change)="changeSelectedCustomer($event.target.value)" id="customerProfileId" name="customerProfile" class="form-control border__grey-light">
                <option *ngFor="let customerProfile of customerProfiles" value={{customerProfile.id}}>
                {{customerProfile.firstName}} {{customerProfile.lastName}}
              </option>
            </select>
        </div>
        <div>
            <button (click)="generateCode()" [disabled]="!selectedCustomer || selectedCustomer == 0" class="form-control">Generate Code</button>
        </div>
    </div>

    <div class="filter-loading-spinner" *ngIf="!isListLoaded">
        <section>
            <app-loader></app-loader>
        </section>
    </div>
</div>