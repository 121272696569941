import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { GetProfileAction } from './state/profile.actions';
import { Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { GoBackFromConfirmMedsCheckAction } from '~/modules/profile/six-cpa/confirm-meds-check/state/confirm-meds-check.actions';
import { GetNoteTypes } from '~/modules/profile/notes/state/notes.actions';
import { ClearSixCpaCommunications } from '~/modules/profile/six-cpa/six-cpa-user-communications/state/six-cpa-user-communications.actions';
import { ClearAIRLookup } from '~/modules/profile/six-cpa/AIR/air-lookup-form/state/air-lookup-form.actions';
import { ClearAIRHistoryStatement } from '~/modules/profile/six-cpa/AIR/air-statement/state/air-statement.actions';

@Injectable()
export class ProfileResolver implements Resolve<Observable<any>> {
  constructor(private store: Store) { }
  resolve(route: ActivatedRouteSnapshot): Observable<any> {
    const clientId = route.params.clientId;
    return this.store.dispatch([
      new GetProfileAction(clientId),
      new GoBackFromConfirmMedsCheckAction(),
      new ClearSixCpaCommunications(),
      new GetNoteTypes(),
      new ClearAIRLookup(),
      new ClearAIRHistoryStatement()
    ]);
  }
}
