<form [formGroup]="sickLeaveConsulationForm" ngxsForm="sickLeaveConsulation.sickLeaveConsulationForm" class="p-4">
  <div class="form-group pb-1">
    <label class="input-label" for="whatIsTheIllness">What is the illness/injury as described by the
      person/member?</label>
    <input type="text" class="form-control form-control-lg w-50" formControlName="whatIsTheIllness" value=""
    [class.has-error]="formControls.whatIsTheIllness.invalid && (showInvalid$ | async)"
      id="whatIsTheIllness">
  </div>
  <div class="form-group pb-1">
    <label class="input-label" for="describeVisualSymptoms">Describe the visual symptoms of the illness/injury?</label>
    <input type="text" class="form-control form-control-lg w-50" formControlName="describeVisualSymptoms" value=""
    [class.has-error]="formControls.describeVisualSymptoms.invalid && (showInvalid$ | async)"
      id="describeVisualSymptoms">
  </div>
  <div class="form-group pb-1">
    <label class="input-label" for="howLongExperiencedSymptoms">How long has the person/member experienced these
      symptoms for?
    </label>
    <div class="d-flex">
      <select formControlName="howLongExperiencedSymptomsInterval" class="form-control form-control-lg w-25 mr-3"
      [class.has-error]="formControls.howLongExperiencedSymptomsInterval.invalid && (showInvalid$ | async)">
        <option *ngFor="let item of howLongInterval" [value]="item">{{item}}</option>
      </select>
      <select formControlName="howLongExperiencedSymptomsPeriod" class="form-control form-control-lg w-25"
      [class.has-error]="formControls.howLongExperiencedSymptomsPeriod.invalid && (showInvalid$ | async)">
        <option [value]="periods.Hours">{{periods.Hours}}</option>
        <option [value]="periods.Days">{{periods.Days}}</option>
        <option [value]="periods.Weeks">{{periods.Weeks}}</option>
      </select>
    </div>
  </div>
  <div class="form-group pb-1" (change)="deselectControl('detailOfConsultation')">
    <label class="input-label mb-0">Has the person/member consulted a doctor in relation to
      the illness/injury?
    </label><br />
    <app-radio-highlight controlName="hasPersonConsultedADoctorYes"
    [hasError]="formControls.hasPersonConsultedADoctor.invalid && (showInvalid$ | async)"
      [isHighlighted]="sickLeaveConsulationForm.value.hasPersonConsultedADoctor == true">
      <input class="form-check-input" type="radio" name="hasPersonConsultedADoctor" id="hasPersonConsultedADoctorYes"
        formControlName="hasPersonConsultedADoctor" [value]="true" checked>
      <label class="form-check-label" for="hasPersonConsultedADoctorYes">Yes</label>
    </app-radio-highlight>
    <app-radio-highlight controlName="hasPersonConsultedADoctorNo"
    [hasError]="formControls.hasPersonConsultedADoctor.invalid && (showInvalid$ | async)"
      [isHighlighted]="sickLeaveConsulationForm.value.hasPersonConsultedADoctor == false">
      <input class="form-check-input" type="radio" name="hasPersonConsultedADoctor" id="hasPersonConsultedADoctorNo"
        formControlName="hasPersonConsultedADoctor" [value]="false">
      <label class="form-check-label" for="hasPersonConsultedADoctorNo">No</label>
    </app-radio-highlight>
  </div>
  <div class="form-group pb-1" *ngIf="sickLeaveConsulationForm.value.hasPersonConsultedADoctor">
    <label class="input-label" for="detailOfConsultation">Detail of consultation
    </label>
    <input type="text" class="form-control form-control-lg w-50"
    [class.has-error]="formControls.detailOfConsultation.invalid && (showInvalid$ | async)"
      formControlName="detailOfConsultation"
      id="detailOfConsultation">
  </div>
  <div class="form-group pb-1" (change)="deselectControl('dateOfCertificate')">
    <label class="input-label mb-0">Is there a prior certificate?
    </label><br />
    <app-radio-highlight controlName="isThereAPriorCertificateYes"
    [hasError]="formControls.isThereAPriorCertificate.invalid && (showInvalid$ | async)"
      [isHighlighted]="sickLeaveConsulationForm.value.isThereAPriorCertificate == true">
      <input class="form-check-input" type="radio" name="isThereAPriorCertificate" id="isThereAPriorCertificateYes"
        formControlName="isThereAPriorCertificate" [value]="true" checked>
      <label class="form-check-label" for="isThereAPriorCertificateYes">Yes</label>
    </app-radio-highlight>
    <app-radio-highlight controlName="isThereAPriorCertificateNo"
    [hasError]="formControls.isThereAPriorCertificate.invalid && (showInvalid$ | async)"
      [isHighlighted]="sickLeaveConsulationForm.value.isThereAPriorCertificate == false">
      <input class="form-check-input" type="radio" name="isThereAPriorCertificate" id="isThereAPriorCertificateNo"
        formControlName="isThereAPriorCertificate" [value]="false">
      <label class="form-check-label" for="isThereAPriorCertificateNo">No</label>
    </app-radio-highlight>
  </div>
  <div class="form-group pb-1" *ngIf="sickLeaveConsulationForm.value.isThereAPriorCertificate">
    <label for="dateOfCertificate" class="input-label">Date of Certificate</label>
    <input type="text" class="form-control form-control-lg w-25"
    [class.has-error]="formControls.dateOfCertificate.invalid && (showInvalid$ | async)"
      value="sickLeaveConsulationForm.value.dateOfCertificate | date: 'DD-MM-YYYY'"
      formControlName="dateOfCertificate" id="dateOfCertificate" [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY' }"
      placeholder="dd-mm-yyyy" bsDatepicker>
  </div>
  <div class="form-group pb-1" (change)="deselectControl('medicationDetail')">
    <label class="input-label mb-0">Is a medication being provided by you (the
      pharmacist) for the person's/member's illness/injury?
    </label><br />
    <app-radio-highlight controlName="isAMedicationBeingProvidedByYouYes"
    [hasError]="formControls.isAMedicationBeingProvidedByYou.invalid && (showInvalid$ | async)"
      [isHighlighted]="sickLeaveConsulationForm.value.isAMedicationBeingProvidedByYou == true">
      <input class="form-check-input" type="radio" name="isAMedicationBeingProvidedByYou" id="isAMedicationBeingProvidedByYouYes"
        formControlName="isAMedicationBeingProvidedByYou" [value]="true" checked>
      <label class="form-check-label" for="isAMedicationBeingProvidedByYouYes">Yes</label>
    </app-radio-highlight>
    <app-radio-highlight controlName="isAMedicationBeingProvidedByYouNo"
    [hasError]="formControls.isAMedicationBeingProvidedByYou.invalid && (showInvalid$ | async)"
      [isHighlighted]="sickLeaveConsulationForm.value.isAMedicationBeingProvidedByYou == false">
      <input class="form-check-input" type="radio" name="isAMedicationBeingProvidedByYou" id="isAMedicationBeingProvidedByYouNo"
        formControlName="isAMedicationBeingProvidedByYou" [value]="false">
      <label class="form-check-label" for="isAMedicationBeingProvidedByYouNo">No</label>
    </app-radio-highlight>
  </div>
  <div class="form-group pb-1" *ngIf="sickLeaveConsulationForm.value.isAMedicationBeingProvidedByYou">
    <label class="input-label" for="medicationDetail">Medication Detail
    </label>
    <input type="text" class="form-control form-control-lg w-50"
      formControlName="medicationDetail"
      [class.has-error]="formControls.medicationDetail.invalid && (showInvalid$ | async)"
      id="medicationDetail">
  </div>
  <div class="form-group pb-1">
    <label class="input-label mb-0">Within your capacity as a pharmacist and based on the available information, are you
      satisfied the person is unfit for work or the member is sufficiently ill/injured to require care?
    </label><br />
    <app-radio-highlight controlName="satisfiedWorkYes"
    [hasError]="formControls.satisfiedThePersonIsUnfitForWork.invalid && (showInvalid$ | async)"
      [isHighlighted]="sickLeaveConsulationForm.value.satisfiedThePersonIsUnfitForWork == true">
      <input class="form-check-input" type="radio" name="satisfiedThePersonIsUnfitForWork" id="satisfiedWorkYes"
        formControlName="satisfiedThePersonIsUnfitForWork" [value]="true" checked>
      <label class="form-check-label" for="satisfiedWorkYes">Yes</label>
    </app-radio-highlight>
    <app-radio-highlight controlName="satisfiedWorkNo"
    [hasError]="formControls.satisfiedThePersonIsUnfitForWork.invalid && (showInvalid$ | async)"
      [isHighlighted]="sickLeaveConsulationForm.value.satisfiedThePersonIsUnfitForWork == false">
      <input class="form-check-input" type="radio" name="satisfiedThePersonIsUnfitForWork" id="satisfiedWorkNo"
        formControlName="satisfiedThePersonIsUnfitForWork" [value]="false">
      <label class="form-check-label" for="satisfiedWorkNo">No</label>
    </app-radio-highlight>
  </div>
  <div class="form-group pb-1">
      <label class="input-label mb-0">Days covered on the certificate
      </label><br />
      <app-radio-highlight controlName="certificateCoverOneDay"
      [hasError]="formControls.certificateCoverDays.invalid && (showInvalid$ | async)"
        [isHighlighted]="sickLeaveConsulationForm.value.certificateCoverDays == certificateCoverDays.OneDay">
        <input class="form-check-input" type="radio" name="certificateCoverDays" id="certificateCoverOneDay"
          formControlName="certificateCoverDays" [value]="certificateCoverDays.OneDay" checked (change)="updateDates(certificateCoverDays.OneDay)">
        <label class="form-check-label" for="certificateCoverOneDay">1 Day</label>
      </app-radio-highlight>
      <app-radio-highlight controlName="certificateCoverTwoDays"
      [hasError]="formControls.certificateCoverDays.invalid && (showInvalid$ | async)"
      [isHighlighted]="sickLeaveConsulationForm.value.certificateCoverDays == certificateCoverDays.TwoDays">
      <input class="form-check-input" type="radio" name="certificateCoverDays" id="certificateCoverTwoDays"
        formControlName="certificateCoverDays" [value]="certificateCoverDays.TwoDays" (change)="updateDates(certificateCoverDays.TwoDays)">
      <label class="form-check-label" for="certificateCoverTwoDays">2 Days</label>
    </app-radio-highlight>
    </div>
  <div class="form-group pb-1">
    <label class="input-label">Dates covered on the certificate</label>
    <div class="d-flex">
      <div class="mr-3 w-25">
          <label for="dateCertificateFrom" class="input-label-date">From</label>
          <input type="text" class="form-control form-control-lg"
          [class.has-error]="formControls.dateCertificateFrom.invalid && (showInvalid$ | async)"
          value="sickLeaveConsulationForm.value.dateCertificateFrom | date: 'DD-MM-YYYY'"
          formControlName="dateCertificateFrom" id="dateCertificateFrom" [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY' }"
          placeholder="dd-mm-yyyy" bsDatepicker>
      </div>
      <div class="w-25">
          <label for="dateCertificateTo" class="input-label-date">To</label>
          <input type="text" class="form-control form-control-lg"
          [class.has-error]="formControls.dateCertificateTo.invalid && (showInvalid$ | async)"
          value="sickLeaveConsulationForm.value.dateCertificateTo | date: 'DD-MM-YYYY'"
            formControlName="dateCertificateTo" id="dateCertificateTo" [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY' }"
            placeholder="dd-mm-yyyy" bsDatepicker>
      </div>
 
    </div>
  </div>
  <div class="form-group pb-1" (change)="deselectControl('referralDetail')">
    <label class="input-label mb-0">Have you referred the person to any
      other health care professional(s)?
    </label><br />
    <app-radio-highlight controlName="haveReferredToAnyOtherHealthCareProfessionalYes"
    [hasError]="formControls.haveReferredToAnyOtherHealthCareProfessional.invalid && (showInvalid$ | async)"
      [isHighlighted]="sickLeaveConsulationForm.value.haveReferredToAnyOtherHealthCareProfessional == true">
      <input class="form-check-input" type="radio" name="haveReferredToAnyOtherHealthCareProfessional" id="haveReferredToAnyOtherHealthCareProfessionalYes"
        formControlName="haveReferredToAnyOtherHealthCareProfessional" [value]="true" checked>
      <label class="form-check-label" for="haveReferredToAnyOtherHealthCareProfessionalYes">Yes</label>
    </app-radio-highlight>
    <app-radio-highlight controlName="haveReferredToAnyOtherHealthCareProfessionalNo"
    [hasError]="formControls.haveReferredToAnyOtherHealthCareProfessional.invalid && (showInvalid$ | async)"
      [isHighlighted]="sickLeaveConsulationForm.value.haveReferredToAnyOtherHealthCareProfessional == false">
      <input class="form-check-input" type="radio" name="haveReferredToAnyOtherHealthCareProfessional" id="haveReferredToAnyOtherHealthCareProfessionalNo"
        formControlName="haveReferredToAnyOtherHealthCareProfessional" [value]="false">
      <label class="form-check-label" for="haveReferredToAnyOtherHealthCareProfessionalNo">No</label>
    </app-radio-highlight>
  </div>
  <div class="form-group pb-1" *ngIf="sickLeaveConsulationForm.value.haveReferredToAnyOtherHealthCareProfessional">
    <label class="input-label" for="referralDetail">Referral Detail
    </label>
    <input type="text" class="form-control form-control-lg w-50"
    [class.has-error]="formControls.referralDetail.invalid && (showInvalid$ | async)"
      formControlName="referralDetail"
      id="referralDetail">
  </div>
</form>