import { HttpErrorResponse } from '@angular/common/http';
import { AslPatient, AslRegisterPayload, SLConsent } from '../../asl/state/asl.models';

export enum ASLActionTypes {
  REGISTER_ASL_PATIENT = '[ASL] Register ASL Patient',
  REGISTER_ASL_PATIENT_SUCCESS = '[ASL] Register ASL Patient Success',
  REGISTER_ASL_PATIENT_FAILED = '[ASL] Register ASL Patient Failed',
  OPEN_REGISTER_MYSL_MODAL = '[ASL] Open Register My Sl Modal',
  CLOSE_REGISTER_MYSL_MODAL = '[ASL] Close Register My Sl Modal',
  OPEN_SUCCESS_MODAL = '[ASL] Open Success Modal',
  CLOSE_SUCCESS_MODAL = '[ASL] Close Success Modal',
  OPEN_SUMMARY_MODAL = '[ASL] Open Summary Modal',
  CLOSE_SUMMARY_MODAL = '[ASL] Close Summary Modal',
  GET_ASL_PATIENT = '[ASL] Get ASL Patient',
  GET_ASL_PATIENT_SUCCESS = '[ASL] Get ASL Patient Success',
  GET_ASL_PATIENT_FAILED = '[ASL] Get ASL Patient Failed',
  REQUEST_ASL_ICOL_CONSENT  = '[ASL] Request ASL Icol Consent',
  REQUEST_ASL_INFA_CONSENT  = '[ASL] Request ASL infa Consent',
  REQUEST_ASL_CONSENT_SUCCESS  = '[ASL]  Request ASL Consent Success',
  REQUEST_ASL_CONSENT_FAILED  = '[ASL] Request ASL Consent Success Failed',
  REQUEST_ASL_REMOVE_CONSENT  = '[ASL] Request Remove ASL Consent',
  REQUEST_ASL_REMOVE_CONSENT_SUCCESS  = '[ASL]  Request Remove ASL Consent Success',
  REQUEST_ASL_REMOVE_CONSENT_FAILED  = '[ASL] Request Remove ASL Consent Success Failed',
  GET_ASL_STATUS = '[ASL] Get MySL Status',
  GET_ASL_STATUS_SUCCESS = '[ASL] Get MySL Status Success',
  GET_ASL_STATUS_FAILED = '[ASL] Get MySL Status Failed',
  UPDATE_ASL_PATIENT = '[ASL] Update ASL Patient',
  UPDATE_ASL_PATIENT_SUCCESS = '[ASL] Update ASL Patient Success',
  UPDATE_ASL_PATIENT_FAILED = '[ASL] Update ASL Patient Failed',
  CLEAR_ASL_PATIENT_STATE = '[ASL] CLEAR_ASL_PATIENT_STATE',
  TOGGLE_REMOVE_MODAL = '[ASL] TOGGLE_REMOVE_MODAL',
}

export class RegisterASLPatient {
  constructor(public patient: AslRegisterPayload) {}
  static readonly type = ASLActionTypes.REGISTER_ASL_PATIENT;
}

export class ToggleRemoveModal {
  constructor(public show: boolean) {}
  static readonly type = ASLActionTypes.TOGGLE_REMOVE_MODAL;
}


export class RegisterASLPatientSuccess {
  constructor(public patient: AslRegisterPayload) {}
  static readonly type = ASLActionTypes.REGISTER_ASL_PATIENT_SUCCESS;
}

export class RegisterASLPatientFailed {
  constructor(public error: HttpErrorResponse) {}
  static readonly type = ASLActionTypes.REGISTER_ASL_PATIENT_FAILED;
}

export class OpenRegisterMySl {
  static readonly type = ASLActionTypes.OPEN_REGISTER_MYSL_MODAL;
}

export class CloseRegisterMySlModal {
  static readonly type = ASLActionTypes.CLOSE_REGISTER_MYSL_MODAL;
}

export class OpenSuccessModal {
  static readonly type = ASLActionTypes.OPEN_SUCCESS_MODAL;
}

export class CloseSuccessModal {
  static readonly type = ASLActionTypes.CLOSE_SUCCESS_MODAL;
}

export class OpenSummaryModal {
  static readonly type = ASLActionTypes.OPEN_SUMMARY_MODAL;
}

export class CloseSummaryModal {
  static readonly type = ASLActionTypes.CLOSE_SUMMARY_MODAL;
}

export class GetASLPatient {
  constructor(public ihiNumber: string, public firstName: string, public surname: string, public isTimerReload = false) {}
  static readonly type = ASLActionTypes.GET_ASL_PATIENT;
}

export class GetASLPatientSuccess {
  constructor(public patient: AslPatient) {}
  static readonly type = ASLActionTypes.GET_ASL_PATIENT_SUCCESS;
}

export class GetASLPatientFailed {
  constructor(public error: HttpErrorResponse) {}
  static readonly type = ASLActionTypes.GET_ASL_PATIENT_FAILED;
}

export class RequestASLIcolConsent {
  static readonly type = ASLActionTypes.REQUEST_ASL_ICOL_CONSENT;
  constructor(public ihiNumber: string, public firstName: string, public lastName: string) { }
}

export class RequestASLInfaConsent {
  static readonly type = ASLActionTypes.REQUEST_ASL_INFA_CONSENT;
  constructor(public ihiNumber: string, public firstName: string, public lastName: string) { }
}

export class RequestASLConsentSuccess {
  static readonly type = ASLActionTypes.REQUEST_ASL_CONSENT_SUCCESS;
  constructor(public ihiNumber: string, public firstName: string, public lastName: string) { }
}

export class RequestASLConsentFailed {
  constructor(public error: HttpErrorResponse) { }
  static readonly type = ASLActionTypes.REQUEST_ASL_CONSENT_FAILED;
}



export class RequestASLRemoveConsent {
  static readonly type = ASLActionTypes.REQUEST_ASL_REMOVE_CONSENT;
  constructor(public id: string, public ihiNumber: string, public firstName: string, public lastName: string) { }
}

export class RequestASLRemoveConsentSuccess {
  static readonly type = ASLActionTypes.REQUEST_ASL_REMOVE_CONSENT_SUCCESS;
  constructor(public mySLStatus: SLConsent) { }
}

export class RequestASLRemoveConsentFailed {
  constructor(public error: HttpErrorResponse) { }
  static readonly type = ASLActionTypes.REQUEST_ASL_REMOVE_CONSENT_FAILED;
}

export class GetASLStatus {
  constructor(public ihiNumber: string, public firstName: string, public lastName: string) {}
  static readonly type = ASLActionTypes.GET_ASL_STATUS;
}

export class GetASLStatusSuccess {
  constructor(public mySLStatus: SLConsent) { }
  static readonly type = ASLActionTypes.GET_ASL_STATUS_SUCCESS;
}

export class GetASLStatusFailed {
  constructor(public error: HttpErrorResponse) { }
  static readonly type = ASLActionTypes.GET_ASL_STATUS_FAILED;
}

export class UpdateASLPatient {
  constructor(public patient: AslRegisterPayload) {}
  static readonly type = ASLActionTypes.UPDATE_ASL_PATIENT;
}

export class UpdateASLPatientSuccess {
  constructor(public patient: AslRegisterPayload) {}
  static readonly type = ASLActionTypes.UPDATE_ASL_PATIENT_SUCCESS;
}

export class UpdateASLPatientFailed {
  constructor(public error: HttpErrorResponse) {}
  static readonly type = ASLActionTypes.UPDATE_ASL_PATIENT_FAILED;
}

export class ClearASLPatientState {
  static readonly type = ASLActionTypes.CLEAR_ASL_PATIENT_STATE;
}
