<footer class="footer">
    <div class="desktop">
        <div class="grid footer__block" g-xs="py3 mt4">
            <div class="grid__unit" g-xs="cols:3 text:center">© {{currentYear}} - Scrypt</div>
            <div class="grid__unit" g-xs="cols:6 text:center">For assistance please call 1800 SCRYPT (727 978) or email support&#64;scrypt.com.au</div>
            <div class="grid__unit" g-xs="cols:3 text:center">
                <a [href]="privacyUrl" target="_blank">Privacy Policy</a> -
                <a [href]="termsUrl" target="_blank">Terms and Conditions</a>
            </div>
        </div>
    </div>
    <div class="mobile" >
        <div class="d-flex justify-content-center" g-xs="py3 mt4">
            <div class="" g-xs="cols:3">© {{currentYear}} - Scrypt</div>
        </div>
    </div>
</footer>
