import { State, Action, StateContext, Selector } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { BiReport } from '~/shared/models/BiReport.model';
import { BimetricService } from '~/shared/services/bimetric.service';
import { GetEmbeddedReport, GetReports } from './report.actions';
import { tap } from 'rxjs/operators';
import { EmbeddedReportModel } from '~/shared/models/report.model';

export interface ReportingStateModel {
    biReports: BiReport[];
    embeddedReport: EmbeddedReportModel;
    loading: boolean;
}


@State<ReportingStateModel>({
  name: 'reporting',
  defaults: {
    biReports: [],
    embeddedReport: undefined,
    loading: false,
  }
})
@Injectable()
export class ReportingState {
    constructor(private biMetricService: BimetricService) { }

  @Selector()
  static reports(state: ReportingStateModel) {
    return state.biReports;
  }


  @Selector()
  static embededReport(state: ReportingStateModel) {
    return state.embeddedReport;
  }

  @Selector()
  static loading(state: ReportingStateModel) {
    return state.loading;
  }

  @Action(GetReports)
  getReports(ctx: StateContext<ReportingStateModel>) {
    ctx.patchState({loading: true})
    return this.biMetricService.getReports().pipe(
        tap(result => {
            ctx.patchState({
                biReports: result,
                loading: false
            });
        }),
      );
  }

  @Action(GetEmbeddedReport)
  getReport(ctx: StateContext<ReportingStateModel>, { reportId }: GetEmbeddedReport) {
    ctx.patchState({loading: true})
    return this.biMetricService.getReport(reportId).pipe(
        tap(result => {
            ctx.patchState({
                embeddedReport: result,
                loading: false
            });
        }),
      );
  }
}