export class TenantUser {
  constructor(
    public tenantId: number,
    public userId: number,
    public email: string,
    public tenantName: string,
    public id?: number,

  ) { }
}
