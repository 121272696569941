<ng-container *ngIf="(commTemplatesState$ | async) as commTemplatesState">
  <div class="p-4 position-relative">
    <ng-container *ngIf="commTemplatesState.error as error">
      <div class="form-help-text" g-xs="mb2">{{error.message}}</div>
    </ng-container>
      <h1 class="blue-header">Messages</h1>
      <div class="btn btn__green mb-2 float-right" (click)="create()">Create a custom message</div><br /><br />

    <div class="flex justify-content-between mt-2">
      <div class="search">
        <input [formControl]="searchInput" class="form-control radius__primary" g-xs="py3" type="text"
          placeholder="Search for a message" />
        <i class="fas fa-search"></i>
      </div>
      <div>
        <select [(ngModel)]="sortBy" class="form-control form-control-lg width__200">
          <option [ngValue]="sortByTypes.Newest">Newest First</option>
          <option [ngValue]="sortByTypes.Oldest">Oldest First</option>
          <option [ngValue]="sortByTypes.Alphabetical">Alphabetical</option>
          <option [ngValue]="sortByTypes.AlphabeticalReverse">Reverse Alphabetical</option>
        </select>
      </div>
    </div>
    <app-comm-templates-list [templateType]="templateType.Default"
      [commTemplates]="(commTemplates$ | async) | commTemplatesFilter: templateType.Default : searchQuery: sortBy"
      (toggleActivationEvent)="toggleActivation($event)" (editEvent)="edit($event)" (deleteEvent)="delete($event)">
    </app-comm-templates-list>
    <app-comm-templates-list [templateType]="templateType.Custom"
      [commTemplates]="(commTemplates$ | async) | commTemplatesFilter: templateType.Custom : searchQuery: sortBy"
      (toggleActivationEvent)="toggleActivation($event)" (editEvent)="edit($event)" (deleteEvent)="delete($event)" (createEvent)="create()">
    </app-comm-templates-list>
    <app-comm-templates-list [templateType]="templateType.Report"
      [commTemplates]="(commTemplates$ | async) | commTemplatesFilter: templateType.Report : searchQuery: sortBy"
      (toggleActivationEvent)="toggleActivation($event)" (editEvent)="edit($event)" (deleteEvent)="delete($event)">
    </app-comm-templates-list>
    <div class="loading__container" *ngIf="commTemplatesState.loading;">
      <app-loader></app-loader>
    </div>
  </div>
</ng-container>
