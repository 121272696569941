import { State, Action, StateContext } from '@ngxs/store';
import { ToggleBladeAction } from './blade.actions';
import { AddContentContainerClassAction, RemoveContentContainerClassAction } from '../../../state/content-container/content-container.actions';
import { OpenBulkStockAdjustmentAction, CloseBulkStockAdjustmentAction } from '../../../../modules/profile/categories/bulk-stock-adjustment/state/bulk-stock-adjustment.actions';
import { OpenDosageHistoryAction, CloseDosageHistoryAction } from '../../../../modules/profile/categories/dosage-history/state/dosage-history.actions';
import { OpenInstoreOrderAction, CloseInstoreOrderAction } from '../../../../modules/profile/categories/instore-order/state/instore-order.actions';
import { CloseManageMetaAction, OpenManageMetaAction } from '../../../../modules/profile/categories/manage-meta/state/manage-meta.actions';
import { OpenStockAdjustmentAction, CloseStockAdjustmentAction } from '../../../../modules/profile/categories/stock-adjustment/state/stock-adjustment.actions';
import { Injectable } from '@angular/core';

export class BladeStateModel {
  public openBlades: Set<string>;
}
@Injectable()
@State<BladeStateModel>({
  name: 'blade',
  defaults: {
    openBlades: new Set()
  }
})
export class BladeState {
  @Action([
    OpenBulkStockAdjustmentAction,
    OpenDosageHistoryAction,
    OpenInstoreOrderAction,
    OpenManageMetaAction,
    OpenStockAdjustmentAction
  ])
  open(ctx: StateContext<BladeStateModel>, action: ToggleBladeAction) {
    const state = ctx.getState();
    const openBlades = new Set(state.openBlades);
    openBlades.add(action.bladeName);
    ctx.setState({ openBlades: openBlades });
    ctx.dispatch(new AddContentContainerClassAction('bladeVisible'));
  }

  @Action([
    CloseBulkStockAdjustmentAction,
    CloseDosageHistoryAction,
    CloseInstoreOrderAction,
    CloseManageMetaAction,
    CloseStockAdjustmentAction
  ])
  close(ctx: StateContext<BladeStateModel>, action: ToggleBladeAction) {
    const state = ctx.getState();
    const openBlades = new Set(state.openBlades);
    openBlades.delete(action.bladeName);
    ctx.setState({ openBlades: openBlades });
    if (state.openBlades.size === 0) {
      ctx.dispatch(new RemoveContentContainerClassAction('bladeVisible'));
    }
  }
}
