import { State, Action, StateContext, Selector } from '@ngxs/store';
import { SetDigitalSignature } from './scrypt-digital-signature.actions';
import { Injectable } from '@angular/core';

export class ScryptDigitalSignatureStateModel {
  public digitalSignatureForm: {
    model: Partial<IDigitalSignatureForm>
  };
  currentDigitalSignature: string;
}

export interface IDigitalSignatureForm {
  base64DigitalSignature: string;
}
@Injectable()
@State<ScryptDigitalSignatureStateModel>({
  name: 'scryptDigitalSignature',
  defaults: {
    digitalSignatureForm: {
      model: undefined
    },
    currentDigitalSignature: undefined
  }
})
export class ScryptDigitalSignatureState {

  @Selector()
  static digitalSignatureForm(state: ScryptDigitalSignatureStateModel) { return state.digitalSignatureForm.model; }

  @Selector()
  static currentDigitalSignature(state: ScryptDigitalSignatureStateModel) { return state.currentDigitalSignature; }

  @Action(SetDigitalSignature)
  setDigitalSignature(ctx: StateContext<ScryptDigitalSignatureStateModel>, {digitalSignature}: SetDigitalSignature) {
    ctx.patchState({
      currentDigitalSignature: digitalSignature
    });
  }
}
