import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { CustomerStatus, MobileStatus } from '~/shared/models/profile/customerProfile.model';
import { ContactMethods } from '~/shared/models/communication/contact-methods.enum';
import { Select, Store } from '@ngxs/store';
import { GroupPageState } from '~/system/group-page/state/group-page.state';
import { Observable } from 'rxjs';
import { Group } from '~/shared/models/group.model';
import { GetScryptSearchResults } from '../state/scrypt-search.actions';
import { OrderMethod } from '~/shared/models/communication/order-method.enum';
import { accordion } from '~/shared/core/animations';
import { AdvancedSearchState } from '../state/advanced-search.state';
import { ScryptSearchState } from '../state/scrypt-search.state';

@Component({
  selector: 'app-scrypt-search',
  templateUrl: './scrypt-search.component.html',
  styleUrls: ['./scrypt-search.component.scss'],
  animations: [accordion]
})
export class ScryptSearchComponent implements OnInit {
  @Select(GroupPageState.groups) groups$: Observable<Group[]>;
  @Select(ScryptSearchState.fetching) fetching$: Observable<boolean>;

  scryptSearchform: FormGroup;
  customerStatus = CustomerStatus;
  contactMethods = ContactMethods;
  mobileStatus = MobileStatus;
  orderMethods = OrderMethod;
  commsAccordion = 'out';

  constructor(private formBuilder: FormBuilder, private store: Store) {}

  ngOnInit() {
    this.generateFormcontrols();
  }

  private generateFormcontrols() {
    this.scryptSearchform = this.formBuilder.group({
      patientTypes: new FormControl(null),
      groups: new FormControl(null),
      statuses: new FormControl(null),
      deliveryPreferences: new FormControl(null),
      commsTypePreferences: new FormControl(null),
      mobileStatuses: new FormControl(null),
      hasOrderCycle: new FormControl(null),
      automatedCommsPreference: new FormControl(null)
    });
  }

  submitSearch() {
    this.store.dispatch(new GetScryptSearchResults(this.scryptSearchform.value));
  }

  clearForm() {
    this.scryptSearchform.reset();
  }

  toggleCommsAccordion() {
    this.commsAccordion =
      this.commsAccordion === "in" ? "out" : "in";
  }
}
