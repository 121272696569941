<form class="form mb-3 ml-3" [formGroup]="scryptSearchform" (ngSubmit)="submitSearch()">
  <div class="mt-3">
    <div class="form-row px-2">
      <div class="form-group col-md-3">
        <label>Patient Type</label>
        <select formControlName="patientTypes" class="form-control" name="type" multiple>
          <option [ngValue]="null">All</option>
          <option value="DAA">DAA</option>
          <option value="SOF">SOF</option>
          <option value="LEAD">Lead</option>
        </select>

      </div>
      <ng-container *ngIf="(groups$ | async) as groups">
        <div class="form-group col-md-3">
          <label>Group</label>
          <select formControlName="groups" class="form-control" name="status" multiple>
            <option [ngValue]="null">All</option>
            <option *ngFor="let group of groups" [ngValue]="group.id">{{group.name}}</option>
          </select>
        </div>
      </ng-container>
      <div class="form-group col-md-3">
        <label>Status</label>
        <select formControlName="statuses" class="form-control" name="status" multiple>
          <option [ngValue]="null">All</option>
          <option [ngValue]="customerStatus.Active">Active</option>
          <option [ngValue]="customerStatus.Inactive">Inactive</option>
          <option [ngValue]="customerStatus.Deceased">Deceased</option>
          <option [ngValue]="customerStatus.Hospital">Hospital</option>
          <option [ngValue]="customerStatus.Holiday">Holiday</option>
          <option [ngValue]="customerStatus.Unknown">Unknown</option>
        </select>
      </div>
      <div class="form-group col-md-3">
        <label>Delivery Preference</label>
        <select formControlName="deliveryPreferences" class="form-control" name="status" multiple>
          <option [ngValue]="null">All</option>
          <option [ngValue]="orderMethods.pickup">Pickup</option>
          <option [ngValue]="orderMethods.delivery">Delivery</option>
        </select>
      </div>
      <div class="form-group col-md-3">
        <label>Comms Preference</label>
        <select formControlName="commsTypePreferences" class="form-control" name="status" multiple>
          <option [ngValue]="null">All</option>
          <option [ngValue]="contactMethods.phone">Phone</option>
          <option [ngValue]="contactMethods.sms">SMS</option>
          <option [ngValue]="contactMethods.email">Email</option>
          <option [ngValue]="contactMethods.postal">Postal</option>
          <!-- <option [ngValue]="contactMethods.scryptMobile">ScryptMobile</option> -->
          <option [ngValue]="contactMethods.medAdvisor">MedAdvisor</option>
          <option [ngValue]="contactMethods.guildCare">GuildCare</option>
        </select>
      </div>
      <div class="form-group col-md-3">
        <label>Mobile Status</label>
        <select formControlName="mobileStatuses" class="form-control" name="status" multiple>
          <option [ngValue]="null">All</option>
          <option [ngValue]="mobileStatus.Inactive">Inactive</option>
          <option [ngValue]="mobileStatus.Invited">Pending</option>
          <option [ngValue]="mobileStatus.Active">Active</option>
        </select>
      </div>
    </div>

    <h4 class="pointer" (click)="toggleCommsAccordion()">
      Communications & Cycles
      <i [ngClass]="commsAccordion === 'in' ? 'fas fa-caret-up' : 'fas fa-caret-down'"></i>
    </h4>

    <div class="pt-3" [@accordionAnimation]="commsAccordion">
      <div class="form-row px-2">
        <div class="form-group col">
          <label class="input-label m-0">Has Order cycle</label><br />
          <app-radio-highlight controlName="hasOrderCycle"
            [isHighlighted]="scryptSearchform.value.hasOrderCycle == null">
            <input class="form-check-input" type="radio" name="hasOrderCycle" id="any" formControlName="hasOrderCycle"
              [value]="null">
            <label class="form-check-label" for="any">Any</label>
          </app-radio-highlight>
          <app-radio-highlight controlName="hasOrderCycle"
            [isHighlighted]="scryptSearchform.value.hasOrderCycle == true">
            <input class="form-check-input" type="radio" name="hasOrderCycle" id="yes" formControlName="hasOrderCycle"
              [value]="true">
            <label class="form-check-label" for="yes">Yes</label>
          </app-radio-highlight>
          <app-radio-highlight controlName="hasOrderCycle"
            [isHighlighted]="scryptSearchform.value.hasOrderCycle == false">
            <input class="form-check-input" type="radio" name="hasOrderCycle" id="no" formControlName="hasOrderCycle"
              [value]="false" checked>
            <label class="form-check-label" for="no">No</label>
          </app-radio-highlight>
        </div>
      </div>
      <div class="form-row px-2 mb-1">
        <div class="form-group col">
          <label class="input-label m-0">Scrypt Reminders Active</label><br />
          <app-radio-highlight controlName="automatedCommsPreference"
            [isHighlighted]="scryptSearchform.value.automatedCommsPreference == null">
            <input class="form-check-input" type="radio" name="automatedCommsPreference" id="commsAny"
              formControlName="automatedCommsPreference" [value]="null">
            <label class="form-check-label" for="commsAny">Any</label>
          </app-radio-highlight>
          <app-radio-highlight controlName="automatedCommsPreference"
            [isHighlighted]="scryptSearchform.value.automatedCommsPreference == true">
            <input class="form-check-input" type="radio" name="automatedCommsPreference" id="commsYes"
              formControlName="automatedCommsPreference" [value]="true">
            <label class="form-check-label" for="commsYes">Yes</label>
          </app-radio-highlight>
          <app-radio-highlight controlName="automatedCommsPreference"
            [isHighlighted]="scryptSearchform.value.automatedCommsPreference == false">
            <input class="form-check-input" type="radio" name="automatedCommsPreference" id="commsNo"
              formControlName="automatedCommsPreference" [value]="false" checked>
            <label class="form-check-label" for="commsNo">No</label>
          </app-radio-highlight>
        </div>
      </div>
    </div>

    <div class="ml-2 d-flex">
      <input type="submit" [disabled]="!scryptSearchform.valid" class="btn btn-primary" value="Search" />
      <input type="button" class="btn btn-danger ml-4 mr-4" (click)="clearForm()" value="Clear Form" />
      <ng-container *ngIf="fetching$ | async">
        <app-loader loadingMessage="Fetching results"></app-loader>
    </ng-container>
    </div>
  </div>
</form>
