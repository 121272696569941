<form [formGroup]="sickLeavePharmacistDeclarationForm" ngxsForm="sickLeavePharmacistDeclaration.sickLeavePharmacistDeclarationForm" class="p-4">
  <ng-container *ngIf="(pharmacists$ | async) as pharmacists">
    <div class="form-group pb-1">
      <label for="pharmacist" class="input-label">Select Pharmacist</label>
      <select 
      [class.has-error]="formControls.pharmacist.invalid && (showInvalid$ | async)"
      formControlName="pharmacist" class="form-control form-control-lg w-50" (change)="setPharmacistData()">
        <option *ngFor="let p of pharmacists" value="{{p.givenNames}} {{p.familyName}}">{{p.givenNames}} {{p.familyName}}</option>
      </select>
    </div>
  </ng-container>
  <div class="form-group pb-1">
    <label class="input-label" for="pharmacistGivenName">Pharmacist Given Name(s)</label>
    <input type="text" class="form-control form-control-lg w-25" formControlName="pharmacistGivenName"
    [class.has-error]="formControls.pharmacistGivenName.invalid && (showInvalid$ | async)"
      id="pharmacistGivenName" readonly>
  </div>
  <div class="form-group pb-1">
    <label class="input-label" for="pharmacistFamilyName">Pharmacist Family Name</label>
    <input type="text" class="form-control form-control-lg w-25" formControlName="pharmacistFamilyName"
    [class.has-error]="formControls.pharmacistFamilyName.invalid && (showInvalid$ | async)"
      id="pharmacistFamilyName" readonly>
  </div>
  <div class="form-group pb-1">
    <label for="ahpraNumber" class="input-label">AHPRA number
    </label>
    <input type="text" class="form-control form-control-lg w-50" name="ahpraNumber"
    [class.has-error]="formControls.ahpraNumber.invalid && (showInvalid$ | async)"
        formControlName="ahpraNumber" id="ahpraNumber" readonly>
</div>
    <div class="form-group pb-1">
      <label class="input-label mb-0">I have delivered the service in accordance with the Guidelines for pharmacists issuing certificates for absence from work
      </label><br />
      <app-radio-highlight controlName="deliveredServiceYes"
      [hasError]="formControls.haveDeliveredService.invalid && (showInvalid$ | async)"
        [isHighlighted]="sickLeavePharmacistDeclarationForm.value.haveDeliveredService == true">
        <input class="form-check-input" type="radio" name="haveDeliveredService" id="deliveredServiceYes"
          formControlName="haveDeliveredService" [value]="true" checked>
        <label class="form-check-label" for="deliveredServiceYes">Yes</label>
      </app-radio-highlight>
      <app-radio-highlight controlName="deliveredServiceNo"
      [hasError]="formControls.haveDeliveredService.invalid && (showInvalid$ | async)"
        [isHighlighted]="sickLeavePharmacistDeclarationForm.value.haveDeliveredService == false">
        <input class="form-check-input" type="radio" name="haveDeliveredService" id="deliveredServiceNo"
          formControlName="haveDeliveredService" [value]="false">
        <label class="form-check-label" for="deliveredServiceNo">No</label>
      </app-radio-highlight>
    </div>
    <div class="form-group pb-1">
      <label for="dateOfService" class="input-label">Service Date</label>
      <input type="text" class="form-control form-control-lg w-25"
      [class.has-error]="formControls.dateOfService.invalid && (showInvalid$ | async)"
        value="sickLeavePharmacistDeclarationForm.value.dateOfService | date: 'DD-MM-YYYY'"
        formControlName="dateOfService" id="dateOfService" [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY' }"
        placeholder="dd-mm-yyyy" bsDatepicker>
    </div>
    <app-scrypt-digital-signature></app-scrypt-digital-signature>
  </form>