import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import { StorageService } from '../core/storage/storage.service';
import { MarketingSetting } from '../models/marketingSetting.model';
import { AlertService } from '~/modules/core/alert/alert.service';
import { BaseApiNoAlerts } from '../core/base-api/base-api-no-alerts';
import { TemplateType } from '../models/pharmacy/comm-template.model';

const API = environment.baseApiUrl;

@Injectable()
export class MarketingService extends BaseApiNoAlerts {
  constructor(public http: HttpClient, public router: Router, public storageService: StorageService, public alertService: AlertService) {
    super(http, router, storageService, alertService);
  }
  
  getMessageTemplates(clientId: string): Observable<MarketingSetting[]> {
    return this.get(`${API}/api/marketing/tenant/templates/${clientId}`);
  }

  getTemplatesByType(clientId: string, type: TemplateType): Observable<MarketingSetting[]> {
    return this.get(`${API}/api/marketing/${clientId}/templates-by-type/${type}`);
  }

  getMarketingSequence(): Observable<MarketingSetting[]> {
    return this.get(`${API}/api/Marketing`);
  }

  createMarketing(item: MarketingSetting): Observable<MarketingSetting> {
    return this.post(`${API}/api/Marketing/tenant`, item);
  }

  createDoctorReportTemplate(item: MarketingSetting): Observable<MarketingSetting> {
    return this.post(`${API}/api/Marketing/doctor-report-template`, item);
  }

  updateMarketingSequence(item: MarketingSetting): Observable<MarketingSetting> {
    return this.put(`${API}/api/Marketing/update/tenant`, item);
  }
  
  updateAllMarketingSequence(items: MarketingSetting[]): Observable<MarketingSetting[]> {
    return this.put(`${API}/api/Marketing/update/tenants`, items);
  }

  getDoctorReportTemplates(clientId: string): Observable<MarketingSetting[]> {
    return this.get(`${API}/api/Marketing/doctor-report-templates/${clientId}`);
  }
}
