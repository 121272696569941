import { Component, OnInit } from '@angular/core';
import { StagedSupplyFormType, StagedSupplyFormListState } from '../staged-supply/state/staged-supply-form-list.state';
import { SSPatientDetailsFormState, SSPatientDetailsFormStateModel } from '../patient-details-form/state/ss-patient-details-form.state';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-ss-patient-details-list-item',
  templateUrl: './ss-patient-details-list-item.component.html'
})
export class SSPatientDetailsListItemComponent implements OnInit {
  @Select(StagedSupplyFormListState.stagedSupplyType) stagedSupplyType$: Observable<StagedSupplyFormType>;
  @Select(SSPatientDetailsFormState) formState$: Observable<SSPatientDetailsFormStateModel>;
  stagedSupplyTypes = StagedSupplyFormType;

  constructor() { }

  ngOnInit() {
  }

}
