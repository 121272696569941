import { Pipe, PipeTransform } from '@angular/core';
import { ConnectionType } from '../models/connectionType.model';
@Pipe({
    name: 'appConnectionType'
})
export class ConnectionTypePipe implements PipeTransform {
    transform(value: number, connectionTypeList: ConnectionType[]): string {
        const item = connectionTypeList.find(x => x.value === value);
        if (item) {
            return item.name;
        }
        return null;
    }
}
