import { State, Action, StateContext } from '@ngxs/store';
import { OpenInstoreOrderAction, CloseInstoreOrderAction, InstoreOrderRequestAction, InstoreOrderResponseAction, InstoreOrderErrorAction } from './instore-order.actions';
import { ScriptService } from '../../../../../shared/services/script.service';
import { catchError, mergeMap } from 'rxjs/operators';
import { ClearSelectedDrugsAction } from '../../multiple-select/state/multiple-drugs.actions';
import { RefreshCategoriesAction } from '../../state/categories.actions';
import { Injectable } from '@angular/core';

export class InstoreOrderStateModel {
  show: boolean;
  loading: boolean;
  error: any;
}
@Injectable()
@State<InstoreOrderStateModel>({
  name: 'instoreOrder',
  defaults: {
    show: false,
    loading: false,
    error: null
  }
})
export class InstoreOrderState {
  constructor(private scriptService: ScriptService) {}

  @Action(OpenInstoreOrderAction)
  open(ctx: StateContext<InstoreOrderStateModel>) {
    ctx.patchState({ show: true });
  }

  @Action(InstoreOrderRequestAction)
  adjust(ctx: StateContext<InstoreOrderStateModel>, action: InstoreOrderRequestAction) {
    ctx.patchState({ loading: true });
    return this.scriptService.createInstoreOrder(action.request).pipe(
      mergeMap(() => ctx.dispatch(new InstoreOrderResponseAction(action.clientId))),
      catchError(error => ctx.dispatch(new InstoreOrderErrorAction(error)))
    );
  }

  @Action(InstoreOrderResponseAction)
  handleResponse(ctx: StateContext<InstoreOrderStateModel>, action: InstoreOrderResponseAction) {
    ctx.dispatch([
      new ClearSelectedDrugsAction(),
      new RefreshCategoriesAction(action.clientId)
    ]);
  }

  @Action(InstoreOrderErrorAction)
  handleError(ctx: StateContext<InstoreOrderStateModel>, action: InstoreOrderErrorAction) {
    ctx.patchState({ error: action.error });
  }

  @Action([InstoreOrderResponseAction, InstoreOrderErrorAction])
  stopLoading(ctx: StateContext<InstoreOrderStateModel>) {
    ctx.patchState({ loading: false });
  }

  @Action(CloseInstoreOrderAction)
  close(ctx: StateContext<InstoreOrderStateModel>) {
    ctx.patchState({ show: false });
  }
}
