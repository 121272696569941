import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'orderDoh',
    pure: true
})
export class OrderDohPipe implements PipeTransform {
    transform(doh) {
        return doh && parseFloat(`${doh}`) > 0 ? doh : 0;
    }
}
