<ng-container>
    <thead class="table-headings">
        <tr>
            <ng-container *ngFor="let column of columns; trackBy:trackByColumnId">
                <ng-container [ngSwitch]="column.type">
                    <ng-container *ngSwitchCase="'Text'">
                        <th [id]="column.id" [nzWidth]="column.width">{{column.name}}</th>
                    </ng-container>
                    <ng-container *ngSwitchCase="'Date'">
                        <th [id]="column.id" [nzSortKey]="column.id" [nzSortOrder]="column?.sortOrder" [nzSortDirections]="['ascend', 'descend']" [nzSortFn]="column?.sortFn" [nzWidth]="column.width">{{column.name}}</th>
                    </ng-container>
                    <ng-container *ngSwitchCase="'Expand'">
                        <th [id]="column.id" [nzWidth]="column.width" nzShowExpand ></th>
                    </ng-container>
                    <ng-container *ngSwitchCase="'Empty'">
                        <th [id]="column.id" [nzWidth]="column.width"></th>
                    </ng-container>
                </ng-container>
            </ng-container>
        </tr>
    </thead>
</ng-container>