import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import { ReactiveFormsModule } from '@angular/forms';
import { ActionPlanFormComponent } from './action-plan-form.component';
import { RadioHighlightModule } from '@base/shared/components/radio-highlight/radio-highlight.module';

@NgModule({
    declarations: [
        ActionPlanFormComponent
    ],
    imports: [
        CommonModule,
        NgxsFormPluginModule,
        ReactiveFormsModule,
        RadioHighlightModule
    ],
    exports: [
        ActionPlanFormComponent
    ]
})
export class ActionPlanFormModule { }
