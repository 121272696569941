import { Injectable } from "@angular/core";
import { Action, State, StateContext } from "@ngxs/store";
import { StatisticsService } from "~/shared/services/statistics.service";
import { CreateStatistic, UpdateStatistic } from "./statistics.actions";

@Injectable()
@State({
    name: 'statisticsState',
})
export class StatisticsState {
    constructor(private statisticService: StatisticsService) {}
    
    @Action(CreateStatistic) 
    createStatistic(ctx: StateContext<any>, { request }: CreateStatistic) {
        return this.statisticService.createStatistics(request);
    }

    @Action(UpdateStatistic)
    updateStatistic(ctx: StateContext<any>, { request }: UpdateStatistic) {
        return this.statisticService.updateStatistic(request);
    }   
}