import { GroupType } from './../../shared/models/crm/groupType.model';
import { Component, OnInit } from '@angular/core';
import { CrmService } from '../../shared/services/crm.service';
import { combineLatest } from 'rxjs/observable/combineLatest';
import { Note } from '../../shared/models/crm/note.model';
import { NoteFilter } from '../../shared/models/crm/noteFilter.model';
import { NoteType } from '../../shared/models/crm/noteType.model';
import { NoteInfo } from '../../shared/models/crm/noteInfo.model';
import { NotesScrollService } from './note-filters/note-filter.service';
import { Store, Select } from '@ngxs/store';
import { CrmState } from './state/crm.state';
import { Observable } from 'rxjs';
import { UpdateTotalsAction, UpdateFiltersAction } from './state/crm.actions';

@Component({
  selector: 'app-crm-page',
  templateUrl: './crm-page.component.html',
  styleUrls: ['./crm-page.component.scss']
})
export class CrmPageComponent implements OnInit {

  constructor(private store: Store, private crmService: CrmService, private notesScrollService: NotesScrollService) { }
  groupList: GroupType[] = [];
  noteTypeList: NoteType[] = [];
  noteTypeFilterList: NoteType[] = [];
  @Select(CrmState) crm$: Observable<any>;
  @Select(CrmState.noteList) noteList$: Observable<Note[]>;
  @Select(CrmState.noteInfo) noteInfo$: Observable<NoteInfo>;

  ngOnInit() {
    this.loadData();
  }

  updateTotals(event: any) {
    this.store.dispatch(new UpdateTotalsAction(event));
  }

  scrollNext() {
    this.notesScrollService.nextPage();
  }

  private loadData() {
    combineLatest([
      this.crmService.getNoteTypes(),
      this.crmService.getGroups()
    ]).subscribe(([noteTypes, groupTypes]) => {
      this.noteTypeList = noteTypes;
      this.groupList = groupTypes;
    });
  }

  filterChanged(filters: NoteFilter) {
    this.store.dispatch(new UpdateFiltersAction(filters));
  }
}
