import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MedsCheckConfirmModalComponent } from './meds-check-confirm-modal.component';
import { ConfirmModalComponent } from './confirm-modal/confirm-modal.component';

@NgModule({
  declarations: [MedsCheckConfirmModalComponent, ConfirmModalComponent],
  imports: [
    CommonModule
  ],
  exports: [MedsCheckConfirmModalComponent]
})
export class MedsCheckConfirmModalModule { }
