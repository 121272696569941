import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { OrderComponent } from './order.component';
import { LoaderModule } from '../../../modules/core/loader/loader.module';
import { RouterModule } from '@angular/router';
import { OrderRoutingModule } from './order-routing.module';
import { NgxsModule } from '@ngxs/store';
import { OrderRequestState } from './state/order.state';
import { PipesModule } from '../../../shared/pipes/pipes.module';
import { FormsModule } from '@angular/forms';
import { OrderService } from './services/order.service';
import { OrderMainModule } from './order-main/order-main.module';
import { StorageService } from '../../../shared/core/storage/storage.service';
import { OrderViewModule } from './order-view/order-view.module';
import { OrderCreateModule } from './order-create/order-create.module';
import { NzNotificationModule } from 'ng-zorro-antd/notification';

@NgModule({
  declarations: [
    OrderComponent
  ],
  providers: [
    OrderService,
    DatePipe,
    StorageService
  ],
  imports: [
    CommonModule,
    FormsModule,
    LoaderModule,
    PipesModule,
    RouterModule,
    OrderMainModule,
    OrderViewModule,
    OrderCreateModule,
    OrderRoutingModule,
    NgxsModule.forFeature([OrderRequestState]),
    NzNotificationModule
  ],
  exports: [
    OrderComponent
  ]
})
export class OrderRequestsModule { }
