import { NgModule } from '@angular/core';
import { OrderDohInsightComponent } from './order-doh-insight.component';
import { OrderDohPipe } from './order-doh.pipe';

@NgModule({
    declarations: [OrderDohInsightComponent, OrderDohPipe],
    exports:  [OrderDohInsightComponent, OrderDohPipe]
})
export class OrderDohModule {
}
