<ng-container *ngIf="(auth$ | async) as auth">
  <section class="auth">
    <ng-container *ngIf="!auth.loading; else loading">
      <div>
        <form [formGroup]="authForm" (ngSubmit)="onSubmit()">
          <h2 class="font__bold">Welcome to Scrypt documents!</h2>
          <h3 class="font__bold" g-xs="my3">Please enter your date of birth to confirm identity</h3>
          <div class="grid">
            <div class="grid_unit" g-xs="col:2">
              <select class="form-control" formControlName="day">
                <option *ngFor="let item of dayOpts" [ngValue]="item">{{item}}</option>
              </select>
            </div>
            <div class="grid_unit" g-xs="col:6 px3">
              <select class="form-control" formControlName="month">
                <option value="1">January</option>
                <option value="2">February</option>
                <option value="3">March</option>
                <option value="4">April</option>
                <option value="5">May</option>
                <option value="6">June</option>
                <option value="7">July</option>
                <option value="8">August</option>
                <option value="9">September</option>
                <option value="10">October</option>
                <option value="11">November</option>
                <option value="12">December</option>
              </select>
            </div>
            <div class="grid_unit" g-xs="col:4">
              <select class="form-control" formControlName="year">
                <option *ngFor="let item of yearOpts" [ngValue]="item">{{item}}</option>
              </select>
            </div>
          </div>
          <div>
          </div>
          <button g-xs="mt3 mb1" class="button btn__yellow font__title-3" type="submit"
            [disabled]="authForm.invalid">Continue</button>
          <div class="form-help-text" *ngIf="auth.error">
              {{auth.error.Message}}. Please check the date of birth entered and try again or request a new link from your pharmacist.
            </div>
          <div *ngIf="authForm.invalid">
            <div class="form-help-text" *ngIf="authForm.hasError('dateInvalid'); else requiredMessage">
              Incorrect date entered
            </div>
            <ng-template #requiredMessage>
              <div class="form-help-text">
                All fields are requires.
              </div>
            </ng-template>
          </div>


        </form>
      </div>
    </ng-container>
    <ng-template #loading>
      <app-loader loadingMessage="Checking your identity..."></app-loader>
    </ng-template>
  </section>
</ng-container>
