import { Selector } from '@ngxs/store';
import { HMRFormState, HMRFormStateModel } from './hmr-form.state';
import { HmrFormModel } from '../../../../../../shared/models/six-cpa/HMR/hmr-form.model';
import { HMRPatientDetailsFormDetailedState } from '../../hmr-patient-details-form/state/hmr-patient-details-form-detailed.state';
import { HMRPatientDetailsFormModel } from '../../../../../../shared/models/six-cpa/HMR/hmr-patient-details-form.model';
import * as moment_ from 'moment';
const moment = moment_;
import { HMRPatientDetailsFormBasicState } from '../../hmr-patient-details-form/state/hmr-patient-details-form-basic.state';
import { HMRPharmacyInformationState } from '../../hmr-pharmacy-information/state/hmr-pharmacy-information.state';
import { HMRPharmacyInformationFormModel } from '../../../../../../shared/models/six-cpa/HMR/hmr-pharmacy-information-form.model';
import { HMRGPDetailsReferralFormDetailedState } from '../../hmr-gp-details-referral-form/state/hmr-gp-details-referral-form-detailed.state';
import { HMRGPDetailsReferralFormModel } from '../../../../../../shared/models/six-cpa/HMR/hmr-gp-details-referral-form.model';
import { HMRGPDetailsReferralFormBasicState } from '../../hmr-gp-details-referral-form/state/hmr-gp-details-referral-form-basic.state';
import { HMRGPDetailsReferralFormBasicLightState } from '../../hmr-gp-details-referral-form/state/hmr-gp-details-referral-form-basic-light.state';
import { HMRLocationFormModel } from '../../../../../../shared/models/six-cpa/HMR/hmr-location-form.model';
import { HMRLocationState } from '../../hmr-location/state/hmr-location.state';
import { HMRServiceProviderDetailsFormModel } from '../../../../../../shared/models/six-cpa/HMR/hmr-service-provider-details-form.model';
import { HMRServiceProviderDetailsFormDetailedState } from '../../hmr-service-provider-details/state/hmr-service-provider-details-form-detailed.state';
import { HMRServiceProviderDetailsFormBasicState } from '../../hmr-service-provider-details/state/hmr-service-provider-details-form-basic.state';
import { HMRProposedDetailsFormModel } from '../../../../../../shared/models/six-cpa/HMR/hmr-proposed-details-form.model';
import { HMRProposedDetailsFormBasicState } from '../../hmr-proposed-details/state/hmr-proposed-details-form-basic.state';
import { HMRProposedDetailsFormDetailedState } from '../../hmr-proposed-details/state/hmr-proposed-details-form-detailed.state';
import { HMRAccreditedPharmacistDetailsFormModel } from '../../../../../../shared/models/six-cpa/HMR/hmr-accredited-pharmacist-details-form.model';
import { HMRAccreditedPharmacistDetailsState } from '../../hmr-accredited-pharmacist-details/state/hmr-accredited-pharmacist-details.state';
import { HMRConsentAndEligibilityFormModel } from '../../../../../../shared/models/six-cpa/HMR/hmr-consent-and-eligibility.model';
import { HMRConsentAndEligibilityState } from '../../hmr-consent-and-eligibility/state/hmr-consent-and-eligibility.state';

export class HMRFormsService {
  @Selector([
    HMRFormState,
    HMRPatientDetailsFormBasicState.form,
    HMRPharmacyInformationState.hmrPharmacyInformationForm,
    HMRGPDetailsReferralFormDetailedState.form,
    HMRLocationState.hmrLocationForm,
    HMRConsentAndEligibilityState.hmrConsentAndEligibilityForm

  ])
  static getHMRNewClaimRequest(
    hmrState: HMRFormStateModel,
    patientDetails: HMRPatientDetailsFormModel,
    pharmacyInformation: HMRPharmacyInformationFormModel,
    gpDetailsReferral: HMRGPDetailsReferralFormModel,
    location: HMRLocationFormModel,
    consentAndEligibility: HMRConsentAndEligibilityFormModel
): HmrFormModel {
    const patientDetailsForm = this.getPatientDetailsNewClaimData(hmrState.patientDetailsFormId, patientDetails);
    const pharmacyInformationForm = this.getPharmacyInformationData(hmrState.pharmacyInformationFormId, pharmacyInformation);
    const gpDetailsReferralForm = this.getGPDetailsReferralNewClaimData(hmrState.gpDetailsReferralFormId, gpDetailsReferral);
    const locationForm = this.getLocationData(hmrState.locationFormId, location);
    const consentAndEligibilityForm = this.getConsentAndEligibilityData(hmrState.consentAndEligibilityFormId, consentAndEligibility);

    return {
      id: hmrState.hmrId,
      sixCpaFormId: hmrState.sixCpaFormId,
      hmrFormType: hmrState.hmrFormType,
      patientDetailsFormId: hmrState.patientDetailsFormId,
      pharmacyInformationFormId: hmrState.pharmacyInformationFormId,
      gpDetailsReferralFormId: hmrState.gpDetailsReferralFormId,
      locationFormId: hmrState.locationFormId,
      consentAndEligibilityFormId: hmrState.consentAndEligibilityFormId,
      patientDetailsForm,
      pharmacyInformationForm,
      gpDetailsReferralForm,
      locationForm,
      consentAndEligibilityForm
    };
  }

  @Selector([
    HMRFormState,
    HMRPatientDetailsFormDetailedState.form,
    HMRGPDetailsReferralFormBasicState.form,
    HMRServiceProviderDetailsFormDetailedState.form,
    HMRProposedDetailsFormBasicState.form,
  ])
  static getHMRInterviewRegisteredPhamacistRequest(
    hmrState: HMRFormStateModel,
    patientDetails: HMRPatientDetailsFormModel,
    gpDetailsReferral: HMRGPDetailsReferralFormModel,
    serviceProviderDetails: HMRServiceProviderDetailsFormModel,
    proposedDetails: HMRProposedDetailsFormModel,
): HmrFormModel {
    const patientDetailsForm = this.getPatientDetailsInterviewData(hmrState.patientDetailsFormId, patientDetails);
    const gpDetailsReferralForm = this.getGPDetailsReferralInterviewData(hmrState.gpDetailsReferralFormId, gpDetailsReferral);
    const serviceProviderDetailsForm = this.getServiceProviderDetailsInterviewData(hmrState.serviceProviderDetailsFormId, serviceProviderDetails);
    const proposedDetailsForm = this.getProposedDetailsIntervieRegisteredPhamacistData(hmrState.proposedDetailsFormId, proposedDetails);

    return {
      id: hmrState.hmrId,
      sixCpaFormId: hmrState.sixCpaFormId,
      hmrFormType: hmrState.hmrFormType,
      patientDetailsFormId: hmrState.patientDetailsFormId,
      gpDetailsReferralFormId: hmrState.gpDetailsReferralFormId,
      serviceProviderDetailsFormId: hmrState.serviceProviderDetailsFormId,
      proposedDetailsFormId: hmrState.proposedDetailsFormId,
      patientDetailsForm,
      gpDetailsReferralForm,
      serviceProviderDetailsForm,
      proposedDetailsForm
    };
  }

  @Selector([
    HMRFormState,
    HMRPatientDetailsFormDetailedState.form,
    HMRGPDetailsReferralFormBasicLightState.form,
    HMRServiceProviderDetailsFormBasicState.form,
    HMRProposedDetailsFormDetailedState.form,
    HMRAccreditedPharmacistDetailsState.hmrAccreditedPharmacistDetailsForm,
  ])
  static getHMRInterviewOutsidePatientHomeRequest(
    hmrState: HMRFormStateModel,
    patientDetails: HMRPatientDetailsFormModel,
    gpDetailsReferral: HMRGPDetailsReferralFormModel,
    serviceProviderDetails: HMRServiceProviderDetailsFormModel,
    proposedDetails: HMRProposedDetailsFormModel,
    accreditedPharmacistDetails: HMRAccreditedPharmacistDetailsFormModel
): HmrFormModel {
    const patientDetailsForm = this.getPatientDetailsInterviewData(hmrState.patientDetailsFormId, patientDetails);
    const gpDetailsReferralForm = this.getGPDetailsReferralIntervieOutsidewData(hmrState.gpDetailsReferralFormId, gpDetailsReferral);
    const serviceProviderDetailsForm = this.getServiceProviderDetailsIntervieOutsideData(hmrState.serviceProviderDetailsFormId, serviceProviderDetails);
    const proposedDetailsForm = this.getProposedDetailsInterviewOutsidePatientData(hmrState.proposedDetailsFormId, proposedDetails);
    const accreditedPharmacistDetailsForm = this.getAccreditedPharmacistDetailsData(hmrState.accreditedPharmacistDetailsFormId, accreditedPharmacistDetails);

    return {
      id: hmrState.hmrId,
      sixCpaFormId: hmrState.sixCpaFormId,
      hmrFormType: hmrState.hmrFormType,
      patientDetailsFormId: hmrState.patientDetailsFormId,
      gpDetailsReferralFormId: hmrState.gpDetailsReferralFormId,
      serviceProviderDetailsFormId: hmrState.serviceProviderDetailsFormId,
      proposedDetailsFormId: hmrState.proposedDetailsFormId,
      accreditedPharmacistDetailsFormId: hmrState.accreditedPharmacistDetailsFormId,
      patientDetailsForm,
      gpDetailsReferralForm,
      serviceProviderDetailsForm,
      proposedDetailsForm,
      accreditedPharmacistDetailsForm
   };
  }

  private static getPharmacyInformationData(id: number, pharmacyInformation: HMRPharmacyInformationFormModel) {
    return pharmacyInformation ? {
      id,
      accreditationNumber: pharmacyInformation.accreditationNumber,
      pharmacistGivenName: pharmacyInformation.pharmacistGivenName,
      pharmacistFamilyName: pharmacyInformation.pharmacistFamilyName,
      dateOfService: moment(pharmacyInformation.dateOfService).toDate(),
      dateCompletedReportSentToGP: moment(pharmacyInformation.dateCompletedReportSentToGP).toDate(),
    } : null;
  }

  private static getAccreditedPharmacistDetailsData(id: number, accreditedPharmacistDetails: HMRAccreditedPharmacistDetailsFormModel) {
    return accreditedPharmacistDetails ? {
      id,
      accreditationNumber: accreditedPharmacistDetails.accreditationNumber,
      pharmacistGivenName: accreditedPharmacistDetails.pharmacistGivenName,
      pharmacistFamilyName: accreditedPharmacistDetails.pharmacistFamilyName,
      pharmacistEmail: accreditedPharmacistDetails.pharmacistEmail,
      pharmacistPhoneNumber: accreditedPharmacistDetails.pharmacistPhoneNumber,

    } : null;
  }

  private static getConsentAndEligibilityData(id: number, сonsentAndEligibility: HMRConsentAndEligibilityFormModel) {
    return сonsentAndEligibility ? {
      id,
      writtenConsentForProvisionOfData: сonsentAndEligibility.writtenConsentForProvisionOfData

    } : null;
  }

  private static getPatientDetailsNewClaimData(id: number, patientDetails: HMRPatientDetailsFormModel) {
    return patientDetails ? {
      id,
      medicareNumber: patientDetails.medicareNumber,
      dob: moment(patientDetails.dob).toDate(),
      firstName: patientDetails.firstName,
      surname: patientDetails.surname
    } : null;
  }

  private static getPatientDetailsInterviewData(id: number, patientDetails: HMRPatientDetailsFormModel) {
    return patientDetails ? {
      id,
      uniqueIdentifier: patientDetails.uniqueIdentifier,
      residentialPostCode: patientDetails.residentialPostCode,
      homeSuburb: patientDetails.homeSuburb,
    } : null;
  }

  private static getGPDetailsReferralNewClaimData(id: number, gpDetailsReferral: HMRGPDetailsReferralFormModel) {
    return gpDetailsReferral ? {
      id,
      reasonForReferral: {
        polypharmacy: gpDetailsReferral.reasonForReferral.polypharmacy,
        suspectedAdverseEvent: gpDetailsReferral.reasonForReferral.suspectedAdverseEvent,
        usingMedicinesWithNarrowTherapeuticRange: gpDetailsReferral.reasonForReferral.usingMedicinesWithNarrowTherapeuticRange,
        other: gpDetailsReferral.reasonForReferral.other
      },
      otherReasonForReferral: gpDetailsReferral.otherReasonForReferral,
      providerNumber: gpDetailsReferral.providerNumber,
      firstName: gpDetailsReferral.firstName,
      surname: gpDetailsReferral.surname,
      referralDate: moment(gpDetailsReferral.referralDate).toDate(),

    } : null;
  }

  private static getGPDetailsReferralInterviewData(id: number, gpDetailsReferral: HMRGPDetailsReferralFormModel) {
    return gpDetailsReferral ? {
      id,
      providerNumber: gpDetailsReferral.providerNumber,
      firstName: gpDetailsReferral.firstName,
      surname: gpDetailsReferral.surname,
      referralDate: moment(gpDetailsReferral.referralDate).toDate(),
    } : null;
  }
  private static getGPDetailsReferralIntervieOutsidewData(id: number, gpDetailsReferral: HMRGPDetailsReferralFormModel) {
    return gpDetailsReferral ? {
      id,
      providerNumber: gpDetailsReferral.providerNumber,
      firstName: gpDetailsReferral.firstName,
      surname: gpDetailsReferral.surname,
   } : null;
  }

  private static getLocationData(id: number, location: HMRLocationFormModel) {
    return location ? {
      id,
      homeSuburb: location.homeSuburb,
      residentialPostCode: location.residentialPostCode,
      outcomesAndRecommendations: {
        increaseInDoseOfMedicines: location.outcomesAndRecommendations.increaseInDoseOfMedicines,
        decreaseInDoseOfMedicines: location.outcomesAndRecommendations.decreaseInDoseOfMedicines,
        changeMedicines: location.outcomesAndRecommendations.changeMedicines,
        cessationOfMedicines: location.outcomesAndRecommendations.cessationOfMedicines,
        other: location.outcomesAndRecommendations.other
      },
      otherRecommendation: location.otherRecommendation,
      tAndCs: location.tAndCs,
   } : null;
  }

  private static getServiceProviderDetailsInterviewData(id: number, serviceProviderDetails: HMRServiceProviderDetailsFormModel) {
    return serviceProviderDetails ? {
      id,
      serviceProviderName: serviceProviderDetails.serviceProviderName,
      serviceProviderPhoneNumber: serviceProviderDetails.serviceProviderPhoneNumber,
      serviceProviderEmail: serviceProviderDetails.serviceProviderEmail,
      pharmacistGivenName: serviceProviderDetails.pharmacistGivenName,
      pharmacistFamilyName: serviceProviderDetails.pharmacistFamilyName,
      ahpraNumber: serviceProviderDetails.ahpraNumber,
      pharmacistPhoneNumber: serviceProviderDetails.pharmacistPhoneNumber,
      pharmacistEmail: serviceProviderDetails.pharmacistEmail,
    } : null;
  }
  private static getServiceProviderDetailsIntervieOutsideData(id: number, serviceProviderDetails: HMRServiceProviderDetailsFormModel) {
    return serviceProviderDetails ? {
      id,
      serviceProviderName: serviceProviderDetails.serviceProviderName,
      serviceProviderPhoneNumber: serviceProviderDetails.serviceProviderPhoneNumber,
      serviceProviderEmail: serviceProviderDetails.serviceProviderEmail,
   } : null;
  }

  private static getProposedDetailsIntervieRegisteredPhamacistData(id: number, proposedDetails: HMRProposedDetailsFormModel) {
    return proposedDetails ? {
      id,
      proposedDateOfInterview: moment(proposedDetails.proposedDateOfInterview).toDate(),
      termsAndConditions: proposedDetails.termsAndConditions
   } : null;
  }

  private static getProposedDetailsInterviewOutsidePatientData(id: number, proposedDetails: HMRProposedDetailsFormModel) {
    return proposedDetails ? {
      id,
      proposedDateOfInterview: moment(proposedDetails.proposedDateOfInterview).toDate(),
      dateOfGPReferral: moment(proposedDetails.dateOfGPReferral).toDate(),
      proposedLocation: proposedDetails.proposedLocation,
      suburb: proposedDetails.suburb,
      postcode: proposedDetails.postcode
   } : null;
  }

}
