import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import * as moment from 'moment';
import { SmsService } from '../../shared/services/sms.service';
import { Sms } from '../../shared/models/sms.model';
@Component({
  selector: 'app-sms-report-page',
  templateUrl: './sms-report-page.component.html',
  styleUrls: ['./sms-report-page.component.scss']
})
export class SmsReportPageComponent implements OnInit, OnDestroy {

  constructor(private smsService: SmsService) { }

  sub1: Subscription;
  smsList: Sms[] = [];
  filteredSmsList: Sms[] = [];
  isLoaded = false;

  ngOnInit() {
    this.sub1 = this.smsService.getSmsList()
    .subscribe((smsList: Sms[]) => {
      this.smsList = smsList;
      this.setOroginalEvents();

      this.isLoaded = true;
    });
  }

  private setOroginalEvents() {
    this.filteredSmsList = this.smsList.slice();
  }
  applyFillters(filters: any) {

    this.filteredSmsList = this.smsList
    .filter((item) => {
      if (filters.type !== 'all') {
        if (item.type.includes(filters.type)) {
          return true;
        }
      } else {
        return true;
      }
    })
    .filter((item) => {
      if (
        (item.customerPhone.toLowerCase()).includes(filters.value) ||
        (item.customerName.toLowerCase()).includes(filters.value)
      ) {
        return true;
      }
    })
    .filter((item) => {
      if (filters.range && filters.range.length > 0) {
        const momentDate =  moment(item.date);
        return momentDate.isBetween(filters.range[0], filters.range[1]);
      }
      return true;
    });
  }

  ngOnDestroy() {
    if (this.sub1) { this.sub1.unsubscribe(); }
  }
}
