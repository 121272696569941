import { State, Action, StateContext, Selector } from '@ngxs/store';
import { SetTotalItemsAndCompletedItems } from './hmr-service-provider-details-form.actions';
import { Injectable } from '@angular/core';

export class HMRServiceProviderDetailsFormStateModel {
  totalItems: number;
  completedItems: number;
}
@State<HMRServiceProviderDetailsFormStateModel>({
  name: 'hmrServiceProviderDetailsForm',
  defaults: {
    completedItems: 0,
    totalItems: 0
  }
})
@Injectable()
export class HMRServiceProviderDetailsFormState {

  @Selector()
  static totalItems({ totalItems }: HMRServiceProviderDetailsFormStateModel) { return totalItems; }

  @Selector()
  static completedItems({ completedItems }: HMRServiceProviderDetailsFormStateModel) { return completedItems; }

  @Action(SetTotalItemsAndCompletedItems)
  setProgress({ patchState }: StateContext<HMRServiceProviderDetailsFormStateModel>, { totalItems, completedItems }: SetTotalItemsAndCompletedItems) {
    patchState({ totalItems, completedItems });
  }
}
