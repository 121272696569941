import { NgModule } from "@angular/core";
import { ActivatedRouteSnapshot, RouterModule, Routes } from "@angular/router";
import { OptOutConfirmationComponent } from "./opt-out-confirmation/opt-out-confirmation.component";
import { OptOutPageComponent } from "./opt-out-page/opt-out-page.component";



const routes: Routes = [
  {
    path: 'opt-out',
    children: [
      { path: '', component: OptOutPageComponent },
      // { path: 'error', component: SignUpErrorComponent },
      { path: 'confirmation', component: OptOutConfirmationComponent }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class OptOutRoutingModule {
}