import { SickLeaveConsentAndEligibilityFormModel } from '../../../../../../shared/models/six-cpa/sick-leave/sick-leave-consent-and-eligibility-form.model';

export enum SickLeaveConsentAndEligibilityFormActions {
  SET_VALID = '[SickLeaveConsentAndEligibilityForm] set valid',
  UPDATE = '[SickLeaveConsentAndEligibilityForm] Update Form',
  UPDATE_PROGRESS = '[SickLeaveConsentAndEligibilityForm] Update Progress'
}

export class SetSickLeaveConsentAndEligibilityFormValidAction {
  static readonly type = SickLeaveConsentAndEligibilityFormActions.SET_VALID;
  constructor(public valid = false) { }
}

export class SetTotalItemsAndCompletedItems {
  static readonly type = SickLeaveConsentAndEligibilityFormActions.UPDATE_PROGRESS;
  constructor(public totalItems, public completedItems) { }
}

export class UpdateSickLeaveConsentAndEligibilityForm {
  static readonly type = SickLeaveConsentAndEligibilityFormActions.UPDATE;
  constructor(public form: Partial<SickLeaveConsentAndEligibilityFormModel>) { }
}
