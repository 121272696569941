import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { StorageService } from '../core/storage/storage.service';
import { AlertService } from '../../modules/core/alert/alert.service';
import { StatisticRequestModel } from '../models/statistics.model';
import { BaseApiNoAlerts } from '../core/base-api/base-api-no-alerts';

@Injectable()
export class StatisticsService extends BaseApiNoAlerts {
    API = '';

    constructor(
      @Inject('environment') env: any,
      public http: HttpClient,
      public router: Router,
      public storageService: StorageService,
      public alertService: AlertService
    ) {
      super(http, router, storageService, alertService);
      this.API = `${env.baseApiUrl}/api/statistics`;
    }

    createStatistics(createRequest: StatisticRequestModel) {
      return this.post(`${this.API}/create`, createRequest );
    }

    updateStatistic(updateRequest: StatisticRequestModel) {
        return this.post(`${this.API}/update`, updateRequest );
    }
}