import { State, Selector, Action, StateContext } from '@ngxs/store';
import { GetEmailHistory, SendEmail, ToggleModal } from './email.actions';
import { Injectable } from '@angular/core';
import { Email } from '~/shared/models/email.model';
import { EmailService } from '~/shared/services/email.service';
import { catchError, finalize, tap } from 'rxjs/operators';
import { EMPTY, of } from 'rxjs';

export interface EmailStateModel {
  emailHistory: Email[],
  loading: boolean,
  showModal: boolean;
}
@Injectable()
@State<EmailStateModel>({
  name: 'email',
  defaults: {
    emailHistory: null,
    loading: true,
    showModal: false
  }
})
export class EmailState {
  constructor(private emailService: EmailService) {}

  @Selector()
  static emailHistory({ emailHistory }: EmailStateModel) {
    return emailHistory;
  }

  @Selector()
  static loading({ loading }: EmailStateModel) {
    return loading;
  } 

  @Selector()
  static showModal(state: EmailStateModel) { return state.showModal };

  @Action(GetEmailHistory) 
  getEmailHistory(ctx: StateContext<EmailStateModel>, { clientId }: GetEmailHistory) {
    return this.emailService.getClientEmailHistory(clientId).pipe(
      tap((res) => {
        ctx.patchState({ emailHistory: res})
      }),
      catchError((error) => of(error)),
      finalize(() => ctx.patchState({ loading: false }))
    );
  }

  @Action(SendEmail) 
  sendEmail(ctx: StateContext<EmailStateModel>, { email }: SendEmail) {
    return this.emailService.sendEmail(email).pipe(
      tap((sentEmail) => {
        ctx.patchState({
          emailHistory: [...ctx.getState().emailHistory, sentEmail]
        });
        return EMPTY;
      }),
      catchError((error) => of(error))
    );
  }

  @Action(ToggleModal)
  toggleModal(ctx: StateContext<EmailStateModel>, { showModal }: ToggleModal) {
    ctx.patchState({
      showModal: showModal
    })
  }
}
