// import { environment } from '../../../../../../../environments/environment';
import { Inject, Injectable } from '@angular/core';
import { BaseApiNoAlerts } from '../../../../../../shared/core/base-api/base-api-no-alerts';
import { Router } from '@angular/router';
import { StorageService } from '../../../../../../shared/core/storage/storage.service';
import { AlertService } from '../../../../../core/alert/alert.service';
import { HttpClient } from '@angular/common/http';
import { SixCpaMedicationModel } from '../../../../../../shared/models/six-cpa/six-cpa-medication.model';
import { Observable } from 'rxjs';
import { DoctorEmailModel } from '../../../../../../shared/models/six-cpa/doctor-email.model';
import { MedicationListFormModel } from '../../../../../../shared/models/six-cpa/medication-list/medication-list-form.model';

@Injectable()
export class MedicationListService extends BaseApiNoAlerts {
  API = '';

  constructor(
    @Inject('environment') env: any,
    public http: HttpClient,
    public router: Router,
    public storageService: StorageService,
    public alertService: AlertService
  ) {
    super(http, router, storageService, alertService);
    this.API = env.baseApiUrl;
  }

  postSubmit(clientId: string, formData: MedicationListFormModel) {
    return this.post<MedicationListFormModel>(
      `${this.API}/api/med-list/${clientId}/submit`,
      formData
    );
  }

  putSubmit(clientId: string, formData: MedicationListFormModel) {
    return this.put(
      `${this.API}/api/med-list/${clientId}/update-submit`,
      formData
    );
  }

  getMedicationListForm(clientId: string, formId: number): Observable<any> {
    return this.get<any>(`${this.API}/api/med-list/${clientId}/form/${formId}`);
  }

  getPatientReport(
    clientId: string,
    sixCpaMedications: SixCpaMedicationModel[],
    fullName?: string,
    allergies?: string,
    primaryDoctor?: string): Observable<any> {
    return this.post(`${this.API}/api/med-list/${clientId}/print/getPatientReport`, {sixCpaMedications, fullName, allergies, primaryDoctor}, { responseType: 'blob' });
  }

  getDoctorReport(
    clientId: string,
    sixCpaMedications: SixCpaMedicationModel[],
    reportBody: string,
    fullName?: string,
    doctorAddress?: string,
    allergies?: string,
    primaryDoctor?: string
    ): Observable<any> {
    return this.post(`${this.API}/api/med-list/${clientId}/print/getDoctorReport`, {sixCpaMedications, reportBody, fullName, doctorAddress, allergies, primaryDoctor}, {responseType: 'blob'});
  }

  sendMedsListReport(clientId: string, formData: DoctorEmailModel) {
    return this.post<DoctorEmailModel>(`${this.API}/api/med-list/${clientId}/sendMedsListReport`, formData);
  }

  sendDoctorReport(clientId: string, formData: DoctorEmailModel) {
    return this.post<DoctorEmailModel>(
      `${this.API}/api/med-list/${clientId}/sendDoctorReport`,
      formData
    );
  }

  sendMedsListEmail(clientId: string, formData: DoctorEmailModel) {
    return this.post<DoctorEmailModel>(
      `${this.API}/api/meds-check/${clientId}/sendMedsListReport`,
      formData
    );
  }
}
