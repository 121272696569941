import { State, Selector, Action, StateContext } from '@ngxs/store';
import { UpdateConsentEligibilityDetailedForm } from './consent-eligibility-form-detailed.actions';
import { IConsentEligibilityFormStateModel } from './consent-eligibility-form-state-model.interface';
import * as moment_ from 'moment';
const moment = moment_;
import { Injectable } from '@angular/core';

export class ConsentEligibilityFormDetailedStateModel {
  consentEligibilityForm: {
    model: Partial<IConsentEligibilityFormStateModel>;
  };
  valid: boolean;
  totalItems: number;
  completedItems: number;
}
@Injectable()
@State<ConsentEligibilityFormDetailedStateModel>({
  name: 'consentEligibilityFormDetailed',
  defaults: {
    consentEligibilityForm: {
      model: undefined
    },
    valid: false,
    completedItems: 0,
    totalItems: 0
  }
})
export class ConsentEligibilityFormDetailedState {
  @Selector()
  static form(state: ConsentEligibilityFormDetailedStateModel) { return state.consentEligibilityForm.model; }

  @Action(UpdateConsentEligibilityDetailedForm)
  updateForm(ctx: StateContext<ConsentEligibilityFormDetailedStateModel>, { form }: UpdateConsentEligibilityDetailedForm) {
    ctx.patchState({
      consentEligibilityForm: {
        model: {
          patientsConsent: form.patientsConsent,
          hasPreviousMedsCheck: form.hasPreviousMedsCheck,
          currentLivingStatus: form.currentLivingStatus,
          currentLivingOtherDetails: form.currentLivingOtherDetails,
          gpOrHospitalLastSixMonthsForMedicationProblems: form.gpOrHospitalLastSixMonthsForMedicationProblems,
          medicineManagementSupport: form.medicineManagementSupport,
          followUpService: form.followUpService && moment(form.followUpService, 'YYYY-MM-DD').format('DD-MM-YYYY'),
          consentType: form.consentType
        }
      }
    });
  }
}
