<div class="pt-2">
  <p>It’s not currently possible for Clinical Interventions to be automatically claimed via the <a
      (click)="openPPAPortal()">PPA portal.</a>
    Completing this form will export the Registered
    Clinical Intervention so it can be manually copied across.</p>
</div>
<app-medication-profile></app-medication-profile>
<app-classification-and-recommendations-form-list-item></app-classification-and-recommendations-form-list-item>
<app-additional-details-form-list-item></app-additional-details-form-list-item>

<div class="flex mt-4">
  <button *ngIf="!(readonly$ | async)" class="btn__green mr-3" (click)="submitForm()">Complete Clinical Intervention</button>
  <button class="btn__white mr-3" (click)="saveForm()">Save Clinical Intervention</button>
  <button class="btn__link" (click)="resetForm()">Reset form</button>
</div>
