import { State, Action, StateContext, Selector } from '@ngxs/store';
import { SickLeaveConsentAndEligibilityFormModel } from '../../../../../../shared/models/six-cpa/sick-leave/sick-leave-consent-and-eligibility-form.model';
import {
  SetSickLeaveConsentAndEligibilityFormValidAction,
  SetTotalItemsAndCompletedItems,
  UpdateSickLeaveConsentAndEligibilityForm } from './sick-leave-consent-and-eligibility.actions';
  import { Injectable } from '@angular/core';

export class SickLeaveConsentAndEligibilityStateModel {
  sickLeaveConsentAndEligibilityForm: {
    model: Partial<SickLeaveConsentAndEligibilityFormModel>
  };
  valid: boolean;
  totalItems: number;
  completedItems: number;
}
@Injectable()
@State<SickLeaveConsentAndEligibilityStateModel>({
  name: 'sickLeaveConsentAndEligibility',
  defaults: {
    sickLeaveConsentAndEligibilityForm: {
      model: undefined
    },
    valid: false,
    completedItems: 0,
    totalItems: 0
  }
})
export class SickLeaveConsentAndEligibilityState {
  @Selector()
  static sickLeaveConsentAndEligibilityForm(state: SickLeaveConsentAndEligibilityStateModel) { return state.sickLeaveConsentAndEligibilityForm.model; }

  @Selector()
  static totalItems(state: SickLeaveConsentAndEligibilityStateModel) { return state.totalItems; }

  @Selector()
  static completedItems(state: SickLeaveConsentAndEligibilityStateModel) { return state.completedItems; }

  @Action(SetSickLeaveConsentAndEligibilityFormValidAction)
  setValid(ctx: StateContext<SickLeaveConsentAndEligibilityStateModel>, action: SetSickLeaveConsentAndEligibilityFormValidAction) {
    ctx.patchState({
      valid: action.valid
    });
  }

  @Action(SetTotalItemsAndCompletedItems)
  setProgress(ctx: StateContext<SickLeaveConsentAndEligibilityStateModel>, action: SetTotalItemsAndCompletedItems) {
    ctx.patchState({
      totalItems: action.totalItems,
      completedItems: action.completedItems
    });
  }

  @Action(UpdateSickLeaveConsentAndEligibilityForm)
  updateForm(ctx: StateContext<SickLeaveConsentAndEligibilityStateModel>, { form }: UpdateSickLeaveConsentAndEligibilityForm) {
       ctx.patchState({
      sickLeaveConsentAndEligibilityForm: {
        model: form || undefined
      }
    });
  }
}
