import { Component } from '@angular/core';
import { Select } from '@ngxs/store';
import { HMRFormState, HMRFormType } from '../hmr-form-list/state/hmr-form.state';
import { Observable } from 'rxjs';
import { HMRPatientDetailsFormState, HMRPatientDetailsFormStateModel } from '../hmr-patient-details-form/state/hmr-patient-details-form.state';

@Component({
  selector: 'app-hmr-patient-details-form-list-item',
  templateUrl: './hmr-patient-details-form-list-item.component.html'
})
export class HMRPatientDetailsFormListItemComponent {
  @Select(HMRFormState.hmrFormType) hmrFormType$: Observable<HMRFormType>;
  @Select(HMRPatientDetailsFormState) formState$: Observable<HMRPatientDetailsFormStateModel>;
  hmrFormTypes = HMRFormType;
}
