import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { RouterModule } from '@angular/router';
import { MessageActionsComponent } from './message-actions.component';

@NgModule({
  declarations: [
    MessageActionsComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    NzDropDownModule,
    NzButtonModule
  ],
  exports: [
    MessageActionsComponent
  ]
})

export class MessageActionsModule { }
