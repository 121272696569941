import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Store, Actions } from '@ngxs/store';
import { HMRChildForm } from '../hmr-child-form';
import { HMRConsentAndEligibilityState } from './state/hmr-consent-and-eligibility.state';
import { SetTotalItemsAndCompletedItems } from './state/hmr-consent-and-eligibility.actions';
import { SixCpaState } from '../../state/six-cpa.state';

@Component({
  selector: 'app-hmr-consent-and-eligibility',
  templateUrl: './hmr-consent-and-eligibility.component.html',
  styleUrls: ['./hmr-consent-and-eligibility.component.scss']
})
export class HMRConsentAndEligibilityComponent extends HMRChildForm implements OnInit, OnDestroy {

  hmrConsentAndEligibilityForm: FormGroup;
  formSubscription: Subscription;
  private readonlySubscription: Subscription;
  
  constructor(private formBuilder: FormBuilder, private store: Store, actions: Actions) {
    super(actions);
    this.generateFormControls();
  }

  ngOnInit() {
    this.formSubscription = this.store.select(HMRConsentAndEligibilityState.hmrConsentAndEligibilityForm)
      .subscribe(() => this.onFormChange());
    this.readonlySubscription = this.store.select(SixCpaState.formsReadOnly)
      .subscribe(readonly => readonly ? this.hmrConsentAndEligibilityForm.disable() : this.hmrConsentAndEligibilityForm.enable());
  }

  ngOnDestroy() {
    this.formSubscription.unsubscribe();
    this.readonlySubscription.unsubscribe();
  }
  get formControls() {
    return this.hmrConsentAndEligibilityForm.controls;
  }
  protected actionOnResetForm() {
    this.hmrConsentAndEligibilityForm.patchValue(
      {
        writtenConsentForProvisionOfData: true
      });
  }

  private onFormChange() {
    const controls = Object.keys(this.hmrConsentAndEligibilityForm.controls);
    const completedItems = this.getTotalValidItems(controls);
    this.store.dispatch(new SetTotalItemsAndCompletedItems(controls.length, completedItems));
  }

  private getTotalValidItems(controls: Array<string>) {
    return controls.filter((key) => {
      const formControl = this.hmrConsentAndEligibilityForm.get(key);
      return  formControl.status === 'VALID' || (formControl.status === 'DISABLED' && !formControl.invalid && formControl.errors === null);
    }).length;
  }

  private generateFormControls() {
    this.hmrConsentAndEligibilityForm = this.formBuilder.group({
      writtenConsentForProvisionOfData: new FormControl(true, Validators.required),
    });
  }

}
