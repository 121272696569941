import { Component, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { SixCpaUserCommunicationsState, SixCpaUserCommunicationTypes } from './state/six-cpa-user-communications.state';
import { Observable } from 'rxjs';
import { ClearSixCpaCommunications } from './state/six-cpa-user-communications.actions';

@Component({
  selector: 'app-six-cpa-user-communications',
  templateUrl: './six-cpa-user-communications.component.html',
  styleUrls: ['./six-cpa-user-communications.component.scss', '../six-cpa-form-list-item.component.scss']
})
export class SixCpaUserCommunicationsComponent implements OnInit {
  @Select(SixCpaUserCommunicationsState.messages) messages$: Observable<any>;
  sixCpaUserCommunicationTypes = SixCpaUserCommunicationTypes;
  constructor(private store: Store) { }

  ngOnInit() {
  }

  clearComms() {
    this.store.dispatch(new ClearSixCpaCommunications());
  }
}
