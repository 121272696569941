export enum ScryptDigitalSignatureActions {
  SET_DIGITAL_SIGNATURE = '[ScryptDigitalSignature] Add item',
  CLEAR_DIGITAL_SIGNATURE = '[ScryptDigitalSignature] Add item'
}

export class SetDigitalSignature {
  static readonly type = ScryptDigitalSignatureActions.SET_DIGITAL_SIGNATURE;
  constructor(public digitalSignature: string) {
  }
}

export class ClearDigitalSignature {
  static readonly type = ScryptDigitalSignatureActions.CLEAR_DIGITAL_SIGNATURE;
  constructor() {
  }
}
