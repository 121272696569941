import { Component, OnInit, ChangeDetectionStrategy, Input, ChangeDetectorRef } from '@angular/core';
import * as moment from 'moment';
import { PdfPaperOrientation, PdfPaperSize } from '~/shared/models/pdf-generator.models';
import { PdfGeneratorService } from '~/shared/services/pdf-generator.service';

@Component({
  selector: 'app-advanced-search-export-options',
  templateUrl: './advanced-search-export-options.component.html',
  styleUrls: ['./advanced-search-export-options.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdvancedSearchExportOptionsComponent implements OnInit {
  @Input() rows: any[];
  @Input() headers: string[];
  @Input() expandedColumns: number[] = [];
  loading = false;
  constructor(private pdfGenerator: PdfGeneratorService, private cd: ChangeDetectorRef) { }

  ngOnInit(): void {
  }

  private fileName() {
    return 'advanced-search'
  }

  private exportAsCsv() {
    this.loading = true;
    let csvContent = "data:text/csv;charset=utf-8," 
      + this.headers.join(",") + "\n"
      + this.rows.map(row => 
        (row.map(val => this.cleanDataForCSV(val)))
          .join(",")).join("\n");
  
    var encodedUri = encodeURI(csvContent);
    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", this.fileName() +".csv");
    document.body.appendChild(link);
    link.click();
    this.loading = false;
    this.cd.markForCheck();
  }

  private cleanDataForCSV(value) {
    let valueNotNull: any = value === null ? '': value

    if (valueNotNull && this.isNumber(valueNotNull)) {
      return this.formatAsTextInExcel(valueNotNull);
    }
    
    if (valueNotNull && typeof valueNotNull === 'string') {
      valueNotNull = valueNotNull.replace(',', ' ') // will create extra columns
      valueNotNull = valueNotNull.replace('#', ' ') // will cause download link to cut off because it's treated as a url
    }

    return valueNotNull;
  }

  private formatAsTextInExcel(value) {
    // this is the only way to show numbers as strings in excel, will show in scientific notation otherwise
    return `"=""${value}"""`
  }

  private isNumber(value) {
    return typeof value === 'number' && isFinite(value) || !isNaN(value);
  }

  private exportAsPdf() {
    this.loading = true;
    const styles =`
    <style>
    tr:nth-child(even) {background-color: rgb(255,250,240)!important;}
    tr {background-color: rgb(254,241,207);}
    
    table {
      border-collapse: collapse;
      border-spacing: 0;
    }
    body, html {
      font-family: 'Nunito', sans-serif;
      font-weight: 400;
      font-size: 14px;
    }
    th {
      padding-top: 3px;
      padding-bottom: 10px;
      font-size: 0.875em;
      vertical-align: bottom;
      font-weight: bold;
      text-align:left;
    }
    .table td, .table th {
      padding: .75rem;
    }
    .expanded {
      min-width: 200px;
    }
    </style>
    `; 
    const table = (thead, tbody) => {
      return `<table class="table">
        <thead>${thead}</thead>
        <tbody>${tbody}</tbody>
      </table>`
    }
    const data = (data)=> {
      return `<td>${data !== null && data !== undefined ? data : ''}</td>`
    }
    const row = (row) => {
      return `<tr>${row.map(td => data(td)).join('\n')}</tr>`
    };
    const theader = (label, index) => {
      return `<th ${this.expandedColumns.includes(index) ? 'class="expanded"': ''}>${label}</th>`
    };
    const thead = (headers) => {
      return `<tr>${headers.map((td, index) => theader(td, index)).join('\n')}</th>`
    };
    const html = (style, content)=>{
      return `
      <!DOCTYPE html>
      <html>
        <head>
          <title>Advanced Search Results</title>
          ${style}
        </head>
        <body>
          ${content}
        </body>
      </html>
      `
    }
    
    const htmlContent = html(
      styles,
       table(
        thead(this.headers),
        this.rows.map((r)=> row(r)).join('\n')
      ));

    this.pdfGenerator.generatePdfFromHtml({
      html: htmlContent,
      pdfPrintOptions: {
        paperOrientation: PdfPaperOrientation.Landscape,
        marginBottom: 33.0,
        marginTop: 33.0,
        marginLeft: 33.0,
        marginRight: 33.0,
        paperSize: PdfPaperSize.A4,
        header:{
          centerText: "{page} of {total-pages}",
          drawDividerLine: true,
          rightText: "Exported: " + moment().format("DD/MM/YYYY")
        }
      }
    }).subscribe(response =>{
      let dataType = response.type;
      let binaryData = [];
      binaryData.push(response);
      let downloadLink = document.createElement('a');
      downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
      downloadLink.setAttribute('download', this.fileName() +".pdf");
      document.body.appendChild(downloadLink);
      downloadLink.click();
      this.loading = false;
      this.cd.markForCheck();
    });
  }

}
