import { OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Store, Actions } from '@ngxs/store';
import { SetTotalItemsAndCompletedItems } from './state/daa-patient-details-form.actions';
import { DAAChildForm } from '../daa-child-form';
import { DoseAdministrationAidsState, DAAFormType } from '../dose-administration-aids/state/dose-administration-aids.state';

const controlsToExclude = ['currentLivingOtherDetails'];
const controlsToIgnore = ['patientDOB', 'medicareNumber', 'residentialPostCode', 'gender'];

export class DAAPatientDetailsFormBaseComponent extends DAAChildForm implements OnInit, OnDestroy {
  patientDetailsForm: FormGroup;
  formResetStatus: boolean;

  constructor(private formBuilder: FormBuilder, protected store: Store, actions: Actions) {
    super(actions);
    this.patientDetailsForm = this.formBuilder.group({
      medicareNumber: new FormControl('', [Validators.required, Validators.minLength(8)]),
    });
  }
  get formControls() {
    return this.patientDetailsForm.controls;
  }
  protected actionOnResetForm() {
    const controls = Object.keys(this.patientDetailsForm.controls);
    controls.filter((key) => {
      if (!controlsToIgnore.includes(key)) {
        this.patientDetailsForm.controls[key].setValue('');
      }
    });

    this.patientDetailsForm.controls['currentLivingStatus'].setValue(0);
    this.patientDetailsForm.controls['concessionCard'].setValue(false);
  }

  protected onFormChange() {
    const controls = Object.keys(this.patientDetailsForm.controls);
    let completedItems = this.getTotalValidItems(controls);
    const activeFormType = this.store.selectSnapshot(DoseAdministrationAidsState.daaFormType);
    if (activeFormType === DAAFormType.DAAClaim) {
      this.store.dispatch(new SetTotalItemsAndCompletedItems(controls.length, completedItems));
    } else {
      const totalControls = controls.filter(x => !controlsToExclude.includes(x));
       completedItems = this.getTotalValidItems(totalControls);

      this.store.dispatch(new SetTotalItemsAndCompletedItems(totalControls.length, completedItems));
    }
  }

  private getTotalValidItems(controls: Array<string>) {
    return controls.filter((key) => {
      const formControl = this.patientDetailsForm.get(key);
      return  formControl.status === 'VALID' || (formControl.status === 'DISABLED' && !formControl.invalid && formControl.errors === null);
    }).length;
  }
}
