import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommQueueComponent } from './comms-queue.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { LoaderModule } from '../../../modules/core/loader/loader.module';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { CommsQueueTableModule } from './comms-queue-table/comms-queue-table.module';
import { CommQueueState } from './state/comms-queue.state';
import { NgxsModule } from '@ngxs/store';
import { CommsQueueService } from './services/comms-queue.service';

@NgModule({
  declarations: [CommQueueComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    LoaderModule,
    BsDatepickerModule.forRoot(),
    CommsQueueTableModule,
    NgxsModule.forFeature([CommQueueState]),
  ],
  exports: [CommQueueComponent],
  providers: [CommsQueueService]
})
export class CommQueueModule { }
