import { CommunicationOptOut } from "../models/opt-out.model";

export enum OptOutActions {
  VERIFY_LINK = '[OptOut] VERIFY_LINK',
  VERIFY_LINK_SUCCESS = '[OptOut] VERIFY_LINK_SUCCESS',
  CONFIRM_OPT_OUT = '[OptOut] CONFIRM_OPT_OUT',
  CONFIRM_OPT_OUT_SUCCESS = '[OptOut] CONFIRM_OPT_OUT_SUCCESS'
}

export class VerifyOptOutLink {
  static readonly type = OptOutActions.VERIFY_LINK;
  constructor(public link: string) { }
}

export class VerifyOptOutLinkSuccess {
  static readonly type = OptOutActions.VERIFY_LINK_SUCCESS;
  constructor(public optOut: CommunicationOptOut) { }
}

export class ConfirmOptOut {
  static readonly type = OptOutActions.CONFIRM_OPT_OUT;
  constructor(public optOut: CommunicationOptOut) { }
}

export class ConfirmOptOutSuccess {
  static readonly type = OptOutActions.CONFIRM_OPT_OUT_SUCCESS;
}
