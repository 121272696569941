import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
} from "@angular/forms";
import { Select, Store } from "@ngxs/store";
import { AdminDashboardState } from "../state/admin-dashboard.state";
import { Observable } from "rxjs";
import { AddTenantInstallReport } from "../state/admin-dashboard.actions";
import { TenantInstallReportModel } from "~/shared/models/install-report.model";
import { TenantDetails } from "~/shared/models/tenant-details.model";
import { AdminDashboardService } from "../services/admin-dashboard.service";

@Component({
  selector: "app-tenant-install-report",
  templateUrl: "./tenant-install-report.component.html",
  styleUrls: ["./tenant-install-report.component.scss"],
})
export class TenantInstallReportComponent implements OnInit {
  @Select(AdminDashboardState.loading) loading$: Observable<boolean>;
  @Select(AdminDashboardState.loadingReport) loadingReport$: Observable<boolean>;
  @Select(AdminDashboardState.tenants) tenantDetails$: Observable<TenantDetails[]>;
  tenantInstallReportForm: FormGroup;
  loading = false;
  filteredTenantDetails: Observable<TenantDetails[]>;
  tenantDetails: TenantDetails[];
  selectedTenant: number;

  constructor(
    private formBuilder: FormBuilder,
    private store: Store,
    private adminService: AdminDashboardService
  ) { }

  ngOnInit() {
    this.generateFormControls();
    this.store.selectSnapshot(AdminDashboardState.tenants);
    this.getTenantDetails();
  }

  private generateFormControls() {
    this.tenantInstallReportForm = this.formBuilder.group({
      tenantId: new FormControl("", Validators.required),
      reportName: new FormControl("", Validators.required),
      reportId: new FormControl("", Validators.required),
      dataSetId: new FormControl("", Validators.required),
    });
  }

  private getTenantDetails() {
    this.adminService.getTenantDetails().subscribe(
      (response: TenantDetails[]) => {
        if (response !== null) {
          this.tenantDetails = response;
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  setupInstallReport() {
    const pharmacyRequest = this.tenantInstallReportForm
      .value as TenantInstallReportModel;
    this.store.dispatch(new AddTenantInstallReport(pharmacyRequest));
  }
}
