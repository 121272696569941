<form class="pt-1 pb-1">
  <ng-container *ngIf="(doctorReportTemplates$ | async) as doctorReportTemplates">
    <div class="form-group pb-1">
      <label for="selectedTemplateKey" class="col-form-label-lg">Choose a template (optional)</label>
      <select class="form-control form-control-lg" #doctorSelect name="selectedTemplateKey"
        (change)="setSelectedTemplate(doctorSelect.value)">
        <option *ngIf="!doctorReportTemplates.length">N/A</option>
        <option *ngFor="let template of doctorReportTemplates" [value]="template.id">{{template.key}}</option>
      </select>
    </div>
  </ng-container>
</form>
