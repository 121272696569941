import { RepeatType } from './../../../shared/models/profile/repeatType.model';
import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { Location } from '@angular/common';
import { CustomerProfileModal, CustomerStatus, IndigenousStatus } from '../../../shared/models/profile/customerProfile.model';
import { ProfileService } from '../../../shared/services/profile.service';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { CustomerType } from '../models/customerType.enum';
import { Select, Store } from '@ngxs/store';
import { CategoriesState } from '../categories/state/categories.state';
import * as moment_ from 'moment';
const moment = moment_;
import { FeaturesState, FeaturesStateModel } from '../../../modules/core/features/features.state';
import { Observable } from 'rxjs';
import { EvaluationHelperService } from '../event-confirmation-modal/services/evaluation-helper.service';
import { TriggerType } from '../../../shared/models/pharmacy/trigger.model';
import { GetEventNotificationOverload, ToggleEventConfirmationModal } from '../event-confirmation-modal/state/event-confirmation.actions';
import { GetProfileAction, ProfilePopupVisibleAction } from '../../../modules/core/profile/state/profile.actions';
import { GetCommTemplatesAction } from '../../../modules/pharmacy-comms/comm-templates/state/comm-templates.actions';
import { GetTriggers } from '../../../modules/pharmacy-comms/comms-reminders/state/triggers.actions';
import { GetPharmacyCommsDetailsAction } from '../../../modules/pharmacy-comms/pharmacy-comms-details/state/pharmacy-comms-details.actions';
import { ClientViewModel } from '../../../modules/core/profile/client.model';
import { ContactMethods } from '../../../shared/models/communication/contact-methods.enum';
import { PharmacyCommsDetailsState } from '../../../modules/pharmacy-comms/pharmacy-comms-details/state/pharmacy-comms-details.state';
import { PharmacyCommsDetails } from '../../../shared/models/pharmacy/pharmacy-comms-details.model';
import { isNullOrWhiteSpace } from '../../../shared/helpers/string.helpers';
import { MobileState } from '../../../modules/profile/details/mobile-state-modal/state/mobile-state-modal.state';

@Component({
  selector: 'app-profile-popup',
  templateUrl: './profile-popup.component.html',
  styleUrls: ['./profile-popup.component.scss']
})
export class ProfilePopupComponent implements OnInit, OnChanges, AfterViewInit {
  @Select(FeaturesState.getFeatures) features$: Observable<FeaturesStateModel>;
  @Select(PharmacyCommsDetailsState.defaultPharmacyCommsDetails) pharmacyComms$: Observable<PharmacyCommsDetails>;
  @Input() customer: ClientViewModel;
  @Output() close = new EventEmitter();
  isModalLoad: boolean = true;
  hasProfile: boolean;
  hasDOB: boolean;
  customerModalForm: CustomerProfileModal = new CustomerProfileModal();
  customerPersonalInfo: any;
  repeatTypes: any;
  repeatType = RepeatType;
  patientForm: FormGroup;
  customerType = CustomerType;
  customerStatus = CustomerStatus;
  loadingMessage = 'Updating Communication Preferences...';
  commsInitiallyEnabled = false;
  refreshing = false;

  constructor(
    private profileService: ProfileService,
    private formBuilder: FormBuilder,
    private location: Location,
    private store: Store,
    private evaluationHelperService: EvaluationHelperService
  ) { }

  ngOnInit() {
    this.hasProfile = this.customer.customerProfile.type != null;
    this.hasDOB = this.customer.patient.birthDate !== null;
    this.initCustomerModelObject();
    this.initCustomerModelForm(this.customerModalForm);
    this.initCustomerPersonalInfo();
    this.initRepeatTypes();
    this.getCommsSettings();
    this.updateTypeValidator();
    this.commsInitiallyEnabled = this.customer.customerProfile && this.customer.customerProfile.isAutomation;

    if (this.customer.customerProfile.mobileStatus == MobileState.Inactive )
    {
      this.customer.contactMethods = this.customer.contactMethods.filter((x)=>{
        return x.key != ContactMethods.scryptMessenger;
      })
    }
  }

  private getCommsSettings() {
    return this.store.dispatch([
      new GetPharmacyCommsDetailsAction(),
      new GetCommTemplatesAction(),
      new GetTriggers()
    ]);
  }

  refreshPharmacyComms() {
    this.refreshing = true;
    this.store.dispatch(new GetPharmacyCommsDetailsAction()).subscribe(() => this.refreshing = false);
  }

  ngAfterViewInit() {
    this.isModalLoad = false;
  }

  ngOnChanges(changes: SimpleChanges) {
    const change = changes['customer'];
    if (change) {
      this.customer = change.currentValue;
    }
  }

  updateOrderCycleDate() {
    if (this.patientForm.value.chooseOrderDateForMe) {
      const daysToAdd = this.store.selectSnapshot(CategoriesState.smallestDoh);
      const today = new Date();
      this.patientForm.patchValue({
        startDate: moment(today).add(daysToAdd, 'days').toDate()
      });
    } else {
      this.patientForm.patchValue({
        startDate: new Date()
      });
    }
  }

  public isFormValid(): boolean {
    if (!this.patientForm) { return; }
    const form = this.patientForm;

    const isScheduleValid: boolean = form.get('repeatCycle').value === true ?
      form.get('intervalValue').valid && form.get('repeatType').valid && form.get('startDate').valid : true;

    return ((isScheduleValid
      && form.get('contactMethod').valid
      && (this.customer.customerProfile.type === CustomerType.Lead || form.get('orderMethod').valid)));
  }

  closeModal() {
    this.close.emit();
  }

  initRepeatTypes() {
    const rp = RepeatType;
    this.repeatTypes = Object.keys(rp).filter(Number);
  }

  private initCustomerModelForm(customerProfile: CustomerProfileModal): void {
    this.patientForm = this.formBuilder.group({
      orderMethod: new FormControl('', Validators.compose([
        Validators.required,
        Validators.min(1)
      ])),
      contactMethod: new FormControl(
        this.customer.customerProfile.mobileStatus == MobileState.Active ? ContactMethods.scryptMessenger : '',
        Validators.compose([
        Validators.required
      ])),
      isAutomation: new FormControl({ value: '', disabled: !this.hasDOB || !this.canUseAutocomms() }),
      repeatCycle: new FormControl(),
      intervalValue: new FormControl('', this.intervalValidator),
      repeatType: new FormControl(),
      startDate: new FormControl(),
      chooseOrderDateForMe: new FormControl(false),
      lastRepeatDue: new FormControl(false),
      beforeNextRepeatDue: new FormControl(false),
      medicationOverdue: new FormControl(false),
      medicationOverdueOnLastRepeat: new FormControl(false),
      scriptExpiring: new FormControl(false),
      scriptOwing: new FormControl(false),
    });
    this.patientForm.patchValue(customerProfile);
    this.contactMethodChange();
  }

  contactMethodChange() {
    if (!this.isAutomaticContactMethod(this.patientForm.value.contactMethod)) {
      this.patientForm.controls.isAutomation.setValue(false)
      this.autoComms.disable();
    } else {
      this.autoComms.enable();
    }
  }

  changeContactMethod(event) {
    this.contactMethodChange();
  }

  isAutomaticContactMethod(method) {
    //SMS/email/messenger
    method = method + '';
    if (method === '1' || method === '2' || method === '7') {
      return true;
    }
    return false;
  }

  get orderMethod() {
    return this.patientForm.get('orderMethod');
  }

  get contactMethod() {
    return this.patientForm.get('contactMethod');
  }

  get orderCycle() {
    return this.patientForm.get('repeatCycle');
  }

  get autoComms() {
    return this.patientForm.get('isAutomation');
  }

  updateTypeValidator() {
    const value = this.customer.customerProfile.type;

    if (value === CustomerType.Lead) {

      this.orderMethod.setValidators(null);
    }
    else {

      this.orderMethod.setValidators([
        Validators.required,
        Validators.min(1)
      ]);
    }

    this.orderMethod.updateValueAndValidity();
    this.onAutocommsChange();
  }

  onAutocommsChange() {
    const isAutoComms = this.patientForm.value.isAutomation;
    if (isAutoComms) {
      this.contactMethod.setValidators([
        Validators.required,
        Validators.min(1)
      ]);
    }
    else {
      this.contactMethod.clearValidators();
    }

    if (isAutoComms) {
      this.orderCycle.setValue(false);
      this.orderCycle.updateValueAndValidity();
    }

    this.contactMethod.updateValueAndValidity();
  }

  updateAutoComms() {
    if (!!this.orderCycle) {
      this.autoComms.setValue(false);
      this.autoComms.updateValueAndValidity();
    }
  }

  canUseAutocomms(): boolean {
    return !!(this.customer.patient.mobileNumber || this.customer.patient.emailAddress);
  }

  onSubmit(item: any) {
    this.setFormValueToModel(item);
    this.isModalLoad = true;
    this.profileService.quickUpdateCustomerProfileComms(this.customerModalForm)
      .subscribe((profileResponse: any) => {
        this.evaluateUpdateEvent(profileResponse);
      });

  }

  private reFetchProfile() {
    this.isModalLoad = true;
    this.loadingMessage = 'Refreshing customer profile...'
    this.store.dispatch([new GetProfileAction(this.customer.clientId)]).subscribe(() => { this.isModalLoad = false })
  }

  evaluateUpdateEvent(customerProfile: any) {
    const shouldEvaluate = this.evaluationHelperService.triggerExistsAndEnabled(TriggerType.onboardedAsSOF);
    const pharmacyAndPatientCommsEnabled = this.evaluationHelperService.pharmacyAndPatientCommsEnabled(this.customerModalForm.isAutomation);
    if (shouldEvaluate && pharmacyAndPatientCommsEnabled && this.customer.customerProfile.type && !this.commsInitiallyEnabled) {
      this.store.dispatch([
        new GetEventNotificationOverload(customerProfile.tenantCustomerId, this.customerModalForm.orderMethod, this.customer.customerProfile.type, TriggerType.onboardedAsSOF),
        new ProfilePopupVisibleAction(false),
        new ToggleEventConfirmationModal(true)
      ]);
    }
    else {
      this.reFetchProfile();
    }
  }

  updateRepeats() {
    this.patientForm.value.lastRepeatDue = !this.patientForm.value.lastRepeatDue;
    this.patientForm.value.beforeNextRepeatDue = this.patientForm.value.lastRepeatDue;
  }

  updateMedicationOverdue() {
    this.patientForm.value.medicationOverdue = !this.patientForm.value.medicationOverdue;
    this.patientForm.value.medicationOverdueOnLastRepeat = this.patientForm.value.medicationOverdue;
  }

  updateScriptReminders() {
    this.patientForm.value.scriptOwing = !this.patientForm.value.scriptOwing;
    this.patientForm.value.scriptExpiring = this.patientForm.value.scriptOwing;
  }

  intervalValidator(control: FormControl) {
    const interval = control.value;
    if (!(+interval > 0 && +interval < 365)) {
      return {
        interval: {
          parsedIterval: interval
        }
      };
    }
    return null;
  }

  initCustomerPersonalInfo() {
    this.customerPersonalInfo = this.customer.patient;
  }

  private setFormValueToModel(formObject: any): void {
    this.customerModalForm.orderMethod = +formObject.orderMethod;
    this.customerModalForm.contactMethod = +formObject.contactMethod;
    this.customerModalForm.isAutomation = formObject.isAutomation;
    this.customerModalForm.repeatCycle = formObject.repeatCycle;
    this.customerModalForm.intervalValue = +formObject.intervalValue;
    this.customerModalForm.repeatType = +formObject.repeatType;
    this.customerModalForm.startDate = formObject.startDate;
    this.customerModalForm.dAACommencementDate = formObject.daaCommencementDate;
    this.customerModalForm.lastRepeatDue = formObject.lastRepeatDue;
    this.customerModalForm.beforeNextRepeatDue = formObject.lastRepeatDue;
    this.customerModalForm.scriptExpiring = formObject.scriptOwing;
    this.customerModalForm.scriptOwing = formObject.scriptOwing;
    this.customerModalForm.medicationOverdue = formObject.medicationOverdue;
    this.customerModalForm.medicationOverdueOnLastRepeat = formObject.medicationOverdue;
  }

  initCustomerModelObject() {
    const customerProfile: any = this.customer.customerProfile;
    if (customerProfile.type != null) {
      this.customerModalForm.type = customerProfile.type;
      this.customerModalForm.customerId = this.customer.clientId;
      this.customerModalForm.firstname = this.customer.patient.firstname;
      this.customerModalForm.lastname = this.customer.patient.surname;
      this.customerModalForm.tenantCustomerId = customerProfile.tenantCustomerId;
      this.customerModalForm.isAutomation = customerProfile.isAutomation;
      this.customerModalForm.lastRepeatDue = customerProfile.lastRepeatDue;
      this.customerModalForm.beforeNextRepeatDue = customerProfile.lastRepeatDue;
      this.customerModalForm.scriptExpiring = customerProfile.lastRepeatDue;
      this.customerModalForm.scriptOwing = customerProfile.lastRepeatDue;
      this.customerModalForm.medicationOverdue = customerProfile.lastRepeatDue;
      this.customerModalForm.medicationOverdueOnLastRepeat = customerProfile.lastRepeatDue;
      this.customerModalForm.dAACommencementDate = customerProfile.dAACommencementDate;
      const startDate = customerProfile.startDate ? customerProfile.startDate : new Date();
      this.customerModalForm.intervalValue = customerProfile.intervalValue === null ? 28 : customerProfile.intervalValue;
      this.customerModalForm.startDate = new Date(startDate);
      this.customerModalForm.repeatCycle = customerProfile.repeatType === null ? false : true;
      this.customerModalForm.repeatType = customerProfile.repeatType === null ? 1 : customerProfile.repeatType;
      this.customerModalForm.orderMethod = customerProfile.orderMethod.toString();
      this.customerModalForm.contactMethod = customerProfile.contactMethod;
    }
  }
}
