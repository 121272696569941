import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AbstractBaseApiModule } from './abstract-base-api.module';

@NgModule({
  imports: [AbstractBaseApiModule, RouterModule],
})
export class BaseApiModule {
  static interceptor(
    interceptor: any
  ): ModuleWithProviders<AbstractBaseApiModule> {
    return {
      ngModule: AbstractBaseApiModule,
      providers: [
        {
          provide: HTTP_INTERCEPTORS,
          useClass: interceptor,
          multi: true,
        },
      ],
    };
  }
}
