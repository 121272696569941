import { Injectable } from "@angular/core";
import { Store } from "@ngxs/store";
import { FeaturesState } from "../../core/features/features.state";

@Injectable({providedIn: 'root'})
export class FeatureDecisions { 
    constructor(private store: Store) {  }

    isInviteToMobileAllowed() {
        return this.store.select(FeaturesState.isEnabled('allowedToInviteToMobile'));
    }

    isAllowedToEditServiceForm() {
        return this.store.select(FeaturesState.isEnabled('allowedToSubmitServiceForms'));
    }
}