import { isNullOrWhiteSpace } from "./string.helpers";

export function getDoctorName(doctor: any) {
    return doctor ?  makeFullName(doctor.title, doctor.firstName, doctor.surname): ''
}

export function makeFullName(...args: string[]): string {
    return args.reduce((name, next) => `${name}${!isNullOrWhiteSpace(next) ? next.trim() + ' ' : ''}`, '').trim();
}

export function makeFullAddress(...args: string[]): string {
    return args.filter(x => !isNullOrWhiteSpace(x)).map(x => x.trim()).join(', ')
}
