import { Component, OnInit } from '@angular/core';
import { HMRLocationState } from '../hmr-location/state/hmr-location.state';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-hmr-location-list-item',
  templateUrl: './hmr-location-list-item.component.html'
})
export class HMRLocationListItemComponent implements OnInit {
  @Select(HMRLocationState) hmrLocationForm$: Observable<any>;

  constructor() { }

  ngOnInit() {
  }

}
