import { Validator, NG_VALIDATORS, FormControl } from '@angular/forms'
import { Directive, OnInit } from '@angular/core';
 
 
@Directive({
  selector: '[methodValidator]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: methodValidator, multi: true }
  ]
})
export class methodValidator implements Validator, OnInit {
 
  ngOnInit() {
  }
 
  validate(c: FormControl) {
 
    let v = c.value;
 
    if (v === 0) {
      return { 'required': true}
    }
 
    return null;
  }
}
 