<div class="channel-select-modal">
  <div class="modal-body border__grey">
    <h3 class="p-2">Send document by</h3>
    <form [formGroup]="sendDocForm" ngxsForm="confirmMedsCheck.medsListEmailForm">
        <select class="form-control mt-1" formControlName="commsType" id="commsType">
          <option [ngValue]="commsType.Email">Email</option>
          <option [ngValue]="commsType.Sms">Sms</option>
          <option *ngIf="profile.customerProfile.mobileStatus === mobileStatus.Active" [ngValue]="commsType.ScryptMessenger">Scrypt Mobile</option>
        </select>
        <input *ngIf="sendDocForm.controls.commsType.value === commsType.Email" class="form-control mt-1" placeholder="Email Address" type="text" formControlName="email"/>
        <input *ngIf="sendDocForm.controls.commsType.value === commsType.Sms" class="form-control mt-1" type="text" placeholder="Mobile number" formControlName="mobile"/>
    </form>
    <div g-xs="mt2">
      <button class="button btn__yellow" g-xs="mr1" (click)="closeClicked()">Cancel</button>
      <button class="button btn__green" (click)="submitClicked()">Send</button>
    </div>
  </div>
</div>
<div class="modal-bg"></div>
