import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxsModule } from '@ngxs/store';
import { InviteToSignUpState } from './state/invite-to-sign-up.state';
import { InviteToSignUpComponent } from './components/invite-to-sign-up.component';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import { InviteService } from '../../../shared/services/invite.service';
import { LoaderModule } from '../../core/loader/loader.module';

@NgModule({
  declarations: [InviteToSignUpComponent],
  imports: [
    CommonModule,
    NgxsModule.forFeature([InviteToSignUpState]),
    ReactiveFormsModule,
    NgxsFormPluginModule,
    LoaderModule
  ],
  providers: [InviteService],
  exports: [InviteToSignUpComponent]
})
export class InviteToSignUpModule { }
