import { ServicesFilter, SixCpaResponse } from './profile-services.state';

export enum ProfileServicesActionTypes {
  UPDATE_SIXCPA_FORMS_FILTER = '[ProfileServices] Update SixCpa Forms Filter',
  GET_ALL_SIXCPA_FORMS = '[ProfileServices] Get All Forms',
  UPDATE_FORMS_LIST = '[ProfileServices] Update Forms',
  TOGGLE_FORM_LOADING = '[ProfileServices] Toggle Form Loading',
}

export class UpdateSixCpaFormFilter {
  static readonly type = ProfileServicesActionTypes.UPDATE_SIXCPA_FORMS_FILTER;
  constructor(public filter: ServicesFilter) {}
}

export class GetSixCpaFormsFromService {
  static readonly type = ProfileServicesActionTypes.GET_ALL_SIXCPA_FORMS;
  constructor(public clientId: string) {}
}

export class SetSixCpaFormsList {
  static readonly type = ProfileServicesActionTypes.UPDATE_FORMS_LIST;
  constructor(public forms: SixCpaResponse[]) {}
}

export class ToggleFormLoading {
  static readonly type = ProfileServicesActionTypes.TOGGLE_FORM_LOADING;
  constructor(public id: number, public loading: boolean) {}
}
