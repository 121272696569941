import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { TenantService } from '../../../shared/services/tenant.service';
import { Subscription } from 'rxjs/Subscription';
import { Tenant } from '../../../shared/models/tenant.model';
import { ConnectionType } from '../../../shared/models/connectionType.model';
import { TenantHealthService } from '~/shared/services/tenant-health.service';

@Component({
  selector: 'app-tenant',
  templateUrl: './tenant.component.html',
  styleUrls: ['./tenant.component.scss']
})
export class TenantComponent implements OnInit, OnDestroy {
  constructor(private tenantService: TenantService, private healthService: TenantHealthService) { }

  isLoaded = false;
  isUpdateFormVisible = false;

  sub1: Subscription;
  tenantSubscription: Subscription;

  @Input() tenantList: Tenant[] = [];
  @Input() connectionTypeList: ConnectionType[] = [];

  @Output() tenantListChangedEvent = new EventEmitter<any>();

  currentItem: Tenant;


  ngOnInit() {
    this.resetCurrentItem();
    this.isLoaded = true;
  }

  openUpdateForm(data?: Tenant) {
    if (data) {
      this.currentItem = data;
    }
    this.isUpdateFormVisible = true;
  }

  updateList(data: any) {
    this.resetCurrentItem();
    this.tenantListChangedEvent.emit(data);
  }
  resetCurrentItem() {
    this.currentItem = new Tenant(null, null, null);
  }
  onUpdateFormCancel() {
    this.isUpdateFormVisible = false;
    this.resetCurrentItem();
  }

  toggleActiveStatus(item: Tenant) {
    this.tenantService.updateTenant(item).subscribe(updatedTenant => {
      const index = this.tenantList.findIndex(t => t.id === updatedTenant.id);
      if (index !== -1) {
        this.tenantList[index] = updatedTenant;
      }
      this.tenantListChangedEvent.emit({ item: updatedTenant, mode: 'update' });
    });
  }

  remove(id: number) {
    this.sub1 = this.tenantService.removeTenant(id).subscribe(
      data => {
        const index = this.tenantList.findIndex(c => c.id === id);
        if (index !== -1) {
          const emitData = {
            'mode': 'delete',
            'item': this.tenantList[index]
          };
          this.tenantListChangedEvent.emit(emitData);
        }
      }
    );
  }

  ngOnDestroy() {
    if (this.sub1) { this.sub1.unsubscribe(); }
  }
}

