import { Component, OnInit, OnDestroy } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable, combineLatest, Subscription } from 'rxjs';
import { SickLeaveConfirmState } from './state/sick-leave-confirm.state';
import { slideRight } from '../../../../../shared/core/animations';
import { SickLeaveState } from '../sick-leave-form-list/state/sick-leave.state';
import { withLatestFrom, map, take, tap } from 'rxjs/operators';
import { ProfileState } from '../../../../core/profile/state/profile.state';
import {
  GetCertificateSickLeaveConfirmAction,
  GetDoctorReportSickLeaveConfirm,
  SendToDoctorSickLeaveConfirm,
  SetDoctorListSickLeaveConfirm,
  SendToPatientSickLeaveConfirm,
  ToggleResponseSickLeaveConfirm
} from './state/sick-leave-confirm.actions';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { IReportModel } from '../../../../../shared/models/six-cpa/doctor-report.model';
import { CustomisedDoctorReportState } from '../../../../../shared/components/customise-doctor-report/state/customised-doctor-report.state';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { TypeaheadMatch } from 'ngx-bootstrap';
import { AttendingDoctorState } from '../../../../../modules/profile/attending-doctor/state/attending-doctor.state';
import { ChannelSelectModalSubmitEvent } from './../../../../../modules/profile/six-cpa/channel-select-modal/channel-select-modal.component';
import { CloseSickLeaveSendDocumentFormAction, SendSickLeaveReport } from './../../../../../modules/profile/six-cpa/sick-leave/sick-leave-confirm/state/sick-leave-confirm.actions';

@Component({
  selector: 'app-sick-leave-confirm',
  templateUrl: './sick-leave-confirm.component.html',
  styleUrls: ['./sick-leave-confirm.component.scss', '../../six-cpa-form-list-item.component.scss'],
  animations: [
    slideRight
  ]
})
export class SickLeaveConfirmComponent implements OnInit, OnDestroy {
  @Select(SickLeaveConfirmState) sickLeaveConfirm$: Observable<any>;
  @Select(SickLeaveConfirmState.certificate) certificate$: Observable<IReportModel>;
  @Select(SickLeaveConfirmState.doctorReport) doctorReport$: Observable<IReportModel>;
  @Select(SickLeaveConfirmState.showSendDocumentForm) showSendDocumentForm$: Observable<boolean>;

  doctorDetailsForm: FormGroup;
  patientDetaisForm: FormGroup;
  attendingDoctorsSubscription$: Subscription;

  constructor(private formBuilder: FormBuilder, private store: Store) {
    this.generateFormControls();
  }
  @Dispatch()
  getCertificate = () =>
    this.store.selectOnce(SickLeaveState.sixCpaFormId).pipe(
      withLatestFrom(this.store.selectOnce(ProfileState.clientId)),
      map(([sixCpaFormId, clientId]) =>
        new GetCertificateSickLeaveConfirmAction(clientId, sixCpaFormId)
      ))
  @Dispatch()
  getDoctorReport = () =>
    combineLatest([
      this.store.selectOnce(ProfileState.clientId),
      this.store.selectOnce(SickLeaveState.sixCpaFormId),
      this.store.selectOnce(CustomisedDoctorReportState.customisedDoctorReport)])
      .pipe(take(1),
        map(([clientId, sixCpaFormId, customisedDoctorReport]) =>
          new GetDoctorReportSickLeaveConfirm(clientId, sixCpaFormId, customisedDoctorReport)
        ))

  @Dispatch()
  sendDoctorReport = () =>
    combineLatest([
      this.store.selectOnce(ProfileState.clientId),
      this.store.selectOnce(SickLeaveState.sixCpaFormId),
      this.store.selectOnce(CustomisedDoctorReportState.customisedDoctorReport)])
      .pipe(take(1),
        map(([clientId, sixCpaFormId, customisedDoctorReport]) =>
          new SendToDoctorSickLeaveConfirm(clientId, sixCpaFormId, customisedDoctorReport)
        ))

  onChannelSelected($event: ChannelSelectModalSubmitEvent)
  {
    var clientId = this.store.selectSnapshot(ProfileState.clientId);
    var sixCpaFormId = this.store.selectSnapshot(SickLeaveState.sixCpaFormId);
    var customisedDoctorReport = this.store.selectSnapshot(CustomisedDoctorReportState.customisedDoctorReport);
    this.store.dispatch([new SendSickLeaveReport(clientId, sixCpaFormId, $event.commsType, $event.contact, customisedDoctorReport),
      new CloseSickLeaveSendDocumentFormAction()]);
  }

  @Dispatch()
  onChannelSelectCancelled = () => new CloseSickLeaveSendDocumentFormAction();

  ngOnInit() {
    this.attendingDoctorsSubscription$ = this.store.selectOnce(AttendingDoctorState.patientDoctors).pipe(
      tap((data: any[]) => this.store.dispatch(new SetDoctorListSickLeaveConfirm(data)))
    ).subscribe();
  }

  ngOnDestroy() {
    if (this.attendingDoctorsSubscription$) {this.attendingDoctorsSubscription$.unsubscribe()}
  }

  onSelect(event: TypeaheadMatch): void {
    const { email } = event.item;
    this.doctorDetailsForm.patchValue({ email });
  }

  hideResponse() {
    this.store.dispatch(new ToggleResponseSickLeaveConfirm('out'));
  }

  private generateFormControls() {
    this.patientDetaisForm =  this.formBuilder.group({
      email: new FormControl(null, [Validators.required, Validators.email])
    });

    this.doctorDetailsForm = this.formBuilder.group({
      fullName: new FormControl(null),
      email: new FormControl(null, [Validators.required, Validators.email]),
      doctorAddress: new FormControl(null)
    });
  }
}
