import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PharmacyCommsDetailsComponent } from './pharmacy-comms-details.component';
import { NgxsModule } from '@ngxs/store';
import { PharmacyCommsDetailsState } from './state/pharmacy-comms-details.state';
import { PharmacyCommsDetailsService } from './services/pharmacy-comms-details.service';
import { LoaderModule } from '../../../modules/core/loader/loader.module';
import { TimepickerModule } from 'ngx-bootstrap';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import { RadioHighlightModule } from '../../../shared/components/radio-highlight/radio-highlight.module';
import { UiSwitchModule } from 'ngx-ui-switch';
import { InfoBoxModule } from '../../../shared/components/info-box/info-box.module';
import { PharmacyService } from '../../../shared/services/pharmacy.service';

@NgModule({
  declarations: [PharmacyCommsDetailsComponent],
  imports: [
    CommonModule,
    NgxsModule.forFeature([PharmacyCommsDetailsState]),
    LoaderModule,
    TimepickerModule.forRoot(),
    ReactiveFormsModule,
    NgxsFormPluginModule,
    RadioHighlightModule,
    UiSwitchModule,
    InfoBoxModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [PharmacyCommsDetailsComponent],
  providers: [PharmacyCommsDetailsService, PharmacyService],
})
export class PharmacyCommsDetailsModule {}
