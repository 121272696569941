import { ConsentType } from "../../../modules/profile/six-cpa/consent-eligibility-form/state/consent-eligibility-form-basic.state";


export interface ConsentAndEligibilityFormModel {
  id: number | null;
  patientsConsent: boolean | null;
  hasPreviousMedsCheck: boolean | null;
  currentLivingStatus: CurrentLivingStatus | null;
  currentLivingOtherDetails: string | null;
  gpOrHospitalLastSixMonthsForMedicationProblems: boolean | null;
  medicineManagementSupport: MedicineManagementSupport | null;
  followUpService: Date | string | null;
  consentType: ConsentType;
}

export enum CurrentLivingStatus {
  Private,
  IndependenLivingRetirementVillage,
  Supported,
  Institutional,
  Other
}

export enum MedicineManagementSupport {
  Minimal,
  Occasional,
  Routine,
  Complete
}
