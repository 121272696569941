import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { LoadingState, LoadingStateModel } from '../state/loading.state';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoaderComponent {
  @Input() loadingMessage: string;
  @Input() size = '50px'
  @Select(LoadingState.loadingState) loadingState$: Observable<LoadingStateModel>;
 }
