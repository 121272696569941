import { Component, Input } from '@angular/core';
import { PhoneNote } from '../../../../../shared/models/phoneNote.model';

@Component({
  selector: 'app-note-history',
  templateUrl: './note-history.component.html',
  styleUrls: ['./note-history.component.scss']
})
export class NoteHistoryComponent {

  constructor() { }

  @Input() phoneNoteList: PhoneNote[] = [];

  highlightText = 'Unable to connect';


}
