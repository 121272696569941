import { Pipe, PipeTransform, NgModule } from '@angular/core';
import { NoteType } from '../models/crm/noteType.model';
import { CommonModule } from '@angular/common';
@Pipe({
    name: 'appNoteTypeIcon'
})
export class NoteTypePipe implements PipeTransform {
    transform(name: string, NoteTypeList: NoteType[]): string {
        if (!NoteTypeList) {
            return null;
        }
        const item = NoteTypeList.find(x => x.name === name);
        if (item) {
            if (item.img) {
                return `<i class="${item.img}"></i>`;
            }
        }
        return null;
    }
}
@NgModule({
  declarations: [
    NoteTypePipe
  ],
  imports: [
    CommonModule
  ],
  exports: [
    NoteTypePipe
  ]
})
export class NoteTypePipeModule { }
