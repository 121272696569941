import { Component, OnInit, Input, EventEmitter, Output, OnDestroy } from '@angular/core';
import { TenantUser } from '../../../shared/models/tenantUser.model';
import { TenantUserService } from '../../../shared/services/tenantUser.service';
import { User } from '../../../shared/models/user.model';
import { Tenant } from '../../../shared/models/tenant.model';
import { NgForm } from '@angular/forms';
import { Subscription } from 'rxjs/Subscription';

@Component({
  selector: 'app-tenant-user-item',
  templateUrl: './tenant-user-item.component.html',
  styleUrls: ['./tenant-user-item.component.scss']
})
export class TenantUserItemComponent implements OnInit, OnDestroy {
  @Input() currentItem: TenantUser;
  @Input() userList: User[] = [];
  @Input() tenantList: Tenant[] = [];

  @Output() saveEvent = new EventEmitter<any>();
  @Output() cancelEvent = new EventEmitter<any>();

  addTenantUserSubscription : Subscription;
  updateTenantUserSubscription: Subscription;

  notification: Notification;
  private mode = 'create';

  public errorMessages = [];

  constructor(private tenantUserService: TenantUserService) {}

  ngOnInit() {
    if (this.currentItem.id) { this.mode = 'update'; }
  }

  onSubmit(form: NgForm) {
    const { tenantId, userId } = form.value;
    const email = this.userList.find(x => x.id === userId).email;
    const tenantName = this.tenantList.find(x => x.id === +tenantId).name;
    const tenantUser = new TenantUser(tenantId, userId, email, tenantName);
    if (this.mode === 'update') {
      tenantUser.id = this.currentItem.id;
      this.updateItem(tenantUser);
    } else {
      this.addItem(tenantUser);
    }
    form.reset();
  }

  private addItem(data: TenantUser) {
    this.addTenantUserSubscription = this.tenantUserService.addTenantUser(data).subscribe(
      (item: TenantUser) => {
        this.saveEvent.emit(
          {
            'mode': this.mode,
            'item': item
          }
        );
        this.cancelEvent.emit();
      }
    );
  }

  private updateItem(data: TenantUser) {
    this.updateTenantUserSubscription = this.tenantUserService.updateTenantUser(data).subscribe(
      (item: TenantUser) => {
        this.saveEvent.emit(
          {
            'mode': this.mode,
            'item': item
          }
        );
        this.cancelEvent.emit();
      }
    );
  }

  cancel() {
    this.cancelEvent.emit();
  }

  ngOnDestroy() {
    if (this.addTenantUserSubscription) { this.addTenantUserSubscription.unsubscribe(); }
    if (this.updateTenantUserSubscription) { this.updateTenantUserSubscription.unsubscribe(); }
  }
}
