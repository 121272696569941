import { MessageDetail, MessageChannel } from '../../../../../shared/models/message.model';
import { Channels } from '../../../../../shared/types/communications';
import { SendMessageModel } from '../../../models/send-message.model';

export enum CommunicationsActions {
  REFRESH_ALL_MAILBOXES = '[Chat] Refresh All Mailboxes',
  TOGGLE_REPLY_MODAL = '[Chat] Toggle Reply Modal',
  OPEN_MESSAGE_CONTENTS = '[Chat] Open Message Contents',
  GET_MESSAGE_HISTORY = '[Chat] Get Message History',
  MAILBOX_DETAIL_ERROR = '[Chat] Mailbox Detail Error',
  SEND_MESSAGE = '[Chat] send mailbox message',
  GET_MESSAGE_TEMPLATES = '[Chat] get message templates',
  SET_MESSAGE_DETAIL_FLAGGED = '[Chat] set message detail flagged',
  SET_MESSAGE_DETAIL_READ = '[Chat] set message detail read'
}

export class SetMessageDetailFlagged {
  static readonly type = CommunicationsActions.SET_MESSAGE_DETAIL_FLAGGED;
  constructor(
    public id: number,
    public isFlagged: boolean
  ) { }
}

export class SetMessageDetailRead {
  static readonly type = CommunicationsActions.SET_MESSAGE_DETAIL_READ;
  constructor(
    public id: number,
    public isRead: boolean
  ) { }
}

export class GetMessageTemplates {
  static readonly type = CommunicationsActions.GET_MESSAGE_TEMPLATES;
  constructor(
    public channel: MessageChannel,
    public clientId: string
  ) { }
}

export class SendMessage {
  static readonly type = CommunicationsActions.SEND_MESSAGE;
  constructor(
    public message: SendMessageModel
  ) { }
}

export class GetMessageHistory {
  static readonly type = CommunicationsActions.GET_MESSAGE_HISTORY;
  constructor(
    public customerId: string,
    public channel: Channels,
    public pageSize: number,
    public directionIsRecent: boolean
  ) { }
}

export class ToggleReplyModal {
  static readonly type = CommunicationsActions.TOGGLE_REPLY_MODAL;
  constructor(public open: boolean, public message: MessageDetail = null) { }
}

export class OpenMessageContent {
  static readonly type = CommunicationsActions.OPEN_MESSAGE_CONTENTS;
  constructor(
    public channel: Channels,
    public messageId: string,
    public customerId: string
  ) { }
}

export class MailboxDetailError {
  static readonly type = CommunicationsActions.MAILBOX_DETAIL_ERROR;
  constructor(
    public error: string,
    public messageId: string = null,
    public customerId: string = null,
    public channel: Channels =  null
  ){}
}
