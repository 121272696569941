import { State, Selector, StateContext, Action } from '@ngxs/store';
import { ArchiveSelected, FlagSelected, GetMailboxAll, GetMailboxPage, MailboxError, GetMonthlySent, ReadSelected,
  RepliedSelected, SearchMailByKeywords, SetMessageArchive, SetMessageFlagged, SetMessageRead, GetUnread, SetMessageReplied, ToggleSelectedMessage, BulkToggleSelectedMessages, CloseBulkCommRecipientsModal, OpenBulkCommRecipientsModal } from './communications.actions';
import { CommunicationsService } from '../services/communications.service';
import { tap, catchError, finalize } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { FilteredSearch, MailboxMessage, MailboxResult } from '../../../shared/models/message.model';
import { MailTabs } from '../../../shared/types/communications';
import { AlertService } from '../../../modules/core/alert/alert.service';
import { BulkMessage, Recipient } from '../../../shared/models/communication/bulk-message.model';
import { SetMessageDetailFlagged, SetMessageDetailRead } from '../Components/reply-modal/state/chat.actions';
export interface CommunicationsStateModel {
  inboxState: MailboxResult;
  scheduledState: MailboxResult;
  filteredScheduledMessages: MailboxMessage[];
  sentState: MailboxResult;
  monthlySent: number,
  isLoading: boolean;
  unreadTotal: number,
  lastFilter: FilteredSearch,
  showBulkCommRecipientsModal: boolean,
  bulkMessage: BulkMessage,
  bulkCommRecipientsIsLoading: boolean
}

@State<CommunicationsStateModel>({
  name: 'communications',
  defaults: {
    inboxState: null,
    scheduledState: null,
    sentState: null,
    isLoading: false,
    filteredScheduledMessages: new Array<MailboxMessage>(),
    monthlySent: null,
    unreadTotal: null,
    lastFilter: null,
    showBulkCommRecipientsModal: false,
    bulkMessage: null,
    bulkCommRecipientsIsLoading: false
  }
})

@Injectable()
export class CommunicationsState {
  constructor(private communicationsService: CommunicationsService, private alertService: AlertService) { }

  @Selector()
  static getState(state: CommunicationsStateModel) {
    return state;
  }

  @Selector()
  static selectedSent(state: CommunicationsStateModel) {
    return state.sentState?.items?.filter(msg => msg.selected) ?? [];
  }

  @Selector()
  static selectedInbox(state: CommunicationsStateModel) {
    return state.inboxState?.items?.filter(msg => msg.selected) ?? [];
  }

  @Selector()
  static selectedScheduled(state: CommunicationsStateModel) {
    return state.filteredScheduledMessages?.filter(msg => msg.selected) ?? [];
  }

  @Selector()
  static monthlySent(state: CommunicationsStateModel) {
    return state.monthlySent;
  }

  @Selector()
  static unread(state: CommunicationsStateModel) {
    return state.unreadTotal;
  }

  @Selector()
  static allSelectedInboxAreRead(state: CommunicationsStateModel) {
    let result = new Array<boolean>();
    result.push(state.inboxState?.items?.filter(msg => msg.selected && !msg.isRead).length === 0);
    return result;
  }

  @Selector()
  static allSelectedInboxAreFlagged(state: CommunicationsStateModel) {
    let result = new Array<boolean>();
    result.push(state.inboxState?.items?.filter(msg => msg.selected && !msg.isFlagged).length === 0);
    return result;
  }

  @Selector()
  static getMailboxMessage(state: CommunicationsStateModel)
  {
    return (messageId: string): MailboxMessage => {
      // TODO: why are there empty try catches here, and this repetition should be refactored away
    // the reason for returning is that there can only be one tab open at a time
    if (state?.inboxState?.items?.length ?? 0 > 0)
    {
        const inboxMessage = state.inboxState.items.filter(x => x.messageId === messageId);
        if (inboxMessage !== null && inboxMessage.length > 0)
        {
          return inboxMessage[0];
        }
    }

    if (state?.sentState?.items?.length ?? 0 > 0)
    {
      try {
        const sentState = state.sentState.items.filter(x => x.messageId === messageId);
        if (sentState !== null && sentState.length > 0)
        {
          return sentState[0];
        }
      }
      catch{}
    }

    if (state?.scheduledState?.items?.length ?? 0 > 0)
    {
      try {
        const scheduledState = state.scheduledState.items.filter(x => x.messageId === messageId);
        if (scheduledState !== null && scheduledState.length > 0)
        {
          return scheduledState[0];
        }
      }
      catch{}
    }

    return null;
    };

  }

  // this is all wrong
  @Selector()
  static getMailboxMessageTab(state: CommunicationsStateModel)
  {
    return (messageId: string): MailTabs => {
      if (state?.inboxState?.items?.length ?? 0 > 0)
      {
        var inboxMessage = state.inboxState.items.filter(x => x.messageId === messageId);
        if (inboxMessage !== null && inboxMessage.length > 0)
        {
          return MailTabs.Inbox;
        }
      }

      if (state?.sentState?.items?.length ?? 0 > 0)
      {
        var sentState = state.sentState.items.filter(x => x.messageId === messageId);
        if (sentState !== null && sentState.length > 0)
        {
          return MailTabs.Sent;
        }
      }

      if (state?.scheduledState?.items?.length ?? 0 > 0)
      {
        var scheduledState = state.scheduledState.items.filter(x => x.messageId === messageId);
        if (scheduledState !== null && scheduledState.length > 0)
        {
          return MailTabs.Scheduled;
        }
      }
      return null;
    };
  }

  @Action(OpenBulkCommRecipientsModal)
  openBulkCommId(ctx: StateContext<CommunicationsStateModel>, { bulkCommId }: OpenBulkCommRecipientsModal) {
    ctx.patchState({
      showBulkCommRecipientsModal: true,
      bulkCommRecipientsIsLoading: true
    });
    return this.communicationsService.getBulkComms(bulkCommId).pipe(
      tap(response => {
        ctx.patchState({
          showBulkCommRecipientsModal: true,
          bulkCommRecipientsIsLoading: true,
          bulkMessage: response
        });
      }),
      catchError(x => {
        ctx.patchState({ showBulkCommRecipientsModal: false});
        return ctx.dispatch(new MailboxError("Error retrieving recipients of bulk message"));
      }),
      finalize(() => {
          ctx.patchState({ bulkCommRecipientsIsLoading: false});
        })
    );
  }

  @Action(CloseBulkCommRecipientsModal)
  closeBulkCommId(ctx: StateContext<CommunicationsStateModel>, { }: CloseBulkCommRecipientsModal) {
    ctx.patchState({
      showBulkCommRecipientsModal: false,
      bulkCommRecipientsIsLoading: false,
      bulkMessage: null
    });
  }

  @Action(SearchMailByKeywords)
  searchMailByKeywords(ctx: StateContext<CommunicationsStateModel>, { filters, mailType }: SearchMailByKeywords) {
    if (mailType !== MailTabs.Scheduled) {
      return ctx.dispatch(new GetMailboxPage(filters, mailType));
    }

    if (filters.Keyword === null || filters.Keyword === undefined) {
      filters.Keyword = "";
    }

    filters.Keyword = filters.Keyword.toLowerCase();
    const keywords = filters.Keyword.split(" ");
    let scheduled = ctx.getState().scheduledState?.items;
    if (scheduled !== null) {
      keywords.forEach(keyword => {
        scheduled = scheduled.filter(msg => msg.firstName?.toLowerCase().includes(keyword)
          || msg.lastName?.toLowerCase().includes(keyword)
          || msg.content?.toLowerCase().includes(keyword));
      });
    }
    ctx.patchState({ filteredScheduledMessages: scheduled })
  }

  @Action(SetMessageArchive)
  setMessageArchive(ctx: StateContext<CommunicationsStateModel>, { message, isArchived, mailType }: SetMessageArchive) {
    return this.communicationsService.setMessageArchived(message, isArchived, mailType).pipe(
      tap(() => {
        message.isArchived = isArchived;
        if (mailType === MailTabs.Inbox)
        {
          const localInboxState = ctx.getState().inboxState;
          localInboxState.items = localInboxState.items.filter(x => x.messageId !== message.messageId);
          ctx.patchState({ inboxState: localInboxState });
        }
      })
    );
  }

  @Action(SetMessageFlagged)
  setMessageFlagged(ctx: StateContext<CommunicationsStateModel>, { message, isFlagged, mailType }: SetMessageFlagged) {
    return this.communicationsService.setMessageFlagged(message, isFlagged, mailType).pipe(
      tap(() => {
        if (mailType === MailTabs.Inbox)
        {
          const localInboxState = ctx.getState().inboxState;
          localInboxState.items = localInboxState.items.map(x => {
            if (x.id === message.id) {
              return ({...x, isFlagged: isFlagged })
            } else {
              return x;
            }
          });
          ctx.patchState({ inboxState: localInboxState });
          ctx.dispatch(new SetMessageDetailFlagged(message.id, isFlagged));
        }
      })
    );
  }

  @Action(SetMessageRead)
  setMessageRead(ctx: StateContext<CommunicationsStateModel>, { message, isRead, mailType }: SetMessageRead) {
    return this.communicationsService.setMessageRead(message, isRead, mailType).pipe(
      tap(() => {
        if (mailType === MailTabs.Inbox)
        {
          const localInboxState = ctx.getState().inboxState;
          localInboxState.items = localInboxState.items.map(x => {
            if (x.id === message.id) {
              return ({...x, isRead: isRead })
            } else {
              return x;
            }
          });
          ctx.patchState({ inboxState: localInboxState });
          ctx.dispatch(new SetMessageDetailRead(message.id, isRead));
          ctx.dispatch(new GetUnread());
        }
      })
    );
  }

  @Action(SetMessageReplied)
  setMessageReplied(ctx: StateContext<CommunicationsStateModel>, { message, isReplied, mailType }: SetMessageReplied) {
    return this.communicationsService.setMessageReplied(message, isReplied, mailType).pipe(
      tap(() => {
        message.isReplied = isReplied;
      })
    );
  }

  @Action(FlagSelected)
  flagSelected(ctx: StateContext<CommunicationsStateModel>, { mailType, isFlagged }: FlagSelected) {
    const selected = this.getSelectedMessages(ctx, mailType);
    return this.communicationsService.setMessageFlaggedBulk(selected, isFlagged, mailType).pipe(
      tap(() => {
        if (mailType === MailTabs.Inbox)
        {
          const inboxState = ctx.getState().inboxState;
          inboxState.items.forEach(item => {
            if (selected.includes(item)) { item.isFlagged = isFlagged; }
          });
          ctx.patchState({ inboxState: inboxState });
        }
        ctx.dispatch(GetUnread);
      })
    );
  }

  @Action(ArchiveSelected)
  archiveSelected(ctx: StateContext<CommunicationsStateModel>, { mailType, isArchived }: ArchiveSelected) {
    const selected = this.getSelectedMessages(ctx, mailType);
    return this.communicationsService.setMessageArchivedBulk(selected, isArchived, mailType).pipe(
      tap(() => {
        if (mailType === MailTabs.Inbox)
        {
          const inboxState = ctx.getState().inboxState;
          inboxState.items.forEach(item => {
            if (selected.includes(item)) { item.isArchived = isArchived; }
          });
          inboxState.items = inboxState.items.filter(x => !x.isArchived);
          ctx.patchState({ inboxState: inboxState });
        }
        ctx.dispatch(GetUnread);
      })
    );
  }

  @Action(ReadSelected)
  readSelected(ctx: StateContext<CommunicationsStateModel>, { mailType, isRead }: ReadSelected) {
    const selected = this.getSelectedMessages(ctx, mailType);
    return this.communicationsService.setMessageReadBulk(selected, isRead, mailType).pipe(
      tap(() => {
        if (mailType === MailTabs.Inbox)
        {
          const inboxState = ctx.getState().inboxState;
          inboxState.items.forEach(item => {
            if (selected.includes(item)) { item.isRead = isRead; }
          });
          ctx.patchState({ inboxState: inboxState });
        }
        ctx.dispatch(GetUnread);
      })
    );
  }

  @Action(RepliedSelected)
  repliedSelected(ctx: StateContext<CommunicationsStateModel>, { mailType, isReplied }: RepliedSelected) {
    const selected = this.getSelectedMessages(ctx, mailType);
    return this.communicationsService.setMessageRepliedBulk(selected, isReplied, mailType).pipe(
      tap(() => {
        if (mailType === MailTabs.Inbox)
        {
          const inboxState = ctx.getState().inboxState;
          inboxState.items.forEach(item => {
            if (selected.includes(item)) { item.isReplied = isReplied; }
          });
          ctx.patchState({ inboxState: inboxState });
        }
        ctx.dispatch(GetUnread);
      })
    );
  }

  private getSelectedMessages(ctx: StateContext<CommunicationsStateModel>, mailType: MailTabs): Array<MailboxMessage> {
    switch (mailType) {
      case MailTabs.Inbox:
        return ctx.getState().inboxState?.items?.filter(msg => msg.selected);
      case MailTabs.Scheduled:
        return ctx.getState().filteredScheduledMessages?.filter(msg => msg.selected);
      case MailTabs.Sent:
        return ctx.getState().sentState?.items?.filter(msg => msg.selected);
    }
  }

  @Action(GetMonthlySent)
  getMonthlySent({ patchState, dispatch }: StateContext<CommunicationsStateModel>) {
    return this.communicationsService.getMonthlySent().pipe(
      tap(result => {
        patchState({ monthlySent: result });
      }),
      catchError(x => dispatch(new MailboxError(x)))
    );
  }

  @Action(GetUnread)
  getUnreadMessages({ patchState, dispatch }: StateContext<CommunicationsStateModel>) {
    return this.communicationsService.getUnread().pipe(
      tap(result => {
        patchState({ unreadTotal: result });
      }),
      catchError(x => dispatch(new MailboxError(x)))
    );
  }

  @Action(BulkToggleSelectedMessages)
  bulkToggleSelectedMessages(ctx: StateContext<CommunicationsStateModel>, { startIndex, endIndex, selected, mailType }: BulkToggleSelectedMessages)
  {
    try {
      switch(mailType)
      {
        case MailTabs.Inbox:
          var messages = ctx.getState().inboxState.items;
          messages.forEach(message => {
            if (messages.indexOf(message) >= startIndex && messages.indexOf(message) <= endIndex)
            {
              message.selected = selected;
            }
          });
          const inboxState = ctx.getState();
          inboxState.inboxState.items = messages;
          ctx.patchState({ inboxState: inboxState.inboxState });
          break;
        case MailTabs.Sent:
          var messages = ctx.getState().sentState.items;
          messages.forEach(message => {
            if (messages.indexOf(message) >= startIndex && messages.indexOf(message) <= endIndex)
            {
              message.selected = selected;
            }
          });
          const sentState = ctx.getState();
          sentState.sentState.items = messages;
          ctx.patchState({ sentState: sentState.sentState });
          break;
        case MailTabs.Scheduled:
          var messages = ctx.getState().filteredScheduledMessages;
          messages.forEach(message => {
            if (messages.indexOf(message) >= startIndex && messages.indexOf(message) <= endIndex)
            {
              message.selected = selected;
            }
          });
          const scheduledState = ctx.getState();
          scheduledState.filteredScheduledMessages = messages;
          ctx.patchState({ filteredScheduledMessages: scheduledState.filteredScheduledMessages });
          break;
      }
    }
    catch(ex)
    {
      ctx.dispatch(new MailboxError("Error selecting messages"));
    }
  }

  @Action(GetMailboxPage)
  getMailboxPage({ patchState, dispatch }: StateContext<CommunicationsStateModel>, { filters, mailType }: GetMailboxPage) {
    if (filters === null) {
      return;
    }

    switch (mailType) {
      case MailTabs.Inbox:
        patchState({ isLoading: true, inboxState: null });
        break;
      case MailTabs.Sent:
        patchState({ isLoading: true, sentState: null });
        break;
      case MailTabs.Scheduled:
        patchState({ isLoading: true, scheduledState: null, filteredScheduledMessages: [] });
        break;
    }

    return this.communicationsService.getMailboxPage(filters, mailType).pipe(
      tap(result => {
        switch (mailType) {
          case MailTabs.Inbox:
            patchState({ inboxState: result });
            break;
          case MailTabs.Sent:
            patchState({ sentState: result });
            break;
          case MailTabs.Scheduled:
            patchState({ scheduledState: result });
            dispatch(new SearchMailByKeywords(filters, mailType));
            break;
        }
      }),
      catchError(x => dispatch(new MailboxError("Error retrieving mail"))),
      finalize(() => { patchState({ isLoading: false }); })
    );
  }

  @Action(GetMailboxAll)
  getMailboxAll({ patchState, dispatch }: StateContext<CommunicationsStateModel>, { filters, mailType }: GetMailboxAll) {
    if (filters === null) {
      return;
    }

    patchState({ isLoading: true, lastFilter: filters });
    return this.communicationsService.getMailboxPage(filters, mailType).pipe(
      tap(result => {
        filters.PageSize = result.total ?? 0;
        if (filters.PageSize === 0)
        {
          patchState({ isLoading: false, inboxState: null, scheduledState: null, sentState: null });
          return;
        }
        dispatch(new GetMailboxPage(filters, mailType));
      }),
      catchError((x) => dispatch(new MailboxError("Error retrieving all mail")).pipe(
        finalize(() => {
          patchState({ isLoading: false });
        })
      )),
    );
  }

  @Action(ToggleSelectedMessage)
  toggleSelectedMessage(ctx: StateContext<CommunicationsStateModel>, { message, selected, mailType }: ToggleSelectedMessage) {
    switch (mailType) {
      case MailTabs.Inbox:
        var messages = ctx.getState().inboxState;
        messages.items.forEach(msg => {
          if (msg.messageId === message.messageId) {
            msg.selected = selected;
          }
        });
        ctx.patchState({ inboxState: messages });
        break;
      case MailTabs.Sent:
        var messages = ctx.getState().sentState;
        messages.items.forEach(msg => {
          if (msg.messageId === message.messageId) {
            msg.selected = selected;
          }
        });
        ctx.patchState({ sentState: messages });
        break;
      case MailTabs.Scheduled:
        var messages = ctx.getState().scheduledState;
        messages.items.forEach(msg => {
          if (msg.messageId === message.messageId) {
            msg.selected = selected;
          }
        });
        ctx.patchState({ scheduledState: messages });
        break;
    }
  }

  @Action(MailboxError)
  mailboxError({ patchState }: StateContext<CommunicationsStateModel>, { error }: MailboxError) {
    console.log(error);
    this.alertService.error(error);
    patchState({ isLoading: false });
  }
}
