import { CommTemplate } from '../../../../shared/models/pharmacy/comm-template.model';
import { CommsQueueItem } from '../models/comms-queue-item.model';

export enum CommsQueueActions {
  GET = '[CommsQueue] get',
  GET_SUCCESS = '[CommsQueue] get success',
  FAILED = '[CommsQueue] failed',
  SORT = '[CommsQueue] sort',
  TOGGLE_MESSAGE = '[CommsQueue] Toggle Message Modal',
  TOGGLE_TEMPLATE = '[CommsQueue] Toggle Template Modal'
}


export class GetCommsQueueAction {
  static readonly type = CommsQueueActions.GET;
  constructor(public startDate: Date) { }
}

export class GetCommsQueueSuccessAction {
  static readonly type = CommsQueueActions.GET_SUCCESS;
  constructor(public commTemplates: CommsQueueItem[]) { }
}

export class CommsQueueFailedAction {
  static readonly type = CommsQueueActions.FAILED;
  constructor(public error: any) { }
}

export class SortCommsQueueAction {
  static readonly type = CommsQueueActions.SORT;
  constructor(public sortKey: string, public sortDirection: string) { }
}

export class ToggleShowMessageModalAction {
  static readonly type = CommsQueueActions.TOGGLE_MESSAGE;
  constructor(public queueItem: CommsQueueItem) { }
}

export class ToggleShowTemplateModalAction {
  static readonly type = CommsQueueActions.TOGGLE_TEMPLATE;
  constructor(public queueItem: CommTemplate) { }
}
