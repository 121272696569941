import { Component, OnInit } from '@angular/core';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-sign-up-success',
  templateUrl: './sign-up-success.component.html',
  styleUrls: ['./sign-up-success.component.scss']
})
export class SignUpSuccessComponent implements OnInit {
  playStoreLink = environment.playStore;
  appStoreLink = environment.appleAppStore;

  constructor() { }

  ngOnInit() {
  }

}
