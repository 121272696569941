import { State, Action, StateContext, Selector, Store } from '@ngxs/store';
import { catchError, mergeMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { AslMedicationsService } from '../services/asl-medications.service';
import { AslMedication } from '../models/asl-medication.model';
import { ClearASLMedicationState, GetAslMedicationsErrorAction, RefreshAslMedicationsAction, SetAslMedicationsAction, ToggleAslMedicationAction } from './asl-medication.actions';

export class AslMedicationsStateModel {
  scripts: AslMedication[];
  loading: boolean;
  refreshLoading: boolean;
  showMedications: boolean;
  loadingNotifications: boolean;
}

class MedicationsStateHelper {
  static sortDrugsByName(a: AslMedication, b: AslMedication) {
    if (!a.medicationGenericName) { return 1; }
    if (!b.medicationGenericName) { return -1; }
    return (a.medicationGenericName < b.medicationGenericName) ? -1 : (a.medicationGenericName > b.medicationGenericName) ? 1 : 0;
  }
}
@Injectable()
@State<AslMedicationsStateModel>({
  name: 'aslmedications',
  defaults: {
    scripts: [],
    loading: true,
    refreshLoading: true,
    showMedications: true,
    loadingNotifications: true
  }
})
export class AslMedicationsState {

  constructor(
    private aslMedicationsService: AslMedicationsService,
    private store: Store
  ) { }

  @Selector()
  static showAslMedications(state: AslMedicationsStateModel) {
    return state.showMedications;
  }

  @Selector()
  static loading(state: AslMedicationsStateModel) {
    return state.loading;
  }

  @Selector()
  static all(state: AslMedicationsStateModel): AslMedication[] {
    return state.scripts.sort((a, b) => MedicationsStateHelper.sortDrugsByName(a, b));
  }

  @Action(ClearASLMedicationState)
  clearASLMedicationState(ctx: StateContext<AslMedicationsStateModel>) {
    ctx.patchState({
      scripts: [],
      loading: false
    });
  }

  @Action(SetAslMedicationsAction)
  set(ctx: StateContext<AslMedicationsStateModel>, { medications }: SetAslMedicationsAction) {
    ctx.patchState({
      scripts: medications,
      loading: false
    });
  }

  @Action(RefreshAslMedicationsAction)
  refresh(ctx: StateContext<AslMedicationsStateModel>, action: RefreshAslMedicationsAction) {
    ctx.patchState({ loading: true });
    return this.aslMedicationsService.getMedications(action.ihiNumber, action.firstName, action.lastName).pipe(
      mergeMap((scripts) => ctx.dispatch(new SetAslMedicationsAction(scripts))),
      catchError((error) => ctx.dispatch(new GetAslMedicationsErrorAction(error)))
    );
  }

  @Action(ToggleAslMedicationAction)
  toggleUncategorised(ctx: StateContext<AslMedicationsStateModel>) {
    const state = ctx.getState();
    ctx.patchState({ showMedications: !state.showMedications });
  }
}
