import { IStock } from '.';

export class StockActions {
    static readonly SearchStock = '[StockActions] search';
    static readonly AddStock = '[StockActions] add';
}


export class SearchStockAction {
    static readonly type = StockActions.SearchStock;
    constructor(public readonly query: string) { }
}

export class AddStockAction {
    static readonly type = StockActions.AddStock;
    constructor(public readonly stocks: IStock[]) { }
}
