<div class="status-filter">
    <nz-select
        ngDefaultControl
        [(ngModel)]="selectedStatus"
        [disabled]="isLoading"
        [nzDropdownMatchSelectWidth]="false"
        (ngModelChange)="changeStatusFilter($event)"
        >
        <nz-option [nzValue]="'Default'" [nzLabel]="'Default'"></nz-option>
        <nz-option 
            *ngFor="let filter of statusFilters"
            [nzValue]="filter.value" 
            [nzLabel]="filter.label"
        ></nz-option>
    </nz-select>
</div>