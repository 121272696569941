import { OrderMethod, OrderRequestStatus } from "./order.enums";

export const DefaultTags = {
    firstName: '<<FirstName>>',
    orderNumber: '<<OrderNumber>>',
    orderDate: '<<OrderDate>>',
    unableToFill: '<<unableToFill>>',
    paymentUrl: '<<paymentUrl>>',
    nextValidDispenseDate: '<<nextValidDispenseDate>>',
    onOrder: '<<onOrder>>'
}

export const MessageTemplates = {
  readyToPickup: `Hi ${DefaultTags.firstName},
Your order #${DefaultTags.orderNumber} is now ready to be collected in store. ${DefaultTags.unableToFill}
`,

  scheduledForDelivery: `Hi ${DefaultTags.firstName},
Your order #${DefaultTags.orderNumber} is scheduled for delivery. ${DefaultTags.unableToFill}
`,

  partiallyCompleted: `Hi ${DefaultTags.firstName},
Your order #${DefaultTags.orderNumber} is partially completed and is ready to be collected in store. ${DefaultTags.unableToFill} ${DefaultTags.onOrder}
${DefaultTags.nextValidDispenseDate}
`,

  unableToFill: `Hi ${DefaultTags.firstName},
Your order #${DefaultTags.orderNumber} was unable to be completed, please review the order details for more information.
`,

  cancelled:
  `Hi ${DefaultTags.firstName},
Your order #${DefaultTags.orderNumber} has been cancelled as you no longer need this medication.
`
}

export const MessageTemplateAddons = {
  onOrderPickUp: `
Some items were not in stock and have been ordered, expected arrival is`,

  onOrderDelivery: `
Some items were not in stock and have been ordered, we'll let you know when they're on their way, expected arrival is`,

  unableToFill: `Some items were unable to be filled.`
}

export const PaymentURL = (orderLink: string) => `You can pay for your order here: ${orderLink}`;
export const NextValidDispenseDate = (dispenseDate: string) => `It is too early to dispense some items, so we will prepare these for you on ${dispenseDate}. If you require this medication more urgently please contact the pharmacy.
`;

export const TemplateLookUpTable = {
    [OrderRequestStatus.Ready]: {
      [OrderMethod.Pickup]: MessageTemplates.readyToPickup,
      [OrderMethod.Delivery]: MessageTemplates.scheduledForDelivery
    },
    [OrderRequestStatus.PartiallyReady]: {
      [OrderMethod.Pickup]: MessageTemplates.partiallyCompleted,
      [OrderMethod.Delivery]: MessageTemplates.partiallyCompleted
    },
    [OrderRequestStatus.UnableToFill]: {
      [OrderMethod.Pickup]: MessageTemplates.unableToFill,
      [OrderMethod.Delivery]: MessageTemplates.unableToFill
    },
    [OrderRequestStatus.Cancelled]: {
      [OrderMethod.Pickup]: MessageTemplates.cancelled,
      [OrderMethod.Delivery]: MessageTemplates.cancelled
    }
};
