import { State, Action, StateContext, Selector } from '@ngxs/store';
import { InitPushNotificationsAction, ReceivePushNotificationAction } from './push-notifications.actions';
import { catchError, tap, switchMap } from 'rxjs/operators';
import { AlertService } from '../alert/alert.service';
import { of, Observable } from 'rxjs';
import { AlertType } from '../alert/alert.model';
import { TenantDeviceService } from './tenant-device.service';
import { PushNotificationsService } from './push-notifications.service';
import { AuthService } from '~/shared/services/auth.service';
import { Injectable } from '@angular/core';

export class PushNotificationsStateModel {
  public token: string;
}

const pn_token = 'pn_token';
@Injectable()
@State<PushNotificationsStateModel>({
  name: 'pushNotifications',
  defaults: {
    token: null
  }
})
export class PushNotificationsState {

  @Selector([PushNotificationsState])
  static hasToken({ token }: PushNotificationsStateModel): boolean {
    return !!token;
  }

  constructor(
    private authService: AuthService,
    private pushNotificationService: PushNotificationsService,
    private alertService: AlertService,
    private notificationService: TenantDeviceService
  ) {
  }

  @Action(InitPushNotificationsAction)
  init(ctx: StateContext<PushNotificationsStateModel>) {
    const existingToken = localStorage.getItem(pn_token);
    return !!existingToken
      ? ctx.setState({ token: existingToken })
      : this.pushNotificationService.requestPermission().pipe(
        switchMap(token => this.onGetToken(token, ctx)),
        catchError(err => of(console.log(AlertType.Error, err))));
  }

  @Action(ReceivePushNotificationAction)
  receive(_, { message }: ReceivePushNotificationAction) {
    if (this.authService.isLoggedIn()) {
      // tslint:disable-next-line: no-unused-expression
      new Notification(message.notification.title, {
        body: message.notification.body,
        icon: 'https://scrypt.blob.core.windows.net/icons/logo-s.png',
        requireInteraction: true,
      });
    }
  }

  private onGetToken(token: string, { setState }: StateContext<PushNotificationsStateModel>): Observable<void> {
    return this.notificationService.registerForPush(token).pipe(
      tap(() => { localStorage.setItem(pn_token, token); setState({ token }); }),
      catchError(err => of(this.alertService.alert(AlertType.Error, err))));
  }
}
