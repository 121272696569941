import { MessageSource } from "../messageHistory.model";

export interface BulkMessage {
  id?: number;
  tenantId?: number;
  scheduledFrom: Date | string | null;
  status?: BulkScheduledMessageStatus;
  createdDate: Date | string | null;
  recipients: Recipient[];
  message: string;
  creationSource: MessageSource;
  recipientCount?: number;
}

export interface Recipient {
  bulkScheduledMessageId?: number;
  destination: string;
  status: BulkScheduledMessageRecipientStatus;
  clientId: string;
  customerName: string;
}

export enum BulkScheduledMessageRecipientStatus {
  Pending,
  Sent,
  Failed,
  Cancelled,
  OptOut,
  PartialSuccess
}

export enum BulkScheduledMessageType {
  SMS,
  Email,
  MMS
}

export enum BulkScheduledMessageStatus {
  Pending,
  Sent,
  Cancelled,
  OptOut
}
