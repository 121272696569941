import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BaseApi } from '../core/base-api/base-api';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import { StorageService } from '../core/storage/storage.service';
import { AlertService } from '~/modules/core/alert/alert.service';
import { UpdateScriptNotificationsRequest } from '../models/script/update-script-notifications-request.model';
import { AdjustmentRequestModel } from '../models/script/adjustment-request.model';
import { StockStartingPointModel } from '../models/script/meta-view-model';
import { Observable } from 'rxjs';
import { CustomerAuth } from '~/modules/order/services/customer-auth';
import { QueueDispenseRequestModel } from '@base/shared/models/dispense/queueDispenseRequest.model';
import { BatchDispenseRequestModel } from '@base/shared/models/dispense/batchDispenseRequest.model';

const API = environment.baseApiUrl;

@Injectable()
export class ScriptService extends BaseApi {
  constructor(public http: HttpClient,
    public router: Router,
    public storageService: StorageService,
    public alertService: AlertService,
    private customerAuth: CustomerAuth) {
    super(http, router, storageService, alertService);
  }

  toggleFlag(meta: any) {
    return this.post(`${API}/api/client/flag`, meta);
  }

  toggleRequestable(meta: any) {
    return this.post(`${API}/api/client/requestable`, meta);
  }

  createMeta(scriptMeta: any) {
    return this.post(`${API}/api/scripts/meta`, scriptMeta);
  }

  createMetaOrders(scriptMeta: any) {
    return this.post(
      this.customerAuth.urlForEncryptedTenantCustomer(
        `${API}/api/customer/orderrequest/meta`
      ), scriptMeta);
  }

  updateMeta(scriptMeta: any) {
    return this.put(`${API}/api/scripts/${scriptMeta.metaId}/meta`, scriptMeta);
  }

  resetMetas(scriptMetaIds: number[]) {
    return this.post(`${API}/api/scripts/meta/delete`, scriptMetaIds);
  }

  addDosage(newDosage: any) {
    return this.post(`${API}/api/scripts/${newDosage.metaId}/dosage`, newDosage);
  }

  deleteDosage(dosageId: any) {
    return this.delete(`${API}/api/dosage/${dosageId}`);
  }

  updateStartDate(startDate: any) {
    return this.put(`${API}/api/scripts/${startDate.metaId}/meta/startdate`, startDate);
  }

  addAdjustment(adjustment: AdjustmentRequestModel) {
    return this.put(`/api/scripts/${adjustment.metaId}/stock`, adjustment);
  }

  addBulkAdjustment(adjustments: AdjustmentRequestModel[]) {
    return this.put(`/api/stock/bulk`, adjustments);
  }

  createInstoreOrder(data) {
    return this.post('/api/order/createInstoreOrder', data);
  }

  bulkDispenseDrugs(data: BatchDispenseRequestModel) {
    return this.post('/api/script/bulkDispense', data);
  }

  dispenseEScript(request: QueueDispenseRequestModel) {
    return this.post(`${API}/api/script/escript`, request);
  }

  updateScriptNotifications(request: UpdateScriptNotificationsRequest) {
    return this.put(`${API}/api/scripts/meta/notifications`, request);
  }

  addStockStartingPoint(stockStartingPoint: StockStartingPointModel): Observable<any> {
    return this.post(`/api/stock/ssp`, stockStartingPoint);
  }
}
