import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { TenantService } from '../../../../shared/services/tenant.service';
import { NgForm } from '@angular/forms';
import { Tenant, TenantPermission } from '../../../../shared/models/tenant.model';
import { ConnectionType } from '../../../../shared/models/connectionType.model';
import { Subscription } from 'rxjs/Subscription';
import { Observable, forkJoin } from 'rxjs';

@Component({
  selector: 'app-tenant-item',
  templateUrl: './tenant-item.component.html',
  styleUrls: ['./tenant-item.component.scss']
})
export class TenantItemComponent implements OnInit, OnDestroy {


  @Input() currentItem: Tenant;
  @Input() connectionTypeList: ConnectionType[] = [];


  @Output() saveEvent = new EventEmitter<any>();
  @Output() cancelEvent = new EventEmitter<any>();

  notification: Notification;
  private mode = 'create';

  sub1: Subscription;
  sub2: Subscription;
  updatePermissions:Subscription;

  public errorMessages = [];

  constructor(
    private tenantService: TenantService
  ) {
  }

  ngOnInit() {
    if (this.currentItem.id) { this.mode = 'update'; }
  }
  onSubmit(form: NgForm) {
    var permissions: TenantPermission[] = [];
    const { name, connection, connectionType } = form.value;
    for(const key of Object.keys(form.value)){
      var permission = this.currentItem.permissions.find(x => x.name.toLowerCase() === key.toLowerCase())
      if(permission){
        permission.isAllowed = form.value[key];
        permissions.push(permission);
      }
    }

    const type = this.connectionTypeList.find(x => x.name === connectionType);
    const tenant = new Tenant(name, connection, type.value, permissions, null);
    if (this.mode === 'update') {
      tenant.id = this.currentItem.id;
      this.updateItem(tenant);
    } else {
      this.addItem(tenant);
    }
    form.reset();
  }


  private addItem(data: Tenant) {
   this.sub1 = this.tenantService.addTenant(data).subscribe(
      (item: Tenant) => {
        this.saveEvent.emit(
          {
            'mode': this.mode,
            'item': item
          }
        );
        this.cancelEvent.emit();
      }
    );
  }

  private updateItem(data: Tenant) {
    let tenantAction = this.tenantService.updateTenant(data);
    let permsAction = this.tenantService.updatePermissions(data.id, data.permissions);
    this.sub2 = forkJoin([tenantAction, permsAction]).subscribe(results=>{
      const item:Tenant = results[0];
      this.saveEvent.emit(
        {
          'mode': this.mode,
          'item': item
        }
      );
      this.cancelEvent.emit();
    })
  }

  cancel() {
    this.cancelEvent.emit();
  }

  ngOnDestroy() {
    if (this.sub1) {this.sub1.unsubscribe(); }
    if (this.sub2) {this.sub2.unsubscribe(); }
    if(this.updatePermissions) {this.updatePermissions.unsubscribe();}
  }

}

