export enum ReportingActionTypes {
    GET_REPORTS = '[Reporting] Get Reports',
    GET_EMBEDDED_REPORT = '[Reporting] Get Embedded Report'
  }
  
  export class GetReports {
    static readonly type = ReportingActionTypes.GET_REPORTS;
    constructor() { }
  }
  
  export class GetEmbeddedReport {
    static readonly type = ReportingActionTypes.GET_EMBEDDED_REPORT;
    constructor(public reportId?: string) { }
  }
  