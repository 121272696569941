import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { OrderMethod, OrderRequestItemStatus, OrderRequestStatus } from '../../models/order.enums';
import { OrderRequest } from '../../models/order-request.model';
import { ContactMethods } from '../../../../../shared/models/communication/contact-methods.enum';
import { ClientViewModel } from '../../../../core/profile/client.model';
import { Store } from '@ngxs/store';

export interface OrderUpdate {
  order: OrderRequest;
  message: string;
  contactMethod: ContactMethods;
}

@Component({
  selector: 'app-order-update-status-card',
  templateUrl: './order-update-status-card.component.html',
  styleUrls: ['./order-update-status-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class OrderUpdateStatusCardComponent implements OnInit, OnChanges {
  @Input() order: OrderRequest;
  @Input() profile: ClientViewModel;
  @Input() disabled = false;
  @Input() isCreate = false;
  orderStatusEnum = OrderRequestStatus;
  orderStatus: OrderRequestStatus;
  @Output() updateStatus: EventEmitter<OrderRequest> = new EventEmitter<OrderRequest>();
  orderMethods = OrderMethod;
  @Input() submitted = false;
  @Output() submittedChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() isValid = true;
  initialStatus: any;

  constructor(private store: Store) { }

  ngOnInit() {
    this.submittedChange.emit(true);
    if (this.order) {
      this.orderStatus = this.order.status;
      this.initialStatus = this.order.status;
    }
    if (!this.profile) {
      throw new Error('Profile input missing');
    }
    if (this.order?.customerProfileId !== this.profile?.customerProfile?.customerProfileId) {
      throw new Error('Profile and Order customerProfileId do not match ' + this.order.customerProfileId + ':' +this.profile.customerProfile.customerProfileId);
    }
  }

  ngOnChanges() {
    if (this.order && !this.isCreate) {
      this.orderStatus = this.order.status;
    }
    if (!this.profile) {
      throw new Error('Profile input missing');
    }
    if (this.order?.customerProfileId !== this.profile?.customerProfile?.customerProfileId) {
      throw new Error('Profile and Order customerProfileId do not match ' + this.order.customerProfileId + ':' +this.profile.customerProfile.customerProfileId);
    }

    //Set parent status based on set item statuses
    if (this.hasOnOrderItemsOrHasTooEarly())
      {
        this.orderStatus = OrderRequestStatus.PartiallyReady;
      }
      else if (this.allItemsAreUnableToFill()) {
        this.orderStatus = OrderRequestStatus.UnableToFill;
      }
      else if (this.allItemsAreReady())
      {
        this.orderStatus = OrderRequestStatus.Ready;
      }
  }

  handleUpdate() {
    this.submittedChange.emit(true);
    if (this.isValid) {
      this.updateStatus.emit({...this.order, status: this.orderStatus, oldStatus: this.initialStatus } as OrderRequest);
    }
  }

  hasPendingItems() {
    return this.order.orderRequestedItems
    .filter(i => i.orderRequestItemStatus === OrderRequestItemStatus.Pending).length > 0;
  }

  hasOnOrderItemsOrHasTooEarly() {
    return this.order.orderRequestedItems
    .filter(i => i.orderRequestItemStatus === OrderRequestItemStatus.OnOrder || i.orderRequestItemStatus === OrderRequestItemStatus.TooEarly ).length > 0;
  }

  allItemsAreReady() {
    return this.order.orderRequestedItems.every(i => i.orderRequestItemStatus === OrderRequestItemStatus.Ready || i.orderRequestItemStatus === OrderRequestItemStatus.UnableToFill)
  }

  allItemsAreUnableToFill() {
    return this.order.orderRequestedItems.every(i => i.orderRequestItemStatus === OrderRequestItemStatus.UnableToFill)
  }
}
