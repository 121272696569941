import { Component, Input, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import * as moment_ from 'moment';
const moment = moment_;
import { Observable } from 'rxjs';
import { groupBy } from '../../../../shared/helpers/groupBy';
import { getFullScriptType, getScriptType } from '../../../../shared/helpers/script-type.helper';
import { ScriptHistory } from '../../../../shared/models/script/script-history.model';
import { RemovePassiveScript, UpdatePassiveScript } from '../../out-of-store/state/out-of-store.actions';
import { OutOfStoreState } from '../../out-of-store/state/out-of-store.state';
import { SelectManyDrugsAction, UnselectManyDrugsAction } from '../multiple-select/state/multiple-drugs.actions';
import { ToggleScriptAvailability, ToggleScriptHistoryModal } from './state/script-history.actions';
import { ScriptHistoryState, ScriptHistoryStateModel } from './state/script-history.state';

@Component({
  selector: 'app-script-history',
  templateUrl: './script-history.component.html',
  styleUrls: ['./script-history.component.scss']
})
export class ScriptHistoryComponent implements OnInit {
  @Select(ScriptHistoryState) scriptHistoryState$: Observable<ScriptHistoryStateModel>;
  @Select(OutOfStoreState.loading) loading$: Observable<boolean>;
  @Input() scriptHistory: ScriptHistory[] = [];
  scriptGroups;
  groupKeys: string[] = [];
  drugsForDispensing: ScriptHistory[] = [];
  groupsToShow: string[] = [];
  showType = false;

  constructor(private store: Store) { }

  ngOnInit(): void {
    this.setSelectedScripts();
  }

  private setSelectedScripts() {
    this.scriptHistory.forEach(script => {
      if (script.isSelected) {
        this.drugsForDispensing.push(script);
      }
    });
  }

  showScriptType(show: boolean) {
    this.showType = show;
  }

  getScriptType(script: ScriptHistory) {
    return getScriptType(script);
  }

  getFullScriptType(script: ScriptHistory) {
    return getFullScriptType(script);
  }

  close() {
    this.store.dispatch(new ToggleScriptHistoryModal(false));
    this.groupKeys = [];
  }

  toggleScriptAvailability(script: ScriptHistory) {
    script.enabled = !script.enabled;
    this.store.dispatch(new ToggleScriptAvailability(script));
  }

  getRecords(scripts: ScriptHistory[]) {
    this.scriptGroups = groupBy(scripts, s => `${moment(s.prescriptionDate).format('YYYY-MM-DD')}-${s.doctor}`) as Record<string, ScriptHistory[]>;
    return this.getGroupKeys();
  }

  private getGroupKeys(): string[] {
    for (let groupKey in this.scriptGroups) {
      if (!this.groupKeys.find(key => key == groupKey)) {
        this.groupKeys.push(groupKey);
      }
    }

    return this.groupKeys;
  }

  toggleDispensingList(script: ScriptHistory) {
    if (script.isPassiveScript) {
      const original = this.scriptHistory.find(sh => sh.scriptNo == script.originalScriptNo || sh.scriptNo == script.originalScriptNo);
      script.scriptId = original.scriptId;
    }

    this.drugsForDispensing.push(script);
    this.store.dispatch(new SelectManyDrugsAction(this.drugsForDispensing))
  }

  addSelectedDrugsToMultiSelect() {
    this.store.dispatch(new SelectManyDrugsAction(this.drugsForDispensing))
  }

  removeScriptFromList(script: ScriptHistory) {
    const index = this.drugsForDispensing.findIndex(drug => drug.scriptNo == script.scriptNo);
    this.drugsForDispensing.splice(index, 1);
  }

  removePassiveScript(passiveScriptId: string) {
    this.store.dispatch(new RemovePassiveScript(passiveScriptId));
  }

  unselectAllScripts() {
    this.store.dispatch(new UnselectManyDrugsAction(this.drugsForDispensing))
    this.drugsForDispensing = [];
  }

  showGroup(group: string): boolean {
    return !!this.groupsToShow.find(groupName => groupName == group);
  }

  toggleGroup(group: string) {
    if (this.showGroup(group)) {
      this.removeGroup(group);
    } else {
      this.groupsToShow.push(group);
    }
  }

  editRepeats(script: ScriptHistory) {
    script.editMode = true;
  }

  save(script: ScriptHistory) {
    script.editMode = false;
    this.store.dispatch(new UpdatePassiveScript(script.passiveScriptId, script.repeats));
  }

  private removeGroup(group: string) {
    const index = this.groupsToShow.findIndex(groupName => groupName == group);
    this.groupsToShow.splice(index, 1);
  }

  private isScriptExpired(script: ScriptHistory) {
    const date = new Date();

    if (script.expiry) {
      return new Date(script.expiry) < date;
    }
    else {
      date.setMonth(date.getDate() - 12);

      if (!script.prescriptionDate) {
        return false; //OTC never expires
      }

      if (date <= new Date(script.prescriptionDate)) {
        return true;
      }
    }

    return false;
  }

  getScriptValidity(group: string) {
    const scripts = this.scriptGroups[group];
    const repeatsLeft = this.getRepeatsRemaining(group);

    return (repeatsLeft > 0) && !this.isScriptExpired(scripts[0]);
  }

  private getScriptRepeats({repeats, timesDispensed, isPassiveScript}: ScriptHistory): number {
    return isPassiveScript ? repeats : (repeats - timesDispensed || 0)
  }

  getRepeatsRemaining(group: string) {
    const scripts = this.scriptGroups[group];

    const latestScript = scripts.reduce((a: ScriptHistory, b: ScriptHistory) => {
      return new Date(a.dispensedDate) > new Date(b.dispensedDate) ? a : b;
    });

    return this.getScriptRepeats(latestScript);
  }

  // This logic belongs on the backend - TODO move - 22/12/2020
  getOriginalScript(group: string) {
    let originalScript = this.findOriginalDispensedScript(group);

    if (originalScript == null) {
      return this.takeFirstAndFudgeData(group);
    }

    return originalScript;
  }

  private findOriginalDispensedScript(group: string): ScriptHistory {
    return this.scriptGroups[group].find(script => (script.scriptNo == script.originalScriptNo) || script.originalScriptNo == null);
  }

  // Look how far my data integrity has stooped
  private takeFirstAndFudgeData(group: string): ScriptHistory {
    let originalScript = this.takeFirstOfGroup(group);
    return originalScript;
  }

  private takeFirstOfGroup(group: string): ScriptHistory {
    return this.scriptGroups[group][0];
  }
}
