import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CrmPageComponent } from './crm-page/crm-page.component';
import { BiMetricComponent } from './bi-metric/bi-metric.component';
import { MetricItemComponent } from './bi-metric/metric-item/metric-item.component';
import { TenantUserPageComponent } from './tenant-user-page/tenant-user-page.component';
import { UserPageComponent } from './user-page/user-page.component';
import { GroupPageComponent } from './group-page/group-page.component';
import { ClientListComponent } from './client-list/client-list.component';
import { VerifyPageComponent } from './verify-page/verify-page.component';
import { ReportingPageComponent } from './reporting-page/reporting-page.component';
import { CategoryItemComponent } from './category-page/category-item/category-item.component';
import { CategoryPageComponent } from './category-page/category-page.component';
import { SelectedTimezoneResolver } from '~/modules/core/timezone/resolvers/selected-timezone.resolver';
import { SystemComponent } from './system.component';
import { AuthGuard } from '~/shared/services/auth-guard';
import { DashboardPageComponent } from '~/system/dashboard-page/dashboard-page.component';
import { CsvExportPageComponent } from './csv-export-page/csv-export-page.component';
import { PushNotificationsResolver } from '~/modules/core/push-notifications/push-notifications.resolver';
import { BulkMessagingPageComponent } from './bulk-messaging-page/bulk-messaging-page.component';
import { BulkMessagingResolver } from './bulk-messaging-page/resolvers/bulk-messaging.resolver';
import { AdvancedSearchComponent } from './advanced-search/advanced-search.component';
import { AdvancedSearchResolver } from './advanced-search/advanced-search.resolver';
import { GroupsResolver } from './group-page/groups.resolver'
import { RecentCustomersPageComponent } from './recent-customers-page/recent-customers-page.component';
import { AdminDashboardComponent } from './admin-dashboard/dashboard/admin-dashboard.component';
import { DispenseRunPageComponent } from './dispense-run/dispense-run-page/dispense-run-page.component';
import { DispenseRunResolver } from './dispense-run/resolvers/dispense-run.resolver';
import { CommunicationsPageComponent } from './communications-page/communications-page.component';
import { CommunicationsResolver } from './communications-page/resolvers/communications.resolver';
import { InvoicesPageComponent } from '@base/modules/invoices-page/invoices-page.component';
import { MobileAnalyticsPageComponent } from './analytics-mobile-page/analytics-mobile-page.component';
import { OrderManagementComponent } from './order-management/order-mgmt.component';

const routes: Routes = [
  {
    path: '',
    component: SystemComponent,
    canActivate: [AuthGuard],
    resolve: {
      selectedTimezone: SelectedTimezoneResolver,
      pushNotifications: PushNotificationsResolver
    },
    children: [
      { path: 'dashboard', component: DashboardPageComponent, resolve: { AdvancedSearchResolver } },
      { path: 'client', component: ClientListComponent },
      { path: 'crm', component: CrmPageComponent },
      { path: 'recent-customers', component: RecentCustomersPageComponent },
      { path: 'advanced-search', component: AdvancedSearchComponent, resolve: { GroupsResolver } },
      { path: 'biMetric', component: BiMetricComponent },
      { path: 'biMetric/add', component: MetricItemComponent },
      { path: 'biMetric/edit/:id', component: MetricItemComponent },
      { path: 'config/pharmacy', loadChildren: () => import('./pharmacy/pharmacy.module').then(m => m.PharmacyModule) },
      { path: 'tenantUser', component: TenantUserPageComponent },
      { path: 'admin', component: AdminDashboardComponent },
      { path: 'config/users', component: UserPageComponent },
      { path: 'config/groups', component: GroupPageComponent },
      { path: 'config/communication', loadChildren: () => import('../modules/pharmacy-comms/parent-component/pharmacy-comms.module').then(m => m.PharmacyCommsModule), resolve: { GroupsResolver } },
      { path: 'verify-mobile', component: VerifyPageComponent },
      { path: 'report', component: ReportingPageComponent },
      { path: 'analytics', component: ReportingPageComponent },
      { path: 'config/category', component: CategoryPageComponent },
      { path: 'config/category/add', component: CategoryItemComponent },
      { path: 'config/category/edit/:id', component: CategoryItemComponent },
      { path: 'config/export', component: CsvExportPageComponent },
      { path: 'profile/:clientId', loadChildren: () => import('../modules/profile/profile.module').then(m => m.ProfileModule), data: { preload: true } },
      { path: 'bulk-messaging', component: BulkMessagingPageComponent, resolve: { BulkMessagingResolver } },
      { path: 'communications', component: CommunicationsPageComponent, resolve: { CommunicationsResolver } },
      { path: 'dispense-run', component: DispenseRunPageComponent, resolve: { DispenseRunResolver } },
      { path: 'invoices', component: InvoicesPageComponent },
      { path: 'analytics-mobile', component: MobileAnalyticsPageComponent },
      { path: 'manage/orders', component: OrderManagementComponent }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class SystemRoutingModule {
}
