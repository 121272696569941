import { ComponentFactoryResolver, Injectable } from '@angular/core';
import { HasViewContainerRef } from '../has-view-container-ref.interface';
import { HMRFormType } from './hmr-form-list/state/hmr-form.state';

@Injectable()
export abstract class HMRResolvedForm {
  abstract formHost: HasViewContainerRef;
  constructor(
    protected componentFactoryResolver: ComponentFactoryResolver
  ) { }

  protected resolveFormComponent(formType: HMRFormType) {
    const component = this.getFormComponent(formType);
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(component);
    const viewContainerRef = this.formHost.viewContainerRef;
    viewContainerRef.clear();
    viewContainerRef.createComponent(componentFactory);
  }

  protected abstract getFormComponent(formType: HMRFormType);
}
