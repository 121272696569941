import {
  ScreeningForm,
  ScreeningFormLink,
  VaccinationError,
} from '../types/vaccination';

export enum VaccinationActions {
  VALIDATE_FORM_LINK_FOR_SCREENING = '[Vaccination] validate form link for screening',
  VALIDATE_FORM_LINK_FOR_SCREENING_SUCCESS = '[Vaccination] validate form link for screening success',
  SCREENING_ERROR = '[Vaccination] screening error',
  REDIRECT_TO_ERROR = '[Vaccination] redirect to ERROR',
  RESET_SCREENING_FORM = '[Vaccination] reset screening form',
  SUBMIT_SCREENING_FORM = '[Vaccination] submit screening form',
  SUBMIT_SCREENING_FORM_SUCCESS = '[Vaccination] submit screening form success',
  REDIRECT_TO_HOME = '[Vaccination] redirect to HOME',
}
export class ValidateFormLinkForScreening {
  static readonly type = VaccinationActions.VALIDATE_FORM_LINK_FOR_SCREENING;
  constructor(public linkId: string) {}
}

export class ValidateFormLinkForScreeningSuccess {
  static readonly type =
    VaccinationActions.VALIDATE_FORM_LINK_FOR_SCREENING_SUCCESS;
  constructor(public formLink: ScreeningFormLink) {}
}

export class ScreeningError {
  static readonly type = VaccinationActions.SCREENING_ERROR;
  constructor(public error: VaccinationError) {}
}

export class RedirectToError {
  static readonly type = VaccinationActions.REDIRECT_TO_ERROR;
  constructor(public error: VaccinationError) {}
}

export class SubmitScreeningForm {
  static readonly type = VaccinationActions.SUBMIT_SCREENING_FORM;
  constructor() {}
}

export class SubmitScreeningFormSuccess {
  static readonly type = VaccinationActions.SUBMIT_SCREENING_FORM_SUCCESS;
  constructor() {}
}

export class RedirectToHome {
  static readonly type = VaccinationActions.REDIRECT_TO_HOME;
  constructor() {}
}

export class ResetScreeningForm {
  static readonly type = VaccinationActions.RESET_SCREENING_FORM;
  constructor() {}
}
