import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AlertService } from '../modules/core/alert/alert.service';
import { BaseApi } from '../shared/core/base-api/base-api';
import { StorageService } from '../shared/core/storage/storage.service';

@Injectable()
export class LiteCustomerService extends BaseApi {
  API = '';

  constructor(
    @Inject('environment') environment: any,
    public httpClient: HttpClient,
    public router: Router,
    public storageService: StorageService,
    public alertService: AlertService
  ) {
    super(httpClient, router, storageService, alertService);
    this.API = environment.customerApiBase;
  }

  createCustomer(customer) {
    return this.post(`${this.API}/api/customer`, customer);
  }

  registerASLCustomer(customer) {
    return this.post(`${this.API}/api/customer/registerASL`, customer);
  }

  removeConsent(id: string, ihiNumber: string, firstName: string, lastName: string) {
    return this.post(`${this.API}/api/consent/remove`, {
      ihi: ihiNumber,
      firstName,
      lastName,
      id: id
    });
  }

  getASLStatus(ihiNumber: string, firstName: string, lastName: string) {
    return this.get(`${this.API}/api/customer/Status`, {
      params: {
        ihi: ihiNumber,
        firstName,
        lastName
      }
    });
  }

  getASLStatuses(customerList) {
    return this.post(`${this.API}/api/customer/statuses`,
      customerList);
  }

  consentASL(ihiNumber: string, firstName: string, lastName: string, consentNeeded: string) {
    return this.post(`${this.API}/api/customer/consent`,
      {
        ihiNumber,
        firstName,
        lastName,
        consentNeeded
      });
  }

  getASLPatient(ihiNumber: string, firstName: string, lastName: string) {
    return this.get(`${this.API}/api/customer/aslPatient`, {
      params: {
        ihiNumber,
        given: firstName,
        family: lastName
      }
    });
  }

  updateASLCustomer(customer) {
    return this.put(`${this.API}/api/customer/updateASL`, customer);
  }

  onboardCustomer(id: string, mobileNumber: string, email: string) {
    return this.post(`${this.API}/api/customer/onboard`,
      {
        id,
        mobileNumber,
        email
      });
  }
}
