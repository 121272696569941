import { ChangePrimaryContactRequest, PrimaryContactType } from "../models/change-primary-contact-request.model";
import { PrimaryContactSummary } from "../models/primary-contact-summary.model";
import { PrimaryContactStateStatus } from "./primary-contact.state";

export enum PrimaryContactActions {
  GET_PRIMARY_CONTACT_SUMMARY = '[PrimaryContact] GET_PRIMARY_CONTACT_SUMMARY',
  SET_PRIMARY_CONTACT_SUMMARY = '[PrimaryContact] SET_PRIMARY_CONTACT_SUMMARY',
  SET_PRIMARY_CONTACT_STATE_STATUS = '[PrimaryContact] SET_PRIMARY_CONTACT_STATE_STATUS',
  TOGGLE_PRIMARY_CONTACT_MODAL = '[PrimaryContact] TOGGLE_PRIMARY_CONTACT_MODAL',

  CHANGE_PRIMARY_CONTACT = '[PrimaryContact] CHANGE_PRIMARY_CONTACT',
  CHANGE_PRIMARY_CONTACT_SUCCESS = '[PrimaryContact] CHANGE_PRIMARY_CONTACT_SUCCESS',
  CHANGE_PRIMARY_CONTACT_ERROR = '[PrimaryContact] CHANGE_PRIMARY_CONTACT_ERROR',

  REMOVE_PRIMARY_CONTACT = '[PrimaryContact] REMOVE_PRIMARY_CONTACT',
  REMOVE_PRIMARY_CONTACT_SUCCESS = '[PrimaryContact] REMOVE_PRIMARY_CONTACT_SUCCESS',
  REMOVE_PRIMARY_CONTACT_ERROR = '[PrimaryContact] REMOVE_PRIMARY_CONTACT_ERROR'
}


export class GetPrimaryContactSummary {
  static readonly type = PrimaryContactActions.GET_PRIMARY_CONTACT_SUMMARY;
  constructor(public tenantCustomerId: number, public mobile: string, public email: string) { }
}

export class SetPrimaryContactSummary {
  static readonly type = PrimaryContactActions.SET_PRIMARY_CONTACT_SUMMARY;
  constructor(public summary: PrimaryContactSummary) { }
}

export class SetPrimaryContactStateStatus {
  static readonly type = PrimaryContactActions.SET_PRIMARY_CONTACT_STATE_STATUS;
  constructor(public status: PrimaryContactStateStatus) { }
}

export class TogglePrimaryContactModal {
  static readonly type = PrimaryContactActions.TOGGLE_PRIMARY_CONTACT_MODAL;
  constructor(public show: boolean) { }
}

export class ChangePrimaryContact {
  static readonly type = PrimaryContactActions.CHANGE_PRIMARY_CONTACT;
  constructor(public request: ChangePrimaryContactRequest) { }
}

export class ChangePrimaryContactSuccess {
  static readonly type = PrimaryContactActions.CHANGE_PRIMARY_CONTACT_SUCCESS;
  constructor() { }
}

export class ChangePrimaryContactError {
  static readonly type = PrimaryContactActions.CHANGE_PRIMARY_CONTACT_ERROR;
  constructor(public error: any) { }
}

export class RemovePrimaryContact {
  static readonly type = PrimaryContactActions.REMOVE_PRIMARY_CONTACT;
  constructor(public tenantCustomerId: number, public primaryContactType: PrimaryContactType) { }
}

export class RemovePrimaryContactSuccess {
  static readonly type = PrimaryContactActions.REMOVE_PRIMARY_CONTACT_SUCCESS;
  constructor() { }
}

export class RemovePrimaryContactError {
  static readonly type = PrimaryContactActions.REMOVE_PRIMARY_CONTACT_ERROR;
  constructor(public error: any) { }
}
