import { BaseApi } from '../../../../shared/core/base-api/base-api';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { StorageService } from '../../../../shared/core/storage/storage.service';
import { AlertService } from '../../../core/alert/alert.service';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class CategoriesService extends BaseApi {
  API = '';

  constructor(
    @Inject('environment') env: any,
    public http: HttpClient,
    public router: Router,
    public storageService: StorageService,
    public alertService: AlertService
  ) {
    super(http, router, storageService, alertService);
    this.API = env.baseApiUrl;
  }
  getClientScripts(clientId: string) {
    return this.get(`${this.API}/api/client/${clientId}/scripts`);
  }
}
