import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { NewPharmacy } from '~/shared/models/pharmacy/new-pharmacy.model';
import { Select, Store } from '@ngxs/store';
import { AdminDashboardState } from '../state/admin-dashboard.state';
import { Observable } from 'rxjs';
import { AddNewPharmacy, GetConnectionTypes } from '../state/admin-dashboard.actions';
import { ConnectionType } from '../../../shared/models/connectionType.model'
import { Tenant } from '~/shared/models/tenant.model';
import { Timezones } from '~/shared/constants/timezones';

interface FormInput {
  id: string, //also used on the for attribute on the label and the formControlName on the input element itself
  name: string;
  type: 'text' | 'email' | 'url' | 'tel' | 'select'
  placeholder?: string;
  options?: TemplateRef<any>; 
}

@Component({
  selector: 'app-new-pharmacy-form',
  templateUrl: './new-pharmacy-form.component.html',
  styleUrls: ['./new-pharmacy-form.component.scss']
})
export class NewPharmacyFormComponent implements OnInit {
  @Select(AdminDashboardState.loading) loading$: Observable<boolean>;
  @Select(AdminDashboardState.tenant) tenant$: Observable<Tenant>;
  @Select(AdminDashboardState.validConnectionTypes) connectionTypes$: Observable<ConnectionType[]>;
  @ViewChild('dispenseTypeFormControl', { static: true }) dispenseTypeFormControl: TemplateRef<any>;
  @ViewChild('timezoneFormControl', { static: true }) timezoneFormControl: TemplateRef<any>;

  timezones = Timezones;
  newPharmacyForm: FormGroup
  formInputs: FormInput[] = []

  constructor(private formBuilder: FormBuilder, private store: Store) { }

  ngOnInit() {
    this.generateFormControls();
    this.store.dispatch(new GetConnectionTypes());
    this.formInputs = [
      {
        id: 'connection',
        name: 'Connection',
        type: 'url',
        placeholder: 'https://servicebus/pharmacy'
      },
      {
        id: 'dispensetype',
        name: 'Dispense Type',
        type: 'select',
        options: this.dispenseTypeFormControl
      },
      {
        id: 'name',
        name: 'Name',
        type: 'text',
        placeholder: 'My Pharmacy'
      },
      {
        id: 'abn',
        name: 'ABN',
        type: 'text',
        placeholder: '012345678'
      },
      {
        id: 'email',
        name: 'Email',
        type: 'email',
        placeholder: 'pharmacy@pharmacy.com.au'
      },
      {
        id: 'pbs',
        name: 'PBS',
        type: 'text',
        placeholder: '10234A'
      }, 
      {
        id: 'phone',
        name: 'Phone',
        type: 'tel',
        placeholder: '02 1234 1234'
      },
      {
        id: 'address',
        name: 'Address',
        type: 'text',
        placeholder: '123 Fake Street, Newcastle NSW 2300'
      },
      {
        id: 'timezone',
        name: 'Timezone',
        type: 'select',
        options: this.timezoneFormControl
      },
      {
        id: 'dedicatedNumber',
        name: 'Dedicated Number',
        type: 'tel',
        placeholder: '+61400000000'
      },
      {
        id: 'dedicatedClient',
        name: 'Dedicated Client',
        type: 'text',
        placeholder: '1234a-abcd1-5678a-abcde'
      },
      {
        id: 'dedicatedEmail',
        name: 'Dedicated Email',
        type: 'email',
        placeholder: 'myPharmacy@test.com.au'
      },
    ]
  }

  private generateFormControls() {
    this.newPharmacyForm = this.formBuilder.group({
      connection: new FormControl(''),
      connectionType: new FormControl(Validators.required),
      name: new FormControl('', Validators.required),
      abn: new FormControl(''),
      email: new FormControl(''),
      pbs: new FormControl(''),
      phone: new FormControl('', Validators.required),
      address: new FormControl('', Validators.required),
      timezone: new FormControl('', Validators.required),
      dedicatedNumber: new FormControl(''),
      dedicatedClient: new FormControl(''),
      dedicatedEmail: new FormControl('', Validators.required)
    });
  }

  setupPharmacy() {
    const pharmacyRequest = this.newPharmacyForm.value as NewPharmacy;
    this.store.dispatch(new AddNewPharmacy(pharmacyRequest));
  }
}
