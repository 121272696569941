import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { getFullScriptType, getScriptType } from '../../../../../shared/helpers/script-type.helper';
import { ScriptHistory } from '../../../../../shared/models/script/script-history.model';

@Component({
  selector: 'app-original-script',
  templateUrl: './original-script.component.html',
  styleUrls: ['./original-script.component.scss']
})
export class OriginalScriptComponent implements OnInit {
  @Input() originalScript: ScriptHistory;
  @Input() canShowHistory: boolean = false;
  @Input() remainingRepeats: number;
  @Input() isScriptValid = true;
  @Output() toggleAvailability = new EventEmitter<any>();
  @Output() toggleGroupEvent = new EventEmitter<any>();
  showType: boolean;

  constructor() { }

  ngOnInit() {
  }

  toggleScriptAvailability(script: ScriptHistory) {
    if (this.isScriptValid) {
      this.toggleAvailability.emit(script);
    }
  }

  toggleGroup() {
    this.toggleGroupEvent.emit();
  }

  getScriptType(script: ScriptHistory) {
    return getScriptType(script);
  }

  getFullScriptType(script: ScriptHistory) {
    return getFullScriptType(script);
  }

  showScriptType(show: boolean) {
    this.showType = show;
  }
}
