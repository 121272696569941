import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReportResponseComponent } from './report-response.component';

@NgModule({
  declarations: [ReportResponseComponent],
  imports: [
    CommonModule
  ],
  exports: [ReportResponseComponent]
})
export class ReportResponseModule { }
