import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MailComponent } from './mail.component';
import { MailService } from '../../../../shared/services/mail.service';
import { LoaderModule } from '../../../core/loader/loader.module';
import { FormsModule } from '@angular/forms';
import { MailNoteHistoryComponent } from './mail-note-history/mail-note-history.component';
import { AddMailNoteComponent } from './add-mail-note/add-mail-note.component';
import { TimezoneModule } from '../../../core/timezone/timezone.module';

@NgModule({
  declarations: [
    MailComponent,
    MailNoteHistoryComponent,
    AddMailNoteComponent
  ],
  providers: [
    MailService
  ],
  imports: [
    TimezoneModule,
    CommonModule,
    FormsModule,
    LoaderModule
  ],
  exports: [
    MailComponent
  ]
})
export class MailModule { }
