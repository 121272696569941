import { State, Action, StateContext, Selector } from '@ngxs/store';
import { CommTemplate } from '~/shared/models/pharmacy/comm-template.model';
import { CommTemplatesService } from '../../services/comm-templates.service';
import {
  CreateCommTemplateAction,
  CreateCommTemplateSuccessAction,
  AddEditCommTemplateModalFailedAction,
  SubmitAddEditCommTemplateModalAction,
  SetupAddEditCommTemplateModalAction,
  UpdateCommTemplateAction,
  UpdateCommTemplateSuccessAction,
  OpenAddEditCommTemplateModalAction,
  CloseAddEditCommTemplateModalAction,
  ClearCommTemplateModal
} from './add-edit-comm-template-modal.actions';
import { tap, catchError } from 'rxjs/operators';
import { GetCommTemplatesAction } from '../../state/comm-templates.actions';
import { GetTriggers } from '~/modules/pharmacy-comms/comms-reminders/state/triggers.actions';
import { Injectable } from '@angular/core';

export class AddEditCommTemplateModalStateModel {
  commTemplateForm: {
    model: Partial<CommTemplate>;
  };
  loading: boolean;
  showModal: boolean;
  error: any;
  newTemplateId?: number;
}
@Injectable()
@State<AddEditCommTemplateModalStateModel>({
  name: 'addEditCommTemplateModal',
  defaults: {
    loading: false,
    commTemplateForm: {
      model: undefined
    },
    showModal: false,
    error: null,
    newTemplateId: null
  }
})
export class AddEditCommTemplateModalState {
  constructor(private commTemplatesService: CommTemplatesService) { }

  @Selector()
  static addEditCommTemplateModalState(state: AddEditCommTemplateModalStateModel) { return state; }

  @Selector()
  static commTemplate(state: AddEditCommTemplateModalStateModel) { return state.commTemplateForm.model; }

  @Selector()
  static showModal(state: AddEditCommTemplateModalStateModel) { return state.showModal; }

  @Action(OpenAddEditCommTemplateModalAction)
  openAddEditCommTemplateModalAction(ctx: StateContext<AddEditCommTemplateModalStateModel>) {
    ctx.patchState({ showModal: true });
  }

  @Action(CloseAddEditCommTemplateModalAction)
  closeAddEditCommTemplateModalAction(ctx: StateContext<AddEditCommTemplateModalStateModel>) {
    ctx.patchState({ showModal: false });
  }

  @Action(CreateCommTemplateAction)
  create({ dispatch }: StateContext<AddEditCommTemplateModalStateModel>, { item }: CreateCommTemplateAction) {
    return this.commTemplatesService.create(item).pipe(
      tap(res => dispatch(new CreateCommTemplateSuccessAction(res as CommTemplate))),
      catchError(error => dispatch(new AddEditCommTemplateModalFailedAction(error)))
    );
  }

  @Action(CreateCommTemplateSuccessAction)
  createSuccess({ dispatch, patchState }: StateContext<AddEditCommTemplateModalStateModel>, { item }: CreateCommTemplateSuccessAction) {
    patchState({ loading: false, showModal: false, newTemplateId: item?.id });
    dispatch([new GetCommTemplatesAction()]);
  }

  @Action(SubmitAddEditCommTemplateModalAction)
  submit({ dispatch, getState, patchState }: StateContext<AddEditCommTemplateModalStateModel>) {
    patchState({ loading: true, error: null });
    const state = getState();
    const requestModel = state.commTemplateForm.model as CommTemplate;
    requestModel.id ? dispatch(new UpdateCommTemplateAction(requestModel)) : dispatch(new CreateCommTemplateAction(requestModel));
  }

  @Action(UpdateCommTemplateAction)
  update({ dispatch }: StateContext<AddEditCommTemplateModalStateModel>, { item }: UpdateCommTemplateAction) {
    return this.commTemplatesService.update(item).pipe(
      tap(() => dispatch(new UpdateCommTemplateSuccessAction())),
      catchError(error => dispatch(new AddEditCommTemplateModalFailedAction(error)))
    );
  }

  @Action(UpdateCommTemplateSuccessAction)
  updateSuccess({ dispatch, patchState }: StateContext<AddEditCommTemplateModalStateModel>) {
    patchState({ loading: false, showModal: false, commTemplateForm: { model: undefined } });
    dispatch([new GetCommTemplatesAction(), new GetTriggers()]);
  }

  @Action(SetupAddEditCommTemplateModalAction)
  setup({ patchState }: StateContext<AddEditCommTemplateModalStateModel>, { item }: SetupAddEditCommTemplateModalAction) {
    if (item) {
      patchState({ commTemplateForm: { model: item } });
    } else {
      patchState({ loading: false, commTemplateForm: { model: undefined } });
    }
  }

  @Action(AddEditCommTemplateModalFailedAction)
  failed({ patchState }: StateContext<AddEditCommTemplateModalStateModel>, { error }: AddEditCommTemplateModalFailedAction) {
    patchState({ error, loading: false });
  }

  @Action([ClearCommTemplateModal, UpdateCommTemplateSuccessAction, CreateCommTemplateSuccessAction, CloseAddEditCommTemplateModalAction])
  clearCommTemplateModal({ patchState }: StateContext<AddEditCommTemplateModalStateModel>) {
    patchState({ loading: false, commTemplateForm: { model: undefined } });
  }
}
