<section>
<div>
  <h3>Bi Metrics List</h3>
  <div g-xs="text:right my3">
    <button routerLink="/biMetric/add" class="btn btn__green">Create Item</button>
  </div>
</div>
<table class="table">
  <thead>
  <tr>
    <th scope="col">Name</th>
    <th scope="col">Current</th>
    <th scope="col">Target</th>
    <th scope="col">Actions</th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let item of metricList">
    <td>{{item.name}}</td>
    <td>{{item.current}}</td>
    <td>{{item.target}}</td>
    <td>
      <div class="btn-group">
        <button routerLink="/biMetric/edit/{{item.id}}" class="btn btn-primary">Edit</button>
        <button (click)="removeMetric(item.id)" class="btn btn-danger">Remove</button>
      </div>
    </td>
  </tr>
  </tbody>
</table>
</section>
