<form [formGroup]="sickLeaveCertificateTypeForm" ngxsForm="sickLeaveCertificateType.sickLeaveCertificateTypeForm"
  class="p-4">
  <div class="form-group pb-1" (change)="deselectCertificateDetails()"
  [class.has-error-group]="formControls.seekingCertificateFor.invalid && (showInvalid$ | async)">
    <label class="input-label mb-0">Seeking a certificate for</label>
    <div class="form-check form-check">
        <input class="form-check-input" type="radio" name="seekingCertificateFor" id="carer"
            formControlName="seekingCertificateFor" [value]="sickLeaveSeekingCertificateForTypes.CarerLeave">
        <label class="form-check-label" for="carer">Carer's leave</label>
    </div>
    <div class="form-check form-check">
        <input class="form-check-input" type="radio" name="seekingCertificateFor" id="personal"
            formControlName="seekingCertificateFor" [value]="sickLeaveSeekingCertificateForTypes.PersonalSickLeave">
        <label class="form-check-label" for="personal">Personal sick leave</label>
    </div>
</div>
<div *ngIf="sickLeaveCertificateTypeForm.value.seekingCertificateFor === sickLeaveSeekingCertificateForTypes.CarerLeave">
  <div class="form-group pb-1">
    <label class="input-label mb-0">Are you satisfied that it is in relation to a member of the person's immediate family or household?      </label><br />
    <app-radio-highlight controlName="satisfiedYes"
    [hasError]="formControls.satisfiedThatItIsInRelationToMember.invalid && (showInvalid$ | async)"
      [isHighlighted]="sickLeaveCertificateTypeForm.value.satisfiedThatItIsInRelationToMember == true">
      <input class="form-check-input" type="radio" name="satisfiedThatItIsInRelationToMember" id="satisfiedYes"
        formControlName="satisfiedThatItIsInRelationToMember" [value]="true" checked>
      <label class="form-check-label" for="satisfiedYes">Yes</label>
    </app-radio-highlight>
    <app-radio-highlight controlName="satisfiedNo"
    [hasError]="formControls.satisfiedThatItIsInRelationToMember.invalid && (showInvalid$ | async)"
      [isHighlighted]="sickLeaveCertificateTypeForm.value.satisfiedThatItIsInRelationToMember == false">
      <input class="form-check-input" type="radio" name="satisfiedThatItIsInRelationToMember" id="satisfiedNo"
        formControlName="satisfiedThatItIsInRelationToMember" [value]="false">
      <label class="form-check-label" for="satisfiedNo">No</label>
    </app-radio-highlight>
  </div>
  <div class="form-group pb-1" [class.has-error-group]="formControls.membersDetails.invalid && (showInvalid$ | async)">
      <label class="input-label mb-0">Member's Details</label>
      <div class="form-check form-check">
          <input class="form-check-input" type="radio" name="membersDetails" id="household"
              formControlName="membersDetails" [value]="sickLeaveMembersDetailsTypes.HouseholdMember">
          <label class="form-check-label" for="household">Household Member</label>
      </div>
      <div class="form-check form-check">
          <input class="form-check-input" type="radio" name="membersDetails" id="immediate"
              formControlName="membersDetails" [value]="sickLeaveMembersDetailsTypes.ImmediateFamily">
          <label class="form-check-label" for="immediate">Immediate family</label>
      </div>
  </div>
  <div class="form-group pb-1">
    <label for="membersName" class="input-label">Member's Name</label>
    <input type="text" formControlName="membersName" class="form-control form-control-lg w-50"
        [class.has-error]="formControls.membersName.invalid && (showInvalid$ | async)" id="membersName"
        placeholder="Member's Name" required>
</div>
</div>

</form>
