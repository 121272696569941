import { ScriptTypes } from '../constants/script-types.constant';
import { ScriptHistory } from '../models/script/script-history.model';

export function getScriptType(script: ScriptHistory) {
  if (isScriptExpired(script)) {
    return ScriptTypes.expired;
  }
  else if (script.isPassiveScript) {
    return ScriptTypes.deferred
  }
  else if (script.isOwing) {
    return ScriptTypes.owing;
  }
  else if (script.isDeferred) {
    return ScriptTypes.deferred;
  }
  else if (script.scriptNo == script.originalScriptNo || !script.originalScriptNo) {
    return ScriptTypes.original;
  } else {
    return ScriptTypes.repeat;
  }
}

export function getFullScriptType(script: ScriptHistory) {
  if (isScriptExpired(script)) {
    return ScriptTypes.expiredFullName;
  }
  else if (script.isPassiveScript) {
    return ScriptTypes.deferredFullName;
  }
  else if (script.isOwing) {
    return ScriptTypes.owingFullName;
  }
  else if (script.isDeferred) {
    return ScriptTypes.deferredFullName;
  }
  else if (script.scriptNo == script.originalScriptNo || !script.originalScriptNo) {
    return ScriptTypes.originalFullName;
  } else {
    return ScriptTypes.repeatFullName;
  }
}

export function isScriptExpired(script: ScriptHistory): boolean {
  const now = new Date();

  if (script.expiry) {
    return new Date(script.expiry) < now;
  }
  else {
    const lastYear = new Date();
    lastYear.setFullYear(lastYear.getFullYear() -1);

    if (!script.prescriptionDate) {
      return false; //OTC never expires
    }

    if (lastYear >= new Date(script.prescriptionDate)) {
      return true;
    }
  }

  return false;
}
