<section>
  <section>
    <h1>Client Search</h1>
    <div class="search-block">
      <div class="font__bold" g-xs="mb2">Client Lookup</div>
      <form class="search-form" (submit)="search()">
        <input type="text" class="form-control" [(ngModel)]="searchText" id="name" name="name" placeholder="Surname">
        <button type="submit" class="btn__img">
          <i class="fas fa-search"></i>
        </button>
      </form>
    </div>
  </section>
  <section class="search-list border__grey" g-xs="mt3">
    <div class="flex search-list__title" g-xs="pt2 px3 pb1">
      <div class="font__title-3">Search Results
        <span *ngIf="searchResult">for
          <strong>
            {{ searchResult }} : {{ resultCount }}
            Client<span *ngIf="resultCount !== 1">s</span>
          </strong>
        </span>
      </div>
      <div>
        <span class="status bg__orange ml-2"></span> LEAD
        <span class="status bg__green ml-2"></span> SOF/DAA
        <span class="status bg__red ml-2"></span> NONE
      </div>
    </div>
    <div *ngIf="(clientResults$ | async) as clients; else loading">
      <table class="table search-list__table">
        <tr class="bg__light-yellow-table">
          <th (click)="sort('fullname')">Patient Name</th>
          <th (click)="sort('patientAdress')">Patient Address</th>
          <th (click)="sort('birthDate')">Date of Birth</th>
          <th (click)="sort('type')">Patient Status</th>
          <th (click)="sort('customerGroup')">Group</th>
          <th> Drive Yello</th>
        </tr>
        <tr *ngFor="let client of clients | appSort: sortColumnProperties">
          <td>
            <a routerLink="/profile/{{client.id}}" class="color__darkest flex align-items-center ">
              <div class="{{ getStatus(client.type) }} search-list__status" g-xs="mr2"></div>
              {{ client.fullname }}
            </a>
          </td>
          <td>
            {{ client.patientAdress }}
          </td>
          <td>
            {{ client.birthDate | tenantDate }}
          </td>
          <td>
            {{client.type}}
          </td>
          <td>
            {{client.customerGroup}}
          </td>
          <td>
            <a *ngIf="client.type" (click)="openDYModal()" routerLink="/profile/{{client.id}}/orders"
              class="color__darkest flex align-items-center">New Order</a>
          </td>
        </tr>
      </table>
    </div>
  </section>
</section>

<ng-template #loading>
  <div g-xs="p4 text:center" class="flex">
    <app-loader class="loading"></app-loader>
  </div>
</ng-template>
