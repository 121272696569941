<section *ngIf="!isLoaded">
  <app-loader></app-loader>
</section>
<section *ngIf="isLoaded">

  <div>
      <h3>User List</h3>
      <div g-xs="text:right my3">
          <button (click)="openUpdateForm()" class="btn btn__green">Create Item</button>
      </div>  
    </div>
    <div *ngIf="isUpdateFormVisible">
      <app-user-item 
      (saveEvent)="updateList($event)" 
      (cancelEvent)="onUpdateFormCancel()"
      [currentItem]="currentItem"
      ></app-user-item>
    </div>
  <table class="table">
      <thead>
      <tr>
        <th scope="col">Email</th>
        <th></th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let item of userList">
        <td>{{item.email}}</td>
        <td>
          <div class="btn-group">
            <!-- <button (click)="openUpdateForm(item)" class="btn btn-primary">Edit</button> -->
            <button (click)="removeItem(item.id)" class="btn btn-danger">Remove</button>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
</section>