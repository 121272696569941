import { hasOrderSuggestionInsight } from '../../../../shared/suggested-order/suggested-order.logic';
import { pharmacistInterventionAdvised, getInsightValue } from '../../../../shared/order-doh/order-doh.logic';
import { OrderScryptInsight, suggestedOrderScryptInsight, dohScryptInsight } from './order-scrypt-insights.model';


  export function insightAvailable(item) {
    if (item == null) {
      return false;
    }
    return hasOrderSuggestionInsight(item) || pharmacistInterventionAdvised(item);
  }

  export function getInsightContext(item) {

    const insights: OrderScryptInsight[] = [];
    if (hasOrderSuggestionInsight(item)) {
      insights.push(
        {
          type: suggestedOrderScryptInsight,
          title: '',
          info: item.suggestedOrderQty,
          action: () => item.orderQty = item.suggestedOrderQty
        }
      );
    }

    if (pharmacistInterventionAdvised(item)) {
      insights.push(
        {
          type: dohScryptInsight,
          title: '',
          info: getInsightValue(item),
          action: () => {}
        }
      );
    }

    return {
      insights,
      suggestedOrderInsight: suggestedOrderScryptInsight,
      orderDohInsight: dohScryptInsight
    };

  }
