import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SixCpaFormListItemChecklistModule } from '../../six-cpa-form-list-item-checklist/six-cpa-form-list-item-checklist.module';
import { DAAClassificationsRecommendationsFormModule } from '../daa-classifications-recommendations/daa-classifications-recommendations.module';
import { DaaClassificationsRecommendationsFormListItemComponent } from './daa-classifications-recommendations-form-list-item.component';

@NgModule({
    declarations: [
      DaaClassificationsRecommendationsFormListItemComponent
    ],
    imports: [
        CommonModule,
        SixCpaFormListItemChecklistModule,
        DAAClassificationsRecommendationsFormModule
    ],
    exports: [
      DaaClassificationsRecommendationsFormListItemComponent
    ]
})
export class DAAClassificationsRecommendationsListItemModule { }