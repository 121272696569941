import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import { ReactiveFormsModule } from '@angular/forms';
import { OutcomesAndActionsFormComponent } from './outcomes-and-actions-form.component';

@NgModule({
    declarations: [
        OutcomesAndActionsFormComponent
    ],
    imports: [
        CommonModule,
        NgxsFormPluginModule,
        ReactiveFormsModule
    ],
    exports: [
        OutcomesAndActionsFormComponent
    ]
})
export class OutcomesAndActionsFormModule { }
