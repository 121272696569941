import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrderTableComponent } from './order-table.component';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { FormsModule } from '@angular/forms';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { ValidService } from './order-table.service';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { TenantDatePipe } from '../../../../core/timezone/pipes/tenant-date.pipe';

@NgModule({
  declarations: [
    OrderTableComponent
  ],
  imports: [
    CommonModule,
    NzDropDownModule,
    NzButtonModule,
    NzCheckboxModule,
    FormsModule,
    NzInputModule,
    NzInputNumberModule,
    NzSelectModule,
    NzDatePickerModule,
    NzPopoverModule,
    NzToolTipModule
  ],
  exports:[
    OrderTableComponent
  ],
  providers:[ValidService, TenantDatePipe]
})
export class OrderTableModule { }
