<form [formGroup]="patientDetailsForm" ngxsForm="daaPatientDetailsFormDetailed.patientDetailsForm" class="p-4">
    <div class="form-group pb-1">
        <label for="patientDOB" class="input-label">Patients Date of Birth</label>
        <input type="text" [class.has-error]="formControls.patientDOB.invalid && (showInvalid$ | async)"
            class="form-control form-control-lg w-25" [bsValue]="patientDetailsForm.value.patientDOB"
            formControlName="patientDOB" id="patientDOB" [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY' }"
            placeholder="dd-mm-yyyy" bsDatepicker>
    </div>
    <div class="form-group pb-1">
        <label for="medicareNumber" class="input-label">Medicare Number</label>
        <input type="text" [class.has-error]="formControls.medicareNumber.invalid && (showInvalid$ | async)"
            class="form-control form-control-lg w-25" name="medicareNumber" formControlName="medicareNumber"
            id="medicareNumber" placeholder="123456789" required>
    </div>
    <div class="form-group pb-1">
        <div>
            <label class="input-label">Patients Gender</label>
        </div>
        <app-radio-highlight [hasError]="formControls.gender.invalid && (showInvalid$ | async)" controlName="genderMale"
            [isHighlighted]="patientDetailsForm.value.gender == 'M'">
            <input class="form-check-input " type="radio" name="gender" formControlName="gender" id="genderMale"
                value="M">
            <label class="form-check-label" for="genderMale">Male</label>
        </app-radio-highlight>
        <app-radio-highlight [hasError]="formControls.gender.invalid && (showInvalid$ | async)"
            controlName="genderFemale" [isHighlighted]="patientDetailsForm.value.gender == 'F'">
            <input class="form-check-input" type="radio" name="gender" formControlName="gender" id="genderFemale"
                value="F">
            <label class="form-check-label" for="genderFemale">Female</label>
        </app-radio-highlight>
    </div>
    <div class="form-group pb-1">
        <label for="residentialPostCode" class="input-label">Patients Residential Postcode</label>
        <input type="text" [class.has-error]="formControls.residentialPostCode.invalid && (showInvalid$ | async)"
            class="form-control form-control-lg w-25" formControlName="residentialPostCode" id="residentialPostCode"
            placeholder="2000" required>
    </div>
    <div class="form-group pb-1"
        [class.has-error-group]="formControls.currentLivingStatus.invalid && (showInvalid$ | async)">
        <label class="input-label mb-0">Where is the patient currently living?</label>
        <div class="form-check form-check">
            <input class="form-check-input" type="radio" name="currentLivingStatus" id="private"
                formControlName="currentLivingStatus" [value]=0>
            <label class="form-check-label" for="private">Private residence</label>
        </div>
        <div class="form-check form-check">
            <input class="form-check-input" type="radio" name="currentLivingStatus" id="independent"
                formControlName="currentLivingStatus" [value]=1>
            <label class="form-check-label" for="independent">Independent living unit within a retirement
                village</label>
        </div>
        <div class="form-check form-check">
            <input class="form-check-input" type="radio" name="currentLivingStatus" id="supportedLiving"
                formControlName="currentLivingStatus" [value]=2>
            <label class="form-check-label" for="supportedLiving">Supported accommodation or supported living</label>
        </div>
        <div class="form-check form-check">
            <input class="form-check-input" type="radio" name="currentLivingStatus" id="institutionalSetting"
                formControlName="currentLivingStatus" [value]=3>
            <label class="form-check-label" for="institutionalSetting">Institutional setting</label>
            <div>
                <small class="xs-disclaimer">
                    Including aged care and psychiatric or mental health community carefacilities
                </small>
            </div>
        </div>
        <div class="form-check form-check">
            <input class="form-check-input" type="radio" name="currentLivingStatus" id="other"
                formControlName="currentLivingStatus" [value]=4 checked>
            <label class="form-check-label" for="other">Other</label>
            <input class="form-control form-control-lg w-50"
                [class.has-error]="formControls.currentLivingOtherDetails.invalid && (showInvalid$ | async)" type="text"
                name="currentLivingOtherDetails" id="otherDetails" formControlName="currentLivingOtherDetails"
                [attr.disabled]="patientDetailsForm.value.currentLivingStatus !== 4 ? '' : null">
        </div>
    </div>

    <div class="form-group pb-1">
        <div>
            <label class="input-label">Does the patient have a government issued concession card?</label>
        </div>
        <app-radio-highlight [hasError]="formControls.concessionCard.invalid && (showInvalid$ | async)"
            controlName="concessionYes" [isHighlighted]="patientDetailsForm.value.concessionCard == true">
            <input class="form-check-input " type="radio" name="concessionCard" formControlName="concessionCard"
                id="concessionYes" [value]="true">
            <label class="form-check-label" for="concessionYes">Yes</label>
        </app-radio-highlight>
        <app-radio-highlight [hasError]="formControls.concessionCard.invalid && (showInvalid$ | async)"
            controlName="concessionNo" [isHighlighted]="patientDetailsForm.value.concessionCard == false">
            <input class="form-check-input" type="radio" name="concessionCard" formControlName="concessionCard"
                id="concessionNo" [value]="false">
            <label class="form-check-label" for="concessionNo">No</label>
        </app-radio-highlight>
    </div>
</form>