import { Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { GetBulkMessagesUpcoming, GetBulkMessageHistory } from '../state/bulk-messaging.actions';

@Injectable()
export class BulkMessagingResolver implements Resolve<Observable<any>> {
  constructor(private store: Store) { }
  resolve(): Observable<any> {
    return this.store.dispatch([
      new GetBulkMessageHistory(false),
      new GetBulkMessagesUpcoming(false)
    ]);
  }
}