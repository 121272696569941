import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { GetSixCpaFormsFromService } from '../../../modules/profile/profile-services/state/profile-services.actions';
import { ProfileState } from './state/profile.state';

@Injectable()
export class ProfileServicesResolver implements Resolve<Observable<any>> {
    constructor(private store: Store) { }
    resolve(route: ActivatedRouteSnapshot): Observable<any> {
        const clientId = this.store.selectSnapshot(ProfileState.clientId)
        return this.store.dispatch(new GetSixCpaFormsFromService(clientId));
    }
}
