<div g-xs="p3 my3" class="bg__grey">
    <form #f="ngForm" (ngSubmit)="onSubmit(f)">
        <div class="form-group">
          <label for="userId">User</label>
          <select class="form-control"
           id="userId" 
           name="userId" 
           required
           #userId="ngModel" [ngModel]="currentItem.userId">
              <option [ngValue]="null">
                Select User
              </option>
              <option *ngFor="let item of userList" [value]="item.id">
                {{item.email}}
              </option>
            </select>
          <span *ngIf="userId.invalid && userId.touched" class="form-help-text">Required field</span>
        </div>
        <div class="form-group">
            <label for="tenantId">Tenant</label>
            <select class="form-control"
             id="tenantId" 
             name="tenantId" 
             required
             #tenantId="ngModel" [ngModel]="currentItem.tenantId">
                <option [ngValue]="null">
                  Select Tenant
                </option>
                <option *ngFor="let item of tenantList" [value]="item.id">
                  {{item.name}}
                </option>
              </select>
            <span *ngIf="tenantId.invalid && tenantId.touched" class="form-help-text">Required field</span>
          </div>
        <div class="btn-group">
          <button type="submit" class="btn btn-primary" [disabled]="f.invalid">Save</button>
          <button class="btn btn-danger" (click)="cancel()">Cancel</button>
        </div>
      </form>
</div>

