import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrderMessageComponent } from './order-message.component';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { FormsModule } from '@angular/forms';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';

@NgModule({
  declarations: [
    OrderMessageComponent
  ],
  imports: [
    CommonModule,
    NzSelectModule,
    NzInputModule,
    FormsModule,
    NzCheckboxModule
  ],
  exports:[
    OrderMessageComponent
  ]
})
export class OrderMessageModule { }
