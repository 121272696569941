<ng-container *ngFor="let hmrFormType of [hmrFormType$ | async]">
  <div [formGroup]="hmrFormList">
    <app-radio-highlight controlName="newClaim" size="lg" [isHighlighted]="hmrFormType === hmrFormTypes.NewClaim"
      [fullWidth]="true">
      <input class="form-check-input" type="radio" name="hmrFormType" id="newClaim" formControlName="hmrFormType"
        [value]="hmrFormTypes.NewClaim" [checked]="hmrFormType == hmrFormTypes.NewClaim">
      <label class="form-check-label" for="newClaim">New Claim</label>
    </app-radio-highlight>
    <app-radio-highlight controlName="interviewRegisteredPhamacist" size="lg"
      [isHighlighted]="hmrFormType === hmrFormTypes.InterviewRegisteredPhamacist" [fullWidth]="true">
      <input class="form-check-input" type="radio" name="hmrFormType" id="interviewRegisteredPhamacist"
        formControlName="hmrFormType" [value]="hmrFormTypes.InterviewRegisteredPhamacist"
        [checked]="hmrFormType == hmrFormTypes.InterviewRegisteredPhamacist">
      <label class="form-check-label" for="interviewRegisteredPhamacist">Interview Registered Phamacist</label>
    </app-radio-highlight>
    <app-radio-highlight controlName="interviewOutsidePatientHome" size="lg"
      [isHighlighted]="hmrFormType === hmrFormTypes.InterviewOutsidePatientHome" [fullWidth]="true">
      <input class="form-check-input" type="radio" name="hmrFormType" id="interviewOutsidePatientHome"
        formControlName="hmrFormType" [value]="hmrFormTypes.InterviewOutsidePatientHome"
        [checked]="hmrFormType == hmrFormTypes.InterviewOutsidePatientHome">
      <label class="form-check-label" for="interviewOutsidePatientHome">Interview Outside Patient's Home</label>
    </app-radio-highlight>
  </div>
  <div [ngSwitch]="hmrFormType">
    <div class="pt-2">
      <p *ngSwitchCase="hmrFormTypes.NewClaim">Submitting this form will send a HMR Claim to the PPA and save the
        claim details in Scrypt.</p>
      <p *ngSwitchCase="hmrFormTypes.InterviewRegisteredPhamacist">It’s not currently possible for Registered
        Pharmacist Interviews to be automatically claimed via the <a (click)="openPPAPortal()">PPA portal.</a>
        Completing this form will export the Registered
        Pharmacist Interview so it can be manually copied across.</p>
      <p *ngSwitchCase="hmrFormTypes.InterviewOutsidePatientHome">It’s not currently possible for Patient Home
        Interviews to be automatically claimed via the <a (click)="openPPAPortal()">PPA portal.</a>
        Completing this form will export the Patient Home
        Interview so it can be manually copied across.</p>
    </div>
    <div *ngSwitchCase="hmrFormTypes.NewClaim">
      <app-hmr-consent-and-eligibility-list-item></app-hmr-consent-and-eligibility-list-item>
      <app-hmr-patient-details-form-list-item></app-hmr-patient-details-form-list-item>
      <app-hmr-pharmacy-information-list-item></app-hmr-pharmacy-information-list-item>
      <app-hmr-gp-details-referral-form-list-item></app-hmr-gp-details-referral-form-list-item>
      <app-hmr-location-list-item></app-hmr-location-list-item>
    </div>
    <div *ngSwitchCase="hmrFormTypes.InterviewRegisteredPhamacist">
      <app-hmr-patient-details-form-list-item></app-hmr-patient-details-form-list-item>
      <app-hmr-service-provider-details-list-item></app-hmr-service-provider-details-list-item>
      <app-hmr-gp-details-referral-form-list-item></app-hmr-gp-details-referral-form-list-item>
      <app-hmr-proposed-details-list-item></app-hmr-proposed-details-list-item>
    </div>
    <div *ngSwitchCase="hmrFormTypes.InterviewOutsidePatientHome">
      <app-hmr-patient-details-form-list-item></app-hmr-patient-details-form-list-item>
      <app-hmr-proposed-details-list-item></app-hmr-proposed-details-list-item>
      <app-hmr-service-provider-details-list-item></app-hmr-service-provider-details-list-item>
      <app-hmr-accredited-pharmacist-details-list-item></app-hmr-accredited-pharmacist-details-list-item>
      <app-hmr-gp-details-referral-form-list-item></app-hmr-gp-details-referral-form-list-item>
    </div>


    <ng-container >
      <div class="pt-4" *ngFor="let saving of [saving$ | async]">
        <app-loader class="loading" *ngIf="saving"></app-loader>
        <ng-container  *ngSwitchCase="hmrFormTypes.NewClaim">
          <button *ngIf="!(readonly$ | async)" class="button submit mr-3" [disabled]="saving" (click)="confirmHMR()">Submit HMR</button>
        </ng-container>
        <ng-container *ngSwitchCase="hmrFormTypes.InterviewRegisteredPhamacist">
          <button *ngIf="!(readonly$ | async)" class="button submit mr-3" [disabled]="saving"
            (click)="submitHMRInterviewRegisteredPhamacist()">Complete
            HMR</button>
        </ng-container>
        <ng-container *ngSwitchCase="hmrFormTypes.InterviewOutsidePatientHome">
          <button *ngIf="!(readonly$ | async)" class="button submit mr-3" [disabled]="saving" (click)="submitHMRInterviewOutsidePatientHome()">Complete
            HMR
          </button>
        </ng-container>
        <button class="button save mr-3" [disabled]="saving" (click)="saveHMRForm()">Save HMR
        </button>
        <span (click)="resetHMRFormList()">Reset Form</span>
      </div>
    </ng-container>

  </div>
</ng-container>
