import { OutcomesAndActionsFormModel } from "~/shared/models/six-cpa/outcomes-and-actions-form.model";

export enum OutcomesAndActionsFormActions {
  SET_VALID = '[OutcomesAndActionsForm] set valid',
  UPDATE = '[OutcomesAndActionsForm] Update Form'
}

export class SetOutcomesAndActionsFormValidAction {
  static readonly type = OutcomesAndActionsFormActions.SET_VALID;
  constructor(public valid = false) { }
}

export class SetTotalItemsAndCompletedItems {
  static readonly type = '[OutcomesAndActionsForm] Update Progress';
  constructor(public totalItems, public completedItems) { }
}

export class UpdateOutcomesAndActionsForm {
  static readonly type = OutcomesAndActionsFormActions.UPDATE;
  constructor(public form: OutcomesAndActionsFormModel) { }
}
