import { State, Selector, Action, StateContext } from '@ngxs/store';
import { IHMRProposedDetailsFormStateModel } from './hmr-proposed-details-form-state-model.interface';
import { UpdateHMRProposedDetailsForm } from './hmr-proposed-details-form.actions';
import * as moment_ from 'moment';
const moment = moment_;
import { Injectable } from '@angular/core';

export interface IHMRProposedDetailsFormDetailedStateModel extends IHMRProposedDetailsFormStateModel  {
    dateOfGPReferral: Date | string | null;
    proposedLocation: string;
    suburb: string;
    postcode: string;
    termsAndConditions: boolean | null;
}

  export class HMRProposedDetailsFormDetailedStateModel {
    proposedDetailsForm: {
      model: Partial<IHMRProposedDetailsFormDetailedStateModel>;
    };
  }

  @State<HMRProposedDetailsFormDetailedStateModel>({
    name: 'hmrProposedDetailsFormDetailed',
    defaults: {
      proposedDetailsForm: {
        model: undefined
      }
    }
  })
  @Injectable()
  export class HMRProposedDetailsFormDetailedState {
    constructor() {}
    @Selector()
    static form(state: HMRProposedDetailsFormDetailedStateModel) { return state.proposedDetailsForm.model; }

    @Action(UpdateHMRProposedDetailsForm)
    updateForm(ctx: StateContext<HMRProposedDetailsFormDetailedStateModel>, { form }: UpdateHMRProposedDetailsForm) {
      if (form) {
        ctx.patchState({
          proposedDetailsForm: {
            model: {
              postcode: form.postcode,
              proposedLocation: form.proposedLocation,
              suburb: form.suburb,
              termsAndConditions: form.termsAndConditions,
              dateOfGPReferral: form.proposedDateOfInterview && moment(form.dateOfGPReferral, 'YYYY-MM-DD').format('DD-MM-YYYY'),
              proposedDateOfInterview: form.proposedDateOfInterview && moment(form.proposedDateOfInterview, 'YYYY-MM-DD').format('DD-MM-YYYY')
            }
          }
        });
      } else {
        ctx.patchState({
          proposedDetailsForm: {
            model: undefined
          }
        });
      }

    }
  }
