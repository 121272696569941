import { Component, OnInit } from '@angular/core';
import { Select } from '@ngxs/store';
import { DAAClassificationsRecommendationsState } from '../daa-classifications-recommendations/state/daa-classifications-recommendations.state';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-daa-classifications-recommendations-form-list-item',
  templateUrl: './daa-classifications-recommendations-form-list-item.component.html'
})
export class DaaClassificationsRecommendationsFormListItemComponent implements OnInit {
  @Select(DAAClassificationsRecommendationsState) daaClassificationsRecommendationsForm$: Observable<any>;
  constructor() { }

  ngOnInit() {
  }

}
