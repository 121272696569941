import { State, Action, StateContext, Selector } from '@ngxs/store';
import { SickLeaveCertificateTypeFormModel } from '../../../../../../shared/models/six-cpa/sick-leave/sick-leave-certificate-type-form.model';
import { SetSickLeaveCertificateTypeValidAction, SetTotalItemsAndCompletedItems, UpdateSickLeaveCertificateType } from './sick-leave-certificate-type.actions';
import { Injectable } from '@angular/core';

export class SickLeaveCertificateTypeStateModel {
  sickLeaveCertificateTypeForm: {
    model: Partial<SickLeaveCertificateTypeFormModel>
  };
  valid: boolean;
  totalItems: number;
  completedItems: number;
}

export enum SickLeaveSeekingCertificateForTypes {
  CarerLeave,
  PersonalSickLeave
}

export enum SickLeaveMembersDetailsTypes {
  HouseholdMember,
  ImmediateFamily
}
@Injectable()
@State<SickLeaveCertificateTypeStateModel>({
  name: 'sickLeaveCertificateType',
  defaults: {
    sickLeaveCertificateTypeForm: {
      model: undefined
    },
    valid: false,
    completedItems: 0,
    totalItems: 0
  }
})
export class SickLeaveCertificateTypeState {
  @Selector()
  static sickLeaveCertificateTypeForm(state: SickLeaveCertificateTypeStateModel) { return state.sickLeaveCertificateTypeForm.model; }

  @Selector()
  static totalItems(state: SickLeaveCertificateTypeStateModel) { return state.totalItems; }

  @Selector()
  static completedItems(state: SickLeaveCertificateTypeStateModel) { return state.completedItems; }

  @Action(SetSickLeaveCertificateTypeValidAction)
  setValid(ctx: StateContext<SickLeaveCertificateTypeStateModel>, action: SetSickLeaveCertificateTypeValidAction) {
    ctx.patchState({
      valid: action.valid
    });
  }

  @Action(SetTotalItemsAndCompletedItems)
  setProgress(ctx: StateContext<SickLeaveCertificateTypeStateModel>, action: SetTotalItemsAndCompletedItems) {
    ctx.patchState({
      totalItems: action.totalItems,
      completedItems: action.completedItems
    });
  }

  @Action(UpdateSickLeaveCertificateType)
  updateForm(ctx: StateContext<SickLeaveCertificateTypeStateModel>, { form }: UpdateSickLeaveCertificateType) {
      ctx.patchState({
        sickLeaveCertificateTypeForm: {
          model: form || undefined
        }
      });
  }
}
