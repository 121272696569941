import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

declare var window: any;

@Injectable()
export class StorageService {
  constructor(private cookieService: CookieService) {}

  public async getItem(key: string) {
    if (window.todesktop) {
      try {
        await this.GetLocalItem(key).then((dataItem) => {
          if (dataItem == undefined) dataItem = null;
          this.cookieService.set(key, dataItem, undefined, '/');
        });
      } catch (error) {
        console.log('not desktop');
      }
    }

    const data = this.cookieService.get(key);
    if (data === '' || data == null) {
      return null;
    }
    return data;
  }

  public setItem(key: string, data: string) {
    if (window.todesktop) {
      try {
        this.SetLocalItem(key, data);
      } catch (error) {
        console.log('not desktop');
      }
    }
    this.cookieService.set(key, data, undefined, '/');
  }

  public deleteItem(key: string) {
    if (window.todesktop) {
      try {
        window.todesktop.secureStorage.remove(key);
      } catch (error) {}
    }
    this.cookieService.delete(key);
  }

  public clearStorage() {
    this.cookieService.deleteAll();
  }

  async GetLocalItem(key) {
    return await window.todesktop.secureStorage.get(key);
  }
  async SetLocalItem(key, value) {
    return await window.todesktop.secureStorage.set(key, value);
  }
}
