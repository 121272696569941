import { State, Action, StateContext, Selector } from '@ngxs/store';
import { HMRPharmacyInformationFormModel } from '../../../../../../shared/models/six-cpa/HMR/hmr-pharmacy-information-form.model';
import { SetHMRPharmacyInformationValidAction, UpdateHMRPharmacyInformation, SetTotalItemsAndCompletedItems } from './hmr-pharmacy-information.actions';
import * as moment_ from 'moment';
const moment = moment_;
import { Injectable } from '@angular/core';

export class HMRPharmacyInformationStateModel {
  hmrPharmacyInformationForm: {
    model: Partial<HMRPharmacyInformationFormModel>
  };
  valid: boolean;
  totalItems: number;
  completedItems: number;
}
@Injectable()
@State<HMRPharmacyInformationStateModel>({
  name: 'hmrPharmacyInformation',
  defaults: {
    hmrPharmacyInformationForm: {
      model: undefined
    },
    valid: false,
    completedItems: 0,
    totalItems: 0
  }
})
export class HMRPharmacyInformationState {

  @Selector()
  static hmrPharmacyInformationForm(state: HMRPharmacyInformationStateModel) { return state.hmrPharmacyInformationForm.model; }

  @Selector()
  static totalItems(state: HMRPharmacyInformationStateModel) { return state.totalItems; }

  @Selector()
  static completedItems(state: HMRPharmacyInformationStateModel) { return state.completedItems; }

  @Action(SetHMRPharmacyInformationValidAction)
  setValid(ctx: StateContext<HMRPharmacyInformationStateModel>, action: SetHMRPharmacyInformationValidAction) {
    ctx.patchState({
      valid: action.valid
    });
  }

  @Action(SetTotalItemsAndCompletedItems)
  setProgress(ctx: StateContext<HMRPharmacyInformationStateModel>, action: SetTotalItemsAndCompletedItems) {
    ctx.patchState({
      totalItems: action.totalItems,
      completedItems: action.completedItems
    });
  }

  @Action(UpdateHMRPharmacyInformation)
  updateForm(ctx: StateContext<HMRPharmacyInformationStateModel>, { form }: UpdateHMRPharmacyInformation) {
    if (form) {
      ctx.patchState({
        hmrPharmacyInformationForm: {
          model: {
            id: form.id,
            dateOfService: moment(form.dateOfService, 'YYYY-MM-DD').toDate(),
            dateCompletedReportSentToGP: moment(form.dateCompletedReportSentToGP, 'YYYY-MM-DD').toDate(),
            accreditationNumber: form.accreditationNumber,
            pharmacistFamilyName: form.pharmacistFamilyName,
            pharmacistGivenName: form.pharmacistGivenName
          }
        }
      });
    } else {
      ctx.patchState({
        hmrPharmacyInformationForm: {
          model: undefined
        }
      });
    }
  }
}
