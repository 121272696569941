import { Inject, Injectable } from '@angular/core';
import { BaseApi } from '../../../../../shared/core/base-api/base-api';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { StorageService } from '../../../../../shared/core/storage/storage.service';
import { AlertService } from '../../../../core/alert/alert.service';
import { PauseMedicationRequest } from '../../../../../shared/models/pause-medication-request.model';
import { Observable } from 'rxjs';

@Injectable()
export class PauseMedicationService extends BaseApi {
  API = '';

  constructor(
    @Inject('environment') env: any,
    public http: HttpClient,
    public router: Router,
    public storageService: StorageService,
    public alertService: AlertService
  ) {
    super(http, router, storageService, alertService);
    this.API = env.baseApiUrl;
  }
  getPausedMedications(clientId: string) {
    return this.get(
      `${this.API}/api/pause-medication/${clientId}/paused-scripts`
    );
  }

  pauseMedication(request: PauseMedicationRequest): Observable<any> {
    return this.post(`${this.API}/api/pause-medication/pause`, request);
  }

  unPauseMedication(request: PauseMedicationRequest): Observable<any> {
    return this.post(`${this.API}/api/pause-medication/resume`, request);
  }

  pauseBulkMedication(request: PauseMedicationRequest[]): Observable<any> {
    return this.post(`${this.API}/api/pause-medication/bulk-pause`, request);
  }

  unPauseBulkMedication(request: PauseMedicationRequest[]): Observable<any> {
    return this.post(`${this.API}/api/pause-medication/bulk-resume`, request);
  }
}
