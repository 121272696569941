import { HMRProposedDetailsFormModel } from '../../../../../../shared/models/six-cpa/HMR/hmr-proposed-details-form.model';

export enum HMRProposedDetailsFormActions {
  UPDATE_PROGRESS = '[HMRProposedDetailsForm] Update Progress',
  UPDATE = '[HMRProposedDetailsForm] Update'
}

export class SetTotalItemsAndCompletedItems {
  static readonly type = HMRProposedDetailsFormActions.UPDATE_PROGRESS;
  constructor(public totalItems: number, public completedItems: number) { }
}

export class UpdateHMRProposedDetailsForm {
  static readonly type = HMRProposedDetailsFormActions.UPDATE;
  constructor(public form: Partial<HMRProposedDetailsFormModel>) { }
}
