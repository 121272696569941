import { Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { Timezone } from '../timezone.model';
import { GetSelectedTenantTimezone } from '../state/timezone.actions';
import { map, switchMap, take } from 'rxjs/operators';
import { TimezoneState } from '../state/timezone.state';
import { Timezones } from '~/shared/constants/timezones';


@Injectable({
    providedIn: 'root'
})
export class SelectedTimezoneResolver implements Resolve<Timezone> {
    constructor(private store: Store) {}

    resolve(): Observable<Timezone> {
       return this.store.dispatch(new GetSelectedTenantTimezone()).pipe(
            switchMap(() => this.store.select(TimezoneState.selectedTimezone)),
            take(1),
            map((timezoneId: string) => Timezones.find(tz => tz.id === timezoneId) as Timezone)
        );
    }
}
