import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Store } from '@ngxs/store';
import { SetClientIdAction } from '../../modules/core/profile/state/profile.actions';

@Injectable()
export class ClientIdResolver implements Resolve<any> {
  constructor(private store: Store) {

  }
  resolve(route: ActivatedRouteSnapshot) {
    const clientId = route.params['clientId'];
    this.store.dispatch(new SetClientIdAction(clientId));

    return clientId;
  }
}
