import { Actions, ofActionSuccessful, Select } from '@ngxs/store';
import { OnInit, OnDestroy, OnChanges, Injectable } from '@angular/core';
import { Subscription, Observable } from 'rxjs';
import { DoseAdministrationAidsState } from './dose-administration-aids/state/dose-administration-aids.state';
import { ResetDAAFormListAction } from './dose-administration-aids/state/dose-administration-aids.actions';

@Injectable()

export abstract class DAAChildForm implements OnInit, OnDestroy, OnChanges {
  private onResetFormSubscription: Subscription;

  constructor(
    protected actions$: Actions
  ) {
    this.configureLifecycleHooks();
  }

  @Select(DoseAdministrationAidsState.showInvalid) showInvalid$: Observable<boolean>;

  ngOnInit() {}
  ngOnDestroy() {}
  ngOnChanges() {}

  protected abstract actionOnResetForm();

  private init(): void {
    this.onResetFormSubscription = this.getResetFormSubscription();

  }

  private destroy(): void {
    if (this.onResetFormSubscription) {
      this.onResetFormSubscription.unsubscribe();
    }
  }

  private configureLifecycleHooks() {
    const onDestroy = this.ngOnDestroy;
    const onInit = this.ngOnInit;
    this.ngOnInit = () => { this.init(); onInit.apply(this); };
    this.ngOnDestroy = () => { this.destroy(); onDestroy.apply(this); };
  }

  private getResetFormSubscription(): Subscription {
    return this.actions$.pipe(ofActionSuccessful(ResetDAAFormListAction))
      .subscribe(() => this.actionOnResetForm());
  }
}
