import { Component, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngxs/store';
import { SetDigitalSignature } from './state/scrypt-digital-signature.actions';
import { SignaturePad } from 'angular2-signaturepad';

@Component({
  selector: 'app-scrypt-digital-signature',
  templateUrl: './scrypt-digital-signature.component.html',
  styleUrls: ['./scrypt-digital-signature.component.scss']
})
export class ScryptDigitalSignatureComponent implements OnInit {
  @ViewChild(SignaturePad, { static: false }) signaturePad: SignaturePad;

  signaturePadOptions: Object = { // passed through to szimek/signature_pad constructor
    'minWidth': 1,
    'maxWidth': 2,
    'canvasWidth': 710,
    'canvasHeight': 150
  };

  constructor(private store: Store) { }

  ngOnInit() {
  }

  drawComplete() {
    this.store.dispatch(new SetDigitalSignature(this.signaturePad.toDataURL()))
  }

  clearSignature() {
    this.signaturePad.clear();
  }
}
