import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SixCpaFormListItemChecklistModule } from '../../six-cpa-form-list-item-checklist/six-cpa-form-list-item-checklist.module';
import { DaaConsentAndEligibilityFormListItemComponent } from './daa-consent-and-eligibility-form-list-item.component';
import { DAAConsentAndEligibilityFormModule } from '../daa-consent-and-eligibility-form/daa-consent-and-eligibility-form.module';

@NgModule({
    declarations: [
      DaaConsentAndEligibilityFormListItemComponent
    ],
    imports: [
        CommonModule,
        SixCpaFormListItemChecklistModule,
        DAAConsentAndEligibilityFormModule
    ],
    exports: [
      DaaConsentAndEligibilityFormListItemComponent
    ]
})
export class DAAConsentAndEligibilityFormListItemModule { }