import { State, StateContext, Action, Selector } from '@ngxs/store';
import { Recipient } from '~/shared/models/communication/bulk-message.model';
import { EditNewBulkMessage, GetBulkMessageTemplates, MatchNumbers, NewBulkMessage, NewBulkMessageSuccess, ResetNewBulkMessage, SetNewBulkMessageRecipients, UpdateBulkMessage, UpdateBulkMessageSuccess } from './new-bulk-message-form.actions';
import { Injectable } from '@angular/core';
import { ResetForm, UpdateFormValue } from '@ngxs/form-plugin';
import { GetBulkMessagesUpcoming } from '../../state/bulk-messaging.actions';
import { catchError, finalize, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { BulkMessagingService } from '../../services/bulk-messaging.service';
import { CommTemplatesService } from '~/modules/pharmacy-comms/comm-templates/services/comm-templates.service';
import { CommTemplate, TemplateType } from '~/shared/models/pharmacy/comm-template.model';
import { messageIsProcessing } from '../helpers/date-helpers';
import { isEqual } from 'lodash';
import { NzNotificationService } from 'ng-zorro-antd/notification';


const MATCHING_MESSAGE = 'Matching number(s) to customer(s)...';
const ALERT_POSITION = 'topRight';

export interface BulkMessageForm {
  sendDate: any;
  message: string;
  recipients: Recipient[];
  chosenTemplate: any;
  manualNumber: string;
  recipientSearch: string;
  id: number;
  csvFile: string;
  tenantId: number;
}

export class NewBulkMessagingFormStateModel {
  newBulkMessageForm: {
    model: Partial<BulkMessageForm>;

  };
  isEditing: boolean;
  isLoadingMatches: boolean;
  loadingMessage: string;
  templates: CommTemplate[];
  loadingTemplates: boolean;
  originalMessageForm: BulkMessageForm
}

@Injectable()
@State<NewBulkMessagingFormStateModel>({
  name: 'newBulkMessageFormState',
  defaults: {
    newBulkMessageForm: {
      model: {
        message: '',
        recipients: [],
        recipientSearch: '',
        manualNumber: ''
      }
    },
    isEditing: false,
    isLoadingMatches: false,
    loadingMessage: '',
    templates: [],
    loadingTemplates: false,
    originalMessageForm: undefined
  }
})
export class NewBulkMessagingFormState {

  @Selector()
  static isDirty(state: NewBulkMessagingFormStateModel): boolean {
    // TODO: I have abused the form controls and state to hold extra info about the bulk message,
    // fix it by storing this info separately
    // consider nesting the following in their own form group, to help separate properties that can't be dirty
    // message
    //   recipients
    //   sendDate
    //   sendTime
    //   id
    // TODO: consider, if recipients are a form array, can use the dirty property on the form group instead of this selector
    return state.isEditing && !isEqual(state.originalMessageForm, state.newBulkMessageForm.model);
  }

  @Selector()
  static templates(state: NewBulkMessagingFormStateModel): CommTemplate[] {
    return state.templates;
  }

  constructor(
    private bulkMessagingService: BulkMessagingService,
    private commTemplateService: CommTemplatesService,
    private nzNotificationService: NzNotificationService
  ) { }

  @Action(SetNewBulkMessageRecipients)
  setBulkMessageRecipients(ctx: StateContext<NewBulkMessagingFormStateModel>, { recipients }: SetNewBulkMessageRecipients) {
    ctx.dispatch(new UpdateFormValue({
      path: 'newBulkMessageFormState.newBulkMessageForm',
      value: {
        recipients: recipients
      }
    }))
  }

  @Action(EditNewBulkMessage)
  editBulkMessage(ctx: StateContext<NewBulkMessagingFormStateModel>, { form }: EditNewBulkMessage) {
    ctx.patchState({ isEditing: true, originalMessageForm: form });
    ctx.dispatch(new UpdateFormValue({
      path: 'newBulkMessageFormState.newBulkMessageForm',
      value: {
        ...form
      }
    }))
  }

  @Action(ResetNewBulkMessage)
  resetBulkMessageForm(ctx: StateContext<NewBulkMessagingFormStateModel>, { }: ResetNewBulkMessage) {
    ctx.patchState({ isEditing: false });
    ctx.dispatch(new ResetForm({
      path: 'newBulkMessageFormState.newBulkMessageForm',
      value: {
        message: '',
        recipients: [],
        recipientSearch: '',
      }
    }))
  }

  @Action(NewBulkMessage)
  NewBulkMessage(ctx: StateContext<NewBulkMessagingFormStateModel>, { bulkMessage }: NewBulkMessage) {
    ctx.patchState({ isLoadingMatches: true });
    return this.bulkMessagingService.newBulkMessage(bulkMessage).pipe(
      tap(() => ctx.dispatch(new NewBulkMessageSuccess())),
      catchError(err => of(this.nzNotificationService.error('Error', err, { nzPlacement: ALERT_POSITION }))),
      finalize(() => ctx.patchState({ isLoadingMatches: false })));
  }

  @Action(UpdateBulkMessage)
  UpdateBulkMessage(ctx: StateContext<NewBulkMessagingFormStateModel>, { bulkMessage }: UpdateBulkMessage) {
    const originalMessage = ctx.getState().originalMessageForm;
    if (messageIsProcessing(originalMessage)) {
      alert('Cannot update message while it is being processed');
      return;
    }
    bulkMessage.recipients.forEach(r => {
      r.bulkScheduledMessageId = bulkMessage.id
    })
    ctx.patchState({ isLoadingMatches: true });
    return this.bulkMessagingService.updateBulkMessage(bulkMessage).pipe(
      tap(() => { ctx.dispatch(new UpdateBulkMessageSuccess()) }),
      catchError(err => of(this.nzNotificationService.error('Error', err, { nzPlacement: ALERT_POSITION }))),
      finalize(() => ctx.patchState({ isLoadingMatches: false })));
  }


  @Action(NewBulkMessageSuccess)
  newBulkMessageSuccess(ctx: StateContext<NewBulkMessagingFormStateModel>) {
    this.nzNotificationService.success('Success', 'The message was scheduled successfully', { nzPlacement: ALERT_POSITION });
    ctx.dispatch(new ResetNewBulkMessage());
    ctx.dispatch(new GetBulkMessagesUpcoming());
  }

  @Action(UpdateBulkMessageSuccess)
  updateBulkMessageSuccess(ctx: StateContext<NewBulkMessagingFormStateModel>) {
    this.nzNotificationService.success('Success', 'The message was updated successfully', { nzPlacement: ALERT_POSITION });
    ctx.dispatch(new ResetNewBulkMessage());
    ctx.dispatch(new GetBulkMessagesUpcoming());
  }

  @Action(MatchNumbers)
  matchNumbers(ctx: StateContext<NewBulkMessagingFormStateModel>, { numbers }: MatchNumbers) {
    ctx.patchState({ isLoadingMatches: true, loadingMessage: MATCHING_MESSAGE })
    const oldRecipients = ctx.getState().newBulkMessageForm.model.recipients;
    return this.bulkMessagingService.matchNumbers(numbers).pipe(
      tap((recipients) => {
        if (recipients != null && recipients.length) {
          ctx.dispatch(new SetNewBulkMessageRecipients([...oldRecipients, ...recipients]));
          //reset manual number field:
          ctx.dispatch(new UpdateFormValue({
            path: 'newBulkMessageFormState.newBulkMessageForm',
            value: {
              manualNumber: '',
            }
          }))
          if (recipients.every(r => r.clientId != null)) {
            this.nzNotificationService.success('Matches Found', `We successfully matched ${recipients.length} profile(s) to ${numbers.length} numbers`, { nzPlacement: ALERT_POSITION });
          } else {
            const count = recipients.filter(r => r.clientId == null)?.length
            this.nzNotificationService.warning('Number Mismatch', `There was no matched profile found one for ${count} number(s) entered`, { nzPlacement: ALERT_POSITION });
          }
        }
      }),
      catchError(err => of(this.nzNotificationService.error('Error', err, { nzPlacement: ALERT_POSITION }))),
      finalize(() => ctx.patchState({ isLoadingMatches: false, loadingMessage: '' })));
  }

  @Action(GetBulkMessageTemplates)
  getBulkMessageTemplates(ctx: StateContext<NewBulkMessagingFormStateModel>, { }: GetBulkMessageTemplates) {
    ctx.patchState({ loadingTemplates: true });
    return this.commTemplateService.get().pipe(
      tap((templates) => ctx.patchState({ templates: templates.filter(template => template.templateType == TemplateType.Custom) })),
      catchError(err => of(this.nzNotificationService.error('Error', err, { nzPlacement: ALERT_POSITION }))),
      finalize(() => ctx.patchState({ loadingTemplates: false })));
  }
}
