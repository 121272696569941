<form [formGroup]="newPharmacyForm">
  <div *ngFor="let formInput of formInputs" class="form-group">
    <ng-container *ngIf="formInput.type === 'select' && formInput.options; then formInput.options else defaultFormControl"></ng-container>
    <ng-template #defaultFormControl>
      <label for={{formInput.id}}>{{formInput.name}}</label>
      <input id={{formInput.id}}  formControlName={{formInput.id}} type={{formInput.type}} placeholder={{formInput.placeholder}} class="form-control">
    </ng-template>
  </div>
  <button *ngIf="!(loading$ | async) as loading" type="submit" [disabled]="newPharmacyForm.status === 'INVALID'" (click)="setupPharmacy()"
    class="btn btn-primary">Set up New Pharmacy</button>
  <ng-container *ngIf="(loading$ | async) as loading">
    <div *ngIf="loading" class="progress">
      <div class="progress-bar progress-bar-striped progress-bar-animated loading-width" role="progressbar" aria-valuenow="100"
        aria-valuemin="0" aria-valuemax="100"></div>
    </div>
  </ng-container>
  <ng-container *ngIf="(tenant$ | async) as tenant">
    <div class="form-group alert alert-primary" class="mt-10">
      <div class="mt-2">Name: {{tenant.name}}</div>
      <div class="mt-2">Connection: {{tenant.connection}}</div>
      <div class="mt-2">ECL TenantId: {{tenant.eclTenantId }}</div>
      <div class="mt-2">Timezone: {{tenant.timezone }}</div>
  </div>
  </ng-container>

  <ng-template #dispenseTypeFormControl>
    <label for="dispensetype">Dispense Type</label>
    <select *ngIf="(connectionTypes$ | async) as connectionTypes" class="form-control" id="dispensetype" formControlName="connectionType">
      <option *ngFor="let connectionType of connectionTypes" value={{connectionType.value}}>{{connectionType.name}}</option>
    </select>
  </ng-template>
  <ng-template #timezoneFormControl>
    <label for="timezone">Timezone</label>
    <select class="form-control" id="timezone" formControlName="timezone" >
      <option *ngFor="let timezone of timezones" value={{timezone.id}}>{{timezone.label}}</option>
    </select>
  </ng-template>
</form>