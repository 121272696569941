import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxsModule } from '@ngxs/store';
import { MedsCheckMedicationProfileComponent } from './meds-check-medication-profile.component';
import { MedsCheckMedicationProfileState } from '../state/meds-check-medication-profile.state';
import { SixCpaFormListItemChecklistModule } from '../../six-cpa-form-list-item-checklist/six-cpa-form-list-item-checklist.module';
import { MedicationProfileListItemModule } from '../../medication-profile/medication-profile-list-item/medication-profile-list-item.module';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
    declarations: [MedsCheckMedicationProfileComponent],
    imports: [
        CommonModule,
        NgxsModule.forFeature([
            MedsCheckMedicationProfileState
        ]),
        NgxsFormPluginModule,
        ReactiveFormsModule,
        FormsModule,
        SixCpaFormListItemChecklistModule,
        MedicationProfileListItemModule
    ],
    exports: [
        MedsCheckMedicationProfileComponent
    ]
})
export class MedsCheckMedicationProfileModule { }
