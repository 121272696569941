import { HttpClient } from '@angular/common/http';
import { Injectable, InjectionToken, Inject } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Router } from '@angular/router';
import { StorageService } from '../shared/core/storage/storage.service';
import { BaseApi } from '../shared/core/base-api/base-api';
import { AlertService } from '../modules/core/alert/alert.service';

export const CTRL_PATH: InjectionToken<string> = new InjectionToken('CTRL_PATH');
export const ENV: InjectionToken<string> = new InjectionToken('env');

@Injectable()
export class CrudService extends BaseApi {
  protected API:string;
  constructor(
    @Inject(ENV) protected env:any,
    protected http: HttpClient,
    protected router: Router,
    protected storageService: StorageService,
    protected alertService: AlertService,
    @Inject(CTRL_PATH) protected ctrlPath: string) {
    super(http, router, storageService, alertService);

    this.path = ctrlPath;
    this.API = env.baseApiUrl;
  }

  private path: string;

  getList(): Observable<any[]> {
    return this.get(`${this.API}/api/${this.path}`);
  }

  getById(id: string): Observable<any> {
    return this.get(`${this.API}/api/${this.path}/${id}`);
  }

  add(item: any): Observable<any> {
    return this.post(`${this.API}/api/${this.path}/add`, item);
  }

  update(item: any): Observable<any> {
    return this.put(`${this.API}/api/${this.path}/edit`, item);
  }

  remove(id: any) {
    return this.delete(`${this.API}/api/${this.path}/remove/${id}`);
  }
}
