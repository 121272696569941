<ng-container *ngIf="(dosageHistory$ | async) as dosageHistory">
  <h2>{{dosageHistory.script.name}}</h2>
  <div class="mt-3">
    <h3>Changes to Dosage</h3>
    <table class="table blade-table">
      <tr>
        <th>Created</th>
        <th>Change</th>
      </tr>
      <tr *ngFor="let record of dosageHistory.items">
        <td>{{record.createdOn | tenantDate}}</td>
        <ng-container *ngIf="record.type === dosageHistoryType.ADJUSTMENT; else dosage;">
          <td colspan="2">Adjusted by {{record.doseAdjustment}} doses</td>
        </ng-container>
        <ng-template #dosage>
          <td>Freq: {{record.frequency}} Dpd: {{record.dosesPerDay}}</td>
        </ng-template>
      </tr>
    </table>
  </div>
  <hr />
  <ng-container *ngIf="(currentScript$ | async) as currentScript">
    <div class="mt-3">
      <div class="d-flex">
        <h3>Starting Point Changes &nbsp; </h3>
        <app-scrypt-insight placement="bottom"
          content="This is a history of where we have started our days on hand calculations from. Each time you make an adjustment or change category, we start fresh from the DOH on that date for more accuracy."
          [context]="'context'">
        </app-scrypt-insight>
      </div>

      <table class="table blade-table mt-2">
        <tr>
          <th>Created</th>
          <th>Start Date</th>
          <th>DOH</th>
          <th>Active</th>
        </tr>
        <tr *ngFor="let ssp of currentScript.cMeta.stockStartingPoints | orderByActiveSSP"
          [ngClass]="ssp.id == currentScript?.cMeta?.currentStockStartingPoint?.id ? 'font-weight-bold':''">
          <td>{{ssp.createdOn | tenantDate}}</td>
          <td>{{ssp.startDate | tenantDate}}</td>
          <td [ngClass]="ssp.doh > 0 ? 'success':'danger'">{{ssp.doh}}</td>
          <td><i [ngClass]="ssp.active ? 'fas fa-check success': 'fas fa-times danger'"></i></td>
        </tr>
      </table>
    </div>
  </ng-container>
</ng-container>
