import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommsQueueTableComponent as CommsQueueTableComponent } from './comms-queue-table.component';
import { CdkTableModule } from '@angular/cdk/table';
import { TimezoneModule } from '../../../../modules/core/timezone/timezone.module';
import { ScryptInsightModule } from '../../../../shared/scrypt-insight/scrypt-insight.module';
import { TenantDatePipe } from '../../../../modules/core/timezone/pipes/tenant-date.pipe';
import { PaginationModule } from 'ngx-bootstrap';
import { RouterModule } from '@angular/router';
import { DateInFuturePipe } from '../pipes/date-in-future.pipe';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [CommsQueueTableComponent, DateInFuturePipe],
  imports: [
    CommonModule,
    CdkTableModule,
    TimezoneModule,
    ScryptInsightModule,
    PaginationModule.forRoot(),
    RouterModule,
    FormsModule
  ],
  providers:[TenantDatePipe],
  exports: [CommsQueueTableComponent]
})
export class CommsQueueTableModule { }
