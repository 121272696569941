import { ScryptSmsComponent } from './scrypt-sms.component';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgxsModule } from '@ngxs/store';
import { ScryptSmsState } from './state/scrypt-sms.state';
import { CommonModule } from '@angular/common';
import { SmsService } from '../../services/sms.service';
import { LoaderModule } from '../../../modules/core/loader/loader.module';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        NgxsModule.forFeature([ScryptSmsState]),
        LoaderModule
    ],
  exports: [
    ScryptSmsComponent
  ],
  declarations: [
    ScryptSmsComponent
  ],
  providers: [
    SmsService
  ]
})
export class ScryptSMSModule { }
