import { State, Selector, Action, StateContext } from '@ngxs/store';
import { SetClassificationAndRecommendationsProgress, UpdateClassificationAndRecommendationsForm } from './classification-and-recommendations.actions';
import { Injectable } from '@angular/core';

export class ClassificationAndRecommendationsStateModel {
  public totalItems: number;
  public completedItems: number;
  public classificationAndRecommendationsForm: {
    model: Partial<IClassificationAndRecommendationsStateModel>;
    status: string,
    errors: any
  };
}
export interface IClassificationAndRecommendationsStateModel {
  interventionCategory: number;
  interventionClassification: number;
  recommendations: {
    doseIncrease: boolean;
    doseDecrease: boolean;
    drugChange: boolean;
    drugFormulationChange: boolean;
    drugBrandChange: boolean;
    doseFrequencyScheduleChange: boolean;
    prescriptionNotDispensed: boolean;
    otherChangesToTherapy: boolean;
    referToPrescriber: boolean;
    referToHospital: boolean;
    referForMedicationReview: boolean;
    otherReferralRequired: boolean;
    educationOrCounsellingSession: boolean;
    writtenSummaryOfMedications: boolean;
    commenceDoseAdministrationAid: boolean;
    otherWrittenInformation: boolean;
    monitoringLaboratory: boolean;
    monitoringNonLaboratory: boolean;
    noRecommendationNecessary: boolean;
  };
  clinicalRecommendationsNotes: string;
}
@Injectable()
@State<ClassificationAndRecommendationsStateModel>({
  name: 'classificationAndRecommendations',
  defaults: {
    completedItems: 0,
    totalItems: 0,
    classificationAndRecommendationsForm: {
      model: undefined,
      status: '',
      errors: {}
    },
  }
})
export class ClassificationAndRecommendationsState {

  @Selector()
  static classificationAndRecommendationsForm(state: ClassificationAndRecommendationsStateModel) { return state.classificationAndRecommendationsForm.model; }

  @Selector()
  static totalItems(state: ClassificationAndRecommendationsStateModel) { return state.totalItems; }

  @Selector()
  static completedItems(state: ClassificationAndRecommendationsStateModel) { return state.completedItems; }

  @Action(SetClassificationAndRecommendationsProgress)
  setProgress(ctx: StateContext<ClassificationAndRecommendationsStateModel>, action: SetClassificationAndRecommendationsProgress) {
    ctx.patchState({
      totalItems: action.totalItems,
      completedItems: action.completedItems
    });
  }

  @Action(UpdateClassificationAndRecommendationsForm)
  UpdateForm(ctx: StateContext<ClassificationAndRecommendationsStateModel>, { form }: UpdateClassificationAndRecommendationsForm) {
    ctx.patchState({
      classificationAndRecommendationsForm: {
        model: {
          recommendations: form.recomendations,
          interventionCategory: form.interventionCategory,
          interventionClassification: form.interventionClassification,
          clinicalRecommendationsNotes: form.clinicalRecommendationsNotes
        },
        errors: null,
        status: null
      }
    });
  }
}
