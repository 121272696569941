import { State, Selector, Action, StateContext } from '@ngxs/store';
import { IHMRProposedDetailsFormStateModel } from './hmr-proposed-details-form-state-model.interface';
import { UpdateHMRProposedDetailsForm } from './hmr-proposed-details-form.actions';
import * as moment_ from 'moment';
const moment = moment_;
import { Injectable } from '@angular/core';

export interface IHMRProposedDetailsFormDBasicStateModel extends IHMRProposedDetailsFormStateModel  {
  termsAndConditions: boolean | null;
}

  export class HMRProposedDetailsFormBasicStateModel {
    proposedDetailsForm: {
      model: Partial<IHMRProposedDetailsFormDBasicStateModel>;
    };
  }

  @State<HMRProposedDetailsFormBasicStateModel>({
    name: 'hmrProposedDetailsFormBasic',
    defaults: {
      proposedDetailsForm: {
        model: undefined
      }
    }
  })
  @Injectable()
  export class HMRProposedDetailsFormBasicState {
    @Selector()
    static form(state: HMRProposedDetailsFormBasicStateModel) { return state.proposedDetailsForm.model; }


    @Action(UpdateHMRProposedDetailsForm)
    updateForm(ctx: StateContext<HMRProposedDetailsFormBasicStateModel>, { form }: UpdateHMRProposedDetailsForm) {
      if (form) {
        ctx.patchState({
          proposedDetailsForm: {
            model: {
              proposedDateOfInterview: form.proposedDateOfInterview && moment(form.proposedDateOfInterview, 'YYYY-MM-DD').format('DD-MM-YYYY'),
              termsAndConditions: form.termsAndConditions
            }
          }
        });
      } else {
        ctx.patchState({
          proposedDetailsForm: {
            model: undefined
          }
        });
      }

    }
  }
