import { State, Selector, Action, StateContext } from '@ngxs/store';
import * as moment_ from 'moment';
const moment = moment_;
import { IDAAPatientDetailsFormStateModel } from './daa-patient-details-form-state-model.interface';
import { SetProfileForSixCpaAction } from '../../../state/six-cpa.actions';
import { CurrentLivingStatus } from '../../../../../../shared/models/six-cpa/consent-and-eligibility-form.model';
import { UpdateDAAPatientDetailsForm } from './daa-patient-details-form.actions';
import { TenantDatePipe } from '../../../../../core/timezone/pipes/tenant-date.pipe';
import { getMedicareDVAOrRepatNumber } from '../../../../../../shared/helpers/patient.helper';
import { Injectable } from '@angular/core';

export interface IDAAPatientDetailsFormDetailedStateModel extends IDAAPatientDetailsFormStateModel {
  gender: string;
  residentialPostCode: string;
  concessionCard: boolean;
  patientDOB: Date | string;
  livingStatus: CurrentLivingStatus;
}

export class DAAPatientDetailsFormDetailedStateModel {
  patientDetailsForm: {
    model: Partial<IDAAPatientDetailsFormDetailedStateModel>;
  };
}
@Injectable()
@State<DAAPatientDetailsFormDetailedStateModel>({
  name: 'daaPatientDetailsFormDetailed',
  defaults: {
    patientDetailsForm: {
      model: undefined
    }
  }
})
export class DAAPatientDetailsFormDetailedState {
  constructor(private tenantPipe: TenantDatePipe) {}
  @Selector()
  static form(state: DAAPatientDetailsFormDetailedStateModel) { return state.patientDetailsForm.model; }

  @Action(SetProfileForSixCpaAction)
  onSetProfile(ctx: StateContext<DAAPatientDetailsFormDetailedStateModel>, { profile }: SetProfileForSixCpaAction) {
    const patient = profile.patient;
    ctx.patchState({
      patientDetailsForm: {
        model: {
          patientDOB: patient.birthDate && this.tenantPipe.transform(patient.birthDate.toString(), 'DD-MM-YYYY'),
          medicareNumber: getMedicareDVAOrRepatNumber(patient),
          gender: (patient.gender !== 'M' && patient.gender !== 'F') ? null : patient.gender,
          residentialPostCode: patient.homePostcode ?  patient.homePostcode.toString() : null
        }
      }
    });
  }

  @Action(UpdateDAAPatientDetailsForm)
  updateForm(ctx: StateContext<DAAPatientDetailsFormDetailedStateModel>, { form }: UpdateDAAPatientDetailsForm) {
    ctx.patchState({
      patientDetailsForm: {
        model: {
          patientDOB: form.dob && moment(form.dob, 'YYYY-MM-DD').format('DD-MM-YYYY'),
          medicareNumber: form.medicareNumber,
          gender: form.gender,
          residentialPostCode: form.postCode,
          livingStatus: form.currentLivingStatus,
          concessionCard: form.concessionCard
        }
      }
    });
  }
}
