import { RouterModule, Routes } from "@angular/router";
import { OrderManagementComponent } from "./order-mgmt.component";
import { NgModule } from "@angular/core";

const routes: Routes = [
    {
      path: '',
      component: OrderManagementComponent
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
  })
  
  export class OrderManagementRoutingModule {
  }