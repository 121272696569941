import { State, Selector, StateContext, Action, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { CloseMobileStateModal, DeleteInvitesFailed, DeleteInvitesSuccessful, DeleteMobileInvites, DeleteMobileUser, DeleteUserFailed, DeleteUserSuccessful, OpenMobileStateModal } from './mobile-state-modal.actions';
import { catchError, finalize, tap } from 'rxjs/operators';
import { AuthService } from '@services/auth.service';
import { AlertService,  } from '../../../../core/alert/alert.service';
import { ProfileState } from '../../../../core/profile/state/profile.state';
export enum MobileState {
  Inactive,
  Pending,
  Active
}

export class MobileInfo {
  constructor(
    public mobileState: MobileState,
    public mobileInviteDate: Date,
    public mobileSignupDate: Date,
    public inviteEmail: string,
    public inviteMobileNumber: string
  ){}
}

export class MobileStateModalStateModel {
  showModal: boolean;
  state: MobileInfo;
  deleting: boolean;
}
@Injectable()
@State<MobileStateModalStateModel>({
  name: 'mobileStateModal',
  defaults: {
    showModal: false,
    state: null,
    deleting: false
  }
})
export class MobileStateModalState {

  constructor(
    public authService: AuthService,
    public store: Store,
    public alertService: AlertService) {}

  @Selector()
  static showModal(state: MobileStateModalStateModel) { return state.showModal };

  @Selector()
  static state(state: MobileStateModalStateModel) { return state.state };

  @Selector()
  static deleting(state: MobileStateModalStateModel) { return state.deleting };

  @Action(OpenMobileStateModal)
  openMobileStatusModal(ctx: StateContext<MobileStateModalStateModel>, { info }: OpenMobileStateModal) {
    ctx.patchState({ showModal: true, state: info })
  }

  @Action(CloseMobileStateModal)
  closeMobileStatusModal(ctx: StateContext<MobileStateModalStateModel>, { }: CloseMobileStateModal) {
    ctx.patchState({ showModal: false })
  }

  @Action(DeleteMobileUser)
  deleteMobileUser(ctx: StateContext<MobileStateModalStateModel>, { }: DeleteMobileUser) {
    ctx.patchState({ deleting: true })
    const customerId = this.store.selectSnapshot(ProfileState.customerProfileId);
    return this.authService.deleteUser(customerId).pipe(
      tap(response => ctx.dispatch(new DeleteUserSuccessful())),
      catchError(err => ctx.dispatch(new DeleteUserFailed(err))),
      finalize(() => { ctx.patchState({ deleting: false }) })
    );
  }

  @Action(DeleteUserSuccessful)
  deleteSuccessful(ctx: StateContext<MobileStateModalStateModel>, {  }: DeleteUserSuccessful) {
    this.alertService.success(`Users mobile account has been deleted`);
    ctx.patchState({ showModal: false })
  }

  @Action(DeleteUserFailed)
  deleteFailed(ctx: StateContext<MobileStateModalStateModel>, { error }: DeleteUserFailed) {
    this.alertService.error(error.error.toString());
  }

  @Action(DeleteMobileInvites)
  deleteInvites(ctx: StateContext<MobileStateModalStateModel>, { }: DeleteMobileInvites) {
    ctx.patchState({ deleting: true })
    const customerId = this.store.selectSnapshot(ProfileState.customerProfileId);
    return this.authService.deleteInvites(customerId).pipe(
      tap(response => ctx.dispatch(new DeleteInvitesSuccessful())),
      catchError(err => ctx.dispatch(new DeleteInvitesFailed(err))),
      finalize(() => { ctx.patchState({ deleting: false }) })
    );
  }

  @Action(DeleteInvitesSuccessful)
  deleteInvitesSuccessful(ctx: StateContext<MobileStateModalStateModel>, {  }: DeleteInvitesSuccessful) {
    this.alertService.success(`Mobile invites have been revoked`);
    ctx.patchState({ showModal: false })
  }

  @Action(DeleteInvitesFailed)
  deleteInvitesFailed(ctx: StateContext<MobileStateModalStateModel>, { error }: DeleteInvitesFailed) {
    this.alertService.error(error.error.toString());
  }
}
