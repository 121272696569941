import { Component, OnInit } from '@angular/core';
import { SSOutcomesAndActionsFormState } from '../ss-outcomes-and-actions-form/state/ss-outcomes-and-actions-form.state';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-ss-outcomes-and-actions-form-list-item',
  templateUrl: './ss-outcomes-and-actions-form-list-item.component.html'
})
export class SSOutcomesAndActionsFormListItemComponent implements OnInit {
  @Select(SSOutcomesAndActionsFormState) outcomesAndActionsForm$: Observable<any>;
  constructor() { }

  ngOnInit() {
  }

}
