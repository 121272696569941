export class SmsMessageHistory {
  constructor(
    public id: string,
    public author: string,
    public messageText: string,
    public date: string,
    public creationSource: MessageSource,
    public phoneNumber: string,
    public attachments?: Attachment[]
  ) { }
}

export class MessengerHistory {
  constructor(
    public tenantId: number,
    public message: string,
    public read: boolean,
    public timestamp: string,
    public sentFrom: FirebaseMessageSource,
    public attachments: string[]
  ) { }
}

export class Attachment {
  id: number;
  messageId: number;
  contentType: string;
  contentLink: string;
}

export enum MessageSource {
  Unknown,
  Customer,
  ScheduledMessage,
  RecurringMessage,
  User,
  System,
  BulkCommunications,
  NewCustomerWelcomeMessage,
  Survey
}

export enum FirebaseMessageSource {
  Client = 'client',
  Pharmacy = 'pharmacy'
}
