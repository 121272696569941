import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { AuthSetEncryptedDocumentId } from './state/document-services.actions';

@Injectable()
export class DocumentResolver implements Resolve<boolean> {
  constructor(private store: Store) {}
  resolve(route: ActivatedRouteSnapshot) {
    const { document } = route.queryParams;
    if (document) {
      this.store.dispatch(new AuthSetEncryptedDocumentId(document));
    }
    return true;
  }
}
