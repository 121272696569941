import { Medication } from '../../../../shared/models/script/chartviewitem.model';

export enum CategoryActionTypes {
  SET_CATEGORIES = '[Categories] Set',
  GET_CATEGORIES_ERROR = '[Categories] Error',
  REFRESH_CATEGORIES = '[Catgeories] Refresh',
  SCRIPT_LOADING_START = '[Categories] Script loading start',
  SCRIPT_LOADING_STOP = '[Categories] Script loading stop',
  UPDATE_SCRIPT = '[Categories] Update script',
  TOGGLE_CATEGORY = '[Categories] Toggle category',
  SET_SELECTED_DRUGS_PAUSE_STATUS = '[Categories] Set Pause status for multiple drugs',
  OPEN_CATEGORY = '[Categories] Open category',
  RESET_SCRIPTS = '[Categories] Reset scripts',
  SCRIPTS_LOADING_START = '[Categories] Scripts loading start',
  SCRIPTS_LOADING_STOP = '[Categories] Scripts loading stop'
}

export class SetCategoriesAction {
  static readonly type = CategoryActionTypes.SET_CATEGORIES;
  constructor(public categories: any[]) { }
}

export class GetCategoriesErrorAction {
  static readonly type = CategoryActionTypes.GET_CATEGORIES_ERROR;
  constructor(public error: any) {}
}

export class RefreshCategoriesAction {
  static readonly type = CategoryActionTypes.REFRESH_CATEGORIES;
  constructor(public clientId: string) { }
}

export class UpdateScriptAction {
  static readonly type = CategoryActionTypes.UPDATE_SCRIPT;
  constructor(public script: Medication) {}
}

export class ScriptLoadingStartAction {
  static readonly type = CategoryActionTypes.SCRIPT_LOADING_START;
  constructor(public script: any) {}
}

export class ScriptLoadingStopAction {
  static readonly type = CategoryActionTypes.SCRIPT_LOADING_STOP;
  constructor(public script: Medication) {}
}

export class ScriptsLoadingStartAction {
  static readonly type = CategoryActionTypes.SCRIPTS_LOADING_START;
  constructor(public scripts: any[]) {}
}
export class ScriptsLoadingStopAction {
  static readonly type = CategoryActionTypes.SCRIPTS_LOADING_STOP;
  constructor(public scripts: any[]) {}
}

export class ToggleCategoryAction {
  static readonly type = CategoryActionTypes.TOGGLE_CATEGORY;
  constructor(public categoryId: number) {}
}

export class OpenCategoryAction {
  static readonly type = CategoryActionTypes.OPEN_CATEGORY;
  constructor(public categoryId: number) {}
}

  export class SetSelectedDrugsPausedStatus {
  static readonly type = CategoryActionTypes.SET_SELECTED_DRUGS_PAUSE_STATUS;
  constructor(public status: any) { }
}


export class ResetScriptsAction {
  static readonly type = CategoryActionTypes.RESET_SCRIPTS;
  constructor(public scripts: any[]) {}
}
