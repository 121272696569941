import { HttpErrorResponse } from '@angular/common/http';
import { BatchDispenseRequestModel } from '@base/shared/models/dispense/batchDispenseRequest.model';

export enum BulkDispenseActionTypes {
  BULK_DISPENSE = '[Bulk Dispense] Request',
  BULK_DISPENSE_FAILED = '[Bulk Dispense] Failed',
  BULK_DISPENSE_SUCCESS = '[Bulk Dispense] Success'
}

export class RequestBulkDispense {
  static readonly type = BulkDispenseActionTypes.BULK_DISPENSE;
  constructor(public request: BatchDispenseRequestModel) {}
}

export class BulkDispenseFailed {
  static readonly type = BulkDispenseActionTypes.BULK_DISPENSE_FAILED;
  constructor(public error: HttpErrorResponse) {}
}

export class BulkDispenseSuccess {
  static readonly type = BulkDispenseActionTypes.BULK_DISPENSE_SUCCESS;
  constructor() {}
}
