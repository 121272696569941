import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { Store } from '@ngxs/store';
import { ResetMedsCheckFormStateToDefaults } from '../../profile/six-cpa/meds-check/state/meds-check-form-list.actions';
import { ResetCIToDefaults } from '../../profile/six-cpa/clinical-intervention/state/clinical-intervention.actions';
import { ResetStagedSupplyFormStateToDefaults } from '../../profile/six-cpa/Staged-Supply/staged-supply/state/staged-supply-form-list.actions';


@Injectable()
export class SixCpaResolver implements Resolve<Observable<any>> {
  constructor(private store: Store) { }
  resolve() {
    return this.store.dispatch([
      new ResetMedsCheckFormStateToDefaults(),
      new ResetCIToDefaults(),
      new ResetStagedSupplyFormStateToDefaults(),
    ]);
  }
}
