import { ToggleBladeAction } from '../../../../core/blade/state/blade.actions';
import { Medication } from '../../../../../shared/models/script/chartviewitem.model';
import { StockStartingPointModel } from '../../../../../shared/models/script/meta-view-model';
import { UpdateScriptNotificationsRequest } from '../../../../../shared/models/script/update-script-notifications-request.model';

export const MANAGE_META_BLADE_NAME = '[Manage Meta]';

export enum ManageMetaActionTypes {
  OPEN = '[Manage Meta] Open',
  MANAGE_META_REQUEST = '[Manage Meta] Request',
  MANAGE_META_RESPONSE = '[Manage Meta] Response',
  UPDATE_START_DATE_REQUEST = '[Manage Meta] Update start date request',
  UPDATE_START_DATE_RESPONSE = '[Manage Meta] Update start date response',
  UPDATE_START_DATE_ERROR = '[Manage Meta] Update start date error',
  MANAGE_META_ERROR = '[Manage Meta] Error',
  CLOSE = '[Manage Meta] Close',
  UPDATE_NOTIFICATIONS = '[Manage Meta] Update Notifications',
  UPDATE_NOTIFICATIONS_SUCCESS = '[Manage Meta] Update Notifications Success',
  UPDATE_NOTIFICATIONS_ERROR = '[Manage Meta] Update Notyifications Error',
  TOGGLE_AUTO_COMMS_MODAL = '[Manage Meta] Toggle Auto Comms Modal',
  ADD_NEW_STOCK_STARTING_POINT = '[Manage Meta] ADD_NEW_STOCK_STARTING_POINT',
  ADD_NEW_STOCK_STARTING_POINT_SUCCESS = '[Manage Meta] ADD_NEW_STOCK_STARTING_POINT_SUCCESS'

}

export class OpenManageMetaAction extends ToggleBladeAction {
  static readonly type = ManageMetaActionTypes.OPEN;
  constructor() { super(MANAGE_META_BLADE_NAME); }
}

export class ManageMetaRequestAction {
  static readonly type = ManageMetaActionTypes.MANAGE_META_REQUEST;
  constructor(public script: Medication) {}
}

export class ManageMetaResponseAction {
  static readonly type = ManageMetaActionTypes.MANAGE_META_RESPONSE;
  constructor(public script: any) {}
}

export class ManageMetaErrorAction {
  static readonly type = ManageMetaActionTypes.MANAGE_META_ERROR;
  constructor(public error: any, public script: Medication) {}
}

export class UpdateStartDateRequestAction {
  static readonly type = ManageMetaActionTypes.UPDATE_START_DATE_REQUEST;
  constructor(public script: Medication) {}
}

export class UpdateStartDateResponseAction {
  static readonly type = ManageMetaActionTypes.UPDATE_START_DATE_RESPONSE;
  constructor(public script: Medication) {}
}

export class UpdateStartDateErrorAction {
  static readonly type = ManageMetaActionTypes.UPDATE_START_DATE_ERROR;
  constructor(public error: any, public script: Medication) {}
}

export class CloseManageMetaAction extends ToggleBladeAction {
  static readonly type = ManageMetaActionTypes.CLOSE;
  constructor() { super(MANAGE_META_BLADE_NAME); }
}

export class UpdateScriptNotifications{
  static readonly type = ManageMetaActionTypes.UPDATE_NOTIFICATIONS;
  constructor(public notificationsRequest: UpdateScriptNotificationsRequest) { }
}

export class UpdateScriptNotificationsSuccess {
  static readonly type = ManageMetaActionTypes.UPDATE_NOTIFICATIONS_SUCCESS;
  constructor(public notificationsRequest: UpdateScriptNotificationsRequest) { }
}

export class UpdateScriptNotificationsError {
  static readonly type = ManageMetaActionTypes.UPDATE_NOTIFICATIONS_ERROR;
  constructor(public error: any) { }
}

export class ToggleAutoCommsModalAction {
  static readonly type = ManageMetaActionTypes.TOGGLE_AUTO_COMMS_MODAL;
  constructor(public show: boolean) { }
}

export class AddNewStockStartingPoint {
  static readonly type = ManageMetaActionTypes.ADD_NEW_STOCK_STARTING_POINT;
  constructor(public stockStartingPoint: StockStartingPointModel) { }
}

export class AddNewStockStartingPointSuccess {
  static readonly type = ManageMetaActionTypes.ADD_NEW_STOCK_STARTING_POINT_SUCCESS;
  constructor(public stockStartingPoint: StockStartingPointModel) { }
}
