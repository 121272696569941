import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HMRConsentAndEligibilityListItemComponent } from './hmr-consent-and-eligibility-list-item.component';
import { SixCpaFormListItemChecklistModule } from '../../six-cpa-form-list-item-checklist/six-cpa-form-list-item-checklist.module';
import { HMRConsentAndEligibilityModule } from '../hmr-consent-and-eligibility/hmr-consent-and-eligibility.module';

@NgModule({
  declarations: [HMRConsentAndEligibilityListItemComponent],
  imports: [
    CommonModule,
    SixCpaFormListItemChecklistModule,
    HMRConsentAndEligibilityModule,

  ],
  exports: [HMRConsentAndEligibilityListItemComponent]

})
export class HMRConsentAndEligibilityListItemModule { }
