import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SentMessagesStatusBarComponent } from './sent-messages-status-bar.component';
import { NzProgressModule } from 'ng-zorro-antd/progress';

@NgModule({
  declarations: [
    SentMessagesStatusBarComponent
  ],
  imports: [
    CommonModule,
    NzProgressModule
  ],
  exports: [
    SentMessagesStatusBarComponent
  ]
})
export class SentMessagesStatusBarComponentModule { }
