import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DispenseOverideConfirmationModalComponent } from './dispense-overide-confirmation-modal.component';

@NgModule({
  declarations: [
    DispenseOverideConfirmationModalComponent
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    DispenseOverideConfirmationModalComponent
  ]
})
export class DispenseOverideConfirmationModalModule { }
