import { BaseApi } from './base-api';
import { HttpClientModule } from '@angular/common/http';
import { StorageModule } from '../storage/storage.module';
import { NgModule } from '@angular/core';
import { AlertModule } from '~/modules/core/alert/alert.module';

@NgModule({
    providers: [
        BaseApi
    ],
    imports: [
        AlertModule,
        HttpClientModule,
        StorageModule
    ],
})
export class AbstractBaseApiModule {}
