<section class="note-list border__grey" g-xs="mt4">
  <div class="flex note-list__title" g-xs="pt2 px3 pb1">
    <div class="font__title-3">Requires Action</div>
    <i class="fas fa-sort-up color__blue-text font__title-5" g-xs="pt1"></i>
  </div>
  <div class="table-responsive scrollable-div" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50"
    [scrollWindow]="false" (scrolled)="onScroll()">

    <div class="filter-loading-spinner" *ngIf="!isScrollNextLoaded || !isLoaded">
      <section>
        <app-loader></app-loader>
      </section>
    </div>

    <table class="table note-list__table">
      <thead>
        <tr class="bg__light-yellow-table">
          <th class="mw110" (click)="sort('nextActionDate')">Due Date
            <i class="fas" g-xs="ml1" [ngClass]="getArrow('nextActionDate')" aria-hidden="true"> </i>
          </th>
          <th (click)="sort('notificationType')">Type
            <i class="fas" g-xs="ml1" [ngClass]="getArrow('notificationType')" aria-hidden="true"> </i>
          </th>
          <th (click)="sort('patient')">Patient
            <i class="fas" g-xs="ml1" [ngClass]="getArrow('patient')" aria-hidden="true"> </i>
          </th>
          <th>Note</th>
          <th g-xs="text:right">
            Select All
            <input type="checkbox" g-xs="ml2 mt1" (change)="selectAll($event)"
              [checked]="selectedNotes.length == noteList.length" />
          </th>
        </tr>
      </thead>
      <tbody>
        <tr class="clickable" *ngFor="let item of noteList | appSort: args" (click)="navigate(item)">
          <td class="font__bold">
            <app-notification-date [date]="item.nextActionDate" [format]="dateTimeFormat(item)"></app-notification-date>
          </td>
          <td>
            <a [href]="item | appNoteTypeLink" class="color__darkest">
              <span [innerHTML]="item.notificationType | appNoteTypeIcon: noteTypeList"></span>
              {{item.notificationType}}
            </a>
          </td>
          <td>
            <a routerLink="/profile/{{item.clientId}}" class="color__darkest">{{item.patient?.firstname}}
              {{item.patient?.surname}}</a>
          </td>
          <td class="note-list__body font__title-0">
            <a [href]="item | appNoteTypeLink" class="color__darkest">{{item.body?.substring(0,300)}}
            </a>
          </td>
          <td (click)="$event.stopPropagation();">
            <app-note-actions [note]="item" (clickTick)="actionNote($event)" (clickEdit)="setModifiedNote(item)"
              (clickRemove)="removeNote(item)" (clickLink)="goToLink(item)" [checked]="setChecked(item)"
              (selectChanged)="selectNote($event)"></app-note-actions>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="selected-items" g-xs="px3 pt3">
    <i class="fas fa-chevron-right close font__title-3" (click)="showSelectedPopup = toggle(showSelectedPopup)"></i>
    <div g-xs="mr1">
      <div class="font__title-0 font__bold">Items Selected</div>
      <div class="font__title-6 color__green">{{selectedNotes.length}}</div>
    </div>
    <div [@hideAnimation]="showSelectedPopup">
      <button g-xs="mt3" class="btn__green selected-items__complete color__dark-blue"
        (click)="completeNoteList()">Complete Task
        <i class="far fa-check" g-xs="ml2"></i>
      </button>
      <button g-xs="mt3" class="btn__yellow selected-items__complete color__dark-blue" (click)="snoozeNoteList()">
        Snooze Selected
        <i class="far fa-check" g-xs="ml2"></i>
      </button>
      <button class="btn__img color__dark-blue" g-xs="mt1 mb3" (click)="removeNoteList()">
        <span class="font__underline font__bold">Delete</span>
        <i class="far fa-trash-alt"></i>
      </button>
    </div>
  </div>
  <section>
    <app-note-settings [noteList]="modifiedNotes" (closeEvent)="clearModifiedNote()"></app-note-settings>
  </section>

  <app-fluid-modal *ngIf="confirmOrderCycleRemoval"
    (submitEvent)="confirmRemoval()"
    (closeEvent)="dismiss()"
    (cancelEvent)="dismiss()"
    submitText="Yes, delete Order Cycles"
    closeText="No, go back"
    title="Order Cycle notes included in bulk selection"
    message="Are you sure you wish to delete selected Order Cycles instead of actioning? Deleting will inactivate all future reminders for these patient(s)">
  </app-fluid-modal>

  <app-fluid-modal *ngIf="confirmOrderCycleReset" (submitEvent)="confirmCycleReset()" (closeEvent)="confirmMaintainCycle()" (cancelEvent)="dismiss()"
  showCancelButton="true"
    submitText="Yes (Next due date in {{currentNoteToAction.intervalValue}} days)"
    closeText="No (Next due date in {{daysUntilNextDueDate(currentNoteToAction)}} days)" title="Confirm removal of order cycle"
    message="This order cycle has been completed {{modalText}} the due date. Would you like to update the order cycle from today?">
  </app-fluid-modal>

</section>
