<div class="note-list__control">
  <button class="btn__img" [disabled]="!canTick" *ngIf="!isHiddenTick" (click)="clickedTick()">
    <i class="fas fa-check color__green"></i>
  </button>
  <button class="btn__img" *ngIf="canLink" (click)="clickedLink()">
    <i class="fas fa-chevron-right color__dark-blue"></i>
  </button>
  <button class="btn__img">
    <i [ngClass]="note.type == noteTypeEnum.eScript ? 'fas fa-arrow-square-up color__blue-text' : 'fas fa-cog color__dark-blue'" (click)="clickedEdit()"></i>
  </button>
  <button class="btn__img" (click)="clickedEdit()">
      <i class="fas fa-history color__yellow"  ></i>
  </button>
  <button class="btn__img" (click)="clickedDelete()">
    <i class="far fa-trash-alt color__red"></i>
  </button>
  <input type="checkbox" [disabled]="!canSelect" (change)="selectChange($event)" [checked]="checked" />
</div>
