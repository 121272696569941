import { HMRLocationFormModel } from '~/shared/models/six-cpa/HMR/hmr-location-form.model';

export enum HMRLocationActions {
  SET_VALID = '[HMRLocation] set valid',
  UPDATE = '[HMRLocation] Update Form',
  UPDATE_PROGRESS = '[HMRLocation] Update Progress'
}

export class SetHMRLocationValidAction {
  static readonly type = HMRLocationActions.SET_VALID;
  constructor(public valid = false) { }
}

export class SetTotalItemsAndCompletedItems {
  static readonly type = HMRLocationActions.UPDATE_PROGRESS;
  constructor(public totalItems, public completedItems) { }
}

export class UpdateHMRLocation {
  static readonly type = HMRLocationActions.UPDATE;
  constructor(public form: Partial<HMRLocationFormModel>) { }
}
