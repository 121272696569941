import { PatientDetailsFormModel } from '~/shared/models/six-cpa/patient-details-form.model';

export enum SSPatientDetailsFormActions {
  UPDATE_PROGRESS = '[SSPatientDetailsForm] Update Progress',
  UPDATE = '[SSPatientDetailsForm] Update'
}

export class SetTotalItemsAndCompletedItems {
  static readonly type = SSPatientDetailsFormActions.UPDATE_PROGRESS;
  constructor(public totalItems: number, public completedItems: number) { }
}

export class UpdateStagedSupplyPatientDetailsForm {
  static readonly type = SSPatientDetailsFormActions.UPDATE;
  constructor(public form: Partial<PatientDetailsFormModel>) { }
}
