<form>
  <div class="form-group">
    <label for="" class="input-label">Sign Here: </label> <br />
    <div class="signature">
      <signature-pad [options]="signaturePadOptions" (onEndEvent)="drawComplete()">
      </signature-pad>
    </div>
  </div>
  <button class="btn__yellow" type="button" (click)="clearSignature()">Clear</button>
</form>
