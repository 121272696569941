import { State, Action, StateContext, Selector } from '@ngxs/store';
import { SickLeavePharmacistDeclarationFormModel } from '../../../../../../shared/models/six-cpa/sick-leave/sick-leave-pharmacist-declaration-form.model';
import { SetSickLeavePharmacistDeclarationValidAction, SetTotalItemsAndCompletedItems, UpdateSickLeavePharmacistDeclaration } from './sick-leave-pharmacist-declaration.actions';
import * as moment_ from 'moment';
const moment = moment_;
import { Injectable } from '@angular/core';

export class SickLeavePharmacistDeclarationStateModel {
  sickLeavePharmacistDeclarationForm: {
    model: Partial<SickLeavePharmacistDeclarationFormModel>
  };
  valid: boolean;
  totalItems: number;
  completedItems: number;
}
@Injectable()
@State<SickLeavePharmacistDeclarationStateModel>({
  name: 'sickLeavePharmacistDeclaration',
  defaults: {
    sickLeavePharmacistDeclarationForm: {
      model: undefined
    },
    valid: false,
    completedItems: 0,
    totalItems: 0
  }
})
export class SickLeavePharmacistDeclarationState {
  @Selector()
  static sickLeavePharmacistDeclarationForm(state: SickLeavePharmacistDeclarationStateModel) { return state.sickLeavePharmacistDeclarationForm.model; }

  @Selector()
  static totalItems(state: SickLeavePharmacistDeclarationStateModel) { return state.totalItems; }

  @Selector()
  static completedItems(state: SickLeavePharmacistDeclarationStateModel) { return state.completedItems; }

  @Action(SetSickLeavePharmacistDeclarationValidAction)
  setValid(ctx: StateContext<SickLeavePharmacistDeclarationStateModel>, action: SetSickLeavePharmacistDeclarationValidAction) {
    ctx.patchState({
      valid: action.valid
    });
  }

  @Action(SetTotalItemsAndCompletedItems)
  setProgress(ctx: StateContext<SickLeavePharmacistDeclarationStateModel>, action: SetTotalItemsAndCompletedItems) {
    ctx.patchState({
      totalItems: action.totalItems,
      completedItems: action.completedItems
    });
  }

  @Action(UpdateSickLeavePharmacistDeclaration)
  updateForm(ctx: StateContext<SickLeavePharmacistDeclarationStateModel>, { form }: UpdateSickLeavePharmacistDeclaration) {
    if (form) {
      ctx.patchState({
        sickLeavePharmacistDeclarationForm: {
          model: {
            id: form.id,
            dateOfService: moment(form.dateOfService).toDate(),
            haveDeliveredService: form.haveDeliveredService,
            pharmacistGivenName: form.pharmacistGivenName,
            pharmacistFamilyName: form.pharmacistFamilyName,
            ahpraNumber: form.ahpraNumber
          }
        }
      });
    } else {
      ctx.patchState({
        sickLeavePharmacistDeclarationForm: {
          model: undefined
        }
      });
    }

  }
}
