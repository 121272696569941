import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store, Select } from '@ngxs/store';
import { DosageHistoryStateModel, DosageHistoryState, DosageHistoryType } from './state/dosage-history.state';
import { Observable, Subscription } from 'rxjs';
import { InitDosageHistoryAction } from './state/dosage-history.actions';
import { ScriptActionsState } from '../state/script-actions.state';
@Component({
  selector: 'app-dosage-history',
  templateUrl: './dosage-history.component.html',
  styleUrls: ['./dosage-history.component.scss']
})
export class DosageHistoryComponent implements OnInit, OnDestroy {
  @Select(DosageHistoryState) dosageHistory$: Observable<DosageHistoryStateModel>;
  @Select(ScriptActionsState.script) currentScript$: Observable<any>;
  private scriptSubscription: Subscription;
  dosageHistoryType = DosageHistoryType;

  constructor(private store: Store) { }

  ngOnInit() {
    this.scriptSubscription = this.store.select(ScriptActionsState.script)
      .subscribe((script) => this.store.dispatch(new InitDosageHistoryAction(script)));
  }

  ngOnDestroy() {
    this.scriptSubscription.unsubscribe();
  }
}
