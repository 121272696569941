import { State, Action, StateContext, Selector } from '@ngxs/store';
import {
  GetCommsQueueAction,
  GetCommsQueueSuccessAction,
  CommsQueueFailedAction,
  SortCommsQueueAction,
  ToggleShowMessageModalAction,
  ToggleShowTemplateModalAction
} from './comms-queue.actions';
import { CommsQueueService } from '../services/comms-queue.service';
import { tap, catchError } from 'rxjs/operators';
import { CommsQueueItem } from '../models/comms-queue-item.model';
import { CommTemplate } from '../../../../shared/models/pharmacy/comm-template.model';
import { Injectable } from '@angular/core';

export class CommQueueStateModel {
  commsQueueItems: CommsQueueItem[];
  error: any;
  loading: boolean;
  showMessageModal: CommsQueueItem;
  showTemplateModal: CommTemplate;
}

@Injectable()
@State<CommQueueStateModel>({
  name: 'commsQueue',
  defaults: {
    commsQueueItems: [],
    error: null,
    loading: false,
    showMessageModal: null,
    showTemplateModal: null,
  }
})
export class CommQueueState {
  constructor(private commsQueueService: CommsQueueService) { }

  @Selector()
  static commsQueueItems(state: CommQueueStateModel) { return state.commsQueueItems; }

  @Action(ToggleShowMessageModalAction)
  openAddEditCommTemplateModalAction(ctx: StateContext<CommQueueStateModel>, { queueItem }: ToggleShowMessageModalAction) {
    ctx.patchState({
      showMessageModal: queueItem
     });
  }

  @Action(ToggleShowTemplateModalAction)
  closeAddEditCommTemplateModalAction(ctx: StateContext<CommQueueStateModel>,  { queueItem }: ToggleShowTemplateModalAction) {
    ctx.patchState({
      showTemplateModal: queueItem
     });
  }

  @Action(GetCommsQueueAction)
  getCommsQueueItems(
    { patchState, dispatch }: StateContext<CommQueueStateModel>,
    { startDate }: GetCommsQueueAction
    ) {
    patchState({ error: null, loading: true });;
    return this.commsQueueService.getCommsQueueItems(startDate).pipe(
      tap((res) =>{ dispatch(new GetCommsQueueSuccessAction(res))}),
      catchError((error) => dispatch(new CommsQueueFailedAction(error))));
  }

  @Action(GetCommsQueueSuccessAction)
  getCommsQueueSuccess(
     { patchState }: StateContext<CommQueueStateModel>,
     { commTemplates }: GetCommsQueueSuccessAction) {
    patchState({ loading: false, commsQueueItems: commTemplates });
  }

  @Action(SortCommsQueueAction)
  sortCommsQueue(
    { patchState, getState }: StateContext<CommQueueStateModel>,
    { sortKey, sortDirection }: SortCommsQueueAction){
      const state = getState();
      const commsQueueItems = Object.values(state.commsQueueItems);

      const sorted = commsQueueItems.sort((a, b) => {
        if(a[sortKey] > b[sortKey]) return sortDirection === 'asc' ? 1 : -1;
        if(a[sortKey] < b[sortKey]) return sortDirection === 'asc' ? -1 : 1;
        return 0;
      });

      patchState({
        commsQueueItems: sorted
      })
  }

}
