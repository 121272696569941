import { trigger, style, transition, animate, state } from '@angular/animations';

export const collapse = trigger(
  'collapseAnimation', [
    transition(':enter', [
      style({ transform: 'translateY(-100%)' }),
      animate('200ms ease-in', style({ transform: 'translateY(0%)' }))
    ]),
    transition(':leave', [
      animate('200ms ease-in', style({ transform: 'translateY(-100%)' }))
    ])
  ]
);

export const accordion = trigger(
  'accordionAnimation', [
    state('*', style({
      overflow: 'hidden',
      height: '*',
      width: '*'
    })),
    state('in', style({
      overflow: 'hidden',
      height: '*',
      width: '*'
    })),
    state('out', style({
      opacity: '0',
      overflow: 'hidden',
      height: '0px'
    })),
    transition('in => out', animate('200ms ease-in-out')),
    transition('out => in', animate('200ms ease-in-out'))
  ]
);

export const hide = trigger(
  'hideAnimation', [
    state('*', style({
      overflow: 'hidden',
      height: '*',
      width: '*'
    })),
    state('in', style({
      overflow: 'hidden',
      height: '*',
      width: '*'
    })),
    state('out', style({
      opacity: '0',
      overflow: 'hidden',
      height: '0px',
      width: '0px'
    })),
    transition('in => out', animate('200ms ease-in-out')),
    transition('out => in', animate('200ms ease-in-out'))
  ]
);

export const slide = trigger(
  'slideAnimation', [
    transition(':enter', [
      style({ transform: 'translateX(100%)', opacity: 1 }),
      animate('500ms', style({ transform: 'translateX(0)', opacity: 1 }))
    ]),
    transition(':leave', [
      style({ transform: 'translateX(0)', opacity: 1 }),
      animate('500ms', style({ transform: 'translateX(100%)', opacity: 1 }))
    ])
  ]
);

export const slideUp = trigger(
  'slideUpAnimation', [
    transition(':enter', [
      style({ transform: 'translateY(100%)', opacity: 1 }),
      animate('500ms', style({ transform: 'translateY(0)', opacity: 1 }))
    ]),
    transition(':leave', [
      style({ transform: 'translateY(0)', opacity: 1 }),
      animate('500ms', style({ transform: 'translateY(100%)', opacity: 1 }))
    ])
  ]
);

export const slideState = trigger(
  'slideStateAnimation', [
    state('*', style({
      width: '*'
    })),
    state('in', style({
      width: '*'
    })),
    state('out', style({
      opacity: 1,
      width: '0px'
    })),
    transition('in => out', animate('400ms ease-in-out')),
    transition('out => in', animate('400ms ease-in-out'))
  ]
);

export const slideLeft = trigger(
  'slideLeftAnimation', [
    state('*', style({
      width: '*',
      display: 'block'
    })),
    state('in', style({
      transform: 'translateX(0%)'
    })),
    state('out', style({
      transform: 'translateX(-150%)',
      display: 'none'
    })),
    transition('in => out', animate('400ms ease-in-out')),
    transition('out => in', animate('400ms ease-in-out'))
  ]
);
export const slideRight = trigger(
  'slideRightAnimation', [
    state('*', style({
      width: '*',
    })),
    state('in', style({
      transform: 'translateX(0%)'
    })),
    state('out', style({
      display: 'none',
      transform: 'translateX(+150%)'
    })),
    transition('in => out', animate('400ms ease-in-out')),
    transition('out => in', animate('400ms ease-in-out'))
  ]
);
