import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'appSort'
})
export class SortPipe implements PipeTransform {
  transform(records: Array<any>, args?: any): any {
    if (args) {
      return records.sort(function (a, b) {

        a[args.property] = a[args.property] || '';
        b[args.property] = b[args.property] || '';
        let first = a[args.property] && a[args.property].value ? a[args.property].value : a[args.property];
        let second = b[args.property] && b[args.property].value ? b[args.property].value : b[args.property];
        if (args.property === 'patient') {
          first = a[args.property]['firstname'] + a[args.property]['surname'];
          second = b[args.property]['firstname'] + b[args.property]['surname'];
        }
        if (args.property === 'Date') {
          first = moment(first, "DD/MM/YYYY")
          second = moment(second, "DD/MM/YYYY")
        }
        
        if (first < second) {
          return -1 * args.direction;
        } else if (first > second) {
          return 1 * args.direction;
        } else {
          return 0;
        }

      });
    }
    return records;

  }
}
