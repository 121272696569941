import { Injectable } from '@angular/core';
import { BaseApiNoAlerts } from '~/shared/core/base-api/base-api-no-alerts';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { StorageService } from '~/shared/core/storage/storage.service';
import { AlertService } from '~/modules/core/alert/alert.service';
import { NewPharmacy } from '~/shared/models/pharmacy/new-pharmacy.model';
import { environment } from '../../../../environments/environment';
import { TenantInstallReportModel } from '~/shared/models/install-report.model';
import { Observable } from 'rxjs/Observable';
import { MessageTemplate } from '~/shared/models/pharmacy/message-template.model';
import { Tenant } from '~/shared/models/tenant.model';

const API = environment.baseApiUrl;

@Injectable({
  providedIn: 'root'
})
export class AdminDashboardService extends BaseApiNoAlerts {
  constructor(public http: HttpClient, public router: Router, public storageService: StorageService, public alertService: AlertService) {
    super(http, router, storageService, alertService);
  }

  newPharmacy(pharmacy: NewPharmacy): Observable<Tenant> {
    return this.post(`${API}/api/admin`, pharmacy);
  }

  tenantInstallReport(model: TenantInstallReportModel) {
    return this.post(`${API}/api/admin/TenantInstallReport`, model);
  }

  getTenantDetails(): Observable<any[]> {
    return this.get(`${API}/api/admin/tenants`);
  }

  createGlobalTemplate(messageTemplate: MessageTemplate) {
    return this.post(`${API}/api/admin/template/global`, messageTemplate)
  }
}
