import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProfilePopupComponent } from './profile-popup.component';
import { ProfileService } from '../../../shared/services/profile.service';
import { LoaderModule } from '../../../modules/core/loader/loader.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { UiSwitchModule } from 'ngx-ui-switch';
import { EventConfirmationModalModule } from '../event-confirmation-modal/event-confirmation-modal.module';
import { EvaluationHelperService } from '../event-confirmation-modal/services/evaluation-helper.service';
import { NgxsModule } from '@ngxs/store';
import { RadioHighlightModule } from '../../../shared/components/radio-highlight/radio-highlight.module';
import { CommsModule } from '../../../modules/profile/comms/comms.module';

@NgModule({
  declarations: [ProfilePopupComponent],
  providers: [ProfileService, EvaluationHelperService],
  imports: [
    CommonModule,
    LoaderModule,
    FormsModule,
    ReactiveFormsModule,
    BsDatepickerModule.forRoot(),
    NgxsModule,
    UiSwitchModule,
    EventConfirmationModalModule,
    RadioHighlightModule,
  ],
  exports: [ProfilePopupComponent],
})
export class ProfilePopupModule {}
