import { State, Selector, StateContext, Action } from "@ngxs/store";
import { AlertService } from "~/modules/core/alert/alert.service";
import { CsvExportService } from "../services/csv-export.service";
import {
  ExportToCsv,
  GetMedsCheckCsv,
  GetDAACsv,
  GetStagedSupplyCsv,
  GetHMRCsv,
  GetCICount,
  ExportToCsvError,
  GetMedsCheckPatientRegistrationCsv
} from "./csv-export.actions";
import { saveAs } from "file-saver";
import { Injectable } from "@angular/core";
import { SixCpaFormTypes } from "~/modules/profile/six-cpa/six-cpa-form-types.enum";


export class CsvExportStateModel {
  clinicalInterventionCount: any;
  loading: boolean;
}
@Injectable()
@State<CsvExportStateModel>({
  name: 'csvExport',
  defaults: {
    clinicalInterventionCount: null,
    loading: false
  }
})
export class CsvExportState {
  constructor(private alertService: AlertService, private csvExportService: CsvExportService) { }

  @Selector()
  static clinicalInterventionCount(state: CsvExportStateModel) { return state.clinicalInterventionCount; }

  
  @Selector()
  static loading(state: CsvExportStateModel) { return state.loading; }

  @Action(ExportToCsv)
  exportToCsv(ctx: StateContext<CsvExportStateModel>, { request, serviceType }: ExportToCsv) {
    ctx.patchState({loading: true});

    switch (serviceType) {
      case SixCpaFormTypes.MedsCheck: {
        ctx.dispatch(new GetMedsCheckCsv(request));
        break;
      }
      case SixCpaFormTypes.DAA: {
        ctx.dispatch(new GetDAACsv(request, false));
        break;
      }
      case SixCpaFormTypes.IDAA: {
        ctx.dispatch(new GetDAACsv(request, true));
        break;
      }
      case SixCpaFormTypes.StagedSupply: {
        ctx.dispatch(new GetStagedSupplyCsv(request));
        break;
      }
      case SixCpaFormTypes.HMR: {
        ctx.dispatch(new GetHMRCsv(request));
        break;
      }
      case SixCpaFormTypes.ClinicalIntervention: {
        ctx.dispatch(new GetCICount(request));
        break;
      }
      default: {
        this.alertService.error('The chosen form export type is not valid');
      }
    }
  }

  @Action(GetMedsCheckCsv)
  getMedsCheckCsv(ctx: StateContext<CsvExportStateModel>, { request }: GetMedsCheckCsv) {
    return this.csvExportService.getMedsCheckCsv(request).subscribe(blob => {
      saveAs(blob, `MedsChecks_${request.fromDate}_To_${request.toDate}.csv`);
      ctx.patchState({loading: false});
    }, err => {
      ctx.dispatch(new ExportToCsvError(err));
    });
  }

  @Action(GetMedsCheckPatientRegistrationCsv)
  getMedsCheckPatientRegistrationCsv(ctx: StateContext<CsvExportStateModel>, { medsCheckForm }: GetMedsCheckPatientRegistrationCsv) {
    return this.csvExportService.getMedsCheckPatientRegistrationCsv(medsCheckForm).subscribe(blob => {
      saveAs(blob, `MedsCheck_Patient_Registration.csv`);
      ctx.patchState({loading: false});
    }, err => {
      ctx.dispatch(new ExportToCsvError(err));
    });
  }

  @Action(GetDAACsv)
  getDAACsv(ctx: StateContext<CsvExportStateModel>, { request, isIDAA }: GetDAACsv) {
    const exportName = isIDAA ? 'IDAA' : 'DAA';
    return this.csvExportService.getDAACsv(request).subscribe(blob => {
      saveAs(blob, `${exportName}_${request.fromDate}_To_${request.toDate}.csv`);
      ctx.patchState({loading: false});
    }, err => {
      ctx.dispatch(new ExportToCsvError(err));
    });
  }

  @Action(GetHMRCsv)
  getHMRCsv(ctx: StateContext<CsvExportStateModel>, { request }: GetHMRCsv) {
    return this.csvExportService.getHMRCsv(request).subscribe(blob => {
      saveAs(blob, `HMRForms_${request.fromDate}_To_${request.toDate}.csv`);
      ctx.patchState({loading: false});
    }, err => {
      ctx.dispatch(new ExportToCsvError(err));
    });
  }

  @Action(GetStagedSupplyCsv)
  getStagedSupplyCsv(ctx: StateContext<CsvExportStateModel>, { request }: GetStagedSupplyCsv) {
    return this.csvExportService.getStagedSupplyCsv(request).subscribe(blob => {
      saveAs(blob, `StagedSupplyForms_${request.fromDate}_To_${request.toDate}.csv`);
      ctx.patchState({loading: false});
    }, err => {
      ctx.dispatch(new ExportToCsvError(err));
    });
  }

  @Action(GetCICount)
  getCICount(ctx: StateContext<CsvExportStateModel>, { request }: GetCICount) {
    return this.csvExportService.getCICount(request).subscribe((count) => {
      ctx.patchState({
        clinicalInterventionCount: count === 0 ? 'No completed records found' : count,
        loading: false
      });
    }, err => {
      ctx.dispatch(new ExportToCsvError(err));
    });
  }

  @Action(ExportToCsvError)
  exportToCsvError(ctx: StateContext<CsvExportStateModel>, { error }: ExportToCsvError) {
    ctx.patchState({loading: false});
    this.alertService.error(error.error);
  }
}
