import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { BaseApi } from '../core/base-api/base-api';
import { Message, ScheduledMessage } from '../models/message.model';
import { SmsMessageHistory } from '../models/messageHistory.model';
import { Sms } from '../models/sms.model';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import { StorageService } from '../core/storage/storage.service';
import { AlertService } from '~/modules/core/alert/alert.service';
import { MarketingSetting } from '../models/marketingSetting.model';
import { TemplateType } from '../models/pharmacy/comm-template.model';

const API = environment.baseApiUrl;

@Injectable()
export class SmsService extends BaseApi {
  constructor(public http: HttpClient, public router: Router, public storageService: StorageService, public alertService: AlertService) {
    super(http, router, storageService, alertService);
  }

  getMessageHistory(clientId: string): Observable<SmsMessageHistory[]> {
    return this.get(`${API}/api/GetMessageHistory/${clientId}`);
  }

  sendMessage(message: Message) {
    return this.post(`${API}/api/SendMessage`, message);
  }

  scheduleMessage(message: ScheduledMessage) {
    return this.post(`${API}/api/scheduled-message/create`, message);
  }

  getSmsList(): Observable<Sms[]> {
    return this.get(`${API}/api/GetSmsList`);
  }
}
