import { Component } from '@angular/core';

enum AdminActionType {
  newPharmacy = 0,
  newInstallReport = 1,
  newTemplate = 2
}

@Component({
  selector: 'app-admin-dashboard',
  templateUrl: './admin-dashboard.component.html',
  styleUrls: ['./admin-dashboard.component.scss']
})
export class AdminDashboardComponent {
  showNewPharmacyForm = false;
  showInstallReportForm = false;
  showGlobalTemplateForm = false;
  actionType = AdminActionType.newPharmacy;
  actionTypes = AdminActionType;

}
