import { State, Action, StateContext, Selector } from '@ngxs/store';
import { SetTotalItemsAndCompletedItems } from './hmr-proposed-details-form.actions';
import { Injectable } from '@angular/core';

export class HMRProposedDetailsFormStateModel {
  totalItems: number;
  completedItems: number;
}
@State<HMRProposedDetailsFormStateModel>({
  name: 'hmrProposedDetailsForm',
  defaults: {
    completedItems: 0,
    totalItems: 0
  }
})
@Injectable()
export class HMRProposedDetailsFormState {

  @Selector()
  static totalItems({ totalItems }: HMRProposedDetailsFormStateModel) { return totalItems; }

  @Selector()
  static completedItems({ completedItems }: HMRProposedDetailsFormStateModel) { return completedItems; }

  @Action(SetTotalItemsAndCompletedItems)
  setProgress({ patchState }: StateContext<HMRProposedDetailsFormStateModel>, { totalItems, completedItems }: SetTotalItemsAndCompletedItems) {
    patchState({ totalItems, completedItems });
  }
}
