import { Component, ChangeDetectionStrategy } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { LoadingState, LoadingStateModel } from '../state/loading.state';

@Component({
  selector: 'app-page-loader',
  templateUrl: './page-loader.component.html',
  styleUrls: ['./page-loader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PageLoaderComponent {
  @Select(LoadingState.loadingState) loadingState$: Observable<LoadingStateModel>;

 }
