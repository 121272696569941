import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DaaMedicationInformationFormListItemComponent } from './daa-medication-information-form-list-item.component';
import { SixCpaFormListItemChecklistModule } from '../../six-cpa-form-list-item-checklist/six-cpa-form-list-item-checklist.module';
import { DAAMedicationInformationFormModule } from '../daa-medication-information-form/daa-medication-information-form.module';

@NgModule({
    declarations: [
      DaaMedicationInformationFormListItemComponent
    ],
    imports: [
        CommonModule,
        SixCpaFormListItemChecklistModule,
        DAAMedicationInformationFormModule
    ],
    exports: [
      DaaMedicationInformationFormListItemComponent
    ]
})
export class DAAMedicationInformationFormListItemModule { }