import { NgModule } from '@angular/core';
import { AlertService } from './alert.service';
import { NgxsModule } from '@ngxs/store';
import { AlertState } from './state/alert.state';

@NgModule({
    imports: [
        NgxsModule.forFeature([AlertState])
    ],
    providers: [
        AlertService
    ]
})
export class AlertModule {
}
