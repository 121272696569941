<ng-container *ngIf="profile$ | async as customer; else loading">
  <section [class.lite-wrapper]="isLite">
    <section>
      <app-event-confirmation-modal></app-event-confirmation-modal>

      <app-client-title *ngIf="customer.patient" [isInSystem]="customer.customerProfile !== null" clientName="{{ customer.patient.title || '' }} {{
          customer.patient.firstname
        }} {{ customer.patient.surname }}">
      </app-client-title>
      <div class="pull-right" *ngIf="customer.customerProfile">
        <ng-template>
          <button disabled="true" class="button btn__green" *ngIf="!isLite" (click)="generateReport()" g-xs="mx3">
            Generate Report
          </button>
        </ng-template>

        <button *ngIf="customer.customerProfile.customerProfileId" class="button btn__yellow" g-xs="mr3"
          [disabled]="!(customer?.customerProfile?.mobileStatus == 0) || !(featureDecisions.isInviteToMobileAllowed() | async)"
          (click)="openInviteToSignUpModal()">
          <span *ngIf="customer?.customerProfile?.mobileStatus == 0">Invite to Scrypt Mobile</span>
          <span *ngIf="customer?.customerProfile?.mobileStatus == 1">Invite to Scrypt Mobile Pending</span>
          <span *ngIf="customer?.customerProfile?.mobileStatus == 2">Scrypt Mobile Active</span>
        </button>

      </div>
      <div class="row">
        <app-nav-panel [clientId]="customer.clientId"></app-nav-panel>
        <ng-container *ngIf="isLite">
          <div *ngIf="!(aslDetails$ | async)?.status.isRegistered" class="ml-3">
            <button class="button btn__yellow" (click)="openRegisterSLModal()">
              <i *ngIf="!aslService.validatedIHI(customer)" class="fas fa-exclamation-triangle mr-1"></i>
              Register with MySL
            </button>
          </div>
        </ng-container>
      </div>
    </section>
    <section>
      <app-profile-details (editClickEvent)="openProfileModal($event)" [customer]="customer"
        [aslDetails]="aslDetails$ | async"></app-profile-details>
    </section>

    <button *ngIf="showOutOfStoreButton()" [disabled]="addItemsLoading$ | async" class="button btn__yellow mt-4"
      (click)="addOutOfStoreClick()" g-xs="mr3">
      Add Additional Item(s)
    </button>
    <div>
      <hr class="color-grey" g-xs="my3" />
      <router-outlet></router-outlet>
    </div>
  </section>
  <ng-container>
    <app-six-cpa></app-six-cpa>
  </ng-container>
  <app-profile-popup *ngIf="popupVisible$ | async" [customer]="customer" (close)="closeProfileModal()">
  </app-profile-popup>

  <app-alert-profile *ngIf="alertVisible$ | async" [customer]="customer.patient" (edit)="showEditModal()"
    (close)="closeAlert()"></app-alert-profile>
  <app-blade [showBlade]="reportBladeOpen" (closeEvent)="reportBladeOpen = false">
    <app-report-blade [customer]="customer"></app-report-blade>
  </app-blade>
  <app-out-of-store *ngIf="addItemsVisible$ | async" (close)="closeOutOfStoreScripts()"></app-out-of-store>
  <app-cipher *ngIf="cipherVisible$ | async"></app-cipher>
  <app-invite-to-sign-up *ngIf="inviteToSignUpVisible$ | async"></app-invite-to-sign-up>

  <section>
    <app-create-note [clientId]="customer.clientId" clientName="{{ customer.patient.firstname }} {{
        customer.patient.surname
      }}">
    </app-create-note>
  </section>
  <ng-container *ngIf="showASLRegister$ | async">
    <div class="modal">
      <div class="modal-body">
        <app-asl-register-form></app-asl-register-form>
      </div>
    </div>
    <div class="modal-background"></div>
  </ng-container>
  <ng-container *ngIf="showASLSummary$ | async">
    <div class="modal">
      <div class="modal-body">
        <app-asl-summary-form></app-asl-summary-form>
      </div>
    </div>
    <div class="modal-background"></div>
  </ng-container>
  <ng-container *ngIf="showASLSuccess$ | async">
    <div class="modal">
      <div class="modal-body modal-body-asl-request">
        <i class="far fa-times fa-2x close-icon" (click)="closeSuccessModal()"></i>
        <div class="d-flex asl-request-heading align-items-baseline">
          <i class="fas fa-check-circle mr-3"></i>
          Request sent to customer
        </div>
        <p>{{ customer.patient.firstname }} {{ customer.patient.surname }} will receive an
          {{ (aslDetails$ | async).mobileNumber ? 'SMS' : 'Email' }} with a link to register with their MySL with
          Scrypt.</p>
        <button class="button btn__yellow mt-4" (click)="closeSuccessModal()" g-sm="mr3">
          Close
        </button>
      </div>
    </div>
    <div class="modal-background"></div>
  </ng-container>
</ng-container>
<ng-template #loading>
  <section *ngIf="notification$ | async as notification">
    <app-loader *ngIf="!notification.text"></app-loader>
    <app-notification *ngIf="notification.text" [notification]="notification"></app-notification>
  </section>
</ng-template>
<ng-container *ngIf="(scryptSMSState$ | async).showModal">
  <app-scrypt-sms></app-scrypt-sms>
</ng-container>
