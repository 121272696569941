import { Clipboard } from "@angular/cdk/clipboard";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, Output } from "@angular/core";
import { TenantDatePipe } from "@base/modules/core/timezone/pipes/tenant-date.pipe";
import { extractEScript } from "@base/shared/helpers/eScript.helper";
import { OrderRequestItem } from "@profile/order-requests/models/order-request-item.model";
import { OrderRequestItemStatus, OrderRequestItemType } from "@profile/order-requests/models/order.enums";
import * as moment from "moment";
import { OrderRequestItemsColumns } from "./column-definitions";
import { OrderRequest } from "@profile/order-requests/models/order-request.model";
import { SendOrderToQueue } from "../../state/order-mgmt.actions";
import { Select, Store } from "@ngxs/store";
import { TabName } from "../../order-mgmt.types";
import { OrderManagementState } from "../../state/order-mgmt.state";
import { Observable } from "rxjs";
import { HumanizePipe } from '~/shared/pipes/humanize.pipe';

@Component({
    selector: 'app-order-request-items-table',
    templateUrl: './order-request-items-table.component.html',
    styleUrls: ['./order-request-items-table.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrderRequestItemsTableComponent implements OnDestroy {
    @Input() selectedTab: TabName;
    @Input() loading: boolean;
    @Input() isOrderDispensing: boolean;
    @Input()
    set order(order: OrderRequest) {
        this._order = order;
        this.orderRequestItems = order.orderRequestedItems.map((x) => {
            if (x.orderRequestItemType === OrderRequestItemType.OnFileScript &&
                x.customerMedication &&
                x.customerMedication.isPaperless
            ) {
                const { customerMedication } = x;
                x.orderRequestItemType = OrderRequestItemType.EScript;
                x.eScriptTokenUrl = customerMedication.repeatTokenUri;
                x.eScriptRepeats = customerMedication.repeats;
                x.eScriptQty = customerMedication.quantity;
            }
            return x;
        })
        this.isIncomingOrdersTab = this.selectedTab === 'Incoming Orders';
        this.isPartiallyReadyOrdersTab = this.selectedTab === 'Partially Ready Orders';
        this.isCompletedOrdersTab = this.selectedTab === 'Completed Orders';
    }
    @Select(OrderManagementState.isIncomingOrPartiallyReadyTab) isIncomingOrPartiallyReadyTab$: Observable<boolean>;
    @Output() statusUpdatedEvent = new EventEmitter<{ order: OrderRequest, notifyCustomer: boolean }>();
    @Output() completeOrderEvent = new EventEmitter<{ order: OrderRequest, notifyCustomer: boolean }>();

    _order: OrderRequest;
    orderRequestItems: OrderRequestItem[];
    isOrderItemEScriptCopied = new Set<number>();
    escriptTokenCopiedTimer: any;

    orderItemsColumns = OrderRequestItemsColumns;
    orderItemType = OrderRequestItemType;
    orderItemStatus = OrderRequestItemStatus;
    isIncomingOrdersTab: boolean;
    isPartiallyReadyOrdersTab: boolean;
    isCompletedOrdersTab: boolean;
    imageModalsOpen = new Set<number>();

    constructor(
        private cdRef: ChangeDetectorRef,
        private tenantPipe: TenantDatePipe,
        private clipboard: Clipboard,
        private store: Store,
        private humanizer: HumanizePipe) {}

    ngOnDestroy() {
        this.isOrderItemEScriptCopied.clear();
        clearTimeout(this.escriptTokenCopiedTimer);
    }

    trackByOrderRequestItemId(orderRequestItem: OrderRequestItem) {
        return orderRequestItem.id;
    }

    createSingleItemOrder(item: OrderRequestItem) {
        return {
            ...this._order,
            orderRequestedItems: [item]
        } as OrderRequest;
    }

    copyToken(item: OrderRequestItem,) {
        const token = extractEScript(item.eScriptTokenUrl);
        this.clipboard.copy(token);

        this.isOrderItemEScriptCopied.add(item.id);

        this.escriptTokenCopiedTimer = setTimeout(() => {
            this.isOrderItemEScriptCopied.delete(item.id)
            this.cdRef.detectChanges();
        }, 500)
    }

    changeOnOrderExpected(_event: any, item: OrderRequestItem) {
        const orderExpectedDate = item.onOrderExpected || moment().add(1, 'day');
        item.onOrderExpected =  moment(orderExpectedDate).hours(0).minutes(0).seconds(0).toDate();
        this.setOnOrderText(item);
    }

    changeTooEarly(_event: any, item: OrderRequestItem) {
        const nextValidDispenseDate = item.nextValidDispenseDate || item.customerMedication?.dispensedDate || moment().add(1, 'day');
        item.nextValidDispenseDate = moment(nextValidDispenseDate).hours(0).minutes(0).seconds(0).toDate();
        this.setTooEarlyText(item);
    }

    changeStatus(event: OrderRequestItemStatus, item: OrderRequestItem) {
        item.orderRequestItemStatus = event;
        if(item.orderRequestItemStatus === OrderRequestItemStatus.UnableToFill) {
            item.notes = "";
        }
        if (item.orderRequestItemStatus === OrderRequestItemStatus.OnOrder) {
            item.notes = "We've ordered this item, expected arrival is <<Date>>";
            if (item.onOrderExpected) {
                const replace = /<<Date>>/gi;
                item.notes = item.notes.replace(replace,
                this.formatDate(item.onOrderExpected));
            }
        }

        item.setReminder = false;
        if (item.orderRequestItemStatus === OrderRequestItemStatus.TooEarly) {
            item.setReminder = true;
            if(item.customerMedication?.dispensedDate) {
                item.nextValidDispenseDate = moment(item.customerMedication.dispensedDate)
                                                .hours(0)
                                                .minutes(0)
                                                .seconds(0)
                                                .add(21, 'days')
                                                .toDate();
            }
            item.notes = "This item is too early, it will be dispensed on <<Date>>";
            if (item.nextValidDispenseDate) {
                const replace = /<<Date>>/gi;
                item.notes = item.notes.replace(replace,
                    this.formatDate(item.nextValidDispenseDate));
            }
        }
    }

    showEditNotesForItem(item: OrderRequestItem) {
        return item.orderRequestItemStatus === OrderRequestItemStatus.OnOrder ||
               item.orderRequestItemStatus === OrderRequestItemStatus.UnableToFill ||
               item.orderRequestItemStatus === OrderRequestItemStatus.TooEarly;
    }

    private formatDate(date: Date | string): string {
        return this.tenantPipe.transform(date.toString(), 'DD-MM-YYYY');
    }

    setOnOrderText(item: OrderRequestItem) {
        if (item.orderRequestItemStatus === OrderRequestItemStatus.OnOrder) {
            item.notes = "We've ordered this item, expected arrival is <<Date>>";
            if (item.onOrderExpected) {
                var replace = /<<Date>>/gi;
                item.notes = item.notes.replace(replace,
                    this.formatDate(item.onOrderExpected));
            }
        }
    }

    setTooEarlyText(item: OrderRequestItem) {
        if (item.orderRequestItemStatus === OrderRequestItemStatus.TooEarly) {
            item.notes = "This item is too early, it will be dispensed on <<Date>>";
            if (item.nextValidDispenseDate) {
                var replace = /<<Date>>/gi;
                item.notes = item.notes.replace(replace,
                    this.formatDate(item.nextValidDispenseDate));
            }
        }
    }

    getStatus(status: OrderRequestItemStatus) {
        const statusText = OrderRequestItemStatus[status];
        switch(status) {
            case OrderRequestItemStatus.Ready:
            case OrderRequestItemStatus.Dispensed: return {
                text: statusText,
                background: 'bg_green',
                icon: 'fas fa-check-circle color_green'
            };
            case OrderRequestItemStatus.UnableToFill: return {
                text: statusText,
                background: 'bg_red',
                icon: 'fas fa-times-circle color_red'
            };
            default: return {
                text: statusText,
                background: 'bg_yellow',
                icon: 'fas fa-info-circle color_yellow' }
        }
    }

    getRepeats(item: OrderRequestItem) {
      const repeats = item.orderRequestItemType === OrderRequestItemType.EScript ?
        item?.eScriptRepeats :
        item?.customerMedication?.repeats;

      return repeats || 0;
    }

    getScriptQuantity(item: OrderRequestItem) {
        return item.orderRequestItemType === OrderRequestItemType.EScript ?
            item.eScriptQty :
            item?.customerMedication?.quantity;
    }

    getToken(content: string) {
        if(!content) {
            return null;
        }
        return extractEScript(content);
    }

    handleSendOrderToQueue(order: OrderRequest) {
        if (order)
            this.store.dispatch(new SendOrderToQueue(order));
    }

    handleStatusUpdate(event) {
        this.statusUpdatedEvent.emit(event);
    }

    completeOrder(event) {
        this.completeOrderEvent.emit(event);
    }

    formatDateForImageModal(date: string): string {
        return this.humanizer.transform(date);
    }

    closeImageModal(orderId: number) {
        this.imageModalsOpen.delete(orderId);
    }

    openImage(orderId: number) {
        this.imageModalsOpen.add(orderId);
    }
}
