import { Component, OnInit } from '@angular/core';
import { HealthConditionsForForm } from '../../../../../shared/models/six-cpa/FormKeyValuePairs';
import { DAAChildForm } from '../daa-child-form';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { Store, Actions } from '@ngxs/store';
import { Subscription } from 'rxjs';
import { DAAClassificationsRecommendationsState } from './state/daa-classifications-recommendations.state';
import { SetTotalItemsAndCompletedItems } from './state/daa-classifications-recommendations.actions';
import { MedicineManagementSupport } from '../../../../../shared/models/six-cpa/consent-and-eligibility-form.model';
import { CollectionFrequency } from '../../../../../shared/models/six-cpa/DAA/daa-classifications-recommendations-form.model';
import { FormControlFunctions } from '../../form-control.functions';
import { SixCpaState } from '../../state/six-cpa.state';

const controlsToExclude = ['healthConditionsBeingMedicatedOtherDetails'];

@Component({
  selector: 'app-daa-classifications-recommendations',
  templateUrl: './daa-classifications-recommendations.component.html',
  styleUrls: ['./daa-classifications-recommendations.component.scss']
})
export class DaaClassificationsRecommendationsComponent extends DAAChildForm implements OnInit {
  private formSubscription: Subscription;
  private readonlySubscription: Subscription;
  daaClassificationsRecommendationsForm: FormGroup;
  healthConditionsForFormArr = HealthConditionsForForm;
  medicineSupport = MedicineManagementSupport;
  collectionFrequency = CollectionFrequency;

  constructor(private formBuilder: FormBuilder, private store: Store, actions: Actions, private formFunctions: FormControlFunctions) {
    super(actions);
    this.generateFormControls();
  }

  ngOnInit() {
    this.formSubscription = this.store.select(DAAClassificationsRecommendationsState.daaClassificationsRecommendations)
      .subscribe(() => this.onFormChange());
    this.readonlySubscription = this.store.select(SixCpaState.formsReadOnly)
      .subscribe(readonly => readonly ? this.daaClassificationsRecommendationsForm.disable() : this.daaClassificationsRecommendationsForm.enable());
  }

  ngOnDestroy() {
    this.formSubscription.unsubscribe();
    this.readonlySubscription.unsubscribe();
  }

  get formControls() {
    return this.daaClassificationsRecommendationsForm.controls;
  }
  protected actionOnResetForm() {
    this.daaClassificationsRecommendationsForm.reset();
    this.daaClassificationsRecommendationsForm.patchValue(
      {
        gpOrHospitalLastSixMonthsForMedicationProblems: false,
        patientUsingDAAPriorToVisit: false,
        patientSupportWithManagingMedicines: this.medicineSupport.Minimal,
        englishAtHome: true,
        aboriginalOrTorresStraitIslander: false,
        frequencyOfCollection: this.collectionFrequency.Weekly
      });
  }

  private onFormChange() {
    this.deselectOtherBoxes();
    const controls = Object.keys(this.daaClassificationsRecommendationsForm.controls);
    const totalControls = controls.filter(x => !controlsToExclude.includes(x));
    const completedItems = this.getTotalValidItems(totalControls);
    this.store.dispatch(new SetTotalItemsAndCompletedItems(totalControls.length, completedItems));
  }

  deselectOtherBoxes() {
    if (this.daaClassificationsRecommendationsForm.value.healthConditionsBeingMedicated
      && this.daaClassificationsRecommendationsForm.value.healthConditionsBeingMedicated.other === false) {
      this.daaClassificationsRecommendationsForm.controls['healthConditionsBeingMedicatedOtherDetails'].setValue('');
    }
  }

  private getTotalValidItems(controls: Array<string>) {
    return controls.filter((key) => {
      const formControl = this.daaClassificationsRecommendationsForm.get(key);
      return  formControl.status === 'VALID' || (formControl.status === 'DISABLED' && !formControl.invalid && formControl.errors === null);
    }).length;
  }

  private generateFormControls() {
    this.daaClassificationsRecommendationsForm = this.formBuilder.group({
      gpOrHospitalLastSixMonthsForMedicationProblems: new FormControl(false, Validators.required),
      patientUsingDAAPriorToVisit: new FormControl(false, Validators.required),
      healthConditionsBeingMedicated: new FormGroup({}),
      healthConditionsBeingMedicatedOtherDetails: new FormControl(''),
      patientSupportWithManagingMedicines: new FormControl(this.medicineSupport.Minimal, Validators.required),
      englishAtHome: new FormControl(true, Validators.required),
      aboriginalOrTorresStraitIslander: new FormControl(false, Validators.required),
      daasPackedPerWeek: new FormControl(1, Validators.required),
      frequencyOfCollection: new FormControl(this.collectionFrequency.Weekly, Validators.required)
    });
    this.formFunctions.generateControlsForGroupFromArr('healthConditionsBeingMedicated', this.healthConditionsForFormArr, this.daaClassificationsRecommendationsForm);
  }
}
