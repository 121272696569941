import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { OrderRequestItem } from '../../../../../modules/profile/order-requests/models/order-request-item.model';


@Component({
  selector: 'app-image-modal',
  templateUrl: './image-modal.component.html',
  styleUrls: ['./image-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImageModalComponent {
  @Input() item: OrderRequestItem;
  @Input() isVisible: boolean;
  @Input() updatedText: string = '';
  @Output() isVisibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
  ) { }

  handleOk() {
    this.isVisible = false;
    this.isVisibleChange.next(false);
  }

  handlePrint() {
      window.print();
  }

  handleCancel() {
    this.isVisible = false;
    this.isVisibleChange.next(false);
  }

}
