import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { Store, Actions } from '@ngxs/store';
import { Subscription } from 'rxjs';
import { SixCpaState } from '../../../state/six-cpa.state';
import { DAAPatientDetailsFormBaseComponent } from '../daa-patient-details-form.base.component';
import { DAAPatientDetailsFormDetailedState } from '../state/daa-patient-details-form-detailed.state';

@Component({
  selector: 'app-daa-patient-details-form-detailed',
  templateUrl: './daa-patient-details-form-detailed.component.html',
  styleUrls: ['./../daa-patient-details-form.component.scss']
})
export class DAAPatientDetailsFormDetailedComponent extends DAAPatientDetailsFormBaseComponent implements OnInit, OnDestroy {
  private formSubscription: Subscription;
  private readonlySubscription: Subscription;
  constructor(formBuilder: FormBuilder, store: Store, actions: Actions) {
    super(formBuilder, store, actions);
    this.patientDetailsForm.addControl('gender', new FormControl(null, Validators.required));
    this.patientDetailsForm.addControl('residentialPostCode', new FormControl('', [
      Validators.required, Validators.pattern('^(?:(?:[2-8]\\d|9[0-7]|0?[28]|0?9(?=09))(?:\\d{2}))$')]));
    this.patientDetailsForm.addControl('patientDOB', new FormControl(null, Validators.required));
    this.patientDetailsForm.addControl('currentLivingStatus', new FormControl(0, Validators.required));
    this.patientDetailsForm.addControl('currentLivingOtherDetails', new FormControl(null));
    this.patientDetailsForm.addControl('concessionCard', new FormControl(false));
  }

  ngOnInit() {
    this.formSubscription = this.store.select(DAAPatientDetailsFormDetailedState.form)
      .subscribe(() => this.onFormChange());
    this.readonlySubscription = this.store.select(SixCpaState.formsReadOnly)
      .subscribe(readonly => readonly ? this.patientDetailsForm.disable() : this.patientDetailsForm.enable());
  }

  ngOnDestroy() {
    this.formSubscription.unsubscribe();
    this.readonlySubscription.unsubscribe();
  }
}
