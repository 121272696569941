<div class="modal" style="overflow: scroll; min-width: 800;">
  <div class="modal-body border__grey">
    <h2><strong>Attachments</strong></h2>
    <ng-container *ngIf="(attachments$ | async) as attachments">
      <div *ngFor="let attachment of attachments">
          <img class="mb-2 attachment" height="400" width="450" [src]="attachment.contentLink" (click)="openAttachment(attachment.contentLink)" />
      </div>
    </ng-container>
    <div g-xs="mt2">
      <button class="button btn__yellow" g-xs="mr1" (click)="closeModal()">Close</button>
    </div>
  </div>

</div>
<div class="modal-background"></div>
