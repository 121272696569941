import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpParameterCodec, HttpParams, HttpParamsOptions, HttpUrlEncodingCodec } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { BaseApiNoAlerts } from '~/shared/core/base-api/base-api-no-alerts';
import { Router } from '@angular/router';
import { StorageService } from '~/shared/core/storage/storage.service';
import { AlertService } from '~/modules/core/alert/alert.service';
import { after, cloneDeep, cloneDeepWith, isArray, isNil, isNull, isObject, isObjectLike, isUndefined, map, omitBy, remove } from 'lodash';

export class HttpParamsNoNulls extends HttpParams {

  constructor(options: HttpParamsOptions = {} as HttpParamsOptions) {
    if (!!options.fromObject) {
      // TODO: make immutable, consider something lke cloneDeepWith
      // remove all null params
      options.fromObject = cloneDeep(options.fromObject);
        Object.keys(options.fromObject).forEach(key => {
          const value = options.fromObject[key];

          if (Array.isArray(value)) {
            options.fromObject[key] = value.filter(x => x !== null);
          } else {
            if (value === null) {
              delete options.fromObject[key];
            }
          }
      });
      super(options);
    }
  }
}

@Injectable()
export class AdvancedSearchService extends BaseApiNoAlerts {
  private API = environment.baseApiUrl;

  constructor(public http: HttpClient, public router: Router, public storageService: StorageService, public alertService: AlertService) {
    super(http, router, storageService, alertService);
  }

  getAdvancedSearchResults(queryItems: any): Observable<any> {
    const completeUrl = `${this.API}/api/search/dispense`;
    return this.get(completeUrl, {params: new HttpParamsNoNulls({fromObject: queryItems})});
  }

  getScryptAdvancedSearchResults(queryItems: any): Observable<any> {
    const completeUrl = `${this.API}/api/search/scrypt`;
    return this.get(completeUrl, {params: new HttpParamsNoNulls({fromObject: queryItems})});
  }
}
