import { Pipe, PipeTransform } from '@angular/core';
import { DirtyEmailTrailConstants } from '../constants/dirty-email-trail.constant';
import { RegularExpressions } from '../constants/regular-expressions';

@Pipe({
  name: 'formatEmail',
})

export class FormatEmailPipe implements PipeTransform {
  transform(value: any): string {
    if (value)
    {
      return this.formatEmailContent(value.toString());
    }
  }

  formatEmailContent(content) {
    if (!content) {
      return content;
    }

    content = this.removeDuplicateLinks(content);
    content = this.replaceUrls(content);
    content = this.addNewLineBreaks(content);
    content = this.addLineBreaksForOlderTrail(content);
    content = this.removeCopyrightTrail(content);
    content = this.removeSequentialUnderscores(content);

    return content;
  }

  private removeSequentialUnderscores(content: string)
  {
    return content.replace(/([_]{2})/g, '');
  }

  private replaceUrls(content: string) {
    return content.replace(RegularExpressions.urlRegex, function (url) {
      return `<a target="_blank" href="${url}"><br />${url}<br /></a>`;
    });
  }

  private addNewLineBreaks(content: string) {
    const emailTrailSeparator = DirtyEmailTrailConstants.latestForwardedEmail;
    var expression = new RegExp(emailTrailSeparator, "g")
    return content.replace(expression, function (separation) {
      return `<span><br />${separation}<br /></span>`;
    });
  }

  private addLineBreaksForOlderTrail(content: string) {
    const emailTrailSeparator = DirtyEmailTrailConstants.forwardedEmail;
    var expression = new RegExp(emailTrailSeparator, "g")
    return content.replace(expression, function (separation) {
      return `<span><br />${separation}<br /></span>`;
    });
  }

  private removeCopyrightTrail(content: string) {
    if (content.includes(DirtyEmailTrailConstants.copyright)) {
      content = content.substring(0, content.indexOf(DirtyEmailTrailConstants.copyright))
    }
    return content;
  }

  private removeDuplicateLinks(content: string) {
    var expression = new RegExp(RegularExpressions.emailDuplicateUrlRegex, "g")
    return content.replace(expression, function () {
      return '';
    });
  }
}
