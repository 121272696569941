import { HttpClient } from '@angular/common/http';
import { Injectable, InjectionToken, Inject } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Router } from '@angular/router';
import { StorageService } from '../core/storage/storage.service';
import { BaseApi } from '../core/base-api/base-api';
import { environment } from '../../../environments/environment';
import { AlertService } from '~/modules/core/alert/alert.service';

const API = environment.baseApiUrl;

export const CTRL_PATH: InjectionToken<string> = new InjectionToken('CTRL_PATH');

@Injectable()
export class CrudService extends BaseApi {
  constructor(
    protected http: HttpClient,
    protected router: Router,
    protected storageService: StorageService,
    protected alertService: AlertService,
    @Inject(CTRL_PATH) protected ctrlPath: string) {
    super(http, router, storageService, alertService);

    this.path = ctrlPath;
  }

  private path: string;

  getList(): Observable<any[]> {
    return this.get(`${API}/api/${this.path}`);
  }

  getById(id: string): Observable<any> {
    return this.get(`${API}/api/${this.path}/${id}`);
  }

  add(item: any): Observable<any> {
    return this.post(`${API}/api/${this.path}/add`, item);
  }

  update(item: any): Observable<any> {
    return this.put(`${API}/api/${this.path}/edit`, item);
  }

  remove(id: any) {
    return this.delete(`${API}/api/${this.path}/remove/${id}`);
  }
}
