import { HMRPatientDetailsFormModel } from '~/shared/models/six-cpa/HMR/hmr-patient-details-form.model';

export enum HMRPatientDetailsFormActions {
  UPDATE_PROGRESS = '[HMRPatientDetailsForm] Update Progress',
  UPDATE = '[HMRPatientDetailsForm] Update'
}

export class SetTotalItemsAndCompletedItems {
  static readonly type = HMRPatientDetailsFormActions.UPDATE_PROGRESS;
  constructor(public totalItems: number, public completedItems: number) { }
}

export class UpdateHMRPatientDetailsForm {
  static readonly type = HMRPatientDetailsFormActions.UPDATE;
  constructor(public form: Partial<HMRPatientDetailsFormModel>) { }
}
