import { Injectable } from "@angular/core";
import { PrimaryContactSummary } from "../models/primary-contact-summary.model";

@Injectable()
export class PrimaryContactHelperService {
    hasPrimaryContacts(summary: PrimaryContactSummary) {
        return this.hasPrimarySMSContact(summary) && this.hasPrimaryEmailContact(summary);
    }

    hasPrimarySMSContact(summary: PrimaryContactSummary): boolean {
        const primarySMSContacts = summary.contacts.filter(c => c.isSMSPrimaryContact);

        if (!primarySMSContacts.length && !summary.smsPrimaryContact) {
            return false;
        }

        return true;
    }

    hasPrimaryEmailContact(summary: PrimaryContactSummary): boolean {
        const primaryEmailContacts = summary.contacts.filter(c => c.isEmailPrimaryContact);

        if (!primaryEmailContacts.length && !summary.emailPrimaryContact) {
            return false;
        }

        return true;
    }

    getSMSPrimary(summary: PrimaryContactSummary) {
        const primarySMSContacts = summary.contacts.filter(c => c.isSMSPrimaryContact);

        if (primarySMSContacts && primarySMSContacts.length) {
            return `${primarySMSContacts[0].firstName} ${primarySMSContacts[0].surname}`;
        }

        return `No one`;
    }

    getEmailPrimary(summary: PrimaryContactSummary) {
        const primaryEmailContacts = summary.contacts.filter(c => c.isEmailPrimaryContact);

        if (primaryEmailContacts && primaryEmailContacts.length) {
            return `${primaryEmailContacts[0].firstName} ${primaryEmailContacts[0].surname}`;
        }

        return `No one`;
    }

    getSMSPrimaryName(summary: PrimaryContactSummary) {
        const primarySMSContacts = summary.contacts.filter(c => c.isSMSPrimaryContact);

        if (primarySMSContacts && primarySMSContacts.length) {
            return `${primarySMSContacts[0].firstName} ${primarySMSContacts[0].surname}`;
        }

        return `No primary contact selected`;
    }

    getEmailPrimaryName(summary: PrimaryContactSummary) {
        const primaryEmailContacts = summary.contacts.filter(c => c.isEmailPrimaryContact);

        if (primaryEmailContacts && primaryEmailContacts.length) {
            return `${primaryEmailContacts[0].firstName} ${primaryEmailContacts[0].surname}`;
        }

        return `No primary contact selected`;
    }

    currentPatientIsPrimary(summary: PrimaryContactSummary) {
        return (summary.smsPrimaryContact || summary.emailPrimaryContact)
    }
}