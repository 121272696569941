import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-sms-report-filter',
  templateUrl: './sms-report-filter.component.html',
  styleUrls: ['./sms-report-filter.component.scss']
})
export class SmsReportFilterComponent implements OnInit {

  constructor() { }

  types = [
    { name: 'all', label: 'All' },
    { name: 'from', label: 'From' },
    { name: 'to', label: 'To' }
  ];
  searchType = 'all';
  searchValue = '';
  searchRange: Date[] = [];
  @Output() filterChangedEvent = new EventEmitter<any>();
  ngOnInit() {
  }
  onValueChange(value: Date[]): void {
    this.searchRange = value;
    this.applyFilters();
  }
  applyFilters(): void {
    this.filterChangedEvent.emit({
      type: this.searchType,
      value: this.searchValue,
      range: this.searchRange
    });
  }
}
