import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TimezoneModule } from '~/modules/core/timezone/timezone.module';
import { CsvExportPageComponent } from './csv-export-page.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { CsvExportService } from './services/csv-export.service';
import { NgxsModule } from '@ngxs/store';
import { CsvExportState } from './state/csv-export.state';
import { LoaderModule } from '~/modules/core/loader/loader.module';
import { SixcpaCsvExportModule } from './sixcpa-csv-export/sixcpa-csv-export.module';

@NgModule({
  declarations: [
    CsvExportPageComponent
  ],
  imports: [
    NgSelectModule,
    TimezoneModule,
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
    BsDatepickerModule.forRoot(),
    NgxsModule.forFeature([CsvExportState]),
    LoaderModule,
    SixcpaCsvExportModule
  ],
  exports: [
    CsvExportPageComponent
  ],
  providers: [
    CsvExportService
  ]
})
export class CsvExportPageModule { }
