import { State, Selector, StateContext, Action, Store } from '@ngxs/store';
import { ToggleReplyModal, OpenMessageContent, GetMessageHistory, MailboxDetailError, SendMessage, GetMessageTemplates, SetMessageDetailFlagged, SetMessageDetailRead } from './chat.actions';
import { CommunicationsService } from '../../../services/communications.service';
import { tap, catchError, finalize } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { MessageDetail, MessageChannel, SendMessageDetailResponse, MessageDetailAttachment } from '../../../../../shared/models/message.model';
import { Channels, MailTabs, MessageSource } from '../../../../../shared/types/communications';
import { EmailType } from '../../../../../shared/models/emailType.model';
import { MarketingSetting } from '../../../../../shared/models/marketingSetting.model';
import { SmsService } from '../../../../../shared/services/sms.service';
import { EmailService } from '../../../../../shared/services/email.service';
import { CommunicationsState } from '../../../state/communications.state';
import { SetMessageRead } from '../../../state/communications.actions';
import { uniqBy, orderBy, last } from 'lodash';
import { ResetForm } from '@ngxs/form-plugin';
import { OpenBulkCommRecipientsModal } from '@base/modules/communications-page/state/communications.actions';
import { MessageType } from '~/shared/models/messageType.model';
import { MarketingService } from '~/shared/services/marketing.service';


const ERROR_MESSAGE = "Message was not loaded correctly, some details may not be available. We'll try to load the history instead.";
const COULD_NOT_FETCH_HISTORY = "We couldn't fetch the comms history for this customer";
const COULD_NOT_FETCH_SMS_TEMPLATES = "Failed to retrieve sms templates";
const COULD_NOT_FETCH_EMAIL_TEMPLATES = "Failed to retrieve email templates";
const ORDER_ASC = "asc"

export interface MessageHistory {
  messages: MessageDetail[];
  lastMessageTime: Date;
}

export interface CustomerDetail {
  customerId: string;
  firstName: string;
  lastName: string;
}

export interface ChatStateModel {
  messageHistory: MessageHistory;
  showReplyModal: boolean,
  isLoadingMessageDetails: boolean,
  modalErrorMessage: string,
  isSendingMessage: boolean,
  isLoadingTemplates: boolean,
  messageTemplates: MessageType[],
  customer: CustomerDetail,
  messageForm: {
    model: Partial<SendMessage>;
  };
  selectedMessageId: string;
  scrollToSelectedMessage: boolean;
}

@State<ChatStateModel>({
  name: 'chat',
  defaults: {
    showReplyModal: false,
    isLoadingMessageDetails: false,
    messageHistory: {
      lastMessageTime: null,
      messages: []
    },
    customer: null,
    modalErrorMessage: null,
    isSendingMessage: false,
    isLoadingTemplates: false,
    messageTemplates: [],
    messageForm: {
      model: undefined
    },
    selectedMessageId: null,
    scrollToSelectedMessage: false
  }
})

@Injectable()
export class ChatState {
  constructor(
    private store: Store,
    private communicationsService: CommunicationsService,
    private smsService: SmsService,
    private emailService: EmailService,
    private marketingService: MarketingService
    ) { }

  @Selector()
  static state(state: ChatStateModel) {
    return state;
  }

  @Selector()
  static loading(state: ChatStateModel) {
    return state.isLoadingMessageDetails;
  }

  @Selector()
  static messageTemplates(state: ChatStateModel) {
    return state.messageTemplates;
  }

  @Selector()
  static mostRecentClientMessage(state: ChatStateModel) {
    return last(state.messageHistory.messages
      .filter(x => x.source === MessageSource.Customer)) ??
      last(state.messageHistory.messages // this is the fall back for sent messages, which makes this selectors name stupid. not even sure if most recent message is necessary.
        .filter(x => x.source === MessageSource.Pharmacy)) ?? null;
  }

  @Selector()
  static moreRecentMessageExists(state: ChatStateModel) {
    return new Date(last(state.messageHistory.messages).date) < state.messageHistory.lastMessageTime;
  }

  @Selector()
  static selectedMessageId(state: ChatStateModel) {
    return state.selectedMessageId;
  }

  @Selector()
  static scrollToSelectedMessage(state: ChatStateModel) {
    return state.scrollToSelectedMessage;
  }

  @Action(SetMessageDetailFlagged)
  setMessageDetailFlagged(ctx: StateContext<ChatStateModel>, { id, isFlagged }: SetMessageDetailFlagged) {
    const messageHistory = ctx.getState().messageHistory;
    ctx.patchState({
      messageHistory: {
        ...messageHistory,
        messages: messageHistory.messages.map(x => {
          if (x.inboxMessageId === id) {
            return ({ ...x, isFlagged: isFlagged })
          } else {
            return x;
          }
        })
      }
    });
  }

  @Action(SetMessageDetailRead)
  setMessageDetailRead(ctx: StateContext<ChatStateModel>, { id, isRead }: SetMessageDetailRead) {
    const messageHistory = ctx.getState().messageHistory;
    ctx.patchState({
      messageHistory: {
        ...messageHistory,
        messages: messageHistory.messages.map(x => {
          if (x.inboxMessageId === id) {
            return ({ ...x, isRead: isRead })
          } else {
            return x;
          }
        })
      }
    });
    if (!isRead) {
      ctx.dispatch(new ToggleReplyModal(false))
    }
  }

  @Action(GetMessageHistory)
  getMessageHistory(ctx: StateContext<ChatStateModel>, { customerId, channel, pageSize, directionIsRecent }: GetMessageHistory) {
    ctx.patchState({ isLoadingMessageDetails: true, scrollToSelectedMessage: false });

    const history = ctx.getState().messageHistory;
    const lastMessageTime = directionIsRecent ? new Date() : new Date(history.messages[0].date);

    return this.communicationsService.getMessageHistory(customerId, channel, lastMessageTime, pageSize).pipe(
      tap(result => {
        if (result.length === 0) {
          return;
        }
        // this will be removed on infinite scroll
        if (directionIsRecent) {
          ctx.dispatch(new ToggleReplyModal(true, null))
        }

        ctx.patchState({
          messageHistory: this.getUpdatedMessageHistory(history, result)
        });
      }),
      catchError(_ => ctx.dispatch(new MailboxDetailError(COULD_NOT_FETCH_HISTORY, null))),
      finalize(() => {
        ctx.patchState({ isLoadingMessageDetails: false, scrollToSelectedMessage: directionIsRecent });
      })
    );
  }

  getUpdatedMessageHistory(oldMessageHistory: MessageHistory, newMessages: MessageDetail[]): MessageHistory {
    // uniq will take new message over old message if same id
    const uniqueMessages = uniqBy([...newMessages, ...oldMessageHistory.messages], (x: MessageDetail) => x.id)
    const orderedMessages = orderBy(uniqueMessages, o => o.date, [ORDER_ASC]);

    return {
      lastMessageTime: oldMessageHistory.lastMessageTime > new Date(orderedMessages[0].date)
        ? orderedMessages[0].date : oldMessageHistory.lastMessageTime,
      messages: orderedMessages
    } as MessageHistory;
  }

  getUpdatedMessageHistoryFromResponse(oldMessageHistory: MessageHistory, newMessage: SendMessageDetailResponse): MessageHistory {
    // uniq will take new message over old message if same id
    const uniqueMessages = uniqBy([newMessage.messageDetail, ...oldMessageHistory.messages], (x: MessageDetail) => x.id)
    const orderedMessages = orderBy(uniqueMessages, o => o.date, [ORDER_ASC]);

    return {
      lastMessageTime: new Date(newMessage.lastMessageTimestamp),
      messages: orderedMessages
    } as MessageHistory;
  }

  @Action(GetMessageTemplates)
  getMessageTemplates(ctx: StateContext<ChatStateModel>, { clientId }: GetMessageTemplates) {
    ctx.patchState({ isLoadingTemplates: true });
    return this.marketingService.getMessageTemplates(clientId).pipe(
      tap((marketingSettings: MarketingSetting[]) => {
        ctx.patchState({
          messageTemplates:
            marketingSettings.map(setting => ({
              id: `${setting.id}`,
              title: setting.key,
              message: setting.messageTemplate
            }))
        });
      }),
      catchError(x => ctx.dispatch(new MailboxDetailError(COULD_NOT_FETCH_SMS_TEMPLATES))),
      finalize(() => {
        ctx.patchState({ isLoadingTemplates: false });
      })
    );
  }

  @Action(SendMessage)
  sendMessage(ctx: StateContext<ChatStateModel>, { message }: SendMessage) {
    ctx.patchState({ isSendingMessage: true });
    return this.communicationsService.sendMessage(message).pipe(
      tap(messageResponse => {
        const state = ctx.getState();
        ctx.patchState({
          messageHistory: this.getUpdatedMessageHistoryFromResponse(state.messageHistory, messageResponse),
          messageForm: { model: undefined }
        }
        );
        ctx.dispatch(new ResetForm({ path: 'chat.messageForm', value: undefined }));
      }),
      catchError(x => ctx.dispatch([
        new MailboxDetailError("Message failed to send")])),
      finalize(() => {
        ctx.patchState({ isSendingMessage: false });
      })
    );
  }

  @Action(ToggleReplyModal)
  toggleReplyModal(ctx: StateContext<ChatStateModel>, { open, message }: ToggleReplyModal) {
    ctx.patchState({
      showReplyModal: open,
      modalErrorMessage: null,
      messageHistory: message ?
        this.getUpdatedMessageHistory(
          ctx.getState().messageHistory,
          [message]) :
        {
          lastMessageTime: null,
          messages: new Array<MessageDetail>()
        }
    })
  }

  @Action(OpenMessageContent)
  getMessageContent(ctx: StateContext<ChatStateModel>, { channel, messageId, customerId }: OpenMessageContent) {
    ctx.patchState({ isLoadingMessageDetails: true, selectedMessageId: messageId });

    const message = this.store.selectSnapshot(CommunicationsState.getMailboxMessage)(messageId);
    if (message?.type && message.type.toLowerCase() === "bulkcommunications") {
      ctx.dispatch(new OpenBulkCommRecipientsModal(message.messageId));
      return;
    }

    ctx.dispatch([
      new ToggleReplyModal(true),
      new ResetForm({ path: 'chat.messageForm', value: undefined }) // move ResetForm into ToggleReply on infinite scroll
    ]);

    return this.communicationsService.getMailboxContents(customerId, channel, messageId).pipe(
      tap(result => {
        const message = this.store.selectSnapshot(CommunicationsState.getMailboxMessage)(messageId);
        const mailbox = this.store.selectSnapshot(CommunicationsState.getMailboxMessageTab)(messageId);

        ctx.patchState({
          customer: {
            customerId: message?.customerId ?? null,
            firstName: message?.firstName ?? "",
            lastName: message?.lastName ?? ""
          }
        });

        if (mailbox === MailTabs.Inbox && !message.isRead) {
          ctx.dispatch(new SetMessageRead(message, true, mailbox));
        }

        ctx.patchState({
          messageHistory: this.getUpdatedMessageHistoryFromResponse(ctx.getState().messageHistory, result)
        });
      }),
      catchError(x => ctx.dispatch([
        new MailboxDetailError(ERROR_MESSAGE, messageId, customerId, channel)])),
      finalize(() => {
        ctx.dispatch(new GetMessageHistory(customerId, channel, 50, true));
        ctx.patchState({ isLoadingMessageDetails: false });
      })
    );
  }

  @Action(MailboxDetailError)
  mailboxDetailError(ctx: StateContext<ChatStateModel>, { error, messageId, customerId, channel }: MailboxDetailError) {
    // pretend message fetch worked if there is message id
    if (messageId !== null) {
      const message = this.store.selectSnapshot(CommunicationsState.getMailboxMessage)(messageId);
      const messageDetail = new MessageDetail("", "", message.content, new Date(message.sendDateTime).toISOString(), false, false, message.customerId,
        message.channel, new Array<MessageDetailAttachment>(), message.firstName, message.lastName, "", MessageSource.Pharmacy, message.isFlagged, message.id);

      if (message.firstName === null && message.lastName === null) {
        messageDetail.firstName = message.receipientAddress;
      }

      ctx.patchState({
        messageHistory: this.getUpdatedMessageHistory(ctx.getState().messageHistory, [messageDetail])
      });
    }
    else if (messageId == null && channel == Channels.App) {
      ctx.dispatch(new GetMessageHistory(customerId, Channels.App, 20, true));
    }

    ctx.patchState({
      modalErrorMessage: error
    });
  }
}
