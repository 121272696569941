import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SixCpaFormListItemChecklistModule } from '../six-cpa-form-list-item-checklist/six-cpa-form-list-item-checklist.module';
import { PharmacyInfoFormListItemComponent } from './pharmacy-info-form-list-item.component';
import { PharmacyInformationFormModule } from '../pharmacy-information-form/pharmacy-information-form/pharmacy-information-form.module';


@NgModule({
    declarations: [
        PharmacyInfoFormListItemComponent
    ],
    imports: [
        CommonModule,
        SixCpaFormListItemChecklistModule,
        PharmacyInformationFormModule
    ],
    exports: [
        PharmacyInfoFormListItemComponent
    ]
})
export class PharmacyInfoFormListItemModule { }