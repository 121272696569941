import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { NgxAutoScroll } from 'ngx-auto-scroll';
import { ScheduledMessage, ScheduledMessageStatus } from '../../../../shared/models/message.model';
import { Client } from '../../../../shared/models/client.model';
import { Select, Store } from '@ngxs/store';
import { ScheduledMessagesState } from './state/scheduled-messages.state';
import { Observable } from 'rxjs';
import { RemoveScheduledMessage, RetryFailedMessage } from './state/scheduled-messages.actions';

@Component({
  selector: 'app-scheduled-messages',
  templateUrl: './scheduled-messages.component.html',
  styleUrls: ['./scheduled-messages.component.scss']
})
export class ScheduledMessagesComponent implements OnInit {
  constructor(private store: Store) { }

  @Select(ScheduledMessagesState.scheduledMessages) scheduledMessages$: Observable<ScheduledMessage[]>;
  @Select(ScheduledMessagesState.scheduledMessagesLoading) scheduledMessagesLoading$: Observable<boolean>;
  @ViewChild(NgxAutoScroll, { static: false }) ngxAutoScroll: NgxAutoScroll;
  @Input() client: Client;
  scheduledMessageStatus =  ScheduledMessageStatus;

  ngOnInit() {
  }

  removeMessage(id: number) {
    this.store.dispatch(new RemoveScheduledMessage(id));
  }

  retryMessage(id: number, ) {
    this.store.dispatch(new RetryFailedMessage(id, this.client.id));
  }
}
