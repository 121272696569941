import { DAAMedicationInformationFormModel } from '../../../../../../shared/models/six-cpa/DAA/daa-medication-information-form.model';

export enum DAAMedicationInformationFormActions {
  SET_VALID = '[DAAMedicationInformationForm] set valid',
  UPDATE = '[DAAMedicationInformationForm] Update Form',
  UPDATE_PROGRESS = '[DAAMedicationInformationForm] Update Progress'
}

export class SetDAAMedicationInformationFormValidAction {
  static readonly type = DAAMedicationInformationFormActions.SET_VALID;
  constructor(public valid = false) { }
}

export class SetTotalItemsAndCompletedItems {
  static readonly type = DAAMedicationInformationFormActions.UPDATE_PROGRESS;
  constructor(public totalItems, public completedItems) { }
}

export class UpdateDAAMedicationInformationForm {
  static readonly type = DAAMedicationInformationFormActions.UPDATE;
  constructor(public form: DAAMedicationInformationFormModel) { }
}
