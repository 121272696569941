<ng-container>
  <span
    [nz-tooltip]="address ? tooltipAddress : null"
    [nzTooltipArrowPointAtCenter]="true"
    class="d-flex align-items-center gap-2">
    <i [ngClass]="order.orderMethod === orderMethodEnum.Delivery ? 'far fa-check success' : 'far fa-times danger'"></i>
  </span>

  <ng-template #tooltipAddress>
    <div class="d-flex justify-content-between align-items-start align-items-center tooltip-address">
      <div class="flex-grow-1">
        <span>{{ address }}</span>
      </div>
      <div class="flex-shrink-0">
        <i class="copy-icon ms-2" nz-icon nzType="copy" (click)="copyAddressToClipboard()"></i>
      </div>
    </div>
  </ng-template>
</ng-container>
