import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { BaseApi } from '../core/base-api/base-api';
import { Router } from '@angular/router';
import { StorageService } from '../core/storage/storage.service';
import { AlertService } from '../../modules/core/alert/alert.service';
import { BatchDispenseRequestModel } from '../models/dispense/batchDispenseRequest.model';
import { QueueDispenseRequestModel } from '../models/dispense/queueDispenseRequest.model';
import { UpdateScriptNotificationsRequest } from '../models/script/update-script-notifications-request.model';
import { AdjustmentRequestModel } from '../models/script/adjustment-request.model';
import { StockStartingPointModel } from '../models/script/meta-view-model';
import { Observable } from 'rxjs';
import { DispensePaperlessRequest } from '../../shared/models/lite/paperlessDispense.model';

@Injectable()
export class ScriptService extends BaseApi {
  API = '';

  constructor(
    @Inject('environment') env: any,
    public http: HttpClient,
    public router: Router,
    public storageService: StorageService,
    public alertService: AlertService
  ) {
    super(http, router, storageService, alertService);
    this.API = env.baseApiUrl;
  }
  toggleFlag(meta: any) {
    return this.post(`${this.API}/api/client/flag`, meta);
  }

  toggleRequestable(meta: any) {
    return this.post(`${this.API}/api/client/requestable`, meta);
  }

  createMeta(scriptMeta: any) {
    return this.post(`${this.API}/api/scripts/meta`, scriptMeta);
  }

  updateMeta(scriptMeta: any) {
    return this.put(
      `${this.API}/api/scripts/${scriptMeta.metaId}/meta`,
      scriptMeta
    );
  }

  resetMetas(scriptMetaIds: number[]) {
    return this.post(`${this.API}/api/scripts/meta/delete`, scriptMetaIds);
  }

  addDosage(newDosage: any) {
    return this.post(
      `${this.API}/api/scripts/${newDosage.metaId}/dosage`,
      newDosage
    );
  }

  deleteDosage(dosageId: any) {
    return this.delete(`${this.API}/api/dosage/${dosageId}`);
  }

  updateStartDate(startDate: any) {
    return this.put(
      `${this.API}/api/scripts/${startDate.metaId}/meta/startdate`,
      startDate
    );
  }

  addAdjustment(adjustment: AdjustmentRequestModel) {
    return this.put(`/api/scripts/${adjustment.metaId}/stock`, adjustment);
  }

  addBulkAdjustment(adjustments: AdjustmentRequestModel[]) {
    return this.put(`${this.API}/api/stock/bulk`, adjustments);
  }

  createInstoreOrder(data) {
    return this.post('/api/order/createInstoreOrder', data);
  }

  bulkDispenseDrugs(data: BatchDispenseRequestModel) {
    return this.post('/api/script/bulkDispense', data);
  }

  dispenseEScript(request: QueueDispenseRequestModel) {
    return this.post(`${this.API}/api/script/escript`, request);
  }

  updateScriptNotifications(request: UpdateScriptNotificationsRequest) {
    return this.put(`${this.API}/api/scripts/meta/notifications`, request);
  }

  addStockStartingPoint(
    stockStartingPoint: StockStartingPointModel
  ): Observable<any> {
    return this.post(`${this.API}/api/stock/ssp`, stockStartingPoint);
  }

  dispensePaperlessScript(request: DispensePaperlessRequest) {
    return this.post(`${this.API}/api/script/dispense/paperless`, request);
  }
}
