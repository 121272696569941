export enum DoctorReportTemplatesSelectorActions {
  GET_DOCTOR_REPORT_TEMPLATES = '[DoctorReportTemplatesSelector] Get doctor report templates',
  SET_SELECTED_TEMPLATE = '[DoctorReportTemplatesSelector] Set selected template',
  GET_ERRRORS = '[DoctorReportTemplatesSelector] Get errors'
}

export class GetDoctorReportTemplates {
  static readonly type = DoctorReportTemplatesSelectorActions.GET_DOCTOR_REPORT_TEMPLATES;
  constructor(public clientId: string) { }
}

export class SetSelectedDoctorReportTemplate {
  static readonly type = DoctorReportTemplatesSelectorActions.SET_SELECTED_TEMPLATE;
  constructor(public templateId: number) { }
}

export class GetDoctorReportTemplatesError {
  static readonly type = DoctorReportTemplatesSelectorActions.GET_ERRRORS;
  constructor(public errors: any) { }
}