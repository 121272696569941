<p>
  BiMetric
</p>

<form [formGroup]="bimetricForm" (ngSubmit)="onSubmit(bimetricForm.value)">

  <div class="form-group">
    <label for="metric_name">Name</label>
    <input type="text" class="form-control" id="metric_name" placeholder="Name" formControlName="name">
  </div>

  <div class="form-group">
    <label for="current">Current</label>
    <input type="text" class="form-control" id="current" placeholder="Current" formControlName="current">
  </div>

  <div class="form-group">
    <label for="target">Target</label>
    <input type="text" class="form-control" id="target" placeholder="Target" formControlName="target">
  </div>

  <div class="btn-group">
    <button type="submit" class="btn btn-primary">Save</button>
    <button routerLink="/biMetric" class="btn btn-danger" type="button">Cancel</button>
  </div>

</form>
