import * as moment from 'moment';
import { BulkMessageForm } from '../state/new-bulk-message-form.state';

export function combineDateAndTime(date: Date, time: Date): moment.Moment {
    const now = moment(new Date());
    const datePart = date ? moment(date) : now;
    const timePart = time ? moment(time) : now;

    return datePart.set({
        hours: timePart.get('hour'),
        minute: timePart.get('minute'),
        second: 0,
        millisecond: 0,
    });
}

export function messageIsProcessing(message: BulkMessageForm) {
    const now = moment(new Date());
    const processingTime = moment(new Date()).add(2, 'minutes');
    const scheduledTime = moment(message.sendDate);
    return scheduledTime.isBetween(now, processingTime);
}