<ng-container *ngIf="(crm$ | async) as crm; else loading">
    <section>
        <ng-container *ngIf="(noteInfo$ | async) as noteInfo;">
            <div class="crm__title">
                <h1 g-xs="my1">Actions</h1>
                <div class="flex" *ngIf="noteInfo">
                    <div g-xs="mr3">
                        <span>Total Outstanding: </span>
                        <span class="font__bold">{{noteInfo.remainingNotes}}</span>
                    </div>
                    <!-- <div>
                        <span>Completed: </span>
                        <span class="font__bold">{{noteInfo.compleatedToday}} actions ({{noteInfo.percent}}%)</span>
                    </div> -->
                </div>
            </div>
        </ng-container>
        <app-note-filters [groupTypeList]="groupList" [noteTypeList]="noteTypeList"
            (filterChanged)="filterChanged($event)">
        </app-note-filters>
        <ng-container *ngIf="(noteList$ | async) as noteList; else loading">
            <app-note-list *ngIf="crm.isLoadedTable; else loadingTable" [isScrollNextLoaded]="crm.isScrollNextLoaded"
                (scrollNotes)="scrollNext()" [noteList]="noteList" [noteTypeList]="noteTypeList"
                (noteActioned)="updateTotals($event)">
            </app-note-list>
        </ng-container>
    </section>
    <ng-template #loadingTable>
        <div g-xs="mt3" class="flex loading">
            <ng-container *ngTemplateOutlet="loading"></ng-container>
        </div>
    </ng-template>
</ng-container>
<ng-template #loading>
    <app-loader></app-loader>
</ng-template>