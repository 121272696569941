import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrderEmptyComponent } from './order-empty.component';

@NgModule({
  declarations: [
    OrderEmptyComponent
  ],
  imports: [
    CommonModule,
  ],
  exports:[
    OrderEmptyComponent
  ]
})
export class OrderEmptyModule { }
