import { State, Selector, Action, StateContext } from '@ngxs/store';
import { SetTotalItemsAndCompletedItems } from './ss-patient-details-form.actions';
import { Injectable } from '@angular/core';

export class SSPatientDetailsFormStateModel {
  totalItems: number;
  completedItems: number;
}
@Injectable()
@State<SSPatientDetailsFormStateModel>({
  name: 'ssPatientDetailsForm',
  defaults: {
    completedItems: 0,
    totalItems: 0
  }
})
export class SSPatientDetailsFormState {

  @Selector()
  static totalItems({ totalItems }: SSPatientDetailsFormStateModel) { return totalItems; }

  @Selector()
  static completedItems({ completedItems }: SSPatientDetailsFormStateModel) { return completedItems; }

  @Action(SetTotalItemsAndCompletedItems)
  setProgress({ patchState }: StateContext<SSPatientDetailsFormStateModel>, { totalItems, completedItems }: SetTotalItemsAndCompletedItems) {
    patchState({ totalItems, completedItems });
  }
}
