<app-header></app-header>
<section class="body-content">
  <div class="main" [ngClass]="contentContainerClasses$ | async">
    <section>
      <app-alert></app-alert>
      <div class="content-block {{showBorder ? 'border__grey' : ''}}" attr.g-xs="{{!isFullWidth ? 'mt5' : ''}}  {{ showBorder ? 'p4' : ''}}">
        <app-breadcrumbs [links]="links"></app-breadcrumbs>
        <app-loader *ngIf="isLoading"></app-loader>
        <router-outlet></router-outlet>
      </div>
    </section>
  </div>
</section>
<app-footer></app-footer>
<app-page-loader *ngIf="pageLoading"></app-page-loader>
