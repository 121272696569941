export interface EScriptRequest {
  scriptToken: string;
  patient?: string;
  notes?: string;
  itemDescription?:string;
  source?: string;
  defer?: boolean;
  scriptType?: EScriptType,
  pickupDate?: Date | string | null;
  generic?: boolean;
  cmi?: boolean;
  patientId?: string;
}

export enum ScriptSource {
  ETP1 = 'ETP1',
  ETP2 = 'ETP2'
}

export enum EScriptType {
  Unknown = 'UNKNOWN',
  InsideRepeat= 'INSIDE_REPEAT',
  EScript= 'E_SCRIPT',
  Manual= 'MANUAL_SCRIPT',
}

export interface MultipleEScriptRequestModel {
  notes: string;
  source?: string;
  patient: string;
  pickupDate: string | null;
  items: EScriptRequest[];
  options: string[];
}
