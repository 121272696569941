import { State, Action, StateContext, Selector } from '@ngxs/store';
import { GetScryptSearchResults, GetScryptSearchResultsSuccess, GetScryptSearchResultsError, ClearScryptSearchResults, ToggleScryptSearchClient } from './scrypt-search.actions';
import { AlertService } from '~/modules/core/alert/alert.service';
import { AdvancedSearchService } from '../services/advanced-search.service';
import { catchError, finalize, tap } from 'rxjs/operators';
import { GetAdvancedSearchResultsError } from './advanced-search.actions';
import { ScryptSearchClient } from '../models/scrypt-search-client.model';
import { Injectable } from '@angular/core';

export class ScryptSearchStateModel {
  public searchResults: ScryptSearchClient[]
  public fetching: boolean;
}
@Injectable()
@State<ScryptSearchStateModel>({
  name: 'scryptSearch',
  defaults: {
    searchResults: [],
    fetching: false
  }
})
export class ScryptSearchState {
  constructor(private alertService: AlertService, private searchService: AdvancedSearchService) { }

  @Selector()
  static searchResults(state: ScryptSearchStateModel) { return state.searchResults };

  @Selector()
  static selectedClients(state: ScryptSearchStateModel) { return state.searchResults.filter(x => x.selected).length }

  @Selector()
  static recipientList(state: ScryptSearchStateModel) {
    return [...new Set(state.searchResults.filter(x => x.selected).map(x => ({
      destination: x.client.mobileNumber ? x.client.mobileNumber : x.client.phoneNumber,
      customerName: `${x.client.firstname} ${x.client.surname}`,
      clientId: x.client.id
    })))]
  }

  @Selector()
  static fetching(state: ScryptSearchStateModel) { return state.fetching };

  @Action(GetScryptSearchResults)
  getScryptSearchResults(ctx: StateContext<ScryptSearchStateModel>, { query }: GetScryptSearchResults) {
    ctx.patchState({ fetching: true });
    return this.searchService.getScryptAdvancedSearchResults(query).pipe(
      tap(res => ctx.dispatch(new GetScryptSearchResultsSuccess(res))),
      catchError(err => ctx.dispatch(new GetAdvancedSearchResultsError(err))),
      finalize(() => ctx.patchState({ fetching: false }))
    );
  }

  @Action(GetScryptSearchResultsSuccess)
  getScryptSearchResultsSuccess(ctx: StateContext<ScryptSearchStateModel>, { results }: GetScryptSearchResultsSuccess) {
    ctx.patchState({ searchResults: results });
  }

  @Action(ToggleScryptSearchClient)
  toggleAdvancedSearchClient(ctx: StateContext<ScryptSearchStateModel>, { id }: ToggleScryptSearchClient) {
    const clients = ctx.getState().searchResults;
    ctx.patchState({ searchResults: clients.map(x => x.client.id == id ? ({ ...x, selected: !x.selected }) : x) });
  }

  @Action(GetScryptSearchResultsError)
  getScryptSearchResultsError(ctx: StateContext<ScryptSearchStateModel>, { error }: GetScryptSearchResultsError) {
    this.alertService.error(error.error);
  }

  @Action(ClearScryptSearchResults)
  clearScryptSearchResults(ctx: StateContext<ScryptSearchStateModel>, { }: ClearScryptSearchResults) {
    ctx.patchState({ searchResults: [] });
  }
}
