import { Directive, ViewContainerRef, Component, OnChanges, Input, ViewChild, Type, ComponentFactoryResolver } from '@angular/core';
import { HasViewContainerRef } from '../../../has-view-container-ref.interface';
import { HMRResolvedForm } from '../../hmr-resolved-form';
import { HMRProposedDetailsFormDetailedComponent } from '../detailed/hmr-proposed-details-form-detailed.component';
import { HMRProposedDetailsFormBasicComponent } from '../basic/hmr-proposed-details-form-basic.component';
import { HMRFormType } from '../../hmr-form-list/state/hmr-form.state';

@Directive({
    selector: '[appHMRProposedDetailsFormHost]'
  })
  export class HMRProposedDetailsFormHostDirective implements HasViewContainerRef {
    constructor(public viewContainerRef: ViewContainerRef) { }
  }

  @Component({
    selector: 'app-hmr-proposed-details-form',
    templateUrl: './hmr-proposed-details-form.component.html'
  })
  export class HMRProposedDetailsFormComponent extends HMRResolvedForm implements OnChanges {
    @Input() formType: HMRFormType;
    @ViewChild(HMRProposedDetailsFormHostDirective, { static: true }) formHost: HMRProposedDetailsFormHostDirective;

    constructor(
      componentFactoryResolver: ComponentFactoryResolver
    ) {
      super(componentFactoryResolver);
    }

    ngOnChanges() {
      this.resolveFormComponent(this.formType);
    }

    protected getFormComponent(formType: HMRFormType): Type<any> {
      switch (formType) {
        case HMRFormType.InterviewRegisteredPhamacist:
          return HMRProposedDetailsFormBasicComponent;
          case HMRFormType.InterviewOutsidePatientHome:
            return HMRProposedDetailsFormDetailedComponent;
      }
    }
  }

