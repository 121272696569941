import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { MailboxMessage, MessageDetail, MessageDetailAttachment } from '~/shared/models/message.model';
import { Channels, MailTabs, MessageSource } from '~/shared/types/communications';
import { SetMessageArchive, SetMessageFlagged, SetMessageRead, SetMessageReplied, ToggleSelectedMessage } from '../../state/communications.actions';
import { ToggleReplyModal, OpenMessageContent } from '../reply-modal/state/chat.actions';
import { replaceEscriptURLWithHTMLLinks } from '~/shared/helpers/eScript.helper';

@Component({
  selector: 'app-inbox-table',
  templateUrl: './inbox-table.component.html',
  styleUrls: ['./inbox-table.component.scss']
})

export class InboxTableComponent implements OnInit {
  @Input() messages: MailboxMessage[];
  @Input() messageType: MailTabs;
  @Input() displayedFromRow;
  @Input() displayedToRow;

  mailTabs = MailTabs;
  channels = [
    {
      name: 'All',
      value: Channels.All
    },
    {
      name: 'SMS',
      value: Channels.Sms,
      icon: 'fal fa-comment-alt-lines'
    },
    {
      name: 'Email',
      value: Channels.Email,
      icon: 'fal fa-envelope'
    },
    {
      name: 'App',
      value: Channels.App,
      icon: 'fas fa-mobile-alt'
    },
  ];
  moreDropdownVisible = false;

  constructor(private store: Store, private router: Router, private domSanitizer: DomSanitizer) { }

  ngOnInit(): void { }

  toggleFlagged(message) {
    this.store.dispatch(new SetMessageFlagged(message, !message.isFlagged, this.messageType));
  }

  toggleRead(message) {
    this.store.dispatch(new SetMessageRead(message, !message.isRead, this.messageType));
  }

  toggleArchived(message) {
    this.store.dispatch(new SetMessageArchive(message, !message.isArchived, this.messageType));
  }

  toggleReplied(message) {
    this.store.dispatch(new SetMessageReplied(message, !message.isReplied, this.messageType));
  }

  onMessageClicked(event, message) {
    var eventId = event.target.id ?? "noId";
    if (eventId === "actions-menu" || eventId === "more-button-text" || eventId === "more-button") {
      this.moreDropdownVisible = !this.moreDropdownVisible;
    } else {
      if (this.messageType === MailTabs.Scheduled) {
        var messageDetail = new MessageDetail("", "", message.content, message.sendDateTime, message.isReplied, message.isRead, message.customerId,
          message.channel, new Array<MessageDetailAttachment>(), message.firstName, message.lastName, "", MessageSource.Pharmacy, message.isFlagged, message.id);
        this.store.dispatch(new ToggleReplyModal(true, messageDetail));
      } else {
        this.store.dispatch(new OpenMessageContent(this.channels[message.channel]?.value, message.messageId, message.customerId));
      }
    }
  }

  changeCheckbox(message, event) {
    this.store.dispatch(new ToggleSelectedMessage(message, event, this.messageType));
  }

  getChannelName(channelId) {
    return this.channels[channelId]?.name ?? "";
  }

  getChannelIcon(channelId) {
    return this.channels[channelId]?.icon ?? "";
  }

  sanitize(html) {
    if (html === null) {
      return "";
    }
    return this.domSanitizer.bypassSecurityTrustHtml(html);
  }

  linkEscripts(html) {
    return replaceEscriptURLWithHTMLLinks(html);
  }

  preserveMergeTags(html) {
    return html.replace(/\<\<(\S*)\>\>/g, '&lt;&lt;$1&gt;&gt;');
  }

}
