import { Trigger } from '../../../../shared/models/pharmacy/trigger.model';

export enum TriggersActions {
  GET_TRIGGERS = '[Triggers]  get triggers',
  GET_TRIGGERS_SUCCESS = '[Triggers] get triggers success',
  ERROR = '[Triggers] error',
  TOGGLE_TRIGGER_ACTIVE = '[Triggers] Toggle trigger',
  TOGGLE_TRIGGER_SUCCESS = '[Triggers] Toggle trigger success'

}

export class TriggerStateError {
  static readonly type = TriggersActions.ERROR;
  constructor(public error: any) { }
}

export class GetTriggers {
  static readonly type = TriggersActions.GET_TRIGGERS;
  constructor() { }
}

export class GetTriggersSuccess {
  static readonly type = TriggersActions.GET_TRIGGERS_SUCCESS;
  constructor(public triggers: Trigger[]) { }
}

export class ToggleTriggerActive {
  static readonly type = TriggersActions.TOGGLE_TRIGGER_ACTIVE;
  constructor(public triggerId: number) { }
}

export class ToggleTriggerSuccess {
  static readonly type = TriggersActions.TOGGLE_TRIGGER_SUCCESS;
  constructor(public triggerId: number) { }
}
