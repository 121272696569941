<section  class="comms-list mt-4">
  <div
  (click)="toggleCommsTable()"
  class="flex justify-content-between align-items-center pt-2 px-3 pb-1 pointer">
    <div class="font__title-4">
      {{getTypeName()}}
      <i *ngIf="commsTableOption == 'in'" class="fas fa-caret-up"></i>
      <i *ngIf="commsTableOption == 'out'" class="fas fa-caret-down"></i>
    </div>
  </div>
  <div [@accordionAnimation]="commsTableOption">
    <div *ngFor="let template of commTemplates">
      <div class="template-card">
        <table>
          <thead>
            <tr>
              <th>Message Name</th>
              <th>Subject Line</th>
              <th class="w-50">Content</th>
              <th></th>
              <th *ngIf="templateType !== templateTypesEnum.Default"></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{{template.name}}</td>
              <td>{{template.subject}}</td>
              <td class="w-50">{{template.message}}</td>
              <td>
                <button (click)="edit(template)" class="btn btn btn-outline-secondary">Edit</button>
              </td>
              <td *ngIf="templateType !== templateTypesEnum.Default">
                <button (click)="delete(template)" class="btn btn btn-outline-secondary float-right">Delete</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <app-fluid-modal *ngIf="confirmationRequired" 
  (submitEvent)="confirm()"
  (closeEvent)="dismiss()"
  title="Confirm" 
  message="Are you sure you want to delete this message?">
</app-fluid-modal>
</section>
