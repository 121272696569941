<ng-container *ngIf="showRecipients">
  <div class="modal">
    <div class="modal-dialog modal-xl" role="document">
      <div class="modal-content">
        <div class="modal-header modal-heading">
          <h5 class="modal-title">Bulk Comminication</h5>
          <button type="button" (click)="closeModal()" class="close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div class="modal-body border__grey" *ngIf="!isLoading && bulkMessage; else loading">
          <table class="statistics-table">
            <thead>
              <tr>
                <th nzAlign="left">Created</th>
                <th nzAlign="left">Scheduled</th>
                <th nzAlign="right">Character Count</th>
                <th nzAlign="right">{{isHistoric ? '' : 'Successful '}}Recipients</th>
                <th nzAlign="right">Failed Recipients</th>
                <th nzAlign="center">Export as CSV</th>
                <th *ngIf="showAddToNewMessage" nzAlign="center">Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td nzAlign="left">{{ bulkMessage.createdDate | tenantDate: "DD/MM/YYYY hh:mm A" }}</td>
                <td nzAlign="left">{{ bulkMessage.scheduledFrom | tenantDate: "DD/MM/YYYY hh:mm A" }}</td>
                <td nzAlign="right">{{ bulkMessage.message.length }}</td>
                <td nzAlign="right">{{ getSuccessfulRecipients() }}</td>
                <td nzAlign="right">{{ getfailedRecipients() }}</td>
                <td nzAlign="center" class="download-icon" (click)="exportRecipients()">
                  <button class="btn" [disabled]="!bulkMessage?.recipients.length" >
                    <i class="far fa-file-download"></i>
                  </button>
                </td>
                <td *ngIf="showAddToNewMessage"  nzAlign="center">
                  <button class="btn btn-warning color__dark-blue" (click)="addToBulkMessage()" [disabled]="!bulkMessage?.recipients.length" >
                    <i class="far fa-plus"></i>&nbsp; Copy Recipients to New Message
                  </button>
                </td>
              </tr>
            </tbody>
          </table>

          <div class="message">
            <p><strong>Message</strong></p>
            <p class="mb-3">{{ bulkMessage.message }}</p>
          </div>
          <div>
            <p><strong>Recipient List ({{bulkMessage.recipients?.length ?? 0}})</strong></p>
          </div>
          <div class="table-size">
            <nz-table 
              class="table bordered recipients-table" 
              #fixedTable 
              [nzData]="bulkMessage?.recipients"
              [nzFrontPagination]="false"
              [nzShowPagination]="false"
              [nzVirtualScroll]="true"
            >
              <thead>
                <tr>
                  <th id="recipient-name" nzSortOrder="null" [nzSortFn]="sortFnName">Name</th>
                  <th id="recipient-mobile" nzSortOrder="null" [nzSortFn]="sortFnMobileNumber">Mobile Number</th>
                  <th id="recipient-status" nzSortOrder="null" [nzSortFn]="sortFnStatus">Status</th>
                </tr>
              </thead>
              <tbody>
                <ng-container *ngFor="let recipient of fixedTable.data">
                  <tr>
                    <td class="recipients-table-width">{{recipient.customerName}}</td>
                    <td>{{recipient.destination}}</td>
                    <td>{{recipientStatus[recipient.status]}}</td>
                  </tr>
                </ng-container>
              </tbody>
            </nz-table>
          </div>
        </div>

        <ng-template #loading>
          <div class="modal-body border__grey">
            <div>
              <app-loader loadingMessage="Fetching recipients..."></app-loader>
            </div>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
  <div class="modal-background"></div>
</ng-container>