import { Injectable } from "@angular/core";
import { Resolve } from "@angular/router";
import { Store } from "@ngxs/store";
import { Observable } from "rxjs";
import { FetchTenantCategoriesAction } from "~/modules/profile/tenant-categories/state/tenant-category.actions";
import { GetGroupList } from "~/system/group-page/state/group-page.actions";

@Injectable()
export class DispenseRunResolver implements Resolve<Observable<any>> {
  constructor(private store: Store) { }
  resolve() {
    return this.store.dispatch([
      new FetchTenantCategoriesAction(),
      new GetGroupList()
    ]);
  }
}