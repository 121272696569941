<h3 class="title">New Service</h3>
<div class="bg-white">
    <div class="upgrade-title d-flex align-items-center">
        <i class="fal fa-lock"></i>
        <h4 class="ml-3">Upgrade to unlock this feature and more</h4>
    </div>
    <div class="check-list d-flex">
        <div class="left">
            <h5><strong>Features of Medscheck service:</strong></h5>
            <div class="check-list-item d-flex align-items-center">
                <span class="icon">
                    <img src="https://scrypt.blob.core.windows.net/icons/PPA.svg" alt="ppa">
                </span>
                <p>Integrated with PPA portal so claim immediately</p>
            </div>
            <div class="check-list-item d-flex align-items-center">
                <i class="fal fa-cloud"></i>
                <p>Cloud based - use ScryptLite in dispense, browser or tablet</p>
            </div>
            <div class="check-list-item d-flex align-items-center">
                <i class="fal fa-file-upload"></i>
                <p>Upload consent & store completed services as documents</p>
            </div>
            <div class="check-list-item d-flex align-items-center">
                <i class="fal fa-paper-plane"></i>
                <p>Send reports to patient or GP by SMS, email or via mobile App</p>
            </div>
            <div class="check-list-item d-flex align-items-center">
                <i class="fal fa-id-card"></i>
                <p>Medication profile, health & patient details deeply  integrated with dispensary</p>
            </div>
        </div>
        <div class="right">
            <h5 class="mb-3"><strong>And unlock other services:</strong></h5>
            <div class="d-flex align-items-center mb-3"><i class="far fa-check"></i> <p class="ml-3">Medschecks</p></div>
            <div class="d-flex align-items-center mb-3"><i class="far fa-check"></i> <p class="ml-3">Sick leave certificates</p></div>
            <div class="d-flex align-items-center mb-3"><i class="far fa-check"></i> <p class="ml-3">Medication Lists</p></div>
            <div class="d-flex align-items-center mb-3"><i class="far fa-check"></i> <p class="ml-3">HMR claims, DAA & Staged Supply</p></div>
            <div class="d-flex align-items-center mb-3"><i class="far fa-check"></i> <p class="ml-3">Clinical Interventions</p></div>
            <div class="mb-4"></div>
            <h5 class="mb-3"><strong>Plus access to Scrypt patient App & Orders</strong></h5>
            <div class="d-flex align-items-center mb-3"><i class="far fa-check"></i> <p class="ml-3">ePrescription conformant to Australian Digital Health Agency standards</p></div>
            <div class="d-flex align-items-center mb-3"><i class="far fa-check"></i> <p class="ml-3">Profile medication management</p></div>
            <div class="d-flex align-items-center mb-3"><i class="far fa-check"></i> <p class="ml-3">Ordering capabilities & patient reminders</p></div>
            <div class="d-flex align-items-center mb-3"><i class="far fa-check"></i> <p class="ml-3">Messenger/chat function with patient & pharmacy</p></div>
        </div>
    </div>
    <div class="hr mt-5 mb-5"></div>
    <p class="text-gray text-center mt-3">
        Have you claimed your 20 MedsChecks this month? Are you struggling to <br /> complete these services due to cumbersome workflows?
    </p>
    <h4 class="text-gray bold text-center mt-3 mb-5">
        You could be missing out on up to $24,000 per year!
    </h4>
    <div class="message-us d-flex justify-content-center align-items-center">
        <i class="fab fa-intercom mr-3"></i>
        <p class="bold text-center">Message us via Intercom below, to upgrade today! </p>
    </div>
</div>
