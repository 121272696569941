<form [formGroup]="sickLeaveConsentAndEligibilityForm" ngxsForm="sickLeaveConsentAndEligibility.sickLeaveConsentAndEligibilityForm"
  class="p-4">
  <div class="form-group pb-1">
    <label class="input-label mb-0">Advised you are not a medical practitioner and any opinion you have provided has been provided as a pharmacist, NOT a doctor</label><br />
    <app-radio-highlight controlName="advisedYouAreNotMedicalPractitionerYes"
    [hasError]="formControls.advisedYouAreNotMedicalPractitioner.invalid && (showInvalid$ | async)"
      [isHighlighted]="sickLeaveConsentAndEligibilityForm.value.advisedYouAreNotMedicalPractitioner == true">
      <input class="form-check-input" type="radio" name="advisedYouAreNotMedicalPractitioner" id="advisedYouAreNotMedicalPractitionerYes"
        formControlName="advisedYouAreNotMedicalPractitioner" [value]="true" checked>
      <label class="form-check-label" for="advisedYouAreNotMedicalPractitionerYes">Yes</label>
    </app-radio-highlight>
    <app-radio-highlight controlName="advisedYouAreNotMedicalPractitionerNo"
    [hasError]="formControls.advisedYouAreNotMedicalPractitioner.invalid && (showInvalid$ | async)"
      [isHighlighted]="sickLeaveConsentAndEligibilityForm.value.advisedYouAreNotMedicalPractitioner == false">
      <input class="form-check-input" type="radio" name="advisedYouAreNotMedicalPractitioner" id="advisedYouAreNotMedicalPractitionerNo"
        formControlName="advisedYouAreNotMedicalPractitioner" [value]="false">
      <label class="form-check-label" for="advisedYouAreNotMedicalPractitionerNo">No</label>
    </app-radio-highlight>
  </div>
  <div class="form-group pb-1">
    <label class="input-label mb-0">Reinforced that the consultation was conducted for the purpose of assessing the person's fitness to work and is not intended to replace medical treatment, opinion or diagnosis; and</label><br />
    <app-radio-highlight controlName="consultationConsentYes"
    [hasError]="formControls.consultationWasConductedForAssessingPersonFitnessToWork.invalid && (showInvalid$ | async)"
      [isHighlighted]="sickLeaveConsentAndEligibilityForm.value.consultationWasConductedForAssessingPersonFitnessToWork == true">
      <input class="form-check-input" type="radio" name="consultationWasConductedForAssessingPersonFitnessToWork" id="consultationConsentYes"
        formControlName="consultationWasConductedForAssessingPersonFitnessToWork" [value]="true" checked>
      <label class="form-check-label" for="consultationConsentYes">Yes</label>
    </app-radio-highlight>
    <app-radio-highlight controlName="consultationConsentNo"
    [hasError]="formControls.consultationWasConductedForAssessingPersonFitnessToWork.invalid && (showInvalid$ | async)"
      [isHighlighted]="sickLeaveConsentAndEligibilityForm.value.consultationWasConductedForAssessingPersonFitnessToWork == false">
      <input class="form-check-input" type="radio" name="consultationWasConductedForAssessingPersonFitnessToWork" id="consultationConsentNo"
        formControlName="consultationWasConductedForAssessingPersonFitnessToWork" [value]="false">
      <label class="form-check-label" for="consultationConsentNo">No</label>
    </app-radio-highlight>
  </div>
  <div class="form-group pb-1">
    <label class="input-label mb-0">Advised that if the illness/injury persists or worsens, the person/member should see their general practitioner </label><br />
    <app-radio-highlight controlName="advisedIllnessYes"
    [hasError]="formControls.advisedIfIllnessPersistsPersonShouldSeePractitioner.invalid && (showInvalid$ | async)"
      [isHighlighted]="sickLeaveConsentAndEligibilityForm.value.advisedIfIllnessPersistsPersonShouldSeePractitioner == true">
      <input class="form-check-input" type="radio" name="advisedIfIllnessPersistsPersonShouldSeePractitioner" id="advisedIllnessYes"
        formControlName="advisedIfIllnessPersistsPersonShouldSeePractitioner" [value]="true" checked>
      <label class="form-check-label" for="advisedIllnessYes">Yes</label>
    </app-radio-highlight>
    <app-radio-highlight controlName="advisedIllnessNo"
    [hasError]="formControls.advisedIfIllnessPersistsPersonShouldSeePractitioner.invalid && (showInvalid$ | async)"
      [isHighlighted]="sickLeaveConsentAndEligibilityForm.value.advisedIfIllnessPersistsPersonShouldSeePractitioner == false">
      <input class="form-check-input" type="radio" name="advisedIfIllnessPersistsPersonShouldSeePractitioner" id="advisedIllnessNo"
        formControlName="advisedIfIllnessPersistsPersonShouldSeePractitioner" [value]="false">
      <label class="form-check-label" for="advisedIllnessNo">No</label>
    </app-radio-highlight>
  </div>
</form>
