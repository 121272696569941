import { State, Action, StateContext, Selector } from '@ngxs/store';
import { SetSixCpaErrors, SetSixCpaSuccessMessages, ClearSixCpaCommunications, SetSixCpaInfo } from './six-cpa-user-communications.actions';
import { Injectable } from '@angular/core';


export enum SixCpaUserCommunicationTypes {
  success = 'success',
  info = 'info',
  error = 'error'
}

export class SixCpaUserCommunicationsStateModel {
  messages: any[];
}
@Injectable()
@State<SixCpaUserCommunicationsStateModel>({
  name: 'sixCpaUserCommunications',
  defaults: {
    messages: []
  }
})
export class SixCpaUserCommunicationsState {
  @Selector()
  static messages(state: SixCpaUserCommunicationsStateModel) { return state.messages }

  @Action(SetSixCpaErrors)
  setSixCpaErrors(ctx: StateContext<SixCpaUserCommunicationsStateModel>, action: SetSixCpaErrors) {
    const state = ctx.getState();
    ctx.patchState({
      messages: [...state.messages, ...action.errors.map(error => { return {
        type: SixCpaUserCommunicationTypes.error,
        message: error.status !== 403 ? error : "You do not have permission to submit service forms. If you believe this is an error, please contact Scrypt Support via Intercom" } })]
    })
  }

  @Action(SetSixCpaInfo)
  setSixCpaInfo(ctx: StateContext<SixCpaUserCommunicationsStateModel>, action: SetSixCpaInfo) {
    const state = ctx.getState();
    ctx.patchState({
      messages: [...state.messages, ...action.info.map(info => { return { type: SixCpaUserCommunicationTypes.info, message: info } })]
    })
  }


  @Action(SetSixCpaSuccessMessages)
  SetSixCpaSuccessMessages(ctx: StateContext<SixCpaUserCommunicationsStateModel>, action: SetSixCpaSuccessMessages) {
    const state = ctx.getState();
    const infoMessages = state.messages.filter(message => message.type == SixCpaUserCommunicationTypes.info);

    if (!infoMessages || !infoMessages.length) {
      ctx.patchState({
        messages: [...state.messages, ...action.successMessages.map(success => { return { type: SixCpaUserCommunicationTypes.success, message: success } })]
      });
    }
  }

  @Action(ClearSixCpaCommunications)
  clearSixCpaCommunications(ctx: StateContext<SixCpaUserCommunicationsStateModel>, action: ClearSixCpaCommunications) {
    ctx.patchState({
      messages: []
    });
  }

}
