import { InviteType } from '~/shared/models/profile/inviteType.enum';
import { ClientViewModel } from '~/modules/core/profile/client.model';

export class InviteToSignUpAction {
  static readonly type = '[InviteToSignUp] Add item';
  constructor(public payload: string) { }
}

export enum InviteToSignUpActions {
  OPEN_MODAL = '[InviteToSignUp] open modal',
  CLOSE_MODAL = '[InviteToSignUp] close modal',
  SET_INVITE_FORM = '[InviteToSignUp] set form',
  REQUEST = '[InviteToSignUp] request',
  CUSTOM_REQUEST = '[InviteToSignUp] custom request',
  REQUEST_SUCCESS = '[InviteToSignUp] request success',
  ERROR = '[InviteToSignUp] error',
  CHECK_CREDENTIALS_AVAILABILITY = '[InviteToSignUp] CHECK_CREDENTIALS_AVAILABILITY',

}

export class CloseInviteToSignUpModal {
  static readonly type = InviteToSignUpActions.CLOSE_MODAL;
}

export class OpenInviteToSignUpModal {
  static readonly type = InviteToSignUpActions.OPEN_MODAL;
}
export class SetInviteToSignUpForm {
  static readonly type = InviteToSignUpActions.SET_INVITE_FORM;
  constructor(public profile: ClientViewModel) { }
}
export class RequestInviteToSignUp {
  static readonly type = InviteToSignUpActions.REQUEST;
  constructor(public inviteType: InviteType) { }
}

export class CustomRequestInviteToSignUp {
  static readonly type = InviteToSignUpActions.CUSTOM_REQUEST;
  constructor(public inviteType: InviteType, public email: string, public mobile: string) { }
}

export class RequestInviteToSignUpSuccess {
  static readonly type = InviteToSignUpActions.REQUEST_SUCCESS;
}
export class RequestInviteToSignUpError {
  static readonly type = InviteToSignUpActions.ERROR;
  constructor(public error: any) { }
}

export class CheckInviteCredentialsAvailability {
  static readonly type = InviteToSignUpActions.CHECK_CREDENTIALS_AVAILABILITY;
  constructor(public mobileNumber: string, public emailAddress: string) { }
}
