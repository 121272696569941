import { Selector } from '@ngxs/store';
import * as moment_ from 'moment';
const moment = moment_;
import { SickLeaveConsentAndEligibilityState } from '../../sick-leave-consent-and-eligibility/state/sick-leave-consent-and-eligibility.state';
import { SickLeaveStateModel, SickLeaveState } from './sick-leave.state';
import { SickLeaveConsentAndEligibilityFormModel } from '../../../../../../shared/models/six-cpa/sick-leave/sick-leave-consent-and-eligibility-form.model';
import { SickLeaveFormModel } from '../../../../../../shared/models/six-cpa/sick-leave/sick-leave-form.model';
import { SickLeaveCertificateTypeFormModel } from '../../../../../../shared/models/six-cpa/sick-leave/sick-leave-certificate-type-form.model';
import { SickLeaveCertificateTypeState } from '../../sick-leave-certificate-type/state/sick-leave-certificate-type.state';
import { SickLeaveConsulationState } from '../../sick-leave-consulation/state/sick-leave-consulation.state';
import { SickLeaveConsulationFormModel } from '../../../../../../shared/models/six-cpa/sick-leave/sick-leave-consultation-form.model';
import { SickLeavePharmacistDeclarationState } from '../../sick-leave-pharmacist-declaration/state/sick-leave-pharmacist-declaration.state';
import { SickLeavePharmacistDeclarationFormModel } from '../../../../../../shared/models/six-cpa/sick-leave/sick-leave-pharmacist-declaration-form.model';
import { SickLeavePatientDetailsState } from '../../sick-leave-patient-details/state/sick-leave-patient-details.state';
import { SickLeavePatientDetailsFormModel } from '../../../../../../shared/models/six-cpa/sick-leave/sick-leave-patient-details-form.model';

export class SickLeaveFormsService {
  @Selector([
    SickLeaveState,
    SickLeavePatientDetailsState.sickLeavePatientDetailsForm,
    SickLeaveConsentAndEligibilityState.sickLeaveConsentAndEligibilityForm,
    SickLeaveCertificateTypeState.sickLeaveCertificateTypeForm,
    SickLeaveConsulationState.sickLeaveConsulationForm,
    SickLeavePharmacistDeclarationState.sickLeavePharmacistDeclarationForm,
  ])
  static getSickLeaveRequest(
    sickLeaveState: SickLeaveStateModel,
    patientDetails: SickLeavePatientDetailsFormModel,
    consentEligibility: SickLeaveConsentAndEligibilityFormModel,
    certificateType: SickLeaveCertificateTypeFormModel,
    consulation: SickLeaveConsulationFormModel,
    pharmacistDeclaration: SickLeavePharmacistDeclarationFormModel
    ): SickLeaveFormModel {
    const patientDetailsForm = this.getPatientDetailsData(sickLeaveState.patientDetailsFormId, patientDetails);
    const consentAndEligibilityForm = this.getConsentAndEligibilityData(sickLeaveState.consentAndEligibilityFormId, consentEligibility);
    const certificateTypeForm = this.getCertificateTypeData(sickLeaveState.certificateTypeFormId, certificateType);
    const consulationForm = this.getConsulationData(sickLeaveState.consulationFormId, consulation);
    const pharmacistDeclarationForm = this.getPharmacistDeclarationData(sickLeaveState.pharmacistDeclarationFormId, pharmacistDeclaration);
    return {
      id: sickLeaveState.sickLeaveId,
      sixCpaFormId: sickLeaveState.sixCpaFormId,
      patientDetailsFormId: sickLeaveState.patientDetailsFormId,
      patientDetailsForm,
      consentAndEligibilityFormId: sickLeaveState.consentAndEligibilityFormId,
      consentAndEligibilityForm,
      certificateTypeFormId: sickLeaveState.certificateTypeFormId,
      certificateTypeForm,
      consulationFormId: sickLeaveState.consulationFormId,
      consulationForm,
      pharmacistDeclarationFormId: sickLeaveState.pharmacistDeclarationFormId,
      pharmacistDeclarationForm
    };
  }
  private static getPatientDetailsData(id: number, patientDetails: SickLeavePatientDetailsFormModel) {
    return patientDetails ? {
      id,
      dob: moment(patientDetails.dob).toDate(),
      firstName: patientDetails.firstName,
      surname: patientDetails.surname,
      address: patientDetails.address
    } : null;
  }
  private static getConsentAndEligibilityData(id: number, consentEligibility: SickLeaveConsentAndEligibilityFormModel) {
    return consentEligibility ? {
      id,
      advisedYouAreNotMedicalPractitioner: consentEligibility.advisedYouAreNotMedicalPractitioner,
      consultationWasConductedForAssessingPersonFitnessToWork: consentEligibility.consultationWasConductedForAssessingPersonFitnessToWork,
      advisedIfIllnessPersistsPersonShouldSeePractitioner: consentEligibility.advisedIfIllnessPersistsPersonShouldSeePractitioner
    } : null;
  }

  private static getCertificateTypeData(id: number, certificateType: SickLeaveCertificateTypeFormModel) {
    return certificateType ? {
      id,
      seekingCertificateFor: certificateType.seekingCertificateFor,
      satisfiedThatItIsInRelationToMember: certificateType.satisfiedThatItIsInRelationToMember,
      membersDetails: certificateType.membersDetails,
      membersName: certificateType.membersName
    } : null;
  }

  private static getConsulationData(id: number, consulation: SickLeaveConsulationFormModel) {
    return consulation ? {
      id,
      whatIsTheIllness: consulation.whatIsTheIllness,
      describeVisualSymptoms: consulation.describeVisualSymptoms,
      howLongExperiencedSymptoms: `${consulation.howLongExperiencedSymptomsInterval} ${consulation.howLongExperiencedSymptomsPeriod}`,
      howLongExperiencedSymptomsInterval: consulation.howLongExperiencedSymptomsInterval,
      howLongExperiencedSymptomsPeriod: consulation.howLongExperiencedSymptomsPeriod,
      hasPersonConsultedADoctor: consulation.hasPersonConsultedADoctor,
      isThereAPriorCertificate: consulation.isThereAPriorCertificate,
      isAMedicationBeingProvidedByYou: consulation.isAMedicationBeingProvidedByYou,
      satisfiedThePersonIsUnfitForWork: consulation.satisfiedThePersonIsUnfitForWork,
      dateCertificateFrom: moment(consulation.dateCertificateFrom).toDate(),
      dateCertificateTo: moment(consulation.dateCertificateTo).toDate(),
      haveReferredToAnyOtherHealthCareProfessional: consulation.haveReferredToAnyOtherHealthCareProfessional,
      detailOfConsultation: consulation.detailOfConsultation,
      dateOfCertificate: moment(consulation.dateOfCertificate).toDate(),
      medicationDetail: consulation.medicationDetail,
      referralDetail: consulation.referralDetail,
    } : null;
  }

  private static getPharmacistDeclarationData(id: number, pharmacistDeclaration: SickLeavePharmacistDeclarationFormModel) {
    return pharmacistDeclaration ? {
      id,
      haveDeliveredService: pharmacistDeclaration.haveDeliveredService,
      dateOfService: moment(pharmacistDeclaration.dateOfService).toDate(),
      pharmacistGivenName: pharmacistDeclaration.pharmacistGivenName,
      pharmacistFamilyName: pharmacistDeclaration.pharmacistFamilyName,
      ahpraNumber: pharmacistDeclaration.ahpraNumber
    } : null;
  }
}
