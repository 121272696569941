import * as moment from 'moment-timezone';

export class DatesService {
  public static ToUtc(dtm) {
    return dtm.toISOString();
  }

  public static ToLocal(dtm, timezone?) {
    if (!timezone) { timezone = moment.tz.guess(); }
    return moment(dtm).tz(timezone).format();
  }
  public static ToEndOfLocalDayInUtc(dtm, timezone?) {
    if (!timezone) { timezone = moment.tz.guess(); }

    let localTime = moment(dtm).tz(timezone);

    localTime = localTime.endOf('day')

    return new Date(localTime.toISOString());
  }

  public static ToStartOfLocalDayInUtc(dtm, timezone?) {
    if (!timezone) { timezone = moment.tz.guess(); }

    let localTime = moment(dtm).tz(timezone);

    localTime = localTime.startOf('day')
    return new Date(localTime.toISOString());
  }
}
