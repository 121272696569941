import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import { BaseApi } from '../../../shared/core/base-api/base-api';
import { Router } from '@angular/router';
import { StorageService } from '../../../shared/core/storage/storage.service';
import { Client } from '../../../shared/models/client.model';
import { AlertService } from '../alert/alert.service';
import { HttpClient } from '@angular/common/http';
import { OptOutUpdateModal } from '../../../modules/profile/primary-contact/models/primary-contact-summary.model';

@Injectable()
export class ClientRepository extends BaseApi {
  API = '';
  constructor(
    @Inject('environment') environment: any,
    public http: HttpClient,
    public router: Router,
    public storageService: StorageService,
    public alertService: AlertService
  ) {
    super(http, router, storageService, alertService);

    this.API = environment.baseApiUrl;
  }

  getClientSearch(lastName: string, firstName: string, phoneNumber: string) {
    return this.get(
      `${this.API}/api/GetClientSearchList?firstname=${firstName}&lastname=${lastName}&phoneNumber=${phoneNumber}`
    );
  }

  getClientInfo(clientId: string): Observable<Client> {
    return this.get(`${this.API}/api/GetClientInfo/${clientId}`);
  }

  getCustomers(count: number): Observable<any[]> {
    return this.get(`${this.API}/api/leads/latest?count=${count}`);
  }

  getSearchClient(name: string): Observable<any[]> {
    return this.get(`${this.API}/api/leads/latest?count=${name}`);
  }

  getProfile(clientId: string): Observable<any> {
    return this.get(`${this.API}/api/client/getProfile/${clientId}`);
  }

  optOutLookup(MobileNumber: string): Observable<any> {
    return this.get(`${this.API}/api/OptOut/lookup?mobileNumber=${MobileNumber}`);
  }

  optOutUpdate(optOutModal: OptOutUpdateModal): Observable<any> {
    return this.post(`${this.API}/api/OptOut/update`, optOutModal);
  }
}
