import { NgModule } from "@angular/core";
import { SSPatientDetailsListItemComponent } from "./ss-patient-details-list-item.component";
import { CommonModule } from "@angular/common";
import { SixCpaFormListItemChecklistModule } from "../../six-cpa-form-list-item-checklist/six-cpa-form-list-item-checklist.module";
import { SSPatientDetailsFormModule } from "../patient-details-form/ss-patient-details-form.module";

@NgModule({
  declarations: [
    SSPatientDetailsListItemComponent
  ],
  imports: [
    CommonModule,
    SixCpaFormListItemChecklistModule,
    SSPatientDetailsFormModule
  ],
  exports: [
    SSPatientDetailsListItemComponent
  ]
})
export class SSPatientFormListItemModule { }
