import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MedsCheckSendDocumentModalComponent } from './meds-check-send-document-modal.component';
import { SendDocumentModalComponent } from './send-document-modal/send-document-modal.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import { NgxsModule } from '@ngxs/store';
import { ConfirmMedsCheckState } from '../../confirm-meds-check/state/confirm-meds-check.state';

@NgModule({
  declarations: [MedsCheckSendDocumentModalComponent, SendDocumentModalComponent],
  imports: [
    NgxsModule.forFeature([
      ConfirmMedsCheckState
    ]),
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxsFormPluginModule
  ],
  exports: [MedsCheckSendDocumentModalComponent]
})
export class MedsCheckSendDocumentModalModule { }
