<table>
  <tbody>
    <ng-container *ngFor="let group of getRecords(scriptHistory)">
      <tr>
        <td colspan="14">
          <div *ngIf="scriptGroups[group]" class="original-script-header">
            <div>
              <app-original-script [canShowHistory]="showGroup(group)" [originalScript]="getOriginalScript(group)"
                [remainingRepeats]="getRepeatsRemaining(group)"
                (toggleAvailability)="toggleScriptAvailability(getOriginalScript(group))"
                (toggleGroupEvent)="toggleGroup(group)" [isScriptValid]="getScriptValidity(group)">
              </app-original-script>
            </div>
          </div>
        </td>
      </tr>
      <ng-container *ngIf="showGroup(group)" class="border-container">
        <tr class="font-weight-bold">
          <td></td>
          <td>Type</td>
          <td>Dispensed</td>
          <td>Script</td>
          <td>Original</td>
          <td>Dispensed</td>
          <td>Repeat</td>
          <td>eScript</td>
          <td>Dispense</td>
        </tr>
        <ng-container *ngFor="let script of scriptGroups[group] | orderByOriginalScript">
          <tr
            [ngClass]="(!script.originalScriptNo || (script.originalScriptNo == script.scriptNo)) ? 'original-script': ''">
            <td style="text-align: end;">
              <span *ngIf="script.isPassiveScript">
                <i class="fas fa-trash-alt" style="color: indianred;"
                  (click)="removePassiveScript(script.passiveScriptId)"></i>
              </span>
            </td>
            <td class="pointer" (mouseenter)="showScriptType(true)" (mouseleave)="showScriptType(false)">
              <span *ngIf="!showType">{{getScriptType(script)}}</span>
              <span *ngIf="showType">{{getFullScriptType(script)}}</span>
            </td>
            <td>
              {{script.dispensedDate | tenantDate}}
            </td>
            <td>
              <span *ngIf="script.scriptNo && !script.isPassiveScript">{{script.scriptNo}}</span>
              <span *ngIf="!script.scriptNo || script.isPassiveScript" style="font-style: italic;">Outside</span>
            </td>
            <td>
              <span *ngIf="script.originalScriptNo; else noScriptNo">{{script.originalScriptNo}}</span>
            </td>
            <td>
              {{script.repeats}} /
              <span *ngIf="script.timesDispensed; else zero">{{script.timesDispensed}}</span>
            </td>
            <td>
              <span *ngIf="!script.editMode; else editMode">
                <span *ngIf="!script.isRepeatFilled; else notDispensable">
                  <span
                    [ngClass]="(script.repeats - script.timesDispensed) > 0 ? 'okay':'not-okay'">{{script.repeats - script.timesDispensed}}</span>
                  <span *ngIf="script.isPassiveScript" (click)="editRepeats(script)"><i
                      class="fal fa-pencil edit"></i></span>
                </span>
              </span>
              <ng-template #notDispensable>
                <span>{{script.repeats - script.timesDispensed}}</span>
                <span *ngIf="script.isPassiveScript" (click)="editRepeats(script)"><i
                    class="fal fa-pencil edit"></i></span>
              </ng-template>
              <ng-template #editMode>
                <input class="edit-input" [(ngModel)]="script.repeats" *ngIf="script.editMode" type="number" />
                <span><i (click)="save(script)" class="fal fa-save save"></i></span>
              </ng-template>
            </td>
            <td>
              <span *ngIf="script.isPaperless; else no">Yes</span>
            </td>
            <td>
              <span *ngIf="!script.isRepeatFilled && script.enabled">
                <button class="btn btn-outline-dark btn-sm select-button"
                  [ngClass]="!script.isDeferred && (script.repeats - script.timesDispensed) <= 0 ? 'disabled': ''"
                  (click)="toggleDispensingList(script)">
                  select
                </button>
              </span>
            </td>
          </tr>
        </ng-container>
      </ng-container>
    </ng-container>
  </tbody>
</table>

<ng-template #noScriptNo>
  None
</ng-template>
<ng-template #zero>
  <span>0</span>
</ng-template>
<ng-template #no>
  <span>No</span>
</ng-template>
<ng-container *ngIf="(loading$ | async) as loading">
  <app-loader loadingMessage="One sec..."></app-loader>
</ng-container>
