import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { slideState, slideLeft } from '../../../shared/core/animations';
import { Store, Select } from '@ngxs/store';
import { ToggleSixCpaAction, StartNewSixCpaAction, SetSelectedFormType, ClearSixCpaFormService } from './state/six-cpa.actions';
import { SixCpaState } from './state/six-cpa.state';
import { Observable } from 'rxjs';
import { ClientViewModel } from '../../core/profile/client.model';
import { ConfirmMedsCheckState } from './confirm-meds-check/state/confirm-meds-check.state';
import { GoBackFromConfirmMedsCheckAction } from './confirm-meds-check/state/confirm-meds-check.actions';
import { SickLeaveConfirmState } from './sick-leave/sick-leave-confirm/state/sick-leave-confirm.state';
import { GoBackFromSickLeaveConfirmAction } from './sick-leave/sick-leave-confirm/state/sick-leave-confirm.actions';
import { ClearSixCpaCommunications } from './six-cpa-user-communications/state/six-cpa-user-communications.actions';
import { GoBackFromMedicationListConfirm } from './medication-list/medication-list-confirm/state/medication-list-confirm.actions';
import { MedicationListConfirmState } from './medication-list/medication-list-confirm/state/medication-list-confirm.state';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { GetPharmacists } from '../../pharmacy/state/pharmacist.actions';
import { PharmacistState } from '@base/modules/pharmacy/state/pharmacist.state';
import { SixCpaFormTypes } from './six-cpa-form-types.enum';
import { FeaturesState, FeaturesStateModel } from '../../features/features.state';
import { FeatureDecisions } from '@base/modules/core/features/feature-decisions.service';
import { SixCpaStateModel } from './state/six-cpa.state';
import { withLatestFrom } from 'rxjs/operators';

@Component({
  selector: 'app-six-cpa',
  templateUrl: './six-cpa.component.html',
  styleUrls: ['./six-cpa.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    slideState,
    slideLeft
  ]
})
export class SixCpaComponent implements OnInit {
  @Select(SixCpaState) sixCpa$: Observable<SixCpaStateModel>;
  @Select(SixCpaState.profile) profile$: Observable<ClientViewModel>;
  @Select(SixCpaState.getProgress) progress$: Observable<number>;
  @Select(ConfirmMedsCheckState.slideMode) slideMode$: Observable<any>;
  @Select(SickLeaveConfirmState.slideMode) sickLeaveSlideMode$: Observable<any>;
  @Select(MedicationListConfirmState.slideMode) medListSlideMode$: Observable<any>;
  @Select(FeaturesState.getFeatures) features$: Observable<FeaturesStateModel>;

  CPA_Version = '';
  formTypes = SixCpaFormTypes;
  activeServiceType: SixCpaFormTypes;


  constructor(
    private store: Store,
    @Inject('environment') env: any,
    public featureDecisions: FeatureDecisions
  ) {
    this.CPA_Version = env.CPAVersion;
  }

  ngOnInit() {
    this.store.select(SixCpaState.activeServiceType)
      .subscribe(serviceType => this.activeServiceType = serviceType);

    const pharmasists = this.store.selectSnapshot(PharmacistState.pharmacists);
    if (pharmasists.length < 1) {
      this.store.dispatch([new GetPharmacists()]);
    }
  }

  canOpenServiceForm () {
    return this.featureDecisions.isAllowedToEditServiceForm()
      .pipe(withLatestFrom(this.sixCpa$, (isAllowedToEditServiceForm, sixCpaState) => isAllowedToEditServiceForm || sixCpaState.readOnly));
  }

  startNewCpa() {
    this.store.dispatch(new StartNewSixCpaAction(true));
  }

  toggle(mode: string) {
    this.store.dispatch([new  ClearSixCpaFormService(), new ToggleSixCpaAction(mode === 'in' ? 'out' : 'in')]);
  }

  close() {
    this.store.dispatch(new ToggleSixCpaAction('out'));
  }

  goBackMedsCheck() {
    this.store.dispatch(new GoBackFromConfirmMedsCheckAction());
  }

  toggleSelectedformType() {
    this.store.dispatch([new SetSelectedFormType(this.activeServiceType), new ClearSixCpaCommunications()]);
  }

  startNewSixCpaFromMedsCheck() {
    this.store.dispatch([new GoBackFromConfirmMedsCheckAction(), new StartNewSixCpaAction()]);
  }

  goBackSickLeave() {
    this.store.dispatch(new GoBackFromSickLeaveConfirmAction());
  }

  startNewSixCpaFromSickLeave() {
    this.store.dispatch([new GoBackFromSickLeaveConfirmAction(), new StartNewSixCpaAction()]);
  }

  @Dispatch()
  goBackMedList = () => this.store.dispatch(new GoBackFromMedicationListConfirm())


  @Dispatch()
  startNewSixCpaFromMedList = () => this.store.dispatch([new GoBackFromMedicationListConfirm(), new StartNewSixCpaAction()])

}
