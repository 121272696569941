export class ASLStateModel {
  isSubmitting: boolean;
  isFetchingPatient: boolean;
  aslPatientLoadFailed: boolean;
  error: null;
  showModal: boolean;
  showFormSuccess: boolean;
  showSummaryModal: boolean;
  aslDetails: AslPatient;
  showRemoveModal: boolean;
  isSubmittingConsentRequest: boolean;
}

export class AslRegisterPayload {
  id: string;
  gender: string;
  firstName: string;
  surname: string;
  dateOfBirth: string;
  mobileNumber: string;
  email: string;
  address: string;
  addressSuburb: string;
  addressState: string;
  addressPostcode: string;
  medicareNumber: string;
  DVANumber: string;
  mySL: boolean;
  ihi: string;
  relatedPersons: AslRelatedPerson[];
  consentNeeded: string;
}

export class AslPatient {
  firstName: string;
  surname: string;
  dateOfBirth: string;
  gender: string;
  mobileNumber: string;
  email: string;
  address: string;
  addressSuburb: string;
  addressState: string;
  addressPostcode: string;
  medicareNumber: string;
  dvaNumber: string;
  ihi: string;
  relatedPersons: AslRelatedPerson[];
  status: SLConsent;
}

export enum CarerType {
  INDIVIDUAL,
  ORGANISATION
}

export class AslRelatedPerson {
  id: string;
  firstName: string;
  surname: string;
  relationship: string;
  organisationName: string;
  phoneNumber: string;
  email: string;
  address: string;
  suburb: string;
  state: string;
  postcode: string;
  isAgent: boolean;
  primaryContact: boolean;
}

export interface SLConsent {
  id: string;
  infaStatus: SLConsentState;
  icolStatus: SLConsentState;
  isRegistered: boolean;
}

export enum SLConsentState {
  Draft = 'Draft',
  Proposed = 'Proposed',
  Active = 'Active',
  Rejected = 'Rejected',
  Inactive = 'Inactive',
  EnteredInError = 'Entered In Error'
}

export const postCodePattern = new RegExp(/^(\d){4}$/, 'i');
