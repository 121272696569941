import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-fluid-modal',
  templateUrl: './fluid-modal.component.html',
  styleUrls: ['./fluid-modal.component.scss']
})
export class FluidModalComponent implements OnInit {
  @Output() submitEvent = new EventEmitter<any>();
  @Output() closeEvent = new EventEmitter<any>();
  @Output() cancelEvent = new EventEmitter<any>();
  @Input() message: string = '';
  @Input() title: string = 'Please confirm';
  @Input() submitText: string = 'Confirm';
  @Input() closeText: string = 'Cancel';

  constructor(){}

  ngOnInit() {  }

  close() {
    this.closeEvent.emit();
  }

  submit() {
    this.submitEvent.emit();
  }

  cancel() {
    this.cancelEvent.emit();
  }
}
