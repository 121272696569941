<ng-container *ngFor="let daaFormType of [daaFormType$ | async]">
  <div [formGroup]="daaFormList">
  </div>
  <div class="pt-2" [ngSwitch]="daaFormType">
    <p *ngSwitchCase="daaFormTypes.PatientRegistration">It’s not currently possible for DAA Registrations to be
      automatically
      claimed via the <a (click)="openPPAPortal()">PPA portal.</a>
      Completing this form will export the DAA Registration so it can be manually copied across.</p>
    <p *ngSwitchCase="daaFormTypes.DAAClaim">It’s not currently possible for DAA Claims to be automatically
      claimed via the <a (click)="openPPAPortal()">PPA portal.</a>
      Completing this form will export the DAA Claim so it can be manually copied across.</p>
  </div>
  <app-daa-patient-form-list-item></app-daa-patient-form-list-item>
  <app-daa-consent-and-eligibility-form-list-item></app-daa-consent-and-eligibility-form-list-item>
  <app-daa-medication-information-form-list-item></app-daa-medication-information-form-list-item>
  <app-daa-classifications-recommendations-form-list-item></app-daa-classifications-recommendations-form-list-item>
  <app-daa-additional-details-form-list-item></app-daa-additional-details-form-list-item>
  <app-daa-medication-profile></app-daa-medication-profile>
  
  <ng-container *ngIf="(sixCpaState$ | async) as sixCpaState">
    <ng-container >
      <div class="pt-4" *ngFor="let saving of [saving$ | async]">
        <app-loader class="loading" *ngIf="saving"></app-loader>
        <button *ngIf="!sixCpaState.readOnly" class="button submit mr-3" [disabled]="saving"
          (click)="daaFormType === daaFormTypes.PatientRegistration ? submitDAARegistration() : submitDAAClaim()">Complete
          DAA
          Claim</button>
        <button class="button save mr-3" [disabled]="saving"
          (click)="saveDaaForm()">Save DAA
          Claim</button>
        <span (click)="openConfirmResetModal()">Reset Form</span>
      </div>
    </ng-container>

    <app-confirm-reset *ngIf="sixCpaState.showResetModal" (closeEvent)="closeConfirmResetModal()"
      (submitEvent)="resetDAAFormList()">
    </app-confirm-reset>

  </ng-container>
</ng-container>
