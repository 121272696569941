import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';
import { OrderMethod, OrderRequestItemStatus, OrderRequestItemType } from '../../models/order.enums';
import { OrderRequestItem } from '../../models/order-request-item.model';
import { NgForm } from '@angular/forms';
import { ValidService } from './order-table.service';
import { isNullOrWhiteSpace } from '../../../../../shared/helpers/string.helpers';
import { EScriptType } from '../../../../../shared/models/dispense/dispenseItem.model';
import { TenantDatePipe } from '../../../../core/timezone/pipes/tenant-date.pipe';
import { extractEScript, mapIdentifierType, mapToken } from '../../../../../shared/helpers/eScript.helper';
import * as moment from 'moment';

@Component({
  selector: 'app-order-table',
  templateUrl: './order-table.component.html',
  styleUrls: ['./order-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class OrderTableComponent implements OnInit {
  @Input() orderItems: OrderRequestItem[] = null;
  @Output() orderItemsChange = new EventEmitter<OrderRequestItem[]>();
  @Output() onOpenImage = new EventEmitter<OrderRequestItem>();
  @Output() onRemoveItem = new EventEmitter<OrderRequestItem>();
  @Output() onValidChange = new EventEmitter<boolean>();
  @Input() dateFn = (date) => date;
  @Input() disabled = false;
  @Input() orderMethod: OrderMethod;
  checked = false;
  orderItemType = OrderRequestItemType;
  itemStatus = OrderRequestItemStatus;
  popoverVisible = false;
  copied: boolean = false;
  @Input() isCreate = false;
  @ViewChild('itemsForm', { static: true }) ngForm: NgForm;

  ToolTipOnFileNoRepeats = "There are no repeats remaining for this script so it can’t be pushed into the queue";
  ToolTipImage = "Pictures cannot be sent to the queue, you can view and print the image for your records if you choose to dispense it manually";
  ToolTipStock = "Stock products cannot be sent to the queue";

  constructor(
    private validService: ValidService,
    private tenantPipe: TenantDatePipe,
    private clipboard: Clipboard
  ) { }

  ngOnInit() {
    this.ngForm.form.valueChanges.subscribe(x => {
      this.orderItemsChange.emit(this.orderItems);
      this.validService.setValid(this.ngForm.valid);
    })
  }

  changeStatus(event, item: OrderRequestItem) {
    const changedFromPendingOrDispensedToReady = (item.orderRequestItemStatus === OrderRequestItemStatus.Dispensed ||
      item.orderRequestItemStatus === OrderRequestItemStatus.Pending) &&
      event === OrderRequestItemStatus.Ready;

    const numberOfItemsNeedingChange = this.dispensedMedicationsCount + this.pendingMedicationsCount;

    if (changedFromPendingOrDispensedToReady &&
      numberOfItemsNeedingChange > 1) {
      this.popoverVisible = true;
    }

    item.orderRequestItemStatus = event;

    if (item.orderRequestItemStatus === OrderRequestItemStatus.OnOrder) {
      this.setOnOrderText(item);
    }

    item.setReminder = false;
    if (item.orderRequestItemStatus === OrderRequestItemStatus.TooEarly) {
      item.setReminder = true;
      this.preFilledNextDispenseDate(item);
    }
  }

  changeOnOrderExpected(event, item: OrderRequestItem) {
    const orderExpectedDate = item.onOrderExpected || moment().add(1, 'day');
    item.onOrderExpected = this.setDateToMidnight(orderExpectedDate).toDate();
    this.setOnOrderText(item);
  }

  changeNextValidDispenseDate(event, item: OrderRequestItem) {
    this.setNextValidDispenseDateText(item);
  }

  preFilledNextDispenseDate(item: OrderRequestItem) {
    if (item.orderRequestItemStatus === OrderRequestItemStatus.TooEarly
        && item.customerMedication?.dispensedDate) {
      const nextDispenseDate = this.setDateToMidnight(item.customerMedication.dispensedDate).add(21, 'days').toDate();
      item.nextValidDispenseDate = nextDispenseDate;
    }
    this.setNextValidDispenseDateText(item);
  }

  setNextValidDispenseDateText(item: OrderRequestItem) {
    if (item.orderRequestItemStatus === OrderRequestItemStatus.TooEarly) {
      item.notes = "This item is too early, it will be dispensed on <<Date>>";
      if (item.nextValidDispenseDate) {
        var replace = /<<Date>>/gi;
        item.notes = item.notes.replace(replace,
          this.formatDate(item.nextValidDispenseDate));
      }
    }
  }

  setOnOrderText(item: OrderRequestItem) {
    if (item.orderRequestItemStatus === OrderRequestItemStatus.OnOrder) {
      item.notes = "We’ve ordered this item, expected arrival is <<Date>>";
      if (item.onOrderExpected) {
        var replace = /<<Date>>/gi;
        item.notes = item.notes.replace(replace,
          this.formatDate(item.onOrderExpected));
      }
    }
  }

  private formatDate(date: Date): string {
    return this.tenantPipe.transform(date.toString(), 'DD-MM-YYYY');
  }

  private setDateToMidnight(date: Date | string | moment.Moment): moment.Moment {
    return moment(date).hours(0).minutes(0).seconds(0);
  }

  get dispensedMedicationsCount() {
    return this.orderItems.filter(i => i.orderRequestItemStatus === OrderRequestItemStatus.Dispensed).length
  }

  get pendingMedicationsCount() {
    return this.orderItems.filter(i => i.orderRequestItemStatus === OrderRequestItemStatus.Pending).length
  }

  popoverText() {
    const readyForText = this.orderMethod == OrderMethod.Pickup ? 'Ready for Pick up?' : 'Ready for Delivery?'
    const dispensedText = `${this.dispensedMedicationsCount} dispensed`;
    const pendingText = `${this.pendingMedicationsCount} pending`
    let medicationText = '';
    if (this.dispensedMedicationsCount > 0 && this.pendingMedicationsCount > 0) {
      medicationText = `${dispensedText} and ${pendingText}`;
    } else if (this.dispensedMedicationsCount > 0) {
      medicationText = `${dispensedText}`;
    } else if (this.pendingMedicationsCount > 0) {
      medicationText = `${pendingText}`;
    }

    return `Update ${medicationText} medications as ${readyForText}`;
  }

  tooltipText(item: OrderRequestItem) {
    switch (item.orderRequestItemType) {
      case OrderRequestItemType.Image:
        return this.ToolTipImage;
      case OrderRequestItemType.OTCItem:
        return this.ToolTipStock;
      case OrderRequestItemType.OnFileScript:
        if (this.getRepeats(item) < 1) {
          return this.ToolTipOnFileNoRepeats;
        }
        break;
    }

    return "";
  }

  updateDispensedMedsToReady() {
    this.orderItems = this.orderItems
      .map(i => i.orderRequestItemStatus === OrderRequestItemStatus.Dispensed ||
        i.orderRequestItemStatus === OrderRequestItemStatus.Pending ?
        ({ ...i, orderRequestItemStatus: OrderRequestItemStatus.Ready }) : i);
    this.orderItemsChange.emit(this.orderItems);
    this.popoverVisible = false;
  }

  isInvalid(orderItems: OrderRequestItem[]) {
    return !orderItems.every(item => item.orderRequestItemStatus !== null && item.orderRequestItemStatus !== undefined)
  }

  showDOH(item: OrderRequestItem) {
    let { customerMedication }  = item;

    return item && customerMedication && customerMedication.stock;
  }

  drugDOH(item: OrderRequestItem) {
    return item && item.customerMedication
      && item.customerMedication.stock
      && item.customerMedication.stock.totalDoh
      && item.customerMedication.stock.totalDoh.value || 0;
  }

  changeMasterCheckbox(event) {
    this.orderItems = this.orderItems.map(item => (
      {
        ...item,
        selected: event
      }
    ))
    this.orderItemsChange.emit(this.orderItems);
  }

  changeCheckbox(event) {
    this.orderItemsChange.emit(this.orderItems);
  }

  openImage(item: OrderRequestItem) {
    this.onOpenImage.emit(item)
  }

  removeItemFromOrder(item) {
    if (this.isCreate || item.orderRequestItemType === OrderRequestItemType.OTCItem
      || item.addedByPharmacist) {
      this.onRemoveItem.emit(item);
    }
  }

  getRepeats(item: OrderRequestItem) {
    return item.orderRequestItemType === OrderRequestItemType.EScript ?
      item.eScriptRepeats :
      item.customerMedication?.repeats;
  }

  getQuantity(item: OrderRequestItem) {
    return item.orderRequestItemType === OrderRequestItemType.EScript ?
      item.eScriptQty :
      item.customerMedication?.quantity;
  }

  isItemQueuable(item: OrderRequestItem) {
    return mapIdentifierType(item.orderRequestItemType) !== EScriptType.Unknown && !isNullOrWhiteSpace(mapToken(item.orderRequestItemType, item));
  }

  getToken(content: string) {
    return extractEScript(content);
  }

  copyToken(item: OrderRequestItem,) {
    const token = extractEScript(item.eScriptTokenUrl);
    this.clipboard.copy(token);
    this.copied=true;
  }
}
