import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxsModule } from '@ngxs/store';
import { UploadDocumentsFormComponent } from './upload-document-form.component';
import { UploadDocumentFormState } from './state/upload-document-form.state';
import { DocumentStorageService } from './services/document-storage.service';
import { FormsModule } from '@angular/forms';
import { MatProgressBarModule } from '@angular/material/progress-bar';

@NgModule({
  declarations: [UploadDocumentsFormComponent],
  imports: [
    CommonModule,
    MatProgressBarModule,
    FormsModule,
    NgxsModule.forFeature([
        UploadDocumentFormState
    ])
  ],
  providers:[DocumentStorageService],
  exports: [
    UploadDocumentsFormComponent
  ]
})
export class UploadDocumentsFormModule { }