import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Store } from '@ngxs/store';
import { AuthSetEncryptedTenantCustomerId } from './state/auth.actions';
import { Injectable } from '@angular/core';

@Injectable()
export class AuthResolver implements Resolve<boolean> {
  constructor(private store: Store) { }
  resolve(route: ActivatedRouteSnapshot) {
    const { customer } = route.queryParams;
    if (customer) {
      this.store.dispatch(new AuthSetEncryptedTenantCustomerId(customer));
    }
    return true;
  }
}
