import * as moment from 'moment-timezone';
import {Moment} from 'moment';

export const debugText = 'DEBUG';

export function convertAndFormat(utcDate: string | Date, timezone: string, isDebug: boolean, format: string) {
    return addDebugInformation(formatMoment(convertToTimezone(timezone, utcDate), format), isDebug);
}

export function addDebugInformation(value, isDebugging) {
    return `${value}${isDebugging ? ` ${debugText}` : ''}`;
}

export function convertToTimezone(timezone: string, utcDate: Date | string): Moment {
    if (timezone) {
        return moment(utcDate).tz(timezone);
    } else {
        return moment(utcDate)
    }
}

export function formatMoment(momentDateTime: Moment, format: string) {
    return momentDateTime.format(format);
}

export function isBeforeToday(utcDate: string | Date): boolean {
    return moment(utcDate).isBefore(moment.utc());
}


