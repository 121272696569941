import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../.././../../environments/environment';
import { AlertService } from '~/modules/core/alert/alert.service';
import { BaseApiNoAlerts } from '~/shared/core/base-api/base-api-no-alerts';
import { StorageService } from '~/shared/core/storage/storage.service';
import { Observable } from 'rxjs';
import { Trigger } from '~/shared/models/pharmacy/trigger.model';

const API = environment.baseApiUrl;

@Injectable()
export class TriggerService extends BaseApiNoAlerts {
  constructor(public http: HttpClient, public router: Router, public storageService: StorageService, public alertService: AlertService) {
    super(http, router, storageService, alertService);
  }

  getTriggers(): Observable<Trigger[]> {
    return this.get(`${API}/api/Trigger`);
  }

  create(trigger: Trigger): Observable<Trigger> {
    return this.post(`${API}/api/Trigger`, trigger);
  }

  update(trigger: Trigger): Observable<Trigger> {
    return this.put(`${API}/api/Trigger`, trigger);
  }

  toggleActive(triggerId: number): Observable<any> {
    return this.http.patch(`${API}/api/Trigger`, triggerId);
  }
}
