import { InboxFilterControls } from "../constants/inbox-filter-controls.constants";

export const DefaultFilterControls = [
    InboxFilterControls.Unread,
    InboxFilterControls.Flagged,
    InboxFilterControls.UnReplied,
    InboxFilterControls.Archived,
    InboxFilterControls.SMS,
    InboxFilterControls.Email,
    InboxFilterControls.MobileApp,
    InboxFilterControls.StartDate,
    InboxFilterControls.EndDate,
    InboxFilterControls.Customer,
    InboxFilterControls.User,
    InboxFilterControls.AutomatedSystemComms,
    InboxFilterControls.AutomatedProjectedComms,
    InboxFilterControls.BulkComms,
    InboxFilterControls.Scheduled,
    InboxFilterControls.NewCustomerWelcomeMessage,
    InboxFilterControls.Survey,
    InboxFilterControls.SurveyResponse,
    InboxFilterControls.Standard
  ];
