export * from './basic/patient-details-form-basic.component';
export * from './detailed/patient-details-form-detailed.component';
export * from './patient-details-form/patient-details-form.component';
export * from './state/patient-details-form.actions';
export * from './state/patient-details-form.state';
export * from './state/patient-details-form-basic.state';
export * from './state/patient-details-form-detailed.state';
export * from './state/patient-details-form-state-model.interface';
export * from './patient-details-form.base.component';
export * from './patient-details-form.module';
export * from './patient-details-state.module';
