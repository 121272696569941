import { Component, OnInit } from '@angular/core';
import { VaccinationState } from '../../state/vaccination.state';
import { Observable } from 'rxjs';
import { Select } from '@ngxs/store';

@Component({
  selector: 'app-vaccination-error',
  templateUrl: './vaccination-error.component.html',
  styleUrls: ['./vaccination-error.component.scss']
})
export class VaccinationErrorComponent implements OnInit {
  @Select(VaccinationState.error) error$: Observable<any>;

  constructor() { }

  ngOnInit() {
  }
}
