import { State, StateContext, Action, Selector, Store } from '@ngxs/store';
import { ScriptService } from '~/shared/services/script.service';
import { RequestBulkDispense, BulkDispenseFailed, BulkDispenseSuccess } from './bulk-dispense.actions';
import { tap, catchError, finalize } from 'rxjs/operators';
import { AlertService } from '~/modules/core/alert/alert.service';
import { ProfileState } from '~/modules/core/profile/state/profile.state';
import { RefreshCategoriesAction } from '../../state/categories.actions';
import { ClearSelectedDrugsAction } from '../state/multiple-drugs.actions';
import { Injectable } from '@angular/core';


export class BulkDispenseStateModel {
  loading: boolean;
  amountOfScripts: number;
}
@Injectable()
@State<BulkDispenseStateModel>({
  name: 'bulkDispense',
  defaults: {
    loading: false,
    amountOfScripts: 0
  }
})
export class BulkDispenseState {
  constructor(private scriptService: ScriptService, private alertService: AlertService, private store: Store) { }

  @Selector()
  static selectBulkDispense(state: BulkDispenseStateModel) {
    return state;
  }

  @Action(RequestBulkDispense)
  requestBulkDispense(ctx: StateContext<BulkDispenseStateModel>, { request }: RequestBulkDispense) {
    ctx.patchState({ loading: true, amountOfScripts: request.scripts.length });
    
    return this.scriptService.bulkDispenseDrugs(request).pipe(
      tap(() => ctx.dispatch(new BulkDispenseSuccess())),
      catchError(err => ctx.dispatch(new BulkDispenseFailed(err))),
      finalize(() => ctx.patchState({ loading: false }))
    )
  }

  @Action(BulkDispenseFailed)
  bulkDispenseFailed(ctx: StateContext<BulkDispenseStateModel>, { error }: BulkDispenseFailed) {
    this.alertService.error(error.message)
  }

  @Action(BulkDispenseSuccess)
  bulkDispenseSuccess(ctx: StateContext<BulkDispenseStateModel>, { }: BulkDispenseSuccess) {
    this.alertService.success(`${ctx.getState().amountOfScripts} Medicine(s) successfully sent to Z Dispense`);

    const clientId = this.store.selectSnapshot(ProfileState.clientId);

    if (clientId) {
      ctx.dispatch([new RefreshCategoriesAction(clientId), new ClearSelectedDrugsAction()])
    }
  }
}
