import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { accordion } from '../../../../shared/core/animations';

@Component({
  selector: 'app-six-cpa-form-list-item',
  templateUrl: './six-cpa-form-list-item-checklist.component.html',
  styleUrls: ['../six-cpa-form-list-item.component.scss'],
  animations: [ accordion ]
})
export class SixCpaFormListItemChecklistComponent implements OnInit {
  @Input() formTitle: string;
  @Input() formDescription: string;
  @Input() itemsCompleted: number;
  @Input() totalItems: number;
  @Input() statusTitle: string;
  @Input() showForm = 'out';
  @Output() modeChangedEvent = new EventEmitter<string>();

  constructor() { }

  toggle() {
    this.showForm = this.showForm === 'in' ? 'out' : 'in';
    this.modeChangedEvent.emit(this.showForm);
  }

  ngOnInit() {
  }
  getStatusCls() {
    if (this.itemsCompleted && this.totalItems) {
      return this.itemsCompleted === this.totalItems ? 'done' : 'pending';
    }
    if (this.itemsCompleted > 0) {
      return 'done';
    }
    return 'pending';
  }

}
