import { State, Selector, StateContext, Action } from '@ngxs/store';
import { OpenAttachmentsModal, CloseAttachmentsModal } from './sms-attachment-modal.actions';
import { Attachment } from '../../../../../../shared/models/messageHistory.model';
import { Injectable } from '@angular/core';

export class SmsAttachmentModalStateModel {
  showModal: boolean;
  attachments: Attachment[]
}
@Injectable()
@State<SmsAttachmentModalStateModel>({
  name: 'smsAttachmentModal',
  defaults: {
    showModal: false,
    attachments: []
  }
})
export class SmsAttachmentModalState {

  @Selector()
  static showModal(state: SmsAttachmentModalStateModel) { return state.showModal };

  @Selector()
  static attachments(state: SmsAttachmentModalStateModel) { return state.attachments };

  @Action(OpenAttachmentsModal)
  openMessageCycleModal(ctx: StateContext<SmsAttachmentModalStateModel>, { attachments }: OpenAttachmentsModal) {
    ctx.patchState({ showModal: true, attachments: attachments })
  }

  @Action(CloseAttachmentsModal)
  closeMessageCycleModal(ctx: StateContext<SmsAttachmentModalStateModel>, { }: CloseAttachmentsModal) {
    ctx.patchState({ showModal: false })
  }
}
