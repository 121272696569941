import { Component, OnInit } from '@angular/core';
import { Attachment } from '~/shared/models/messageHistory.model';
import { Store, Select } from '@ngxs/store';
import { CloseAttachmentsModal } from './state/sms-attachment-modal.actions';
import { SmsAttachmentModalState } from './state/sms-attachment-modal.state';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-sms-attachment-modal',
  templateUrl: './sms-attachment-modal.component.html',
  styleUrls: ['../add-sms/add-sms.component.scss']
})
export class SmsAttachmentModalComponent implements OnInit {
  @Select(SmsAttachmentModalState.attachments) attachments$: Observable<Attachment[]>

  constructor(private store: Store) { }

  ngOnInit() {
  }

  openAttachment(link: string) {
    window.open(link, '_blank').print();
  }

  closeModal() {
    this.store.dispatch(new CloseAttachmentsModal());
  }
}
