import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import { Router } from '@angular/router';
import { BaseApi } from '../../../shared/core/base-api/base-api';
import { StorageService } from '../../../shared/core/storage/storage.service';
import { AlertService } from '../../../modules/core/alert/alert.service';
import { CustomerAuth } from './customer-auth';

@Injectable()
export class StockService extends BaseApi {

  API = '';

  constructor(@Inject('environment') env: any, public http: HttpClient, public router: Router, public storageService: StorageService, public alertService: AlertService, private customerAuth: CustomerAuth) {
    super(http, router, storageService, alertService);
    this.API = env.baseApiUrl;
  }

  pharmacistSearchStock(query: string) {
    return this.get(`${this.API}/api/stock?query=${query}`);
  }

  searchStock(query: string) {
    return this.get(this.customerAuth.urlForEncryptedTenantCustomer(`${this.API}/api/customer/order/stock-search`) + `&query=${query}`);
  }
}
