import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SendToQueueModalComponent } from './send-to-queue-modal.component';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzTimePickerModule } from 'ng-zorro-antd/time-picker';

@NgModule({
  declarations: [
    SendToQueueModalComponent
  ],
  imports: [
    CommonModule,
    NzModalModule,
    NzButtonModule,
    FormsModule,
    ReactiveFormsModule,
    NzCheckboxModule,
    NzDatePickerModule,
    NzTimePickerModule
  ],
  exports:[
    SendToQueueModalComponent
  ]
})
export class SendToQueueModalModule { }
