import { Component, OnInit, OnDestroy } from '@angular/core';
import { MarketingService } from '../../shared/services/marketing.service';
import { MarketingSettingService } from './services/marketing-settings.sevice';
import { MarketingSetting } from '~/shared/models/marketingSetting.model';
import { Router, ActivatedRoute } from '@angular/router';
import { MarketingItemService } from './services/marketing-item.service';
import { take } from 'rxjs/operators';
import { Unsubscribable, Observable } from 'rxjs';

@Component({
  selector: 'app-marketing-page',
  templateUrl: './marketing-page.component.html',
  styleUrls: ['./marketing-page.component.scss'],
})
export class MarketingPageComponent implements OnInit, OnDestroy {

  public isLoaded = false;
  public isUpdateFormVisible = false;
  public sequenceList$: Observable<MarketingSetting[]>;
  public currentItem: MarketingSetting;
  private itemUpdateListener: Unsubscribable;

  constructor(
    private marketingService: MarketingService,
    private marketingItemService: MarketingItemService,
    private settingsService: MarketingSettingService,
    private router: Router,
    private route: ActivatedRoute
  ) { }


  ngOnInit() {
    this.sequenceList$ = this.marketingItemService.getItems();

    this.marketingService.getMarketingSequence()
      .pipe(take(1))
      .subscribe((data: MarketingSetting[]) => {
        this.marketingItemService.setItems(data);
        this.isLoaded = true;
      });
  }

  onChangeAll(e, items: MarketingSetting[]) {
    items.forEach(element => {
      element.isActive = e.target.checked;
    });
    this.marketingService.updateAllMarketingSequence(items)
      .subscribe((changedItems) => {
        this.marketingItemService.setItems(changedItems);
      });
  }

  editMarketingSetting(data: MarketingSetting) {
    this.marketingItemService.select(data);
    this.router.navigate(['edit', data.id], { relativeTo: this.route });
  }

  hasActive(tenantSetting: MarketingSetting) {
    return this.settingsService.hasActive(tenantSetting);
  }

  hasInterval(tenantSetting: MarketingSetting) {
    return this.settingsService.hasInterval(tenantSetting);
  }

  ngOnDestroy() {
    if (this.itemUpdateListener) { this.itemUpdateListener.unsubscribe(); }
  }
}
