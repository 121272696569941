import { OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { Store, Actions } from '@ngxs/store';
import { MedsCheckChildForm } from '../meds-check-child-form';
import { SetTotalItemsAndCompletedItems } from './state/consent-eligibility-form.actions';

export class ConsentEligibilityFormBaseComponent extends MedsCheckChildForm implements OnInit, OnDestroy {
  consentEligibilityForm: FormGroup;
  formResetStatus: boolean;

  constructor(private formBuilder: FormBuilder, protected store: Store, actions: Actions) {
    super(actions);
    this.consentEligibilityForm = this.formBuilder.group({
      patientsConsent: new FormControl(null, Validators.requiredTrue),
      consentType: new FormControl(0, Validators.required)
    });
  }

  protected actionOnResetForm() {
    const controls = Object.keys(this.consentEligibilityForm.controls);
    controls.forEach(key => {
      if(key == 'hasPreviousMedsCheck' || key == 'gpOrHospitalLastSixMonthsForMedicationProblems' || key == 'patientsConsent')
      this.consentEligibilityForm.controls[key].setValue(false);
      else if(key == 'currentLivingStatus' || key == 'medicineManagementSupport'|| key == 'consentType') {
        this.consentEligibilityForm.controls[key].setValue(0);
      } else {
        this.consentEligibilityForm.controls[key].setValue('');
      }
    });
  }

  get formControls() {
    return this.consentEligibilityForm.controls;
  }
  protected onFormChange() {
    const controls = Object.keys(this.consentEligibilityForm.controls);
    const completedItems = this.getTotalValidItems(controls);
    this.store.dispatch(new SetTotalItemsAndCompletedItems(controls.length, completedItems));
  }

  private getTotalValidItems(controls: Array<string>) {
    return controls.filter((key) => {
      const formControl = this.consentEligibilityForm.get(key);
      return  formControl.status === 'VALID' || (formControl.status === 'DISABLED' && !formControl.invalid && formControl.errors === null);
    }).length;
  }
}
