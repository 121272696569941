import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { CarerType } from '../../../asl/state/asl.models';

@Component({
  selector: 'app-asl-related-persons-form',
  templateUrl: './asl-related-persons-form.component.html',
  styleUrls: ['./asl-related-persons-form.component.scss']
})
export class AslRelatedPersonsFormComponent implements OnInit {
  @Input()
  relatedPersonForm: FormGroup;

  @Input()
  id: number;

  @Input()
  isAgent = false;

  @Output()
  removeClickEvent = new EventEmitter<number>();

  @Output()
  updatePrimaryContactEvent = new EventEmitter<{id: number; isAgent: boolean}>();

  showForm = true;
  selectedContact = 'mobileNumber';
  carerTypes = CarerType;
  carerType = CarerType.INDIVIDUAL;

  constructor() { }

  ngOnInit(): void { }

  removeRelatedPerson(): void {
    this.removeClickEvent.emit(this.id);
  }

  updatePrimaryContact(): void {
    const primaryChecked = this.relatedPersonForm.get('primary').value;
    if (primaryChecked) {
      this.updatePrimaryContactEvent.emit({id: this.id, isAgent: this.isAgent});
    }
  }

  updateChannel(event: any): void {
    if (event.value === 'mobileNumber') {
      this.relatedPersonForm.get('email').clearValidators();
      this.relatedPersonForm.get('email').setValue(null);
      this.relatedPersonForm.get('mobileNumber').setValidators([Validators.required, Validators.pattern('04[0-9]{8}')]);
    } else if (event.value === 'email') {
      this.relatedPersonForm.get('mobileNumber').clearValidators();
      this.relatedPersonForm.get('mobileNumber').setValue(null);
      this.relatedPersonForm.get('email').setValidators([Validators.required, Validators.email]);
    }
    this.relatedPersonForm.get('mobileNumber').updateValueAndValidity();
    this.relatedPersonForm.get('email').updateValueAndValidity();
    this.selectedContact = event.value;
  }

  toggleForm(): void {
    this.showForm = !this.showForm;
  }

  updateCarer(): void {
    this.carerType = this.relatedPersonForm.get('carerType').value;
    switch (this.carerType) {
      case CarerType.INDIVIDUAL:
        this.relatedPersonForm.get('firstName').setValidators([Validators.required]);
        this.relatedPersonForm.get('surname').setValidators([Validators.required]);
        this.relatedPersonForm.get('relationship').setValidators([Validators.required]);
        this.relatedPersonForm.get('organisation').clearValidators();
        break;
      case CarerType.ORGANISATION:
        this.relatedPersonForm.get('organisation').setValidators([Validators.required]);
        this.relatedPersonForm.get('firstName').clearValidators();
        this.relatedPersonForm.get('surname').clearValidators();
        this.relatedPersonForm.get('relationship').clearValidators();
        break;
    }
    this.relatedPersonForm.get('organisation').updateValueAndValidity();
    this.relatedPersonForm.get('firstName').updateValueAndValidity();
    this.relatedPersonForm.get('surname').updateValueAndValidity();
    this.relatedPersonForm.get('relationship').updateValueAndValidity();
  }
}
