import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxsModule } from '@ngxs/store';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PrimaryContactComponent } from './primary-contact.component';
import { PrimaryContactService } from './services/primary-contact.service';
import { PrimaryContactState } from './state/primary-contact.state';
import { TimezoneModule } from '../../../modules/core/timezone/timezone.module';
import { LoaderModule } from '../../../modules/core/loader/loader.module';
import { FilterLinkedContactsPipe } from './pipes/filter-linked-contacts.pipe';
import { PrimaryContactHelperService } from './helpers/primary-contact.helper';
import { RouterModule } from '@angular/router';
import { UiSwitchModule } from 'ngx-ui-switch';

@NgModule({
  declarations: [
    PrimaryContactComponent,
    FilterLinkedContactsPipe
  ],
  imports: [
    TimezoneModule,
    CommonModule,
    LoaderModule,
    NgxsModule.forFeature([PrimaryContactState]),
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    UiSwitchModule
  ],
  providers: [
    PrimaryContactService,
    PrimaryContactHelperService
  ],
  exports: [
    PrimaryContactComponent
  ]
})
export class PrimaryContactModule { }
