import { Component, Input } from '@angular/core';
import { Store } from '@ngxs/store';
import { MailboxMessage, MessageDetail } from '../../../../shared/models/message.model';
import { MailTabs } from '../../../../shared/types/communications';
import { SetMessageArchive, SetMessageFlagged, SetMessageRead } from '../../state/communications.actions';

@Component({
  selector: 'app-message-actions',
  templateUrl: './message-actions.component.html',
  styleUrls: ['./message-actions.component.scss']
})

export class MessageActionsComponent {
  @Input() message: MailboxMessage;
  @Input() messageDetail: MessageDetail;
  @Input() messageType: MailTabs;
  @Input() actions: string[] = ['flag', 'read', 'view', 'archive'];

  mailTabs = MailTabs;
  constructor(private store: Store) { }

  getMessageObject() {
    return this.message || {id: this.messageDetail.inboxMessageId, isFlagged: this.messageDetail.isFlagged, isRead: this.messageDetail.isRead, customerId: this.messageDetail.customerId} as MailboxMessage;
  }

  toggleFlagged(message) {
    this.store.dispatch(new SetMessageFlagged(message, !message.isFlagged, this.messageType));
  }

  toggleRead(message) {
    this.store.dispatch(new SetMessageRead(message, !message.isRead, this.messageType));
  }

  toggleArchived(message) {
    this.store.dispatch(new SetMessageArchive(message, !message.isArchived, this.messageType));
  }
}
