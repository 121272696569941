import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SickLeavePatientDetailsModule } from '../sick-leave-patient-details/sick-leave-patient-details.module';
import { SickLeavePatientDetailsListItemComponent } from './sick-leave-patient-details-list-item.component';
import { SixCpaFormListItemChecklistModule } from '../../six-cpa-form-list-item-checklist/six-cpa-form-list-item-checklist.module';

@NgModule({
  declarations: [SickLeavePatientDetailsListItemComponent],
  imports: [
    CommonModule,
    SickLeavePatientDetailsModule,
    SixCpaFormListItemChecklistModule
  ],
  exports: [SickLeavePatientDetailsListItemComponent]
})
export class SickLeavePatientDetailsListItemModule { }
