import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import { BaseApi } from '../core/base-api/base-api';
import { environment } from '../../../environments/environment';
import { Auth } from '../models/auth.model';
import { AuthUser } from '../models/authUser.model';
import { Router } from '@angular/router';
import { StorageService } from '../core/storage/storage.service';
import { AlertService } from '../../modules/core/alert/alert.service';

const API = environment.baseApiUrl;

@Injectable()
export class AuthService extends BaseApi {
  constructor(public http: HttpClient, public router: Router, public storageService: StorageService, public alertService: AlertService) {
    super(http, router, storageService, alertService);
  }

  deleteUser(customerId): Observable<string> {
    return this.delete(`${API}/api/delete_account/${customerId}`);
  }

  deleteInvites(customerId): Observable<string> {
    return this.delete(`${API}/api/delete_mobile_invites/${customerId}`);
  }

  changePassword(user: AuthUser): Observable<string> {
    return this.post(`${API}/api/password_reset`, user);
  }

  login(login: AuthUser): Observable<Auth> {
    return this.post(`${API}/api/Login`, login)
      .map((user: Auth) => {
        return user;
      });
  }

  logout() {
    this.storageService.clearStorage();

  }

  async isLoggedIn(): Promise<boolean> {
    const res = await this.storageService.getItem('scrypt_access_token');
    return res != null;
  }
}
