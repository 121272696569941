import { Component, OnInit, Output, EventEmitter } from '@angular/core';
// import { environment } from '../../../../../../../../environments/environment';

@Component({
  selector: 'app-hmr-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss']
})
export class ConfirmModalComponent implements OnInit {
  @Output() submitEvent = new EventEmitter<any>();
  @Output() closeEvent = new EventEmitter<any>();
  // CPA_Version = environment.CPAVersion;
  CPA_Version = '';

  constructor() { }

  ngOnInit() {
  }

  close() {
    this.closeEvent.emit();
  }

  submit() {
    this.submitEvent.emit();
  }
}
