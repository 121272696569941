import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MedicationListConfirmComponent } from './medication-list-confirm.component';
import { NgxsModule } from '@ngxs/store';
import { MedicationListConfirmState } from './state/medication-list-confirm.state';
import { DownloadPdfModule } from '../../../../core/download-pdf/download-pdf.module';
import { ReactiveFormsModule } from '@angular/forms';
import { TypeaheadModule } from 'ngx-bootstrap';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import { ReportResponseModule } from '../../report-response/report-response.module';
import { CustomiseDoctorReportModule } from '../../../../../shared/components/customise-doctor-report/customise-doctor-report.module';
import { LoaderModule } from '../../../../core/loader/loader.module';
import { MedicationListService } from '../medication-list-form-list/services/medication-list.service';
import { WindowService } from '../../../../../shared/services/window.service';
import { ChannelSelectModalModule } from './../../../../../modules/profile/six-cpa/channel-select-modal/channel-select-modal.module';

@NgModule({
  declarations: [MedicationListConfirmComponent],
  imports: [
    CommonModule,
    NgxsModule.forFeature([
      MedicationListConfirmState
    ]),
    DownloadPdfModule,
    ReactiveFormsModule,
    TypeaheadModule.forRoot(),
    NgxsFormPluginModule,
    ReportResponseModule,
    LoaderModule,
    BsDatepickerModule.forRoot(),
    CustomiseDoctorReportModule,
    ChannelSelectModalModule
  ],
  providers: [MedicationListService, WindowService],
  exports: [MedicationListConfirmComponent],
})
export class MedicationListConfirmModule {}
