import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScryptDigitalSignatureComponent } from './scrypt-digital-signature.component';
import { NgxsModule } from '@ngxs/store';
import { ScryptDigitalSignatureState } from './state/scrypt-digital-signature.state';
import { SignaturePadModule } from 'angular2-signaturepad';


@NgModule({
  exports: [
    ScryptDigitalSignatureComponent
  ],
  declarations: [
    ScryptDigitalSignatureComponent
  ],
  imports: [
    CommonModule,
    SignaturePadModule,
    NgxsModule.forFeature([ScryptDigitalSignatureState])
  ]
})
export class ScryptDigitalSignatureModule {

}
