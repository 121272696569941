<ng-container *ngIf="(selectedDrugs$ | async) as selectedDrugs">
    <ng-container *ngIf="(instoreOrder$ | async) as instoreOrder">
        <div>
            <h1>Create Instore Order</h1>
            <p>This order contains the following drugs:</p>
            <ul>
                <li *ngFor="let drug of selectedDrugs">{{drug.name}}</li>
            </ul>
            <div>
                <div class="form-group" g-xs="mt4">
                    <label class="font__bold">Total DOH being delivered to client</label>
                    <input type="number" step="1" min="1" class="form-control" [(ngModel)]="daysOnHandAdjustment" />
                </div>
                <ng-container *ngIf="!instoreOrder.loading; else loading">
                    <button (click)="createInstoreOrder(selectedDrugs)" class="button btn__green pull-right" g-xs="mt2">Create</button>
                </ng-container>
                <ng-template #loading>
                    <i class="fas fa-sync fa-spin"></i>
                </ng-template>
            </div>
        </div>
    </ng-container>
</ng-container>