<form [formGroup]="airPaitentInfoForm" class="p-4">
  <div class="form-group">
    <label class="form-control-label">You can change the lookup method by using the toggles below </label>
  </div>
  <app-radio-highlight 
    controlName="byMedicare" 
    [isHighlighted]="airPaitentInfoForm.value.individualRequestType === individualRequestType.byMedicare"
    [fullWidth]="true" 
    size="lg">
      <input class="form-check-input" type="radio" id="byMedicare" formControlName="individualRequestType"
        [value]="individualRequestType.byMedicare" [checked]="airPaitentInfoForm.value.individualRequestType === individualRequestType.byMedicare">
      <label class="form-check-label" for="byMedicare">Medicare & IRN</label>
  </app-radio-highlight>
  <app-radio-highlight 
    controlName="withoutMedicare" 
    size="lg"
    [isHighlighted]="airPaitentInfoForm.value.individualRequestType === individualRequestType.withoutMedicare" 
    [fullWidth]="true">
      <input class="form-check-input" type="radio" id="withoutMedicare" formControlName="individualRequestType"
        [value]="individualRequestType.withoutMedicare" [checked]="airPaitentInfoForm.value.individualRequestType === individualRequestType.withoutMedicare">
      <label class="form-check-label" for="withoutMedicare">No Medicare Card</label>
  </app-radio-highlight>
  <app-radio-highlight 
    controlName="withoutMedicareIRN" 
    size="lg"
    [isHighlighted]="airPaitentInfoForm.value.individualRequestType === individualRequestType.withoutMedicareIRN" 
    [fullWidth]="true">
      <input class="form-check-input" type="radio" id="withoutMedicareIRN" formControlName="individualRequestType"
        [value]="individualRequestType.withoutMedicareIRN" [checked]="airPaitentInfoForm.value.individualRequestType === individualRequestType.withoutMedicareIRN">
      <label class="form-check-label" for="withoutMedicareIRN">Medicare with no IRN</label>
  </app-radio-highlight>

  <ng-container *ngIf="(pharmacy$ | async) as pharmacy">
    <div class="mb-3 w-50" [ngClass]="pharmacy.airProviderNumber ? 'alert alert-success' : 'alert alert-danger'">
      Your AIR provider number
      <span *ngIf="pharmacy.airProviderNumber"> is <strong>{{pharmacy.airProviderNumber}}</strong></span>
      <span *ngIf="!pharmacy.airProviderNumber"> is not registered with us, please add it via the pharmacy
        page.</span>
    </div>
  </ng-container>

  <div class="form-group pb-1" *ngIf="airPaitentInfoForm.value.individualRequestType != individualRequestType.withoutMedicare">
    <label for="medicareNumber" class="input-label">Medicare Number &amp; IRN</label>
    <input type="text" class="form-control form-control-lg w-25" name="medicareNumber"
      formControlName="medicareNumber" id="medicareNumber" placeholder="123456789" required>
  </div>
  <div class="form-group pb-1" *ngIf="airPaitentInfoForm.value.individualRequestType == individualRequestType.byMedicare">
    <label for="medicareIRN" class="input-label">IRN</label>
    <input type="text" class="form-control form-control-lg w-25" name="medicareIRN" formControlName="medicareIRN"
      id="medicareIRN" placeholder="1" required>
  </div>
  <div 
    class="form-group pb-1" 
    *ngIf="airPaitentInfoForm.value.individualRequestType == individualRequestType.withoutMedicare">
    <label for="patientFirstName" class="input-label">Patients First Name</label>
    <input type="text" formControlName="patientFirstName" class="form-control form-control-lg w-50"
      id="patientFirstName" placeholder="Patients First Name" required>
  </div>
  <div class="form-group pb-1"
    *ngIf="airPaitentInfoForm.value.individualRequestType != individualRequestType.byMedicare">
    <label for="patientSurname" class="input-label">Patients Surname</label>
    <input type="text" formControlName="patientSurname" class="form-control form-control-lg w-50" id="patientSurname"
      placeholder="Patients Surname" required>
  </div>
  <div class="form-group pb-1"
    *ngIf="airPaitentInfoForm.value.individualRequestType != individualRequestType.byMedicare">
    <label for="dob" class="input-label">Patients Date of Birth</label>
    <input type="text" class="form-control form-control-lg w-25" formControlName="dob" id="dob"
      [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY' }" placeholder="dd-mm-yyyy" bsDatepicker>
  </div>
  <div class="form-group pb-1" *ngIf="airPaitentInfoForm.value.individualRequestType == individualRequestType.withoutMedicare">
    <label for="postCode" class="input-label">Post Code</label>
    <input type="text" formControlName="postCode" class="form-control form-control-lg w-50" id="postCode"
      placeholder="2000" required>
  </div>
  <div class="form-group pb-1">
    <div class="form-check">
      <input type="checkbox" id="consent" class="form-check-input" formControlName="consent" required />
      <label for="consent" class="form-check-label">I have received consent from the patient to look up their
        Immunisation
        History Statement</label>
    </div>
  </div>

  <hr />
  <ng-container *ngIf="(error$ | async) as error">
    <div class="mb-2 ml-2">
      <p class="alert alert-danger" *ngIf="error.message"><strong>(AIR Response):</strong> {{error.message}}</p>
      <div *ngIf="error?.errors?.length">
        <div *ngFor="let err of error.errors">
          <ul>
            <li class="ml-2"><span>Code: {{ err.code}}</span></li>
            <li class="ml-2"><span>Field: {{ err.field}}</span></li>
            <li class="ml-2"><span>Message: {{ err.message}}</span></li>
          </ul>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="!(saving$ | async); else loading">
    <button class="btn btn__green submit mr-3" [disabled]="airPaitentInfoForm.status == 'INVALID'" (click)="submit()">
      Lookup <i class="fal fa-binoculars"></i>
    </button>
  </ng-container>
</form>
<ng-template #loading>
  <app-loader loadingMessage="Validating with the AIR..."></app-loader>
</ng-template>
