import { State, Action, StateContext, Selector } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { SetActionPlanForm, SetTotalItemsAndCompletedItems } from './action-plan-form.actions';
import { ForConsiderationByTypes } from '../../../../../shared/models/six-cpa/action-plan.model';

export class ActionPlanFormStateModel {
  actionPlanForm: {
    model: Partial<IActionPlanForm>;
  }
  valid: boolean;
  totalItems: number;
  completedItems: number;
  errors: any;
}

export interface IActionPlanForm {
  hasActions?: boolean | null;
  actions?: IActionPlan[];
}

export interface IActionPlan {
  id?: number;
  issue?: string | null;
  recommendation?: string | null;
  forConsiderationBy?: ForConsiderationByTypes;
  otherConsideration?: string | null;
}

@Injectable()
@State<ActionPlanFormStateModel>({
  name: 'actionPlanForm',
  defaults: {
    actionPlanForm: {
      model: undefined
    },
    valid: false,
    completedItems: 0,
    totalItems: 0,
    errors: null
  }
})
export class ActionPlanFormState {

  @Selector()
  static actionPlanForm(state: ActionPlanFormStateModel) { return state.actionPlanForm.model; }

  @Selector()
  static totalItems(state: ActionPlanFormStateModel) { return state.totalItems; }

  @Selector()
  static completedItems(state: ActionPlanFormStateModel) { return state.completedItems; }

  @Action(SetTotalItemsAndCompletedItems)
  setProgress(ctx: StateContext<ActionPlanFormStateModel>, action: SetTotalItemsAndCompletedItems) {
    ctx.patchState({
      totalItems: action.totalItems,
      completedItems: action.completedItems
    });
  }

  @Action(SetActionPlanForm)
  updateForm(ctx: StateContext<ActionPlanFormStateModel>, { form }: SetActionPlanForm) {
    if (!form) {
      ctx.patchState({
        actionPlanForm: {
          model: {
            hasActions: null,
            actions: null
          }
        }
      });
      return;
    }
    ctx.patchState({
      actionPlanForm: {
        model: {
          hasActions: form.hasActions,
          actions: form.sixCpaActionPlanItems ? form.sixCpaActionPlanItems.map(action => ({
            id: action.id,
            issue: action.issue,
            recommendation: action.recommendation,
            forConsiderationBy: action.forConsiderationBy,
            otherConsideration: action.otherConsideration
          })): null
        }
      }
    });
  }
}

