import { HttpEvent } from "@angular/common/http";
import { CustomerDocument, PatientDocumentViewModel, PatientUploadDocument } from "../services/document-storage.service";

export enum UploadDocumentFormActions {
    UPLOAD_DOCUMENT = '[UploadDocumentForm] Upload Document',
    UPLOAD_DOCUMENT_SUCCESS = '[UploadDocumentForm] Upload Document Success',
    UPLOAD_DOCUMENT_FAILURE = '[UploadDocumentForm] Upload Document failure',
    CANCEL_UPLOAD = '[UploadDocumentForm] Cancel Upload',
    CLEAR_DOCUMENTS = '[UploadDocumentForm] Clear Documents',
    SET_UPLOADED_DOCUMENTS = '[UploadDocumentForm] set uploaded documents',
    DOWNLOAD_DOCUMENT = '[UploadDocumentForm] download documents',
    DELETE_DOCUMENT = '[UploadDocumentForm] delete document',
    DELETE_DOCUMENT_SUCCESS = '[UploadDocumentForm] delete document success',
    DELETE_DOCUMENT_FAILURE = '[UploadDocumentForm] delete document failure',
    UPLOAD_DOCUMENT_REQUEST = '[UploadDocumentForm] Upload Document Request',

}
  
export class GetDocument {
    static readonly type = UploadDocumentFormActions.DOWNLOAD_DOCUMENT;
    constructor(public id: number, public filename: string) { }
}

export class DeleteDocument {
    static readonly type = UploadDocumentFormActions.DELETE_DOCUMENT;
    constructor(public id: number) { }
}

export class DeleteDocumentSuccess {
    static readonly type = UploadDocumentFormActions.DELETE_DOCUMENT_SUCCESS;
    constructor(public id: number) { }
}

export class DeleteDocumentFailed {
    static readonly type = UploadDocumentFormActions.DELETE_DOCUMENT_FAILURE;
    constructor(public errors: any, public id: number) { }
}

export class SetUploadedDocuments {
    static readonly type = UploadDocumentFormActions.SET_UPLOADED_DOCUMENTS;
    constructor(public documents: CustomerDocument[]) { }
}

export class UploadDocument {
    static readonly type = UploadDocumentFormActions.UPLOAD_DOCUMENT;
    constructor(public document: PatientUploadDocument) { }
}

export class UploadDocumentSuccess {
    static readonly type = UploadDocumentFormActions.UPLOAD_DOCUMENT_SUCCESS;
    constructor(public event: HttpEvent<PatientDocumentViewModel>, public uploadId: number) { }
}

export class UploadDocumentFailed {
    static readonly type = UploadDocumentFormActions.UPLOAD_DOCUMENT_FAILURE;
    constructor(public errors: any, public uploadId: number) { }
}

export class CancelDocumentUpload {
    static readonly type = UploadDocumentFormActions.CANCEL_UPLOAD;
    constructor(public uploadId: number) { }
}

export class ClearDocuments {
    static readonly type = UploadDocumentFormActions.CLEAR_DOCUMENTS;
    constructor() { }
}

export class UploadDocumentRequest {
    static readonly type = UploadDocumentFormActions.UPLOAD_DOCUMENT_REQUEST;
    constructor() {}
}
  
  