import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HMRPharmacyInformationComponent } from './hmr-pharmacy-information.component';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import { ReactiveFormsModule } from '@angular/forms';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';

@NgModule({
  declarations: [HMRPharmacyInformationComponent],
  imports: [
    CommonModule,
    NgxsFormPluginModule,
    ReactiveFormsModule,
    BsDatepickerModule.forRoot()
  ],
  exports: [HMRPharmacyInformationComponent]
})
export class HMRPharmacyInformationModule { }
