import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { DAAChildForm } from '../daa-child-form';
import { Store, Actions } from '@ngxs/store';
import { SetTotalItemsAndCompletedItems } from './state/daa-medication-information-form.actions';
import { DAAMedicationInformationFormState, DAADisabilityTypes } from './state/daa-medication-information-form.state';
import { WindowService } from '../../../../../shared/services/window.service';
//import { environment } from '../../../../../../environments/environment';
import { SixCpaState } from '../../state/six-cpa.state';

@Component({
  selector: 'app-daa-medication-information-form',
  templateUrl: './daa-medication-information-form.component.html',
  styleUrls: ['./daa-medication-information-form.component.scss']
})
export class DaaMedicationInformationFormComponent extends DAAChildForm implements OnInit, OnDestroy {
  private formSubscription: Subscription;
  daaMedicationInformationForm: FormGroup;
  daaDisabilityTypes = DAADisabilityTypes;
  private readonlySubscription: Subscription;

  constructor(private formBuilder: FormBuilder, private store: Store, actions: Actions, private windowService: WindowService) {
    super(actions);
    this.generateFormControls();
  }

  openMedIndexCalculator() {
    // this.windowService.open(environment.medIndexCalculator);
    this.windowService.open('');
  }

  ngOnInit() {
    this.formSubscription = this.store.select(DAAMedicationInformationFormState.daaMedicationInformationForm)
      .subscribe(() => this.onFormChange());
    this.readonlySubscription = this.store.select(SixCpaState.formsReadOnly)
      .subscribe(readonly => readonly ? this.daaMedicationInformationForm.disable() : this.daaMedicationInformationForm.enable());
  }

  ngOnDestroy() {
    this.formSubscription.unsubscribe();
    this.readonlySubscription.unsubscribe();
  }
  get formControls() {
    return this.daaMedicationInformationForm.controls;
  }
  protected actionOnResetForm() {
    this.daaMedicationInformationForm.reset();
    this.daaMedicationInformationForm.patchValue(
      {
        daaEligibileDisability: this.daaDisabilityTypes.No,
        difficultiesWithMedicationManagement: false,
        historyOfNonAdherence: false
      });
  }

  private onFormChange() {
    const controls = Object.keys(this.daaMedicationInformationForm.controls);
    const completedItems = this.getTotalValidItems(controls);
    this.store.dispatch(new SetTotalItemsAndCompletedItems(controls.length, completedItems));
  }

  private getTotalValidItems(controls: Array<string>) {
    return controls.filter((key) => {
      const formControl = this.daaMedicationInformationForm.get(key);
      return  formControl.status === 'VALID' || (formControl.status === 'DISABLED' && !formControl.invalid && formControl.errors === null);
    }).length;
  }

  private generateFormControls() {
    this.daaMedicationInformationForm = this.formBuilder.group({
      prescriptionMedicines: new FormControl(''),
      nonPrescriptionMedicines: new FormControl(''),
      medIndexScore: new FormControl('', Validators.required),
      historyOfNonAdherence: new FormControl(false, Validators.required),
      difficultiesWithMedicationManagement: new FormControl(false, Validators.required),
      daaEligibileDisability: new FormControl(this.daaDisabilityTypes.No, Validators.required)
    });
  }
}
