import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import { ReactiveFormsModule } from '@angular/forms';
import { RadioHighlightModule } from '../../../../../shared/components/radio-highlight/radio-highlight.module';
import { HMRServiceProviderDetailsFormComponent, HMRServiceProviderDetailsFormHostDirective } from './service-provider-details-form/hmr-service-provider-details-form.components';
import { HMRServiceProviderDetailsFormBasicComponent } from './basic/hmr-service-provider-details-form-basic.component';
import { HMRServiceProviderDetailsFormDetailedComponent } from './detailed/hmr-service-provider-details-form-detailed.component';

@NgModule({
    declarations: [
      HMRServiceProviderDetailsFormComponent,
      HMRServiceProviderDetailsFormBasicComponent,
      HMRServiceProviderDetailsFormDetailedComponent,
      HMRServiceProviderDetailsFormHostDirective
    ],
    imports: [
      CommonModule,
      NgxsFormPluginModule,
      ReactiveFormsModule,
      RadioHighlightModule
    ],
    exports: [
      HMRServiceProviderDetailsFormComponent
    ],
    entryComponents: [
      HMRServiceProviderDetailsFormBasicComponent,
      HMRServiceProviderDetailsFormDetailedComponent
    ]
  })
  export class HMRServiceProviderDetailsFormModule { }
