import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdditionalItemsModalComponent } from './additional-items-modal.component';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzListModule } from 'ng-zorro-antd/list';
import { NzInputModule } from 'ng-zorro-antd/input';
@NgModule({
  declarations: [
    AdditionalItemsModalComponent
  ],
  imports: [
    CommonModule,
    NzModalModule,
    NzButtonModule,
    FormsModule,
    ReactiveFormsModule,
    NzListModule,
    NzInputModule,
  ],
  exports:[
    AdditionalItemsModalComponent
  ]
})
export class AdditionalItemsModalModule { }
