import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BrandPreference, OrderRequestStatus, OrderMethod, OrderRequestItemStatus, OrderOrigin } from '../../models/order.enums';
import { OrderRequest } from '../../models/order-request.model';

@Component({
  selector: 'app-order-card',
  templateUrl: './order-card.component.html',
  styleUrls: ['./order-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class OrderCardComponent implements OnInit {
  @Input() order: OrderRequest = null;
  @Input() dateFn = (date) => date;
  @Input() isHeader = false;
  @Input() disabled = false;
  orderStates = OrderRequestStatus;
  orderMethods = OrderMethod;
  @Output() orderMethodChange: EventEmitter<OrderRequest> = new EventEmitter<OrderRequest>();
  @Output() updateStatus: EventEmitter<OrderRequest> = new EventEmitter<OrderRequest>();
  @Output() onNavigateToComms: EventEmitter<any> = new EventEmitter<any>();
  isLite = false;
  constructor(
      private router: Router,
      private route: ActivatedRoute,
      @Inject('environment') env: any
  ) {
    this.isLite = env.isLite;
  }

  ngOnInit() {
  }

  updateStatusReady() {
    this.updateStatus.emit({...this.order, status: OrderRequestStatus.Ready });
  }

  updateStatusCancel() {
    this.updateStatus.emit({...this.order, status: OrderRequestStatus.Cancelled });
  }

  clickCard() {
      if (!this.isHeader) {
          this.router.navigate(['./' + this.order.id], { relativeTo: this.route});
      }
  }

  orderOriginText(orderMethod: OrderOrigin): string{
    switch(orderMethod) {
        case OrderOrigin.MobileApp: return 'App';
        case OrderOrigin.OrderLink: return 'Web';
        case OrderOrigin.PredictedOrder: return 'HQ';
        default: return 'Unknown'
    }
 }

  orderMethodText(orderMethod: OrderMethod): string{
     switch(orderMethod) {
         case OrderMethod.Delivery: return 'Delivery';
         case OrderMethod.Pickup: return 'Pick up';
         default: return 'Unknown'
     }
  }

  orderMethodClass(orderMethod: OrderMethod): string {
    switch(orderMethod) {
        case OrderMethod.Delivery: return 'fal fa-map-marker-alt';
        case OrderMethod.Pickup: return 'fal fa-store-alt';
        default: return 'fal fa-question-square'
    }
  }

  brandPrefText(brandPref: BrandPreference): string{
    switch(brandPref) {
        case BrandPreference.AllowSubstitutes: return 'Yes, substitute';
        case BrandPreference.DontAllowSubstitutes: return 'No, don\'t substitute';
        default: return 'No preference'
    }
 }

 brandPrefClass(brandPref: BrandPreference): string {
   switch(brandPref) {
       case BrandPreference.AllowSubstitutes: return 'fal fa-check';
       case BrandPreference.DontAllowSubstitutes: return 'fal fa-times';
       default: return 'fal fa-question-square'
   }
 }

 statusIconClass = (status: OrderRequestStatus) => {
    switch(status) {
        case OrderRequestStatus.Ready: return 'fas fa-check-circle color_green';
        case OrderRequestStatus.PartiallyReady: return 'fas fa-info-circle color_yellow';
        case OrderRequestStatus.UnableToFill: return 'fas fa-check-circle color_red';
        case OrderRequestStatus.Cancelled: return 'fas fa-times-circle color_red';
        case OrderRequestStatus.Pending: return 'fas fa-info-circle color_yellow';
        default: return 'fas fa-question-square color_yellow'
    }
  }

 statusText = (status: OrderRequestStatus, orderMethod: OrderMethod) => {
    switch(status) {
        case OrderRequestStatus.Ready:
        switch(orderMethod) {
            case OrderMethod.Delivery: return 'Delivery scheduled';
            case OrderMethod.Pickup: return 'Ready to pick up';
            default: return 'Order ready';
        }
        case OrderRequestStatus.PartiallyReady:
            return 'Partially ready';
        case OrderRequestStatus.UnableToFill: return 'Unable to fill';
        case OrderRequestStatus.Cancelled: return 'Cancelled';
        case OrderRequestStatus.Pending: return 'Pending';
        default: return 'Unknown';
    }
}

  statusBackground = (status: OrderRequestStatus) => {
    switch(status) {
        case OrderRequestStatus.Ready:
        case OrderRequestStatus.PartiallyReady: return 'bg_green';
        case OrderRequestStatus.UnableToFill:
        case OrderRequestStatus.Cancelled: return 'bg_red';
        case OrderRequestStatus.Pending: return 'bg_yellow';
        default: return 'bg_yellow'
    }
  }

 changeOrderMethod() {
     this.order.orderMethod = this.oppositeOrderMethod(this.order.orderMethod);
    this.orderMethodChange.next(this.order);
 }

 oppositeOrderMethod(orderMethod: OrderMethod) {
    switch(orderMethod) {
        case OrderMethod.Delivery: return OrderMethod.Pickup;
        case OrderMethod.Pickup: return OrderMethod.Delivery;
        default: return OrderMethod.Undefined;
    }
 }

 oppositeOrderMethodText(orderMethod: OrderMethod) {
    switch(orderMethod) {
        case OrderMethod.Delivery: return 'pick up';
        case OrderMethod.Pickup: return 'delivery';
        default: return 'pickup/delivery';
    }
 }

 orderHasItemsOnOrder() {
     return (this.order.orderRequestedItems
        .filter(i => i.orderRequestItemStatus === OrderRequestItemStatus.OnOrder).length > 0)
      &&
        (this.order.status == OrderRequestStatus.PartiallyReady || this.order.status == OrderRequestStatus.Pending);
 }

 navigateToComms() {
    this.onNavigateToComms.emit();
 }

}
