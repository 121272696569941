<ng-template #loading>
  <app-loader></app-loader>
</ng-template>

<ng-container *ngIf="isLoaded; else loading">
  <section *ngIf="(sequenceList$ | async) as sequenceList">
    <router-outlet></router-outlet>
    <div class="text-right">
      <button class="btn btn-success" routerLink="add">Add new</button>
    </div>
    <div *ngFor="let el of sequenceList | appGroupBy: 'tenantName'">
      <div>
        <h3>{{ el.key }}</h3>
      </div>
      <table class="table" g-xs="mt1 mb2">
        <thead>
          <tr>
            <th>Tenant</th>
            <th>Template Name</th>
            <th>Interval</th>
            <th>Subject</th>
            <th>Template</th>
            <th>
              Is Active
              <input type="checkbox" (change)="onChangeAll($event, el.value)" [checked]="false" />
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of el.value">
            <td>{{ item.tenantName }}</td>
            <td class="table__template-name">{{ item.key }}</td>
            <td>
              <span *ngIf="hasInterval(item)">{{ item.interval }}</span>
            </td>
            <td class="table__template">{{ item.subject }}</td>
            <td class="table__template">{{ item.messageTemplate }}</td>
            <td>
              <input *ngIf="hasActive(item)" type="checkbox" (change)="onChange($event, item)"
                [checked]="item.isActive" />
            </td>
            <td>
              <button (click)="editMarketingSetting(item)" class="btn btn-primary">
                Edit
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </section>
</ng-container>
