<form [formGroup]="patientDetailsForm" ngxsForm="patientDetailsFormBasic.patientDetailsForm" class="p-4">
    <div class="form-group pb-1">
        <label for="patientFirstName" class="input-label">Patients First Name</label>
        <input type="text" formControlName="patientFirstName" class="form-control form-control-lg w-50"
            [class.has-error]="formControls.patientFirstName.invalid && (showInvalid$ | async)" id="patientFirstName"
            placeholder="Patients First Name" required>
    </div>
    <div class="form-group pb-1">
        <label for="patientSurname" class="input-label">Patients Surname</label>
        <input type="text" formControlName="patientSurname" class="form-control form-control-lg w-50"
            [class.has-error]="formControls.patientSurname.invalid && (showInvalid$ | async)" id="patientSurname"
            placeholder="Patients Surname" required>
    </div>
    <div class="form-group pb-1">
        <label for="patientDOB" class="input-label">Patients Date of Birth</label>
        <input [class.has-error]="formControls.patientDOB.invalid && (showInvalid$ | async)" type="text"
            class="form-control form-control-lg w-25" value="patientDetailsForm.value.patientDOB | date: 'DD-MM-YYYY'"
            formControlName="patientDOB" id="patientDOB" [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY' }"
            placeholder="dd-mm-yyyy" bsDatepicker>
    </div>
    <div class="form-group pb-1">
        <label for="medicareNumber" class="input-label">Medicare/DVA Number</label>
        <input [class.has-error]="formControls.medicareNumber.invalid && (showInvalid$ | async)" type="text"
            class="form-control form-control-lg w-25" name="medicareNumber" formControlName="medicareNumber"
            id="medicareNumber" placeholder="123456789" required>
    </div>
    <div class="form-group pb-1">
        <label for="dateOfMedsCheck" class="input-label">Date of MedsCheck</label>
        <input [class.has-error]="formControls.dateOfMedsCheck.invalid && (showInvalid$ | async)" type="text"
            class="form-control form-control-lg w-25"
            value="patientDetailsForm.value.dateOfMedsCheck | date: 'DD-MM-YYYY'" formControlName="dateOfMedsCheck"
            id="dateOfMedsCheck" [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY' }" placeholder="dd-mm-yyyy" bsDatepicker>
    </div>
    <div class="form-group pb-1">
        <div>
            <label class="input-label">Reviewed By Pharmacist?</label>
        </div>
        <app-radio-highlight [hasError]="formControls.reviewed.invalid && (showInvalid$ | async)"
            controlName="reviewedTrue" [isHighlighted]="patientDetailsForm.value.reviewed == true">
            <input class="form-check-input " type="radio" name="reviewed" formControlName="reviewed" id="reviewedTrue"
                [value]="true">
            <label class="form-check-label" for="reviewedTrue">Yes</label>
        </app-radio-highlight>
        <app-radio-highlight [hasError]="formControls.reviewed.invalid && (showInvalid$ | async)"
            controlName="reviewedFalse" [isHighlighted]="patientDetailsForm.value.reviewed == false">
            <input class="form-check-input " type="radio" name="reviewed" formControlName="reviewed" id="reviewedFalse"
                [value]="false">
            <label class="form-check-label" for="reviewedFalse">No</label>
        </app-radio-highlight>
    </div>
</form>