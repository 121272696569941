import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxsModule } from '@ngxs/store';
import { ProjectedMessagesComponent } from './projected-messages.component';
import { ProjectedMessagesState } from './state/projected-messages.state';
import { TimezoneModule } from '../../../../modules/core/timezone/timezone.module';
import { LoaderModule } from '../../../../modules/core/loader/loader.module';
import { CommsQueueService } from '../../../../modules/pharmacy-comms/comms-queue/services/comms-queue.service';

@NgModule({
  declarations: [
    ProjectedMessagesComponent
  ],
  imports: [
    CommonModule,
    TimezoneModule,
    LoaderModule,
    NgxsModule.forFeature([ProjectedMessagesState])
  ],
  providers: [
    CommsQueueService
  ],
  exports: [
    ProjectedMessagesComponent
  ]
})
export class ProjectedMessagesModule { }
