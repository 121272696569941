import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { getClasses, getClassesWithBold } from './crm-notification-date.logic';

@Component({
  selector: 'app-notification-date',
  styleUrls: [],
  template: /*html*/`
        <div [class]="classes">{{ date | tenantDate : format}}</div>
    `
})
export class CrmNotificationDateComponent implements OnInit, OnChanges {
  @Input() date: Date | string;
  @Input() bold: boolean;
  @Input() format: string;
  @Input() ignoreColours: boolean = false;

  classes = '';

  ngOnInit() {
    this.setClasses();
  }

  ngOnChanges() {
    this.setClasses();
  }

  setClasses() {
    if (this.ignoreColours) return;

    this.classes = this.bold ? getClassesWithBold(this.date) : getClasses(this.date);
  }

}
