import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';
import { ProfileState } from './state/profile.state';
import { BaseApiModule } from '~/shared/core/base-api/base-api.module';
import { ClientRepository } from './client.repository';
import { ProfileResolver } from './profile.resolver';
import { DoctorsResolver } from './doctors.resolver';
import { CategoriesResolver } from './categories.resolver';
import { PharmacistsResolver } from './pharmacists.resolver';
import { UserService } from '~/shared/services/user.service';
import { NotesResolver } from './notes-resolver';
import { SixCpaResolver } from './six-cpa.resolver';

@NgModule({
  imports: [NgxsModule.forFeature([ProfileState]), BaseApiModule],
  providers: [ClientRepository, ProfileResolver, DoctorsResolver, CategoriesResolver, PharmacistsResolver, UserService, NotesResolver, SixCpaResolver],
})
export class ProfileModule { }
