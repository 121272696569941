import { State, Action, StateContext, Selector } from '@ngxs/store';
import { AuthRequestAction, AuthResponseAction, AuthErrorAction, AuthLogoutAction, AuthSetClientAction, AuthSetOrderAction,
  AuthSetTenantAction, AuthSetEncryptedTenantCustomerId } from './auth.actions';
import { AuthService } from '../auth.service';
import { catchError, switchMap } from 'rxjs/operators';
import { Auth } from '../../../order/models/auth.model';
import { Injectable } from '@angular/core';

export class AuthStateModel {
  public clientId: string;
  public orderId: number;
  public tenantId: number;
  public encryptedTenantCustomerId: string;
  public isAuth: boolean;
  public loading: boolean;
  public error: any;
}
@Injectable()
@State<AuthStateModel>({
  name: 'auth',
  defaults: {
    clientId: null,
    orderId: null,
    isAuth: false,
    encryptedTenantCustomerId: '',
    loading: false,
    error: null,
    tenantId: null,
  }
})
export class AuthState {
  constructor(private authService: AuthService) { }

  @Selector()
  static loading(state: AuthStateModel) { return state.loading; }

  @Selector()
  static isAuth(state: AuthStateModel) { return state.isAuth; }

  @Selector()
  static clientId(state: AuthStateModel) { return state.clientId; }

  @Selector()
  static orderId(state: AuthStateModel) { return state.orderId; }

  @Selector()
  static tenantId(state: AuthStateModel) { return state.tenantId; }

  @Selector()
  static encryptedTenantCustomerId(state: AuthStateModel) { return state.encryptedTenantCustomerId; }

  @Action(AuthSetTenantAction)
  setupTenant(ctx: StateContext<AuthStateModel>, action: AuthSetTenantAction) {
    const{ tenantId } = action;
    ctx.patchState({ tenantId });
  }

  @Action(AuthSetClientAction)
  setClient(ctx: StateContext<AuthStateModel>, action: AuthSetClientAction) {
    const{ clientId, } = action;
    ctx.patchState({ clientId });
  }

  @Action(AuthSetEncryptedTenantCustomerId)
  setEncryptedTenantCustomer(ctx: StateContext<AuthStateModel>, action: AuthSetEncryptedTenantCustomerId) {
    const { encryptedTenantCustomerId } = action;
    ctx.patchState({ encryptedTenantCustomerId });
  }

  @Action(AuthSetOrderAction)
  setup(ctx: StateContext<AuthStateModel>, action: AuthSetOrderAction) {
    const{ orderId } = action;
    ctx.patchState({ orderId });
  }

  @Action(AuthRequestAction)
  request(ctx: StateContext<AuthStateModel>, action: AuthRequestAction) {
    const state = ctx.getState();
    const auth = {
      clientId: state.clientId,
      tenantId: state.tenantId,
      orderId: state.orderId,
      encryptedTenantCustomerId: state.encryptedTenantCustomerId,
      DOB: action.DOB
    } as Auth;

    ctx.patchState({ loading: true});
    return this.authService.confirmIdentity(auth).pipe(
      switchMap((res) => ctx.dispatch(new AuthResponseAction(res))),
      catchError(error => ctx.dispatch(new AuthErrorAction(error)))
    );
  }
  
  @Action(AuthLogoutAction)
  logout(ctx: StateContext<AuthStateModel>, action: AuthLogoutAction) {
    ctx.patchState({ isAuth: false });
  }

  @Action(AuthResponseAction)
  handleResponse(ctx: StateContext<AuthStateModel>, { response }: AuthResponseAction) {
    ctx.patchState({ isAuth: true, orderId: response });
  }

  @Action(AuthErrorAction)
  handleError(ctx: StateContext<AuthStateModel>, action: AuthErrorAction) {
    ctx.patchState({ error: action.error });
  }

  @Action([AuthResponseAction, AuthErrorAction])
  stopLoading(ctx: StateContext<AuthStateModel>) {
    ctx.patchState({ loading: false });
  }
}
