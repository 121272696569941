import {Component, OnInit} from '@angular/core';
import {BimetricService} from '../../shared/services/bimetric.service';
import {Bimetric} from '../../shared/models/bimetric.model';

@Component({
  selector: 'app-bi-metric',
  templateUrl: './bi-metric.component.html',
  styleUrls: ['./bi-metric.component.scss']
})
export class BiMetricComponent implements OnInit {

  public metricList: Bimetric[] = [];
  isLoaded = false;
  private sub;

  constructor(private metricService: BimetricService) {
  }

  ngOnInit() {
    this.loadMetricList();
  }

  private loadMetricList() {
    this.sub = this.metricService.getBiMetricList().subscribe(
      (metric: Bimetric[]) => {
        this.metricList = metric;
        this.isLoaded = true;
      }
    );
  }

  public removeMetric(id: string) {
    this.sub.unsubscribe();
    this.metricService.removeBimetric(id).subscribe(
      data => {
        this.loadMetricList();
      }
    );
  }
}
