import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ZapiState, ZapiStateModel } from '../state/zapi.state';
import { Observable } from 'rxjs';
import { Select } from '@ngxs/store';

@Component({
  selector: 'app-z-api-client-dispense-info-results',
  templateUrl: './z-api-client-dispense-info-results.component.html',
  styleUrls: []
})
export class ZApiClientDispenseInfoResultsComponent implements OnInit {
  @Select(ZapiState.state) state$: Observable<ZapiStateModel>;
  @Output() close: EventEmitter<void> = new EventEmitter<void>();
  @Input() show: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

  closeResults() {
    this.close.emit();
  }
}
