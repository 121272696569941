import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MessengerComponent } from './messenger.component';
import { LoaderModule } from '../../../core/loader/loader.module';
import { HistoryComponent } from './history/history.component';
import { TimezoneModule } from '../../../core/timezone/timezone.module';
import { SendMessageComponent } from './send-message/send-message.component';
import { NotificationModule } from '../../../../shared/components/notification/notification.module';
import { FormsModule } from '@angular/forms';
import { FirestoreService } from '../../../../shared/services/firestore.service';
import { OrderByMessageDatePipe } from './pipes/message-order.pipe';
import { MessageModule } from '../message/message.module';
import { LinkShortenerService } from '../../../../shared/services/linkshortener.service';
import { NgxAutoScrollModule } from 'ngx-auto-scroll';

@NgModule({
  declarations: [
    MessengerComponent,
    HistoryComponent,
    SendMessageComponent,
    OrderByMessageDatePipe
  ],
  imports: [
    CommonModule,
    LoaderModule,
    TimezoneModule,
    NotificationModule,
    FormsModule,
    MessageModule,
    NgxAutoScrollModule
  ],
  providers: [
    FirestoreService,
    LinkShortenerService
  ],
  exports: [
    MessengerComponent
  ]
})
export class MessengerModule { }
