import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { Notification } from '../../models/notification.model';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationComponent {
  @Input() notification: Notification = new Notification('danger', '');
}
