import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-customer-order-route',
  templateUrl: './customer-order-route.component.html'
})
export class CustomerOrderRouteComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
