import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Note } from '../../../../shared/models/crm/note.model';

@Component({
  selector: 'app-actions',
  templateUrl: './actions.component.html',
  styleUrls: ['./actions.component.scss']
})

export class ActionsComponent implements OnInit {
  @Input() note: Note;
  @Output() clickTick: EventEmitter<Note> = new EventEmitter<Note>();
  @Output() clickEdit: EventEmitter<Note> = new EventEmitter<Note>();
  @Output() clickRemove: EventEmitter<Note> = new EventEmitter<Note>();
  @Output() clickLink: EventEmitter<Note> = new EventEmitter<Note>();

  isHiddenTick = false;
  canTick: boolean;
  canEdit: boolean;
  canDelete: boolean;
  canLink: boolean;
  canSelect: boolean;

  constructor() { }

  ngOnInit() {
    switch (this.note.type) {
      case 0:
        this.standardOptions();
        break;
      case 1:
      case 25:
        this.noEditOptions();
        break;
      case 2: case 3:
        this.systemGeneratedOptions();
        break;
      case 4:
        this.limitedOptions();
        break;
      case 7:
      case 6:
        this.standardOptions();
        break;
      default:
        this.standardOptions();
    }
  }

  // system gen actions that are tickable and deletable
  private noEditOptions = () => {
    this.canTick = true;
    this.canDelete = true;
    this.canEdit = false;
    this.canLink = false;
  }

  // system gen actions that are only tickable
  private limitedOptions = () => {
    this.canTick = true;
    this.canDelete = false;
    this.canEdit = false;
    this.canLink = false;
  }

  // user created actions actions
  private standardOptions = () => {
    this.canTick = true;
    this.canDelete = true;
    this.canEdit = true;
    this.canLink = false;
  }

  private systemGeneratedOptions = () => {
    this.canTick = true;
    this.canDelete = true;
    this.canEdit = true;
    this.canLink = true;
  }

  //This is a viable option -  just not currently used
  // system gen actions that are only editable
  /*
  private onlyEditOptions = () => {
    this.canTick = false;
    this.canDelete = false;
    this.canEdit = true;
    this.canLink = false;
  }
  */

  // system gen actions that are linked
  // private linkOptions = () => {
  //   this.isHiddenTick = true;
  //   this.canTick = false;
  //   this.canDelete = false;
  //   this.canEdit = false;
  //   this.canLink = true;
  // }

  clickedEdit = () => {
    this.clickEdit.emit(this.note);
  }

  clickedTick = () => {
    this.clickTick.emit(this.note);
  }
  clickedDelete = () => {
    this.clickRemove.emit(this.note);
  }
  clickedLink = () => {
    this.clickLink.emit(this.note);
  }
}
