import { State, Selector, Action, StateContext } from '@ngxs/store';
import { FetchSMSHistory, FetchSMSHistorySuccess, FetchSMSHistoryFailed } from './sms-history.actions';
import { SmsService } from '../../../../../shared/services/sms.service';
import { SmsMessageHistory } from '../../../../../shared/models/messageHistory.model';
import { tap, catchError, finalize } from 'rxjs/operators';
import { AlertService } from '../../../../../modules/core/alert/alert.service';
import { Injectable } from '@angular/core';

export class SMSHistoryStateModel {
  messages: SmsMessageHistory[];
  loading: boolean;
}
@Injectable()
@State<SMSHistoryStateModel>({
  name: 'smsHistory',
  defaults: {
    messages: [],
    loading: false
  }
})
export class SMSHistoryState {
  constructor(private smsService: SmsService, private alertService: AlertService) {}

  @Selector()
  static smsHistory(state: SMSHistoryStateModel) { return state.messages; }

  @Selector()
  static smsHistoryLoading(state: SMSHistoryStateModel) { return state.loading; }

  @Action(FetchSMSHistory)
  fetchSMSHistory(ctx: StateContext<SMSHistoryStateModel>, { clientId }: FetchSMSHistory) {
    ctx.patchState({loading: true})
    return this.smsService.getMessageHistory(clientId).pipe(
      tap(messages => ctx.dispatch(new FetchSMSHistorySuccess(messages))),
      catchError(err => ctx.dispatch(new FetchSMSHistoryFailed(err))),
      finalize(() => ctx.patchState({loading: false}))
    )
  }

  @Action(FetchSMSHistorySuccess)
  fetchSMSHistorySuccess(ctx: StateContext<SMSHistoryStateModel>, { messages }: FetchSMSHistorySuccess) {
    ctx.patchState({ messages })
  }

  @Action(FetchSMSHistoryFailed)
  fetchSMSHistoryFailed(ctx: StateContext<SMSHistoryStateModel>, { error }: FetchSMSHistoryFailed) {
    this.alertService.error(error.message);
  }
}
