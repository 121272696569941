import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { Store, Actions } from '@ngxs/store';
import { Subscription } from 'rxjs';
import { AdditionalDetailsState } from '../state/additional-details.state';
import { SetAdditionalDetailsProgress } from '../state/additional-details.actions';
import { ClinicalInterventionChildForm } from '../../clinical-intervention-child-form';

import { SixCpaState } from '../../state/six-cpa.state';
@Component({
  selector: 'app-additional-details-form',
  templateUrl: './additional-details-form.component.html',
  styleUrls: ['./additional-details-form.component.scss']
})
export class AdditionalDetailsFormComponent extends ClinicalInterventionChildForm implements OnInit, OnDestroy {

  constructor(private formBuilder: FormBuilder, private store: Store, actions: Actions) {
    super(actions);
    this.generateFormControls();
  }
  private readonlySubscription: Subscription;
  private formSubscription: Subscription;

  additionalDetailsForm: FormGroup;

  ngOnInit() {
    this.formSubscription = this.store.select(AdditionalDetailsState.additionalDetailsForm)
      .subscribe(() => this.onFormChange());
    this.readonlySubscription = this.store.select(SixCpaState.formsReadOnly)
      .subscribe(readonly => readonly ? this.additionalDetailsForm.disable() : this.additionalDetailsForm.enable());
  }

  ngOnDestroy() {
    this.formSubscription.unsubscribe();
    this.readonlySubscription.unsubscribe();
  }
  get formControls() {
    return this.additionalDetailsForm.controls;
  }
  protected actionOnResetForm() {
    this.setDefaults();
  }

  private setDefaults() {
    this.additionalDetailsForm.reset();
  }

  private onFormChange() {
    const controls = Object.keys(this.additionalDetailsForm.controls);
    const completedItems = this.getTotalValidItems(controls);
    this.store.dispatch(new SetAdditionalDetailsProgress(controls.length, completedItems));
  }

  private getTotalValidItems(controls: Array<string>) {
    return controls.filter((key) => {
      const formControl = this.additionalDetailsForm.get(key);
      return  formControl.status === 'VALID' || (formControl.status === 'DISABLED' && !formControl.invalid && formControl.errors === null);
    }).length;
  }

  private generateFormControls() {
    this.additionalDetailsForm = this.formBuilder.group({
      relevantMedicalConditions: new FormControl(null),
      clinicalInterventions: new FormControl(null),
      result: new FormControl(null),
    });
  }
}
