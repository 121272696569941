export interface PharmacyCommsDetails {
    id: number | null;
    communicationsEnabled: boolean;
    primaryChannel: ChannelPreference;
    secondaryChannel: ChannelPreference | null;
    drugAggregationDays: number;
    notificationSchedule: Date;
}

export enum ChannelPreference {
  Sms = 'Sms',
  PushNotifications = 'PushNotifications',
  Email = 'Email',
  ScryptMessenger = 'ScryptMessenger',
  ScryptApp = 'App'
}

