<div class="container" [ngClass]="{ 'container-bottom' : itemsTableMode, 'justify-content-end' : orderRequestRowMode }">
  <div class="mr-4" *ngIf="itemsTableMode">Update Order Status</div>
  <nz-select *ngIf="!orderRequestRowMode"
    class="select-status"
    ngDefaultControl
    [(ngModel)]="orderRequest.status"
  >
    <nz-option [nzValue]="orderStatusEnum.Pending" [nzLabel]="orderStatusLabelMap[orderStatusEnum.Pending]"></nz-option>
    <nz-option [nzValue]="orderStatusEnum.PartiallyReady" [nzLabel]="orderStatusLabelMap[orderStatusEnum.PartiallyReady]"></nz-option>
    <nz-option [nzValue]="orderStatusEnum.Ready" [nzLabel]="orderStatusLabelMap[orderStatusEnum.Ready]"></nz-option>
    <nz-option [nzValue]="orderStatusEnum.UnableToFill" [nzLabel]="orderStatusLabelMap[orderStatusEnum.UnableToFill]"></nz-option>
  </nz-select>
  <ng-container *ngIf="showNotifyCheckbox && !orderRequestRowMode">
    <div class="checkbox-container">
      <span nz-tooltip nzTooltipTitle="Notify Customer" [nzTooltipArrowPointAtCenter]="true" nzTooltipPlacement="top">
        <label nz-checkbox class="checkbox" [(ngModel)]="notifyCustomer"></label>
      </span>
      <span class="checkbox-label">Notify</span>
    </div>
  </ng-container>
  <button *ngIf="!orderRequestRowMode" class="update-button action-button button btn__yellow" [disabled]="originalStatus === orderRequest.status" (click)="updateStatus()">Update</button>
  <span *ngIf="showQuickCompleteButton" nz-tooltip nzTooltipTitle="Marks Order & Items as Completed" [nzTooltipArrowPointAtCenter]="true" nzTooltipPlacement="top">
    <button class="quick-complete-button action-button button btn__green" (click)="completeOrder()">Quick Complete</button>
  </span>
</div>
