import { NgModule } from '@angular/core';
import { TimezoneModule } from '../../../../core/timezone/timezone.module';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoaderModule } from '../../../../core/loader/loader.module';
import { NotificationModule } from '../../../../../shared/components/notification/notification.module';
import { NgxsModule } from '@ngxs/store';
import { MessageCycleModalComponent } from './message-cycle-modal.component';
import { MessageCycleState } from '../state/message-cycle.state';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { MessageCycleService } from '../../../../../shared/services/message-cycle.service';


@NgModule({
  declarations: [
    MessageCycleModalComponent
  ],
  imports: [
    TimezoneModule,
    CommonModule,
    FormsModule,
    LoaderModule,
    NotificationModule,
    BsDatepickerModule.forRoot(),
    ReactiveFormsModule,
    LoaderModule,
    NgxsModule.forFeature([MessageCycleState])
  ],
  providers: [
    MessageCycleService
  ],
  exports: [
    MessageCycleModalComponent
  ]
})
export class MessageCycleModalModule { }
