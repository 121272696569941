import { Injectable } from '@angular/core';
import { State, Selector, Action, StateContext } from '@ngxs/store';
import { IGPDetailsReferralFormStateModel } from './hmr-gp-details-referral-form-state-model.interface';
import { UpdateHMRGPDetailsReferralForm } from './hmr-gp-details-referral-form.actions';

  export class HMRGPDetailsReferralFormBasicLightStateModel {
    gpDetailsReferralForm: {
      model: Partial<IGPDetailsReferralFormStateModel>;
    };
  }

  @State<HMRGPDetailsReferralFormBasicLightStateModel>({
    name: 'hmrGPDetailsReferralFormBasicLight',
    defaults: {
      gpDetailsReferralForm: {
        model: undefined
      }
    }
  })
  @Injectable()
  export class HMRGPDetailsReferralFormBasicLightState {
    @Selector()
    static form(state: HMRGPDetailsReferralFormBasicLightStateModel) { return state.gpDetailsReferralForm.model; }


    @Action(UpdateHMRGPDetailsReferralForm)
    updateForm(ctx: StateContext<HMRGPDetailsReferralFormBasicLightStateModel>, { form }: UpdateHMRGPDetailsReferralForm) {
      if (form) {
        ctx.patchState({
          gpDetailsReferralForm: {
            model: form
          }
        });
      } else {
        const state = ctx.getState();
        ctx.patchState({
          gpDetailsReferralForm: {
            model: {
              ...state.gpDetailsReferralForm.model
            }
          }
        });
      }
    }
  }
