import { ExportToCsvRequest } from '../../../shared/models/export-to-csv-request.model';
import { SixCpaFormTypes } from "../../../modules/profile/six-cpa/six-cpa-form-types.enum";
import { MedsCheckFormModel } from '../../../shared/models/six-cpa/meds-check-form.model';

export enum CsvExportActions {
  EXPORT_TO_CSV = '[CsvExport] exportm',
  GET_MEDSCHECK_CSV = '[CsvExport] export medscheck',
  GET_HMR_CSV = '[CsvExport] export hmr',
  GET_DAA_CSV = '[CsvExport] export daa',
  GET_STAGED_SUPPLY_CSV = '[CsvExport] export staged supply',
  GET_CI_COUNT = '[CsvExport] get CI count',
  EXPORT_TO_CSV_SUCCESS = '[CsvExport] export success',
  EXPORT_TO_CSV_ERROR = '[CsvExport] export error',
  GET_MEDSCHECK_SINGLE_CSV = '[CsvExport] export medscheck single record'
}

export class ExportToCsv {
  static readonly type = CsvExportActions.EXPORT_TO_CSV;
  constructor(public request: ExportToCsvRequest, public serviceType: SixCpaFormTypes) { }
}

export class GetMedsCheckCsv {
  static readonly type = CsvExportActions.GET_MEDSCHECK_CSV;
  constructor(public request: ExportToCsvRequest) { }
}

export class GetMedsCheckPatientRegistrationCsv {
  static readonly type = CsvExportActions.GET_MEDSCHECK_SINGLE_CSV;
  constructor(public medsCheckForm: MedsCheckFormModel) { }
}

export class GetHMRCsv {
  static readonly type = CsvExportActions.GET_HMR_CSV;
  constructor(public request: ExportToCsvRequest) { }
}

export class GetStagedSupplyCsv {
  static readonly type = CsvExportActions.GET_STAGED_SUPPLY_CSV;
  constructor(public request: ExportToCsvRequest) { }
}

export class GetDAACsv {
  static readonly type = CsvExportActions.GET_DAA_CSV;
  constructor(public request: ExportToCsvRequest, public isIDAA: boolean) { }
}

export class GetCICount {
  static readonly type = CsvExportActions.GET_CI_COUNT;
  constructor(public request: ExportToCsvRequest) { }
}

export class ExportToCsvSuccess {
  static readonly type = CsvExportActions.EXPORT_TO_CSV_SUCCESS;
  constructor() { }
}

export class ExportToCsvError {
  static readonly type = CsvExportActions.EXPORT_TO_CSV_ERROR;
  constructor(public error: any) { }
}

