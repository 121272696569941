import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BulkStockAdjustmentComponent } from './bulk-stock-adjustment.component';
import { ScriptService } from '../../../../shared/services/script.service';
import { FormsModule } from '@angular/forms';
import { NgxsModule } from '@ngxs/store';
import { BulkStockAdjustmentState } from './state/bulk-stock-adjustment.state';
import { ProfileModule } from '../../../../modules/core/profile/profile.module';

@NgModule({
  declarations: [
    BulkStockAdjustmentComponent
  ],
  providers: [
    ScriptService
  ],
  imports: [
    CommonModule,
    FormsModule,
    ProfileModule,
    NgxsModule.forFeature([BulkStockAdjustmentState])
  ],
  exports: [
    BulkStockAdjustmentComponent
  ],
})
export class BulkStockAdjustmentModule { }
