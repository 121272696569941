import { MailTabs, QuickModifiers } from "~/shared/types/communications";

export const InboxQuickModifierDefaults = [
    {
      name: 'Unread',
      value: QuickModifiers.Unread,
      visible: [MailTabs.Inbox],
      defaults: [
        { tab: MailTabs.Inbox, default: false }
      ],
    },
    {
      name: 'Flagged',
      value: QuickModifiers.Flagged,
      visible: [MailTabs.Inbox],
      defaults: [
        { tab: MailTabs.Inbox, default: false },
      ],
    },
    {
      name: 'Unreplied',
      value: QuickModifiers.Unreplied,
      visible: [MailTabs.Inbox],
      defaults: [
        { tab: MailTabs.Inbox, default: false },
      ],
    },
    {
      name: 'Archived',
      value: QuickModifiers.Archived,
      visible: [MailTabs.Inbox],
      defaults: [
        { tab: MailTabs.Inbox, default: false }
      ],
    },
  ];
