<section>
  <div class="nav-panel">
    <ul class="nav-panel__list border__blue font__bold">
      <li g-xs="px3 py2">
        <a [routerLinkActive]="['active']" [routerLink]="profile">Profile</a>
      </li>
      <li g-xs="px3 py2">
        <a [routerLinkActive]="['active']" [routerLink]="notes">Notes</a>
      </li>
      <li g-xs="px3 py2">
        <a [routerLinkActive]="['active']" [routerLink]="orders">Order</a>
      </li>
      <li g-xs="px3 py2">
        <a [routerLinkActive]="['active']" [routerLink]="comms">Comms</a>
      </li>
      <li g-xs="px3 py2">
        <a [routerLinkActive]="['active']" [routerLink]="services">Services</a>
      </li>
    </ul>
  </div>
</section>
