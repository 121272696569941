<ng-container *ngIf="(messages$ | async) as messages">
  <div class="container" *ngIf="messages.length > 0">
    <div class="content">
      <ng-container *ngFor="let message of messages">
        <ng-container [ngSwitch]="message.type">
          <div *ngSwitchCase="sixCpaUserCommunicationTypes.success">
            <h3 class="p-3">The following operation was successful: </h3>
            <ul>
              <label class="custom">
                <li> {{message.message.message || message.message}} </li> <input type="checkbox" disabled [checked]="true" />
                <span class="checkmark"></span>
              </label>
            </ul>
          </div>
          <div class="info" *ngSwitchCase="sixCpaUserCommunicationTypes.info">
            <h3 class="p-3">Information: </h3>
            <ul>
              <label class="custom">
                <li> {{message.message}} </li>
              </label>
            </ul>
          </div>
          <div class="error" *ngSwitchCase="sixCpaUserCommunicationTypes.error">
            <h3 class="p-3">We've found an error: </h3>
            <ul>
              <label class="custom">
                <li> {{message.message.message || message.message}} </li> <input type="checkbox" disabled [checked]="false" />
                <span class="checkmark"></span>
              </label>
            </ul>
          </div>
       </ng-container>
      </ng-container>

      <button class="btn btn-warning" (click)="clearComms()"><strong>Clear Form</strong></button>
    </div>
  </div>
</ng-container>
