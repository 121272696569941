import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MedListMedicationProfileModule } from '../med-list-medication-profile/med-list-medication-profile.module';
import { NgxsModule } from '@ngxs/store';
import { MedicationListFormListState } from './state/medication-list-form.state';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import { ReactiveFormsModule } from '@angular/forms';
import { ConfirmResetModule } from '../../../../../shared/components/confirm-reset/confirm-reset.module';
import { MedicationListFormListComponent } from './medication-list-form-list.component';
import { MedicationListSaveState } from './state/medication-list-save.state';

@NgModule({
  declarations: [MedicationListFormListComponent],
  imports: [
    CommonModule,
    NgxsFormPluginModule,
    ReactiveFormsModule,
    MedListMedicationProfileModule,
    NgxsModule.forFeature([
      MedicationListFormListState,
      MedicationListSaveState,
    ]),
    ConfirmResetModule,
  ],
  exports : [ MedicationListFormListComponent ]
})
export class MedicationListFormListModule { }
