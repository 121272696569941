import { ToggleBladeAction } from '../../../../core/blade/state/blade.actions';

export const BULK_STOCK_ADJUSMENT_BLADE_NAME = '[BulkStockAdjustment]';

export enum BulkStockAdjusmentActionTypes {
  OPEN = '[BulkStockAdjustment] Open',
  CLOSE = '[BulkStockAdjustment] Close',
  BULK_ADJUST_REQUEST = '[BulkStockAdjustment] Request',
  BULK_ADJUST_RESPONSE = '[BulkStockAdjustment] Response',
  BULK_ADJUST_ERROR = '[BulkStockAdjustment] Error',
}

export class OpenBulkStockAdjustmentAction extends ToggleBladeAction {
  static readonly type = BulkStockAdjusmentActionTypes.OPEN;
  constructor() { super(BULK_STOCK_ADJUSMENT_BLADE_NAME); }
}

export class CloseBulkStockAdjustmentAction extends ToggleBladeAction {
  static readonly type = BulkStockAdjusmentActionTypes.CLOSE;
  constructor() { super(BULK_STOCK_ADJUSMENT_BLADE_NAME); }
}

export class BulkAdjustmentRequestAction {
  static readonly type = BulkStockAdjusmentActionTypes.BULK_ADJUST_REQUEST;
  constructor(public clientId: string, public request: any) {}
}

export class BulkAdjustmentResponseAction {
  static readonly type = BulkStockAdjusmentActionTypes.BULK_ADJUST_RESPONSE;
  constructor(public clientId: string) { }
}

export class BulkAdjustmentErrorAction {
  static readonly type = BulkStockAdjusmentActionTypes.BULK_ADJUST_ERROR;
  constructor(public error: any) {}
}
