import { Component, Directive, ViewContainerRef, ViewChild, ComponentFactoryResolver, Input, Type, OnChanges } from '@angular/core';
import { PatientDetailsFormBasicComponent } from '../basic/patient-details-form-basic.component';
import { PatientDetailsFormDetailedComponent } from '../detailed/patient-details-form-detailed.component';
import { HasViewContainerRef } from '../../has-view-container-ref.interface';
import { MedsCheckResolvedForm } from '../../meds-check-resolved-form';

@Directive({
  selector: '[appPatientDetailsFormHost]'
})
export class PatientDetailsFormHostDirective implements HasViewContainerRef {
  constructor(public viewContainerRef: ViewContainerRef) { }
}

@Component({
  selector: 'app-patient-details-form',
  templateUrl: './patient-details-form.component.html'
})
export class PatientDetailsFormComponent extends MedsCheckResolvedForm implements OnChanges {
  @Input() isDetailed: boolean;
  @ViewChild(PatientDetailsFormHostDirective, {static: true}) formHost: PatientDetailsFormHostDirective;

  constructor(
    componentFactoryResolver: ComponentFactoryResolver
  ) {
    super(componentFactoryResolver);
  }

  ngOnChanges() {
    this.resolveFormComponent(this.isDetailed);
  }

  protected getFormComponent(isDetailed: boolean): Type<any> {
    return isDetailed
      ? PatientDetailsFormDetailedComponent
      : PatientDetailsFormBasicComponent;
  }
}


