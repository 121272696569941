import { Component, OnInit, OnDestroy, InjectionToken, Inject } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { Store, Actions } from '@ngxs/store';
import { Subscription } from 'rxjs';
import { SixCpaState } from '../../../state/six-cpa.state';
import { DAAPatientDetailsFormBaseComponent } from '../daa-patient-details-form.base.component';
import { DAAPatientDetailsFormBasicState } from '../state/daa-patient-details-form-basic.state';

export const PATIENT_FORM_DEFAULT_SERVICE_DATE: InjectionToken<Date> = new InjectionToken('PATIENT_FORM_DEFAULT_MEDSCHECK_DATE');

@Component({
  selector: 'app-daa-patient-details-form-basic',
  templateUrl: './daa-patient-details-form-basic.component.html',
  styleUrls: ['./../daa-patient-details-form.component.scss'],
  providers: [
    { provide: PATIENT_FORM_DEFAULT_SERVICE_DATE, useValue: new Date() }
  ]
})
export class DAAPatientDetailsFormBasicComponent extends DAAPatientDetailsFormBaseComponent implements OnInit, OnDestroy {
  private formSubscription: Subscription;
  private readonlySubscription: Subscription;
  constructor(formBuilder: FormBuilder, store: Store, actions: Actions,  @Inject(PATIENT_FORM_DEFAULT_SERVICE_DATE)  defaultServiceDate: Date) {
    super(formBuilder, store, actions);
    this.patientDetailsForm.addControl('dateOfService',  new FormControl(defaultServiceDate, Validators.required));
  }

  ngOnInit() {
    this.formSubscription = this.store.select(DAAPatientDetailsFormBasicState.form)
      .subscribe(() => this.onFormChange());
    this.readonlySubscription = this.store.select(SixCpaState.formsReadOnly)
      .subscribe(readonly => readonly ? this.patientDetailsForm.disable() : this.patientDetailsForm.enable());
  }

  ngOnDestroy() {
    this.formSubscription.unsubscribe();
    this.readonlySubscription.unsubscribe();
  }
}
