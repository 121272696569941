import { SickLeavePatientDetailsFormModel } from '../../../../../../shared/models/six-cpa/sick-leave/sick-leave-patient-details-form.model';

export enum SickLeavePatientDetailsActions {
  UPDATE_PROGRESS = '[SickLeavePatientDetails] Update Progress',
  UPDATE = '[SickLeavePatientDetails] Update'
}

export class SetTotalItemsAndCompletedItems {
  static readonly type = SickLeavePatientDetailsActions.UPDATE_PROGRESS;
  constructor(public totalItems: number, public completedItems: number) { }
}

export class UpdateSickLeavePatientDetails {
  static readonly type = SickLeavePatientDetailsActions.UPDATE;
  constructor(public form: Partial<SickLeavePatientDetailsFormModel>) { }
}
