import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { AirLookupState } from './state/air-lookup-form.state';
import { ProfileState } from '../../../../core/profile/state/profile.state';
import { ClientViewModel } from '../../../../core/profile/client.model';
import { PharmacyCommsDetailsState } from '../../../../pharmacy-comms/pharmacy-comms-details/state/pharmacy-comms-details.state';
import { PharmacyLocation } from '../../../../../shared/models/pharmacy/pharmacy-address.model';
import { GetPharmacyLocationAction } from '../../../../pharmacy-comms/pharmacy-comms-details/state/pharmacy-comms-details.actions';
import * as moment from 'moment';
import { IndividualRequestType } from './state/air-lookup-form.model';
import { IdentifyIndividualWithMedicareType, IdentifyIndividualWithoutMedicareIrnType, IdentifyIndividualWithoutMedicareType } from '../models/identify-individual-request.model';
import { SubmitAIRLookupByMedicare, SubmitAIRLookupWithoutMedicare, SubmitAIRLookupWithoutMedicareIRN } from './state/air-lookup-form.actions';

@Component({
  selector: 'app-air-lookup-form',
  templateUrl: './air-lookup-form.component.html',
  styleUrls: ['./air-lookup-form.component.scss']
})
export class AirLookupFormComponent implements OnInit, OnDestroy {
  @Select(AirLookupState.saving) saving$: Observable<boolean>;
  @Select(AirLookupState.error) error$: Observable<any>;
  @Select(ProfileState.profile) profile$: Observable<ClientViewModel>;
  @Select(PharmacyCommsDetailsState.pharmacy) pharmacy$: Observable<PharmacyLocation>;
  airPaitentInfoForm: FormGroup;
  individualRequestType = IndividualRequestType;

  constructor(private fb: FormBuilder, private store: Store) { }

  ngOnInit(): void {
    this.generateFormControls();
    this.store.dispatch(new GetPharmacyLocationAction())
  }

  ngOnDestroy(): void {
    this.resetForm();
  }

  get formControls() {
    return this.airPaitentInfoForm.controls;
  }


  private generateFormControls() {
    const patient = this.store.selectSnapshot(ProfileState.profile).patient;
    const { cardNum, irn } = this.fixMedicareNum(patient && patient.medicareNo ? patient.medicareNo.trim() : '');
    
    this.airPaitentInfoForm = this.fb.group({
      dob: new FormControl(moment(patient.birthDate, 'YYYY-MM-DD').format('DD-MM-YYYY'), Validators.required),
      patientFirstName: new FormControl(patient.firstname, Validators.required),
      patientSurname: new FormControl(patient.surname, Validators.required),
      medicareNumber: new FormControl(cardNum, [Validators.required, Validators.minLength(10), Validators.maxLength(11)]),
      medicareIRN: new FormControl(irn),
      consent: new FormControl('', Validators.required),
      postCode: new FormControl(patient.homePostcode, Validators.required),
      individualRequestType: new FormControl(IndividualRequestType.byMedicare, Validators.required)
    });
  }

  submit() {
    this.buildAndSendLookupRequest();
  }

  private resetForm() {
    this.airPaitentInfoForm.reset();
  }


  private convertToEightDigitDate(date: Date): string {
    return moment(date, 'DD-MM-MMMM').format('DDMMYYYY');
  }

  private fixMedicareNum(medicareCardNumber: string) {
    let cardNum = medicareCardNumber
    let irn = ''
    if (medicareCardNumber.length === 11) {
      cardNum = medicareCardNumber.substring(0, 10)
      irn = medicareCardNumber.substring(10)
    }
    return {
      cardNum,
      irn
    }
  }


  private buildAndSendLookupRequest() {
    const lookupType = this.airPaitentInfoForm.value.individualRequestType;
    const patient = this.store.selectSnapshot(ProfileState.profile).customerProfile;

    switch (lookupType) {
      case IndividualRequestType.byMedicare: {
        const request = this.buildMedicareRequest();
        return this.store.dispatch(new SubmitAIRLookupByMedicare(request, patient.tenantCustomerId));
      }
      case IndividualRequestType.withoutMedicare: {
        const request = this.buildNoMedicareCardRequest();
        return this.store.dispatch(new SubmitAIRLookupWithoutMedicare(request, patient.tenantCustomerId));
      }
      case IndividualRequestType.withoutMedicareIRN: {
        const request = this.buildNoMedicareCardIRNRequest();
        return this.store.dispatch(new SubmitAIRLookupWithoutMedicareIRN(request, patient.tenantCustomerId));
      }
      default: {
        const request = this.buildMedicareRequest();
        return this.store.dispatch(new SubmitAIRLookupByMedicare(request, patient.tenantCustomerId));
      }
    }
  }

  private buildMedicareRequest() {
    const providerNumber = this.store.selectSnapshot(PharmacyCommsDetailsState.pharmacy).airProviderNumber;

    const request: IdentifyIndividualWithMedicareType = {
      individual: {
        medicareCard: {
          medicareCardNumber: this.formControls.medicareNumber.value,
          medicareIRN: this.formControls.medicareIRN.value,
        }
      },
      informationProvider: {
        providerNumber: providerNumber
      }
    }
    return request;
  }

  private buildNoMedicareCardRequest() {
    const providerNumber = this.store.selectSnapshot(PharmacyCommsDetailsState.pharmacy).airProviderNumber;

    const request: IdentifyIndividualWithoutMedicareType = {
      individual: {
        personalDetails: {
          dateOfBirth: this.convertToEightDigitDate(this.formControls.dob.value),
          firstName: this.formControls.patientFirstName.value,
          lastName: this.formControls.patientSurname.value,
        },
        address: {
          postCode: this.formControls.postCode.value
        }
      },
      informationProvider: {
        providerNumber: providerNumber
      }
    }
    return request;
  }

  private buildNoMedicareCardIRNRequest() {
    const providerNumber = this.store.selectSnapshot(PharmacyCommsDetailsState.pharmacy).airProviderNumber;

    const request: IdentifyIndividualWithoutMedicareIrnType = {
      individual: {
        personalDetails: {
          dateOfBirth: this.convertToEightDigitDate(this.formControls.dob.value),
          lastName: this.formControls.patientSurname.value,
        },
        address: {
          postCode: this.formControls.postCode.value
        },
        medicareCard: {
          medicareCardNumber: this.formControls.medicareNumber.value
        }
      },
      informationProvider: {
        providerNumber: providerNumber
      }
    }
    return request;
  }
}