import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { OrderService } from "~/modules/profile/order-requests/services/order.service";
import {
  SendOrderToQueue,
  UpdateOrderRequest,
  UpdateOrderRequestSuccess,
  GetOrderRequestsForSelectedTab,
  UpdateSelectedTab,
  UpdateSelectedStatusFilter,
  GetOrderRequestItems,
  PatchActionLoadingState,
  UpdateCurrentPage,
  PushOrderToQueue,
  GetOrderRequestItemsSuccess,
  GetPendingOrdersCount,
  CompleteOrderRequest,
  ResetOrderStatus, UpdateOriginalStatuses
} from "./order-mgmt.actions";
import { catchError, finalize, map, switchMap, tap } from "rxjs/operators";
import { HttpErrorResponse } from "@angular/common/http";
import { EMPTY, of } from "rxjs";
import { NzNotificationDataOptions, NzNotificationService } from "ng-zorro-antd/notification";
import { UpdateOrderRequestModel } from "@profile/order-requests/models/order-request-update.model";
import { OrderMethod, OrderRequestItemStatus, OrderRequestStatus } from "@profile/order-requests/models/order.enums";
import { mapOrderRequestItemstoDispenseItemsModel } from "@base/shared/helpers/dispense-request.helpers";
import { QueueDispenseRequestModel } from "@base/shared/models/dispense/queueDispenseRequest.model";
import { ScriptService } from "~/shared/services/script.service";
import { ContactMethods } from "@base/shared/models/communication/contact-methods.enum";
import { startCase } from 'lodash';
import { ChannelPreference } from "@base/shared/models/pharmacy/pharmacy-comms-details.model";
import { OrderRequestItem } from "@profile/order-requests/models/order-request-item.model";
import { MessageTemplateAddons, DefaultTags, TemplateLookUpTable, NextValidDispenseDate } from "@profile/order-requests/models/order-request-messages.constants";
import { StatusToOrdersDictionary } from "@profile/order-requests/models/order-request-search-result.model";
import { OrderRequest } from "@profile/order-requests/models/order-request.model";
import * as moment from "moment";
import { OrderRequestSearchDto } from "@profile/order-requests/models/order-request-search.model";
import { CompletedStatuses, DefaultPageSize, DefaultStatusFilters, IncomingStatuses, OrderRequestSortConstants, PartiallyReadyStatuses, TabToSortByFilter, TabToStatusFilterOptions } from "../order-mgmt.constants";
import { TenantDatePipe } from "@base/modules/core/timezone/pipes/tenant-date.pipe";
import {
  ActionLoadingMap,
  OrdersStateKey,
  StatusFilterOption,
  OrderStatusFilter,
  OrderRequestTableData,
  StatusFitlerOptionsSlice,
  TabName,
  OrderStatuses
} from "../order-mgmt.types";
import {EvaluteClients} from "~/system/dispense-run/state/dispense-run.actions";

/**
 * @internal Intended for use only within the OrderManagement module only.
 */
export interface OrderManagementStateModel {
    incomingOrders: OrderRequest[],
    partiallyReadyOrders: OrderRequest[],
    completedOrders: OrderRequest[],
    total: number, //total number of orders currently in the backend/db for the selected tab
    pendingOrdersCount: number,
    selectedTab: TabName,
    selectedStatusFilter: OrderStatusFilter,
    statusFilters: StatusFilterOption[],
    currentPage: number,
    loading: boolean,
    loadingActions: ActionLoadingMap,
    errors: string,
    originalStatuses: OrderStatuses,
}

@State<OrderManagementStateModel>({
    name: "OrderManagement",
    defaults: {
        incomingOrders: [],
        partiallyReadyOrders: [],
        completedOrders: [],
        total: 0,
        pendingOrdersCount: 0,
        selectedTab: 'Incoming Orders',
        selectedStatusFilter: 'Default',
        statusFilters: [],
        currentPage: 0,
        loading: false,
        loadingActions: null,
        errors: null,
        originalStatuses: {},
    }
})

@Injectable()
export class OrderManagementState {
    private defaultNotificationOptions: NzNotificationDataOptions = {
        nzDuration: 4000
    };

    private sortBy = OrderRequestSortConstants.requestedDateAscending;

    private defaultSearchFilters: OrderRequestSearchDto = {
        page: 0,
        pageSize: DefaultPageSize,
        sort: OrderRequestSortConstants.requestedDateAscending
    };

    constructor(
        private orderService: OrderService,
        private scriptService: ScriptService,
        private notificationService: NzNotificationService,
        private tenantPipe: TenantDatePipe) { }

    @Selector()
    static tableData(stateModel: OrderManagementStateModel): OrderRequestTableData {
        const { errors, ...tableData } = stateModel;
        return tableData as OrderRequestTableData;
    }

    @Selector()
    static loadingActions({ loadingActions }: OrderManagementStateModel) {
      return loadingActions;
    }

    @Selector()
    static statusFilterOptions({ loading, selectedTab, selectedStatusFilter, statusFilters }: OrderManagementStateModel) {
        return {
            selectedTab,
            selectedStatusFilter,
            statusFilters,
            loading
        } as StatusFitlerOptionsSlice;
    }

    @Selector()
    static totalOrders({ total }: OrderManagementStateModel) {
        return total;
    }

    @Selector()
    static selectedTab({ selectedTab }: OrderManagementStateModel) {
        return selectedTab;
    }

    @Selector()
    static selectedStatusFilter({ selectedStatusFilter }: OrderManagementStateModel) {
        return selectedStatusFilter;
    }

    @Selector()
    static currentStatusFitlers({ selectedStatusFilter, selectedTab }: OrderManagementStateModel) {
        return selectedStatusFilter === 'Default' ? DefaultStatusFilters[selectedTab] : [selectedStatusFilter];
    }

    @Selector()
    static currentPage({ currentPage }: OrderManagementStateModel) {
        return currentPage;
    }

    @Selector()
    static isLoading({ loading }: OrderManagementStateModel) {
      return loading;
    }

    @Selector()
    static errors({ errors }: OrderManagementStateModel) {
      return errors;
    }

    @Selector()
    static areFiltersAvailable({ statusFilters }: OrderManagementStateModel) {
        return statusFilters?.length;
    }

    @Selector()
    static isIncomingOrPartiallyReadyTab({ selectedTab }: OrderManagementStateModel) {
        return selectedTab === 'Incoming Orders' || selectedTab === 'Partially Ready Orders';
    }

    @Selector()
    static totalPartiallyReadyOrders({ partiallyReadyOrders }: OrderManagementStateModel) {
        return partiallyReadyOrders.length;
    }

    @Selector()
    static pendingOrdersCount({ pendingOrdersCount }: OrderManagementStateModel) {
        return pendingOrdersCount;
    }

    @Action(GetOrderRequestsForSelectedTab)
    getIncomingOrderRequests(ctx: StateContext<OrderManagementStateModel>, { paginationPage, statusFilters }: GetOrderRequestsForSelectedTab) {
        const { selectedTab, loadingActions } = ctx.getState();

        const searchFilters: OrderRequestSearchDto = {
            ...this.defaultSearchFilters,
            sort: this.sortBy,
            page: paginationPage,
            statuses: statusFilters
        }

        // Request always get all incoming and partially ready orders
        const keys: OrdersStateKey[] = ['incomingOrders', 'partiallyReadyOrders'];

        // Get also completed orders only if Completed Orders tab is selected
        if (selectedTab === 'Completed Orders') {
            keys.push('completedOrders');
        }

        ctx.patchState({loading: true});
        return this.orderService.getOrdersByPharmacy(searchFilters).pipe(
            tap(({ orderRequests }) => {
                const incomingOrders = this.reduceDictionaryByStatus(orderRequests, IncomingStatuses).orders;
                const partiallyReadyOrders = this.reduceDictionaryByStatus(orderRequests, PartiallyReadyStatuses).orders;
                const completedOrders = this.reduceDictionaryByStatus(orderRequests, CompletedStatuses).orders;

                let incomingOrderIds = incomingOrders.map(o => o.id);
                let partiallyReadyOrderIds = partiallyReadyOrders.map(o => o.id);
                let completedOrderIds = completedOrders.map(o => o.id);

                if (loadingActions) {
                    const prevIds = Object.keys(loadingActions).map(x => +x);
                    incomingOrderIds = incomingOrderIds.filter(id => !prevIds.includes(id));
                    partiallyReadyOrderIds = partiallyReadyOrderIds.filter(id => !prevIds.includes(id));
                    completedOrderIds = completedOrderIds.filter(id => !prevIds.includes(id));
                }

                const newLoadingActions = {
                    ...this.generateLoadingActionsState(incomingOrderIds),
                    ...this.generateLoadingActionsState(partiallyReadyOrderIds),
                    ...this.generateLoadingActionsState(completedOrderIds)
                }

                const updatedState: Partial<OrderManagementStateModel> = {
                    incomingOrders: [],
                    partiallyReadyOrders: [],
                    completedOrders: [],
                    loadingActions: {
                        ...loadingActions,
                        ...newLoadingActions
                    }
                };

                keys.map(key => {
                    if (key === 'incomingOrders') {
                        updatedState[key] = incomingOrders;
                    } else if (key === 'partiallyReadyOrders') {
                        updatedState[key] = partiallyReadyOrders;
                    } else if (key === 'completedOrders') {
                        updatedState[key] = completedOrders;
                    }
                });

                ctx.patchState(updatedState);
            }),
            tap(() => ctx.dispatch(new UpdateOriginalStatuses())),
            catchError((error: HttpErrorResponse) => {
                ctx.patchState({errors: error.message});
                return of(error)
            }),
            finalize(() => ctx.patchState({loading: false}))
        )
    }

    @Action(GetOrderRequestItems)
    getOrderRequestItems(ctx: StateContext<OrderManagementStateModel>, { orderRequestId }: GetOrderRequestItems) {
        ctx.dispatch(new PatchActionLoadingState(orderRequestId, 'isOrderItemsLoading', true));
        return this.orderService.getOrder(orderRequestId).pipe(
            map((res) => ctx.dispatch(new GetOrderRequestItemsSuccess(res))),
            catchError((error: HttpErrorResponse) => {
                ctx.patchState({errors: error.message});
                return of(error)
            }),
            finalize(() => ctx.dispatch(new PatchActionLoadingState(orderRequestId, 'isOrderItemsLoading', false)))
        )
    }

    @Action(GetOrderRequestItemsSuccess)
    getOrderRequestItemsSuccess(ctx: StateContext<OrderManagementStateModel>, { updatedOrder }: GetOrderRequestItemsSuccess) {
        const { incomingOrders, partiallyReadyOrders, completedOrders, selectedTab } = ctx.getState();

        let currentOrders: OrderRequest[] = [];
        let stateKey: OrdersStateKey | null = null;

        switch (selectedTab) {
            case 'Incoming Orders':
                stateKey = 'incomingOrders';
                currentOrders = incomingOrders;
                break;
            case 'Partially Ready Orders':
                stateKey = 'partiallyReadyOrders';
                currentOrders = partiallyReadyOrders;
                break;
            case 'Completed Orders':
                stateKey = 'completedOrders';
                currentOrders = completedOrders;
                break;
            default: throw new Error(`Tab ${selectedTab} is not supported`);
        }

        const { id, orderRequestedItems } = updatedOrder;
        const updatedOrders = currentOrders.map(order =>
            order.id === id ? {
                ...order,
                status: updatedOrder.status,
                orderRequestedItems
            } as OrderRequest : order
        );

        ctx.patchState({[stateKey]: updatedOrders})
    }

    @Action(UpdateOrderRequest)
    updateOrderStatus(ctx: StateContext<OrderManagementStateModel>, { order, notifyCustomer }: UpdateOrderRequest) {
        ctx.dispatch(new PatchActionLoadingState(order.id, 'isOrderStatusUpdating', true));

        const channelPreference = this.getChannelPreferenceFrom(order.customerPrefferedComms);
        const hasCommsChannel = order.customerPrefferedComms !== null && channelPreference !== null;

        const sendMessage = notifyCustomer && hasCommsChannel;
        let message = null;

        if(sendMessage) {
            const messageTemplate = this.getOrderNotificationTemplate(order.status, order.orderMethod);
            message = this.composeNotificationMessageFor(order, messageTemplate);
        }

        order.lastUpdated = moment.utc().format();
        const orderUpdate: UpdateOrderRequestModel = {
            ...order,
            sendMessage,
            messageText: message,
            messageChannel: channelPreference
        };

        return this.orderService.updateOrder(orderUpdate).pipe(
            tap(()=> {
                ctx.dispatch(new UpdateOrderRequestSuccess(order));
                this.notificationService.success(
                    `Order status update to ${startCase(OrderRequestStatus[order.status])}`,
                    sendMessage ? `${order.customerName} has been notified via ${ContactMethods[order.customerPrefferedComms]}` : null,
                    this.defaultNotificationOptions
                );
            }),
            tap(() => ctx.dispatch(new GetPendingOrdersCount())),
            catchError(() => {
                this.notificationService.error(
                    null,
                    `There was an issue updating the status to ${OrderRequestStatus[order.status]}, please try again`,
                    this.defaultNotificationOptions
                )
                return of();
            }),
            finalize(() => {
                ctx.dispatch(new PatchActionLoadingState(order.id, 'isOrderStatusUpdating', false));
            })
        )
    }

    @Action(UpdateOrderRequestSuccess)
    updateRequestedOrder(ctx: StateContext<OrderManagementStateModel>, { updatedOrder }: UpdateOrderRequestSuccess) {
        const { incomingOrders, partiallyReadyOrders, completedOrders, originalStatuses } = ctx.getState();

        const isIncomingOrder = incomingOrders.some(x => x.id === updatedOrder.id);
        const isPartiallyReadyOrder = partiallyReadyOrders.some(x => x.id === updatedOrder.id);
        const isCompletedOrder = completedOrders.some(x => x.id === updatedOrder.id);

        let sourceKey: OrdersStateKey;
        if (isIncomingOrder) {
            sourceKey = 'incomingOrders';
        } else if (isPartiallyReadyOrder) {
            sourceKey = 'partiallyReadyOrders';
        } else if (isCompletedOrder) {
            sourceKey = 'completedOrders';
        } else {
            throw new Error(`Order #${updatedOrder.id} is in neither incoming, partially ready, nor complete`);
        }

        const destinationTab = this.getDestinationTab(updatedOrder.status);
        const destinationKey = this.tabToStateKey[destinationTab];
        if (!destinationKey) {
          throw new Error(`Destination tab ${destinationTab} is not supported`);
        }

        // Build updated state arrays while restoring statuses from originalStatuses
        const updatedState: Partial<OrderManagementStateModel> = {
            incomingOrders: incomingOrders.map(order =>
                order.id === updatedOrder.id ? updatedOrder : { ...order, status: originalStatuses[order.id] ?? order.status }
            ),
            partiallyReadyOrders: partiallyReadyOrders.map(order =>
                order.id === updatedOrder.id ? updatedOrder : { ...order, status: originalStatuses[order.id] ?? order.status }
            ),
            completedOrders: completedOrders.map(order =>
                order.id === updatedOrder.id ? updatedOrder : { ...order, status: originalStatuses[order.id] ?? order.status }
            ),
        };

        // Remove the updatedOrder from its source list and add it to its destination list
        updatedState[sourceKey] = this.filterOutOrderById(updatedState[sourceKey] as OrderRequest[], updatedOrder.id);
        updatedState[destinationKey] = this.upsertOrder(updatedState[destinationKey] as OrderRequest[], updatedOrder);

        return ctx.patchState(updatedState);
    }

    @Action(SendOrderToQueue)
    sendOrderToQueue(ctx: StateContext<OrderManagementStateModel>, { order }: SendOrderToQueue) {
        const { orderRequestedItems } = order
        orderRequestedItems.forEach((x) => x.selected = this.isValidOrderRequestItem(x));

        const dispenseItems = mapOrderRequestItemstoDispenseItemsModel(orderRequestedItems, order.customerId);
        if(!dispenseItems.length) {
            this.notificationService.warning(
                "The items on this order were unable to be sent to the queue",
                `Please review order #${order.id}`,
                this.defaultNotificationOptions
            )
            return EMPTY;
        }

        const missingItems = orderRequestedItems.filter(x => !x.selected);

        const dispenseOptions = order.orderMethod === OrderMethod.Delivery ? ["Delivery"] : null;
        const dispenseNotes = this.generateNotes(order.patientNotes, missingItems);

        const toastWarningMessage = missingItems && missingItems.length > 0 ? `${missingItems.length} ${missingItems.length > 1 ? 'items' : 'item'} not sent to queue, please review order.` : null;
        const toastSuccessMessage = `${dispenseItems.length} ${dispenseItems.length > 1 ? 'items' : 'item'} sent to queue.`;

        const request: QueueDispenseRequestModel = {
            orderID: order.id,
            items: dispenseItems,
            patient: order.customerName,
            pickupDate: null,
            options: dispenseOptions,
            notes: dispenseNotes
        }

        ctx.dispatch(new PatchActionLoadingState(order.id, 'isOrderDispensing', true));
        return this.scriptService.dispenseEScript(request).pipe(
            tap(() => {
                if(toastWarningMessage) {
                    this.notificationService.warning(
                        toastSuccessMessage,
                        toastWarningMessage,
                        this.defaultNotificationOptions
                    )
                } else {
                    this.notificationService.success(
                        null,
                        toastSuccessMessage,
                        this.defaultNotificationOptions
                    )
                }

            }),
            catchError(() => {
                this.notificationService.error(
                    null,
                    `There was an issue sending order #${order.id} to the queue, please try again.`,
                    this.defaultNotificationOptions
                )
                return of();
            }),
            finalize(() => ctx.dispatch(new PatchActionLoadingState(order.id, 'isOrderDispensing', false)))
        )
    }

    // NOTE: Since getting medications for each order request item for each order is a very costly operation
    //          especially if we have loaded 50+ orders for a selected tab
    //          we have to get the order with its medicaitons before we send to queue.
    @Action(PushOrderToQueue)
    pushOrderToQueue(ctx: StateContext<OrderManagementStateModel>, { order }: PushOrderToQueue) {
        return this.orderService.getOrder(order.id).pipe(
            switchMap(response => {
                const medicationsExist = order.orderRequestedItems.every(x => x.customerMedication !== null);
                const actions = medicationsExist ? [new SendOrderToQueue(order)] : [new GetOrderRequestItemsSuccess(response), new SendOrderToQueue(response)];

                ctx.dispatch(actions);
                return EMPTY;
            }),
            catchError((error: HttpErrorResponse) => {
                ctx.patchState({errors: error.message});
                return of(error)
            })
        )
    }

    @Action(PatchActionLoadingState)
    updateDispensingOrder(ctx: StateContext<OrderManagementStateModel>, { orderId, key, isActionLoading }: PatchActionLoadingState) {
        const { loadingActions: actionLoadingStates } = ctx.getState();

        const currentState = actionLoadingStates[orderId];
        const newState = {
            ...currentState,
            [key]: isActionLoading
        }

        ctx.patchState({ loadingActions: {...actionLoadingStates, [orderId]: newState } })
    }

    @Action(UpdateSelectedTab)
    updateSelectedTab(ctx: StateContext<OrderManagementStateModel>, { tab }: UpdateSelectedTab) {
        const { selectedTab: prevSelectedTab, selectedStatusFilter, partiallyReadyOrders, completedOrders } = ctx.getState();

        this.sortBy = TabToSortByFilter[tab];

        ctx.patchState({
            selectedTab: tab,
            selectedStatusFilter: prevSelectedTab !== tab ? 'Default' : selectedStatusFilter,
            statusFilters: TabToStatusFilterOptions[tab]
        });

        ctx.dispatch(new UpdateSelectedStatusFilter('Default'));
    }

    @Action(UpdateSelectedStatusFilter)
    updateSelectedStatusFilter(ctx: StateContext<OrderManagementStateModel>, { statusFilter }: UpdateSelectedStatusFilter) {
        const { selectedTab, currentPage } = ctx.getState();
        const filters = this.getCurrentFilters(selectedTab, statusFilter);

        ctx.patchState({ selectedStatusFilter: statusFilter });
        ctx.dispatch(new GetOrderRequestsForSelectedTab(currentPage, filters));
    }

    @Action(UpdateCurrentPage)
    updateCurrentPage(ctx: StateContext<OrderManagementStateModel>, { page }: UpdateCurrentPage) {
        const { selectedStatusFilter, selectedTab } = ctx.getState();
        const filters = this.getCurrentFilters(selectedTab, selectedStatusFilter);

        ctx.patchState({ currentPage: page });
        ctx.dispatch(new GetOrderRequestsForSelectedTab(page, filters));
    }

    @Action(GetPendingOrdersCount)
    getPendingOrdersCount(ctx: StateContext<OrderManagementStateModel>) {
        return this.orderService.getPendingOrdersCount().pipe(
            tap(result =>
                ctx.patchState({ pendingOrdersCount: result })
            ),
            catchError((error: HttpErrorResponse) => {
                ctx.patchState({ errors: error.message });
                return of(error)
            })
        );
    }

    @Action(CompleteOrderRequest)
    completeOrder(ctx: StateContext<OrderManagementStateModel>, { order, notifyCustomer }: CompleteOrderRequest) {
        order.status = OrderRequestStatus.Ready;
        order.orderRequestedItems.map(item => item.orderRequestItemStatus = OrderRequestItemStatus.Ready);
        ctx.dispatch(new UpdateOrderRequest(order, notifyCustomer));
    }

    @Action(ResetOrderStatus)
    resetOrderStatus(ctx: StateContext<OrderManagementStateModel>, { orderId, originalStatus }: ResetOrderStatus) {
        const state = ctx.getState();

        const updatedIncomingOrders = state.incomingOrders.map(order =>
            order.id === orderId ? { ...order, status: originalStatus } : order
        );
        const updatedPartiallyReadyOrders = state.partiallyReadyOrders.map(order =>
            order.id === orderId ? { ...order, status: originalStatus } : order
        );
        const updatedCompletedOrders = state.completedOrders.map(order =>
            order.id === orderId ? { ...order, status: originalStatus } : order
        );

        ctx.patchState({
            incomingOrders: updatedIncomingOrders,
            partiallyReadyOrders: updatedPartiallyReadyOrders,
            completedOrders: updatedCompletedOrders
        });
    }

    @Action(UpdateOriginalStatuses)
    updateOriginalStatuses(ctx: StateContext<OrderManagementStateModel>) {
        const state = ctx.getState();

        // Create a new Record by combining all orders
        const originalStatuses: Record<number, OrderRequestStatus> = {
          ...state.incomingOrders.reduce((acc, order) => ({ ...acc, [order.id]: order.status }), {}),
          ...state.partiallyReadyOrders.reduce((acc, order) => ({ ...acc, [order.id]: order.status }), {}),
          ...state.completedOrders.reduce((acc, order) => ({ ...acc, [order.id]: order.status }), {})
        };

        // Update the state with the new originalStatuses object
        ctx.patchState({ originalStatuses });
    }

    private getCurrentFilters(selectedTab: TabName, statusFilter: OrderStatusFilter): OrderRequestStatus[] {
        return statusFilter === 'Default' ? DefaultStatusFilters[selectedTab] : [statusFilter];
    }

    private isValidOrderRequestItem = (item: OrderRequestItem) => {
        const isValidEScript = item?.eScriptRepeats && item?.eScriptRepeats > 0;
        const isValidCustomerMedication = item?.customerMedication && item?.customerMedication?.repeats && item?.customerMedication?.repeats > 0;
        return isValidEScript || isValidCustomerMedication;
    }

    private reduceDictionaryByStatus = (statusToOrdersMap: StatusToOrdersDictionary, statuses: string[]) => {
        return statuses.reduce((memo, s) => {
            const map = statusToOrdersMap[s];
            if(!map) {
                return memo
            }

            const { items, total } = map;
            const { orders: prevOrders, total: prevTotal } = memo;

            return {
                orders: items ? [...prevOrders, ...items] : prevOrders,
                total: prevTotal + total
            };
        }, {orders: [] as OrderRequest[], total: 0});
    }

    private generateLoadingActionsState = (ids: number[]): ActionLoadingMap => {
        return ids.reduce((map, id) => {
            map[id] = {
                isOrderDispensing: false,
                isOrderStatusUpdating: false,
                isOrderItemsLoading: false
            };
            return map;
        }, {} as ActionLoadingMap);
    }

    private generateNotes = (patientNotes: string, missingItems: OrderRequestItem[]) => {
        const missingItemsMessage = missingItems.map(x => `${x.name ?? x.stockItemName} - ${x?.customerMedication?.repeats ?? x.eScriptRepeats ?? 0} repeats\n`);
        let result = '';
        if (missingItems.length) {
            result = `Missing Items: ${missingItemsMessage}`;
        }
        if (patientNotes) {
            result += `Patient Notes: ${patientNotes}`;
        }
        return result;
    }

    private getChannelPreferenceFrom = (contactMethod: ContactMethods) => {
        switch(contactMethod) {
            case ContactMethods.email:
                return ChannelPreference.Email;
            case ContactMethods.sms:
                return ChannelPreference.Sms;
            case ContactMethods.scryptMessenger:
            case ContactMethods.scryptMobile:
                return ChannelPreference.ScryptApp;
            default: return null;
        }
    }

    private composeNotificationMessageFor = (order: OrderRequest, template: string) => {
        if (!template || !order) return '';

        // On Order
        let onOrderText = '';
        const onOrderItems = order.orderRequestedItems.filter(x => x.orderRequestItemStatus === OrderRequestItemStatus.OnOrder);
        if (onOrderItems.length) {
          const { onOrderExpected: arrivalDate } = onOrderItems.sort(
            (itemA, itemB) => itemA.onOrderExpected.getDate() - itemB.onOrderExpected.getDate(),
          ).shift();

          let messageAddon = '';
          switch(order.orderMethod) {
            case OrderMethod.Pickup: messageAddon = MessageTemplateAddons.onOrderPickUp; break;
            case OrderMethod.Delivery: messageAddon = MessageTemplateAddons.onOrderDelivery; break;
            default: messageAddon = ''; break;
          }

          onOrderText = `${messageAddon} ${this.formatDateForMessage(arrivalDate)}.`;
        }

        template = this.replaceTag(template, DefaultTags.onOrder, onOrderText);

        // Too Early
        let tooEarlyText = '';
        const tooEarlyItems = order.orderRequestedItems.filter(x => x.orderRequestItemStatus === OrderRequestItemStatus.TooEarly);
        if (tooEarlyItems.length) {
          const { nextValidDispenseDate: dispenseDate } = tooEarlyItems.sort(
            (itemA, itemB) => itemA.nextValidDispenseDate.getDate() - itemB.nextValidDispenseDate.getDate(),
          ).shift();
          tooEarlyText = NextValidDispenseDate(this.formatDateForMessage(dispenseDate));
        }
        template = this.replaceTag(template, DefaultTags.nextValidDispenseDate, tooEarlyText);

        // Unable to Fill
        const hasUnableToFillItems = order.orderRequestedItems.filter(x => x.orderRequestItemStatus === OrderRequestItemStatus.UnableToFill).length;
        const unableToFillText = hasUnableToFillItems ? MessageTemplateAddons.unableToFill : '';
        template = this.replaceTag(template, DefaultTags.unableToFill, unableToFillText);

        // OrderId
        if (order.id)
          template = this.replaceTag(template, DefaultTags.orderNumber, order.id.toString());

        // Order Link
        template = this.replaceTag(template, DefaultTags.paymentUrl, order.orderLink);

        // First Name
        template = this.replaceTag(template, DefaultTags.firstName, order.customerName.split(' ')[0]);

        return template;
    }

    private getDestinationTab = (orderRequestStatus: OrderRequestStatus) => {
        switch (orderRequestStatus) {
            case OrderRequestStatus.Pending:
                return 'Incoming Orders';
            case OrderRequestStatus.PartiallyReady:
                return 'Partially Ready Orders';
            case OrderRequestStatus.Ready:
            case OrderRequestStatus.UnableToFill:
            case OrderRequestStatus.Cancelled:
                return 'Completed Orders';
            default: throw new Error(`Status ${orderRequestStatus} is not supported`);
        }
    };

    private tabToStateKey: { [key: string]: OrdersStateKey } = {
        'Incoming Orders': 'incomingOrders',
        'Partially Ready Orders': 'partiallyReadyOrders',
        'Completed Orders': 'completedOrders',
    };

    private filterOutOrderById = (orders: OrderRequest[], orderId: number) =>
        orders.filter(order => order.id !== orderId);

    private upsertOrder = (orders: OrderRequest[], updatedOrder: OrderRequest) =>
        orders.some(order => order.id === updatedOrder.id)
            ? orders.map(order => order.id === updatedOrder.id ? updatedOrder : order)
            : [...orders, updatedOrder];

    private getOrderNotificationTemplate = (orderRequestStatus: OrderRequestStatus, orderMethod: OrderMethod): string => TemplateLookUpTable[orderRequestStatus]?.[orderMethod] || '';

    private replaceTag = (template: string, tag: string, replacement: string): string => template.replace(new RegExp(tag, 'g'), replacement);

    private formatDateForMessage = (date: Date): string => this.tenantPipe.transform(date.toString(), 'DD-MM-YYYY');
}
