<form [formGroup]="patientDetailsForm" ngxsForm="ssPatientDetailsFormDetailed.patientDetailsForm" class="p-4">
  <div class="form-group pb-1">
    <label for="firstName" class="input-label">Patients First Name</label>
    <input type="text" [class.has-error]="formControls.firstName.invalid && (showInvalid$ | async)"
      formControlName="firstName" class="form-control form-control-lg w-50" id="firstName"
      placeholder="Patients First Name" required>
  </div>
  <div class="form-group pb-1">
    <label for="surname" class="input-label">Patients Surname</label>
    <input type="text" [class.has-error]="formControls.surname.invalid && (showInvalid$ | async)"
      formControlName="surname" class="form-control form-control-lg w-50" id="surname" placeholder="Patients Surname"
      required>
  </div>
  <div class="form-group pb-1">
    <label for="dob" class="input-label">Patients Date of Birth</label>
    <input type="text" [class.has-error]="formControls.dob.invalid && (showInvalid$ | async)"
      class="form-control form-control-lg w-25" value="patientDetailsForm.value.dob" formControlName="dob" id="dob"
      [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY' }" placeholder="dd-mm-yyyy" bsDatepicker>
  </div>
  <div class="form-group pb-1">
    <label for="medicareNumber" class="input-label">Medicare/DVA Number</label>
    <input type="text" [class.has-error]="formControls.medicareNumber.invalid && (showInvalid$ | async)"
      class="form-control form-control-lg w-25" name="medicareNumber" formControlName="medicareNumber"
      id="medicareNumber" placeholder="123456789" required>
  </div>
  <div class="form-group pb-1">
    <div>
      <label class="input-label">Patients Gender</label>
    </div>
    <app-radio-highlight controlName="genderMale" [hasError]="formControls.gender.invalid && (showInvalid$ | async)"
      [isHighlighted]="patientDetailsForm.value.gender == 'M'">
      <input class="form-check-input " type="radio" name="gender" formControlName="gender" id="genderMale" value="M">
      <label class="form-check-label" for="genderMale">Male</label>
    </app-radio-highlight>
    <app-radio-highlight controlName="genderFemale" [hasError]="formControls.gender.invalid && (showInvalid$ | async)"
      [isHighlighted]="patientDetailsForm.value.gender == 'F'">
      <input class="form-check-input" type="radio" name="gender" formControlName="gender" id="genderFemale" value="F">
      <label class="form-check-label" for="genderFemale">Female</label>
    </app-radio-highlight>
  </div>
  <div class="form-group pb-1">
    <label for="residentialPostCode" class="input-label">Patients Residential Postcode</label>
    <input type="text" [class.has-error]="formControls.residentialPostCode.invalid && (showInvalid$ | async)"
      class="form-control form-control-lg w-25" formControlName="residentialPostCode" id="residentialPostCode"
      placeholder="2000" required>
  </div>
  <div class="form-group pb-1">
    <div>
      <label class="input-label">Is English the patient’s primary language at home?</label>
    </div>
    <app-radio-highlight controlName="isEnglishPrimaryLanguageYes"
      [hasError]="formControls.englishAtHome.invalid && (showInvalid$ | async)"
      [isHighlighted]="!!patientDetailsForm.value.englishAtHome">
      <input class="form-check-input" type="radio" name="englishAtHome" id="isEnglishPrimaryLanguageYes"
        formControlName="englishAtHome" [value]="true" checked>
      <label class="form-check-label " for="isEnglishPrimaryLanguageYes">Yes</label>
    </app-radio-highlight>
    <app-radio-highlight controlName="isEnglishPrimaryLanguageNo"
      [hasError]="formControls.englishAtHome.invalid && (showInvalid$ | async)"
      [isHighlighted]="!patientDetailsForm.value.englishAtHome">
      <input class="form-check-input" type="radio" name="englishAtHome" id="isEnglishPrimaryLanguageNo"
        formControlName="englishAtHome" [value]="false">
      <label class="form-check-label" for="isEnglishPrimaryLanguageNo">No</label>
    </app-radio-highlight>
  </div>
  <div class="form-group pb-1">
    <div>
      <label class="input-label-not-required">Does the patient identify as Aboriginal and/or Torres Strait
        Islander?</label>
    </div>
    <app-radio-highlight controlName="aboriginalYes"
      [hasError]="formControls.aboriginalOrTorresStraitIslander.invalid && (showInvalid$ | async)"
      [isHighlighted]="!!patientDetailsForm.value.aboriginalOrTorresStraitIslander">
      <input class="form-check-input" type="radio" name="aboriginalOrTorresStraitIslander" id="aboriginalYes"
        formControlName="aboriginalOrTorresStraitIslander" [value]="true">
      <label class="form-check-label" for="aboriginalYes">Yes</label>
    </app-radio-highlight>
    <app-radio-highlight controlName="aboriginalNo"
      [hasError]="formControls.aboriginalOrTorresStraitIslander.invalid && (showInvalid$ | async)"
      [isHighlighted]="!patientDetailsForm.value.aboriginalOrTorresStraitIslander">
      <input class="form-check-input" type="radio" name="aboriginalOrTorresStraitIslander" id="aboriginalNo"
        formControlName="aboriginalOrTorresStraitIslander" [value]="false" checked>
      <label class="form-check-label" for="aboriginalNo">No</label>
    </app-radio-highlight>
  </div>
  <div class="form-group pb-1">
    <div>
      <label class="input-label">Reviewed By Pharmacist?</label>
    </div>
    <app-radio-highlight controlName="reviewedTrue" [hasError]="formControls.reviewed.invalid && (showInvalid$ | async)"
      [isHighlighted]="!!patientDetailsForm.value.reviewed">
      <input class="form-check-input " type="radio" name="reviewed" formControlName="reviewed" id="reviewedTrue"
        [value]="true">
      <label class="form-check-label" for="reviewedTrue">Yes</label>
    </app-radio-highlight>
    <app-radio-highlight controlName="reviewedFalse"
      [hasError]="formControls.reviewed.invalid && (showInvalid$ | async)"
      [isHighlighted]="!patientDetailsForm.value.reviewed">
      <input class="form-check-input " type="radio" name="reviewed" formControlName="reviewed" id="reviewedFalse"
        [value]="false">
      <label class="form-check-label" for="reviewedFalse">No</label>
    </app-radio-highlight>
  </div>
</form>