<form [formGroup]="outcomesAndActionsForm" ngxsForm="ssOutcomesAndActionsForm.outcomesAndActionsForm" class="p-4">
  <div class="form-group mb-0 pb-2" formGroupName="stagedSupplyPharmacistActions"
    [class.has-error-group]="formControls.stagedSupplyPharmacistActions.invalid && (showInvalid$ | async)">
    <label class="input-label" for="">Actions undertaken by pharmacist as a result of the staged supply service?</label>
    <div *ngFor="let outcome of stagedSupplyPharmacistActions">
      <div class="form-check">
        <label class="form-check-label pl-2">
          <input class="form-check-input big-checkbox" [formControlName]="outcome.key" type="checkbox"
            [value]="outcome.value" />
          {{outcome.value}}</label>
      </div>
    </div>
  </div>
  <div class="form-group pb-1">
    <label for="patientRatingOfServicePreventingMedicineProblems" class="input-label">On a scale of 1 to 10 (low to
      high), how does the patient feel that participating in the staged supply service will have an impact on preventing
      a medicine related problem?</label>
    <input type="text"
      [class.has-error]="formControls.patientRatingOfServicePreventingMedicineProblems.invalid && (showInvalid$ | async)"
      class="form-control form-control-lg w-25" name="patientRatingOfServicePreventingMedicineProblems"
      formControlName="patientRatingOfServicePreventingMedicineProblems"
      id="patientRatingOfServicePreventingMedicineProblems" placeholder="5" required>
  </div>
  <div class="form-group pb-1">
    <label for="patientRatingOfServiceInMagaingMedicines" class="input-label">On a scale of 1 to 10 (low to high), how
      helpful does the patient think the staged supply service will be in managing the medicines?</label>
    <input type="text"
      [class.has-error]="formControls.patientRatingOfServiceInMagaingMedicines.invalid && (showInvalid$ | async)"
      class="form-control form-control-lg w-25" name="patientRatingOfServiceInMagaingMedicines"
      formControlName="patientRatingOfServiceInMagaingMedicines" id="patientRatingOfServiceInMagaingMedicines"
      placeholder="5" required>
  </div>
</form>