import { CommTemplate } from '../../../../../shared/models/pharmacy/comm-template.model';

export enum AddEditCommTemplateModalActions {
  CREATE = '[AddEditCommTemplateModal] create',
  CREATE_SUCCESS = '[AddEditCommTemplateModal] create success',
  FAILED = '[AddEditCommTemplateModal] failed',
  SUBMIT = '[AddEditCommTemplateModal] submit',
  SETUP = '[AddEditCommTemplateModal] setup',
  UPDATE = '[AddEditCommTemplateModal] update',
  UPDATE_SUCCESS = '[AddEditCommTemplateModal] update success',
  OPEN_MODAL = '[AddEditCommTemplateModal] open modal',
  CLOSE_MODAL = '[AddEditCommTemplateModal] close modal',
  CLEAR_MODAL_FORM = '[AddEditCommTemplateModal] CLEAR_MODAL_FORM'

}

export class CreateCommTemplateAction {
  static readonly type = AddEditCommTemplateModalActions.CREATE;
  constructor(public item: CommTemplate) { }
}

export class CreateCommTemplateSuccessAction {
  static readonly type = AddEditCommTemplateModalActions.CREATE_SUCCESS;
}

export class AddEditCommTemplateModalFailedAction {
  static readonly type = AddEditCommTemplateModalActions.FAILED;
  constructor(public error: any) { }
}

export class SubmitAddEditCommTemplateModalAction {
  static readonly type = AddEditCommTemplateModalActions.SUBMIT;
}

export class OpenAddEditCommTemplateModalAction {
  static readonly type = AddEditCommTemplateModalActions.OPEN_MODAL;
}
export class CloseAddEditCommTemplateModalAction {
  static readonly type = AddEditCommTemplateModalActions.CLOSE_MODAL;
}

export class SetupAddEditCommTemplateModalAction {
  static readonly type = AddEditCommTemplateModalActions.SETUP;
  constructor(public item?: CommTemplate) { }
}

export class UpdateCommTemplateAction {
  static readonly type = AddEditCommTemplateModalActions.UPDATE;
  constructor(public item: CommTemplate) { }
}

export class UpdateCommTemplateSuccessAction {
  static readonly type = AddEditCommTemplateModalActions.UPDATE_SUCCESS;
}

export class ClearCommTemplateModal {
  static readonly type = AddEditCommTemplateModalActions.CLEAR_MODAL_FORM;
}
