<nz-table class="table bordered" #fixedTable [nzData]="bulkMessages" nzTableLayout="fixed">
  <thead>
    <tr>
      <th style="width: 4%;"></th>
      <th style="width: 15%;">Scheduled</th>
      <th style="width: 15%;">Created</th>
      <th style="width: 20%;">Message</th>
      <th nzAlign="right" style="width: 10%;">Length <i class="far fa-info-circle ml-1"
          nz-tooltip
          nzTooltipTitle="SMS charged per 160 characters"
          nzTooltipPlacement="right"></i></th>
      <th nzAlign="right" style="width: 11%;">Recipients</th>
      <th nzAlign="center" style="width: 25%;">Actions</th>
    </tr>
  </thead>
  <tbody>
    <ng-container *ngFor="let bulkMessage of fixedTable.data">
      <tr>
        <td style="width: 4%;" [nzExpand]="expandSet.has(bulkMessage.id)"
          (nzExpandChange)="onExpandChange(bulkMessage.id, $event)"></td>
        <td style="width: 15%;">
          {{ bulkMessage.scheduledFrom | tenantDate: "DD/MM/YYYY hh:mm A" }}
        </td>
        <td style="width: 15%;">{{ bulkMessage.createdDate | tenantDate: "DD/MM/YYYY hh:mm A" }}</td>
        <td nzEllipsis style="width: 20%;">{{ bulkMessage.message }}</td>
        <td nzAlign="right" style="width: 10%;">
          {{bulkMessage.message.length}} chars
        </td>
        <td nzAlign="right" style="width: 11%;">{{bulkMessage.recipientCount}}</td>
        <td nzAlign="center" style="width: 25%;">
          <input type="button" class="btn btn-primary" value="Recipients"
            (click)="openRecipientsModal(bulkMessage)" />
          <ng-container *ngIf="canDeleteMessages">
            <!--NGZORRO does not allow tooltips to show on disabled button-->
            <span *ngIf="messageProcessing(bulkMessage) else canEdit" nz-tooltip [nzTooltipTrigger]="'hover'"
              [nzToolTipVisible]="true" nzTooltipTitle="Can't edit while processing">
              <input [disabled]="true" type="button" class="ml-2 btn btn-primary" value="Edit"
                (click)="editMessage(bulkMessage)" />
            </span>
            <ng-template #canEdit>
              <input [disabled]="isAutomatedBulkMessage(bulkMessage)" type="button" class="ml-2 btn btn-primary"
                value="Edit" (click)="editMessage(bulkMessage)" />
            </ng-template>
            <input type="button" class="ml-2 btn btn-danger" value="Delete" (click)="deleteUpcoming(bulkMessage.id)" />
          </ng-container>
        </td>
      </tr>
      <tr [nzExpand]="expandSet.has(bulkMessage.id)">
        <span>{{bulkMessage.message}}</span>
      </tr>
    </ng-container>
  </tbody>
</nz-table>

<app-bulk-messaging-recipients-modal [showRecipients]="showRecipientsModal" [bulkMessage]="currentBulkMessage$ | async"
  [isHistoric]="isHistoric" (close)="closeModal()" [isLoading]="loadingCurrentMessage$ | async"
  (addRecipientsToBulkMessage)="addRecipientsToNewBulkMessage()" [showAddToNewMessage]="true">
</app-bulk-messaging-recipients-modal>