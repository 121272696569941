import { Component, Input } from '@angular/core';
import { MailNote } from '../../../../../shared/models/mail.model';

@Component({
  selector: 'app-mail-note-history',
  templateUrl: './mail-note-history.component.html',
  styleUrls: ['./mail-note-history.component.scss']
})

export class MailNoteHistoryComponent {

  constructor() { }

  @Input() mailNoteList: MailNote[] = [];

}
