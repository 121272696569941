import { Selector } from '@ngxs/store';
import * as moment_ from 'moment';
const moment = moment_;
import { StagedSupplyFormListState, StagedSupplyFormListStateModel } from './staged-supply-form-list.state';
import { SSPatientDetailsFormDetailedState } from '../../patient-details-form/state/ss-patient-details-form-detailed.state';
import { SSConsentEligibilityFormDetailedState } from '../../consent-eligibility-form/state/ss-consent-eligibility-form-detailed.state';
import { SSMedicationInformationFormState } from '../../ss-medication-information-form/state/ss-medication-information-form.state';
import { SSOutcomesAndActionsFormState } from '../../ss-outcomes-and-actions-form/state/ss-outcomes-and-actions-form.state';
import { SSAdditionalDetailsFormState } from '../../ss-aditional-details-form/state/ss-additional-details-form.state';
import { StagedSupplyMedicationProfileState } from '../../staged-supply-medication-profile/state/staged-supply-medication-profile.state';
import { Medicine } from '../../../../../../shared/models/six-cpa/medicine.model';
import { StagedSupplyFormModel } from '../../../../../../shared/models/six-cpa/staged-supply/staged-supply-form.model';
import { SSAdditionalDetailsFormModel } from '../../../../../../shared/models/six-cpa/staged-supply/ss-additional-details-form.model';
import { SSOutcomesAndActionsFormModel } from '../../../../../../shared/models/six-cpa/staged-supply/ss-outcomes-and-actions-form.model';
import { SSMedicationInformationFormModel } from '../../../../../../shared/models/six-cpa/staged-supply/ss-medication-information-form.model';
import { SSConsentEligibilityFormModel } from '../../../../../../shared/models/six-cpa/staged-supply/ss-consent-eligibility-form.model';
import { SSPatientDetailsFormBasicState } from '../../patient-details-form/state/ss-patient-details-form-basic.state';
import { SSConsentEligibilityFormBasicState } from '../../consent-eligibility-form/state/ss-consent-eligibility-form-basic.state';
import { PatientDetailsFormModel } from '../../../../../../shared/models/six-cpa/patient-details-form.model';
import { Injectable } from '@angular/core';

@Injectable()
export class StagedSupplyFormsService {
  @Selector([
    StagedSupplyFormListState,
    SSPatientDetailsFormDetailedState.form,
    SSConsentEligibilityFormDetailedState.form,
    SSMedicationInformationFormState.medicationInformationForm,
    SSOutcomesAndActionsFormState.outcomesAndActionsForm,
    StagedSupplyMedicationProfileState.selectedMedicines
  ])
  static getStagedSupplyPatientRegistrationRequest(
    stagedSupplyState: StagedSupplyFormListStateModel,
    patientDetails: PatientDetailsFormModel,
    consentEligibility: SSConsentEligibilityFormModel,
    medicationInformation: SSMedicationInformationFormModel,
    outcomesAndActions: SSOutcomesAndActionsFormModel,
    selectedMedicines: Medicine[]): StagedSupplyFormModel {
    const patientDetailsForm = this.getPatientDetailsRegistrationData(stagedSupplyState.patientDetailsFormId, patientDetails);
    const consentAndEligibilityForm = this.getConsentAndEligibilityRegistrationData(stagedSupplyState.consentAndEligibilityFormId, consentEligibility);
    const medicationInformationForm = this.getMedicationInformationRegistrationData(stagedSupplyState.medicationInformationFormId, medicationInformation);
    const outcomesAndActionsForm = this.getOutcomesAndActionsRegistrationData(stagedSupplyState.outcomesAndActionsFormId, outcomesAndActions);
    const sixCpaMedications = this.getSixCpaMedications(selectedMedicines);
    return {
      id: stagedSupplyState.stagedSupplyId,
      sixCpaFormId: stagedSupplyState.sixCpaFormId,
      patientDetailsFormId: stagedSupplyState.patientDetailsFormId,
      consentAndEligibilityFormId: stagedSupplyState.consentAndEligibilityFormId,
      medicationInformationFormId: stagedSupplyState.medicationInformationFormId,
      outcomesAndActionsFormId: stagedSupplyState.outcomesAndActionsFormId,
      patientDetailsForm,
      consentAndEligibilityForm,
      medicationInformationForm,
      outcomesAndActionsForm,
      sixCpaMedications,
      stagedSupplyFormType: stagedSupplyState.stagedSupplyFormType
    };
  }

  @Selector([
    StagedSupplyFormListState,
    SSPatientDetailsFormBasicState.form,
    SSConsentEligibilityFormBasicState.form,
    SSAdditionalDetailsFormState.additionalDetailsForm,
    StagedSupplyMedicationProfileState.selectedMedicines
  ])
  static getStagedSupplyClaimRequest(
    stagedSupplyState: StagedSupplyFormListStateModel,
    patientDetails: PatientDetailsFormModel,
    consentEligibility: SSConsentEligibilityFormModel,
    additionalDetails: SSAdditionalDetailsFormModel,
    selectedMedicines: Medicine[]): StagedSupplyFormModel {
    const patientDetailsForm = this.getPatientDetailsClaimData(stagedSupplyState.patientDetailsFormId, patientDetails);
    const consentAndEligibilityForm = this.getConsentAndEligibilityClaimData(stagedSupplyState.consentAndEligibilityFormId, consentEligibility);
    const additionalDetailsForm = this.getAdditionalDetailsClaimData(stagedSupplyState.additionalDetailsFormId, additionalDetails);
    const sixCpaMedications = this.getSixCpaMedications(selectedMedicines);
    return {
      id: stagedSupplyState.stagedSupplyId,
      sixCpaFormId: stagedSupplyState.sixCpaFormId,
      patientDetailsFormId: stagedSupplyState.patientDetailsFormId,
      consentAndEligibilityFormId: stagedSupplyState.consentAndEligibilityFormId,
      additionalDetailsFormId: stagedSupplyState.additionalDetailsFormId,
      patientDetailsForm,
      consentAndEligibilityForm,
      additionalDetailsForm,
      sixCpaMedications,
      stagedSupplyFormType: stagedSupplyState.stagedSupplyFormType
    };
  }

  private static getPatientDetailsRegistrationData(id: number, patientDetails: PatientDetailsFormModel) {
    return patientDetails ? {
      id,
      dob: moment(patientDetails.dob).toDate(),
      medicareNumber: patientDetails.medicareNumber,
      gender: patientDetails.gender,
      residentialPostCode: patientDetails.residentialPostCode,
      firstName: patientDetails.firstName,
      surname: patientDetails.surname,
      englishAtHome: patientDetails.englishAtHome,
      aboriginalOrTorresStraitIslander: patientDetails.aboriginalOrTorresStraitIslander,
      reviewed: patientDetails.reviewed
    } : null;
  }

  private static getPatientDetailsClaimData(id: number, patientDetails: PatientDetailsFormModel) {
    return patientDetails ? {
      id,
      medicareNumber: patientDetails.medicareNumber,
      dob: moment(patientDetails.dob).toDate(),
      firstName: patientDetails.firstName,
      surname: patientDetails.surname,
      reviewed: patientDetails.reviewed
    } : null;
  }

  private static getConsentAndEligibilityRegistrationData(id: number, consentEligibility: SSConsentEligibilityFormModel) {
    return consentEligibility ? {
      id,
      dateOfPatientRegistration: moment(consentEligibility.dateOfPatientRegistration).toDate(),
      writtenConsentForProvisionOfData: consentEligibility.writtenConsentForProvisionOfData,
      gpOrHospitalLastSixMonthsForMedicationProblems: consentEligibility.gpOrHospitalLastSixMonthsForMedicationProblems
    } : null;
  }


  private static getConsentAndEligibilityClaimData(id: number, consentEligibility: SSConsentEligibilityFormModel) {
    return consentEligibility ? {
      id,
      writtenConsentForServiceProvision: consentEligibility.writtenConsentForServiceProvision,
      governmentIssuedConcessionCard: consentEligibility.governmentIssuedConcessionCard,
      pbsCodeOfTheMedicationSupplied: consentEligibility.pbsCodeOfTheMedicationSupplied,
      dateStagedSupplyServiceCommenced: moment(consentEligibility.dateStagedSupplyServiceCommenced).toDate()
    } : null;
  }

  private static getMedicationInformationRegistrationData(id: number, medicationInfo: SSMedicationInformationFormModel) {
    return medicationInfo ? {
      id,
      medicationHealthConditions: {
        cVD: medicationInfo.medicationHealthConditions.cVD,
        diabetes: medicationInfo.medicationHealthConditions.diabetes,
        osteoporosis: medicationInfo.medicationHealthConditions.osteoporosis,
        arthritis: medicationInfo.medicationHealthConditions.arthritis,
        pain: medicationInfo.medicationHealthConditions.pain,
        mentalHealthIssue: medicationInfo.medicationHealthConditions.mentalHealthIssue,
        epilepsy: medicationInfo.medicationHealthConditions.epilepsy,
        parkinsonsDisease: medicationInfo.medicationHealthConditions.parkinsonsDisease,
        dementia: medicationInfo.medicationHealthConditions.dementia,
        respiratoryDisorders: medicationInfo.medicationHealthConditions.respiratoryDisorders,
        alimentaryTract: medicationInfo.medicationHealthConditions.alimentaryTract,
        other: medicationInfo.medicationHealthConditions.other
      },
      medIndexScore: medicationInfo.medIndexScore,
      patientHadAnyProblemsOverThePastMonthWithTheirMedications: medicationInfo.patientHadAnyProblemsOverThePastMonthWithTheirMedications,
      gPPrescriberNumber: medicationInfo.gPPrescriberNumber,
      reasonsForStagedSupply: medicationInfo.reasonForStagedSupply,
      medicationHealthConditionsOtherDetails: medicationInfo.medicationHealthConditionsOtherDetails
    } : null;
  }

  private static getAdditionalDetailsClaimData(id: number, additionalDetails: SSAdditionalDetailsFormModel) {
    return additionalDetails ? {
      id,
      claimedCalendarMonth: additionalDetails.claimedCalendarMonth,
      claimedCalendarYear: additionalDetails.claimedCalendarYear,
      amountOfDaysInMonthPatientVisitedForStagedSupplyMedication: additionalDetails.amountOfDaysInMonthPatientVisitedForStagedSupplyMedication,
      dateOfPatientsFinalDosingBatch: moment(additionalDetails.dateOfPatientsFinalDosingBatch).toDate()
    } : null;
  }

  private static getOutcomesAndActionsRegistrationData(id: number, outcomesAndActions: SSOutcomesAndActionsFormModel) {
    return outcomesAndActions ? {
      id,
      patientRatingOfServicePreventingMedicineProblems: outcomesAndActions.patientRatingOfServicePreventingMedicineProblems,
      stagedSupplyPharmacistActions: outcomesAndActions.stagedSupplyPharmacistActions,
      patientRatingOfServiceInMagaingMedicines: outcomesAndActions.patientRatingOfServiceInMagaingMedicines
    } : null;
  }

  private static getSixCpaMedications(medicines: Medicine[]) {
    return medicines
      .map(m => ({
        id: m.id,
        productDispensedId: m.productDispensedId,
        brandName: m.brandName,
        genericName: m.genericName,
        directions: m.directions,
        quantity: m.quantity,
        reasonsForTaking: m.reasonsForTaking,
        notes: m.notes
      }));
  }
}
