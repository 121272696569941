import { State, Action, StateContext, Selector, Store } from '@ngxs/store';
import {
  ToggleSixCpaAction,
  SetProfileForSixCpaAction,
  ToggleSixCpaContentAction,
  QuickCISixCpaAction,
  SetSelectedFormType,
  StartNewSixCpaAction,
  SwitchProfileSixCpaAction,
  OpenResetFormModal,
  CloseResetFormModal,
  SetSixCpaFormDrafts,
  GetSixCpaFormError,
  SetSixCpaFormSummary,
  OpenPdfFormModal,
  ClosePdfFormModal,
  ClearSixCpaFormService
} from './six-cpa.actions';
import { ClientViewModel } from '@base/modules/core/profile/client.model';
import { SelectMedicationProfileListItemAction, ToggleMedicationProfileAction } from '../medication-profile/state/medication-profile.actions';
import { ResetCIAction, GetCIFormSuccess } from '../clinical-intervention/state/clinical-intervention.actions';
import { ResetMedsCheckFormListAction, GetMedsCheckFormSuccess } from '../meds-check/state/meds-check-form-list.actions';
import { ClinicalInterventionFormsService } from '../clinical-intervention/state/clinical-intervention-forms.service';
import { MedsCheckFormsProgressService } from '../meds-check/state/meds-check-forms-progress.service';
import { UpdateConsentEligibilityBasicForm } from '../consent-eligibility-form/state/consent-eligibility-form-basic.actions';
import { PharmacistState } from '@base/modules/pharmacy/state/pharmacist.state';
import { UpdatePharmacyInformationForm } from '../pharmacy-information-form/state/pharmacy-information-form.actions';
import * as moment from 'moment';
import { UpdateConsentEligibilityDetailedForm } from '../consent-eligibility-form/state/consent-eligibility-form-detailed.actions';
import { DAAFormsProgressService } from '../DAA/dose-administration-aids/state/daa-forms-progress.service';
import { SickLeaveFormsProgressService } from '../sick-leave/sick-leave-form-list/state/sick-leave-forms-progress.service';
import { ResetSickLeaveFormList, GetSickLeaveFormSuccess } from '../sick-leave/sick-leave-form-list/state/sick-leave.actions';
import { HMRFormsProgressService } from '../HMR/hmr-form-list/state/hmr-forms-progress.service';
import { StagedSupplyFormsProgressService } from '../Staged-Supply/staged-supply/state/staged-supply-forms-progress.service';
import { GetHMRFormSuccess, ResetHMRFormListAction } from '../HMR/hmr-form-list/state/hmr-form.actions';
import { SixCpaFormsService } from '../../../../shared/services/six-cpa-forms.service';
import { switchMap, catchError } from 'rxjs/operators';
import { GetDAAFormSuccess, ResetDAAFormListAction } from '../DAA/dose-administration-aids/state/dose-administration-aids.actions';
import { GetStagedSupplyFormSuccess, ResetStagedSupplyFormListAction } from '../Staged-Supply/staged-supply/state/staged-supply-form-list.actions';
import { ClearSixCpaCommunications } from '../six-cpa-user-communications/state/six-cpa-user-communications.actions';
import { GetMedicationListFormSuccess, ResetMedicationListFormListAction } from '../medication-list/medication-list-form-list/state/medication-list-form.actions';
import { MedicationListFormsProgressService } from '../medication-list/medication-list-form-list/state/medication-list-forms-progress.service';
import { Injectable } from '@angular/core';
import { SetAIRHistoryStatement } from '../AIR/air-statement/state/air-statement.actions';
import { TogglePatientConfirm } from '../AIR/air-lookup-form/state/air-lookup-form.actions';
import { SixCpaFormTypes, SixCpaFormTypesDictionary } from '../six-cpa-form-types.enum';
import { closeConfirmationActions } from './six-cpa-toggles-list';
import { SixCpaResponse } from '../../profile-services/state/profile-services.state';

export class SixCpaStateModel {
  public sixCpaMode: string;
  public profile: ClientViewModel;
  public sixCpaContentMode: string;
  public activeServiceType: SixCpaFormTypes;
  public showResetModal: boolean;
  public showPdfModal: boolean;
  public error: any;
  public sixCpaSummary: string[];
  public readOnly: boolean;
  public formsReadOnly: boolean;
}

@Injectable()
@State<SixCpaStateModel>({
  name: 'sixCpa',
  defaults: {
    sixCpaMode: 'out',
    sixCpaContentMode: 'in',
    profile: null,
    activeServiceType: SixCpaFormTypes.MedsCheck,
    showResetModal: false,
    showPdfModal: false,
    error: null,
    sixCpaSummary: null,
    readOnly: false,
    formsReadOnly: false
  }
})
export class SixCpaState {
  constructor(private store: Store, private sixCpaFormsService: SixCpaFormsService) { }

  @Selector()
  static readonly(state: SixCpaStateModel) { return state.readOnly; }

  @Selector()
  static formsReadOnly(state: SixCpaStateModel) { return state.formsReadOnly; }

  @Selector()
  static profile(state: SixCpaStateModel) { return state.profile; }

  @Selector()
  static activeServiceType(state: SixCpaStateModel) { return state.activeServiceType; }

  @Selector()
  static showResetModal(state: SixCpaStateModel) { return state.showResetModal; }

  @Selector()
  static showPdfModal(state: SixCpaStateModel) { return state.showPdfModal; }

  @Selector([ClinicalInterventionFormsService.getProgress,
  MedsCheckFormsProgressService.getProgress,
  DAAFormsProgressService.getProgress,
  SickLeaveFormsProgressService.getProgress,
  StagedSupplyFormsProgressService.getProgress,
  HMRFormsProgressService.getProgress,
  MedicationListFormsProgressService.getProgress
  ])
  static getProgress(
    state,
    CIProgress,
    MedsCheckProgress,
    DAAProgress,
    SickLeaveProgress,
    StagedSupplyProgress,
    HMRProgress,
    MedListProgress,
    AIRProgress) {
    switch (state.activeServiceType) {
      case SixCpaFormTypes.ClinicalIntervention:
        return CIProgress;
      case SixCpaFormTypes.MedsCheck:
        return MedsCheckProgress;
      case SixCpaFormTypes.DAA:
        return DAAProgress;
      case SixCpaFormTypes.SickLeaveCertificate:
        return SickLeaveProgress;
      case SixCpaFormTypes.HMR:
        return HMRProgress;
      case SixCpaFormTypes.StagedSupply:
        return StagedSupplyProgress;
      case SixCpaFormTypes.MedicationList:
        return MedListProgress;
      case SixCpaFormTypes.AIRLookup:
        return AIRProgress;
      default:
        return 0;
    }
  }

  @Action(ToggleSixCpaAction)
  toggle(ctx: StateContext<SixCpaStateModel>, action: ToggleSixCpaAction) {
    ctx.patchState({ sixCpaMode: action.sixCpaMode });
  }

  @Action(ToggleSixCpaContentAction)
  toggleContent(ctx: StateContext<SixCpaStateModel>, action: ToggleSixCpaContentAction) {
    ctx.patchState({ sixCpaContentMode: action.sixCpaContentMode });
  }

  @Action(SetProfileForSixCpaAction)
  setProfile(ctx: StateContext<SixCpaStateModel>, { profile }: SetProfileForSixCpaAction) {
    ctx.patchState({ profile });
    return this.sixCpaFormsService.getAllSixCPAFormDrafts(profile.clientId)
      .pipe(switchMap(response => ctx.dispatch(new SetSixCpaFormDrafts(response))),
        catchError(err => ctx.dispatch(new GetSixCpaFormError(err))));
  }
  
  @Action(SetSixCpaFormDrafts)
  setSixCpaFormDrafts(ctx: StateContext<SixCpaStateModel>, { sixCpaDrafts }: SetSixCpaFormDrafts) {
    // if (sixCpaDrafts.medsCheckForm) {
    //   ctx.dispatch([
    //     new GetMedsCheckFormSuccess(sixCpaDrafts.medsCheckForm),
    //     new SetSixCpaFormSummary({
    //       hasSubmitted: false,
    //       formType: SixCpaFormTypes.MedsCheck,
    //       id: sixCpaDrafts.medsCheckForm.sixCpaFormId
    //      } as SixCpaResponse)
    //   ]);
    // }
    // if (sixCpaDrafts.clinicalInterventionForm) {
    //   ctx.dispatch([
    //     new GetCIFormSuccess(sixCpaDrafts.clinicalInterventionForm),
    //     new SetSixCpaFormSummary({
    //       hasSubmitted: false,
    //       formType: SixCpaFormTypes.ClinicalIntervention,
    //       id: sixCpaDrafts.clinicalInterventionForm.sixCpaFormId
    //      } as SixCpaResponse)
    //   ]);
    // }
    // if (sixCpaDrafts.daaForm) {
    //   ctx.dispatch([
    //     new GetDAAFormSuccess(sixCpaDrafts.daaForm),
    //     new SetSixCpaFormSummary({
    //       hasSubmitted: false,
    //       formType: SixCpaFormTypes.DAA,
    //       id: sixCpaDrafts.daaForm.sixCpaFormId
    //      } as SixCpaResponse)
    //   ]);
    // }
    // if (sixCpaDrafts.stagedSupplyForm) {
    //   ctx.dispatch([
    //     new GetStagedSupplyFormSuccess(sixCpaDrafts.stagedSupplyForm),
    //     new SetSixCpaFormSummary({
    //       hasSubmitted: false,
    //       formType: SixCpaFormTypes.StagedSupply,
    //       id: sixCpaDrafts.stagedSupplyForm.sixCpaFormId
    //      } as SixCpaResponse)
    //   ]);
    // }
    // if (sixCpaDrafts.sickLeaveForm) {
    //   ctx.dispatch([
    //     new GetSickLeaveFormSuccess(sixCpaDrafts.sickLeaveForm),
    //     new SetSixCpaFormSummary({
    //        hasSubmitted: false,
    //        formType: SixCpaFormTypes.SickLeaveCertificate,
    //        id: sixCpaDrafts.sickLeaveForm.sixCpaFormId
    //       } as SixCpaResponse)
    //     ]);
    // }
    // if (sixCpaDrafts.hmrForm) {
    //   ctx.dispatch([
    //     new GetHMRFormSuccess(sixCpaDrafts.hmrForm),
    //     new SetSixCpaFormSummary({
    //       hasSubmitted: false,
    //       formType: SixCpaFormTypes.HMR,
    //       id: sixCpaDrafts.hmrForm.sixCpaFormId
    //      } as SixCpaResponse)
    //     ]);
    // }
    // if (sixCpaDrafts.medicationListForm) {
    //   ctx.dispatch([
    //     new GetMedicationListFormSuccess(sixCpaDrafts.medicationListForm),
    //     new SetSixCpaFormSummary({
    //       hasSubmitted: false,
    //       formType: SixCpaFormTypes.MedicationList,
    //       id: sixCpaDrafts.medicationListForm.sixCpaFormId
    //      } as SixCpaResponse)
    //   ]);
    // }
    // if (sixCpaDrafts.immunisationHistoryStatement) {
    //   ctx.dispatch(new SetAIRHistoryStatement(sixCpaDrafts.immunisationHistoryStatement));
    // }
  }

  @Action(GetSixCpaFormError)
  getSixCpaFormError(ctx: StateContext<SixCpaStateModel>, { error }: GetSixCpaFormError) {
    ctx.patchState({ error });
  }
  @Action(SetSelectedFormType)
  setSelectedFormType(ctx: StateContext<SixCpaStateModel>, action: SetSelectedFormType) {
    ctx.patchState({ activeServiceType: action.formType });
  }

  @Action(QuickCISixCpaAction)
  quickCI(ctx: StateContext<SixCpaStateModel>, action: QuickCISixCpaAction) {
    ctx.patchState({
      sixCpaMode: 'in',
      activeServiceType: SixCpaFormTypes.ClinicalIntervention
    });
    ctx.dispatch([
      new SelectMedicationProfileListItemAction(action.productDispensedId),
      new ToggleMedicationProfileAction('in')
    ]);
  }

  @Action(StartNewSixCpaAction)
  startNewSixCpa(ctx: StateContext<SixCpaStateModel>, {onlyClearFormIds}: StartNewSixCpaAction) {
    ctx.dispatch([
      ...closeConfirmationActions,
      new ResetCIAction(onlyClearFormIds),
      new ResetMedsCheckFormListAction(onlyClearFormIds),
      new ResetSickLeaveFormList(onlyClearFormIds),
      new ResetHMRFormListAction(onlyClearFormIds),
      new ClearSixCpaCommunications(),
      new ResetMedicationListFormListAction(onlyClearFormIds),
      new ResetStagedSupplyFormListAction(onlyClearFormIds),
      new ResetDAAFormListAction(onlyClearFormIds),
      new SetSixCpaFormSummary()
    ]);
  }

  @Action(SwitchProfileSixCpaAction)
  switchProfileSixCpa(ctx: StateContext<SixCpaStateModel>, action: SwitchProfileSixCpaAction) {
    ctx.dispatch(new UpdateConsentEligibilityBasicForm({ patientsConsent: null }));
    const pharmacist = this.store.selectSnapshot(PharmacistState.pharmacists);
    if (pharmacist[0] !== undefined) {
      const { id, ahpraNumber, givenNames, familyName } = pharmacist[0];
      ctx.dispatch(new UpdatePharmacyInformationForm({ id, ahpraNumber, pharmacist: `${givenNames} ${familyName}` }));
    }
    ctx.dispatch([
      new SetSelectedFormType(SixCpaFormTypes.MedsCheck),
      new UpdateConsentEligibilityDetailedForm(
        {
          patientsConsent: null,
          hasPreviousMedsCheck: false,
          currentLivingStatus: 0,
          currentLivingOtherDetails: '',
          gpOrHospitalLastSixMonthsForMedicationProblems: false,
          medicineManagementSupport: 0,
          followUpService: moment(new Date()).add(6, 'M').toDate()
        }),
      new StartNewSixCpaAction()
    ]);
  }

  @Action(OpenResetFormModal)
  openConfirmForm(ctx: StateContext<SixCpaStateModel>, action: OpenResetFormModal) {
    ctx.patchState({ showResetModal: true });
  }

  @Action(CloseResetFormModal)
  closeConfirmForm(ctx: StateContext<SixCpaStateModel>, action: CloseResetFormModal) {
    ctx.patchState({ showResetModal: false });
  }

  @Action(OpenPdfFormModal)
  openPdfForm(ctx: StateContext<SixCpaStateModel>, action: OpenPdfFormModal) {
    ctx.patchState({ showPdfModal: true });
  }

  @Action(ClosePdfFormModal)
  closePdfForm(ctx: StateContext<SixCpaStateModel>, action: ClosePdfFormModal) {
    ctx.patchState({ showPdfModal: false });
  }

  @Action(ClearSixCpaFormService)
  clearState(ctx: StateContext<SixCpaStateModel>) {
    ctx.patchState({
      sixCpaMode: 'out',
      sixCpaContentMode: 'in',
      showResetModal: false,
      showPdfModal: false,
      error: null,
      sixCpaSummary: null,
      readOnly: false,
      formsReadOnly: false
    });
  }

  @Action(SetSixCpaFormSummary)
  setSixCpaFormSummary(ctx: StateContext<SixCpaStateModel>, { selectedSixCpaResponse }: SetSixCpaFormSummary) {
    if (selectedSixCpaResponse) {
      const { hasSubmitted, claimId, id, formType, } = selectedSixCpaResponse;

      ctx.patchState({
        readOnly: hasSubmitted
      });

      const displayFormType = SixCpaFormTypesDictionary.get(formType);

      if (hasSubmitted && claimId !== null) {
        ctx.patchState({ sixCpaSummary: [
          `Submitted to PPA: Claim Id = ${claimId}`,
          `You are viewing a completed ${displayFormType} form: ${id}`
        ]});
        return;
      } else if (!hasSubmitted) {
        ctx.patchState({ sixCpaSummary: [
          `You are editing a draft ${displayFormType} form: ${id}`
        ]});
        return;
      } else {
        ctx.patchState({ sixCpaSummary: [
          `You are viewing a completed ${displayFormType} form: ${id}`
        ]});
        return;
      }
    } else {
      ctx.patchState({
        readOnly: false
      });
    }
    ctx.patchState({ sixCpaSummary: null });
  }
}

