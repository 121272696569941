import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NzProgressModule } from 'ng-zorro-antd/progress';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzTableModule } from 'ng-zorro-antd/table';
import { InboxTableComponent } from './inbox-table.component';
import { TenantDatePipe } from '~/modules/core/timezone/pipes/tenant-date.pipe';
import { TenantTimezonePipe } from '~/modules/core/timezone/pipes/tenantTimezone.pipe';
import { TimezoneModule } from '~/modules/core/timezone/timezone.module';
import { FormsModule } from '@angular/forms';
import { PipesModule } from '~/shared/pipes/pipes.module';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [
    InboxTableComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    NzProgressModule,
    NzCheckboxModule,
    NzSelectModule,
    NzDropDownModule,
    NzButtonModule,
    NzInputModule,
    NzDatePickerModule,
    TimezoneModule,
    NzTableModule,
    PipesModule
  ],
  exports: [
    InboxTableComponent
  ],
  providers: [
    TenantDatePipe,
    TenantTimezonePipe
  ],
})

export class InboxTableModule { }
