import { State, Action, StateContext, Selector } from '@ngxs/store';
import {
  OpenMessageCycleModal,
  CloseMessageCycleModal,
  CreateNewMessageCycle,
  UpdateMessageCycle,
  RemoveMessageCycle,
  CreateMessageCycleSuccess,
  HandleMessageCycleError,
  GetMessageCycles,
  OpenMessageCycleScheduleModal,
  CloseMessageCycleScheduleModal,
  RemoveMessageCycleSuccess
} from './message-cycle.actions';
import { MessageCycleModel } from '../../../../../shared/models/communication/messageCycleModel';
import { MessageCycleService } from '../../../../../shared/services/message-cycle.service';
import { tap, catchError, finalize } from 'rxjs/operators';
import { AlertService } from '../../../../core/alert/alert.service';
import { Injectable } from '@angular/core';

export class MessageCycleStateModel {
  public showModal: boolean;
  public showSchedule: boolean;
  public messageCycleForm: MessageCycleModel;
  public messageCycles: MessageCycleModel[];
  public loading: boolean;
}
@Injectable()
@State<MessageCycleStateModel>({
  name: 'messageCycle',
  defaults: {
    showModal: false,
    showSchedule: false,
    messageCycleForm: undefined,
    messageCycles: [],
    loading: false
  }
})
export class MessageCycleState {
  constructor(private messageCycleService: MessageCycleService, private alertService: AlertService) { }

  @Selector()
  static showModal(state: MessageCycleStateModel) { return state.showModal };

  @Selector()
  static showSchedule(state: MessageCycleStateModel) { return state.showSchedule };

  @Selector()
  static messageCycles(state: MessageCycleStateModel) { return state.messageCycles };

  @Selector()
  static messageCycleForm(state: MessageCycleStateModel) { return state.messageCycleForm };

  @Selector()
  static loading(state: MessageCycleStateModel) { return state.loading };

  @Action(OpenMessageCycleModal)
  openMessageCycleModal(ctx: StateContext<MessageCycleStateModel>, { }: OpenMessageCycleModal) {
    ctx.patchState({ showModal: true })
  }

  @Action(CloseMessageCycleModal)
  closeMessageCycleModal(ctx: StateContext<MessageCycleStateModel>, { }: CloseMessageCycleModal) {
    ctx.patchState({ showModal: false })
  }

  @Action(OpenMessageCycleScheduleModal)
  openMessageCycleScheduleModal(ctx: StateContext<MessageCycleStateModel>, { }: OpenMessageCycleScheduleModal) {
    ctx.patchState({ showSchedule: true })
  }

  @Action(CloseMessageCycleScheduleModal)
  closeMessageCycleScheduleModal(ctx: StateContext<MessageCycleStateModel>, { }: CloseMessageCycleScheduleModal) {
    ctx.patchState({ showSchedule: false })
  }

  @Action(CreateNewMessageCycle)
  createNewMessageCycle(ctx: StateContext<MessageCycleStateModel>, { messagecycle }: CreateNewMessageCycle) {
    ctx.patchState({ loading: true })
    return this.messageCycleService.createMessageCycle(messagecycle).pipe(
      tap(response => ctx.dispatch(new CreateMessageCycleSuccess(response))),
      catchError(err => ctx.dispatch(new HandleMessageCycleError(err))),
      finalize(() => ctx.patchState({ loading: false, showModal: false }))
    );
  }

  @Action(CreateMessageCycleSuccess)
  createMessageCycleSuccess(ctx: StateContext<MessageCycleStateModel>, { messageCycle }: CreateMessageCycleSuccess) {
    const messageCycles = ctx.getState().messageCycles;
    messageCycles.push(messageCycle);
    ctx.patchState({ messageCycles: messageCycles })
  }

  @Action(GetMessageCycles)
  getMessageCycles(ctx: StateContext<MessageCycleStateModel>, { clientId }: GetMessageCycles) {
    ctx.patchState({ loading: true });
    return this.messageCycleService.getActiveMessageCycles(clientId).pipe(
      tap(response => ctx.patchState({ messageCycles: response })),
      catchError(err => ctx.dispatch(new HandleMessageCycleError(err))),
      finalize(() => ctx.patchState({ loading: false })
      ));
  }

  @Action(UpdateMessageCycle)
  updateMessageCycle(ctx: StateContext<MessageCycleStateModel>, { messagecycle }: UpdateMessageCycle) {
    ctx.patchState({ loading: true });
    //TODO: Build in update functionality @Phase 2
    ctx.patchState({ showModal: false })
  }

  @Action(RemoveMessageCycle)
  removeMessageCycle(ctx: StateContext<MessageCycleStateModel>, { messageCycleId }: RemoveMessageCycle) {
    ctx.patchState({loading: true})
    return this.messageCycleService.removeMessageCycle(messageCycleId).pipe(
      tap(resp => ctx.dispatch(new RemoveMessageCycleSuccess(messageCycleId))),
      catchError(err => ctx.dispatch(new HandleMessageCycleError(err))),
      finalize(() => ctx.patchState({loading: false}))
    );
  }

  @Action(RemoveMessageCycleSuccess)
  removeMessageCycleSuccess(ctx: StateContext<MessageCycleStateModel>, { messageCycleId }: RemoveMessageCycleSuccess) {
    ctx.patchState({loading: true})
    let messageCycles =  ctx.getState().messageCycles;
    const messageCycleIndex =  messageCycles.findIndex(mc => mc.id == messageCycleId);
    messageCycles.splice(messageCycleIndex, 1)
    ctx.patchState({
      messageCycles: messageCycles,
      loading: false
    });
  }

  @Action(HandleMessageCycleError)
  handleMessageCycleError(ctx: StateContext<MessageCycleStateModel>, { error }: HandleMessageCycleError) {
    this.alertService.error(error.error)
  }
}
