import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrderViewComponent } from './order-view.component';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { TabsModule } from 'ngx-bootstrap';
import { NgxsModule } from '@ngxs/store';
import { OrderRequestState } from '../state/order.state';
import { PipesModule } from '../../../../shared/pipes/pipes.module';
import { ScryptInsightModule } from '../../../../shared/scrypt-insight/scrypt-insight.module';
import { FeaturesState } from '../../../../modules/core/features/features.state';
import { OrderCardModule } from '../components/order-card/order-card.module';
import { OrderTableModule } from '../components/order-table/order-table.module';
import { OrderUpdateStatusModule } from '../components/order-update-status-modal/order-update-status-modal.module';
import { OrderUpdateStatusCardModule } from '../components/order-update-status-card/order-update-status-card.module';
import { SendToQueueModalModule } from '../components/send-to-queue-modal/send-to-queue-modal.module';
import { ImageModalModule } from '../components/image-modal/image-modal.module';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { AdditionalItemsModalModule } from '../components/additional-items-modal/additional-items-modal.module';
import { AddMedicationModalModule } from '../components/add-medication-modal/add-medication-modal.module';
import { NzResultModule } from 'ng-zorro-antd/result';
import { StripeModalModule } from '../components/stripe-modal/stripe-modal.module';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';

@NgModule({
  declarations: [
    OrderViewComponent,
  ],
  imports: [
    NzResultModule,
    CommonModule,
    FormsModule,
    TabsModule,
    RouterModule,
    PipesModule,
    ScryptInsightModule,
    NgxsModule.forFeature([
      OrderRequestState,
      FeaturesState
      ]),
    OrderCardModule,
    OrderTableModule,
    OrderUpdateStatusModule,
    OrderUpdateStatusCardModule,
    SendToQueueModalModule,
    StripeModalModule,
    ImageModalModule,
    NzButtonModule,
    AdditionalItemsModalModule,
    AddMedicationModalModule,
    NzToolTipModule
  ]
})
export class OrderViewModule { }
