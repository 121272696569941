export enum ContentContainerActionTypes {
  ADD_CLASS = '[Content Container] Add class',
  REMOVE_CLASS = '[Content Container] Remove class'
}

export class AddContentContainerClassAction {
  static readonly type = ContentContainerActionTypes.ADD_CLASS;
  constructor(public class_: string) { }
}

export class RemoveContentContainerClassAction {
  static readonly type = ContentContainerActionTypes.REMOVE_CLASS;
  constructor(public class_: string) { }
}
