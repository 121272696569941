
export class NewNote {
    constructor(
        public customerId: string,
        public noteType: number,
        public body: string,
        public repeatType: number,
        public repeat: boolean,
        public startDate?: Date | string,
        public intervalValue?: number,
        public id?: number,
        public tenantCustomerId?: number,
        public entityId?: string,
    ) { }
}
