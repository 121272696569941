import { FormGroup, FormControl, ValidatorFn } from '@angular/forms';
import { Injectable } from '@angular/core';
import { Pharmacist } from '../../../shared/models/pharmacy/pharmacist.model';
@Injectable()
export class FormControlFunctions {

  /*
  Function for dynamically adding controls to a nested form group if required.
  Controls to be passed in as a key/value pair with the key as the form control name and value as label.
  See markup in MedicalInformationComponent for example of usage.
  */
  public generateControlsForGroup(childFormGroupName: string, controlsToAdd: any, parentFormGroup: FormGroup) {
    for (const cond of Object.keys(controlsToAdd)) {
      const group = parentFormGroup.get(childFormGroupName) as FormGroup;
      group.validator = this.requireCheckboxesToBeCheckedValidator();
      group.addControl(cond, new FormControl(false));
    }
  }

  public generateControlsForGroupFromArr(childFormGroupName: string, controlsToAdd: any[], parentFormGroup: FormGroup) {
    for (const cond of controlsToAdd) {
      const group = parentFormGroup.get(childFormGroupName) as FormGroup;
      group.validator = this.requireCheckboxesToBeCheckedValidator();
      group.addControl(cond.key, new FormControl(false));
    }
  }

  public generateControlsForGroupFromArrWithoutValidators(childFormGroupName: string, controlsToAdd: any[], parentFormGroup: FormGroup) {
    for (const cond of controlsToAdd) {
      const group = parentFormGroup.get(childFormGroupName) as FormGroup;
      group.addControl(cond.key, new FormControl(false));
    }
  }

  public getDefaultTime() {
    const now = new Date();
    const minutes = now.getMinutes();

    if (minutes >= 30) {
      return `${now.getHours() + 3}:00`
    }
    return `${now.getHours() + 2}:00`
  }
  /*
  Function for ensuring at least one checkbox in a group of checkboxes is selected.
  Paramaters of required amount of checkboxes can be passed to the function.
  */
  public requireCheckboxesToBeCheckedValidator(minRequired = 1): ValidatorFn {
    return function validate(formGroup: FormGroup) {
      let checked = 0;
      Object.keys(formGroup.controls).forEach(key => {
        const control = formGroup.controls[key];
        if (control.value === true) {
          checked++;
        }
      });
      if (checked < minRequired) {
        return {
          requireCheckboxesToBeChecked: true,
        };
      }
      return null;
    };
  }

  requiresNFieldsNotNullValidator(fieldsRequired = 1): ValidatorFn {
    return function validate(formGroup: FormGroup) {
      let validFields = 0;
      Object.keys(formGroup.controls).forEach(key => {
        const control = formGroup.controls[key];
        if (typeof control.value === 'string' && control.value.length > 0) {
          validFields++;
        }
        if (control.value instanceof Date && control.value != null) {
          validFields++;
        }
        if (control.value == true) {
          validFields++;
        }
      });
      if (validFields < fieldsRequired) {
        return {
          requiresNFieldsNotNull: true,
        };
      }
      return null;
    };
  }

  public setupPharmacistData(formGroup: FormGroup, firstNameField: string, lastName: string, pharmacistList: Pharmacist[]) {
    if (pharmacistList.length > 0) {
      const { givenNames, familyName } = pharmacistList[0];
      formGroup.patchValue({
        [firstNameField]: givenNames,
        [lastName]: familyName,
      });
    }
  }
}
