<ng-container *ngIf="profile$ | async as profile">
  <ng-container *ngIf="!(loading$ | async) as loading; else loading">
    <section *ngIf="isLoaded">
      <div>
        <div class="font__bold" g-xs="mb1">Date Range</div>
        <form [formGroup]="noteFiltersForm" ngxsForm="notes.noteFiltersForm">
          <div class="flex">
            <div class="calendar" g-xs="mr2">
              <input type="text" placeholder="From" class="form-control border__grey-light calendar"
                formControlName="fromDate" id="fromDate" [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY' }"
                placeholder="dd-mm-yyyy" bsDatepicker />
            </div>
            <div class="calendar">
              <input type="text" placeholder="From" class="form-control border__grey-light calendar"
                formControlName="toDate" id="toDate" [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY' }"
                placeholder="dd-mm-yyyy" bsDatepicker />
            </div>
          </div>
        </form>
      </div>
      <div class="crm-block">
        <div g-xs="mt3">
          <div class="table-responsive border__grey">
            <table class="table">
              <tr class="crm-block__title" (click)="toggleRequiresAction()">
                <td colspan="7" g-xs="px3 py2">
                  <span class="font__title-3">Requires Action</span>
                  <i class="fas fa-caret-up color__blue-text pull-right font__title-5"></i>
                </td>
              </tr>
              <tbody *ngIf="showRequiresAction$ | async">
                <tr class="crm-block__header">
                  <th>Due Date</th>
                  <th>Type</th>
                  <th>Note</th>
                  <th>Schedule</th>
                  <th></th>
                </tr>
                <tr *ngFor="
                    let note of noteList
                      | appNotesFilter: 'requires':(noteFiltersForm$ | async)
                  " class="body-row">
                  <td>
                    <app-notification-date [date]="note.nextActionDate" bold="true" format="DD MMM YYYY">
                    </app-notification-date>
                  </td>
                  <td>
                    <span>
                      <i class="{{ noteTypeList[note.type].img }}"></i>
                      {{ noteTypeList[note.type].name }}
                    </span>
                  </td>
                  <td class="crm-block__note-body font__title-0">
                    <span [outerHTML]="detectURLs(note.body)"></span>
                  </td>
                  <td *ngIf="note.repeat" class="font__title-0">
                    Every {{ note.intervalValue }}
                    {{ repeatType[note.repeatType] }} from
                    {{ note.startDate | tenantDate: "DD MMM YYYY" }}
                  </td>
                  <td *ngIf="!note.repeat">No Schedule</td>
                  <td class="flex crm-block__action">
                    <app-actions [note]="note" (clickTick)="actionNote(note)" (clickEdit)="setModifiedNote(note)"
                      (clickRemove)="removeNote(note)" (clickLink)="goToLink(note)"
                      (selectChanged)="selectNote($event)">
                    </app-actions>
                  </td>
                </tr>
              </tbody>
              <tr class="crm-block__title" (click)="toggleUpcoming()">
                <td colspan="7" g-xs="px3 py2">
                  <span class="font__title-3">Upcoming</span>
                  <i class="fas fa-caret-up color__blue-text pull-right font__title-5"></i>
                </td>
              </tr>
              <tbody *ngIf="showUpcoming$ | async">
                <tr class="crm-block__header">
                  <th>Due Date</th>
                  <th>Type</th>
                  <th>Note</th>
                  <th>Schedule</th>
                  <th></th>
                </tr>
                <tr *ngFor="
                    let note of noteList
                      | appNotesFilter: 'upcoming':(noteFiltersForm$ | async)
                  " class="body-row">
                  <td *ngIf="note.nextActionDate">
                    <span class="font__bold">
                      {{ note.nextActionDate | tenantDate: "DD MMM YYYY" }}
                    </span>
                  </td>
                  <td *ngIf="!note.nextActionDate">
                    <span class="font__bold">
                      {{ note.lastActioned | tenantDate: "DD MMM YYYY" }}
                    </span>
                  </td>

                  <td>
                    <span>
                      <i class="{{ noteTypeList[note.type].img }}"></i>
                      {{ noteTypeList[note.type].name }}
                    </span>
                  </td>
                  <td class="crm-block__note-body font__title-0">
                    <span [outerHTML]="detectURLs(note.body)"></span>
                  </td>
                  <td *ngIf="note.repeat" class="font__title-0">
                    Every {{ note.intervalValue }}
                    {{ repeatType[note.repeatType] }} from
                    {{ note.startDate | tenantDate: "DD MMM YYYY" }}
                  </td>
                  <td *ngIf="!note.repeat">No Schedule</td>
                  <td class="flex crm-block__action">
                    <app-actions [note]="note" (clickTick)="actionNote(note)" (clickEdit)="setModifiedNote(note)"
                      (clickRemove)="removeNote(note)" (clickLink)="goToLink(note)"
                      (selectChanged)="selectNote($event)">
                    </app-actions>
                  </td>
                </tr>
              </tbody>
              <tr class="crm-block__title" (click)="toggleHistory()">
                <td colspan="7" g-xs="px3 py2">
                  <span class="font__title-3">History</span>
                  <i class="fas fa-caret-up color__blue-text pull-right font__title-5"></i>
                </td>
              </tr>

              <tbody *ngIf="showHistory$ | async">
                <tr class="crm-block__header">
                  <th>Created</th>
                  <th>Type</th>
                  <th>Note</th>
                  <th>Schedule</th>
                  <th>Actioned</th>
                </tr>
                <tr *ngFor="
                    let note of noteList
                      | appNotesFilter: 'history':(noteFiltersForm$ | async)
                  " class="body-row">
                  <td>
                    <span>{{ note.created | tenantDate: "DD MMM YYYY" }}</span>
                  </td>
                  <td>
                    <span>
                      <i class="{{ noteTypeList[note.type].img }}"></i>
                      {{ noteTypeList[note.type].name }}
                    </span>
                  </td>
                  <td class="crm-block__note-body font__title-0">
                    <span [outerHTML]="detectURLs(note.body)"></span>
                  </td>
                  <td>
                    <span *ngIf="note.repeat" class="font__title-0">
                      Every {{ note.intervalValue }}
                      {{ repeatType[note.repeatType] }} from
                      {{ note.startDate | tenantDate: "DD MMM YYYY" }}
                    </span>
                    <span *ngIf="!note.repeat" class="font__title-0">No Schedule</span>
                  </td>
                  <td>
                    <span *ngIf="!!note.removedOn" class="font__title-0">
                      Removed on
                      {{ note.removedOn | tenantDate: "DD MMM YYYY" }}
                    </span>
                    <span *ngIf="!note.removedOn && !!note.lastActioned" class="font__title-0">
                      Actioned On
                      {{ note.lastActioned | tenantDate: "DD MMM YYYY" }}
                    </span>
                    <span *ngIf="
                        !note.removedOn &&
                        !note.lastActioned &&
                        !!note.nextActionDate
                      " class="font__title-0">
                      Actioned On
                      {{ note.nextActionDate | tenantDate: "DD MMM YYYY" }}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <section>
          <app-note-settings [noteList]="modifiedNotes" (closeEvent)="clearModifiedNote()"></app-note-settings>
        </section>
      </div>
    </section>
  </ng-container>
</ng-container>

<ng-template #loading>
  <app-loader></app-loader>
</ng-template>

<app-fluid-modal *ngIf="confirmNoteRemoval" (submitEvent)="confirmRemoval()" (closeEvent)="dismiss()"
  (cancelEvent)="dismiss()" submitText="Yes, delete Order Cycles" closeText="No, go back"
  title="Confirm removal of order cycle"
  message="Are you sure you wish to delete this Order Cycle? Deleting will inactivate all future reminders for this patient">
</app-fluid-modal>

<app-fluid-modal *ngIf="confirmOrderCycleReset" (submitEvent)="confirmCycleReset()"
  (closeEvent)="confirmMaintainCycle()" (cancelEvent)="dismiss()" showCancelButton="true"
  submitText="Yes (Next due date in {{currentNote.intervalValue}} days)"
  closeText="No (Next due date in {{daysUntilNextDueDate(currentNote)}} days)" title="Confirm removal of order cycle"
  message="This order cycle has been completed after the due date. Would you like to update the order cycle from today?">
</app-fluid-modal>