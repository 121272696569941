export const validMergeTags = [
  { key: 'FirstName', value: '<<FirstName>>' },
  { key: 'LastName', value: '<<LastName>>' },
  { key: 'PharmacyName', value: '<<PharmacyName>>' },
  { key: 'PharmacyPhone', value: '<<PharmacyPhone>>' },
  { key: 'PharmacyAddress', value: '<<PharmacyAddress>>' },
  { key: 'OrderLink', value: '<<OrderLink>>' },
  { key: 'RepeatTokenLink', value: '<<RepeatTokenLink>>' },
  { key: 'OptOutLink', value: '<<OptOutLink>>' }
];
