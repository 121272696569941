import { Component, OnInit, OnDestroy } from '@angular/core';
import { HMRChildForm } from '../hmr-child-form';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Store, Actions } from '@ngxs/store';
import { HMRLocationState } from './state/hmr-location.state';
import { SetTotalItemsAndCompletedItems } from './state/hmr-location.actions';
import { FormControlFunctions } from '../../form-control.functions';
import { SixCpaState } from '../../state/six-cpa.state';

const controlsToExclude = ['otherRecommendation'];
const controlsToIgnore = ['residentialPostCode', 'homeSuburb', 'outcomesAndRecommendations'];

@Component({
  selector: 'app-hmr-location',
  templateUrl: './hmr-location.component.html',
  styleUrls: ['./hmr-location.component.scss']
})
export class HMRLocationComponent extends HMRChildForm implements OnInit, OnDestroy {
  hmrLocationForm: FormGroup;
  formSubscription: Subscription;
  private readonlySubscription: Subscription;
  outcomesAndRecommendationsForFormArr = [
    {key: 'increaseInDoseOfMedicines', value: 'Increase in dose of one or more medicines'},
    {key: 'decreaseInDoseOfMedicines', value: 'Decrease in dose of one or more medicines'},
    {key: 'changeMedicines', value: 'Change of medicines to different medicine'},
    {key: 'cessationOfMedicines', value: 'Cessation of one or more medicines'},
    {key: 'other', value: 'Other'}
  ];

  constructor(private formBuilder: FormBuilder, private store: Store, actions: Actions, private formFunctions: FormControlFunctions) {
    super(actions);
    this.generateFormControls();
  }

  ngOnInit() {
    this.formSubscription = this.store.select(HMRLocationState.hmrLocationForm)
      .subscribe(() => this.onFormChange());
    this.readonlySubscription = this.store.select(SixCpaState.formsReadOnly)
      .subscribe(readonly => readonly ? this.hmrLocationForm.disable() : this.hmrLocationForm.enable());
  }

  ngOnDestroy() {
    this.formSubscription.unsubscribe();
    this.readonlySubscription.unsubscribe();
  }
  get formControls() {
    return this.hmrLocationForm.controls;
  }
  protected actionOnResetForm() {
    const controls = Object.keys(this.hmrLocationForm.controls);
    controls.filter((key) => {
      if (!controlsToIgnore.includes(key)) {
        this.hmrLocationForm.controls[key].setValue('');
      }
    });
    this.hmrLocationForm.patchValue(
      {
        tAndCs: null
      });
      this.hmrLocationForm.controls['outcomesAndRecommendations'].reset();
  }

  private onFormChange() {
    this.deselectOtherBoxes();
    const controls = Object.keys(this.hmrLocationForm.controls);
    const totalControls = controls.filter(x => !controlsToExclude.includes(x));
    const completedItems = this.getTotalValidItems(totalControls);
    this.store.dispatch(new SetTotalItemsAndCompletedItems(totalControls.length, completedItems));
  }

  private getTotalValidItems(controls: Array<string>) {
    return controls.filter((key) => {
      const formControl = this.hmrLocationForm.get(key);
      return  formControl.status === 'VALID' || (formControl.status === 'DISABLED' && !formControl.invalid && formControl.errors === null);
    }).length;
  }

  private generateFormControls() {
    this.hmrLocationForm = this.formBuilder.group({
      homeSuburb: new FormControl('', Validators.required),
      residentialPostCode: new FormControl('', Validators.required),
      outcomesAndRecommendations: this.formBuilder.group({}, Validators.required),
      otherRecommendation: new FormControl('', Validators.required),
      tAndCs: new FormControl(null, Validators.required),
    });
    this.formFunctions.generateControlsForGroupFromArr('outcomesAndRecommendations', this.outcomesAndRecommendationsForFormArr, this.hmrLocationForm);
  }

  private deselectOtherBoxes() {
    if (this.hmrLocationForm.value.outcomesAndRecommendations.other === false) {
      this.hmrLocationForm.controls['otherRecommendation'].setValue('');
    }
  }

}
