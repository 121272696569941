import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CipherComponent } from './components/cipher.component';
import { LoaderModule } from '../../core/loader/loader.module';
import { CipherState } from './state/cipher.state';
import { NgxsModule } from '@ngxs/store';
import { VerifyService } from '../../../shared/services/verify.service';

@NgModule({
  declarations: [CipherComponent],
  imports: [CommonModule, NgxsModule.forFeature([CipherState]), LoaderModule],
  providers: [VerifyService],
  exports: [CipherComponent],
})
export class CipherModule {}
