import { NgModule } from "@angular/core";
import { NgxsModule } from "@ngxs/store";
import { InvoicesPageService } from "../services/invoices-page.service";
import { InvoicesPageState } from "./invoices-page.state";

@NgModule({
    imports: [
      NgxsModule.forFeature([
        InvoicesPageState
      ])
    ],
    providers: [
      InvoicesPageService
    ]
  })
export class InvoicesPageSateModule { }
