import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Notification } from '../../../../../shared/models/notification.model';
import { MessageType } from '../../../../../shared/models/messageType.model';
import { Message, ScheduledMessage, CommunicationType } from '../../../../../shared/models/message.model';
import { SmsService } from '../../../../../shared/services/sms.service';
import { Subscription } from 'rxjs/Subscription';
import { SmsMessageHistory } from '../../../../../shared/models/messageHistory.model';
import { MarketingSetting } from '../../../../../shared/models/marketingSetting.model';
import * as moment from 'moment';
import { Store } from '@ngxs/store';
import { AddScheduledMessage } from '../../scheduled-messages/state/scheduled-messages.actions';
import { GetClientNotes } from '../../../../../modules/profile/notes/state/notes.actions';
import { CharacterCountService } from '../../../../../shared/helpers/character-count.service';
import { CreateStatistic } from '../../../../../shared/state/statistics/statistics.actions';
import { NzNotificationService } from 'ng-zorro-antd/notification';

@Component({
  selector: 'app-add-sms',
  templateUrl: './add-sms.component.html',
  styleUrls: ['./add-sms.component.scss']
})
export class AddSmsComponent implements OnInit, OnDestroy {

  constructor(
    private smsService: SmsService,
    private store: Store,
    private characterCountService: CharacterCountService,
    private notificationService: NzNotificationService
  ) { }

  @Input() clientId: string;
  @Input() hasMobileNumber: boolean;
  @Output() messageSentEvent = new EventEmitter<SmsMessageHistory>();

  isLoaded = false;
  sub1: Subscription;
  sub2: Subscription;
  notification: Notification;
  messageTypes: MessageType[] = [];
  showScheduleSMS = false;
  defaultDate: Date;
  defaultTime: Date;
  toastDuration: number = 4000;

  ngOnInit() {
    this.notification = new Notification('success', '');
    if (this.hasMobileNumber) {
      this.sub1 = this.smsService.getMessageTypes(this.clientId).subscribe((marketingSettings: MarketingSetting[]) => {
        this.messageTypes = marketingSettings.map(setting => ({
          id: `${setting.id}`,
          title: setting.key,
          message: setting.messageTemplate
        }));
        this.isLoaded = true;
      }, (error) => {
        this.notificationService.warning('Something went wrong', error, { nzDuration: this.toastDuration });
      });
    }
    else {
      this.isLoaded = true;
    }
  }

  disabledDates(current: Date): boolean {
    const minDate = moment().add(-1, 'days');
    const maxDate = moment().add(12, 'months');
    return !moment(current).isBetween(minDate, maxDate);
  }

  getCharacterCount(message: string) {
    return this.characterCountService.getCharacterCount(message);
  }

  getMessageCount(message: string) {
    return this.characterCountService.getMessageCount(message);
  }

  toggleScheduleForm() {
    this.showScheduleSMS = !this.showScheduleSMS;
  }

  onMessageTypeChange(form: NgForm) {
    const { messageTypeId } = form.value;
    const { message } = this.messageTypes.find(c => c.id === messageTypeId);
    form.form.patchValue({ message: message ?? '' });
  }

  onSubmit(form: NgForm) {
    const { scheduleSMS, scheduledDate, scheduledTime } = form.value;
    if (scheduleSMS && (scheduledDate || scheduledTime))
      this.scheduleSMS(form);
    else
      this.sendInstantSMS(form);
  }

  private scheduleSMS(form: NgForm) {
    const { message, messageTypeId } = form.value;
    let { scheduledDate, scheduledTime } = form.value;
    scheduledDate = scheduledDate || Date();
    scheduledTime = scheduledTime || moment().set({ hour: 10, minute: 0, second: 0 }).toDate();

    const isSendDateToday = moment(scheduledDate).isSame(moment(), 'day');
    if (isSendDateToday && scheduledTime) {
      const isSendTimeBeforeNow = moment(scheduledTime).isBefore(moment(), 'minute');
      if(isSendTimeBeforeNow) {
        scheduledTime = moment().add(5, 'minutes');
      }
    }
    scheduledDate = moment(scheduledDate).set({
      hour: moment(scheduledTime).hours(),
      minute: moment(scheduledTime).minutes()
    }).toDate();

    const smsMessage = new ScheduledMessage(
      this.clientId,
      message,
      scheduledDate,
      CommunicationType.SMS
    );

    this.sub2 = this.smsService.scheduleMessage(smsMessage)
      .subscribe((result) => {
        form.reset();
        this.showScheduleSMS = false;
        this.store.dispatch(new AddScheduledMessage(result));
        this.store.dispatch(new GetClientNotes(this.clientId));
        this.notificationService.success('Message scheduled successfully', '', { nzDuration: this.toastDuration });

      },
        (error) => {
          this.notificationService.warning('Something went wrong', error, { nzDuration: this.toastDuration });
        });
  }

  private sendInstantSMS(form: NgForm) {
    const { message, messageTypeId } = form.value;
    const smsMessage = new Message(this.clientId, message);

    this.sub2 = this.smsService.sendMessage(smsMessage)
      .subscribe((messageHistory: SmsMessageHistory) => {
        form.reset();
        this.showScheduleSMS = false;
        form.form.patchValue({ message: '', messageTypeId: '0' });
        this.messageSentEvent.emit(messageHistory);
        this.updateCommTemplateStatistic(messageTypeId);
        this.notificationService.success('Message sent successfully', '', { nzDuration: this.toastDuration });
      },
        (error) => {
          this.notificationService.warning('Something went wrong', error, { nzDuration: this.toastDuration });
        });
  }

  ngOnDestroy() {
    if (this.sub1) { this.sub1.unsubscribe(); }
    if (this.sub2) { this.sub2.unsubscribe(); }
  }

  private updateCommTemplateStatistic(id: string) {
    if(+id > 0) {
      const { title } = this.messageTypes.find(c => c.id === id);

      const request = {
        name: `CommTemplate - ${title}`,
        value: 1,
        entityId: +id,
        entityName: 'CommTemplate',
      };

      this.store.dispatch(new CreateStatistic(request));
    }
  }
}
