<section *ngIf="triggers.length" class="comms-list mt-4" >
  <div class="flex justify-content-between align-items-center pt-2 px-3 pb-1 pointer">
    <div class="font__title-4">{{patientType}}</div>
  </div>
  <div>
    <div *ngFor="let trigger of triggers">
    <div class="template-card">
      <table>
        <thead>
          <tr class="m-1">
            <th class="pl-3 blue-header">{{trigger.name}}</th>
            <th class="text-right p-2">
              <span class="alert alert-success mr-2 p-1" [ngClass]="trigger.enabled ? 'toggle-on' : 'toggle-off'">

                  <span class="text-dark font-weight-light">{{getTriggerType(trigger.triggerType)}}</span>
                  <span class="text-dark font-weight-light font-italic" *ngIf="trigger.leadTimeInDays"> ({{trigger.leadTimeInDays}} days {{getChronology(trigger.triggerType)}})</span> &nbsp;
                  <span class="text-dark font-weight-light font-italic" *ngIf="!trigger.leadTimeInDays"> (Immediate)</span> &nbsp;
                <ui-switch class="align-middle" color="#70B603" size="small" (change)="toggle(trigger.id)" [checked]="trigger.enabled"></ui-switch>
              </span>
              <button class="btn btn-outline-secondary text-dark" (click)="editTrigger(trigger)">Edit</button>
            </th>
          </tr>
        </thead>
      </table>
      <div class="inner-card">
        <table>
          <thead>
            <tr>
              <th>Message Name</th>
              <th>Subject Line</th>
              <th>Content</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{{trigger.commTemplate.name}}</td>
              <td>{{trigger.commTemplate.subject}}</td>
              <td>{{trigger.commTemplate.message}}</td>
              <td class="text-right pointer"><a (click)="editTemplate(trigger.commTemplate)">View message</a></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    </div>
  </div>
</section>

