export enum MultipleDrugsActionTypes {
  TOGGLE_DRUG = '[Multiple Drugs] Toggle',
  SELECT_DRUG = '[Multiple Drugs] Select',
  UNSELECT_DRUG = '[Multiple Drugs] Unselect',
  SELECT_MANY = '[Multiple Drugs] Select many',
  UNSELECT_MANY = '[Multiple Drugs] Unselect many',
  CLEAR_ALL = '[Multiple Drugs] Clear'
}

export class SelectDrugAction {
  static readonly type = MultipleDrugsActionTypes.SELECT_DRUG;
  constructor(public drug: any) { }
}

export class UnselectDrugAction {
  static readonly type = MultipleDrugsActionTypes.UNSELECT_DRUG;
  constructor(public drug: any) { }
}

export class SelectManyDrugsAction {
  static readonly type = MultipleDrugsActionTypes.SELECT_MANY;
  constructor(public drugs: any[]) { }
}

export class UnselectManyDrugsAction {
  static readonly type = MultipleDrugsActionTypes.UNSELECT_MANY;
  constructor(public drugs: any[]) { }
}

export class ToggleDrugSelectedAction {
  static readonly type = MultipleDrugsActionTypes.TOGGLE_DRUG;
  constructor(public drug: any) { }
}

export class ClearSelectedDrugsAction {
  static readonly type = MultipleDrugsActionTypes.CLEAR_ALL;
}


