import { PipeTransform, Pipe } from '@angular/core';
import { Store } from '@ngxs/store';
import { defaultDateFormat } from './tenant-date.config';
import { getTenantTimezoneDateString } from '../logic/timezone-tenant.logic';

@Pipe({
  name: 'tenantDate'
})
export class TenantDatePipe implements PipeTransform {

  constructor(private store: Store) { }

  transform(value: string, format: string = defaultDateFormat): string {
    return getTenantTimezoneDateString(this.store, value, format);
  }
}
