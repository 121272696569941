<div class="modal">
  <div class="modal-body border__grey" style="width: 300px;">
    <h3>SMS Schedule <small>(next 10 cycles)</small></h3>
    <h5 class="mt-1">Every {{messageCycle.intervalValue}} {{formatIntervalType(messageCycle.intervalType)}} starting {{messageCycle.startDate | tenantDate}}</h5>
    <div>
      <form class = "mt-2">
          <div *ngFor="let date of messageCycleSchedule">
            {{date | tenantDate}}
          </div>
      </form>
    </div>
    <div class="mt-2">
      <button class="button btn__yellow" (click)="closeModal()">Close</button>
    </div>
  </div>
</div>
<div class="modal-background"></div>
