import { NgModule } from '@angular/core';
import { TimezoneModule } from '../../../../core/timezone/timezone.module';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { LoaderModule } from '../../../../core/loader/loader.module';
import { NotificationModule } from '../../../../../shared/components/notification/notification.module';
import { TenantDatePipe } from '../../../../core/timezone/pipes/tenant-date.pipe';
import { MessageCycleScheduleListComponent } from './message-cycle-schedule-list.component';
import { MessageCycleState } from '../state/message-cycle.state';
import { NgxsModule } from '@ngxs/store';

@NgModule({
  declarations: [
    MessageCycleScheduleListComponent
  ],
  imports: [
    TimezoneModule,
    CommonModule,
    FormsModule,
    LoaderModule,
    NotificationModule,
    NgxsModule.forFeature([MessageCycleState]),

  ],
  providers: [
    TenantDatePipe
  ],
  exports: [
    MessageCycleScheduleListComponent
  ]
})
export class MessageCycleScheduleListModule { }
