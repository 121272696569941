<section>
  <div id="phone-history" g-xs="p3 mt2 mb3" class="history border__grey" ngx-auto-scroll observe-attributes>
    <div *ngIf="phoneNoteList.length === 0" class="color__red font__bold">
        No one record has been added yet.
    </div>
    <div g-xs="mb3" *ngFor="let item of phoneNoteList" >
      <div class="color__grey-text font__title-0">{{item.date | tenantDate}}</div>
      <div class="font__title-1" [ngClass]="{'color__red font__bold': item.note === highlightText}">
        {{item.note}}</div>
    </div>
  </div>
</section>