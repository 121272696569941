<ng-container>
    <nz-table
        #ordersTable
        nzBordered
        [nzData]="orderRequests"
        [nzLoading]="isLoading"
        nzSize="small"
        [nzFrontPagination]="false"
        [nzShowPagination]="true"
        [nzTotal]="totalOrders"
        [nzPageSize]="pageSize"
        [nzPageIndex]="pageIndex$ | async"
        (nzPageIndexChange)="onPageIndexChange($event)"
    >
        <ng-container [ngSwitch]="selectedTab">
            <ng-container *ngSwitchCase="'Incoming Orders'">
                <ng-container *ngTemplateOutlet="fullTable; context: { orders: ordersTable.data }"></ng-container>
            </ng-container>
            <ng-container *ngSwitchCase="'Partially Ready Orders'">
                <ng-container *ngTemplateOutlet="fullTable; context: { orders: ordersTable.data }"></ng-container>
            </ng-container>
            <ng-container *ngSwitchCase="'Completed Orders'">
                <ng-container *ngTemplateOutlet="completedOrdersTable; context: { orders: ordersTable.data }"></ng-container>
            </ng-container>
        </ng-container>

        <ng-template #fullTable let-orders="orders">
            <app-order-mgmt-table-headings [columns]="fullTableColumnns">
            </app-order-mgmt-table-headings>
            <tbody>
                <ng-container *ngFor="let order of orders; trackBy:trackByOrderId">
                    <ng-container [ngTemplateOutlet]="loadingActions[order.id].isOrderStatusUpdating ? tableRowSpinner : tableContent">
                    </ng-container>
                    <ng-template #tableContent>
                        <tr>
                            <ng-container *ngIf="order.orderRequestedItems.length; else noItems">
                              <td
                                  [nzExpand]="expandedRows.has(order.id)"
                                  (click)="onExpandChange(order.id, !expandedRows.has(order.id))"
                                  class="cursor-pointer"
                              ></td>
                            </ng-container>
                            <ng-template #noItems>
                                <td></td>
                            </ng-template>
                            <td nzAlign="center">
                                <nz-tag [nzColor]="orderStatusColourMap[getOrigingalStatus(order.id)]">
                                    {{ orderStatusLabelMap[getOrigingalStatus(order.id)] }}
                                </nz-tag>
                            </td>
                            <td>
                                <app-notification-date [date]="order.requestedDate" [format]="dateFormat" [ignoreColours]="true"></app-notification-date>
                            </td>
                            <td>
                                <ng-container *ngIf="order.customerId; else defaultId">
                                    <a [routerLink]="['/profile', order.customerId, 'orders', order.id]" target="_blank" class="link">
                                        {{order.id}}
                                    </a>
                                    <ng-template #defaultId>{{order.id}}</ng-template>
                                </ng-container>
                            </td>
                            <td>{{order.customerName ?? "Unknown"}}</td>
                            <td>{{order?.orderRequestedItems?.length ?? 0}}</td>
                            <td nzAlign="center">
                                <span *ngIf="orderBrandPreference(order) as brandPreference">
                                    <i [ngClass]="brandPreference"></i>
                                </span>
                            </td>
                            <td nzAlign="center">
                              <app-address-tooltip
                                [order]="order"
                              ></app-address-tooltip>
                            </td>
                            <td
                                nzAlign="center"
                                nz-tooltip
                                [nzTooltipTitle]="order.patientNotes ? order.patientNotes : null"
                                [nzTooltipArrowPointAtCenter]="true"
                            >
                                <i [ngClass]="order.patientNotes ? 'far fa-check success': 'far fa-times danger'"></i>
                            </td>
                            <td>
                                <app-send-to-queue-button
                                    [order]="order"
                                    [loading]="loadingActions[order.id].isOrderDispensing"
                                    (sendOrderToQueueEvent)="handleSendOrderToQueue($event)"
                                >
                                </app-send-to-queue-button>
                            </td>
                            <td>
                                <app-status-selector
                                    [order]="order"
                                    [showNotifyCheckbox]="isIncomingOrPartiallyReadyTab$ | async"
                                    [orderRequestRowMode]="expandedRows.has(order.id)"
                                    [showQuickCompleteButton]="selectedTab === 'Incoming Orders'"
                                    [itemsTableMode]="false"
                                    (statusUpdatedEvent)="handleStatusUpdate($event)"
                                    (completeOrderEvent)="completeOrder($event)"
                                ></app-status-selector>
                            </td>
                            <td class="link-column">
                                <a
                                    [routerLink]="['/profile', order.customerId, 'orders', order.id]"
                                    target="_blank"
                                    class="link"
                                >
                                    View Order <i class="fal fa-arrow-right"></i>
                                </a>
                            </td>
                        </tr>
                    </ng-template>
                    <tr [nzExpand]="expandedRows.has(order.id)">
                        <app-order-request-items-table
                            [order]="order"
                            [selectedTab]="selectedTab"
                            [loading]="loadingActions[order.id].isOrderItemsLoading"
                            [isOrderDispensing]="loadingActions[order.id].isOrderDispensing"
                            (statusUpdatedEvent)="handleStatusUpdate($event)"
                            (completeOrderEvent)="completeOrder($event)"
                        >
                        </app-order-request-items-table>
                    </tr>
                </ng-container>
            </tbody>
        </ng-template>

        <ng-template #completedOrdersTable let-orders="orders">
            <app-order-mgmt-table-headings [columns]="completedOrdersColumns">
            </app-order-mgmt-table-headings>
            <tbody>
                <ng-container *ngFor="let order of orders; trackBy:trackByOrderId">
                    <ng-container [ngTemplateOutlet]="loadingActions[order.id].isOrderStatusUpdating ? tableRowSpinner : tableContent">
                    </ng-container>
                    <ng-template #tableContent>
                        <tr>
                            <ng-container *ngIf="order.orderRequestedItems.length; else noItems">
                              <td
                                  [nzExpand]="expandedRows.has(order.id)"
                                  (click)="onExpandChange(order.id, !expandedRows.has(order.id))"
                                  class="cursor-pointer"
                              ></td>
                            </ng-container>
                            <ng-template #noItems>
                                <td></td>
                            </ng-template>
                            <td nzAlign="center">
                                <nz-tag [nzColor]="orderStatusColourMap[getOrigingalStatus(order.id)]">
                                    {{ orderStatusLabelMap[getOrigingalStatus(order.id)] }}
                                </nz-tag>
                            </td>
                            <td>
                                <app-notification-date [date]="order.requestedDate" [format]="dateFormat" [ignoreColours]="true"></app-notification-date>
                            </td>
                            <td>
                                <ng-container *ngIf="order.customerId; else defaultId">
                                    <a [routerLink]="['/profile', order.customerId, 'orders', order.id]" target="_blank" class="link">
                                    {{order.id}}
                                    </a>
                                    <ng-template #defaultId>
                                    {{order.id}}
                                    </ng-template>
                                </ng-container>
                            </td>
                            <td>{{order.customerName ?? "Unknown"}}</td>
                            <td>{{order.orderRequestedItems.length}}</td>
                            <td nzAlign="center">
                                <span *ngIf="orderBrandPreference(order) as brandPreference">
                                    <i [ngClass]="brandPreference"></i>
                                </span>
                            </td>
                            <td nzAlign="center">
                              <app-address-tooltip
                                [order]="order"
                              ></app-address-tooltip>
                            </td>
                            <td
                                nzAlign="center"
                                nz-tooltip
                                [nzTooltipTitle]="order.patientNotes ? order.patientNotes : null"
                                [nzTooltipArrowPointAtCenter]="true"
                            >
                                <i [ngClass]="order.patientNotes ? 'far fa-check success': 'far fa-times danger'"></i>
                            </td>
                            <td>
                                <app-notification-date [date]="order.lastUpdated" [format]="dateFormat" [ignoreColours]="true"></app-notification-date>
                            </td>
                            <td>
                                <app-status-selector
                                    [order]="order"
                                    [showNotifyCheckbox]="false"
                                    [orderRequestRowMode]="expandedRows.has(order.id)"
                                    [showQuickCompleteButton]="false"
                                    [itemsTableMode]="false"
                                    (statusUpdatedEvent)="handleStatusUpdate($event)"
                                ></app-status-selector>
                            </td>
                            <td class="link-column">
                                <a
                                    [routerLink]="['/profile', order.customerId, 'orders', order.id]"
                                    target="_blank"
                                    class="link"
                                >
                                    View Order <i class="fal fa-arrow-right"></i>
                                </a>
                            </td>
                        </tr>
                    </ng-template>
                    <tr [nzExpand]="expandedRows.has(order.id)">
                        <app-order-request-items-table
                            [order]="order"
                            [selectedTab]="selectedTab"
                            [loading]="loadingActions[order.id].isOrderItemsLoading"
                            [isOrderDispensing]="loadingActions[order.id].isOrderDispensing"
                            (statusUpdatedEvent)="handleStatusUpdate($event)"
                            (completeOrderEvent)="completeOrder($event)"
                        >
                        </app-order-request-items-table>
                    </tr>
                </ng-container>
            </tbody>
        </ng-template>

        <ng-template #tableRowSpinner>
            <tr>
                <td colspan="11">
                    <div class="centered-row-spinner">
                        <span>
                        <nz-spin nzSimple [nzSpinning]="true" [nzIndicator]="loadingIndicator"></nz-spin>
                        </span>
                        <ng-template #loadingIndicator><span nz-icon nzType="loading"></span></ng-template>
                    </div>
                </td>
            </tr>
        </ng-template>
    </nz-table>
</ng-container>
