<ng-container *ngIf="(loading$ | async) as loading">
  <app-loader *ngIf="loading" loadingMessage="Please wait..."></app-loader>
</ng-container>

<div class="d-flex justify-content-between align-items-center">
  <a class="link pb-3" routerLink="/communications" g-xs="mr2"><i class="fas fa-arrow-left"></i>&nbsp;Communications</a>
</div>



<div class="d-flex justify-content-between align-items-center">
  <h1 class="pb-3 blue-header">Bulk Communications</h1>
  <button *ngIf="!showCreateNewMessage" class="btn btn__green" (click)="displayCreateNewMessage()">Create New</button>
</div>

<app-new-bulk-message *ngIf="showCreateNewMessage" [closeForm]="cancelNewMessage"></app-new-bulk-message>

<section class="pb-3 mb-3">
  <div class="accordion">
    <h4 class="mb-2 cursor-pointer" (click)="toggleMessagesUpcoming()">
      Upcoming
      ({{(upcomingBulkMessages$ | async).length}})
      <i *ngIf="showMessagesUpcoming == 'in'" class="fas fa-caret-up"></i>
      <i *ngIf="showMessagesUpcoming == 'out'" class="fas fa-caret-down"></i>
    </h4>
    <div [@accordionAnimation]="showMessagesUpcoming">
      <app-bulk-messaging-messages-table [bulkMessages]="upcomingBulkMessages$ | async" 
        [canDeleteMessages]="true"
        (onOpenEditModal)="displayCreateNewMessage()"
        (onDelete)="handleDelete($event)"></app-bulk-messaging-messages-table>
    </div>
  </div>
</section>

<section class="pb-3">
  <h4 class="mb-2 cursor-pointer" (click)="toggleMessagesHistory()">
    History
    <i *ngIf="showMessagesHistory == 'in'" class="fas fa-caret-up"></i>
    <i *ngIf="showMessagesHistory == 'out'" class="fas fa-caret-down"></i>
  </h4>
  <div [@accordionAnimation]="showMessagesHistory">
    <app-bulk-messaging-messages-table [bulkMessages]="bulkMessageHistory$ | async" [isHistoric]="true">
    </app-bulk-messaging-messages-table>
  </div>
</section>
