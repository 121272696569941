import { Inject, Injectable } from '@angular/core';
import { SixCpaResponse } from '../../modules/profile/profile-services/state/profile-services.state';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { BaseApi } from '../core/base-api/base-api';
import { Router } from '@angular/router';
import { StorageService } from '../core/storage/storage.service';
import { AlertService } from '../../modules/core/alert/alert.service';
import { SixCpaDraftModel } from '../models/six-cpa/six-cpa-draft.model';

@Injectable()
export class SixCpaFormsService extends BaseApi {
  API = '';

  constructor(
    @Inject('environment') env: any,
    public http: HttpClient,
    public router: Router,
    public storageService: StorageService,
    public alertService: AlertService
  ) {
    super(http, router, storageService, alertService);
    this.API = env.baseApiUrl;
  }

  getSixCpaFormsForClient(clientId: string): Observable<SixCpaResponse[]> {
    return this.get<SixCpaResponse[]>(`${this.API}/api/six-cpa/${clientId}`);
  }

  getAllSixCPAFormDrafts(clientId: string): Observable<SixCpaDraftModel> {
    return this.get<SixCpaDraftModel>(
      `${this.API}/api/six-cpa/${clientId}/getSixCPAFormDrafts`
    );
  }

  createSixCpaNote(clientId: string, folowUpDate: Date, body: string): Observable<any> {
    return this.post(
      `${this.API}/api/six-cpa/${clientId}/createNote`,
      {StartDate: folowUpDate, body}
    );
  }
}
