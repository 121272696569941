import { State, Action, StateContext, Selector } from '@ngxs/store';
import { GetGroupList, GroupPageError, CreateNewGroupPage, DeleteGroupPage, UpdateGroupPage } from './group-page.actions';
import { GroupService } from '~/shared/services/group.service';
import { tap, catchError } from 'rxjs/operators';
import { Group } from '~/shared/models/group.model';
import { Injectable } from '@angular/core';

export class GroupPageStateModel {
  public allGroups: Group[];
}
@Injectable()
@State<GroupPageStateModel>({
  name: 'groupPage',
  defaults: {
    allGroups: []
  }
})
export class GroupPageState {
  constructor(private groupService: GroupService) { }

  @Selector()
  static groups(state: GroupPageStateModel) { return state.allGroups }

  @Action(GetGroupList)
  getGroupList(ctx: StateContext<GroupPageStateModel>, action: GetGroupList) {
    return this.groupService.getGroupList().pipe(
      tap((data) => {ctx.patchState({ allGroups: data })}),
      catchError((error) => {
        return ctx.dispatch(new GroupPageError(error));
      })
    );
  }

  @Action(CreateNewGroupPage)
  createNewGroupPage(ctx: StateContext<GroupPageStateModel>, { item }: CreateNewGroupPage) {
    return this.groupService.createGroup(item).pipe(
      tap((data) => ctx.patchState({ allGroups: data })),
      catchError((error) => {
        return ctx.dispatch(new GroupPageError(error));
      })
    );
  }

  @Action(DeleteGroupPage)
  deleteGroupPage(ctx: StateContext<GroupPageStateModel>, { itemId }: DeleteGroupPage) {
    const state = ctx.getState();
    return this.groupService.deleteGroup(itemId).pipe(
      tap(() => ctx.patchState({ allGroups: state.allGroups.filter(x => x.id !== +itemId) })),
      catchError((error) => {
        return ctx.dispatch(new GroupPageError(error));
      })
    );
  }
  @Action(UpdateGroupPage)
  updateGroupPage(ctx: StateContext<GroupPageStateModel>, { item }: UpdateGroupPage) {
    return this.groupService.updateGroup(item).pipe(
      tap((data) => ctx.patchState({ allGroups: data })),
      catchError((error) => {
        return ctx.dispatch(new GroupPageError(error));
      })
    );
  }
}
