import { ScheduledMessage } from '../../../../../shared/models/message.model';
import { HttpErrorResponse } from '@angular/common/http';

export enum ScheduledMessagesActions {
  GET_SCHEDULED_MESSAGES = '[ScheduledMessages] Get',
  ADD_SCHEDULED_MESSAGE = '[ScheduledMessages] Add',
  REMOVE_SCHEDULED_MESSAGE = '[ScheduledMessages] Remove',
  REMOVE_SCHEDULED_MESSAGES = '[ScheduledMessages] Remove Bulk',
  GET_SCHEDULED_MESSAGE_SUCCESS = '[ScheduledMessages] Success',
  GET_SCHEDULED_MESSAGE_ERROR = '[ScheduledMessages] Error',
  REMOVE_SCHEDULED_MESSAGE_SUCCESS = '[ScheduledMessages] Remove Success',
  RETRY_SCHEDULED_MESSAGE = '[ScheduledMessages] Retry',
  RETRY_SCHEDULED_MESSAGE_SUCCESS = '[ScheduledMessages] Retry Success',
  RETRY_SCHEDULED_MESSAGE_FAILED = '[ScheduledMessages] Retry Failed',

}

export class GetScheduledMessages {
  static readonly type = ScheduledMessagesActions.GET_SCHEDULED_MESSAGES;
  constructor(public clientId: string) { }
}

export class RemoveScheduledMessage {
  static readonly type = ScheduledMessagesActions.REMOVE_SCHEDULED_MESSAGE;
  constructor(public scheduledMessageId: number) { }
}

export class RemoveScheduledMessageSuccess {
  static readonly type = ScheduledMessagesActions.REMOVE_SCHEDULED_MESSAGE_SUCCESS;
  constructor() { }
}

export class RemoveScheduledMessages {
  static readonly type = ScheduledMessagesActions.REMOVE_SCHEDULED_MESSAGES;
  constructor(public scheduledMessageIds: number[]) { }
}

export class AddScheduledMessage {
  static readonly type = ScheduledMessagesActions.ADD_SCHEDULED_MESSAGE;
  constructor(public message: ScheduledMessage) { }
}

export class GetScheduledMessagesSuccess {
  static readonly type = ScheduledMessagesActions.GET_SCHEDULED_MESSAGE_SUCCESS;
  constructor(public messages: ScheduledMessage[]) { }
}

export class GetScheduledMessagesError{
  static readonly type = ScheduledMessagesActions.GET_SCHEDULED_MESSAGE_ERROR;
  constructor(public error: any) { }
}

export class RetryFailedMessage {
  static readonly type = ScheduledMessagesActions.RETRY_SCHEDULED_MESSAGE;
  constructor(public id: number, public clientId: string) { }
}

export class RetryFailedMessageSuccess {
  static readonly type = ScheduledMessagesActions.RETRY_SCHEDULED_MESSAGE_SUCCESS;
  constructor(public clientId: string) { }
}

export class RetryFailedMessageFailed {
  static readonly type = ScheduledMessagesActions.RETRY_SCHEDULED_MESSAGE_FAILED;
  constructor(public error: HttpErrorResponse) { }
}
