import { HMRAccreditedPharmacistDetailsFormModel } from '../../../../../../shared/models/six-cpa/HMR/hmr-accredited-pharmacist-details-form.model';

export enum HMRAccreditedPharmacistDetailsActions {
  SET_VALID = '[HMRAccreditedPharmacistDetails] set valid',
  UPDATE = '[HMRAccreditedPharmacistDetails] Update Form',
  UPDATE_PROGRESS = '[HMRAccreditedPharmacistDetails] Update Progress'
}

export class SetHMRAccreditedPharmacistDetailsValidAction {
  static readonly type = HMRAccreditedPharmacistDetailsActions.SET_VALID;
  constructor(public valid = false) { }
}

export class SetTotalItemsAndCompletedItems {
  static readonly type = HMRAccreditedPharmacistDetailsActions.UPDATE_PROGRESS;
  constructor(public totalItems, public completedItems) { }
}

export class UpdateHMRAccreditedPharmacistDetails {
  static readonly type = HMRAccreditedPharmacistDetailsActions.UPDATE;
  constructor(public form: Partial<HMRAccreditedPharmacistDetailsFormModel>) { }
}
