import { Component, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { InvoiceStateModel } from './models/InvoiceStateModel.model';
import { GetAllInvoicesFromAMonthAgo } from './state/invoices-page.actions';
import { InvoicesPageState } from './state/invoices-page.state';
import { InvoiceStatus } from './models/invoiceStatus.model';

@Component({
  selector: 'invoices-page',
  templateUrl: './invoices-page.component.html',
  styleUrls: ['./invoices-page.component.scss']
})
export class InvoicesPageComponent implements OnInit {
  @Select(InvoicesPageState.getState) invoices$: Observable<InvoiceStateModel>;
  InvoiceStatus = InvoiceStatus;

  constructor(
    private store: Store,
  ) { }

  ngOnInit(): void {
    this.store.dispatch([new GetAllInvoicesFromAMonthAgo()]);
  }

  getCreatedDateTime(createdAt: string): Date {
    return new Date(createdAt);
  }
}
