import { Injectable } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { Observable, interval } from 'rxjs';
import { AlertType, Alert } from './alert.model';
import { Store } from '@ngxs/store';
import { AlertState } from './state/alert.state';
import { AddAlertAction, ClearAlertsAction } from './state/alert.actions';
import {
  getTemporallyRelevantAlerts
} from './alert.logic';

@Injectable()
export class AlertService {
  private keepAfterRouteChange = false;

  constructor(router: Router, private store: Store) {
    // clear alert messages on route change unless 'keepAfterRouteChange' flag is true
    router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        if (this.keepAfterRouteChange) {
          // only keep for a single route change
          this.keepAfterRouteChange = false;
        } else {
          // clear alert messages
          this.clear();
        }
      }
    });
  }

  getAlert(): Observable<Alert[]> {
      return getTemporallyRelevantAlerts(interval(1000)) // tick
          (this.store.select(AlertState.alerts)); // alerts observable;
  }

  success(message: string, keepAfterRouteChange = false) {
    this.alert(AlertType.Success, message, keepAfterRouteChange);
  }

  error(message: string, keepAfterRouteChange = false) {
    this.alert(AlertType.Error, message, keepAfterRouteChange);
  }

  info(message: string, keepAfterRouteChange = false) {
    this.alert(AlertType.Info, message, keepAfterRouteChange);
  }

  warn(message: string, keepAfterRouteChange = false) {
    this.alert(AlertType.Warning, message, keepAfterRouteChange);
  }

  alert(type: AlertType, message: string, keepAfterRouteChange = false) {
    this.keepAfterRouteChange = keepAfterRouteChange;
    this.store.dispatch(new AddAlertAction({ type: type, message: message }));
  }

  clear() {
    this.store.dispatch(new ClearAlertsAction());
  }
}
