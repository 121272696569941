<div class="add-node">
  <form #f="ngForm" (ngSubmit)="onSubmit(f)">
    <div class="form-group" [ngClass]="{'has-error': message.invalid && message.touched}">
      <label class="font__bold font__title-1" for="note-message">Notes</label>
      <textarea id="note-message" g-xs="p3" class="form-control message" required #message="ngModel" name="message" ngModel>
        {{message}}
      </textarea>
      <span *ngIf="message.invalid && message.touched" class="form-help-text">Required field for message</span>
    </div>
    <div class="grid">
      <div  g-xs="pr3 cols:6" class="grid__unit">
          <button type="submit" g-xs="p2 mr3 text:center" class="btn__yellow cntrl-btn" [disabled]="f.invalid">Send Message</button>
      </div>
    </div>
  </form>
</div>