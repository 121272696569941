import { Component, OnInit } from '@angular/core';
import { StagedSupplyFormType, StagedSupplyFormListState } from '../staged-supply/state/staged-supply-form-list.state';
import { Observable } from 'rxjs';
import { SSConsentEligibilityFormState, SSConsentEligibilityFormStateModel } from '../consent-eligibility-form/state/ss-consent-eligibility-form.state';
import { Select } from '@ngxs/store';

@Component({
  selector: 'app-ss-consent-eligibility-form-list-item',
  templateUrl: './ss-consent-eligibility-form-list-item.component.html'
})
export class SSConsentEligibilityFormListItemComponent implements OnInit {
  @Select(StagedSupplyFormListState.stagedSupplyType) stagedSupplyType$: Observable<StagedSupplyFormType>;
  @Select(SSConsentEligibilityFormState) formState$: Observable<SSConsentEligibilityFormStateModel>;
  stagedSupplyTypes = StagedSupplyFormType;

  constructor() { }

  ngOnInit() {
  }

}
