import { Component, OnInit } from '@angular/core';
import { Select } from '@ngxs/store';
import { DAAMedicationInformationFormState } from '../daa-medication-information-form/state/daa-medication-information-form.state';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-daa-medication-information-form-list-item',
  templateUrl: './daa-medication-information-form-list-item.component.html'
})
export class DaaMedicationInformationFormListItemComponent implements OnInit {
  @Select(DAAMedicationInformationFormState) daaMedicationInformationForm$: Observable<any>;

  constructor() { }

  ngOnInit() {
  }

}
