export function detectEScript(content: string) {
  return content && content.includes('https://ausscripts');
}

export function extractEScript(content: string): string {
  const tokenPattern = /\/scripts\/([^\s]+)/;
  const match = content.match(tokenPattern);

  if (match?.length < 2) return null;

  const token = match[1];
  return token;
}


export function extractEScriptFromNote(content: string) {
  return content.substring(
    content.indexOf("*") + 1,
    content.lastIndexOf("*")
  );
}

export function replaceEscriptURLWithHTMLLinks(text: string): string {
  const medisecure = /(\b(https?):\/\/ausscripts.medisecure.com.au\/scripts\/[^\s]*)/ig;
  const erx = /(\b(https?):\/\/ausscripts.erx.com.au\/scripts\/[^\s]*)/ig;
  const link = "<a class=\"message-link\" target=\"_blank\" href='$1'>$1</a>";
  return text.replace(medisecure, link)
    .replace(erx, link);
}
