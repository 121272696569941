import { Component, OnInit, OnDestroy } from '@angular/core';
import { HMRGPDetailsReferralFormBaseComponent } from '../hmr-gp-details-referral-form.base.component';
import { Subscription } from 'rxjs';
import { FormBuilder, FormControl, Validators, FormGroup } from '@angular/forms';
import { Store, Actions } from '@ngxs/store';
import { HMRGPDetailsReferralFormDetailedState } from '../state/hmr-gp-details-referral-form-detailed.state';
import { FormControlFunctions } from '../../../form-control.functions';
import { SixCpaState } from '../../../state/six-cpa.state';


@Component({
    selector: 'app-hmr-gp-details-referral-form-detailed',
    templateUrl: './hmr-gp-details-referral-form-detailed.component.html',
    styleUrls: ['./../hmr-gp-details-referral-form.component.scss']
  })
  export class HMRGPDetailsReferralFormDetailedComponent extends HMRGPDetailsReferralFormBaseComponent implements OnInit, OnDestroy {
    private formSubscription: Subscription;
    private readonlySubscription: Subscription;
    reasonForReferralForFormArr = [
      {key: 'polypharmacy', value: 'Polypharmacy'},
      {key: 'suspectedAdverseEvent', value: 'Suspected adverse event'},
      {key: 'usingMedicinesWithNarrowTherapeuticRange', value: 'Using medicines with narrow therapeutic range'},
      {key: 'other', value: 'Other'}
    ];

    constructor(formBuilder: FormBuilder, store: Store, actions: Actions, private formFunctions: FormControlFunctions) {
      super(formBuilder, store, actions);
      this.gpDetailsReferralForm.addControl('reasonForReferral', new FormGroup({}, Validators.required));
      this.gpDetailsReferralForm.addControl('referralDate', new FormControl(new Date(), Validators.required));
      this.gpDetailsReferralForm.addControl('otherReasonForReferral', new FormControl('', Validators.required));
      this.formFunctions.generateControlsForGroupFromArr('reasonForReferral', this.reasonForReferralForFormArr, this.gpDetailsReferralForm);

    }

    ngOnInit() {
      this.formSubscription = this.store.select(HMRGPDetailsReferralFormDetailedState.form)
        .subscribe(() => this.onFormChange());
      this.readonlySubscription = this.store.select(SixCpaState.formsReadOnly)
        .subscribe(readonly => readonly ? this.gpDetailsReferralForm.disable() : this.gpDetailsReferralForm.enable());
    }

    ngOnDestroy() {
      this.formSubscription.unsubscribe();
      this.readonlySubscription.unsubscribe();
    }
  }
