import { Selector } from '@ngxs/store';
import { HMRPatientDetailsFormState } from '../../hmr-patient-details-form/state/hmr-patient-details-form.state';
import { HMRFormState, HMRFormType } from './hmr-form.state';
import { HMRPharmacyInformationState } from '../../hmr-pharmacy-information/state/hmr-pharmacy-information.state';
import { HMRGPDetailsReferralFormState } from '../../hmr-gp-details-referral-form/state/hmr-gp-details-referral-form.state';
import { HMRLocationState } from '../../hmr-location/state/hmr-location.state';
import { HMRServiceProviderDetailsFormState } from '../../hmr-service-provider-details/state/hmr-service-provider-details-form.state';
import { HMRProposedDetailsFormState } from '../../hmr-proposed-details/state/hmr-proposed-details-form.state';
import { HMRAccreditedPharmacistDetailsState } from '../../hmr-accredited-pharmacist-details/state/hmr-accredited-pharmacist-details.state';
import { HMRConsentAndEligibilityState } from '../../hmr-consent-and-eligibility/state/hmr-consent-and-eligibility.state';

export class HMRFormsProgressService {
  @Selector([
    HMRPatientDetailsFormState.totalItems,
    HMRPatientDetailsFormState.completedItems,
    HMRPharmacyInformationState.totalItems,
    HMRPharmacyInformationState.completedItems,
    HMRGPDetailsReferralFormState.totalItems,
    HMRGPDetailsReferralFormState.completedItems,
    HMRLocationState.totalItems,
    HMRLocationState.completedItems,
    HMRConsentAndEligibilityState.totalItems,
    HMRConsentAndEligibilityState.completedItems,
 ])
  static getHMRNewClaimProgress(
    patientDetailsTotal,
    patientDetailsCompleted,
    pharmacyInformationTotal,
    pharmacyInformationCompleted,
    gpDetailsReferralTotal,
    gpDetailsReferralCompleted,
    locationTotal,
    locationCompleted,
    сonsentAndEligibilityTotal,
    сonsentAndEligibilityCompleted,
  ) {
    return Math.floor((
      patientDetailsCompleted
      + pharmacyInformationCompleted
      + gpDetailsReferralCompleted
      + locationCompleted
      + сonsentAndEligibilityCompleted) /
     (
       patientDetailsTotal
       + pharmacyInformationTotal
       + gpDetailsReferralTotal
       + locationTotal
       + сonsentAndEligibilityTotal
       ) * 100);
  }

  @Selector([
     HMRPatientDetailsFormState.totalItems,
     HMRPatientDetailsFormState.completedItems,
     HMRGPDetailsReferralFormState.totalItems,
     HMRGPDetailsReferralFormState.completedItems,
    HMRServiceProviderDetailsFormState.totalItems,
    HMRServiceProviderDetailsFormState.completedItems,
    HMRProposedDetailsFormState.totalItems,
    HMRProposedDetailsFormState.completedItems,
 ])
  static getHMRInterviewRegisteredPhamacistProgress(
     patientDetailsTotal,
     patientDetailsCompleted,
     gpDetailsReferralTotal,
     gpDetailsReferralCompleted,
     serviceProviderDetailsTotal,
     serviceProviderDetailsCompleted,
     proposedDetailsTotal,
     proposedDetailsCompleted
  ) {
    return Math.floor(
      (patientDetailsCompleted + gpDetailsReferralCompleted + serviceProviderDetailsCompleted + proposedDetailsCompleted) /
      (patientDetailsTotal + gpDetailsReferralTotal + serviceProviderDetailsTotal + proposedDetailsTotal) * 100);
  }
  @Selector([
     HMRPatientDetailsFormState.totalItems,
     HMRPatientDetailsFormState.completedItems,
     HMRGPDetailsReferralFormState.totalItems,
     HMRGPDetailsReferralFormState.completedItems,
     HMRServiceProviderDetailsFormState.totalItems,
     HMRServiceProviderDetailsFormState.completedItems,
     HMRProposedDetailsFormState.totalItems,
     HMRProposedDetailsFormState.completedItems,
     HMRAccreditedPharmacistDetailsState.totalItems,
     HMRAccreditedPharmacistDetailsState.completedItems,
 ])
  static getHMRInterviewOutsidePatientHomeProgress(
     patientDetailsTotal,
     patientDetailsCompleted,
     gpDetailsReferralTotal,
     gpDetailsReferralCompleted,
     serviceProviderDetailsTotal,
     serviceProviderDetailsCompleted,
     proposedDetailsTotal,
     proposedDetailsCompleted,
     accreditedPharmacistDetailsTotal,
     accreditedPharmacistDetailsCompleted
  ) {
    return Math.floor(
      (
        patientDetailsCompleted
        + gpDetailsReferralCompleted
        + serviceProviderDetailsCompleted
        + proposedDetailsCompleted
        + accreditedPharmacistDetailsCompleted) /
      (
        patientDetailsTotal
        + gpDetailsReferralTotal
        + serviceProviderDetailsTotal
        + proposedDetailsTotal
        + accreditedPharmacistDetailsTotal
      ) * 100);
  }

  @Selector([
      HMRFormState.hmrFormType,
      HMRFormsProgressService.getHMRNewClaimProgress,
      HMRFormsProgressService.getHMRInterviewRegisteredPhamacistProgress,
      HMRFormsProgressService.getHMRInterviewOutsidePatientHomeProgress])
  static getProgress(hmrFormType, newClaimPRogress, interviewRegisteredPhamacistProgress, interviewOutsidePatientHomeProgress) {
      switch (hmrFormType) {
          case HMRFormType.NewClaim:
              return newClaimPRogress;
          case HMRFormType.InterviewRegisteredPhamacist:
              return interviewRegisteredPhamacistProgress;
          case HMRFormType.InterviewOutsidePatientHome:
              return interviewOutsidePatientHomeProgress;
      }
  }
}
