import { SSAdditionalDetailsFormModel } from '../../../../../../shared/models/six-cpa/staged-supply/ss-additional-details-form.model';

export enum SSAdditionalDetailsActions {
  SET_PROGRESS = '[SSAdditionalDetails] update progress',
  UPDATE = '[SSAdditionalDetails] Update Form'
}

export class SetSSAdditionalDetailsProgress {
  static readonly type = SSAdditionalDetailsActions.SET_PROGRESS;
  constructor(public totalItems, public completedItems) { }
}

export class UpdateSSAdditionalDetailsForm {
  static readonly type = SSAdditionalDetailsActions.UPDATE;
  constructor(public form: SSAdditionalDetailsFormModel) { }
}
