import { MarketingItemComponent } from './marketing-item.component';
import { MarketingItemService } from '../services/marketing-item.service';
import { MarketingService } from '~/shared/services/marketing.service';
import { MarketingSettingService } from '../services/marketing-settings.sevice';
import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { MarketingSetting } from '~/shared/models/marketingSetting.model';
import { MarketingType } from '~/shared/models/sequence-type.enum';
import { AlertService } from '~/modules/core/alert/alert.service';
import { TemplateType } from '~/shared/models/pharmacy/comm-template.model';

@Component({
  selector: 'app-add-martketing-item',
  templateUrl: './marketing-item.component.html'
})
export class AddMarketingItemComponent extends MarketingItemComponent implements OnInit {

  constructor(
    protected marketingService: MarketingService,
    protected router: Router,
    protected route: ActivatedRoute,
    settingsService: MarketingSettingService,
    protected marketingItemService: MarketingItemService,
    private alertService: AlertService
  ) {
    super(router, route, settingsService, marketingItemService);
    this.marketingItem = {
      isActive: true,
      messageTemplate: '',
      key: '',
      tenantId: 0,
      tenantName: '',
      templateType: TemplateType.Custom,
      marketingType: MarketingType.UserConfigurable,
      subject: ''
    };
  }

  ngOnInit() {
    super.ngOnInit();
    this.marketingTypes = this.settingsService.getCreatableMarketingTypes();
  }

  submit() {
    this.addItem(this.marketingItem);
  }

  isReadonlyTenant() {
    return false;
  }

  isReadonlyMarketingType() {
    return false;
  }

  isReadonlyMarketingKey() {
    return false;
  }

  goBack() {
    this.router.navigate(['../'], { relativeTo: this.route });
  }

  getMessageCount(characterCount: number) {
    if(characterCount == 0) {
      return 1;
    }
    return Math.ceil(characterCount / 160);
  }

  private addItem(data: MarketingSetting) {
    if (data.marketingType == MarketingType.DoctorReportTemplate) {
      return this.marketingService.createDoctorReportTemplate(data)
        .subscribe(
          (newItem) => {
            this.marketingItemService.update(newItem);
            this.goBack();
          },
          (err) => this.alertService.error(err.error)
        );
    }
    else {
      return this.marketingService.createMarketing(data)
        .subscribe(
          (newItem) => {
            this.marketingItemService.update(newItem);
            this.goBack();
          },
          (err) => this.alertService.error(err.error)
        )
    }
  }
}
