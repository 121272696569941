<div class="d-flex justify-content-between align-items-center">
  <div>
    <h3>Medication</h3>
  </div>
  <div *ngIf="isInvalid(orderItems)" class="update-warning">
    <i class="fal fa-exclamation-triangle"></i>&nbsp; Update the item status
  </div>
</div>
<form #itemsForm="ngForm">
      <ng-template #titleTemplate>
        <h3 class="popover-title">
            {{popoverText()}}
        </h3>
      </ng-template>
      <ng-template #contentTemplate>
        <div class="d-flex justify-content-between align-items-center">
            <button class="update-button button btn__yellow" (click)="updateDispensedMedsToReady()">Yes, Update</button>
            <button nz-button nzType="link" (click)="popoverVisible = false">Don't update now</button>
        </div>
      </ng-template>
        <table
         nz-popover
         [nzPopoverTitle]="titleTemplate"
         [nzPopoverContent]="contentTemplate"
         [nzPopoverTrigger]="null"
         [nzPopoverVisible]="popoverVisible"
         [nzPopoverPlacement]="'bottomRight'">
            <tr>
                <th class="master-checkbox">
                    <label
                        class="checkbox"
                        nz-checkbox
                        [nzDisabled]="disabled"
                        name="mastercheckbox"
                        [ngModel]="false"
                        (ngModelChange)="changeMasterCheckbox($event)"
                    ></label>
                </th>
                <th class="medication-heading">Medication</th>
                <th class="center">Script QTY</th>
                <th class="center">Repeats</th>
                <th class="center">Last dispensed</th>
                <th class="center">Total DOH</th>
                <th class="center">QTY</th>
                <th class="center">Status</th>
                <th></th>
            </tr>
            <ng-container *ngFor="let item of orderItems; let i = index">
                <tr>
                    <td><label
                            nz-tooltip
                            nzTooltipTitle="{{tooltipText(item)}}"
                            nzTooltipPlacement="right"
                            class="checkbox"
                            nz-checkbox
                            name="checkbox"
                            [nzDisabled]="disabled || !isItemQueuable(item)"
                            [(ngModel)]="item.selected"
                            (ngModelChange)="changeCheckbox($event)"
                        ></label>
                    </td>
                    <td>
                        <div class="d-flex justify-content-between align-items-center">
                            <div class="d-flex justify-content-between align-items-center">
                                <div class="item-icon" [ngSwitch]="item.orderRequestItemType">
                                        <div *ngSwitchCase="orderItemType.OnFileScript">
                                            <i class="fal fa-file-prescription"></i>
                                        </div>
                                        <div *ngSwitchCase="orderItemType.EScript">
                                            <i class="fas fa-qrcode"></i>
                                        </div>
                                        <div *ngSwitchCase="orderItemType.Image">
                                            <img
                                             class="med-image"
                                             width="15px"
                                             height="15px"
                                             [src]="item.attachmentUrl"
                                             alt="medication image">
                                        </div>
                                        <div *ngSwitchCase="orderItemType.OTCItem">
                                            <i class="fal fa-prescription-bottle-alt"></i>
                                        </div>
                                        <div *ngSwitchDefault></div>
                                </div>
                                <div>
                                    <div *ngIf="
                                        item.orderRequestItemType !== orderItemType.Image
                                        "
                                        class="item-name">
                                        {{item.name}}
                                    </div>
                                    <button
                                        *ngIf="item.orderRequestItemType === orderItemType.Image"
                                        nz-button
                                        nzType="link"
                                        (click)="openImage(item)"
                                    >{{item.name}}</button>
                                    <ng-container [ngSwitch]="item.orderRequestItemType">
                                        <div *ngSwitchCase="orderItemType.EScript" class="item-desc">
                                            <ng-container *ngIf="item.eScriptTokenUrl">
                                                {{getToken(item.eScriptTokenUrl)}}&nbsp;
                                          <a (click)="copyToken(item)">Copy</a> <i class="fas fa-check-circle ml-2" *ngIf="copied"></i>
                                            </ng-container>
                                        </div>
                                        <div *ngSwitchDefault class="item-desc">
                                            {{item.customerMedication?.description}}
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                            <div class="item-new" *ngIf="item.isNewForCustomer">
                                New for patient
                            </div>
                        </div>

                    </td>
                    <td class="center">
                        {{getQuantity(item) == null ? "" : getQuantity(item) }}
                    </td>
                    <td [ngClass]="{red: getRepeats(item) < 2, center: true}">
                        {{getRepeats(item) == null ? "" : getRepeats(item) }}
                    </td>
                    <td class="center">
                        <span *ngIf="item.customerMedication?.dispensedDate">
                            {{dateFn(item.customerMedication?.dispensedDate)}}
                        </span>
                    </td>
                    <td class="center">
                        <span *ngIf="showDOH(item); else noDoh">
                        {{ drugDOH(item) }}d
                    </span>
                    </td>
                    <td class="center">
                        <nz-input-number
                            class="item-qty"
                            [name]="'quantity'+i"
                            [nzDisabled]="disabled"
                            [(ngModel)]="item.quantity"
                            [nzMin]="1"
                            [nzMax]="10"
                            [nzStep]="1"></nz-input-number>
                    </td>
                    <td>
                        <nz-select
                        ngDefaultControl
                        [name]="'status'+i"
                        required
                        [nzDisabled]="disabled"
                        [nzDropdownMatchSelectWidth]="false"
                        [ngClass]="{
                            'invalid':
                                item.orderRequestItemStatus === null ||
                                item.orderRequestItemStatus == undefined
                            }"
                        [ngModel]="item.orderRequestItemStatus"
                        (ngModelChange)="changeStatus($event, item)"
                            nzPlaceHolder="Please select">
                            <nz-option
                            [nzValue]="itemStatus.Pending"
                            nzLabel="Pending"
                        ></nz-option>
                        <nz-option
                          [nzValue]="itemStatus.Ready"
                          nzLabel="Completed"></nz-option>
                        <nz-option
                            [nzValue]="itemStatus.Dispensed"
                            nzLabel="Sent To Queue"
                        ></nz-option>
                        <nz-option
                            [nzValue]="itemStatus.OnOrder"
                            nzLabel="On Order"
                        ></nz-option>
                        <nz-option
                            [nzValue]="itemStatus.UnableToFill"
                            nzLabel="Unable To Fill"
                        ></nz-option>
                        <nz-option
                            [nzValue]="itemStatus.TooEarly"
                            nzLabel="Too Early"
                        ></nz-option>
                        </nz-select>
                    </td>
                    <td>
                        <button
                            [disabled]="disabled || (!((item?.addedByPharmacist || false) || item.orderRequestItemType === orderItemType.OTCItem) && !isCreate)"
                            (click)="removeItemFromOrder(item)"
                            nz-button
                            nzType="text">
                            <i class="fas fa-trash"></i>
                        </button>
                    </td>
                </tr>
                <tr *ngIf="
                item.orderRequestItemStatus &&
                item.orderRequestItemStatus === itemStatus.TooEarly
                "
                class="nested-padding">
                <td></td>
                <td colspan="7" >
                    <table class="nested-table">
                        <thead>
                            <tr>
                            <th class="nested-label pl-5">Next Valid Dispense Date<span class="red">*</span></th>
                            <th class="nested-label pl-2">Notes to customer (optional)</th>
                            <th class="nested-label pl-5">Set Reminder</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                            <td class="order-expected">
                                <nz-date-picker
                                    required
                                    [name]="'nextValidDispenseDate'+i"
                                    [(ngModel)]="item.nextValidDispenseDate"
                                    (ngModelChange)="changeNextValidDispenseDate($event, item)"
                                    nzFormat="dd-MM-yyyy"
                                    >
                                </nz-date-picker>
                            </td>
                            <td class="item-notes">
                                <input
                                    nz-input
                                    [name]="'notes'+i"
                                    placeholder="Notes to customer (optional)"
                                    [(ngModel)]="item.notes" />
                            </td>
                            <td class="set-reminder">
                               <label nz-checkbox [name]="'setReminder'+i" [(ngModel)]="item.setReminder"
                                    [value]="item.setReminder"></label>
                            </td>
                            </tr>
                        </tbody>
                        </table>
                </td>
                <td></td>
            </tr>
                <tr *ngIf="
                    item.orderRequestItemStatus &&
                    item.orderRequestItemStatus === itemStatus.OnOrder
                    "
                    class="nested-padding">
                    <td></td>
                    <td colspan="7" >
                        <table class="nested-table">
                            <thead>
                                <tr>
                                <th class="nested-label pl-5">Order expected<span class="red">*</span></th>
                                <th class="nested-label pl-2">Notes to customer (optional)</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                <td class="order-expected">
                                    <nz-date-picker
                                        required
                                        [name]="'onOrderExpected'+i"
                                        [(ngModel)]="item.onOrderExpected"
                                        (ngModelChange)="changeOnOrderExpected($event, item)"
                                        nzFormat="dd-MM-yyyy"
                                        >
                                    </nz-date-picker>
                                </td>
                                <td class="item-notes">
                                    <input
                                        nz-input
                                        [name]="'notes'+i"
                                        placeholder="Notes to customer (optional)"
                                        [(ngModel)]="item.notes" />
                                </td>
                                </tr>
                            </tbody>
                            </table>
                    </td>
                    <td></td>
                </tr>
                <tr *ngIf="
                    item.orderRequestItemStatus &&
                    item.orderRequestItemStatus === itemStatus.UnableToFill
                    "
                    class="nested-padding">
                    <td></td>
                    <td colspan="7">
                        <table class="nested-table">
                            <thead>
                                <tr>
                                <th class="nested-label pl-2">Notes to customer (optional)</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                <td class="w100">
                                    <input
                                    [name]="'notes'+i"
                                    nz-input
                                    placeholder="Notes to customer (optional)"
                                    [(ngModel)]="item.notes" />
                                </td>
                                </tr>
                            </tbody>
                            </table>
                    </td>
                    <td></td>
                </tr>
            </ng-container>
        </table>
    </form>
    <ng-template #noDoh>
        <span>N/A</span>
    </ng-template>
