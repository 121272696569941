import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClientTitleComponent } from './client-title.component';

@NgModule({
  declarations: [
    ClientTitleComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    ClientTitleComponent
  ]
})
export class ClientTitleModule { }
