import { ChannelPreference } from './../../../../../../shared/models/pharmacy/pharmacy-comms-details.model';
import { DoctorReportModel } from '../../../../../../shared/models/six-cpa/doctor-report.model';

export enum SickLeaveConfirmActionTypes {
  TOGGLE_CONFIRM_SICK_LEAVE_CHECK_ACTION = '[SickLeaveConfirm] Toggle Blade',
  GO_BACK_CONFIRM_SICK_LEAVE_ACTION = '[SickLeaveConfirm] Go Back',
  CONFIRM_SICK_LEAVE_ERROR_ACTION = '[SickLeaveConfirm] Error',
  CONFIRM_SICK_LEAVE_GET_CERTIFICATE_ACTION = '[SickLeaveConfirm] Get Certificate',
  SET_DOCTORS_CONFIRM_SICK_LEAVE_ACTION = '[SickLeaveConfirm] set doctor list',
  SEND_TO_DOCTOR_CONFIRM_SICK_LEAVE_ACTION = '[SickLeaveConfirm] send to doctor',
  GET_DOCTOR_REPORT_CONFIRM_SICK_LEAVE_ACTION = '[SickLeaveConfirm] Get Doctor Report',
  SEND_TO_PATIENT_CONFIRM_SICK_LEAVE_ACTION = '[SickLeaveConfirm] send to patient',
  TOGGLE_RESPONSE_CONFIRM_SICK_LEAVE_ACTION = '[SickLeaveConfirm] stoggle response',
  SEND_SICK_LEAVE_REPORT = '[SickLeaveConfirm] send sick leave report',
  OPEN_SICKLEAVE_SEND_DOCUMENT_FORM = '[SickLeaveConfirm] Open SickLeave Send Document Form',
  CLOSE_SICKLEAVE_SEND_DOCUMENT_FORM = '[SickLeaveConfirm] Close SickLeave Send Document Form',
}

export class SendSickLeaveReport {
  static readonly type = SickLeaveConfirmActionTypes.SEND_SICK_LEAVE_REPORT;
  constructor(public clientId: string, public sixCpaFormId: number, public commsType: ChannelPreference, public contact: string, public customisedDoctorReport: Partial<DoctorReportModel>) { }
}

export class OpenSickLeaveSendDocumentFormAction {
  static readonly type = SickLeaveConfirmActionTypes.CLOSE_SICKLEAVE_SEND_DOCUMENT_FORM;
  constructor() { }
}

export class CloseSickLeaveSendDocumentFormAction {
  static readonly type = SickLeaveConfirmActionTypes.OPEN_SICKLEAVE_SEND_DOCUMENT_FORM;
  constructor() { }
}

export class ToggleSickLeaveConfirmAction {
  static readonly type = SickLeaveConfirmActionTypes.TOGGLE_CONFIRM_SICK_LEAVE_CHECK_ACTION;
  constructor(public slideMode: string) { }
}

export class GoBackFromSickLeaveConfirmAction {
  static readonly type = SickLeaveConfirmActionTypes.GO_BACK_CONFIRM_SICK_LEAVE_ACTION;
}

export class SickLeaveConfirmErrorAction {
  static readonly type = SickLeaveConfirmActionTypes.CONFIRM_SICK_LEAVE_ERROR_ACTION;
  constructor(public error: any) { }
}

export class GetCertificateSickLeaveConfirmAction {
  static readonly type = SickLeaveConfirmActionTypes.CONFIRM_SICK_LEAVE_GET_CERTIFICATE_ACTION;
  constructor(public clientId: string, public sixCpaFormId: number) { }
}

export class SetDoctorListSickLeaveConfirm {
  static readonly type = SickLeaveConfirmActionTypes.SET_DOCTORS_CONFIRM_SICK_LEAVE_ACTION;
  constructor(public doctors: any[]) { }
}

export class SendToDoctorSickLeaveConfirm {
  static readonly type = SickLeaveConfirmActionTypes.SEND_TO_DOCTOR_CONFIRM_SICK_LEAVE_ACTION;
  constructor(public clientId: string, public sixCpaFormId: number, public customisedDoctorReport: Partial<DoctorReportModel>) { }
}

export class GetDoctorReportSickLeaveConfirm {
  static readonly type = SickLeaveConfirmActionTypes.GET_DOCTOR_REPORT_CONFIRM_SICK_LEAVE_ACTION;
  constructor(public clientId: string, public sixCpaFormId: number, public customisedDoctorReport: Partial<DoctorReportModel>) { }
}

export class SendToPatientSickLeaveConfirm {
  static readonly type = SickLeaveConfirmActionTypes.SEND_TO_PATIENT_CONFIRM_SICK_LEAVE_ACTION;
  constructor(public clientId: string, public sixCpaFormId: number) { }
}

export class ToggleResponseSickLeaveConfirm {
  static readonly type = SickLeaveConfirmActionTypes.TOGGLE_RESPONSE_CONFIRM_SICK_LEAVE_ACTION;
  constructor(public showResponse: string) { }
}
