import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControlName, FormGroup } from '@angular/forms';

@Component({
  selector: 'lib-inline-input-edit',
  templateUrl: './inline-input-edit.component.html',
  styleUrls: ['./inline-input-edit.component.scss']
})
export class InlineInputEditComponent implements OnInit {
  @Input() medicationForm: FormGroup;
  @Input() myFormControlName: any;
  @Output() focusOut: EventEmitter<any> = new EventEmitter<any>();
  
  editMode = false;
  
  constructor() {}

  ngOnInit() {
  }

  onFocusOut() {
    this.focusOut.emit(this.medicationForm);
  }

}
