import { Component, Input, ViewChild, ComponentFactoryResolver, Type, Directive, ViewContainerRef, OnChanges } from '@angular/core';
import { StagedSupplyResolvedForm } from '../../../staged-supply-resolved-form';
import { HasViewContainerRef } from '../../../has-view-container-ref.interface';
import { SSConsentEligibilityFormDetailedComponent } from '../detailed/ss-consent-eligibility-form-detailed.component';
import { SSConsentEligibilityFormBasicComponent } from '../basic/ss-consent-eligibility-form-basic.component';

@Directive({
  selector: '[appSSConsentEligibilityFormHost]'
})
export class SSConsentEligibilityFormHostDirective implements HasViewContainerRef {
  constructor(public viewContainerRef: ViewContainerRef) { }
}

@Component({
  selector: 'app-ss-consent-eligibility-form',
  templateUrl: './ss-consent-eligibility-form.component.html'
})
export class SSConsentEligibilityFormComponent extends StagedSupplyResolvedForm implements OnChanges {
  @Input() isDetailed: boolean;
  @ViewChild(SSConsentEligibilityFormHostDirective, { static: true }) formHost: SSConsentEligibilityFormHostDirective;

  constructor(
    componentFactoryResolver: ComponentFactoryResolver
  ) {
    super(componentFactoryResolver);
  }

  ngOnChanges() {
    this.resolveFormComponent(this.isDetailed);
  }

  protected getFormComponent(isDetailed: boolean): Type<any> {
    return isDetailed
      ? SSConsentEligibilityFormDetailedComponent
      : SSConsentEligibilityFormBasicComponent;
  }
}
