import { DoctorReportModel } from '../../../models/six-cpa/doctor-report.model';

export enum CustomiseDoctorReportActions {
  SET_CUSTOMISED_DOCTOR_REPORT = '[CustomisedDoctorReportState] Set Customized Report'
}

export class SetCustomisedDoctorReportData {
  static readonly type = CustomiseDoctorReportActions.SET_CUSTOMISED_DOCTOR_REPORT
  constructor(public report: DoctorReportModel) { }
}
