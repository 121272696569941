import { Injectable } from '@angular/core';
import { BaseApi } from '~/shared/core/base-api/base-api';
import { Router } from '@angular/router';
import { StorageService } from '~/shared/core/storage/storage.service';
import { AlertService } from '~/modules/core/alert/alert.service';
import { DaaFormModel } from '~/shared/models/six-cpa/DAA/daa-form.model';
import { DatesService } from '~/shared/core/dates/dates.service';
import { Observable } from 'rxjs';
import { environment } from '../../../../../../../environments/environment';
import { HttpClient } from '@angular/common/http';

const API = environment.baseApiUrl;

@Injectable()
export class DoseAdministrationAidsService extends BaseApi {

  constructor(public http: HttpClient, public router: Router, public storageService: StorageService, public alertService: AlertService) {
    super(http, router, storageService, alertService);
  }

  postSave(clientId: string, formData: DaaFormModel) {
    formData = this.formatFormDataDates(formData);
    return this.post<DaaFormModel>(`${API}/api/daa/${clientId}/save`, formData);
  }

  postSubmit(clientId: string, formData: DaaFormModel) {
    formData = this.formatFormDataDates(formData);
    return this.post<DaaFormModel>(`${API}/api/daa/${clientId}/submit`, formData);
  }

  putSave(clientId: string, formData: DaaFormModel) {
    formData = this.formatFormDataDates(formData);
    return this.put(`${API}/api/daa/${clientId}/update-save`, formData);
  }

  putSubmit(clientId: string, formData: DaaFormModel) {
    formData = this.formatFormDataDates(formData);
    return this.put(`${API}/api/daa/${clientId}/update-submit`, formData);
  }

  getDAAForm(clientId: string, formId: number): Observable<any> {
    return this.get<any>(`${API}/api/daa/${clientId}/form/${formId}`);
  }

  private formatFormDataDates(form: DaaFormModel): DaaFormModel {
    form.patientDetailsForm.dob = DatesService.ToEndOfLocalDayInUtc(
      form.patientDetailsForm.dob
    );
    form.patientDetailsForm.dateOfDAAService = DatesService.ToEndOfLocalDayInUtc(
      form.patientDetailsForm.dateOfDAAService
    );
    return form;
  }
}
