import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BsDropdownModule } from 'ngx-bootstrap';
import { LoaderModule } from '~/modules/core/loader/loader.module';
import { PdfGeneratorService } from '~/shared/services/pdf-generator.service';
import { AdvancedSearchExportOptionsComponent } from './advanced-search-export-options.component';

@NgModule({
  declarations: [AdvancedSearchExportOptionsComponent],
  exports: [AdvancedSearchExportOptionsComponent],
  providers: [
    PdfGeneratorService
  ],
  imports: [
    BsDropdownModule,
    LoaderModule,
    CommonModule
  ]
})
export class AdvancedSearchExportOptionsModule {
  constructor() { }
}
