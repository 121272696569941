import { State, Action, StateContext, Selector } from '@ngxs/store';
import { HMRLocationFormModel } from '../../../../../../shared/models/six-cpa/HMR/hmr-location-form.model';
import { SetHMRLocationValidAction, SetTotalItemsAndCompletedItems, UpdateHMRLocation } from './hmr-location.actions';
import { SetProfileForSixCpaAction } from '../../../state/six-cpa.actions';
import { Injectable } from '@angular/core';

export class HMRLocationStateModel {
  hmrLocationForm: {
    model: Partial<HMRLocationFormModel>
  };
  valid: boolean;
  totalItems: number;
  completedItems: number;
}
@Injectable()
@State<HMRLocationStateModel>({
  name: 'hmrLocation',
  defaults: {
    hmrLocationForm: {
      model: undefined
    },
    valid: false,
    completedItems: 0,
    totalItems: 0
  }
})
export class HMRLocationState {
  @Selector()
  static hmrLocationForm(state: HMRLocationStateModel) { return state.hmrLocationForm.model; }

  @Selector()
  static totalItems(state: HMRLocationStateModel) { return state.totalItems; }

  @Selector()
  static completedItems(state: HMRLocationStateModel) { return state.completedItems; }

  @Action(SetHMRLocationValidAction)
  setValid(ctx: StateContext<HMRLocationStateModel>, action: SetHMRLocationValidAction) {
    ctx.patchState({
      valid: action.valid
    });
  }

  @Action(SetTotalItemsAndCompletedItems)
  setProgress(ctx: StateContext<HMRLocationStateModel>, action: SetTotalItemsAndCompletedItems) {
    ctx.patchState({
      totalItems: action.totalItems,
      completedItems: action.completedItems
    });
  }

  @Action(UpdateHMRLocation)
  updateForm(ctx: StateContext<HMRLocationStateModel>, { form }: UpdateHMRLocation) {
    ctx.patchState({
      hmrLocationForm: {
        model: form || undefined
      }
    });
  }

  @Action(SetProfileForSixCpaAction)
  onSetProfile(ctx: StateContext<HMRLocationStateModel>, { profile }: SetProfileForSixCpaAction) {
    const patient = profile.patient;
    ctx.patchState({
      hmrLocationForm: {
        model: {
          homeSuburb: patient.homeSuburb,
          residentialPostCode: (patient && patient.homePostcode) ? patient && patient.homePostcode.toString() : null
        }
      }
    });
  }
}

