import { HttpClient, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { StorageService } from '../core/storage/storage.service';
import { AlertService } from '~/modules/core/alert/alert.service';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs/Observable';
import { BaseApi } from '../core/base-api/base-api';
import { HtmlAsPdfFromStringRequest } from '../models/pdf-generator.models';

const API = environment.baseApiUrl;

@Injectable()
export class PdfGeneratorService extends BaseApi {
  constructor(
    public http: HttpClient,
    public router: Router,
    public storageService: StorageService,
    public alertService: AlertService
  ) {
    super(http, router, storageService, alertService);
  }

  generatePdfFromHtml(options: HtmlAsPdfFromStringRequest): Observable<any> {
    return this.post<HttpEvent<Blob>>(`${API}/api/pdf-generator/from-html`, options ,{
      responseType: 'blob',
    });
  }
}
