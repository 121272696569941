export const getDohValue = (item: {stock: {totalDoh: {value: number | string}}}) => convertToNumber(getDOH(item));

export function pharmacistInterventionAdvised(item: {stock: {totalDoh: {value: number | string}}}) {
    return dohIsBelowZero(getDohValue(item));
}

export function getInsightValue(item: {stock: {totalDoh: {value: number | string}}}) {
    return Math.round(Math.abs(getDohValue(item)));
}

export function getDOH(item: {stock: {totalDoh: {value: number | string}}}) {
    return item && item.stock && item.stock.totalDoh && item.stock.totalDoh.value || 0;
}

export function convertToNumber(value: string | number) {
    if (!value) {
        return 0;
    }
    return parseFloat(`${value}`);
}

export function dohIsBelowZero(doh: number) {
    return doh < 0;
}
