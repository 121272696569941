import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-client-title',
  templateUrl: './client-title.component.html',
  styleUrls: ['./client-title.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ClientTitleComponent {
  @Input() clientName: string;
  @Input() isInSystem = false;
}
