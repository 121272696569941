<section class="invoices-page">
  <h3>Invoices</h3>
  <div class="table mt-4 margin-sm-top">
    <div class="col-sm-12">
  <ng-container *ngIf="(invoices$ | async) as invoiceResponse">
    <nz-table
      [nzData]="invoiceResponse.invoices" 
      [nzFrontPagination]="false" 
      [nzShowPagination]="false" 
      #basicTable>
      <thead>
        <tr>
          <th>Status</th>
          <th>Created At</th>
          <th>Invoice Number</th>
          <th>Customer Name</th>
          <th>Price</th>
          <th>No. of Items</th>
          <th>Link to Order</th>
        </tr>
      </thead>
        <tbody>
          <ng-container *ngIf="invoiceResponse.isLoading; else showTable">
            <tr style="border: none;">
              <td colspan="7" style="border: none;">
                <app-loader loadingMessage="loading"></app-loader>
              </td>
            </tr>
          </ng-container>
          <ng-template #showTable>
            <tr *ngFor="let invoice of invoiceResponse.invoices">
              <td>
                <i class="fal" [ngClass]="{
                  'fa-envelope-open': invoice.status == InvoiceStatus.Open,
                  'fa-check': invoice.status == InvoiceStatus.Paid,
                  'fa-pen': invoice.status == InvoiceStatus.Draft,
                  'fa-envelope-open-dollar': invoice.status == InvoiceStatus.Void,
                  'fa-exclamation': invoice.status == InvoiceStatus.Uncollectible
                }"></i>
                <span class="inv-status">{{ invoice.status | titlecase}}</span>
              </td>
              <td>{{ getCreatedDateTime(invoice.createdAt) | tenantTimezone | date:'medium' }}</td>
              <td>{{ invoice.invoiceNumber }}</td>
              <td>{{ invoice.customerName }}</td>
              <td>${{ (invoice.totalPayableAmount / 100).toFixed(2) }}</td>
              <td>{{ invoice.totalNumberOfItemsBought }}</td>
              <td><a [href]="invoice.orderLink" target="_blank">View Order</a></td>
            </tr>
          </ng-template>
        </tbody>
    </nz-table>
  </ng-container>
  </div>
  </div>
</section>
