import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdditionalDetailsFormListItemComponent } from './additional-details-form-list-item/additional-details-form-list-item.component';
import { AdditionalDetailsFormComponent } from './additional-details-form/additional-details-form.component';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import { ReactiveFormsModule } from '@angular/forms';
import { SixCpaFormListItemChecklistModule } from '../six-cpa-form-list-item-checklist/six-cpa-form-list-item-checklist.module';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';

@NgModule({
  declarations: [AdditionalDetailsFormListItemComponent, AdditionalDetailsFormComponent],
  imports: [
    CommonModule,
    NgxsFormPluginModule,
    ReactiveFormsModule,
    SixCpaFormListItemChecklistModule,
    BsDatepickerModule.forRoot(),
  ],
  exports: [AdditionalDetailsFormListItemComponent]
})
export class AdditionalDetailsModule { }
