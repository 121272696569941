import { IdentifyIndividualWithMedicareType, IdentifyIndividualWithoutMedicareIrnType, IdentifyIndividualWithoutMedicareType } from '../../models/identify-individual-request.model';
import { AirHistoryRequestType } from '../../models/individual-history-statement.model';
import { IdentifyIndividualRequestModel } from './air-lookup-form.model';

export enum AIRActionTypes {
  SUBMIT_AIR_PATIENT = '[AIR] Submit AIR Patient',
  SUBMIT_LOOKUP_BY_MEDICARE = '[AIR] SUBMIT_LOOKUP_BY_MEDICARE',
  SUBMIT_LOOKUP_WITHOUT_MEDICARE = '[AIR] SUBMIT_LOOKUP_WITHOUT_MEDICARE',
  SUBMIT_LOOKUP_WITHOUT_MEDICARE_IRN = '[AIR] SUBMIT_LOOKUP_WITHOUT_MEDICARE_IRN',
  SUBMIT_AIR_PATIENT_SUCCESS = '[AIR] Submit AIR Patient Success',
  SUBMIT_AIR_PATIENT_FAILED = '[AIR] Submit AIR Patient Failed',
  GET_AIR_HISTORY_STATEMENT = '[AIR] GET_AIR_HISTORY_STATEMENT',
  GET_AIR_HISTORY_STATEMENT_SUCCESS = '[AIR] GET_AIR_HISTORY_STATEMENT_SUCCESS',
  GET_AIR_HISTORY_STATEMENT_ERROR = '[AIR] GET_AIR_HISTORY_STATEMENT_ERROR',
  CLEAR_AIR_LOOKUP = '[AIR] CLEAR_AIR_LOOKUP',
  TOGGLE_AIR_PATIENT_CONFIRM = '[AIR] Toggle Blade'
}

export class SubmitAIRPatient {
  constructor(public patient: IdentifyIndividualRequestModel) { }
  static readonly type = AIRActionTypes.SUBMIT_AIR_PATIENT;
}

export class SubmitAIRLookupByMedicare {
  constructor(public request: IdentifyIndividualWithMedicareType, public tenantCustomerId: number) { }
  static readonly type = AIRActionTypes.SUBMIT_LOOKUP_BY_MEDICARE;
}

export class SubmitAIRLookupWithoutMedicare {
  constructor(public request: IdentifyIndividualWithoutMedicareType, public tenantCustomerId: number) { }
  static readonly type = AIRActionTypes.SUBMIT_LOOKUP_WITHOUT_MEDICARE;
}

export class SubmitAIRLookupWithoutMedicareIRN {
  constructor(public request: IdentifyIndividualWithoutMedicareIrnType, public tenantCustomerId: number) { }
  static readonly type = AIRActionTypes.SUBMIT_LOOKUP_WITHOUT_MEDICARE_IRN;
}

export class SubmitAIRPatientSuccess {
  constructor(public patient: IdentifyIndividualRequestModel) { }
  static readonly type = AIRActionTypes.SUBMIT_AIR_PATIENT_SUCCESS;
}

export class SubmitAIRPatientFailed {
  constructor(public error: any) { }
  static readonly type = AIRActionTypes.SUBMIT_AIR_PATIENT_FAILED;
}

export class GetAIRHistoryStatement {
  constructor(public request: AirHistoryRequestType) { }
  static readonly type = AIRActionTypes.GET_AIR_HISTORY_STATEMENT;
}

export class GetAIRHistoryStatementSuccess {
  constructor(public statement: any) { }
  static readonly type = AIRActionTypes.GET_AIR_HISTORY_STATEMENT_SUCCESS;
}

export class GetAIRHistoryStatementError {
  constructor(public error: any) { }
  static readonly type = AIRActionTypes.GET_AIR_HISTORY_STATEMENT_ERROR;
}

export class ClearAIRLookup {
  constructor() { }
  static readonly type = AIRActionTypes.CLEAR_AIR_LOOKUP;
}

export class TogglePatientConfirm {
  constructor(public slideMode: string) { }
  static readonly type = AIRActionTypes.TOGGLE_AIR_PATIENT_CONFIRM;
}
