export interface PrimaryContactSummary {
    smsPrimaryContact: boolean;
    emailPrimaryContact: boolean;
    contacts: LinkedContactSummary[];
    email: string;
    mobileNumber: string;
    tenantCustomerId: number;
}

export interface LinkedContactSummary {
    clientId: string;
    tenantCustomerId: number | null;
    firstName: string;
    surname: string;
    isSMSPrimaryContact: boolean;
    isEmailPrimaryContact: boolean;
    mobile: string;
    email: string;
}

export class OptOutUpdateModal {
    constructor(
        public mobileNumber: string = "",
        public isCreating: boolean = false,
    ) { }
}