import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';

import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import { StorageService } from '../core/storage/storage.service';
import { TenantUser } from '../models/tenantUser.model';
import { CrudService } from './crud.service';
import { ConnectionType } from '../models/connectionType.model';
import { AlertService } from '~/modules/core/alert/alert.service';

const API = environment.baseApiUrl;

@Injectable()
export class TenantUserService extends CrudService {
  constructor(public http: HttpClient, public router: Router, public storageService: StorageService, public alertService: AlertService) {
    super(http, router, storageService, alertService, 'tenantUser');
  }

  getConnectionTypes(): Observable<ConnectionType[]> {
    return this.get(`${API}/api/tenantUser/getConnectionTypes`);
  }

  getTenantUserList(): Observable<TenantUser[]> {
    return this.getList();
  }

  addTenantUser(item: TenantUser): Observable<TenantUser> {
    return this.add(item);
  }

  updateTenantUser(item: TenantUser): Observable<TenantUser> {
    return this.update(item);
  }

  removeTenantUser(id: any) {
    return this.remove(id);
  }
}
