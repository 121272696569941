import { HMRChildForm } from '../hmr-child-form';
import { OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Store, Actions } from '@ngxs/store';
import { SetTotalItemsAndCompletedItems } from './state/hmr-gp-details-referral-form.actions';

const controlsToExclude = ['otherReasonForReferral'];
const controlsToIgnore = ['firstName', 'surname', 'reasonForReferral'];
export class HMRGPDetailsReferralFormBaseComponent extends HMRChildForm implements OnInit, OnDestroy {
    gpDetailsReferralForm: FormGroup;
    formResetStatus: boolean;

    constructor(private formBuilder: FormBuilder, protected store: Store, actions: Actions) {
      super(actions);
      this.gpDetailsReferralForm = this.formBuilder.group({
        firstName: new FormControl('', Validators.required),
        surname: new FormControl('', Validators.required),
        providerNumber: new FormControl('', [Validators.required, Validators.pattern('^[A-Z0-9]*')])
      });
    }
    get formControls() {
      return this.gpDetailsReferralForm.controls;
    }
    protected actionOnResetForm() {
      const controls = Object.keys(this.gpDetailsReferralForm.controls);
      controls.filter((key) => {
        if (!controlsToIgnore.includes(key)) {
          this.gpDetailsReferralForm.controls[key].setValue('');
        }
        this.gpDetailsReferralForm.patchValue(
          {
            referralDate: new Date()
          });
      });
      if (this.gpDetailsReferralForm.controls['reasonForReferral'] !== undefined) {
        this.gpDetailsReferralForm.controls['reasonForReferral'].reset();
      }

    }

    protected onFormChange() {
      this.deselectOtherBoxes();
      const controls = Object.keys(this.gpDetailsReferralForm.controls);
      const totalControls = controls.filter(x => !controlsToExclude.includes(x));
      const completedItems = this.getTotalValidItems(totalControls);
      this.store.dispatch(new SetTotalItemsAndCompletedItems(totalControls.length, completedItems));
    }

    private getTotalValidItems(controls: Array<string>) {
      return controls.filter((key) => {
        const formControl = this.gpDetailsReferralForm.get(key);
        return  formControl.status === 'VALID' || (formControl.status === 'DISABLED' && !formControl.invalid && formControl.errors === null);
      }).length;
    }
    private deselectOtherBoxes() {
      if (this.gpDetailsReferralForm.value.reasonForReferral
        && this.gpDetailsReferralForm.value.reasonForReferral.other === false) {
        this.gpDetailsReferralForm.controls['otherReasonForReferral'].setValue('');
      }
    }
  }

