import { Component } from '@angular/core';
import { Select } from '@ngxs/store';
import { HMRFormState, HMRFormType } from '../hmr-form-list/state/hmr-form.state';
import { Observable } from 'rxjs';
import { HMRGPDetailsReferralFormState, HMRGPDetailsReferralFormStateModel } from '../hmr-gp-details-referral-form/state/hmr-gp-details-referral-form.state';

@Component({
  selector: 'app-hmr-gp-details-referral-form-list-item',
  templateUrl: './hmr-gp-details-referral-form-list-item.component.html'
})
export class HMRGPDetailsReferralFormListItemComponent {
  @Select(HMRFormState.hmrFormType) hmrFormType$: Observable<HMRFormType>;
  @Select(HMRGPDetailsReferralFormState) formState$: Observable<HMRGPDetailsReferralFormStateModel>;
  hmrFormTypes = HMRFormType;

  getFormTitle(formType: HMRFormType) {
    switch (formType) {
      case HMRFormType.NewClaim:
        return 'HMR Details of GP Referral';
        case HMRFormType.InterviewRegisteredPhamacist:
        return 'HMR GP Details';
        case HMRFormType.InterviewOutsidePatientHome:
          return 'HMR Details of GP Referral';
    }
  }
}

