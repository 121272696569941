import { Message } from '../../../models/message.model';

export enum ScryptSmsActions {
  TOGGLE_MODAL = '[ScryptSms] Toggle Modal',
  GET_TEMPLATES = '[ScryptSms] Get Templates',
  SET_SELECTED_TEMPLATE = '[ScryptSms] Set Selected Template',
  SET_REPEAT_URI = '[ScryptSms] Set Repeat Uri',
  SEND_REPEAT_REMINDER = '[ScryptSms] Send Repeat Reminder'
}

export class ToggleScryptSmsModal {
  static readonly type = ScryptSmsActions.TOGGLE_MODAL;
  constructor(public showModal: boolean) { }
}

export class GetScryptSMSRepeatTemplates {
  static readonly type = ScryptSmsActions.GET_TEMPLATES;
  constructor() { }
}

export class SetSelectedRepeatTemplate {
  static readonly type = ScryptSmsActions.SET_SELECTED_TEMPLATE;
  constructor(public templateId: number) { }
}

export class SetRepeatUri {
  static readonly type = ScryptSmsActions.SET_REPEAT_URI;
  constructor(public repeatUri: string) { }
}

export class SendRepeatReminder {
  static readonly type = ScryptSmsActions.SEND_REPEAT_REMINDER;
  constructor(public message: Message) { }
}

