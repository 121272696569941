import { NoteFilter } from "~/shared/models/crm/noteFilter.model";
import { Customer } from "~/shared/models/dashboard/customer.model";
import { BioMetricsOptions } from "../dashboard-page.component";

export enum DashboardActionTypes {
  GET_CUSTOMERS = '[Dashboard] Get Customers',
  SET_CUSTOMERS = '[Dashboard] Set Customers',
  GET_CRM_TABLE = '[Dashboard] Get CRM Table',
  FILTER_CRM_TABLE = '[Dashboard] Filter CRM Table',
  FILTER_CUSTOMERS = '[Dashboard] Filter Customers',
  GET_CLEANLINESS_DATA = '[Dashboard] Get Cleanliness Data',
  GET_GAUGE_DATA = '[Dashboard] Get Gauge Data',
  UPDATE_GAUGE = '[Dashboard] Update Gauge',
  SET_SEARCH = '[Dashboard] Set Search',
  FIRE_SEARCH = '[Dashboard] Fire Search'
}

export class GetCustomers {
  static readonly type = DashboardActionTypes.GET_CUSTOMERS;
  constructor(public count: number) { };
}

export class SetCustomers {
  static readonly type = DashboardActionTypes.SET_CUSTOMERS;
  constructor(public customers: Customer[]) { };
}

export class GetCrmTable {
  static readonly type = DashboardActionTypes.GET_CRM_TABLE;
  constructor(public filter: NoteFilter) { }
}

export class FilterCrmTable {
  static readonly type = DashboardActionTypes.FILTER_CRM_TABLE;
  constructor(public filterText: string) { }
}

export class FilterCustomers {
  static readonly type = DashboardActionTypes.FILTER_CUSTOMERS;
  constructor(public filterText: string) { }
}

export class GetCleanlinessData {
  static readonly type = DashboardActionTypes.GET_CLEANLINESS_DATA;
  constructor(public biometricOption: BioMetricsOptions) { }
}

export class GetGaugeData {
  static readonly type = DashboardActionTypes.GET_GAUGE_DATA;
  constructor() { }
}

export class UpdateGauge {
  static readonly type = DashboardActionTypes.UPDATE_GAUGE;
  constructor(public type: string, public index: number) { }
}

export class SetSearch {
  static readonly type = DashboardActionTypes.SET_SEARCH;
  constructor(public input: string[]) { }
}

export class FireSearch {
  static readonly type = DashboardActionTypes.FIRE_SEARCH;
  constructor() { }
}
