import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthService } from '../../shared/services/auth.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Notification } from '../../shared/models/notification.model';
import { Auth } from '../../shared/models/auth.model';
import { Subscription } from 'rxjs/Subscription';
import { AuthUser } from '../../shared/models/authUser.model';
import { StorageService } from '../../shared/core/storage/storage.service';
import { AlertService } from '../../modules/core/alert/alert.service';
import { AngularFireAnalytics } from '@angular/fire/analytics';

declare var window: any;

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss'],
})
export class LoginPageComponent implements OnInit, OnDestroy {
  constructor(
    private authServise: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private storageService: StorageService,
    public alertService: AlertService,
    private analytics: AngularFireAnalytics
  ) {}

  submitting = false;
  username: string;
  password: string;
  notification: Notification;
  authSubscription: Subscription;

  ngOnInit() {
    window.Intercom('shutdown');

    window.intercomSettings = {
      app_id: 'kow7asty',
    };

    window.Intercom('update');

    this.notification = new Notification('danger', '');
    this.route.queryParams.subscribe((params: Params) => {
      if (params['accessDenied']) {
        this.showNotification({
          text: 'No access, please login',
          type: 'warning',
        });
      }
    });
  }
  private showNotification(notification: Notification) {
    this.notification = notification;
    window.setTimeout(() => {
      this.notification.text = '';
    }, 5000);
  }

  onSubmit() {
    const login = new AuthUser(this.username, this.password);
    this.submitting = true;
    this.authSubscription = this.authServise.login(login).subscribe(
      (user: Auth) => {
        this.logUserLoggedInGAEvent(this.username);
        this.storageService.clearStorage();
        this.storageService.setItem('scrypt_access_token', user.access_token);
        this.router.navigate(['']);
      },
      (error) => {
        this.showNotification({ text: error.error, type: 'danger' });
        this.submitting = false;
      }
    );
  }

  private logUserLoggedInGAEvent(userName: string) {
    this.analytics.logEvent('hq_login', {
      user: this.username,
    });
  }

  onForgot() {
    this.router.navigate(['forgot']);
  }

  ngOnDestroy() {
    if (this.authSubscription) {
      this.authSubscription.unsubscribe();
    }
  }
}
