export interface DAAAdditionalDetailsFormModel {
  id?: number | null;
  daaReferralSource: DAAReferralSource;
  datePlanWasMade: Date | string | null;
  dateOfInitialContact: Date | string | null;
  daaCollectionSource: DAACollectionSource;
  typeOfDAAPackedByPharmacy: DAAPackageTypes
}

export enum DAAReferralSource {
  HMRManagementPlan,
  MedsCheckReview,
  GPReferralNotHealthCareHomesPilot,
  GPReferralHealthCareHomesPilot,
  SelfReferral,
  Pharmacy,
  NotStatedOrInadequatelyDescribed
}

export enum DAACollectionSource {
  CollectedFromPharmacy,
  HomeDelivered,
  Other
}

export enum DAAPackageTypes {
  CompartmentalisedBoxes,
  BlisterPacks,
  BubblePacks,
  SachetSystems
}