import { AdditionalItemStock } from '../out-of-store-stock.model';
import { PassiveScriptType } from '../out-of-store.model';

export enum OutOfStoreActionTypes {
  OPEN = '[OutOfStore] open',
  CLOSE = '[OutOfStore] close',
  ADD_ITEMS_REQUEST = '[OutOfStore] Request',
  ADD_ITEMS_RESPONSE = '[OutOfStore] Response',
  ADD_ITEMS_ERROR = '[OutOfStore] Error',
  ADD_OUT_OF_STORE_ITEM = '[OutOfStore] Add out of store item',
  REMOVE_OUT_OF_STORE_ITEM = '[OutOfStore] Remove',
  ADD_OUT_OF_STORE_ITEM_FROM_CATEGORIES = '[OutOfStore] ADD_OUT_OF_STORE_ITEM_FROM_CATEGORIES',
  REMOVE_PASSIVE_SCRIPT = '[OutOfStore] REMOVE_PASSIVE_SCRIPT',
  UPDATE_PASSIVE_SCRIPT = '[OutOfStore] UPDATE_PASSIVE_SCRIPT'

}

export class OpenOutOfStore {
  static readonly type = OutOfStoreActionTypes.OPEN;
  constructor(public isFullItem: boolean = true) {}
}

export class CloseOutOfStore {
  static readonly type = OutOfStoreActionTypes.CLOSE;
}
export class AddOutOfStoreItemAction {
  static readonly type = OutOfStoreActionTypes.ADD_OUT_OF_STORE_ITEM;
  constructor(public itemId: string) {}
}

export class AddOutOfStoreItemActionFromCategory {
  static readonly type = OutOfStoreActionTypes.ADD_OUT_OF_STORE_ITEM_FROM_CATEGORIES;
  constructor(public script: any, public type: PassiveScriptType) {}
}

export class RemoveOutOfStoreItemAction {
  static readonly type = OutOfStoreActionTypes.REMOVE_OUT_OF_STORE_ITEM;
  constructor(public itemId: string) { }
}

export class RemovePassiveScript {
  static readonly type = OutOfStoreActionTypes.REMOVE_PASSIVE_SCRIPT;
  constructor(public passiveScriptId: string) { }
}

export class OutOfStoreRequestAction {
  static readonly type = OutOfStoreActionTypes.ADD_ITEMS_REQUEST;
  constructor(public tenantCustomerId: number, public items: AdditionalItemStock[]) {}
}

export class OutOfStoreResponseAction {
  static readonly type = OutOfStoreActionTypes.ADD_ITEMS_RESPONSE;
}

export class OutOfStoreErrorAction {
  static readonly type = OutOfStoreActionTypes.ADD_ITEMS_ERROR;
  constructor(public error: any) {}
}

export class UpdatePassiveScript{
  static readonly type = OutOfStoreActionTypes.UPDATE_PASSIVE_SCRIPT;
  constructor(public passiveScriptId: string, public repeats: number) {}
}
