import { HMRConsentAndEligibilityFormModel } from '~/shared/models/six-cpa/HMR/hmr-consent-and-eligibility.model';

export enum HMRConsentAndEligibilityActions {
  SET_VALID = '[HMRConsentAndEligibility] set valid',
  UPDATE = '[HMRConsentAndEligibility] Update Form',
  UPDATE_PROGRESS = '[HMRConsentAndEligibility] Update Progress'
}

export class SetHMRConsentAndEligibilityValidAction {
  static readonly type = HMRConsentAndEligibilityActions.SET_VALID;
  constructor(public valid = false) { }
}

export class SetTotalItemsAndCompletedItems {
  static readonly type = HMRConsentAndEligibilityActions.UPDATE_PROGRESS;
  constructor(public totalItems, public completedItems) { }
}

export class UpdateHMRConsentAndEligibility {
  static readonly type = HMRConsentAndEligibilityActions.UPDATE;
  constructor(public form: Partial<HMRConsentAndEligibilityFormModel>) { }
}
