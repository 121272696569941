import { Injectable } from '@angular/core';
import { State, Selector, Action, StateContext } from '@ngxs/store';
import { IHMRServiceProviderDetailsFormStateModel } from './hmr-service-provider-details-form-state-model.interface';
import { UpdateHMRServiceProviderDetailsForm } from './hmr-service-provider-details-form.actions';

  export class HMRServiceProviderDetailsFormBasicStateModel {
    serviceProviderDetailsForm: {
      model: Partial<IHMRServiceProviderDetailsFormStateModel>;
    };
  }

  @State<HMRServiceProviderDetailsFormBasicStateModel>({
    name: 'hmrServiceProviderDetailsFormBasic',
    defaults: {
      serviceProviderDetailsForm: {
        model: undefined
      }
    }
  })
  @Injectable()
  export class HMRServiceProviderDetailsFormBasicState {
    @Selector()
    static form(state: HMRServiceProviderDetailsFormBasicStateModel) { return state.serviceProviderDetailsForm.model; }


    @Action(UpdateHMRServiceProviderDetailsForm)
    updateForm(ctx: StateContext<HMRServiceProviderDetailsFormBasicStateModel>, { form }: UpdateHMRServiceProviderDetailsForm) {
      ctx.patchState({
        serviceProviderDetailsForm: {
          model: form || undefined
        }
      });
    }
  }
