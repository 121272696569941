import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { Store } from "@ngxs/store";
import { FetchAttendingDoctors } from "~/modules/profile/attending-doctor/state/attending-doctor.action";
import { GetDoctorReportTemplates } from "~/shared/components/doctor-report-templates-selector/state/doctor-report-templates-selector.actions";

@Injectable()
export class DoctorsResolver implements Resolve<Observable<any>> {
    constructor(private store: Store) { }
    resolve(route: ActivatedRouteSnapshot) {
        const clientId = route.params.clientId;
        return this.store.dispatch([new FetchAttendingDoctors(clientId), new GetDoctorReportTemplates(clientId)]);
    }
}