import { State, Action, StateContext, Selector } from '@ngxs/store';
import { HMRConsentAndEligibilityFormModel } from '../../../../../../shared/models/six-cpa/HMR/hmr-consent-and-eligibility.model';
import { SetHMRConsentAndEligibilityValidAction, SetTotalItemsAndCompletedItems, UpdateHMRConsentAndEligibility } from './hmr-consent-and-eligibility.actions';
import { Injectable } from '@angular/core';

export class HMRConsentAndEligibilityStateModel {
  hmrConsentAndEligibilityForm: {
    model: Partial<HMRConsentAndEligibilityFormModel>
  };
  valid: boolean;
  totalItems: number;
  completedItems: number;
}
@Injectable()
@State<HMRConsentAndEligibilityStateModel>({
  name: 'hmrConsentAndEligibility',
  defaults: {
    hmrConsentAndEligibilityForm: {
      model: undefined
    },
    valid: false,
    completedItems: 0,
    totalItems: 0
  }
})
export class HMRConsentAndEligibilityState {
  @Selector()
  static hmrConsentAndEligibilityForm(state: HMRConsentAndEligibilityStateModel) { return state.hmrConsentAndEligibilityForm.model; }

  @Selector()
  static totalItems(state: HMRConsentAndEligibilityStateModel) { return state.totalItems; }

  @Selector()
  static completedItems(state: HMRConsentAndEligibilityStateModel) { return state.completedItems; }

  @Action(SetHMRConsentAndEligibilityValidAction)
  setValid(ctx: StateContext<HMRConsentAndEligibilityStateModel>, action: SetHMRConsentAndEligibilityValidAction) {
    ctx.patchState({
      valid: action.valid
    });
  }

  @Action(SetTotalItemsAndCompletedItems)
  setProgress(ctx: StateContext<HMRConsentAndEligibilityStateModel>, action: SetTotalItemsAndCompletedItems) {
    ctx.patchState({
      totalItems: action.totalItems,
      completedItems: action.completedItems
    });
  }

  @Action(UpdateHMRConsentAndEligibility)
  updateForm(ctx: StateContext<HMRConsentAndEligibilityStateModel>, { form }: UpdateHMRConsentAndEligibility) {
    ctx.patchState({
      hmrConsentAndEligibilityForm: {
        model: form || undefined
      }
    });
  }
}
