import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Router } from '@angular/router';
import { StorageService } from '../core/storage/storage.service';
import { MarketingSetting } from '../models/marketingSetting.model';
import { AlertService } from '../../modules/core/alert/alert.service';
import { BaseApiNoAlerts } from '../core/base-api/base-api-no-alerts';

@Injectable()
export class MarketingService extends BaseApiNoAlerts {
  API = '';

  constructor(
    @Inject('environment') env: any,
    public http: HttpClient,
    public router: Router,
    public storageService: StorageService,
    public alertService: AlertService
  ) {
    super(http, router, storageService, alertService);
    this.API = env.baseApiUrl;
  }

  getMarketingSequence(): Observable<MarketingSetting[]> {
    return this.get(`${this.API}/api/Marketing`);
  }

  createMarketing(item: MarketingSetting): Observable<MarketingSetting> {
    return this.post(`${this.API}/api/Marketing/tenant`, item);
  }

  createDoctorReportTemplate(
    item: MarketingSetting
  ): Observable<MarketingSetting> {
    return this.post(`${this.API}/api/Marketing/doctor-report-template`, item);
  }

  updateMarketingSequence(
    item: MarketingSetting
  ): Observable<MarketingSetting> {
    return this.put(`${this.API}/api/Marketing/update/tenant`, item);
  }

  updateAllMarketingSequence(
    items: MarketingSetting[]
  ): Observable<MarketingSetting[]> {
    return this.put(`${this.API}/api/Marketing/update/tenants`, items);
  }

  getDoctorReportTemplates(clientId: string): Observable<MarketingSetting[]> {
    return this.get(
      `${this.API}/api/Marketing/doctor-report-templates/${clientId}`
    );
  }
}
