import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StripeModalComponent } from './stripe-modal.component';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { LoaderModule } from '../../../../../modules/core/loader/loader.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';

@NgModule({
  declarations: [
    StripeModalComponent
  ],
  imports: [
    CommonModule,
    NzModalModule,
    NzButtonModule,
    CommonModule,
    NzCheckboxModule,
    LoaderModule,
    FormsModule,
    NzToolTipModule,
    ReactiveFormsModule,
    NzRadioModule,
  ],
  exports:[
    StripeModalComponent
  ]
})

export class StripeModalModule { }
