  <div>
      <h1>Generate Client Report</h1>
      <form #f="ngForm" (ngSubmit)="onSubmit(f)">
        <div class="form-group">
            <label class="font__bold">Type</label>
            <select
            class="form-control"
            name="type"
            #type="ngModel"
            ngModel
            required
            >
              <option *ngFor="let item of customer.typeOptions" [value]="item.value" [selected]="item.selected">
                {{item.text}}
              </option>
            </select>
            <span *ngIf="type.invalid && type.touched" class="form-help-text">Required field</span>
          </div>
        <div class="form-group">
          <label class="font__bold">Categories</label>
          <select
          class="form-control"
          name="categories"
          #categories="ngModel"
          ngModel
          multiple
          required
          >
            <option *ngFor="let item of customer.categoryOptions" [value]="item.value" [selected]="item.selected">
              {{item.text}}
            </option>
          </select>
          <span *ngIf="categories.invalid && categories.touched" class="form-help-text">Required field</span>

        </div>
        <div class="form-group">
          <label class="font__bold">Repeats</label>
          <select
          class="form-control"
          name="repeatsOptions"
          #repeatsOptions="ngModel"
          ngModel
          multiple
          >
            <option *ngFor="let item of customer.repeatsOptions" [value]="item.value" [selected]="item.selected">
              {{item.text}}
            </option>
          </select>
          <span *ngIf="repeatsOptions.invalid && repeatsOptions.touched" class="form-help-text">Required field</span>

        </div>
        <div class="form-group">
          <label class="font__bold">Doctor</label>
          <select
          class="form-control"
          name="doctor"
          #doctor="ngModel"
          [ngModel]="null"
          >
            <option [ngValue]="null">All</option>
            <option *ngFor="let item of (doctors$ | async)" [value]="item.doctorID">
              {{item.surname}} {{item.firstName}}
            </option>
          </select>
        </div>
        <div *ngIf="f.controls.type?.value === 'Request'">
          <div class="form-group">
            <label class="font__bold">Include DOH</label>
            <input type="checkbox"  class="ml-1" name="includeDOH" #includeDOH="ngModel" [ngModel]="false" />
          </div>
          <div class="form-group">
            <label class="font__bold">Needed by</label>
            <input type="text" class="form-control form-control-lg" name="neededBy" [ngModel]="todayDate"
                  #neededBy="ngModel"
                  [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY' }" placeholder="dd-mm-yyyy" bsDatepicker
                  placement="top">
          </div>
        </div>
      
        <div g-xs="mt2">
          <button type="submit" [disabled]="f.invalid" *ngIf="!isLoading" class="button btn__green pull-right">Generate Report</button>
          <app-loader *ngIf="isLoading"></app-loader>
      </div>
      </form>
  </div>
