import { NgModule } from '@angular/core';
import { ProfileServicesComponent } from './profile-services.component';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxsModule } from '@ngxs/store';
import { ProfileServicesState } from './state/profile-services.state';
import { SixCpaModule } from '../six-cpa/six-cpa.module';
import { SixCpaFormsService } from '../../../shared/services/six-cpa-forms.service';
import { TimezoneModule } from '../../../modules/core/timezone/timezone.module';
import { TabsModule } from 'ngx-bootstrap';
import { DocumentModule } from '../../document/document.module';
import { LoaderModule } from '../../core/loader/loader.module';

@NgModule({
  declarations: [ProfileServicesComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NgxsModule.forFeature([ProfileServicesState]),
    SixCpaModule,
    TimezoneModule,
    TabsModule,
    DocumentModule,
    LoaderModule,
  ],
  exports: [ProfileServicesComponent],
  providers: [SixCpaFormsService],
})
export class ProfileServicesModule {}
