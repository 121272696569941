<div class="modal confirm-modal">
  <div class="modal-body border__grey">
    <div>
      <h3 class="p-2">Info</h3>
      <p class="p-2">The following medications could not be sent to Dispense as they were manually entered into ScryptHQ</p>
      <p class="p-2">Please dispense these scripts manually</p>
      <ul class="p-2 ml-3">
        <li *ngFor="let script of scripts">
            {{script.name}} 
            <span *ngIf="script.scriptNo; else outsideScript">({{script.scriptNo}})</span>
            <span *ngIf="script.originalScriptNo"> | Original: {{script.originalScriptNo}}</span>
        </li>
      </ul>
      <div g-xs="mt2">
        <button class="button btn__yellow" g-xs="mr1" (click)="close()">Cancel</button>
        <button class="button btn__green" (click)="continue()">Ok</button>
      </div>
    </div>
  </div>
</div>
<div class="modal-background"></div>
<ng-template #outsideScript>
    (Outside Script)
</ng-template>
