import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { VaccinationRouteComponent } from './components/vaccination-route/vaccination-route.component';
import { VaccinationErrorComponent } from './components/vaccination-error/vaccination-error.component';
import { VaccinationResolver } from './resolvers/vaccination.resolver';
import { VaccinationRoute } from './types/vaccination';

const routes: Routes = [
  {
    path: VaccinationRoute.Screening,
    children: [
      {
        path: '',
        resolve: [VaccinationResolver],
        component: VaccinationRouteComponent,
      },
      {
        path: VaccinationRoute.WhoopingCough,
        loadChildren: () =>
          import('./whooping-cough/whooping-cough.module').then(
            (x) => x.WhoopingCoughModule
          ),
      },
      { path: VaccinationRoute.Error, component: VaccinationErrorComponent },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class VaccinationRoutingModule {}
