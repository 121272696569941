import { State, Action, StateContext, Selector, Store, ofActionSuccessful, Actions } from '@ngxs/store';
import {
  SetCategoriesAction,
  GetCategoriesErrorAction,
  RefreshCategoriesAction,
  ScriptLoadingStartAction,
  ScriptLoadingStopAction,
  UpdateScriptAction,
  ToggleCategoryAction,
  OpenCategoryAction,
  ScriptsLoadingStartAction,
  ScriptsLoadingStopAction,
  ResetScriptsAction,
  SetSelectedDrugsPausedStatus
} from './categories.actions';
import { CategoriesService } from '../services/categories.service';
import { catchError, mergeMap, take } from 'rxjs/operators';
import { SetLastVisitDate, SetMedsListsForSixCpa, SetProfileAction } from '../../../core/profile/state/profile.actions';
import { Injectable } from '@angular/core';
import { Medication } from '../../../../shared/models/script/chartviewitem.model';
import { getDohOrZero } from '../../../../shared/helpers/doh.helper';
import { MedicineCategoryConstants } from '../../../../shared/constants/medicine-category.constant';
import { ProfileState } from '../../../core/profile/state/profile.state';
import { ClearSelectedDrugsAction } from '../multiple-select/state/multiple-drugs.actions';

export enum DrugLoadingState {
  NONE,
  LOADING
}

export class CategoriesStateModel {
  scripts: Medication[];
  loading: boolean;
  showCategories: Set<number>;
  smallestDoh: number;
  loadingNotifications: boolean;
}

export interface DosageForm {
  frequency: any;
  dosesPerDay: any;
  effectiveFrom: Date | string | null;
  doh?: number;
}
export interface MetaForm {
  categoryId: any;
  requestable: any;
  flagged: any;
}

class CategoriesStateHelper {
  static extendItem(item: Medication): Medication {
    let result = {
      ...item,
      metaForm: {
        categoryId: item.cMeta.categoryId,
        requestable: item.cMeta.requestable,
        flagged: item.isFlagged
      },
      dosageLoading: false
    };
    if (item.cMeta.currentDosage) {
      result = {
        ...result,
        dosageForm: {
          frequency: item.cMeta.currentDosage.frequency,
          dosesPerDay: item.cMeta.currentDosage.dosesPerDay,
          effectiveFrom: new Date(),
          doh: getDohOrZero(item)
        },
      };
    }
    return result;
  }

  static addNewCategory(output, item) {
    if (!item.cMeta.categoryId){
      output.push({
        key: {
          id: 0,
          name: MedicineCategoryConstants.Uncategorised,
          position: -1,
          friendlyName: MedicineCategoryConstants.Uncategorised
        },
        items: [item]
      });
    } else {
      output.push({
        key: {
          id: Number(item.cMeta.categoryId),
          name: item.cMeta.categoryName,
          position: item.cMeta.categoryPosition,
          friendlyName: item.cMeta.categoryFriendlyName
        },
        items: [item]
      });
    }
  }

  static sortDrugsByBrandName(a, b) {
    const sortKey = "description";
    const aValue = a[sortKey];
    const bValue = b[sortKey];
    if (!aValue) { return 1; }
    if (!bValue) { return -1; }
    return (aValue < bValue) ? -1 : (aValue > bValue) ? 1 : 0;
  }
}
@Injectable()
@State<CategoriesStateModel>({
  name: 'categories',
  defaults: {
    scripts: [],
    loading: true,
    showCategories: new Set,
    smallestDoh: 0,
    loadingNotifications: true
  }
})
export class CategoriesState {

  constructor(
    private categoriesService: CategoriesService,
    private store: Store,
    private actions: Actions
  ) { }

  @Selector()
  static showCategory(state: CategoriesStateModel) {
    return (categoryId: number) => !!state.showCategories.has(categoryId);
  }

  @Selector()
  static loading(state: CategoriesStateModel) {
    return state.loading;
  }

  @Selector()
  static all(state: CategoriesStateModel) {
    return state.scripts;
  }

  @Selector()
  static smallestDoh(state: CategoriesStateModel) {
    return state.smallestDoh;
  }

  @Selector()
  static uncategorised(state: CategoriesStateModel) {
    return state.scripts.filter(x => !x.cMeta.categoryId)
    .sort((a, b) => CategoriesStateHelper.sortDrugsByBrandName(a, b));
  }

  @Selector()
  static categorised(state: CategoriesStateModel) {
    const result = state.scripts
      .map(x => {
        if (x.cMeta !== null)
        {
          x.cMeta.categoryId = Number(x.cMeta.categoryId);
        }
        return x;
      })
      .reduce((output, item) => {
        if (item.cMeta === null)
        {
          return output;
        }
        const existingCategory = output.find(a => {
          return a.key && a.key.id === Number(item.cMeta.categoryId);
        });

        const extendedItem = CategoriesStateHelper.extendItem(item);

        if (!existingCategory) {
          CategoriesStateHelper.addNewCategory(output, extendedItem);
        } else {
          existingCategory.items.push(extendedItem);
        }

        return output;
      }, [])
      .sort((a, b) => a.key.position - b.key.position);

    result.forEach(category => {
      category.items.sort((a, b) => CategoriesStateHelper.sortDrugsByBrandName(a, b));
    });

    return result;
  }


  @Selector()
  static scripts(state: CategoriesStateModel) { return state.scripts; }

  static scripts$(store: Store) {
    return store.select(CategoriesState.scripts);
  }
  static categorised$(store: Store) {
    return store.select(CategoriesState.categorised);
  }



  @Action(SetCategoriesAction)
  set(ctx: StateContext<CategoriesStateModel>, action: SetCategoriesAction) {
    let scripts = action.categories;

    if(scripts && scripts.length) {
      scripts.filter(x => x.dispensedDate != null);
      scripts.sort((c1, c2) => {
        const date1 = new Date(c1.dispensedDate);
        const date2 = new Date(c2.dispensedDate);

        if(date1 === date2) return 0;
        if(date1 > date2) return -1;
        if(date1 < date2) return 1;
      });
      const lastVisit = scripts[0].dispensedDate;

      ctx.dispatch(new SetLastVisitDate(lastVisit));
    }

    const uncategorisedDrugs = 0;
    ctx.patchState({
      scripts: action.categories,
      showCategories: new Set([...action.categories
        .filter(category => !!category.cMeta)
        .map(category => category.cMeta.categoryId), uncategorisedDrugs]),
      loading: false
    });
    if (action.categories.length) {
      ctx.patchState({ smallestDoh: this.getSmallestDoh() })
    }
  }

  @Action(RefreshCategoriesAction)
  refresh(ctx: StateContext<CategoriesStateModel>, action: RefreshCategoriesAction) {
    ctx.patchState({ loading: true });
    const profile = this.store.selectSnapshot(ProfileState.profile);
    // ensure profile is onboarded.
    if (profile && profile.customerProfile && profile.customerProfile.type !== null) {
      return this.categoriesService.getClientScripts(action.clientId).pipe(
        mergeMap((scripts) => ctx.dispatch(new SetCategoriesAction(scripts.filter(drug => !!drug).map(drug => this.getDrugLineItem(drug))))),
        catchError((error) => ctx.dispatch(new GetCategoriesErrorAction(error)))
      );
    } else {
      this.actions.pipe(ofActionSuccessful(SetProfileAction), take(1))
      .subscribe(() => {
        // try again if a new profile is loaded. this will happen on Onboarding.
        ctx.dispatch(new RefreshCategoriesAction(action.clientId));
      });
    }
  }

  @Action(ScriptLoadingStartAction)
  startDrugLoading(ctx: StateContext<CategoriesStateModel>, action: ScriptLoadingStartAction) {
    if (action.script.cMeta) {
      ctx.dispatch(new OpenCategoryAction(action.script.cMeta.categoryId));
    }
    this.toggleDrugLoading(ctx, [action.script], DrugLoadingState.LOADING);
  }

  @Action(ScriptsLoadingStartAction)
  startDrugsLoading(ctx: StateContext<CategoriesStateModel>, { scripts }: ScriptsLoadingStartAction) {
    Array.from(new Set(scripts.filter(x => x.cMeta).map(x => x.cMeta.categoryId)))
      .forEach(x => new OpenCategoryAction(x));
    this.toggleDrugLoading(ctx, scripts, DrugLoadingState.LOADING);
  }

  @Action(ScriptLoadingStopAction)
  stopDrugLoading(ctx: StateContext<CategoriesStateModel>, action: ScriptLoadingStopAction) {
    this.toggleDrugLoading(ctx, [action.script], DrugLoadingState.NONE);
  }

  @Action(ScriptsLoadingStopAction)
  stopDrugsLoading(ctx: StateContext<CategoriesStateModel>, { scripts }: ScriptsLoadingStopAction) {
    this.toggleDrugLoading(ctx, scripts, DrugLoadingState.NONE);
  }

  @Action(UpdateScriptAction)
  updateDrug(ctx: StateContext<CategoriesStateModel>, { script }: UpdateScriptAction) {;
    if (script == null)
      return;

    const state = ctx.getState();
    const updatedDrug = this.getDrugLineItem(script);
    const categorised = this.store.selectSnapshot(CategoriesState.categorised);
    ctx.patchState({
      scripts: state.scripts
        .map(drug => drug.compositeKey === updatedDrug.compositeKey
          ? updatedDrug
          : drug)
    });

    ctx.dispatch([
      new OpenCategoryAction(script.cMeta.categoryId),
      new SetMedsListsForSixCpa(categorised),
      new ScriptLoadingStopAction(script)
    ]);
  }

  @Action(SetSelectedDrugsPausedStatus)
  setSelectedDrugsPausedStatus(ctx: StateContext<CategoriesStateModel>, { status }: SetSelectedDrugsPausedStatus) {
    const state = ctx.getState();
    state.scripts.forEach(drug => {
      drug.cMeta.isPaused = status
    });
  }

  @Action(ResetScriptsAction)
  resetDrugs(ctx: StateContext<CategoriesStateModel>, { scripts }: ResetScriptsAction) {
    const state = ctx.getState();
    const compositeKeys = scripts.map(x => x.compositeKey);
    ctx.patchState({
      scripts: state.scripts.map(x =>
        compositeKeys.includes(x.compositeKey) ? { ...x, cMeta: {...x.cMeta, categoryId: null}, loadingState: DrugLoadingState.NONE } : x
      )
    });
    const clientId = this.store.selectSnapshot(ProfileState.clientId);

    if (clientId) {
      ctx.dispatch([new RefreshCategoriesAction(clientId), new ClearSelectedDrugsAction()])
    }
  }

  @Action(OpenCategoryAction)
  openCategory(ctx: StateContext<CategoriesStateModel>, action: OpenCategoryAction) {
    const state = ctx.getState();
    if (!state.showCategories.has(action.categoryId)) {
      state.showCategories.add(action.categoryId);
      ctx.patchState({ showCategories: state.showCategories });
    }
  }

  @Action(ToggleCategoryAction)
  toggleCategory(ctx: StateContext<CategoriesStateModel>, action: ToggleCategoryAction) {
    const state = ctx.getState();
    if (state.showCategories.has(action.categoryId)) {
      state.showCategories.delete(action.categoryId);
    } else {
      state.showCategories.add(action.categoryId);
    }
    ctx.patchState({ showCategories: state.showCategories });
  }

  private getDrugLineItem(drug: Medication): Medication {
    return {
      ...drug,
      loadingState: DrugLoadingState.NONE,
      compositeKey: this.getCompositeKey(drug)
    };
  }

  private toggleDrugLoading(ctx: StateContext<CategoriesStateModel>, drugToToggle: any[], loadingState: DrugLoadingState) {
    const state = ctx.getState();
    const compositeKeys = drugToToggle.map(x => x.compositeKey);
    ctx.patchState({
      scripts: state.scripts
        .map(drug => compositeKeys.includes(drug.compositeKey)
          ? ({ ...drug, loadingState: loadingState })
          : drug)
    });
  }

  private getCompositeKey(drug) {
    return `${drug.clientId}-${drug.genericCategoryId}-${drug.productDispensedId}`;
  }

  private getSmallestDoh() {
    let catsList = [];
    const categories = this.store.selectSnapshot(CategoriesState.categorised)
      .filter(cat => cat.key && cat.key.name.includes('Regular'));

    if (!categories.length) {
      return 0;
    }

    categories.forEach(category => {
      category.items = category.items.filter(cat => cat && cat.stock && cat.stock.totalDoh);

      category.items.forEach(item => {
        catsList.push(item);
      });
    });

    if(catsList) {
      catsList.sort((c1, c2) => c1.stock.totalDoh.value - c2.stock.totalDoh.value);
    }

    return (catsList[0].stock && catsList[0].stock.totalDoh.value >= 0) ?
      catsList[0].stock.totalDoh.value
      : 0;
  }
}
