<div *ngIf="order" class="order-card">
    <div class="d-flex justify-content-between align-items-center">
        <h3>
            Update order status
        </h3>
        <div class="update-section d-flex align-items-center">
            <div class="update-badge"><i class="fal fa-info-circle"></i> Update the order to notify {{profile.patient.firstname}}</div>
            <nz-select
              class="select-status zorro-select-look-enabled"
              #status="ngModel"
              nzPlaceHolder="Select order status"
              required
              [ngClass]="{'zorro-select-invalid': submitted && status.errors?.required}"
              [nzDisabled]="disabled"
              nzSize="large"
              ngDefaultControl
              [nzDropdownMatchSelectWidth]="false"
              [(ngModel)]="orderStatus">
            <ng-container *ngIf="!hasPendingItems()">
                <ng-container *ngIf="order.orderMethod === orderMethods.Pickup">
                    <nz-option [nzValue]="orderStatusEnum.Pending" [nzLabel]="'Pending'"></nz-option>
                    <nz-option
                    *ngIf="!hasOnOrderItemsOrHasTooEarly()"
                    [nzValue]="orderStatusEnum.Ready" [nzLabel]="'Ready to collect'"></nz-option>
                    <nz-option
                    *ngIf="!allItemsAreReady()"
                    [nzValue]="orderStatusEnum.PartiallyReady" [nzLabel]="'Partially ready to collect'"></nz-option>
                    <nz-option [nzValue]="orderStatusEnum.UnableToFill" [nzLabel]="'Unable to fill'"></nz-option>
                    <nz-option *ngIf="!isCreate" [nzValue]="orderStatusEnum.Cancelled" [nzLabel]="'Cancelled'"></nz-option>
                </ng-container>
                <ng-container *ngIf="order.orderMethod === orderMethods.Delivery">
                    <nz-option [nzValue]="orderStatusEnum.Pending" [nzLabel]="'Pending'"></nz-option>
                    <nz-option
                    *ngIf="!hasOnOrderItemsOrHasTooEarly()"
                    [nzValue]="orderStatusEnum.Ready" [nzLabel]="'Scheduled for delivery'"></nz-option>
                    <nz-option
                    *ngIf="!allItemsAreReady()"
                     [nzValue]="orderStatusEnum.PartiallyReady" [nzLabel]="'Partially scheduled for delivery'"></nz-option>
                    <nz-option [nzValue]="orderStatusEnum.UnableToFill" [nzLabel]="'Unable to fill'"></nz-option>
                </ng-container>
                <ng-container *ngIf="order.orderMethod === orderMethods.Undefined">
                    <nz-option [nzValue]="orderStatusEnum.Pending" [nzLabel]="'Pending'"></nz-option>
                    <nz-option
                    *ngIf="!hasOnOrderItemsOrHasTooEarly()"
                    [nzValue]="orderStatusEnum.Ready" [nzLabel]="'Ready'"></nz-option>
                    <nz-option
                    *ngIf="!allItemsAreReady()"
                    [nzValue]="orderStatusEnum.PartiallyReady" [nzLabel]="'Partially ready'"></nz-option>
                    <nz-option [nzValue]="orderStatusEnum.UnableToFill" [nzLabel]="'Unable to fill'"></nz-option>
                </ng-container>
            </ng-container>
            <ng-container *ngIf="hasPendingItems()">
                <nz-option [nzValue]="orderStatusEnum.Pending" [nzLabel]="'Pending'"></nz-option>
            </ng-container>
            </nz-select>
            <button class="update-button button btn__yellow" [disabled]="disabled || status.errors?.required" (click)="handleUpdate()">
                {{isCreate ? 'Create order': 'Update order'}}
            </button>
        </div>
    </div>
</div>
