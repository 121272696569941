import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CustomerOrderRouteComponent } from './customer-order-route.component';

const routes: Routes = [
    {
        path: '', component: CustomerOrderRouteComponent, children: [
            { path: 'customer/order', loadChildren: () => import('~/modules/order/order.module').then(m => m.OrderModule), data: { preload: true } },
        ]
    }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class CustomerOrderRoutingModule {
}
