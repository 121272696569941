import { Component, OnInit, OnDestroy } from '@angular/core';
import { HMRPatientDetailsFormBaseComponent } from '../hmr-patient-details-form.base.component';
import { Subscription } from 'rxjs';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { Store, Actions } from '@ngxs/store';
import { HMRPatientDetailsFormDetailedState } from '../state/hmr-patient-details-form-detailed.state';
import { SixCpaState } from '../../../state/six-cpa.state';

@Component({
    selector: 'app-hmr-patient-details-form-detailed',
    templateUrl: './hmr-patient-details-form-detailed.component.html',
    styleUrls: ['./../hmr-patient-details-form.component.scss']
  })
  export class HMRPatientDetailsFormDetailedComponent extends HMRPatientDetailsFormBaseComponent implements OnInit, OnDestroy {
    private formSubscription: Subscription;
    private readonlySubscription: Subscription;
    constructor(formBuilder: FormBuilder, store: Store, actions: Actions) {
      super(formBuilder, store, actions);
      this.patientDetailsForm.addControl('uniqueIdentifier', new FormControl('', Validators.required));
      this.patientDetailsForm.addControl('residentialPostCode', new FormControl('', [
        Validators.required, Validators.pattern('^(?:(?:[2-8]\\d|9[0-7]|0?[28]|0?9(?=09))(?:\\d{2}))$')]));
        this.patientDetailsForm.addControl('homeSuburb', new FormControl('', Validators.required));
    }

    ngOnInit() {
      this.formSubscription = this.store.select(HMRPatientDetailsFormDetailedState.form)
        .subscribe(() => this.onFormChange());
      this.readonlySubscription = this.store.select(SixCpaState.formsReadOnly)
        .subscribe(readonly => readonly ? this.patientDetailsForm.disable() : this.patientDetailsForm.enable());
    }

    ngOnDestroy() {
      this.formSubscription.unsubscribe();
      this.readonlySubscription.unsubscribe();
    }
  }
