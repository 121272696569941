import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { Store } from "@ngxs/store";
import { RefreshCategoriesAction } from "~/modules/profile/categories/state/categories.actions";

@Injectable()
export class CategoriesResolver implements Resolve<Observable<any>> {
  constructor(private store: Store) { }
  resolve(route: ActivatedRouteSnapshot) {
    const clientId = route.params.clientId;
    return this.store.dispatch([
      new RefreshCategoriesAction(clientId)
    ]);
  }
}