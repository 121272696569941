<ng-container *ngIf="(aslMedications$ | async) as aslmedications">
  <div *ngIf="aslmedications.length > 0" g-xs="pt4">
    <div>
      <div class="category">
        <div class="category__title d-flex align-items-center" g-xs="mb1">
          <h4 (click)="toggleShowAslMedications()" class="flex"><i class="fas fa-caret-up font__title-4" g-xs="mr1"></i>MySL Medications
          </h4>
          <div *ngIf="loading$ | async" class="ml-4"><app-loader></app-loader></div>
          <button (click)="refreshMedications()" *ngIf="!(loading$ | async)" class="btn btn__white ml-4">
            <i class="fas fa-sync mr-2"></i>Refresh Medications
          </button>
        </div>
        <div class="collapse-container">
          <div *ngIf="(showAslMedications$ | async)" [@collapseAnimation]>
            <table cdk-table
             [dataSource]="dataOnPage$"
             class="table category-table">
             <tr class="bg__light-yellow-table" cdk-header-row *cdkHeaderRowDef="displayedColumns$.value"></tr>
             <tr class="cust-col-height" cdk-row *cdkRowDef="let row; columns: displayedColumns$.value"></tr>

             <tr *cdkNoDataRow>
              <ng-container *ngIf="loading$ | async">
                <td>Fetching results...</td>
              </ng-container>
             </tr>

             <ng-container cdkColumnDef="status">
              <th cdk-header-cell *cdkHeaderCellDef>
              </th>
              <td class="status-container"
                cdk-cell *cdkCellDef="let row">
                <i class="status"
                   placement="top" tooltip="{{row.status ? row.status : 'N/A'}}"
                   [ngClass]="medicationStatus[row.status] === medicationStatus.Active ? 'bg__green'
                   : 'bg__red'"
                >
                </i>
              </td>
            </ng-container>
             <ng-container cdkColumnDef="medicationGenericName">
              <th cdk-header-cell *cdkHeaderCellDef (click)="adjustSort('medicationGenericName')">
                Generic Name
                <span *ngIf="sortKey$.value === 'medicationGenericName'">
                  {{ sortDirection$.value === 'asc' ? '↥' : '↧' }}
                </span>
              </th>
              <td class="col-width-10 break-word"
               cdk-cell *cdkCellDef="let row">
                <div>
                  <span *ngIf="row.medicationGenericName">
                    {{row.medicationGenericName}}
                  </span>
                </div>
              </td>
            </ng-container>
            <ng-container cdkColumnDef="medicationBrandName">
              <th cdk-header-cell *cdkHeaderCellDef (click)="adjustSort('medicationBrandName')">
                Brand Name
                <span *ngIf="sortKey$.value === 'medicationBrandName'">
                  {{ sortDirection$.value === 'asc' ? '↥' : '↧' }}
                </span>
              </th>
              <td class="col-width-10 break-word"
               cdk-cell *cdkCellDef="let row">
                <div>
                  <span *ngIf="row.medicationBrandName">
                    {{row.medicationBrandName}}
                  </span>
                </div>
              </td>
            </ng-container>
            <ng-container cdkColumnDef="patientName">
              <th cdk-header-cell *cdkHeaderCellDef (click)="adjustSort('patientName')">
                Patient Name
                <span *ngIf="sortKey$.value === 'patientName'">
                  {{ sortDirection$.value === 'asc' ? '↥' : '↧' }}
                </span>
              </th>
              <td class="col-width-15 break-word"
               cdk-cell *cdkCellDef="let row">
                <div>
                  <span *ngIf="row.patientName">
                    {{row.patientName}}
                  </span>
                </div>
              </td>
            </ng-container>
            <ng-container cdkColumnDef="quantity">
              <th cdk-header-cell *cdkHeaderCellDef (click)="adjustSort('qty')">
                QTY
                <span *ngIf="sortKey$.value === 'qty'">
                  {{ sortDirection$.value === 'asc' ? '↥' : '↧' }}
                </span>
              </th>
              <td class="col-width-2 break-word"
               cdk-cell *cdkCellDef="let row">
                <div>
                  <span *ngIf="row.quantity">
                    {{row.quantity}}
                  </span>
                </div>
              </td>
            </ng-container>
            <ng-container cdkColumnDef="repeatsRemaining">
              <th cdk-header-cell *cdkHeaderCellDef (click)="adjustSort('repeatsRemaining')">
                Repeats Available
                <span *ngIf="sortKey$.value === 'repeatsRemaining'">
                  {{ sortDirection$.value === 'asc' ? '↥' : '↧' }}
                </span>
              </th>
              <td class="col-width-25 break-word"
               cdk-cell *cdkCellDef="let row">
                <div>
                  <span *ngIf="row.repeatsRemaining">
                    {{row.repeatsRemaining}}
                  </span>
                </div>
              </td>
            </ng-container>
            <ng-container cdkColumnDef="prescribedDate">
              <th cdk-header-cell *cdkHeaderCellDef (click)="adjustSort('prescribedDate')">
                Prescribed Date
                <span *ngIf="sortKey$.value === 'prescribedDate'">
                  {{ sortDirection$.value === 'asc' ? '↥' : '↧' }}
                </span>
              </th>
              <td class="col-width-25 break-word"
               cdk-cell *cdkCellDef="let row">
                <div>
                  <span *ngIf="row.prescribedDate">
                    {{ row.prescribedDate | date: 'dd/MM/yyyy' }}
                  </span>
                </div>
              </td>
            </ng-container>
            <ng-container cdkColumnDef="dispensedDate">
              <th cdk-header-cell *cdkHeaderCellDef (click)="adjustSort('dispensedDate')">
                Last Disp
                <span *ngIf="sortKey$.value === 'dispensedDate'">
                  {{ sortDirection$.value === 'asc' ? '↥' : '↧' }}
                </span>
              </th>
              <td class="col-width-25 break-word"
               cdk-cell *cdkCellDef="let row">
                <div>
                  <span>
                    <span *ngIf="row.dispensedDate">{{ row.dispensedDate | date: 'dd/MM/yyyy' }}</span>
                    <span *ngIf="!row.dispensedDate">Never</span>
                  </span>
                </div>
              </td>
            </ng-container>
            <ng-container cdkColumnDef="token">
              <th cdk-header-cell *cdkHeaderCellDef (click)="adjustSort('token')">
                Token
                <span *ngIf="sortKey$.value === 'token'">
                  {{ sortDirection$.value === 'asc' ? '↥' : '↧' }}
                </span>
              </th>
              <td class="col-width-25 break-word"
               cdk-cell *cdkCellDef="let row">
                <div *ngIf="row.token && row.isPaperless">
                  <div class="code mb-2">
                    <qrcode [qrdata]="row.token" [elementType]="'img'" [margin]="0" [width]="44" [errorCorrectionLevel]="'M'"></qrcode>
                  </div>
                  {{row.token}}
                </div>
                <div *ngIf="!row.isPaperless">
                  <span class="far fa-2x fa-file-prescription"></span>&nbsp;Paper Prescription
                </div>
              </td>
            </ng-container>
            <ng-container cdkColumnDef="actions">
              <th cdk-header-cell *cdkHeaderCellDef>
                Actions
              </th>
              <td class="col-width-25 break-word"
               cdk-cell *cdkCellDef="let row;">
                <div *ngIf="row.token">
                  <div *ngIf="dispenseStates[row.index]">
                    Sent to dispense.
                  </div>
                  <div class="actions d-flex" *ngIf="!dispenseStates[row.index]">
                    <div>
                      <button class="button btn__white" (click)="dispenseMedication(row, row.index)" *ngIf="row.token">Send to Queue</button>
                    </div>
                    <div>
                      <button class="button btn__white" (click)="sendSMSRepeat(row)" *ngIf="row.token && row.repeatTokenUri">SMS Repeat</button>
                    </div>
                  </div>
                </div>
              </td>
            </ng-container>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
