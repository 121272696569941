import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AlertService } from '../../../../modules/core/alert/alert.service';
import { BaseApi } from '../../../../shared/core/base-api/base-api';
import { StorageService } from '../../../../shared/core/storage/storage.service';
import { ChangePrimaryContactRequest, PrimaryContactType } from '../models/change-primary-contact-request.model';
 
const BASE_URL = 'api/v1/PrimaryContact';

@Injectable()
export class PrimaryContactService extends BaseApi {
  API = '';

  constructor(
    @Inject('environment') env: any,
    public http: HttpClient,
    public router: Router,
    public storageService: StorageService,
    public alertService: AlertService
  ) {
    super(http, router, storageService, alertService);
    this.API = env.baseApiUrl;
  }

  getSummary(tenantCustomerId: number, email: string, mobile: string) {
    return this.get(`${this.API}/${BASE_URL}/summary?tenantCustomerId=${tenantCustomerId}&email=${email}&mobile=${mobile}`);
  }

  changePrimaryContact(changeRequest: ChangePrimaryContactRequest) {
    return this.post(`${this.API}/${BASE_URL}/change`, changeRequest);
  }

  removePrimaryContact(tenantCustomerId: number, type: PrimaryContactType) {
    return this.delete(`${this.API}/${BASE_URL}/${tenantCustomerId}/type/${type}`);
  }
}
