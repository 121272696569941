import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';

import { BaseApi } from '../core/base-api/base-api';
import { Router } from '@angular/router';
import { StorageService } from '../core/storage/storage.service';
import { EmailType } from '../models/emailType.model';
import { Email } from '../models/email.model';
import { AlertService } from '../../modules/core/alert/alert.service';

@Injectable()
export class EmailService extends BaseApi {
  API = '';

  constructor(
    @Inject('environment') env: any,
    public http: HttpClient,
    public router: Router,
    public storageService: StorageService,
    public alertService: AlertService
  ) {
    super(http, router, storageService, alertService);
    this.API = env.baseApiUrl;
  }
  getCustomEmails(): Observable<EmailType[]> {
    return this.get(`${this.API}/api/GetCustomEmails`);
  }

  getClientEmailHistory(clientId: string): Observable<Email[]> {
    return this.get(`${this.API}/api/GetClientEmailHistory/${clientId}`);
  }

  sendEmail(email: Email) {
    return this.post(`${this.API}/api/SendEmail`, email);
  }
}
