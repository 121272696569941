    <ng-container *ngIf="(addEditTriggerState$ | async) as addEditTriggerState">
      <ng-container *ngIf="addEditTriggerState.showModal">
        <div class="modal">
          <div class="modal-body border__grey position-relative">
            <i (click)="close()" class="fas fa-times float-right pointer"></i>
            <h3>Edit Trigger</h3>
            <div class="mt-3">
              <ng-container>
                <div class="form-help-text" g-xs="mb2"></div>
              </ng-container>
              <form [formGroup]="addEditTriggerForm" ngxsForm="addEditTriggerModal.addEditTriggerModalForm">
                <div class="form-group pb-1">
                  <label class="font__bold">Trigger Name</label>
                  <input type="text" disabled formControlName="name" class="form-control form-control-lg w-100" placeholder="On Patient Signup" required>
                </div>
                <div class="form-group">
                  <label class="font__bold" for="triggerType">Trigger/Event</label>
                  <input
                    class="form-control form-control-lg w-100"
                    disabled
                    ngDefaultControl
                    formControlName="triggerType"
                    name="triggerType"
                    required
                    [value]="getTriggerType(addEditTriggerForm.value.triggerType)"/>
                </div>
                <div class="form-group">
                  <Label class="font__bold">Lead time in days
                      <small>(How many days {{getChronology(addEditTriggerForm.value.triggerType)}}?)</small>
                    </Label><br />
                  <input type="number" class="form-control form-control-lg w-25" min="0" max="90"
                    formControlName="leadTimeInDays" id="leadTime" />
                </div>
                <div class="form-group">
                  <label class="font__bold" for="template">Template</label>
                  <input
                    disabled
                    class="form-control form-control-lg w-100"
                    ngDefaultControl
                    [value]="addEditTriggerState.addEditTriggerModalForm.model.commTemplate.name"
                    name="templateId"
                    required />
                </div>
              </form>
            </div>
            <div g-xs="mt-3">
              <button class="btn btn-primary create-button btn-lg" [disabled]="addEditTriggerForm.status === 'INVALID'" g-xs="mr1" (click)="submit()">Submit</button>
            </div>
          </div>
          <div class="loading__container" *ngIf="addEditTriggerState.loading;">
            <app-loader loadingMessage="Won't be long..."></app-loader>
          </div>
        </div>
        <div class="modal-background"></div>
      </ng-container>
    </ng-container>
