import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HMRAccreditedPharmacistDetailsListItemComponent } from './hmr-accredited-pharmacist-details-list-item.component';
import { HMRAccreditedPharmacistDetailsModule } from '../hmr-accredited-pharmacist-details/hmr-accredited-pharmacist-details.module';
import { SixCpaFormListItemChecklistModule } from '../../six-cpa-form-list-item-checklist/six-cpa-form-list-item-checklist.module';

@NgModule({
  declarations: [HMRAccreditedPharmacistDetailsListItemComponent],
  imports: [
    CommonModule,
    HMRAccreditedPharmacistDetailsModule,
    SixCpaFormListItemChecklistModule
  ],
  exports: [HMRAccreditedPharmacistDetailsListItemComponent]
})
export class HMRAccreditedPharmacistDetailsListItemModule { }
