import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { OrderRequestItemStatus, OrderRequestItemType } from '../../models/order.enums';
import { OrderRequestItem } from '../../models/order-request-item.model';


@Component({
  selector: 'app-add-medication-modal',
  templateUrl: './add-medication-modal.component.html',
  styleUrls: ['./add-medication-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddMedicationModalComponent {
  @Input() isVisible: boolean;
  @Output() isVisibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() profileOrderItems: any[];
  @Output() onAddItem: EventEmitter<OrderRequestItem> = new EventEmitter<OrderRequestItem>();
  @Output() onRemoveItem: EventEmitter<OrderRequestItem> = new EventEmitter<OrderRequestItem>();
  @Input() dateFn = (date) => date;
  @Input() isCreate = false;
  orderItemType = OrderRequestItemType;
  addingItem: OrderRequestItem = null;
  constructor(
    private cd: ChangeDetectorRef
  ) { }

  addItem(item) {
    this.onAddItem.emit({
       ...item,
       quantity: 1,
       orderRequestItemStatus: OrderRequestItemStatus.Pending,
       addedByPharmacist: true
      });
    this.addingItem = item;
    setTimeout(() => {
      this.addingItem = null;
      this.cd.markForCheck();
    }, 3000);
  }

  removeItem(item: OrderRequestItem) {
    this.onRemoveItem.emit(item);
  }

  handleCancel() {
    this.isVisible = false;
    this.isVisibleChange.next(false);
  }

  drugDOH(item: OrderRequestItem) {
    return item && item.customerMedication 
    && item.customerMedication.stock
    && item.customerMedication.stock.totalDoh
    && item.customerMedication.stock.totalDoh.value || 0;
  }

  isAdding(item) {
    return this.addingItem && this.addingItem.stockId == item.stockId;
  }

}
