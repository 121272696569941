<section class="dashboard-desktop">
  <h1 g-xs="mb2">Dashboard</h1>
  <div *ngIf="(isDashboardLoaded$ | async) else loadingDashboard">
    <div class="grid">
      <div class="grid__unit" g-xs="cols:4 pl1 pr1">
        <div g-xs="mb2" class="chart__block chart__pie border rounded px-2">
          <div class="flex chart__title">
            <div class="font__bold">DAA Signups</div>
            <select class="form-control border__grey-light" [(ngModel)]="daaSelected"
              (ngModelChange)="changeGuage('DAA', daaSelected)">
              <option [ngValue]="0">
                1 Day
              </option>
              <option [ngValue]="1">
                7 Days
              </option>
              <option [ngValue]="2">
                1 Month
              </option>
            </select>
          </div>
          <div>
            <div [chart]="daaChart$ | async"></div>
          </div>
        </div>
        <div g-xs="mb2" class="chart__block chart__pie border rounded px-2">
          <div class="flex chart__title">
            <div class="font__bold">SOF Signups</div>
            <select class="form-control border__grey-light" [(ngModel)]="sofSelected"
              (ngModelChange)="changeGuage('SOF', sofSelected)">
              <option [ngValue]="0">
                1 Day
              </option>
              <option [ngValue]="1">
                7 Days
              </option>
              <option [ngValue]="2">
                1 Month
              </option>
            </select>
          </div>
          <div>
            <div [chart]="sofChart$ | async"></div>
          </div>
        </div>
        <div g-xs="mb2" class="chart__block border rounded px-2">
          <div class="flex chart__title">
            <div class="font__bold">Data Cleanliness</div>
            <select class="form-control wide" [(ngModel)]="biometricOption" (change)="cleanlinessCharts()">
              <option [value]="metricsOptions.Today">Today (since 12:01AM)</option>
              <option [value]="metricsOptions.ThisWeek">This Week (since Monday)</option>
              <option [value]="metricsOptions.ThisMonth">This Month (since 1st)</option>
              <option [value]="metricsOptions.ThisYear">This Year (since 1st Jan)</option>
            </select>
          </div>
          <div g-xs="mt4">
            <div [chart]="cleanChart$ | async"></div>
          </div>
        </div>
      </div>
      <div class="grid__unit" g-xs="cols:4 px1">
        <div class="border rounded column-table">
          <div *ngIf="(isCrmLoaded$ | async) else loadingCrm">
            <ng-container *ngIf="(crmTable$ | async) as crmTable">
              <app-column-table title="CRM actions" filterTitle="Filter Actions" totalText="actions"
                [data]="crmTable" (columnClicked)="handleColumnClick($event)" (filter)="handleFilter($event)">
              </app-column-table>
            </ng-container>
            <a routerLink="/crm" g-xs="mx2">View All</a>
          </div>
          <ng-template #loadingCrm>
            <app-loader class="loading"></app-loader>
          </ng-template>
        </div>
      </div>
      <div class="grid__unit" g-xs="cols:4 px1">
        <div class="border rounded column-table">
          <div *ngIf="(isCustomerListLoaded$ | async) else loadingCustomers">
            <ng-container *ngIf="(customerData$ | async) as customerData">
              <app-column-table [customerView]="true" title="Recent Customers" filterTitle="Filter Customers" totalText="customers"
                [data]="customerData" (columnClicked)="handleCustomerColumnClick($event)" (filter)="handleCustomerFilter($event)">
              </app-column-table>
            </ng-container>
            <a routerLink="/recent-customers" g-xs="mx2">View more</a>
          </div>
          <ng-template #loadingCustomers>
            <app-loader class="loading"></app-loader>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
  <ng-template #loadingDashboard>
    <div g-xs="p4 text:center" class="flex">
      <app-loader class="loading"></app-loader>
    </div>
  </ng-template>
</section>

<div class="mobile">
  <div class="d-flex flex-column justify-content-around">
    <div class="d-flex justify-content-center search-banner">
      <form (submit)="navigateSearch()" class="d-flex justify-content-center">
        <span class="px-3 pt-2" (click)="navigateSearch()">
          <i class="far fa-search"></i>
        </span>
        <input (keyup)="setSearch($event)" type="text"
          class="search-box" placeholder="Search Client">
      </form>
    </div>
    <div class="d-flex justify-content-center link-button">
      <div class="button mobile-button btn__yellow analytics__new">
        <a (click)="openEScriptScanner()" class="d-flex justify-content-center align-items-center">
          <span class="analytics-icon">
            <i class="fas fa-qrcode"></i>
          </span>
          <span class="mobile-button-text">Scan eScript</span>
        </a>
      </div>
    </div>
    <div class="d-flex justify-content-center link-button">
      <div class="button mobile-button btn__yellow analytics__new">
        <a routerLink="/analytics-mobile" class="d-flex justify-content-center align-items-center">
          <span class="analytics-icon">
            <i class="fas fa-analytics"></i>
          </span>
          <span class="mobile-button-text">Mobile Analytics</span>
        </a>
      </div>
    </div>
  </div>
</div>
