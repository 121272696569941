<section>
  <div class="col-sm-2">
    <h4 class="mb-2">Select Report</h4>
  </div>
  <div *ngIf="(reports$ | async) as reports" class="col-sm-6">
    <select class="form-control mb-1" (change)="onReportChange($event.target.value)" id="selectedReport"
      name="selectedReport" required #userId="ngModel" [ngModel]="selectedReport">
      <option *ngFor="let item of reports" [value]="item.id">
        {{item.name}}
      </option>
      <option *ngIf="!reports.length" [value]="null">
        No reports found
      </option>
    </select>
  </div>
  <div *ngIf="(loading$ | async) as loading" g-xs="p4 text:center" class="flex">
    <app-loader *ngIf="loading" class="loading"></app-loader>
  </div>
</section>

<div *ngIf="(embededReport$ | async) as embededReport" class="bi-report">
  <ngx-powerbi-component type="report" [id]="embededReport.id" [embedUrl]="embededReport.embedUrl" tokenType="Embed"
    [accessToken]="embededReport.embedToken" on-embedded="onEmbedded($event)">
  </ngx-powerbi-component>
</div>

<div class="col-sm-12">
  <p class="red">Reports blank or not appearing? - <a href="https://intercom.help/scrypt/en/articles/6188337-scrypt-analytics-isn-t-loading-how-to-restart-scrypt-db-service" target="_blank">Click here</a> for steps to resolve</p>
</div>
