import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { FormsModule } from '@angular/forms';
import { ViewEditPausedMedicationsComponent } from './view-edit-paused-medications.component';

@NgModule({
  declarations: [
    ViewEditPausedMedicationsComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    BsDatepickerModule.forRoot()
  ],
  exports: [
    ViewEditPausedMedicationsComponent
  ]
})
export class ViewEditPausedMedicationsModule { }
