import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { OutcomesAndActionsFormState } from '../state/outcomes-and-actions-form.state';
import { Store, Actions } from '@ngxs/store';
import { SetTotalItemsAndCompletedItems } from '../state/outcomes-and-actions-form.actions';
import { FormControlFunctions } from '../../form-control.functions';
import { MedsCheckChildForm } from '../../meds-check-child-form';

import { SixCpaState } from '../../state/six-cpa.state';
const controlsToExclude = ['medsCheckOutcomeOtherDetails', 'pharmacistActionsOtherDetails'];

@Component({
  selector: 'app-outcomes-and-actions-form',
  templateUrl: './outcomes-and-actions-form.component.html',
  styleUrls: ['./outcomes-and-actions-form.component.scss']
})
export class OutcomesAndActionsFormComponent extends MedsCheckChildForm implements OnInit, OnDestroy {
  private readonlySubscription: Subscription;
  private formSubscription: Subscription;
  outcomesAndActionsForm: FormGroup;

  medsCheckOutcomesForFormArr = [
    {key: 'recommendNoChange', value: 'Recommendation of no change in medications'},
    {key: 'recommendIncreaseDose', value: 'Recommendation to increase dose and/or number of medicines'},
    {key: 'recommendDecreaseDose', value: 'Recommendation to decrease dose and/or number of medicines'},
    {key: 'increaseKnowledgeMedicineRegime', value: 'Increase in knowledge about importance of their medicine regime'},
    {key: 'increaseKnowledgeMedicineAdherence', value: 'Increase in knowledge about importance of medicine adherence'},
    {key: 'other', value: 'Other'}
  ];

  actionsTakenByPharamacistForFormArr = [
    {key: 'actionPlanToPatient', value: 'Action plan developed and provided to patient'},
    {key: 'actionPlanToGP', value: 'Action plan developed and provided to patient\'s GP'},
    {key: 'gPVerballyConsulted', value: 'GP verbally consulted about the patient'},
    {key: 'referredToGp', value: 'Referred to GP significant issues identified'},
    {key: 'other', value: 'Other'}
  ];

  constructor(private formBuilder: FormBuilder, private store: Store, private formFunctions: FormControlFunctions, actions: Actions) {
    super(actions);
    this.generateFormControls();
  }

  ngOnInit() {
    this.formSubscription = this.store.select(OutcomesAndActionsFormState.outcomesAndActionsForm)
      .subscribe(() => this.onFormChange());
    this.readonlySubscription = this.store.select(SixCpaState.formsReadOnly)
      .subscribe(readonly => readonly ? this.outcomesAndActionsForm.disable() : this.outcomesAndActionsForm.enable());
  }

  ngOnDestroy() {
    this.formSubscription.unsubscribe();
    this.readonlySubscription.unsubscribe();
  }
  get formControls() {
    return this.outcomesAndActionsForm.controls;
  }
  protected actionOnResetForm() {
    this.outcomesAndActionsForm.reset();
  }

  private onFormChange() {
    this.deselectOtherBoxes();
    const controls = Object.keys(this.outcomesAndActionsForm.controls);
    const completedItems = this.getTotalValidItems(controls) - controlsToExclude.length;
    this.store.dispatch(new SetTotalItemsAndCompletedItems(controls.length - controlsToExclude.length, completedItems));
  }

  private getTotalValidItems(controls: Array<string>) {
    return controls.filter((key) => {
          const formControl = this.outcomesAndActionsForm.get(key);
          return  formControl.status === 'VALID' || (formControl.status === 'DISABLED' && !formControl.invalid && formControl.errors === null);
    }).length;
  }

  private generateFormControls() {
    this.outcomesAndActionsForm = this.formBuilder.group({
      medsCheckOutcome: this.formBuilder.group({}, Validators.required),
      pharmacistActions: this.formBuilder.group({}, Validators.required),
      medsCheckOutcomeOtherDetails: new FormControl(''),
      pharmacistActionsOtherDetails: new FormControl(''),
    });
    this.formFunctions.generateControlsForGroupFromArr('medsCheckOutcome', this.medsCheckOutcomesForFormArr, this.outcomesAndActionsForm);
    this.formFunctions.generateControlsForGroupFromArr('pharmacistActions', this.actionsTakenByPharamacistForFormArr, this.outcomesAndActionsForm);
  }

  private deselectOtherBoxes() {
    if(this.outcomesAndActionsForm.value.medsCheckOutcome.other == false) {
      this.outcomesAndActionsForm.controls['medsCheckOutcomeOtherDetails'].setValue('');
    }
    if(this.outcomesAndActionsForm.value.pharmacistActions.other == false) {
      this.outcomesAndActionsForm.controls['pharmacistActionsOtherDetails'].setValue('');
    }
  }
}
