import { State, Action, StateContext, Selector, Store } from '@ngxs/store';
import {
  OpenOutOfStore,
  CloseOutOfStore,
  OutOfStoreRequestAction,
  OutOfStoreResponseAction,
  OutOfStoreErrorAction,
  AddOutOfStoreItemAction,
  RemoveOutOfStoreItemAction,
  AddOutOfStoreItemActionFromCategory,
  RemovePassiveScript,
  UpdatePassiveScript
} from './out-of-store.actions';
import { mergeMap, catchError, tap, finalize } from 'rxjs/operators';
import { RefreshCategoriesAction } from '../../categories/state/categories.actions';
import { IAdditionalItem, IHasStockId } from '../out-of-store.model';
import { PassiveScriptRepository } from '../../../core/passive-scripts/passive-script.repository';
import { ProfileState } from '../../../core/profile/state/profile.state';
import * as moment_ from 'moment';
const moment = moment_;
import { Injectable } from '@angular/core';
import { CategoriesState } from '../../categories/state/categories.state';

export class OutOfStoreStateModel {
  public showModal: boolean;
  public loading: boolean;
  public error: any;
  public items: IHasStockId[];
  public isFullItem: boolean;
}
@Injectable()
@State<OutOfStoreStateModel>({
  name: 'outOfStore',
  defaults: {
    showModal: false,
    loading: false,
    error: null,
    items: [
    ],
    isFullItem: true
  }
})
export class OutOfStoreState {
  constructor(private store: Store, private passiveScriptRepository: PassiveScriptRepository) { }

  @Selector()
  static showModal(state: OutOfStoreStateModel) {
    return state.showModal;
  }

  @Selector()
  static loading(state: OutOfStoreStateModel) {
    return state.loading;
  }

  @Action(AddOutOfStoreItemAction)
  addOutOfStoreItem(ctx: StateContext<OutOfStoreStateModel>, action: AddOutOfStoreItemAction) {
    const state = ctx.getState();
    ctx.patchState({
      items: [
        ...state.items,
        {
          stockId: action.itemId
        }
      ]
    });
  }

  @Action(AddOutOfStoreItemActionFromCategory)
  addOutOfStoreItemActionFromCategory(ctx: StateContext<OutOfStoreStateModel>, { script, type }: AddOutOfStoreItemActionFromCategory) {
    let items = ctx.getState().items;
    items = [];
    let newItem = script as IAdditionalItem;
    newItem.stockId = script.productDispensedId;
    newItem.passiveScriptType = type;
    newItem.prescriptionDate = script.prescriptionDate;
    newItem.repeats = null;

    this.store.selectSnapshot(CategoriesState.scripts).forEach(scr => {
      if(scr.productDispensedId == script.productDispensedId) {
        newItem.quantity =  scr.quantity;
        newItem.frequency = scr.cMeta.currentDosage.frequency;
      }
    })

    items.push(newItem)
    ctx.patchState({
      items: items
    });
  }

  @Action(RemovePassiveScript)
  removePassiveScript(ctx: StateContext<OutOfStoreStateModel>, { passiveScriptId }: RemovePassiveScript) {
    ctx.patchState({loading: true});
    const tenantCustomerId = this.store.selectSnapshot(ProfileState.profile).customerProfile.tenantCustomerId;
    const clientId = this.store.selectSnapshot(ProfileState.clientId);

    return this.passiveScriptRepository.remove(tenantCustomerId, passiveScriptId).pipe(
      tap(resp => ctx.dispatch(new RefreshCategoriesAction(clientId))),
      catchError(err => ctx.dispatch(new OutOfStoreErrorAction(err))),
      finalize(() => ctx.patchState({loading: false}))
    );
  }

  @Action(RemoveOutOfStoreItemAction)
  removeOutOfStoreItem(ctx: StateContext<OutOfStoreStateModel>, action: RemoveOutOfStoreItemAction) {
    const state = ctx.getState();
    const newItems = state.items.filter(item => item.stockId !== action.itemId);

    return ctx.patchState({
      items: newItems
    });
  }

  @Action(OpenOutOfStore) open(ctx: StateContext<OutOfStoreStateModel>, action: OpenOutOfStore) {
    ctx.patchState({ showModal: true, isFullItem: action.isFullItem });
  }

  @Action(CloseOutOfStore) close(ctx: StateContext<OutOfStoreStateModel>, action: CloseOutOfStore) {
    ctx.patchState({ showModal: false, items : [] });
  }

  @Action(OutOfStoreRequestAction)
  request(ctx: StateContext<OutOfStoreStateModel>, action: OutOfStoreRequestAction) {
    ctx.patchState({ loading: true });

    return this.passiveScriptRepository
      .create(
        action.tenantCustomerId,
        action.items.map(item => ({
          ...item,
          drugName: item.name,
          lastDispenseDate: item.lastDispensedDate,
          originalScriptNo: item.originalScriptNo,
          prescriptionDate: moment(item.prescriptionDate).toDate(),
          passiveScriptType: item.passiveScriptType
        })))
      .pipe(
        mergeMap(() => ctx.dispatch(new OutOfStoreResponseAction())),
        catchError(error => ctx.dispatch(new OutOfStoreErrorAction(error)))
      );
  }

  @Action(OutOfStoreResponseAction)
  handleResponse(ctx: StateContext<OutOfStoreStateModel>) {
    const clientId = this.store.selectSnapshot(ProfileState.clientId);
    ctx.dispatch([new CloseOutOfStore(), new RefreshCategoriesAction(clientId)]);
  }

  @Action(OutOfStoreErrorAction)
  handleError(
    ctx: StateContext<OutOfStoreStateModel>,
    action: OutOfStoreErrorAction
  ) {
    ctx.patchState({ error: action.error });
  }

  @Action([OutOfStoreResponseAction, OutOfStoreErrorAction])
  stopLoading(ctx: StateContext<OutOfStoreStateModel>) {
    ctx.patchState({ loading: false });
  }

  @Action(UpdatePassiveScript)
  updatePassiveScript(ctx: StateContext<OutOfStoreStateModel>, { passiveScriptId, repeats }: UpdatePassiveScript) {
    ctx.patchState({ loading: true });
    return this.passiveScriptRepository.updateRepeats(passiveScriptId, repeats).pipe(
      tap(() => ctx.dispatch(new OutOfStoreResponseAction())),
      catchError(err => ctx.dispatch(new OutOfStoreErrorAction(err)))
    )
  }
}
