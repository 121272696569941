import { Component, OnInit, OnDestroy, InjectionToken, Inject } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { Store, Actions } from '@ngxs/store';
import { PatientDetailsFormBaseComponent } from '../patient-details-form.base.component';
import { Subscription } from 'rxjs';
import { PatientDetailsFormBasicState } from '../state/patient-details-form-basic.state';
import * as moment_ from 'moment';
const moment = moment_;

import { SixCpaState } from '../../state/six-cpa.state';
export const PATIENT_FORM_DEFAULT_MEDSCHECK_DATE: InjectionToken<Date> = new InjectionToken('PATIENT_FORM_DEFAULT_MEDSCHECK_DATE');

@Component({
  selector: 'app-patient-details-form-basic',
  templateUrl: './patient-details-form-basic.component.html',
  styleUrls: ['./../patient-details-form.component.scss'],
  providers: [
    { provide: PATIENT_FORM_DEFAULT_MEDSCHECK_DATE, useValue: moment(new Date()).toDate() }
  ]
})
export class PatientDetailsFormBasicComponent extends PatientDetailsFormBaseComponent implements OnInit, OnDestroy {
  private formSubscription: Subscription;
  private readonlySubscription: Subscription;
  constructor(formBuilder: FormBuilder, store: Store, actions: Actions, @Inject(PATIENT_FORM_DEFAULT_MEDSCHECK_DATE)  defaultMedsCheckDate: Date) {
    super(formBuilder, store, actions);
    this.patientDetailsForm.addControl('dateOfMedsCheck',  new FormControl(defaultMedsCheckDate, Validators.required));
  }

  ngOnInit() {
    this.formSubscription = this.store.select(PatientDetailsFormBasicState.form)
      .subscribe(() => this.onFormChange());
    this.readonlySubscription = this.store.select(SixCpaState.formsReadOnly)
      .subscribe(readonly => readonly ? this.patientDetailsForm.disable() : this.patientDetailsForm.enable());
  }

  ngOnDestroy() {
    this.formSubscription.unsubscribe();
    this.readonlySubscription.unsubscribe();
  }
}
