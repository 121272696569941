import { State, Action, StateContext, Selector } from '@ngxs/store';
import { GetSelectedTenantTimezone, SetSelectedTimezone } from './timezone.actions';
import { catchError, tap } from 'rxjs/operators';
import { EMPTY, Observable } from 'rxjs';
import { TimezoneRepository } from '../repositories/timezone.repository';
import { Injectable } from '@angular/core';
import { Timezones } from '~/shared/constants/timezones';

export const timezoneStateName = 'timezones';

export interface ITimezoneState {
  selected: string;
  isDebugging: boolean;
}

@Injectable()
@State<ITimezoneState>({
  name: timezoneStateName,
  defaults: {
    selected: null,
    isDebugging: false
  }
})
export class TimezoneState {

  @Selector()
  static selectedTimezone(state: ITimezoneState) { return state.selected; }

  @Selector()
  static allTimezones() { return Timezones; }

  @Selector()
  static isDebugging(state: ITimezoneState) { return state.isDebugging; }

  constructor(private timezoneRepository: TimezoneRepository) { }

  @Action(GetSelectedTenantTimezone)
  fetchTimezone(ctx: StateContext<ITimezoneState>): Observable<string> {
    return this.timezoneRepository.getTimezoneId().pipe(
      tap((timezoneId) => {
        ctx.patchState({
          selected: timezoneId
        });
      }),
      catchError(x => {
        console.error(x);
        return EMPTY;
      }),
    );
  }
}
