import { Component, OnInit } from '@angular/core';
import { Alert, AlertType } from '../../../modules/core/alert/alert.model';
import { AlertService } from '../../../modules/core/alert/alert.service';
import { Observable } from 'rxjs';
import { Store } from '@ngxs/store';
import { RemoveAlertAction } from '../../../modules/core/alert/state/alert.actions';


@Component({
    moduleId: module.id,
    selector: 'app-alert',
    templateUrl: './alert.component.html',
    styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit {
    alerts$: Observable<Alert[]>;

    constructor(private store: Store, private alertService: AlertService) { }

    ngOnInit() {
        this.alerts$ = this.alertService.getAlert();
    }

    removeAlert(alert: Alert) {
        return this.store.dispatch(new RemoveAlertAction(alert));
    }

    cssClass(alert: Alert) {
        if (!alert) {
            return;
        }
        // return css class based on alert type
        switch (alert.type) {
            case AlertType.Success:
                return 'alert alert-success';
            case AlertType.Error:
                return 'alert alert-danger';
            case AlertType.Info:
                return 'alert alert-info';
            case AlertType.Warning:
                return 'alert alert-warning';
        }
    }
}
