import { State, Selector, StateContext, Action } from '@ngxs/store';
import { BulkMessage } from '~/shared/models/communication/bulk-message.model';
import { GetBulkMessageHistory, BulkMessageError  } from './bulk-messaging.actions';
import { BulkMessagingService } from '../services/bulk-messaging.service';
import { tap, catchError, finalize } from 'rxjs/operators';
import { Injectable } from '@angular/core';

export class BulkMessagingHistoryStateModel {
  bulkMessageHistory: BulkMessage[];
  loading: boolean;
}
@Injectable()
@State<BulkMessagingHistoryStateModel>({
  name: 'bulkMessagingHistory',
  defaults: {
    bulkMessageHistory: [],
    loading: false,
  }
})
export class BulkMessagingHistoryState {
  @Selector()
  static bulkMessageHistory(state: BulkMessagingHistoryStateModel) { return state.bulkMessageHistory; }

  @Selector()
  static loading(state: BulkMessagingHistoryStateModel) { return state.loading; }

  constructor(private bulkMessagingService: BulkMessagingService) { }

  @Action(GetBulkMessageHistory)
  getBulkMessageHistory({ patchState, dispatch }: StateContext<BulkMessagingHistoryStateModel>, { showRecipients, page, pageSize }: GetBulkMessageHistory) {
    patchState({ loading: true });
    return this.bulkMessagingService.getBulkMessageHistory(showRecipients, page, pageSize ).pipe(
      tap(bulkMessageHistory => patchState({ bulkMessageHistory })),
      catchError(error => dispatch(new BulkMessageError(error))),
      finalize(() => patchState({ loading: false }))
    );
  }
}
