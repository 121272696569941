import { State, Action, StateContext, Selector } from '@ngxs/store';
import {
  RedirectToError,
  RedirectToHome,
  ResetScreeningForm,
  ScreeningError,
  SubmitScreeningForm,
  ValidateFormLinkForScreening,
  ValidateFormLinkForScreeningSuccess,
} from './vaccination.actions';
import { VaccinationService } from '../services/vaccination.service';
import { tap, catchError, finalize } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Injectable, NgZone } from '@angular/core';
import {
  PatientForm,
  ScreeningForm,
  ScreeningFormLink,
  VaccinationError,
  VaccinationErrorType,
  VaccinationRoute,
} from '../types/vaccination';

export class VaccinationStateModel {
  public formLink: ScreeningFormLink;
  public form: ScreeningForm;
  public error: VaccinationError;
  public saving: boolean;
  public validating: boolean;
  public patientForm: {
    model: Partial<PatientForm>;
  };
}
@Injectable()
@State<VaccinationStateModel>({
  name: 'vaccination',
  defaults: {
    formLink: null,
    form: null,
    error: null,
    saving: false,
    validating: false,
    patientForm: {
      model: undefined,
    },
  },
})
export class VaccinationState {
  constructor(
    private vaccinationService: VaccinationService,
    private router: Router,
    private ngZone: NgZone
  ) {}

  @Selector()
  static error(state: VaccinationStateModel) {
    return state.error;
  }

  @Selector()
  static validating(state: VaccinationStateModel) {
    return state.validating;
  }

  @Selector()
  static saving(state: VaccinationStateModel) {
    return state.saving;
  }

  @Selector()
  static state(state: VaccinationStateModel) {
    return state;
  }

  @Selector()
  static formLink(state: VaccinationStateModel) {
    return state.formLink;
  }

  @Selector()
  static pharmacyLogo(state: VaccinationStateModel) {
    return state.formLink?.pharmacyLogo ?? '/assets/img/logo.png';
  }

  @Selector()
  static form(state: VaccinationStateModel): ScreeningForm {
    return state.form;
  }

  @Action(ValidateFormLinkForScreening)
  validateFormLinkForScreening(
    ctx: StateContext<VaccinationStateModel>,
    { linkId }: ValidateFormLinkForScreening
  ) {
    ctx.patchState({ validating: true });
    return this.vaccinationService.getCustomerByLink(linkId).pipe(
      tap((response) =>
        ctx.dispatch(new ValidateFormLinkForScreeningSuccess(response))
      ),
      catchError((err) =>
        ctx.dispatch(
          new ScreeningError({
            type: VaccinationErrorType.InvalidLink,
            error: err,
          })
        )
      ),
      finalize(() => ctx.patchState({ validating: false }))
    );
  }

  @Action(ValidateFormLinkForScreeningSuccess)
  validateFormLinkForScreeningSuccess(
    ctx: StateContext<VaccinationStateModel>,
    { formLink }: ValidateFormLinkForScreeningSuccess
  ) {
    ctx.patchState({
      formLink,
    });
  }

  @Action(SubmitScreeningForm)
  submitScreeningForm(ctx: StateContext<VaccinationStateModel>) {
    var formLink = ctx.getState().formLink;
    var patientForm = ctx.getState().patientForm;
    var form = {
      formLinkId: formLink.id,
      ...patientForm.model,
    } as Partial<ScreeningForm>;
    ctx.patchState({ saving: true, error: null });
    return this.vaccinationService.submitScreeningForm(form).pipe(
      catchError((err) => {
        ctx.dispatch(
          new ScreeningError({
            type: VaccinationErrorType.SubmitFormError,
            error: err.error,
          })
        );
        throw new Error('Error occurred whiling submitting form');
      }),
      finalize(() => ctx.patchState({ saving: false }))
    );
  }

  @Action(ScreeningError)
  screeningError(
    ctx: StateContext<VaccinationStateModel>,
    { error }: ScreeningError
  ) {
    ctx.patchState({
      error,
    });
  }

  @Action(RedirectToError)
  redirectToError(
    ctx: StateContext<VaccinationStateModel>,
    { error }: VaccinationError
  ): void {
    ctx.patchState({
      error,
    });
    this.router.navigate([
      VaccinationRoute.Screening + '/' + VaccinationRoute.Error,
    ]);
  }

  @Action(RedirectToHome)
  redirectToHome(ctx: StateContext<VaccinationStateModel>): void {
    this.router.navigate([
      VaccinationRoute.Screening + '/' + VaccinationRoute.WhoopingCough,
    ]);
  }

  @Action(ResetScreeningForm)
  resetScreeningForm(ctx: StateContext<VaccinationStateModel>): void {
    ctx.patchState({
      error: null,
      saving: false,
      patientForm: {
        model: undefined,
      },
    });
  }
}
