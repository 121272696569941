import { deepClone } from '../objects';

export function merge<T>(arr1: T[], arr2: T[]): T[] {
    return [
        ...arr1,
        ...arr2
    ];
}

export function distinctBy<T>(identity: (val: T) => any): (arr: T[]) => T[] {
    return (arr: T[]) => {
        const dict = toDictionary(identity)(arr);
        return Object.keys(dict)
        .map((key) => dict[key]);
    };
}

export function toDictionary<T>(identity: (val: T) => any): (arr: T[]) => {[key: string]: T} {
    return (arr: T[]) => {
        return arr.reduce((accumulator, currentValue) => ({
            ...accumulator,
            [`${identity(currentValue)}`]: currentValue
        }), {});
    };
 }

 export function toArrayFromDict<T>(dict: {[key: string]: T}) {
    return Object.keys(dict)
    .map((key) => dict[key]);
 }

 export function toDeepMergeSet<T>(identity: (type: T) => string) {
     return (arr1: T[], arr2: T[]) => {
        return distinctBy(identity)(merge(arr1, arr2))
        .map(item => {
            return deepClone(item);
        });
     };
 }

 export function mergeValuesIntoSet<T>(identity: (val: T) => string) {
     return (valueSource: T[]) => {
        return (itemSource: T[]) => {
            const filteredItems = valueSource.filter(item => itemSource.find(x => identity(x) === identity(item)));
            return toDeepMergeSet(identity)(itemSource, filteredItems);
        };
    };
 }
