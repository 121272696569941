import { Actions, ofActionSuccessful, Select } from '@ngxs/store';
import { OnInit, OnDestroy, OnChanges, Injectable } from '@angular/core';
import { Subscription, Observable } from 'rxjs';
import { ResetStagedSupplyFormListAction } from './Staged-Supply/staged-supply/state/staged-supply-form-list.actions';
import { StagedSupplyFormListState } from './Staged-Supply/staged-supply/state/staged-supply-form-list.state';

@Injectable()
export abstract class StagedSupplyChildForm implements OnInit, OnDestroy, OnChanges {
  private onResetFormSubscription: Subscription;

  constructor(
    protected actions$: Actions
  ) {
    this.configureLifecycleHooks();
  }

  @Select(StagedSupplyFormListState.showInvalid) showInvalid$: Observable<boolean>;

  ngOnInit() { }
  ngOnDestroy() { }
  ngOnChanges() { }

  protected abstract actionOnResetForm();

  private init(): void {
    this.onResetFormSubscription = this.getResetFormSubscription();
  }

  private destroy(): void {
    if (this.onResetFormSubscription) {
      this.onResetFormSubscription.unsubscribe();
    }
  }

  private configureLifecycleHooks() {
    const onDestroy = this.ngOnDestroy;
    const onInit = this.ngOnInit;
    this.ngOnInit = () => { this.init(); onInit.apply(this); };
    this.ngOnDestroy = () => { this.destroy(); onDestroy.apply(this); };
  }

  private getResetFormSubscription(): Subscription {
    return this.actions$.pipe(ofActionSuccessful(ResetStagedSupplyFormListAction))
      .subscribe(() => this.actionOnResetForm());
  }
}
