import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BaseApi } from '../core/base-api/base-api';
import { Router } from '@angular/router';
import { StorageService } from '../core/storage/storage.service';
import { Observable } from 'rxjs/Observable';
import { Note } from '../models/crm/note.model';
import { NoteFilter } from '../models/crm/noteFilter.model';
import { NoteType } from '../models/crm/noteType.model';
import { AlertService } from '../../modules/core/alert/alert.service';
import { GroupType } from '../models/crm/groupType.model';
import { NewNote } from '../models/crm/newNote.model';
import { NoteFullInfo } from '../models/crm/noteFullInfo.model';
import { NoteTypeEnum } from '../models/note/note-type.enum';
import { DatesService } from '../core/dates/dates.service';

@Injectable()
export class CrmService extends BaseApi {
  API = '';

  constructor(
    @Inject('environment') env: any,
    public http: HttpClient,
    public router: Router,
    public storageService: StorageService,
    public alertService: AlertService
  ) {
    super(http, router, storageService, alertService);
    this.API = env.baseApiUrl;
  }

  getUnactionedNotes(noteType: string = 'Notification'): Observable<Note[]> {
    return this.get(`${this.API}/api/crm/getUnactionedNotes/${noteType}`);
  }

  getNotesByClientId(clientId: string): Observable<Note[]> {
    return this.get(`${this.API}/api/crm/getNotes/${clientId}`);
  }

  resetOrderCycle(clientId: number) {
    return this.post(`${this.API}/api/client/resetOrderCycle/${clientId}`);
  }

  getFilteredNotes(filters: NoteFilter): Observable<NoteFullInfo> {
    const formFilters = <NoteFilter>{ ...filters };
    if (formFilters.fromDate) {
      formFilters.fromDate = DatesService.ToStartOfLocalDayInUtc(
        formFilters.fromDate
      );
    }
    if (formFilters.toDate) {
      formFilters.toDate = DatesService.ToEndOfLocalDayInUtc(
        formFilters.toDate
      );
    }

    return this.post(`${this.API}/api/crm/notes/filter`, formFilters);
  }

  getISOString(date: Date): any {
    if (!date) {
      return null;
    }
    const dispenedDate = (typeof date === 'string' && new Date(date)) || date;

    return dispenedDate.toISOString();
  }

  getGroups(): Observable<GroupType[]> {
    return this.get(`${this.API}/api/group/`);
  }

  getNoteTypes(): Observable<NoteType[]> {
    return this.get(`${this.API}/api/crm/notes/types`).map(
      (items: NoteType[]) => {
        return items.map((item) => this.includeImagesInNoteTypes(item));
      }
    );
  }

  updateNote(items: Note[]) {
    return this.put(`${this.API}/api/crm/update`, items);
  }

  actionNote(item: any) {
    return this.post(`${this.API}/api/crm/actionNote`, item);
  }

  removeNote(id: number) {
    return this.delete(`${this.API}/api/crm/removeNote/${id}`);
  }

  removeNoteList(ids: number[]) {
    return this.post(`${this.API}/api/crm/notes/remove`, ids);
  }

  completeNoteList(ids: number[]) {
    return this.post(`${this.API}/api/crm/notes/actioned`, ids);
  }

  addNote(item: NewNote) {
    return this.post(`${this.API}/api/crm/postNote`, item);
  }

  checkNotes(): Observable<number> {
    return this.get(`${this.API}/api/crm/notes/check`);
  }

  getOutstandingCount(filters: NoteFilter): Observable<number> {
    const formFilters = <NoteFilter>{ ...filters };
      formFilters.toDate = DatesService.ToEndOfLocalDayInUtc(
        formFilters.toDate
      );
    return this.post(`${this.API}/api/crm/outstanding`,formFilters).map(res=> res.result);
  }

  private includeImagesInNoteTypes(item: NoteType): NoteType {
    switch (item.value) {
      case NoteTypeEnum.general:
        item.img = 'far fa-info-circle';
        break;
      case NoteTypeEnum.dispense:
        item.img = 'fal fa-arrow-up';
        break;
      case NoteTypeEnum.scriptRequest:
        item.img = 'fal fa-arrow-down';
        break;
      case NoteTypeEnum.newDrug:
        item.img = 'fas fa-pills';
        break;
      case NoteTypeEnum.order:
        item.img = 'far fa-shopping-cart';
        break;
      case NoteTypeEnum.awaitingDelivery:
      case NoteTypeEnum.awaiting:
        item.img = 'far fa-truck';
        break;
      case NoteTypeEnum.awaitingPickup:
        item.img = 'far fa-shopping-cart';
        break;
      case NoteTypeEnum.sms:
      case NoteTypeEnum.orderConfirmationSms:
        item.img = 'far fa-comment';
        break;
      case NoteTypeEnum.email:
      case NoteTypeEnum.emailReply:
        item.img = 'fal fa-at';
        break;
      case NoteTypeEnum.phoneCall:
        item.img = 'far fa-phone';
        break;
      case NoteTypeEnum.smsReply:
        item.img = 'fal fa-comments';
        break;
      case NoteTypeEnum.mail:
        item.img = 'far fa-envelope';
        break;
      case NoteTypeEnum.delivery:
        item.img = 'far fa-truck';
        break;
      case NoteTypeEnum.specialOrder:
        item.img = 'far fa-cart-plus';
        break;
      case NoteTypeEnum.highCostItem:
        item.img = 'fas fa-dollar-sign';
        break;
      case NoteTypeEnum.scriptsReady:
        item.img = 'fas fa-pills';
        break;
      case NoteTypeEnum.scheduledSMS:
        item.img = 'fas fa-sms';
        break;
      case NoteTypeEnum.scriptRequest:
        item.img = 'fas fa-scroll';
        break;
      case NoteTypeEnum.sixCpa:
        item.img = 'far fa-file-alt';
        break;
      case NoteTypeEnum.patientNote:
        item.img = 'far fa-sticky-note';
        break;
      case NoteTypeEnum.eScript:
        item.img = 'fas fa-file-prescription';
        break;
      case NoteTypeEnum.ScryptMessage:
        item.img = 'fas fa-comment';
        break;
        case NoteTypeEnum.VaxScreening:
          item.img = 'fas fa-ballot-check';
          break;
        case NoteTypeEnum.NewCustomer:
          item.img = 'fas fa-user-plus';
          break;
      default:
        break;
    }
    return item;
  }
}
