import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { Router } from '@angular/router';
import { StorageService } from '../storage/storage.service';
import { AlertService } from '../../../modules/core/alert/alert.service';

@Injectable()
export class BaseApi {
  constructor(protected http: HttpClient,
    protected router: Router,
    protected storageService: StorageService,
    protected alertService: AlertService) { }

  public get<T>(url: string = '', opts: any = {}): Observable<any> {
    this.configureAuth(opts);
    return this.http.get<T>(url, opts).catch((error: HttpErrorResponse) => this.handleErrorResponse(error));
  }

  public post<T>(url: string = '', data: any = {}, opts: any = {}): Observable<any> {
    this.configureAuth(opts);
    return this.http.post<T>(url, data, opts).catch((error: HttpErrorResponse) => this.handleErrorResponse(error));
  }

  public put(url: string = '', data: any = {}, opts: any = {}): Observable<any> {
    this.configureAuth(opts);
    return this.http.put(url, data, opts).catch((error: HttpErrorResponse) => this.handleErrorResponse(error));
  }

  public patch(url: string = '', data: any = {}, opts: any = {}): Observable<any> {
    this.configureAuth(opts);
    return this.http.patch(url, data, opts).catch((error: HttpErrorResponse) => this.handleErrorResponse(error));
  }

  public delete(url: string = '', opts: any = {}): Observable<any> {
    this.configureAuth(opts);
    return this.http.delete(url, opts).catch((error: HttpErrorResponse) => this.handleErrorResponse(error));
  }

  protected handleErrorResponse(error: HttpErrorResponse) {
    if (error.status === 401) {
      this.isUnauthorized();
    } else {
      this.alertService.error(error.url + ' ' + error.statusText);
      return throwError(error);
    }
  }

  private configureAuth(opts: any) {
    const token = this.storageService.getItem('scrypt_access_token');
    if (token) {
      if (opts.headers == null) {
        opts.headers = new HttpHeaders();
      }
      opts.headers = opts.headers.set('Authorization', `Bearer ${token}`);
    }

  }

  protected isUnauthorized() {
    this.storageService.clearStorage();
    this.router.navigateByUrl('/login');
  }
}
