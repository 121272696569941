import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { NgxAutoScroll } from 'ngx-auto-scroll';
import { Client } from '../../../../shared/models/client.model';
import { Select, Store } from '@ngxs/store';
import { MessageCycleState, MessageCycleStateModel } from './state/message-cycle.state';
import { Observable } from 'rxjs';
import { OpenMessageCycleModal, OpenMessageCycleScheduleModal, RemoveMessageCycle } from './state/message-cycle.actions';
import { IntervalType } from '../../../../shared/models/communication/messageCycleModel';
import { ProfileState } from '../../../core/profile/state/profile.state';

@Component({
  selector: 'app-message-cycle',
  templateUrl: './message-cycle.component.html',
  styleUrls: ['./message-cycle.component.scss']
})
export class MessageCycleComponent implements OnInit {
  @Select(MessageCycleState.showModal) showModal$: Observable<boolean>;
  @Select(MessageCycleState.showSchedule) showSchedule$: Observable<boolean>;
  @Select(MessageCycleState.messageCycles) messageCycles$: Observable<boolean>;
  @Select(ProfileState.profile) profile$: Observable<any>;
  @ViewChild(NgxAutoScroll, { static: false }) ngxAutoScroll: NgxAutoScroll;
  @Input() client: Client;

  messageCycleToOpen: MessageCycleStateModel;

  constructor(private store: Store) { }

  ngOnInit() {
  }

  openModal() {
    this.store.dispatch(new OpenMessageCycleModal());
  }

  removeCycle(messageCycleId: number) {
    this.store.dispatch(new RemoveMessageCycle(messageCycleId));
  }

  openSchedule(messageCycle: MessageCycleStateModel) {
    this.messageCycleToOpen = messageCycle;
    this.store.dispatch(new OpenMessageCycleScheduleModal());
  }

  getIntervalTypeString(intervalType: IntervalType) {
    return `${IntervalType[intervalType]}(s)`
  }
}
