import { Component, OnInit } from '@angular/core';
import { SickLeavePatientDetailsState, SickLeavePatientDetailsStateModel } from '../sick-leave-patient-details/state/sick-leave-patient-details.state';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-sick-leave-patient-details-list-item',
  templateUrl: './sick-leave-patient-details-list-item.component.html',
  styleUrls: ['./sick-leave-patient-details-list-item.component.scss']
})
export class SickLeavePatientDetailsListItemComponent implements OnInit {
  @Select(SickLeavePatientDetailsState) formState$: Observable<SickLeavePatientDetailsStateModel>;

  constructor() { }

  ngOnInit() {
  }

}
