import { OrderRequest } from "@profile/order-requests/models/order-request.model";
import { NzTableSortOrder } from "ng-zorro-antd/table";

export const ColumnTypes = ['Text', 'Date', 'Expand' ,'Empty'] as const;
export type ColumnType = typeof ColumnTypes[number];

export interface ColumnDef<T> {
    id: string, //html id attribute
    name: string,
    type: ColumnType,
    width?: string,
    sortOrder?: NzTableSortOrder,
    sortFn?: (a: T, b: T) => number
}

export function sortByRequestedDate(a: OrderRequest, b: OrderRequest) {
    return new Date(a.requestedDate).getTime() - new Date(b.requestedDate).getTime();
};

export function sortByLastUpdated(a: OrderRequest, b: OrderRequest) {
    return new Date(a.lastUpdated).getTime() - new Date(b.lastUpdated).getTime();
}

export const sortStatus = (a: OrderRequest, b: OrderRequest) => a.status.toString().localeCompare(b.status.toString());

const RowExpandColumn: ColumnDef<OrderRequest> ={
    id: 'rowExpand',
    name: '',
    type: 'Expand',
    width: '40px'
}

const StatusTagColumn: ColumnDef<OrderRequest> = {
    id: 'statusTag',
    name: 'Status',
    type: 'Text',
    width: '120px'
}

const CreatedOnColumn: ColumnDef<OrderRequest> = {
    id: 'createdOn',
    name: 'Created',
    type: 'Date',
    width: '175px',
    sortOrder: 'ascend',
    sortFn: sortByRequestedDate
}

const CompletedCreatedOnColumn: ColumnDef<OrderRequest> = {
    ...CreatedOnColumn,
    sortOrder: null
}

const LastUpdatedColumn: ColumnDef<OrderRequest> = {
    id: 'lastUpdated',
    name: 'Last updated',
    type: 'Date',
    width: '175px',
    sortOrder: 'descend',
    sortFn: sortByLastUpdated
}

const IDColumn: ColumnDef<OrderRequest> = {
    id: 'id',
    name: 'ID',
    type: 'Text',
}

const CustomerNameColumn: ColumnDef<OrderRequest> = {
    id: 'customerName',
    name: 'Customer',
    type: 'Text',
}

const OrderItemsColumn: ColumnDef<OrderRequest> = {
    id: 'items',
    name: 'Items',
    type: 'Text',
    width: '70px'
}

const BrandSubstitutionColumn: ColumnDef<OrderRequest> = {
    id: 'brandSubstitution',
    name: 'Brand Substitution',
    type: 'Text',
    width: '70px'
}

const DeliveryOrderColumn: ColumnDef<OrderRequest> = {
    id: 'delivery',
    name: 'Delivery',
    type: 'Text',
    width: '70px'
}

const OrderNotesColumn: ColumnDef<OrderRequest> = {
    id: 'notes',
    name: 'Notes',
    type: 'Text',
    width: '70px'
}

const DispenseOrderColumn: ColumnDef<OrderRequest> = {
    id: 'dispenseAction',
    name: 'Dispense',
    type: 'Text',
    width: '150px'
}

const StatusSelectorColumn: ColumnDef<OrderRequest> = {
    id: 'statusSelector',
    name: 'Actions',
    type: 'Text',
    width: '300px'
}

const ViewOrderLinkColumn: ColumnDef<OrderRequest> = {
    id: 'viewOrderLink',
    name: '',
    type: 'Empty',
    width: '150px'
}

/**
 * @internal Only to be used within the OrderRequestTableComponent;
 */
export const IncomingOrdersColumns = [
    RowExpandColumn,
    StatusTagColumn,
    CreatedOnColumn,
    IDColumn,
    CustomerNameColumn,
    OrderItemsColumn,
    BrandSubstitutionColumn,
    DeliveryOrderColumn,
    OrderNotesColumn,
    DispenseOrderColumn,
    StatusSelectorColumn,
    ViewOrderLinkColumn
] as const;

/**
 * @internal Only to be used within the OrderRequestTableComponent;
 */
export const CompletedOrdersColumns = [
    RowExpandColumn,
    StatusTagColumn,
    CompletedCreatedOnColumn,
    IDColumn,
    CustomerNameColumn,
    OrderItemsColumn,
    BrandSubstitutionColumn,
    DeliveryOrderColumn,
    OrderNotesColumn,
    LastUpdatedColumn,
    StatusSelectorColumn,
    ViewOrderLinkColumn
] as const;
