import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { OrderRequestItem } from '../../models/order-request-item.model';


@Component({
  selector: 'app-additional-items-modal',
  templateUrl: './additional-items-modal.component.html',
  styleUrls: ['./additional-items-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdditionalItemsModalComponent implements OnInit, OnDestroy {
  @Input() isVisible: boolean;
  @Output() isVisibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() data: OrderRequestItem[];
  @Output() onGetNewData: EventEmitter<String> = new EventEmitter<string>();
  @Output() onAddAdditionalItem: EventEmitter<OrderRequestItem> = new EventEmitter<OrderRequestItem>();
  searchInput = new FormControl();
  formCtrlSub: Subscription;
  @Input() isLoading: boolean;
  constructor(
  ) { }

  ngOnInit() {
    this.formCtrlSub = this.searchInput.valueChanges
    .pipe(
      debounceTime(1000)
    )
    .subscribe(newValue => {
      if (newValue.length > 3) {
        this.onGetNewData.emit(newValue);
      }
    });
  }

  ngOnDestroy(){ 
    this.formCtrlSub.unsubscribe();   
  }

  addItem(item) {
    this.onAddAdditionalItem.emit(item);
  }

  handlePrint() {  
      window.print();
  }

  handleCancel() {
    this.isVisible = false;
    this.isVisibleChange.next(false);
  }

}
