import { Component, OnDestroy, OnInit } from '@angular/core';
import { MedicationListChildForm } from '../medication-list-child-form';
import { Store, Actions, Select } from '@ngxs/store';
import { MedListMedicationProfileState } from './state/med-list-medication-profile.state';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { MedicineCategory, Medicine } from '../../../../../shared/models/six-cpa/medicine.model';
import {
  ToggleMedListMedicationProfileListItem,
  ToggleAllMedListMedicationProfileList,
  ToggleMedListMedicationProfile
} from './state/med-list-medication-profile.actions';
import { SelectMedicationAction, ToggleAddEditMedicationAction } from '../../add-edit-medication/state/add-edit-medication.actions';
import { ToggleSixCpaContentAction } from '../../state/six-cpa.actions';

import { SixCpaState } from '../../state/six-cpa.state';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SetMedsListsForSixCpa } from '../../../../../modules/core/profile/state/profile.actions';
import { CategoriesState } from '../../../../../modules/profile/categories/state/categories.state';
@Component({
  selector: 'app-med-list-medication-profile',
  templateUrl: './med-list-medication-profile.component.html',
  styleUrls: ['./med-list-medication-profile.component.scss']
})
export class MedListMedicationProfileComponent extends MedicationListChildForm implements OnInit, OnDestroy {
  @Select(MedListMedicationProfileState) medListMedicationProfile$: Observable<any>;
  @Select(MedListMedicationProfileState.categorisedMedicines) categorisedMedicines$: Observable<MedicineCategory[]>;
  @Select(MedListMedicationProfileState.selectedMedicineCount) selectedMedicines$: Observable<any>;
  @Select(MedListMedicationProfileState.showMode) showMode$: Observable<string>;
  @Select(SixCpaState.formsReadOnly) readonly$: Observable<boolean>;
  medProfileForm: FormGroup;
  sixCpaSubscription: Subscription;

  constructor(private store: Store, actions: Actions, private fb: FormBuilder) {
    super(actions);
  }

  ngOnInit() {
    this.medProfileForm = this.fb.group({
      allergies: this.fb.control(''),
      primaryDoctor: this.fb.control('')
    });
  }

  ngOnDestroy() {
    if (this.sixCpaSubscription) { this.sixCpaSubscription.unsubscribe() }
  }

  protected actionOnResetForm() {
    this.sixCpaSubscription = CategoriesState.categorised$(this.store)
    .subscribe((results) =>{
      this.reset();
      this.store.dispatch(new SetMedsListsForSixCpa(results));
    }
    );
  }
  
  // This is wired up to the ResetMedicationListFormListAction which is a user triggered hard reset
  private reset() {
    this.medProfileForm.patchValue({
      allergies: '',
      primaryDoctor: ''
    })
  }

  select(item: Medicine) {
    this.store.dispatch(new ToggleMedListMedicationProfileListItem(item));
  }

  selectAll(isSelected: boolean, categoryId?: number) {
    this.store.dispatch(new ToggleAllMedListMedicationProfileList(isSelected, categoryId));
  }

  checkIsSelectedByCategory(medicationsByCategory: Medicine[]) {
    return medicationsByCategory.some(x => !x.isSelected);
  }

  updateMedication(item: Medicine) {
    this.store.dispatch(new SelectMedicationAction(item));
    this.addEditMedication();
  }

  addEditMedication() {
    this.store.dispatch([new ToggleSixCpaContentAction('out'), new ToggleAddEditMedicationAction('in')]);
  }

  modeChanged(mode) {
    this.store.dispatch(new ToggleMedListMedicationProfile(mode));
  }
}
