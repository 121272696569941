import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormControl, Validators, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Store, Actions } from '@ngxs/store';
import { SetTotalItemsAndCompletedItems } from '../state/medication-information.actions';
import { MedicationInformationState } from '../state/medication-information.state';
import { FormControlFunctions } from '../../form-control.functions';
import { MedsCheckChildForm } from '../../meds-check-child-form';
import { HealthConditionsForForm, MedsCheckReasonsForForm } from '../../../../../shared/models/six-cpa/FormKeyValuePairs';
import { WindowService } from '../../../../../shared/services/window.service';
// import { environment } from '../../../../../../environments/environment';

import { SixCpaState } from '../../state/six-cpa.state';
const controlsToExclude = ['medicationHealthConditionsOtherDetails'];

@Component({
  selector: 'app-medication-information-form',
  templateUrl: './medication-information-form.component.html',
  styleUrls: ['./medication-information-form.component.scss']
})
export class MedicationInformationFormComponent extends MedsCheckChildForm implements OnInit, OnDestroy {
  private readonlySubscription: Subscription;
  private formSubscription: Subscription;
  medicationInformationForm: FormGroup;
  healthConditionsForFormArr = HealthConditionsForForm;
  medsCheckReasonsForForm = MedsCheckReasonsForForm;

  constructor(private formBuilder: FormBuilder, private store: Store, private formFunctions: FormControlFunctions, actions: Actions, private windowService: WindowService) {
    super(actions);
    this.generateFormControls();
  }

  ngOnInit() {
    this.formSubscription = this.store.select(MedicationInformationState.medicationInformationForm)
      .subscribe(() => this.onFormChange());
    this.readonlySubscription = this.store.select(SixCpaState.formsReadOnly)
      .subscribe(readonly => readonly ? this.medicationInformationForm.disable() : this.medicationInformationForm.enable());
  }

  ngOnDestroy() {
    this.formSubscription.unsubscribe();
    this.readonlySubscription.unsubscribe();
  }
  get formControls() {
    return this.medicationInformationForm.controls;
  }
  openMedIndexCalculator() {
    // this.windowService.open(environment.medIndexCalculator);
    this.windowService.open('');
  }

  protected actionOnResetForm() {
    this.medicationInformationForm.reset();
  }

  private onFormChange() {
    const controls = Object.keys(this.medicationInformationForm.controls);
    const completedItems = this.getTotalValidItems(controls);
    this.store.dispatch(new SetTotalItemsAndCompletedItems(controls.length - controlsToExclude.length, completedItems - controlsToExclude.length));
  }

  private getTotalValidItems(controls: Array<string>) {
    this.medicationInformationForm.controls['medsCheckServiceReasons'].setErrors(null);
    this.medicationInformationForm.controls['medicationHealthConditions'].setErrors(null);
    return controls.filter((key) => {
      const formControl = this.medicationInformationForm.get(key);
      return  formControl.status === 'VALID' || (formControl.status === 'DISABLED' && !formControl.invalid && formControl.errors === null);
    }).length;
  }

  private generateFormControls() {
    this.medicationInformationForm = this.formBuilder.group({
        prescriptionMedicines: new FormControl(''),
        nonPrescriptionMedicines: new FormControl(''),
      medsCheckServiceReasons: this.formBuilder.group({}),
      medicationHealthConditions: this.formBuilder.group({}),
      medIndexScore: new FormControl('', Validators.required),
      medicationHealthConditionsOtherDetails: new FormControl(''),
    });
    this.formFunctions.generateControlsForGroupFromArr('medicationHealthConditions', this.healthConditionsForFormArr, this.medicationInformationForm);
    this.formFunctions.generateControlsForGroup('medsCheckServiceReasons', this.medsCheckReasonsForForm, this.medicationInformationForm);
  }

  deselectOtherBox() {
    if(this.medicationInformationForm.value.medicationHealthConditions.other == false) {
      this.medicationInformationForm.controls['medicationHealthConditionsOtherDetails'].setValue('');
    }
  }
}
