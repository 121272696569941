import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { CommunicationsPageComponent } from './communications-page.component';
import { CommunicationsResolver } from './resolvers/communications.resolver';
import { CommunicationsStateModule } from './state/communications-state.module';
import { SentMessagesStatusBarComponentModule } from './sent-messages-status-bar/sent-messages-status-bar.module';
import { RouterModule } from '@angular/router';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NgxsModule } from '@ngxs/store';
import { GroupPageState } from '../group-page/state/group-page.state';
import { TimezoneModule } from '~/modules/core/timezone/timezone.module';
import { NzTableModule } from 'ng-zorro-antd/table';
import { LoaderModule } from '~/modules/core/loader/loader.module';
import { ReplyModalComponentModule } from './Components/reply-modal/reply-modal.module';
import { InboxTableModule } from './Components/inbox-table/inbox-table.module';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { BulkMessagingRecipientsModalModule } from '../bulk-messaging-page/bulk-messaging-recipients-modal/bulk-messaging-recipients-modal.module';
import { PhoneNumberPipe } from '~/shared/pipes/phoneNumber.pipe';
import { PipesModule } from '~/shared/pipes/pipes.module';

@NgModule({
  declarations: [
    CommunicationsPageComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SentMessagesStatusBarComponentModule,
    CommunicationsStateModule,
    RouterModule,
    NzBadgeModule,
    NzSelectModule,
    NzDropDownModule,
    NzButtonModule,
    NzCheckboxModule,
    NzEmptyModule,
    NzInputModule,
    NzTableModule,
    NzDatePickerModule,
    NgxsModule.forFeature([GroupPageState]),
    TimezoneModule,
    LoaderModule,
    ReplyModalComponentModule,
    InboxTableModule,
    NzGridModule,
    BulkMessagingRecipientsModalModule,
    PipesModule
  ],
  exports: [
    CommunicationsPageComponent
  ],
  providers: [
    CommunicationsResolver,
    PhoneNumberPipe
  ]
})
export class CommunicationsPageModule { }
