<ng-container *ngIf="(eScriptState$ | async) as eScriptState">
  <ng-container *ngIf="eScriptState.showModal">
    <div class="modal" style="top: 40px;">
      <div *ngIf="tenantCustomerCanDispense; else fredTemplate" class="modal-body border__grey">
        <div class="container">
          <div class="float-right pointer">
            <i class="far fa-times-circle" (click)="closeModal()"></i>
          </div><br>
          <h3>eScripts</h3>
          <div *ngIf="showScanner">
            <form [formGroup]="scannerForm">
              <div class="form-row">
                <select class="form-control mb-1" (change)="toggleCamera()" formControlName="chosenDeviceId">
                  <option *ngIf="!devicesFound.length">Finding cameras...</option>
                  <option *ngFor="let device of devicesFound" [value]="device.deviceId">{{device.label}}</option>
                </select>
              </div>
            </form>
            <zxing-scanner
              [enable]="scannerEnabled"
              [formats]="['QR_CODE', 'EAN_13', 'CODE_128', 'DATA_MATRIX']"
              (scanSuccess)="scanSuccess($event)"
              (camerasFound)="scanDevices($event)"
              [device]="currentDevice">
            </zxing-scanner>
            <button class="btn btn-secondary btn-sm" (click)="closeScanner()"><i class="fas fa-angle-double-left"></i>
              Back</button>
          </div>
          <form *ngIf="!showScanner" [formGroup]="eScriptForm">
            <div class="form-row">
              <div class="form-group col-md-10">
                <label class="input-label" g-xs="mt2 mb1">Script Token</label>
                <button class="btn btn-link" (click)="openScanner()">Scan Code</button>
                <input name="scriptToken"
                    formControlName="scriptToken"
                    type="text"
                    class="form-control border__grey-light"
                    id="scriptToken"
                    />
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-md-6">
                <label class="input-label" g-xs="mt2 mb1">Patient Last Name</label>
                <input name="patient" formControlName="patient" type="text" class="form-control border__grey-light"
                  id="patient" />
              </div>
              <div class="form-group col-md-6">
                <label class="input-label" g-xs="mt2 mb1">Notes</label>
                <input name="notes" formControlName="notes" type="text" class="form-control border__grey-light"
                  id="notes" />
              </div>
            </div>
            <label class="input-label" hidden>Source</label><br />
            <div class="form-row" hidden>
              <div class="form-group col-md-8">
                <app-radio-highlight controlName="etp1"
                  [isHighlighted]="eScriptForm.value.source === scriptSource.ETP1">
                  <input class="form-check-input" type="radio" name="source" id="etp1" formControlName="source"
                    [value]="scriptSource.ETP1">
                  <label class="form-check-label" for="etp1">ETP1</label>
                </app-radio-highlight>
                <app-radio-highlight controlName="etp2"
                  [isHighlighted]="eScriptForm.value.source === scriptSource.ETP2">
                  <input class="form-check-input" type="radio" name="source" id="etp2" formControlName="source"
                    [value]="scriptSource.ETP2" checked>
                  <label class="form-check-label" for="etp2">ETP2</label>
                </app-radio-highlight>
              </div>
            </div>
            <label class="input-label">Defer Script</label><br />
            <div class="form-row">
              <div class="form-group col-md-8">
                <app-radio-highlight controlName="deferNo" [isHighlighted]="!eScriptForm.value.defer">
                  <input class="form-check-input" type="radio" name="defer" id="no" formControlName="defer"
                    [value]="false">
                  <label class="form-check-label" for="no">No</label>
                </app-radio-highlight>
                <app-radio-highlight controlName="deferYes" [isHighlighted]="!!eScriptForm.value.defer">
                  <input class="form-check-input" type="radio" name="defer" id="yes" formControlName="defer"
                    [value]="true" checked>
                  <label class="form-check-label" for="yes">Yes</label>
                </app-radio-highlight>
              </div>
            </div>
            <a class="pointer" style="text-decoration: underline;" g-xs="mt1" (click)="toggleEScriptOptions()">More
              options
              <i *ngIf="eScriptOptions == 'in'" class="fas fa-caret-up"></i>
              <i *ngIf="eScriptOptions == 'out'" class="fas fa-caret-down"></i>
            </a><br />
            <div class="pt-3" [@accordionAnimation]="eScriptOptions">
              <label class="input-label" g-xs="mt1">Script Type</label><br />
              <div class="form-row">
                <div class="form-group col-md-6">
                  <select class="form-control" formControlName="type">
                    <option [value]="eScriptTypes.EScript">eScript</option>
                    <option [value]="eScriptTypes.InsideRepeat">Inside Repeat</option>
                  </select>
                </div>
              </div>
              <div formGroupName="options">
                <label class="input-label" g-xs="mt1">Additional Options</label><br />
                <div class="form-group">
                  <div *ngFor="let option of additionaloptions">
                    <div class="form-check">
                      <label class="form-check-label pl-2">
                        <input class="form-check-input big-checkbox" [formControlName]="option.key" type="checkbox"
                          [value]="option.value">&nbsp; {{option.key}}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <a class="pointer" style="text-decoration: underline;" g-xs="mt1" (click)="toggleEScriptPickup()">Set
                pickup time
                <i *ngIf="eScriptPickup == 'in'" class="fas fa-caret-up"></i>
                <i *ngIf="eScriptPickup == 'out'" class="fas fa-caret-down"></i>
              </a><br />
              <div class="pt-3" [@accordionAnimation]="eScriptPickup">
                <div class="form-row mb1">
                  <div class="form-group col-md-4">
                    <label class="input-label-not-required" g-xs="mt2 mb1">Pickup time</label>
                    <input name="deliverTime" formControlName="pickupTime" type="time"
                      class="form-control border__grey-light" id="deliverTime" />
                  </div>
                  <div class="form-group col-md-3">
                    <label class="input-label-not-required" g-xs="mt2 mb1">Pickup date</label>
                    <input name="deliverDate" type="text" placeholder="dd-mm-yyyy"
                      class="form-control border__grey-light calendar" id="deliverDate" formControlName="pickupDate"
                      [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY' }" [minDate]="minDate" bsDatepicker />
                  </div>
                  <small class="ml-2">NOTE: Leaving this blank will default to Awaiting</small><br />
                </div>
                <div class="form-row mb-2">
                  <div class="ml-2 tags flex" g-xs="mt2">
                    <small>Pickup in: &nbsp;</small>
                    <div *ngFor="let item of tags" (click)="setPickupTime(item.value)">{{item.name}}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-row mt-2">
              <div *ngIf="!eScriptState.dispensing; else dispensing" class="form-group col-md-3">
                <button [disabled]="eScriptForm.status === 'INVALID'" class="form-control"
                  [ngClass]="eScriptForm.status === 'INVALID' ? 'btn btn-light' : 'btn btn-success'"
                  (click)="dispense()">
                  Dispense
                </button>
              </div>
              <ng-template #dispensing>
                <app-loader loadingMessage="Dispensing..."></app-loader>
              </ng-template>
            </div>
          </form>
        </div>
      </div>
      <ng-template #fredTemplate>
        <div class="modal-body border__grey">
          <div class="container">
            <div class="float-right pointer">
              <i class="far fa-times-circle" (click)="closeModal()"></i>
            </div><br>
            <span>This feature is currently unavaliable for FRED customers.</span>
          </div>
        </div>
      </ng-template>
    </div>
    <div class="modal-background"></div>
  </ng-container>
</ng-container>
