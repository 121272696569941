import { Component, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { PauseMedicationState } from '../state/pause-medication.state';
import { Observable } from 'rxjs';
import { PausedMedicationModel, PauseStatus, PauseType, PauseMedicationRequest } from '../../../../../shared/models/pause-medication-request.model';
import { CategoriesState } from '../../state/categories.state';
import { UnPauseBulkMedication } from '../state/pause-medication.actions';

@Component({
  selector: 'app-view-edit-paused-medications',
  templateUrl: './view-edit-paused-medications.component.html',
  styleUrls: ['./view-edit-paused-medications.component.scss']
})
export class ViewEditPausedMedicationsComponent implements OnInit {
  @Select(PauseMedicationState.pausedMedications) pausedMedications$: Observable<PausedMedicationModel[]>;
  bulkResumeRequest: PauseMedicationRequest[] = [];

  constructor(private store: Store) { }

  ngOnInit() {
  }

  getPauseStatus(status: PauseStatus) {
    return PauseStatus[status];
  }

  getPauseType(type: PauseType) {
    return PauseType[type];
  }

  getDrugName(metaId: number) {
    const scripts = this.store.selectSnapshot(CategoriesState.all);
    const drug = scripts.find(drug => drug.cMeta.id == metaId);
    return drug.name;
  }

  resumeAllMedicines() {
    const pauses = this.store.selectSnapshot(PauseMedicationState.pausedMedications);
    pauses.forEach(pause => {
      this.bulkResumeRequest.push(
        {
          metaId: pause.metaId,
          startDate: pause.startDate,
          endDate: new Date(),
          reason: pause.reason,
          pauseType: pause.type,
        }
      )
    });
    this.store.dispatch(new UnPauseBulkMedication(this.bulkResumeRequest));
  }
}
