import { BaseApi } from '~/shared/core/base-api/base-api';
import { environment } from './../../../../../environments/environment';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { StorageService } from '~/shared/core/storage/storage.service';
import { AlertService } from '~/modules/core/alert/alert.service';
import { HttpClient } from '@angular/common/http';
import { Medication } from '~/shared/models/script/chartviewitem.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

const API = environment.baseApiUrl;

@Injectable()
export class CategoriesService extends BaseApi {

  constructor(public http: HttpClient, public router: Router, public storageService: StorageService, public alertService: AlertService) {
    super(http, router, storageService, alertService);
  }
  
  getClientScripts(clientId: string): Observable<Medication[]> {
    return this.get<Medication[]>(`${API}/api/client/${clientId}/scripts`, {observe: "body"}).pipe(
      map(response => {
        return response || [];
      })
    );
  }
}
