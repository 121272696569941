import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MedicationProfileComponent } from './medication-profile.component';
import { MedicationProfileState } from './state/medication-profile.state';
import { NgxsModule } from '@ngxs/store';
import { SixCpaFormListItemChecklistModule } from '../six-cpa-form-list-item-checklist/six-cpa-form-list-item-checklist.module';
import { MedicationProfileListItemModule } from './medication-profile-list-item/medication-profile-list-item.module';

@NgModule({
  declarations: [MedicationProfileComponent],
  imports: [
    CommonModule,
    NgxsModule.forFeature([
      MedicationProfileState
    ]),
    SixCpaFormListItemChecklistModule,
    MedicationProfileListItemModule
  ],
  exports: [
    MedicationProfileComponent
  ]
})
export class MedicationProfileModule { }
