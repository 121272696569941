import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import { Router } from '@angular/router';
import { BaseApi } from '~/shared/core/base-api/base-api';
import { StorageService } from '~/shared/core/storage/storage.service';
import { AlertService } from '~/modules/core/alert/alert.service';
import { environment } from '../../../../environments/environment';
import { CustomerAuth } from './customer-auth';

const API = environment.baseApiUrl;

@Injectable()
export class StockService extends BaseApi {
  constructor(public http: HttpClient, public router: Router, public storageService: StorageService, public alertService: AlertService, private customerAuth: CustomerAuth) {
    super(http, router, storageService, alertService);
  }

  pharmacistSearchStock(query: string) {
    return this.get(`${API}/api/stock?query=${query}`);
  }

  searchStock(query: string) {
    return this.get(this.customerAuth.urlForEncryptedTenantCustomer(`${API}/api/customer/orderrequest/stock-search`) + `&query=${query}`);
  }
}
