import { Component, OnDestroy, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Email } from '~/shared/models/email.model';
import { Client } from '~/shared/models/client.model';
import { Actions, ofActionSuccessful } from '@ngxs/store';
import { MessageTemplate } from '../../state/comms.state';
import { SendEmail } from '../state/email.actions';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-add-email',
  templateUrl: './add-email.component.html',
  styleUrls: ['./add-email.component.scss']
})
export class AddEmailComponent implements OnDestroy {
  @Input() client: Client;
  @Input() templates: MessageTemplate[];
  @Output() emailSentEvent = new EventEmitter<{email: Email, templateId: string}>();
  @ViewChild('f') form: NgForm;

  ngOnDestroy$ = new Subject();

  constructor(private actions$: Actions) { 
    this.actions$.pipe(
      ofActionSuccessful(SendEmail),
      takeUntil(this.ngOnDestroy$)
    ).subscribe(() => {
      this.form.reset();
      this.form.form.patchValue({ message: '', emailTypeId: '0', subject: '' });
    });
  }

  ngOnDestroy() {
    this.ngOnDestroy$.next();
    this.ngOnDestroy$.complete();
  }


  onTemplateChange(form: NgForm) {
    const { templateId } = this.form.form.value;
    const template = this.templates.find(c => c.id === templateId);

    if (!template) {
      return;
    }

    const { message, subject } = template;
    this.form.form.patchValue({ message, subject });
  }

  onSubmit(form: NgForm) {
    const { message, subject, templateId } = this.form.form.value;
    const email = new Email(null, [], message, subject, this.client.emailAddress, null, null, this.client.id);
    this.emailSentEvent.emit({email, templateId});    
  }
}
