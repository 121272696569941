import { ConsentAndEligibilityFormModel } from '../../../../../shared/models/six-cpa/consent-and-eligibility-form.model';

export enum ConsentEligibilityBasicFormActions {
  UPDATE = '[ConsentEligibilityFormBasic] Update Form'
}

export class UpdateConsentEligibilityBasicForm {
  static readonly type = ConsentEligibilityBasicFormActions.UPDATE;
  constructor(public form: Partial<ConsentAndEligibilityFormModel>) { }
}
