import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { LoaderModule } from "~/modules/core/loader/loader.module";
import { TimezoneModule } from "~/modules/core/timezone/timezone.module";
import { TenantInstallReportComponent } from "./tenant-install-report.component";
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { NgSelectModule } from "@ng-select/ng-select";


@NgModule({
  declarations: [
    TenantInstallReportComponent
  ],
  imports: [
    NgSelectModule,
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
    LoaderModule,
    TimezoneModule,
    MatAutocompleteModule
  ],
  exports: [
    TenantInstallReportComponent
  ]
})
export class TenantInstallReportModule { }
