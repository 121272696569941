import { HMRPharmacyInformationFormModel } from '~/shared/models/six-cpa/HMR/hmr-pharmacy-information-form.model';

export enum HMRPharmacyInformationActions {
  SET_VALID = '[HMRPharmacyInformation] set valid',
  UPDATE = '[HMRPharmacyInformation] Update Form',
  UPDATE_PROGRESS = '[HMRPharmacyInformation] Update Progress'
}

export class SetHMRPharmacyInformationValidAction {
  static readonly type = HMRPharmacyInformationActions.SET_VALID;
  constructor(public valid = false) { }
}

export class SetTotalItemsAndCompletedItems {
  static readonly type = HMRPharmacyInformationActions.UPDATE_PROGRESS;
  constructor(public totalItems, public completedItems) { }
}

export class UpdateHMRPharmacyInformation {
  static readonly type = HMRPharmacyInformationActions.UPDATE;
  constructor(public form: Partial<HMRPharmacyInformationFormModel>) { }
}
