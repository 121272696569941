
<ng-container *ngIf="showBlade" >
    <div
        *ngIf="mobile"
        class="backdrop"
        [ngStyle]="{'z-index': zIndex-10}">
    </div>

    <ng-template #bladeContent>
        <div  [ngClass]="{ 'blade-content' : !doubleWidth, 'blade-double-content' : doubleWidth } ">
            <i class="fas fa-times close" (click)="closeBlade()"></i>
            <ng-content></ng-content>
        </div>
    </ng-template>
    <div  *ngIf="!mobile" class="blade" [@slideAnimation] [ngStyle]="{'z-index': zIndex}"
    [ngClass]="{ 'doubleWidth' : doubleWidth, 'mobile': mobile}">
        <ng-container *ngTemplateOutlet="bladeContent"></ng-container>
    </div>
    <div *ngIf="mobile" class="blade" [@slideUpAnimation] [ngStyle]="{'z-index': zIndex, 'top': (100 - mobileHeight) + 'vh', 'height': mobileHeight + 'vh'}"
    [ngClass]="{ 'doubleWidth' : doubleWidth, 'mobile': mobile}">
        <ng-container *ngTemplateOutlet="bladeContent"></ng-container>
    </div>
</ng-container>
