<div class="modal confirm-modal">
  <div class="modal-body border__grey">
    <div>
      <h3 class="p-2">You are submitting a {{getMedsCheckFormType(medsCheckClaimType)}} to the PPA</h3>
      <p class="p-2">You have requested that Scrypt transfer information for the selected record to the Pharmacy
        Programs
        Administrator Portal for {{CPA_Version}} claiming purposes. Please therefore, ensure that all information is correct before
        submitting.</p>
      <p class="p-2">By clicking Submit you acknowledge and agree that:</p>
      <ul class="p-2">
        <li>You are authorised to submit this claim to the Pharmacy Programs Administrator Portal on behalf of the
          pharmacy and in doing so bind the pharmacy to the Pharmacy Programs Administrator General Terms and Conditions
          (General Terms) and the Program Rules, applicable as at the date of the service/s</li>
        <li>You have permission to pass on the details of any pharmacist/s and service recipients included in the claim
          to the Pharmacy Programs Administrator and the Australian Government, as required under the General Terms and
          Program Rules</li>
        <li> To the best of your knowledge, all services included in this claim were supplied in accordance
          with the General Terms and the Program Rules, including those related to patient eligibility</li>
        <li>You have used your best endeavours to ensure that all information provided in the claim is complete and
          correct at the time of submission</li>
        <li>Documentation in relation to this claim is available for audit by the Pharmacy Programs Administrator.</li>
      </ul>
      <div g-xs="mt2">
        <button class="button btn__yellow" g-xs="mr1" (click)="close()">Cancel</button>
        <button class="button btn__green" (click)="submit()">Submit</button>
      </div>
    </div>
  </div>
</div>
<div class="modal-background"></div>
