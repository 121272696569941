import { Resolve } from '@angular/router';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { GetCommTemplatesAction } from '../comm-templates/state/comm-templates.actions';
import { GetPharmacyLocationAction, GetPharmacyCommsDetailsAction } from '../pharmacy-comms-details/state/pharmacy-comms-details.actions';
import { GetTriggers } from '../comms-reminders/state/triggers.actions';

@Injectable()
export class PharmacyCommsResolver implements Resolve<void> {
  constructor(private store: Store) { }
  resolve() {
    return this.store.dispatch([
      new GetPharmacyLocationAction(),
      new GetPharmacyCommsDetailsAction(),
      new GetCommTemplatesAction(),
      new GetTriggers()
    ]);
  }
}
