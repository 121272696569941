import { Component, OnInit, Input } from '@angular/core';
import { MessageCycleModel, IntervalType } from '../../../../../shared/models/communication/messageCycleModel';
import { Store } from '@ngxs/store';
import { CloseMessageCycleScheduleModal } from '../state/message-cycle.actions';

import * as moment_ from 'moment';
const moment = moment_;

@Component({
  selector: 'app-message-cycle-schedule-list',
  templateUrl: './message-cycle-schedule-list.component.html'
})
export class MessageCycleScheduleListComponent implements OnInit {
  @Input() messageCycle: MessageCycleModel;
  messageCycleSchedule: Date[] = [];

  constructor(private store: Store) { }

  ngOnInit() {
    this.messageCycleSchedule = this.getScheduledDates(this.messageCycle);
  }

  closeModal() {
    this.store.dispatch(new CloseMessageCycleScheduleModal());
  }

  formatIntervalType(intervalType: IntervalType) {
    return `${IntervalType[intervalType]}(s)`;
  }

  private getScheduledDates(messageCycle: MessageCycleModel) {
    const startDate = messageCycle.nextScheduledDate;
    let nextDate = startDate;

    this.messageCycleSchedule.push(moment(startDate).toDate());
    for (let i = 0; i < 9; i++) {
      nextDate = this.getNextDate(moment(nextDate).toDate(), messageCycle.intervalValue, messageCycle.intervalType);
      this.messageCycleSchedule.push(nextDate)
    }
    return this.messageCycleSchedule;
  }

  private getNextDate(startDate: Date, intervalValue: number, intervalType: IntervalType) {
    if (intervalType == IntervalType.month) {
      return moment(startDate).add(intervalValue, 'months').toDate();
    } else {
      return moment(startDate).add(this.getIntervalInDays(intervalValue, intervalType), 'days').toDate();
    }
  }

  private getIntervalInDays(intervalValue: number, intervalType: IntervalType) {
    switch (intervalType) {
      case IntervalType.none:
      case IntervalType.day: {
        return intervalValue;
      }
      case IntervalType.week: {
        return intervalValue * 7;
      }
      default: {
        return intervalValue;
      }
    }
  }
}
