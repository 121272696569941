import { Category } from './category.model';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';

import { BaseApi } from '../../../shared/core/base-api/base-api';
import { Router } from '@angular/router';
import { StorageService } from '../../../shared/core/storage/storage.service';
import { HttpClient } from '@angular/common/http';
import { AlertService } from '../../core/alert/alert.service';

@Injectable()
export class CategoryRepository extends BaseApi {
  API = '';

  constructor(
    @Inject('environment') env: any,
    public http: HttpClient,
    public router: Router,
    public storageService: StorageService,
    public alertService: AlertService
  ) {
    super(http, router, storageService, alertService);
    this.API = env.baseApiUrl;
  }

  getCategories(): Observable<Category[]> {
    return this.get(`${this.API}/api/categories`);
  }

  getCategory(id: number): Observable<Category> {
    return this.get(`${this.API}/api/categories/${id}`);
  }

  updateCategory(item: Category): Observable<any> {
    return this.put(`${this.API}/api/categories/${item.id}`, item);
  }

  createCategory(item: Category): Observable<any> {
    return this.post(`${this.API}/api/categories`, item);
  }

  deleteCategory(id: number): Observable<any> {
    return this.post(`${this.API}/api/categories/${id}`);
  }
}
