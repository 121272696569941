<ng-container *ngIf="(selectedTab$ | async) as selectedTab ">
  <div class="heading-container" g-xs="pt4 pl3 pr3 pb2 valign:middle">
    <h1 g-xs="m0 valign:middle" class="blue-header">Orders</h1>
    <!-- TODO: update this with actual help page link -->
    <div><a href="https://intercom.help/scrypt/en/articles/8674955-orders-page" target="_blank">How to use this page?</a></div>
  </div>
  <div class="tabs-bar d-flex justify-content-between align-items-stretch">
    <div class="tabs row d-flex align-items-stretch">
      <ng-container *ngFor="let tabName of tabNames">
        <button
          type="button"
          *ngIf="tabClasses(tabName) as tabClasses"
          [ngClass]="tabClasses"
          (click)="changeSelectedTab(tabName)"
        >
          <nz-badge [nzCount]="badgeCounts[tabName] | async" [nzOverflowCount]="pageSize">
            <span class="tabname">{{tabName}}</span>
          </nz-badge>
        </button>
      </ng-container>
      <div class="filter-cell">
        <app-order-mgmt-tab-status-filter
          *ngIf="(areFiltersAvailable$ | async) && (statusFilterOptions$ | async) as statusFilterOptions"
          [selectedTab]="statusFilterOptions.selectedTab"
          [selectedStatus]="statusFilterOptions.selectedStatusFilter"
          [statusFilters]="statusFilterOptions.statusFilters"
          [loading]="statusFilterOptions.loading"
          (onStatusFilterChange)="changeSelectedStatusFilter($event)"
        ></app-order-mgmt-tab-status-filter>
      </div>
      <div class="filter-cell refresh" (click)="refreshOrders()">
        <span>
          <i class="far fa-redo-alt"></i>
        </span>
      </div>
    </div>
  </div>
  <ng-container *ngIf="(tableData$ | async) as tableData">
    <ng-container [ngSwitch]="selectedTab">
      <ng-container *ngSwitchCase="'Incoming Orders'">
        <app-mgmt-order-request-table
          [orderRequests]="tableData.incomingOrders"
          [isLoading]="tableData.loading"
          [loadingActions]="tableData.loadingActions"
          [selectedTab]="selectedTab"
          [totalOrders]="tableData.total"
        ></app-mgmt-order-request-table>
      </ng-container>
      <ng-container *ngSwitchCase="'Partially Ready Orders'">
        <app-mgmt-order-request-table
          [orderRequests]="tableData.partiallyReadyOrders"
          [isLoading]="tableData.loading"
          [loadingActions]="tableData.loadingActions"
          [selectedTab]="selectedTab"
          [totalOrders]="tableData.total"
        ></app-mgmt-order-request-table>
      </ng-container>
      <ng-container *ngSwitchCase="'Completed Orders'">
        <app-mgmt-order-request-table
          [orderRequests]="tableData.completedOrders"
          [isLoading]="tableData.loading"
          [loadingActions]="tableData.loadingActions"
          [selectedTab]="selectedTab"
          [totalOrders]="tableData.total"
        ></app-mgmt-order-request-table>
      </ng-container>
    </ng-container>
  </ng-container>
  <nz-back-top [nzVisibilityHeight]="800"></nz-back-top>
</ng-container>

