import { State, Action, StateContext, Selector } from '@ngxs/store';
import { SelectDrugAction, UnselectDrugAction, SelectManyDrugsAction, UnselectManyDrugsAction, ToggleDrugSelectedAction, ClearSelectedDrugsAction } from './multiple-drugs.actions';
import { Medication } from '@base/shared/models/script/chartviewitem.model';
import { Injectable } from '@angular/core';

export class MultipleDrugsStateModel {
  public drugs: Set<Medication>;
  public adjustDOH: boolean;
  public createNewInstoreOrder: boolean;
}
@Injectable()
@State<MultipleDrugsStateModel>({
  name: 'multipleDrugs',
  defaults: {
    drugs: new Set<Medication>(),
    adjustDOH: false,
    createNewInstoreOrder: false
  }
})
export class MultipleDrugsState {
  @Selector()

  static selectedDrugsArray(state: MultipleDrugsStateModel) {
    return Array.from(state.drugs);
  }

  static selectDrugsSet(state: MultipleDrugsStateModel) {
    return state.drugs;
  }

  @Action(SelectDrugAction)
  add(ctx: StateContext<MultipleDrugsStateModel>, { drug }: SelectDrugAction) {
    const state = ctx.getState();
    drug.selected = true;
    state.drugs.add(drug);
    ctx.patchState(state);
  }

  @Action(UnselectDrugAction)
  remove(ctx: StateContext<MultipleDrugsStateModel>, action: UnselectDrugAction) {
    const state = ctx.getState();
    action.drug.selected = false;
    state.drugs.delete(action.drug);
    ctx.patchState(state);
  }

  @Action(SelectManyDrugsAction)
  addMany(ctx: StateContext<MultipleDrugsStateModel>, action: SelectManyDrugsAction) {
    action.drugs.forEach((drug) => {
      ctx.dispatch(new SelectDrugAction(drug));
    });
  }

  @Action(UnselectManyDrugsAction)
  removeMany(ctx: StateContext<MultipleDrugsStateModel>, action: UnselectManyDrugsAction) {
    action.drugs.forEach((drug) => {
      ctx.dispatch(new UnselectDrugAction(drug));
    });
  }

  @Action(ToggleDrugSelectedAction)
  toggle(ctx: StateContext<MultipleDrugsStateModel>, action: ToggleDrugSelectedAction) {
    const state = ctx.getState();
    if (state.drugs.has(action.drug)) {
      ctx.dispatch(new UnselectDrugAction(action.drug));
    } else {
      ctx.dispatch(new SelectDrugAction(action.drug));
    }
  }

  @Action(ClearSelectedDrugsAction)
  clear(ctx: StateContext<MultipleDrugsStateModel>) {
    const state = ctx.getState();
    state.drugs.clear();
    ctx.patchState({ drugs: state.drugs });
  }
}
