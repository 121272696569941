import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrderMainComponent } from './order-main.component';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { TabsModule } from 'ngx-bootstrap';
import { NgxsModule } from '@ngxs/store';
import { PipesModule } from '../../../../shared/pipes/pipes.module';
import { ScryptInsightModule } from '../../../../shared/scrypt-insight/scrypt-insight.module';
import { FeaturesState } from '../../../../modules/core/features/features.state';
import { OrderCardModule } from '../components/order-card/order-card.module';
import { OrderEmptyModule } from '../components/order-empty/order-empty.module';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { LoaderModule } from '../../../core/loader/loader.module';
import { OrderRequestState } from '../state/order.state';
import { OrderUpdateStatusModule } from '../components/order-update-status-modal/order-update-status-modal.module';

@NgModule({
  declarations: [
    OrderMainComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    TabsModule,
    RouterModule,
    PipesModule,
    ScryptInsightModule,
    NgxsModule.forFeature([
      OrderRequestState,
      FeaturesState
      ]),
    OrderCardModule,
    OrderEmptyModule,
    NzPaginationModule,
    LoaderModule,
    OrderUpdateStatusModule
  ]
})
export class OrderMainModule { }
