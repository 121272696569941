import { TenantCategory } from '../tenant-category.model';

export class TenantCategoryActions {
  static readonly FETCH = '[TenantCategoryActions] fetch';
  static readonly SET = '[TenantCategoryActions] set';
}

export class FetchTenantCategoriesAction {
  static readonly type = TenantCategoryActions.FETCH;
}

export class SetTenantCategoriesAction {
    static readonly type = TenantCategoryActions.SET;
    constructor(public readonly categories: TenantCategory[]) {}
}
