import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { StorageService } from '../core/storage/storage.service';
import { CrudService } from './crud.service';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import { Group } from '../models/group.model';
import { map } from 'rxjs/operators';
import { AlertService } from '~/modules/core/alert/alert.service';


@Injectable()
export class GroupService extends CrudService {
    constructor(http: HttpClient, router: Router, storage: StorageService, alertService: AlertService) {
        super(http, router, storage, alertService, 'group');
    }

    getGroupList(): Observable<Group[]> {
        return this.getList();
    }

    getGroup(id: string): Observable<Group> {
        return this.getById(id);
    }

    deleteGroup(id: string): Observable<any> {
        return this.remove(id);
    }

    createGroup(group: Group): Observable<Group[]> {
        return this.add(group).pipe(
            map(groups => groups.value)
        );
    }

    updateGroup(group: Group): Observable<Group[]> {
        return this.update(group).pipe(
            map(groups => groups.value)
        );
    }
}
