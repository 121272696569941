import { FormControlFunctions } from '../../form-control.functions';
import { StagedSupplyComponent } from './staged-supply.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import { ReactiveFormsModule } from '@angular/forms';
import { RadioHighlightModule } from '../../../../../shared/components/radio-highlight/radio-highlight.module';
import { NgxsModule } from '@ngxs/store';
import { StagedSupplyFormListState } from './state/staged-supply-form-list.state';
import { SSPatientFormListItemModule } from '../ss-patient-details-list-item/ss-patient-details-list-item.module';
import { SSPatientDetailsStateModule } from '../patient-details-form/ss-patient-details-state.module';
import { SSConsentEligibilityStateModule } from '../consent-eligibility-form/ss-consent-eligibility-state.module';
import { SSConsentEligibilityFormListItemModule } from '../ss-consent-eligibility-form-list-item/ss-consent-eligibility-form-list-item.module';
import { StagedSupplyMedicationProfileModule } from '../staged-supply-medication-profile/staged-supply-medication-profile.module';
import { StagedSupplyMedicationProfileState } from '../staged-supply-medication-profile/state/staged-supply-medication-profile.state';
import { SSOutcomesAndActionsFormListItemModule } from '../ss-outcomes-and-actions-form-list-item/ss-outcomes-and-actions-form-list-item.module';
import { SSOutcomesAndActionsFormState } from '../ss-outcomes-and-actions-form/state/ss-outcomes-and-actions-form.state';
import { SSAdditionalDetailsFormState } from '../ss-aditional-details-form/state/ss-additional-details-form.state';
import { SSAdditionalDetailsFormListItemModule } from '../ss-aditional-details-form-list-item/ss-additional-details-form-list-item.module';
import { SSMedicationInformationFormListItemModule } from '../ss-medication-information-form-list-item/ss-medication-information-form-list-item.module';
import { SSMedicationInformationFormState } from '../ss-medication-information-form/state/ss-medication-information-form.state';
import { StagedSupplyFormsService } from './state/staged-supply-forms.service';
import { StagedSupplyService } from './services/staged-supply.service';
import { ConfirmResetModule } from '../../../../../shared/components/confirm-reset/confirm-reset.module';
import { LoaderModule } from '../../../../core/loader/loader.module';

@NgModule({
  declarations: [
    StagedSupplyComponent,
  ],
  imports: [
    CommonModule,
    NgxsFormPluginModule,
    ReactiveFormsModule,
    RadioHighlightModule,
    NgxsModule.forFeature([
      StagedSupplyFormListState,
      StagedSupplyMedicationProfileState,
      SSOutcomesAndActionsFormState,
      SSAdditionalDetailsFormState,
      SSMedicationInformationFormState
    ]),
    SSPatientFormListItemModule,
    SSPatientDetailsStateModule,
    SSConsentEligibilityStateModule,
    SSConsentEligibilityFormListItemModule,
    StagedSupplyMedicationProfileModule,
    SSOutcomesAndActionsFormListItemModule,
    SSAdditionalDetailsFormListItemModule,
    SSMedicationInformationFormListItemModule,
    SSAdditionalDetailsFormListItemModule,
    ConfirmResetModule,
    LoaderModule
  ],
  exports: [
    StagedSupplyComponent
  ],
  providers: [
    FormControlFunctions,
    StagedSupplyFormsService,
    StagedSupplyService
  ]
})
export class StagedSupplyModule { }
