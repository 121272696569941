import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Store } from '@ngxs/store';
import { GetPharmacists } from '~/modules/pharmacy/state/pharmacist.actions';

@Injectable()
export class PharmacistsResolver implements Resolve<Observable<any>> {
  constructor(private store: Store) { }
  resolve(route: ActivatedRouteSnapshot) {
    return this.store.dispatch([new GetPharmacists()]);
  }
}