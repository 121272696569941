export interface SSOutcomesAndActionsFormModel {
  patientRatingOfServicePreventingMedicineProblems?: number | null;
  stagedSupplyPharmacistActions?: StagedSupplyPharmacistActions;
  patientRatingOfServiceInMagaingMedicines?: number | null;
}

export enum StagedSupplyPharmacistActions {
  serviceAgreementDevelopedAndExecutedByPharmacistAndPatient,
  planDevelopedAndProvidedToPatient,
  planDevelopedAndProvidedToGP,
  planOfCommunicationsWithPrescriber,
  otherHealthcareProfessionsAndOrThePatient,
  other
}

export const StagedSupplyPharmacistActionsKeyValues = [
  {key: 'serviceAgreementDevelopedAndExecutedByPharmacistAndPatient', value: 'Staged Supply service agreement developed and executed by both pharmacist and patient'},
  {key: 'planDevelopedAndProvidedToPatient', value: 'Staged Supply plan/record developed and provided to patient'},
  {key: 'planDevelopedAndProvidedToGP', value: 'Staged Supply plan/record developed and provided to GP'},
  {key: 'planOfCommunicationsWithPrescriber', value: 'Staged Supply plan/record of communications with prescriber'},
  {key: 'otherHealthcareProfessionsAndOrThePatient', value: 'Other healthcare professions and/or the patient'},
  {key: 'other', value: 'Other'}
];