import { State, Action, StateContext, Selector } from '@ngxs/store';
import { SetTotalItemsAndCompletedItems, SetPharmacyInformationFormValidAction, UpdatePharmacyInformationForm } from './pharmacy-information-form.actions';
import { Injectable } from '@angular/core';

export class PharmacyInformationFormStateModel {
  pharmacyInformationForm: {
    model: Partial<IPharmacyInformationFormModel>;
  }
  valid: boolean;
  totalItems: number;
  completedItems: number;
  errors: any;
}

export interface IPharmacyInformationFormModel {
  pharmacistName: string;
  ahpraNumber: string;
}
@Injectable()
@State<PharmacyInformationFormStateModel>({
  name: 'pharmacyInformationForm',
  defaults: {
    pharmacyInformationForm: {
      model: undefined
    },
    valid: false,
    completedItems: 0,
    totalItems: 0,
    errors: null
  }
})
export class PharmacyInformationFormState {

  @Selector()
  static pharmacyInformationForm(state: PharmacyInformationFormStateModel) { return state.pharmacyInformationForm.model; }

  @Selector()
  static totalItems(state: PharmacyInformationFormStateModel) { return state.totalItems; }

  @Selector()
  static completedItems(state: PharmacyInformationFormStateModel) { return state.completedItems; }

  @Action(SetPharmacyInformationFormValidAction)
  setValid(ctx: StateContext<PharmacyInformationFormStateModel>, action: SetPharmacyInformationFormValidAction) {
    ctx.patchState({
      valid: action.valid
    });
  }

  @Action(SetTotalItemsAndCompletedItems)
  setProgress(ctx: StateContext<PharmacyInformationFormStateModel>, action: SetTotalItemsAndCompletedItems) {
    ctx.patchState({
      totalItems: action.totalItems,
      completedItems: action.completedItems
    });
  }

  @Action(UpdatePharmacyInformationForm)
  updateForm(ctx: StateContext<PharmacyInformationFormStateModel>, { form }: UpdatePharmacyInformationForm) {
    ctx.patchState({
      pharmacyInformationForm: {
        model: {
          ahpraNumber: form.ahpraNumber,
          pharmacistName: form.pharmacist
        }
      }
    });
  }
}
