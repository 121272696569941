<form [formGroup]="consentEligibilityForm" ngxsForm="ssConsentEligibilityFormBasic.consentEligibilityForm" class="p-4">
  <div class="form-group pb-1">
    <label class="input-label mb-0">Written patient consent for service provision?</label>
    <a class="d-block" (click)="openStagedSupplyConsentForm()">
      Click here to view to patient consent form.</a>
    <div>
      <app-radio-highlight controlName="consentYes"
        [hasError]="formControls.writtenConsentForServiceProvision.invalid && (showInvalid$ | async)"
        [isHighlighted]="!!consentEligibilityForm.value.writtenConsentForServiceProvision">
        <input class="form-check-input" type="radio" name="writtenConsentForServiceProvision" id="consentYes"
          formControlName="writtenConsentForServiceProvision" [value]="true">
        <label class="form-check-label" for="consentYes">Yes</label>
      </app-radio-highlight>
      <app-radio-highlight controlName="consentNo"
        [hasError]="formControls.writtenConsentForServiceProvision.invalid && (showInvalid$ | async)"
        [isHighlighted]="!consentEligibilityForm.value.writtenConsentForServiceProvision">
        <input class="form-check-input" type="radio" name="writtenConsentForServiceProvision" id="consentNo"
          formControlName="writtenConsentForServiceProvision" [value]="false" checked>
        <label class="form-check-label" for="consentNo">No</label>
      </app-radio-highlight>
    </div>
  </div>
  <div class="form-group pb-1">
    <label class="input-label mb-0">Does the patient have a government issued concession card?</label>
    <div>
      <app-radio-highlight controlName="concessionYes"
        [hasError]="formControls.governmentIssuedConcessionCard.invalid && (showInvalid$ | async)"
        [isHighlighted]="!!consentEligibilityForm.value.governmentIssuedConcessionCard">
        <input class="form-check-input" type="radio" name="governmentIssuedConcessionCard" id="concessionYes"
          formControlName="governmentIssuedConcessionCard" [value]="true">
        <label class="form-check-label" for="concessionYes">Yes</label>
      </app-radio-highlight>
      <app-radio-highlight controlName="concessionNo"
        [hasError]="formControls.governmentIssuedConcessionCard.invalid && (showInvalid$ | async)"
        [isHighlighted]="!consentEligibilityForm.value.governmentIssuedConcessionCard">
        <input class="form-check-input" type="radio" name="governmentIssuedConcessionCard" id="concessionNo"
          formControlName="governmentIssuedConcessionCard" [value]="false" checked>
        <label class="form-check-label" for="concessionNo">No</label>
      </app-radio-highlight>
    </div>
  </div>
  <div class="form-group pb-1">
    <label for="pbsCodeOfTheMedicationSupplied" class="input-label">PBS code of the Medication Supplied</label>
    <input type="text" [class.has-error]="formControls.pbsCodeOfTheMedicationSupplied.invalid && (showInvalid$ | async)"
      class="form-control form-control-lg w-25" name="pbsCodeOfTheMedicationSupplied"
      formControlName="pbsCodeOfTheMedicationSupplied" id="pbsCodeOfTheMedicationSupplied" placeholder="123456789"
      required>
  </div>
  <div class="form-group pb-1">
    <label for="dateStagedSupplyServiceCommenced" class="input-label">Date commenced Staged Supply Service</label>
    <input type="text"
      [class.has-error]="formControls.dateStagedSupplyServiceCommenced.invalid && (showInvalid$ | async)"
      class="form-control form-control-lg w-25" formControlName="dateStagedSupplyServiceCommenced"
      id="dateStagedSupplyServiceCommenced" [bsValue]="defaultDate | date: 'DD-MM-YYYY'"
      [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY', adaptivePosition: true }" placeholder="dd-mm-yyyy" bsDatepicker>
  </div>
</form>