import { Component, OnInit, OnDestroy } from '@angular/core';
import { HMRProposedDetailsFormBaseComponent } from '../hmr-proposed-details-form.base.component';
import { Subscription } from 'rxjs';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { Store, Actions } from '@ngxs/store';
import { HMRProposedDetailsFormBasicState } from '../state/hmr-proposed-details-form-basic.state';
import { SixCpaState } from '../../../state/six-cpa.state';

@Component({
    selector: 'app-hmr-proposed-details-form-basic',
    templateUrl: './hmr-proposed-details-form-basic.component.html',
    styleUrls: ['./../hmr-proposed-details-form.component.scss'],
  })
  export class HMRProposedDetailsFormBasicComponent extends HMRProposedDetailsFormBaseComponent implements OnInit, OnDestroy {
    private formSubscription: Subscription;
    private readonlySubscription: Subscription;
    constructor(formBuilder: FormBuilder, store: Store, actions: Actions) {
      super(formBuilder, store, actions);
      this.proposedDetailsForm.addControl('termsAndConditions', new FormControl(null, Validators.required));
     }

    ngOnInit() {
      this.formSubscription = this.store.select(HMRProposedDetailsFormBasicState.form)
        .subscribe(() => this.onFormChange());
        this.readonlySubscription = this.store.select(SixCpaState.formsReadOnly)
      .subscribe(readonly => readonly ? this.proposedDetailsForm.disable() : this.proposedDetailsForm.enable());
    }

    ngOnDestroy() {
      this.formSubscription.unsubscribe();
      this.readonlySubscription.unsubscribe();
    }
  }
