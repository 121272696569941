import { NgModule } from '@angular/core';
import { NzTableModule } from 'ng-zorro-antd/table';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { InvoicesPageComponent } from './invoices-page.component';
import { InvoicesPageService } from './services/invoices-page.service';
import { InvoicesPageSateModule } from './state/invoices-page-state.module';
import { AlertService } from '../core/alert/alert.service';
import { StorageService } from '../../shared/core/storage/storage.service';
import { LoaderModule } from '../../modules/core/loader/loader.module';
import { TenantTimezonePipe } from '../../modules/core/timezone/pipes/tenantTimezone.pipe';
import { TimezoneModule } from '../../modules/core/timezone/timezone.module';

@NgModule({
  declarations: [
    InvoicesPageComponent
  ],
  imports: [
    LoaderModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    InvoicesPageSateModule,
    NzTableModule,
    TimezoneModule,
  ],
  exports: [
    InvoicesPageComponent
  ],
  providers: [
    InvoicesPageService,
    StorageService,
    AlertService,
    TenantTimezonePipe,
  ]
})
export class InvoicesPageModule { }
