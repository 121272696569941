import { SickLeavePharmacistDeclarationFormModel } from '../../../../../../shared/models/six-cpa/sick-leave/sick-leave-pharmacist-declaration-form.model';

export enum SickLeavePharmacistDeclarationActions {
  SET_VALID = '[SickLeavePharmacistDeclaration] set valid',
  UPDATE = '[SickLeavePharmacistDeclaration] Update Form',
  UPDATE_PROGRESS = '[SickLeavePharmacistDeclaration] Update Progress'
}

export class SetSickLeavePharmacistDeclarationValidAction {
  static readonly type = SickLeavePharmacistDeclarationActions.SET_VALID;
  constructor(public valid = false) { }
}

export class SetTotalItemsAndCompletedItems {
  static readonly type = SickLeavePharmacistDeclarationActions.UPDATE_PROGRESS;
  constructor(public totalItems, public completedItems) { }
}

export class UpdateSickLeavePharmacistDeclaration {
  static readonly type = SickLeavePharmacistDeclarationActions.UPDATE;
  constructor(public form: Partial<SickLeavePharmacistDeclarationFormModel>) { }
}
