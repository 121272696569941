import { Selector } from '@ngxs/store';
import { ClinicalInterventionState, ClinicalInterventionStateModel } from './clinical-intervention.state';
import {
  ClassificationAndRecommendationsState,
  IClassificationAndRecommendationsStateModel
} from '../../classification-and-recommendations/state/classification-and-recommendations.state';
import { AdditionalDetailsState, IAdditionalDetailsStateModel } from '../../additional-details/state/additional-details.state';
import { MedicationProfileState } from '../../medication-profile/state/medication-profile.state';
import { Medicine } from '../../../../../shared/models/six-cpa/medicine.model';
import { ClinicalInterventionFormModel } from '../../../../../shared/models/six-cpa/clinical-intervention-form.model';


export class ClinicalInterventionFormsService {

  @Selector([
    AdditionalDetailsState.totalItems,
    AdditionalDetailsState.completedItems,
    ClassificationAndRecommendationsState.totalItems,
    ClassificationAndRecommendationsState.completedItems,
    MedicationProfileState.selectedMedicineCount
  ])
  static getProgress(
    additionalDetailsTotal,
    additionalDetailsCompleted,
    classificationAndRecommendationsTotal,
    classificationAndRecommendationsCompleted,
    selectedMedicines
    ) {
     return Math.floor((additionalDetailsCompleted + classificationAndRecommendationsCompleted + (selectedMedicines > 0 ? 1 : 0)) /
         (additionalDetailsTotal + classificationAndRecommendationsTotal + 1) * 100);
  }

  @Selector([
    ClinicalInterventionState,
    ClassificationAndRecommendationsState.classificationAndRecommendationsForm,
    AdditionalDetailsState.additionalDetailsForm,
    MedicationProfileState.selectedMedicines
  ])
  static getClinicalInterventionRequest(
    clinicalInterventionstate: ClinicalInterventionStateModel,
    classificationAndRecommendations: IClassificationAndRecommendationsStateModel,
    additionalDetails: IAdditionalDetailsStateModel,
    selectedMedicines: Medicine[]): ClinicalInterventionFormModel {
    const classificationAndRecommendationsForm =
    this.getClassificationAndRecommendationsData(clinicalInterventionstate.classificationAndRecommendationsFormId, classificationAndRecommendations);
    const additionalDetailsForm =
    this.getAdditionalDetailsData(clinicalInterventionstate.additionalDetailsFormId, additionalDetails);
    const sixCpaMedications = this.getSixCpaMedications(selectedMedicines);

    return {
      id: clinicalInterventionstate.clinicalInterventionId,
      sixCpaFormId: clinicalInterventionstate.sixCpaFormId,
      classificationAndRecommendationsFormId: clinicalInterventionstate.classificationAndRecommendationsFormId,
      additionalDetailsFormId: clinicalInterventionstate.additionalDetailsFormId,
      classificationAndRecommendationsForm,
      additionalDetailsForm,
      sixCpaMedications
    };
  }


  private static getClassificationAndRecommendationsData(id: number, classificationAndRecommendations: IClassificationAndRecommendationsStateModel) {
    return {
      id,
      recomendations: {
        doseIncrease: classificationAndRecommendations.recommendations.doseIncrease,
        doseDecrease: classificationAndRecommendations.recommendations.doseDecrease,
        drugChange: classificationAndRecommendations.recommendations.drugChange,
        drugFormulationChange: classificationAndRecommendations.recommendations.drugFormulationChange,
        drugBrandChange: classificationAndRecommendations.recommendations.drugBrandChange,
        doseFrequencyScheduleChange: classificationAndRecommendations.recommendations.doseFrequencyScheduleChange,
        prescriptionNotDispensed: classificationAndRecommendations.recommendations.prescriptionNotDispensed,
        otherChangesToTherapy: classificationAndRecommendations.recommendations.otherChangesToTherapy,
        referToPrescriber: classificationAndRecommendations.recommendations.referToPrescriber,
        referToHospital: classificationAndRecommendations.recommendations.referToHospital,
        referForMedicationReview: classificationAndRecommendations.recommendations.referForMedicationReview,
        otherReferralRequired: classificationAndRecommendations.recommendations.otherReferralRequired,
        educationOrCounsellingSession: classificationAndRecommendations.recommendations.educationOrCounsellingSession,
        writtenSummaryOfMedications: classificationAndRecommendations.recommendations.writtenSummaryOfMedications,
        commenceDoseAdministrationAid: classificationAndRecommendations.recommendations.commenceDoseAdministrationAid,
        otherWrittenInformation: classificationAndRecommendations.recommendations.otherWrittenInformation,
        monitoringLaboratory: classificationAndRecommendations.recommendations.monitoringLaboratory,
        monitoringNonLaboratory: classificationAndRecommendations.recommendations.monitoringNonLaboratory,
        noRecommendationNecessary: classificationAndRecommendations.recommendations.noRecommendationNecessary,
      },
      interventionClassification: classificationAndRecommendations.interventionClassification,
      interventionCategory: classificationAndRecommendations.interventionCategory,
      clinicalRecommendationsNotes: classificationAndRecommendations.clinicalRecommendationsNotes,
    };
  }


  private static getAdditionalDetailsData(id: number, additionalDetails: IAdditionalDetailsStateModel) {
    return {
      id,
      relevantMedicalConditions: additionalDetails.relevantMedicalConditions,
      clinicalInterventions: additionalDetails.clinicalInterventions,
      result: additionalDetails.result,
    };
  }

  private static getSixCpaMedications(medicines: Medicine[]) {
    return medicines
      .map(m => ({
        id: m.id,
        productDispensedId: m.productDispensedId,
        brandName: m.brandName,
        genericName: m.genericName,
        directions: m.directions,
        quantity: m.quantity,
        reasonsForTaking: m.reasonsForTaking,
        notes: m.notes
      }));
  }
}


