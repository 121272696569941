<nz-modal
    [(nzVisible)]="isVisible"
    [nzTitle]="modalHeader"
    [nzFooter]="modalFooter"
    [nzWidth]="'700px'"
    [nzCloseIcon]="modalCloseIcon"
    (nzOnCancel)="handleCancel()"
>

    <ng-template #modalHeader>
        Send to Queue
        <div class="small-text">Choose the options to send the medication to your dispensing software</div>
    </ng-template>
    <ng-template #modalCloseIcon>
        <i class="fas fa-times"></i>
    </ng-template>
    <form [formGroup]="sendToQueueForm" *nzModalContent>
        <div class="d-flex justify-content-between">
            <div class="w45">
                <label for="">Pick up Time (leave blank for waiting)</label>
                <div class="d-flex justify-content-between align-items-center">
                    <nz-time-picker formControlName="timePart" nzFormat="HH:mm a"></nz-time-picker>
                    <nz-date-picker formControlName="datePart"></nz-date-picker>
                </div>
                <div
                class="d-flex justify-content-between align-items-center mt-2"
                 >
                    <button
                    class="quick-time-button"
                     nz-button
                     nzType="default"
                     *ngFor="let control of quickTimeControls"
                     (click)="updateTime(control.adjustment)">
                     {{control.description}}
                    </button>
                </div>
            </div>
            <nz-checkbox-wrapper formArrayName="checkboxOptions" class="w45" (nzOnChange)="updateForm($event)">
                <label for="">Select any of the following</label>
                <div class="w50" nz-row *ngFor="let opt of defaultOptions; index as i">
                  <div nz-col nzSpan="8"><label nz-checkbox [formControlName]="i" nzValue="{{opt.value}}">{{opt.name}}</label></div>
                </div>
            </nz-checkbox-wrapper>
        </div>
        <div class="mt-2">
            <div><label>Notes</label></div>
            <textarea class="w100 p-2" nz-input rows="5" formControlName="notes"></textarea>
        </div>
    </form>
    <ng-template #modalFooter>
        <button class="update-button button btn__yellow"  (click)="handleOk()" [nzLoading]="isConfirmLoading">Send to queue</button>
        <button class="cancel-button" (click)="handleCancel()" >Cancel</button>
    </ng-template>
</nz-modal>
