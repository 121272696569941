import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SixCpaFormListItemChecklistModule } from '../six-cpa-form-list-item-checklist/six-cpa-form-list-item-checklist.module';
import { ActionPLanFormListItemComponent } from './action-plan-form-list-item.component';
import { ActionPlanFormModule } from '../action-plan-form/action-plan-form/action-plan-form.module';


@NgModule({
    declarations: [
        ActionPLanFormListItemComponent
    ],
    imports: [
        CommonModule,
        SixCpaFormListItemChecklistModule,
        ActionPlanFormModule
    ],
    exports: [
        ActionPLanFormListItemComponent
    ]
})
export class ActionPlanFormListItemModule { }