import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxsModule } from '@ngxs/store';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import { LoaderModule } from '../../../modules/core/loader/loader.module';
import { EventConfirmationModalComponent } from './event-confirmation-modal.component';
import { EventConfirmationState } from './state/event-confirmation.state';
import { EvaluationService } from './services/evaluation.service';
import { EvaluationHelperService } from './services/evaluation-helper.service';

@NgModule({
  declarations: [EventConfirmationModalComponent],
  imports: [
    CommonModule,
    NgxsModule.forFeature([EventConfirmationState]),
    ReactiveFormsModule,
    NgxsFormPluginModule,
    LoaderModule
  ],
  providers: [
    EvaluationService,
    EvaluationHelperService
  ],
  exports: [EventConfirmationModalComponent]
})
export class EventConfirmationModalModule { }
