<nz-table #innerTable
    [nzData]="orderRequestItems"
    nzBordered
    nzSize="small"
    [nzLoading]="loading"
    [nzFrontPagination]="false"
    [nzHideOnSinglePage]="true"
    class="inner-table"
>
    <app-order-mgmt-table-headings [columns]="orderItemsColumns"></app-order-mgmt-table-headings>
    <tbody>
        <ng-container *ngFor="let item of innerTable.data; let i = index; trackBy:trackByOrderRequestItemId">
            <tr>
                <td class="medication-container">
                    <div class="medication-container ">
                        <!--Icon-->
                        <div class="item-icon" [ngClass]="{ 'color__red' : item?.customerMedication?.isDangerousDrug }" [ngSwitch]="item.orderRequestItemType">
                                <div *ngSwitchCase="orderItemType.OnFileScript">
                                    <i class="fal fa-file-prescription"></i>
                                </div>
                                <div *ngSwitchCase="orderItemType.EScript">
                                    <i class="fas fa-qrcode"></i>
                                </div>
                                <div *ngSwitchCase="orderItemType.Image">
                                    <img
                                     class="med-image"
                                     width="15px"
                                     height="15px"
                                     [src]="item.attachmentUrl"
                                     alt="medication image">
                                </div>
                                <div *ngSwitchCase="orderItemType.OTCItem">
                                    <i class="fal fa-prescription-bottle-alt"></i>
                                </div>
                                <div *ngSwitchDefault></div>
                        </div>
                        <div>
                            <div *ngIf="item.orderRequestItemType !== orderItemType.Image; else imageType" class="item-name">
                                {{item.name}}
                            </div>
                            <ng-template #imageType>
                                <button
                                    nz-button
                                    nzType="link"
                                    (click)="openImage(item.id)"
                                >{{item.name}}</button>
                                <app-image-modal
                                    [item]="item"
                                    [isVisible]="imageModalsOpen.has(item.id)"
                                    [updatedText]="formatDateForImageModal(_order.requestedDate)"
                                    (isVisibleChange)="closeImageModal(item.id)"
                                ></app-image-modal>
                            </ng-template>
                            <ng-container [ngSwitch]="item.orderRequestItemType">
                                <div *ngSwitchCase="orderItemType.EScript" class="item-desc">
                                    <ng-container *ngIf="getToken(item.eScriptTokenUrl) as token">
                                        {{ token }}&nbsp;
                                        <a (click)="copyToken(item)">Copy</a> <i *ngIf="isOrderItemEScriptCopied.has(item.id)" class="fas fa-check-circle ml-2" ></i>
                                    </ng-container>
                                </div>
                                <div *ngSwitchDefault class="item-desc">
                                    {{ item.customerMedication?.description }}
                                </div>
                            </ng-container>
                        </div>
                    </div>
                    <div class="medication-container">
                        <ng-container *ngIf="item.isNewForCustomer; else sendToQueueButton">
                            <div class="item-new">
                                New
                            </div>
                            <ng-container *ngTemplateOutlet="sendToQueueButton"></ng-container>
                        </ng-container>
                        <ng-template #sendToQueueButton>
                            <div *ngIf="isIncomingOrPartiallyReadyTab$ | async" class="show-button">
                                <app-send-to-queue-button
                                    [order]="createSingleItemOrder(item)"
                                    [loading]="isOrderDispensing"
                                    (sendOrderToQueueEvent)="handleSendOrderToQueue($event)"
                                >
                                </app-send-to-queue-button>
                            </div>
                        </ng-template>
                    </div>
                </td>
                <td>
                    <span *ngIf="item.orderRequestItemType === orderItemType.OTCItem; else noOTCItemQty">
                      N/A
                    </span>
                    <ng-template #noOTCItemQty>
                      <span *ngIf="getScriptQuantity(item) as quantity; else noQuantity">
                          {{ quantity }}
                      </span>
                      <ng-template #noQuantity>
                          <span>0</span>
                      </ng-template>
                    </ng-template>
                </td>
                <td>
                    <span *ngIf="item.orderRequestItemType === orderItemType.OTCItem; else noOTCItemRepeats">
                      N/A
                    </span>
                    <ng-template #noOTCItemRepeats>
                      <span [ngClass]="{red: getRepeats(item) < 2 }">
                          {{ getRepeats(item) }}
                      </span>
                    </ng-template>
                </td>
                <td>
                    <span  *ngIf="item?.customerMedication?.dispensedDate; else noDispenseDate">
                        {{ item?.customerMedication?.dispensedDate | humanize }}
                    </span>
                    <ng-template #noDispenseDate>
                        <span>N/A</span>
                    </ng-template>
                </td>
                <td>
                    {{ item.quantity }}
                </td>
                <td>
                    <span *ngIf="isIncomingOrPartiallyReadyTab$ | async; else noStatusSelect">
                        <nz-select
                            [name]="'status'+i"
                            ngDefaultControl
                            [ngClass]="{
                                'select-status': true,
                                'invalid':
                                    item.orderRequestItemStatus === null ||
                                    item.orderRequestItemStatus == undefined
                                }"
                            [(ngModel)]="item.orderRequestItemStatus"
                            (ngModelChange)="changeStatus($event, item)"
                            nzPlaceHolder="Please select"
                        >
                            <nz-option
                                [nzValue]="orderItemStatus.Pending"
                                nzLabel="Pending"
                            ></nz-option>
                            <nz-option
                                [nzValue]="orderItemStatus.Ready"
                                nzLabel="Completed"
                            ></nz-option>
                            <nz-option
                                [nzValue]="orderItemStatus.OnOrder"
                                nzLabel="On Order"
                            ></nz-option>
                            <nz-option
                                [nzValue]="orderItemStatus.UnableToFill"
                                nzLabel="Unable To Fill"
                            ></nz-option>
                            <nz-option
                                [nzValue]="orderItemStatus.TooEarly"
                                nzLabel="Too Early"
                            ></nz-option>
                    </nz-select>
                    </span>
                    <ng-template #noStatusSelect>
                        <div *ngIf="getStatus(item.orderRequestItemStatus) as status" [ngClass]="status.background + ' status-badge'">
                            <i [ngClass]="status.icon"></i>
                            {{ status.text }}
                        </div>
                    </ng-template>
                </td>
            </tr>
            <tr *ngIf="showEditNotesForItem(item) as showEditNotes" [nzExpand]="item.orderRequestItemStatus && showEditNotes">
                <ng-container *ngTemplateOutlet="notesNestedTable; context: { item: item }"></ng-container>
            </tr>
        </ng-container>
        <tr class="bg_soft_grey">
            <td colspan="6">
                <app-status-selector
                    [order]="_order"
                    [showNotifyCheckbox]="isIncomingOrPartiallyReadyTab$ | async"
                    [orderRequestRowMode]="false"
                    [showQuickCompleteButton]="false"
                    [itemsTableMode]="true"
                    (statusUpdatedEvent)="handleStatusUpdate($event)"
                    (completeOrderEvent)="completeOrder($event)"
                ></app-status-selector>
            </td>
        </tr>
    </tbody>
</nz-table>

<ng-template #notesNestedTable let-item="item">
    <nz-table
        [nzData]="[item]"
        nzSize="small"
        nzBordered
        [nzFrontPagination]="false"
        [nzHideOnSinglePage]="true"
    >
        <ng-container [ngSwitch]="item.orderRequestItemStatus">
            <ng-container *ngSwitchCase="orderItemStatus.OnOrder">
                <thead>
                    <tr>
                        <th id="orderExpectedHeader" class="label pl-5">Order expected<span class="red">*</span></th>
                        <th id="customerNotesHeader" class="label pl-2">Notes to customer (optional)</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td class="order-expected">
                            <nz-date-picker
                                required
                                [name]="'onOrderExpected'+i"
                                [(ngModel)]="item.onOrderExpected"
                                (ngModelChange)="changeOnOrderExpected($event, item)"
                                nzFormat="dd-MM-yyyy"
                                [nzDisabled]="isCompletedOrdersTab"
                            >
                            </nz-date-picker>
                        </td>
                        <td class="item-notes">
                            <input
                                nz-input
                                [name]="'notes'+i"
                                placeholder="Notes to customer (optional)"
                                [(ngModel)]="item.notes"
                                [disabled]="isCompletedOrdersTab"
                            />
                        </td>
                    </tr>
                </tbody>
            </ng-container>
            <ng-container  *ngSwitchCase="orderItemStatus.TooEarly">
                <thead>
                    <tr>
                        <th id="dispenseDateHeader" class="label pl-5">Next Valid Dispense Date<span class="red">*</span></th>
                        <th id="customerNotesHeader" class="label pl-2">Notes to customer (optional)</th>
                        <th id="setReminderHeader" class="label pl-5">Set Reminder</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td class="order-expected">
                            <nz-date-picker
                                required
                                [name]="'nextValidDispenseDate'+i"
                                [(ngModel)]="item.nextValidDispenseDate"
                                (ngModelChange)="changeTooEarly($event, item)"
                                nzFormat="dd-MM-yyyy"
                                [nzDisabled]="isCompletedOrdersTab"
                                >
                            </nz-date-picker>
                        </td>
                        <td class="item-notes">
                            <input
                                nz-input
                                [name]="'notes'+i"
                                placeholder="Notes to customer (optional)"
                                [(ngModel)]="item.notes"
                                [disabled]="isCompletedOrdersTab"
                            />
                        </td>
                        <td class="set-reminder">
                            <div class="set-reminder-container">
                                <label
                                    nz-checkbox
                                    [name]="'setReminder'+i"
                                    [(ngModel)]="item.setReminder"
                                    [value]="item.setReminder"
                                    [disabled]="isCompletedOrdersTab"
                                ></label>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </ng-container>
            <ng-container *ngSwitchCase="orderItemStatus.UnableToFill">
                <thead>
                    <tr>
                        <th id="customerNotesHeader" class="label pl-2">Notes to customer (optional)</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td class="w100">
                            <input
                            [name]="'notes'+i"
                            nz-input
                            placeholder="Notes to customer (optional)"
                            [(ngModel)]="item.notes"
                            [disabled]="isCompletedOrdersTab"
                        />
                        </td>
                    </tr>
                </tbody>
            </ng-container>
        </ng-container>
    </nz-table>
</ng-template>
