<ng-container *ngIf="(addEditCommTemplateModalState$ | async) as addEditCommTemplateModalState">
  <ng-container *ngIf="(showModal$ | async) as showModal">
    <div class="modal">
      <div class="modal-body border__grey position-relative">
        <i (click)="closeModal()" class="fas fa-times float-right pointer"></i>
        <h3> {{commTemplateForm.value.id ? 'Edit': 'Create'}} Template</h3>
        <div class="mt-3">
          <ng-container *ngIf="addEditCommTemplateModalState.error as error">
            <div class="form-help-text" g-xs="mb2">{{error.message}}</div>
          </ng-container>
          <form [formGroup]="commTemplateForm" ngxsForm="addEditCommTemplateModal.commTemplateForm">
            <div class="form-group pb-1">
              <label class="font__bold">Template Type</label>
                <select
                *ngIf="templateType !== templateTypesEnum.Default"
                formControlName="templateType"
                class="form-control form-control-lg w-50"
                [class.has-error]="formControls.templateType.invalid && (showInvalid$ | async)"
                (change)="setTemplateType()">
                    <ng-container *ngFor="let templateTypePair of templateTypesEnum | keyvalue">
                      <option
                      *ngIf="isNumeric(templateTypePair.value) && (templateTypePair.value != templateTypesEnum.Default || templateType === templateTypesEnum.Default)"
                        value="{{templateTypePair.value}}">{{templateTypePair.key}}</option>
                    </ng-container>
                </select>
                <select
                *ngIf="templateType === templateTypesEnum.Default"
                formControlName="templateType"
                class="form-control form-control-lg w-50"
                [class.has-error]="formControls.templateType.invalid && (showInvalid$ | async)"
                (change)="setTemplateType()"
                disabled>
                    <ng-container *ngFor="let templateTypePair of templateTypesEnum | keyvalue">
                      <option
                      *ngIf="isNumeric(templateTypePair.value) && (templateTypePair.value != templateTypesEnum.Default || templateType === templateTypesEnum.Default)"
                        value="{{templateTypePair.value}}">{{templateTypePair.key}}</option>
                    </ng-container>
                </select>
            </div>
            <div class="form-group">
              <label class="font__bold">Template Name</label>
              <input type="text" class="form-control form-control-lg w-50" formControlName="name" required
                [readonly]="commTemplateForm.value.templateType === templateTypesEnum.Default">
              <div class="form-help-text" *ngIf="formControls.name.invalid && formControls.name.touched">Required Field
              </div>
            </div>
            <div class="form-group">
              <label class="font__bold">Subject Line (optional)</label>
              <input type="text" class="form-control form-control-lg w-50" formControlName="subject" />
            </div>
            <div class="form-group">
              <label class="font__bold">Content</label>
              <textarea #messageTextarea rows="5" class="form-control" required formControlName="message">
                  </textarea>
              <span *ngIf="formControls.message.invalid && formControls.message.touched" class="form-help-text">Required
                field</span>
              <div class="row ml-1">
                <div class="p-1" *ngFor="let tag of mergeTags">
                  <span class="pointer font-italic underline text-info" *ngIf="tag.key != 'RepeatTokenLink'" (click)="insertTag(tag.value)">
                    {{tag.key}}
                  </span>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div g-xs="mt2">
          <button class="btn btn-primary create-button btn-lg" (click)="submit()" [disabled]="commTemplateForm.invalid">Submit</button>
        </div>
        <div class="loading__container" *ngIf="addEditCommTemplateModalState.loading;">
          <app-loader></app-loader>
        </div>
      </div>
    </div>
    <div class="modal-background"></div>
  </ng-container>
</ng-container>
