import { Component, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { PharmacyCommsDetailsState, PharmacyCommsDetailsStateModel } from './state/pharmacy-comms-details.state';
import { Observable } from 'rxjs';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { ChannelPreference, PharmacyCommsDetails } from '../../../shared/models/pharmacy/pharmacy-comms-details.model';
import { SubmitPharmacyCommsDetailsAction, UpdatePharmacyCommsDetailsAction } from './state/pharmacy-comms-details.actions';
import { FeaturesState, FeaturesStateModel } from '../../../modules/core/features/features.state';

@Component({
  selector: 'app-pharmacy-comms-details',
  styleUrls: ['./pharmacy-comms-details.component.scss'],
  templateUrl: './pharmacy-comms-details.component.html'
})
export class PharmacyCommsDetailsComponent implements OnInit {
  @Select(PharmacyCommsDetailsState) pharmacyCommsDetailsState$: Observable<PharmacyCommsDetailsStateModel>;
  @Select(FeaturesState.getFeatures) features$: Observable<FeaturesStateModel>;
  pharmacyCommsDetailsForm: FormGroup;
  channelPreference: Array<string> = Object.values(ChannelPreference);
  showScheduleHelper = false;
  showGroupingHelper = false;

  constructor(private formBuilder: FormBuilder, private store: Store) { }

  ngOnInit() {
    this.initForm();
  }

  toggleScheduleHelp() {
    this.showScheduleHelper = ! this.showScheduleHelper;
  }

  toggleGroupingHelp() {
    this.showGroupingHelper = ! this.showGroupingHelper;
  }

  submitPharmacyCommsDetails() {
    if (this.pharmacyCommsDetailsForm.valid) {
      this.store.dispatch(new SubmitPharmacyCommsDetailsAction());
    }
  }

  resetPharmacyCommsDetails() {
    const defaultPharmacyCommsDetails = this.store.selectSnapshot(PharmacyCommsDetailsState.defaultPharmacyCommsDetails);
    defaultPharmacyCommsDetails ? this.pharmacyCommsDetailsForm.patchValue({ ...defaultPharmacyCommsDetails }) : this.pharmacyCommsDetailsForm.reset();
  }

  private initForm() {
    this.pharmacyCommsDetailsForm = this.formBuilder.group({
      id: new FormControl(null),
      communicationsEnabled: new FormControl(true, Validators.required),
      primaryChannel: new FormControl(null, Validators.required),
      secondaryChannel: new FormControl(null),
      drugAggregationDays: new FormControl(null, Validators.required),
      notificationSchedule: new FormControl(null, Validators.required),
    });
  }

  get formControls() {
    return this.pharmacyCommsDetailsForm.controls;
  }

  togglePharmacyComms() {
    this.pharmacyCommsDetailsForm.value.communicationsEnabled = !this.pharmacyCommsDetailsForm.value.communicationsEnabled;
    this.store.dispatch(new UpdatePharmacyCommsDetailsAction(this.pharmacyCommsDetailsForm.value as PharmacyCommsDetails));
  }
}
