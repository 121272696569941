import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoaderModule } from '../../modules/core/loader/loader.module';
import { NotificationModule } from '../../shared/components/notification/notification.module';
import { ProfileDetailsComponent } from './details/profile-details.component';
import { ProfileComponent } from './profile.component';
import { CategoriesModule } from './categories/categories.module';
import { NotesModule } from './notes/notes.module';
import { ProfilePopupModule } from './popup/profile-popup.module';
import { ProfileRoutingModule } from './routing/profile-routing.module';
import { BsDatepickerModule, TabsModule } from 'ngx-bootstrap';
import { CommsModule } from './comms/comms.module';
import { AttendingDoctorModule } from './attending-doctor/attending-doctor.module';
import { BladeModule } from '../core/blade/blade.module';
import { ReportBladeModule } from './report-blade/report-blade.module';
import { ClientTitleModule } from './client-title/client-title.module';
import { NavPanelModule } from './nav-panel/nav-panel.module';
import { OutOfStoreModule } from './out-of-store/out-of-store.module';
import { ProfileModule as CoreProfileModule } from '../core/profile/profile.module';
import { AlertModule } from '../../modules/core/alert/alert.module';
import { CipherModule } from './cipher/cipher.module';
import { AlertProfileComponent } from './alert-profile/alert-profile.component';
import { OrdersTabGuard } from './orders-tab.guard';
import { ClientIdResolver } from './clientId.resolver';
import { TimezoneModule } from '../core/timezone/timezone.module';
import { ProfileServicesModule } from './profile-services/profile-services.module';
import { SixCpaModule } from './six-cpa/six-cpa.module';
import { NgxsModule } from '@ngxs/store';
import { NotesState } from './notes/state/notes.state';
import { CreateNoteModule } from './notes/create-note/create-note.module';
import { ScryptSMSModule } from '../../shared/components/scrypt-sms/scrypt-sms.module';
import { InviteToSignUpModule } from './invite-to-sign-up/invite-to-sign-up.module';
import { EventConfirmationModalModule } from './event-confirmation-modal/event-confirmation-modal.module';
import { PharmacyService } from '../../shared/services/pharmacy.service';
import { NoteActionService } from '../../shared/services/note-action.service';
import { TriggersState } from '../pharmacy-comms/comms-reminders/state/triggers.state';
import { FeaturesState } from '../../modules/core/features/features.state';
import { TriggerService } from '../pharmacy-comms/comms-reminders/services/trigger.service';
import { AslModule } from '../profile/asl/asl.module';
import { MobileStateModalState } from './details/mobile-state-modal/state/mobile-state-modal.state';
import { AuthService } from '../core/auth/auth.service';
import { MobileStateModalComponent } from './details/mobile-state-modal/mobile-state-modal.component';
import { ProfileService } from '../../shared/services/profile.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PrimaryContactModule } from './primary-contact/primary-contact.module';
import { PharmacyCommsDetailsState } from '../../modules/pharmacy-comms/pharmacy-comms-details/state/pharmacy-comms-details.state';
import { PharmacyCommsDetailsService } from '../../modules/pharmacy-comms/pharmacy-comms-details/services/pharmacy-comms-details.service';
import { StatisticsState } from '../../shared/state/statistics/statistics.state';
import { StatisticsService } from '../../shared/services/statistics.service';

@NgModule({
  declarations: [
    ProfileComponent,
    ProfileDetailsComponent,
    AlertProfileComponent,
    MobileStateModalComponent
  ],
  imports: [
    TimezoneModule,
    AlertModule,
    CommonModule,
    CoreProfileModule,
    ProfileRoutingModule,
    LoaderModule,
    NotificationModule,
    ClientTitleModule,
    NavPanelModule,
    CategoriesModule,
    NotesModule,
    ProfilePopupModule,
    AttendingDoctorModule,
    BladeModule,
    TabsModule,
    CommsModule,
    BladeModule,
    ReportBladeModule,
    OutOfStoreModule,
    AlertModule,
    CipherModule,
    ProfileServicesModule,
    SixCpaModule,
    NgxsModule.forFeature([NotesState, TriggersState, FeaturesState, MobileStateModalState, PharmacyCommsDetailsState, StatisticsState]),
    CreateNoteModule,
    ScryptSMSModule,
    InviteToSignUpModule,
    EventConfirmationModalModule,
    AslModule,
    BsDatepickerModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    PrimaryContactModule
  ],
  exports: [ProfileComponent],
  providers: [
    OrdersTabGuard,
    ClientIdResolver,
    PharmacyService,
    NoteActionService,
    TriggerService,
    AuthService,
    ProfileService,
    PharmacyCommsDetailsService,
    StatisticsService
  ],
})
export class ProfileModule {}
