import { Note } from "~/shared/models/crm/note.model";
import { NoteType } from "~/shared/models/crm/noteType.model";
import { NewNote } from "~/shared/models/crm/newNote.model";

export class NotesAction {
  static readonly type = '[Notes] Add item';
  constructor(public payload: string) { }
}
export enum NotesActionTypes {
  TOGGLE_REQUIRES_ACTION= '[Notes] Toggle Requires Action',
  TOGGLE_UPCOMING= '[Notes] Toggle Upcoming',
  TOGGLE_HISTORY= '[Notes] Toggle History',
  GET_CLIENT_NOTES = '[Notes] Get Notes For Client',
  GET_NOTE_TYPES= '[Notes] Get Note Types',
  ADD_NOTE_TO_LIST = '[Notes] Add New Note To List',
  CREATE_NEW_NOTE = '[Notes] Create New Note',
  DELETE_NOTE = '[Notes] Remove Note',
  ACTION_NOTE = '[Notes] Action Client Note',
  EDIT_NOTE = '[Notes] Edit Note',
  GET_NOTES_SUCCESS = '[Notes] Get Notes Success',
  GET_NOTES_TYPES_SUCCESS = '[Notes] Get Note Types Success',
  NOTE_ACTION_SUCCESS = '[Notes] Note Action Success',
  NOTE_ACTION_ERROR = '[Notes] Note Action Error',
  ACTION_RESET_NOTE_FILTERS = '[Notes] Reset Note Filters Action Error',
  RESET_ORDERS_CYCLE = "[Notes] Reset Orders Cycle"
}

export class ToggleRequiresAction {
  static readonly type = NotesActionTypes.TOGGLE_REQUIRES_ACTION;
}

export class ToggleUpcoming {
  static readonly type = NotesActionTypes.TOGGLE_UPCOMING;
}

export class ToggleHistory {
  static readonly type = NotesActionTypes.TOGGLE_HISTORY;
}

export class GetClientNotes {
  static readonly type = NotesActionTypes.GET_CLIENT_NOTES;
  constructor(public clientId: string) { }
}

export class GetNoteTypes {
  static readonly type = NotesActionTypes.GET_NOTE_TYPES;
  constructor() { }
}

export class AddNewNote {
  static readonly type = NotesActionTypes.ADD_NOTE_TO_LIST;
  constructor(public note: Note) { }
}

export class CreateNewNote {
  static readonly type = NotesActionTypes.CREATE_NEW_NOTE;
  constructor(public newNote: NewNote) { }
}

export class RemoveNote {
  static readonly type = NotesActionTypes.DELETE_NOTE;
  constructor(public noteId: number) { }
}

export class EditExistingNote {
  static readonly type = NotesActionTypes.EDIT_NOTE;
  constructor(public note: Note) { }
}

export class ActionNote {
  static readonly type = NotesActionTypes.ACTION_NOTE;
  constructor(public noteId: number) { }
}

export class GetClientNotesSuccess {
  static readonly type = NotesActionTypes.GET_NOTES_SUCCESS;
  constructor(public notes: Note[]) { }
}

export class GetNoteTypesSuccess {
  static readonly type = NotesActionTypes.GET_NOTES_TYPES_SUCCESS;
  constructor(public types: NoteType[]) { }
}

export class NoteActionSuccessful {
  static readonly type = NotesActionTypes.NOTE_ACTION_SUCCESS;
  constructor() { }
}

export class NoteActionError {
  static readonly type = NotesActionTypes.NOTE_ACTION_ERROR;
  constructor(public error: any) { }
}

export class ActionClientNote {
  static readonly type = NotesActionTypes.ACTION_NOTE;
  constructor(public note: Note, public clientId: string) { }
}

export class ResetOrdersCycle {
  static readonly type = NotesActionTypes.RESET_ORDERS_CYCLE;
  constructor(public clientId: number) {}
}

export class ResetProfileNoteFilters {
  static readonly type = NotesActionTypes.ACTION_RESET_NOTE_FILTERS;
}
