import { Component } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { SixCpaState } from '../../state/six-cpa.state';
import { SaveOrUpdateMedsCheckAction } from '../state/meds-check-save.actions';
import { ClosePdfFormModal } from '../../state/six-cpa.actions';

@Component({
  selector: 'app-meds-check-save-pdf-modal',
  templateUrl: './meds-check-save-pdf-modal.component.html'
})
export class MedsCheckSavePdfModalComponent {
  @Select(SixCpaState.showPdfModal) showPdfModal$: Observable<boolean>;
  constructor() {
  }

  @Dispatch()
  saveMedsCheckWithPdf = () => [new SaveOrUpdateMedsCheckAction(false, true), new ClosePdfFormModal()];

  @Dispatch()
  saveMedsCheck = () => [new SaveOrUpdateMedsCheckAction(), new ClosePdfFormModal()];

  
}
