import { CustomerService } from './../shared/services/customer.service';
import { VerifyService } from './../shared/services/verify.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxAutoScrollModule } from 'ngx-auto-scroll';
import { BsDropdownModule, TabsModule } from 'ngx-bootstrap';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ChartModule, HIGHCHARTS_MODULES } from 'angular-highcharts';
import * as more from 'highcharts/highcharts-more.src';
import * as exporting from 'highcharts/modules/exporting.src';
import * as solidGauge from 'highcharts/modules/solid-gauge.src';
import { SystemRoutingModule } from './system-routing.module';
import { SharedModule } from '../shared/shared.module';
import { PublicHeaderComponent } from '../shared/components/public-header/public-header.component';
import { CrmPageComponent } from './crm-page/crm-page.component';
import { NoteListComponent } from './crm-page/note-list/note-list.component';
import { SmsReportPageComponent } from './sms-report-page/sms-report-page.component';
import { SmsReportListComponent } from './sms-report-page/sms-report-list/sms-report-list.component';
import { SmsReportFilterComponent } from './sms-report-page/sms-report-filter/sms-report-filter.component';
import { SmsService } from '../shared/services/sms.service';
import { SystemComponent } from './system.component';
import { BiMetricComponent } from './bi-metric/bi-metric.component';
import { BimetricService } from '../shared/services/bimetric.service';
import { MetricItemComponent } from './bi-metric/metric-item/metric-item.component';
import { PharmacyService } from '../shared/services/pharmacy.service';
import { CrudService } from '../shared/services/crud.service';
import { TenantUserPageComponent } from './tenant-user-page/tenant-user-page.component';
import { TenantUserItemComponent } from './tenant-user-page/tenant-user-item/tenant-user-item.component';
import { TenantUserService } from '../shared/services/tenantUser.service';
import { TenantComponent } from './tenant-user-page/tenant/tenant.component';
import { TenantItemComponent } from './tenant-user-page/tenant/tenant-item/tenant-item.component';
import { TenantService } from '../shared/services/tenant.service';
import { UserService } from '../shared/services/user.service';
import { UserPageComponent } from './user-page/user-page.component';
import { UserItemComponent } from './user-page/user-item/user-item.component';
import { NoteFiltersComponent } from './crm-page/note-filters/note-filters.component';
import { GroupPageComponent } from './group-page/group-page.component';
import { GroupService } from '../shared/services/group.service';
import { NoteActionComponent } from '../system/crm-page/note-list/note-actions/note-actions.component';
import { HttpClient } from '@angular/common/http';
import { StorageService } from '../shared/core/storage/storage.service';
import { Router } from '@angular/router';
import { MarketingService } from '../shared/services/marketing.service';
import { PipesModule } from '../shared/pipes/pipes.module';
import { ClientListComponent } from './client-list/client-list.component';
import { AlertService } from '~/modules/core/alert/alert.service';
import { ReportingPageComponent } from './reporting-page/reporting-page.component';
import { NgxPowerBiModule } from 'ngx-powerbi';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ProfileService } from '../shared/services/profile.service';
import { BreadCrumbService } from '../shared/components/breadcrumbs/breadcrumbs.service';
import { NoteTypeLinkPipe } from '../shared/pipes/noteTypeLink.pipe';
import { VerifyPageComponent } from './verify-page/verify-page.component';
import { RedirectService } from '../shared/services/redirect.service';
import { OrderService } from '../shared/services/order.service';
import { CategoryPageComponent } from './category-page/category-page.component';
import { CategoryRepository } from '../modules/profile/tenant-categories/category.repository';
import { CategoryItemComponent } from './category-page/category-item/category-item.component';
import { MarketingModule } from './marketing-page/marketing.module';
import { NoteSettingsModule } from '~/modules/core/note-settings/note-settings.module';
import { ScriptService } from '~/shared/services/script.service';
import { NgxsModule } from '@ngxs/store';
import { ContentContainerState } from '~/modules/state/content-container/content-container.state';
import { LoaderModule } from '~/modules/core/loader/loader.module';
import { NoteActionService } from '~/shared/services/note-action.service';
import { StorageModule } from '~/shared/core/storage/storage.module';
import { ProfileModule } from '~/modules/core/profile/profile.module';
import { AlertModule } from '~/modules/core/alert/alert.module';
import { NotesScrollService } from './crm-page/note-filters/note-filter.service';
import { TimezoneModule } from '~/modules/core/timezone/timezone.module';
import { CrmNotificationDateModule } from '~/modules/crm/crm-notification-date/crm-notification-date.module';
import { CrmState } from './crm-page/state/crm.state';
import { CsvExportPageModule } from './csv-export-page/csv-export-page.module';
import { GroupPageState } from './group-page/state/group-page.state';
import { ProfileState } from '~/modules/core/profile/state/profile.state';
import { PushNotificationsResolver } from '~/modules/core/push-notifications/push-notifications.resolver';
import { BulkMessagingPageModule } from './bulk-messaging-page/bulk-messaging-page.module';
import { FeaturesState } from '~/modules/core/features/features.state';
import { AdvancedSearchModule } from './advanced-search/advanced-search.module';
import { GroupsResolver } from './group-page/groups.resolver';
import { EscriptsState } from '~/shared/components/escripts/state/escripts.state';
import { TenantHealthService } from '~/shared/services/tenant-health.service';
import { RecentCustomersPageComponent } from './recent-customers-page/recent-customers-page.component';
import { AdminDashboardModule } from './admin-dashboard/dashboard/admin-dashboard.module';
import { DispenseRunPageModule } from './dispense-run/dispense-run-page/dispense-run-page.module';
import { ReactiveFormsModule } from '@angular/forms';
import { FluidModalModule } from '~/shared/components/fluid-modal/fluid-modal.module';
import { CommunicationsPageModule } from './communications-page/communications-page.module';
import { CustomerAuth } from './../modules/order/services/customer-auth';
import { NotesState } from './../modules/profile/notes/state/notes.state';
import { InvoicesPageModule } from '@base/modules/invoices-page/invoices-page.module';
import { ReportingState } from '~/modules/state/reporting/reporting.state';
import { DashboardState } from './dashboard-page/state/dashboard.state';
import { MobileAnalyticsPageComponent } from './analytics-mobile-page/analytics-mobile-page.component';
import { OrderManagementModule } from './order-management/order-mgmt.module';
import { ZapiState } from './z-api/state/zapi.state';

@NgModule({
  declarations: [
    SmsReportPageComponent,
    SmsReportListComponent,
    SmsReportFilterComponent,
    CrmPageComponent,
    NoteListComponent,
    SystemComponent,
    PublicHeaderComponent,
    BiMetricComponent,
    MetricItemComponent,
    TenantUserPageComponent,
    TenantUserItemComponent,
    TenantComponent,
    TenantItemComponent,
    UserPageComponent,
    UserItemComponent,
    NoteFiltersComponent,
    GroupPageComponent,
    NoteActionComponent,
    ReportingPageComponent,
    ClientListComponent,
    VerifyPageComponent,
    CategoryPageComponent,
    CategoryItemComponent,
    RecentCustomersPageComponent,
    MobileAnalyticsPageComponent
  ],
  imports: [
    CrmNotificationDateModule,
    AlertModule,
    TimezoneModule,
    CommonModule,
    ProfileModule,
    StorageModule,
    PipesModule,
    SystemRoutingModule,
    SharedModule,
    NgxAutoScrollModule,
    ChartModule,
    NgxPowerBiModule,
    InfiniteScrollModule,
    NoteSettingsModule,
    BsDatepickerModule.forRoot(),
    BsDropdownModule.forRoot(),
    TabsModule.forRoot(),
    NgxsModule.forFeature([
      ContentContainerState,
      CrmState,
      GroupPageState,
      ProfileState,
      FeaturesState,
      EscriptsState,
      NotesState,
      ReportingState,
      DashboardState,
      ZapiState
    ]),
    MarketingModule,
    LoaderModule,
    CsvExportPageModule,
    BulkMessagingPageModule,
    CommunicationsPageModule,
    AdvancedSearchModule,
    AdminDashboardModule,
    DispenseRunPageModule,
    ReactiveFormsModule,
    FluidModalModule,
    InvoicesPageModule,
    OrderManagementModule
  ],
  providers: [
    SmsService,
    GroupsResolver,
    {
      provide: 'CrudService',
      useFactory: (http, router, storageService, alertService) => new CrudService(http, router, storageService, alertService, ''),
      deps: [HttpClient, Router, StorageService, AlertService]
    },
    {provide: HIGHCHARTS_MODULES, useFactory: () => [more, exporting, solidGauge]},
    CategoryRepository,
    NoteActionService,
    BimetricService,
    BreadCrumbService,
    PharmacyService,
    TenantUserService,
    TenantService,
    UserService,
    GroupService,
    MarketingService,
    ScriptService,
    ProfileService,
    RedirectService,
    NoteTypeLinkPipe,
    VerifyService,
    CustomerService,
    OrderService,
    NotesScrollService,
    PushNotificationsResolver,
    TenantHealthService,
    CustomerAuth
  ],
  exports: [
    UserPageComponent
  ]
})
export class SystemModule { }
