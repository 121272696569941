<nz-modal
    [(nzVisible)]="isVisible"
    [nzTitle]="modalHeader"
    [nzFooter]="modalFooter"
    [nzWidth]="'740px'"
    [nzCloseIcon]="modalCloseIcon"
    (nzOnCancel)="handleCancel()"
>
    <ng-template #modalHeader>
        Add medication
    </ng-template>
    <ng-template #modalCloseIcon>
        <i class="fas fa-times"></i>
    </ng-template>
    <div *nzModalContent>
        <div class="medications-container">
            <ng-container *ngFor="let category of profileOrderItems">
                <h3>{{category.name}}</h3>
                <table>
                    <tr>
                        <th>Medication</th>
                        <th class="center">Script QTY</th>
                        <th class="center">Repeats</th>
                        <th class="center">Last dispensed</th>
                        <th class="center">Total DOH</th>
                        <th></th>
                    </tr>
                    <tr *ngFor="let item of category.orderItems" [ngClass]="{'row-adding': isAdding(item)}">
                        <td>
                            <div class="d-flex justify-content-between align-items-center">
                                <div class="d-flex justify-content-between align-items-center">
                                    <div class="item-icon" [ngSwitch]="item.orderRequestItemType">
                                            <div *ngSwitchCase="orderItemType.OnFileScript">
                                                <i class="fal fa-file-prescription"></i>
                                            </div>
                                            <div *ngSwitchCase="orderItemType.Escript">
                                                <i class="fas fa-qrcode"></i>
                                            </div>
                                            <div *ngSwitchCase="orderItemType.Image">
                                                <img width="15px" height="15px" 
                                                [src]="item.attachmentUrl" alt="medication image">
                                            </div>
                                            <div *ngSwitchCase="orderItemType.OTCItem">
                                                <i class="fal fa-prescription-bottle-alt"></i>
                                            </div>
                                            <div *ngSwitchDefault>
                                                
                                            </div>
                                    </div>
                                    <div>
                                        <div *ngIf="item.orderRequestItemType !== orderItemType.Image" class="item-name">
                                            {{item.name}}
                                        </div>
                                        <button 
                                            *ngIf="item.orderRequestItemType === orderItemType.Image"
                                            nz-button nzType="link"
                                            (click)="openImage(item)"
                                        >{{item.name}}</button>
                                        <div class="item-desc">
                                            {{item.customerMedication?.description}}
                                        </div>
                                    </div>
                                </div>
                                <div class="item-new" *ngIf="item.isNewForCustomer">
                                    New for patient
                                </div>
                            </div>
                            
                        </td>
                        <td class="center">{{item.customerMedication?.quantity == null ? '' : item.customerMedication.quantity }}</td>
                        <td [ngClass]="{red: item.customerMedication?.repeats < 2, center: true}">{{item.customerMedication?.repeats == null ? '' : item.customerMedication.repeats }}</td>
                        <td class="center">
                            <span *ngIf="item.customerMedication?.dispensedDate">
                                {{dateFn(item.customerMedication?.dispensedDate)}}
                            </span>
                        </td>
                        <td class="center">{{drugDOH(item)}}d</td>
                        <td>
                            <button class="add-button" *ngIf="!item.isAdded" (click)="addItem(item)" nz-button nzType="link">
                                <i class="fas fa-plus"></i>
                                &nbsp;Add
                            </button>
                            <button class="added-button" *ngIf="isAdding(item)" nz-button nzType="link">
                                &nbsp;Added
                            </button>
                            <button class="remove-button"
                            *ngIf="item.isAdded && !isAdding(item)"
                            (click)="removeItem(item)" nz-button nzType="link"
                            [disabled]="(!((item?.addedByPharmacist || false) || item.orderRequestItemType === orderItemType.OTCItem) && !isCreate)"
                            >
                                <i class="fas fa-times"></i>
                                &nbsp;Remove
                            </button>
                        </td>
                    </tr>
                </table>
            </ng-container>
        </div>
    </div>
    <ng-template #modalFooter>
        <button class="done-button button btn__yellow" (click)="handleCancel()">Done</button>
    </ng-template>
</nz-modal>