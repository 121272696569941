import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
@Pipe({
  name: 'humanize',
})
export class HumanizePipe implements PipeTransform {
  transform(value: any): string {
    moment.updateLocale('en', {
      relativeTime: {
        future: 'in %s',
        past: '%s ago',
        s: 'a few seconds',
        ss: '%d seconds',
        m: 'a min',
        mm: '%d mins',
        h: 'an hour',
        hh: '%d hours',
        d: 'a day',
        dd: '%d days',
        M: 'a month',
        MM: '%d months',
        y: 'a year',
        yy: '%d years',
      },
    });
    if (value) {
      var dtm = moment(Date.parse(value));
      var now = moment();
      var today = moment().startOf('day');
      var hdiff = Math.abs(now.diff(dtm, 'hour'));
      var dtmToday = moment(dtm).startOf('day');
      var ddiff = today.diff(dtmToday, 'days');

      if(hdiff < 6){
        return dtm.fromNow();
      }
      if(ddiff === 0){
        return dtm.format('h:mma') + ', Today';
      }
      if(ddiff === 1){
        return dtm.format('h:mma') + ', Yesterday';
      }
      if(ddiff === -1){
        return dtm.format('h:mma') + ', Tomorrow';
      }
      return dtm.format('D MMM')
    }
    return '';
  }
}
