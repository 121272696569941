import { Component, OnInit, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { MailService } from '../../../../../shared/services/mail.service';
import { MailNote } from '../../../../../shared/models/mail.model';
import { Subscription } from 'rxjs/Subscription';
import { Notification } from '../../../../../shared/models/notification.model';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-add-mail-note',
  templateUrl: './add-mail-note.component.html',
  styleUrls: ['./add-mail-note.component.scss']
})
export class AddMailNoteComponent implements OnInit, OnDestroy {

  constructor(private mailService: MailService) { }

  @Input() clientId: string;
  @Output() noteAddedEvent = new EventEmitter<MailNote>();

  sub1: Subscription;
  notification: Notification;


  ngOnInit() {
    this.notification = new Notification('success', '');
  }


  onSubmit(form: NgForm) {
    const { message } = form.value;
    this.addNote(message, form);
  }
  addNote(message: string, form: NgForm) {
    const newItem = new MailNote(message, this.clientId);
    this.sub1 = this.mailService.sendMailNote(newItem)
      .subscribe((item: MailNote) => {
        form.reset();
        form.form.patchValue({ message: '' });
        this.noteAddedEvent.emit(item);
      },
        (error) => {
          this.showNotification('danger', error);
        });
  }

  showNotification(type: string = 'danger', text: string = '') {
    this.notification.text = text;
    this.notification.type = type;
    window.setTimeout(() => {
      this.notification.text = '';
    }, 4000);
  }
  ngOnDestroy() {
    if (this.sub1) { this.sub1.unsubscribe(); }
  }
}
