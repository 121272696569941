import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrderCreateComponent } from './order-create.component';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { TabsModule } from 'ngx-bootstrap';
import { NgxsModule } from '@ngxs/store';
import { OrderRequestState } from '../state/order.state';
import { PipesModule } from '../../../../shared/pipes/pipes.module';
import { ScryptInsightModule } from '../../../../shared/scrypt-insight/scrypt-insight.module';
import { FeaturesState } from '../../../core/features/features.state';
import { OrderTableModule } from '../components/order-table/order-table.module';
import { OrderUpdateStatusModule } from '../components/order-update-status-modal/order-update-status-modal.module';
import { OrderUpdateStatusCardModule } from '../components/order-update-status-card/order-update-status-card.module';
import { SendToQueueModalModule } from '../components/send-to-queue-modal/send-to-queue-modal.module';
import { ImageModalModule } from '../components/image-modal/image-modal.module';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { AdditionalItemsModalModule } from '../components/additional-items-modal/additional-items-modal.module';
import { AddMedicationModalModule } from '../components/add-medication-modal/add-medication-modal.module';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { methodValidator } from './method-validator';
import { LoaderModule } from '../../../core/loader/loader.module';
import { StripeModalModule } from '../components/stripe-modal/stripe-modal.module';

@NgModule({
  declarations: [
    OrderCreateComponent,
    methodValidator
  ],
  imports: [
    CommonModule,
    FormsModule,
    TabsModule,
    RouterModule,
    PipesModule,
    ScryptInsightModule,
    NgxsModule.forFeature([
      OrderRequestState,
      FeaturesState
      ]),
    OrderTableModule,
    OrderUpdateStatusModule,
    OrderUpdateStatusCardModule,
    SendToQueueModalModule,
    ImageModalModule,
    NzButtonModule,
    AdditionalItemsModalModule,
    AddMedicationModalModule,
    NzSelectModule,
    LoaderModule,
    StripeModalModule
  ]
})
export class OrderCreateModule { }
