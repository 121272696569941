import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Actions, Select, Store, ofActionSuccessful } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { validMergeTags } from '~/shared/models/communication/mergeTags';
import { CommTemplate, TemplateType } from '~/shared/models/pharmacy/comm-template.model';
import { CreateGlobalTemplate, GetTenantDetails } from '../state/admin-dashboard.actions';
import { AdminDashboardState } from '../state/admin-dashboard.state';
import { MessageTemplate } from '~/shared/models/pharmacy/message-template.model';
import { TenantDetails } from '~/shared/models/tenant-details.model';

@Component({
  selector: 'app-global-template',
  templateUrl: './global-template.component.html',
  styleUrls: ['./global-template.component.scss']
})
export class GlobalTemplateComponent implements OnInit {
  @Select(AdminDashboardState.loading) loading$: Observable<boolean>;
  @Select(AdminDashboardState.tenants) tenants$: Observable<TenantDetails[]>;
  commTemplateForm: FormGroup;
  templateTypes = TemplateType;
  mergeTags = validMergeTags;
  @ViewChild('messageTextArea') messageTextAreaElementRef: ElementRef;
  loadingMessage = '';
  loadingMessages = [
    'Adding the spices and cooking your nachos...',
    'Churning the milk and making the butter...',
    'Spinning some beats like DJ Gilies...',
    'Baby shark doo doo doo doo doo...',
    'Cooking your steak until well done...',
    'Launching the missiles...',
    'Preparing you a nice seafood dinner...',
    'BRB, just getting a drink...',
  ];
  selectedTenants: number[] = [];
  private createTemplateAction: Subscription;

  constructor(private formBuilder: FormBuilder, private store: Store, private action$: Actions) { }

  ngOnInit(): void {
    this.generateFormControls();
    this.setLoadingMessage();
    this.store.dispatch(new GetTenantDetails());
    this.createTemplateAction = this.action$
      .pipe(ofActionSuccessful(CreateGlobalTemplate))
      .subscribe(_ => this.resetToDefaults());
  }

  ngOnDestroy(): void {
    this.createTemplateAction.unsubscribe();
  }

  private setLoadingMessage() {
    const messageIndex = Math.floor(Math.random() * (this.loadingMessages.length - 0 + 1) + 0);
    this.loadingMessage = this.loadingMessages[messageIndex];
  }

  submit() {
    const messageTemplate: MessageTemplate = {
      template: this.commTemplateForm.value as CommTemplate,
      tenantIds: this.selectedTenants
    };
    this.store.dispatch(new CreateGlobalTemplate(messageTemplate));
  }

  insertTag(tag: string) {
    const text = this.formControls.message.value;
    const cursorPos = this.messageTextAreaElementRef.nativeElement.selectionStart;
    var output = [text.slice(0, cursorPos), tag, text.slice(cursorPos)].join('');
    this.commTemplateForm.patchValue({ message: output });
  }

  private generateFormControls() {
    this.commTemplateForm = this.formBuilder.group({
      id: new FormControl(null),
      name: new FormControl('', Validators.required),
      message: new FormControl('', Validators.required),
      interval: new FormControl(1, [Validators.min(0)]),
      isActive: new FormControl(true, Validators.required),
      templateType: new FormControl(TemplateType.Custom, Validators.required),
      subject: new FormControl(null)
    });
  }

  get formControls() {
    return this.commTemplateForm.controls;
  }

  get templateType() {
    return this.commTemplateForm.value.templateType;
  }

  private resetToDefaults() {
    this.setLoadingMessage();
    this.selectedTenants = [];
    this.commTemplateForm.reset();
    this.commTemplateForm.patchValue({
      id: null,
      name: '',
      message: '',
      interval: 1,
      isActive: true,
      subject: null,
      templateType: TemplateType.Custom
    });
  }
}
