import { Component, OnInit } from '@angular/core';
import { mapHQDrugsToDispenseItemModel, mapHQDrugToDispenseItemModel } from '@base/shared/helpers/dispense-request.helpers';
import { BatchDispenseRequestModel } from '@base/shared/models/dispense/batchDispenseRequest.model';
import { DispenseItemModel } from '@base/shared/models/dispense/dispenseItem.model';
import { Medication } from '~/shared/models/script/chartviewitem.model';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs/Observable';
import { AlertService } from '~/modules/core/alert/alert.service';
import { ClientViewModel } from '~/modules/core/profile/client.model';
import { RequestBulkDispense } from '~/modules/profile/categories/multiple-select/bulk-dispense/bulk-dispense.actions';
import { BulkDispenseState, BulkDispenseStateModel } from '~/modules/profile/categories/multiple-select/bulk-dispense/bulk-dispense.state';
import { DeprecatedClientViewModel, ReportViewModel } from '../models/dispense-report.model';
import { DispenseRunState } from '../state/dispense-run.state';

@Component({
  selector: 'app-dispense-run-results',
  templateUrl: './dispense-run-results.component.html',
  styleUrls: ['./dispense-run-results.component.scss']
})
export class DispenseRunResultsComponent implements OnInit {
  @Select(DispenseRunState.report) dispenseReport$: Observable<ReportViewModel>;
  @Select(DispenseRunState.preFetchedClients) clients$: Observable<ClientViewModel[]>;
  @Select(BulkDispenseState.selectBulkDispense) bulkDispenseState$: Observable<BulkDispenseStateModel>;
  selectAll = false;
  columns = [
    '',
    'Generic Name',
    'Brand',
    'QTY',
    'Directions',
    'Doctor',
    'Last Disp',
    'Next Repeat',
    'Freq',
    'Rpts',
    'DOH',
    'Dispense'
  ];
  dangerousCategories = ['s4d', 's8'];

  constructor(private store: Store, private alertService: AlertService) { }

  ngOnInit(): void {
  }

  getEvaluationStatus(clients: ClientViewModel[]): string {
    const completedClients = clients.filter(x => !!x.evaluated);
    if (completedClients.length == clients.length) {
      return `All ${clients.length} potential clients have been checked.`
    }

    return `Evaluated ${completedClients.length} clients of ${clients.length}...`;
  }

  getIcon(clients: ClientViewModel[]): string {
    const completedClients = clients.filter(x => !!x.evaluated);
    if (completedClients.length == clients.length) {
      return 'fad fa-check-circle success';
    }

    return 'fad fa-hourglass-half warning';
  }

  hasAnyScriptsSelected(clients: DeprecatedClientViewModel[]): boolean {
    if (!clients)
      return false;

    return !!clients.filter(client => client.items.filter(drug => !!drug.bulkDispense));
  }

  toggleAll(clients: DeprecatedClientViewModel[]) {
    clients.forEach(client => {
      client.items.forEach(script => {
        script.bulkDispense = this.selectAll;
      });
    });
  }

  bulkDispenseAllSelected(clients: DeprecatedClientViewModel[]) {
    let selectedDrugs: Medication[] = [];
    let items: DispenseItemModel[] = [];

    clients.forEach(client => {
      const selectedDrugs = client.items.filter(drug => !!drug.bulkDispense);

      const scripts = selectedDrugs.map(drug => {
        return mapHQDrugToDispenseItemModel(drug, `${client.patient.firstname} ${client.patient.surname}`)
      });
      scripts.forEach(script => items.push(script));
    });

    const request: BatchDispenseRequestModel = {
      scripts: items,
      username: "Script HQ",
      notes: "Generated in Scrypt HQ"
    }

    if (!items || !items.length) {
      this.alertService.warn('No valid scripts selected, please select valid scripts for dispensing...');
      return;
    }

    this.store.dispatch(new RequestBulkDispense(request));
  }

  bulkDispenseforClient(client: DeprecatedClientViewModel) {
    const selectedDrugs = client.items.filter(drug => drug.scriptDispenseId && !!drug.bulkDispense);

    const requestItems = mapHQDrugsToDispenseItemModel(selectedDrugs, `${client.patient.firstname} ${client.patient.surname}`)

    const request: BatchDispenseRequestModel = {
      scripts: requestItems,
      username: "Script HQ",
      notes: "Generated in Scrypt HQ"
    }

    if (!requestItems || !requestItems.length) {
      this.alertService.warn('No valid scripts selected, please select valid scripts for dispensing...');
      return;
    }

    this.store.dispatch(new RequestBulkDispense(request));
  }

  hasSelectedItems(client: DeprecatedClientViewModel): boolean {
    if (!client || !client.items)
      return false;

    return !!client.items.filter(drug => !!drug.bulkDispense);
  }

  getScriptIcon(item: Medication): string {
    if (!item) return '';

    let result = 'fa-2x ';

    if (item.isChart) {
      result += 'fas fa-notes-medical'
    } else if (item.isPaperless && item.repeats > 0) {
      result += 'fas fa-qrcode'
    } else {
      result += 'fal fa-file-prescription'
    }

    if (item.isDangerousDrug) {
      result += ' color__red';
    }

    return result;
  }
}
