import { Selector } from '@ngxs/store';
import { OutOfStoreState, OutOfStoreStateModel } from './state/out-of-store.state';
import { StockState, StockStateModel, IStock } from '../stock';
import { AdditionalItemStock } from './out-of-store-stock.model';
import { IAdditionalItem, IHasStockId } from './out-of-store.model';
import { mergeValuesIntoSet, toDictionary } from '../../core/arrays/arrays.helper';
import { Injectable } from '@angular/core';


const defaultAdditionalItem: Partial<IAdditionalItem> = {
  frequency: 28,
  quantity: 1,
  repeats: 1,
  lastDispensedDate: new Date()
};

@Injectable()
export class OutOfStoreService {

  @Selector([OutOfStoreState, StockState])
  static items(
    state: OutOfStoreStateModel,
    stock: StockStateModel
  ): AdditionalItemStock[] {
    const getStockForItem = OutOfStoreService.getStockForItems(stock.stocks);
    return state.items.map((item) =>
    ({
      ...defaultAdditionalItem,
      ...item,
      ...getStockForItem(item)
    } as AdditionalItemStock)
    );
  }

  static getStockForItems(stocks: IStock[]): (item: IHasStockId) => IStock {
    const dict = toDictionary<IStock>(x => x.id)(stocks);
    return (item: IHasStockId) => {
      return dict[item.stockId];
    };
  }

  static mergeValuesIntoSet(formItems: AdditionalItemStock[]) {
    return mergeValuesIntoSet<AdditionalItemStock>((x) => x.stockId)(formItems);
  }
}
