import { ChannelPreference } from "../../../../../../../scrypt-core/src/lib/shared/models/pharmacy/pharmacy-comms-details.model";
import { OrderRequestSearchDto } from "../../../../../../../scrypt-core/src/lib/modules/profile/order-requests/models/order-request-search.model";
import { OrderRequest } from "../../../../../../../scrypt-core/src/lib/modules/profile/order-requests/models/order-request.model";
import { UpdateOrderRequestModel } from "../../../../../../../scrypt-core/src/lib/modules/profile/order-requests/models/order-request-update.model";
import { OrderRequestItem } from "../../../../../../../scrypt-core/src/lib/modules/profile/order-requests/models/order-request-item.model";
import { InvoiceRequestModal } from "../../../../../../../scrypt-core/src/lib/modules/profile/order-requests/models/invoice-request-model";
import { QueueDispenseRequestModel } from "@base/shared/models/dispense/queueDispenseRequest.model";

export enum OrderActionTypes {
  GET = '[OrderRequests] Profile Get',
  GET_ID = '[OrderRequests] Profile Get by id',
  SET = '[OrderRequests] Profile Set',
  ERROR = '[OrderRequests] Error',
  CANCEL = '[OrderRequests] Cancel',
  CONFIRM_BY_PHARMACIST = '[OrderRequests] Confirm By Phamacist',
  DELIVER_ORDER = '[OrderRequests] Mark as Delivered',
  DELETE_ACTION_RESPONSE = '[OrderRequests] Delete Action Response',
  UPDATE_ORDER_METHOD = '[OrderRequests] Update order method',
  UPDATE_ORDER = '[OrderRequests] Update',
  UPDATE_ORDER_RESPONSE = '[OrderRequests] Update Response',
  CREATE_ORDER_RESPONSE = '[OrderRequests] Create Response',
  CREATE_ORDER = '[OrderRequests] Create',
  SET_PAGINATION = '[OrderRequests] Set Pagination',
  TOGGLE_ORDER_STATUS_MODAL = '[OrderRequests] Toggle order status modal',
  TOGGLE_SEND_TO_QUEUE_MODAL = '[OrderRequests] Toggle send to queue modal',
  DISPENSE_TO_QUEUE = '[OrderRequests] dispense scripts to queue',
  TOGGLE_IMAGE_MODAL = '[OrderRequests] Toggle image modal',
  STOCK_SEARCH = '[OrderRequests] stock search',
  SET_STOCK = '[OrderRequests] set stock',
  TOGGLE_ADDITIONAL_MODAL = '[OrderRequests] Toggle additional items modal',
  SET_CURRENT_ORDER = '[OrderRequests] Set current order',
  TOGGLE_ADD_MEDICATION_MODAL = '[OrderRequests] Toggle add medication modal',
  GET_PREDICTED_ORDER = '[OrderRequests] Get predicted order',
  STRIPE_MODAL_VISIBLE= '[OrderRequests] Action Modal Visible',
  GENERATE_PAYMENT_LINK = '[OrderRequests] Generate payment link',
  GENERATE_PAYMENT_LINK_RESPONSE = '[OrderRequests] Generate payment link response',
}

export class ToggleAddMedicationModal {
  static readonly type = OrderActionTypes.TOGGLE_ADD_MEDICATION_MODAL;
  constructor(public open: boolean) { }
}

export class SetCurrentOrder {
  static readonly type = OrderActionTypes.SET_CURRENT_ORDER;
  constructor(public currentOrder: OrderRequest) { }
}

export class ToggleAdditionalItemsModal {
  static readonly type = OrderActionTypes.TOGGLE_ADDITIONAL_MODAL;
  constructor(public open: boolean) { }
}

export class SearchOrderStockAction {
  static readonly type = OrderActionTypes.STOCK_SEARCH;
  constructor(public query: string) { }
}

export class SetStockListAction {
  static readonly type = OrderActionTypes.SET_STOCK;
  constructor(public items: any) { }
}


export class DispenseScriptsToQueue {
  static readonly type = OrderActionTypes.DISPENSE_TO_QUEUE;
  constructor(public request: QueueDispenseRequestModel) { }
}

export class GetOrderRequestById {
  static readonly type = OrderActionTypes.GET_ID;
  constructor(public id: number) { }
}

export class ToggleSendToQueueModal {
  static readonly type = OrderActionTypes.TOGGLE_SEND_TO_QUEUE_MODAL;
  constructor(public open: boolean) { }
}

export class ToggleImageModal {
  static readonly type = OrderActionTypes.TOGGLE_IMAGE_MODAL;
  constructor(public open: boolean, public currentItem: OrderRequestItem = null) { }
}

export class ToggleOrderStatusModal {
  static readonly type = OrderActionTypes.TOGGLE_ORDER_STATUS_MODAL;
  constructor(public open: boolean, public current = null, public status: any = null) { }
}

export class SetOrderPagination {
  static readonly type = OrderActionTypes.SET_PAGINATION;
  constructor(public pagination: any) { }

}

export class GetOrderList {
  static readonly type = OrderActionTypes.GET;
  constructor(public clientId: number, public filters: OrderRequestSearchDto) { }
}

export class GetPredictedOrder {
  static readonly type = OrderActionTypes.GET_PREDICTED_ORDER;
  constructor(public clientId: string) { }
}


export class SetOrderProfileAction {
  static readonly type = OrderActionTypes.SET;
  constructor(public orders: any) { }
}

export class OrderErrorAction {
  static readonly type = OrderActionTypes.ERROR;
  constructor(public error: any) { }
}
export class DeleteOrderAction {
  static readonly type = OrderActionTypes.CANCEL;
  constructor(public orderId: number) { }
}

export class ConfirmOrderByPharmacistAction {
  static readonly type = OrderActionTypes.CONFIRM_BY_PHARMACIST;
  constructor(public orderId: number, public drugs: any[], public additionalDrugs: any[]) { }
}
export class DeliverOrderAction {
  static readonly type = OrderActionTypes.DELIVER_ORDER;
  constructor(public orderId: number) { }
}

export class RemoveOrderResponse {
  static readonly type = OrderActionTypes.DELETE_ACTION_RESPONSE;
  constructor(public orderId: number) { }
}

export class UpdateOrderAction {
  static readonly type = OrderActionTypes.UPDATE_ORDER;
  constructor(public order: OrderRequest, public message: string, public messageChannel: ChannelPreference, public sendMessage:boolean) { }
}

export class CreateOrderAction {
  static readonly type = OrderActionTypes.CREATE_ORDER;
  constructor(public order: OrderRequest, public message: string, public messageChannel: ChannelPreference, public sendMessage:boolean) { }
}

export class UpdateOrderResponse {
  static readonly type = OrderActionTypes.UPDATE_ORDER_RESPONSE;
  constructor(public request: UpdateOrderRequestModel) { }
}

export class CreateOrderResponse {
  static readonly type = OrderActionTypes.CREATE_ORDER_RESPONSE;
  constructor(public request: UpdateOrderRequestModel) { }
}

export class UpdateOrderMethod {
  static readonly type = OrderActionTypes.UPDATE_ORDER_METHOD;
  constructor(public order: OrderRequest) { }
}

export class StripeModalVisibleAction {
  static readonly type = OrderActionTypes.STRIPE_MODAL_VISIBLE;
  constructor(public visible: boolean) { }
}

export class GeneratePaymentLinkAction {
  static readonly type = OrderActionTypes.GENERATE_PAYMENT_LINK;
  constructor(public invoiceRequest: InvoiceRequestModal) { }
}