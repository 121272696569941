import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable()
export class ValidService {
    public selectedCategoryName: string = '';
    private valid$ = new BehaviorSubject<Boolean>(false);
    private order$ = new BehaviorSubject<any>(null);
    constructor() {

    }

    public setOrder(valid){
        this.order$.next(valid)
    }


    public get orderStatus() {
        return this.order$.asObservable();
    }

    public setValid(valid){
        this.valid$.next(valid)
    }


    public get validStatus() {
        return this.valid$.asObservable();
    }
}