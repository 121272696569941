import { ScryptInsight } from '../scrypt-insight/scrypt-insight.model';
import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-order-doh-insight',
    styleUrls: ['../scrypt-insight/scrypt-insight-content.style.scss'],
    template: /*html*/`
    <div class="scrypt-insight-content">
        <div class="scrypt-insight-content__title">
            {{title}}
        </div>
        <div class="scrypt-insight-content__content">
            The patient may have been without drugs for <b>{{info}} days</b> days. Consider intervention.
        </div>
    </div>`
})
export class OrderDohInsightComponent implements ScryptInsight {
    @Input() title: string;
    @Input() info: any;
    @Input() action: Function;
}
