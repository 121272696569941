import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InstoreOrderComponent } from './instore-order.component';
import { ScriptService } from '../../../../shared/services/script.service';
import { FormsModule } from '@angular/forms';
import { NgxsModule } from '@ngxs/store';
import { InstoreOrderState } from './state/instore-order.state';
import { ProfileModule } from '../../../core/profile/profile.module';

@NgModule({
  declarations: [
    InstoreOrderComponent
  ],
  providers: [
    ScriptService
  ],
  imports: [
    CommonModule,
    FormsModule,
    ProfileModule,
    NgxsModule.forFeature([InstoreOrderState])
  ],
  exports: [
    InstoreOrderComponent
  ]
})
export class InstoreOrderModule { }
