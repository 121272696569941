import { CommsQueueItem } from '../../../../../modules/pharmacy-comms/comms-queue/models/comms-queue-item.model';

export enum ProjectedMessagesActions {
  GET_PROJECTED_MESSAGES = '[ProjectedMessages] Get',
  GET_PROJECTED_MESSAGES_SUCCESS = '[ProjectedMessages] Success',
  GET_PROJECTED_MESSAGES_ERROR = '[ProjectedMessages] Error'
}

export class GetProjectedMessages {
  static readonly type = ProjectedMessagesActions.GET_PROJECTED_MESSAGES;
  constructor(public clientId: string, public startDate: Date) { }
}

export class GetProjectedMessagesSuccess {
  static readonly type = ProjectedMessagesActions.GET_PROJECTED_MESSAGES_SUCCESS;
  constructor(public messages: CommsQueueItem[]) { }
}

export class GetProjectedMessagesErrors {
  static readonly type = ProjectedMessagesActions.GET_PROJECTED_MESSAGES_ERROR;
  constructor(public error: any) { }
}
