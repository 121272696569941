import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngxs/store';
import { AddMedication } from '../../../six-cpa/add-edit-medication/state/add-edit-medication.actions';
import { Medicine } from '../../../../../shared/models/six-cpa/medicine.model';

@Component({
  selector: 'app-medication-profile-list-item',
  templateUrl: './medication-profile-list-item.component.html',
  styleUrls: ['./medication-profile-list-item.component.scss']
})
export class MedicationProfileListItemComponent implements OnInit {
  medicationForm : FormGroup;
  constructor(public fb: FormBuilder, private store: Store) { }
  @Input() drug: Medicine;
  @Input() disabled: boolean = false;
  @Output() selectEvent = new EventEmitter<any>();
  @Output() updateEvent = new EventEmitter<any>();

  ngOnInit() {
    this.medicationForm = this.fb.group({
      id: this.fb.control(this.drug.id),
      productDispensedId: this.fb.control(this.drug.productDispensedId, Validators.required),
      brandName: this.fb.control(this.drug.brandName, Validators.required),
      genericName: this.fb.control(this.drug.genericName),
      quantity: this.fb.control(this.drug.quantity),
      directions: this.fb.control(this.drug.directions),
      reasonsForTaking: this.fb.control(this.drug.reasonsForTaking),
      notes: this.fb.control(this.drug.notes),
      prescriber: this.fb.control(this.drug.prescriber),
      startDate: this.fb.control(this.drug.startDate),
      dispensedDate: this.fb.control(this.drug.dispensedDate)
    });
  }
  select() {
    if (!this.disabled) {
      this.selectEvent.emit(this.drug);
    }
    
  }
  update() {
    if (!this.disabled) {
      this.updateEvent.emit(this.drug);
    }
  }

  updateMedicationState(){
    this.store.dispatch(new AddMedication(this.medicationForm.value as Medicine));
  }
}
