import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxsModule } from '@ngxs/store';
import { SixCpaUserCommunicationsState } from './state/six-cpa-user-communications.state';
import { SixCpaUserCommunicationsComponent } from './six-cpa-user-communications.component';

@NgModule({
  declarations: [
    SixCpaUserCommunicationsComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NgxsModule.forFeature([
      SixCpaUserCommunicationsState,
    ])
  ],
  exports: [
    SixCpaUserCommunicationsComponent
  ]
})
export class SixCpaUserCommunicationsModule { }
