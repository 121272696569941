<ng-container *ngIf="(signUpState$ | async) as signUpState">
  <div class="container" style="max-width: 400px;">
    <div class="customer-info">
      <h1>Create Account</h1>
      <h3 class="pt-2" *ngIf="signUpState.customer?.customerName">{{signUpState.customer.customerName}}</h3>
      <h5 class="pt-2" *ngIf="signUpState.customer?.email">{{signUpState.customer.email}}</h5>
      <h5 class="pt-2" *ngIf="signUpState.customer?.mobile">{{signUpState.customer.mobile}}</h5>
      <small class="pointer pt-2" (click)="toggleIncorrectDetailsModal()"><u>Incorrect details?</u></small>
      <ng-container *ngIf="showPopup">
        <div class="incorrect-info-box">
          <div class="incorrect-info">
            If these details are incorrect, or don’t belong to you please contact your pharmacist. <br /><br /><br />
            <span class="pointer" (click)="toggleIncorrectDetailsModal()"><u>Dismiss</u></span>
          </div>
        </div>
      </ng-container>
    </div>
    <ng-container *ngIf="signUpState.validating">
      <div class="account-creating">
        <div style="padding: 25%">
          <app-loader loadingMessage="Creating account..."></app-loader>
        </div>
      </div>
    </ng-container>
    <hr />
    <div>
      <h3>Create a password</h3>
      <form [formGroup]="createAccountForm" class="pt-2">
        <div class="form-group">
          <div class="form-group">
            <label for="password">Password</label>
            <input type="password" 
                formControlName="password" 
                [ngClass]="['form-control inputs', createAccountForm.status == 'VALID' ? 'valid' : '']"    
                id="password"
                placeholder="Password">
          </div>
          <div class="form-group">
            <label for="confirm">Confirm Password</label>
            <input 
                [ngClass]="['form-control inputs', passwordsDontMatch() ? 'invalid' : '', createAccountForm.status == 'VALID' ? 'valid' : '']" 
                type="password" 
                formControlName="confirmPassword" 
                class="form-control inputs" 
                id="confirm"
                placeholder="Confirm Password">
          </div>
          <label for="exampleInputPassword1">I want to log in with my: </label>
          <div class="custom-control custom-radio" *ngIf="signUpState.customer?.mobile">
            <input 
                type="radio" 
                id="mobile" 
                [value]="inviteTypes.Sms" 
                formControlName="loginMethod"
                class="custom-control-input">
            <label class="custom-control-label" for="mobile">Mobile number ({{signUpState.customer.mobile}})</label>
          </div>
          <div class="custom-control custom-radio" *ngIf="signUpState.customer?.email">
            <input type="radio" id="email" [value]="inviteTypes.Email" formControlName="loginMethod"
              class="custom-control-input">
            <label class="custom-control-label" for="email">Email address ({{signUpState.customer.email}})</label>
          </div>
          <div class="form-group accept">
            <p>To continue signing up, you must agree to our <a [href]="privacyUrl" target="_blank">Privacy Policy</a> and <a [href]="termsUrl" target="_blank">Terms of Use</a></p>
              <input
                id="accept-terms"
                type="checkbox"
                formControlName="consent"
                required
              />
              <label for="accept-terms">
                I accept
              </label>
          </div>
        </div>
      </form>
      <div class="info">
        <span>Password must be at least 8 characters, with at least one uppercase and numeric character.</span>
      </div>
      <div class="info">
        <span>By creating a Scrypt account you agree to our terms and conditions and privacy policy</span>
      </div>
      <button *ngIf="!signUpState.validating;" class="btn btn-warning" (click)="createAccount()"
        [disabled]="createAccountForm.status == 'INVALID' || passwordsDontMatch()">
        Create Account
      </button>
    </div>
    <ng-container *ngIf="signUpState.error">
      <div class="mt-3 alert alert-danger">
        We've encountered an error setting up your account: <br />
        {{signUpState.error}}
      </div>
    </ng-container>
  </div>

</ng-container>
