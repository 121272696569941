import { Inject, Injectable } from '@angular/core';
import { BaseApi } from '../core/base-api/base-api';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { StorageService } from '../core/storage/storage.service';
import { AlertService } from '../../modules/core/alert/alert.service';
import { Observable } from 'rxjs';

@Injectable()
export class ScheduledMessageService extends BaseApi {
  API = '';

  constructor(
    @Inject('environment') env: any,
    public http: HttpClient,
    public router: Router,
    public storageService: StorageService,
    public alertService: AlertService
  ) {
    super(http, router, storageService, alertService);
    this.API = env.baseApiUrl;
  }

  getScheduledMessages(clientId: string): Observable<any> {
    return this.get(
      `${this.API}/api/scheduled-message/GetScheduledMessagesByClientId/${clientId}`
    );
  }

  removeScheduledMessage(messageId: number) {
    return this.post(`${this.API}/api/scheduled-message/delete`, messageId);
  }

  removeScheduledMessages(messageIds: number[]) {
    return this.post(
      `${this.API}/api/scheduled-message/delete-bulk`,
      messageIds
    );
  }

  retryFailedMessage(messageId: number) {
    return this.post(
      `${this.API}/api/scheduled-message/RetryFailedMessage/${messageId}`
    );
  }
}
