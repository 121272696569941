import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { ClientRepository } from '../../modules/core/profile/client.repository';
import { tap, flatMap, map } from 'rxjs/operators';
import { Store } from '@ngxs/store';
import { ToggleDriveYelloNewOrderModal } from '~/modules/drive-yello/drive-yello-order-modal/state/drive-yello-new-order.actions';

@Component({
  selector: 'app-client-list',
  templateUrl: './client-list.component.html',
  styleUrls: ['./client-list.component.scss']
})
export class ClientListComponent implements OnInit {
  searchText = '';
  searchResult = '';
  isLoaded = false;
  clientResults$ = new Observable<any>();
  firstname = null;
  lastname = null;
  phoneNumber = null;
  resultCount = 0;
  property: string;
  isDesc = false;
  sortColumnProperties = {};

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private clientRepository: ClientRepository,
    private store: Store
  ) { }

  ngOnInit(): void {
    this.getClientResults();
  }

  private getClientResults() {
    this.clientResults$ = this.route.params
      .pipe(
        tap(params => {
          this.firstname = params.firstName;
          this.lastname = params.lastName;
          this.phoneNumber = params.phoneNumber;
          if (this.firstname !== 'null' && this.firstname) {
            this.searchResult = `${this.lastname} ${this.firstname}`;
          } else if (this.lastname) {
            this.searchResult = `${this.lastname}`;
          }
        }),
        flatMap(a => this.clientRepository.getClientSearch(this.lastname, this.firstname, this.phoneNumber)),
        map(clientList => clientList.clients.map(client => ({
          fullname: `${client.firstname} ${client.surname}`,
          patientAdress: (client.homeAddress || '') + (client.homeSuburb ? `, ${client.homeSuburb}`: ''),
          ...client
        }))),
        tap(clientList => {
          this.resultCount = clientList.length;
        })
      );
  }

  getStatus(type: string) {
    if (type === 'DAA' || type === 'SOF') {
      return 'bg__green';
    }
    else if (type === 'LEAD') {
      return 'bg__orange';
    }
    else {
      return 'bg__red';
    }
  }
  search() {
    const search = this.searchText.split(' ');
    this.lastname = search[0];
    this.phoneNumber = search[0];
    this.firstname = null;
    if (search.length > 1) {
      this.firstname = search[1];
    }

    this.router.navigate(['client', { firstName: this.firstname, lastName: this.lastname, phoneNumber: this.phoneNumber }]);
  }

  getLink(id) {
    return `${window.location.protocol}//${window.location.host}/client/view/${id}`;
  }

  sort(property) {
    this.isDesc = !this.isDesc;
    this.property = property;
    const direction = this.isDesc ? 1 : -1;
    this.sortColumnProperties = { property: property, direction: direction };
  }

  openDYModal() {
    this.store.dispatch(new ToggleDriveYelloNewOrderModal(true));
  }
}
