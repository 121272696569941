import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AlertService } from '../../../../../core/alert/alert.service';
import { StorageService } from '../../../../../../shared/core/storage/storage.service';
import { IdentifyIndividualRequestModel } from '../state/air-lookup-form.model';
import { BaseApiNoAlerts } from '../../../../../../shared/core/base-api/base-api-no-alerts';
import { Observable } from 'rxjs';
import { AirHistoryRequestType } from '../../models/individual-history-statement.model';
import {
  IdentifyIndividualWithMedicareType,
  IdentifyIndividualWithoutMedicareIrnType,
  IdentifyIndividualWithoutMedicareType,
} from '../../models/identify-individual-request.model';

@Injectable({
  providedIn: 'root',
})
export class AirLookupService extends BaseApiNoAlerts {
  API = '';

  constructor(
    @Inject('environment') env: any,
    public httpClient: HttpClient,
    public router: Router,
    public storageService: StorageService,
    public alertService: AlertService
  ) {
    super(httpClient, router, storageService, alertService);
    this.API = env.baseApiUrl;
  }

  lookup(customer: IdentifyIndividualRequestModel): Observable<any> {
    return this.post(`${this.API}/api/IndividualIdentity/details`, customer);
  }

  lookupByMedicare(
    customer: IdentifyIndividualWithMedicareType,
    tenantCustomerId: number
  ): Observable<any> {
    return this.post(
      `${this.API}/api/IndividualIdentity/details/medicare/${tenantCustomerId}`,
      customer
    );
  }

  lookupWithoutMedicare(
    customer: IdentifyIndividualWithoutMedicareType,
    tenantCustomerId: number
  ): Observable<any> {
    return this.post(
      `${this.API}/api/IndividualIdentity/details/without-medicare/${tenantCustomerId}`,
      customer
    );
  }

  lookupWithoutMedicareIRN(
    customer: IdentifyIndividualWithoutMedicareIrnType,
    tenantCustomerId: number
  ): Observable<any> {
    return this.post(
      `${this.API}/api/IndividualIdentity/details/without-medicare-irn/${tenantCustomerId}`,
      customer
    );
  }

  getStatement(
    clientId: string,
    customer: AirHistoryRequestType
  ): Observable<any> {
    return this.post(
      `${this.API}/api/IndividualHistory/statement/${clientId}`,
      customer
    );
  }
}
