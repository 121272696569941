<div class="projected-mesages" *ngIf="!(projectedMessagesLoading$ | async); else loading">
  <ng-container *ngIf="(projectedMessages$ | async) as projectedMessages">
    <section class="message-history">
      <div class="font__title-3 font__light">
        <h5>Projected Messages</h5>
      </div>
      <div id="projected-messages-history" g-xs="p3 mt2 mb3" class="sms-history border__grey" ngx-auto-scroll
        observe-attributes>
        <div g-xs="mb3" *ngFor="let item of projectedMessages">
          <div *ngIf="item.to" class="color__grey-text font__title-0">
            <strong>To: </strong>
            <span>{{ item.to }}</span>
          </div>
          <div *ngIf="item.from" class="color__grey-text font__title-0">
            <strong>From: </strong>
            <span>{{ item.from }}</span>
          </div>
          <div *ngIf="item.subject" class="color__grey-text font__title-1">
            <strong>Subject: </strong>
            <span>{{ item.subject }}</span>
          </div>
          <div class="color__grey-text font__title-0">
            <strong>Send On: </strong> {{ format(item.sendDate) }}
          </div>
          <div class="font__title-1">
            {{ item.message }}
          </div>
          <hr />
        </div>
        <div *ngIf="!projectedMessages.length">
          <span>
            There are no currently projected messages for
            {{ client.firstname }} {{ client.surname }}
          </span>
        </div>
      </div>
    </section>
  </ng-container>
</div>

<ng-template #loading>
  <div g-xs="p4 text:center" class="flex">
    <app-loader class="loading"></app-loader>
  </div>
</ng-template>
