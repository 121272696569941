import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrderCardComponent } from './order-card.component';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [
    OrderCardComponent
  ],
  imports: [
    CommonModule,
    NzDropDownModule,
    NzButtonModule,
    RouterModule
  ],
  exports:[
    OrderCardComponent
  ]
})
export class OrderCardModule { }
