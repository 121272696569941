import { Component, OnInit, OnDestroy } from '@angular/core';
import { SickLeaveChildForm } from '../sick-leave-child-form';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Store, Actions } from '@ngxs/store';
import { SickLeaveCertificateTypeState, SickLeaveMembersDetailsTypes, SickLeaveSeekingCertificateForTypes } from './state/sick-leave-certificate-type.state';
import { SetTotalItemsAndCompletedItems } from './state/sick-leave-certificate-type.actions';
import { SixCpaState } from '../../state/six-cpa.state';

@Component({
  selector: 'app-sick-leave-certificate-type',
  templateUrl: './sick-leave-certificate-type.component.html',
  styleUrls: ['./sick-leave-certificate-type.component.scss']
})
export class SickLeaveCertificateTypeComponent extends SickLeaveChildForm implements OnInit, OnDestroy  {

  sickLeaveCertificateTypeForm: FormGroup;
  formSubscription: Subscription;
  private readonlySubscription: Subscription;
  sickLeaveSeekingCertificateForTypes = SickLeaveSeekingCertificateForTypes;
  sickLeaveMembersDetailsTypes = SickLeaveMembersDetailsTypes;

  constructor(private formBuilder: FormBuilder, private store: Store, actions: Actions) {
    super(actions);
    this.generateFormControls();
  }

  ngOnInit() {
    this.formSubscription = this.store.select(SickLeaveCertificateTypeState.sickLeaveCertificateTypeForm)
      .subscribe(() => this.onFormChange());
    this.readonlySubscription = this.store.select(SixCpaState.formsReadOnly)
      .subscribe(readonly => readonly ? this.sickLeaveCertificateTypeForm.disable() : this.sickLeaveCertificateTypeForm.enable());
  }

  ngOnDestroy() {
    this.formSubscription.unsubscribe();
    this.readonlySubscription.unsubscribe();
  }
  get formControls() {
    return this.sickLeaveCertificateTypeForm.controls;
  }
  deselectCertificateDetails() {
    this.sickLeaveCertificateTypeForm.controls['satisfiedThatItIsInRelationToMember']
    .setValue(this.sickLeaveCertificateTypeForm.value.seekingCertificateFor === this.sickLeaveSeekingCertificateForTypes.CarerLeave ? true : null);
    this.sickLeaveCertificateTypeForm.controls['membersDetails'].setValue(null);
    this.sickLeaveCertificateTypeForm.controls['membersName'].setValue(null);
  }

  protected actionOnResetForm() {
    this.sickLeaveCertificateTypeForm.reset();
    this.sickLeaveCertificateTypeForm.patchValue(
      {
        seekingCertificateFor: null,
        satisfiedThatItIsInRelationToMember: true,
        membersDetails: null,
        membersName: null
      });
  }

  private onFormChange() {
    const controlsToExclude =
    this.sickLeaveCertificateTypeForm.value.seekingCertificateFor === this.sickLeaveSeekingCertificateForTypes.CarerLeave
     ? [] : ['satisfiedThatItIsInRelationToMember', 'membersDetails', 'membersName'];
    const controls = Object.keys(this.sickLeaveCertificateTypeForm.controls);
    const totalControls = controls.filter(x => !controlsToExclude.includes(x));
    const completedItems = this.getTotalValidItems(totalControls);
    this.store.dispatch(new SetTotalItemsAndCompletedItems(totalControls.length, completedItems));
  }

  private getTotalValidItems(controls: Array<string>) {
    return controls.filter((key) => {
      const formControl = this.sickLeaveCertificateTypeForm.get(key);
      return  formControl.status === 'VALID' || (formControl.status === 'DISABLED' && !formControl.invalid && formControl.errors === null);
    }).length;
  }

  private generateFormControls() {
    this.sickLeaveCertificateTypeForm = this.formBuilder.group({
      seekingCertificateFor: new FormControl(null, Validators.required),
      satisfiedThatItIsInRelationToMember: new FormControl(null, Validators.required),
      membersDetails: new FormControl(null, Validators.required),
      membersName:  new FormControl(null, Validators.required),
    });
  }

}
