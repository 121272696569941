export const ScriptTypes = {
  expired: 'E',
  repeat: 'R',
  deferred: 'D',
  original: 'O',
  owing: 'W',
  expiredFullName: 'Expired',
  repeatFullName: 'Repeat',
  deferredFullName: 'Deferred',
  originalFullName: 'Original',
  owingFullName: 'Owing'
}