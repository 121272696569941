import { OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Store, Actions } from '@ngxs/store';
import { SetTotalItemsAndCompletedItems } from './state/ss-consent-eligibility-form.actions';
import { StagedSupplyChildForm } from '../../staged-supply-child-form';
import * as moment_ from 'moment';
const moment = moment_;

export class SSConsentEligibilityFormBaseComponent extends StagedSupplyChildForm implements OnInit, OnDestroy {
  consentEligibilityForm: FormGroup;
  formResetStatus: boolean;

  constructor(private formBuilder: FormBuilder, protected store: Store, actions: Actions) {
    super(actions);
    this.consentEligibilityForm = this.formBuilder.group({});
  }

  protected actionOnResetForm() {
    this.consentEligibilityForm.patchValue({
      dateOfPatientRegistration: moment(new Date(), 'YYYY-MM-DD').format('DD-MM-YYYY'),
      writtenConsentForProvisionOfData: false,
      writtenConsentForServiceProvision: false,
      gpOrHospitalLastSixMonthsForMedicationProblems: false,
      governmentIssuedConcessionCard: false,
      pbsCodeOfTheMedicationSupplied: null,
      dateStagedSupplyServiceCommenced: moment(new Date(), 'YYYY-MM-DD').format('DD-MM-YYYY'),
    });
  }

  get formControls() {
    return this.consentEligibilityForm.controls;
  }

  protected onFormChange() {
    const controls = Object.keys(this.consentEligibilityForm.controls);
    const completedItems = this.getTotalValidItems(controls);
    this.store.dispatch(new SetTotalItemsAndCompletedItems(controls.length, completedItems));
  }

  private getTotalValidItems(controls: Array<string>) {
    return controls.filter((key) => {
      const formControl = this.consentEligibilityForm.get(key);
      return  formControl.status === 'VALID' || (formControl.status === 'DISABLED' && !formControl.invalid && formControl.errors === null);
    }).length;
  }
}
