import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';
import { CommunicationsState } from './communications.state';
import { CommunicationsService } from '../services/communications.service';

@NgModule({
  imports: [
    NgxsModule.forFeature([
      CommunicationsState])
  ],
  providers: [
    CommunicationsService
  ]
})
export class CommunicationsStateModule { }
