import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { interval } from "rxjs/internal/observable/interval";


declare var window: any;

@Component({
  selector: 'desktop',
  template: '<p>Bootstrapping desktop</p>'
})
export class DesktopComponent implements OnInit {


  complete: boolean;
  constructor(private router: Router) { }


  ngOnInit() {
    const subscription = interval(1000)
      .subscribe(
        res => {
          if (window.todesktop) {
            console.log("desktop found redirecting");
            this.router.navigateByUrl("/dashboard").then(() => subscription.unsubscribe());
          } else { console.log("no desktop found") }
        }
      );

  }
}
