<section *ngIf="!isLoaded">
  <app-loader *ngIf="!notification.text"></app-loader>
  <app-notification [notification]="notification"></app-notification>
</section>
<section *ngIf="isLoaded">
  <section>
    <div class="description-block flex font__title-1" g-xs="my4">
      <div g-xs="mr3">
        <div class="font__bold font__title-2">Contact Preference</div>
        <div>
          <img src="/assets/img/message.png" />
          <span> SMS</span>
        </div>
      </div>
      <div>
        <div class="font__bold font__title-2">Communication Status</div>
        <div>Requested information</div>
      </div>
    </div>
  </section>
  <section>
    <div class="tabs">
      <tabset>
        <tab heading="SMS" [active]="type === 'sms'">
          <div>
            <app-sms [client]="client"></app-sms>
          </div>
        </tab>
        <tab heading="Email" [active]="type === 'email'">
          <div>
            <app-email [client]="client"></app-email>
          </div>
          <!-- <div *ngIf="!client.emailAddress" g-xs="m3">
            <app-notification [notification]="{'type':'danger', 'text':'No client Email on file'}"></app-notification>
          </div> -->
        </tab>
        <tab heading="Phone Call" [active]="type === 'phone'">
          <div *ngIf="client.mobileNumber || client.phoneNumber">
            <app-phone [client]="client"></app-phone>
          </div>
          <div *ngIf="!client.mobileNumber && !client.phoneNumber" g-xs="m3">
            <app-notification [notification]="{
                type: 'danger',
                text: 'No client mobile number on file'
              }">
            </app-notification>
          </div>
        </tab>
        <tab heading="Mail" [active]="type === 'mail'">
          <div>
            <app-mail [client]="client"></app-mail>
          </div>
        </tab>
        <tab heading="Scheduled Messages" [active]="type === 'scheduledMessages'">
          <div>
            <app-scheduled-messages [client]="client"></app-scheduled-messages>
          </div>
        </tab>
        <tab heading="Projected Messages" [active]="type==='projectedMessages'">
          <div>
            <app-projected-messages [client]="client"></app-projected-messages>
          </div>
        </tab>
        <tab heading="Recurring SMS" [active]="type === 'recurringSMS'">
          <div>
            <app-message-cycle [client]="client"></app-message-cycle>
          </div>
        </tab>
        <tab heading="Scrypt Messenger" [active]="type === 'messenger'">
          <div>
            <app-messenger [client]="client"></app-messenger>
          </div>
        </tab>
      </tabset>
    </div>
  </section>
</section>
