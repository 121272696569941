<ng-container>
  <div class="original-script p-2 pl-4 text-left">
    <div *ngIf="originalScript; else noOriginal">
      <span (click)="toggleGroup()" class="toggle-banner">
        <span style="margin-right: 10px;">
          <i style="font-weight: bold; font-size: 15px;"
            [ngClass]="canShowHistory ? 'fas fa-chevron-up' : 'fas fa-chevron-down'"></i>
        </span>
        <span (mouseenter)="showScriptType(true)" (mouseleave)="showScriptType(false)">
          <strong>Type: </strong>
          <span [ngClass]="getScriptType(originalScript) == 'E' ? 'expired': ''">
            <span *ngIf="!showType">{{getScriptType(originalScript)}}&nbsp;&nbsp;</span>
            <span *ngIf="showType">{{getFullScriptType(originalScript)}}&nbsp;&nbsp;</span>
          </span>
        </span>
        <span
          *ngIf="originalScript.originalScriptNo && !originalScript.isPassiveScript; else noOriginal"><strong>Script:
          </strong>
          {{originalScript.originalScriptNo}} &nbsp;</span>
        <ng-template #noOriginal>
          <span *ngIf="originalScript.scriptNo && !originalScript.isPassiveScript"><strong>Script: </strong>
            {{originalScript.scriptNo}} &nbsp;</span>
        </ng-template>
        <strong *ngIf="!originalScript.scriptNo || originalScript.isPassiveScript">Outside Script(s)/Repeat(s)</strong>
        &nbsp;
        <span *ngIf="originalScript.prescriptionDate; else noDate">
          <strong>Prescribed: </strong> {{originalScript.prescriptionDate | tenantDate}}
          &nbsp;
        </span>
        <span *ngIf="originalScript.doctor">
          <strong>Doctor: </strong> {{originalScript.doctor}}
          &nbsp;
        </span>
        <strong>QTY: </strong> {{originalScript.qty}} &nbsp;
        <strong>Repeats: </strong> {{remainingRepeats}} &nbsp;
      </span>
      <span style="float: right; margin-right: 10px;">
        <span class="availability"><span *ngIf="!isScriptValid || !originalScript.enabled">Not </span>On File
          &nbsp;</span>
        <ui-switch [checked]="(isScriptValid && originalScript.enabled)" [disabled]="!isScriptValid" size="small"
          (click)="toggleScriptAvailability(script)">
        </ui-switch>
      </span>
    </div>
    <ng-template #noOriginal>
      <div class="original-script"><strong>Dispensed with no original</strong></div>
    </ng-template>
    <ng-template #noDate>
      <strong>-No Date-</strong>&nbsp;
    </ng-template>
    <ng-template #unavailable>
      <span class="alert alert-danger" style="float: right; margin-right: 10px; padding: 0px;"> Unavailable
        &nbsp;</span>
    </ng-template>
  </div>
</ng-container>
