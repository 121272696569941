import { HmrFormModel } from '~/shared/models/six-cpa/HMR/hmr-form.model';
import { HMRFormType } from './hmr-form.state';

export enum HMRActions {
  SET_VALID = '[HMR] Set Valid',
  SUBMIT = '[HMR] Submit',
  RESET = '[HMR] Reset',
  SAVE = '[HMR] Save',
  UPDATE_SAVE = '[HMR] Update Save',
  UPDATE_SUBMIT = '[HMR] Update Submit',
  SAVE_SUCCESS = '[HMR] Save Success',
  SAVE_FAILED = '[HMR] Save Failed',
  SET_HMR_FORM_TYPE = '[HMR] Set HMR Type',
  GET = '[HMR] Get',
  GET_HMR_FORM_SUCCESS = '[HMR] On Get HMR Form Success',
  GET_HMR_FORM_ERROR = '[HMR] On Get HMR Form Error',
  UPDATE_HMR_NEW_CLAIM_FORMS = '[HMR] Update HMR Child Forms',
  UPDATE_HMR_INTERVIEW_REGISTERED_PHARMACIST_FORMS = '[HMR] Update HMR Child Forms',
  UPDATE_HMR_INTERVIEW_OUTSIDE_PATIENT_HOME_FORMS = '[HMR] Update HMR Child Forms',
  OPEN_HMR_CONFIRM_FORM = '[HMR] Open Confirm Form',
  CLOSE_HMR_CONFIRM_FORM = '[HMR] Close Confirm Form',
  SHOW_INVALID_HMR = '[HMR] Show Invalid Form',

}

export class HMRFormListValid {
  static readonly type = HMRActions.SET_VALID;
  constructor(public valid = false) { }
}

export class ResetHMRFormListAction {
  static readonly type = HMRActions.RESET;
  constructor(public onlyClearFormIds: boolean = false) { }
}

export class SetHMRFormType {
  static readonly type = HMRActions.SET_HMR_FORM_TYPE;
  constructor(public hmrFormType: HMRFormType) { }
}

export class GetHMRForm {
  static readonly type = HMRActions.GET;
  constructor(public clientId: string, public sixCpaFormId: number) { }
}

export class SubmitNewHMR {
  static readonly type = HMRActions.SUBMIT;
  constructor(public clientId: string, public formData: HmrFormModel) { }
}

export class SubmitExisitingHMR {
  static readonly type = HMRActions.UPDATE_SUBMIT;
  constructor(public clientId: string, public formData: HmrFormModel) { }
}

export class SaveNewHMR {
  static readonly type = HMRActions.SAVE;
  constructor(public clientId: string, public formData: HmrFormModel) { }
}

export class SaveExisitingHMR {
  static readonly type = HMRActions.UPDATE_SAVE;
  constructor(public clientId: string, public formData: HmrFormModel) { }
}

export class SaveHMRFormSuccessAction {
  static readonly type = HMRActions.SAVE_SUCCESS;
  constructor(public formData: HmrFormModel) { }
}

export class SaveHMRFormFailedAction {
  static readonly type = HMRActions.SAVE_FAILED;
  constructor(public errors: any) { }
}

export class GetHMRFormSuccess {
  static readonly type = HMRActions.GET_HMR_FORM_SUCCESS;
  constructor(public hmrForm: HmrFormModel) { }
}

export class GetHMRFormError {
  static readonly type = HMRActions.GET_HMR_FORM_ERROR;
  constructor(public error: any) { }
}

export class UpdateHMRNewClaimForms {
  static readonly type = HMRActions.UPDATE_HMR_NEW_CLAIM_FORMS;
  constructor(public hmrForm: HmrFormModel) { }
}
export class UpdateHMRInterviewRegisteredPhamacistForms {
  static readonly type = HMRActions.UPDATE_HMR_INTERVIEW_REGISTERED_PHARMACIST_FORMS;
  constructor(public hmrForm: HmrFormModel) { }
}

export class UpdateHMRInterviewOutsidePatientHomeForms {
  static readonly type = HMRActions.UPDATE_HMR_INTERVIEW_OUTSIDE_PATIENT_HOME_FORMS;
  constructor(public hmrForm: HmrFormModel) { }
}

export class OpenHMRConfirmForm {
  static readonly type = HMRActions.OPEN_HMR_CONFIRM_FORM;
  constructor() { }
}

export class CloseHMRConfirmForm {
  static readonly type = HMRActions.CLOSE_HMR_CONFIRM_FORM;
  constructor() { }
}
export class ShowInvalidHMRForm {
  static readonly type = HMRActions.SHOW_INVALID_HMR;
}
