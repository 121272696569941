import { DAAConsentAndEligibilityFormModel } from "~/shared/models/six-cpa/DAA/daa-consent-and-eligibility-form.model";

export enum DaaConsentAndEligibilityFormActions {
  SET_VALID = '[DaaConsentAndEligibilityForm] set valid',
  UPDATE = '[DaaConsentAndEligibilityForm] Update Form',
  UPDATE_PROGRESS = '[DaaConsentAndEligibilityForm] Update Progress'
}

export class SetDAAConsentAndEligibilityFormValidAction {
  static readonly type = DaaConsentAndEligibilityFormActions.SET_VALID;
  constructor(public valid = false) { }
}

export class SetTotalItemsAndCompletedItems {
  static readonly type = DaaConsentAndEligibilityFormActions.UPDATE_PROGRESS;
  constructor(public totalItems, public completedItems) { }
}

export class UpdateDAAConsentAndEligibilityForm {
  static readonly type = DaaConsentAndEligibilityFormActions.UPDATE;
  constructor(public form: DAAConsentAndEligibilityFormModel) { }
}
