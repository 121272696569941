<form [formGroup]="patientDetailsForm" ngxsForm="patientDetailsFormDetailed.patientDetailsForm" class="p-4">
  <div class="form-group pb-1">
    <label for="patientFirstName" class="input-label">Patients First Name</label>
    <input type="text" 
    [class.has-error]="formControls.patientFirstName.invalid && (showInvalid$ | async)"
 formControlName="patientFirstName" class="form-control form-control-lg w-50"
      id="patientFirstName" placeholder="Patients First Name" required>
  </div>
  <div class="form-group pb-1">
    <label for="patientSurname" class="input-label">Patients Surname</label>
    <input [class.has-error]="formControls.patientSurname.invalid && (showInvalid$ | async)"
    type="text" formControlName="patientSurname" class="form-control form-control-lg w-50" id="patientSurname"
      placeholder="Patients Surname" required>
  </div>
  <div class="form-group pb-1">
    <label for="patientDOB" class="input-label">Patients Date of Birth</label>
    <input [class.has-error]="formControls.patientDOB.invalid && (showInvalid$ | async)"
    type="text" class="form-control form-control-lg w-25" value="patientDetailsForm.value.patientDOB"
      formControlName="patientDOB" id="patientDOB" [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY' }"
      placeholder="dd-mm-yyyy" bsDatepicker>
  </div>
  <div class="form-group pb-1">
    <label for="medicareNumber" class="input-label">Medicare/DVA Number</label>
    <input [class.has-error]="formControls.medicareNumber.invalid && (showInvalid$ | async)"
    type="text" class="form-control form-control-lg w-25" name="medicareNumber" formControlName="medicareNumber"
      id="medicareNumber" placeholder="123456789" required>
  </div>
  <div class="form-group pb-1">
    <div>
      <label class="input-label">Patients Gender</label>
    </div>
    <app-radio-highlight 
    [hasError]="formControls.gender.invalid && (showInvalid$ | async)" 
    controlName="genderMale" [isHighlighted]="patientDetailsForm.value.gender == 'M'">
      <input class="form-check-input " type="radio" name="gender" formControlName="gender" id="genderMale" value="M">
      <label class="form-check-label" for="genderMale">Male</label>
    </app-radio-highlight>
    <app-radio-highlight
    [hasError]="formControls.gender.invalid && (showInvalid$ | async)"
     controlName="genderFemale" [isHighlighted]="patientDetailsForm.value.gender == 'F'">
      <input class="form-check-input" type="radio" name="gender" formControlName="gender" id="genderFemale" value="F">
      <label class="form-check-label" for="genderFemale">Female</label>
    </app-radio-highlight
    >
  </div>
  <div class="form-group pb-1">
    <label for="residentialPostCode" class="input-label">Patients Residential Postcode</label>
    <input
    [class.has-error]="formControls.residentialPostCode.invalid && (showInvalid$ | async)"
 type="text" class="form-control form-control-lg w-25" formControlName="residentialPostCode"
      id="residentialPostCode" placeholder="2000" required>
  </div>
  <div class="form-group pb-1">
    <div>
      <label class="input-label-not-required">Does the patient have Diabetes?</label>
    </div>
    <app-radio-highlight controlName="diabeticYes"
    [hasError]="formControls.patientHasDiabetes.invalid && (showInvalid$ | async)"
      [isHighlighted]="patientDetailsForm.value.patientHasDiabetes == true">
      <input class="form-check-input" type="radio" name="patientHasDiabetes" id="diabeticYes"
        formControlName="patientHasDiabetes" [value]="true" checked>
      <label class="form-check-label " for="diabeticYes">Yes</label>
    </app-radio-highlight>
    <app-radio-highlight controlName="diabeticNo"
    [hasError]="formControls.patientHasDiabetes.invalid && (showInvalid$ | async)"
      [isHighlighted]="patientDetailsForm.value.patientHasDiabetes == false">
      <input class="form-check-input" type="radio" name="patientHasDiabetes" id="diabeticNo"
        formControlName="patientHasDiabetes" [value]="false">
      <label class="form-check-label" for="diabeticNo">No</label>
    </app-radio-highlight>
  </div>
  <div class="form-group pb-1">
    <div>
      <label class="input-label">Is English the patient’s primary language at home?</label>
    </div>
    <app-radio-highlight controlName="isEnglishPrimaryLanguageYes"
    [hasError]="formControls.englishAtHome.invalid && (showInvalid$ | async)"
      [isHighlighted]="patientDetailsForm.value.englishAtHome == true">
      <input class="form-check-input" type="radio" name="englishAtHome" id="isEnglishPrimaryLanguageYes"
        formControlName="englishAtHome" [value]="true" checked>
      <label class="form-check-label " for="isEnglishPrimaryLanguageYes">Yes</label>
    </app-radio-highlight>
    <app-radio-highlight controlName="isEnglishPrimaryLanguageNo"
    [hasError]="formControls.englishAtHome.invalid && (showInvalid$ | async)"
      [isHighlighted]="patientDetailsForm.value.englishAtHome == false">
      <input class="form-check-input" type="radio" name="englishAtHome" id="isEnglishPrimaryLanguageNo"
        formControlName="englishAtHome" [value]="false">
      <label class="form-check-label" for="isEnglishPrimaryLanguageNo">No</label>
    </app-radio-highlight>
  </div>
  <div class="form-group pb-1">
    <div>
      <label class="input-label-not-required">Does the patient identify as Aboriginal and/or Torres Strait
        Islander?</label>
    </div>
    <app-radio-highlight controlName="aboriginalYes"
    [hasError]="formControls.isPatientAboriginalOrTSI.invalid && (showInvalid$ | async)"
      [isHighlighted]="patientDetailsForm.value.isPatientAboriginalOrTSI == true">
      <input class="form-check-input" type="radio" name="isPatientAboriginalOrTSI" id="aboriginalYes"
        formControlName="isPatientAboriginalOrTSI" [value]="true">
      <label class="form-check-label" for="aboriginalYes">Yes</label>
    </app-radio-highlight>
    <app-radio-highlight controlName="aboriginalNo"
    [hasError]="formControls.isPatientAboriginalOrTSI.invalid && (showInvalid$ | async)"
      [isHighlighted]="patientDetailsForm.value.isPatientAboriginalOrTSI == false">
      <input class="form-check-input" type="radio" name="isPatientAboriginalOrTSI" id="aboriginalNo"
        formControlName="isPatientAboriginalOrTSI" [value]="false" checked>
      <label class="form-check-label" for="aboriginalNo">No</label>
    </app-radio-highlight>
  </div>
  <div class="form-group pb-1">
    <div>
      <label class="input-label">Reviewed By Pharmacist?</label>
    </div>
    <app-radio-highlight 
    [hasError]="formControls.reviewed.invalid && (showInvalid$ | async)"
    controlName="reviewedTrue" [isHighlighted]="patientDetailsForm.value.reviewed == true">
      <input class="form-check-input " type="radio" name="reviewed" formControlName="reviewed" id="reviewedTrue"
        [value]="true">
      <label class="form-check-label" for="reviewedTrue">Yes</label>
    </app-radio-highlight>
    <app-radio-highlight  [hasError]="formControls.reviewed.invalid && (showInvalid$ | async)"
     controlName="reviewedFalse" [isHighlighted]="patientDetailsForm.value.reviewed == false">
      <input class="form-check-input " type="radio" name="reviewed" formControlName="reviewed" id="reviewedFalse"
        [value]="false">
      <label class="form-check-label" for="reviewedFalse">No</label>
    </app-radio-highlight>
  </div>
</form>
