import { State, Selector, Action, StateContext } from '@ngxs/store';
import { UpdateHMRGPDetailsReferralForm } from './hmr-gp-details-referral-form.actions';
import { IGPDetailsReferralFormStateModel } from './hmr-gp-details-referral-form-state-model.interface';
import * as moment_ from 'moment';
const moment = moment_;
import { Injectable } from '@angular/core';

export interface IHMRGPDetailsReferralFormDetailedStateModel extends IGPDetailsReferralFormStateModel {
  referralDate: Date | string | null;
  otherReasonForReferral: string | null;
  reasonForReferral: {
    polypharmacy: boolean;
    suspectedAdverseEvent: boolean;
    usingMedicinesWithNarrowTherapeuticRange: boolean;
    other: boolean;
  };
}

  export class HMRGPDetailsReferralFormDetailedStateModel {
    gpDetailsReferralForm: {
      model: Partial<IHMRGPDetailsReferralFormDetailedStateModel>;
    };
  }

  @State<HMRGPDetailsReferralFormDetailedStateModel>({
    name: 'hmrGPDetailsReferralFormDetailed',
    defaults: {
      gpDetailsReferralForm: {
        model: undefined
      }
    }
  })
  @Injectable()
  export class HMRGPDetailsReferralFormDetailedState {
    constructor() {}
    @Selector()
    static form(state: HMRGPDetailsReferralFormDetailedStateModel) { return state.gpDetailsReferralForm.model; }

    @Action(UpdateHMRGPDetailsReferralForm)
    updateForm(ctx: StateContext<HMRGPDetailsReferralFormDetailedStateModel>, { form }: UpdateHMRGPDetailsReferralForm) {
      if (form) {
        const {firstName, surname, otherReasonForReferral, providerNumber, reasonForReferral, referralDate} = form;

        ctx.patchState({
          gpDetailsReferralForm: {
            model: {
              firstName,
              otherReasonForReferral,
              providerNumber,
              reasonForReferral,
              referralDate: moment(referralDate, 'YYYY-MM-DD').toDate(),
              surname
            }
          }
        });
      } else {
        const state = ctx.getState();
        ctx.patchState({
          gpDetailsReferralForm: {
            model: {
              ...state.gpDetailsReferralForm.model,
              otherReasonForReferral: null,
              providerNumber: null,
              reasonForReferral: null,
              referralDate: null
            }
          }
        });
      }

    }
  }
