import { Injectable } from "@angular/core";
import { BaseApiNoAlerts } from "~/shared/core/base-api/base-api-no-alerts";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { StorageService } from "~/shared/core/storage/storage.service";
import { AlertService } from "~/modules/core/alert/alert.service";
import { PharmacyCommsDetails } from "~/shared/models/pharmacy/pharmacy-comms-details.model";
import { Observable } from "rxjs";
import { environment } from "../../../../../environments/environment.prod";

const API = environment.baseApiUrl;

@Injectable()
export class PharmacyCommsDetailsService extends BaseApiNoAlerts {
  constructor(public http: HttpClient, public router: Router, public storageService: StorageService, public alertService: AlertService) {
    super(http, router, storageService, alertService);
  }

  get(): Observable<PharmacyCommsDetails> {
    return this.http.get<PharmacyCommsDetails>(`${API}/api/tenant/comm-settings`);
  }

  create(model: PharmacyCommsDetails): Observable<PharmacyCommsDetails> {
    return this.http.post<PharmacyCommsDetails>(`${API}/api/tenant/comm-settings`, model);
  }
  update(model: PharmacyCommsDetails): Observable<PharmacyCommsDetails> {
    return this.http.put<PharmacyCommsDetails>(`${API}/api/tenant/comm-settings`, model);
  }

}
