import { State, Action, StateContext, Selector } from '@ngxs/store';
import { HMRService } from '../services/hmr.service';
import {
  SetHMRFormType,
  SaveHMRFormSuccessAction,
  SaveHMRFormFailedAction,
  SubmitNewHMR,
  SubmitExisitingHMR,
  SaveNewHMR,
  SaveExisitingHMR,
  GetHMRForm,
  GetHMRFormSuccess,
  UpdateHMRNewClaimForms,
  UpdateHMRInterviewRegisteredPhamacistForms,
  UpdateHMRInterviewOutsidePatientHomeForms,
  GetHMRFormError,
  OpenHMRConfirmForm,
  CloseHMRConfirmForm,
  ShowInvalidHMRForm
} from './hmr-form.actions';
import { switchMap, catchError, finalize } from 'rxjs/operators';
import { ToggleSixCpaAction, SetSelectedFormType, SetSixCpaFormSummary } from '../../../state/six-cpa.actions';
import { UpdateHMRPatientDetailsForm } from '../../hmr-patient-details-form/state/hmr-patient-details-form.actions';
import { AlertService } from '../../../../../core/alert/alert.service';
import { UpdateHMRConsentAndEligibility } from '../../hmr-consent-and-eligibility/state/hmr-consent-and-eligibility.actions';
import { UpdateHMRPharmacyInformation } from '../../hmr-pharmacy-information/state/hmr-pharmacy-information.actions';
import { UpdateHMRGPDetailsReferralForm } from '../../hmr-gp-details-referral-form/state/hmr-gp-details-referral-form.actions';
import { UpdateHMRLocation } from '../../hmr-location/state/hmr-location.actions';
import { UpdateHMRServiceProviderDetailsForm } from '../../hmr-service-provider-details/state/hmr-service-provider-details-form.actions';
import { UpdateHMRProposedDetailsForm } from '../../hmr-proposed-details/state/hmr-proposed-details-form.actions';
import { UpdateHMRAccreditedPharmacistDetails } from '../../hmr-accredited-pharmacist-details/state/hmr-accredited-pharmacist-details.actions';
import { SetSixCpaSuccessMessages, SetSixCpaErrors, ClearSixCpaCommunications } from '../../../six-cpa-user-communications/state/six-cpa-user-communications.actions';
import { Injectable } from '@angular/core';
import { SaveOrUpdateHMRFormAction } from './hmr-form-save.actions';
import { closeConfirmationActions } from '../../../state/six-cpa-toggles-list';
import { GetSixCpaFormsFromService } from '../../../../profile-services/state/profile-services.actions';
import { ResetHMRFormListAction } from './hmr-form.actions';
import { SixCpaFormTypes } from '../../../six-cpa-form-types.enum';
import { SixCpaResponse } from '../../../../profile-services/state/profile-services.state';
import { SetSixCpaInfo } from '../../../six-cpa-user-communications/state/six-cpa-user-communications.actions';
import { PPASuccessMessages } from '../../../../../../shared/constants/success-messages/ppa-success-messages.constant';

export class HMRFormStateModel {
  hmrId: number;
  sixCpaFormId: number;
  patientDetailsFormId: number;
  gpDetailsReferralFormId: number;
  pharmacyInformationFormId: number;
  serviceProviderDetailsFormId: number;
  proposedDetailsFormId: number;
  locationFormId: number;
  accreditedPharmacistDetailsFormId: number;
  consentAndEligibilityFormId: number;
  valid: boolean;
  hmrFormType: HMRFormType;
  saving: boolean;
  error: any;
  showConfirmForm: boolean;
  showInvalid: boolean;

}

export enum HMRFormType {
  NewClaim,
  InterviewRegisteredPhamacist,
  InterviewOutsidePatientHome
}
@Injectable()
@State<HMRFormStateModel>({
  name: 'hmrForm',
  defaults: {
    hmrId: null,
    sixCpaFormId: null,
    patientDetailsFormId: null,
    gpDetailsReferralFormId: null,
    pharmacyInformationFormId: null,
    serviceProviderDetailsFormId: null,
    proposedDetailsFormId: null,
    locationFormId: null,
    accreditedPharmacistDetailsFormId: null,
    consentAndEligibilityFormId: null,
    valid: false,
    hmrFormType: HMRFormType.NewClaim,
    saving: false,
    error: null,
    showConfirmForm: false,
    showInvalid: false,
  }
})
export class HMRFormState {
  constructor(private hmrService: HMRService, private alertService: AlertService) { }

  @Selector()
  static saving(state: HMRFormStateModel) { return state.saving; }

  @Selector()
  static hmrFormType(state: HMRFormStateModel) { return state.hmrFormType; }

  @Selector()
  static sixCpaFormId(state: HMRFormStateModel) { return state.sixCpaFormId; }

  @Selector()
  static showConfirmForm(state: HMRFormStateModel) { return state.showConfirmForm; }

  @Selector()
  static showInvalid(state: HMRFormStateModel) { return state.showInvalid; }

  @Action(SetHMRFormType)
  setHMRFormType(ctx: StateContext<HMRFormStateModel>, action: SetHMRFormType) {
    ctx.patchState({
      hmrFormType: action.hmrFormType
    });
  }

  @Action(ResetHMRFormListAction)
  resetHMRForm(ctx: StateContext<HMRFormStateModel>, {onlyClearFormIds}: ResetHMRFormListAction) {
    ctx.patchState({
      hmrId: null,
      sixCpaFormId: null,
      patientDetailsFormId: null,
      pharmacyInformationFormId: null,
      gpDetailsReferralFormId: null,
      locationFormId: null,
      serviceProviderDetailsFormId: null,
      proposedDetailsFormId: null,
      accreditedPharmacistDetailsFormId: null,
      consentAndEligibilityFormId: null,
      hmrFormType: HMRFormType.NewClaim,
    });
    if (!onlyClearFormIds) {
      ctx.dispatch([
        new UpdateHMRAccreditedPharmacistDetails(null),
        new UpdateHMRConsentAndEligibility(null),
        new UpdateHMRGPDetailsReferralForm(null),
        new UpdateHMRLocation(null),
        new UpdateHMRPatientDetailsForm(null),
        new UpdateHMRPharmacyInformation(null),
        new UpdateHMRProposedDetailsForm(null),
        new UpdateHMRServiceProviderDetailsForm(null),
        new ClearSixCpaCommunications()
      ]);
    }
    
  }

  @Action(SaveHMRFormSuccessAction)
  saveHMRSuccess(ctx: StateContext<HMRFormStateModel>, { formData }: SaveHMRFormSuccessAction) {
    const { id, sixCpaFormId, patientDetailsFormId, pharmacyInformationFormId, gpDetailsReferralFormId,
      locationFormId, serviceProviderDetailsFormId, proposedDetailsFormId, accreditedPharmacistDetailsFormId,
      consentAndEligibilityFormId, hasSubmitted
    } = formData;
    ctx.patchState({
      hmrId: id,
      sixCpaFormId,
      patientDetailsFormId,
      pharmacyInformationFormId,
      gpDetailsReferralFormId,
      locationFormId,
      serviceProviderDetailsFormId,
      proposedDetailsFormId,
      accreditedPharmacistDetailsFormId,
      consentAndEligibilityFormId
    });
    ctx.dispatch([
      new SetSixCpaFormSummary({
        id: sixCpaFormId,
        formType: SixCpaFormTypes.HMR,
        hasSubmitted: hasSubmitted
      } as SixCpaResponse)
    ]);
    ctx.dispatch(new GetSixCpaFormsFromService(null));
  }


  @Action(SaveHMRFormFailedAction)
  saveMedsCheckFailed(ctx: StateContext<HMRFormStateModel>, { errors }: SaveHMRFormFailedAction) {
    if (errors.error[0].value) {
      errors.error.forEach(err => {
        this.alertService.error(`Please check the following error: ${err.value}`);
        ctx.patchState({
          error: err
        });
      });
    } else {
      this.alertService.error(errors.error);
      ctx.dispatch(new SetSixCpaErrors([errors.error]));
    }
  }
  @Action(SubmitNewHMR)
  submitNewHMR(ctx: StateContext<HMRFormStateModel>, { clientId, formData }: SubmitNewHMR) {
    ctx.patchState({ saving: true, showConfirmForm: false });
    return this.hmrService.postSubmit(clientId, formData).pipe(
      switchMap(response => ctx.dispatch([new SaveHMRFormSuccessAction(response), new SetSixCpaSuccessMessages([PPASuccessMessages.submitSucess])])),
      catchError(error => ctx.dispatch([new SaveHMRFormFailedAction(error), new SetSixCpaInfo(["We were unable to submit the claim so we've saved it as a draft."]), new SaveOrUpdateHMRFormAction(true)])),
      finalize(() => {
        ctx.patchState({ saving: false });
      }));
  }

  @Action(SubmitExisitingHMR)
  submitExisitingHMR(ctx: StateContext<HMRFormStateModel>, { clientId, formData }: SubmitExisitingHMR) {
    ctx.patchState({ saving: true, showConfirmForm: false });
    return this.hmrService.putSubmit(clientId, formData).pipe(
      switchMap(response => ctx.dispatch([new SaveHMRFormSuccessAction(response), new SetSixCpaSuccessMessages([PPASuccessMessages.submitSucess])])),
      catchError(error => ctx.dispatch([new SaveHMRFormFailedAction(error), new SetSixCpaInfo(["We were unable to submit the claim so we've saved it as a draft."]), new SaveOrUpdateHMRFormAction(true)])),
      finalize(() => {
        ctx.patchState({ saving: false });
      }));
  }

  @Action(SaveNewHMR)
  saveNewHMR(ctx: StateContext<HMRFormStateModel>, { clientId, formData }: SaveNewHMR) {
    ctx.patchState({ saving: true });
    return this.hmrService.postSave(clientId, formData).pipe(
      switchMap(response => ctx.dispatch([new SaveHMRFormSuccessAction(response), new SetSixCpaSuccessMessages(['HMR draft form saved to scrypt.'])])),
      catchError(error => ctx.dispatch(new SaveHMRFormFailedAction(error))),
      finalize(() => ctx.patchState({ saving: false })));
  }

  @Action(SaveExisitingHMR)
  saveHMR(ctx: StateContext<HMRFormStateModel>, { clientId, formData }: SaveExisitingHMR) {
    ctx.patchState({ saving: true });
    return this.hmrService.putSave(clientId, formData).pipe(
      switchMap(response => ctx.dispatch([new SaveHMRFormSuccessAction(response), new SetSixCpaSuccessMessages(['HMR draft form saved to scrypt.'])])),
      catchError(error => ctx.dispatch(new SaveHMRFormFailedAction(error))),
      finalize(() => ctx.patchState({ saving: false })));
  }

  @Action(GetHMRForm)
  getHMRForm(ctx: StateContext<HMRFormStateModel>, { clientId, sixCpaFormId }: GetHMRForm) {
    return this.hmrService.getHMRForm(clientId, sixCpaFormId)
      .pipe(switchMap(response => ctx.dispatch([new GetHMRFormSuccess(response), new ToggleSixCpaAction('in')])),
        catchError(err => ctx.dispatch(new GetHMRFormError(err))));
  }

  @Action(GetHMRFormSuccess)
  getHMRSuccess(ctx: StateContext<HMRFormStateModel>, { hmrForm }: GetHMRFormSuccess) {
    const {
      id,
      sixCpaFormId,
      patientDetailsFormId,
      gpDetailsReferralFormId,
      locationFormId,
      serviceProviderDetailsFormId,
      proposedDetailsFormId,
      accreditedPharmacistDetailsFormId,
      consentAndEligibilityFormId,
      hmrFormType
    } = hmrForm;
    ctx.patchState({
      hmrId: id,
      sixCpaFormId,
      patientDetailsFormId,
      gpDetailsReferralFormId,
      locationFormId,
      serviceProviderDetailsFormId,
      proposedDetailsFormId,
      accreditedPharmacistDetailsFormId,
      consentAndEligibilityFormId,
      hmrFormType
    });

    ctx.dispatch(closeConfirmationActions);

    switch (hmrForm.hmrFormType) {
      case HMRFormType.NewClaim:
        ctx.dispatch(new UpdateHMRNewClaimForms(hmrForm));
        break;
      case HMRFormType.InterviewRegisteredPhamacist:
        ctx.dispatch(new UpdateHMRInterviewRegisteredPhamacistForms(hmrForm));
        break;
      case HMRFormType.InterviewOutsidePatientHome:
        ctx.dispatch(new UpdateHMRInterviewOutsidePatientHomeForms(hmrForm));
        break;
    }
    ctx.dispatch([
      new SetSelectedFormType(SixCpaFormTypes.HMR)
    ]);

  }

  @Action(UpdateHMRNewClaimForms)
  updateHMRNewClaimForms(ctx: StateContext<HMRFormStateModel>, { hmrForm }: UpdateHMRNewClaimForms) {
    ctx.dispatch([
      new UpdateHMRPatientDetailsForm(hmrForm.patientDetailsForm),
      new UpdateHMRConsentAndEligibility(hmrForm.consentAndEligibilityForm),
      new UpdateHMRPharmacyInformation(hmrForm.pharmacyInformationForm),
      new UpdateHMRGPDetailsReferralForm(hmrForm.gpDetailsReferralForm),
      new UpdateHMRLocation(hmrForm.locationForm)
    ]);
  }

  @Action(UpdateHMRInterviewRegisteredPhamacistForms)
  updateHMRInterviewRegisteredPhamacistForms(ctx: StateContext<HMRFormStateModel>, { hmrForm }: UpdateHMRInterviewRegisteredPhamacistForms) {
    ctx.dispatch([
      new UpdateHMRPatientDetailsForm(hmrForm.patientDetailsForm),
      new UpdateHMRServiceProviderDetailsForm(hmrForm.serviceProviderDetailsForm),
      new UpdateHMRGPDetailsReferralForm(hmrForm.gpDetailsReferralForm),
      new UpdateHMRProposedDetailsForm(hmrForm.proposedDetailsForm)
    ]);
  }

  @Action(UpdateHMRInterviewOutsidePatientHomeForms)
  updateHMRInterviewOutsidePatientHomeForms(ctx: StateContext<HMRFormStateModel>, { hmrForm }: UpdateHMRInterviewOutsidePatientHomeForms) {
    ctx.dispatch([
      new UpdateHMRPatientDetailsForm(hmrForm.patientDetailsForm),
      new UpdateHMRProposedDetailsForm(hmrForm.proposedDetailsForm),
      new UpdateHMRServiceProviderDetailsForm(hmrForm.serviceProviderDetailsForm),
      new UpdateHMRAccreditedPharmacistDetails(hmrForm.accreditedPharmacistDetailsForm),
      new UpdateHMRGPDetailsReferralForm(hmrForm.gpDetailsReferralForm)
    ]);
  }

  @Action(GetHMRFormError)
  getHMRFormError(ctx: StateContext<HMRFormStateModel>, action: GetHMRFormError) {
    ctx.patchState({
      error: action.error
    });
  }

  @Action(OpenHMRConfirmForm)
  openConfirmForm(ctx: StateContext<HMRFormStateModel>, action: OpenHMRConfirmForm) {
    ctx.patchState({ showConfirmForm: true });
  }

  @Action(CloseHMRConfirmForm)
  closeConfirmForm(ctx: StateContext<HMRFormStateModel>, action: CloseHMRConfirmForm) {
    ctx.patchState({ showConfirmForm: false });
  }

  @Action(ShowInvalidHMRForm)
  showInvalidHMRForm(ctx: StateContext<HMRFormStateModel>) {
    ctx.patchState({
      showInvalid: true
    });
  }
}
