import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Store, Actions } from '@ngxs/store';
import { DAAChildForm } from '../daa-child-form';
import { DAAConsentAndEligibilityFormState } from './state/daa-consent-and-eligibility-form.state';
import { SetTotalItemsAndCompletedItems } from './state/daa-consent-and-eligibility-form.actions';
import { WindowService } from '../../../../../shared/services/window.service';
//import { environment } from '../../../../../../environments/environment';
import { SixCpaState } from '../../state/six-cpa.state';

@Component({
  selector: 'app-daa-consent-and-eligibility-form',
  templateUrl: './daa-consent-and-eligibility-form.component.html',
  styleUrls: ['./daa-consent-and-eligibility-form.component.scss']
})
export class DaaConsentAndEligibilityFormComponent extends DAAChildForm implements OnInit {
  daaConsentAndEligibilityForm: FormGroup;
  formSubscription: Subscription;
  private readonlySubscription: Subscription;

  constructor(private formBuilder: FormBuilder, private store: Store, actions: Actions, private windowService: WindowService) {
    super(actions);
    this.generateFormControls();
  }

  openDAAConsentForm() {
    // this.windowService.open(environment.daaConsentPdf);
    this.windowService.open('');
  }

  ngOnInit() {
    this.formSubscription = this.store.select(DAAConsentAndEligibilityFormState.daaConsentAndEligibilityForm)
      .subscribe(() => this.onFormChange());
    this.readonlySubscription = this.store.select(SixCpaState.formsReadOnly)
      .subscribe(readonly => readonly ? this.daaConsentAndEligibilityForm.disable() : this.daaConsentAndEligibilityForm.enable());
  }

  ngOnDestroy() {
    this.formSubscription.unsubscribe();
    this.readonlySubscription.unsubscribe();
  }

  get formControls() {
    return this.daaConsentAndEligibilityForm.controls;
  }
  protected actionOnResetForm() {
    this.daaConsentAndEligibilityForm.reset();
    this.daaConsentAndEligibilityForm.patchValue(
      {
        writtenConsentForServiceProvision: false,
        writtenConsentForProvisionOfData: false,
      });
  }

  private onFormChange() {
    const controls = Object.keys(this.daaConsentAndEligibilityForm.controls);
    const completedItems = this.getTotalValidItems(controls);
    this.store.dispatch(new SetTotalItemsAndCompletedItems(controls.length, completedItems));
  }

  private getTotalValidItems(controls: Array<string>) {
    return controls.filter((key) => {
      const formControl = this.daaConsentAndEligibilityForm.get(key);
      return  formControl.status === 'VALID' || (formControl.status === 'DISABLED' && !formControl.invalid && formControl.errors === null);
    }).length;
  }
  

  private generateFormControls() {
    this.daaConsentAndEligibilityForm = this.formBuilder.group({
      writtenConsentForServiceProvision: new FormControl(true, Validators.requiredTrue),
      writtenConsentForProvisionOfData: new FormControl(true, Validators.requiredTrue)
    });
  }
}
