import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DocumentRoutingModule } from './document-routing.module';
import { SystemModule } from '~/system/system.module';
import { DashboardModule } from '~/modules/dashboard/dashboard.module';
import { AuthModule } from '~/modules/core/auth/auth.module';
import { DocumentRouteComponent } from './document-route.component';

@NgModule({
  declarations: [DocumentRouteComponent],
  imports: [
    CommonModule,
    AuthModule,
    DocumentRoutingModule,
    SystemModule,
    DashboardModule
  ]
})
export class DocumentRouteModule { }
