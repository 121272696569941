import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { Select } from '@ngxs/store';
import { MedsCheckType, MedsCheckFormListState } from '../meds-check/state/meds-check-form-list.state';
import { ConsentEligibilityFormState, ConsentEligibilityFormStateModel } from '../consent-eligibility-form/state/consent-eligibility-form.state';

@Component({
  selector: 'app-consent-eligibility-form-list-item',
  templateUrl: './consent-eligibility-form-list-item.component.html'
})
export class ConsentEligibilityFormListItemComponent {
  @Select(MedsCheckFormListState.medsCheckType) medsCheckType$: Observable<MedsCheckType>;
  @Select(ConsentEligibilityFormState) formState$: Observable<ConsentEligibilityFormStateModel>;
  medsCheckTypes = MedsCheckType;
}
