import { Component, Input, Output, OnInit, EventEmitter, SimpleChanges } from '@angular/core';
import { NoteTypeEnum } from '~/shared/models/note/note-type.enum';
import { Note } from '../../../../shared/models/crm/note.model';

@Component({
  selector: 'app-note-actions',
  templateUrl: './note-actions.component.html',
  styleUrls: ['./note-actions.component.scss']
})
export class NoteActionComponent implements OnInit {
  @Input() note: Note;
  @Input() checked: any;
  @Output() selectChanged: EventEmitter<any> = new EventEmitter<any>();
  @Output() clickTick: EventEmitter<Note> = new EventEmitter<Note>();
  @Output() clickEdit: EventEmitter<Note> = new EventEmitter<Note>();
  @Output() clickRemove: EventEmitter<Note> = new EventEmitter<Note>();
  @Output() clickLink: EventEmitter<Note> = new EventEmitter<Note>();

  isHiddenTick = false;
  canTick: boolean;
  canLink: boolean;
  canSelect: boolean;

  noteTypeEnum = NoteTypeEnum;

  constructor() { }

  ngOnInit() {
    switch (this.note.type) {
      case 0:
      case 1:
      case 3:
      case 4:
        this.standardOptions();
        break;
      case 2:
      case 5:
        this.linkOptions();
        break;
      case 6:
      case 7:
        this.onlyEditOptions();
      break;
      default:
        this.standardOptions();
    }
  }

  // user created actions actions
  private standardOptions = () => {
    this.canTick = true;
    this.canLink = false;
    this.canSelect = true;
  }

  // system gen actions that are only editable
  private onlyEditOptions = () => {
    this.canTick = false;
    this.canLink = false;
    this.canSelect = false;
  }

  // system gen actions that are linked
  private linkOptions = () => {
    this.isHiddenTick = true;
    this.canTick = false;
    this.canLink = true;
    this.canSelect = true;
  }

  selectChange = ($event: SimpleChanges) => {
    this.selectChanged.emit({ event: $event, note: this.note });
  }

  clickedEdit = () => {
    this.clickEdit.emit(this.note);
  }

  clickedTick = () => {
    this.clickTick.emit(this.note);
  }
  clickedDelete = () => {
    this.clickRemove.emit(this.note);
  }
  clickedLink = () => {
    this.clickLink.emit(this.note);
  }
}
