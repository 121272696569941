<div class="recurring-messages">
  <ng-container *ngIf="(profile$ | async) as profile">
    <ng-container *ngIf="(messageCycles$ | async) as messageCycles">
      <section class="message-history">
        <div class="font__title-3 font__light">
          <h5>Reccurring SMS</h5>
        </div>
        <div id="cycles" g-xs="p3 mt2 mb3" class="cycles border__grey" *ngIf="messageCycles.length; else noCycles"
          ngx-auto-scroll observe-attributes>
          <div class="font__title-1">
            <div class="row message-cycle-grid">
              <div *ngFor="let cycle of messageCycles; let i = index;" class="w-50">
                <div class="clearfix" ng-if="i % 2 === 0"></div>
                <div class="card mt-1 col-md">
                  <div class="card-body">
                    <span><strong>Start date:</strong> {{cycle.startDate | tenantDate}}</span><br />
                    <span><strong>Reccurring every:</strong> {{cycle.intervalValue}}
                      {{getIntervalTypeString(cycle.intervalType)}}</span><br />
                    <span><strong *ngIf="cycle.nextScheduledDate">Next scheduled date:</strong>
                      {{cycle.nextScheduledDate | tenantDate}}</span><br />
                    <span><strong>Message:</strong> {{cycle.text}}</span><br />
                    <span *ngIf="cycle.notes"><strong>Notes:</strong> {{cycle.notes}}</span><br /><br />
                    <div>
                      <button class="btn__dark font__title-0 mr3" (click)="openSchedule(cycle)">View Cycle
                        Dates</button>
                      <button class="btn__red font__title-0 mr3" (click)="removeCycle(cycle.id)">Cancel Cycle</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </ng-container>
    <ng-template #noCycles>
      <div class="mt-3 mb-3">
        <span *ngIf="profile?.patient?.mobileNumber">No active reccuring SMS cycles, click below to create.</span>
        <div *ngIf="!profile?.patient?.mobileNumber" class="alert alert-danger w-75 m-3">No patient mobile number found.</div>
      </div>
    </ng-template>
  </ng-container>
  <section class="form-group">
    <button class="btn__green font__title-1 mr3" (click)="openModal()">Create Recurring SMS Cycle</button>
    <span><small><strong>Note: </strong>Edit functionality is coming soon, until then please remove and recreate a cycle
        if changes are needed.</small></span>
  </section>
</div>

<ng-container *ngIf="(showModal$ | async) as showModal">
  <app-message-cycle-modal *ngIf="showModal"></app-message-cycle-modal>
</ng-container>
<ng-container *ngIf="(showSchedule$ | async) as showSchedule">
  <app-message-cycle-schedule-list *ngIf="showSchedule" [messageCycle]="messageCycleToOpen">
  </app-message-cycle-schedule-list>
</ng-container>
