import { Component, OnInit, Input, ViewChild, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngxs/store';
import { PaginationComponent } from 'ngx-bootstrap';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { accordion } from '../../../../shared/core/animations';

import { CommTemplate } from '../../../../shared/models/pharmacy/comm-template.model';
import { CommsQueueItem } from '../models/comms-queue-item.model';
import { ToggleShowMessageModalAction, ToggleShowTemplateModalAction } from '../state/comms-queue.actions';

@Component({
  selector: 'app-comms-queue-table',
  templateUrl: './comms-queue-table.component.html',
  styleUrls: ['./comms-queue-table.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [accordion]
})
export class CommsQueueTableComponent implements OnInit{
  displayedColumns$ = new BehaviorSubject<string[]>([
    'channel',
    'patient',
    'template',
    'medication',
    'sendDate',
    'actions',
  ]);
  dataOnPage$ = new BehaviorSubject<any[]>([]);
  currentPage$ = new BehaviorSubject<number>(1);

  @Input() sortKey: Observable<any>;
  @Input() pageSize: Observable<number>;

  @Input() dataSource: Observable<string>;
  @Input() sortDirection: Observable<string>;

  @ViewChild('pagination') pagination: PaginationComponent;

  constructor(private store: Store) { }

  ngOnInit() {
    combineLatest([this.dataSource, this.currentPage$, this.pageSize])
      .subscribe(([allSources, currentPage, pageSize]) => {
        const startingIndex = (currentPage - 1) * pageSize;
        const onPage = allSources.slice(startingIndex, startingIndex + pageSize);
        this.dataOnPage$.next(<any>onPage);
      });
  }


  showMessage(row: CommsQueueItem) {
    this.store.dispatch(new ToggleShowMessageModalAction(row));
  }

  showTemplate(row: CommTemplate) {
    this.store.dispatch(new ToggleShowTemplateModalAction(row));
  }

}
