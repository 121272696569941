import { State, Selector, Action, StateContext } from '@ngxs/store';
import { UpdateStagedSupplyPatientDetailsForm } from './ss-patient-details-form.actions';
import { IPatientDetailsFormStateModel } from './ss-patient-details-form-state-model.interface';
import * as moment_ from 'moment';
const moment = moment_;
import { SetProfileForSixCpaAction } from '../../../state/six-cpa.actions';
import { TenantDatePipe } from '../../../../../core/timezone/pipes/tenant-date.pipe';
import { getMedicareDVAOrRepatNumber } from '../../../../../../shared/helpers/patient.helper';
import { Injectable } from '@angular/core';

export interface IPatientDetailsFormDetailedStateModel extends IPatientDetailsFormStateModel {
  gender: string;
  residentialPostCode: string;
  englishAtHome: boolean | null;
  aboriginalOrTorresStraitIslander: boolean | null;
}

export class SSPatientDetailsFormDetailedStateModel {
  patientDetailsForm: {
    model: Partial<IPatientDetailsFormDetailedStateModel>;
  };
}
@Injectable()
@State<SSPatientDetailsFormDetailedStateModel>({
  name: 'ssPatientDetailsFormDetailed',
  defaults: {
    patientDetailsForm: {
      model: undefined
    }
  }
})
export class SSPatientDetailsFormDetailedState {
  constructor(private tenantPipe: TenantDatePipe) { }
  @Selector()
  static form(state: SSPatientDetailsFormDetailedStateModel) { return state.patientDetailsForm.model; }

  @Action(SetProfileForSixCpaAction)
  onSetProfile(ctx: StateContext<SSPatientDetailsFormDetailedStateModel>, { profile }: SetProfileForSixCpaAction) {
    const patient = profile.patient;
    ctx.patchState({
      patientDetailsForm: {
        model: {
          firstName: patient.firstname,
          surname: patient.surname,
          dob: patient.birthDate && moment(this.tenantPipe.transform(patient.birthDate.toString(), 'YYYY-MM-DD'), 'YYYY-MM-DD').toDate(),
          medicareNumber: getMedicareDVAOrRepatNumber(patient),
          gender: (patient.gender !== 'M' && patient.gender !== 'F') ? null : patient.gender,
          residentialPostCode: patient.homePostcode ?  patient.homePostcode.toString() : null
        }
      }
    });
  }

  @Action(UpdateStagedSupplyPatientDetailsForm)
  updateForm(ctx: StateContext<SSPatientDetailsFormDetailedStateModel>, { form }: UpdateStagedSupplyPatientDetailsForm) {
    ctx.patchState({
      patientDetailsForm: {
        model: {
          firstName: form.firstName,
          surname: form.surname,
          dob: form.dob && moment(form.dob, 'YYYY-MM-DD').format('DD-MM-YYYY'),
          medicareNumber: form.medicareNumber,
          reviewed: form.reviewed ? form.reviewed : false,
          residentialPostCode: form.residentialPostCode,
          englishAtHome: form.englishAtHome ? form.englishAtHome : true,
          aboriginalOrTorresStraitIslander: form.aboriginalOrTorresStraitIslander ? form.aboriginalOrTorresStraitIslander : false,
        }
      }
    });
  }
}
