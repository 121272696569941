import { Component, OnInit, OnDestroy } from '@angular/core';
import { Select, Store, Actions } from '@ngxs/store';
import { PharmacistState } from '../../../../pharmacy/state/pharmacist.state';
import { Observable, Subscription } from 'rxjs';
import { HMRChildForm } from '../hmr-child-form';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { HMRAccreditedPharmacistDetailsState } from './state/hmr-accredited-pharmacist-details.state';
import { Pharmacist } from '../../../../../shared/models/pharmacy/pharmacist.model';
import { SetTotalItemsAndCompletedItems } from './state/hmr-accredited-pharmacist-details.actions';
import { SixCpaState } from '../../state/six-cpa.state';

const controlsToIgnore = ['pharmacistGivenName', 'pharmacistFamilyName', 'pharmacistEmail', 'pharmacistPhoneNumber', 'pharmacist'];

@Component({
  selector: 'app-hmr-accredited-pharmacist-details',
  templateUrl: './hmr-accredited-pharmacist-details.component.html',
  styleUrls: ['./hmr-accredited-pharmacist-details.component.scss']
})


export class HMRAccreditedPharmacistDetailsComponent extends HMRChildForm implements OnInit, OnDestroy {
  @Select(PharmacistState.pharmacists) pharmacists$: Observable<any>;

  hmrAccreditedPharmacistDetailsForm: FormGroup;
  formSubscription: Subscription;
  private readonlySubscription: Subscription;
  constructor(private formBuilder: FormBuilder, private store: Store, actions: Actions) {
    super(actions);
    this.generateFormControls();
  }

  ngOnInit() {
    const pharmacistList = this.store.selectSnapshot(PharmacistState.pharmacists);
    if (pharmacistList.length > 0) {
      this.setupPharmacistData(pharmacistList);
    }

    this.formSubscription = this.store.select(HMRAccreditedPharmacistDetailsState.hmrAccreditedPharmacistDetailsForm)
      .subscribe(() => this.onFormChange());
    this.readonlySubscription = this.store.select(SixCpaState.formsReadOnly)
      .subscribe(readonly => readonly ? this.hmrAccreditedPharmacistDetailsForm.disable() : this.hmrAccreditedPharmacistDetailsForm.enable());
  }

  ngOnDestroy() {
    if (this.formSubscription) {this.formSubscription.unsubscribe(); }
    if (this.readonlySubscription) {this.readonlySubscription.unsubscribe(); }
  }
  get formControls() {
    return this.hmrAccreditedPharmacistDetailsForm.controls;
  }
  protected actionOnResetForm() {
    const controls = Object.keys(this.hmrAccreditedPharmacistDetailsForm.controls);
    controls.filter((key) => {
      if (!controlsToIgnore.includes(key)) {
        this.hmrAccreditedPharmacistDetailsForm.controls[key].setValue('');
      }
    });
    const pharmacistList = this.store.selectSnapshot(PharmacistState.pharmacists);
    this.setupPharmacistData(pharmacistList);
  }

  setPharmacistData() {
    const pharmacist = this.store.selectSnapshot(PharmacistState.pharmacists)
      .filter((x) => `${x.givenNames} ${x.familyName}` === this.hmrAccreditedPharmacistDetailsForm.value.pharmacist);
    this.setupPharmacistData(pharmacist);
  }

  private setupPharmacistData (pharmacistList: Pharmacist[]) {
    if (pharmacistList.length > 0) {
      const { givenNames, mrnOrSHPAAccreditationNumber, familyName, pharmacistPhone, pharmacistEmail } = pharmacistList[0];
      this.hmrAccreditedPharmacistDetailsForm.patchValue({
        pharmacist: `${givenNames} ${familyName}`,
        pharmacistGivenName: givenNames,
        pharmacistFamilyName: familyName,
        pharmacistPhoneNumber: pharmacistPhone,
        pharmacistEmail: pharmacistEmail,
        accreditationNumber: mrnOrSHPAAccreditationNumber,
       });
    }
  }

  private onFormChange() {
    const controls = Object.keys(this.hmrAccreditedPharmacistDetailsForm.controls);
    const completedItems = this.getTotalValidItems(controls);
    this.store.dispatch(new SetTotalItemsAndCompletedItems(controls.length, completedItems));
  }

  private getTotalValidItems(controls: Array<string>) {
    return controls.filter((key) => {
      const formControl = this.hmrAccreditedPharmacistDetailsForm.get(key);
      return  formControl.status === 'VALID' || (formControl.status === 'DISABLED' && !formControl.invalid && formControl.errors === null);
    }).length;
  }

  private generateFormControls() {
    this.hmrAccreditedPharmacistDetailsForm = this.formBuilder.group({
      pharmacist: new FormControl('', Validators.required),
      accreditationNumber: new FormControl('', Validators.required),
      pharmacistGivenName: new FormControl('', Validators.required),
      pharmacistFamilyName: new FormControl('', Validators.required),
      pharmacistPhoneNumber: new FormControl('', Validators.required),
      pharmacistEmail: new FormControl('', [Validators.required, Validators.email]),
    });
  }

}
