import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DocumentRoutingModule } from './document-routing.module';
import { DocumentComponent } from './document.component';
import { AuthModule } from './auth/auth.module';
import { TimezoneModule } from '../core/timezone/timezone.module';
import { NgxsModule } from '@ngxs/store';
import { DocumentServicesState } from './state/document-services.state';
import { DocumentsService } from '../../shared/services/documents.service';
import { DocumentResolver } from './document.resolver';
import { DocumentListComponent } from './document-list/document-list.component';

@NgModule({
  declarations: [DocumentComponent, DocumentListComponent],
  imports: [
    CommonModule,
    DocumentRoutingModule,
    AuthModule,
    TimezoneModule,
    NgxsModule.forFeature([DocumentServicesState]),
  ],
  exports: [DocumentListComponent],
  providers: [DocumentsService, DocumentResolver],
})
export class DocumentModule {}
