<ng-container *ngIf="(categorised$ | async) as categoryList">
  <ng-container *ngIf="(categoryOptions$ | async) as categoryOptions">
    <ng-container *ngIf="(manageMeta$ | async) as manageMeta">
      <ng-container *ngIf="(stockAdjustment$ | async) as stockAdjustment">
        <ng-container *ngIf="(dosageHistory$ | async) as dosageHistory">
          <ng-container *ngIf="(bulkStockAdjustment$ | async) as bulkStockAdjustment">
            <ng-container *ngIf="(instoreOrder$ | async) as instoreOrder">
              <ng-container *ngIf="(pauseMedicationState$ | async) as pauseMedicationState">
                <div>
                  <h4 class="mb-4">Current medication</h4>
                  <ng-container *ngIf="!(isFetchingPatient$ | async) && !(aslPatientLoadFailed$ | async)">
                    <ng-container *ngIf="{aslDetails: aslDetails$ | async} as aslDetails">
                      <div *ngIf="getConsentState(aslDetails.aslDetails) === consentState.NOT_REGISTERED" class="asl-banner asl__grey p-4">
                        <div class="asl-description">
                          <i class="far fa-info-circle fa-lg mr-3 asl-info"></i>
                          <div>Please register patient for MySL to request consent to their Active Script List.</div>
                        </div>
                        <div class="asl-action">
                          <div>
                            <button class="button btn__yellow" (click)="openRegisterSLModal()">
                              <i *ngIf="!aslService.validatedIHI(profile$ | async)" class="fas fa-exclamation-triangle mr-1"></i>
                              Register with MySL
                            </button>
                          </div>
                        </div>
                      </div>
                      <div *ngIf="getConsentState(aslDetails.aslDetails) === consentState.NO" class="asl-banner asl__yellow p-4">
                        <div class="asl-description">
                          <i class="fas fa-exclamation-triangle fa-lg mr-3 asl-warning"></i>
                          <div>Patient has not yet given consent to access their Active Script List.</div>
                        </div>
                        <div class="asl-action">
                          <div class="asl-view-details">
                            <a (click)="openMySLDetails()">View Details</a>
                          </div>
                          <!--REQUEST CONSENT NOT CURRENTLY WORKING-->
                          <!--                          <div class="ml-4">-->
                          <!--                            <button class="button btn__yellow" (click)="sendConsent()">-->
                          <!--                              Request consent again-->
                          <!--                            </button>-->
                          <!--                          </div>-->
                        </div>
                      </div>
                      <div *ngIf="getConsentState(aslDetails.aslDetails) === consentState.REJECTED" class="asl-banner asl__red p-4">
                        <div class="asl-description">
                          <i class="fas fa-exclamation-triangle fa-lg mr-3 asl-error"></i>
                          <div>Patient has not allowed consent for information access and collection.</div>
                        </div>
                        <div class="asl-action">
                          <div class="asl-view-details">
                            <a (click)="openMySLDetails()">View Details</a>
                          </div>
                          <!--REQUEST CONSENT NOT CURRENTLY WORKING-->
                          <!--                          <div class="ml-4">-->
                          <!--                            <button class="button btn__yellow" (click)="sendConsent()">-->
                          <!--                              Request consent again-->
                          <!--                            </button>-->
                          <!--                          </div>-->
                        </div>
                      </div>
                      <div *ngIf="getConsentState(aslDetails.aslDetails) === consentState.PARTIAL" class="asl-banner asl__yellow p-4">
                        <div class="asl-description">
                          <i class="fas fa-exclamation-triangle fa-lg mr-3 asl-warning"></i>
                          <div>Patient has given partial consent to access their Active Script List.</div>
                        </div>
                        <div class="asl-action">
                          <div class="asl-view-details">
                            <a (click)="openMySLDetails()">View Details</a>
                          </div>
                          <!--REQUEST CONSENT NOT CURRENTLY WORKING-->
                          <!--                          <div class="ml-4">-->
                          <!--                            <button class="button btn__yellow" (click)="sendConsent()">-->
                          <!--                              Request full consent-->
                          <!--                            </button>-->
                          <!--                          </div>-->
                        </div>
                      </div>
                    </ng-container>
                  </ng-container>
                  <ng-container *ngIf="(loading$ | async); else categories">
                    <app-loader></app-loader>
                  </ng-container>

                  <ng-template #categories>
                    <app-asl-medications></app-asl-medications>
                    <div *ngIf="categoryList.length">
                      <div class="category" g-xs="pt4" *ngFor="let category of categoryList">
                        <ng-container *ngFor="let showCat of [showCategory$(category.key.id) | async]">
                          <div class="category__title flex" g-xs="mb1">
                            <h4 class="flex" (click)="toggleCategory(category.key.id)"><i
                                [class.fa-caret-up]="showCat"
                                [class.fa-caret-down]="!showCat"
                                class="fas font__title-4" g-xs="mr1"></i>{{category.key.name}}</h4>
                            <input type="checkbox" g-xs="ml2" [(ngModel)]="category.selected"
                              (change)="toggleCategorySelect(category)" />
                          </div>
                          <div class="collapse-container">
                            <div *ngIf="showCat">
                              <table class="table category-table">
                                <tbody>
                                  <tr>
                                    <th></th>
                                    <th>Brand Name</th>
                                    <th>Generic Name</th>
                                    <th>QTY</th>
                                    <th>Directions</th>
                                    <th>Doctor</th>
                                    <th>Last Disp</th>
                                    <th>Next Disp</th>
                                    <th *ngIf="category.key.id !== 0">Repeats</th>
                                    <th *ngIf="category.key.id !== 0">On File</th>
                                    <th *ngIf="category.key.id !== 0">Total DOH</th>
                                    <th *ngIf="category.key.id !== 0" class="text-center">
                                      Insights
                                    </th>
                                    <th *ngIf="category.key.id === 0">Repeat Frequency & Category</th>
                                  </tr>
                                </tbody>
                                <tbody *ngFor="let item of category.items; let idx = index;">
                                  <ng-container
                                  *ngIf="item.loadingState === drugLoadingState.LOADING; else dataRow">
                                    <tr>
                                      <td colspan="12">
                                        <app-loader loadingMessage="Re-calculating..."></app-loader>
                                      </td>
                                    </tr>
                                  </ng-container>
                                  <ng-template #dataRow>
                                    <tr (click)="toggleScript(item)"
                                      [ngClass]="{'info':item.hasAuthority, 'flagged': item.cMeta.isflagged}">
                                      <td (click)="$event.stopPropagation();">
                                        <input type="checkbox" [ngModel]="item.selected"
                                          (change)="toggleScriptSelect(item)" />
                                      </td>
                                      <td class="primary-drug-name break-word">
                                        {{item.description}}
                                      </td>
                                      <td class="break-word">
                                        {{item.name}}
                                      </td>
                                      <td>
                                        {{item.quantity}}
                                      </td>
                                      <td class="break-word">
                                        {{item.directions}}
                                      </td>
                                      <td *ngIf="item.doctor">
                                        {{item.doctor.firstName ? item.doctor.firstName.substr(0, 1) : ''}}
                                        {{item.doctor.surname}}
                                      </td>
                                      <td class="warning" *ngIf="!item.doctor">Unknown</td>
                                      <td>
                                        {{item.dispensedDate | tenantDate}}
                                      </td>
                                      <td>
                                        <ng-container *ngIf="item.nextRepeatDue">
                                          {{item.nextRepeatDue ? (item.nextRepeatDue | tenantDate):''}}
                                        </ng-container>                                      </td>
                                      <td *ngIf="category.key.id !== 0">
                                        <span>{{item.repeats}} <span style="color: grey;">On File</span></span><br />
                                        <span>{{item.potentialRepeats ? item.potentialRepeats : item.repeats}}  <span style="color: grey;">Total</span></span>
                                      </td>
                                      <td *ngIf="category.key.id !== 0">
                                        <ui-switch size="small" [checked]="item.cMeta.onFile"
                                          (click)="toggleScriptOnFile(item)">
                                        </ui-switch>
                                      </td>
                                    <ng-container *ngIf="item.stock; else noStock">
                                        <td *ngIf="category.key.id !== 0" appTotalDohClass [stock]="item.stock">
                                          <div class="d-flex justify-content-between align-items-center px-1">
                                            <span *ngIf="item.stock.totalDoh?.value === null">
                                              {{item.stock.totalDoh?.isInfinite ? '&infin;' : 'N/A'}}
                                            </span>
                                            <span *ngIf="item.stock.totalDoh?.value !== null">
                                              {{(item.stock.totalDoh.value < 0 ? 0 : item.stock.totalDoh.value) |
                                              number : '1.1-1' }} </span>
                                            <app-scrypt-insight (click)="$event.stopPropagation();"
                                              *ngIf="insightAvailable(item)" [content]="scryptInsightTemplate"
                                              [context]="getInsightContext(item)">
                                            </app-scrypt-insight>
                                          </div>
                                        </td>
                                      </ng-container>
                                      <ng-template #noStock>
                                        <td *ngIf="category.key.id !== 0">N/A</td>
                                      </ng-template>
                                      <td *ngIf="category.key.id !== 0" class="text-center">
                                        <ng-container
                                          *ngIf="item.stock?.totalDoh?.isInfinite || item.stock.totalDoh?.value === null">
                                          <app-scrypt-insight (click)="$event.stopPropagation();"
                                            content="We cannot calculate the days on hand for this medicine, we may not have an accurate starting date OR the frequency may be 0 OR the doses per day may be 0 causing in in-calculatable amount or infinity."
                                            context="context">
                                          </app-scrypt-insight>
                                        </ng-container>
                                        <ng-container
                                          *ngIf="!item.stock?.totalDoh?.isInfinite && item.cMeta.currentStockStartingPoint && item.cMeta.currentStockStartingPoint.changeReason == changeReasons.nonCompliantDoh; else regularSSP">
                                          <app-scrypt-insight (click)="$event.stopPropagation();"
                                            content="The customer was without this medicine for a period before a new script was dispensed. We are now calculating from when the medicine was last dispensed ({{item.cMeta.currentStockStartingPoint.startDate | tenantDate}}) to stay accurate."
                                            context="context">
                                          </app-scrypt-insight>
                                        </ng-container>
                                        <ng-template #regularSSP>
                                          <app-scrypt-insight *ngIf="item.cMeta.currentStockStartingPoint"
                                            (click)="$event.stopPropagation();"
                                            content="We are currently calculating from ({{item.cMeta.currentStockStartingPoint.startDate | tenantDate}}), this will be a reflection of the medicines last adjustment, dispensing or category change."
                                            context="context">
                                          </app-scrypt-insight>
                                        </ng-template>
                                      </td>
                                      <td *ngIf="category.key.id === 0" (click)="$event.stopPropagation()" class="col-width-25 meta-cat">
                                        <span class="rpt-frq">Rpt Freq </span>
                                        <span style="margin-right: 13%;">Category </span>
                                        <form *ngIf="categoryOptions.length; else noCategoryOptions" #f="ngForm"
                                          (ngSubmit)="setUpDrug(item, f)">
                                          <input class="form-control input__primary" #frequency="ngModel"
                                            name="frequency" type="text" [ngModel]="item.repeatFrequency ? item.repeatFrequency : item.quantity" />
                                          <select class="form-control input__primary" name="categoryId"
                                            #categoryId="ngModel" [ngModel]="categoryOptions[0].value">
                                            <option *ngFor="let opt of categoryOptions" value="{{opt.value}}">
                                              {{opt.text}}</option>
                                          </select>
                                          <button type="submit" class="button btn__green"
                                            [disabled]="f.invalid">Go</button>
                                        </form>
                                        <ng-template #noCategoryOptions>
                                          N/A
                                        </ng-template>
                                      </td>
                                    </tr>
                                    <ng-container *ngIf="isDrugOpen$(item) | async">

                                      <tr class="drug-info">
                                        <td colspan="14">
                                          <div class="grid">
                                            <div *ngIf="item.dosageForm" class="grid__unit column column--border"
                                              g-xs="cols:6">
                                              <div class="dosage flex" g-xs="mt2">
                                                <div class="form-group" g-xs="mr2">
                                                  <div class="font__bold">Rpt Frequency</div>
                                                  <input type="text" name="freq" class="form-control width__75"
                                                    [(ngModel)]="item.dosageForm.frequency"
                                                    (change)="matchDpd(item)" />
                                                </div>
                                                <div class="form-group" g-xs="mr2" hidden>
                                                  <div class="font__bold">Doses P/Day</div>
                                                  <input type="text" name="dpd" class="form-control width__75"
                                                    [(ngModel)]="item.dosageForm.dosesPerDay"
                                                    (change)="matchFrequency(item)" />
                                                </div>

                                                <div class="form-group" g-xs="mr2">
                                                  <div class="font__bold">Days on Hand</div>
                                                  <input [readonly]="!item.dosageForm.editDoh" type="text" name="doh"
                                                    class="form-control width__75"
                                                    [(ngModel)]="item.dosageForm.doh" />
                                                </div>

                                                <div class="form group form-check" g-xs="mr2"
                                                  style="margin-bottom: 5%;">
                                                  <input type="checkbox" name="doh" class="form-check-input width__25"
                                                    [(ngModel)]="item.dosageForm.editDoh" id="editDoh" />
                                                  <div class="font__bold form-check-label" for="editDoh">Edit</div>
                                                </div>

                                                <div class="form-group pull-left mt--xs">
                                                  <button (click)="addDosage(item)"
                                                    [disabled]="item.dosageForm.frequency % 1 !== 0 || item.dosageForm.doh % 1 !== 0"
                                                    class="button btn__yellow pull-right">
                                                    Update Dosage
                                                  </button>
                                                  <i class="fas fa-sync fa-spin font__size-5"
                                                    *ngIf="item.dosageLoading"></i>
                                                </div>
                                              </div>
                                              <p class="error" *ngIf="item.dosageForm.frequency % 1 !== 0 || item.dosageForm.doh % 1 !== 0">
                                                Values must
                                                be
                                                a whole number</p>
                                            </div>
                                            <div *ngIf="category.key.id !== 0" class="grid__unit" g-xs="cols:7 pl3">
                                              <ng-container *ngIf="!isLite">
                                                <button g-xs="mr2" (click)="quickCI(item)" type="button"
                                                class="button btn__white quick-ci">CI</button>
                                              </ng-container>
                                              <button g-xs="mr2 px3" (click)="openManageMeta()"
                                                [disabled]="manageMeta.show" type="button"
                                                class="button btn__white">Script Settings</button>
                                              <button g-xs="mr2 px3" (click)="openStockAdjustment()"
                                                [disabled]="stockAdjustment.show" type="button"
                                                class="button btn__white">Stock
                                                Adjustment</button>
                                              <button g-xs="px3" (click)="openDosageHistory()"
                                                [disabled]="dosageHistory.show" class="button btn__white">View
                                                Dosage
                                                History</button>
                                              <button *ngIf="item.repeatTokenUri" g-xs="px3 mt1"
                                                (click)="sendRepeatSMS(item)" class="button btn__white">SMS Repeat
                                              </button>
                                              <button class="button btn__white mt-2"
                                                (click)="deferredScriptClick(item)">
                                                Deferred/Outside Script
                                              </button>
                                            </div>
                                          </div>
                                        </td>
                                      </tr>
                                      <tr class="drug-info"
                                        *ngIf="(features$ | async).scriptHistory as scriptHistory">
                                        <td colspan="14" *ngIf="item.scriptHistory">
                                          <div class="grid">
                                            <app-script-history [scriptHistory]="item.scriptHistory">
                                            </app-script-history>
                                          </div>
                                        </td>
                                      </tr>
                                    </ng-container>
                                  </ng-template>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </ng-container>
                      </div>
                    </div>
                    <app-blade [showBlade]="manageMeta.show" (closeEvent)="closeManageMeta()">
                      <app-manage-meta *ngIf="manageMeta.show"></app-manage-meta>
                    </app-blade>
                    <app-blade [showBlade]="stockAdjustment.show" (closeEvent)="closeStockAdjustment()">
                      <app-stock-adjustment *ngIf="stockAdjustment.show"></app-stock-adjustment>
                    </app-blade>
                    <app-blade [showBlade]="dosageHistory.show" (closeEvent)="closeDosageHistory()">
                      <app-dosage-history *ngIf="dosageHistory.show"></app-dosage-history>
                    </app-blade>
                    <app-multiple-select (orderBladeOpenEvent)="openInstoreOrder()"
                      (bsaBladeOpenEvent)="openBulkStockAdjustment()" (pauseBladeOpenEvent)="openPauseMedication()">
                    </app-multiple-select>
                    <app-pause-popup (openAllPausesBladeEvent)="openAllPauses()"></app-pause-popup>
                    <app-blade [showBlade]="bulkStockAdjustment.show" (closeEvent)="closeBulkStockAdjustment()">
                      <app-bulk-stock-adjustment></app-bulk-stock-adjustment>
                    </app-blade>
                    <app-blade [showBlade]="instoreOrder.show" (closeEvent)="closeInstoreOrder()">
                      <app-instore-order></app-instore-order>
                    </app-blade>
                    <app-blade [showBlade]="pauseMedicationState.show" (closeEvent)="closePauseMedication()">
                      <app-pause-medication></app-pause-medication>
                    </app-blade>
                    <app-blade [showBlade]="pauseMedicationState.showAllPauses" [doubleWidth]="true"
                      (closeEvent)="closeAllPauses()">
                      <app-view-edit-paused-medications></app-view-edit-paused-medications>
                    </app-blade>
                    <ng-template #scryptInsightTemplate let-insights="insights">
                      <ul class="list-group order-insight">
                        <li *ngFor="let insight of insights" class="order-insight__item list-group-item">
                          <span>
                            <app-order-doh-insight [title]="insight.title" [info]="insight.info"
                              [action]="insight.action">
                            </app-order-doh-insight>
                          </span>
                        </li>
                      </ul>
                    </ng-template>
                  </ng-template>
                </div>
              </ng-container>
            </ng-container>
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-container>
