import { Pipe, PipeTransform } from '@angular/core';
import { parsePhoneNumber } from 'awesome-phonenumber';

@Pipe({
  name: 'formatPhoneNumber'
})
export class PhoneNumberPipe implements PipeTransform {
  transform(phoneNumber: string) {
    if (phoneNumber)
      return this.formatToNational(phoneNumber);
  }

  formatToNational(phoneNumber: string) {
    const parsedPhoneNumber = parsePhoneNumber(phoneNumber.trim());
    return parsedPhoneNumber.number.national;
  }
}
