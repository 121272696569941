import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import { ReactiveFormsModule } from '@angular/forms';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { RadioHighlightModule } from '../../../../shared/components/radio-highlight/radio-highlight.module';
import { PatientDetailsFormBasicComponent } from './basic/patient-details-form-basic.component';
import { PatientDetailsFormDetailedComponent } from './detailed/patient-details-form-detailed.component';
import { PatientDetailsFormHostDirective, PatientDetailsFormComponent } from './patient-details-form/patient-details-form.component';

@NgModule({
  declarations: [
    PatientDetailsFormComponent,
    PatientDetailsFormBasicComponent,
    PatientDetailsFormDetailedComponent,
    PatientDetailsFormHostDirective
  ],
  imports: [
    CommonModule,
    NgxsFormPluginModule,
    ReactiveFormsModule,
    BsDatepickerModule.forRoot(),
    RadioHighlightModule
  ],
  exports: [
    PatientDetailsFormComponent
  ]
})
export class PatientDetailsFormModule { }
