import { Directive, ViewContainerRef, Component, OnChanges, Input, ViewChild, Type, ComponentFactoryResolver } from '@angular/core';
import { HasViewContainerRef } from '../../../has-view-container-ref.interface';
import { HMRResolvedForm } from '../../hmr-resolved-form';
import { HMRPatientDetailsFormDetailedComponent } from '../detailed/hmr-patient-details-form-detailed.component';
import { HMRPatientDetailsFormBasicComponent } from '../basic/hmr-patient-details-form-basic.component';
import { HMRFormType } from '../../hmr-form-list/state/hmr-form.state';

@Directive({
  selector: '[appHMRPatientDetailsFormHost]'
})
export class HMRPatientDetailsFormHostDirective implements HasViewContainerRef {
  constructor(public viewContainerRef: ViewContainerRef) { }
}

@Component({
  selector: 'app-hmr-patient-details-form',
  templateUrl: './hmr-patient-details-form.component.html'
})
export class HMRPatientDetailsFormComponent extends HMRResolvedForm implements OnChanges {
  @Input() formType: HMRFormType;
  @ViewChild(HMRPatientDetailsFormHostDirective, { static: true }) formHost: HMRPatientDetailsFormHostDirective;

  constructor(
    componentFactoryResolver: ComponentFactoryResolver
  ) {
    super(componentFactoryResolver);
  }

  ngOnChanges() {
    this.resolveFormComponent(this.formType);
  }

  protected getFormComponent(formType: HMRFormType): Type<any> {
    return formType === HMRFormType.NewClaim
      ? HMRPatientDetailsFormBasicComponent
      : HMRPatientDetailsFormDetailedComponent;
  }
}

