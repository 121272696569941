import { State, Action, StateContext, Selector } from '@ngxs/store';
import { RequestCipherAction, ReceiveCipherAction, RequestCipherErrorAction, CloseCipherModalAction } from './cipher.actions';
import { VerifyService } from '../../../../shared/services/verify.service';
import { tap, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';

export class CipherStateModel {
  cipher: string;
  loading: boolean;
  modalVisible: boolean;
  error: any;
}
@Injectable()
@State<CipherStateModel>({
  name: 'cipher',
  defaults: {
    cipher: null,
    loading: false,
    error: null,
    modalVisible: false
  }
})
export class CipherState {

  @Selector()
  static modalVisible(state: CipherStateModel) {
    return state.modalVisible;
  }

  constructor(private verifyService: VerifyService) {}

  @Action(RequestCipherAction)
  request(ctx: StateContext<CipherStateModel>, action: RequestCipherAction) {
    ctx.patchState({ loading: true, modalVisible: true });
    return this.verifyService.verifyCustomer(action.customerProfileId).pipe(
      tap((response) => ctx.dispatch(new ReceiveCipherAction(response.code))),
      catchError((error) => ctx.dispatch(new RequestCipherErrorAction(error))));
  }

  @Action(ReceiveCipherAction)
  receive(ctx: StateContext<CipherStateModel>, action: ReceiveCipherAction) {
    ctx.patchState({
      cipher: action.cipher
    });
  }

  @Action(RequestCipherErrorAction)
  error(ctx: StateContext<CipherStateModel>, action: RequestCipherErrorAction) {
    ctx.patchState({ error: action.error });
  }

  @Action(CloseCipherModalAction)
  closeModal(ctx: StateContext<CipherStateModel>) {
    ctx.patchState({ modalVisible: false });
  }

  @Action([ReceiveCipherAction, RequestCipherErrorAction])
  complete(ctx: StateContext<CipherStateModel>) {
    ctx.patchState({ loading: false });
  }
}
