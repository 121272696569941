import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { PhoneService } from '../../../../shared/services/phone.service';
import { Client } from '../../../../shared/models/client.model';
import { Subscription } from 'rxjs/Subscription';
import 'rxjs/add/operator/map';
import { PhoneNote } from '../../../../shared/models/phoneNote.model';

@Component({
  selector: 'app-phone',
  templateUrl: './phone.component.html',
  styleUrls: ['./phone.component.scss']
})

export class PhoneComponent implements OnInit, OnDestroy {

  constructor(private phoneService: PhoneService) { }

  @Input() client: Client;
  isLoaded = false;
  sub1: Subscription;
  phoneNoteList: PhoneNote[] = [];

  ngOnInit() {
    this.sub1 = this.phoneService.getPhoneNotesByClient(this.client.id)
      .subscribe((item: PhoneNote[]) => {
        this.phoneNoteList = item;
        this.isLoaded = true;
      });
  }
  noteAdded(item: PhoneNote) {
    this.phoneNoteList.push(item);
  }
  ngOnDestroy() {
    if (this.sub1) { this.sub1.unsubscribe(); }
  }
}
