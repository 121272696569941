import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BaseApi } from '../../../shared/core/base-api/base-api';
import { Router } from '@angular/router';
import { StorageService } from '../../../shared/core/storage/storage.service';
import { AlertService } from '~/modules/core/alert/alert.service';
import { environment } from '../../../../environments/environment';

const API = environment.baseApiUrl;

@Injectable({
  providedIn: 'root'
})
export class TenantDeviceService extends BaseApi {
  constructor(public http: HttpClient, public router: Router, public storageService: StorageService, public alertService: AlertService) {
    super(http, router, storageService, alertService);
  }

  registerForPush(token: string) {
     return this.post<any>(`${API}/api/tenant-device`, { fcmRegistrationId: token });
  }
}
