import { State, Action, StateContext, Selector } from '@ngxs/store';
import {
  GetPharmacists,
  GetPharmacistsSuccess,
  GetPharmacistsError,
  AddNewPharmacist,
  UpdatePharmacistList,
  UpdatePharmacist,
  AddToPharmacistList,
  DeleteFromPharmacistList,
  DeletePharmacist,
  TogglePharmacistForm,
  UpdatePharmacistForm,
  SavePharmacist
} from './pharmacist.actions';
import { Pharmacist } from '../../../shared/models/pharmacy/pharmacist.model';
import { PharmacistService } from '../../../shared/services/pharmacist.service';
import { switchMap, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';

export class PharmacistStateModel {
  pharmacists: Pharmacist[];
  showPharmacistForm: boolean;
  errors: any;
  pharmacistForm: {
    model: Partial<Pharmacist>
  };
}
@Injectable()
@State<PharmacistStateModel>({
  name: 'pharmacist',
  defaults: {
    pharmacists: [],
    showPharmacistForm: false,
    pharmacistForm: {
      model: undefined
    },
    errors: undefined
  }
})
export class PharmacistState {
  constructor(private pharmacistService: PharmacistService) { }

  @Selector()
  static pharmacists(state: PharmacistStateModel) { return state.pharmacists; }

  @Selector()
  static showPharmacistForm(state: PharmacistStateModel) { return state.showPharmacistForm; }

  @Action(TogglePharmacistForm)
  togglePharmacistForm(ctx: StateContext<PharmacistStateModel>, { showPharmacistForm }: TogglePharmacistForm) {
    ctx.patchState({
      showPharmacistForm
    });
  }

  @Action(UpdatePharmacistForm)
  udatePharmacistForm(ctx: StateContext<PharmacistStateModel>, { currentPharmacist }: UpdatePharmacistForm) {
    ctx.patchState({
      pharmacistForm: {
        model: currentPharmacist
      }
    });
  }
  @Action(GetPharmacists)
  getPharmacists(ctx: StateContext<PharmacistStateModel>, action: GetPharmacists) {
    return this.pharmacistService.getPharmacistsForTenant()
      .pipe(switchMap(response => ctx.dispatch(new GetPharmacistsSuccess(response))),
        catchError(err => ctx.dispatch(new GetPharmacistsError(err))));
  }

  @Action(GetPharmacistsSuccess)
  getPharmacistsSuccess(ctx: StateContext<PharmacistStateModel>, { pharmacists }: GetPharmacistsSuccess) {
    ctx.patchState({
      pharmacists: pharmacists
    });
  }

  @Action(GetPharmacistsError)
  getPharmacistsError(ctx: StateContext<PharmacistStateModel>, { error }: GetPharmacistsError) {
    ctx.patchState({
      errors: error
    });
  }

  @Action(SavePharmacist)
  savePharmacist(ctx: StateContext<PharmacistStateModel>, { pharmacist }: SavePharmacist) {
    if (pharmacist.id) {
      ctx.dispatch(new UpdatePharmacist(pharmacist));
    } else {
      ctx.dispatch(new AddNewPharmacist(pharmacist));

    }
  }

  @Action(AddNewPharmacist)
  addNewPharmacist(ctx: StateContext<PharmacistStateModel>, { pharmacist }: AddNewPharmacist) {
    return this.pharmacistService.createPharmacist(pharmacist)
      .pipe(switchMap(response => ctx.dispatch(new AddToPharmacistList(response))),
        catchError(err => ctx.dispatch(new GetPharmacistsError(err))));
  }

  @Action(UpdatePharmacist)
  updatePharmacist(ctx: StateContext<PharmacistStateModel>, { pharmacist }: UpdatePharmacist) {
    return this.pharmacistService.updatePharmacist(pharmacist)
      .pipe(switchMap(response => ctx.dispatch(new UpdatePharmacistList(response))),
        catchError(err => ctx.dispatch(new GetPharmacistsError(err))));
  }

  @Action(DeletePharmacist)
  deletePharmacist(ctx: StateContext<PharmacistStateModel>, { pharmacistId }: DeletePharmacist) {
    return this.pharmacistService.deletePharmacist(pharmacistId)
      .pipe(switchMap(response => ctx.dispatch(new DeleteFromPharmacistList(pharmacistId))),
        catchError(err => ctx.dispatch(new GetPharmacistsError(err))));
  }

  @Action(AddToPharmacistList)
  addPharmacistList(ctx: StateContext<PharmacistStateModel>, { pharmacist }: AddToPharmacistList) {
    const { pharmacists } = ctx.getState();
    pharmacists.push(pharmacist);
    ctx.patchState({
      pharmacists: pharmacists
    });
  }

  @Action(UpdatePharmacistList)
  updatePharmacistList(ctx: StateContext<PharmacistStateModel>, { pharmacist }: UpdatePharmacistList) {
    const { pharmacists } = ctx.getState();
    ctx.patchState({
      pharmacists: pharmacists
        .map(item => item.id === pharmacist.id
          ? pharmacist
          : item)
    });
  }

  @Action(DeleteFromPharmacistList)
  deleteFromPharmacistList(ctx: StateContext<PharmacistStateModel>, { pharmacistId }: DeleteFromPharmacistList) {
    const { pharmacists } = ctx.getState();
    ctx.patchState({
      pharmacists: pharmacists.filter(x => x.id !== pharmacistId)
    });
  }
}
