export const environment = {
  production: true,
  baseApiUrl: '',
  medIndexCalculator: 'http://6cpa.com.au/files/medsindex-excel-calculator-2/',
  ppaPortalLink: 'https://app.ppaonline.com.au/medscheck/36980',
  stagedSupplyConsentPdf:
    'https://www.ppaonline.com.au/wp-content/uploads/2019/01/Staged-Supply-Patient-Information-and-Consent.pdf',
  daaConsentPdf:
    'https://www.ppaonline.com.au/wp-content/uploads/2019/02/DAA-Patient-Information-and-Consent.pdf',
  getFeatureIntervalInMilliseconds: 300000, // 300000 == 5 minutes
  driveYelloLogo:
    'https://scrypt.blob.core.windows.net/icons/yellologo_600_®-.png',
  helpLink: 'https://intercom.help/scrypt/en/',
  appVersion: 'Master',
  CPAVersion: 'Service',
  scryptDesktop:
    'https://scrypt.blob.core.windows.net/hq-notify/scryptnotify-setup-3.7.0-x64.exe',
  scryptNotify:
    'https://scrypt.blob.core.windows.net/hq-notify/scryptnotify-setup-3.7.0-x64.exe',
  firestore: {
    apiKey: 'AIzaSyAcEL38U40aPbhveGboou8UT_DqU6H_P04',
    authDomain: 'scrypt-hq.firebaseapp.com',
    databaseURL: 'https://scrypt-hq.firebaseio.com',
    projectId: 'scrypt-hq',
    storageBucket: 'scrypt-hq.appspot.com',
    messagingSenderId: '648757266454',
    appId: '1:648757266454:web:c89b3b2704b035f89f4d37',
    measurementId: 'G-V20EBFN5WZ',
  },
  appleAppStore: 'https://apps.apple.com/au/app/scrypt-mobile/id1457940601',
  playStore:
    'https://play.google.com/store/apps/details?id=org.mudbath.ScryptMob&hl=en_AU',
  baseRoute: 'https://hq.scrypt.com.au/app',
};
