import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { PRIVACY_POLICY_URL, TERMS_URL } from '~/shared/constants/policies';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  constructor() { }

  public currentYear = moment().year();
  privacyUrl = PRIVACY_POLICY_URL;
  termsUrl = TERMS_URL;

  ngOnInit() {
  }

}
