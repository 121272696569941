import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import { NgxsModule } from '@ngxs/store';
import { ConfirmMedsCheckState } from '../confirm-meds-check/state/confirm-meds-check.state';
import { ChannelSelectModalComponent } from './channel-select-modal.component';

@NgModule({
  declarations: [ChannelSelectModalComponent],
  imports: [
    NgxsModule.forFeature([
      ConfirmMedsCheckState
    ]),
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxsFormPluginModule
  ],
  exports: [ChannelSelectModalComponent]
})
export class ChannelSelectModalModule { }
