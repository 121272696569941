export enum SixCpaFormTypes {
  MedsCheck,
  ClinicalIntervention,
  DAA,
  StagedSupply,
  HMR,
  SickLeaveCertificate,
  MedicationList,
  HMS,
  AIRLookup,
  IDAA //export view only
}

export const SixCpaFormTypesDictionary = new Map<number, string>([
  [SixCpaFormTypes.MedsCheck, 'Meds Check'],
  [SixCpaFormTypes.ClinicalIntervention, 'Clinical Intervention'],
  [SixCpaFormTypes.DAA, 'DAA'],
  [SixCpaFormTypes.StagedSupply, 'Staged Supply'],
  [SixCpaFormTypes.HMR, 'HMR'],
  [SixCpaFormTypes.HMS, 'Home Medicine Service'],
  [SixCpaFormTypes.AIRLookup, 'AIR'],
  [SixCpaFormTypes.SickLeaveCertificate, 'Sick Leave Certificate'],
  [SixCpaFormTypes.MedicationList, 'Medication List'],
  [SixCpaFormTypes.IDAA, 'IDAA'],
]);
