import { Medicine } from '~/shared/models/six-cpa/medicine.model';
import { SixCpaMedicationModel } from '~/shared/models/six-cpa/six-cpa-medication.model';

export enum MedicationProfileActionTypes {
  SET_MEDICATION_PROFILE_SCRIPRS_ACTION = '[MedicationProfile] Set Scripts',
  TOGGLE_MEDICATION_PROFILE_LIST_ITEM_ACTION = '[MedicationProfile] Toggle List Item',
  TOGGLE_ALL_MEDICATION_PROFILE_LIST_ACTION = '[MedicationProfile] Toggle All List Item',
  ADD_MEDICATION_MEDICATION_PROFILE_LIST_ACTION = '[MedicationProfile] Add Medication To List Item',
  SELECT_MEDICATION_PROFILE_LIST_ITEM_ACTION = '[MedicationProfile] Select List Item',
  TOGGLET_MEDICATION_PROFILE_ACTION = '[MedicationProfile] Toggle Content',
  UPDATE_MEDICATION_PROFILE = '[MedicationProfile] Update Content',
  RESET_MEDICATION_PROFILE = '[MedicationProfile] Reset Medication Profile'
}


export class SetMedicationProfileScriptsAction {
  static readonly type = MedicationProfileActionTypes.SET_MEDICATION_PROFILE_SCRIPRS_ACTION;
  constructor(public categorisedScripts: any[]) { }
}
export class ToggleMedicationProfileListItemAction {
  static readonly type = MedicationProfileActionTypes.TOGGLE_MEDICATION_PROFILE_LIST_ITEM_ACTION;
  constructor(public item: Medicine) { }
}

export class ToggleAllMedicationProfileListAction {
  static readonly type = MedicationProfileActionTypes.TOGGLE_ALL_MEDICATION_PROFILE_LIST_ACTION;
  constructor(public isSelectAll: boolean, public categoryId?: number) { }
}

export class AddMedicationToMedicationProfileListAction {
  static readonly type = MedicationProfileActionTypes.ADD_MEDICATION_MEDICATION_PROFILE_LIST_ACTION;
  constructor(public item: Medicine) { }
}

export class SelectMedicationProfileListItemAction {
  static readonly type = MedicationProfileActionTypes.SELECT_MEDICATION_PROFILE_LIST_ITEM_ACTION;
  constructor(public productDispensedId: string) { }
}

export class ToggleMedicationProfileAction {
  static readonly type = MedicationProfileActionTypes.TOGGLET_MEDICATION_PROFILE_ACTION;
  constructor(public showMode: string) { }
}

export class UpdateMedicationProfileList {
  static readonly type = MedicationProfileActionTypes.UPDATE_MEDICATION_PROFILE;
  constructor(public medication: SixCpaMedicationModel[]) { }
}

export class ResetMedicationProfileIdsAction {
  static readonly type = MedicationProfileActionTypes.RESET_MEDICATION_PROFILE;
}
