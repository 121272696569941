import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { OrderRequestItemStatus, OrderRequestStatus } from '../../models/order.enums';
import { OrderRequest } from '../../models/order-request.model';
import { ContactMethods } from '../../../../../shared/models/communication/contact-methods.enum';
import { ClientViewModel } from '../../../../core/profile/client.model';
import { MessageChannel } from '../../../../../shared/models/message.model';

export interface OrderUpdate {
  order: OrderRequest;
  sendMessage: boolean;
  message: string;
  contactMethod: ContactMethods;
}

@Component({
  selector: 'app-order-update-status-modal',
  templateUrl: './order-update-status-modal.component.html',
  styleUrls: ['./order-update-status-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrderUpdateStatusModalComponent implements OnInit, OnChanges {
  @Input() order: OrderRequest;
  @Input() dateFn = (date) => date;
  @Input() profile: ClientViewModel;
  @Input() isVisible: boolean;
  @Output() isVisibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  orderStatusEnum = OrderRequestStatus;
  orderStatus: OrderRequestStatus;
  hasMessage: boolean = false;
  contactMethod: ContactMethods;
  message: string;
  @Output() updateOrder: EventEmitter<OrderUpdate> = new EventEmitter<OrderUpdate>()
  constructor(
  ) { }

  ngOnInit() {
    if (this.order) {
      this.orderStatus = this.order.status;
    }
    if (!this.profile) {
      throw new Error('Profile input missing');
    }
    if (this.order?.customerProfileId !== this.profile?.customerProfile?.customerProfileId) {
      throw new Error('Profile and Order customerProfileId do not match ' + this.order.customerProfileId + ':' +this.profile.customerProfile.customerProfileId);
    }
    if (this.profile) {
      this.contactMethod = this.profile.customerProfile.contactMethod;
    }
  }

  ngOnChanges() {
    if (this.order) {
      this.orderStatus = this.order.status;
    }
    if (!this.profile) {
      throw new Error('Profile input missing');
    }
    if (this.order?.customerProfileId !== this.profile?.customerProfile?.customerProfileId) {
      throw new Error('Profile and Order customerProfileId do not match ' + this.order.customerProfileId + ':' +this.profile.customerProfile.customerProfileId);
    }
  }

  handleOk() {
    this.isVisible = false;
    this.isVisibleChange.next(false)
    this.updateOrder.emit({
       order: {...this.order, status: this.orderStatus},
       sendMessage: this.hasMessage,
       message: this.message,
       contactMethod: this.contactMethod
    });
  }

  handleCancel() {
    this.isVisible = false;
    this.isVisibleChange.next(false);
  }

  hasOnOrderItems() {
    return this.order.orderRequestedItems
    .filter(i => i.orderRequestItemStatus === OrderRequestItemStatus.OnOrder).length > 0;
  }

  allItemsAreReady() {
    return this.order.orderRequestedItems.every(i => i.orderRequestItemStatus === OrderRequestItemStatus.Ready)
  }
}
