import { ChangeDetectionStrategy, Component, ElementRef, Input, OnInit, QueryList, ViewChildren, ViewEncapsulation } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Select, Store } from '@ngxs/store';
import { interval, Observable, Subscription } from 'rxjs';
import { SetEScriptToken, ToggleEScriptModal } from '~/shared/components/escripts/state/escripts.actions';
import { detectEScript, extractEScript, replaceEscriptURLWithHTMLLinks } from '~/shared/helpers/eScript.helper';
import { MailboxMessage, MessageDetail } from '~/shared/models/message.model';
import { HumanizePipe } from '~/shared/pipes/humanize.pipe';
import { Channels, MailTabs, MessageSource } from '~/shared/types/communications';
import { SetMessageFlagged } from '../../state/communications.actions';
import { GetMessageHistory, ToggleReplyModal } from './state/chat.actions';
import { ChatState, ChatStateModel } from './state/chat.state';
import { take } from 'rxjs/operators';

@Component({
  selector: 'reply-modal',
  templateUrl: './reply-modal.component.html',
  styleUrls: ['./reply-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class ReplyModal implements OnInit {
  @Select(ChatState.state) chatState$: Observable<ChatStateModel>;
  @Select(ChatState.loading) loading$: Observable<boolean>;
  @Select(ChatState.mostRecentClientMessage) mostRecentClientMessage$: Observable<MessageDetail>;
  @Select(ChatState.moreRecentMessageExists) moreRecentMessageExists$: Observable<boolean>;
  @Select(ChatState.selectedMessageId) selectedMessageId$: Observable<string>;
  @Select(ChatState.scrollToSelectedMessage) scrollToSelectedMessage$: Observable<boolean>;
  @ViewChildren('message') messageHistory!: QueryList<ElementRef>;

  @Input() messageType: MailTabs;
  mailTabs = MailTabs;
  messageSources = MessageSource;
  templateChannel: Channels;
  messageText: string;
  messageSubject: string;
  updateSentHumanizer = new Observable<any>();
  loadingSubscription: Subscription;
  channels = [
    {
      name: 'All',
      value: Channels.All
    },
    {
      name: 'SMS',
      value: Channels.Sms,
      icon: 'fal fa-comment-alt-lines'
    },
    {
      name: 'Email',
      value: Channels.Email,
      icon: 'fal fa-envelope'
    },
    {
      name: 'Scrypt Mobile',
      value: Channels.App,
      icon: 'fab fa-stripe-s'
    },
  ];

  constructor(private store: Store, private domSanitizer: DomSanitizer, private humanizePipe: HumanizePipe) {
    this.templateChannel = Channels.App;
    this.updateSentHumanizer = interval(5000);
  }

  ngOnInit() {
    this.scrollToSelectedMessage$.subscribe(scroll => {
      if (scroll)
        this.scrollToSelectedMessage()
    });
  }

  scrollToSelectedMessage() {
    this.selectedMessageId$
      .pipe(take(1))
      .subscribe(messageId => this.scrollToMessage(messageId));
  }

  scrollToMessage(messageId: string) {
    if (!messageId || !this.messageHistory?.length) return;

    const targetMessage = this.messageHistory.find(item => item.nativeElement.id.toLowerCase() === messageId.toLowerCase());
    if (targetMessage?.nativeElement)
      targetMessage.nativeElement.scrollIntoView({ behavior: 'smooth' });
  }

  toggleFlagged(messageDetail) {
    const message = { id: messageDetail.inboxMessageId, isFlagged: messageDetail.isFlagged, isRead: messageDetail.isRead, customerId: messageDetail.customerId } as MailboxMessage
    this.store.dispatch(new SetMessageFlagged(message, !message.isFlagged, this.messageType));
  }

  getHumanizedDate(date) {
    return this.humanizePipe.transform(date);
  }

  getChannelName(channel) {
    switch (this.channels[channel].value) {
      case Channels.App:
        return "messenger";
      case Channels.Email:
        return "email";
      case Channels.Sms:
        return "sms";
    }
  }

  closeModal() {
    this.store.dispatch(ToggleReplyModal);
  }

  showMoreHistory(customerId: string, channel: Channels, oldestMessages: MessageDetail[], isRecent: boolean) {
    if (oldestMessages?.length)
      this.scrollToMessage(oldestMessages[0].id);

    this.store.dispatch(new GetMessageHistory(customerId, channel, 10, isRecent));
  }

  getChannelIcon(channelId) {
    return this.channels[channelId]?.icon ?? "";
  }

  sanitize(html) {
    if (!html) return "";

    return this.domSanitizer.bypassSecurityTrustHtml(html);
  }

  checkForEScript(message: string) {
    return detectEScript(message);
  }

  openEScriptModal(message: string) {
    const scriptToken = extractEScript(message);
    this.store.dispatch([new SetEScriptToken(scriptToken), new ToggleEScriptModal(true)]);
  }

  linkEscripts(html, channel) {
    if (!html) return html;

    const surroundWithPre = (html) => `<pre>${html}</pre>`; // this is to respect line-breaks in sms/app

    switch (this.channels[channel].value) {
      case Channels.Email:
        return html;
      case Channels.Sms:
      case Channels.App:
        return surroundWithPre(replaceEscriptURLWithHTMLLinks(html));
    }
  }
}
