import { HttpClient, HttpParams, HttpParamsOptions } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import { Router } from '@angular/router';
import { StorageService } from '../../../../shared/core/storage/storage.service';
import { AlertService } from '../../../core/alert/alert.service'
import { BaseApi } from '../../../../shared/public-api';
import { OrderRequest } from '../models/order-request.model';
import { OrderRequestItem } from '../models/order-request-item.model';
import { OrderRequestSearchDto } from '../models/order-request-search.model';
import { OrderRequestSearchResult } from '../models/order-request-search-result.model';
import { UpdateOrderRequestModel } from '../models/order-request-update.model';
import { cloneDeep } from 'lodash';
import { InvoiceRequestModal } from '../models/invoice-request-model';

export class HttpParamsNoNulls extends HttpParams {

  constructor(options: HttpParamsOptions = {} as HttpParamsOptions) {
    if (!!options.fromObject) {
      // TODO: make immutable, consider something lke cloneDeepWith
      // remove all null params
      options.fromObject = cloneDeep(options.fromObject);
        Object.keys(options.fromObject).forEach(key => {
          const value = options.fromObject[key];

          if (Array.isArray(value)) {
            options.fromObject[key] = value.filter(x => x !== null);
          } else {
            if (value === null) {
              delete options.fromObject[key];
            }
          }
      });
      super(options);
    }
  }
}

@Injectable()
export class OrderService extends BaseApi {
  API = '';

  constructor(
    @Inject('environment') env: any,
    public http: HttpClient,
    public router: Router,
    public storageService: StorageService,
    public alertService: AlertService
  ) {
    super(http, router, storageService, alertService);
    this.API = env.baseApiUrl;
  }

  getOrdersByClient(profileId: number, filters: OrderRequestSearchDto): Observable<OrderRequestSearchResult> {
    return this.get(
      `${this.API}/api/orderRequest/customer/${profileId}`,
      { params: new HttpParamsNoNulls({fromObject: filters as any})}
    );
  }

  getOrdersByPharmacy(): Observable<OrderRequest[]> {
    return this.get(
      `${this.API}/api/orderRequest/pharmacy`
    );
  }

  getPredictedOrderByClient(profileId: string): Observable<OrderRequestItem[]> {
    return this.get(
      `${this.API}/api/orderRequest/predict/${profileId}`
    );
  }

  getOrder(orderId: number): Observable<OrderRequest[]> {
    return this.get(
      `${this.API}/api/orderRequest/${orderId}`
    );
  }

  createOrder(order: UpdateOrderRequestModel): Observable<OrderRequest> {
    return this.post(
      `${this.API}/api/orderRequest`,
      order
    );
  }

  updateOrder(
    order: UpdateOrderRequestModel
  ): Observable<any> {
    return this.put(
      `${this.API}/api/orderRequest`,
      order
    );
  }

  updateOrderMethod(
    id:number, orderMethod: any
  ): Observable<any> {
    return this.put(
      `${this.API}/api/orderRequest/${id}/order-method`,
      orderMethod
    );
  }

  deleteOrder(orderId: number): Observable<any> {
    return this.delete(
      `${this.API}/api/orderRequest/${orderId}`
    );
  }

  generatePaymentLink(invoiceRequest: InvoiceRequestModal): Observable<string> {
    return this.post(
      `${this.API}/api/invoice`,
      invoiceRequest,{responseType: 'text'}
    );
  }

}
