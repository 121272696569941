import { NgModule } from '@angular/core';
import { TimezoneModule } from '../../../core/timezone/timezone.module';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { LoaderModule } from '../../../core/loader/loader.module';
import { NotificationModule } from '../../../../shared/components/notification/notification.module';
import { NgxsModule } from '@ngxs/store';
import { MessageCycleComponent } from './message-cycle.component';
import { MessageCycleState } from './state/message-cycle.state';
import { MessageCycleModalModule } from './message-cycle-modal/message-cycle-modal.module';
import { TenantDatePipe } from '../../../core/timezone/pipes/tenant-date.pipe';
import { MessageCycleScheduleListModule } from './message-cycle-schedule-list/message-cycle-schedule-list.module';
import { ProfileState } from '../../../core/profile/state/profile.state';

@NgModule({
  declarations: [
    MessageCycleComponent
  ],
  imports: [
    TimezoneModule,
    CommonModule,
    FormsModule,
    LoaderModule,
    NotificationModule,
    NgxsModule.forFeature([MessageCycleState, ProfileState]),
    MessageCycleModalModule,
    MessageCycleScheduleListModule
  ],
  providers: [
    TenantDatePipe
  ],
  exports: [
    MessageCycleComponent
  ]
})
export class MessageCycleModule { }
