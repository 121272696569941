import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';
import { HMRServiceProviderDetailsFormState } from './state/hmr-service-provider-details-form.state';
import { HMRServiceProviderDetailsFormDetailedState } from './state/hmr-service-provider-details-form-detailed.state';
import { HMRServiceProviderDetailsFormBasicState } from './state/hmr-service-provider-details-form-basic.state';


@NgModule({
  imports: [
    NgxsModule.forFeature([
        HMRServiceProviderDetailsFormState,
        HMRServiceProviderDetailsFormDetailedState,
        HMRServiceProviderDetailsFormBasicState
        ]),
  ]
})
export class HMRServiceProviderDetailsStateModule { }
