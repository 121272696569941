import { State, Selector, Action, StateContext } from '@ngxs/store';
import { ToggleModal } from './email.actions';
import { Injectable } from '@angular/core';

export class EmailStateModel {
  public showModal: boolean;
}
@Injectable()
@State<EmailStateModel>({
  name: 'email',
  defaults: {
    showModal: false
  }
})
export class EmailState {

  @Selector()
  static showModal(state: EmailStateModel) { return state.showModal };

  @Action(ToggleModal)
  toggleModal(ctx: StateContext<EmailStateModel>, { showModal }: ToggleModal) {
    ctx.patchState({
      showModal: showModal
    })
  }
}
