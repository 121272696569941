import { DAAFormType } from './dose-administration-aids.state';
import { DaaFormModel } from '../../../../../../shared/models/six-cpa/DAA/daa-form.model';

export enum DoseAdministrationAidsActions {
  SET_VALID = '[DoseAdministrationAids] Set Valid',
  SUBMIT = '[DoseAdministrationAids] Submit',
  RESET = '[DoseAdministrationAids] Reset',
  SAVE = '[DoseAdministrationAids] Save',
  UPDATE_SAVE = '[DoseAdministrationAids] Update Save',
  UPDATE_SUBMIT = '[DoseAdministrationAids] Update Submit',
  SAVE_SUCCESS = '[DoseAdministrationAids] Save Success',
  SAVE_FAILED = '[DoseAdministrationAids] Save Failed',
  SET_DAA_FORM_TYPE = '[DoseAdministrationAids] Set DAA Type',
  GET = '[DoseAdministrationAids] Get',
  GET_DAA_FORM_SUCCESS = '[DoseAdministrationAids] On Get DAA Form Success',
  GET_DAA_FORM_ERROR = '[DoseAdministrationAids] On Get DAA Form Error',
  UPDATE_DAA_CLAIM_FORMS = '[DoseAdministrationAids] Update DAA Child Forms',
  UPDATE_DAA_REGISTRATION_FORMS = '[DoseAdministrationAids] Update DAA Child Forms',
  OPEN_DAA_CONFIRM_FORM = '[DoseAdministrationAids] Open Confirm Form',
  CLOSE_DAA_CONFIRM_FORM = '[DoseAdministrationAids] Close Confirm Form',
  SHOW_INVALID_DAA = '[DoseAdministrationAids] Show Invalid Form'
}

export class DAAFormListValid {
  static readonly type = DoseAdministrationAidsActions.SET_VALID;
  constructor(public valid = false) { }
}

export class SubmitNewDAAForm {
  static readonly type = DoseAdministrationAidsActions.SUBMIT;
  constructor(public clientId: string, public formData: any) { }
}

export class ResetDAAFormListAction {
  static readonly type = DoseAdministrationAidsActions.RESET;
  constructor(public onlyClearFormIds: boolean = false) {}
}

export class SetDAAFormType {
  static readonly type = DoseAdministrationAidsActions.SET_DAA_FORM_TYPE;
  constructor(public daaFormType: DAAFormType) { }
}

export class GetDAAForm {
  static readonly type = DoseAdministrationAidsActions.GET;
  constructor(public clientId: string, public sixCpaFormId: number) { }
}

export class SubmitNewDAA {
  static readonly type = DoseAdministrationAidsActions.SUBMIT;
  constructor(public clientId: string, public formData: DaaFormModel) { }
}

export class SubmitExisitingDAA {
  static readonly type = DoseAdministrationAidsActions.UPDATE_SUBMIT;
  constructor(public clientId: string, public formData: DaaFormModel) { }
}

export class SaveNewDAA {
  static readonly type = DoseAdministrationAidsActions.SAVE;
  constructor(public clientId: string, public formData: DaaFormModel) { }
}

export class SaveExisitingDAA {
  static readonly type = DoseAdministrationAidsActions.UPDATE_SAVE;
  constructor(public clientId: string, public formData: DaaFormModel) { }
}

export class SaveDAAFormSuccessAction {
  static readonly type = DoseAdministrationAidsActions.SAVE_SUCCESS;
  constructor(public formData: DaaFormModel) { }
}

export class SaveDAAFormFailedAction {
  static readonly type = DoseAdministrationAidsActions.SAVE_FAILED;
  constructor(public error: any) { }
}

export class GetDAAFormSuccess {
  static readonly type = DoseAdministrationAidsActions.GET_DAA_FORM_SUCCESS;
  constructor(public daaForm: DaaFormModel) { }
}

export class GetDAAFormError {
  static readonly type = DoseAdministrationAidsActions.GET_DAA_FORM_ERROR;
  constructor(public error: any) { }
}

export class UpdateDAAClaimForms {
  static readonly type = DoseAdministrationAidsActions.UPDATE_DAA_CLAIM_FORMS;
  constructor(public daaForm: DaaFormModel) { }
}

export class UpdateDAARegistrationForms {
  static readonly type = DoseAdministrationAidsActions.UPDATE_DAA_REGISTRATION_FORMS;
  constructor(public daaForm: DaaFormModel) { }
}

export class ShowInvalidDAAForm {
  static readonly type = DoseAdministrationAidsActions.SHOW_INVALID_DAA;
}
