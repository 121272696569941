import { Component, OnInit } from '@angular/core';
import { SignUpState } from '../../state/sign-up.state';
import { Observable } from 'rxjs';
import { Select } from '@ngxs/store';

@Component({
  selector: 'app-sign-up-error',
  templateUrl: './sign-up-error.component.html',
  styleUrls: ['./sign-up-error.component.scss']
})
export class SignUpErrorComponent implements OnInit {
  @Select(SignUpState.error) error$: Observable<any>;

  constructor() { }

  ngOnInit() {
  }
}
