import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { OrderComponent } from './order.component';
import { OrderMainComponent } from './order-main/order-main.component';
import { OrderViewComponent } from './order-view/order-view.component';
import { OrderCreateComponent } from './order-create/order-create.component';

const routes: Routes = [
    {
        path: '', component: OrderComponent, children: [
            { path: '', component: OrderMainComponent },
            { path: 'create', component: OrderCreateComponent },
            {
                path: ':orderId', children: [
                    { path: '**', component: OrderViewComponent },
                ]
            },
        ]
    },
];


@NgModule({
    imports: [
        RouterModule.forChild(routes)
    ],
    exports: [RouterModule]
})
export class OrderRoutingModule { }
