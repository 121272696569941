import { State, Action, StateContext, Selector, Store } from '@ngxs/store';
import { SixCpaMedicationModel } from '../../../../../../shared/models/six-cpa/six-cpa-medication.model';
import { MedicationListService } from '../../medication-list-form-list/services/medication-list.service';
import {
  ToggleMedicationListConfirm,
  SetDoctorListMedicationListConfirm,
  SendToDoctorMedicationListConfirm,
  MedicationListConfirmError,
  GoBackFromMedicationListConfirm,
  ToggleResponseMedicationListConfirm,
  SetupMedicationsMedicationListConfirm,
  GetPatientReportMedicationListConfirm,
  GetDoctorReportMedicationListConfirm,
  SendMedsicationListEmail,
  SendMedicationListReport,
  OpenMedsListSendDocumentFormAction,
  CloseMedsListSendDocumentFormAction
} from './medication-list-confirm.actions';
import { DoctorEmailModel } from '../../../../../../shared/models/six-cpa/doctor-email.model';
import { tap, catchError, finalize } from 'rxjs/operators';
import { ToggleSixCpaContentAction, SetProfileForSixCpaAction } from '../../../state/six-cpa.actions';
import { saveAs } from 'file-saver';
import { WindowService } from '../../../../../../shared/services/window.service';
import * as moment_ from 'moment';
const moment = moment_;
import { ProfileState } from '../../../../../core/profile/state/profile.state';
import { IDoctorDetailsModel, IReportModel } from '../../../../../../shared/models/six-cpa/doctor-report.model';
import { Injectable } from '@angular/core';
import { MedicationListFormsService } from '../../medication-list-form-list/state/medication-list-forms.service';
import { getDoctorName } from '../../../../../../shared/helpers/doctor-name.helper';
import { ChannelPreference } from './../../../../../../shared/models/pharmacy/pharmacy-comms-details.model';

export class MedicationListConfirmStateModel {
  public slideMode: string;
  public sixCpaMedications: SixCpaMedicationModel[];

  public patientReport: IReportModel;
  public doctorReport: IReportModel;
  public doctorDetailsForm: {
    model: Partial<IDoctorDetailsModel>;
  };
  public medsListEmailForm: {
    model: Partial<IMedsListEmailModel>;
  };
  public doctors: IDoctorDetailsModel[];
  public loading: boolean;
  public showResponse: string;
  public error: any;
  public showSendDocumentForm: boolean;
}

export interface IMedsListEmailModel {
  doctorEmail?: string;
}
@Injectable()
@State<MedicationListConfirmStateModel>({
  name: 'medicationListConfirm',
  defaults: {
    slideMode: 'out',
    patientReport: {
      name: 'Patient Meds List',
      loading: false
    },
    doctorReport: {
      name: 'Doctor Report',
      loading: false
    },
    doctorDetailsForm: undefined,
    medsListEmailForm: undefined,
    doctors: [],
    sixCpaMedications: [],
    loading: false,
    showResponse: 'out',
    error: null,
    showSendDocumentForm: false,
  }
})
export class MedicationListConfirmState {
  constructor(private medicationListService: MedicationListService, private windowService: WindowService, private store: Store) { }

  @Selector()
  static showSendDocumentForm(state: MedicationListConfirmStateModel) {
    return state.showSendDocumentForm;
  }

  @Selector()
  static slideMode(state: MedicationListConfirmStateModel) { return state.slideMode; }

  @Selector()
  static patientReport(state: MedicationListConfirmStateModel) { return state.patientReport; }

  @Selector()
  static doctorReport(state: MedicationListConfirmStateModel) { return state.doctorReport; }

  @Selector()
  static doctorDetailsForm(state: MedicationListConfirmStateModel) { return state.doctorDetailsForm.model; }

  @Selector()
  static medsListEmailForm(state: MedicationListConfirmStateModel) { return state.medsListEmailForm.model; }

  @Action(ToggleMedicationListConfirm)
  toggle(ctx: StateContext<MedicationListConfirmStateModel>, action: ToggleMedicationListConfirm) {
    ctx.patchState({ slideMode: action.slideMode });
  }

  @Action(OpenMedsListSendDocumentFormAction)
  openMedsListSendDocumentFormAction(ctx: StateContext<MedicationListConfirmStateModel>, action: OpenMedsListSendDocumentFormAction) {
    ctx.patchState({ showSendDocumentForm: true });
  }

  @Action(CloseMedsListSendDocumentFormAction)
  closeMedsListSendDocumentFormAction(ctx: StateContext<MedicationListConfirmStateModel>, action: CloseMedsListSendDocumentFormAction) {
    ctx.patchState({ showSendDocumentForm: false });
  }

  @Action(SendMedicationListReport)
  sendMedicationListReport(ctx: StateContext<MedicationListConfirmStateModel>, action: SendMedicationListReport) {
    const state = ctx.getState();
    const { clientId } = action;
    const { fullName, doctorAddress } = state.doctorDetailsForm.model;
    const { allergies, primaryDoctor } = this.store.selectSnapshot(MedicationListFormsService.getMedicationListRequest);
    ctx.patchState({ loading: true });
    return this.medicationListService.sendMedsListReport(
      clientId,
      {
        fullName,
        mobile: (action.commsType === ChannelPreference.Sms) ? action.contact : null,
        email: (action.commsType === ChannelPreference.Email) ? action.contact : null,
        commsType: action.commsType,
        reportBody: action.customisedDoctorReport.reportBody,
        subject: action.customisedDoctorReport.subject,
        doctorAddress,
        sixCpaMedications: state.sixCpaMedications,
        allergies: allergies,
        primaryDoctor: primaryDoctor
      } as DoctorEmailModel).pipe(
        tap(() => ctx.patchState({ showResponse: 'in' })),
        catchError((error) => {
          return ctx.dispatch(new MedicationListConfirmError(error));
        }),
        finalize(() => ctx.patchState({ loading: false }))
      );
  }

  @Action(SetDoctorListMedicationListConfirm)
  setDoctorList(ctx: StateContext<MedicationListConfirmStateModel>, action: SetDoctorListMedicationListConfirm) {
    const state = ctx.getState();
    const doctors = action.doctors.map(item => {
      const { doctorID, emailAddress } = item;
      return {
        id: doctorID,
        fullName: getDoctorName(item),
        email: emailAddress
      } as IDoctorDetailsModel;
    });
    let doctorDetailsForm;
    if (doctors.length > 0) {
      const { email, fullName } = doctors[0];
      doctorDetailsForm = { email, fullName } as IDoctorDetailsModel;
    }
    ctx.patchState({
      doctors,
      doctorDetailsForm: { ...state.doctorDetailsForm, model: doctorDetailsForm }
    });
  }

  @Action(SendMedsicationListEmail)
  sendMedsListToDoctor(ctx: StateContext<MedicationListConfirmStateModel>, action: SendMedsicationListEmail) {
    const state = ctx.getState();
    const { clientId } = action;
    const { doctorEmail } = state.medsListEmailForm.model;
    const { fullName } = state.doctorDetailsForm.model;
    const { allergies, primaryDoctor } = this.store.selectSnapshot(MedicationListFormsService.getMedicationListRequest);

    ctx.patchState({ loading: true });
    return this.medicationListService.sendMedsListEmail(
      clientId,
      {
        fullName: fullName,
        email: doctorEmail, reportBody: null,
        subject: null,
        sixCpaMedications: state.sixCpaMedications,
        allergies: allergies,
        primaryDoctor: primaryDoctor
      } as DoctorEmailModel).pipe(
        tap(() => ctx.patchState({ showResponse: 'in' })),
        catchError((error) => {
          return ctx.dispatch(new MedicationListConfirmError(error));
        }),
        finalize(() => ctx.patchState({ loading: false }))
      );
  }

  @Action(SendToDoctorMedicationListConfirm)
  sendToDoctor(ctx: StateContext<MedicationListConfirmStateModel>, action: SendToDoctorMedicationListConfirm) {
    const state = ctx.getState();
    const { clientId } = action;
    const { fullName, email, doctorAddress } = state.doctorDetailsForm.model;
    const { allergies, primaryDoctor } = this.store.selectSnapshot(MedicationListFormsService.getMedicationListRequest);

    ctx.patchState({ loading: true });
    return this.medicationListService.sendDoctorReport(
      clientId,
      {
        fullName, email, reportBody: action.customisedDoctorReport.reportBody,
        subject: action.customisedDoctorReport.subject,
        doctorAddress,
        sixCpaMedications: state.sixCpaMedications,
        allergies: allergies,
        primaryDoctor: primaryDoctor
      } as DoctorEmailModel).pipe(
        tap(() => ctx.patchState({ showResponse: 'in' })),
        catchError((error) => {
          return ctx.dispatch(new MedicationListConfirmError(error));
        }),
        finalize(() => ctx.patchState({ loading: false }))
      );
  }

  @Action(GoBackFromMedicationListConfirm)
  goBack(ctx: StateContext<MedicationListConfirmStateModel>, action: GoBackFromMedicationListConfirm) {
    ctx.dispatch([new ToggleSixCpaContentAction('in'), new ToggleMedicationListConfirm('out')]);
  }

  @Action(ToggleResponseMedicationListConfirm)
  toggleResponse(ctx: StateContext<MedicationListConfirmStateModel>, action: ToggleResponseMedicationListConfirm) {
    ctx.patchState({ showResponse: action.showResponse });
  }

  @Action(SetupMedicationsMedicationListConfirm)
  setupMedications(ctx: StateContext<MedicationListConfirmStateModel>, { sixCpaMedications }: SetupMedicationsMedicationListConfirm) {
    ctx.patchState({ sixCpaMedications: sixCpaMedications });
  }

  @Action(GetPatientReportMedicationListConfirm)
  getPatientReport(ctx: StateContext<MedicationListConfirmStateModel>, { clientId, doctorFullName }: GetPatientReportMedicationListConfirm) {
    const state = ctx.getState();
    const { allergies, primaryDoctor } = this.store.selectSnapshot(MedicationListFormsService.getMedicationListRequest);
    ctx.patchState({ patientReport: { ...state.patientReport, loading: true } });
    const profile = this.store.selectSnapshot(ProfileState.profile);
    const fileName = `${profile.patient.firstname} ${profile.patient.surname} - ${moment(profile.patient.birthDate, 'YYYY-MM-DD').format('DD-MM-YYYY')} - Medication_List.pdf`;
    return this.medicationListService
      .getPatientReport(clientId, state.sixCpaMedications, doctorFullName, allergies, primaryDoctor).pipe(
        tap((response) => {
          saveAs(response, fileName);
          this.openPdfInNewTab(response);
        }),
        catchError((error) => ctx.dispatch(new MedicationListConfirmError(error))),
        finalize(() => ctx.patchState({ patientReport: { ...state.patientReport, loading: false } }))
      );
  }

  @Action(GetDoctorReportMedicationListConfirm)
  getDoctorReport(ctx: StateContext<MedicationListConfirmStateModel>, { clientId, customisedDoctorReport }: GetDoctorReportMedicationListConfirm) {
    const state = ctx.getState();
    ctx.patchState({ doctorReport: { ...state.doctorReport, loading: true } });
    const { fullName, doctorAddress } = state.doctorDetailsForm.model;
    const { allergies, primaryDoctor } = this.store.selectSnapshot(MedicationListFormsService.getMedicationListRequest);

    const profile = this.store.selectSnapshot(ProfileState.profile);
    const fileName = `${profile.patient.firstname} ${profile.patient.surname} - ${moment(profile.patient.birthDate, 'YYYY-MM-DD').format('DD-MM-YYYY')} - Doctor Report.pdf`;
    return this.medicationListService.getDoctorReport(
      clientId,
      state.sixCpaMedications,
      customisedDoctorReport.reportBody,
      fullName,
      doctorAddress,
      allergies,
      primaryDoctor
      ).pipe(
        tap((response) => {
          saveAs(response, fileName);
          this.openPdfInNewTab(response);
        }),
        catchError((error) => {
          return ctx.dispatch(new MedicationListConfirmError(error));
        }),
        finalize(() => ctx.patchState({ doctorReport: { ...state.doctorReport, loading: false } }))
      );
  }

  @Action(MedicationListConfirmError)
  handleError(ctx: StateContext<MedicationListConfirmStateModel>, action: MedicationListConfirmError) {
    ctx.patchState({ error: action.error });
  }
  @Action(SetProfileForSixCpaAction)
  onSetProfile(ctx: StateContext<MedicationListConfirmStateModel>, { profile }: SetProfileForSixCpaAction) {
    const patient = profile.patient;
    ctx.patchState({
      medsListEmailForm: {
        model: {
          doctorEmail: patient.emailAddress,
        }
      }
    });
  }
  private openPdfInNewTab(response: any) {
    const fileURL = URL.createObjectURL(response);
    this.windowService.open(fileURL);
  }

  private generateDoctorsFullName(title: string, firstName: string, surname: string) {
    return `${title ? `${title} ` : ''}${firstName ? `${firstName} ` : ''}${surname ? surname : ''}`;
  }
}
