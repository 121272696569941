import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';

import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import { StorageService } from '../core/storage/storage.service';
import { PharmacyPostcode } from '../models/pharmacy/pharmacyPostcode.model';
import { Postcode } from '../models/pharmacy/postcode.model';
import { CrudService } from './crud.service';
import { AlertService } from '~/modules/core/alert/alert.service';
import { PharmacyLocation } from '../models/pharmacy/pharmacy-address.model';

const API = environment.baseApiUrl;

@Injectable()
export class PharmacyService extends CrudService {
  constructor(public http: HttpClient, public router: Router, public storageService: StorageService, public alertService: AlertService) {
    super(http, router, storageService, alertService, 'pharmacyPostcode');
  }

  getPharmacyLocation(): Observable<PharmacyLocation> {
    return this.get(`${API}/api/tenant/location`);
  }

  getAnonymousPharmacyLocation(tenantId: number): Observable<PharmacyLocation> {
    return this.get(`${API}/api/tenant/optout/location?tenantId=${tenantId}`);
  }

  updatePharmacyLocation(location: PharmacyLocation): Observable<PharmacyLocation> {
    return this.put(`${API}/api/tenant/location`, location);
  }

  getPostcodeList(): Observable<Postcode[]> {
    return this.get(`${API}/api/postcode`);
  }

  getPharmacyPostcodeList(): Observable<PharmacyPostcode[]> {
    return this.get(`${API}/api/tenant/post-codes`);
  }

  addPharmacyPostcode(item: PharmacyPostcode) {
    return this.add(item);
  }

  updatePharmacyPostcode(item: PharmacyPostcode) {
    return this.update(item);
  }

  removePharmacyPostcode(id: string) {
    return this.remove(id);
  }
}
