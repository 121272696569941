import { State, Selector, Action, StateContext } from '@ngxs/store';
import {
  SetStagedSupplyTypeAction,
  ResetStagedSupplyFormListAction,
  ResetStagedSupplyFormStateToDefaults,
  SaveExisitingStagedSupplyForm,
  SubmitNewStagedSupply as SubmitNewStagedSupplyFormAction,
  SubmitExisitingStagedSupply,
  SaveNewStagedSupplyForm,
  SaveStagedSupplyFormSuccess as SaveStagedSupplyFormSuccessAction,
  SaveStagedSupplyFormFailed as SaveStagedSupplyFormFailedAction,
  GetStagedSupplyForm,
  GetStagedSupplyFormError,
  GetStagedSupplyFormSuccess,
  UpdateDetailedStagedSupplyForms,
  UpdateBasicStagedSupplyForms,
  ShowInvalidStagedSupplyForm
} from './staged-supply-form-list.actions';
import { ToggleSixCpaAction, SetSelectedFormType, CloseResetFormModal, SetSixCpaFormSummary } from '../../../state/six-cpa.actions';
import { StagedSupplyService } from '../services/staged-supply.service';
import { switchMap, catchError, finalize } from 'rxjs/operators';
import { AlertService } from '~/modules/core/alert/alert.service';
import { UpdateStagedSupplyPatientDetailsForm } from '../../patient-details-form/state/ss-patient-details-form.actions';
import { UpdateSSConsentEligibilityDetailedForm } from '../../consent-eligibility-form/state/ss-consent-eligibility-form-detailed.actions';
import { UpdateSSOutcomesAndActionsForm } from '../../ss-outcomes-and-actions-form/state/ss-outcomes-and-actions-form.actions';
import { UpdateSSMedicationInformationForm } from '../../ss-medication-information-form/state/ss-medication-information-form.actions';
import { ResetStagedSupplyMedicationProfileIdsAction, UpdateStagedSupplyMedicationProfileList } from '../../staged-supply-medication-profile/state/staged-supply-medication-profile.actions';
import { UpdateSSAdditionalDetailsForm } from '../../ss-aditional-details-form/state/ss-additional-details-form.actions';
import { UpdateSSConsentEligibilityBasicForm } from '../../consent-eligibility-form/state/ss-consent-eligibility-form-basic.actions';
import { ClearSixCpaCommunications, SetSixCpaSuccessMessages, SetSixCpaErrors, SetSixCpaInfo } from '../../../six-cpa-user-communications/state/six-cpa-user-communications.actions';
import { Injectable } from '@angular/core';
import { SaveOrUpdateStagedSupplyFormAction } from './staged-supply-form-save.actions';
import { SixCpaFormTypes } from '../../../six-cpa-form-types.enum';
import { ResetMedsCheckFormStateToDefaults } from '../../../meds-check/state/meds-check-form-list.actions';
import { SixCpaResponse } from '~/modules/profile/profile-services/state/profile-services.state';
import { closeConfirmationActions } from '../../../state/six-cpa-toggles-list';
import { GetSixCpaFormsFromService } from '~/modules/profile/profile-services/state/profile-services.actions';

export class StagedSupplyFormListStateModel {
  stagedSupplyId: number;
  sixCpaFormId: number;
  patientDetailsFormId: number;
  consentAndEligibilityFormId: number;
  medicationInformationFormId: number;
  outcomesAndActionsFormId: number;
  additionalDetailsFormId: number;
  valid: boolean;
  stagedSupplyFormType: StagedSupplyFormType;
  saving: boolean;
  showInvalid: boolean;

  static showConfirmForm(showConfirmForm: any) {
    throw new Error("Method not implemented.");
  }
  error: any;
  showConfirmForm: boolean;
}

export enum StagedSupplyFormType {
  PatientRegistration,
  Claim
}
@Injectable()
@State<StagedSupplyFormListStateModel>({
  name: 'stagedSupplyFormList',
  defaults: {
    stagedSupplyId: null,
    sixCpaFormId: null,
    patientDetailsFormId: null,
    consentAndEligibilityFormId: null,
    medicationInformationFormId: null,
    outcomesAndActionsFormId: null,
    additionalDetailsFormId: null,
    valid: false,
    stagedSupplyFormType: StagedSupplyFormType.PatientRegistration,
    saving: false,
    error: null,
    showConfirmForm: false,
    showInvalid: false,

  }
})

export class StagedSupplyFormListState {
  constructor(private stagedSupplyService: StagedSupplyService, private alertService: AlertService) { }

  @Selector()
  static saving(state: StagedSupplyFormListStateModel) { return state.saving; }

  @Selector()
  static stagedSupplyType(state: StagedSupplyFormListStateModel) { return state.stagedSupplyFormType; }

  @Selector()
  static sixCpaFormId(state: StagedSupplyFormListStateModel) { return state.sixCpaFormId; }

  @Selector()
  static showConfirmForm(state: StagedSupplyFormListStateModel) { return state.showConfirmForm; }

  @Selector()
  static showInvalid(state: StagedSupplyFormListStateModel) { return state.showInvalid; }

  @Action(SetStagedSupplyTypeAction)
  setStagedSupplyType(ctx: StateContext<StagedSupplyFormListStateModel>, action: SetStagedSupplyTypeAction) {
    ctx.patchState({
      stagedSupplyFormType: action.stagedSupplyFormType
    });
  }

  @Action(ResetStagedSupplyFormListAction)
  resetStagedSupply(ctx: StateContext<StagedSupplyFormListStateModel>, {onlyClearFormIds}: ResetStagedSupplyFormListAction) {
    ctx.patchState({
      sixCpaFormId: null
    });
    ctx.dispatch([
      new CloseResetFormModal(),
      new ResetMedsCheckFormStateToDefaults(onlyClearFormIds),
      new ClearSixCpaCommunications()
    ]);
  }

  @Action(ResetStagedSupplyFormStateToDefaults)
  resetFormStateToDefaults(ctx: StateContext<StagedSupplyFormListStateModel>, {onlyClearFormIds}: ResetStagedSupplyFormStateToDefaults) {
    ctx.patchState({
      stagedSupplyId: null,
      sixCpaFormId: null,
      patientDetailsFormId: null,
      consentAndEligibilityFormId: null,
      medicationInformationFormId: null,
      outcomesAndActionsFormId: null,
      additionalDetailsFormId: null,
      valid: false,
      stagedSupplyFormType: StagedSupplyFormType.PatientRegistration,
      saving: false,
      error: null,
      showConfirmForm: false
    });
    ctx.dispatch(new ResetStagedSupplyMedicationProfileIdsAction());
    if (!onlyClearFormIds) {
      ctx.dispatch(new ToggleSixCpaAction('out'));
    }
  }

  @Action(SaveStagedSupplyFormSuccessAction)
  saveStagedSupplySuccess(ctx: StateContext<StagedSupplyFormListStateModel>, { formData }: SaveStagedSupplyFormSuccessAction) {
    const { id, sixCpaFormId, patientDetailsFormId, consentAndEligibilityFormId, medicationInformationFormId,
      outcomesAndActionsFormId, additionalDetailsFormId, hasSubmitted } = formData;
    ctx.patchState({
      stagedSupplyId: id,
      sixCpaFormId,
      patientDetailsFormId,
      consentAndEligibilityFormId,
      medicationInformationFormId,
      outcomesAndActionsFormId,
      additionalDetailsFormId
    });
    this.alertService.success('Success! The Staged Supply Form has been saved to Scrypt.');
    ctx.dispatch([
      new SetSixCpaSuccessMessages(['Success! The Staged Supply Form has been saved to Scrypt.']),
      new SetSixCpaFormSummary({
        id: sixCpaFormId,
        formType: SixCpaFormTypes.StagedSupply,
        hasSubmitted: hasSubmitted
      } as SixCpaResponse)
    ]);
    ctx.dispatch(new GetSixCpaFormsFromService(null));
  }

  @Action(SaveStagedSupplyFormFailedAction)
  saveStagedSupplyFailed(ctx: StateContext<StagedSupplyFormListStateModel>, { error }: SaveStagedSupplyFormFailedAction) {
    this.alertService.error(error);
    ctx.dispatch(new SetSixCpaErrors([error]));
  }

  @Action(SubmitNewStagedSupplyFormAction)
  submitNewStagedSupply(ctx: StateContext<StagedSupplyFormListStateModel>, { clientId, formData }: SubmitNewStagedSupplyFormAction) {
    ctx.patchState({ saving: true, showConfirmForm: false });
    return this.stagedSupplyService.postSubmit(clientId, formData).pipe(
      switchMap(response => ctx.dispatch([new SaveStagedSupplyFormSuccessAction(response)])),
      catchError(error => ctx.dispatch([new SaveStagedSupplyFormFailedAction(error), new SetSixCpaInfo(["We were unable to submit the claim so we've saved it as a draft."]), new SaveOrUpdateStagedSupplyFormAction()])),
      finalize(() => {
        ctx.patchState({ saving: false });
      }));
  }

  @Action(SubmitExisitingStagedSupply)
  submitExisitingStagedSupply(ctx: StateContext<StagedSupplyFormListStateModel>, { clientId, formData }: SubmitExisitingStagedSupply) {
    ctx.patchState({ saving: true, showConfirmForm: false });
    return this.stagedSupplyService.putSubmit(clientId, formData).pipe(
      switchMap(response => ctx.dispatch([new SaveStagedSupplyFormSuccessAction(response)])),
      catchError(error => ctx.dispatch([new SaveStagedSupplyFormFailedAction(error), new SetSixCpaInfo(["We were unable to submit the claim so we've saved it as a draft."]), new SaveOrUpdateStagedSupplyFormAction()])),
      finalize(() => {
        ctx.patchState({ saving: false });
      }));
  }

  @Action(SaveNewStagedSupplyForm)
  saveNewStagedSupply(ctx: StateContext<StagedSupplyFormListStateModel>, { clientId, formData }: SaveNewStagedSupplyForm) {
    ctx.patchState({ saving: true });
    return this.stagedSupplyService.postSave(clientId, formData).pipe(
      switchMap(response => ctx.dispatch(new SaveStagedSupplyFormSuccessAction(response))),
      catchError(error => ctx.dispatch(new SaveStagedSupplyFormFailedAction(error))),
      finalize(() => ctx.patchState({ saving: false })));
  }

  @Action(SaveExisitingStagedSupplyForm)
  saveStagedSupply(ctx: StateContext<StagedSupplyFormListStateModel>, { clientId, formData }: SaveExisitingStagedSupplyForm) {
    ctx.patchState({ saving: true });
    return this.stagedSupplyService.postSave(clientId, formData).pipe(
      switchMap(response => ctx.dispatch(new SaveStagedSupplyFormSuccessAction(response))),
      catchError(error => ctx.dispatch(new SaveStagedSupplyFormFailedAction(error))),
      finalize(() => ctx.patchState({ saving: false })));
  }

  @Action(GetStagedSupplyForm)
  getStagedSupplyForm(ctx: StateContext<StagedSupplyFormListStateModel>, { clientId, sixCpaFormId }: GetStagedSupplyForm) {
    return this.stagedSupplyService.getStagedSupplyForm(clientId, sixCpaFormId)
      .pipe(switchMap(response => ctx.dispatch([new GetStagedSupplyFormSuccess(response), new ToggleSixCpaAction('in')])),
        catchError(err => ctx.dispatch(new GetStagedSupplyFormError(err))));
  }

  @Action(GetStagedSupplyFormSuccess)
  getStagedSupplyFormSuccess(ctx: StateContext<StagedSupplyFormListStateModel>, { stagedSupplyForm }: GetStagedSupplyFormSuccess) {
    ctx.patchState({
      stagedSupplyId: stagedSupplyForm.id,
      sixCpaFormId: stagedSupplyForm.sixCpaFormId
    });
    ctx.dispatch(closeConfirmationActions);
    stagedSupplyForm.stagedSupplyFormType === StagedSupplyFormType.PatientRegistration
      ? ctx.dispatch(new UpdateDetailedStagedSupplyForms(stagedSupplyForm))
      : ctx.dispatch(new UpdateBasicStagedSupplyForms(stagedSupplyForm));
  }

  @Action(UpdateDetailedStagedSupplyForms)
  updateDetailedStagedSupplyForms(ctx: StateContext<StagedSupplyFormListStateModel>, { stagedSupplyForm }: UpdateDetailedStagedSupplyForms) {
    ctx.dispatch([
      new UpdateStagedSupplyPatientDetailsForm(stagedSupplyForm.patientDetailsForm),
      new UpdateSSConsentEligibilityDetailedForm(stagedSupplyForm.consentAndEligibilityForm),
      new UpdateSSOutcomesAndActionsForm(stagedSupplyForm.outcomesAndActionsForm),
      new UpdateSSMedicationInformationForm(stagedSupplyForm.medicationInformationForm),
      new UpdateStagedSupplyMedicationProfileList(stagedSupplyForm.sixCpaMedications),
      new SetSelectedFormType(SixCpaFormTypes.StagedSupply),
      new SetStagedSupplyTypeAction(stagedSupplyForm.stagedSupplyFormType)
    ]);
  }

  @Action(UpdateBasicStagedSupplyForms)
  updateBasicStagedSupplyForms(ctx: StateContext<StagedSupplyFormListStateModel>, { stagedSupplyForm }: UpdateBasicStagedSupplyForms) {
    ctx.dispatch([
      new UpdateStagedSupplyPatientDetailsForm(stagedSupplyForm.patientDetailsForm),
      new UpdateSSConsentEligibilityBasicForm(stagedSupplyForm.consentAndEligibilityForm),
      new UpdateSSAdditionalDetailsForm(stagedSupplyForm.additionalDetailsForm),
      new UpdateStagedSupplyMedicationProfileList(stagedSupplyForm.sixCpaMedications),
      new SetSelectedFormType(SixCpaFormTypes.StagedSupply),
      new SetStagedSupplyTypeAction(stagedSupplyForm.stagedSupplyFormType),
      new ToggleSixCpaAction('in')
    ]);
  }

  @Action(GetStagedSupplyFormError)
  getStagedSupplyFormError(ctx: StateContext<StagedSupplyFormListStateModel>, action: GetStagedSupplyFormError) {
    ctx.patchState({
      error: action.error
    });
  }

  @Action(ShowInvalidStagedSupplyForm)
  showInvalidStagedSupplyForm(ctx: StateContext<StagedSupplyFormListStateModel>) {
    ctx.patchState({
      showInvalid: true
    });
  }
}
