import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Router } from '@angular/router';
import { StorageService } from '../../../../shared/core/storage/storage.service';
import { AlertService } from '../../../core/alert/alert.service'
import { OrderRequest } from '../../../../../../../scrypt-core/src/lib/modules/profile/order-requests/models/order-request.model';
import { OrderRequestItem } from '../../../../../../../scrypt-core/src/lib/modules/profile/order-requests/models/order-request-item.model';
import { BaseApi } from '~/shared/core/base-api/base-api';
import { OrderRequestSearchDto } from '../../../../../../../scrypt-core/src/lib/modules/profile/order-requests/models/order-request-search.model';
import { PharmacyOrderRequestSearchResult, OrderRequestSearchResult } from '../../../../../../../scrypt-core/src/lib/modules/profile/order-requests/models/order-request-search-result.model';
import { UpdateOrderRequestModel } from '../../../../../../../scrypt-core/src/lib/modules/profile/order-requests/models/order-request-update.model';
import { InvoiceRequestModal } from '../../../../../../../scrypt-core/src/lib/modules/profile/order-requests/models/invoice-request-model';

@Injectable()
export class OrderService extends BaseApi {
  API = '';

  constructor(
    @Inject('environment') env: any,
    public http: HttpClient,
    public router: Router,
    public storageService: StorageService,
    public alertService: AlertService
  ) {
    super(http, router, storageService, alertService);
    this.API = env.baseApiUrl;
  }

  getOrdersByClient(profileId: number, filter: OrderRequestSearchDto): Observable<OrderRequestSearchResult> {
    const formattedFilter = this.cleanupPaginationFilters(filter);
    Object.keys(filter).forEach(key => !filter[key] ? delete filter[key] : {});
    return this.get(
      `${this.API}/api/orderRequest/customer/${profileId}`,
      {params: new HttpParams({fromObject: {...formattedFilter}})}
    );
  }

  getOrdersByPharmacy(filter: OrderRequestSearchDto): Observable<PharmacyOrderRequestSearchResult> {
    const formattedFilter = this.cleanupPaginationFilters(filter);
    return this.get(
      `${this.API}/api/orderRequest/pharmacy`,
      {params: new HttpParams({fromObject: {...formattedFilter}})}
    );
  }

  getPredictedOrderByClient(profileId: string): Observable<OrderRequestItem[]> {
    return this.get(
      `${this.API}/api/orderRequest/predict/${profileId}`
    );
  }

  getOrder(orderId: number): Observable<OrderRequest> {
    return this.get(
      `${this.API}/api/orderRequest/${orderId}`
    );
  }

  createOrder(order: UpdateOrderRequestModel): Observable<OrderRequest> {
    return this.post(
      `${this.API}/api/orderRequest`,
      order
    );
  }

  updateOrder(
    order: UpdateOrderRequestModel
  ): Observable<OrderRequest> {
    return this.put(
      `${this.API}/api/orderRequest`,
      order
    );
  }

  updateOrderMethod(
    id:number, orderMethod: any
  ): Observable<any> {
    return this.put(
      `${this.API}/api/orderRequest/${id}/order-method`,
      orderMethod
    );
  }

  deleteOrder(orderId: number): Observable<any> {
    return this.delete(
      `${this.API}/api/orderRequest/${orderId}`
    );
  }

  generatePaymentLink(invoiceRequest: InvoiceRequestModal): Observable<string> {
    return this.post(
      `${this.API}/api/invoice`,
      invoiceRequest,{responseType: 'text'}
    );
  }

  getPendingOrdersCount(): Observable<number> {
    return this.get(`${this.API}/api/orderRequest/pending`);
  }

  private cleanupPaginationFilters(filter: OrderRequestSearchDto) {
    // Clear null values
    Object.keys(filter).forEach(key => !filter[key] ? delete filter[key] : {});
    return filter;
  }
}
