import { NgModule } from '@angular/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { TimezoneModule } from '~/modules/core/timezone/timezone.module';
import { CommonModule } from '@angular/common';
import { LoaderModule } from '~/modules/core/loader/loader.module';
import { TooltipModule } from 'ngx-bootstrap';
import { RouterModule } from '@angular/router';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { ZApiService } from './services/z-api.service';
import { ZApiClientDispenseInfoResultsComponent } from './z-api-client-dispense-info-results/z-api-client-dispense-info-results.component';
import { NzModalModule } from 'ng-zorro-antd/modal';

@NgModule({
  declarations: [
    ZApiClientDispenseInfoResultsComponent
  ],
  imports: [
    NgSelectModule,
    CommonModule,
    LoaderModule,
    TimezoneModule,
    TooltipModule.forRoot(),
    RouterModule,
    NzTableModule,
    NzToolTipModule,
    NzButtonModule,
    NzModalModule,
    NzTableModule,
  ],
  exports: [
    ZApiClientDispenseInfoResultsComponent,
    
  ],
  providers: [
    ZApiService,
    NzNotificationService
  ],
  
})
export class ZApiModule { }
