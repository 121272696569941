import { State, Action, StateContext, Selector, Store } from '@ngxs/store';
import {
  ToggleConfirmMedsCheckAction,
  SetDoctorListConfirmMedsCheckAction,
  SendToDoctorConfirmMedsCheckAction,
  GoBackFromConfirmMedsCheckAction,
  ToggleResponseConfirmMedsCheckAction,
  GetPatientReportConfirmMedsCheckAction,
  ConfirmMedsCheckErrorAction,
  GetDoctorReportConfirmMedsCheckAction,
  CreateSixCpaNoteConfirmMedsCheckAction,
  SetupMedicationsConfirmMedsCheck,
  SendMedsicationList,
} from './confirm-meds-check.actions';
import { saveAs } from 'file-saver';

import { ToggleSixCpaContentAction, SetProfileForSixCpaAction } from '../../state/six-cpa.actions';
import { catchError, tap, finalize } from 'rxjs/operators';
import { MedsCheckService } from '../../meds-check/services/meds-check.service';
import { SixCpaFormsService } from '../../../../../shared/services/six-cpa-forms.service';
import { DoctorEmailModel } from '../../../../../shared/models/six-cpa/doctor-email.model';
import { SixCpaMedicationModel } from '../../../../../shared/models/six-cpa/six-cpa-medication.model';
import { ProfileState } from '../../../../core/profile/state/profile.state';
import * as moment_ from 'moment';
const moment = moment_;
import { WindowService } from '../../../../../shared/services/window.service';
import { IDoctorDetailsModel, IReportModel } from '../../../../../shared/models/six-cpa/doctor-report.model';
import { Injectable } from '@angular/core';
import { SixCpaActionPlanForm } from '../../../../../shared/models/six-cpa/action-plan.model';
import { ChannelPreference } from '../../../../../shared/models/pharmacy/pharmacy-comms-details.model';
import { MedsCheckMedicationProfileState } from '../../meds-check-medication-profile/state/meds-check-medication-profile.state';
import { getDoctorName } from '../../../../../shared/helpers/doctor-name.helper';
// import { FirebaseMessageSource, MessengerHistory } from '../../../../../shared/models/messageHistory.model';
// import { AuthState } from '../../../../core/auth/state/auth.state';
// import { FirestoreService } from '../../../../../shared/services/firestore.service';

export class ConfirmMedsCheckStateModel {
  public slideMode: string;
  public sixCpaMedications: SixCpaMedicationModel[];
  public actionPlan: Partial<SixCpaActionPlanForm>;

  public patientReport: IReportModel;
  public doctorReport: IReportModel;
  public doctorDetailsForm: {
    model: Partial<IDoctorDetailsModel>;
  };
  public medsListEmailForm: {
    model: Partial<IMedsListEmailModel>;
  }
  public doctors: IDoctorDetailsModel[];
  public sixCpaNoteForm: {
    model: Partial<ISixCpaNoteModel>;
    loading: boolean;
    successResponse: boolean;
  };
  public loading: boolean;
  public showResponse: string;
  public error: any;
}

export interface IMedsListEmailModel {
  email?: string;
  mobile?: string;
  commsType: ChannelPreference;
}

export interface ISixCpaNoteModel {
  followUpDate: Date;
  body: string;
}

@Injectable()
@State<ConfirmMedsCheckStateModel>({
  name: 'confirmMedsCheck',
  defaults: {
    slideMode: 'out',
    patientReport: {
      name: 'Patient Meds List',
      loading: false
    },
    doctorReport: {
      name: 'Doctor Report',
      loading: false
    },
    doctorDetailsForm: undefined,
    medsListEmailForm: undefined,
    doctors: [],
    sixCpaMedications: [],
    actionPlan: null,
    sixCpaNoteForm: {
      loading: false,
      model: undefined,
      successResponse: false
    },
    loading: false,
    showResponse: 'out',
    error: null
  }
})
export class ConfirmMedsCheckState {
  constructor(
    private medsCheckService: MedsCheckService,
    private sixCpaFormsService: SixCpaFormsService,
    private store: Store,
    private windowService: WindowService) { }

  @Selector()
  static slideMode(state: ConfirmMedsCheckStateModel) { return state.slideMode; }

  @Selector()
  static patientReport(state: ConfirmMedsCheckStateModel) { return state.patientReport; }

  @Selector()
  static doctorReport(state: ConfirmMedsCheckStateModel) { return state.doctorReport; }

  @Selector()
  static doctorDetailsForm(state: ConfirmMedsCheckStateModel) { return state.doctorDetailsForm.model; }

  @Selector()
  static medsListEmailForm(state: ConfirmMedsCheckStateModel) { return state.medsListEmailForm.model; }

  @Selector()
  static sixCpaNoteForm(state: ConfirmMedsCheckStateModel) { return state.sixCpaNoteForm.model; }

  @Action(ToggleConfirmMedsCheckAction)
  toggle(ctx: StateContext<ConfirmMedsCheckStateModel>, action: ToggleConfirmMedsCheckAction) {
    ctx.patchState({ slideMode: action.slideMode });
  }

  @Action(SetDoctorListConfirmMedsCheckAction)
  setDoctorList(ctx: StateContext<ConfirmMedsCheckStateModel>, action: SetDoctorListConfirmMedsCheckAction) {
    const state = ctx.getState();
    const doctors = action.doctors.map(item => {
      const { doctorID, title, firstName, surname, emailAddress } = item;
      return {
        id: doctorID,
        fullName: getDoctorName(item),
        email: emailAddress
      } as IDoctorDetailsModel;
    });
    let doctorDetailsForm;
    if (doctors.length > 0) {
      const { email, fullName } = doctors[0];
      doctorDetailsForm = { email, fullName } as IDoctorDetailsModel;
    }
    ctx.patchState({
      doctors,
      doctorDetailsForm: { ...state.doctorDetailsForm, model: doctorDetailsForm }
    });
  }

  @Action(SendToDoctorConfirmMedsCheckAction)
  sendToDoctor(ctx: StateContext<ConfirmMedsCheckStateModel>, action: SendToDoctorConfirmMedsCheckAction) {
    const state = ctx.getState();
    const { clientId } = action;
    const { fullName, email, doctorAddress } = state.doctorDetailsForm.model;
    const { allergies, primaryDoctor} = this.store.selectSnapshot(MedsCheckMedicationProfileState.medicationProfileForm)
    ctx.patchState({ loading: true });
    return this.medsCheckService.sendDoctorReport(
      clientId,
      {
        fullName,
        email,
        reportBody: action.customisedDoctorReport.reportBody,
        subject:  action.customisedDoctorReport != null ? action.customisedDoctorReport.subject : '',
        doctorAddress,
        sixCpaMedications: state.sixCpaMedications,
        actionPlan: state.actionPlan,
        allergies,
        primaryDoctor
      } as DoctorEmailModel).pipe(
      tap(() => ctx.patchState({ showResponse: 'in' })),
      catchError((error) => {
        return ctx.dispatch(new ConfirmMedsCheckErrorAction(error));
      }),
      finalize(() => ctx.patchState({ loading: false }))
    );
  }

  @Action(SendMedsicationList)
  sendMedsListToDoctor(ctx: StateContext<ConfirmMedsCheckStateModel>, action: SendMedsicationList) {
    const state = ctx.getState();
    const { clientId } = action;
    const { email, mobile, commsType } = state.medsListEmailForm.model;
    const { fullName } = state.doctorDetailsForm.model;
    const { allergies, primaryDoctor} = this.store.selectSnapshot(MedsCheckMedicationProfileState.medicationProfileForm);

    ctx.patchState({ loading: true });

    return this.medsCheckService.sendMedsListReport(
      clientId,
      { fullName: fullName,
        email: email,
        mobile: mobile,
        commsType: commsType == ChannelPreference.ScryptMessenger ? 'ScryptMessenger' : commsType,
        reportBody: null,
        subject: action.customisedDoctorReport != null ? action.customisedDoctorReport.subject : '',
        sixCpaMedications: state.sixCpaMedications,
        actionPlan: state.actionPlan,
        allergies,
        primaryDoctor
      } as DoctorEmailModel).pipe(
      tap((message) => {
        // //Commented out until Scrypt lite release
        // if (message){
        //   const tenantId = this.store.selectSnapshot(AuthState.tenantId);
        //   const fsMessage: MessengerHistory = {
        //     attachments: [],
        //     message,
        //     read: false,
        //     sentFrom: FirebaseMessageSource.Pharmacy,
        //     tenantId: tenantId,
        //     timestamp: new Date().toISOString()
        //   }
        //   var profileId = this.store.selectSnapshot(ProfileState.customerProfileId);
        //   this.firestoreService.sendMessage<MessengerHistory>(fsMessage, profileId);
        // }

        ctx.patchState({ showResponse: 'in' });
      }),
      catchError((error) => {
        return ctx.dispatch(new ConfirmMedsCheckErrorAction(error));
      }),
      finalize(() => ctx.patchState({ loading: false }))
    );
  }

  @Action(GoBackFromConfirmMedsCheckAction)
  goBack(ctx: StateContext<ConfirmMedsCheckStateModel>, action: GoBackFromConfirmMedsCheckAction) {
    ctx.dispatch([new ToggleSixCpaContentAction('in'), new ToggleConfirmMedsCheckAction('out')]);
  }

  @Action(ToggleResponseConfirmMedsCheckAction)
  toggleResponse(ctx: StateContext<ConfirmMedsCheckStateModel>, action: ToggleResponseConfirmMedsCheckAction) {
    ctx.patchState({ showResponse: action.showResponse });
  }

  @Action(SetupMedicationsConfirmMedsCheck)
  setupMedications(ctx: StateContext<ConfirmMedsCheckStateModel>, {sixCpaMedications, actionPlan}: SetupMedicationsConfirmMedsCheck) {
    ctx.patchState({ sixCpaMedications: sixCpaMedications, actionPlan:  actionPlan});
  }

  @Action(GetPatientReportConfirmMedsCheckAction)
  getPatientReport(ctx: StateContext<ConfirmMedsCheckStateModel>, action: GetPatientReportConfirmMedsCheckAction) {
    const state = ctx.getState();
    ctx.patchState({ patientReport: { ...state.patientReport, loading: true } });
    const { clientId, doctorFullName } = action;
    const profile = this.store.selectSnapshot(ProfileState.profile);
    const { allergies, primaryDoctor} = this.store.selectSnapshot(MedsCheckMedicationProfileState.medicationProfileForm);

    const fileName = `${profile.patient.firstname} ${profile.patient.surname} - ${moment(profile.patient.birthDate, 'YYYY-MM-DD').format('DD-MM-YYYY')}
    - Medication List.pdf`
    return this.medsCheckService.getPatientReport(clientId, state.sixCpaMedications, state.actionPlan, doctorFullName, allergies, primaryDoctor).pipe(
      tap((response) => {
          saveAs(response, fileName);
           this.openPdfInNewTab(response);
      }),
      catchError((error) => ctx.dispatch(new ConfirmMedsCheckErrorAction(error))),
      finalize(() => ctx.patchState({ patientReport: { ...state.patientReport, loading: false } }))
    );
  }

  @Action(CreateSixCpaNoteConfirmMedsCheckAction)
  createSixCpaNote(ctx: StateContext<ConfirmMedsCheckStateModel>, action: CreateSixCpaNoteConfirmMedsCheckAction) {
    const state = ctx.getState();
    ctx.patchState({ sixCpaNoteForm: { ...state.sixCpaNoteForm, loading: true, successResponse: false } });
    const { clientId, folowUpDate, body } = action;
    return this.sixCpaFormsService.createSixCpaNote(clientId, folowUpDate, body).pipe(
      tap(() => ctx.patchState({ sixCpaNoteForm: { ...state.sixCpaNoteForm, successResponse: true } })),
      catchError((error) => {
        return ctx.dispatch(new ConfirmMedsCheckErrorAction(error));
      }),
      finalize(() => ctx.patchState({ sixCpaNoteForm: { ...ctx.getState().sixCpaNoteForm, loading: false } }))
    );
  }

  @Action(GetDoctorReportConfirmMedsCheckAction)
  getDoctorReport(ctx: StateContext<ConfirmMedsCheckStateModel>, action: GetDoctorReportConfirmMedsCheckAction) {
    const state = ctx.getState();
    ctx.patchState({ doctorReport: { ...state.doctorReport, loading: true } });
    const { clientId } = action;
    const { fullName, doctorAddress } = state.doctorDetailsForm.model;
    const { allergies, primaryDoctor} = this.store.selectSnapshot(MedsCheckMedicationProfileState.medicationProfileForm);

    const profile = this.store.selectSnapshot(ProfileState.profile);
    const fileName = `${profile.patient.firstname} ${profile.patient.surname} - ${moment(profile.patient.birthDate, 'YYYY-MM-DD').format('DD-MM-YYYY')} - Doctor Report.pdf`;
    return this.medsCheckService.getDoctorReport(
      clientId, state.sixCpaMedications, state.actionPlan,
      action.customisedDoctorReport.reportBody,
      fullName,
      doctorAddress,
      allergies,
      primaryDoctor
      ).pipe(
      tap((response) => {
        saveAs(response, fileName);
        this.openPdfInNewTab(response);
      }),
      catchError((error) => {
        return ctx.dispatch(new ConfirmMedsCheckErrorAction(error));
      }),
      finalize(() => ctx.patchState({ doctorReport: { ...state.doctorReport, loading: false } }))
    );
  }

  @Action(ConfirmMedsCheckErrorAction)
  handleError(ctx: StateContext<ConfirmMedsCheckStateModel>, action: ConfirmMedsCheckErrorAction) {
    ctx.patchState({ error: action.error });
  }

  @Action(SetProfileForSixCpaAction)
  onSetProfile(ctx: StateContext<ConfirmMedsCheckStateModel>, { profile }: SetProfileForSixCpaAction) {
    const patient = profile.patient;
    ctx.patchState({
      medsListEmailForm: {
        model: {
          email: patient.emailAddress,
          mobile: patient.mobileNumber
        }
      }
    });
  }
  private openPdfInNewTab(response: any) {
    const fileURL = URL.createObjectURL(response);
    this.windowService.open(fileURL);
  }
}
