import { Component, OnInit, OnDestroy } from '@angular/core';
import { BiReport } from '~/shared/models/BiReport.model';
import { Select, Store } from '@ngxs/store';
import { RemoveContentContainerClassAction, AddContentContainerClassAction } from '~/modules/state/content-container/content-container.actions';
import { ReportingState } from '~/modules/state/reporting/reporting.state';
import { Observable } from 'rxjs-compat';
import { GetEmbeddedReport, GetReports } from '~/modules/state/reporting/report.actions';
import { EmbeddedReportModel } from '~/shared/models/report.model';

export const REPORT_CONTENT_CONTAINER_CLASSNAME = 'report-container';

@Component({
  selector: 'app-reporting-page',
  templateUrl: './reporting-page.component.html',
  styleUrls: ['./reporting-page.component.scss']
})
export class ReportingPageComponent implements OnInit, OnDestroy {
  @Select(ReportingState.reports) reports$: Observable<BiReport[]>;
  @Select(ReportingState.embededReport) embededReport$: Observable<EmbeddedReportModel>;
  @Select(ReportingState.loading) loading$: Observable<boolean>;

  constructor(private store: Store) { }

  ngOnInit() {
    this.getReports();
    this.getSelectedReport();
    this.store.dispatch(new AddContentContainerClassAction(REPORT_CONTENT_CONTAINER_CLASSNAME));
  }

  private getReports() {
    this.store.dispatch(new GetReports());
  }

  private getSelectedReport() {
    if (this.store.selectSnapshot(ReportingState.reports) == null)
      return;

    this.store.dispatch(new GetEmbeddedReport());
  }

  ngOnDestroy() {
    this.store.dispatch(new RemoveContentContainerClassAction(REPORT_CONTENT_CONTAINER_CLASSNAME));
  }

  onReportChange(id: string) {
    this.store.dispatch(new GetEmbeddedReport(id));
  }

  onEmbedded(a) {
    console.log(a, 'loaded');
  }
}
