
export function validSearchQuery(query: string): boolean {
    return !!(query && query.length >= 3);
}

export const indexOfLowercaseElseMax = (value, substring) => convertNegativeToMaxNumber(indexOfLowercase(value, substring));

export function orderBySearchTerm(searchProperty: (value: any) => string) {
    return (query: string) => {
        return (values: any[]) => {
            const sortedValues = [...values];
            return sortedValues.sort((a, b) => {
                return indexOfLowercaseElseMax(searchProperty(a), query) < indexOfLowercaseElseMax(searchProperty(b), query) ? -1 : 1;
            });
        };
    };
}

export function indexOfLowercase(value: string, substring: string) {
    return value.toLowerCase().indexOf(substring.toLowerCase());
}

export function convertNegativeToMaxNumber(value: number) {
    if (value < 0) {
        return Number.MAX_VALUE;
    }
    return value;
}

export const searchDebounceTime  = 500;
