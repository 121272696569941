export enum NoteTypeEnum {
    general = 0,
    dispense = 1,
    request = 2,
    order = 3,
    awaiting = 4,
    newDrug = 5,
    sms = 6,
    email = 7,
    mail = 8,
    phoneCall = 9,
    emailReply = 10,
    smsReply = 11,
    audit = 12,
    patientNote = 13,
    scriptRequest = 14,
    awaitingDelivery = 15,
    awaitingPickup = 16,
    orderConfirmationSms = 17,
    customerOrder = 19,
    sixCpa = 20,
    delivery = 21,
    specialOrder = 22,
    highCostItem = 23,
    scriptsReady = 24,
    scheduledSMS = 25,
    eScript = 26,
    ScryptMessage = 27,
    VaxScreening = 28,
    NewCustomer = 29
}
