import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AIRStatementComponent } from './air-statement.component';
import { LoaderModule } from '../../../../core/loader/loader.module';
import { NgxsModule } from '@ngxs/store';
import { AIRStatementState } from './state/air-statement.state';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import { AIRStatementService } from './services/air-statement.service';
import { DownloadPdfModule } from '../../../../core/download-pdf/download-pdf.module';
import { AlertService } from '../../../../core/alert/alert.service';

@NgModule({
  declarations: [AIRStatementComponent],
  imports: [
    CommonModule,
    NgxsFormPluginModule,
    ReactiveFormsModule,
    FormsModule,
    NgxsModule.forFeature([AIRStatementState]),
    LoaderModule,
    DownloadPdfModule
  ],
  exports: [
    AIRStatementComponent
  ],
  providers: [
    AIRStatementService,
    AlertService
  ]
})
export class AIRStatementModule { }
