<form [formGroup]="proposedDetailsForm" ngxsForm="hmrProposedDetailsFormBasic.proposedDetailsForm" class="p-4">
  <div class="form-group pb-1">
    <label for="proposedDateOfInterview" class="input-label">Proposed date of Interview</label>
    <input type="text" [class.has-error]="formControls.proposedDateOfInterview.invalid && (showInvalid$ | async)"
      class="form-control form-control-lg w-25"
      value="proposedDetailsForm.value.proposedDateOfInterview | date: 'DD-MM-YYYY'"
      formControlName="proposedDateOfInterview" id="proposedDateOfInterview"
      [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY' }" placeholder="dd-mm-yyyy" bsDatepicker>
  </div>
  <div class="form-group pb-1">
    <label class="input-label mb-0">Terms and conditions</label><br />
    <app-radio-highlight [hasError]="formControls.termsAndConditions.invalid && (showInvalid$ | async)"
      controlName="termsAndConditionsYes" [isHighlighted]="proposedDetailsForm.value.termsAndConditions == true">
      <input class="form-check-input" type="radio" name="termsAndConditions" id="termsAndConditionsYes"
        formControlName="termsAndConditions" [value]="true" checked>
      <label class="form-check-label" for="termsAndConditionsYes">Yes</label>
    </app-radio-highlight>
    <app-radio-highlight [hasError]="formControls.termsAndConditions.invalid && (showInvalid$ | async)"
      controlName="termsAndConditionsNo" [isHighlighted]="proposedDetailsForm.value.termsAndConditions == false">
      <input class="form-check-input" type="radio" name="termsAndConditions" id="termsAndConditionsNo"
        formControlName="termsAndConditions" [value]="false">
      <label class="form-check-label" for="termsAndConditionsNo">No</label>
    </app-radio-highlight>
  </div>
</form>