import { Component, OnInit } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { VaccinationState } from '../../state/vaccination.state';

@Component({
  selector: 'app-vaccination-route',
  templateUrl: './vaccination-route.component.html',
  styleUrls: []
})
export class VaccinationRouteComponent implements OnInit {
  @Select(VaccinationState.validating) validating$: Observable<boolean>;

  constructor() { }

  ngOnInit() {
  }

}
