import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxsModule } from '@ngxs/store';
import { NotesComponent } from './notes.component';
import { ActionsComponent } from './actions/actions.component';
import { CrmService } from '../../../shared/services/crm.service';
import { LoaderModule } from '../../../modules/core/loader/loader.module';
import { NotesFilterPipe } from './notes-filter.pipe';
import { NoteSettingsModule } from '../../../modules/core/note-settings/note-settings.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NoteTypePipeModule } from '../../../shared/pipes/noteTypeIcon.pipe';
import { BsDropdownModule } from 'ngx-bootstrap';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { NotesState } from './state/notes.state';
import { PipesModule } from '../../../shared/pipes/pipes.module';
import { TimezoneModule } from '../../../modules/core/timezone/timezone.module';
import { CrmNotificationDateModule } from '../../../modules/crm/crm-notification-date/crm-notification-date.module';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import { CharacterCountService } from '../../../shared/helpers/character-count.service';
import { SmsService } from '../../../shared/services/sms.service';
import { FluidModalModule } from '../../../modules/core/fluid-modal/fluid-modal.module';

@NgModule({
  declarations: [
    NotesComponent,
    ActionsComponent,
    NotesFilterPipe,
  ],
  providers: [
    CrmService,
    CharacterCountService,
    SmsService
  ],
  imports: [
    PipesModule,
    CommonModule,
    FormsModule,
    LoaderModule,
    TimezoneModule,
    CrmNotificationDateModule,
    NoteTypePipeModule,
    NoteSettingsModule,
    BsDatepickerModule,
    BsDropdownModule,
    NgxsModule.forFeature([
      NotesState
    ]),
    ReactiveFormsModule,
    NgxsFormPluginModule,
    FluidModalModule
  ],
  exports: [
    NotesComponent
  ]
})
export class NotesModule { }
