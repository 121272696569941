import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { PaginationModule, TabsModule } from 'ngx-bootstrap';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { NgxsModule } from '@ngxs/store';
import { AdvancedSearchState } from './state/advanced-search.state';
import { AdvancedSearchComponent } from './advanced-search.component';
import { AdvancedSearchService } from './services/advanced-search.service';
import { RouterModule } from '@angular/router';
import { TimezoneModule } from '~/modules/core/timezone/timezone.module';
import { AlertService } from '~/modules/core/alert/alert.service';
import { FormControlFunctions } from '~/modules/profile/six-cpa/form-control.functions';
import { FeaturesState } from '~/modules/core/features/features.state';
import { AdvancedSearchResolver } from './advanced-search.resolver';
import { BulkMessagingStateModule } from '../bulk-messaging-page/state/bulk-messaging-state.module';
import { ScryptSearchModule } from './scrypt-search/scrypt-search.module';
import { AdvancedSearchResultsTableComponent } from './advanced-search-results-table/advanced-search-results-table.component';
import { ScryptSearchState } from './state/scrypt-search.state';
import { ScryptSearchResultsTableModule } from './scrypt-search-results-table/scrypt-search-results-table.module';
import { CdkTableModule } from '@angular/cdk/table';
import { AdvancedSearchExportOptionsModule } from './advanced-search-export-options/advanced-search-export-options.module';
import { ScryptInsightModule } from '~/shared/scrypt-insight/scrypt-insight.module';
import { LoaderModule } from '~/modules/core/loader/loader.module';

@NgModule({
  declarations: [AdvancedSearchComponent, AdvancedSearchResultsTableComponent],
  exports: [],
  providers: [
    AdvancedSearchService,
    AlertService,
    FormControlFunctions,
    AdvancedSearchResolver
  ],
  imports: [
    FormsModule,
    TabsModule,
    RouterModule,
    CommonModule,
    ReactiveFormsModule,
    TimezoneModule,
    BsDatepickerModule.forRoot(),
    BulkMessagingStateModule,
    NgxsModule.forFeature([
      AdvancedSearchState,
      ScryptSearchState,
      FeaturesState]),
    ScryptSearchModule,
    ScryptSearchResultsTableModule,
    CdkTableModule,
    PaginationModule.forRoot(),
    AdvancedSearchExportOptionsModule,
    ScryptInsightModule,
    LoaderModule
  ]
})
export class AdvancedSearchModule {
  constructor() { }
}
