import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SickLeaveFormListComponent } from './sick-leave-form-list.component';
import { SickLeaveService } from './services/sick-leave.service';
import { NgxsModule } from '@ngxs/store';
import { SickLeaveState } from './state/sick-leave.state';
import { SickLeaveConsentAndEligibilityListItemModule } from '../sick-leave-consent-and-eligibility-list-item/sick-leave-consent-and-eligibility-list-item.module';
import { SickLeaveConsentAndEligibilityState } from '../sick-leave-consent-and-eligibility/state/sick-leave-consent-and-eligibility.state';
import { SickLeaveCertificateTypeListItemModule } from '../sick-leave-certificate-type-list-item/sick-leave-certificate-type-list-item.module';
import { SickLeaveCertificateTypeState } from '../sick-leave-certificate-type/state/sick-leave-certificate-type.state';
import { SickLeaveConsulationState } from '../sick-leave-consulation/state/sick-leave-consulation.state';
import { SickLeaveConsulationListItemModule } from '../sick-leave-consulation-list-item/sick-leave-consulation-list-item.module';
import { SickLeavePharmacistDeclarationListItemModule } from '../sick-leave-pharmacist-declaration-list-item/sick-leave-pharmacist-declaration-list-item.module';
import { SickLeavePharmacistDeclarationState } from '../sick-leave-pharmacist-declaration/state/sick-leave-pharmacist-declaration.state';
import { SickLeavePatientDetailsListItemModule } from '../sick-leave-patient-details-list-item/sick-leave-patient-details-list-item.module';
import { SickLeavePatientDetailsState } from '../sick-leave-patient-details/state/sick-leave-patient-details.state';
import { ConfirmResetModule } from '../../../../../shared/components/confirm-reset/confirm-reset.module';
import { LoaderModule } from '../../../../core/loader/loader.module';
import { SickLeaveSaveState } from './state/sick-leave-save.state';

@NgModule({
  declarations: [
    SickLeaveFormListComponent
  ],
  imports: [
    CommonModule,
    NgxsModule.forFeature([
      SickLeaveState,
      SickLeaveSaveState,
      SickLeaveConsentAndEligibilityState,
      SickLeaveCertificateTypeState,
      SickLeaveConsulationState,
      SickLeavePharmacistDeclarationState,
      SickLeavePatientDetailsState,
    ]),
    SickLeaveConsentAndEligibilityListItemModule,
    SickLeaveCertificateTypeListItemModule,
    SickLeaveConsulationListItemModule,
    SickLeavePharmacistDeclarationListItemModule,
    SickLeavePatientDetailsListItemModule,
    ConfirmResetModule,
    LoaderModule
  ],
  exports: [
    SickLeaveFormListComponent
  ],
  providers: [
    SickLeaveService
  ]
})
export class SickLeaveModule { }
