import { ChangeDetectionStrategy, Component, Input, Output, EventEmitter } from '@angular/core';
import { OrderRequest } from '@profile/order-requests/models/order-request.model';
import { OrderRequestStatus } from '@profile/order-requests/models/order.enums';
import { OrderStatusLabelMap } from '../../order-mgmt.constants';

@Component({
    selector: 'app-status-selector',
    templateUrl: './status-selector.component.html',
    styleUrls: ['./status-selector.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class StatusSelectorComponent {
    @Input()
    set order(order: OrderRequest) {
        this.orderRequest = order;
        this.originalStatus = order?.status;
    }
    @Input() showNotifyCheckbox: boolean;
    @Input() orderRequestRowMode: boolean;
    @Input() showQuickCompleteButton: boolean;
    @Input() itemsTableMode: boolean;
    @Output() statusUpdatedEvent = new EventEmitter<{ order: OrderRequest, notifyCustomer: boolean }>();
    @Output() completeOrderEvent = new EventEmitter<{ order: OrderRequest, notifyCustomer: boolean }>();

    orderRequest: OrderRequest;
    orderStatusEnum = OrderRequestStatus;
    notifyCustomer = !this.isCompleteOrder;
    originalStatus: OrderRequestStatus;
    orderStatusLabelMap = OrderStatusLabelMap;

    get isCompleteOrder(): boolean {
        return this.originalStatus === OrderRequestStatus.Ready || this.originalStatus === OrderRequestStatus.UnableToFill;
    }

    updateStatus(): void {
        this.statusUpdatedEvent.emit({
            order: this.orderRequest,
            notifyCustomer: this.sendNotification()
        });
    }

    completeOrder(): void {
        this.completeOrderEvent.emit({
            order: this.orderRequest,
            notifyCustomer: this.sendNotification()
        });
    }

    private sendNotification = (): boolean => this.notifyCustomer && !this.isCompleteOrder;
}
