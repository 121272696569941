
<div class="comms-queue-table">
  <table cdk-table [dataSource]="dataOnPage$">
    <tr cdk-header-row *cdkHeaderRowDef="displayedColumns$.value"></tr>
    <tr cdk-row *cdkRowDef="let row; columns: displayedColumns$.value"></tr>

    <ng-container cdkColumnDef="channel">
      <th cdk-header-cell *cdkHeaderCellDef> Channel </th>
      <td cdk-cell *cdkCellDef="let row"> {{row.commType}} </td>
    </ng-container>

    <ng-container cdkColumnDef="patient">
      <th cdk-header-cell *cdkHeaderCellDef> Patient </th>
      <td cdk-cell *cdkCellDef="let row">
        <a [routerLink]="['/profile', row.clientID, 'categories']">
          {{row.clientName}}
        </a>
      </td>
    </ng-container>

    <ng-container cdkColumnDef="template">
      <th cdk-header-cell *cdkHeaderCellDef> Template </th>
      <td cdk-cell *cdkCellDef="let row"> <div (click)="showTemplate(row.template)" class="link">{{row.template.name}}</div> </td>
    </ng-container>

    <ng-container cdkColumnDef="medication">
      <th cdk-header-cell *cdkHeaderCellDef> Medication </th>
      <td cdk-cell *cdkCellDef="let row">
        <ng-container *ngIf="row.medications">
          <span *ngIf="row.medications.length === 1">{{row.medications[0]}}</span>
          <span *ngIf="row.medications.length > 1">
            <ng-template #medications>
              <ul class="medications-list">
                <li *ngFor="let medication of row.medications">{{medication}}</li>
              </ul>
            </ng-template>
            <app-scrypt-insight
                placement="bottom"
                [content]="medications"
                [context]="'test test'"></app-scrypt-insight>
                <strong>{{row.medications.length}} Medicines</strong>
          </span>
        </ng-container>
      </td>
    </ng-container>

    <ng-container cdkColumnDef="sendDate">
      <th cdk-header-cell *cdkHeaderCellDef> Date/Time </th>
      <td cdk-cell *cdkCellDef="let row"> {{row.sendDate | dateInFuture}} </td>
    </ng-container>

    <ng-container cdkColumnDef="actions">
      <th cdk-header-cell *cdkHeaderCellDef> Actions </th>
      <td cdk-cell *cdkCellDef="let row"> <div (click)="showMessage(row)" class="link">View</div> </td>
    </ng-container>

  </table>

  <pagination
    #pagination
   [boundaryLinks]="true"
   [totalItems]="(dataSource | async).length"
   [itemsPerPage]="pageSize | async"
    [ngModel]="currentPage$ | async"
    (ngModelChange)="currentPage$.next($event)"
   ></pagination>
</div>
