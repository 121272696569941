import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxsModule } from '@ngxs/store';
import { MedListMedicationProfileState } from './state/med-list-medication-profile.state';
import { SixCpaFormListItemChecklistModule } from '../../six-cpa-form-list-item-checklist/six-cpa-form-list-item-checklist.module';
import { MedicationProfileListItemModule } from '../../medication-profile/medication-profile-list-item/medication-profile-list-item.module';
import { MedListMedicationProfileComponent } from './med-list-medication-profile.component';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [MedListMedicationProfileComponent],
  imports: [
    CommonModule,
    NgxsModule.forFeature([
      MedListMedicationProfileState
    ]),
    NgxsFormPluginModule,
    ReactiveFormsModule,
    FormsModule,
    SixCpaFormListItemChecklistModule,
    MedicationProfileListItemModule
  ],
  exports: [MedListMedicationProfileComponent]
})
export class MedListMedicationProfileModule { }
