import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { DoctorReportTemplatesSelectorState } from '../doctor-report-templates-selector/state/doctor-report-templates-selector.state';
import { MarketingSetting } from '../../models/marketingSetting.model';

import { QuillService } from 'ngx-quill';
@Component({
  selector: 'app-customise-doctor-report',
  templateUrl: './customise-doctor-report.component.html'
})
export class CustomiseDoctorReportComponent implements OnInit {
  customisedDoctorReport: FormGroup;
  @Select(DoctorReportTemplatesSelectorState.selectedTemplate) selectedTemplate$: Observable<MarketingSetting>;
  modules = {toolbar: [
    ['bold', 'italic', 'underline', 'strike'],        // toggled buttons

    [{ 'header': 1 }, { 'header': 2 }],               // custom button values
    [{ 'list': 'ordered'}, { 'list': 'bullet' }],
    [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
    [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
    [{ 'direction': 'rtl' }],                         // text direction

    [{ 'size': ['10px', false, '20px', '40px'] }],  // custom dropdown
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

    [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
    [{ 'font': [] }],
    [{ 'align': [] }],

    ['clean'],                                         // remove formatting button

                            // link and image, video
  ]}
  constructor(private formBuilder: FormBuilder, private service: QuillService) {
    this.generateFormControls();
    this.forceQuillInlineStyling();
  }

  ngOnInit() {
  }

  private async forceQuillInlineStyling() {
    const Quill = await this.service.getQuill();
    
    // configure Quill to use inline styles so the email's format properly
    var DirectionAttribute = Quill.import('attributors/attribute/direction');
    Quill.register(DirectionAttribute,true);

    var AlignClass = Quill.import('attributors/class/align');
    Quill.register(AlignClass,true);

    var BackgroundClass = Quill.import('attributors/class/background');
    Quill.register(BackgroundClass,true);

    var ColorClass = Quill.import('attributors/class/color');
    Quill.register(ColorClass,true);

    var DirectionClass = Quill.import('attributors/class/direction');
    Quill.register(DirectionClass,true);

    var FontClass = Quill.import('attributors/class/font');
    Quill.register(FontClass,true);

    var SizeClass = Quill.import('attributors/class/size');
    Quill.register(SizeClass,true);

    var AlignStyle = Quill.import('attributors/style/align');
    Quill.register(AlignStyle,true);

    var BackgroundStyle = Quill.import('attributors/style/background');
    Quill.register(BackgroundStyle,true);

    var ColorStyle = Quill.import('attributors/style/color');
    Quill.register(ColorStyle,true);

    var DirectionStyle = Quill.import('attributors/style/direction');
    Quill.register(DirectionStyle,true);

    var FontStyle = Quill.import('attributors/style/font');
    Quill.register(FontStyle,true);
    
    var SizeStyle = Quill.import('attributors/style/size');
    SizeStyle.whitelist = ['10px', '20px', '40px'];
    Quill.register(SizeStyle,true);
  }

  private generateFormControls() {
    this.customisedDoctorReport = this.formBuilder.group({
      reportBody: new FormControl(''),
      subject: new FormControl('')
    });
  }
}
