import { Injectable } from '@angular/core';
import { State, Action, StateContext, Selector, Store } from '@ngxs/store';
import { catchError, finalize, tap } from 'rxjs/operators';
import { ProfileState } from '../../../../modules/core/profile/state/profile.state';
import { PrimaryContactSummary } from '../models/primary-contact-summary.model';
import { PrimaryContactService } from '../services/primary-contact.service';
import { ChangePrimaryContact, ChangePrimaryContactError, ChangePrimaryContactSuccess, GetPrimaryContactSummary, RemovePrimaryContact, RemovePrimaryContactError, RemovePrimaryContactSuccess, SetPrimaryContactStateStatus, SetPrimaryContactSummary, TogglePrimaryContactModal } from './primary-contact.actions';

export class PrimaryContactStateModel {
  public summary: PrimaryContactSummary;
  public stateStatus: PrimaryContactStateStatus;
  public showModal: boolean;
  public error: any;
  public hasLinkedContacts: boolean;
}

export enum PrimaryContactStateStatus {
  Finalised,
  Error,
  Loading,
  None
}

@State<PrimaryContactStateModel>({
  name: 'primaryContact',
  defaults: {
    summary: null,
    stateStatus: PrimaryContactStateStatus.None,
    showModal: false,
    error: null,
    hasLinkedContacts: false
  }
})
@Injectable()
export class PrimaryContactState {
  constructor(
    private contactService: PrimaryContactService,
    private store: Store
  ) { }

  @Selector()
  static state(state: PrimaryContactStateModel) {
    return state;
  }

  @Selector()
  static stateStatus(state: PrimaryContactStateModel) {
    return state.stateStatus;
  }

  @Selector()
  static summary(state: PrimaryContactStateModel) {
    return state.summary;
  }

  @Action(GetPrimaryContactSummary)
  getPrimaryContactSummary(ctx: StateContext<PrimaryContactStateModel>, { tenantCustomerId, email, mobile }: GetPrimaryContactSummary) {
    ctx.patchState({
      stateStatus: PrimaryContactStateStatus.Loading
    });
    return this.contactService.getSummary(tenantCustomerId, email, mobile)
      .pipe(
        tap(summary => ctx.dispatch(new SetPrimaryContactSummary(summary))),
        catchError(_ => ctx.dispatch(new SetPrimaryContactStateStatus(PrimaryContactStateStatus.Error))),
        finalize(() => ctx.patchState({ stateStatus: PrimaryContactStateStatus.Finalised }))
      )
  }

  @Action(SetPrimaryContactSummary)
  setPrimaryContactSummary(ctx: StateContext<PrimaryContactStateModel>, { summary }: SetPrimaryContactSummary) {
    ctx.patchState({
      stateStatus: PrimaryContactStateStatus.Finalised,
      summary: summary,
      hasLinkedContacts: this.hasLinkedContacts(summary)
    });
  }

  hasLinkedContacts(summary: PrimaryContactSummary) {
    if (summary && summary.contacts.length > 0) {
      const linkedContacts = summary.contacts.filter(contact => contact.tenantCustomerId != summary.tenantCustomerId || contact.tenantCustomerId == null);
      if (linkedContacts.length > 0) {
        return true;
      }
    }
    return false;
  }

  @Action(SetPrimaryContactStateStatus)
  setPrimaryContactStateStatus(ctx: StateContext<PrimaryContactStateModel>, { status }: SetPrimaryContactStateStatus) {
    ctx.patchState({
      stateStatus: status
    })
  }

  @Action(TogglePrimaryContactModal)
  togglePrimaryContactModal(ctx: StateContext<PrimaryContactStateModel>, { show }: TogglePrimaryContactModal) {
    ctx.patchState({
      showModal: show
    })
  }

  /* Change Contact Actions */
  @Action(ChangePrimaryContact)
  changePrimaryContact(ctx: StateContext<PrimaryContactStateModel>, { request }: ChangePrimaryContact) {
    ctx.patchState({
      stateStatus: PrimaryContactStateStatus.Loading
    });

    return this.contactService.changePrimaryContact(request)
      .pipe(
        tap(() => ctx.dispatch(new ChangePrimaryContactSuccess())),
        catchError(err => ctx.dispatch(new ChangePrimaryContactError(err)))
      )
  }

  @Action(ChangePrimaryContactSuccess)
  changePrimaryContactSuccess(ctx: StateContext<PrimaryContactStateModel>, { }: ChangePrimaryContactSuccess) {
    const profile = this.store.selectSnapshot(ProfileState.profile);
    ctx.dispatch(
      new GetPrimaryContactSummary(
        profile.customerProfile.tenantCustomerId,
        profile.patient.mobileNumber,
        profile.patient.emailAddress
      )
    );
  }

  @Action(ChangePrimaryContactError)
  changePrimaryContactError(ctx: StateContext<PrimaryContactStateModel>, { error }: ChangePrimaryContactError) {
    ctx.patchState({
      stateStatus: PrimaryContactStateStatus.Error,
      error: JSON.stringify(error)
    });
  }

  /* Remove Contact Actions */
  @Action(RemovePrimaryContact)
  removePrimaryContact(ctx: StateContext<PrimaryContactStateModel>, { tenantCustomerId, primaryContactType }: RemovePrimaryContact) {
    ctx.patchState({
      stateStatus: PrimaryContactStateStatus.Loading
    });

    return this.contactService.removePrimaryContact(tenantCustomerId, primaryContactType)
      .pipe(
        tap(() => ctx.dispatch(new RemovePrimaryContactSuccess())),
        catchError(err => ctx.dispatch(new RemovePrimaryContactError(err)))
      )
  }

  @Action(RemovePrimaryContactSuccess)
  removePrimaryContactSuccess(ctx: StateContext<PrimaryContactStateModel>, { }: RemovePrimaryContactSuccess) {
    const profile = this.store.selectSnapshot(ProfileState.profile);
    ctx.dispatch(
      new GetPrimaryContactSummary(
        profile.customerProfile.tenantCustomerId,
        profile.patient.mobileNumber,
        profile.patient.emailAddress
      )
    );
  }

  @Action(RemovePrimaryContactError)
  removePrimaryContactError(ctx: StateContext<PrimaryContactStateModel>, { error }: RemovePrimaryContactError) {
    ctx.patchState({
      stateStatus: PrimaryContactStateStatus.Error,
      error: JSON.stringify(error)
    });
  }
}
