import { toArrayFromDict, toDictionary } from '../arrays/arrays.helper';
import * as moment_ from 'moment';
const moment = moment_;
import { Alert } from './alert.model';
import { removeItemFromDict } from '../dict/dict.logic';
import { Observable } from 'rxjs';
import { mergeMap, map } from 'rxjs/operators';


export function addAlertToDictionary(alerts: {[key: string]: Alert}) {
    return (alert: Alert) => {
        return toDictionary(getIdentifier)([...toArrayFromDict(alerts), getAndDefault(alert)]);
    };
}

export function removeAlertFromDictionary(alerts: {[key: string]: Alert}) {
    return removeItemFromDict(getIdentifier)(alerts);
}

export function filterToTemporalyRelevantAlerts(alerts: { [key: string]: Alert}) {
    return toArrayFromDict(alerts)
    .filter(alert => moment(alert.createTime).add(alert.timeout, 'ms').isAfter(moment()));
}


export function getTemporallyRelevantAlerts(tick: Observable<any>) {
    return (alerts$: Observable<{ [key: string]: Alert }>) => tick.pipe(
        mergeMap(() => alerts$),
        map((alerts) => filterToTemporalyRelevantAlerts(alerts as any))
    );
}

export function getIdentifier(alert: Alert) {
    return `${alert.type}-${alert.message}`;
}

export function getAndDefault(alert: Alert) {
    return {
        createTime: new Date(),
        timeout: 10000,
        ...alert
    };
}
