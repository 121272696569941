import { State, Selector, Action, StateContext } from '@ngxs/store';
import { SetTotalItemsAndCompletedItems, SetOutcomesAndActionsFormValidAction, UpdateOutcomesAndActionsForm } from './outcomes-and-actions-form.actions';
import { Injectable } from '@angular/core';

export class OutcomesAndActionsFormStateModel {
  outcomesAndActionsForm: {
    model: Partial<IOutcomesAndActionsFormStateModel>;
  };
  valid: boolean;
  totalItems: number;
  completedItems: number;
}

export interface IOutcomesAndActionsFormStateModel {
  medsCheckOutcome: {
    recommendNoChange: boolean;
    recommendIncreaseDose: boolean;
    recommendDecreaseDose: boolean;
    increaseKnowledgeMedicineRegime: boolean;
    increaseKnowledgeMedicineAdherence: boolean;
    other: boolean;
  };
  pharmacistActions: {
    actionPlanToPatient: boolean;
    actionPlanToGP: boolean;
    gPVerballyConsulted: boolean;
    referredToGP: boolean;
    other: boolean;
  };
  medsCheckOutcomeOtherDetails: string | null;
  pharmacistActionsOtherDetails: string | null;
}
@Injectable()
@State<OutcomesAndActionsFormStateModel>({
  name: 'outcomesAndActionsForm',
  defaults: {
    outcomesAndActionsForm: {
      model: undefined
    },
    valid: false,
    completedItems: 0,
    totalItems: 0
  }
})
export class OutcomesAndActionsFormState {
  @Selector()
  static outcomesAndActionsForm(state: OutcomesAndActionsFormStateModel) { return state.outcomesAndActionsForm.model; }

  @Selector()
  static totalItems(state: OutcomesAndActionsFormStateModel) { return state.totalItems; }

  @Selector()
  static completedItems(state: OutcomesAndActionsFormStateModel) { return state.completedItems; }

  @Action(SetOutcomesAndActionsFormValidAction)
  setValid(ctx: StateContext<OutcomesAndActionsFormStateModel>, action: SetOutcomesAndActionsFormValidAction) {
    ctx.patchState({
      valid: action.valid
    });
  }

  @Action(SetTotalItemsAndCompletedItems)
  setProgress(ctx: StateContext<OutcomesAndActionsFormStateModel>, action: SetTotalItemsAndCompletedItems) {
    ctx.patchState({
      totalItems: action.totalItems,
      completedItems: action.completedItems
    });
  }

  @Action(UpdateOutcomesAndActionsForm)
  updateForm(ctx: StateContext<OutcomesAndActionsFormStateModel>, { form }: UpdateOutcomesAndActionsForm) {
    const { pharmacistActions, medsCheckOutcome, medsCheckOutcomeOtherDetails, pharmacistActionsOtherDetails } = form;
    ctx.patchState({
      outcomesAndActionsForm: {
        model: {
          pharmacistActions: pharmacistActions,
          medsCheckOutcome: medsCheckOutcome,
          medsCheckOutcomeOtherDetails: medsCheckOutcomeOtherDetails,
          pharmacistActionsOtherDetails: pharmacistActionsOtherDetails,
        }
      }
    });
  }
}
