import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import {
  CommunicationsState,
  CommunicationsStateModel,
} from '../state/communications.state';

@Component({
  selector: 'app-sent-messages-status-bar',
  templateUrl: './sent-messages-status-bar.component.html',
  styleUrls: ['./sent-messages-status-bar.component.scss'],
})
export class SentMessagesStatusBarComponent implements OnInit, OnDestroy {
  targetTotal: number = 200;

  @Select(CommunicationsState.monthlySent) monthlySent$: Observable<number>;
  constructor(private store: Store) {}

  ngOnInit(): void {
  }

  ngOnDestroy(): void {}

}
