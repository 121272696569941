import { DoseAdministrationAidsComponent } from './dose-administration-aids.component';
import { DoseAdministrationAidsService } from './services/dose-administration-aids.service';
import { DoseAdministrationAidsState } from './state/dose-administration-aids.state';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import { ReactiveFormsModule } from '@angular/forms';
import { RadioHighlightModule } from '../../../../../shared/components/radio-highlight/radio-highlight.module';
import { PatientSignatureModule } from '../../patient-signature/patient-signature.module';
import { NgxsModule } from '@ngxs/store';
import { DAAPatientFormListItemModule } from '../daa-patient-form-list-item/daa-patient-form-list-item.module';
import { DAAPatientDetailsStateModule } from '../daa-patient-details-form/daa-patient-details-state.module';
import { DAAMedicationInformationFormState } from '../daa-medication-information-form/state/daa-medication-information-form.state';
import { DAAMedicationInformationFormListItemModule } from '../daa-medication-information-form-list-item/daa-medication-information-form-list-item.module';
import { DAAClassificationsRecommendationsListItemModule } from '../daa-classifications-recommendations-form-list-item/daa-classifications-recommendations-form-list-item.module';
import { DAAClassificationsRecommendationsState } from '../daa-classifications-recommendations/state/daa-classifications-recommendations.state';
import { DAAAdditionalDetailsFormState } from '../daa-additional-details-form/state/daa-additional-details-form.state';
import { DAAAdditionalDetailsFormListItemModule } from '../daa-additional-details-form-list-item/daa-additional-details-form-list-item.module';
import { DAAConsentAndEligibilityFormListItemModule } from '../daa-consent-and-eligibility-form-list-item/daa-consent-and-eligibility-form-list-item.module';
import { DAAConsentAndEligibilityFormState } from '../daa-consent-and-eligibility-form/state/daa-consent-and-eligibility-form.state';
import { DAAMedicationProfileState } from '../daa-medication-profile/state/daa-medication-profile.state';
import { DAAMedicationProfileModule } from '../daa-medication-profile/daa-medication-profile.module';
import { DAAFormsService } from './state/daa-forms.service';
import { ConfirmResetModule } from '../../../../../shared/components/confirm-reset/confirm-reset.module';
import { LoaderModule } from '../../../../core/loader/loader.module';
import { DoseAdministrationAidsSaveState } from "./state/daa-administration-aids-save.state";

@NgModule({
  declarations: [
    DoseAdministrationAidsComponent
  ],
  imports: [
    CommonModule,
    NgxsFormPluginModule,
    ReactiveFormsModule,
    RadioHighlightModule,
    PatientSignatureModule,
    NgxsModule.forFeature([
      DoseAdministrationAidsState,
      DoseAdministrationAidsSaveState,
      DAAMedicationInformationFormState,
      DAAClassificationsRecommendationsState,
      DAAAdditionalDetailsFormState,
      DAAConsentAndEligibilityFormState,
      DAAMedicationProfileState
    ]),
    DAAPatientFormListItemModule,
    DAAPatientDetailsStateModule,
    DAAMedicationInformationFormListItemModule,
    DAAClassificationsRecommendationsListItemModule,
    DAAAdditionalDetailsFormListItemModule,
    DAAConsentAndEligibilityFormListItemModule,
    DAAMedicationProfileModule,
    ConfirmResetModule,
    LoaderModule
  ],
  exports: [
    DoseAdministrationAidsComponent
  ],
  providers: [
    DoseAdministrationAidsService,
    DAAFormsService
  ]
})
export class DoseAdministrationAidsModule { }
