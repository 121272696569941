<form [formGroup]="customisedDoctorReport" ngxsForm="customisedDoctorReport.customisedDoctorReport" class="px-4 pb-2">
  <app-doctor-report-templates-selector></app-doctor-report-templates-selector>

  <ng-container *ngIf="(selectedTemplate$ | async) as selectedTemplate; else noTemplate">
    <p class="col-form-label-lg">Email Subject:</p>
    <input type="text" formControlName="subject" class="form-control form-control-lg"
      [value]="selectedTemplate.subject" />
      <p class="col-form-label-lg">Message Body:</p>
      <quill-editor
       [value]="selectedTemplate.messageTemplate"
       formControlName="reportBody"
       [modules]="modules"
       >
      </quill-editor>
  </ng-container>
  <ng-template #noTemplate>
    <p class="col-form-label-lg">Email Subject:</p>
    <input type="text" formControlName="subject" class="form-control form-control-lg" value="Your subject" />
    <p class="col-form-label-lg">Message Body:</p>
    <quill-editor
      formControlName="reportBody"
      [modules]="modules">
    </quill-editor>
  </ng-template>
</form>
