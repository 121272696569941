import { PatientDetailsFormModel } from '../../../../../shared/models/six-cpa/patient-details-form.model';

export enum PatientDetailsFormActions {
  UPDATE_PROGRESS = '[PatientDetailsForm] Update Progress',
  UPDATE = '[PatientDetailsForm] Update'
}

export class SetTotalItemsAndCompletedItems {
  static readonly type = PatientDetailsFormActions.UPDATE_PROGRESS;
  constructor(public totalItems: number, public completedItems: number) { }
}

export class UpdatePatientDetailsForm {
  static readonly type = PatientDetailsFormActions.UPDATE;
  constructor(public form: Partial<PatientDetailsFormModel>) { }
}
