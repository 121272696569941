import { Component, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { SickLeaveState } from './state/sick-leave.state';
import { Observable, forkJoin } from 'rxjs';
import { ResetSickLeaveFormList, SubmitExisitingSickLeave, SubmitNewSickLeave, SaveNewSickLeave, SaveExisitingSickLeave, ShowInvalidSickLeaveForm } from './state/sick-leave.actions';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { SickLeaveFormsProgressService } from './state/sick-leave-forms-progress.service';
import { filter, switchMap, map, withLatestFrom } from 'rxjs/operators';
import { SickLeaveFormsService } from './state/sick-leave-forms.service';
import { ProfileState } from '../../../../core/profile/state/profile.state';
import { OpenResetFormModal, CloseResetFormModal, ToggleSixCpaContentAction } from '../../state/six-cpa.actions';
import { SixCpaState } from '../../state/six-cpa.state';
import { SaveOrUpdateSickLeaveAction } from './state/sick-leave-save.actions';
import { ToggleSickLeaveConfirmAction } from '../sick-leave-confirm/state/sick-leave-confirm.actions';
@Component({
  selector: 'app-sick-leave-form-list',
  templateUrl: './sick-leave-form-list.component.html',
  styleUrls: ['./sick-leave-form-list.component.scss']
})
export class SickLeaveFormListComponent implements OnInit {
  @Select(SickLeaveState.saving) saving$: Observable<boolean>;
  @Select(SixCpaState) sixCpaState$: Observable<any>;

  constructor(private store: Store) { }

  ngOnInit() {
  }
  resetSickLeaveFormList() {
    this.store.dispatch(new ResetSickLeaveFormList());
  }

  openConfirmResetModal() {
    this.store.dispatch(new OpenResetFormModal());
  }

  closeConfirmResetModal() {
    this.store.dispatch(new CloseResetFormModal());
  }

  @Dispatch()
  viewConfirmation = () => [new ToggleSixCpaContentAction('out'), new ToggleSickLeaveConfirmAction('in')];

  @Dispatch()
  submitSickLeave = () => {
    if (this.store.selectSnapshot(SickLeaveFormsProgressService.getProgress) !== 100) {
      this.store.dispatch(new ShowInvalidSickLeaveForm());
    }
    return this.store.selectOnce(SickLeaveFormsProgressService.getProgress).pipe(
      filter(progress => progress === 100),
      switchMap(() => forkJoin([
        this.store.selectOnce(SickLeaveFormsService.getSickLeaveRequest),
        this.store.selectOnce(ProfileState.clientId)
      ])),
      map(([formData, clientId]) =>
        formData && formData.sixCpaFormId !== null
          ? new SubmitExisitingSickLeave(clientId, formData)
          : new SubmitNewSickLeave(clientId, formData)))
  }


  @Dispatch()
  saveSickLeave = () => new SaveOrUpdateSickLeaveAction();
}
