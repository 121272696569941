import { greaterMagnitudeThanOne } from '../magnitudes/magnitudes.logic';

export function hasOrderSuggestionInsight(drug: {suggestedOrderQty: number}) {
    const { suggestedOrderQty } = drug;
    return greaterMagnitudeThanOne(suggestedOrderQty);
}

export function getOrderSuggestion(calculatedSuggestedOrder: number) {
    return calculatedSuggestedOrder > 0 ? 1 : 0;
}

export function getOrderSuggestionFromString(suggestion: string | number) {
    return getOrderSuggestion(parseFloat(`${suggestion}`));
}
