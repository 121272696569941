import { PipeTransform, Pipe } from '@angular/core';
import { ScriptHistory } from '../../../../../shared/models/script/script-history.model';
@Pipe({
  name: 'orderByOriginalScript'
})
export class OrderByOriginalScriptPipe implements PipeTransform {
  transform(scripts: ScriptHistory[]): ScriptHistory[] {
    if (!scripts) {
      return scripts;
    }

    scripts = scripts.sort((x, y) => {
      return new Date(y.prescriptionDate).getTime() - new Date(x.prescriptionDate).getTime();
    });

    scripts = scripts.sort((x, y) => {
      return new Date(y.dispensedDate).getTime() - new Date(x.dispensedDate).getTime();
    });

    scripts = scripts.sort((x, y) => {
      return (!x.isPassiveScript === !y.isPassiveScript)? 0 : x? -1 : 1;
    });

    return scripts;
  }
}

