import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: 'stripWhiteSpace'
})
export class StripWhiteSpacePipe implements PipeTransform {
  transform(value: string) {
    if (value)
      return this.stripWhiteSpace(value);
  }

  stripWhiteSpace(value: string) {
    return value.replace(/\s/g, '');
  }
}
