import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SickLeaveConfirmComponent } from './sick-leave-confirm.component';
import { NgxsModule } from '@ngxs/store';
import { SickLeaveConfirmState } from './state/sick-leave-confirm.state';
import { DownloadPdfModule } from '../../../../core/download-pdf/download-pdf.module';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import { LoaderModule } from '../../../../core/loader/loader.module';
import { CustomiseDoctorReportModule } from '../../../../../shared/components/customise-doctor-report/customise-doctor-report.module';
import { ReactiveFormsModule } from '@angular/forms';
import { TypeaheadModule } from 'ngx-bootstrap';
import { ReportResponseModule } from '../../report-response/report-response.module';
import { ChannelSelectModalModule } from './../../../../../modules/profile/six-cpa/channel-select-modal/channel-select-modal.module';

@NgModule({
  declarations: [SickLeaveConfirmComponent],
  imports: [
    CommonModule,
    NgxsModule.forFeature([
      SickLeaveConfirmState
    ]),
    DownloadPdfModule,
    NgxsFormPluginModule,
    LoaderModule,
    CustomiseDoctorReportModule,
    ReactiveFormsModule,
    ReportResponseModule,
    TypeaheadModule.forRoot(),
    ChannelSelectModalModule
  ],
  exports: [SickLeaveConfirmComponent]
})
export class SickLeaveConfirmModule { }
