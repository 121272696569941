import { ToggleBladeAction } from '../../../../core/blade/state/blade.actions';

export const STOCK_ADJUSTMENT_BLADE_NAME = '[Stock Adjustment]';

export enum StockAdjustmentActionTypes {
  OPEN = '[Stock Adjustment] Open',
  REQUEST = '[Stock Adjustment] Request',
  RESPONSE = '[Stock Adjustment] Response',
  ERROR = '[Stock Adjustment] Error',
  CLOSE = '[Stock Adjustment] Close'
}

export class OpenStockAdjustmentAction extends ToggleBladeAction {
  static readonly type = StockAdjustmentActionTypes.OPEN;
  constructor() { super(STOCK_ADJUSTMENT_BLADE_NAME); }
}

export class CloseStockAdjustmentAction extends ToggleBladeAction {
  static readonly type = StockAdjustmentActionTypes.CLOSE;
  constructor() { super(STOCK_ADJUSTMENT_BLADE_NAME); }
}

export class StockAdjustmentRequestAction {
  static readonly type = StockAdjustmentActionTypes.REQUEST;
  constructor(public script: any, public days: number, public isAbsolute: boolean) {}
}

export class StockAdjustmentErrorAction {
  static readonly type = StockAdjustmentActionTypes.ERROR;
  constructor(public error: any, public script: any) {}
}

export class StockAdjustmentResponseAction {
  static readonly type = StockAdjustmentActionTypes.RESPONSE;
  constructor(public script: any) {}
}
