import { Injectable } from '@angular/core';
import { State, Action, StateContext, Selector } from '@ngxs/store';
import { ToggleLoading } from './loading.actions';

export class LoadingStateModel {
  public loading: boolean;
  public loadingMessage: string;
}

@Injectable()
@State<LoadingStateModel>({
  name: 'loading',
  defaults: {
    loading: false,
    loadingMessage: ''
  }
})
export class LoadingState {

  @Selector()
  static loadingState(state: LoadingStateModel) { return state }

  @Action(ToggleLoading)
  add(ctx: StateContext<LoadingStateModel>, { loading, loadingMessage }: ToggleLoading) {
    ctx.patchState({
      loading: loading,
      loadingMessage: loadingMessage
    })
  }
}
