import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CarerType, postCodePattern } from '../../state/asl.models';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Select } from '@ngxs/store';
import { ASLState } from '../../state/asl.state';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-asl-related-person-edit-form',
  templateUrl: './asl-related-person-edit-form.component.html',
  styleUrls: ['./asl-related-person-edit-form.component.scss']
})
export class AslRelatedPersonEditFormComponent implements OnInit {
  @Input()
  isAgent: boolean;

  @Output()
  cancelEvent = new EventEmitter();

  @Output()
  saveEvent = new EventEmitter<FormGroup>();

  @Select(ASLState.isSubmitting)
  isSubmitting$: Observable<boolean>;

  relatedPersonForm: FormGroup;

  carerTypes = CarerType;
  carerType = CarerType.INDIVIDUAL;
  selectedContact = 'mobileNumber';

  constructor(private fb: FormBuilder) { }

  ngOnInit(): void {
    this.relatedPersonForm = this.fb.group({
      carerType: new FormControl(CarerType.INDIVIDUAL, [Validators.required]),
      firstName: new FormControl('', [Validators.required]),
      surname: new FormControl('', [Validators.required]),
      mobileNumber: new FormControl('', [Validators.required, Validators.pattern('04[0-9]{8}')]),
      email: new FormControl('', [Validators.email]),
      relationship: new FormControl('', [Validators.required]),
      address: new FormControl(''),
      suburb: new FormControl(''),
      state: new FormControl(''),
      postcode: new FormControl('', [Validators.pattern(postCodePattern)]),
      organisation: new FormControl(''),
      primary: new FormControl(false),
      consent: new FormControl('', [Validators.requiredTrue])
    });
  }

  updateCarer(): void {
    this.carerType = this.relatedPersonForm.get('carerType').value;
    switch (this.carerType) {
      case CarerType.INDIVIDUAL:
        this.relatedPersonForm.get('firstName').setValidators([Validators.required]);
        this.relatedPersonForm.get('surname').setValidators([Validators.required]);
        this.relatedPersonForm.get('relationship').setValidators([Validators.required]);
        this.relatedPersonForm.get('organisation').clearValidators();
        break;
      case CarerType.ORGANISATION:
        this.relatedPersonForm.get('organisation').setValidators([Validators.required]);
        this.relatedPersonForm.get('firstName').clearValidators();
        this.relatedPersonForm.get('surname').clearValidators();
        this.relatedPersonForm.get('relationship').clearValidators();
        break;
    }
    this.relatedPersonForm.get('organisation').updateValueAndValidity();
    this.relatedPersonForm.get('firstName').updateValueAndValidity();
    this.relatedPersonForm.get('surname').updateValueAndValidity();
    this.relatedPersonForm.get('relationship').updateValueAndValidity();
  }

  save(): void {
    this.saveEvent.emit(this.relatedPersonForm);
  }

  get formDisabled(): boolean {
    return this.relatedPersonForm.invalid;
  }

  updateChannel(event: any): void {
    if (event.value === 'mobileNumber') {
      this.relatedPersonForm.get('email').clearValidators();
      this.relatedPersonForm.get('email').setValue(null);
      this.relatedPersonForm.get('mobileNumber').setValidators([Validators.required]);
    } else if (event.value === 'email') {
      this.relatedPersonForm.get('mobileNumber').clearValidators();
      this.relatedPersonForm.get('mobileNumber').setValue(null);
      this.relatedPersonForm.get('email').setValidators([Validators.required, Validators.email]);
    }
    this.relatedPersonForm.get('mobileNumber').updateValueAndValidity();
    this.relatedPersonForm.get('email').updateValueAndValidity();
    this.selectedContact = event.value;
  }

  exitAddMode(): void {
    this.cancelEvent.emit();
  }
}
