import { State, Action, StateContext, Selector } from '@ngxs/store';
import {
  ToggleEScriptModal,
  DispenseEScript,
  EscriptSuccess,
  EscriptError,
  SetEScriptToken,
  SetEScriptNote,
  DispensePaperlessScript, DispensePaperlessScriptSuccess, DispensePaperlessScriptFailed
} from './escripts.actions';
import { AlertService } from '../../../../modules/core/alert/alert.service';
import { ScriptService } from '../../../../shared/services/script.service';
import { tap, catchError, finalize } from 'rxjs/operators';
import { RemoveNote } from '../../../../modules/profile/notes/state/notes.actions';
import { Injectable } from '@angular/core';

export class EscriptsStateModel {
  showModal: boolean;
  errors: any;
  dispensing: boolean;
  token: string;
  eScriptNoteId: number;
}
@Injectable()
@State<EscriptsStateModel>({
  name: 'escripts',
  defaults: {
    showModal: false,
    errors: undefined,
    dispensing: false,
    token: null,
    eScriptNoteId: null
  }
})
export class EscriptsState {
  constructor(private alertService: AlertService, private scriptService: ScriptService) { }

  @Selector()
  static dispensing(state: EscriptsStateModel) { return state.dispensing };

  @Selector()
  static showModal(state: EscriptsStateModel) { return state.showModal };

  @Selector()
  static eScriptState(state: EscriptsStateModel) { return state };

  @Selector()
  static eScriptToken(state: EscriptsStateModel) { return state.token };

  @Action(ToggleEScriptModal)
  toggleModal(ctx: StateContext<EscriptsStateModel>, { showModal }: ToggleEScriptModal) {
    ctx.patchState({ showModal: showModal });
  }

  @Action(DispenseEScript)
  DispenseEScript(ctx: StateContext<EscriptsStateModel>, { request }: DispenseEScript) {
    ctx.patchState({ dispensing: true });
    return this.scriptService.dispenseEScript(request).pipe(
      tap(res => ctx.dispatch(new EscriptSuccess(res))),
      catchError(err => ctx.dispatch(new EscriptError(err))),
      finalize(() => ctx.patchState({dispensing: false}))
    );
  }

  @Action(EscriptSuccess)
  EscriptSuccess(ctx: StateContext<EscriptsStateModel>, { response }: EscriptSuccess) {
    this.alertService.success('Script successfully pushed to queue!');

    const noteId = ctx.getState().eScriptNoteId;
    if(noteId) {
      ctx.dispatch(new RemoveNote(noteId));
    }
  }

  @Action(SetEScriptToken)
  setEScriptToken(ctx: StateContext<EscriptsStateModel>, { token }: SetEScriptToken) {
    ctx.patchState({
      token: token
    });
  }

  @Action(SetEScriptNote)
  setEScriptNote(ctx: StateContext<EscriptsStateModel>, { noteId }: SetEScriptNote) {
    ctx.patchState({
      eScriptNoteId: noteId
    });
  }

  @Action(EscriptError)
  EscriptError(ctx: StateContext<EscriptsStateModel>, { error }: EscriptError) {
    ctx.patchState({ errors: error.error });
    this.alertService.error(JSON.stringify(error.error));
  }

  @Action(DispensePaperlessScript)
  DispensePaperlessScript(ctx: StateContext<EscriptsStateModel>, { script, noteId }: DispensePaperlessScript) {
    ctx.patchState({ dispensing: true });
    return this.scriptService.dispensePaperlessScript(script).pipe(
      tap(res => ctx.dispatch(new DispensePaperlessScriptSuccess(res, noteId))),
      catchError(err => ctx.dispatch(new DispensePaperlessScriptFailed(err))),
      finalize(() => ctx.patchState({dispensing: false}))
    );
  }

  @Action(DispensePaperlessScriptSuccess)
  DispensePaperlessScriptSuccess(ctx: StateContext<EscriptsStateModel>, { response, noteId }: DispensePaperlessScriptSuccess): void {
    if(noteId) {
      ctx.dispatch(new RemoveNote(noteId));
    }
    this.alertService.success('Script successfully pushed to queue!');
  }

  @Action(DispensePaperlessScriptFailed)
  DispensePaperlessScriptFailed(ctx: StateContext<EscriptsStateModel>, { error }: DispensePaperlessScriptFailed): void {
    ctx.patchState({ errors: error.error });
    this.alertService.error(JSON.stringify(error.error));
  }
}
