<section class="messenger">
  <ng-container>
    <div class="messenger-history" g-xs="px3 pt3 pb4" >
      <div>
        <app-history [messageHistory]="messages$ | async"></app-history>
      </div>
      <ng-container *ngIf="(hasMobileApp$ | async) as hasMobileApp; else noMobile">
        <div *ngIf="hasMobileApp">
          <app-send-message [clientId]="client.id" [collection]="firestoreCollection"></app-send-message>
        </div>
      </ng-container>
    </div>
  </ng-container>
</section>
<ng-template #noMobile>
  <div class="alert alert-warning mx-5">
    This customer does not have the Scrypt Mobile App, To invite them use the 'Invite to Scrypt' button in the top
    right of their profile.
  </div>
</ng-template>

<ng-template #loading>
  <div g-xs="p4 text:center" class="flex">
    <app-loader class="loading"></app-loader>
  </div>
</ng-template>
