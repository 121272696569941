import { CommunicationType } from '../message.model';

export interface MessageCycleModel {
  id?: number;
  text: string;
  communicationType: CommunicationType.MessageCycleSMS;
  intervalValue: number;
  intervalType: IntervalType;
  startDate: Date | string;
  nextScheduledDate?: Date | string;
  notes: string;
  clientId: string;
}

export enum IntervalType{
  none,
  day,
  week,
  month
}
