import { Component, OnInit, OnDestroy } from '@angular/core';
import { HMRGPDetailsReferralFormBaseComponent } from '../hmr-gp-details-referral-form.base.component';
import { Subscription } from 'rxjs';
import { FormBuilder } from '@angular/forms';
import { Store, Actions } from '@ngxs/store';
import { HMRGPDetailsReferralFormBasicLightState } from '../state/hmr-gp-details-referral-form-basic-light.state';
import { SixCpaState } from '../../../state/six-cpa.state';


@Component({
  selector: 'app-hmr-gp-details-referral-form-basic-light',
  templateUrl: './hmr-gp-details-referral-form-basic-light.component.html',
  styleUrls: ['./../hmr-gp-details-referral-form.component.scss'],
})
export class HMRGPDetailsReferralFormBasicLightComponent extends HMRGPDetailsReferralFormBaseComponent implements OnInit, OnDestroy {
  private formSubscription: Subscription;
  private readonlySubscription: Subscription;
  constructor(formBuilder: FormBuilder, store: Store, actions: Actions) {
    super(formBuilder, store, actions);
  }

  ngOnInit() {
    this.formSubscription = this.store.select(HMRGPDetailsReferralFormBasicLightState.form)
      .subscribe(() => this.onFormChange());
    this.readonlySubscription = this.store.select(SixCpaState.formsReadOnly)
      .subscribe(readonly => readonly ? this.gpDetailsReferralForm.disable() : this.gpDetailsReferralForm.enable());
  }

  ngOnDestroy() {
    this.formSubscription.unsubscribe();
    this.readonlySubscription.unsubscribe();
  }
}
