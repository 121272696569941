<div class="accordian">
  <div class="row align-items-center"
    [ngClass]="{'top-panel-active': showForm == 'in', 'top-panel': showForm == 'out' }"
    (click)="toggle()">
    <div class="px-3 py-2 col-9 ">
      <div class="title">{{formTitle}}</div> 
      <div *ngIf="formDescription && showForm==='in'">{{formDescription}}</div>
    </div>
    <div [ngClass]="getStatusCls()" class="progress px-3 col-3">
      {{itemsCompleted}} <span *ngIf="totalItems">/{{totalItems}}</span><span class="ml-1 mr-3">{{statusTitle}}</span> 
      <i class="fas" [ngClass]="{ 'fa-caret-up': showForm == 'in', 'fa-caret-down': showForm == 'out' }"></i>
    </div>
  </div>
  <div [@accordionAnimation]="showForm" class="form">
    <ng-content></ng-content>
  </div>
</div>