import { State, Action, StateContext } from '@ngxs/store';
import { OpenDosageHistoryAction,
  CloseDosageHistoryAction,
  InitDosageHistoryAction,
  RemoveDosageHistoryRecordRequestAction,
  RemoveDosageHistoryRecordResponseAction,
  RemoveDosageHistoryRecordErrorAction } from './dosage-history.actions';
import { ScriptLoadingStopAction, UpdateScriptAction, ScriptLoadingStartAction } from '../../state/categories.actions';
import { ScriptService } from '../../../../../shared/services/script.service';
import { mergeMap, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';

export enum DosageHistoryType {
  ADJUSTMENT,
  DOSAGE
}

export class DosageHistoryStateModel {
  public show: boolean;
  public loading: boolean;
  public script: any;
  public items: any[];
}
@Injectable()
@State<DosageHistoryStateModel>({
  name: 'dosageHistory',
  defaults: {
    show: false,
    loading: false,
    script: null,
    items: []
  }
})
export class DosageHistoryState {

  constructor(private scriptService: ScriptService) {}

  @Action(OpenDosageHistoryAction)
  open(ctx: StateContext<DosageHistoryStateModel>) {
    ctx.patchState({ show: true });
  }

  @Action(InitDosageHistoryAction)
  init(ctx: StateContext<DosageHistoryStateModel>, action: InitDosageHistoryAction) {
    ctx.patchState({
      script: action.script,
      items: (action.script.cMeta.dosageHistory.concat(action.script.cMeta.adjustments) || [])
        .map((item) => ({
          ...item,
          type: item.metaId ? DosageHistoryType.DOSAGE : DosageHistoryType.ADJUSTMENT,
          loading: false
        }))
        .sort((a, b) => this.compareHistory(a, b))
    });
  }

  @Action(RemoveDosageHistoryRecordRequestAction)
  removeDosageRecord(ctx: StateContext<DosageHistoryStateModel>, action: RemoveDosageHistoryRecordRequestAction) {
    ctx.dispatch(new ScriptLoadingStartAction(action.script));
    return this.scriptService.deleteDosage(action.recordId).pipe(
      mergeMap((response) => ctx.dispatch(new RemoveDosageHistoryRecordResponseAction(action.recordId, response))),
      catchError((error) => ctx.dispatch(new RemoveDosageHistoryRecordErrorAction(error, action.recordId, action.script)))
    );
  }

  @Action(RemoveDosageHistoryRecordErrorAction)
  error(ctx: StateContext<DosageHistoryStateModel>, action: RemoveDosageHistoryRecordErrorAction) {
    ctx.dispatch(new ScriptLoadingStopAction(action.script));
  }

  @Action([RemoveDosageHistoryRecordResponseAction])
  handleResponse(ctx: StateContext<DosageHistoryStateModel>, action: RemoveDosageHistoryRecordResponseAction) {
    return ctx.dispatch(new UpdateScriptAction(action.script));
  }

  @Action([RemoveDosageHistoryRecordRequestAction, RemoveDosageHistoryRecordResponseAction, RemoveDosageHistoryRecordErrorAction])
  setItemLoading(
    ctx: StateContext<DosageHistoryStateModel>,
    action: RemoveDosageHistoryRecordRequestAction | RemoveDosageHistoryRecordResponseAction | RemoveDosageHistoryRecordErrorAction) {
    const state = ctx.getState();
    ctx.patchState({
      script: action.script,
      items: state.items.map(item => item.id === action.recordId
        ? {...item, loading: action instanceof RemoveDosageHistoryRecordRequestAction }
        : item)
    });
  }

  @Action(CloseDosageHistoryAction)
  close(ctx: StateContext<DosageHistoryStateModel>) {
    ctx.patchState({ show: false });
  }

  private compareHistory(a , b): number {
    if (new Date(a.createdOn) > new Date(b.createdOn)) { return -1; }
    if (new Date(b.createdOn) > new Date(a.createdOn)) { return 1; }
    return 0;
  }
}
