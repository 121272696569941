<section *ngIf="!isLoaded">
  <app-loader *ngIf="!notification.text"></app-loader>
  <app-notification [notification]="notification"></app-notification>
</section>
<section>
  <div>
    <h3>Filter by</h3>
  </div>
  <div class="form-group">
    <div class="row">
      <div class="col-md-4">
        <label>Tenant Name:</label>
        <input class="form-control" [(ngModel)]="tenantFilter" (change)="tenantFilterChanged()" type="text">
      </div>
      <div class="col-md-4">
        <label>User Email:</label>
        <input class="form-control" [(ngModel)]="userFilter" (change)="userFilterChanged()" type="text">
      </div>
    </div>
  </div>
</section>
<hr />
<section *ngIf="isLoaded">
  <div style="text-decoration: underline; margin-bottom: 5px; cursor: pointer;">
    <span *ngIf="!showTenantSection" (click)="toggleTenantSection()">Show Tenants  <i class="fas fa-caret-down"></i></span>
    <span *ngIf="showTenantSection" (click)="toggleTenantSection()">Hide Tenants  <i class="fas fa-caret-up"></i></span>
  </div>
  <app-tenant *ngIf="showTenantSection" [tenantList]="tenantList" [connectionTypeList]="connectionTypeList"
    (tenantListChangedEvent)="updateTenantList($event)">
  </app-tenant>
  <div>
    <h3>Tenant User List</h3>
    <div style="text-decoration: underline; margin-top: 5px; cursor: pointer;">
      <span *ngIf="!showUserSection" (click)="toggleUserSection()">Show Users  <i class="fas fa-caret-down"></i></span>
      <span *ngIf="showUserSection" (click)="toggleUserSection()">Hide Users  <i class="fas fa-caret-up"></i></span>
    </div>
    <div g-xs="text:right my3">
      <button (click)="openUpdateForm()" class="btn btn__green">Create Item</button>
    </div>
  </div>
  <div *ngIf="isUpdateFormVisible">
    <app-tenant-user-item (saveEvent)="updateList($event)" (cancelEvent)="onUpdateFormCancel()"
      [currentItem]="currentItem" [userList]="userList" [tenantList]="tenantList"></app-tenant-user-item>
  </div>
  <table class="table" *ngIf="showUserSection">
    <thead>
      <tr>
        <th scope="col">Tenant</th>
        <th scope="col">User</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of tenantUserList">
        <td>{{item.tenantName}}</td>
        <td>{{item.email}}</td>
        <td>
          <div class="btn-group">
            <button (click)="openUpdateForm(item)" class="btn btn-primary">Edit</button>
            <button (click)="remove(item.id)" class="btn btn-danger">Remove</button>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</section>
