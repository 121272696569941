import { State, Action, StateContext, Selector, Store } from '@ngxs/store';
import {
  GetProfileAction,
  SetProfileAction,
  ProfileErrorAction,
  ProfilePopupVisibleAction,
  ProfileZIndexCounterAction,
  ProfileAlertVisibleAction,
  SetClientIdAction,
  ProfileUpdateMedsCheckEligibilityAction,
  SetMedsListsForSixCpa,
  GetCustomerMobileAppStatus,
  GetCustomerMobileAppStatusSuccess,
  OnboardLiteCustomer,
  OnboardLiteCustomerSuccess,
  SetLastVisitDate,
  GetOptOutLookup,
} from './profile.actions';
import { ClientRepository } from '../client.repository';
import { mergeMap, catchError, filter, tap } from 'rxjs/operators';
import { Notification } from '../../../../shared/models/notification.model';
import { ClientViewModel } from '../client.model';
import { SetProfileForSixCpaAction, SwitchProfileSixCpaAction } from '../../../../modules/profile/six-cpa/state/six-cpa.actions';
import { SixCpaFormTypes } from '../../../../modules/profile/six-cpa/six-cpa-form-types.enum';
import { SetMedicationProfileScriptsAction } from '../../../../modules/profile/six-cpa/medication-profile/state/medication-profile.actions';
import { SetMedsCheckMedicationProfileScriptsAction } from '../../../../modules/profile/six-cpa/meds-check-medication-profile/state/meds-check-medication-profile.actions';
import { SetDAAMedicationProfileScriptsAction } from '../../../../modules/profile/six-cpa/DAA/daa-medication-profile/state/daa-medication-profile.actions';
import { SetMedListMedicationProfileScripts } from '../../../../modules/profile/six-cpa/medication-list/med-list-medication-profile/state/med-list-medication-profile.actions';
import { SetStagedSupplyMedicationProfileScriptsAction } from '../../../../modules/profile/six-cpa/Staged-Supply/staged-supply-medication-profile/state/staged-supply-medication-profile.actions';
import { SetInviteToSignUpForm, CustomRequestInviteToSignUp } from '../../../../modules/profile/invite-to-sign-up/state/invite-to-sign-up.actions';
import { Inject, Injectable } from '@angular/core';
import { LiteCustomerService } from '../../../../services/lite-customer.service';
import { UserService } from '../../../../services/user.service';
import { ToggleLoading } from '../../loader/state/loading.actions';

export class ProfileStateModel {
  clientId: string;
  profile: ClientViewModel;
  popupVisible = false;
  notification: Notification;
  tenantCustomerId: number;
  categoryOptions: any[];
  zIndexCounter: number;
  alertVisible = false;
  hasMobileApp: boolean;
  lastVisitDate: Date | string | null;
  isOptOut: boolean;
}

@Injectable()
@State<ProfileStateModel>({
  name: 'profile',
  defaults: {
    clientId: '',
    profile: null,
    popupVisible: false,
    notification: new Notification('danger', ''),
    tenantCustomerId: null,
    categoryOptions: [],
    zIndexCounter: 0,
    alertVisible: false,
    hasMobileApp: false,
    lastVisitDate: null,
    isOptOut: null
  }
})
export class ProfileState {
  isLite = false;

  constructor(
    private clientRepository: ClientRepository,
    private userService: UserService,
    private liteCustomerService: LiteCustomerService,
    @Inject('environment') env: any) {
    this.isLite = env.isLite;
  }

  @Selector()
  static optOutlookup(state: ProfileStateModel) { return state.isOptOut; }

  @Selector()
  static emailAddress(state: ProfileStateModel) { return state.profile.patient.emailAddress; }

  @Selector()
  static client(state: ProfileStateModel) { return state.profile; }

  @Selector()
  static profile(state: ProfileStateModel) { return state.profile; }

  @Selector()
  static notification(state: ProfileStateModel) { return state.notification; }

  @Selector()
  static popupVisible(state: ProfileStateModel) { return state.popupVisible; }

  @Selector()
  static tenantCustomerId(state: ProfileStateModel) { return state.tenantCustomerId; }

  @Selector()
  static categoryOptions(state: ProfileStateModel) { return state.categoryOptions; }

  @Selector()
  static clientId(state: ProfileStateModel) { return state.clientId; }

  @Selector()
  static zIndexCounter(state: ProfileStateModel) { return state.zIndexCounter; }

  @Selector()
  static customerProfileId(state: ProfileStateModel) { return state.profile.customerProfile.customerProfileId; }

  @Selector()
  static hasMobileApp(state: ProfileStateModel) { return state.hasMobileApp; }

  @Selector()
  static lastVisitDate(state: ProfileStateModel) { return state.lastVisitDate; }

  static clientId$(store: Store) {
    return store.select(ProfileState.clientId).pipe(filter(clientId => !!clientId));
  }

  @Selector()
  static alertVisible(state: ProfileStateModel) { return state.alertVisible; }

  @Action(SetClientIdAction)
  setClientId(ctx: StateContext<ProfileStateModel>, action: SetClientIdAction) {
    ctx.patchState({
      clientId: action.clientId
    });
  }

  @Action(GetProfileAction)
  get(ctx: StateContext<ProfileStateModel>, action: GetProfileAction) {
    ctx.patchState({ profile: null });

    return this.clientRepository.getProfile(action.profileId).pipe(
      mergeMap((profile) => ctx.dispatch(new SetProfileAction(
        profile, profile.customerProfile ? profile.customerProfile.tenantCustomerId : null, profile.categoryOptions, action.inviteToMobile))),
      catchError((error) => ctx.dispatch(new ProfileErrorAction(error)))
    );
  }

  @Action(SetProfileAction)
  set(ctx: StateContext<ProfileStateModel>, action: SetProfileAction) {
    const state = ctx.getState();
    if (action.tenantCustomerId) { // dont try to fetch anything if the customer isn't onboarded yet
      ctx.dispatch(new SetProfileForSixCpaAction(action.profile));
    }
    if (state.clientId !== '' && state.clientId !== action.profile.clientId) {
      ctx.dispatch(new SwitchProfileSixCpaAction());
    }
    if (action.profile.patient !== null) {
      ctx.dispatch(new SetInviteToSignUpForm(action.profile));
    }
    ctx.patchState({
      profile: action.profile,
      clientId: action.profile.clientId,
      tenantCustomerId: action.tenantCustomerId,
      categoryOptions: action.categoryOptions,
      popupVisible: false
    });
    if (action.inviteToMobile) {
      ctx.dispatch(new CustomRequestInviteToSignUp(action.inviteToMobile.inviteType, action.inviteToMobile.email, action.inviteToMobile.mobile));
    }

    // If Lite perform automatic onboarding as Lead
    ctx.dispatch(new ToggleLoading(false, ""));
    if (action.profile.customerProfile.type === null) {
      const profile = action.profile;
      ctx.dispatch(new OnboardLiteCustomer(profile.clientId, profile.patient.mobileNumber, profile.patient.emailAddress, `${profile.patient.firstname} ${profile.patient.surname}`));
    }
  }

  @Action(ProfileErrorAction)
  error(ctx: StateContext<ProfileStateModel>, action: ProfileErrorAction) {
    const state = ctx.getState();
    ctx.patchState({ notification: { ...state.notification, text: action.error } });
  }

  @Action(ProfilePopupVisibleAction)
  popupVisible(ctx: StateContext<ProfileStateModel>, action: ProfilePopupVisibleAction) {
    ctx.patchState({ popupVisible: action.visible });
  }

  @Action(ProfileZIndexCounterAction)
  updateZIndexCounter(ctx: StateContext<ProfileStateModel>, action: ProfileZIndexCounterAction) {
    ctx.patchState({ zIndexCounter: action.zIndexCounter });
  }

  @Action(ProfileAlertVisibleAction)
  alertVisible(ctx: StateContext<ProfileStateModel>, action: ProfileAlertVisibleAction) {
    ctx.patchState({ alertVisible: action.visible });
  }

  @Action(ProfileUpdateMedsCheckEligibilityAction)
  updateMedsCheckEligibility(ctx: StateContext<ProfileStateModel>, action: ProfileUpdateMedsCheckEligibilityAction) {
    const state = ctx.getState();
    ctx.patchState({
      profile: {
        ...state.profile,
        sixCpaEligibility: state.profile.sixCpaEligibility.map(item => item.key === SixCpaFormTypes.MedsCheck ? { ...item, value: false } : item)
      }
    });
  }

  @Action(SetMedsListsForSixCpa)
  setMedsListsForSixCpa(ctx: StateContext<ProfileStateModel>, { categorisedScripts }: SetMedsListsForSixCpa) {
    ctx.dispatch([
      new SetMedicationProfileScriptsAction(categorisedScripts),
      new SetMedsCheckMedicationProfileScriptsAction(categorisedScripts),
      new SetDAAMedicationProfileScriptsAction(categorisedScripts),
      new SetMedListMedicationProfileScripts(categorisedScripts),
      new SetStagedSupplyMedicationProfileScriptsAction(categorisedScripts)
    ]);
  }

  @Action(GetCustomerMobileAppStatus)
  getCustomerMobileAppStatus(ctx: StateContext<ProfileStateModel>, action: GetCustomerMobileAppStatus) {
    const customerProfileId = ctx.getState().profile.customerProfile.customerProfileId;
    return this.userService.getUserMobileAppStatus(customerProfileId).pipe(
      mergeMap((resp) => ctx.dispatch(new GetCustomerMobileAppStatusSuccess(resp))),
      catchError((error) => ctx.dispatch(new ProfileErrorAction(error))),
    );
  }

  @Action(GetCustomerMobileAppStatusSuccess)
  getCustomerMobileAppStatusSuccess(ctx: StateContext<ProfileStateModel>, { customerHasApp }: GetCustomerMobileAppStatusSuccess) {
    ctx.patchState({
      hasMobileApp: customerHasApp
    });
  }

  @Action(OnboardLiteCustomer)
  onboardLiteCustomer(
    ctx: StateContext<ProfileStateModel>,
    { id, mobileNumber, email, name }: OnboardLiteCustomer
  ) {
    ctx.dispatch(new ToggleLoading(true, `Setting up ${name} for the first time`))
    return this.liteCustomerService.onboardCustomer(id, mobileNumber, email).pipe(
      tap(() => ctx.dispatch(new OnboardLiteCustomerSuccess(id))),
      catchError(error => ctx.dispatch(new ProfileErrorAction(error)))
    );
  }

  @Action(OnboardLiteCustomerSuccess)
  onboardLiteCustomerSuccess(
    ctx: StateContext<ProfileStateModel>,
    { id }: OnboardLiteCustomerSuccess
  ): void {
    ctx.dispatch(new GetProfileAction(id));
  }

  @Action(SetLastVisitDate)
  setLastVisitDate(ctx: StateContext<ProfileStateModel>, { lastVisitDate }: SetLastVisitDate) {
    ctx.patchState({
      lastVisitDate: lastVisitDate
    });
  }

  @Action(GetOptOutLookup)
  getOptOutLookup(ctx: StateContext<ProfileStateModel>, { mobileNumber }: GetOptOutLookup) {
    return this.clientRepository.optOutLookup(mobileNumber).pipe(
      tap((resp) => {
        ctx.patchState({
          isOptOut: resp
        });
      }),
      catchError(error => ctx.dispatch(new ProfileErrorAction(error)))
    )
  }
}
