import { Component, Input, ChangeDetectionStrategy, OnDestroy, SimpleChanges, OnChanges } from "@angular/core";
import { OrderRequest } from "@profile/order-requests/models/order-request.model";
import { OrderRequestItemType, OrderMethod, BrandPreference, OrderRequestStatus } from "@profile/order-requests/models/order.enums";
import { CompletedOrdersColumns, IncomingOrdersColumns } from "./column-definitions";
import { Actions, Select, Store, ofActionDispatched } from "@ngxs/store";
import {
  CompleteOrderRequest,
  GetOrderRequestItems,
  PushOrderToQueue,
  UpdateCurrentPage,
  UpdateOrderRequest,
  ResetOrderStatus
} from "../../state/order-mgmt.actions";
import { takeUntil } from "rxjs/operators";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { ActionLoadingMap, TabName } from "../../order-mgmt.types";
import { DefaultPageSize, OrderStatusColourMap, OrderStatusLabelMap, TabNames } from "../../order-mgmt.constants";
import { OrderManagementState } from "../../state/order-mgmt.state";

@Component({
    selector: 'app-mgmt-order-request-table',
    templateUrl: './order-request-table.component.html',
    styleUrls: ['./order-request-table.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrderRequestTableComponent implements OnDestroy, OnChanges {
    @Input() orderRequests: OrderRequest[];
    @Input() selectedTab: TabName;
    @Input() totalOrders = 0;
    @Input() isLoading: boolean;
    @Input() loadingActions: ActionLoadingMap;
    @Select(OrderManagementState.isIncomingOrPartiallyReadyTab) isIncomingOrPartiallyReadyTab$: Observable<boolean>;

    pageIndex$ = new BehaviorSubject(1);
    ngOnDestroy$ = new Subject();
    expandedRows = new Set<number>();
    loadedExpandedRows = new Set<number>();

    fullTableColumnns = IncomingOrdersColumns;
    completedOrdersColumns = CompletedOrdersColumns;
    orderItemTypeEnum = OrderRequestItemType;
    orderMethodEnum = OrderMethod;
    dateFormat = "DD/MM/YYYY hh:mm a" as const;
    tabNames = TabNames;
    pageSize = DefaultPageSize;
    orderStatusLabelMap = OrderStatusLabelMap;
    orderStatusColourMap = OrderStatusColourMap;
    ordersOriginalStatuses: { orderId: number, status: OrderRequestStatus }[];

    constructor(private store: Store,private actions$: Actions) {
        this.actions$.pipe(
            ofActionDispatched(GetOrderRequestItems),
            takeUntil(this.ngOnDestroy$)
        ).subscribe(({ orderRequestId}: GetOrderRequestItems) => {
            this.loadedExpandedRows.add(orderRequestId);
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.orderRequests && this.orderRequests?.length) {
            this.ordersOriginalStatuses = this.orderRequests.map(order => {
                return {
                    orderId: order.id,
                    status: order.status
                };
            });
        }
    }

    ngOnDestroy() {
        this.clearExpandedRows();

        this.ngOnDestroy$.next();
        this.ngOnDestroy$.complete();
    }

    public clearExpandedRows() {
        this.loadedExpandedRows.clear();
        this.expandedRows.clear();
    }

    trackByOrderId(orderRequest: OrderRequest) {
        return orderRequest.id;
    }

    onPageIndexChange(pageIndex: number) {
        this.pageIndex$.next(pageIndex);
        // --index because it will go through to the backend where we use 0 based indexing pagination
        this.store.dispatch(new UpdateCurrentPage(Math.max(0, --pageIndex)));
    }

    onExpandChange(id: number, checked: boolean) {
        if (!this.loadedExpandedRows.has(id)) {
            this.store.dispatch(new GetOrderRequestItems(id));
        }

        // Reset status on expanded order to force clear selection
        const originalStatus = this.getOrigingalStatus(id);
        this.store.dispatch(new ResetOrderStatus(id, originalStatus));

        return checked ? this.expandedRows.add(id) : this.expandedRows.delete(id);
    }

    orderBrandPreference(order: OrderRequest) {
        switch(order.brandPreference) {
            case BrandPreference.AllowSubstitutes: return 'far fa-check success';
            case BrandPreference.DontAllowSubstitutes: return 'far fa-times danger';
            default: return 'far fa-minus'
        }
    }

    handleStatusUpdate({ order, notifyCustomer }) {
        this.store.dispatch(new UpdateOrderRequest(order, notifyCustomer));

        // Close inner table if open when an order is marked Partially Ready
        if (order.status === OrderRequestStatus.PartiallyReady && this.expandedRows.has(order.id))
            this.expandedRows.delete(order.id)
    }

    handleSendOrderToQueue(order: OrderRequest) {
        if (order)
            this.store.dispatch(new PushOrderToQueue(order));
    }

    completeOrder({ order, notifyCustomer }) {
        if (order)
            this.store.dispatch(new CompleteOrderRequest(order, notifyCustomer))
    }

    getOrigingalStatus(orderId: number) {
        const orderStatus = this.ordersOriginalStatuses.find(o => o.orderId === orderId);

        if (orderStatus)
            return orderStatus.status;
    }
}
