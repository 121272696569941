import { NoteFilter } from '~/shared/models/crm/noteFilter.model';
import { NoteType } from '~/shared/models/crm/noteType.model';
import { NoteTypeEnum } from '~/shared/models/note/note-type.enum';
import { GroupType } from '~/shared/models/crm/groupType.model';
import * as moment from 'moment';

export const filtersLocalStorage = 'filters';

export function initFilter(existing: NoteFilter) {
  const filters = new NoteFilter();
  filters.pageSize = 20;
  filters.toDate = new Date();
  filters.pageIndex = 1;

  if (existing) {
    filters.noteType = existing.noteType;
    filters.groupId = existing.groupId;
    filters.query = existing.query;
    filters.toDate = existing.toDate
      ? new Date(moment(existing.toDate).valueOf())
      : new Date();
    filters.fromDate = existing.fromDate
      ? new Date(moment(existing.fromDate).valueOf())
      : null;
  }
  return filters;
}


export function getSelectedFilterValue<TFilter, TValue>(filter: NoteFilter) {
  return (getSubject: (filter: NoteFilter) => TValue) =>
    (isTruthy: (value: TValue) => boolean) =>
      (getValue: (value: TFilter) => TValue) =>
        (arr: TFilter[]) => (
          (filter && isTruthy(getSubject(filter)) && arr.find(value => getValue(value) === getSubject(filter)))
          || getDefault(arr)
        );
}

export function isNumber(value?: number) {
  return value === 0 || !!value;
}

export function getDefault<T>(arr: T[]) {
  return arr[0];
}

export function filterToUIGroupTypes(arr: GroupType[]) {
  return arr.filter(group => isNumber(group.id));
}

export function filterToUINoteTypes(arr: NoteType[]) {
  return arr.filter(value => {
    switch (value.value) {
      case NoteTypeEnum.general:
      //case NoteTypeEnum.dispense:
      //case NoteTypeEnum.sms:
      case NoteTypeEnum.order:
      //case NoteTypeEnum.awaiting:
      //case NoteTypeEnum.request:
      //case NoteTypeEnum.email:
      case NoteTypeEnum.phoneCall:
      case NoteTypeEnum.newDrug:
      case NoteTypeEnum.emailReply:
      case NoteTypeEnum.smsReply:
      case NoteTypeEnum.mail:
      case NoteTypeEnum.customerOrder:
      case NoteTypeEnum.sixCpa:
      case NoteTypeEnum.delivery:
      case NoteTypeEnum.specialOrder:
      case NoteTypeEnum.highCostItem:
      //case NoteTypeEnum.scriptsReady:
      case NoteTypeEnum.scheduledSMS:
      case NoteTypeEnum.eScript:
      case NoteTypeEnum.ScryptMessage:
      case NoteTypeEnum.VaxScreening:
      case NoteTypeEnum.NewCustomer:
        return true;
      default: return false;
    }
  });

}
