<form [formGroup]="proposedDetailsForm" ngxsForm="hmrProposedDetailsFormDetailed.proposedDetailsForm" class="p-4">
    <div class="form-group pb-1">
        <label for="dateOfGPReferral" class="input-label">Date of GP Referral</label>
        <input type="text" [class.has-error]="formControls.dateOfGPReferral.invalid && (showInvalid$ | async)"
            class="form-control form-control-lg w-25"
            value="proposedDetailsForm.value.dateOfGPReferral | date: 'DD-MM-YYYY'" formControlName="dateOfGPReferral"
            id="dateOfGPReferral" [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY' }" placeholder="dd-mm-yyyy" bsDatepicker>
    </div>
    <div class="form-group pb-1">
        <label for="proposedDateOfInterview" class="input-label">Proposed date of Interview</label>
        <input type="text" [class.has-error]="formControls.proposedDateOfInterview.invalid && (showInvalid$ | async)"
            class="form-control form-control-lg w-25"
            value="proposedDetailsForm.value.proposedDateOfInterview | date: 'DD-MM-YYYY'"
            formControlName="proposedDateOfInterview" id="proposedDateOfInterview"
            [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY' }" placeholder="dd-mm-yyyy" bsDatepicker>
    </div>
    <div class="form-group pb-1">
        <label for="proposedLocation" class="input-label">Proposed location
        </label>
        <input type="text" [class.has-error]="formControls.proposedLocation.invalid && (showInvalid$ | async)"
            class="form-control form-control-lg w-25" name="proposedLocation" formControlName="proposedLocation"
            id="proposedLocation" required>
    </div>
    <div class="form-group pb-1">
        <label for="suburb" class="input-label">Suburb
        </label>
        <input type="text" [class.has-error]="formControls.suburb.invalid && (showInvalid$ | async)"
            class="form-control form-control-lg w-25" name="suburb" formControlName="suburb" id="suburb" required>
    </div>
    <div class="form-group pb-1">
        <label for="postcode" class="input-label">Postcode
        </label>
        <input type="text" [class.has-error]="formControls.postcode.invalid && (showInvalid$ | async)"
            class="form-control form-control-lg w-25" name="proposedLocation" formControlName="postcode" id="postcode"
            required>
    </div>
</form>