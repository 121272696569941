import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Router } from '@angular/router';
import { StorageService } from '../core/storage/storage.service';
import { CrudService } from './crud.service';
import { environment } from '../../../environments/environment.dev';
import { User } from '../models/user.model';
import { AlertService } from '~/modules/core/alert/alert.service';

const API = environment.baseApiUrl;

@Injectable()
export class UserService extends CrudService {
  constructor(public http: HttpClient, public router: Router, public storageService: StorageService, public alertService: AlertService) {
    super(http, router, storageService, alertService, 'user');
  }

  getLoggedInUser() {
    return this.get(`${API}/api/user/loggedInUser`);
  }

  getUserList(): Observable<User[]> {
    return this.getList();
  }

  addUser(item: User): Observable<User> {
    return this.add(item);
  }

  ressetPassword(item: User): Observable<User> {
    return this.put(`${API}/api/user/ressetPassword`);
  }

  removeUser(id: any) {
    return this.remove(id);
  }

  getUserMobileAppStatus(customerProfileId: number): Observable<any> {
    return this.get(`${API}/api/user/${customerProfileId}/mobileAppStatus`);
  }
}
