<form [formGroup]="patientDetailsForm" ngxsForm="hmrPatientDetailsFormDetailed.patientDetailsForm" class="p-4">
    <div class="form-group pb-1">
        <label for="uniqueIdentifier" class="input-label">Unique Patient Identifier (Patient name, Medicare or DVA
            number should not be used)
        </label>
        <input type="text" [class.has-error]="formControls.uniqueIdentifier.invalid && (showInvalid$ | async)"
            class="form-control form-control-lg w-25" formControlName="uniqueIdentifier" id="uniqueIdentifier" required>
    </div>
    <div class="form-group pb-1">
        <label for="homeSuburb" class="input-label">Patient Home Suburb
        </label>
        <input type="text" [class.has-error]="formControls.homeSuburb.invalid && (showInvalid$ | async)"
            class="form-control form-control-lg w-50" formControlName="homeSuburb" id="homeSuburb" required>
    </div>
    <div class="form-group pb-1">
        <label for="residentialPostCode" class="input-label">Patient Home Postcode
        </label>
        <input type="text" [class.has-error]="formControls.residentialPostCode.invalid && (showInvalid$ | async)"
            class="form-control form-control-lg w-25" formControlName="residentialPostCode" id="residentialPostCode"
            placeholder="2000" required>
    </div>
</form>