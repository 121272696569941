import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VaccinationRoutingModule } from './vaccination-routing.module';
import { VaccinationRouteComponent } from './components/vaccination-route/vaccination-route.component';
import { VaccinationErrorComponent } from './components/vaccination-error/vaccination-error.component';
import { VaccinationResolver } from './resolvers/vaccination.resolver';
import { NgxsModule } from '@ngxs/store';
import { VaccinationState } from './state/vaccination.state';
import { VaccinationService } from './services/vaccination.service';
import { LoaderModule } from '../core/loader/loader.module';
import { VaccinationFormGuard } from './guards/vaccination-form.guard';
import { DirectivesModule } from '~/shared/directives/directives.module';

@NgModule({
  declarations: [VaccinationRouteComponent, VaccinationErrorComponent],
  imports: [
    CommonModule,
    VaccinationRoutingModule,
    NgxsModule.forFeature([VaccinationState]),
    LoaderModule,
    DirectivesModule,
  ],
  providers: [VaccinationResolver, VaccinationService, VaccinationFormGuard],
})
export class VaccinationRouteModule {}
