import { Component, OnInit, Input } from '@angular/core';
import { slide, slideState } from '../../../../shared/core/animations';
import { NoteType } from '../../../../shared/models/crm/noteType.model';
import { NewNote } from '../../../../shared/models/crm/newNote.model';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { Subscription } from 'rxjs/Subscription';
import * as moment_ from 'moment';
const moment = moment_;
import { Select, Store } from '@ngxs/store';
import { NotesState } from '../state/notes.state';
import { Observable } from 'rxjs';
import { CreateNewNote } from '../state/notes.actions';
import { NoteTypeEnum } from '../../../../shared/models/note/note-type.enum';
import { ProfileState } from '../../../../modules/core/profile/state/profile.state';
import { CharacterCountService } from '../../../../shared/helpers/character-count.service';
import { SmsService } from '../../../../shared/services/sms.service';
import { MarketingSetting } from '../../../../shared/models/marketingSetting.model';
import { MessageType } from '../../../../shared/models/messageType.model';

@Component({
  selector: 'app-create-note',
  templateUrl: './create-note.component.html',
  styleUrls: ['./create-note.component.scss'],
  animations: [
    slide,
    slideState
  ]
})
export class CreateNoteComponent implements OnInit {
  @Input() clientName: string;
  @Input() clientId: string;
  @Select(NotesState.newNoteTypeList) newNoteTypeList$: Observable<NoteType[]>;
  @Select(ProfileState.profile) profile$: Observable<any>;

  constructor(
    private formBuilder: FormBuilder,
    private store: Store,
    private characterCountService: CharacterCountService,
    private smsService: SmsService
  ) { }

  noteTypeList: NoteType[] = []
  crmSubscription: Subscription;
  templateSubscription: Subscription;
  showBlade = false;
  btnStatus = 'out';
  newNote: NewNote;
  date: Date;
  repeatTypes: any[] = [];
  selectedType: NoteType;
  noteForm: FormGroup;
  messageTypes: MessageType[] = [];

  ngOnInit() {
    this.initNoteTypesListSubscription();
    this.fetchTemplates();
    this.setRepeatTypes();
    this.setFormControls();
    this.initFormState();
  }

  private fetchTemplates() {
    this.templateSubscription = this.smsService.getMessageTypes(this.clientId).subscribe((marketingSettings: MarketingSetting[]) => {
      this.messageTypes = marketingSettings.map(setting => ({
        id: `${setting.id}`,
        title: setting.key,
        message: setting.messageTemplate
      }));
    }, (error) => {
      console.log(`Could not fetch templates: ${error}`);
    });
  }

  onMessageTypeChange() {
    const messageType = this.messageTypes.find(c => c.id === this.noteForm.value.messageTypeId);
    const message = messageType ? messageType.message : '';
    this.noteForm.patchValue({ body: message });
  }

  private initNoteTypesListSubscription() {
    this.newNoteTypeList$.subscribe(noteType =>
      // filter out these two enum types on the front end so the user can no longer create them but can still see/action old ones -  slow death from 06/04/2021
      noteType.filter(noteType => noteType.value != NoteTypeEnum.sms && noteType.value != NoteTypeEnum.email).forEach(type => {
        this.noteTypeList.push(type);
      })
    );
  }

  private setRepeatTypes() {
    this.repeatTypes.push(
      { name: 'Day(s)', value: 1 },
      { name: 'Week(s)', value: 2 },
      { name: 'Month(s)', value: 3 }
    );
  }

  private setFormControls() {
    this.selectedType = this.noteTypeList[0];
    this.noteForm = this.formBuilder.group({
      noteType: new FormControl('', Validators.required),
      body: new FormControl('', Validators.required),
      startDate: new FormControl('', Validators.required),
      repeat: new FormControl(''),
      intervalValue: new FormControl(''),
      repeatType: new FormControl(''),
      scheduledDate: new FormControl(moment(new Date()).toDate()),
      scheduledTime: new FormControl(),
      messageTypeId: new FormControl(0),
    });
  }

  toggle() {
    this.btnStatus === 'in' ? this.btnStatus = 'out' : this.btnStatus = 'in';
    this.showBlade = !this.showBlade;
  }

  closeBlade() {
    this.btnStatus = 'out';
    this.showBlade = false;
  }

  updateNoteType(type) {
    this.selectedType = type;
    this.noteForm.patchValue({
      noteType: type.value
    });
  }

  initFormState() {
    this.noteForm.patchValue({
      noteType: this.selectedType.value,
      body: '',
      startDate: new Date(),
      repeat: false,
      intervalValue: 0,
      repeatType: this.repeatTypes[0].value
    });
  }

  postNote() {
    var { noteType, body, startDate, repeat, intervalValue, repeatType, scheduledDate, scheduledTime } = this.noteForm.value;
    const utcStartDate = moment(startDate).toDate().toISOString();
    let item = new NewNote(
      this.clientId,
      noteType,
      body,
      repeatType,
      repeat,
      utcStartDate,
      intervalValue
    );

    if (item.noteType === NoteTypeEnum.scheduledSMS) {
      const adjustedScheduledTime = this.getAdjustedScheduledTime(scheduledTime, scheduledDate);
      let sendDate = moment(scheduledDate).set({
        hour: moment(adjustedScheduledTime).hours(),
        minute: moment(adjustedScheduledTime).minutes()
      });
      item.startDate = sendDate.toDate();
    }

    this.store.dispatch(new CreateNewNote(item))
      .subscribe(() => {
        this.noteForm.reset();
        this.closeBlade();
        this.initFormState();
      });
  }

  getCharacterCount() {
    return this.characterCountService.getCharacterCount(this.noteForm.value.body)
  }

  getMessageCount() {
    return this.characterCountService.getMessageCount(this.noteForm.value.body)
  }

  disabledDates(current: Date): boolean {
    const minDate = moment().add(-1, 'days');
    const maxDate = moment().add(12, 'months');
    return !moment(current).isBetween(minDate, maxDate);
  }

  private getAdjustedScheduledTime(scheduledTime: Date, scheduledDate: Date): Date {
    if(!scheduledTime)
      return moment().set({ hour: 10, minute: 0, second: 0 }).toDate();

    const isSendDateToday = moment(scheduledDate).isSame(moment(), 'day');
    if (isSendDateToday && scheduledTime) {
      const isSendTimeBeforeNow = moment(scheduledTime).isBefore(moment(), 'minute');
      if(isSendTimeBeforeNow) {
        return moment().add(5, 'minutes').toDate();
      }
    }
    return scheduledTime;
  }
}
