<ng-container>
  <button
    class="send-to-queue-button button btn__yellow"
    [disabled]="loading"
    (click)="sendToQueue()"
  >
    <span *ngIf="loading; else defaultText">
      <nz-spin nzSimple [nzSpinning]="loading" [nzIndicator]="loadingIndicator"></nz-spin>
    </span>
    <ng-template #loadingIndicator><span nz-icon nzType="loading"></span></ng-template>
    <ng-template #defaultText>Send To Queue</ng-template>
  </button>
</ng-container>