import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BaseApi } from '../core/base-api/base-api';
import { StorageService } from '../core/storage/storage.service';
import { AlertService } from '../../modules/core/alert/alert.service';
import { CustomerProfileModal } from '../models/profile/customerProfile.model';
import { DatesService } from '../core/dates/dates.service';
import { Store } from '@ngxs/store';
import { TimezoneState } from '../../modules/core/timezone/state/timezone.state';

import * as moment_ from 'moment';
const moment = moment_;

@Injectable()
export class ProfileService extends BaseApi {
  API = '';

  constructor(
    @Inject('environment') env: any,
    public http: HttpClient,
    public router: Router,
    public storageService: StorageService,
    public alertService: AlertService,
    private store: Store
  ) {
    super(http, router, storageService, alertService);
    this.API = env.baseApiUrl;
  }

  updateCustomerProfile(customerProfile: CustomerProfileModal) {
    customerProfile.startDate = this.convertToUtc(customerProfile.startDate);
    return this.post(`${this.API}/api/client/update`, customerProfile);
  }

  quickUpdateCustomerProfileComms(customerProfile: CustomerProfileModal) {
    customerProfile.startDate = this.convertToUtc(customerProfile.startDate);
    return this.post(`${this.API}/api/client/quick-update-comms`, customerProfile);
  }

  quickUpdateCustomerProfile(customerProfile) {
    return this.put(`${this.API}/api/client/quick-update`, customerProfile);
  }

  setUpCustomerProfile(customerProfile: CustomerProfileModal) {
    return this.post(`${this.API}/api/client/setup`, customerProfile);
  }

  private convertToUtc(date: Date) {
    const timezone = this.store.selectSnapshot(TimezoneState.selectedTimezone);
    return moment(DatesService.ToStartOfLocalDayInUtc(date, timezone)).toDate();
  }
}
