import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-column-table',
  templateUrl: './column-table.component.html',
  styleUrls: ['./column-table.component.scss']
})
export class ColumnTableComponent implements OnInit {
  @Input() title = '';
  @Input() totalText  = '';
  @Input() filterTitle = '';
  @Input() data: any = {};
  @Input() customerView = false;
  @Input() showIcons = true;
  @Output() columnClicked = new EventEmitter<any>();
  @Output() filter = new EventEmitter<string>();

  property: string;
  isDesc = false;
  args = {};
  filterText = '';
  public dataSub;

  constructor() { }

  ngOnInit() {
  }

  handleColumnClick(title, item) {
    if (!this.customerView) {this.columnClicked.emit({title, item}); }

  }
  
  getArrow(value: string) {
    if (value === this.property && this.isDesc) {
      return 'fa-sort-asc';
    } else {
      return 'fa-sort-desc';
    }
  }

  sort(property) {
    this.isDesc = !this.isDesc;
    this.property = property;
    const direction = this.isDesc ? 1 : -1;
    this.args = { property: property, direction: direction };
  }

  textChanged() {
    this.filter.emit(this.filterText);
  }

}
