import { StagedSupplyFormListState, StagedSupplyFormType } from "./staged-supply-form-list.state";
import { Selector } from "@ngxs/store";
import { SSPatientDetailsFormState } from "../../patient-details-form/state/ss-patient-details-form.state";
import { SSConsentEligibilityFormState } from "../../consent-eligibility-form/state/ss-consent-eligibility-form.state";
import { SSAdditionalDetailsFormState } from "../../ss-aditional-details-form/state/ss-additional-details-form.state";
import { SSOutcomesAndActionsFormState } from "../../ss-outcomes-and-actions-form/state/ss-outcomes-and-actions-form.state";
import { StagedSupplyMedicationProfileState } from "../../staged-supply-medication-profile/state/staged-supply-medication-profile.state";
import { SSMedicationInformationFormState } from "../../ss-medication-information-form/state/ss-medication-information-form.state";


export class StagedSupplyFormsProgressService {
  @Selector([
    SSPatientDetailsFormState.totalItems,
    SSPatientDetailsFormState.completedItems,
    SSConsentEligibilityFormState.totalItems,
    SSConsentEligibilityFormState.completedItems,
    SSOutcomesAndActionsFormState.totalItems,
    SSOutcomesAndActionsFormState.completedItems,
    SSMedicationInformationFormState.totalItems,
    SSMedicationInformationFormState.completedItems,
    StagedSupplyMedicationProfileState.selectedMedicineCount
  ])
  static getSSRegistrationProgress(
    patientDetailsTotal,
    patientDetailsCompleted,
    consentEligibilityTotal,
    consentEligibilityCompleted,
    outcomesAndActionsTotal,
    outcomesAndActionsCompleted,
    medicationInformationTotal,
    medicationInformationCompleted,
    medicineCount
  ) {
    return Math.floor((
      patientDetailsCompleted +
      consentEligibilityCompleted +
      outcomesAndActionsCompleted +
      medicationInformationCompleted +
      (medicineCount > 0 ? 1 : 0)) /
      (patientDetailsTotal +
        consentEligibilityTotal +
        outcomesAndActionsTotal +
        medicationInformationTotal + 1) * 100);
  }

  @Selector([
    SSPatientDetailsFormState.totalItems,
    SSPatientDetailsFormState.completedItems,
    SSConsentEligibilityFormState.totalItems,
    SSConsentEligibilityFormState.completedItems,
    SSAdditionalDetailsFormState.totalItems,
    SSAdditionalDetailsFormState.completedItems,
    StagedSupplyMedicationProfileState.selectedMedicineCount
  ])
  static getSSClaimProgress(
    patientDetailsTotal,
    patientDetailsCompleted,
    consentEligibilityTotal,
    consentEligibilityCompleted,
    additionalDetailsTotal,
    additionalDetailsCompleted,
    medicineCount
  ) {
    return Math.floor((
      patientDetailsCompleted +
      consentEligibilityCompleted +
      additionalDetailsCompleted +
      (medicineCount > 0 ? 1 : 0)) /
      (patientDetailsTotal +
        consentEligibilityTotal +
        additionalDetailsTotal + 1) * 100);
  }

  @Selector([StagedSupplyFormListState.stagedSupplyType, StagedSupplyFormsProgressService.getSSClaimProgress, StagedSupplyFormsProgressService.getSSRegistrationProgress])
  static getProgress(stagedSupplyType, claimProgress, registrationProgress) {
    return stagedSupplyType == StagedSupplyFormType.PatientRegistration
      ? registrationProgress
      : claimProgress;
  }
}
