<ng-container *ngIf="(order$ | async) as orderState">
  <ng-container *ngIf="!orderState.loading else loading">
    <ng-container *ngIf="orderState.currentOrder as order">
      <ng-container *ngIf="orderMatchesCustomer(order); else wrongCustomerMessage">
        <a [routerLink]="['../']"><i class="fal fa-chevron-left"></i>&nbsp; Back to orders</a>
        <app-order-card
          class="mt2"
          [dateFn]="formatDate"
          [order]="order"
          [disabled]="isReadOnly(order)"
          [isHeader]="true"
          (orderMethodChange)="changeOrderMethod($event)"
          (updateStatus)="openUpdateStatusModal($event)"
          (onValidChange)="tableChange($event)"
          (onNavigateToComms)="navToComms()"
        ></app-order-card>

        <app-order-table class="mt2"
          [dateFn]="formatDate"
          [orderItems]="mapItems(order.orderRequestedItems)"
          [disabled]="isReadOnly(order)"
          (onOpenImage)="openImageModal($event)"
          (onRemoveItem)="removeItem(order, $event)"
          (onFormChange)="tableChange($event)"
          (orderItemsChange)="changeOrderItems($event)"
          ></app-order-table>

        <ng-container *ngFor="let hasSelectedOrderItems of [(hasSelectedOrderItems$ | async)]">
          <button nz-button nzType="ghost"
          [disabled]="isReadOnly(order) || !hasSelectedOrderItems"
          class="mt-2"
          [ngClass]="{'disabled-button' : isReadOnly(order) || !hasSelectedOrderItems}"
          (click)="openSendToQueueModal(order)"
          >
            Send To Queue&nbsp;<i class="fas fa-arrow-right"></i>
          </button>
        </ng-container>

          <button nz-button nzType="link"
          class="mt-2 hover-link"
          [disabled]="isReadOnly(order)"
          (click)="openAddMedicationModal(order)"
          ><i class="fas fa-plus"></i>&nbsp;Add medication
          </button>
          <button
            nz-button nzType="link"
            class="hover-link"
            [disabled]="isReadOnly(order)"
            (click)="openAdditionalItemsModal(order)"
            >
            <i class="fas fa-plus"></i>&nbsp;Add additional items
          </button>

          <div class="mt-4">
            <h3>Notes &nbsp;&nbsp;<span class="small"><i class="far fa-eye"></i>&nbsp;Visible to patient when order is updated</span></h3>
            <button
              *ngIf="!order.pharmacistNotes && !notesOpen"
              nz-button
              class="hover-link"
              nzType="link"
              [disabled]="isReadOnly(order)"
              (click)="notesOpen = !notesOpen"
              >
              <i class="fas fa-plus"></i>&nbsp;Add notes
            </button>
            <textarea
              class="w50 order-notes"
            *ngIf="order.pharmacistNotes || notesOpen"
            nz-input
            [disabled]="isReadOnly(order)"
            rows="4" [(ngModel)]="order.pharmacistNotes"></textarea>
          </div>

          <div class="stripe-box" *ngIf="pharmacy && pharmacy.stripeApiKey && order.orderLink">
          <table>
            <tr>
              <td class="stripe-logo">
                 <img src="assets/img/stripe.png"/>
              </td>
              <td style="width: 57%;">STRIPE Payment Gateway allows you to select medication, add additional items and customise each order individually
                to ensure a customer can make payment quickly, easily and conveniently.
              </td>
              <td class="payment-edit-btn">
                <button class="btn__white" (click)="visibleStripeModal(true)">
                  Edit Payment Link
                </button>
             </td>
             <td class="copy-payment-link" (click)="copyPaymentLink(order.orderLink)"  nz-tooltip [nzTooltipTrigger]="'click'"
             nzTooltipTitle="Link copied!" nzTooltipPlacement="bottomRight">
              <i class="fas fa-regular fa-copy"></i>
                Copy Payment Link
           </td>
            </tr>
          </table>
        </div>

        <div class="stripe-box" *ngIf="pharmacy && pharmacy.stripeApiKey && !order.orderLink">
          <table>
            <tr>
              <td class="stripe-logo">
                 <img src="assets/img/stripe.png"/>
              </td>
              <td style="width: 60%;">STRIPE Payment Gateway allows you to select medication, add additional items and customise each order individually
                to ensure a customer can make payment quickly, easily and conveniently.
              </td>
              <td class="generate-payment-link" (click)="visibleStripeModal(true)">
                <button class="btn__white">
                  Generate Payment Link
                </button>
             </td>
            </tr>
          </table>
        </div>

        <div class="stripe-box" *ngIf="pharmacy && !pharmacy.stripeApiKey && !order.orderLink">
          <table>
            <tr>
              <td class="stripe-logo">
                 <img src="assets/img/stripe.png"/>
              </td>
              <td style="width: 77%;">STRIPE Payment Gateway allows you to select medication, add additional items and customise each order individually
                to ensure a customer can make payment quickly, easily and conveniently.
              </td>
              <td class="generate-payment-link">
                <button class="btn__white" (click)="learnMore()">
                  Learn More
                </button>
             </td>
            </tr>
          </table>
        </div>

        <app-stripe-modal
        [isVisible]="orderState.stripeModalVisible"
        (isVisibleChange)="closeStripeModal()"
        [orderItems]="order.orderRequestedItems"
        [order]="order"
      >
        </app-stripe-modal>

        <app-order-update-status-modal
            [order]="(orderWithNewStatus$| async)"
            [dateFn]="formatDate"
            [profile]="profile"
            [isVisible]="orderState.showStatusModal"
            (isVisibleChange)="closeModal(order)"
            (updateOrder)="updateOrder($event)"
        >
        </app-order-update-status-modal>
        <app-send-to-queue-modal
          [order]="order"
          [profile]="profile"
          [isVisible]="orderState.showSendToQueue"
          (isVisibleChange)="closeSendModal()"
          (onSendToQueue)="sendToQueue($event)"
        >
        </app-send-to-queue-modal>
        <app-image-modal
          [item]="orderState.imageViewItem"
          [isVisible]="orderState.showImageModal"
          [updatedText]="formatDate(order.requestedDate)"
          (isVisibleChange)="closeImageModal()"
        >
        </app-image-modal>
        <app-additional-items-modal
          [isLoading]="orderState.additionalItemsLoading"
          [data]="searchListFiltered$ | async"
          [isVisible]="orderState.showAdditionalItemsModal"
          (isVisibleChange)="closeAdditionalItemsModal()"
          (onGetNewData)="stockSearch($event)"
          (onAddAdditionalItem)="addItem(order, $event)"
        >
        </app-additional-items-modal>
        <app-add-medication-modal
          [profileOrderItems]="profileOrderItems$ | async"
          [isVisible]="orderState.showAddMedicationModal"
          (isVisibleChange)="closeAddMedicationModal()"
          (onAddItem)="addItem(order, $event)"
          (onRemoveItem)="removeItem(order, $event)"
          [dateFn]="formatDate"
        >
        </app-add-medication-modal>
        <app-order-update-status-card
        [disabled]="isReadOnly(order) || !(validService.validStatus | async)"
        [order]="(orderWithNewStatus$| async)"
        [profile]="profile"
        (updateStatus)="updateOrderFromStatusCard($event, order)"></app-order-update-status-card>
      </ng-container>
    </ng-container>
  </ng-container>
  <ng-template #wrongCustomerMessage>
    <nz-result nzStatus="info" nzTitle="This order does not belong to this customer" class="ant-result-warning">
      <div nz-result-extra>
        <button [routerLink]="['../']" class="button btn__yellow">Go back to orders</button>
      </div>
    </nz-result>
  </ng-template>
  <ng-template #loading>
    <app-loader></app-loader>
  </ng-template>
</ng-container>
