import { StatisticRequestModel } from '../../../shared/models/statistics.model';

enum Staticitics {
  CREATE_STATISTIC = "[Statistics] Create Stat",
  UPDATE_STATISTIC = "[Statistics] Update Stat",
}

export class CreateStatistic {
  static readonly type = Staticitics.CREATE_STATISTIC;
  constructor(public request: StatisticRequestModel) {}
}

export class UpdateStatistic {
  static readonly type = Staticitics.UPDATE_STATISTIC;
  constructor(public request: StatisticRequestModel) {}
}


