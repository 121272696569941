import { ToggleBladeAction } from '../../../../core/blade/state/blade.actions';

export const DOSAGE_HISTORY_BLADE_NAME = '[Dosage History]';

export enum DosageHistoryActionTypes {
  OPEN = '[Dosage History] Open',
  INIT = '[Dosage History] Init',
  CLOSE = '[Dosage History] Close',
  REMOVE_DOSAGE_RECORD_REQUEST = '[Dosage History] Remove dosage record request',
  REMOVE_DOSAGE_RECORD_RESPONSE = '[Dosage History] Remove dosage record response',
  REMOVE_DOSAGE_RECORD_ERROR = '[Dosage History] Remove dosage record error'
}

export class OpenDosageHistoryAction extends ToggleBladeAction {
  static readonly type = DosageHistoryActionTypes.OPEN;
  constructor() { super(DOSAGE_HISTORY_BLADE_NAME); }
}

export class InitDosageHistoryAction {
  static readonly type = DosageHistoryActionTypes.INIT;
  constructor(public script: any) { }
}

export class CloseDosageHistoryAction extends ToggleBladeAction {
  static readonly type = DosageHistoryActionTypes.CLOSE;
  constructor() { super(DOSAGE_HISTORY_BLADE_NAME); }
}

export class RemoveDosageHistoryRecordRequestAction {
  static readonly type = DosageHistoryActionTypes.REMOVE_DOSAGE_RECORD_REQUEST;
  constructor(public recordId: any, public script: any) { }
}

export class RemoveDosageHistoryRecordResponseAction {
  static readonly type = DosageHistoryActionTypes.REMOVE_DOSAGE_RECORD_RESPONSE;
  constructor(public recordId: any, public script: any) { }
}

export class RemoveDosageHistoryRecordErrorAction {
  static readonly type = DosageHistoryActionTypes.REMOVE_DOSAGE_RECORD_ERROR;
  constructor(public error: any, public recordId: any, public script: any) { }
}
