import { NgModule } from '@angular/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { TimezoneModule } from '~/modules/core/timezone/timezone.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { LoaderModule } from '~/modules/core/loader/loader.module';
import { NewBulkMessageComponent } from './new-bulk-message.component';
import { PapaParseModule } from 'ngx-papaparse';
import { NotificationModule } from '../../../shared/components/notification/notification.module';
import { ScryptInsightModule } from '~/shared/scrypt-insight/scrypt-insight.module';
import { BulkMessagingStateModule } from '../state/bulk-messaging-state.module';
import { TooltipModule } from 'ngx-bootstrap';
import { CommTemplatesService } from '~/modules/pharmacy-comms/comm-templates/services/comm-templates.service';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzTimePickerModule } from 'ng-zorro-antd/time-picker';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { RouterModule } from '@angular/router';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { AddEditCommTemplateModalModule } from '~/modules/pharmacy-comms/comm-templates/add-edit-comm-template-modal/add-edit-comm-template-modal.module';
import { ZApiModule } from '~/system/z-api/z-api.module';

@NgModule({
  declarations: [
    NewBulkMessageComponent
  ],
  imports: [
    NotificationModule,
    NgSelectModule,
    PapaParseModule,
    FormsModule,
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    BulkMessagingStateModule,
    LoaderModule,
    TimezoneModule,
    ScryptInsightModule,
    TooltipModule.forRoot(),
    NzDatePickerModule,
    NzTimePickerModule,
    NzTableModule,
    NgxsFormPluginModule,
    NzPopconfirmModule,
    NzModalModule,
    NzButtonModule,
    NzCheckboxModule,
    AddEditCommTemplateModalModule,
    ZApiModule
  ],
  exports:[ NewBulkMessageComponent ],
  providers: [
    CommTemplatesService,
    NzNotificationService
  ]
})
export class NewBulkMessageModule { }
