<div class="modal">
  <div class="modal-body border__grey">
    <h3>Create new recurring SMS</h3>
    <div>
      <form [formGroup]="messageCycleForm" ngxsForm="messageCycle.messageCycleForm">
        <div class="form-group">
            <label class="font__bold" g-xs="mt2">Every</label>
        </div>

        <div class="form-row">
          <div class="form-group col-md-3">
            <input name="intervalValue" formControlName="intervalValue" type="number" class="form-control border__grey-light" id="intervalValue"
              required />
          </div>
          <div class="form-group col-md-3">
            <select class="form-control" formControlName="intervalType">
              <option [value]="intervalTypes.day">Days</option>
              <option [value]="intervalTypes.week">Weeks</option>
              <option [value]="intervalTypes.month">Month(s)</option>
            </select>
          </div>
        </div>

        <div class="form-row">
          <div class="form-group col-md-4">
            <label class="font__bold" g-xs="mt2 mb1">Scheduled Time</label>
            <input name="startTime" type="time" formControlName="startTime" class="form-control border__grey-light" id="time" required />
          </div>
          <div class="form-group col-md-3">
            <label class="font__bold" g-xs="mt2 mb1">Start Date</label>
            <input name="startDate" type="text" placeholder="dd-mm-yyyy"
              class="form-control border__grey-light calendar" formControlName="startDate" id="date" 
              [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY' }" [minDate]="minDate"
              bsDatepicker required />
          </div>
        </div>

        <div class="form-group">
            <label class="font__bold" for="pre-selected-messages">Templates</label>
            <select class="form-control pre-selected-messages" g-xs="py2" id="pre-selected-messages"
              name="templateId" formControlName="templateId" (change)="updateMessageTemplateBody()">
              <option>
                Use a pre-selected message to send to your clients
              </option>
              <option *ngFor="let item of messageTypes" [value]="item.id">
                {{item.title}}
              </option>
            </select>
          </div>

        <div class="form-group">
            <label class="font__bold" g-xs="mt2 mb1">Message</label>
            <textarea name="text" type="text" rows="5" formControlName="text" class="form-control border__grey-light" id="text" required></textarea>
          </div>

        <div class="form-group">
          <label class="font__bold" g-xs="mt2 mb1">Notes</label>
          <input name="notes" type="text" formControlName="notes" class="form-control border__grey-light" id="notes" required />
        </div>

      </form>
    </div>
    <div g-xs="mt2">
      <button class="button btn__yellow" g-xs="mr1" (click)="closeModal()">Cancel</button>
      <button class="button btn__green" (click)="submitMessageCycleForm()">Save</button>
    </div>
  </div>
  <ng-container *ngIf="(loading$ | async) as loading">
    <app-loader></app-loader>
  </ng-container>
</div>
<div class="modal-background"></div>
