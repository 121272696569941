<div class="modal confirm-modal">
  <div class="modal-body border__grey">
    <div class="scroll">
    <i class="fas fa-2x fa-times-circle" style="float:right; cursor: pointer;" (click)="closeModal()"></i>
      <div [innerHTML]="markup">
      </div>
    </div>
  </div>
</div>
<div class="modal-background"></div>
