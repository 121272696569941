<div class="scheduled-messages" *ngIf="!(scheduledMessagesLoading$ | async); else loading">
  <ng-container *ngIf="scheduledMessages$ | async as scheduledMessages">
    <section class="message-history">
      <div class="font__title-3 font__light">
        <h5>Scheduled Messages</h5>
      </div>
      <div
        id="sms-history"
        g-xs="p3 mt2 mb3"
        class="sms-history border__grey"
        ngx-auto-scroll
        observe-attributes
      >
        <div g-xs="mb3" *ngFor="let item of scheduledMessages">
          <div class="color__grey-text font__title-0">
            <strong>Created: </strong>
            <span *ngIf="item.createdOn">
              {{ item.createdOn | date: "medium" }}
            </span>
            <span *ngIf="!item.createdOn">Just Now</span>
            <button class="btn__img" (click)="removeMessage(item.id)">
              <i class="far fa-trash-alt color__red"></i>
            </button>
          </div>
          <div class="color__grey-text font__title-0">
            <strong>Send On: </strong>{{ item.sendOn | date: "medium" }}
          </div>
          <div class="font__title-1">
            {{ item.text }}
            <ng-container *ngIf="item.status == scheduledMessageStatus.Failed">
              <span>
                - Failed <i class="fas fa-exclamation-triangle color__red"></i>
              </span>
              <button class="btn btn-link p-0 ml-2 text-danger" (click)="retryMessage(item.id)">RETRY MESSAGE</button>
            </ng-container>
          </div>
        </div>
        <div *ngIf="!scheduledMessages.length">
          <span>
            There are no currently scheduled messages for
            {{ client.firstname }} {{ client.surname }}
          </span>
        </div>
      </div>
    </section>
  </ng-container>
</div>

<ng-template #loading>
  <div g-xs="p4 text:center" class="flex">
    <app-loader class="loading"></app-loader>
  </div>
</ng-template>

