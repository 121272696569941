import { MedicationHealthConditions } from '../medication-information-form.model';
import { MedicineManagementSupport } from '../consent-and-eligibility-form.model';

export class DAAClassificationsRecomendationsFormModel {
  id?: number | null;
  gpOrHospitalLastSixMonthsForMedicationProblems: boolean | null;
  patientUsingDAAPriorToVisit: boolean | null;
  healthConditionsBeingMedicated?: MedicationHealthConditions;
  healthConditionsBeingMedicatedOtherDetails?: string;
  patientSupportWithManagingMedicines?: MedicineManagementSupport;
  englishAtHome: boolean | null;
  aboriginalOrTorresStraitIslander: boolean | null;
  daasPackedPerWeek: number | null;
  frequencyOfCollection: CollectionFrequency;
}

export enum CollectionFrequency {
  Weekly,
  Fortnightly,
  Monthly,
  Other
}
