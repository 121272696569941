import { StagedSupplyFormType } from './staged-supply-form-list.state';
import { StagedSupplyFormModel } from '../../../../../../shared/models/six-cpa/staged-supply/staged-supply-form.model';

export enum StagedSupplyFormListActions {
  SET_VALID = '[StagedSupplyFormList] Set Valid',
  SUBMIT = '[StagedSupplyFormList] Submit',
  RESET = '[StagedSupplyFormList] Reset',
  RESET_STATE = '[StagedSupplyFormList] Reset State',
  SAVE = '[StagedSupplyFormList] Save',
  UPDATE_SAVE = '[StagedSupplyFormList] Update Save',
  UPDATE_SUBMIT = '[StagedSupplyFormList] Update Submit',
  SAVE_SUCCESS = '[StagedSupplyFormList] Save Success',
  SAVE_FAILED = '[StagedSupplyFormList] Save Failed',
  SET_STAGED_SUPPLY_TYPE = '[StagedSupplyFormList] Set Meds Check Type',
  GET = '[StagedSupplyFormList] Get',
  GET_STAGED_SUPPLY_FORM_SUCCESS = '[StagedSupplyFormList] On Get Meds Check Form Success',
  GET_STAGED_SUPPLY_FORM_ERROR = '[StagedSupplyFormList] On Get Meds Check Form Error',
  UPDATE_BASIC_STAGED_SUPPLY_FORM = '[StagedSupplyFormList] Update Basic Meds Check Child Forms',
  UPDATE_DETAILED_STAGED_SUPPLY_StagedSupply_FORM = '[StagedSupplyFormList] Update Detailed Meds Check Child Forms',
  OPEN_STAGED_SUPPLY_CONFIRM_FORM = '[StagedSupplyFormList] Open Confirm Form',
  CLOSE_STAGED_SUPPLY_CONFIRM_FORM = '[StagedSupplyFormList] Close Confirm Form',
  SHOW_INVALID = '[StagedSupplyFormList] Show Invalid StagedSupply Form',

}

export class StagedSupplyFormListValidAction {
  static readonly type = StagedSupplyFormListActions.SET_VALID;
  constructor(public valid = false) { }
}

export class SubmitNewStagedSupply {
  static readonly type = StagedSupplyFormListActions.SUBMIT;
  constructor(public clientId: string, public formData: StagedSupplyFormModel) { }
}

export class SubmitExisitingStagedSupply {
  static readonly type = StagedSupplyFormListActions.UPDATE_SUBMIT;
  constructor(public clientId: string, public formData: StagedSupplyFormModel) { }
}

export class ResetStagedSupplyFormListAction {
  static readonly type = StagedSupplyFormListActions.RESET;
  constructor(public onlyClearFormIds: boolean = false) { }
}

export class ResetStagedSupplyFormStateToDefaults {
  static readonly type = StagedSupplyFormListActions.RESET_STATE;
  constructor(public onlyClearFormIds: boolean = false) { }
}

export class SaveNewStagedSupplyForm {
  static readonly type = StagedSupplyFormListActions.SAVE;
  constructor(public clientId: string, public formData: StagedSupplyFormModel) { }
}

export class SaveExisitingStagedSupplyForm {
  static readonly type = StagedSupplyFormListActions.UPDATE_SAVE;
  constructor(public clientId: string, public formData: StagedSupplyFormModel) { }
}

export class SaveStagedSupplyFormSuccess{
  static readonly type = StagedSupplyFormListActions.SAVE_SUCCESS;
  constructor(public formData: any) { }
}

export class SaveStagedSupplyFormFailed{
  static readonly type = StagedSupplyFormListActions.SAVE_FAILED;
  constructor(public error: any) { }
}

export class SetStagedSupplyTypeAction {
  static readonly type = StagedSupplyFormListActions.SET_STAGED_SUPPLY_TYPE;
  constructor(public stagedSupplyFormType: StagedSupplyFormType) { }
}

export class GetStagedSupplyForm {
  static readonly type = StagedSupplyFormListActions.GET;
  constructor(public clientId: string, public sixCpaFormId: number) { }
}

export class GetStagedSupplyFormSuccess {
  static readonly type = StagedSupplyFormListActions.GET_STAGED_SUPPLY_FORM_SUCCESS;
  constructor(public stagedSupplyForm: StagedSupplyFormModel) { }
}

export class GetStagedSupplyFormError {
  static readonly type = StagedSupplyFormListActions.GET_STAGED_SUPPLY_FORM_ERROR;
  constructor(public error: any) { }
}

export class UpdateBasicStagedSupplyForms {
  static readonly type = StagedSupplyFormListActions.UPDATE_BASIC_STAGED_SUPPLY_FORM;
  constructor(public stagedSupplyForm: StagedSupplyFormModel) { }
}

export class UpdateDetailedStagedSupplyForms {
  static readonly type = StagedSupplyFormListActions.UPDATE_DETAILED_STAGED_SUPPLY_StagedSupply_FORM;
  constructor(public stagedSupplyForm: StagedSupplyFormModel) { }
}

export class OpenStagedSupplyConfirmFormAction {
  static readonly type = StagedSupplyFormListActions.OPEN_STAGED_SUPPLY_CONFIRM_FORM;
  constructor() { }
}

export class CloseStagedSupplyConfirmFormAction {
  static readonly type = StagedSupplyFormListActions.CLOSE_STAGED_SUPPLY_CONFIRM_FORM;
  constructor() { }
}
export class ShowInvalidStagedSupplyForm {
  static readonly type = StagedSupplyFormListActions.SHOW_INVALID;
}
