import { State, Action, StateContext, Selector } from '@ngxs/store';
import { AddContentContainerClassAction, RemoveContentContainerClassAction } from './content-container.actions';
import { Injectable } from '@angular/core';

export class ContentContainerStateModel {
  public classes: Set<string>;
}
@Injectable()
@State<ContentContainerStateModel>({
  name: 'contentContainer',
  defaults: {
    classes: new Set()
  }
})
export class ContentContainerState {
  @Selector()
  static classValue(state: ContentContainerStateModel) {
    return state.classes;
  }

  @Action(AddContentContainerClassAction)
  add(ctx: StateContext<ContentContainerStateModel>, action: AddContentContainerClassAction) {
    const state = ctx.getState();
    state.classes.add(action.class_);
    ctx.patchState(state);
  }

  @Action(RemoveContentContainerClassAction)
  remove(ctx: StateContext<ContentContainerStateModel>, action: RemoveContentContainerClassAction) {
    const state = ctx.getState();
    state.classes.delete(action.class_);
    ctx.patchState(state);
  }
}
