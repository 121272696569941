<ng-container *ngIf="(error$ | async) as error; else defaultError">
  <div class="container">
    <div class="icon-container">
      <i class="fal fa-history large-icon"></i>
      <div>Expired!</div>
    </div>
    <p>
      {{error.error}}
    </p>
  </div>
</ng-container>
<ng-template #defaultError>
  <div class="container">
    <div class="icon-container">
      <i class="fas fa-exclamation-triangle large-icon"></i>
      <div>Uh oh!</div>
    </div>
    <p>
      Looks like there's something wrong with your link, please contact your pharmacy for a new link.
    </p>
  </div>
</ng-template>
