import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NursingHomeService } from './nursing-homes.service';
import { NgxsModule } from '@ngxs/store';
import { NursingHomeState } from './state/nursing-homes.state';

@NgModule({
  imports: [
    CommonModule,
    NgxsModule.forFeature([NursingHomeState])
  ],
  providers: [NursingHomeService]

})
export class NursingHomeModule {
  constructor() {}
}
