<section class="login">
  <div class="login__logo" g-xs="mb3">
    <img src="./assets/img/scrypt-vector.svg" class="logo-size" />
  </div>
  <div class="login__panel">
    <div *ngIf="!submitting; else loading">
      <app-notification [notification]="notification"></app-notification>
      <form #loginForm="ngForm" (ngSubmit)="onSubmit()">
        <div
          class="form-group"
          [ngClass]="{ 'has-error': usernameControl.invalid && usernameControl.touched }"
        >
          <h4 g-xs="mb2">Your User Name</h4>
          <input
            type="email"
            email="true"
            required
            class="form-control input__yellow"
            id="username"
            placeholder="your@email.com"
            [(ngModel)]="username"
            name="usernameControl"
            #usernameControl="ngModel"
          />
          <span
            class="form-help-text"
            *ngIf="usernameControl.invalid && (usernameControl.touched || usernameControl.dirty)"
          >
            <span *ngIf="usernameControl.errors.required">Required</span>
            <span *ngIf="usernameControl.errors.email">Invalid Email Address</span>
          </span>
        </div>
        <div
          class="form-group"
          [ngClass]="{ 'has-error': passwordControl.invalid && passwordControl.touched }"
        >
          <h4 g-xs="mb2">Your Password</h4>
          <input
            type="password"
            class="form-control input__yellow"
            id="password"
            required
            placeholder="Password"
            [(ngModel)]="password"
            name="passwordControl"
            #passwordControl="ngModel"
          />
          <span
            class="form-help-text"
            *ngIf="passwordControl.invalid && passwordControl.touched"
          >
            <span *ngIf="passwordControl.errors.required">Required</span>
          </span>
        </div>
        <div g-xs="my4" class="font__title-3">
          <a (click)="onForgot()"
            ><i class="fas fa-lock font__title-0" g-xs="mr2"></i>Forgotten
            Password</a
          >
        </div>
        <div class="form-group">
          <button
            type="submit"
            class="btn__yellow font__title-4"
            g-xs="mr3"
            [disabled]="loginForm.invalid"
          >
            Login
          </button>
          <button class="btn__white font__title-4">Register</button>
        </div>
      </form>
    </div>
    <ng-template #loading> <app-loader></app-loader> </ng-template>
  </div>
</section>
