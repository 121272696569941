import { Inject, Injectable } from '@angular/core';
import { BaseApi } from '../../../../shared/core/base-api/base-api';
import { Observable } from 'rxjs';
import { AttendingDoctor } from '../model/attending-doctor';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { StorageService } from '../../../../shared/core/storage/storage.service';
import { AlertService } from '../../../core/alert/alert.service';

@Injectable()
export class AttendingDoctorService extends BaseApi {
  API = '';

  constructor(
    @Inject('environment') env: any,
    public http: HttpClient,
    public router: Router,
    public storageService: StorageService,
    public alertService: AlertService
  ) {
    super(http, router, storageService, alertService);
    this.API = env.baseApiUrl;
  }

  getDoctors(
    clientId: string,
    fromDate: any,
    toDate: any
  ): Observable<Array<AttendingDoctor>> {
    return this.get(`${this.API}/api/clients/${clientId}/doctors`, {
      from: fromDate,
      to: toDate,
    });
  }
}
