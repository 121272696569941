import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddEditCommTemplateModalComponent } from './add-edit-comm-template-modal.component';
import {  FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoaderModule } from '../../../../modules/core/loader/loader.module';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import { RadioHighlightModule } from '../../../../shared/components/radio-highlight/radio-highlight.module';
import { AddEditCommTemplateModalState } from './state/add-edit-comm-template-modal.state';
import { NgxsModule } from '@ngxs/store';

@NgModule({
  declarations: [AddEditCommTemplateModalComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    LoaderModule,
    NgxsFormPluginModule,
    RadioHighlightModule,
    FormsModule,
    NgxsModule.forFeature([AddEditCommTemplateModalState])
  ],
  exports: [AddEditCommTemplateModalComponent]
})
export class AddEditCommTemplateModalModule { }
