import { Pipe, PipeTransform } from "@angular/core";
import { PrimaryContactType } from "../models/change-primary-contact-request.model";
import { LinkedContactSummary, PrimaryContactSummary } from "../models/primary-contact-summary.model";

@Pipe({
    name: 'filterLinkedContacts'
})
export class FilterLinkedContactsPipe implements PipeTransform {

    transform(summary: PrimaryContactSummary, filterBy: PrimaryContactType): LinkedContactSummary[] {
        let contacts = summary.contacts;

        switch (filterBy) {
            case PrimaryContactType.SMS: {
                return contacts.filter(contact => contact.mobile == summary.mobileNumber);
            }
            case PrimaryContactType.Email: {
                return contacts.filter(contact => contact.email == summary.email);
            }
            default: {
                return contacts;
            }
        }
    }
}