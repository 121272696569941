import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';

import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import { StorageService } from '../core/storage/storage.service';
import { Bimetric } from '../models/bimetric.model';
import { CrudService } from './crud.service';
import { AlertService } from '~/modules/core/alert/alert.service';
import { BiReport } from '../models/BiReport.model';
import { BioMetricsOptions } from '~/system/dashboard-page/dashboard-page.component';

const API = environment.baseApiUrl;

@Injectable()
export class BimetricService extends CrudService {
  constructor(public http: HttpClient, public router: Router, public storageService: StorageService, public alertService: AlertService) {
    super(http, router, storageService, alertService, 'bimetrics');
  }

  getBiMetricList(): Observable<Bimetric[]> {
    return this.getList();
  }

  getBiMetrcisById(metricId: string): Observable<Bimetric> {
    return this.getById(metricId);
  }

  addBimetric(metric: Bimetric) {
    return this.add(metric);
  }

  updateBimetric(metric: Bimetric) {
    return this.update(metric);
  }

  removeBimetric(metricId: string) {
    return this.remove(metricId);
  }

  getCleanliness(option: BioMetricsOptions): Observable<Bimetric[]> {
    return this.get(`${API}/api/bimetrics/GetCleanliness/${option}`);
  }

  getOutstandingCRM(): Observable<Bimetric> {
    return this.get(`${API}/api/bimetrics/GetOutstandingCRM`);
  }

  getScriptsOnFile(): Observable<Bimetric> {
    return this.get(`${API}/api/bimetrics/GetScriptsOnFile`);
  }

  getNewCustomers(key: string, days: number, type: string): Observable<Bimetric> {
    return this.get(`${API}/api/bimetrics/GetNewCustomers?key=${key}&days=${days}&type=${type}`);
  }

  getReport(reportId: string = '') {
    return this.get(`${API}/api/bimetrics/report?id=${reportId}`);
  }

  getReports():  Observable<BiReport[]> {
    return this.get(`${API}/api/bimetrics/reports`);
  }

}
