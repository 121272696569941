import { Resolve } from '@angular/router';
import { Timezone } from '../timezone.model';
import { Observable } from 'rxjs';
import { Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { resolveTimezones } from '../logic/timezone-resolver.logic';
import { take } from 'rxjs/operators';


@Injectable({
    providedIn: 'root'
})
export class AllTimezonesResolver implements Resolve<Timezone[]> {
    constructor(private store: Store) {}

    resolve(): Observable<Timezone[]> {
        return resolveTimezones(this.store)
        .pipe(take(1));
    }
}
