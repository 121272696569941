import { State, Action, StateContext, Selector, Store } from '@ngxs/store';
import { ToggleScryptSmsModal, GetScryptSMSRepeatTemplates, SetSelectedRepeatTemplate, SetRepeatUri, SendRepeatReminder } from './scrypt-sms.actions';
import { SmsService } from '../../../services/sms.service';
import { MarketingSetting } from '../../../models/marketingSetting.model';
import { ProfileState } from '../../../../modules/core/profile/state/profile.state';
import { catchError, finalize, tap } from 'rxjs/operators';
import { AlertService } from '../../../../modules/core/alert/alert.service';
import { ClearSelectedDrugsAction } from '../../../../modules/profile/categories/multiple-select/state/multiple-drugs.actions';
import { Injectable } from '@angular/core';
import { TemplateType } from '../../../../shared/models/pharmacy/comm-template.model';

export class ScryptSmsStateModel {
  public showModal: boolean;
  public loading: boolean;
  public templates: MarketingSetting[];
  public selectedTemplate: MarketingSetting;
  public repeatUri: string;
}
@Injectable()
@State<ScryptSmsStateModel>({
  name: 'scryptSms',
  defaults: {
    showModal: false,
    loading: false,
    templates: [],
    selectedTemplate: null,
    repeatUri: null
  }
})
export class ScryptSmsState {
  constructor(private smsService: SmsService, private store: Store, private alertService: AlertService) {
  }

  @Selector()
  static showModal(state: ScryptSmsStateModel): boolean  { return state.showModal; }

  @Selector()
  static scryptSMSState(state: ScryptSmsStateModel): ScryptSmsStateModel { return state; }

  @Selector()
  static isLoading(state: ScryptSmsStateModel): boolean { return state.loading; }

  @Selector()
  static selectedTemplate(state: ScryptSmsStateModel): MarketingSetting { return state.selectedTemplate; }

  @Action(ToggleScryptSmsModal)
  toggle(ctx: StateContext<ScryptSmsStateModel>, { showModal }: ToggleScryptSmsModal) {
    ctx.patchState({ showModal: showModal });

    if(!showModal) {
      ctx.patchState({
        repeatUri: null,
        selectedTemplate: null
      });
    }
  }

  @Action(GetScryptSMSRepeatTemplates)
  getTemplates(ctx: StateContext<ScryptSmsStateModel>, action: GetScryptSMSRepeatTemplates) {
    const clientId = this.store.selectSnapshot(ProfileState.clientId);
    ctx.patchState({ loading: true });
    return this.smsService.getTemplatesByType(clientId, TemplateType.Custom).pipe(
      tap(response => ctx.patchState({ templates: response })),
      catchError(err => this.handleError(err)),
      finalize(() => ctx.patchState({ loading: false }))
    )
  }

  @Action(SetSelectedRepeatTemplate)
  setSelectedTemplate(ctx: StateContext<ScryptSmsStateModel>, { templateId }: SetSelectedRepeatTemplate) {
    const template = ctx.getState().templates.find(template => template.id == templateId);
    ctx.patchState({ selectedTemplate: template });
  }

  @Action(SetRepeatUri)
  setRepeatUri(ctx: StateContext<ScryptSmsStateModel>, { repeatUri }: SetRepeatUri) {
    ctx.patchState({ repeatUri: repeatUri });
  }

  @Action(SendRepeatReminder)
  sendRepeatReminder(ctx: StateContext<ScryptSmsStateModel>, { message }: SendRepeatReminder) {
    ctx.patchState({ loading: true });
    return this.smsService.sendMessage(message).pipe(
      tap(response => this.showSuccessMessage()),
      catchError(err => this.handleError(err)),
      finalize(() => ctx.patchState({ loading: false, showModal: false }))
    );
  }

  private async showSuccessMessage() {
    this.alertService.success('The repeat has been sent to customer.');
  }

  private async handleError(error: any) {
    this.alertService.error(JSON.stringify(error));
  }
}
