import { Component, OnInit, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Store } from '@ngxs/store';
import { ToggleModal } from '../state/email.actions';

@Component({
  selector: 'app-email-history-modal',
  templateUrl: './email-history-modal.component.html',
  styleUrls: ['./email-history-modal.component.scss']
})
export class EmailHistoryModalComponent implements OnInit {
  @Input() markup;

  constructor(private store: Store, private domSanitizer: DomSanitizer) { }

  ngOnInit() {
    this.markup =  this.domSanitizer.bypassSecurityTrustHtml(this.markup);
  }

  closeModal() {
    this.store.dispatch(new ToggleModal(false));
  }
}
