import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SixCpaFormListItemChecklistModule } from '../../six-cpa-form-list-item-checklist/six-cpa-form-list-item-checklist.module';
import { HMRServiceProviderDetailsListItemComponent } from './hmr-service-provider-details-list-item.component';
import { HMRServiceProviderDetailsFormModule } from '../hmr-service-provider-details/hmr-service-provider-details-form.module';

@NgModule({
  declarations: [HMRServiceProviderDetailsListItemComponent],
  imports: [
    CommonModule,
    SixCpaFormListItemChecklistModule,
    HMRServiceProviderDetailsFormModule
  ],
  exports: [HMRServiceProviderDetailsListItemComponent]
})
export class HMRServiceProviderDetailsListItemModule { }
