import { PauseMedicationComponent } from './pause-medication.component';
import { PauseMedicationState } from './state/pause-medication.state';
import { PauseMedicationService } from './services/pause-medication.service';
import { AlertService } from '../../../core/alert/alert.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxsModule } from '@ngxs/store';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    PauseMedicationComponent
  ],
  providers: [
    PauseMedicationService,
    AlertService
  ],
  imports: [
    CommonModule,
    FormsModule,
    NgxsModule.forFeature([
      PauseMedicationState
    ]),
    BsDatepickerModule.forRoot()
  ],
  exports: [
    PauseMedicationComponent
  ]
})
export class PauseMedicationModule { }
