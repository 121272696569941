import { Component, OnInit, Input } from '@angular/core';
import { MessageType } from '../../../../../shared/models/messageType.model';
import * as moment_ from 'moment';
const moment = moment_;
import { Notification } from '../../../../../shared/models/notification.model';
import { Subscription } from 'rxjs';
import { MarketingSetting } from '../../../../../shared/models/marketingSetting.model';
import { SmsService } from '../../../../../shared/services/sms.service';
import { NgForm } from '@angular/forms';
import { FirestoreService } from '../../../../../shared/services/firestore.service';
import { AngularFirestoreCollection } from '@angular/fire/firestore';
import { MessengerHistory, FirebaseMessageSource } from '../../../../../shared/models/messageHistory.model';
import { Store } from '@ngxs/store';
import { ProfileState } from '../../../../core/profile/state/profile.state';
import { PharmacyService } from '../../../../../shared/services/pharmacy.service';
import { CreateStatistic } from '../../../../../shared/state/statistics/statistics.actions';

@Component({
  selector: 'app-send-message',
  templateUrl: './send-message.component.html',
  styleUrls: ['./send-message.component.scss']
})
export class SendMessageComponent implements OnInit {

  constructor(private smsService: SmsService, private firestoreService: FirestoreService, private store: Store, private pharmacyService: PharmacyService) { }

  @Input() clientId: string;
  @Input() collection: AngularFirestoreCollection<MessengerHistory>;

  isLoaded = false;
  getMessageTypes: Subscription;
  notification: Notification;
  messageTypes: MessageType[] = [];
  maxDate = moment(new Date()).toDate();
  defaultTime = '10:00'
  defaultDate = moment(new Date()).toDate();
  profileId: number;
  tenantId: number;


  ngOnInit() {
    this.pharmacyService.getPharmacyLocation().subscribe(t => this.tenantId = t.tenantId)
    this.profileId = this.store.selectSnapshot(ProfileState.customerProfileId);
    this.maxDate = moment(this.maxDate).add(12, 'months').toDate();
    this.notification = new Notification('success', '');
      this.getMessageTypes = this.smsService.getMessageTypes(this.clientId).subscribe((marketingSettings: MarketingSetting[]) => {
        this.messageTypes = marketingSettings.map(setting => ({
          id: `${setting.id}`,
          title: setting.key,
          message: setting.messageTemplate
        }));
        this.isLoaded = true;
      }, (error) => {
        this.showNotification('danger', error);
      });
  }

  onSubmit(form: NgForm) {
    const { message, messageTypeId } = form.value;
    const fsMessage: MessengerHistory = {
      attachments: [],
      message,
      read: false,
      sentFrom: FirebaseMessageSource.Pharmacy,
      tenantId: this.tenantId,
      timestamp: new Date().toISOString()
    }

    this.updateCommTemplateStatistic(messageTypeId);

    this.firestoreService.sendMessage<MessengerHistory>(fsMessage, this.profileId);
    form.reset();
    this.showNotification('success', 'Message send successfully');
  }

  onMessageTypeChange(form: NgForm) {
    const { messageTypeId } = form.value;
    const { message } = this.messageTypes.find(c => c.id === messageTypeId);
    form.form.patchValue({ message: message ?? '' });
  }

  showNotification(type: string = 'danger', text: string = '') {
    this.notification.text = text;
    this.notification.type = type;
    window.setTimeout(() => {
      this.notification.text = '';
    }, 4000);
  }

  private updateCommTemplateStatistic(messageTypeId: string) {
    if(+messageTypeId > 0) {
      const { id, title } = this.messageTypes.find(c => c.id === messageTypeId);

      const updateStatRequest = {
        name: `CommTemplate - ${title}`,
        entityId: +id,
        entityName: 'CommTemplate',
      }

      this.store.dispatch(new CreateStatistic(updateStatRequest))
    }
  }
}
