import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { Store, Actions } from '@ngxs/store';
import { ClassificationAndRecommendationsState } from '../state/classification-and-recommendations.state';
import { Subscription } from 'rxjs';
import { SetClassificationAndRecommendationsProgress } from '../state/classification-and-recommendations.actions';
import { FormControlFunctions } from '../../form-control.functions';
import { ClinicalInterventionChildForm } from '../../clinical-intervention-child-form';
import { InterventionCategory } from '../../../../../shared/models/six-cpa/classification-and-recommendations-form.model';
import {
  CIRecommendationsGrouped,
  InterventionCategories,
  DrugSelectionClassifications,
  ComplianceClassifications,
  EducationClassifications,
  MonitoringClassifications,
  NonClassificableClassifications,
  DosingClassifications,
  ToxicityReactionsClassifications,
  UndertreatedClassifications
} from '../../../../../shared/models/six-cpa/FormKeyValuePairs';

import { SixCpaState } from '../../state/six-cpa.state';
@Component({
  selector: 'app-classification-and-recommendations-form',
  templateUrl: './classification-and-recommendations-form.component.html',
  styleUrls: ['./classification-and-recommendations-form.component.scss']
})

export class ClassificationAndRecommendationsFormComponent extends ClinicalInterventionChildForm implements OnInit, OnDestroy {
  constructor(private formBuilder: FormBuilder, private store: Store, actions: Actions, private formFunctions: FormControlFunctions) {
    super(actions);
    this.generateFormControls();
  }

  private readonlySubscription: Subscription;
  private formSubscription: Subscription;
  classificationAndRecommendationsForm: FormGroup;
  recommendationsGrouped = CIRecommendationsGrouped;
  interventionCategories = InterventionCategories;
  interventionClassifications = DrugSelectionClassifications;

  ngOnInit() {
    this.formSubscription = this.store.select(ClassificationAndRecommendationsState.classificationAndRecommendationsForm)
      .subscribe(() => this.onFormChange());
    this.readonlySubscription = this.store.select(SixCpaState.formsReadOnly)
      .subscribe(readonly => readonly ? this.classificationAndRecommendationsForm.disable() : this.classificationAndRecommendationsForm.enable());
  }

  ngOnDestroy() {
    this.formSubscription.unsubscribe();
    this.readonlySubscription.unsubscribe();
  }

  get formControls() {
    return this.classificationAndRecommendationsForm.controls;
  }

  protected actionOnResetForm() {
    this.setDefaults();
  }

  private setDefaults() {
    this.classificationAndRecommendationsForm.reset();
    this.resetRecommendations();
    this.classificationAndRecommendationsForm.patchValue(
      {
        interventionCategory: this.interventionCategories[0].key,
        interventionClassification: this.interventionClassifications[0].key
      });
  }

  private resetRecommendations() {
    this.classificationAndRecommendationsForm.patchValue({
      recommendations: {
        doseIncrease: false,
        doseDecrease: false,
        drugChange: false,
        drugFormulationChange: false,
        drugBrandChange: false,
        doseFrequencyScheduleChange: false,
        prescriptionNotDispensed: false,
        otherChangesToTherapy: false,
        referToPrescriber: false,
        referToHospital: false,
        referForMedicationReview: false,
        otherReferralRequired: false,
        educationOrCounsellingSession: false,
        writtenSummaryOfMedications: false,
        commenceDoseAdministrationAid: false,
        otherWrittenInformation: false,
        monitoringLaboratory: false,
        monitoringNonLaboratory: false,
        noRecommendationNecessary: false
      }
    })
  }

  private onFormChange() {
    const controls = Object.keys(this.classificationAndRecommendationsForm.controls);
    const completedItems = this.getTotalValidItems(controls);
    this.store.dispatch(new SetClassificationAndRecommendationsProgress(controls.length, completedItems));
  }

  private getTotalValidItems(controls: Array<string>) {
    return controls.filter((key) => {
      const formControl = this.classificationAndRecommendationsForm.get(key);
      return  formControl.status === 'VALID' || (formControl.status === 'DISABLED' && !formControl.invalid && formControl.errors === null);
    }).length;
  }

  private generateFormControls() {
    this.classificationAndRecommendationsForm = this.formBuilder.group({
      interventionCategory: new FormControl(this.interventionCategories[0].key),
      interventionClassification: new FormControl(this.interventionClassifications[0].key),
      recommendations: this.formBuilder.group({}),
      clinicalRecommendationsNotes: new FormControl('')
    });
    const recommendations = [].concat(...this.recommendationsGrouped.map(item => item.subcategories));
    this.formFunctions.generateControlsForGroupFromArr('recommendations', recommendations, this.classificationAndRecommendationsForm);
  }

  updateCIClassifications() {
    switch (Number(this.classificationAndRecommendationsForm.value.interventionCategory)) {
      case InterventionCategory.DrugSelection: {
        this.interventionClassifications = DrugSelectionClassifications;
        break;
      }
      case InterventionCategory.Compliance: {
        this.interventionClassifications = ComplianceClassifications;
        break;
      }
      case InterventionCategory.EducationOrInformation: {
        this.interventionClassifications = EducationClassifications;
        break;
      }
      case InterventionCategory.Monitoring: {
        this.interventionClassifications = MonitoringClassifications;
        break;
      }
      case InterventionCategory.NotClassifiable: {
        this.interventionClassifications = NonClassificableClassifications;
        break;
      }
      case InterventionCategory.OverOrUnderDose: {
        this.interventionClassifications = DosingClassifications;
        break;
      }
      case InterventionCategory.ToxicityOrAdverseReaction: {
        this.interventionClassifications = ToxicityReactionsClassifications;
        break;
      }
      case InterventionCategory.UnderTreated: {
        this.interventionClassifications = UndertreatedClassifications;
        break;
      }
    }
    this.classificationAndRecommendationsForm.value.interventionClassification = this.interventionClassifications[0].key;
  }
}
