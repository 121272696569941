<form [formGroup]="hmrLocationForm" ngxsForm="hmrLocation.hmrLocationForm" class="p-4">
  <div class="form-group pb-1">
    <label class="input-label" for="homeSuburb">Patient Home Suburb</label>
    <input type="text" [class.has-error]="formControls.homeSuburb.invalid && (showInvalid$ | async)"
      class="form-control form-control-lg w-50" formControlName="homeSuburb" id="homeSuburb">
  </div>
  <div class="form-group pb-1">
    <label class="input-label" for="residentialPostCode">Patient Home Postcode</label>
    <input type="text" [class.has-error]="formControls.residentialPostCode.invalid && (showInvalid$ | async)"
      class="form-control form-control-lg w-50" formControlName="residentialPostCode" id="residentialPostCode">
  </div>
  <div class="form-group mb-0" formGroupName="outcomesAndRecommendations"
    [class.has-error-group]="formControls.outcomesAndRecommendations.invalid && (showInvalid$ | async)">
    <label class="input-label" for="">Outcomes/Recommendations of Service</label>
    <div *ngFor="let item of outcomesAndRecommendationsForFormArr">
      <div class="form-check">
        <label class="form-check-label pl-2">
          <input class="form-check-input big-checkbox" [formControlName]="item.key" type="checkbox"
            [value]="item.value" />
          {{item.value}}</label>
      </div>
    </div>
  </div>
  <input class="form-control form-control-lg w-50 ml-4 mb-2" type="text" name="otherRecommendation"
    id="otherRecommendation" formControlName="otherRecommendation"
    [class.has-error]="formControls.otherRecommendation.invalid && hmrLocationForm.value.outcomesAndRecommendations.other && (showInvalid$ | async)"
    [attr.disabled]="!hmrLocationForm.value.outcomesAndRecommendations.other ? '' : null">
  <div class="form-group pb-1">
    <label class="input-label mb-0">T&Cs </label><br />
    <app-radio-highlight controlName="tAndCsYes" [isHighlighted]="hmrLocationForm.value.tAndCs"
      [hasError]="formControls.tAndCs.invalid && (showInvalid$ | async)">
      <input class="form-check-input" type="radio" name="tAndCs" id="tAndCsYes" formControlName="tAndCs" [value]="true"
        checked>
      <label class="form-check-label" for="tAndCsYes">Yes</label>
    </app-radio-highlight>
    <app-radio-highlight controlName="tAndCsNo" [isHighlighted]="hmrLocationForm.value.tAndCs == false"
      [hasError]="formControls.tAndCs.invalid && (showInvalid$ | async)">
      <input class="form-check-input" type="radio" name="tAndCs" id="tAndCsNo" formControlName="tAndCs" [value]="false">
      <label class="form-check-label" for="tAndCsNo">No</label>
    </app-radio-highlight>
  </div>
</form>