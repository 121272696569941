import { Medicine } from '../../../../../../shared/models/six-cpa/medicine.model';
import { SixCpaMedicationModel } from '../../../../../../shared/models/six-cpa/six-cpa-medication.model';

export enum StagedSupplyMedicationProfileActionTypes {
  SET_MEDICATION_PROFILE_SCRIPRS_ACTION = '[StagedSupplyMedicationProfile] Set Scripts',
  TOGGLE_MEDICATION_PROFILE_LIST_ITEM_ACTION = '[StagedSupplyMedicationProfile] Toggle List Item',
  TOGGLE_ALL_MEDICATION_PROFILE_LIST_ACTION = '[StagedSupplyMedicationProfile] Toggle All List Item',
  ADD_MEDICATION_MEDICATION_PROFILE_LIST_ACTION = '[StagedSupplyMedicationProfile] Add Medication To List Item',
  SELECT_MEDICATION_PROFILE_LIST_ITEM_ACTION = '[StagedSupplyMedicationProfile] Select List Item',
  TOGGLE_MEDICATION_PROFILE_ACTION = '[StagedSupplyMedicationProfile] Toggle Content',
  UPDATE = '[StagedSupplyMedicationProfile] Update Content',
  RESET = '[StagedSupplyMedicationProfile] Reset Medication Profile Ids'
}

export class SetStagedSupplyMedicationProfileScriptsAction {
  static readonly type = StagedSupplyMedicationProfileActionTypes.SET_MEDICATION_PROFILE_SCRIPRS_ACTION;
  constructor(public categorisedScripts: any[]) { }
}
export class ToggleStagedSupplyMedicationProfileListItemAction {
  static readonly type = StagedSupplyMedicationProfileActionTypes.TOGGLE_MEDICATION_PROFILE_LIST_ITEM_ACTION;
  constructor(public item: Medicine) { }
}

export class ToggleAllStagedSupplyMedicationProfileListAction {
  static readonly type = StagedSupplyMedicationProfileActionTypes.TOGGLE_ALL_MEDICATION_PROFILE_LIST_ACTION;
  constructor(public isSelectAll: boolean, public categoryId?: number) { }
}
export class AddMedicationToStagedSupplyMedicationProfileListAction {
  static readonly type = StagedSupplyMedicationProfileActionTypes.ADD_MEDICATION_MEDICATION_PROFILE_LIST_ACTION;
  constructor(public item: Medicine) { }

}
export class SelectStagedSupplyMedicationProfileListItemAction {
  static readonly type = StagedSupplyMedicationProfileActionTypes.SELECT_MEDICATION_PROFILE_LIST_ITEM_ACTION;
  constructor(public productDispensedId: string) { }
}

export class ToggleStagedSupplyMedicationProfileAction {
  static readonly type = StagedSupplyMedicationProfileActionTypes.TOGGLE_MEDICATION_PROFILE_ACTION;
  constructor(public showMode: string) { }
}

export class UpdateStagedSupplyMedicationProfileList {
  static readonly type = StagedSupplyMedicationProfileActionTypes.UPDATE;
  constructor(public medication: SixCpaMedicationModel[]) { }
}

export class ResetStagedSupplyMedicationProfileIdsAction {
  static readonly type = StagedSupplyMedicationProfileActionTypes.RESET;
}
