import { Component, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { OptOutUpdateModal, PrimaryContactSummary } from './models/primary-contact-summary.model';
import { ChangePrimaryContact, RemovePrimaryContact, TogglePrimaryContactModal } from './state/primary-contact.actions';
import { PrimaryContactState, PrimaryContactStateModel, PrimaryContactStateStatus } from './state/primary-contact.state';
import { ChangePrimaryContactRequest, PrimaryContactType } from './models/change-primary-contact-request.model';
import { PrimaryContactHelperService } from './helpers/primary-contact.helper';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ClientRepository } from '../../../modules/core/profile/client.repository';
import { ProfileState } from '../../../modules/core/profile/state/profile.state';
import { GetOptOutLookup, ProfileErrorAction } from '../../../modules/core/profile/state/profile.actions';


@Component({
  selector: 'app-primary-contact',
  templateUrl: './primary-contact.component.html',
  styleUrls: ['./primary-contact.component.scss']
})
export class PrimaryContactComponent implements OnInit {
  @Select(PrimaryContactState.state) primaryContactState$: Observable<PrimaryContactStateModel>;
  @Select(ProfileState.optOutlookup) isOptOut$: Observable<boolean>;
  showInfo = false;
  stateStatus = PrimaryContactStateStatus;
  primaryContactTypes = PrimaryContactType;
  optoutForm: FormGroup;
  optOutModalForm: OptOutUpdateModal = new OptOutUpdateModal();

  constructor(private store: Store, private pcHelper: PrimaryContactHelperService, private formBuilder: FormBuilder,
    private clientRepo: ClientRepository) { }

  ngOnInit(): void {
    this.initOptOutModelForm();
  }

  private initOptOutModelForm(): void {
    this.primaryContactState$.subscribe(res => {
      this.isOptOut$.subscribe((isOptOut) => {
        this.optoutForm = this.formBuilder.group({
          mobileNumber: new FormControl(res?.summary?.mobileNumber),
          isCreating: new FormControl(isOptOut),
        });
      })
    })
  }

  updateOptOut() {
    this.optoutForm.value.isCreating = !this.optoutForm.value.isCreating;
    this.optOutModalForm = this.optoutForm.value;

    this.clientRepo.optOutUpdate(this.optOutModalForm)
      .subscribe((res) => {
        if (this.optOutModalForm.mobileNumber) {
          this.store.dispatch(new GetOptOutLookup(this.optOutModalForm.mobileNumber));
        }
      });
  }

  getProfileForContactType(summary: PrimaryContactSummary, primaryContactType: PrimaryContactType) {
    switch (primaryContactType) {
      case PrimaryContactType.SMS: {
        const contact = summary.contacts.filter(contact => contact.isSMSPrimaryContact);
        return contact[0].clientId;
      }
      case PrimaryContactType.SMS: {
        const contact = summary.contacts.filter(contact => contact.isEmailPrimaryContact);
        return contact[0].clientId;
      }
      default: {
        const contact = summary.contacts.filter(contact => contact.isSMSPrimaryContact);
        return contact[0].clientId;
      }
    }
  }

  closeModal() {
    this.store.dispatch(new TogglePrimaryContactModal(false))
  }

  toggleInfoBox(show: boolean) {
    this.showInfo = show;
  }

  hasPrimaryContacts(summary: PrimaryContactSummary) {
    return this.pcHelper.hasPrimaryContacts(summary);
  }

  hasSMSPrimary(summary: PrimaryContactSummary) {
    return this.pcHelper.hasPrimarySMSContact(summary);
  }

  hasEmailPrimary(summary: PrimaryContactSummary) {
    return this.pcHelper.hasPrimaryEmailContact(summary);
  }

  getSMSPrimary(summary: PrimaryContactSummary) {
    return this.pcHelper.getSMSPrimary(summary);
  }

  getEmailPrimary(summary: PrimaryContactSummary) {
    return this.pcHelper.getEmailPrimary(summary);
  }

  getSMSPrimaryName(summary: PrimaryContactSummary) {
    return this.pcHelper.getSMSPrimaryName(summary);
  }

  getEmailPrimaryName(summary: PrimaryContactSummary) {
    return this.pcHelper.getEmailPrimaryName(summary);
  }

  currentPatientIsPrimary(summary: PrimaryContactSummary) {
    return this.pcHelper.currentPatientIsPrimary(summary);
  }

  notNullOrEmpty(content: string) {
    return content != null && content != "" && content != "null";
  }

  removeAsSMSPrimary() {
    const summary = this.store.selectSnapshot(PrimaryContactState.summary);
    this.store.dispatch(new RemovePrimaryContact(summary.tenantCustomerId, PrimaryContactType.SMS));
  }

  makeSMSPrimary() {
    const request = this.buildChangeRequest(PrimaryContactType.SMS);
    this.store.dispatch(new ChangePrimaryContact(request));
  }

  removeAsEmailPrimary() {
    const summary = this.store.selectSnapshot(PrimaryContactState.summary);
    this.store.dispatch(new RemovePrimaryContact(summary.tenantCustomerId, PrimaryContactType.Email));
  }

  makeEmailPrimary() {
    const request = this.buildChangeRequest(PrimaryContactType.Email);
    this.store.dispatch(new ChangePrimaryContact(request));
  }

  buildChangeRequest(type: PrimaryContactType): ChangePrimaryContactRequest {
    const summary = this.store.selectSnapshot(PrimaryContactState.summary);

    const request: ChangePrimaryContactRequest = {
      newContactTenantCustomerId: summary.tenantCustomerId,
      linkedTenantCustomerIds: this.extractLinkedTenantCustomerIds(summary),
      primaryContactType: type
    }

    return request;
  }

  private extractLinkedTenantCustomerIds(summary: PrimaryContactSummary): number[] {
    if (!summary.contacts || !summary.contacts.length) {
      return null;
    }

    return summary.contacts
      .filter(contact => contact.tenantCustomerId != null && contact.tenantCustomerId != summary.tenantCustomerId)
      .map(contact => contact.tenantCustomerId)
  }
}
