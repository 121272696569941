import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Select } from '@ngxs/store';
import { HMRFormState, HMRFormType } from '../hmr-form-list/state/hmr-form.state';
import { HMRServiceProviderDetailsFormState, HMRServiceProviderDetailsFormStateModel } from '../hmr-service-provider-details/state/hmr-service-provider-details-form.state';

@Component({
  selector: 'app-hmr-service-provider-details-list-item',
  templateUrl: './hmr-service-provider-details-list-item.component.html'
})
export class HMRServiceProviderDetailsListItemComponent implements OnInit {
  @Select(HMRFormState.hmrFormType) hmrFormType$: Observable<HMRFormType>;
  @Select(HMRServiceProviderDetailsFormState) formState$: Observable<HMRServiceProviderDetailsFormStateModel>;
  hmrFormTypes = HMRFormType;

  ngOnInit() {
  }

}
