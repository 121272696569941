<div class="modal send-document-modal">
  <div class="modal-body border__grey">
    <div>
      <div class="title-container">
        <h3 class="p-2">{{title}}</h3>
        <span class="co-md-1 pointer cancel-button" (click)="cancel()"><i class="fas fa-times font__title-5"></i></span>
      </div>
      <hr />
      <div>
        <p>
          {{message}}
        </p>
      </div>
      <div g-xs="mt2">
        <button class="button btn__green" g-xs="mr1 my1" (click)="submit()">{{submitText}}</button>
        <button class="button btn__yellow" (click)="close()">{{closeText}}</button>
      </div>
    </div>
  </div>
</div>
<div class="modal-background"></div>
