import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss']
})
export class MessageComponent implements OnInit {

  @Input() fromCustomer: boolean;
  @Input() fromPharmacy: boolean;
  @Input() isSystem: boolean;
  type = '';

  constructor() { }

  ngOnInit(): void {
    this.type = this.fromCustomer ? 'client' : 'pharmacy';
    if (this.fromPharmacy && this.isSystem) {
      this.type = 'system';
    }
  }

  urlify(text) {
    if(!text) {
      return text;
    }

    var urlRegex = /(https?:\/\/[^\s]+)/g;
    return text.replace(urlRegex, function(url) {
      return `<a target="_blank" href="${url}">${url}</a>`;
    });
  }

}
