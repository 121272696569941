import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';
import { SSPatientDetailsFormDetailedState } from './state/ss-patient-details-form-detailed.state';
import { SSPatientDetailsFormState } from './state/ss-patient-details-form.state';
import { TenantDatePipe } from '../../../../core/timezone/pipes/tenant-date.pipe';
import { SSPatientDetailsFormBasicState } from './state/ss-patient-details-form-basic.state';

@NgModule({
  imports: [
    NgxsModule.forFeature([
      SSPatientDetailsFormState,
      SSPatientDetailsFormDetailedState,
      SSPatientDetailsFormBasicState
    ]),
  ],
  providers: [
    TenantDatePipe
  ]
})
export class SSPatientDetailsStateModule { }
