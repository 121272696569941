import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { OrderStatus } from '../../models/orderStatus.model';
import { OrderAction } from '../../models/orderAction.enum';
import { Store, Select } from '@ngxs/store';
import { OrderRequestState, OrderRequestStateModel } from '../state/order.state';
import { FeaturesState } from '../../../../modules/core/features/features.state';
import { OrderRequest } from '../models/order-request.model';
import { OrderRequestStatus, OrderStatusFilter } from '../models/order.enums';
import { OrderRequestSortConstants } from '../models/order-request.constants';
import { ProfileState } from '../../../core/profile/state/profile.state';
import { withLatestFrom } from 'rxjs/operators';
import { GetOrderList, SetOrderPagination, ToggleOrderStatusModal, UpdateOrderAction } from '../state/order.actions';
import { sortIntoCurrentAndPast } from './order-main-sort';
import { Router } from '@angular/router';
import { HumanizePipe } from '../../../../shared/pipes/humanize.pipe';

@Component({
  selector: 'app-order-main',
  templateUrl: './order-main.component.html',
  styleUrls: ['./order-main.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class OrderMainComponent implements OnInit {
  @Select(OrderRequestState) order$: Observable<OrderRequestStateModel>;
  @Select(OrderRequestState.orderItems) ordersItems$: Observable<OrderRequest[]>;
  @Select(FeaturesState.getFeatures) features$: Observable<any>;
  orderStatus = OrderStatus;
  orderAction = OrderAction;
  orderDetailsOpen = false;
  selectedOrder: any;
  orders: any;
  pageSizes = [
    5,
    10,
    25,
    50,
    100
  ];
  pageSize = 10;
  sortOrder = OrderRequestSortConstants.requestedDateDescending;
  sortOrderConstants = OrderRequestSortConstants;
  currentPage$ =  new BehaviorSubject<number>(0);
  pageSize$ =  new BehaviorSubject<number>(10);
  dataOnPage$ = new BehaviorSubject<OrderRequest[]>([]);
  totalOrders$ =  new BehaviorSubject<number>(0);
  sortOrder$ =  new BehaviorSubject<string>(OrderRequestSortConstants.requestedDateDescending);
  clientId: number;
  profile: any;
  orderFilter = OrderStatusFilter.All;
  orderFilterStatuses = OrderStatusFilter;

  constructor(
    private store: Store,
    private router: Router,
    private humanizePipe: HumanizePipe
  ) { }

  ngOnInit() {
    this.profile = this.store.selectSnapshot(ProfileState.profile);
    const pagination = this.store.selectSnapshot(OrderRequestState.pagination);
    this.currentPage$.next(pagination.currentPage);
    this.sortOrder$.next(pagination.sortOrder);
    this.pageSize$.next(pagination.pageSize);
    this.sortOrder = pagination.sortOrder;
    this.pageSize = pagination.pageSize;
    this.clientId = this.profile.customerProfile.customerProfileId;
    combineLatest([this.currentPage$, this.pageSize$, this.sortOrder$])
      .subscribe(([currentPage, pageSize, sortOrder]) => {
        this.store.dispatch([new GetOrderList(
          this.clientId,
          {
            page: currentPage,
            pageSize: pageSize,
            sort: sortOrder
          }
          ),
        new SetOrderPagination({
            currentPage: currentPage,
            pageSize: pageSize,
            sortOrder: sortOrder
        })]);
      });
      this.ordersItems$
      .pipe(withLatestFrom(this.currentPage$))
      .subscribe(([orders, page]) => {
          if (!orders) {return;}
          const currentAndPastOrders = sortIntoCurrentAndPast(orders, page);
          this.totalOrders$.next(currentAndPastOrders.length);
          this.dataOnPage$.next(<any>currentAndPastOrders);
      });
  }

  orderIsAllowedByFilter(orderStatus: OrderRequestStatus)
  {
    switch(this.orderFilter)
    {
      case OrderStatusFilter.Current:
        return (orderStatus === OrderRequestStatus.Pending || orderStatus === OrderRequestStatus.PartiallyReady);
      case OrderStatusFilter.History:
        return (orderStatus !== OrderRequestStatus.Pending && orderStatus !== OrderRequestStatus.PartiallyReady);
    }
    return true;
  }

  changePageSize() {
    this.pageSize$.next(this.pageSize);
  }

  changeSortOrder() {
    this.sortOrder$.next(this.sortOrder);
  }


  formatDate = (date: string) => {
    return this.humanizePipe.transform(date);
  }

  changeOrderMethod(event) {
    this.store.dispatch(new UpdateOrderAction(event, null, null, false));
  }

  closeModal(order) {
    this.store.dispatch(new ToggleOrderStatusModal(false, order, null));
  }

  openUpdateStatusModal(order, status) {
    this.store.dispatch(new ToggleOrderStatusModal(true, order, status));
  }

  updateOrder(update) {
    this.store.dispatch(new UpdateOrderAction(update.order, update.message, update.channel, update.sendMessage));
  }

  navToComms() {
    this.router.navigate(this.createOutletSettings(
      ['profile', this.profile.clientId, 'comms', 'sms'])
      );
  }

  private createOutletSettings(routes: string[]): any {
    return [
      '',
      {
        outlets: {
          patient: ['patient', ...routes],
        },
      },
    ];
  }


}
