import { Component, OnInit, OnDestroy } from '@angular/core';
import { TenantUserService } from '../../shared/services/tenantUser.service';
import { Subscription } from 'rxjs/Subscription';
import { combineLatest } from 'rxjs/observable/combineLatest';
import { TenantUser } from '../../shared/models/tenantUser.model';
import { Tenant } from '../../shared/models/tenant.model';
import { TenantService } from '../../shared/services/tenant.service';
import { ConnectionType } from '../../shared/models/connectionType.model';
import { UserService } from '../../shared/services/user.service';
import { User } from '../../shared/models/user.model';
import { Notification } from '../../shared/models/notification.model';

@Component({
  selector: 'app-tenant-user-page',
  templateUrl: './tenant-user-page.component.html',
  styleUrls: ['./tenant-user-page.component.scss']
})
export class TenantUserPageComponent implements OnInit, OnDestroy {
  constructor(private tenantUserService: TenantUserService, private tenantService: TenantService, private userService: UserService) { }

  isLoaded = false;
  isUpdateFormVisible = false;
  notification: Notification;

  sub1: Subscription;
  sub2: Subscription;

  tenantList: Tenant[] = [];
  userList: User[] = [];
  tenantUserList: TenantUser[] = [];
  currentItem: TenantUser;
  connectionTypeList: ConnectionType[] = [];
  userFilter = '';
  tenantFilter = '';
  fullUserList = [];
  fullTenantList = [];
  showTenantSection = true;
  showUserSection = true;

  ngOnInit() {
    this.notification = new Notification('danger', '');

    this.resetCurrentItem();
    this.sub1 = combineLatest([
      this.tenantService.getList(),
      this.userService.getUserList(),
      this.tenantUserService.getList(),
      this.tenantUserService.getConnectionTypes()
    ]).subscribe(
      (data: [Tenant[], User[], TenantUser[], ConnectionType[]]) => {
        this.tenantList = data[0];
        this.userList = data[1];
        this.tenantUserList = data[2];
        this.fullUserList = data[2];
        this.connectionTypeList = data[3];
        this.fullTenantList = data[0];
        this.isLoaded = true;
      },
      error => {
        if (error.status === 403) {
          this.notification.text = error.json().message;
        }
      }
    );
  }

  userFilterChanged() {
    if (this.userFilter != '' && this.userFilter != null && this.userFilter.length) {
      this.tenantUserList = this.fullUserList.filter(x => !!x.email && x.email.includes(this.userFilter));
    }
    else {
      this.tenantUserList = this.fullUserList;
    }
  }

  tenantFilterChanged() {
    if (this.tenantFilter != '' && this.tenantFilter != null && this.tenantFilter.length) {
      this.tenantList = this.fullTenantList.filter(x => x.name.toLowerCase().includes(this.tenantFilter.toLowerCase()));
    }
    else {
      this.tenantList = this.fullTenantList;
    }
  }

  toggleTenantSection() {
    this.showTenantSection = !this.showTenantSection;
  }

  toggleUserSection() {
    this.showUserSection = !this.showUserSection;
  }

  openUpdateForm(data?: TenantUser) {
    if (data) {
      this.currentItem = data;
    }
    this.isUpdateFormVisible = true;
  }

  updateList(data: any) {
    const { item, mode } = data;
    if (mode === 'create') {
      this.tenantUserList.push(item);
    } else {
      const index = this.tenantUserList.findIndex(c => c.id === item.id);
      this.tenantUserList[index] = item;
    }
    this.resetCurrentItem();
  }

  updateTenantList(data: any) {
    const { item, mode } = data;
    if (mode === 'create') {
      this.tenantList.push(item);
    } else if (mode === 'delete') {
      const index = this.tenantList.findIndex(c => c.id === item.id);
      if (index !== -1) {
        this.tenantList.splice(index, 1);
      }
    } else {
      const index = this.tenantList.findIndex(c => c.id === item.id);
      this.tenantList[index] = item;
    }
  }

  resetCurrentItem() {
    this.currentItem = new TenantUser(null, null, null, null, undefined);
  }

  onUpdateFormCancel() {
    this.isUpdateFormVisible = false;
    this.resetCurrentItem();
  }

  remove(id: number) {
    this.sub2 = this.tenantUserService.removeTenantUser(id).subscribe(
      data => {
        const index = this.tenantUserList.findIndex(c => c.id === id);
        if (index !== -1) {
          this.tenantUserList.splice(index, 1);
        }
      }
    );
  }

  ngOnDestroy() {
    if (this.sub1) { this.sub1.unsubscribe(); }
    if (this.sub2) { this.sub2.unsubscribe(); }
  }
}
