import { Store } from '@ngxs/store';
import { AuthState } from '~/modules/core/auth/state/auth.state';
import { Injectable } from '@angular/core';

@Injectable()
export class CustomerAuth {
  public constructor( private store: Store) {

  }

  urlForEncryptedTenantCustomer(url: string) {
    return url + '?customer=' + this.getEncryptedTenantCustomer();
  }

  getEncryptedTenantCustomer() {
    return encodeURIComponent(decodeURIComponent(this.store.selectSnapshot(AuthState.encryptedTenantCustomerId)));
  }
}
