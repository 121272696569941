import { Component, OnDestroy, ChangeDetectorRef, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { MedsCheckFormListState, MedsCheckType } from './state/meds-check-form-list.state';
import { Store, Select } from '@ngxs/store';
import { Subscription, Observable, forkJoin } from 'rxjs';
import {
  ResetMedsCheckFormListAction,
  SetMedsCheckTypeAction,
  SubmitNewMedsCheck,
  SubmitExisitingMedsCheck,
  OpenMedsCheckConfirmFormAction,
  ShowInvalidMedsCheckForm,
  ToggleMedsCheckSaving
} from './state/meds-check-form-list.actions';
import { MedsCheckFormsService } from './state/meds-check-forms.service';
import { ProfileState } from '../../../core/profile/state/profile.state';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { withLatestFrom, map, switchMap, filter } from 'rxjs/operators';
import { MedsCheckFormsProgressService } from './state/meds-check-forms-progress.service';
import { OpenResetFormModal, CloseResetFormModal, ToggleSixCpaContentAction } from '../state/six-cpa.actions';
import { SixCpaState } from '../state/six-cpa.state';
import { PatientDetailsFormDetailedState } from '../patient-details-form/state/patient-details-form-detailed.state';
import { WindowService } from '../../../../shared/services/window.service';
// import { environment } from '../../../../../environments/environment';
import { SaveOrUpdateMedsCheckAction } from './state/meds-check-save.actions';
import { GetPatientReportConfirmMedsCheckAction, SetupMedicationsConfirmMedsCheck, ToggleConfirmMedsCheckAction } from '../confirm-meds-check/state/confirm-meds-check.actions';
import { ClosePdfFormModal, OpenPdfFormModal } from '../state/six-cpa.actions';
import { MedsCheckMedicationProfileState } from '../meds-check-medication-profile/state/meds-check-medication-profile.state';
import { SixCpaMedicationModel } from '../../../../shared/models/six-cpa/six-cpa-medication.model';
import { ActionPlanFormState } from '../action-plan-form/state/action-plan-form.state';
import { SixCpaActionPlanForm } from '../../../../shared/models/six-cpa/action-plan.model';

@Component({
  selector: 'app-meds-check',
  templateUrl: './meds-check.component.html',
  styleUrls: ['./meds-check.component.scss']
})
export class MedsCheckComponent implements OnInit, OnDestroy {
  @Select(MedsCheckFormListState.medsCheckType) medsCheckType$: Observable<MedsCheckType>;
  @Select(MedsCheckFormListState.saving) saving$: Observable<boolean>;
  @Select(MedsCheckFormListState.showConfirmForm) showConfirmForm$: Observable<boolean>;
  @Select(SixCpaState) sixCpaState$: Observable<any>;
  @Select(MedsCheckMedicationProfileState.selectedMedicineCount) medicineCount$: Observable<number>;
  @Select(ActionPlanFormState.actionPlanForm) actionPlanForm$: Observable<any>;

  @Select(PatientDetailsFormDetailedState.patientHasDiabetes) patientHasDiabetes$: Observable<boolean>;
  medsCheckFormList: FormGroup;
  formSubscription: Subscription;
  medsCheckTypes = MedsCheckType;
  private readonlySubscription: Subscription;
  private actionPlanFormSubscription: Subscription;

  @Dispatch()
  submitPatientRegistration = () => {
    if (this.store.selectSnapshot(MedsCheckFormsProgressService.getProgressDetailed) !== 100) {
      this.store.dispatch(new ShowInvalidMedsCheckForm());
    }
    return this.store.selectOnce(MedsCheckFormsProgressService.getProgressDetailed).pipe(
      filter(progress => progress === 100),
      switchMap(() => forkJoin([
        this.store.selectOnce(MedsCheckFormsService.getMedsCheckRegistrationRequest),
        this.store.selectOnce(ProfileState.clientId)
      ])),
      map(([formData, clientId]) =>
        formData && formData.sixCpaFormId !== null
          ? new SubmitExisitingMedsCheck(clientId, formData)
          : new SubmitNewMedsCheck(clientId, formData)))
    }
  @Dispatch()
  confirmMedsCheck = () => {
    if (this.store.selectSnapshot(MedsCheckFormsProgressService.getProgressBasic) !== 100) {
      this.store.dispatch(new ShowInvalidMedsCheckForm());
    }
    return this.store.selectOnce(MedsCheckFormsProgressService.getProgressBasic).pipe(
      filter(progress => progress === 100),
      map(() => new OpenMedsCheckConfirmFormAction()))
    }

  @Dispatch()
  saveMedsCheck = () => [new SaveOrUpdateMedsCheckAction(), new ClosePdfFormModal()];

  @Dispatch()
  resetMedsCheck = () => new ResetMedsCheckFormListAction()

  @Dispatch()
  viewConfirmation = () => [new ToggleSixCpaContentAction('out'), new ToggleConfirmMedsCheckAction('in')];

  constructor(
    private formBuilder: FormBuilder,
    private store: Store,
    private cdr: ChangeDetectorRef,
    private windowService: WindowService
  ) {
  }

  ngOnInit() {
    this.initForm();
    this.initFormChangesSubscription();
    this.initReadonlySubscription();
  }

  ngOnDestroy() {
    if (this.formSubscription) {
      this.formSubscription.unsubscribe();
    }
    if (this.readonlySubscription) {
      this.readonlySubscription.unsubscribe();
    }
    if (this.actionPlanFormSubscription) {
      this.actionPlanFormSubscription.unsubscribe();
    }
  }

  previewServiceDocument() {
    this.store.dispatch(new ToggleMedsCheckSaving(true))
    const clientId = this.store.selectSnapshot(ProfileState.clientId);
    const meds = this.store.selectSnapshot(MedsCheckMedicationProfileState.selectedMedicines);

    this.actionPlanFormSubscription = this.actionPlanForm$.subscribe( actionPlan => {
      var actionPlans = {
        hasActions : actionPlan.hasActions,
        sixCpaActionPlanItems: actionPlan.actions
      } as SixCpaActionPlanForm;

      this.store.dispatch([
        new SetupMedicationsConfirmMedsCheck(meds as SixCpaMedicationModel[], actionPlans),
        new GetPatientReportConfirmMedsCheckAction(clientId, '')
      ]).subscribe(() => this.store.dispatch(new ToggleMedsCheckSaving(false)));
    });

  }

  openPdfModal() {
    this.store.dispatch(new OpenPdfFormModal());
  }

  openConfirmResetModal() {
    this.store.dispatch(new OpenResetFormModal());
  }

  closeConfirmResetModal() {
    this.store.dispatch(new CloseResetFormModal());
  }

  private initForm() {
    this.medsCheckFormList = this.formBuilder.group({
      medsCheckType: new FormControl(MedsCheckType.Regular, Validators.required)
    });
  }

  private initFormChangesSubscription() {
    this.formSubscription = this.medsCheckFormList.get('medsCheckType').valueChanges.pipe(
      switchMap((medsCheckType) => this.store.dispatch(new SetMedsCheckTypeAction(medsCheckType)).pipe(
        withLatestFrom(this.store.select(MedsCheckFormListState.medsCheckType)))))
      .subscribe(() => { this.cdr.detectChanges(); });
  }

  private initReadonlySubscription() {
    this.readonlySubscription = this.store.select(SixCpaState.formsReadOnly)
      .subscribe(readonly => readonly ? this.medsCheckFormList.disable() : this.medsCheckFormList.enable());
  }

  openPPAPortal() {
    // this.windowService.open(environment.ppaPortalLink);
    this.windowService.open('');
  }
}
