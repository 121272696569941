<div *ngIf="profile$ | async as profile">
  <div class="registration-header d-flex py-4">
    <h3 class="font__bold">MySL Registration</h3>
    <i class="far fa-times fa-2x" (click)="closeModal()"></i>
  </div>
  <div class="patient-details mt-2">
    <div *ngIf="!aslService.validatedIHI(profile) || !aslService.validatedMedicareOrDva(profile)" class="ihi-details my-3 p-4">
      <ng-container *ngIf="!aslService.validatedIHI(profile); else noMedicareOrDva">
        <div class="ihi-warning-icon mb-2"><i class="fas fa-exclamation-triangle mr-2"></i>No IHI number found</div>
        <div>You can't register with MySL without a patient's IHI number. Make sure you've got a valid IHI number in your dispense profile before continuing.</div>
      </ng-container>
      <ng-template #noMedicareOrDva>
        <div class="ihi-warning-icon mb-2"><i class="fas fa-exclamation-triangle mr-2"></i>No Medicare or DVA Number found</div>
        <div>You can't register with MySL without either a Medicare or DVA number. Make sure you've got either details in your dispense profile before continuing.</div>
      </ng-template>
    </div>
    <h4 class="mb-3">Patient Details</h4>
    <div class="static-details p-4">
      <i class="fas fa-lock-alt fa-lg tool-lock" placement="bottom" tooltip="To make changes to these patient details go to your dispense system"></i>
      <div class="row mb-3">
        <div class="col">
          <div class="static-heading">
            IHI number
          </div>
          <div *ngIf="aslService.validatedIHI(profile)" class="static-content">
            <span class="mr-4">{{ profile.patient.ihiNumber }}</span>
            <span class="ihi-success"><i class="fas fa-check mr-2"></i>IHI verified</span>
          </div>
          <div *ngIf="!aslService.validatedIHI(profile)" class="static-content">
            <i class="fas fa-exclamation-triangle mr-1 ihi-warning-icon"></i>
            No IHI number found
          </div>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-3">
          <div class="static-heading">Gender</div>
          <div class="static-content">{{ profile.patient.gender }}</div>
        </div>
        <div class="col-3">
          <div class="static-heading">Given name</div>
          <div class="static-content">{{ profile.patient.firstname }}</div>
        </div>
        <div class="col-3">
          <div class="static-heading">Family name</div>
          <div class="static-content">{{ profile.patient.surname }}</div>
        </div>
        <div class="col-3">
          <div class="static-heading">Date of birth</div>
          <div class="static-content">{{ profile.patient.birthDate ? (profile.patient.birthDate | tenantDate) : "N/A" }}</div>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col">
          <div class="static-heading">Address</div>
          <div class="static-content">
            {{ profile.patient.homeAddress ? profile.patient.homeAddress + ', ' : '' }}{{ profile.patient.homeSuburb ? profile.patient.homeSuburb + ', ' : '' }}
            {{ profile.patient.homeState ? profile.patient.homeState + ', ' : '' }}{{ profile.patient.homePostcode ? profile.patient.homePostcode : '' }}
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-3" *ngIf="profile.patient.medicareNo">
          <div class="static-heading">Medicare Number</div>
          <div class="static-content">{{ profile.patient.medicareNo }}</div>
        </div>
        <div class="col-3" *ngIf="profile.patient.repatNo">
          <div class="static-heading">DVA Number</div>
          <div class="static-content">{{ profile.patient.repatNo }}</div>
        </div>
      </div>
    </div>
  </div>
  <form *ngIf="aslService.validatedIHI(profile) && aslService.validatedMedicareOrDva(profile)" [formGroup]="patientForm">
    <div class="asl-register-form mt-3">
      <div class="mt-3 mb-3 justify-content-between">
        <label class="label register-label">
          Contact Details<span class="text-danger ml-2">*</span>
        </label>
        <div class="d-flex">
          <div class="form-group mr-1 w-75">
            <select name="channelSelect" class="form-control" (change)="updateChannel($event.target)">
              <option [selected]="channelForInviteSelect === 'mobileNumber'" value="mobileNumber">Mobile Number</option>
              <option [selected]="channelForInviteSelect === 'email'" value="email">Email</option>
            </select>
          </div>
          <div class="form-group w-100 ml-1" *ngIf="channelForInviteSelect === 'mobileNumber'">
            <input
              class="form-control"
              formControlName="mobileNumber"
              placeholder="Mobile or phone number"
            />
            <div *ngIf="patientForm.controls['mobileNumber'].invalid && (patientForm.controls['mobileNumber'].dirty || patientForm.controls['mobileNumber'].touched)" class="alert">
              <div *ngIf="patientForm.controls['mobileNumber'].errors.pattern">
                Please enter a 10 digit mobile number
              </div>
            </div>
          </div>
          <div class="form-group w-100 ml-1" *ngIf="channelForInviteSelect === 'email'">
            <input
              class="form-control"
              formControlName="email"
              placeholder="name@email.com"
            />
          </div>
        </div>
      </div>
      <div class="form-group mb-4">
        <div class="form-check">
          <input
            class="form-check-input"
            type="checkbox"
            formControlName="mySLConfirm"
          />
          <span class="radio-labels">Patient consents to adding their details to the ASL</span>
        </div>
      </div>
    </div>

    <!-- CARERS DETAILS SECTION -->
    <h4 class="my-3 font__bold">Carers details</h4>
    <div class="related-persons-info p-3 d-flex mb-4">
      <div class="mr-4">
        <i class="far fa-info-circle fa-lg"></i>
      </div>
      <div>
        A family member, friend, nominated person of support or organisation who has the same ASL rights and access as the patient.
      </div>
    </div>
    <div *ngFor="let relatedPersonForm of carerForms.controls; let i = index" formArrayName="carers">
      <app-asl-related-persons-form
        [relatedPersonForm]="relatedPersonForm"
        [id]="i"
        (removeClickEvent)="removeRelatedPersons(carerForms, $event)"
        (updatePrimaryContactEvent)="updatePrimaryContact($event)"
      ></app-asl-related-persons-form>
    </div>
    <button class="lite-btn btn__white mb-5" (click)="addNewRelatedPerson(carerForms)">
      <i class="far fa-plus fa-lg mr-2"></i>
      Add another carer
    </button>

    <!-- AGENT DETAILS SECTION -->
    <h4 class="mb-3 font__bold">Agent details</h4>
    <div class="related-persons-info p-3 d-flex mb-4">
      <div class="mr-4">
        <i class="far fa-info-circle fa-lg"></i>
      </div>
      <div>
        Someone authorised to collect a medication on behalf of the patient.
      </div>
    </div>
    <div *ngFor="let relatedPersonForm of agentForms.controls; let i = index" formArrayName="agents">
      <app-asl-related-persons-form
        [relatedPersonForm]="relatedPersonForm"
        [id]="i"
        [isAgent]="true"
        (removeClickEvent)="removeRelatedPersons(agentForms, $event)"
        (updatePrimaryContactEvent)="updatePrimaryContact($event)"
      ></app-asl-related-persons-form>
    </div>
    <button class="lite-btn btn__white" (click)="addNewRelatedPerson(agentForms)">
      <i class="far fa-plus fa-lg mr-2"></i>
      Add another agent
    </button>
  </form>

  <hr />
  <p *ngIf="error$ | async as error" class="text-danger">{{ error }}</p>
  <ng-container *ngIf="!(isSubmitting$ | async); else loading">
    <button
      *ngIf="aslService.validatedIHI(profile) && aslService.validatedMedicareOrDva(profile)"
      [class.button-disabled]="formDisabled"
      [disabled]="formDisabled"
      class="lite-btn btn__yellow mr-4"
      (click)="submit()"
    >
      Request Consent
    </button>
    <button
      class="lite-btn btn__white"
      (click)="closeModal()"
    >
      {{ aslService.validatedIHI(profile) && aslService.validatedMedicareOrDva(profile) ? 'Cancel' : 'Close' }}
    </button>
  </ng-container>
  <ng-template #loading>
    <app-loader></app-loader>
  </ng-template>
</div>
