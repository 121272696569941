import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScriptService } from '../../../shared/services/script.service';
import { NgxsModule } from '@ngxs/store';
import { OutOfStoreState } from './state/out-of-store.state';
import { OutOfStoreComponent } from './out-of-store.component';
import { FormsModule } from '@angular/forms';
import { LoaderModule } from '../../core/loader/loader.module';
import { TypeaheadModule } from 'ngx-bootstrap';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { StockModule } from '../stock';
import { OutOfStoreService } from './out-of-store.service';
import { TenantCategoryModule } from '../tenant-categories/tenant-categories.module';
import { PassiveScriptModule } from '../../core/passive-scripts/passive-scripts.module';
import { ProfileModule } from '../../core/profile/profile.module';
@NgModule({
  declarations: [
    OutOfStoreComponent
  ],
  providers: [
    ScriptService,
    OutOfStoreService
  ],
  imports: [
    ProfileModule,
    LoaderModule,
    FormsModule,
    CommonModule,
    StockModule,
    TenantCategoryModule,
    PassiveScriptModule,
    BsDatepickerModule.forRoot(),
    TypeaheadModule.forRoot(),
    NgxsModule.forFeature([OutOfStoreState])
  ],
  exports: [
    OutOfStoreComponent
  ]
})
export class OutOfStoreModule { }
