<div class="pdf-download pointer" (click)="downloadReport()">
  <div class="mb-1">
    <div class="pdf-download__body d-inline-flex">
      <div class="pdf-download__icon p-4">
        <i class="fal fa-file-pdf font__title-6"></i>
      </div>
      <div class="pdf-download__title p-4">
        <h4 class="font__bold">{{name}}</h4>
        <div class="font__title-1">.pdf</div>
      </div>
    </div>
  </div>
  <div class="pdf-download__link font__title-1 font__underline">Download & Print</div>
</div>