import { Component, OnInit } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { SignUpState } from '../../state/sign-up.state';

@Component({
  selector: 'app-sign-up-route',
  templateUrl: './sign-up-route.component.html',
  styleUrls: ['./sign-up-route.component.scss']
})
export class SignUpRouteComponent implements OnInit {
  @Select(SignUpState.validating) validating$: Observable<boolean>;

  constructor() { }

  ngOnInit() {
  }

}
