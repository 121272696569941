<ng-container *ngVar="(error$ | async) as error">
  <div class="sign-up">
    <div class="container d-md-flex justify-content-between">
      <div>
        <div class="container" *ngIf="!error || error.type == 0">
          <div class="icon-container">
            <i class="fas fa-exclamation-triangle large-icon"></i>
            <div>Uh oh!</div>
          </div>
          <p>
            Looks like there's something wrong with your link, please check to ensure part of the link is not missing or
            contact your pharmacy for assistance.
          </p>
        </div>
        <div class="container" *ngIf="error && error.type != 0">
          <div class="icon-container">
            <i class="fal fa-history large-icon"></i>
            <div>Uh oh!</div>
          </div>
          <p>
            {{error.error}}
          </p>
        </div>
      </div>
      <div class="img-content">
      </div>
    </div>
  </div>
</ng-container>