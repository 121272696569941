import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PatientSignatureFormListItemComponent } from './patient-signature-form-list-item/patient-signature-form-list-item.component';
import { SixCpaFormListItemChecklistModule } from '../six-cpa-form-list-item-checklist/six-cpa-form-list-item-checklist.module';
import { PatientSignatureFormComponent } from './patient-signature-form/patient-signature-form.component';
import { DownloadPdfModule } from '../../../core/download-pdf/download-pdf.module';

@NgModule({
  declarations: [PatientSignatureFormListItemComponent, PatientSignatureFormComponent],
  imports: [
    CommonModule,
    SixCpaFormListItemChecklistModule,
    DownloadPdfModule
  ],
  exports: [PatientSignatureFormListItemComponent]
})
export class PatientSignatureModule { }
