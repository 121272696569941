import { Component, OnDestroy, Input, Output, EventEmitter, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Notification } from '~/shared/models/notification.model';
import { MessageType } from '~/shared/models/messageType.model';
import { Message, ScheduledMessage, CommunicationType } from '~/shared/models/message.model';
import { SmsService } from '~/shared/services/sms.service';
import { Subscription } from 'rxjs/Subscription';
import { SmsMessageHistory } from '~/shared/models/messageHistory.model';
import * as moment from 'moment';
import { Store } from '@ngxs/store';
import { AddScheduledMessage } from '../../scheduled-messages/state/scheduled-messages.actions';
import { GetClientNotes } from '~/modules/profile/notes/state/notes.actions';
import { CharacterCountService } from '~/shared/helpers/character-count.service';
import { CreateStatistic } from '~/shared/state/statistics/statistics.actions';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { MessageTemplate } from '../../state/comms.state';

@Component({
  selector: 'app-add-sms',
  templateUrl: './add-sms.component.html',
  styleUrls: ['./add-sms.component.scss']
})
export class AddSmsComponent implements OnDestroy {

  constructor(
    private smsService: SmsService,
    private store: Store,
    private characterCountService: CharacterCountService,
    private notificationService: NzNotificationService
  ) { }

  @Input() clientId: string;
  @Input() hasMobileNumber: boolean;
  @Input() templates: MessageTemplate[];
  @Output() messageSentEvent = new EventEmitter<SmsMessageHistory>();
  @ViewChild('f') form: NgForm;
  
  sub1: Subscription;
  sub2: Subscription;
  notification: Notification;
  messageTypes: MessageType[] = [];
  showScheduleSMS = false;
  defaultDate: Date;
  defaultTime: Date;
  toastDuration: number = 4000;

  ngOnDestroy() {
    if (this.sub1) { this.sub1.unsubscribe(); }
    if (this.sub2) { this.sub2.unsubscribe(); }
  }


  disabledDates(current: Date): boolean {
    const minDate = moment().add(-1, 'days');
    const maxDate = moment().add(12, 'months');
    return !moment(current).isBetween(minDate, maxDate);
  }

  getCounts(message: string) {
    let charCount = "0";
    let msgCount = "0";
    if (this.form?.form?.value?.message && this.form?.form?.value?.message.length) {
      charCount = `${this.characterCountService.getCharacterCount(message)}`;
      msgCount = `${this.characterCountService.getMessageCount(message)}`;
    }

    return {
      characterCount: charCount,
      messageCount: msgCount
    };
  }

  toggleScheduleForm() {
    this.showScheduleSMS = !this.showScheduleSMS;
  }

  onMessageTypeChange() {
    const { templateId } = this.form.value;
    const template = this.templates.find(c => c.id === templateId);
    if (!template) { 
      console.error("This is a bug with selecting a message template")
      return; 
    };
    const { message } = template;

    this.form.form.patchValue({ message: message ?? '' });
  }

  onSubmit() {
    const { scheduleSMS, scheduledDate, scheduledTime } = this.form.value;

    if (scheduleSMS && (scheduledDate || scheduledTime))
      this.scheduleSMS();
    else
      this.sendInstantSMS();
  }

  private scheduleSMS() {
    const { message, messageTypeId } = this.form.value;
    let { scheduledDate, scheduledTime } = this.form.value;
    scheduledDate = scheduledDate || Date();
    scheduledTime = scheduledTime || moment().set({ hour: 10, minute: 0, second: 0 }).toDate();

    const isSendDateToday = moment(scheduledDate).isSame(moment(), 'day');
    if (isSendDateToday) {
      const isSendTimeBeforeNow = moment(scheduledTime).isBefore(moment(), 'minute');
      if(isSendTimeBeforeNow) {
        scheduledTime = moment().add(5, 'minutes').toDate();
      }
    }
    scheduledDate = moment(scheduledDate).set({
      hour: moment(scheduledTime).hours(),
      minute: moment(scheduledTime).minutes()
    }).toDate();

    const smsMessage = new ScheduledMessage(
      this.clientId,
      message,
      scheduledDate,
      CommunicationType.SMS
    );

    this.sub2 = this.smsService.scheduleMessage(smsMessage)
      .subscribe((result) => {
        this.form.reset();
        this.showScheduleSMS = false;

        this.updateCommTemplateStatistic(messageTypeId);
        this.store.dispatch([new AddScheduledMessage(result), new GetClientNotes(this.clientId)])
        this.notificationService.success('Message scheduled successfully', '', { nzDuration: this.toastDuration });
      },
        (error) => {
          this.notificationService.warning('Something went wrong', error, { nzDuration: this.toastDuration });
        });
  }

  private sendInstantSMS() {
    const { message, messageTypeId } = this.form.value;
    const smsMessage = new Message(this.clientId, message);

    this.sub2 = this.smsService.sendMessage(smsMessage)
      .subscribe((messageHistory: SmsMessageHistory) => {
        this.form.reset();
        this.showScheduleSMS = false;
        this.form.form.patchValue({ message: '', messageTypeId: '0' });
        this.messageSentEvent.emit(messageHistory);
        this.updateCommTemplateStatistic(messageTypeId);
        this.notificationService.success('Message sent successfully', '', { nzDuration: this.toastDuration });
      },
        (error) => {
          this.notificationService.warning('Something went wrong', error, { nzDuration: this.toastDuration });
        });
  }

  private updateCommTemplateStatistic(id: string) {
    if(+id > 0) {
      const { title } = this.messageTypes.find(c => c.id === id);

      const request = {
        name: `CommTemplate - ${title}`,
        value: 1,
        entityId: +id,
        entityName: 'CommTemplate',
      };

      this.store.dispatch(new CreateStatistic(request));
    }
  }
}
