import { Component, OnInit, Input } from '@angular/core';
import { SmsMessageHistory } from '~/shared/models/messageHistory.model';
import { Client } from '~/shared/models/client.model';
import { Store, Select } from '@ngxs/store';
import { FetchSMSHistory } from './state/sms-history.actions';
import { SMSHistoryState } from './state/sms-history.state';
import { Observable } from 'rxjs';
import { MessageTemplate } from '../state/comms.state';

@Component({
  selector: 'app-sms',
  templateUrl: './sms.component.html',
  styleUrls: ['./sms.component.scss']
})
export class SmsComponent implements OnInit {
  @Select(SMSHistoryState.smsHistoryLoading) smsHistoryLoading$: Observable<boolean>
  @Select(SMSHistoryState.smsHistory) smsHistory$: Observable<SmsMessageHistory[]>
  
  @Input() client: Client;
  @Input() templates: MessageTemplate[];

  constructor(private store: Store) { }

  ngOnInit() {
    this.fetchMessageHistory();
  }

  private fetchMessageHistory() {
    this.store.dispatch(new FetchSMSHistory(this.client.id));
  }

  messageSent() {
    this.fetchMessageHistory();
  }

}
