import { DoctorReportModel } from '../../../../../../shared/models/six-cpa/doctor-report.model';
import { SixCpaMedicationModel } from '../../../../../../shared/models/six-cpa/six-cpa-medication.model';
import { ChannelPreference } from './../../../../../../shared/models/pharmacy/pharmacy-comms-details.model';

export enum MedicationListConfirmActionTypes {
  TOGGLE_CONFIRM_MED_LIST_ACTION = '[MedicationListConfirm] Toggle Blade',
  SET_DOCTORS_CONFIRM_MED_LIST_ACTION = '[MedicationListConfirm] set doctor list',
  SEND_TO_DOCTOR_CONFIRM_MED_LIST_ACTION = '[MedicationListConfirm] send to doctor',
  GO_BACK_CONFIRM_MED_LIST_ACTION = '[MedicationListConfirm] Go Back',
  TOGGLE_RESPONSE_CONFIRM_MED_LIST_ACTION = '[MedicationListConfirm] Toggle Response',
  GET_PATIENT_REPORT_CONFIRM_MED_LIST_ACTION = '[MedicationListConfirm] Get Patient Report',
  CONFIRM_MED_LIST_ERROR_ACTION = '[MedicationListConfirm] Error',
  GET_DOCTOR_REPORT_CONFIRM_MED_LIST_ACTION = '[MedicationListConfirm] Get Doctor Report',
  SETUP_MADICATIONS_MEDS_CONFIRM_CHECK_ERROR_ACTION = '[MedicationListConfirm] Setup Medications',
  SEND_MEDICATION_LIST_EMAIL = '[MedicationListConfirm] Send Meds List Email',
  SEND_MEDICATION_LIST_REPORT = '[MedicationListConfirm] Send Medication List Report',
  OPEN_MEDSLIST_SEND_DOCUMENT_FORM = '[MedicationListConfirm] Open MedList Send Document Form',
  CLOSE_MEDSLIST_SEND_DOCUMENT_FORM = '[MedicationListConfirm] Close MedList Send Document Form'
}

export class OpenMedsListSendDocumentFormAction {
  static readonly type = MedicationListConfirmActionTypes.OPEN_MEDSLIST_SEND_DOCUMENT_FORM;
  constructor() { }
}

export class CloseMedsListSendDocumentFormAction {
  static readonly type = MedicationListConfirmActionTypes.CLOSE_MEDSLIST_SEND_DOCUMENT_FORM;
  constructor() { }
}

export class SendMedicationListReport {
  static readonly type = MedicationListConfirmActionTypes.SEND_MEDICATION_LIST_REPORT;
  constructor(public clientId: string, public commsType: ChannelPreference, public contact: string, public customisedDoctorReport: Partial<DoctorReportModel>) { }
}

export class ToggleMedicationListConfirm {
  static readonly type = MedicationListConfirmActionTypes.TOGGLE_CONFIRM_MED_LIST_ACTION;
  constructor(public slideMode: string) { }
}

export class SetDoctorListMedicationListConfirm {
  static readonly type = MedicationListConfirmActionTypes.SET_DOCTORS_CONFIRM_MED_LIST_ACTION;
  constructor(public doctors: any[]) { }
}

export class SendToDoctorMedicationListConfirm {
  static readonly type = MedicationListConfirmActionTypes.SEND_TO_DOCTOR_CONFIRM_MED_LIST_ACTION;
  constructor(public clientId: string, public customisedDoctorReport: Partial<DoctorReportModel>) { }
}

export class SendMedsicationListEmail {
  static readonly type = MedicationListConfirmActionTypes.SEND_MEDICATION_LIST_EMAIL;
  constructor(public clientId: string, public customisedDoctorReport: Partial<DoctorReportModel>) { }
}

export class GoBackFromMedicationListConfirm {
  static readonly type = MedicationListConfirmActionTypes.GO_BACK_CONFIRM_MED_LIST_ACTION;
}

export class ToggleResponseMedicationListConfirm {
  static readonly type = MedicationListConfirmActionTypes.TOGGLE_RESPONSE_CONFIRM_MED_LIST_ACTION;
  constructor(public showResponse: string) { }
}

export class GetPatientReportMedicationListConfirm {
  static readonly type = MedicationListConfirmActionTypes.GET_PATIENT_REPORT_CONFIRM_MED_LIST_ACTION;
  constructor(public clientId: string, public doctorFullName?: string) { }
}

export class GetDoctorReportMedicationListConfirm {
  static readonly type = MedicationListConfirmActionTypes.GET_DOCTOR_REPORT_CONFIRM_MED_LIST_ACTION;
  constructor(public clientId: string, public customisedDoctorReport: Partial<DoctorReportModel>) { }
}

export class MedicationListConfirmError {
  static readonly type = MedicationListConfirmActionTypes.CONFIRM_MED_LIST_ERROR_ACTION;
  constructor(public error: any) { }
}

export class SetupMedicationsMedicationListConfirm {
  static readonly type = MedicationListConfirmActionTypes.SETUP_MADICATIONS_MEDS_CONFIRM_CHECK_ERROR_ACTION;
  constructor(public sixCpaMedications: SixCpaMedicationModel[]) { }
}
