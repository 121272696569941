import { Component, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { HMRFormState, HMRFormType } from '../state/hmr-form.state';
import { Observable, forkJoin } from 'rxjs';
import { HMRFormsProgressService } from '../state/hmr-forms-progress.service';
import { filter, switchMap, map } from 'rxjs/operators';
import { HMRFormsService } from '../state/hmr-forms.service';
import { ProfileState } from '../../../../../core/profile/state/profile.state';
import { SubmitExisitingHMR, SubmitNewHMR, CloseHMRConfirmForm } from '../state/hmr-form.actions';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';

@Component({
  selector: 'app-hmr-confirm-form',
  templateUrl: './hmr-confirm-form.component.html'
})
export class HmrConfirmFormComponent implements OnInit {
  @Select(HMRFormState.showConfirmForm) showConfirmForm$: Observable<boolean>;
  @Select(HMRFormState.hmrFormType) hmrFormType$: Observable<HMRFormType>;

  constructor(private store: Store) { }

  ngOnInit() {
  }

  @Dispatch()
  closeConfirmHMR = () => new CloseHMRConfirmForm()

  @Dispatch()
  submitHMRNewClaim = () =>
    this.store.selectOnce(HMRFormsProgressService.getHMRNewClaimProgress).pipe(
      filter(progress => progress === 100),
      switchMap(() => forkJoin([
        this.store.selectOnce(HMRFormsService.getHMRNewClaimRequest),
        this.store.selectOnce(ProfileState.clientId)
      ])),
      map(([formData, clientId]) =>
        formData && formData.sixCpaFormId !== null
          ? new SubmitExisitingHMR(clientId, formData)
          : new SubmitNewHMR(clientId, formData)))
}
