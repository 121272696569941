import { Component, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { SixCpaState } from '../../state/six-cpa.state';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { ResetMedicationListFormListAction } from './state/medication-list-form.actions';
import { OpenResetFormModal, CloseResetFormModal, ToggleSixCpaContentAction } from '../../state/six-cpa.actions';
import { MedicationListFormListState } from './state/medication-list-form.state';
import { SaveOrUpdateMedicationListAction } from './state/medication-list-save.action';
import { ToggleMedicationListConfirm } from '../medication-list-confirm/state/medication-list-confirm.actions';

@Component({
  selector: 'app-medication-list-form-list',
  templateUrl: './medication-list-form-list.component.html',
  styleUrls: ['./medication-list-form-list.component.scss']
})
export class MedicationListFormListComponent implements OnInit {
  @Select(SixCpaState) sixCpaState$: Observable<any>;
  @Select(MedicationListFormListState.saving) saving$: Observable<boolean>;

  constructor(private store: Store) { }

  ngOnInit() {
  }

  resetMedicationListFormList() {
    this.store.dispatch(new ResetMedicationListFormListAction());
  }

  @Dispatch()
  viewConfirmation = () => [new ToggleSixCpaContentAction('out'), new ToggleMedicationListConfirm('in')];

  @Dispatch()
  submitMedicationList = () => new SaveOrUpdateMedicationListAction();

  @Dispatch()
  openConfirmResetModal = () => this.store.dispatch(new OpenResetFormModal())

  @Dispatch()
  closeConfirmResetModal = () => this.store.dispatch(new CloseResetFormModal())
}
