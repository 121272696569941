<ng-container *ngIf="(pharmacyCommsDetailsState$ | async) as pharmacyCommsDetailsState">
  <ng-container *ngIf="pharmacyCommsDetailsState.pharmacyLocation as pharmacyLocation">
    <h1 class="blue-header">Communications Preferences</h1>
    <div style="float: right;" *ngIf="showGroupingHelper">
      <app-info-box message="Same message type reminders will be combined as a single message for multiple drugs if scheduled within X days.">
      </app-info-box>
    </div>
    <div class="position-relative">
      <ng-container *ngIf="pharmacyCommsDetailsState.error as error">
        <div class="form-help-text" g-xs="mb2">{{error.message}}</div>
      </ng-container>
      <form [formGroup]="pharmacyCommsDetailsForm" ngxsForm="pharmacyCommsDetails.pharmacyCommsDetailsForm"
        class="border__grey p-4">
        <div class="container-fluid">
          <div class="row">
            <div class="col-xl-4 col-lg-12">
              <div [ngClass]="!!pharmacyCommsDetailsForm.value.communicationsEnabled ? 'alert alert-success': 'alert alert-danger'">
                <div class="row">
                  <div class="font__bold mb-1 col h5"
                    [style.color]="!!pharmacyCommsDetailsForm.value.communicationsEnabled ? '#03B615': '#D9001B'">
                    <i
                      [ngClass]="!!pharmacyCommsDetailsForm.value.communicationsEnabled ? 'fas fa-check': 'fas fa-times'"></i>
                    Notifications
                    <span *ngIf="!!pharmacyCommsDetailsForm.value.communicationsEnabled"> enabled</span>
                    <span *ngIf="!pharmacyCommsDetailsForm.value.communicationsEnabled"> disabled</span>
                  </div>
                </div>
                <div class="row">
                  <div class="mt-2 mb-2 col-md-9 color-black">
                    Scrypt is <span *ngIf="!pharmacyCommsDetailsForm.value.communicationsEnabled">not</span> sending
                    notifications on behalf of
                    <strong>{{pharmacyLocation.name}}</strong>
                  </div>
                  <ui-switch color="#03B615" class="col-md-3" (change)="togglePharmacyComms()" formControlName="communicationsEnabled"></ui-switch>
                </div>
            </div>
            </div>
            <div class="col-xl-2 mb-4">
              <div class="font__bold mb-1">Primary Channel</div>
              <select formControlName="primaryChannel"
                class="form-control form-control-lg mt-4">
                <option [ngValue]="null">Select...</option>
                <ng-container *ngFor="let type of channelPreference | keyvalue">
                  <option *ngIf="formControls.secondaryChannel.value !== +type.key" [ngValue]="+type.key"
                    [label]="type.value"></option>
                </ng-container>
              </select>
              <div class="form-help-text"
                *ngIf="formControls.primaryChannel.invalid && formControls.primaryChannel.touched">Required Field
              </div>
            </div>
            <div class="col-xl-2 mb-4">
              <div class="font__bold mb-1">Secondary Channel
              </div>
              <select formControlName="secondaryChannel"
                class="form-control form-control-lg mt-4">
                <option [ngValue]="null">Select...</option>
                <ng-container *ngFor="let type of channelPreference | keyvalue">
                  <option *ngIf="formControls.primaryChannel.value !== +type.key" [ngValue]="+type.key"
                    [label]="type.value"></option>
                </ng-container>
              </select>
              <div class="form-help-text"
                *ngIf="formControls.secondaryChannel.invalid && formControls.secondaryChannel.touched">Required Field
              </div>
            </div>
            <div class="col-xl-2 d-xl-flex justify-content-center mb-4">
              <div>
                <div (mouseenter)="toggleScheduleHelp()" (mouseleave)="toggleScheduleHelp()"
                  class="font__bold mb-1 pointer">Schedule time
                </div>
                <timepicker [formControlName]="'notificationSchedule'" [showMinutes]="false"></timepicker>
                <div *ngIf="showScheduleHelper">
                  <app-info-box message="Time for which daily reminders will be sent."></app-info-box>
                </div>
              </div>
            </div>
            <div class="lite-group col-xl-2 mb-4">
              <div (mouseenter)="toggleGroupingHelp()" (mouseleave)="toggleGroupingHelp()" style="white-space: nowrap;" class="font__bold mb-1">Drug
                Grouping Period
              </div>
              <label class="mt-4">
                <input type="number" formControlName="drugAggregationDays" class="form-control d-inline width__50 mr-1" />
                days
              </label>
            </div>
          </div>
        </div>
        <div class="d-flex">
          <div class="mt-3 ml-3 text-center">
            <button class="btn btn__green" (click)="submitPharmacyCommsDetails()"
              [disabled]="pharmacyCommsDetailsForm.invalid">Save Changes</button>
            <button class="btn btn__white ml-3" (click)="resetPharmacyCommsDetails()">Discard Changes</button>
          </div>
        </div>
      </form>
      <div class="loading__container" *ngIf="pharmacyCommsDetailsState.loading;">
        <app-loader></app-loader>
      </div>
    </div>
  </ng-container>
</ng-container>
