import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SmsComponent } from './sms.component';
import { SmsService } from '~/shared/services/sms.service';
import { AddSmsComponent } from './add-sms/add-sms.component';
import { HistoryComponent } from './history/history.component';
import { LoaderModule } from '~/modules/core/loader/loader.module';
import { NotificationModule } from '~/shared/components/notification/notification.module';
import { FormsModule } from '@angular/forms';
import { TimezoneModule } from '~/modules/core/timezone/timezone.module';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { SmsAttachmentModalComponent } from './sms-attachment-modal/sms-attachment-modal.component';
import { NgxsModule } from '@ngxs/store';
import { SmsAttachmentModalState } from './sms-attachment-modal/state/sms-attachment-modal.state';
import { SMSHistoryState } from './state/sms-history.state';
import { MessageModule } from '../message/message.module';
import { NgxAutoScrollModule } from 'ngx-auto-scroll';
import { TooltipModule } from 'ngx-bootstrap';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzTimePickerModule } from 'ng-zorro-antd/time-picker';
import { NzNotificationService } from 'ng-zorro-antd/notification';


@NgModule({
  declarations: [
    SmsComponent,
    AddSmsComponent,
    HistoryComponent,
    SmsAttachmentModalComponent
  ],
  imports: [
    TimezoneModule,
    CommonModule,
    FormsModule,
    LoaderModule,
    NotificationModule,
    BsDatepickerModule.forRoot(),
    NzDatePickerModule,
    NzTimePickerModule,
    TooltipModule.forRoot(),
    NgxsModule.forFeature([SmsAttachmentModalState, SMSHistoryState]),
    MessageModule,
    NgxAutoScrollModule
  ],
  providers: [
    SmsService,
    NzNotificationService
  ],
  exports: [
    SmsComponent
  ]
})
export class SmsModule { }
