import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { RegularExpressions } from '../../../../shared/constants/regular-expressions';
import { Group } from '../../../../shared/models/group.model';
import { CommTemplate } from '../../../../shared/models/pharmacy/comm-template.model';
import { TriggerType } from '../../../../shared/models/pharmacy/trigger.model';
import { GroupPageState } from '../../../../settings/group-page/state/group-page.state';
import { CommTemplatesState } from '../../comm-templates/state/comm-templates.state';
import { HandleAddEditEvent, ToggleAddEditTriggerModal } from './state/add-edit-trigger-modal.actions';
import { AddEditTriggerModalState, AddEditTriggerModalStateModel } from './state/add-edit-trigger-modal.state';

@Component({
  selector: 'app-add-edit-trigger-modal',
  templateUrl: './add-edit-trigger-modal.component.html',
  styleUrls: ['./add-edit-trigger-modal.component.scss']
})
export class AddEditTriggerModalComponent implements OnInit {
  @Select(AddEditTriggerModalState.addEditTriggerState) addEditTriggerState$: Observable<AddEditTriggerModalStateModel>
  @Select(CommTemplatesState.commTemplates) commTemplates$: Observable<CommTemplate[]>;
  @Select(GroupPageState.groups) groups$: Observable<Group[]>;
  addEditTriggerForm: FormGroup;
  triggerTypes = TriggerType;
  triggers = [];

  constructor(private store: Store, private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.setTriggersList();
    this.initFormControls();
  }


  private initFormControls() {
    this.addEditTriggerForm = this.formBuilder.group({
      id: new FormControl(null),
      tenantId: new FormControl(null),
      templateId: new FormControl(null, Validators.required),
      name: new FormControl('', Validators.required),
      patientType: new FormControl(null),
      leadTimeInDays: new FormControl(0),
      triggerType: new FormControl(Validators.required),
    });
  }

  private setTriggersList() {
    Object.keys(TriggerType).map(key => {
      if (isNaN(TriggerType[key])) {
        this.triggers.push({
          id: TriggerType[key],
          value: +key,
        });
      }
    });
  }

  submit() {
    this.store.dispatch(new HandleAddEditEvent());
  }

  close() {
    this.store.dispatch(new ToggleAddEditTriggerModal(false));
    this.addEditTriggerForm.reset();
  }

  getTriggerType(type: TriggerType) {
    if(type== TriggerType.lastRepeatDue) {
      return 'No Repeats';
    }

    var stringType = TriggerType[type];
    var sentence = stringType.replace(RegularExpressions.camelCaseToUIStringRegex, " $1");

    return sentence.charAt(0).toUpperCase() + sentence.slice(1);
  }

  getChronology(type: TriggerType) {
    switch(type) {
      case TriggerType.scriptOwing:
      case TriggerType.medicationOverdue:
      case TriggerType.medicationOverdueOnLastRepeat: {
        return 'after'
      }
      default: {
        return 'before'
      }
    }
  }
}
