import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Store, Select, Actions, ofActionSuccessful } from '@ngxs/store';
import { OrderRequestState, OrderRequestStateModel } from '../state/order.state';
import { OrderRequest } from '../models/order-request.model';
import { ActivatedRoute, Router } from '@angular/router';
import { ToggleSendToQueueModal, DispenseScriptsToQueue, GetOrderRequestById, ToggleOrderStatusModal, UpdateOrderAction, ToggleImageModal, SearchOrderStockAction, ToggleAdditionalItemsModal, SetCurrentOrder, ToggleAddMedicationModal, UpdateOrderMethod, UpdateOrderResponse } from '../state/order.actions';
import { ProfileState } from '../../../core/profile/state/profile.state';
import { SendToQueueUpdate } from '../components/send-to-queue-modal/send-to-queue-modal.component';
import { OrderRequestItemType, OrderRequestStatus } from '../models/order.enums';
import { OrderRequestItem } from '../models/order-request-item.model';
import { ClientViewModel } from '../../../core/profile/client.model';
import { combineDateAndTime } from '../../../../shared/helpers/date-helpers';
import { ValidService } from '../components/order-table/order-table.service';
import { isNullOrWhiteSpace } from '../../../../shared/helpers/string.helpers';
import { takeUntil } from 'rxjs/operators';
import { HumanizePipe } from '../../../../shared/pipes/humanize.pipe';
import { mapIdentifierType, mapToken } from '../../../../shared/helpers/eScript.helper';
import { ConnectionTypeEnum } from '../../../../shared/models/connectionType.model';
import { PharmacyLocation } from '../../../../shared/models/pharmacy/pharmacy-address.model';
import { PharmacyCommsDetailsState } from '../../../../modules/pharmacy-comms/pharmacy-comms-details/state/pharmacy-comms-details.state';
import { GetPharmacyLocationAction } from '../../../../modules/pharmacy-comms/pharmacy-comms-details/state/pharmacy-comms-details.actions';
import { StripeModalVisibleAction } from '../../../../modules/profile/order-requests/state/order.actions';
import { Clipboard } from '@angular/cdk/clipboard';
import { EScriptType } from '@base/shared/models/dispense/dispenseItem.model';
import { mapOrderRequestItemstoDispenseItemsModel } from '@base/shared/helpers/dispense-request.helpers';

@Component({
  selector: 'app-order-view',
  templateUrl: './order-view.component.html',
  styleUrls: ['./order-view.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class OrderViewComponent implements OnInit {
  @Select(OrderRequestState) order$: Observable<OrderRequestStateModel>;
  @Select(OrderRequestState.searchListFiltered) searchListFiltered$: Observable<any>;
  @Select(OrderRequestState.profileOrderItems) profileOrderItems$: Observable<any[]>;
  @Select(OrderRequestState.orderWithNewStatus) orderWithNewStatus$: Observable<any>;
  @Select(OrderRequestState.hasSelectedOrderItems) hasSelectedOrderItems$: Observable<any>;
  @Select(PharmacyCommsDetailsState.pharmacy) pharmacy$: Observable<any>;

  pharmacy : PharmacyLocation;
  profile: ClientViewModel;
  notesOpen = false;
  originalOrderStatus = null;
  canSendToQueue = true;
  private ngUnsubscribe = new Subject();
  constructor(
    private store: Store,
    private validService: ValidService,
    private router: Router,
    private route: ActivatedRoute,
    private actions$: Actions,
    private humanizer: HumanizePipe,
    private clipboard: Clipboard
  ) {
  }

  ngOnInit() {
    this.validService.validStatus.subscribe(x => x);
    this.profile = this.store.selectSnapshot(ProfileState.profile);
    this.route.params.subscribe(params => {
      const items = this.store.selectSnapshot(OrderRequestState.orderItems);
      if (!items) {
        this.store.dispatch(new GetOrderRequestById(params.orderId)).subscribe(result => {
          this.store.dispatch(new SetCurrentOrder(
            this.store.selectSnapshot(OrderRequestState.orderItems)
            .find(x => x.id === +params.orderId)
            ));
            this.originalOrderStatus = this.store.selectSnapshot(OrderRequestState.orderItems)
            .find(x => x.id === +params.orderId).status;
        })
      } else {
        this.originalOrderStatus = items.find(x => x.id === +params.orderId).status;
        this.store.dispatch(new SetCurrentOrder(
          items.find(x => x.id === +params.orderId)
          ));
      }
      });
      this.actions$
      .pipe(
        ofActionSuccessful(UpdateOrderResponse),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe(() => {
        this.router.navigate([`../`], { relativeTo: this.route });
      });

      this.pharmacy = this.store.selectSnapshot(PharmacyCommsDetailsState.pharmacy);
      this.setCanSendToQueue();

      if (this.pharmacy == null) {
        this.store.dispatch(new GetPharmacyLocationAction());
        this.pharmacy$.subscribe((res) => {
          this.pharmacy = res;
          this.setCanSendToQueue();
        })
      }
  }

  setCanSendToQueue() {
    if (!this.pharmacy || this.pharmacy == null)
      return;

    switch (this.pharmacy.connectionType) {
      case ConnectionTypeEnum.Aquarius: {
        this.canSendToQueue = true;
        break;
      }
      case ConnectionTypeEnum.ZDispense: {
        this.canSendToQueue = true;
        break;
      }
      case ConnectionTypeEnum.FredDispense: {
        this.canSendToQueue = false;
        break;
      }
      case ConnectionTypeEnum.FredNXTDispense: {
        this.canSendToQueue = false;
        break;
      }
      case ConnectionTypeEnum.Corum: {
        this.canSendToQueue = true;
        break;
      }
    }
  }

  orderMatchesCustomer(order)
  {
    return order && this.profile && order.customerProfileId === this.profile.customerProfile.customerProfileId;
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  changeOrderItems($event) {
    var currentOrder = this.store.selectSnapshot(OrderRequestState.currentOrder);
    this.store.dispatch(new SetCurrentOrder(
      {
        ...currentOrder,
        orderRequestedItems: currentOrder.orderRequestedItems
      }
    ));
  }

  isReadOnly(order: OrderRequest) {
    return this.originalOrderStatus === OrderRequestStatus.Cancelled
    || this.originalOrderStatus === OrderRequestStatus.Ready;
  }

  formatDate = (date: string) => {
    return this.humanizer.transform(date);
  }

  changeOrderMethod(event) {
    this.store.dispatch(new UpdateOrderMethod(event));
  }

  learnMore() {
    window.open('https://stripe.com/', '_blank');
  }

  visibleStripeModal(visible) {
    this.store.dispatch(new StripeModalVisibleAction(visible));
  }

  closeStripeModal() {
    this.store.dispatch(new StripeModalVisibleAction(false));
  }

  copyPaymentLink(paymentUrl: string) {
    this.clipboard.copy(paymentUrl);
  }

  closeModal(order) {
    this.store.dispatch(new ToggleOrderStatusModal(false, order, null));
  }

  closeSendModal() {
    this.store.dispatch(new ToggleSendToQueueModal(false));
  }

  updateOrderFromStatusCard(event, order) {
    if (event.status === event.oldStatus) {
      this.originalOrderStatus = event.status;
      this.store.dispatch(new UpdateOrderAction(event, null, null, false));
    } else {
      this.openUpdateStatusModal(order, event.status);
    }
  }

 openUpdateStatusModal(order, status) {
   this.store.dispatch(new ToggleOrderStatusModal(true, order, status));
 }

 updateOrder(update) {
  this.originalOrderStatus = update.order.status;
  this.store.dispatch(new UpdateOrderAction(update.order, update.message, update.contactMethod, update.sendMessage));
 }

 openSendToQueueModal(order) {
  this.store.dispatch(new ToggleSendToQueueModal(true))
 }

  isItemQueuable(item: OrderRequestItem) {
    return mapIdentifierType(item.orderRequestItemType) !== EScriptType.Unknown && !isNullOrWhiteSpace(mapToken(item.orderRequestItemType, item));
  }

  sendToQueue(form: SendToQueueUpdate) {
    this.store.dispatch(new DispenseScriptsToQueue({
        items: mapOrderRequestItemstoDispenseItemsModel(form.order.orderRequestedItems, this.profile.clientId),
        patient: `${this.profile.patient.surname} ${this.profile.patient.firstname}`,
        pickupDate: form.datePart !== null ? combineDateAndTime(form.datePart, form.timePart).utc().toDate().toISOString() : null,
        options: form.options,
        notes: form.notes
      }));
  }

  openImageModal(item) {
    this.store.dispatch(new ToggleImageModal(true, item));
  }

  closeImageModal() {
    this.store.dispatch(new ToggleImageModal(false));
  }

  openAdditionalItemsModal() {
    this.store.dispatch(new ToggleAdditionalItemsModal(true));
  }

  closeAdditionalItemsModal() {
    this.store.dispatch(new ToggleAdditionalItemsModal(false));
  }

  openAddMedicationModal() {
    this.store.dispatch(new ToggleAddMedicationModal(true));
  }

  closeAddMedicationModal() {
    this.store.dispatch(new ToggleAddMedicationModal(false));
  }

  stockSearch(searchText) {
    this.store.dispatch(new SearchOrderStockAction(searchText));
  }

  addItem(order: OrderRequest, item: OrderRequestItem) {
    if (item.orderRequestItemType == OrderRequestItemType.EScript) {
      item.price = item.customerMedication.patientPrice;
      item.eScriptRepeats =  item.customerMedication?.repeats;
      item.eScriptQty = item.customerMedication?.quantity;
      item.eScriptDrugName = item.customerMedication?.name;
      item.eScriptTokenUrl = item.customerMedication?.repeatTokenUri;
    }
    order.orderRequestedItems = [...order.orderRequestedItems, item];
    this.store.dispatch(new SetCurrentOrder({...order}))
  }

  removeItem(order: OrderRequest, item: OrderRequestItem) {
    order.orderRequestedItems = [...order.orderRequestedItems.filter(x => x.stockId !== item.stockId)];
    this.store.dispatch(new SetCurrentOrder({...order}))
  }

  navToComms() {
    this.router.navigate(this.createOutletSettings(
      ['profile', this.profile.clientId, 'comms', 'sms'])
      );
  }

  private createOutletSettings(routes: string[]): any {
    return [
      '',
      {
        outlets: {
          patient: ['patient', ...routes],
        },
      },
    ];
  }

  mapItems(items: OrderRequestItem[]) {
    items.forEach((x) => {
      if(x.orderRequestItemType === OrderRequestItemType.OnFileScript && x.customerMedication && x.customerMedication.isPaperless) {
        let { customerMedication } = x;
        x.orderRequestItemType = OrderRequestItemType.EScript;
        x.eScriptTokenUrl = customerMedication.repeatTokenUri;
        x.eScriptRepeats = customerMedication.repeats;
        x.eScriptQty = customerMedication.quantity;
      }
    })
    return items;
 }
}
