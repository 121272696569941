import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';
import { BulkMessagingUpcomingState } from './bulk-messaging-upcoming.state';
import { BulkMessagingHistoryState } from './bulk-messaging-history.state';
import { BulkMessagingFormState } from './bulk-messaging-form.state';
import { BulkMessagingService } from '../services/bulk-messaging.service';
import { NewBulkMessagingFormState } from '../new-bulk-message/state/new-bulk-message-form.state';
import { BulkMessagingState } from './bulk-messaging.state';

@NgModule({
  imports: [
    NgxsModule.forFeature([
      BulkMessagingUpcomingState,
      BulkMessagingHistoryState,
      BulkMessagingFormState,
      NewBulkMessagingFormState,
      BulkMessagingState
    ])
  ],
  providers: [
    BulkMessagingService
  ]
})
export class BulkMessagingStateModule { }
