import { State, Action, StateContext, Selector } from '@ngxs/store';
import {
  ToggleDAAMedicationProfileAction,
  SetDAAMedicationProfileScriptsAction,
  ToggleDAAMedicationProfileListItemAction,
  ToggleAllDAAMedicationProfileListAction,
  AddMedicationToDAAMedicationProfileListAction,
  SelectDAAMedicationProfileListItemAction,
  UpdateDAAMedicationProfileList
} from './daa-medication-profile.actions';
import { MedicineCategory, Medicine } from '../../../../../../shared/models/six-cpa/medicine.model';
import { addUncategorisedIfMissing } from '../../../../../../shared/helpers/uncategorised-meds.helper';
import { Injectable } from '@angular/core';
import { ResetDAAMedicationProfileIdsAction } from './daa-medication-profile.actions';

export class DAAMedicationProfileStateModel {
  public categorisedMedicines: MedicineCategory[];
  public showMode: string;
}
@Injectable()
@State<DAAMedicationProfileStateModel>({
  name: 'daaMedicationProfile',
  defaults: {
    categorisedMedicines: [],
    showMode: 'out'
  }
})
export class DAAMedicationProfileState {
  @Selector()
  static categorisedMedicines(state: DAAMedicationProfileStateModel) { return state.categorisedMedicines; }

  @Selector()
  static showMode(state: DAAMedicationProfileStateModel) { return state.showMode; }

  @Selector()
  static selectedMedicines(state: DAAMedicationProfileStateModel) {
    let res = [] as Medicine[];
    state.categorisedMedicines.forEach(category => {
      res = res.concat(category.medicines.filter(x => x.isSelected));
    });
    return res;
  }

  @Selector()
  static selectedMedicineCount(state: DAAMedicationProfileStateModel) {
    let res = [] as Medicine[];
    state.categorisedMedicines.forEach(category => {
      res = res.concat(category.medicines.filter(x => x.isSelected));
    });
    return res.length;
  }

  @Action(ToggleDAAMedicationProfileAction)
  toggle(ctx: StateContext<DAAMedicationProfileStateModel>, action: ToggleDAAMedicationProfileAction) {
    ctx.patchState({ showMode: action.showMode });
  }

  @Action(SetDAAMedicationProfileScriptsAction)
  setScripts(ctx: StateContext<DAAMedicationProfileStateModel>, action: SetDAAMedicationProfileScriptsAction) {
    const categorisedMedicines = action.categorisedScripts.map(category => {
      const { id, name } = category.key;
      const medicineList = category.items.map(item => {
        return this.ConstructMedicine(item);
      });
      return {
        id,
        name,
        medicines: medicineList
      } as MedicineCategory;
    });
    ctx.patchState({ categorisedMedicines });
  }

  private ConstructMedicine(item: any) {
    const { productDispensedId, description, directionsDesc, quantity, name, dispensedDate } = item;
    return {
      id: null,
      productDispensedId,
      brandName: description,
      genericName: name,
      directions: directionsDesc,
      isSelected: false,
      quantity,
      dispensedDate
    } as Medicine;
  }

  @Action(ToggleDAAMedicationProfileListItemAction)
  toggleListItem(ctx: StateContext<DAAMedicationProfileStateModel>, action: ToggleDAAMedicationProfileListItemAction) {
    const state = ctx.getState();
    ctx.patchState({
      categorisedMedicines: state.categorisedMedicines.map(category => {
        return {
          ...category,
          medicines: category.medicines.map(item => item === action.item ? { ...item, isSelected: !item.isSelected } : item)
        };
      })
    });
  }

  @Action(ToggleAllDAAMedicationProfileListAction)
  toggleAll(ctx: StateContext<DAAMedicationProfileStateModel>, {isSelectAll, categoryId}: ToggleAllDAAMedicationProfileListAction) {
    const state = ctx.getState();
    ctx.patchState({
      categorisedMedicines: state.categorisedMedicines.map(category => {
        if ((categoryId && category.id === categoryId) || !categoryId) {
          return {
            ...category,
            medicines: category.medicines.map(item => {
              return { ...item, isSelected: isSelectAll } as Medicine;
            })
          };
        } else { return category; }
      })
    });
  }

  @Action(AddMedicationToDAAMedicationProfileListAction)
  addMedication(ctx: StateContext<DAAMedicationProfileStateModel>, action: AddMedicationToDAAMedicationProfileListAction) {
    const state = ctx.getState();
    const findDrug = state.categorisedMedicines.find(y => !!y.medicines.find(x => x.productDispensedId === action.item.productDispensedId));
    if (findDrug) {
      ctx.patchState({
        categorisedMedicines: state.categorisedMedicines.map(category => {
          return {
            ...category,
            medicines: category.medicines.map(item => item.productDispensedId === action.item.productDispensedId
              ? { ...action.item, isSelected: item.isSelected }
              : item)
          };
        })
      });
    } else {
      addUncategorisedIfMissing(state.categorisedMedicines);
      ctx.patchState({
        categorisedMedicines: state.categorisedMedicines.map(category => {
          if (category.id) {
            return {
              ...category,
            };
          } else {
            return {
              ...category,
              medicines: [...category.medicines, { ...action.item, isSelected: true }]
            };
          }
        })
      });
    }
  }

  @Action(SelectDAAMedicationProfileListItemAction)
  selectListItem(ctx: StateContext<DAAMedicationProfileStateModel>, action: SelectDAAMedicationProfileListItemAction) {
    const state = ctx.getState();
    ctx.patchState({
      categorisedMedicines: state.categorisedMedicines.map(category => {
        return {
          ...category,
          medicines: category.medicines.map(item => item.productDispensedId === action.productDispensedId ? { ...item, isSelected: true } : item)
        };
      })
    });
  }

  @Action(UpdateDAAMedicationProfileList)
  updateMedicines(ctx: StateContext<DAAMedicationProfileStateModel>, { medication }: UpdateDAAMedicationProfileList) {
    const state = ctx.getState();

    //clear all previous selections
    ctx.dispatch(new ToggleAllDAAMedicationProfileListAction(false, null));

    medication.forEach(med => {
      ctx.dispatch(new SelectDAAMedicationProfileListItemAction(med.productDispensedId));
    });

    ctx.patchState({
      categorisedMedicines: state.categorisedMedicines.map(category => {
        return {
          ...category,
          medicines: category.medicines.map(item => {
            const el = medication.find(x => x.productDispensedId === item.productDispensedId);
            return el ? el : item;
          })
        };
      })
    });
  }

  @Action(ResetDAAMedicationProfileIdsAction)
  resetMedicationProfileIds(ctx: StateContext<DAAMedicationProfileStateModel>, action: ResetDAAMedicationProfileIdsAction) {
    const state = ctx.getState();
    ctx.patchState({
      categorisedMedicines: state.categorisedMedicines.map(category => {
        return {
          ...category,
          medicines: category.medicines ? category.medicines.map(med => {return {...med, id: null}}): [],
        }
      })
    });
  }
}
