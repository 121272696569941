import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AlertService } from '../../../../core/alert/alert.service';
import { BaseApiNoAlerts } from '../../../../../shared/core/base-api/base-api-no-alerts';
import { StorageService } from '../../../../../shared/core/storage/storage.service';
import { ScriptHistory } from '../../../../../shared/models/script/script-history.model';
import { Observable } from 'rxjs';

@Injectable()
export class ScriptHistoryService extends BaseApiNoAlerts {
  API = '';

  constructor(
    @Inject('environment') env: any,
    public http: HttpClient,
    public router: Router,
    public storageService: StorageService,
    public alertService: AlertService
  ) {
    super(http, router, storageService, alertService);
    this.API = env.baseApiUrl;
  }

  toggleScriptAvailability(script: ScriptHistory): Observable<ScriptHistory> {
    return this.http.patch<ScriptHistory>(
      `${this.API}/api/script-history`,
      script
    );
  }
}
