import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {Bimetric} from '../../../shared/models/bimetric.model';
import {BimetricService} from '../../../shared/services/bimetric.service';

@Component({
  selector: 'app-metric-item',
  templateUrl: './metric-item.component.html',
  styleUrls: ['./metric-item.component.scss']
})
export class MetricItemComponent implements OnInit {

  public bimetricForm;
  public bimetric;
  private metricId;
  private mode = 'create';

  public errorMessages = [];

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private metricService: BimetricService
  ) {

    // this.bimetric = new Bimetric();
    this.bimetricForm = this.formBuilder.group({
      name: new FormControl('', Validators.required),
      current: new FormControl('', Validators.required),
      target: new FormControl('', Validators.required),
    });
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => this.metricId = params.id);

    if (this.metricId) {
      this.metricService.getBiMetrcisById(this.metricId).subscribe(
        (item: Bimetric) => {
          this.bimetric = item;
          this.bimetricForm.patchValue(item);
        }
      );

      this.mode = 'update';
    }
  }


  onSubmit(values) {
    if (this.mode === 'update') {
      this.updateItem(values);
    } else {
      this.addItem(values);
    }
  }


  private addItem(data) {
    this.metricService.addBimetric(data).subscribe(
      response => {
        this.router.navigate(['/biMetric']);
      }
    );
  }

  private updateItem(data) {
    data.id = this.metricId;
    this.metricService.updateBimetric(data).subscribe(
      response => {
        this.router.navigate(['/biMetric']);
      }
    );
  }

}
