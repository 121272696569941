import { SickLeaveCertificateTypeFormModel } from '../../../../../../shared/models/six-cpa/sick-leave/sick-leave-certificate-type-form.model';

export enum SickLeaveCertificateTypeActions {
  SET_VALID = '[SickLeaveCertificateType] set valid',
  UPDATE = '[SickLeaveCertificateType] Update Form',
  UPDATE_PROGRESS = '[SickLeaveCertificateType] Update Progress'
}

export class SetSickLeaveCertificateTypeValidAction {
  static readonly type = SickLeaveCertificateTypeActions.SET_VALID;
  constructor(public valid = false) { }
}

export class SetTotalItemsAndCompletedItems {
  static readonly type = SickLeaveCertificateTypeActions.UPDATE_PROGRESS;
  constructor(public totalItems, public completedItems) { }
}


export class UpdateSickLeaveCertificateType {
  static readonly type = SickLeaveCertificateTypeActions.UPDATE;
  constructor(public form: Partial<SickLeaveCertificateTypeFormModel>) { }
}
