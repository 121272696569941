import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  OnDestroy
} from '@angular/core';
import {
  OutOfStoreState, OutOfStoreStateModel
} from './state/out-of-store.state';
import { Observable, BehaviorSubject, Unsubscribable } from 'rxjs';
import { Select, Store } from '@ngxs/store';
import {
  OutOfStoreRequestAction,
  AddOutOfStoreItemAction,
  RemoveOutOfStoreItemAction
} from './state/out-of-store.actions';
import { TypeaheadMatch } from 'ngx-bootstrap';
import { AdditionalItemStock } from './out-of-store-stock.model';
import {
  debounceTime,
  map,
  filter,
  tap,
  mergeMap
} from 'rxjs/operators';
import { OutOfStoreService } from './out-of-store.service';
import { StockState, IStock } from '../stock';
import { TenantCategoryState } from '../tenant-categories/state/tenant-category.state';
import { TenantCategory } from '../tenant-categories/tenant-category.model';
import { SearchStockAction } from '../stock/stock.actions';
import { searchDebounceTime, validSearchQuery } from '../../core/search';
import { FetchTenantCategoriesAction } from '../tenant-categories/state/tenant-category.actions';
import { ProfileState } from '../../core/profile/state/profile.state';
import { PassiveScriptType } from './out-of-store.model';

@Component({
  selector: 'app-out-of-store',
  templateUrl: './out-of-store.component.html',
  styleUrls: ['./out-of-store.component.scss']
})
export class OutOfStoreComponent implements OnInit, OnDestroy {
  @Output() close = new EventEmitter();
  @Select(StockState.isFetching) stocksLoading$: Observable<boolean>;
  @Select(OutOfStoreState.loading) loading$: Observable<boolean>;
  @Select(OutOfStoreState) outOfStoreState$: Observable<OutOfStoreStateModel>;
  @Select(TenantCategoryState.categories) tenantCategories$: Observable<TenantCategory[]>;
  searchStock = new BehaviorSubject<string>('');
  searchedStocks$: Observable<IStock[]>;
  searchedVal: string;
  formItems: AdditionalItemStock[] = [];
  subscriptions: Unsubscribable[] = [];
  passiveScriptTypes = PassiveScriptType;

  constructor(private readonly store: Store) { }

  ngOnInit() {

    this.store.dispatch(new FetchTenantCategoriesAction());

    this.searchedStocks$ = this.searchStock.pipe(mergeMap(query => this.store
      .select(StockState.stocksOrderByClosestNameMatch)
      .pipe(map(sorter => sorter(query)))));

    this.subscriptions = [
      this.searchStock
        .pipe(
          filter(validSearchQuery),
          debounceTime(searchDebounceTime),
          tap((query) => this.store.dispatch(new SearchStockAction(query)))
        ).subscribe(),
      this.store
        .select(OutOfStoreService.items)
        .subscribe((items) => {
          this.formItems = OutOfStoreService.mergeValuesIntoSet(this.formItems)(items)
        })
    ];
  }

  typeaheadOnSelect(e: TypeaheadMatch): void {
    this.addOutOfStoreItem(e.item.id);
    this.searchChanged(this.searchedVal = '');
  }

  searchChanged(query: string) {
    this.searchStock.next(query);
  }

  closeModal() {
    this.close.emit();
  }

  addToProfile() {
    const tenantCustomerId = this.store.selectSnapshot(ProfileState.tenantCustomerId);
    this.store.dispatch(new OutOfStoreRequestAction(tenantCustomerId, this.formItems));
  }

  addOutOfStoreItem(itemId) {
    this.store.dispatch(new AddOutOfStoreItemAction(itemId));
  }

  removeItem(item) {
    if (!item.id) {
      item.id = item.stockId;
    }
    this.store.dispatch(new RemoveOutOfStoreItemAction(item.id));
  }

  getFormName(item: AdditionalItemStock, elementName: string) {
    return `${item.id}_${elementName}`;
  }

  ngOnDestroy() {
    this.subscriptions.forEach(x => x && x.unsubscribe());
  }
}
