<ng-container *ngIf="(cipher$ | async) as cipher">
  <div class="modal">
    <div class="modal-body text-center">
      <div>
        <span (click)="closeModal()" class="pull-right pointer">Close <i class="fas fa-times"></i></span>
      </div>
      <ng-container *ngIf="cipher.loading; else modalBody">
        <div class="display-inline-block">
          <app-loader></app-loader>
        </div>
      </ng-container>
      <ng-template #modalBody>
        <ng-container *ngIf="cipher.error; else code">
          <div>{{cipher.error}}</div>
        </ng-container>
      </ng-template>
      <ng-template #code>
        <h3>{{cipher.cipher}}</h3>
      </ng-template>
    </div>
  </div>
  <div class="modal-background">
  </div>
</ng-container>