import { NgxsModule } from '@ngxs/store';
import { AuthState } from './state/auth.state';
import { AuthService } from './auth.service';
import { NgModule } from '@angular/core';
import { AlertModule } from '../alert/alert.module';
import { CommonModule } from '@angular/common';
import { AuthResolver } from './auth.resolver';

@NgModule({
    imports: [
        AlertModule,
        CommonModule,
        NgxsModule.forFeature([AuthState])
    ],
    providers: [AuthService, AuthResolver]
})
export class AuthModule {}
