import { State, Action, Selector, StateContext } from "@ngxs/store";
import { NursingHome } from "../models/nursing-home";
import {
  GetNursingHomes,
  GetNursingHomesSuccess,
} from "./nursing-homes.actions";
import { NursingHomeService } from "../nursing-homes.service";
import { tap, catchError, finalize } from "rxjs/operators";
import { Injectable } from "@angular/core";

export class NursingHomeStateModel {
  loading: boolean;
  nursingHomes: NursingHome[];
}

@Injectable()
@State<NursingHomeStateModel>({
  name: "nursingHomes",
  defaults: {
    loading: false,
    nursingHomes: [],
  },
})
export class NursingHomeState {
  @Selector()
  static nursingHomes(state: NursingHomeStateModel) {
    return state.nursingHomes;
  }

  @Selector()
  static loading(state: NursingHomeStateModel) {
    return state.loading;
  }

  constructor(private nursingHomeService: NursingHomeService) {}

  @Action(GetNursingHomes)
  getNursingHomes(
    ctx: StateContext<NursingHomeStateModel>,
    {}: GetNursingHomes
  ) {
    ctx.patchState({ loading: false });

    return this.nursingHomeService.getNursingHomes().pipe(
      tap((response) => ctx.dispatch(new GetNursingHomesSuccess(response))),
      catchError((error) => error),
      finalize(() => ctx.patchState({ loading: false }))
    );
  }

  @Action(GetNursingHomesSuccess)
  getNursingHomesSuccess(
    ctx: StateContext<NursingHomeStateModel>,
    { nursingHomes }: GetNursingHomesSuccess
  ) {
    ctx.patchState({ nursingHomes });
  }
}
