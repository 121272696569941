import { ImmunisationHistoryStatementType } from '../../models/individual-history-statement.model';
import {
  SendStatementRequest,
  SendStatementSmsRequest,
} from '../../models/send-statement-request.model';

export enum AirStatementActionTypes {
  SEND_AIR_HISTORY_STATEMENT = '[statementHistory] SEND_AIR_HISTORY_STATEMENT',
  SEND_AIR_HISTORY_STATEMENT_SMS = '[statementHistory] SEND_AIR_HISTORY_STATEMENT_SMS',
  SEND_AIR_HISTORY_STATEMENT_SUCCESS = '[statementHistory] SEND_AIR_HISTORY_STATEMENT_SUCCESS',
  SEND_AIR_HISTORY_STATEMENT_ERROR = '[statementHistory] SEND_AIR_HISTORY_STATEMENT_ERROR',
  DOWNLOAD_AIR_HISTORY_STATEMENT = '[statementHistory] DOWNLOAD_AIR_HISTORY_STATEMENT',
  SET_AIR_HISTORY_STATEMENT = '[statementHistory] SET_AIR_HISTORY_STATEMENT',
  CLEAR_AIR_HISTORY_STATEMENT = '[statementHistory] CLEAR_AIR_HISTORY_STATEMENT',
  GOBACK_AIR_LOOKUP_FORMCONFIRM = '[statementHistory] GOBACK_AIR_LOOKUP_FORMCONFIRM',
  TOGGLE_AIR_LOOKUP_FORM = '[statementHistory] TOGGLE_AIR_LOOKUP_FORM',
}

export class SendAIRHistoryStatement {
  constructor(public request: SendStatementRequest) {}
  static readonly type = AirStatementActionTypes.SEND_AIR_HISTORY_STATEMENT;
}

export class SendAIRHistoryStatementSms {
  constructor(public request: SendStatementSmsRequest) {}
  static readonly type = AirStatementActionTypes.SEND_AIR_HISTORY_STATEMENT_SMS;
}

export class SendAIRHistoryStatementSuccess {
  constructor() {}
  static readonly type =
    AirStatementActionTypes.SEND_AIR_HISTORY_STATEMENT_SUCCESS;
}

export class SendAIRHistoryStatementError {
  constructor(public error: any) {}
  static readonly type =
    AirStatementActionTypes.SEND_AIR_HISTORY_STATEMENT_ERROR;
}

export class DownloadAIRHistoryStatement {
  constructor() {}
  static readonly type = AirStatementActionTypes.DOWNLOAD_AIR_HISTORY_STATEMENT;
}

export class SetAIRHistoryStatement {
  constructor(
    public statement: ImmunisationHistoryStatementType,
    public documentId?: number
  ) {}
  static readonly type = AirStatementActionTypes.SET_AIR_HISTORY_STATEMENT;
}

export class ClearAIRHistoryStatement {
  constructor() {}
  static readonly type = AirStatementActionTypes.CLEAR_AIR_HISTORY_STATEMENT;
}
export class GoBackAirLookupFormConfirm {
  constructor() {}
  static readonly type = AirStatementActionTypes.GOBACK_AIR_LOOKUP_FORMCONFIRM;
}

export class ToggleAirLookupForm {
  constructor(public slideMode: string) {}
  static readonly type = AirStatementActionTypes.TOGGLE_AIR_LOOKUP_FORM;
}
