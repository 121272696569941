import { State, Action, StateContext, Selector } from '@ngxs/store';
import { AddNewPharmacy, AddTenantInstallReport, CreateGlobalTemplate, GetTenantDetails, GetConnectionTypes } from './admin-dashboard.actions';
import { AdminDashboardService } from '../services/admin-dashboard.service';
import { catchError, finalize, tap } from 'rxjs/operators';
import { AlertService } from '~/modules/core/alert/alert.service';
import { TenantDetails } from '~/shared/models/tenant-details.model';
import { Injectable } from '@angular/core';
import { TenantUserService } from '~/shared/services/tenantUser.service';
import { ConnectionType } from '~/shared/models/connectionType.model';
import { Tenant } from '~/shared/models/tenant.model';
export class AdminDashboardStateModel {
  loading: boolean;
  loadingReport: boolean;
  tenants: TenantDetails[];
  tenant: Tenant;
  connectionTypes: ConnectionType[]
}
@Injectable()
@State<AdminDashboardStateModel>({
  name: 'adminDashboard',
  defaults: {
    loading: false,
    loadingReport: false,
    tenants: [],
    tenant: null,
    connectionTypes: []
  }
})
export class AdminDashboardState {
  constructor(private adminDashboardService: AdminDashboardService, private tenantUserService: TenantUserService, private alertService: AlertService) { }

  @Selector()
  static loading(state: AdminDashboardStateModel) { return state.loading }

  @Selector()
  static loadingReport(state: AdminDashboardStateModel) { return state.loadingReport }

  @Selector()
  static tenants(state: AdminDashboardStateModel) { return state.tenants; }

  @Selector()
  static tenant(state: AdminDashboardStateModel) { return state.tenant; }

  @Selector()
  static validConnectionTypes({ connectionTypes: validConnectionTypes }: AdminDashboardStateModel) { return validConnectionTypes;}

  @Action(AddNewPharmacy)
  addNewPharmacy(ctx: StateContext<AdminDashboardStateModel>, { newPharmacy }: AddNewPharmacy) {
    ctx.patchState({ loading: true });
    return this.adminDashboardService.newPharmacy(newPharmacy).pipe(
      tap(res => {
        ctx.patchState({ tenant: res });
        this.onSuccess('Success, new Pharmacy has been added to Scrypt');
        ctx.dispatch(new GetTenantDetails());
      }),
      catchError(err => this.onError(err)),
      finalize(() => ctx.patchState({ loading: false }))
    )
  }

  @Action(AddTenantInstallReport)
  addTenantInstallReport(ctx: StateContext<AdminDashboardStateModel>, { model }: AddTenantInstallReport) {
    ctx.patchState({ loadingReport: true });
    return this.adminDashboardService.tenantInstallReport(model).pipe(
      tap(res => this.onSuccess('Success, Install Report has been added to Scrypt')),
      catchError(err => this.onError(err)),
      finalize(() => ctx.patchState({ loadingReport: false }))
    )
  }

  @Action(GetTenantDetails)
  getByClient(ctx: StateContext<AdminDashboardStateModel>, action: GetTenantDetails) {
    ctx.patchState({ loading: false });
    return this.adminDashboardService.getTenantDetails()
      .pipe(
        tap((data: TenantDetails[]) =>
          ctx.patchState({ tenants: data })
        ),
        catchError(err => this.onError(err)),
      );
  }

  @Action(CreateGlobalTemplate)
  createGlobalTemplate(ctx: StateContext<AdminDashboardStateModel>, { messageTemplate }: CreateGlobalTemplate) {
    ctx.patchState({ loading: true });
    return this.adminDashboardService.createGlobalTemplate(messageTemplate)
      .pipe(
        tap(_ => {
          const tenantsCount = messageTemplate.tenantIds?.length ? messageTemplate.tenantIds.length.toString() : 'all';
          this.onSuccess(`New template created for ${tenantsCount} tenants`)
        }),
        catchError(err => this.onError(err)),
        finalize(() => ctx.patchState({ loading: false }))
      );
  }

  @Action(GetConnectionTypes)
  getConnectionTypes(ctx: StateContext<AdminDashboardStateModel>) {
    ctx.patchState({ loading: true });
    return this.tenantUserService.getConnectionTypes()
    .pipe(
      tap(res => {
        ctx.patchState({ connectionTypes: res })
      }),
      catchError(err => this.onError(err)),
      finalize(() => ctx.patchState({ loading: false }))
    );
  }

  private async onError(err: any) {
    this.alertService.error(JSON.stringify(err));
  }

  private async onSuccess(msg: string) {
    this.alertService.success(msg);
  }
}
