import { Component, OnInit, OnDestroy } from '@angular/core';
import { SickLeaveChildForm } from '../sick-leave-child-form';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Store, Actions } from '@ngxs/store';
import { SickLeavePatientDetailsState } from './state/sick-leave-patient-details.state';
import { SetTotalItemsAndCompletedItems } from './state/sick-leave-patient-details.actions';
import { CurrentLivingStatus } from '../../../../../shared/models/six-cpa/consent-and-eligibility-form.model';

import { SixCpaState } from '../../state/six-cpa.state';
const controlsToIgnore = ['dob'];

@Component({
  selector: 'app-sick-leave-patient-details',
  templateUrl: './sick-leave-patient-details.component.html',
  styleUrls: ['./sick-leave-patient-details.component.scss']
})

export class SickLeavePatientDetailsComponent extends SickLeaveChildForm implements OnInit, OnDestroy {

  sickLeavePatientDetailsForm: FormGroup;
  private readonlySubscription: Subscription;
  formSubscription: Subscription;
  livingStatus = CurrentLivingStatus;

  constructor(private formBuilder: FormBuilder, private store: Store, actions: Actions) {
    super(actions);
    this.generateFormControls();
  }

  ngOnInit() {
    this.formSubscription = this.store.select(SickLeavePatientDetailsState.sickLeavePatientDetailsForm)
      .subscribe(() => this.onFormChange());
    this.readonlySubscription = this.store.select(SixCpaState.formsReadOnly)
      .subscribe(readonly => readonly ? this.sickLeavePatientDetailsForm.disable() : this.sickLeavePatientDetailsForm.enable());
  }
  get formControls() {
    return this.sickLeavePatientDetailsForm.controls;
  }
  ngOnDestroy() {
    this.formSubscription.unsubscribe();
    this.readonlySubscription.unsubscribe();
  }

  protected actionOnResetForm() {
    const controls = Object.keys(this.sickLeavePatientDetailsForm.controls);
    controls.filter((key) => {
      if (!controlsToIgnore.includes(key)) {
        this.sickLeavePatientDetailsForm.controls[key].setValue('');
      }
      this.sickLeavePatientDetailsForm.patchValue(
        {
          currentLivingStatus: 0,
          concessionCard: false
        });
    });
  }

  private onFormChange() {
    const controls = Object.keys(this.sickLeavePatientDetailsForm.controls);
    const completedItems = this.getTotalValidItems(controls);
    this.store.dispatch(new SetTotalItemsAndCompletedItems(controls.length, completedItems));
  }

  private getTotalValidItems(controls: Array<string>) {
    return controls.filter((key) => {
      const formControl = this.sickLeavePatientDetailsForm.get(key);
      return  formControl.status === 'VALID' || (formControl.status === 'DISABLED' && !formControl.invalid && formControl.errors === null);
    }).length;
  }

  private generateFormControls() {
    this.sickLeavePatientDetailsForm = this.formBuilder.group({
      dob: new FormControl(null, Validators.required),
      firstName: new FormControl(null, Validators.required),
      surname: new FormControl(null, Validators.required),
      address: new FormControl(null, Validators.required),
    });
  }


}
