import { Trigger } from '../../../../../shared/models/pharmacy/trigger.model';

export enum AddEditTriggerModalActions {
  TOGGLE_MODAL = '[AddEditTriggerModal] Add item',
  SET_TRIGGER_FOR_EDIT = '[AddEditTriggerModal] Set trigger for edit',
  ADD_NEW_TRIGGER = '[AddEditTriggerModal] Add Trigger',
  ADD_NEW_TRIGGER_SUCCESS = '[AddEditTriggerModal] Add Trigger Success',
  UPDATE_TRIGGER = '[AddEditTriggerModal] Update Trigger',
  UPDATE_TRIGGER_SUCCESS = '[AddEditTriggerModal] Update Trigger Success',
  ERROR = '[AddEditTriggerModal] Error',
  HANDLE_ADD_UPDATE_EVENT = '[AddEditTriggerModal] Handle Add Update Event'
}

export class ToggleAddEditTriggerModal {
  static readonly type = AddEditTriggerModalActions.TOGGLE_MODAL;
  constructor(public showModal: boolean) { }
}

export class SetTriggerForEditing {
  static readonly type = AddEditTriggerModalActions.SET_TRIGGER_FOR_EDIT;
  constructor(public trigger: Partial<Trigger>) { }
}

export class AddTrigger {
  static readonly type = AddEditTriggerModalActions.ADD_NEW_TRIGGER;
  constructor() { }
}

export class UpdateTrigger {
  static readonly type = AddEditTriggerModalActions.UPDATE_TRIGGER;
  constructor() { }
}

export class AddTriggerSuccess {
  static readonly type = AddEditTriggerModalActions.ADD_NEW_TRIGGER_SUCCESS;
  constructor(public trigger: Trigger) { }
}

export class UpdateTriggerSuccess {
  static readonly type = AddEditTriggerModalActions.UPDATE_TRIGGER_SUCCESS;
  constructor(public trigger: Trigger) { }
}

export class TriggerModalStateError {
  static readonly type = AddEditTriggerModalActions.ERROR;
  constructor(public error: any) { }
}

export class HandleAddEditEvent {
  static readonly type = AddEditTriggerModalActions.HANDLE_ADD_UPDATE_EVENT;
  constructor() { }
}
