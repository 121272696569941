import { State, Selector, Action, StateContext } from '@ngxs/store';
import { IGPDetailsReferralFormStateModel } from './hmr-gp-details-referral-form-state-model.interface';
import { UpdateHMRGPDetailsReferralForm } from './hmr-gp-details-referral-form.actions';
import * as moment_ from 'moment';
const moment = moment_;
import { Injectable } from '@angular/core';

export interface IHMRGPDetailsReferralFormBasicStateModel extends IGPDetailsReferralFormStateModel {
    referralDate: Date | string | null;
  }

  export class HMRGPDetailsReferralFormBasicStateModel {
    gpDetailsReferralForm: {
      model: Partial<IHMRGPDetailsReferralFormBasicStateModel>;
    };
  }

  @State<HMRGPDetailsReferralFormBasicStateModel>({
    name: 'hmrGPDetailsReferralFormBasic',
    defaults: {
      gpDetailsReferralForm: {
        model: undefined
      }
    }
  })
  @Injectable()
  export class HMRGPDetailsReferralFormBasicState {
    @Selector()
    static form(state: HMRGPDetailsReferralFormBasicStateModel) { return state.gpDetailsReferralForm.model; }

    @Action(UpdateHMRGPDetailsReferralForm)
    updateForm(ctx: StateContext<HMRGPDetailsReferralFormBasicStateModel>, { form }: UpdateHMRGPDetailsReferralForm) {
      if (form) {
      const {firstName, surname, providerNumber, referralDate} = form;

      ctx.patchState({
        gpDetailsReferralForm: {
          model: {
            firstName,
            providerNumber,
            referralDate: moment(referralDate, 'YYYY-MM-DD').toDate(),
            surname
          }
        }
      });
    } else {
      const state = ctx.getState();
      ctx.patchState({
        gpDetailsReferralForm: {
          model: {
            ...state.gpDetailsReferralForm.model,
            providerNumber: null,
            referralDate: null
          }
        }
      });
    }
    }
  }
