import { NoteTypeEnum } from '../../models/note/note-type.enum';
import { StringsService } from '../strings/strings.service';

export class NoteTypeService {
    static getFromName(name: string) {
        if (typeof name !== 'string') {
            throw new Error('NoteTypeService.getFromNam: requires name parameter to be of type string.');
        }
        return NoteTypeEnum[StringsService.camelize(name)] as any as NoteTypeEnum;
    }

    static shouldMarkAsActionedWhenViewed(noteType: NoteTypeEnum) {
        switch (noteType) {
            case NoteTypeEnum.sms:
            case NoteTypeEnum.smsReply:
            case NoteTypeEnum.email:
            case NoteTypeEnum.emailReply:
            case NoteTypeEnum.mail:
            case NoteTypeEnum.phoneCall:
                return true;
            default:
                return false;
        }
    }

    static getNoteTypeVariants(type: NoteTypeEnum) {
        switch (type) {
            case NoteTypeEnum.sms:
            case NoteTypeEnum.smsReply:
                return [NoteTypeEnum.sms, NoteTypeEnum.smsReply];
            case NoteTypeEnum.email:
            case NoteTypeEnum.emailReply:
                return [NoteTypeEnum.email, NoteTypeEnum.emailReply];
            default:
                return[type];
        }
    }
}
