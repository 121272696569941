import { Component, OnInit } from '@angular/core';
import { DAAChildForm } from '../daa-child-form';
import { DAAMedicationProfileState } from './state/daa-medication-profile.state';
import { Store, Actions, Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { MedicineCategory, Medicine } from '../../../../../shared/models/six-cpa/medicine.model';
import { ToggleDAAMedicationProfileListItemAction, ToggleAllDAAMedicationProfileListAction, ToggleDAAMedicationProfileAction } from './state/daa-medication-profile.actions';
import { SelectMedicationAction, ToggleAddEditMedicationAction } from '../../add-edit-medication/state/add-edit-medication.actions';
import { ToggleSixCpaContentAction } from '../../state/six-cpa.actions';
import { SixCpaState } from '../../state/six-cpa.state';

@Component({
  selector: 'app-daa-medication-profile',
  templateUrl: './daa-medication-profile.component.html',
  styleUrls: ['./daa-medication-profile.component.scss']
})
export class DaaMedicationProfileComponent extends DAAChildForm implements OnInit {

  constructor(private store: Store, actions: Actions) {
    super(actions);
  }
  @Select(DAAMedicationProfileState) daaMedicationProfile$: Observable<any>;
  @Select(DAAMedicationProfileState.categorisedMedicines) categorisedMedicines$: Observable<MedicineCategory[]>;
  @Select(DAAMedicationProfileState.selectedMedicineCount) selectedMedicines$: Observable<any>;
  @Select(DAAMedicationProfileState.showMode) showMode$: Observable<string>;
  @Select(SixCpaState.readonly) readonly$: Observable<boolean>;
  
  ngOnInit() {
  }

  protected actionOnResetForm() {
    this.selectAll(false);
  }

  select(item: Medicine) {
    this.store.dispatch(new ToggleDAAMedicationProfileListItemAction(item));
  }

  selectAll(isSelected: boolean, categoryId?: number) {
    this.store.dispatch(new ToggleAllDAAMedicationProfileListAction(isSelected, categoryId));
  }
  checkIsSelectedByCategory(medicationsByCategory: Medicine[]) {
    return medicationsByCategory.some(x => !x.isSelected);
  }
  updateMedication(item: Medicine) {
    this.store.dispatch(new SelectMedicationAction(item));
    this.addEditMedication();
  }

  addEditMedication() {
    this.store.dispatch([new ToggleSixCpaContentAction('out'), new ToggleAddEditMedicationAction('in')]);
  }

  modeChanged(mode) {
    this.store.dispatch(new ToggleDAAMedicationProfileAction(mode));
  }
}
