import { State, Selector, Action, StateContext } from '@ngxs/store';
import { SetProfileForSixCpaAction } from '../../state/six-cpa.actions';
import { UpdatePatientDetailsForm } from './patient-details-form.actions';
import { IPatientDetailsFormStateModel } from './patient-details-form-state-model.interface';
import * as moment_ from 'moment';
const moment = moment_;
import { TenantDatePipe } from '../../../../core/timezone/pipes/tenant-date.pipe';
import { getMedicareDVAOrRepatNumber } from '../../../../../shared/helpers/patient.helper';
import { Injectable } from '@angular/core';

export interface IPatientDetailsFormDetailedStateModel extends IPatientDetailsFormStateModel {
  gender: string;
  residentialPostCode: string;
  englishAtHome: boolean | null;
  isPatientAboriginalOrTSI: boolean | null;
  patientHasDiabetes: boolean | null;
}

export class PatientDetailsFormDetailedStateModel {
  patientDetailsForm: {
    model: Partial<IPatientDetailsFormDetailedStateModel>;
  };
}
@Injectable()
@State<PatientDetailsFormDetailedStateModel>({
  name: 'patientDetailsFormDetailed',
  defaults: {
    patientDetailsForm: {
      model: undefined
    }
  }
})
export class PatientDetailsFormDetailedState {
  constructor(private tenantPipe: TenantDatePipe) {}
  @Selector()
  static form(state: PatientDetailsFormDetailedStateModel) { return state.patientDetailsForm.model; }

  @Selector()
  static patientHasDiabetes(state: PatientDetailsFormDetailedStateModel) { return state.patientDetailsForm.model.patientHasDiabetes; }

  @Action(SetProfileForSixCpaAction)
  onSetProfile(ctx: StateContext<PatientDetailsFormDetailedStateModel>, { profile }: SetProfileForSixCpaAction) {
    const patient = profile.patient;
    ctx.patchState({
      patientDetailsForm: {
        model: {
          patientFirstName: patient.firstname,
          patientSurname: patient.surname,
          patientDOB: patient.birthDate && moment(this.tenantPipe.transform(patient.birthDate.toString(), 'YYYY-MM-DD'), 'YYYY-MM-DD').toDate(),
          medicareNumber: getMedicareDVAOrRepatNumber(patient),
          gender: (patient.gender !== 'M' && patient.gender !== 'F') ? null : patient.gender,
          residentialPostCode: (patient && patient.homePostcode) ? patient && patient.homePostcode.toString() : null
        }
      }
    });
  }

  @Action(UpdatePatientDetailsForm)
  updateForm(ctx: StateContext<PatientDetailsFormDetailedStateModel>, { form }: UpdatePatientDetailsForm) {
    ctx.patchState({
      patientDetailsForm: {
        model: {
          patientFirstName: form.firstName,
          patientSurname: form.surname,
          patientDOB: form.dob && moment(form.dob, 'YYYY-MM-DD').format('DD-MM-YYYY'),
          medicareNumber: form.medicareNumber,
          gender: form.gender,
          residentialPostCode: form.residentialPostCode,
          englishAtHome: form.englishAtHome,
          isPatientAboriginalOrTSI: form.aboriginalOrTorresStraitIslander,
          patientHasDiabetes: form.patientHasDiabetes,
          reviewed: form.reviewed
        }
      }
    });
  }
}
