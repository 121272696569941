import * as moment from 'moment';

export function combineDateAndTime(date: Date, time: Date): moment.Moment{
  const now = moment(new Date());
    const datePart = date ? moment(date) : now;
    const timePart = time ? moment(time) : now;

    return datePart.set({
        hours: timePart.get('hour'),
        minute: timePart.get('minute'),
        second: 0,
        millisecond: 0,
    });
}
