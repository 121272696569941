export enum SixCpaUserCommunicationsActions {
  SET_SIX_CPA_ERRORS = '[SixCpaUserCommunications] Set Six Cpa Errors',
  SET_SIX_CPA_SUCCESS_MESSAGES = '[SixCpaUserCommunications] Set Six Cpa Success Messages',
  CLEAR_SIX_CPA_COMMUNICATIONS = '[SixCpaUserCommunications] Clear Comms',
  SET_SIX_CPA_INFO = '[SixCpaUserCommunications] Set Six Cpa Info',
}


export class SetSixCpaErrors {
  static readonly type = SixCpaUserCommunicationsActions.SET_SIX_CPA_ERRORS;
  constructor(public errors: any[]) { }
}

export class SetSixCpaInfo {
  static readonly type = SixCpaUserCommunicationsActions.SET_SIX_CPA_INFO;
  constructor(public info: any[]) { }
}

export class SetSixCpaSuccessMessages {
  static readonly type = SixCpaUserCommunicationsActions.SET_SIX_CPA_SUCCESS_MESSAGES;
  constructor(public successMessages: any[]) { }
}

export class ClearSixCpaCommunications {
  static readonly type = SixCpaUserCommunicationsActions.CLEAR_SIX_CPA_COMMUNICATIONS;
  constructor() { }
}
