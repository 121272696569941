import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class NotesScrollService {
  static eventStream: Subject<any> = new Subject<any>();
  nextPage() {
    NotesScrollService.eventStream.next();
  }
  listen() {
    return NotesScrollService.eventStream.asObservable();
  }
}
