import { Email } from "~/shared/models/email.model";

export enum EmailActions {
  GET_EMAIL_HISTORY = '[Profile Comms Email] Get Email History',
  SEND_EMAIL = '[Profile Comms Email] Send Email',
  TOGGLE_MODAL = '[Profile Comms Email] Toggle modal'
}

export class GetEmailHistory {
  static readonly type = EmailActions.GET_EMAIL_HISTORY;
  constructor(public clientId: string) { }
}

export class SendEmail {
  static readonly type = EmailActions.SEND_EMAIL;
  constructor(public email: Email) { }
}

export class ToggleModal {
  static readonly type = EmailActions.TOGGLE_MODAL;
  constructor(public showModal: boolean) { }
}
