import { State, Selector, Action, StateContext } from '@ngxs/store';
import { DoctorReportModel } from '../../../models/six-cpa/doctor-report.model';
import { SetCustomisedDoctorReportData } from './customised-doctor-report.actions';
import { Injectable } from '@angular/core';

export class CustomisedDoctorReportStateModel {
  customisedDoctorReport: {
    model: Partial<DoctorReportModel>
  }
}
@Injectable()
@State<CustomisedDoctorReportStateModel>({
  name: 'customisedDoctorReport',
  defaults: {
    customisedDoctorReport: {
      model: undefined
    }
  }
})
export class CustomisedDoctorReportState {

  @Selector()
  static customisedDoctorReport(state: CustomisedDoctorReportStateModel) { return state.customisedDoctorReport.model; }

  @Action(SetCustomisedDoctorReportData)
  setCustomisedDoctorReportData(ctx: StateContext<CustomisedDoctorReportStateModel>, { report }: SetCustomisedDoctorReportData) {
    report.reportBody = report.reportBody.replace(/(?:\r\n|\r|\n)/g, '<br>')
    ctx.patchState({
      customisedDoctorReport: {
        model: report
      }
    });
  }
}
