<div g-xs="p3 my3" class="bg__grey">
  <form #form="ngForm" (ngSubmit)="submit()">
    <div g-xs="my1" class="form-group">
      <span class="font__bold">Tenant: </span>
      <select class="form-control" [(ngModel)]="marketingItem.tenantId" name="tenant" [disabled]="isReadonlyTenant()">
        <option *ngFor="let tenant of tenants" [value]="tenant.id">{{tenant.name}}</option>
      </select>
    </div>
    <div g-xs="my1"><span class="font__bold">Template Name: </span>
      <input class="form-control" [(ngModel)]="marketingItem.key" name="marketingKey"
        [disabled]="marketingItem.marketingType != 11 && marketingItem.marketingType != 13 && marketingItem.marketingType != 14" />
    </div>
    <div g-xs="my1"><span class="font__bold">Type:</span>
      <select class="form-control" [(ngModel)]="marketingItem.marketingType" name="marketingTypeName"
        (change)="isTypeWithInterval(marketingItem)">
        <option *ngFor="let type of marketingTypes" [value]="type">{{getMarketingTypeName(type)}}</option>
      </select>
    </div>
    <div class="form-group" *ngIf="marketingTypeRequiresInterval"
      [ngClass]="{'has-error': interval.invalid && interval.touched}">
      <label class="font__bold font__title-1" for="interval">Interval(days)</label>
      <input type="number" id="interval" class="form-control" #interval="ngModel" name="interval" required
        [(ngModel)]="marketingItem.interval" />
      <span *ngIf="interval.invalid && interval.touched" class="form-help-text">Required field</span>
    </div>

    <div class="form-group">
        <label class="font__bold font__title-1" for="subject">Subject:</label>
        <input id="subject" class="form-control" #template="ngModel" name="subject"
          [(ngModel)]="marketingItem.subject" />
      </div>

    <div class="form-group" [ngClass]="{'has-error': template.invalid && template.touched}">
      <label class="font__bold font__title-1" for="template">Template</label>
      <textarea id="template" rows="5" class="form-control" required #template="ngModel" name="template"
        [(ngModel)]="marketingItem.messageTemplate">
            </textarea>
      <span *ngIf="template.invalid && template.touched" class="form-help-text">Required field</span>

      <div class="d-flex" style="justify-content: center;">
        <div class="p-2" *ngFor="let tag of mergeTags">
          <div class="btn btn-outline-dark" 
            *ngIf="tag.key != 'RepeatTokenLink'" 
            (click)="insertTag(tag.value)">{{tag.key}}
        </div>
        <div class="btn btn-outline-dark" 
            *ngIf="marketingItem.marketingType == 14 && tag.key == 'RepeatTokenLink'" 
            (click)="insertTag(tag.value)">{{tag.key}}
        </div>
        </div>
        <div class="p-2">
          <div class="btn btn-info" (click)="toggleInformation()">More Info</div>
        </div>
      </div>
    </div>

    <div g-xs="my1" class="alert alert-info" role="alert" *ngIf="showInformation">
      <p class="font__bold">What are merge tags?</p>
      <div>
        <p>
          Merge tags are quick tags that we will dynamically replace in SMS, Email & Doctor Report Templates with the right details for you.<br />
          This makes your templates much more reusable and saves time!<br />
          An example of this is that the {{tagExample}} tag will become 'John' when this template is used.
        </p>
        <br />
        <p class="font__bold">
          Sample:
        </p><br />
        <p class="font-italic">
          Before:
        </p>
        <p>
          {{mergeTagExample}}
        </p>
        <p class="font-italic">
          After:
        </p>
        <p>
          {{mergeTagLive}}
        </p>
      </div>
    </div>

    <div class="btn-group pt-2">
      <button type="submit" class="btn btn-primary" [disabled]="form.invalidinvalid">Save</button>
      <button class="btn btn-danger" (click)="cancel()">Cancel</button>
    </div>
    <div class="btn-group pt-2 float-right" *ngIf="marketingItem.messageTemplate?.length">
        <div class="btn btn-outline-primary">Message length: {{marketingItem.messageTemplate?.length}} | Total Messages: {{getMessageCount(marketingItem.messageTemplate?.length)}}</div>
    </div>
  </form>
</div>
