<ng-container *ngIf="(pausedMedications$ | async) as pausedMedications">
  <h3>Current Active & Pending Pause(s)</h3>
  <div class="collapse-container mt-3">
    <table class="table category-table">
      <tbody>
        <tr>
          <th>Name</th>
          <th>Start Date</th>
          <th>End Date</th>
          <th>Reason</th>
          <th>Type</th>
          <th>Status</th>
        </tr>
      </tbody>

      <tbody *ngFor="let pause of pausedMedications">
        <td>{{getDrugName(pause.metaId)}}</td>
        <td>{{pause.startDate | date}}</td>
        <td><span *ngIf="pause.endDate">{{pause.endDate | date}}</span><span *ngIf="!pause.endDate"></span>None</td>
        <td>{{pause.reason}}</td>
        <td>{{getPauseType(pause.type)}}</td>
        <td>{{getPauseStatus(pause.status)}}</td>
      </tbody>
    </table>
  </div>
  <button class="button btn__green mt-2" (click)="resumeAllMedicines()">Resume All</button>
  <div class="mt-2">
    <span><strong>Note: </strong>You can also resume a medication individually by clicking on the medication on the
      patients profile.</span><br />
      <span>This action will end all current and pending pauses for these medicines.</span>
  </div>
</ng-container>
