import { Component, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { SubmitCIAction, ResetCIAction, SaveCIAction, UpdateCIForm, SubmitExisitingCI, ShowInvalidCIForm } from './state/clinical-intervention.actions';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { ClinicalInterventionFormsService } from './state/clinical-intervention-forms.service';
import { filter, switchMap, map, withLatestFrom } from 'rxjs/operators';
import { forkJoin } from 'rxjs';
import { ProfileState } from '../../../core/profile/state/profile.state';
import { WindowService } from '../../../../shared/services/window.service';
import { SaveOrUpdateCIAction } from './state/clinical-intervention-save.actions';
// import { environment } from '../../../../../environments/environment';

import { SixCpaState } from '../state/six-cpa.state';
import { Observable } from 'rxjs';
@Component({
  selector: 'app-clinical-intervention',
  templateUrl: './clinical-intervention.component.html',
  styleUrls: ['./clinical-intervention.component.scss']
})
export class ClinicalInterventionComponent implements OnInit {
  @Select(SixCpaState.readonly) readonly$: Observable<boolean>;
  constructor(private store: Store, private windowService: WindowService) { }

  ngOnInit() {

  }

  openPPAPortal() {
    // this.windowService.open(environment.ppaPortalLink);
    this.windowService.open('');
  }

  @Dispatch()
  submitForm = () => {
    if (this.store.selectSnapshot(ClinicalInterventionFormsService.getProgress) !== 100) {
      this.store.dispatch(new ShowInvalidCIForm());
    }
    return this.store.selectOnce(ClinicalInterventionFormsService.getProgress).pipe(
      filter(progress => progress === 100),
      switchMap(() => forkJoin([
        this.store.selectOnce(ClinicalInterventionFormsService.getClinicalInterventionRequest),
        this.store.selectOnce(ProfileState.clientId)
      ])),
      map(([formData, clientId]) =>
        formData && formData.sixCpaFormId !== null
          ? new SubmitExisitingCI(clientId, formData)
          : new SubmitCIAction(clientId, formData)));
    }
  @Dispatch()
  saveForm = () => new SaveOrUpdateCIAction();

  resetForm() {
    this.store.dispatch(new ResetCIAction());
  }
}
