import { Component, OnInit, OnDestroy } from '@angular/core';
import * as moment_ from 'moment';
const moment = moment_;
import { Subscription } from 'rxjs/Subscription';
import { Note } from '../../../shared/models/crm/note.model';
import { RepeatType } from '../../../shared/models/profile/repeatType.model';
import { NoteType } from '../../../shared/models/crm/noteType.model';
import { Select, Store } from '@ngxs/store';
import { combineLatest, Observable } from 'rxjs';
import { NotesState, NoteFilter } from './state/notes.state';
import { ToggleRequiresAction, ToggleUpcoming, ToggleHistory, RemoveNote, ActionClientNote, ResetOrdersCycle } from './state/notes.actions';
import { ProfileState } from '../../../modules/core/profile/state/profile.state';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { NoteTypeEnum } from '../../../shared/models/note/note-type.enum';
import { extractEScriptFromNote } from '../../../shared/helpers/eScript.helper';
import { SetEScriptToken, ToggleEScriptModal, SetEScriptNote, DispensePaperlessScript } from '../../../shared/components/escripts/state/escripts.actions';
import { ConnectionTypeEnum } from '../../../shared/models/connectionType.model';
import { PharmacyLocation } from '../../../shared/models/pharmacy/pharmacy-address.model';
import { DispensePaperlessRequest } from '../../../shared/models/lite/paperlessDispense.model';
import { PharmacyService } from '../../../shared/services/pharmacy.service';

@Component({
  selector: 'app-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.scss']
})
export class NotesComponent implements OnInit, OnDestroy {
  @Select(ProfileState.profile) profile$: Observable<any>;
  @Select(NotesState.showRequiresAction) showRequiresAction$: Observable<boolean>;
  @Select(NotesState.showUpcoming) showUpcoming$: Observable<boolean>;
  @Select(NotesState.showHistory) showHistory$: Observable<boolean>;
  @Select(NotesState.clientNotes) clientNotes$: Observable<Note[]>;
  @Select(NotesState.noteTypes) noteTypes$: Observable<NoteType[]>;
  @Select(NotesState.loading) loading$: Observable<boolean>;
  @Select(NotesState.noteFiltersForm) noteFiltersForm$: Observable<NoteFilter>;
  isLoaded = false;
  actionNoteSubscription: Subscription;
  noteList: Note[] = [];
  modifiedNotes: Note[] = [];
  todayDate = moment(new Date()).format('YYYY-MM-DD');
  noteTypeList: NoteType[] = [];
  newNoteTypeList: NoteType[] = [];
  repeatType = RepeatType;
  noteFiltersForm: FormGroup;
  pharmacy: PharmacyLocation;
  pharmacySubscription: Subscription;
  currentNote: Note;
  confirmNoteRemoval = false;
  confirmOrderCycleReset = false;

  constructor(
    private store: Store,
    private formBuilder: FormBuilder,
    private pharmacyService: PharmacyService
  ) { }

  ngOnInit() {
    this.pharmacySubscription = this.pharmacyService.getPharmacyLocation().subscribe(pharmacy => {
      this.pharmacy = pharmacy;
    });
    this.generateFormControls();
    combineLatest([this.clientNotes$, this.noteTypes$])
      .subscribe((data: [Note[], NoteType[]]) => {
        this.noteList = data[0];
        this.noteTypeList = data[1];
        this.isLoaded = true;
      });
  }

  toggleRequiresAction() {
    this.store.dispatch(new ToggleRequiresAction());
  }

  detectURLs(str) {
    var exp = /(((https?:\/\/)|(www\.))[^\s]+)/g;
    return str?.replace(exp, '<a href="$1" target=_blank>$1</a>');
  }

  toggleUpcoming() {
    this.store.dispatch(new ToggleUpcoming());
  }

  toggleHistory() {
    this.store.dispatch(new ToggleHistory());
  }

  actionNote(note: Note) {
    if (note.type === NoteTypeEnum.order && moment(note.nextActionDate).isBefore(new Date(), 'days'))
    {
      this.currentNote = note;
      this.confirmOrderCycleReset = true;
      return;
    }

    this.actionClientNote(note);
  }

  daysUntilNextDueDate(note: Note)
  {
    var nextDate = note.nextActionDate;
    var today = new Date();
    while(moment(nextDate).isBefore(today))
    {
      switch(note.repeatType)
      {
        case 1:
          nextDate = moment(nextDate).add(note.intervalValue, "days").toDate();
          break;
        case 2:
          nextDate = moment(nextDate).add(note.intervalValue, "weeks").toDate();
          break;
        case 3:
          nextDate = moment(nextDate).add(note.intervalValue, "months").toDate();
          break;
      }
    }

    var daysUntilNextDue = moment(nextDate).diff(moment(today), 'days');
    return daysUntilNextDue;
  }

  actionClientNote(note: Note)
  {
    this.isLoaded = false;
    this.store.dispatch(new ActionClientNote(note, note.clientId));
  }

  setModifiedNote(item: Note) {
    if(item.type == NoteTypeEnum.eScript) {
       this.store.dispatch(
        new SetEScriptNote(item.id));
      return this.openEScriptModal(item.body, item.id);
    }

    if (this.modifiedNotes.length === 0) {
      this.modifiedNotes = [item];
    } else {
      if (!this.modifiedNotes.find(x => x === item)) {
        this.modifiedNotes = [];
        setTimeout(() => {
          this.modifiedNotes = [item];
        }, 100);
      }
    }
  }

  openEScriptModal(message: string, noteId: number) {
    const pharmacyType = this.pharmacy.connectionType;
    const scriptToken = extractEScriptFromNote(message);

    switch(pharmacyType) {
      case ConnectionTypeEnum.ZDispense: {
        return this.store.dispatch([new SetEScriptToken(scriptToken), new ToggleEScriptModal(true)]);
      }
      case ConnectionTypeEnum.FredDispense: {
        alert('This feature is unavailable for FRED Dispense');
        break;
      }
      case ConnectionTypeEnum.FredNXTDispense: {
        alert('This feature is unavailable for FRED NXT Dispense');
        break;
      }
      case ConnectionTypeEnum.Aquarius: {
        this.dispenseAquariusMedication(scriptToken, noteId);
        break;
      }
      default: {
        alert('This feature is unavailable for your dispense system');
      }
    }
  }

  dispenseAquariusMedication(token: string, noteId: number): void {
    const patient = this.store.selectSnapshot(ProfileState.profile).patient;
    const request: DispensePaperlessRequest = {
      token: token,
      drugName: '',
      firstName: patient.firstname,
      lastName: patient.surname,
      prescriberName: ''
    };
    this.store.dispatch(new DispensePaperlessScript(request, noteId));;
  }

  removeNote(note: Note) {
    if (note.type == NoteTypeEnum.order) {
      this.currentNote = note;
      this.confirmNoteRemoval = true;
    }
    else {
      this.store.dispatch(new RemoveNote(note.id));
    }
  }

  confirmCycleReset() {
    const note = this.currentNote;
    this.actionClientNote(note);
    this.actionNoteSubscription = this.store.dispatch(new ResetOrdersCycle(note.tenantCustomerId)).subscribe(() => {
      window.location.reload();
    });
    this.dismiss();
  }

  confirmMaintainCycle() {
    const note = this.currentNote;
    this.actionClientNote(note);
    this.dismiss();
  }

  confirmRemoval() {
    this.confirmNoteRemoval = false;
    this.actionNoteSubscription = this.store.dispatch(new RemoveNote(this.currentNote.id)).subscribe(() => {
      window.location.reload();
    })
    this.currentNote = null;
  }

  dismiss() {
    this.confirmNoteRemoval = false;
    this.confirmOrderCycleReset = false;
    this.currentNote = null;
  }

  goToLink = (item: Note) => {
    window.location.href = item.link;
  }

  clearModifiedNote() {
    this.modifiedNotes = [];
  }

  ngOnDestroy() {
    if (this.actionNoteSubscription) { this.actionNoteSubscription.unsubscribe(); }
    if (this.pharmacySubscription) { this.pharmacySubscription.unsubscribe() }
  }

  private generateFormControls() {
    this.noteFiltersForm = this.formBuilder.group({
      fromDate: new FormControl(null),
      toDate: new FormControl(null)
    });
  }
}
