import { NgModule } from '@angular/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { TimezoneModule } from '~/modules/core/timezone/timezone.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { LoaderModule } from '~/modules/core/loader/loader.module';
import { BulkMessagingPageComponent } from './bulk-messaging-page.component';
import { BulkMessagingResolver } from './resolvers/bulk-messaging.resolver';
import { BulkMessagingMessagesTableComponent } from './bulk-messaging-messages-table/bulk-messaging-messages-table.component';
import { NotificationModule } from '../../shared/components/notification/notification.module';
import { ScryptInsightModule } from '~/shared/scrypt-insight/scrypt-insight.module';
import { AdvancedSearchResolver } from '../advanced-search/advanced-search.resolver';
import { BulkMessagingStateModule } from './state/bulk-messaging-state.module';
import { TooltipModule } from 'ngx-bootstrap';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzTimePickerModule } from 'ng-zorro-antd/time-picker';
import { RouterModule } from '@angular/router';
import { BulkMessagingRecipientsModalModule } from './bulk-messaging-recipients-modal/bulk-messaging-recipients-modal.module';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NewBulkMessageModule } from './new-bulk-message/new-bulk-message.module';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzNotificationService } from 'ng-zorro-antd/notification';

@NgModule({
  declarations: [
    BulkMessagingPageComponent,
    BulkMessagingMessagesTableComponent
  ],
  imports: [
    NotificationModule,
    NgSelectModule,
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
    BsDatepickerModule.forRoot(),
    BulkMessagingStateModule,
    LoaderModule,
    TimezoneModule,
    ScryptInsightModule,
    TooltipModule.forRoot(),
    NzDatePickerModule,
    NzTimePickerModule,
    RouterModule,
    BulkMessagingRecipientsModalModule,
    NzTableModule,
    NewBulkMessageModule,
    NzToolTipModule,
    NzButtonModule
  ],
  exports: [
    BulkMessagingPageComponent
  ],
  providers: [
    BulkMessagingResolver,
    AdvancedSearchResolver,
    NzNotificationService
  ],
  
})
export class BulkMessagingPageModule { }
