import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import { ReactiveFormsModule } from '@angular/forms';
import { InlineInputEditComponent } from './inline-input-edit.component';
import { AutofocusDirective } from './autofocus.directive';

@NgModule({
  declarations: [
    InlineInputEditComponent,
    AutofocusDirective
  ],
  imports: [
    CommonModule,
    NgxsFormPluginModule,
    ReactiveFormsModule
  ],
  exports: [
    InlineInputEditComponent
  ]
})
export class InlineInputEditModule { }
