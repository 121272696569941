import { Router, NavigationStart, NavigationEnd, NavigationCancel, NavigationError, Event } from '@angular/router';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

export function isLoadingRoute(router: Router, currentValue: boolean): Observable<boolean> {
    return router.events.pipe(map((event) => shouldBeloading(event, currentValue)));
}

export function shouldBeloading(event: Event, currentValue: boolean) {
  switch (true) {
    case event instanceof NavigationStart:
      return true;

    case event instanceof NavigationEnd:
    case event instanceof NavigationCancel:
    case event instanceof NavigationError:
      return false;

    default:
      return currentValue;
  }
}
