<form [formGroup]="daaMedicationInformationForm" ngxsForm="daaMedicationInformationForm.daaMedicationInformationForm"
    class="p-4">
    <div class="form-group pb-1">
        <label class="input-label-not-required" for="">Total Number of Medicines</label>
        <div class="form-row">
            <div class="col-3">
                <p class="col-form-label-lg">Prescription</p>
                <input type="text"
                    [class.has-error]="formControls.prescriptionMedicines.invalid && (showInvalid$ | async)"
                    formControlName="prescriptionMedicines" class="form-control form-control-lg" placeholder="0">
            </div>
            <div class="col-3">
                <p class="col-form-label-lg">Non-Prescription</p>
                <input type="text"
                    [class.has-error]="formControls.nonPrescriptionMedicines.invalid && (showInvalid$ | async)"
                    formControlName="nonPrescriptionMedicines" class="form-control form-control-lg" placeholder="0">
            </div>
        </div>
    </div>
    <div class="form-group pb-1"
        [class.has-error-group]="formControls.historyOfNonAdherence.invalid && (showInvalid$ | async)">
        <label class="input-label mb-0">Does the patient have a history of non-adherence?</label><br />
        <app-radio-highlight controlName="historyYes"
            [isHighlighted]="!!daaMedicationInformationForm.value.historyOfNonAdherence">
            <input class="form-check-input" type="radio" name="historyOfNonAdherence" id="historyYes"
                formControlName="historyOfNonAdherence" [value]="true">
            <label class="form-check-label" for="historyYes">Yes</label>
        </app-radio-highlight>
        <app-radio-highlight controlName="historyNo"
            [isHighlighted]="!daaMedicationInformationForm.value.historyOfNonAdherence">
            <input class="form-check-input" type="radio" name="historyOfNonAdherence" id="historyNo"
                formControlName="historyOfNonAdherence" [value]="false" checked>
            <label class="form-check-label" for="historyNo">No</label>
        </app-radio-highlight>
    </div>
    <div class="form-group pb-1">
        <label class="input-label mb-0">Is the patient experiencing difficulties with medication management?</label>
        <app-radio-highlight
            [hasError]="formControls.difficultiesWithMedicationManagement.invalid && (showInvalid$ | async)"
            controlName="difficultiesYes"
            [isHighlighted]="!!daaMedicationInformationForm.value.difficultiesWithMedicationManagement">
            <input class="form-check-input" type="radio" name="difficultiesWithMedicationManagement"
                id="difficultiesYes" formControlName="difficultiesWithMedicationManagement" [value]="true">
            <label class="form-check-label" for="difficultiesYes">Yes</label>
        </app-radio-highlight>
        <app-radio-highlight
            [hasError]="formControls.difficultiesWithMedicationManagement.invalid && (showInvalid$ | async)"
            controlName="difficultiesNo"
            [isHighlighted]="!daaMedicationInformationForm.value.difficultiesWithMedicationManagement">
            <input class="form-check-input" type="radio" name="difficultiesWithMedicationManagement" id="difficultiesNo"
                formControlName="difficultiesWithMedicationManagement" [value]="false" checked>
            <label class="form-check-label" for="difficultiesNo">No</label>
        </app-radio-highlight>
    </div>
    <div class="form-group pb-1"
        [class.has-error-group]="formControls.daaEligibileDisability.invalid && (showInvalid$ | async)">
        <label class="input-label mb-0">Does the patient have a disability that makes them eligible for a DAA?</label>
        <div class="form-check form-check">
            <input class="form-check-input" type="radio" name="daaEligibileDisability" id="no"
                formControlName="daaEligibileDisability" [value]="daaDisabilityTypes.No">
            <label class="form-check-label" for="no">No</label>
        </div>
        <div class="form-check form-check">
            <input class="form-check-input" type="radio" name="daaEligibileDisability" id="physical"
                formControlName="daaEligibileDisability" [value]="daaDisabilityTypes.PhysicalDisability">
            <label class="form-check-label" for="physical">Physical disability</label>
        </div>
        <div class="form-check form-check">
            <input class="form-check-input" type="radio" name="daaEligibileDisability" id="cognitive"
                formControlName="daaEligibileDisability" [value]="daaDisabilityTypes.CognitiveDisability">
            <label class="form-check-label" for="cognitive">Cognitive disability</label>
        </div>
        <div class="form-check form-check">
            <input class="form-check-input" type="radio" name="daaEligibileDisability" id="both"
                formControlName="daaEligibileDisability" [value]="daaDisabilityTypes.PhysicalAndCognitiveDisability">
            <label class="form-check-label" for="both">Physical and cognitive disability</label>
        </div>
        <div class="form-check form-check">
            <input class="form-check-input" type="radio" name="daaEligibileDisability" id="notStated"
                formControlName="daaEligibileDisability" [value]="daaDisabilityTypes.NotStatedOrInadequatelyDescribed">
            <label class="form-check-label" for="notStated">Not stated or inadequately described</label>
        </div>
    </div>
    <div class="form-group pb-1">
        <label class="input-label" for="medIndexScore">Patients MedIndex Score</label><span
            (click)="openMedIndexCalculator()" class="medindex-link pl-1">You can calculate the patient's MedIndex score
            using this spreadsheet</span>
        <input type="text" [class.has-error]="formControls.medIndexScore.invalid && (showInvalid$ | async)"
            class="form-control form-control-lg w-25" formControlName="medIndexScore" value="" id="medIndexScore">
    </div>
</form>