export enum AuthActionTypes {
  AUTH_SET_ENCRYPTED_TENANT_CUSTOMER = '[Auth] Set Encrypted Tenant CustomerId',
  AUTH_SET_CLIENT = '[Auth] Set Client',
  AUTH_SET_TENANT = '[Auth] Set Tenant',
  AUTH_SET_ORDER = '[Auth] Set Order',
  AUTH_REQUEST = '[Auth] Request',
  AUTH_RESPONSE = '[Auth] Response',
  AUTH_ERROR = '[Auth] Error',
  AUTH_LOGOUT = '[Auth] Logout'
}

export class AuthSetClientAction {
  static readonly type = AuthActionTypes.AUTH_SET_CLIENT;
  constructor(public clientId: string) { }
}
export class AuthSetTenantAction {
  static readonly type = AuthActionTypes.AUTH_SET_TENANT;
  constructor(public tenantId: number) { }
}
export class AuthSetOrderAction {
  static readonly type = AuthActionTypes.AUTH_SET_ORDER;
  constructor(public orderId: number) { }
}

export class AuthSetEncryptedTenantCustomerId {
  static readonly type = AuthActionTypes.AUTH_SET_ENCRYPTED_TENANT_CUSTOMER;
  constructor(public encryptedTenantCustomerId: string) {}
}

export class AuthRequestAction {
  static readonly type = AuthActionTypes.AUTH_REQUEST;
  constructor(public DOB: Date) {}
}

export class AuthResponseAction {
  static readonly type = AuthActionTypes.AUTH_RESPONSE;
  constructor(public response?: any) {}
}

export class AuthLogoutAction {
  static readonly type = AuthActionTypes.AUTH_LOGOUT;
}

export class AuthErrorAction {
  static readonly type = AuthActionTypes.AUTH_ERROR;
  constructor(public error: any) {}
}
