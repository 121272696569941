import { Component, Input, TemplateRef, ViewEncapsulation } from '@angular/core';

@Component({
    moduleId: module.id,
    selector: 'app-scrypt-insight',
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['./scrypt-insight.style.scss'],
    template: `
        <div class="scrypt-insight">
            <app-tooltip
                name="i"
                [placement]="placement"
                [triggers]="triggers"
                [content]="content"
                [contentContext]="context">
            </app-tooltip>
        </div>
    `
})
export class ScryptInsightComponent {
    @Input() placement = 'top';
    @Input() title: string;
    @Input() context: any;
    @Input() content: string | TemplateRef<any>;
    @Input() triggers = 'click';
}
