import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SixCpaFormListItemChecklistModule } from '../six-cpa-form-list-item-checklist/six-cpa-form-list-item-checklist.module';
import { ConsentEligibilityFormListItemComponent } from './consent-eligibility-form-list-item.component';
import { ConsentEligibilityFormModule } from '../consent-eligibility-form/consent-eligibility-form.module';


@NgModule({
  declarations: [
    ConsentEligibilityFormListItemComponent
  ],
  imports: [
    CommonModule,
    SixCpaFormListItemChecklistModule,
    ConsentEligibilityFormModule
  ],
  exports: [
    ConsentEligibilityFormListItemComponent
  ]
})
export class ConsentEligibilityFormListItemModule { }
