import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { Channels, MailTabs, MessageSource } from '../../../../../shared/types/communications';
import { SendMessageModel } from '../../../models/send-message.model';
import { CommunicationsState, CommunicationsStateModel } from '../../../state/communications.state';
import { MessageChannel } from '../../../../../shared/models/message.model'
import { GetMessageTemplates, SendMessage } from '../state/chat.actions';
import { ChatState } from '@base/modules/communications-page/Components/reply-modal/state/chat.state';
import { ChatStateModel } from '../state/chat.state';
import { CreateStatistic } from '~/shared/state/statistics/statistics.actions';

@Component({
  selector: 'reply-composition-footer',
  templateUrl: './reply-composition-footer.component.html',
  styleUrls: ['./reply-composition-footer.component.scss']
})
export class ReplyCompositionFooterComponent implements OnChanges {
  @Input() channelType: MessageChannel;
  @Input() customerId: string;
  @Input() tenantCustomerId: string;

  channels = [
    {
      name: 'All',
      value: Channels.All
    },
    {
      name: 'SMS',
      value: Channels.Sms,
      icon: 'fal fa-comment-alt-lines'
    },
    {
      name: 'Email',
      value: Channels.Email,
      icon: 'fal fa-envelope'
    },
    {
      name: 'Scrypt Mobile',
      value: Channels.App,
      icon: 'fab fa-stripe-s'
    },
  ];

  mailTabs = MailTabs;
  messageSources = MessageSource;
  
  // This is for expanding the edit section, set to expanded by default due to feedback
  expanded = true; 

  messageChannels = MessageChannel;

  messageForm: FormGroup;
  @Select(ChatState) commsInboxState$: Observable<ChatStateModel>;

  requiredIfExpanded(c: AbstractControl) {
    if (this.expanded && this.channelType === MessageChannel.Email) {
      return Validators.required(c);
    } else {
      return Validators.nullValidator(c);
    }
  }

  constructor(private store: Store, private fb: FormBuilder) {
    this.messageForm = this.fb.group({
      subject: this.fb.control('', this.requiredIfExpanded.bind(this) ),
      message: this.fb.control('', Validators.required),
      template: this.fb.control(null)
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.channelType === MessageChannel.Email) {
      this.expanded = true;
    }

    this.store.dispatch(new GetMessageTemplates(this.channelType, this.customerId));
  }

  clickReply() {
    const newMessage = {
      ...this.messageForm.value,
      customerId: this.customerId,
      tenantCustomerId: this.tenantCustomerId,
      channel: this.channelType
    } as SendMessageModel;

    this.updateCommTemplateStatistic();
    this.store.dispatch(new SendMessage(newMessage));
  }

  getChannelIcon(channelId) {
    return this.channels[channelId]?.icon ?? "";
  }

  getChannelName(channelId) {
    return this.channels[channelId]?.name ?? "";
  }

  toggleExpandedView() {
    if (this.channelType !== MessageChannel.Email) {
      this.expanded = !this.expanded;
    }
    
    this.messageForm.controls.subject.updateValueAndValidity();
  }

  changeTemplate(event) {
    this.messageForm.get('message').setValue(event);
  }

  private updateCommTemplateStatistic() {
    const templates = this.store.selectSnapshot(ChatState.messageTemplates);
    const template = templates.find(x => x.message === this.messageForm.value.template);
    if(template) {
      const {id, title} = template; 
      const updateStatRequest = {
        name: `CommTemplate - ${title}`,
        entityId: +id,
        entityName: 'CommTemplate',
      }
      this.store.dispatch(new CreateStatistic(updateStatRequest))
    }
  }
}
