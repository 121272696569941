<section class="sms-report-filter">
  <div class="row">
    <div class="col-md-2">
        <select class="form-control" [(ngModel)]="searchType" (change)="applyFilters()">
            <option *ngFor="let t of types" [value]="t.name">{{t.label}}</option>
          </select>
    </div>
    <div class="col-md-2">
        <input 
        type="text" 
        placeholder="Enter range" 
        class="form-control" 
        (bsValueChange)="onValueChange($event)"
        bsDaterangepicker>
      </div>
      <div class="col-md-2">
          <input 
          type="text" 
          placeholder="Enter Customer Name Or Phone" 
          class="form-control search"
          [(ngModel)]= "searchValue"
          (input)="applyFilters()"/>
        </div>
  </div>
    
   
    
</section>