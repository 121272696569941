export const fetchTimeZone = '[timezone] fetch';
export const setAllTimeZones = '[timezone] set all';
export const setTimeZone = '[timezone] set';

export class GetSelectedTenantTimezone {
    public static readonly type = fetchTimeZone;
    constructor() {}
}

export class SetSelectedTimezone {
    public static readonly type = setTimeZone;
    constructor(public timezoneId: string) {}
}
