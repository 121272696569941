import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, Validators, FormControl } from '@angular/forms';
import { Store, Actions } from '@ngxs/store';
import { Subscription } from 'rxjs';
import { SSPatientDetailsFormDetailedState } from '../state/ss-patient-details-form-detailed.state';
import { SSPatientDetailsFormBaseComponent } from '../ss-patient-details-form.base.component';
import { SixCpaState } from '../../../state/six-cpa.state';

@Component({
  selector: 'app-ss-patient-details-form-detailed',
  templateUrl: './ss-patient-details-form-detailed.component.html',
  styleUrls: ['./../ss-patient-details-form.component.scss']
})
export class SSPatientDetailsFormDetailedComponent extends SSPatientDetailsFormBaseComponent implements OnInit, OnDestroy {
  private formSubscription: Subscription;
  private readonlySubscription: Subscription;
  constructor(formBuilder: FormBuilder, store: Store, actions: Actions) {
    super(formBuilder, store, actions);
    this.patientDetailsForm.addControl('gender', new FormControl(null, Validators.required));
    this.patientDetailsForm.addControl('residentialPostCode', new FormControl('', [
      Validators.required, Validators.pattern('^(?:(?:[2-8]\\d|9[0-7]|0?[28]|0?9(?=09))(?:\\d{2}))$')]));
    this.patientDetailsForm.addControl('englishAtHome', new FormControl(true, Validators.required));
    this.patientDetailsForm.addControl('aboriginalOrTorresStraitIslander', new FormControl(false));
  }

  ngOnInit() {
    this.formSubscription = this.store.select(SSPatientDetailsFormDetailedState.form)
      .subscribe(() => this.onFormChange());
    this.readonlySubscription = this.store.select(SixCpaState.formsReadOnly)
      .subscribe(readonly => readonly ? this.patientDetailsForm.disable() : this.patientDetailsForm.enable());
  }

  ngOnDestroy() {
    this.formSubscription.unsubscribe();
    this.readonlySubscription.unsubscribe();
  }
}
