
import { Injectable } from '@angular/core';

@Injectable()
export class RedirectService {
  constructor() { }
  post(obj, url) {
    const mapForm = document.createElement('form');
    // mapForm.target = "_blank";
    mapForm.method = 'POST';
    mapForm.action = url;
    Object.keys(obj).forEach(function(param) {
      if (Array.isArray(obj[param])) {
        obj[param].forEach(element => {
          const mapInput = document.createElement('input');
          mapInput.type = 'hidden';
          mapInput.name = param;
          mapInput.setAttribute('value', element);
          mapForm.appendChild(mapInput);
        });
      } else {
        const mapInput = document.createElement('input');
      mapInput.type = 'hidden';
      mapInput.name = param;
      mapInput.setAttribute('value', obj[param]);
      mapForm.appendChild(mapInput);
      }

  });
  document.body.appendChild(mapForm);
  mapForm.submit();
}

}
