import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MarketingPageComponent } from './marketing-page.component';
import { MarketingItemComponent } from './marketing-item/marketing-item.component';
import { MarketingItemService } from './services/marketing-item.service';
import { RouterModule } from '@angular/router';
import { MarketingRoutes } from './routing/marketing.routes';
import { LoaderModule } from '~/modules/core/loader/loader.module';
import { PipesModule } from '~/shared/pipes/pipes.module';
import { FormsModule } from '@angular/forms';
import { EditMarketingItemComponent } from './marketing-item/edit-marketing-item.component';
import { AddMarketingItemComponent } from './marketing-item/add-marketing-item.component';
import { MarketingSettingService } from './services/marketing-settings.sevice';

@NgModule({
  declarations: [
    MarketingPageComponent,
    MarketingItemComponent,
    EditMarketingItemComponent,
    AddMarketingItemComponent
  ],
  imports: [
    RouterModule.forChild(MarketingRoutes),
    CommonModule,
    PipesModule,
    LoaderModule,
    FormsModule
  ],
  providers: [MarketingItemService, MarketingSettingService]
})
export class MarketingModule { }
