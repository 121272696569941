<ng-container *ngIf="(groupPage$ | async) as groupPage">
  <div>
      <h3>Group List</h3>
      <div class="text-right">
        <button class="btn btn-primary" (click)="addNew()">Add new</button>
      </div>
      <div class="bg-grey px-4 py-5 my-3" *ngIf="displayAddNewSection">
        <div class="form-group">
          <label for="groupName">Group Name</label>
          <input class="form-control" [(ngModel)]="currentGroup.name" id="groupName">
        </div>
        <button class="btn btn-success" [disabled]="!currentGroup.name" (click)="saveGroup()">Save</button>
        <button class="btn btn-danger" (click)="cancel()">Cancel</button>
      </div>

      <table class="table mt-4">
        <thead>
          <tr>
            <th>Group Name</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let group of filterGroups(groupPage.allGroups)">
            <td>
              {{ group.name}}
            </td>
            <td>
              <div class="btn-group float-right">
                <button (click)="editGroup(group)" class="btn btn-primary">Edit</button>
                <button (click)="remove(group.id, group.countForEnabled)" class="btn btn-danger">Remove</button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
  </div>
</ng-container>

<app-fluid-modal *ngIf="confirmationRequired"
(submitEvent)="confirm()"
(closeEvent)="dismiss()"
(cancelEvent)="dismiss()"
title="Confirm"
message="Are you sure you want to delete this group? There are {{countForEnabled}} patients still remaining in this group">
</app-fluid-modal>
