import { NursingHome } from '../models/nursing-home'

export enum NursingHomeActions {
  GET_NURSING_HOMES = "[NursingHomes] Get Nursing Homes",
  GET_NURSING_HOMES_SUCCESS = "[NursingHomes] Get Nursing Homes Success"
}

export class GetNursingHomes {
  static type = NursingHomeActions.GET_NURSING_HOMES
  constructor() {}
}

export class GetNursingHomesSuccess {
  static type = NursingHomeActions.GET_NURSING_HOMES_SUCCESS
  constructor(public nursingHomes: NursingHome[]) {}
}
