<form [formGroup]="outcomesAndActionsForm" ngxsForm="outcomesAndActionsForm.outcomesAndActionsForm" class="p-4">
    <div class="form-group mb-0" formGroupName="medsCheckOutcome"
    [class.has-error-group]="formControls.medsCheckOutcome.invalid && (showInvalid$ | async)">
        <label class="input-label" for="">Outcome of MedsCheck Service</label>
        <div *ngFor="let outcome of medsCheckOutcomesForFormArr">
            <div class="form-check">
                <label class="form-check-label pl-2">
                    <input class="form-check-input big-checkbox" [formControlName]="outcome.key" type="checkbox"
                        [value]="outcome.value" />
                    {{outcome.value}}</label>
            </div>
        </div>
    </div>
    <input class="form-control form-control-lg w-50 ml-4 mb-2" type="text" name="medsCheckOutcomeOtherDetails"
        id="medsCheckOutcomeOtherDetails" formControlName="medsCheckOutcomeOtherDetails"
        [class.has-error]="formControls.medsCheckOutcomeOtherDetails.invalid && (showInvalid$ | async)"
        [attr.disabled]="!outcomesAndActionsForm.value.medsCheckOutcome.other ? '' : null">
    <div class="form-group mb-0" formGroupName="pharmacistActions" 
    [class.has-error-group]="formControls.pharmacistActions.invalid && (showInvalid$ | async)"
>
        <label class="input-label" for="">Actions taken by pharmacist as a result of the MedsCheck</label>
        <div *ngFor="let action of actionsTakenByPharamacistForFormArr">
            <div class="form-check">
                <label class="form-check-label pl-2">
                    <input class="form-check-input big-checkbox" [formControlName]="action.key" type="checkbox"
                        [value]="action.value" />
                    {{action.value}}</label>
            </div>
        </div>
    </div>
    <input class="form-control form-control-lg w-50 ml-4 mb-2"
    [class.has-error]="formControls.pharmacistActionsOtherDetails.invalid && (showInvalid$ | async)"
 type="text" name="pharmacistActionsOtherDetails"
        id="pharmacistActionsOtherDetails" formControlName="pharmacistActionsOtherDetails"
        [attr.disabled]="!outcomesAndActionsForm.value.pharmacistActions.other ? '' : null">
</form>