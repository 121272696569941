export enum SortOrder {
  mostRecent,
  leastRecent,
  nameAsc,
  nameDesc
}

export enum Channels {
  All = 'channelsall',
  Sms = 'sms',
  Email = 'email',
  App = 'app'
}

export enum MailTabs {
  Inbox = 'inbox',
  Scheduled = 'scheduled',
  Sent = 'sent'
}

export enum MessageSource {
  Unknown,
  Customer,
  Pharmacy,
  Survey
}

export enum MessageTypes {
  All = 'messagetypesall',
  Customer = 'customer',
  User = 'user',
  AutomatedSystem = 'automatedsystem',
  AutomatedProjected = 'automatedprojected',
  BulkComm = 'bulkcommunications',
  RecurringComm = 'recurringcomm',
  Scheduled = 'scheduled',
  NewCustomerWelcomeMessage = 'newcustomerwelcomemessage',
  Survey = 'survey',
  SurveyResponse = 'surveyresponse',
  Standard = 'standard'
}

export enum QuickModifiers {
  Unread = 'unread',
  Flagged = 'flagged',
  Unreplied = 'unreplied',
  Archived = 'archived'
}

export const IncomingMessageTypes = ["Standard", "SurveyResponse"] as const;
export type IncomingMessageTypesEnum = typeof IncomingMessageTypes[number];
