<section class="med-list-item flex" g-xs="mb3" *ngIf="drug">
  <form [formGroup]="medicationForm" >
    <div class="med-list-item__content">
      <div class="flex justify-content-between" g-xs="p2">
        <div class="d-flex">
          <div class="pointer" g-xs="mr3" (click)="select()">
            <i class="checkbox fas fa-check" [ngClass]="{'selected': drug.isSelected}"></i>
          </div>
          <div g-xs="mr3">
            <h5 class="font__bold" g-xs="mb1">{{drug.brandName}}</h5>
            <div class="font__title-0">{{drug.genericName}}</div>
          </div>
          <div class="med-list-item__description" g-xs="mr3">
            <span class="font__bold" g-xs="mb1">Prescriber: &nbsp;</span>
              <lib-inline-input-edit [medicationForm]='medicationForm'  [myFormControlName]="'prescriber'" (focusOut)='updateMedicationState()'></lib-inline-input-edit>
           <br>
            <span class="font__bold" g-xs="mb1">Last Dispense:</span>&nbsp; {{drug.dispensedDate | date : 'dd/MM/yyyy'}} 
          </div>
        </div>
        <div>
          <i (click)="update()" class="far fa-pencil" g-xs="p2"></i>
        </div>
      </div>
      <div class="med-list-item__description ml-2">
        <div class="row description-line">
          <span class="col-md-4 font__bold">Indication &amp; reasons for taking:</span>
          <span class="col-md-8 ellipsis">
            <lib-inline-input-edit [medicationForm]='medicationForm' [myFormControlName]="'reasonsForTaking'" (focusOut)='updateMedicationState()'></lib-inline-input-edit>
          </span>
        </div>
        <div class="row description-line">
          <span class="col-md-4 font__bold">Dosage instructions:</span>
          <span class="col-md-8"><lib-inline-input-edit [medicationForm]='medicationForm'  [myFormControlName]="'directions'" (focusOut)='updateMedicationState()'></lib-inline-input-edit></span>
        </div>
        <div class="row description-line">
          <span class="col-md-4 font__bold">Notes &amp; special instructions:</span>
          <span class="col-md-8 ellipsis notes"> <lib-inline-input-edit [medicationForm]='medicationForm'  [myFormControlName]="'notes'" (focusOut)='updateMedicationState()'></lib-inline-input-edit></span>
        </div>
        <div class="row description-line">
          <span class="col-md-4 font__bold">Start date:</span>
          <span class="col-md-8 ellipsis notes"><lib-inline-input-edit [medicationForm]='medicationForm'  [myFormControlName]="'startDate'" (focusOut)='updateMedicationState()'></lib-inline-input-edit></span>
        </div>
      </div>
    </div>
  </form>
</section>
