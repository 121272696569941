import { Component, Input, OnInit } from '@angular/core';
import { Store, Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import {DocumentOrigin, DocumentResponse, DocumentServicesState } from '../state/document-services.state';
import { DownloadDocumentFromService, GetDocumentsFromService } from '../state/document-services.actions';

@Component({
  selector: 'app-document-list',
  templateUrl: './document-list.component.html',
  styleUrls: ['./document-list.component.scss'],
})
export class DocumentListComponent implements OnInit {
  @Select(DocumentServicesState.documents) documents$: Observable<any>;
  @Input() enabled: boolean | undefined;

  DocumentOrigin = DocumentOrigin;

  constructor(private store: Store) {}

  ngOnInit(): void {
    if(this.enabled) {
      this.store.dispatch(new GetDocumentsFromService());
    }
  }

  downloadDocument(doc: DocumentResponse) {
    this.store.dispatch(new DownloadDocumentFromService(doc.id));
  }
}
