import { State, Selector, StateContext, Action } from '@ngxs/store';
import { UpdateSSConsentEligibilityBasicForm } from './ss-consent-eligibility-form-basic.actions';
import { ISSConsentEligibilityFormStateModel } from './ss-consent-eligibility-form-state-model.interface';
import *  as moment from 'moment';
import { Injectable } from '@angular/core';

export class SSConsentEligibilityFormBasicStateModel {
  consentEligibilityForm: {
    model: Partial<ISSConsentEligibilityFormStateModel>;
  };
  valid: boolean;
  totalItems: number;
  completedItems: number;
}
@Injectable()
@State<SSConsentEligibilityFormBasicStateModel>({
  name: 'ssConsentEligibilityFormBasic',
  defaults: {
    consentEligibilityForm: {
      model: undefined
    },
    valid: false,
    completedItems: 0,
    totalItems: 0
  }
})
export class SSConsentEligibilityFormBasicState {
  @Selector()
  static form(state: SSConsentEligibilityFormBasicStateModel) { return state.consentEligibilityForm.model; }

  @Action(UpdateSSConsentEligibilityBasicForm)
  updateForm(ctx: StateContext<SSConsentEligibilityFormBasicStateModel>, { form }: UpdateSSConsentEligibilityBasicForm) {
    ctx.patchState({
      consentEligibilityForm: {
        model: {
          writtenConsentForServiceProvision: form.writtenConsentForServiceProvision,
          governmentIssuedConcessionCard: form.governmentIssuedConcessionCard,
          pbsCodeOfTheMedicationSupplied: form.pbsCodeOfTheMedicationSupplied,
          dateStagedSupplyServiceCommenced: form.dateStagedSupplyServiceCommenced && moment(form.dateStagedSupplyServiceCommenced, 'YYYY-MM-DD').format('DD-MM-YYYY')
        }
      }
    });
  }
}
