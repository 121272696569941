<div class="blade ng-animate-slide" *ngIf="noteList && noteList.length > 0" [@slideAnimation]>
  <div class="blade__content">
    <i class="fas fa-times close" (click)="closeBlade()"></i>
    <h1>Note Settings</h1>
    <div class="flex" g-xs="mb1">
      <div g-xs="mr2" *ngIf="noteList.length > 1">
        <div class="blade__message__count font__title-3">
            {{noteList.length}}
        </div>
        <div class="font__title-0">Notes Selected</div>
      </div>
        <div class="blade__message flex">
            <h3 class="font__bold" g-xs="mr1">{{noteList[0].patient?.firstname}} {{noteList[0].patient?.surname}} </h3>
            <span class="font__italic">{{noteList[0].body}}</span>
        </div>
    </div>

    <hr class="separator"/>

    <h3>Configure Schedule</h3>
    <form [formGroup]="noteSettingsForm">
      <div class="form-group font__bold" g-xs="mt3">
        <label><input type="checkbox" formControlName="repeat"/> I want this to
          repeat</label>
      </div>
      <div *ngIf="noteSettingsForm.value.repeat">
        <div class="form-group flex  blade__period">
          <label class="font__bold">Every</label>
          <input type="number" class="form-control interval border__grey-light pr-0" placeholder="5" formControlName="intervalValue" />
          <select formControlName="repeatType" class="form-control repeatType border__grey-light">
            <option [ngValue]="null">Select Interval</option>
            <option *ngFor="let item of repeatTypeList" [value]="item.value"> {{item.name}} </option>
          </select>
        </div>
        <div>
          <div *ngIf="formControls.intervalValue.invalid && formControls.intervalValue.touched" class="form-help-text">Interval is required field</div>
          <div *ngIf="formControls.repeatType.invalid && formControls.repeatType.touched" class="form-help-text">Interval type is required field</div>

        </div>
        <p g-xs="my3" ng="noteList.length == 1">Every {{noteSettingsForm.value.intervalValue}} {{repeatTypeList[noteSettingsForm.value.repeatType-1]?.name}} from
          {{noteList[0].startDate | tenantDate:'DD MMM YYYY' }}</p>
      </div>
      <hr class="separator"/>
      <div class="blade__snooze" g-xs="mb5" >
        <h3 g-xs="mb2">Snooze Note</h3>
        <div class="flex" *ngIf="noteList.length === 1">

          <div class="w45">
            <label for="">Snooze note until</label>
            <div class="d-flex justify-content-between align-items-center">
                <nz-date-picker nzFormat="dd-MM-YYYY" formControlName="snoozeDate"></nz-date-picker>
                <nz-time-picker formControlName="snoozeInterval" nzFormat="HH:mm a"></nz-time-picker>
            </div>

            <div *ngIf="noteList.length === 1" class="tags flex" g-xs="mt2">
              <div *ngFor="let control of tags" (click)="updateTime(control.adjustment)">{{control.description}}</div>
            </div>
        </div>
        </div>
      </div>
      <div>
          <button type="submit" class="btn__yellow" [disabled]="noteSettingsForm.invalid">Update</button>
      </div>
    </form>
  </div>
</div>