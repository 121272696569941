export interface DispenseItemModel {
    itemDescription?: string;
    customerId?: string;
    patientName?: string;
    patientID?: string,
    scriptToken: string;
    stockName?: string;
    genericName?: string;
    scriptNo: string;
    scriptId: string;
    genericCategoryId?: string;
    directions?: string;
    directionsDesc?: string;
    quantity?: number;
    doctorName?: string;
    productDispensedId?: string;
    isPaperless?: boolean;
    isCombination?: boolean;
    notes?: string;
    defer?: boolean;
    generic?: boolean;
    cMI?: boolean;
    itemIndex?: number;
    scriptType?: EScriptType;
    isNewMedication?: boolean;
}

export enum ScriptSource {
    ETP1 = 'ETP1',
    ETP2 = 'ETP2'
  }

  export enum EScriptType {
    Unknown = 'UNKNOWN',
    InsideRepeat= 'INSIDE_REPEAT',
    EScript= 'E_SCRIPT',
    Manual= 'MANUAL_SCRIPT',
  }
