import { HttpErrorResponse, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { BaseApi } from './base-api';
import { Router } from '@angular/router';
import { StorageService } from '../storage/storage.service';
import { AlertService } from '../../../modules/core/alert/alert.service';

@Injectable()
export class BaseApiNoAlerts extends BaseApi {
  constructor(public http: HttpClient, public router: Router, public storageService: StorageService, public alertService: AlertService) {
    super(http, router, storageService, alertService);
  }

  protected handleErrorResponse(error: HttpErrorResponse) {
    if (error.status === 401) {
      this.isUnauthorized();
    } else {
      return throwError(error);
    }
  }
}
