import { State, Selector, Action, StateContext } from '@ngxs/store';
import { DAAClassificationsRecomendationsFormModel } from '../../../../../../shared/models/six-cpa/DAA/daa-classifications-recommendations-form.model';
import { SetTotalItemsAndCompletedItems, SetDAAClassificationsRecommendationsFormValidAction, UpdateDAAClassificationsRecommendationsForm } from './daa-classifications-recommendations.actions';
import { Injectable } from '@angular/core';

export class DAAClassificationsRecommendationsStateModel {
  daaClassificationsRecommendationsForm: {
    model: Partial<DAAClassificationsRecomendationsFormModel>;
  };
  valid: boolean;
  totalItems: number;
  completedItems: number;
}
@Injectable()
@State<DAAClassificationsRecommendationsStateModel>({
  name: 'daaClassificationsRecommendationsForm',
  defaults: {
    daaClassificationsRecommendationsForm: {
      model: undefined
    },
    valid: false,
    completedItems: 0,
    totalItems: 0
  }
})
export class DAAClassificationsRecommendationsState {
  @Selector()
  static daaClassificationsRecommendations(state: DAAClassificationsRecommendationsStateModel) { return state.daaClassificationsRecommendationsForm.model; }

  @Selector()
  static totalItems(state: DAAClassificationsRecommendationsStateModel) { return state.totalItems; }

  @Selector()
  static completedItems(state: DAAClassificationsRecommendationsStateModel) { return state.completedItems; }

  @Action(SetDAAClassificationsRecommendationsFormValidAction)
  setValid(ctx: StateContext<DAAClassificationsRecommendationsStateModel>, action: SetDAAClassificationsRecommendationsFormValidAction) {
    ctx.patchState({
      valid: action.valid
    });
  }

  @Action(SetTotalItemsAndCompletedItems)
  setProgress(ctx: StateContext<DAAClassificationsRecommendationsStateModel>, action: SetTotalItemsAndCompletedItems) {
    ctx.patchState({
      totalItems: action.totalItems,
      completedItems: action.completedItems
    });
  }

  @Action(UpdateDAAClassificationsRecommendationsForm)
  updateDAAClassificationsRecommendationsForm(ctx: StateContext<DAAClassificationsRecommendationsStateModel>, { form }: UpdateDAAClassificationsRecommendationsForm) {
    ctx.patchState({
      daaClassificationsRecommendationsForm: {
        model: form
      }
    });
  }
}
