import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store, Select, Actions, ofActionSuccessful } from '@ngxs/store';
import { Observable, Subject, Subscription } from 'rxjs';
import { ManageMetaState, ManageMetaStateModel } from './state/manage-meta.state';
import { ScriptActionsState } from '../state/script-actions.state';
import { AddNewStockStartingPoint, ManageMetaRequestAction, ToggleAutoCommsModalAction, ManageMetaResponseAction, UpdateScriptNotifications, UpdateStartDateRequestAction } from './state/manage-meta.actions';
import { ProfileState } from '../../../core/profile/state/profile.state';
import { UpdateScriptNotificationsRequest } from '../../../../shared/models/script/update-script-notifications-request.model';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { FeaturesState, FeaturesStateModel } from '../../../core/features/features.state';
import { takeUntil, tap } from 'rxjs/operators';
import { Medication } from '../../../../shared/models/script/chartviewitem.model';
import { StartingPointChangeReason, StockStartingPointModel } from '../../../../shared/models/script/meta-view-model';
import { getDohOrZero } from '../../../../shared/helpers/doh.helper';
import { PharmacyCommsDetailsState } from '../../../../modules/pharmacy-comms/pharmacy-comms-details/state/pharmacy-comms-details.state';

@Component({
  selector: 'app-manage-meta',
  templateUrl: './manage-meta.component.html',
  styleUrls: ['./manage-meta.component.scss']
})
export class ManageMetaComponent implements OnInit, OnDestroy {
  @Select(ScriptActionsState.script) script$: Observable<Medication>;
  @Select(ManageMetaState) manageMeta$: Observable<ManageMetaStateModel>;
  @Select(ProfileState.categoryOptions) categoryOptions$: Observable<any>;
  @Select(FeaturesState.getFeatures) features$: Observable<FeaturesStateModel>;
  notificationsForm: FormGroup;
  currentScriptSubscription: Subscription;
  oldCategoryId: number;
  newDOH = null;
  patientAutoCommsEnabled;
  pharmacyAutoCommsEnabled;
  private ngUnsubscribe = new Subject();
  constructor(private store: Store, private formBuilder: FormBuilder, private actions: Actions) { }

  ngOnInit(): void {
    this.initform();
    this.setAutoCommsStatus();
    this.initInProgressSubscription();

    this.actions.pipe(ofActionSuccessful(ManageMetaResponseAction)).subscribe(()=>{
      const script = this.store.selectSnapshot(ScriptActionsState.script);
      this.categoryOptions$
      .subscribe((result) => {
        const updatedCategoryId = Number(script.cMeta.categoryId);
        const discontinuedCategory = result.find(x => x.text.toLowerCase().includes('discontinued'));
        const newCategoryIsDiscontinued = updatedCategoryId === Number(discontinuedCategory.value);

        if (
          newCategoryIsDiscontinued &&
          script.cMeta.notificationsEnabled
        ) {
          this.store.dispatch(new ToggleAutoCommsModalAction(true));
        }

        if (
          this.isDiscontinuedCategory(updatedCategoryId) &&
          !this.isDiscontinuedCategory(this.oldCategoryId)
        ) {
          this.store.dispatch(
            new AddNewStockStartingPoint(this.buildStockStartingPoint(script))
          );
        }

        if (this.newDOH) {
          this.store.dispatch(
            new AddNewStockStartingPoint(
              this.buildStockStartingPoint(script, this.newDOH)
            )
          );
        }
      });

      this.oldCategoryId = script.cMeta.categoryId;
    });
  }

  ngOnDestroy() {
    if (this.currentScriptSubscription) {
      this.currentScriptSubscription.unsubscribe();
    }

    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  private initInProgressSubscription() {
    this.manageMeta$.pipe(
      takeUntil(this.ngUnsubscribe))
      .subscribe(x => {
      if(x.loadingNotifications) {
        this.notificationsForm.disable();
      }
      else{
        if(this.notificationsForm.disabled) {
          this.notificationsForm.enable();
        }
      }

    });
  }

  initform() {
    this.currentScriptSubscription = this.script$.subscribe((result) => {
    const currentScriptMeta = result.cMeta;
    if(!this.notificationsForm){
      this.oldCategoryId = Number(currentScriptMeta.categoryId);
      this.notificationsForm = this.formBuilder.group({
        metaId: new FormControl(currentScriptMeta.id),
        notificationsEnabled: new FormControl(currentScriptMeta.notificationsEnabled),
        lastRepeatDue: new FormControl(currentScriptMeta.lastRepeatDue),
        beforeNextRepeatDue: new FormControl(currentScriptMeta.beforeNextRepeatDue),
        medicationOverdue: new FormControl(currentScriptMeta.medicationOverdue),
        medicationOverdueOnLastRepeat: new FormControl(currentScriptMeta.medicationOverdueOnLastRepeat),
        scriptExpiring: new FormControl(currentScriptMeta.scriptExpiring),
        scriptOwing: new FormControl(currentScriptMeta.scriptOwing)
      });
    }
    else{
      this.notificationsForm.patchValue({
        metaId: currentScriptMeta.id,
        notificationsEnabled: currentScriptMeta.notificationsEnabled,
        lastRepeatDue: currentScriptMeta.lastRepeatDue,
        beforeNextRepeatDue: currentScriptMeta.beforeNextRepeatDue,
        medicationOverdue: currentScriptMeta.medicationOverdue,
        medicationOverdueOnLastRepeat: currentScriptMeta.medicationOverdueOnLastRepeat,
        scriptExpiring: currentScriptMeta.scriptExpiring,
        scriptOwing: currentScriptMeta.scriptOwing
      });
    }

  });
}

  private setAutoCommsStatus() {
    const patient = this.store.selectSnapshot(ProfileState.profile);
    const pharmacyComms = this.store.selectSnapshot(PharmacyCommsDetailsState.pharmacyCommsDetails);

    this.patientAutoCommsEnabled = !!patient.customerProfile.isAutomation;
    this.pharmacyAutoCommsEnabled = !!pharmacyComms.communicationsEnabled;
  }

  onChangeCategoryId(event, drug) {
    this.categoryOptions$.subscribe(result => {
      const newName = result.filter(cat => cat.value === event)[0].text;
      drug.cMeta.categoryName = newName;
    })
  }

  notificationsToggled(enabled: boolean) {
    this.notificationsForm.patchValue({
      notificationsEnabled: enabled
    });
    this.updateNotificationSettings();
  }

  updateMeta(script: Medication) {
    this.store.dispatch(new ManageMetaRequestAction(script))
  }

  changeDOH($event) {
    this.newDOH = Number($event)
  }

  private buildStockStartingPoint(script: Medication, newDOH: number = null) {
    const ssp: StockStartingPointModel = {
      scriptMetaId: script.cMeta.id,
      doh: newDOH !== null ? newDOH : getDohOrZero(script),
      startDate: new Date(),
      createdOn: new Date(),
      active: true,
      deleted: false,
      changeReason: StartingPointChangeReason.categoryChange
    };

    return ssp;
  }

  updateStartDate(script) {
    this.store.dispatch(new UpdateStartDateRequestAction(script));
  }

  isDiscontinuedCategory(categoryId): boolean {
    const selectedCategory = this.store.selectSnapshot(ProfileState.categoryOptions)
      .find(cat => cat.value == categoryId);

    return selectedCategory.text.toLowerCase().includes('discont') || selectedCategory.text.toLowerCase().includes('cease')
  }

  updateNotificationSettings() {
    const request = this.getNotificationsRequest();
    this.store.dispatch(new UpdateScriptNotifications(request));
  }

  private getNotificationsRequest() {
    const requestForm = this.notificationsForm.value;
    let request: UpdateScriptNotificationsRequest = {
      lastRepeatDue: this.getBoolOrDefault(requestForm.lastRepeatDue),
      beforeNextRepeatDue: this.getBoolOrDefault(requestForm.beforeNextRepeatDue),
      medicationOverdue: this.getBoolOrDefault(requestForm.medicationOverdue),
      medicationOverdueOnLastRepeat: this.getBoolOrDefault(requestForm.medicationOverdueOnLastRepeat),
      scriptExpiring: this.getBoolOrDefault(requestForm.scriptExpiring),
      scriptOwing: this.getBoolOrDefault(requestForm.scriptOwing),
      metaId: requestForm.metaId,
      notificationsEnabled: this.getBoolOrDefault(requestForm.notificationsEnabled)
    };

    return request;
  }

  private getBoolOrDefault(value?: boolean) {
    return !!value;
  }

  updateScriptReminders() {
    const scriptOwing = this.notificationsForm.value.scriptOwing;
    this.notificationsForm.patchValue({
      scriptOwing: !scriptOwing,
      scriptExpiring: !scriptOwing,
    });
    this.updateNotificationSettings();
  }

  updateMedicationReminders() {
    const medicationOverdue = this.notificationsForm.value.medicationOverdue;
    this.notificationsForm.patchValue({
      medicationOverdue: !medicationOverdue,
      medicationOverdueOnLastRepeat: !medicationOverdue,
    });
    this.updateNotificationSettings();
  }

  updateRepeatReminders() {
    const lastRepeatDue = this.notificationsForm.value.lastRepeatDue;
    this.notificationsForm.patchValue({
      lastRepeatDue: !lastRepeatDue,
      beforeNextRepeatDue: !lastRepeatDue,
    });
    this.updateNotificationSettings();
  }

  disableAutoComms() {
    const settings = {
      notificationsEnabled: false,
      beforeNextRepeatDue: false,
      lastRepeatDue: false,
      medicationOverdue: false,
      medicationOverdueOnLastRepeat: false,
      //metaId: metaId,
      scriptExpiring: false,
      scriptOwing: false,
    };
    this.notificationsForm.patchValue(settings);

    this.store.dispatch(new UpdateScriptNotifications(this.notificationsForm.value))
    this.store.dispatch(new ToggleAutoCommsModalAction(false));
  }

  cancelAutoComms() {
    this.store.dispatch(new ToggleAutoCommsModalAction(false))
  }
}

