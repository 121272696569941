import { Component, Output, EventEmitter, OnInit } from '@angular/core';
import { accordion } from '../../../../shared/core/animations';
import { MultipleDrugsState } from './state/multiple-drugs.state';
import { Observable } from 'rxjs';
import { Select, Store } from '@ngxs/store';
import { CategoriesState } from '../state/categories.state';
import { UnselectManyDrugsAction } from './state/multiple-drugs.actions';
import { ResetMetasRequestAction } from '../state/script-actions.actions';
import {
  FeaturesState,
  FeaturesStateModel,
} from '../../../core/features/features.state';
import { ProfileState } from '../../../core/profile/state/profile.state';
import { RequestBulkDispense } from './bulk-dispense/bulk-dispense.actions';
import { PharmacyService } from '../../../../shared/services/pharmacy.service';
import { ConnectionTypeEnum } from '../../../../shared/models/connectionType.model';
import { BulkDispenseState, BulkDispenseStateModel } from './bulk-dispense/bulk-dispense.state';
import { AlertService } from '../../../core/alert/alert.service';
import { SetRepeatUri, ToggleScryptSmsModal, GetScryptSMSRepeatTemplates } from '../../../../shared/components/scrypt-sms/state/scrypt-sms.actions';
import { Medication } from '../../../../shared/models/script/chartviewitem.model';
import { BatchDispenseRequestModel } from '../../../../shared/models/dispense/batchDispenseRequest.model';
import { mapHQDrugsToDispenseItemModel } from '@base/shared/helpers/dispense-request.helpers';

@Component({
  selector: 'app-multiple-select',
  templateUrl: './multiple-select.component.html',
  styleUrls: ['./multiple-select.component.scss'],
  animations: [accordion],
})
export class MultipleSelectComponent implements OnInit {
  @Select(MultipleDrugsState.selectedDrugsArray) selectedDrugs$: Observable<any[]>;
  @Select(FeaturesState.getFeatures) features$: Observable<FeaturesStateModel>;
  @Select(BulkDispenseState.selectBulkDispense) bulkDispenseState$: Observable<BulkDispenseStateModel>;
  @Output() orderBladeOpenEvent = new EventEmitter<any>();
  @Output() bsaBladeOpenEvent = new EventEmitter<any>();
  @Output() pauseBladeOpenEvent = new EventEmitter<any>();
  showBtnGroup = 'in';
  overriddenScripts = [];
  tenantCustomerCanDispense: Boolean = false;
  bulkDispenseRequest: BatchDispenseRequestModel;

  constructor(private store: Store, private pharmacyService: PharmacyService, private alertService: AlertService) { }

  ngOnInit() {
    this.getTenant();
  }

  private getTenant() {
    this.pharmacyService.getPharmacyLocation()
      .subscribe(local => {
        switch(local.connectionType) {
          case ConnectionTypeEnum.ZDispense:
          case ConnectionTypeEnum.Corum:
            this.tenantCustomerCanDispense = true
            break;
          case ConnectionTypeEnum.FredDispense:
          case ConnectionTypeEnum.FredNXTDispense:
          case ConnectionTypeEnum.Aquarius:
            this.tenantCustomerCanDispense = false
            break;
        }
      });
  }

  drugsContainsUncategorised(scripts: Medication[]) {
    const uncategorisedScripts = scripts.filter(s => !s.cMeta || !s.cMeta.categoryId || !s.cMeta.dosageHistory);
    return uncategorisedScripts.length > 0;
  }
  toggle(val: string) {
    return val === 'in' ? 'out' : 'in';
  }

  openOrderBlade() {
    this.orderBladeOpenEvent.emit();
  }
  openBsaBlade() {
    this.bsaBladeOpenEvent.emit();
  }
  openPauseBlade() {
    this.pauseBladeOpenEvent.emit();
  }

  resetScripts() {
    const scripts = this.store.selectSnapshot(
      MultipleDrugsState.selectedDrugsArray
    );
    this.store.dispatch([new ResetMetasRequestAction(scripts)]);
    this.deselectAll(scripts);
  }

  deselectAll(scripts: any[]) {
    this.store
      .selectSnapshot(CategoriesState.categorised)
      .forEach((category) => {
        category.selected = false;
        this.store.dispatch(new UnselectManyDrugsAction(category.items));
      });
    if (scripts) {
      this.store.dispatch(new UnselectManyDrugsAction(scripts));
    }
  }

  selectedDrugsContainHistoricScripts(scripts: any[]) {
    return !!scripts.find(script => script.stock == null);
  }

  drugsCanBeDispensed(selectedDrugs){
    return selectedDrugs.some(x=>x.scriptDispenseId?.length); 
  }

  repeatsCanBeSms(selectedDrugs){
    return selectedDrugs.some(x=>x.repeatTokenUri?.length);;
  }

  dispenseDrugs(selectedDrugs: Medication[]) {
    const client = this.store.selectSnapshot(ProfileState.profile).patient;
    const clientId = this.store.selectSnapshot(ProfileState.clientId);

    this.bulkDispenseRequest = {
      scripts: mapHQDrugsToDispenseItemModel(selectedDrugs, `${client.title} ${client.firstname} ${client.surname}`),
      username: 'Script HQ',
      notes: 'Generated in Scrypt HQ',
      clientId,
    } as BatchDispenseRequestModel;

    if (this.requestHasOverridenScripts(selectedDrugs)) {
      return;
    }
    this.submitBulkDispenseRequest();
  }

  requestHasOverridenScripts(selectedDrugs: any[]): boolean {
    if (selectedDrugs.find(drug => drug.isPassiveScript)) {
      selectedDrugs.forEach(script => {
        if (script.isPassiveScript) {
          this.overriddenScripts.push(script);
        }
      });
      return true;
    }
    return false;
  }

  submitBulkDispenseRequest() {
    if (!this.bulkDispenseRequest.scripts.length) {
      this.alertService.warn('No valid prescriptions to dispense');
      this.closeConfirmation();
    }
    else {
      this.store.dispatch(new RequestBulkDispense(this.bulkDispenseRequest));
    }
  }

  closeConfirmation() {
    this.overriddenScripts = [];
  }

  continue() {
    this.submitBulkDispenseRequest();
  }

  sendRepeats(selectedDrugs: any[]) {
    let repeatUris = '';
    let repeatCount = 0;

    selectedDrugs.forEach(drug => {
      if (drug.repeatTokenUri) {
        repeatUris += `${drug.repeatTokenUri} \n`;
        repeatCount++;
      }
    });

    if (repeatCount == 0) {
      this.alertService.error('No valid eScript repeat tokens exist on the select scripts.');
    }
    else {
      this.store.dispatch([
        new SetRepeatUri(repeatUris),
        new ToggleScryptSmsModal(true),
        new GetScryptSMSRepeatTemplates()
      ]);
    }
  }
}
