<form [formGroup]="actionPlanForm" ngxsForm="actionPlanForm.actionPlanForm" class="p-4">
  <div>
    <div>
      <app-radio-highlight controlName="actions" size="lg" [isHighlighted]="!!actionPlanForm.value.hasActions"
        [fullWidth]="true" [border]="true">
        <input class="form-check-input" type="radio" id="actions" formControlName="hasActions" [value]="true"
          [checked]="!!actionPlanForm.value.hasActions" (change)="setHasActions()">
        <label class="form-check-label" for="actions">Add Issue(s)</label>
      </app-radio-highlight>

      <app-radio-highlight controlName="noActions" size="lg" [isHighlighted]="!actionPlanForm.value.hasActions"
        [fullWidth]="true" [border]="true">
        <input class="form-check-input" type="radio" id="noActions" formControlName="hasActions" [value]="false"
          [checked]="!actionPlanForm.value.hasActions" (change)="setHasActions()">
        <label class="form-check-label" for="noActions">No Action(s) Taken</label>

      </app-radio-highlight>
    </div>
    <ng-container *ngFor="let actionFormGroup of actionPlanForm.controls.actions['controls']; let i = index">
      <div class="action-plan-form" [formGroup]="actionFormGroup">
        <div class="form-group">
          <strong class="">Issue #{{i+1}}</strong>&nbsp;
          <div *ngIf="actionPlanForm.controls.actions['controls'].length > 1" class="link"><button
              (click)="removeFormControl(i)"><i class="fas fa-times-circle danger"></i></button></div>
        </div>
        <div>
          <div>
            <div class="form-group pb-1">
              <label for="issue" class="input-label">Issue</label>
              <textarea minlength="1"
                [class.has-error]="actionFormGroup.controls.issue.invalid && (showInvalid$ | async)" type="text"
                formControlName="issue" class="form-control form-control-lg" id="issue" placeholder="Issue"
                required></textarea>
            </div>
          </div>
          <div>
            <div class="form-group pb-1">
              <label for="recommendation" class="input-label">Outcome Recommendation</label>
              <textarea minlength="1"
                [class.has-error]="actionFormGroup.controls.recommendation.invalid && (showInvalid$ | async)"
                type="text" formControlName="recommendation" class="form-control form-control-lg" id="recommendation"
                placeholder="Recommendation" required></textarea>
            </div>
          </div>
        </div>
        <div>
          <div class=" pb-1" formGroupName="forConsiderationBy">
            <label class="input-label-not-required">For Consideration By:</label> <br />
            <div *ngFor="let reason of forConsiderationByForm" class="form-check form-check-inline">
              <input class="form-check-input big-checkbox" [formControlName]="reason.key" type="checkbox"
                [value]="reason.value" [id]="reason.key">
              <label class="form-check-label pl-2" [for]="reason.key">{{reason.value}}</label>
            </div>
          </div>
          <input *ngIf="actionFormGroup.controls.forConsiderationBy.value.other" class="form-control mb-2 w-50"
            type="text" name="otherConsideration" id="otherConsideration" formControlName="otherConsideration"
            [attr.disabled]="!actionFormGroup.controls.forConsiderationBy.value.other ? '' : null">
        </div>
      </div>
    </ng-container>
    <button *ngIf="formControls.hasActions.value" class="btn btn__green" (click)="addFormControl()">Add Issue</button>
  </div>
</form>
