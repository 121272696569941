import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { ProgressStatusEnum } from '../../../../shared/models/progress-status-type.enum';
import { DocumentOrigin } from './services/document-storage.service';
import { CancelDocumentUpload, DeleteDocument, UploadDocument, UploadDocumentRequest } from './state/upload-document-form.actions';
import { FormDocument, UploadDocumentFormState, UploadDocumentsFormStateModel } from './state/upload-document-form.state';

@Component({
  selector: 'app-upload-documents-form',
  templateUrl: './upload-document-form.component.html',
  styleUrls: ['./upload-document-form.component.scss']
})
export class UploadDocumentsFormComponent {
  @Input() disabled: boolean;
  @Input() name: string;
  @Input() documentOrigin: DocumentOrigin = DocumentOrigin.General;
  @Input() isDocumentListDisplay : boolean = true;
  @Select(UploadDocumentFormState) state$: Observable<UploadDocumentsFormStateModel>;
  @ViewChild("consentDocument", {static: false}) consentDocument: ElementRef;

  statusType = ProgressStatusEnum;
  files: any[];
  notes: string;
  constructor(private formBuilder: FormBuilder,private store: Store) {
  }

  fileChosen(event) {
    this.files = event.target.files;
  }

  clearSelection() {
    this.files = [];
    this.consentDocument.nativeElement.value = "";
  }

  deleteFile(file: FormDocument) {
    this.store.dispatch(new DeleteDocument(file.document.id));
  }

  cancelDownload(uploadId) {
    this.store.dispatch(new CancelDocumentUpload(uploadId))
  }

  uploadFile() {
    if (this.files.length > 0) {
      for (var file of this.files) {
        this.store.dispatch(new UploadDocument({
          documentOrigin: this.documentOrigin,
          document: file,
          fileName: file.name,
          fileType: file.type,
          notes: this.notes
        })).subscribe((res)=> {
          if (!this.isDocumentListDisplay) {
            this.store.dispatch(new UploadDocumentRequest());
          }
          this.notes ="";
          this.clearSelection();
        });
      }
  }

  }
}
