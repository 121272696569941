import { Selector } from '@ngxs/store';
import { MedListMedicationProfileState } from '../../med-list-medication-profile/state/med-list-medication-profile.state';

export class MedicationListFormsProgressService {
  @Selector([
    MedListMedicationProfileState.selectedMedicineCount
  ])
  static getProgress(
    selectedMedicines
  ) {
    return Math.floor((selectedMedicines > 0 ? 1 : 0) * 100);
  }
}
