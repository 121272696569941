import { Component, OnInit, OnDestroy } from '@angular/core';
import { Chart } from 'angular-highcharts';
import { Subscription } from 'rxjs/Subscription';
import { CustomerData } from '~/shared/models/dashboard/customer.model';
import { BreadCrumbService } from '~/shared/components/breadcrumbs/breadcrumbs.service';
import { NoteTypeLinkPipe } from '~/shared/pipes/noteTypeLink.pipe';
import { Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { getTenantTimezoneDateString } from '~/modules/core/timezone/logic/timezone-tenant.logic';
import { getClassesWithBold } from '~/modules/crm/crm-notification-date/crm-notification-date.logic';
import { ToggleEScriptModal } from '~/shared/components/escripts/state/escripts.actions';
import { Observable, interval } from 'rxjs';
import { DashboardCrmTable, DashboardState } from './state/dashboard.state';
import {
  FilterCrmTable,
  FilterCustomers,
  FireSearch,
  GetCleanlinessData,
  GetCrmTable,
  GetCustomers,
  GetGaugeData,
  SetSearch,
  UpdateGauge,
} from './state/dashboard.action';
import { NoteFilter } from '~/shared/models/crm/noteFilter.model';

export enum BioMetricsOptions {
  Today,
  ThisWeek,
  ThisMonth,
  ThisYear,
}

@Component({
  selector: 'app-dashboard-page',
  templateUrl: './dashboard-page.component.html',
  styleUrls: ['./dashboard-page.component.scss'],
})
export class DashboardPageComponent implements OnInit, OnDestroy {
  @Select(DashboardState.isCrmLoaded) isCrmLoaded$: Observable<boolean>;
  @Select(DashboardState.isCustomerListLoaded)
  isCustomerListLoaded$: Observable<boolean>;
  @Select(DashboardState.isDashboardLoaded)
  isDashboardLoaded$: Observable<boolean>;
  @Select(DashboardState.crmTable) crmTable$: Observable<
    DashboardCrmTable | undefined
  >;
  @Select(DashboardState.customerData) customerData$: Observable<
    CustomerData | undefined
  >;
  @Select(DashboardState.daaChart) daaChart$: Observable<Chart | undefined>;
  @Select(DashboardState.sofChart) sofChart$: Observable<Chart | undefined>;
  @Select(DashboardState.cleanChart) cleanChart$: Observable<Chart | undefined>;
  metricsOptions = BioMetricsOptions;
  biometricOption = BioMetricsOptions.Today;
  daaSelected = 0;
  sofSelected = 0;
  updateSubscription: Subscription;

  constructor(
    private router: Router,
    private breadcrumbService: BreadCrumbService,
    private noteTypeLinkPipe: NoteTypeLinkPipe,
    private store: Store
  ) {
    breadcrumbService.displayBreadCrumbs.next(false);
  }

  ngOnInit() {
    this.cleanlinessCharts();
    this.guageCharts();
    this.crmTable();
    this.customersTable();
    this.initializeAutoRefresh();
  }

  private initializeAutoRefresh() {
    this.updateSubscription = interval(120000).subscribe((val) => {
      window.location.reload();
    });
  }

  openEScriptScanner() {
    this.store.dispatch(new ToggleEScriptModal(true));
  }

  customersTable() {
    this.store.dispatch(new GetCustomers(15));
  }

  handleCustomerFilter(filterText) {
    this.store.dispatch(new FilterCustomers(filterText));
  }

  handleCustomerColumnClick(data) {
    const { item } = data;
    this.router.navigate(['profile', item.Raw.clientId, 'categories']);
  }

  crmTable() {
    const filter = {
      query: '',
      noteType: null,
      pageSize: 15,
      pageIndex: 1,
      toDate: new Date(Date.now()),
    } as NoteFilter;
    this.store.dispatch(new GetCrmTable(filter));
  }

  cleanlinessCharts() {
    this.store.dispatch(new GetCleanlinessData(this.biometricOption));
  }

  guageCharts() {
    this.store.dispatch(new GetGaugeData());
  }

  getDate(date, format) {
    return {
      value: getTenantTimezoneDateString(this.store, date, format),
      class: getClassesWithBold(date),
    };
  }

  handleColumnClick(data) {
    const { title, item } = data;
    if (title === 'Action' || title === 'Date') {
      const url = this.noteTypeLinkPipe.transform(item.Raw);
      this.router.navigateByUrl(url.slice(4));
    }
    if (title === 'Customer') {
      this.router.navigate(['profile', item.Raw.clientId, 'categories']);
    }
  }

  handleFilter(filterText: string) {
    this.store.dispatch(new FilterCrmTable(filterText));
  }

  changeGuage(type: string, index: number) {
    this.store.dispatch(new UpdateGauge(type, index));
  }

  setSearch($event) {
    this.store.dispatch(new SetSearch($event.target.value.split(' ')));
  }

  navigateSearch() {
    this.store.dispatch(new FireSearch());
  }

  ngOnDestroy() {
    if (this.updateSubscription) {
      this.updateSubscription.unsubscribe();
    }
    this.breadcrumbService.displayBreadCrumbs.next(true);
  }
}
