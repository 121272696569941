import { NgModule } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { StorageService } from './storage.service';
@NgModule({
    providers: [
        StorageService,
        CookieService
    ]
})
export class StorageModule {
}
