import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { StagedSupplyFormType, StagedSupplyFormListState } from './state/staged-supply-form-list.state';
import { Subscription, Observable, forkJoin } from 'rxjs';
import { Select, Store } from '@ngxs/store';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { SetStagedSupplyTypeAction,
  SubmitExisitingStagedSupply,
  SubmitNewStagedSupply,
  SaveExisitingStagedSupplyForm,
  SaveNewStagedSupplyForm,
  ResetStagedSupplyFormListAction,
  ShowInvalidStagedSupplyForm} from './state/staged-supply-form-list.actions';
import { switchMap, withLatestFrom, filter, map } from 'rxjs/operators';
import { StagedSupplyFormsProgressService } from './state/staged-supply-forms-progress.service';
import { StagedSupplyFormsService } from './state/staged-supply-forms.service';
import { ProfileState } from '../../../../core/profile/state/profile.state';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { OpenResetFormModal, CloseResetFormModal } from '../../state/six-cpa.actions';
import { SixCpaState } from '../../state/six-cpa.state';
import { WindowService } from '../../../../../shared/services/window.service';
// import { environment } from '../../../../../../environments/environment';
import { SaveOrUpdateStagedSupplyFormAction } from './state/staged-supply-form-save.actions';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-staged-supply',
  templateUrl: './staged-supply.component.html',
  styleUrls: ['./staged-supply.component.scss']
})
export class StagedSupplyComponent implements OnInit {
  @Select(StagedSupplyFormListState.stagedSupplyType) stagedSupplyType$: Observable<StagedSupplyFormType>;
  @Select(StagedSupplyFormListState.saving) saving$: Observable<boolean>;
  @Select(SixCpaState) sixCpaState$: Observable<any>;

  stagedSupplyFormList: FormGroup;
  formSubscription: Subscription;
  stagedSupplyTypes = StagedSupplyFormType;
  private readonlySubscription: Subscription;

  constructor(
    private formBuilder: FormBuilder,
    private store: Store,
    private cdr: ChangeDetectorRef,
    private windowService: WindowService
  ) {
  }

  ngOnInit() {
    this.initForm();
    this.initFormChangesSubscription();
    this.initReadonlySubscription();
  }

  ngOnDestroy() {
    if (this.formSubscription) {
      this.formSubscription.unsubscribe();
    }
    if (this.readonlySubscription) {
      this.readonlySubscription.unsubscribe();
    }
  }

  openPPAPortal() {
    // this.windowService.open(environment.ppaPortalLink);
    this.windowService.open('');
  }
  
  private initForm() {
    this.stagedSupplyFormList = this.formBuilder.group({
      stagedSupplyType: new FormControl(StagedSupplyFormType.PatientRegistration, Validators.required)
    });
  }

  private initFormChangesSubscription() {
    this.formSubscription = this.stagedSupplyFormList.get('stagedSupplyType').valueChanges.pipe(
      switchMap((stagedSupplyType) => this.store.dispatch(new SetStagedSupplyTypeAction(stagedSupplyType)).pipe(
        withLatestFrom(this.store.select(StagedSupplyFormListState.stagedSupplyType)))))
      .subscribe(() => { this.cdr.detectChanges() });
  }

  private initReadonlySubscription() {
    this.readonlySubscription = this.store.select(SixCpaState.formsReadOnly)
      .subscribe(readonly => readonly ? this.stagedSupplyFormList.disable() : this.stagedSupplyFormList.enable());
  }

  @Dispatch()
  saveForm = () => new SaveOrUpdateStagedSupplyFormAction();

  @Dispatch()
  submitStagedSupplyRegistration = () => {
    if (this.store.selectSnapshot(StagedSupplyFormsProgressService.getSSRegistrationProgress) !== 100) {
      this.store.dispatch(new ShowInvalidStagedSupplyForm());
    }
    return this.store.selectOnce(StagedSupplyFormsProgressService.getSSRegistrationProgress).pipe(
      filter(progress => progress === 100),
      switchMap(() => forkJoin([
        this.store.selectOnce(StagedSupplyFormsService.getStagedSupplyPatientRegistrationRequest),
        this.store.selectOnce(ProfileState.clientId)
      ])),
      map(([formData, clientId]) =>
        formData && formData.sixCpaFormId !== null
          ? new SubmitExisitingStagedSupply(clientId, formData)
          : new SubmitNewStagedSupply(clientId, formData)));
  }

  @Dispatch()

  submitStagedSupplyClaim = () => {
    if (this.store.selectSnapshot(StagedSupplyFormsProgressService.getSSClaimProgress) !== 100) {
      this.store.dispatch(new ShowInvalidStagedSupplyForm());
    }
    return this.store.selectOnce(StagedSupplyFormsProgressService.getSSClaimProgress).pipe(
      filter(progress => progress === 100),
      switchMap(() => forkJoin([
        this.store.selectOnce(StagedSupplyFormsService.getStagedSupplyClaimRequest),
        this.store.selectOnce(ProfileState.clientId)
      ])),
      map(([formData, clientId]) =>
        formData && formData.sixCpaFormId !== null
          ? new SubmitExisitingStagedSupply(clientId, formData)
          : new SubmitNewStagedSupply(clientId, formData)));
  }

  openConfirmResetModal() {
    this.store.dispatch(new OpenResetFormModal())
  }

  closeConfirmResetModal() {
    this.store.dispatch(new CloseResetFormModal())
  }

  resetStagedSupplyFormList() {
    this.store.dispatch(new ResetStagedSupplyFormListAction());
  }

  downloadSchedulePdf() {
    const pdfUrl = "https://scrypt.blob.core.windows.net/public-documents/Staged-Supply-Record-Sheet-Scrypt.pdf";
    const pdfName = "Staged-Supply-Record-Sheet-Scrypt.pdf";
    saveAs(pdfUrl, pdfName);
  }
}
