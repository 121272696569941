import { State, Selector, StateContext, Action, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { ProfileState } from '../../../../core/profile/state/profile.state';
import { Observable } from 'rxjs';
import { MedsCheckFormsService } from './meds-check-forms.service';
import { MedsCheckFormListState, MedsCheckType } from './meds-check-form-list.state';
import { SaveOrUpdateMedsCheckAction } from './meds-check-save.actions';
import { withLatestFrom, map, tap } from 'rxjs/operators';
import { PutUpdateMedsCheckFormListAction, SaveMedsCheckFormListAction } from './meds-check-form-list.actions';
import { UploadDocumentRequest } from '../../../six-cpa/upload-document-form/state/upload-document-form.actions';

export class MedsCheckSaveStateModel {

}

@Injectable()
@State<MedsCheckSaveStateModel>({
  name: 'medsCheckSave',
  defaults: {
  }
})
export class MedsCheckSaveState {
  constructor(
    private store: Store
  ) { }

  @Action([SaveOrUpdateMedsCheckAction, UploadDocumentRequest])
  saveOrUpdateMedsCheckByType(ctx: StateContext<MedsCheckSaveStateModel>, {submissionFailed, savePdf}: SaveOrUpdateMedsCheckAction) {
    const formType = this.store.selectSnapshot(MedsCheckFormListState.medsCheckType);
    return this.saveOrUpdateByFormType(formType, submissionFailed, savePdf).pipe(tap(action => ctx.dispatch(action)));
  }

  private saveOrUpdateByFormType(formType: MedsCheckType, submissionFailed: boolean, savePdf: boolean): Observable<any> {
    return this.store.selectOnce(this.getFormSelectorForType(formType)).pipe(
      withLatestFrom(this.store.select(ProfileState.clientId)),
      map(([formData, clientId]) =>{
        formData.submissionFailed = submissionFailed;
        formData.savePdf = savePdf;
        return formData && formData.sixCpaFormId !== null
        ? new PutUpdateMedsCheckFormListAction(clientId, formData)
          : new SaveMedsCheckFormListAction(clientId, formData);
      })
    );
  }

  private getFormSelectorForType(formType: MedsCheckType) {
    switch(formType) {
        case MedsCheckType.PatientRegistration: return MedsCheckFormsService.getMedsCheckRegistrationRequest;
        case MedsCheckType.Diabetes: return MedsCheckFormsService.getMedsCheckBasicRequest;
        case MedsCheckType.Regular: return MedsCheckFormsService.getMedsCheckBasicRequest;
        default: throw Error('unsupported form type')
    }
  }
}
