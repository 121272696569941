import { Injectable } from '@angular/core';
import { BaseApi } from '~/shared/core/base-api/base-api';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { StorageService } from '~/shared/core/storage/storage.service';
import { AlertService } from '~/modules/core/alert/alert.service';
import { environment } from '../../../../environments/environment';
import { ExportToCsvRequest } from '~/shared/models/export-to-csv-request.model';
import { Observable } from 'rxjs';
import { MedsCheckFormModel } from '~/shared/models/six-cpa/meds-check-form.model';

const API = environment.baseApiUrl

@Injectable()
export class CsvExportService extends BaseApi {

  constructor(public http: HttpClient, public router: Router, public storageService: StorageService, public alertService: AlertService) {
    super(http, router, storageService, alertService);
  }

  getMedsCheckCsv(request: ExportToCsvRequest): Observable<any> {
    return this.post(`${API}/api/csv-export/meds-check`, request, { responseType: 'blob' as 'json' });
  }

  getMedsCheckPatientRegistrationCsv(request: MedsCheckFormModel): Observable<any> {
    return this.post(`${API}/api/csv-export/meds-check-single`, request, { responseType: 'blob' as 'json' });
  }

  getHMRCsv(request: ExportToCsvRequest): Observable<any> {
    return this.post(`${API}/api/csv-export/hmr`, request, { responseType: 'blob' as 'json' });
  }

  getStagedSupplyCsv(request: ExportToCsvRequest): Observable<any> {
    return this.post(`${API}/api/csv-export/staged-supply`, request, { responseType: 'blob' as 'json' });
  }

  getDAACsv(request: ExportToCsvRequest): Observable<any> {
    return this.post(`${API}/api/csv-export/daa`, request, { responseType: 'blob' as 'json' });
  }

  getCICount(request: ExportToCsvRequest): Observable<any> {
    return this.post(`${API}/api/csv-export/clinical-intervention`, request);
  }
}
