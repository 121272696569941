<ng-container *ngIf="sendStatement$ | async as sendStatement">
  <div class="airStatement" [@slideRightAnimation]="slideMode$ | async">
    <div class="airStatement__body">
      <div class="flex airStatement__title">
        <div (click)="close()" g-xs="px3" class="flex align-items-center">
          <i class="fas fa-arrow-left font__title-3 back-arrow" title="back to form"></i>
        </div>
        <div>
          <h2 g-xs="px3 py2">AIR History Statement Confirmation</h2>
        </div>
      </div>
      <div class="airStatement__content" g-xs="p3">
        <form [formGroup]="airStatementForm" ngxsForm="sendStatement.airStatementForm">
          <app-download-pdf name="AIR_History_Statement" (downloadReportEvent)="donwloadStatement()">
          </app-download-pdf>
          <hr />
          <p class="col-form-label-lg font__bold mt-2">Email Statement</p>
          <div class="form-group">
            <p class="col-form-label-lg">Email Address</p>
            <input type="text" formControlName="email" class="form-control form-control-lg w-50"
              placeholder="Email Address" />
          </div>
          <div class="d-flex align-items-center">
            <button [disabled]="sendStatement.loading || airStatementForm.status == 'INVALID'" class="btn__green mt-2" (click)="send()">
              Send Statement
            </button>
            <i *ngIf="sendStatement.loading" class="fas fa-sync fa-spin font__title-2 ml-2 mt-2"></i>
          </div>
        </form>
        <hr />
        <form [formGroup]="airStatementSmsForm">
          <p class="col-form-label-lg font__bold mt-2">Sms Statement</p>
          <div class="form-group">
            <p class="col-form-label-lg">Mobile Number</p>
            <input type="text" formControlName="mobile" class="form-control form-control-lg w-50"
              placeholder="Mobile Number" />
          </div>
          <div class="form-group">
            <p class="col-form-label-lg">Template</p>
            <select 
              class="form-control form-control-lg pre-selected-messages color__grey-text" 
              g-xs="py2" id="pre-selected-messages" 
              name="messageTypeId" 
              formControlName="messageTypeId"
              (change)="onMessageTypeChange()">
                <option value="0">
                  Use a pre-selected message to send to your clients
                </option>
                <option *ngFor="let item of messageTypes" [value]="item.id">
                  {{ item.title }}
                </option>
            </select>
            <textarea class="form-control mt-2" type="text" rows="4" [value]="airStatementSmsForm.value.body" formControlName="body"></textarea>
          </div>
          <div class="d-flex align-items-center">
            <button [disabled]="sendStatement.loading || airStatementSmsForm.status =='INVALID'" class="btn__green mt-2" (click)="sendSms()">
              Send Statement
            </button>
            <i *ngIf="sendStatement.loading" class="fas fa-sync fa-spin font__title-2 ml-2 mt-2"></i>
          </div>
          <ng-container *ngIf="loading$ | async as loading">
            <app-loader *ngIf="loading" loadingMessage="Won't be too long..."></app-loader>
          </ng-container>
        </form>
      </div>
    </div>
  </div>
</ng-container>
