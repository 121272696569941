import { HttpClient } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Router } from '@angular/router';
import { StorageService } from '../shared/core/storage/storage.service';
import { CrudService } from './crud.service';
import { User } from '../shared/models/user.model';
import { AlertService } from '../modules/core/alert/alert.service';

@Injectable()
export class UserService extends CrudService {
  constructor(
    @Inject('environment') protected env: any,
    public http: HttpClient,
    public router: Router,
    public storageService: StorageService,
    public alertService: AlertService
  ) {
    super(env, http, router, storageService, alertService, 'user');
    this.API = env.baseApiUrl;
  }

  getLoggedInUser() {
    return this.get(`${this.API}/api/user/loggedInUser`);
  }

  getUserList(): Observable<User[]> {
    return this.getList();
  }

  addUser(item: User): Observable<User> {
    return this.add(item);
  }

  ressetPassword(item: User): Observable<User> {
    return this.put(`${this.API}/api/user/ressetPassword`);
  }

  removeUser(id: any) {
    return this.remove(id);
  }

  getUserMobileAppStatus(customerProfileId: number): Observable<any> {
    return this.get(
      `${this.API}/api/user/${customerProfileId}/mobileAppStatus`
    );
  }
}
