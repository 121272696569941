import { State, Selector, Action, StateContext } from '@ngxs/store';
import { TenantCategory } from '../tenant-category.model';
import { FetchTenantCategoriesAction, SetTenantCategoriesAction } from './tenant-category.actions';
import { CategoryRepository } from '../category.repository';
import { tap, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { Injectable } from '@angular/core';


export class TenantCategoryStateModel {
  categories: TenantCategory[];
  fetching: boolean;
}
@Injectable()
@State<TenantCategoryStateModel>({
  name: 'tenantCategories',
  defaults: {
    fetching: false,
    categories: []
  }
})
export class TenantCategoryState {


  constructor(private readonly categoryRepository: CategoryRepository) { }

  @Selector()
  static categories(state: TenantCategoryStateModel) {
    return state.categories;
  }

  @Selector()
  static fetching(state: TenantCategoryStateModel) {
    return state.fetching;
  }

  @Action(FetchTenantCategoriesAction)
  fetchCategories(ctx: StateContext<TenantCategoryStateModel>, action: FetchTenantCategoriesAction) {
    const state = ctx.getState();
    if (!state.fetching && !state.categories.length) {
      ctx.patchState({
        fetching: true
      });

      const resetFetching = () => ctx.patchState({
        fetching: false
      });

      return this.categoryRepository.getCategories()
        .pipe(
          tap(categories => ctx.patchState({ categories })),
          tap(resetFetching),
          catchError(() => of(resetFetching))
        );
    }
  }

  @Action(SetTenantCategoriesAction)
  setTenantCategories(ctx: StateContext<TenantCategoryStateModel>, action: SetTenantCategoriesAction) {
    ctx.patchState({
      categories: action.categories
    });
  }
}
