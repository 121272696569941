import { Router } from '@angular/router';
import { Category } from '../../modules/profile/tenant-categories/category.model';
import { CategoryRepository } from '../../modules/profile/tenant-categories/category.repository';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';

@Component({
  selector: 'app-category-page',
  templateUrl: './category-page.component.html',
  styleUrls: ['./category-page.component.scss']
})
export class CategoryPageComponent implements OnInit, OnDestroy {
  constructor(private categoryRepository: CategoryRepository) { }

  public categories: Category[] = [];
  public isLoaded = false;
  private categoriesSubscription: Subscription;
  private deleteCategorySubscription: Subscription;
  currentCategoryId: number;
  showModal = false;

  ngOnInit() {
    this.loadCategories();
  }

  loadCategories(): void {
    this.startLoad();
    this.categoriesSubscription = this.categoryRepository.getCategories()
      .subscribe({
        next: (response: Category[]) => {
          this.categories = response;
        },
        complete: () => {
          this.finishLoad();
        }
      });
  }

  public deleteCategory(id: number): void {
    this.currentCategoryId = id;
    this.showModal = true;
  }

  modalClosed() {
    this.showModal = false;
  }

  confirmed() {
    this.showModal = false;
    this.startLoad();
    this.deleteCategorySubscription = this.categoryRepository.deleteCategory(this.currentCategoryId)
      .subscribe({
        next: (_) => {
          this.loadCategories();
        },
        complete: () => {
          this.finishLoad();
          this.currentCategoryId = null;
        }
      });
  }

  private startLoad(): void {
    this.isLoaded = false;
  }

  private finishLoad(): void {
    this.isLoaded = true;
  }

  ngOnDestroy(): void {
    if (this.categoriesSubscription) {
      this.categoriesSubscription.unsubscribe();
    }
    if (this.deleteCategorySubscription) {
      this.deleteCategorySubscription.unsubscribe();
    }
  }
}
