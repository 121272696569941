import { Injectable } from '@angular/core';
import { BaseApi } from '../core/base-api/base-api';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { StorageService } from '../core/storage/storage.service';
import { AlertService } from '~/modules/core/alert/alert.service';
import { NoteTypeEnum } from '../models/note/note-type.enum';
import { environment } from '../../../environments/environment';

const API = environment.baseApiUrl;

@Injectable()
export class NoteActionService extends BaseApi {
    constructor(public http: HttpClient, public router: Router, public storageService: StorageService, public alertService: AlertService) {
        super(http, router, storageService, alertService);
    }

    public markAsActioned(customerId: string, noteType: NoteTypeEnum[]) {
        return this.post(`${API}/api/customer/${customerId}/note/action?type=${noteType.join('&type=')}`);
    }
}
