import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import { ReactiveFormsModule } from '@angular/forms';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { RadioHighlightModule } from '../../../../../shared/components/radio-highlight/radio-highlight.module';
import { DAAPatientDetailsFormBasicComponent } from './basic/daa-patient-details-form-basic.component';
import { DAAPatientDetailsFormComponent, DAAPatientDetailsFormHostDirective } from './patient-details-form/daa-patient-details-form.component';
import { DAAPatientDetailsFormDetailedComponent } from './detailed/daa-patient-details-form-detailed.component';

@NgModule({
  declarations: [
    DAAPatientDetailsFormComponent,
    DAAPatientDetailsFormBasicComponent,
    DAAPatientDetailsFormDetailedComponent,
    DAAPatientDetailsFormHostDirective
  ],
  imports: [
    CommonModule,
    NgxsFormPluginModule,
    ReactiveFormsModule,
    BsDatepickerModule.forRoot(),
    RadioHighlightModule
  ],
  exports: [
    DAAPatientDetailsFormComponent
  ],
  entryComponents: [
    DAAPatientDetailsFormBasicComponent,
    DAAPatientDetailsFormDetailedComponent
  ]
})
export class DAAPatientDetailsFormModule { }
