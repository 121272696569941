import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-download-pdf',
  templateUrl: './download-pdf.component.html',
  styleUrls: ['./download-pdf.component.scss']
})
export class DownloadPdfComponent implements OnInit {

  constructor() { }

  @Input() name: string;
  @Output() downloadReportEvent = new EventEmitter<any>();

  ngOnInit() {
  }
  downloadReport() {
    this.downloadReportEvent.emit();
  }
}
