import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { EmailService } from '../../../../../shared/services/email.service';
import { Subscription } from 'rxjs/Subscription';
import 'rxjs/add/operator/map';
import { EmailType } from '../../../../../shared/models/emailType.model';
import { Notification } from '../../../../../shared/models/notification.model';
import { NgForm } from '@angular/forms';
import { Email } from '../../../../../shared/models/email.model';
import { Client } from '../../../../../shared/models/client.model';
import { CreateStatistic } from '../../../../../shared/state/statistics/statistics.actions';
import { Store } from '@ngxs/store';

@Component({
  selector: 'app-add-email',
  templateUrl: './add-email.component.html',
  styleUrls: ['./add-email.component.scss']
})
export class AddEmailComponent implements OnInit, OnDestroy {

  constructor(private store: Store, private emailService: EmailService) { }

  @Input() client: Client;
  @Output() emailSentEvent = new EventEmitter<Email>();

  isLoaded = false;
  emailSubscription: Subscription;
  newEmailSubscription: Subscription;
  notification: Notification;
  emailTypes: EmailType[] = [];


  ngOnInit() {
    this.notification = new Notification('success', '');
    this.emailSubscription = this.emailService.getCustomEmails().subscribe((item: EmailType[]) => {
      this.emailTypes = item;
      this.isLoaded = true;
    }, (error) => {
      this.showNotification('danger', error);
    });
  }

  onEmailTypeChange(form: NgForm) {
    const { emailTypeId } = form.value;
    const { text, subject } = this.emailTypes.find(c => c.id === emailTypeId);
    form.form.patchValue({ message: text ?? '', subject });
  }

  onSubmit(form: NgForm) {
    const { message, subject, emailTypeId } = form.value;
    const email = new Email(null, [], message, subject, this.client.emailAddress, null, null, this.client.id);

    this.newEmailSubscription = this.emailService.sendEmail(email)
      .subscribe((item: Email) => {
        form.reset();
        form.form.patchValue({ message: '', emailTypeId: '0', subject: '' });
        this.updateCommTemplateStatistc(emailTypeId);
        this.emailSentEvent.emit(item);
        this.showNotification('success', 'Message sent successfully');
      },
      (error) => {
        this.showNotification('danger', error);
      });

  }
  showNotification(type: string = 'danger', text: string = '') {
    this.notification.text = text;
    this.notification.type = type;
    window.setTimeout(() => {
      this.notification.text = '';
    }, 4000);
  }
  ngOnDestroy() {
    if (this.emailSubscription) { this.emailSubscription.unsubscribe(); }
    if (this.newEmailSubscription) { this.newEmailSubscription.unsubscribe(); }
  }

  private updateCommTemplateStatistc(emailTypeId: string) {
    if(+emailTypeId > 0) {
      const {id, subject} = this.emailTypes.find(c => c.id === emailTypeId);
      const updateStatRequest = {
        name: `CommTemplate - ${subject}`,
        entityId: +id,
        entityName: 'CommTemplate',
      }

      this.store.dispatch(new CreateStatistic(updateStatRequest))
    }
  }

}
