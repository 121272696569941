import { State, Action, StateContext, Selector } from '@ngxs/store';
import {
  GetCommTemplatesAction,
  GetCommTemplatesSuccessAction,
  CommTemplatesFailedAction,
  ActivateCommTemplatesAction,
  ActivateCommTemplatesSuccessAction,
  DeactivateCommTemplatesAction,
  DeactivateCommTemplatesSuccessAction,
} from './comm-templates.actions';
import { CommTemplate } from '~/shared/models/pharmacy/comm-template.model';
import { CommTemplatesService } from '../services/comm-templates.service';
import { tap, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';

export class CommTemplatesStateModel {
  commTemplates: CommTemplate[];
  error: any;
  loading: boolean;
}
@Injectable()
@State<CommTemplatesStateModel>({
  name: 'commTemplates',
  defaults: {
    commTemplates: [],
    error: null,
    loading: false
  }
})
export class CommTemplatesState {
  constructor(private commTemplatesService: CommTemplatesService) { }
  @Selector()
  static commTemplates(state: CommTemplatesStateModel) { return state.commTemplates; }

  @Action(GetCommTemplatesAction)
  getCommTemplates({ patchState, dispatch }: StateContext<CommTemplatesStateModel>) {
    patchState({ error: null, loading: true });
    return this.commTemplatesService.get().pipe(
      tap((res) => dispatch(new GetCommTemplatesSuccessAction(res))),
      catchError((error) => dispatch(new CommTemplatesFailedAction(error))));
  }

  @Action(GetCommTemplatesSuccessAction)
  getCommTemplatesSuccess({ patchState }: StateContext<CommTemplatesStateModel>, { commTemplates }: GetCommTemplatesSuccessAction) {
    patchState({ loading: false, commTemplates });
  }

  @Action(ActivateCommTemplatesAction)
  activateCommTemplates({ patchState, dispatch }: StateContext<CommTemplatesStateModel>, { id }: ActivateCommTemplatesAction) {
    patchState({ error: null, loading: true });
    return this.commTemplatesService.activate(id).pipe(
      tap(() => dispatch(new ActivateCommTemplatesSuccessAction(id))),
      catchError((error) => dispatch(new CommTemplatesFailedAction(error))));
  }

  @Action(ActivateCommTemplatesSuccessAction)
  activateCommTemplatesSuccess({ patchState, getState }: StateContext<CommTemplatesStateModel>, { id }: ActivateCommTemplatesSuccessAction) {
    const state = getState();
    patchState({
      loading: false,
      commTemplates: state.commTemplates.map(i => i.id === id ? { ...i, isActive: true } : i),
    });
  }

  @Action(DeactivateCommTemplatesAction)
  deactivateCommTemplates({ patchState, dispatch }: StateContext<CommTemplatesStateModel>, { id }: ActivateCommTemplatesAction) {
    patchState({ error: null, loading: true });
    return this.commTemplatesService.deactivate(id).pipe(
      tap(() => dispatch(new DeactivateCommTemplatesSuccessAction(id))),
      catchError((error) => dispatch(new CommTemplatesFailedAction(error))));
  }

  @Action(DeactivateCommTemplatesSuccessAction)
  deactivateCommTemplatesSuccess({ patchState, getState }: StateContext<CommTemplatesStateModel>, { id }: ActivateCommTemplatesSuccessAction) {
    const state = getState();
    patchState({
      loading: false,
      commTemplates: state.commTemplates.filter(i => i.id !== id),
    });
  }

  @Action(CommTemplatesFailedAction)
  pharmacyCommsDetailsFailed({ patchState }: StateContext<CommTemplatesStateModel>, { error }: CommTemplatesFailedAction) {
    patchState({ error: 'There was an issue loading your templates/messages. Please refresh (F5) the page to try again.', loading: false });
  }
}
