import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RadioHighlightModule } from '../../../../../shared/components/radio-highlight/radio-highlight.module';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import { ReactiveFormsModule } from '@angular/forms';
import { DaaClassificationsRecommendationsComponent } from './daa-classifications-recommendations.component';
import { FormControlFunctions } from '../../form-control.functions';

@NgModule({
  declarations: [
    DaaClassificationsRecommendationsComponent
  ],
  imports: [
    CommonModule,
    RadioHighlightModule,
    NgxsFormPluginModule,
    ReactiveFormsModule
  ],
  exports: [
    DaaClassificationsRecommendationsComponent
  ],
  providers: [
    FormControlFunctions
  ]
})
export class DAAClassificationsRecommendationsFormModule { }
