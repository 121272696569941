import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { OrderRequestStatus } from '../../models/order.enums';
import { OrderRequest } from '../../models/order-request.model';
import { ContactMethods } from '../../../../../shared/models/communication/contact-methods.enum';
import { ClientViewModel } from '../../../../core/profile/client.model';
import { FormBuilder, FormGroup } from '@angular/forms';
import * as moment from 'moment';

export interface SendToQueueUpdate {
  order: OrderRequest;
  notes: string;
  timePart: Date;
  datePart: Date;
  options: string[];
}

@Component({
  selector: 'app-send-to-queue-modal',
  templateUrl: './send-to-queue-modal.component.html',
  styleUrls: ['./send-to-queue-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SendToQueueModalComponent implements OnInit {
  @Input() order: OrderRequest;
  @Input() profile: ClientViewModel;
  @Input() isVisible: boolean;
  @Output() isVisibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  orderStatusEnum = OrderRequestStatus;
  orderStatus: OrderRequestStatus;
  message: string;
  sendToQueueForm: FormGroup;
  quickTimeControls = [
    {
      description: '+ 15 min',
      adjustment: (date: Date): Date => moment(date).add(15, 'minutes').toDate()
    }, {
      description: '+ 1 hour',
      adjustment: (date: Date): Date => moment(date).add(1, 'hour').toDate()
    }, {
      description: '+ 1 day',
      adjustment: (date: Date): Date => moment(date).add(1, 'day').toDate()
    }, {
      description: 'Clear',
      adjustment: (date: Date): Date => null
    }
  ];
  @Output() onSendToQueue: EventEmitter<SendToQueueUpdate> = new EventEmitter<SendToQueueUpdate>()
  defaultOptions = [
    {
      name: 'Generic All',
      value: 'Generic All'
    },
    {
      name: 'Seek Pharmacist',
      value: 'Seek Pharmacist'
    },
    {
      name: 'Receipt',
      value: 'Receipt'
    },
    {
      name: 'PRF Sticker',
      value: 'PRF Sticker'
    }
    ,
    {
      name: 'Delivery',
      value: 'Delivery'
    }
  ];

  constructor(
    private fb: FormBuilder
  ) { }

  ngOnInit(): void {
    this.configureForm();
  }

  configureForm() {
    this.sendToQueueForm = this.fb.group({
      timePart: this.fb.control(null),
      datePart: this.fb.control(null),
      checkboxOptions: this.fb.array(this.defaultOptions.map(x => this.fb.control(false))),
      options: this.fb.array(this.defaultOptions.map(x => this.fb.control(''))),
      notes: this.fb.control('')
    });
  }

updateForm(values:any){
  this.options.patchValue(values);
}

  get options() {
    return this.sendToQueueForm.get('options');
  }

  updateTime(adjustment: (date: Date) => Date): void {
    let {datePart, timePart} = this.sendToQueueForm.value;
    if(!datePart || !timePart) {
      datePart = new Date();
      timePart = new Date();
    }
    this.sendToQueueForm.patchValue({
      datePart: datePart ? adjustment(datePart): datePart,
      timePart: timePart ? adjustment(timePart): timePart
    });
  }

  handleOk() {
    this.isVisible = false;
    this.isVisibleChange.next(false);
    this.onSendToQueue.emit({...this.sendToQueueForm.value, order: this.order})
  }

  handleCancel() {
    this.isVisible = false;
    this.isVisibleChange.next(false);
  }

}
