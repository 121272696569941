import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { Select } from '@ngxs/store';
import { HMRFormState, HMRFormType } from '../hmr-form-list/state/hmr-form.state';
import { HMRProposedDetailsFormState, HMRProposedDetailsFormStateModel } from '../hmr-proposed-details/state/hmr-proposed-details-form.state';

@Component({
  selector: 'app-hmr-proposed-details-list-item',
  templateUrl: './hmr-proposed-details-list-item.component.html'
})
export class HMRProposedDetailsListItemComponent {
  @Select(HMRFormState.hmrFormType) hmrFormType$: Observable<HMRFormType>;
  @Select(HMRProposedDetailsFormState) formState$: Observable<HMRProposedDetailsFormStateModel>;
  hmrFormTypes = HMRFormType;

  getFormTitle(formType: HMRFormType) {
    switch (formType) {
        case HMRFormType.InterviewRegisteredPhamacist:
        return 'HMR Details';
        case HMRFormType.InterviewOutsidePatientHome:
          return 'HMR Proposed Details';
    }
  }
}
