import { OrderRequestItem } from "@profile/order-requests/models/order-request-item.model";
import { ColumnDef } from "../order-request-table/column-definitions"

const MedicationsColumn: ColumnDef<OrderRequestItem> ={
    id: 'medications',
    name: 'Medications',
    type: 'Text',
    width: '650px'
}

const ScriptQuantityColumn: ColumnDef<OrderRequestItem> = {
    id: 'scriptQTY',
    name: 'Script QTY',
    type: 'Text',
}

const RepeatsColumn: ColumnDef<OrderRequestItem> = {
    id: 'repeats',
    name: 'Repeats',
    type: 'Text',
}

const LastDispensedColumn: ColumnDef<OrderRequestItem> = {
    id: 'lastDispensed',
    name: 'Last dispensed',
    type: 'Text',
}

const QuantityColumn: ColumnDef<OrderRequestItem> = {
    id: 'quantity',
    name: 'Quantity',
    type: 'Text',
    width: '50px',
}

const StatusSelectorColumn: ColumnDef<OrderRequestItem> = {
    id: 'statusSelector',
    name: 'Status',
    type: 'Text',
    width: '200px'
}

/**
 * @internal Only to be used within the OrderRequestItemsTableComponent
 */
export const OrderRequestItemsColumns = [
    MedicationsColumn,
    ScriptQuantityColumn,
    RepeatsColumn,
    LastDispensedColumn,
    QuantityColumn,
    StatusSelectorColumn,
] as const;