<ng-container *ngIf="(order$ | async) as orderState; else loading">
  <ng-container *ngIf="!orderState.loading; else loading">
    <ng-container *ngIf="orderState.currentOrder as order">
      <a [routerLink]="['../']"><i class="fal fa-chevron-left"></i>&nbsp; Back to orders</a>

      <div class="d-flex align-items-center mt-2">
        <h2>New Order</h2>
          <nz-select
          class="order-method zorro-select-look-enabled"
          methodValidator
          #orderMethod="ngModel"
          ngDefaultControl
          nzPlaceHolder="Collection type"
          [ngClass]="{'zorro-select-invalid': submitted && orderMethod.errors?.required}"
          [(ngModel)]="order.orderMethod"
          (ngModelChange)="changeOrderStatus($event, order)"
          >
            <nz-option [nzValue]="orderMethods.Pickup" [nzLabel]="'Pickup'"></nz-option>
            <nz-option [nzValue]="orderMethods.Delivery" [nzLabel]="'Delivery'"></nz-option>
          </nz-select>
          <div class="red" *ngIf="submitted && orderMethod.errors?.required">
            Collection type is required.
          </div>
      </div>
      <div
        *ngIf="order.orderRequestedItems.length < 1"
        class="bg_blue status-badge">
          <i class="fas fa-info-circle"></i>
          No suggested items to dispense at this time. Please review profile or manually add items below
      </div>

      <app-order-table class="mt2"
        [dateFn]="formatDate"
        [orderItems]="order.orderRequestedItems"
        [disabled]="isReadOnly(order)"
        [isCreate]="true"
        (onOpenImage)="openImageModal($event)"
        (onRemoveItem)="removeItem(order, $event)"
        (onFormChange)="tableChange($event)"
        (orderItemsChange)="changeOrderItems($event, order)"
        ></app-order-table>

      <ng-container *ngFor="let hasSelectedOrderItems of [(hasSelectedOrderItems$ | async)]">
        <button nz-button nzType="ghost"
        [disabled]="isReadOnly(order) || !hasSelectedOrderItems"
        class="mt-2"
        [ngClass]="{'disabled-button' : isReadOnly(order) || !hasSelectedOrderItems}"
        (click)="openSendToQueueModal(order)"
        >
          Send To Queue&nbsp;<i class="fas fa-arrow-right"></i>
        </button>
      </ng-container>

      <button nz-button nzType="link"
        class="mt-2"
        [disabled]="isReadOnly(order)"
        (click)="openAddMedicationModal(order)"
        ><i class="fas fa-plus"></i>&nbsp;Add medication
      </button>

      <button
        nz-button nzType="link"
        [disabled]="isReadOnly(order)"
        (click)="openAdditionalItemsModal(order)"
        >
        <i class="fas fa-plus"></i>&nbsp;Add additional items
      </button>

      <div class="mt-4">
        <h3>Notes &nbsp;&nbsp;<span class="small"><i class="far fa-eye"></i>&nbsp;Visible to patient when order is updated</span></h3>
        <button
          *ngIf="!order.pharmacistNotes && !notesOpen"
          nz-button
          nzType="link"
          [disabled]="isReadOnly(order)"
          (click)="notesOpen = !notesOpen"
          >
          <i class="fas fa-plus"></i>&nbsp;Add notes
        </button>
        <textarea
          class="w50 order-notes"
          *ngIf="order.pharmacistNotes || notesOpen"
          nz-input
          [disabled]="isReadOnly(order)"
          rows="4" [(ngModel)]="order.pharmacistNotes"></textarea>
      </div>

      <!-- This container can be removed if we want to show Stripe Panel on Create Suggested Order Page -->
      <ng-container *ngIf="false">
        <div class="stripe-box" *ngIf="pharmacy && pharmacy.stripeApiKey && order.orderLink">
          <table>
            <tr>
              <td class="stripe-logo">
                <img src="assets/img/stripe.png"/>
              </td>
              <td style="width: 59%;">STRIPE Payment Gateway allows you to select medication, add additional items and customise each order individually
                to ensure a customer can make payment quickly, easily and conveniently.
              </td>
              <td class="payment-edit-btn">
                <button class="btn__white" (click)="visibleStripeModal(true)">
                  Edit Payment Link
                </button>
            </td>
            <td class="copy-payment-link" (click)="copyPaymentLink(order.orderLink)">
              <i class="fas fa-regular fa-copy"></i>
                Copy Payment Link
          </td>
            </tr>
          </table>
        </div>

        <div class="stripe-box" *ngIf="pharmacy && pharmacy.stripeApiKey && !order.orderLink">
          <table>
            <tr>
              <td class="stripe-logo">
                <img src="assets/img/stripe.png"/>
              </td>
              <td style="width: 67%;">STRIPE Payment Gateway allows you to select medication, add additional items and customise each order individually
                to ensure a customer can make payment quickly, easily and conveniently.
              </td>
              <td class="generate-payment-link" (click)="visibleStripeModal(true)">
                <button class="btn__white">
                  Generate Payment Link
                </button>
            </td>
            </tr>
          </table>
        </div>

        <div class="stripe-box" *ngIf="pharmacy && !pharmacy.stripeApiKey && !order.orderLink">
          <table>
            <tr>
              <td class="stripe-logo">
                <img src="assets/img/stripe.png"/>
              </td>
              <td style="width: 77%;">STRIPE Payment Gateway allows you to select medication, add additional items and customise each order individually
                to ensure a customer can make payment quickly, easily and conveniently.
              </td>
              <td class="generate-payment-link">
                <button class="btn__white" (click)="learnMore()">
                  Learn More
                </button>
            </td>
            </tr>
          </table>
        </div>

        <app-stripe-modal
        [isVisible]="orderState.stripeModalVisible"
        (isVisibleChange)="closeStripeModal()"
        [orderItems]="order.orderRequestedItems"
        [order]="order"
        >
        </app-stripe-modal>
      </ng-container>
      <app-order-update-status-modal
          [order]="order"
          [dateFn]="formatDate"
          [profile]="profile"
          [isVisible]="orderState.showStatusModal"
          (isVisibleChange)="closeModal(order)"
          (updateOrder)="createOrder($event)"
      >
      </app-order-update-status-modal>
      <app-send-to-queue-modal
        [order]="order"
        [profile]="profile"
        [isVisible]="orderState.showSendToQueue"
        (isVisibleChange)="closeSendModal()"
        (onSendToQueue)="sendToQueue($event)"
      >
      </app-send-to-queue-modal>
      <app-image-modal
        [item]="orderState.imageViewItem"
        [isVisible]="orderState.showImageModal"
        [updatedText]="formatDate(order.requestedDate)"
        (isVisibleChange)="closeImageModal()"
      >
      </app-image-modal>
      <app-additional-items-modal
        [isLoading]="orderState.additionalItemsLoading"
        [data]="searchListFiltered$ | async"
        [isVisible]="orderState.showAdditionalItemsModal"
        (isVisibleChange)="closeAdditionalItemsModal()"
        (onGetNewData)="stockSearch($event)"
        (onAddAdditionalItem)="addItem(order, $event)"
      >
      </app-additional-items-modal>
      <app-add-medication-modal
        [profileOrderItems]="profileOrderItems$ | async"
        [isVisible]="orderState.showAddMedicationModal"
        (isVisibleChange)="closeAddMedicationModal()"
        (onAddItem)="addItem(order, $event)"
        (onRemoveItem)="removeItem(order, $event)"
        [dateFn]="formatDate"
        [isCreate]="true"
      >
      </app-add-medication-modal>
      <app-order-update-status-card
       [disabled]="isReadOnly(order) || !(validService.validStatus | async)"
       [order]="order"
       [isCreate]="true"
       [profile]="profile"
       [(submitted)]="submitted"
       [isValid]="!orderMethod.errors?.required && (validService.validStatus | async)"
       (updateStatus)="updateOrderFromStatusCard($event)"></app-order-update-status-card>
      </ng-container>
  </ng-container>
</ng-container>
<ng-template #loading>
  <app-loader></app-loader>
</ng-template>
