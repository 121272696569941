import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SixCpaFormListItemChecklistModule } from '../../six-cpa-form-list-item-checklist/six-cpa-form-list-item-checklist.module';
import { SSMedicationInformationFormModule } from '../ss-medication-information-form/ss-medication-information-form.module';
import { SSMedicationInformationFormListItemComponent } from './ss-medication-information-form-list-item.component';

@NgModule({
  declarations: [
    SSMedicationInformationFormListItemComponent
  ],
  imports: [
    CommonModule,
    SixCpaFormListItemChecklistModule,
    SSMedicationInformationFormModule
  ],
  exports: [
    SSMedicationInformationFormListItemComponent
  ]
})
export class SSMedicationInformationFormListItemModule { }