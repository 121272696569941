import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable } from "rxjs/Observable";
import { BaseApi } from "../../../shared/core/base-api/base-api";
import { StorageService } from "../../../shared/core/storage/storage.service";
import { AlertService } from "../../core/alert/alert.service";


@Injectable()
export class InvoicesPageService extends BaseApi {
  baseApiUrl = '';

  constructor(public http: HttpClient, 
    public router: Router, 
    public storageService: StorageService, 
    public alertService: AlertService,
    @Inject('environment') public env: any) {
      super(http, router, storageService, alertService);
    this.baseApiUrl = env.baseApiUrl;
  }

  getAllInvoicesFromMonthAgo(): Observable<any> {
    const dateFromMonthAgo = this.getDateFromMonthAgo();
    return this.get(`${this.baseApiUrl}/api/invoice?startDate=${dateFromMonthAgo}`);
  }
  
  private getDateFromMonthAgo() {
    const today = new Date();
    const dateFromMonthAgo = new Date(today.getFullYear(), today.getMonth() - 1, today.getDate() - 2).toISOString();

    return dateFromMonthAgo;
  }
}
