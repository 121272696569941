import { AlertService } from '~/modules/core/alert/alert.service';
import { Subscription } from 'rxjs/Subscription';
import { CustomerProfile } from '~/shared/models/customerProfile.model';
import { VerifyService } from '~/shared/services/verify.service';
import { CustomerService } from '~/shared/services/customer.service';
import { Component, OnInit, OnDestroy  } from '@angular/core';

@Component({
  selector: 'app-verify-page',
  templateUrl: './verify-page.component.html',
  styleUrls: ['./verify-page.component.scss']
})
export class VerifyPageComponent implements OnInit, OnDestroy  {

  constructor(private customerService: CustomerService,
    private verifyService: VerifyService,
    private alertService: AlertService) { }

  customerProfileSub: Subscription;
  customerVerifySub: Subscription;
  customerProfiles: CustomerProfile[] = [];
  isListLoaded = false;
  selectedCustomer = 0;

  ngOnInit() {
    this.loadCustomersProfile();
  }

  loadCustomersProfile() {
    this.customerProfileSub = this.customerService.getCustomers()
      .subscribe(
        (data: CustomerProfile[]) => {
          this.customerProfiles = data;
          this.isListLoaded = true;

          if (this.customerProfiles[0]) {
            this.selectedCustomer = this.customerProfiles[0].id;
          } else {
            this.selectedCustomer = 0;
          }
        },
        (error: any) => {
          this.isListLoaded = true;
          this.alertService.error('An error occurred while loading customers');
        }
      );
  }

  changeSelectedCustomer(id: number) {
    this.selectedCustomer = id;
  }

  generateCode() {
    this.customerVerifySub = this.verifyService.verifyCustomer(this.selectedCustomer)
    .subscribe(
      result  => {
        const customer = this.customerProfiles.find(x => x.id === +this.selectedCustomer);
        const customerName = `${customer.firstName} ${customer.lastName}`;
        this.alertService.success(`Successfully generated ${result.code} code for ${customerName}`);
      },
      error => {
        this.alertService.error('An error occurred while generating the code');
      }
    );
  }

  ngOnDestroy() {
    this.customerProfileSub.unsubscribe();
    this.customerVerifySub.unsubscribe();
  }
}
