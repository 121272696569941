import { SSConsentEligibilityFormModel } from "~/shared/models/six-cpa/staged-supply/ss-consent-eligibility-form.model";

export enum UpdateSSConsentEligibilityDetailedFormActions {
  UPDATE = '[SSConsentEligibilityFormDetailed] Update Form'
}

export class UpdateSSConsentEligibilityDetailedForm {
  static readonly type = UpdateSSConsentEligibilityDetailedFormActions.UPDATE;
  constructor(public form: Partial<SSConsentEligibilityFormModel>) { }
}
