import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SixCpaFormListItemChecklistModule } from '../../six-cpa-form-list-item-checklist/six-cpa-form-list-item-checklist.module';
import { HMRGPDetailsReferralFormListItemComponent } from './hmr-gp-details-referral-form-list-item.component';
import { HMRGPDetailsReferralFormModule } from '../hmr-gp-details-referral-form/hmr-gp-details-referral-form.module';

@NgModule({
  declarations: [HMRGPDetailsReferralFormListItemComponent],
  imports: [
    CommonModule,
    SixCpaFormListItemChecklistModule,
    HMRGPDetailsReferralFormModule
  ],
  exports: [HMRGPDetailsReferralFormListItemComponent]
})
export class HMRGPDetailsReferralFormListItemModule { }
