import { Component, OnDestroy, ChangeDetectionStrategy } from '@angular/core';
import { Store, Select } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { MultipleDrugsState } from '../multiple-select/state/multiple-drugs.state';
import { InstoreOrderState, InstoreOrderStateModel } from './state/instore-order.state';
import { InstoreOrderRequestAction } from './state/instore-order.actions';
import { take, switchMap } from 'rxjs/operators';
import { ProfileState } from '../../../core/profile/state/profile.state';

@Component({
    selector: 'app-instore-order',
    templateUrl: './instore-order.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class InstoreOrderComponent implements OnDestroy {
    @Select(MultipleDrugsState.selectedDrugsArray) selectedDrugs$: Observable<any>;
    @Select(InstoreOrderState) instoreOrder$: Observable<InstoreOrderStateModel>;

    daysOnHandAdjustment = 0;
    private orderSubscription: Subscription;

    constructor(private store: Store) { }

    createInstoreOrder(selectedDrugs) {
        ProfileState.clientId$(this.store)
            .pipe(
                switchMap(clientId => this.store.dispatch(new InstoreOrderRequestAction(
                    clientId,
                    {
                        daysOnHandAdjustment: this.daysOnHandAdjustment,
                        metaIds: selectedDrugs.map((item) => item.cMeta.id)
                    }))
                ),
                take(1)
            )
            .subscribe(() => this.daysOnHandAdjustment = 0);
    }

    ngOnDestroy() {
        if (this.orderSubscription) { this.orderSubscription.unsubscribe(); }
    }
}
