import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { NavigationEnd, Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class RouterService {
  _events = new ReplaySubject<string>();

  constructor(private readonly router: Router) {
    router.events
      .pipe(
        filter((e) => e instanceof NavigationEnd),
        map((e) => e as NavigationEnd),
        map((e: NavigationEnd) => e.urlAfterRedirects),
        tap((url) => this._events.next(url))
      )
      .subscribe();
  }

  get events(): Observable<string> {
    return this._events.asObservable();
  }
}
