import {
  Component,
  OnInit
} from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Store, Select } from '@ngxs/store';
import { GetOrderList } from './state/order.actions';
import { OrderRequestState, OrderRequestStateModel } from './state/order.state';
import { ProfileState } from '../../../modules/core/profile/state/profile.state';
import { OrderRequestSortConstants } from './models/order-request.constants';

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss'],
})
export class OrderComponent implements OnInit {
  clientId: number;

  @Select(OrderRequestState) order$: Observable<OrderRequestStateModel>;
  
  constructor(
    private store: Store
  ) { }
  ngOnInit() {
    this.clientId = this.store.selectSnapshot(ProfileState.profile).customerProfile.customerProfileId;
    this.store.dispatch(new GetOrderList(this.clientId, {page: 0, pageSize: 10, sort: OrderRequestSortConstants.requestedDateDescending}));
  }
}
