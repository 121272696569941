import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AslCategoriesComponent } from './asl-medications.component';
import { NgxsModule } from '@ngxs/store';
import { AslMedicationsState } from './state/asl-medication.state';
import { EScriptModalModule } from '../../../shared/components/escripts/e-script-modal/e-script-modal.module';
import { ProfileState } from '../../../modules/core/profile/state/profile.state';
import { LoaderModule } from '../../..//modules/core/loader/loader.module';
import { QRCodeModule } from 'angularx-qrcode';
import { TooltipModule } from 'ngx-bootstrap';
import { CdkTableModule } from '@angular/cdk/table';

@NgModule({
  declarations: [
    AslCategoriesComponent
  ],
  imports: [
      CommonModule,
      EScriptModalModule,
      NgxsModule.forFeature([
          AslMedicationsState,
          ProfileState
      ]),
      LoaderModule,
      QRCodeModule,
      TooltipModule,
      CdkTableModule
  ],
  exports: [
    AslCategoriesComponent
  ]
})
export class AslMedicationsModule { }
