<form [formGroup]="pharmacyInformationForm" ngxsForm="pharmacyInformationForm.pharmacyInformationForm" class="p-4">
    <ng-container *ngIf="(pharmacists$ | async) as pharmacists">
        <label class="input-label">Pharmacist</label>
        <div class="row">
            <div class="form-group pb-1 col-md-5">
                <label for="pharmacistName" class="disclaimer">Pharmacist Name</label>
                <select formControlName="pharmacistName" class="form-control form-control-lg"
                [class.has-error]="formControls.pharmacistName.invalid && (showInvalid$ | async)"

                    (change)="setAhpraNumber()">
                    <option *ngFor="let p of pharmacists"
                        value="{{p.givenNames}} {{p.familyName}}">{{p.givenNames}} {{p.familyName}}</option>
                </select>
            </div>
            <div class="form-group pb-1 col-md-3">
                <label for="ahpraNumber" class="disclaimer">AHPRA Number</label>
                <input type="text" class="form-control form-control-lg" formControlName="ahpraNumber"
                [class.has-error]="formControls.ahpraNumber.invalid && (showInvalid$ | async)"

                    [value]="pharmacyInformationForm.value.ahpraNumber" readonly />
            </div>
        </div>
    </ng-container>
</form>
