import { Injectable } from '@angular/core';
import { State, Selector, Action, StateContext } from '@ngxs/store';
import { SetProfileForSixCpaAction } from '../../../state/six-cpa.actions';
import { UpdateHMRPatientDetailsForm } from './hmr-patient-details-form.actions';

export interface IHMRPatientDetailsFormDetailedStateModel {
    uniqueIdentifier: string;
    residentialPostCode: string;
    homeSuburb: string;
  }

  export class HMRPatientDetailsFormDetailedStateModel {
    patientDetailsForm: {
      model: Partial<IHMRPatientDetailsFormDetailedStateModel>;
    };
  }

  @State<HMRPatientDetailsFormDetailedStateModel>({
    name: 'hmrPatientDetailsFormDetailed',
    defaults: {
      patientDetailsForm: {
        model: undefined
      }
    }
  })
  @Injectable()
  export class HMRPatientDetailsFormDetailedState {
    constructor() {}
    @Selector()
    static form(state: HMRPatientDetailsFormDetailedStateModel) { return state.patientDetailsForm.model; }

    @Action(SetProfileForSixCpaAction)
    onSetProfile(ctx: StateContext<HMRPatientDetailsFormDetailedStateModel>, { profile }: SetProfileForSixCpaAction) {
      const patient = profile.patient;
      ctx.patchState({
        patientDetailsForm: {
          model: {
            homeSuburb: patient.homeSuburb,
            residentialPostCode: (patient && patient.homePostcode) ? patient && patient.homePostcode.toString() : null
          }
        }
      });
    }

    @Action(UpdateHMRPatientDetailsForm)
    updateForm(ctx: StateContext<HMRPatientDetailsFormDetailedStateModel>, { form }: UpdateHMRPatientDetailsForm) {
      if (form) {
        ctx.patchState({
          patientDetailsForm: {
            model: {
              residentialPostCode: form.residentialPostCode,
              homeSuburb: form.homeSuburb,
              uniqueIdentifier: form.uniqueIdentifier
            }
          }
        });
      } else {
        const state = ctx.getState();
        ctx.patchState({
          patientDetailsForm: {
            model: {
              ...state.patientDetailsForm.model,
              uniqueIdentifier: null
            }
          }
        });
      }
    }
  }
