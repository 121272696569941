import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Store, Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { ActivatedRoute, Router, NavigationExtras } from '@angular/router';
import * as moment from 'moment';
import { AuthDocumentRequest } from '../state/document-services.actions';
import {
  DocumentServicesState,
  DocumentServicesStateModel,
} from '../state/document-services.state';

function dateValidator(form: FormGroup): { [key: string]: boolean } | null {
  if (form) {
    const day = form.get('day').value;
    const month = form.get('month').value;
    const year = form.get('year').value;
    const dob = moment([year, month - 1, day]);
    if (!dob.isValid()) {
      return { dateInvalid: true };
    }
  }
  return null;
}
@Component({
  selector: 'app-document-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss'],
})
export class AuthComponent implements OnInit {
  constructor(
    private store: Store,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {}
  @Select(DocumentServicesState)
  auth$: Observable<DocumentServicesStateModel>;
  @Select(DocumentServicesState) isAuth$: Observable<boolean>;

  dayOpts: number[] = [];
  yearOpts: number[] = [];

  maxDate: Date;

  authForm = new FormGroup(
    {
      day: new FormControl(1, Validators.required),
      month: new FormControl(1, Validators.required),
      year: new FormControl(1980, Validators.required),
    },
    { validators: [dateValidator] }
  );

  ngOnInit() {
    this.maxDate = new Date();

    for (let i = 0; i < 31; i++) {
      this.dayOpts.push(i + 1);
    }

    for (let i = this.maxDate.getFullYear(); i > 1899; i--) {
      this.yearOpts.push(i);
    }
  }

  onSubmit() {
    const { day, month, year } = this.authForm.value;
    const birthDate = moment([year, month - 1, day])
      .startOf('day')
      .utc()
      .toDate();
    this.store.dispatch(new AuthDocumentRequest(birthDate));
  }
}
